import styled from "styled-components";

export const WrapperScatter=styled.div`
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
    background-color: #2e2e2e;
    display: grid !important;
    height: 100%;
    width: 100%;
`;

export const SelectAxisType = styled.div `
    opacity: 1;
    margin-top:0.2em;
    margin-left:0.3em;
    display:inline;
    position:relative;
`

export const HeaderScatterWrapping = styled.div `
    cursor:move;
    margin-top:1;
`

export const VersusWrapper =styled.p`
    display:inline;
    font-weight: bold;
    margin-left: 0.5em;
    margin-right: 0.5em;
    margin-top: 0.3em;
    color:white;
`

export const WrapperLog=styled.div`
    display:flex;
    flex-direction:row;
    margin-left: 0.5em;
`

export const CenterSelect=styled.div`
    display:block;
    text-align:center;
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color:white;

`

export const DropdownWrapper=styled.div`
    display:flex;
    flex-direction:row;
`

export const CheckboxWrapper=styled.div`
    display:flex;
    flex-direction:row;
    align-self: center;
    padding-top:-0.5em

`