import styled from "styled-components";

export const SearchInput = styled.input`
    padding: 0px 40px 0px 30px;
    width: 100%;
    min-height: ${props=>props.height?props.height:'30px'};
    height: 100%;
    font-family: Abel;
    font-size: ${props=>props.fontSize?props.fontSize:'1em'};
    background-color: ${props=>props.backgroundColor?props.backgroundColor:'rgb(17, 17, 17)'};
    border-color: ${props=>props.borderColor?props.borderColor:'rgb(236, 236, 236)'};
    border-radius: 8px;
    color: ${props=>props.color?props.color:'white'};
    border-width: ${props=>props.borderWidth?props.borderWidth:'2px'};
    &:focus {
        outline: none;
        border: ${props=>props.borderWidth?props.borderWidth:'2px'} solid goldenrod;
    };
    &::-webkit-search-cancel-button {
        -webkit-appearance: none;
    }
    &::placeholder {
        color: ${props=>props.color?props.color:'white'};
        opacity: 1;
    }
    &:-ms-input-placeholder {
        color: ${props=>props.color?props.color:'white'};
    }
    &::-ms-input-placeholder {
        color: ${props=>props.color?props.color:'white'};
    }
`;

export const SearchContainer = styled.div`
    flex-direction: column;
    width: 100%;
    align-items: center;
`
export const SearchInputContainer = styled.div`
    min-height: 30px;
    height: 3%;
    min-width: 180px;
    width: 100%;
    position: relative;
`