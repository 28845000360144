/**
 * Axios instance with automatic token refresh interceptor to token API
 * References
 * https://medium.com/swlh/handling-access-and-refresh-tokens-using-axios-interceptors-3970b601a5da
*/
import axios from 'axios';

import {
  BASE_URL,
} from '../constants/Server/base';

import cookies from './cookies';

// const cookies = new Cookies();
const cookieOpt = {
  path: '/',
};

let link = BASE_URL;

const instance = axios.create({
  baseURL: link,
  timeout: 60 * 4 * 1000,
});

instance.interceptors.request.use(
    (config) => {
        // use interceptor to set access token before sending request
        let accessToken = cookies.get('accessToken');
        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        };
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

instance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        let originalRequest = error.config;
        if (!error.response) {
            return Promise.reject(error)
        }

        if (error.response.status === 401 && !originalRequest._retry) {
            let refreshToken = cookies.get('refreshToken');
            originalRequest._retry = true;
            return axios({
                method: 'get',
                url: `${link}/refresh`,
                headers: {
                    'Authorization': `Bearer ${refreshToken}`,
                },
            })
                .then(res => {
                    if (res.status === 200) {
                        let accessToken = res.data['access_token'];
                        let refreshToken = res.data['refresh_token'];
                        // put token in cookie
                        cookies.set('accessToken', accessToken, cookieOpt);
                        cookies.set('refreshToken', refreshToken, cookieOpt);
                        // change auth header
                        instance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
                        // return original request object with axios
                        return instance(originalRequest);
                    };
                })
        }
        return Promise.reject(error);
    },
);

export const getInstance = () => {
    const instance = axios.create();

    instance.interceptors.request.use(
        (config) => {
            // use interceptor to set access token before sending request
            let accessToken = cookies.get('accessToken');
            // let refreshToken = cookies.get('refreshToken');
            if (accessToken) {
                config.headers['Authorization'] = `Bearer ${accessToken}`;
            };
            return config;
        },
        (error) => {
            return Promise.reject(error);
        },
    );

    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            let originalRequest = error.config;
            if (error.response.status === 401 && !originalRequest._retry) {
                let refreshToken = cookies.get('refreshToken');
                originalRequest._retry = true;
                return axios({
                    method: 'get',
                    url: `${link}/refresh`,
                    headers: {
                        'Authorization': `Bearer ${refreshToken}`,
                    },
                })
                    .then(res => {
                        if (res.status === 200) {
                            let accessToken = res.data['access_token'];
                            let refreshToken = res.data['refresh_token'];
                            // put token in cookie
                            cookies.set('accessToken', accessToken, cookieOpt);
                            cookies.set('refreshToken', refreshToken, cookieOpt);
                            // change auth header
                            instance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
                            // return original request object with axios
                            return instance(originalRequest);
                        };
                    })
            }
            return Promise.reject(error);
        },
    );
    return instance;
}

export function getProgressDownload(event, cbProgress) {
    let contentLength

    if (event.lengthComputable) {
        contentLength = event.total
    } else {
        contentLength = parseInt(event.target.getResponseHeader('x-decompressed-content-length'), 10)
    }

    let fetchDataProgress = (event.loaded / contentLength * 100).toFixed(0)
        + '% of '

    const showSize = [
        {
            fileSize: 1e12,
            size: 'TB',
        },
        {
            fileSize: 1e9,
            size: 'GB'
        },
        {
            fileSize: 1e6,
            size: 'MB'
        },
        {
            fileSize: 1e3,
            size: 'KB'
        },
        {
            fileSize: 1,
            size: 'B'
        }
    ]

    for (let i = 0; i < showSize.length; i++) {
        if (contentLength > showSize[i].fileSize) {
            fetchDataProgress += (contentLength / showSize[i].fileSize).toFixed(1)
            fetchDataProgress += showSize[i].size
            
            break
        }
    }

    cbProgress(fetchDataProgress)
}

export default instance;
