import { interpolateSpectral } from 'd3'

import {
  SCORING_COLOR,
  SCORING_LOGIC,
  CUSTOM_INTERPOLATION
} from '../../../../constants/Colors/graph'

import TechnicalParametersGraphic from '../../../Graphics/TechnicalParametersGraphic'

import { rgbToHsl } from '../../../helpers/colorParser'

import { ReactComponent as VisitIcon } from '../../../../images/Icons/visit.svg'
import { ReactComponent as CompareIcon } from '../../../../images/Icons/compare.svg'

export function renderCompare({
  addThousand,
  compareSite
}) {
  function getNowByKey(data, key, year) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].year === year) {
        return data[i][key]
      }
    }

    return ''
  }

  function getHueColor(score) {
    const interpolate = CUSTOM_INTERPOLATION(score)

    const [red, green, blue] = interpolate(score).slice(4, -1).split(', ')

    return rgbToHsl(red, green, blue)
  }

  if (compareSite.from && compareSite.target) {
    // const date = new Date()
    // const year = date.getFullYear()

    let dataGraph = {
      from: {
        id: 'technical-paramaters-graph-from',
        graph: null,
        graphNonTechnical: null,
        score: null,
        scoreNonTechnical: null,
        graphDesign: null,
        graphDesignNonTechnical: null
      },
      target: {
        id: 'technical-paramaters-graph-target',
        graph: null,
        graphNonTechnical: null,
        score: null,
        scoreNonTechnical: null,
        graphDesign: null,
        graphDesignNonTechnical: null
      }
    }

    dataGraph.from.graph = {
      geology: Number(compareSite.from.final_rating_geology),
      lineament: Number(compareSite.from.final_rating_lineament),
      others: Number(compareSite.from.final_rating_others)
    }

    dataGraph.from.score = compareSite.from.final_rating_technical

    const fromScore = getHueColor(dataGraph.from.score)

    dataGraph.from.graphDesign = {
      point: {
        hue: fromScore.h,
        saturation: fromScore.s,
        lightness: fromScore.l - 40
      },
      outline: {
        hue: fromScore.h,
        saturation: fromScore.s,
        lightness: fromScore.l - 20
      },
      fill: {
        hue: fromScore.h,
        saturation: fromScore.s,
        lightness: fromScore.l
      }
    }

    dataGraph.target.graph = {
      geology: Number(compareSite.target.final_rating_geology),
      lineament: Number(compareSite.target.final_rating_lineament),
      others: Number(compareSite.target.final_rating_others)
    }

    dataGraph.target.score = compareSite.target.final_rating_technical

    const targetScore = getHueColor(dataGraph.target.score)

    dataGraph.target.graphDesign = {
      point: {
        hue: targetScore.h,
        saturation: targetScore.s,
        lightness: targetScore.l - 40
      },
      outline: {
        hue: targetScore.h,
        saturation: targetScore.s,
        lightness: targetScore.l - 20
      },
      fill: {
        hue: targetScore.h,
        saturation: targetScore.s,
        lightness: targetScore.l
      }
    }

    dataGraph.from.graphNonTechnical = {
      accessibility: Number(compareSite.from.final_rating_accessibility),
      hazard: Number(compareSite.from.final_rating_hazard),
      forestry: Number(compareSite.from.final_rating_forestry)
    }

    dataGraph.from.scoreNonTechnical = compareSite.from.final_rating_non_technical

    const fromScoreNonTechnical = getHueColor(dataGraph.from.scoreNonTechnical)

    dataGraph.from.graphDesignNonTechnical = {
      point: {
        hue: fromScoreNonTechnical.h,
        saturation: fromScoreNonTechnical.s,
        lightness: fromScoreNonTechnical.l - 40
      },
      outline: {
        hue: fromScoreNonTechnical.h,
        saturation: fromScoreNonTechnical.s,
        lightness: fromScoreNonTechnical.l - 20
      },
      fill: {
        hue: fromScoreNonTechnical.h,
        saturation: fromScoreNonTechnical.s,
        lightness: fromScoreNonTechnical.l
      }
    }

    dataGraph.target.graphNonTechnical = {
      accessibility: Number(compareSite.target.final_rating_accessibility),
      hazard: Number(compareSite.target.final_rating_hazard),
      forestry: Number(compareSite.target.final_rating_forestry)
    }

    dataGraph.target.scoreNonTechnical = compareSite.target.final_rating_non_technical

    const targetScoreNonTechnical = getHueColor(dataGraph.target.scoreNonTechnical)

    dataGraph.target.graphDesignNonTechnical = {
      point: {
        hue: targetScoreNonTechnical.h,
        saturation: targetScoreNonTechnical.s,
        lightness: targetScoreNonTechnical.l - 40
      },
      outline: {
        hue: targetScoreNonTechnical.h,
        saturation: targetScoreNonTechnical.s,
        lightness: targetScoreNonTechnical.l - 20
      },
      fill: {
        hue: targetScoreNonTechnical.h,
        saturation: targetScoreNonTechnical.s,
        lightness: targetScoreNonTechnical.l
      }
    }

    return <>
      <div className="similar-project-compare-flex">
        <div className="similar-project-compare-header-left-flex">
        </div>
        <div className="similar-project-compare-header-flex">
          <img
            src={compareSite.from.image}
            alt={compareSite.from.name}
            className="similar-project-compare-image"
          />
          <p className="similar-project-compare-text">
            {compareSite.from.name}
          </p>
        </div>
        <div className="similar-project-compare-header-flex">
          <img
            src={compareSite.target.image}
            alt={compareSite.target.name}
            className="similar-project-compare-image"
          />
          <p className="similar-project-compare-text">
            {compareSite.target.name}
          </p>
        </div>
      </div>
      <div className="similar-project-compare-flex">
        <div className="similar-project-compare-header-left-flex">
        <p className="similar-project-compare-text">
          Rating
        </p>
        </div>
        <div className="similar-project-compare-header-flex">
          <p className="similar-project-compare-text">
            {
              compareSite.from.final_rating
            }
          </p>
        </div>
        <div className="similar-project-compare-header-flex">
          <p className="similar-project-compare-text">
            {
              compareSite.target.final_rating
            }
          </p>
        </div>
      </div>
      <div className="similar-project-compare-flex">
        <div className="similar-project-compare-header-left-flex">
          <p className="similar-project-compare-text">
            Rating Technical
          </p>
        </div>
        <div className="similar-project-compare-header-flex">
          <p className="similar-project-compare-text">
            {
              compareSite.from.final_rating_technical
            }
          </p>
        </div>
        <div className="similar-project-compare-header-flex">
          <p className="similar-project-compare-text">
            {
              compareSite.target.final_rating_technical
            }
          </p>
        </div>
      </div>
      <div className="similar-project-compare-flex">
        <div className="similar-project-compare-header-left-flex">
          <p className="similar-project-compare-text">
            Rating Non Technical
          </p>
        </div>
        <div className="similar-project-compare-header-flex">
          <p className="similar-project-compare-text">
            {
              compareSite.from.final_rating_non_technical
            }
          </p>
        </div>
        <div className="similar-project-compare-header-flex">
          <p className="similar-project-compare-text">
            {
              compareSite.target.final_rating_non_technical
            }
          </p>
        </div>
      </div>
      <div className="similar-project-compare-flex">
        <div className="similar-project-compare-header-left-flex">
        <p className="similar-project-compare-text">
          Technical Parameters
        </p>
        </div>
        <div className="similar-project-compare-header-flex">
          <div className="technical-graphic-from">
            {
              dataGraph.from.graph
                ? <TechnicalParametersGraphic
                  classContainer=".technical-graphic-from"
                  id={dataGraph.from.id}
                  graph={dataGraph.from.graph}
                  graphDesign={dataGraph.from.graphDesign}
                />
                : <></>
            }
          </div>
        </div>
        <div className="similar-project-compare-header-flex">
          <div className="technical-graphic-target">
            {
              dataGraph.target.graph
                ? <TechnicalParametersGraphic
                  classContainer=".technical-graphic-target"
                  id={dataGraph.target.id}
                  graph={dataGraph.target.graph}
                  graphDesign={dataGraph.target.graphDesign}
                />
                : <></>
            }
          </div>
        </div>
      </div>
      <div className="similar-project-compare-flex">
        <div className="similar-project-compare-header-left-flex">
        <p className="similar-project-compare-text">
          Non Technical Parameters
        </p>
        </div>
        <div className="similar-project-compare-header-flex">
          <div className="non-technical-graphic-from">
            {
              dataGraph.from.graphNonTechnical
                ? <TechnicalParametersGraphic
                  classContainer=".non-technical-graphic-from"
                  id={dataGraph.from.id}
                  graph={dataGraph.from.graphNonTechnical}
                  graphDesign={dataGraph.from.graphDesignNonTechnical}
                  parameterType="non-technical"
                />
                : <></>
            }
          </div>
        </div>
        <div className="similar-project-compare-header-flex">
          <div className="non-technical-graphic-target">
            {
              dataGraph.target.graphNonTechnical
                ? <TechnicalParametersGraphic
                  classContainer=".non-technical-graphic-target"
                  id={dataGraph.target.id}
                  graph={dataGraph.target.graphNonTechnical}
                  graphDesign={dataGraph.target.graphDesignNonTechnical}
                  parameterType="non-technical"
                />
                : <></>
            }
          </div>
        </div>
      </div>
    {/* <div className="similar-project-compare-flex">
      <div className="similar-project-compare-header-left-flex">
      <p className="similar-project-compare-text">
        Lithology
      </p>
      </div>
      <div className="similar-project-compare-header-flex">
        {
          // compareSite.from.lithologies
          //   ? compareSite.from.lithologies.map()
          //   : <></>
        }
        <p className="similar-project-compare-text">
          Batuan A
        </p>
      </div>
      <div className="similar-project-compare-header-flex">
        <p className="similar-project-compare-text">
          Batuan B
        </p>
      </div>
    </div>
    <div className="similar-project-compare-flex">
      <div className="similar-project-compare-header-left-flex">
      <p className="similar-project-compare-text">
        Structures
      </p>
      </div>
      <div className="similar-project-compare-header-flex">
        <p className="similar-project-compare-text">
          Major Minor Moderate
        </p>
      </div>
      <div className="similar-project-compare-header-flex">
        <p className="similar-project-compare-text">
          Major Minor Moderate
        </p>
      </div>
    </div> */}
      {/* <div className="similar-project-compare-flex">
        <div className="similar-project-compare-header-left-flex">
        <p className="similar-project-compare-text">
          Resources (Oz)
        </p>
        </div>
        <div className="similar-project-compare-header-flex">
          <p className="similar-project-compare-text">
            {addThousand(compareSite.from.resourceOz)}
          </p>
        </div>
        <div className="similar-project-compare-header-flex">
          <p className="similar-project-compare-text">
            {addThousand(compareSite.target.resourceOz)}
          </p>
        </div>
      </div>
      <div className="similar-project-compare-flex">
        <div className="similar-project-compare-header-left-flex">
        <p className="similar-project-compare-text">
          Reserves (Oz)
        </p>
        </div>
        <div className="similar-project-compare-header-flex">
          <p className="similar-project-compare-text">
            {addThousand(compareSite.from.reserveOz)}
          </p>
        </div>
        <div className="similar-project-compare-header-flex">
          <p className="similar-project-compare-text">
            {addThousand(compareSite.target.reserveOz)}
          </p>
        </div>
      </div> */}
    </>
  } else {
    return <></>
  }
}

export function renderContent({
  matchSites,
  setCompare,
  setCompareSite,
  sites,
  visitSite
}) {
  if (!matchSites.length) {
    return <></>
  } else {
    return <>
      {
        matchSites.map((site, i) => {
          return <div
            key={site.id}
            className="match-sites-container"
            style={{
              backgroundColor: i % 2
                ? 'white'
                : 'rgb(230, 230, 230)'
            }}
          >
            <div className="match-sites-left-content">
              <img
                src={site.image}
                alt={site.id + '-image'}
                className="match-sites-content-image"
              />
              <div className="row">
                <button
                  className="match-sites-content-button match-sites-visit"
                  onClick={() => {
                    visitSite({
                      site
                    })
                  }}
                >
                  <VisitIcon className="match-sites-icon" />
                  Visit
                </button>
                <button
                  className="match-sites-content-button match-sites-compare"
                  onClick={async () => {
                    setCompare(true)

                    setCompareSite({
                      from: sites.selected.site,
                      target: site
                    })
                  }}
                >
                  <CompareIcon className="match-sites-icon" />
                  Compare
                </button>
              </div>
            </div>
            <div className="match-sites-right-content">
              <div className="row">
                <p className="match-sites-text">Site Name</p>
                <p className="match-sites-text-value">: {site.name}</p>
              </div>
              <div className="row">
                <p className="match-sites-text">Final Rating</p>
                <p className="match-sites-text-value">: {site.final_rating}</p>
              </div>
              <div className="row">
                <p className="match-sites-text">Final Rating Technical</p>
                <p className="match-sites-text-value">: {site.final_rating_technical}</p>
              </div>
              <div className="row">
                <p className="match-sites-text">Final Rating Non Technical</p>
                <p className="match-sites-text-value">: {site.final_rating_non_technical}</p>
              </div>
              <div className="row">
                <p className="match-sites-text">Deposit Type</p>
                <p className="match-sites-text-value">: {site?.deposit_type?.toUpperCase()}</p>
              </div>
              {/* <div className="row">
                <p className="match-sites-text">Resource (Oz)</p>
                <p className="match-sites-text-value">: {addThousand(site.resourceOz)}</p>
              </div> */}
              <div className="row">
                <p className="match-sites-text">Similarities</p>
                <p className="match-sites-text-value">: {site.percentage_similar} %</p>
              </div>
              {/* <div className="row">
                <p className="match-sites-text">Reserve (Oz)</p>
                <p className="match-sites-text-value">: {addThousand(site.reserveOz)}</p>
              </div> */}
            </div>
          </div>
        })
      }
    </>
  }
}
