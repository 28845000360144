import * as actionTypes from '../../constants/store/actionTypes/sitebar'

import {
  SITEBAR_WIDTH
} from '../../constants/Menubars/DetailSitebar'

const initialState = {
  toggle: false,
  minimize: false,
  width: SITEBAR_WIDTH
}

function sitebarReducer (state = initialState, { type, payload }) {
  switch (type) {
    case actionTypes.TOGGLE_SITEBAR_SUCCESS:
      return {
        ...state,
        toggle: payload.toggle,
        minimize: payload.minimize
      }
    case actionTypes.MINIMIZE_SITEBAR:
      return {
        ...state,
        minimize: payload.minimize
      }
    case actionTypes.RESET_SITEBAR:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default sitebarReducer
