import styled from 'styled-components'

// refactor next for styled components search "from 'styled-components'"

const FlexWrapper = styled.div.attrs((props) => {
  let style = {}

  // ALIGN ITEMS
  if (props.center) {
    style.alignItems = 'center'
  }

  // JUSTIFY CONTENT
  if (props.between) {
    style.justifyContent = 'space-between'
  }

  // FLEX DIRECTION
  if (props.col) {
    style.flexDirection = 'column'
  }

  // PADDING
  if (props.padding) {
    style.padding = props.padding
  } else if (props.header) {
    style.padding = '0.5em 0.5em'
  } else if (props['main-content']) {
    style.padding = '0.5em 0.5em'
  } else if (props['wider-height']) {
    style.padding = '0.32em 0em';
  }

  // HEADER
  if (props.header) {
    style.backgroundColor = 'rgb(175, 175, 175)'
  } else if (props.backgroundColor) {
    style.backgroundColor = props.backgroundColor
  }

  // BORDER
  if (props.header) {
    style.border = '0.01px solid rgb(145, 145, 145)'
  }

  // MIN HEIGHT
  if (props.minHeight) {
    style.minHeight = props.minHeight
  } else {
    style.minHeight = '1em'
  }

  // MAX HEIGHT
  if (props.maxHeight) {
    style.maxHeight = props.maxHeight
  }

  // HEIGHT
  if (props.container) {
    style.height = '100%'
  } else if (props.header) {
    style.height = '1em'
  } else if (props['main-content']) {
    style.height = 'calc(50% - 6.7em)'
  } else if (props.height) {
    style.height = props.height
  }

  // WIDTH
  if (props.container) {
    style.width = '100%'
  } else if (props.width) {
    style.width = props.width
  }

  // OVERFLOW Y
  if (props['no-overflow']) {
    style.overflowY = 'hidden'
  } else if (!props['non-scroll']) {
    style.overflowY = 'auto'
  }

  // MARGIN TOP
  if (props.marginTop) {
    style.marginTop = props.marginTop
  }

  // MARGIN LEFT
  if (props.marginLeft) {
    style.marginLeft = props.marginLeft
  }

  // MARGIN RIGHT
  if (props.marginRight) {
    style.marginRight = props.marginRight
  }

  // MARGIN BOTTOM
  if (props.marginBottom) {
    style.marginBottom = props.marginBottom
  }
  
  // MARGIN
  if (props.margin) {
    style.margin = props.margin
  }

  return {
    style
  }
})`
  display: flex;
`

export default FlexWrapper
