import React from 'react'

import FocusContainer, {
  Header,
  LinkText,
  Paragraph
} from '../StyledComponent'
import AssistiveButtonPNG from '../HelpImage/assistive-button.png'
import AssistiveButtonDoublePNG from '../HelpImage/assistive-button-double.png'
import AssistiveButtonMiddlePNG from '../HelpImage/assistive-button-middle.png'
import AssistiveButtonMousePNG from '../HelpImage/assistive-button-mouse.png'
import AssistiveButtonSinglePNG from '../HelpImage/assistive-button-single.png'
import { LIST_HELP } from '../constant'

function HelpAssistiveButton(props) {
  const flyTo=(x,y)=>{
    let cloneOpened = []
    cloneOpened.push(y)
    
    props.setOpened(cloneOpened)

    props.setFocus(x)
  }
  return <>
    <FocusContainer {...props}>
      <Header>{LIST_HELP['dashboard'].detail.assistiveButton.header}</Header>
      <img src={AssistiveButtonPNG} alt="Assistive button image" width="350px" style={{marginBottom:'1em'}}/>
      <img src={AssistiveButtonMousePNG} alt="Assistive button mouse image" width="500px" style={{margin:'0em 0em 3em 1em'}}/>
      <Paragraph>
        a. Show/hide button assistive menu settings by double left click.
      </Paragraph>
      <Paragraph style={{marginLeft:'1em'}}>
        1. Button Display Settings, tick to activate button in assistive button
      </Paragraph>
      <img src={AssistiveButtonDoublePNG} alt="Assistive button double image" width="600px" style={{marginBottom:'1em'}}/>
      <Paragraph>
        b. Show/hide map view manager by left click.
      </Paragraph>
      <Paragraph style={{marginLeft:'1em'}}>
        1. <LinkText onClick={()=>flyTo('home','assistive')}>Home</LinkText>, left click to fly to Indonesia map view
      </Paragraph>
      <Paragraph style={{marginLeft:'1em'}}>
        2. <LinkText onClick={()=>flyTo('cross','assistive')}>Cross Section</LinkText>, click to use turn it on or off cross section
      </Paragraph>
      <Paragraph style={{marginLeft:'1em'}}>
        3. <LinkText onClick={()=>flyTo('block','assistive')}>Block Section</LinkText>, click to use turn it on or off block section
      </Paragraph>
      <Paragraph style={{marginLeft:'1em'}}>
        4. <LinkText onClick={()=>flyTo('cursor','assistive')}>Cursor</LinkText>, make cursor work normally
      </Paragraph>
      <Paragraph style={{marginLeft:'1em'}}>
        5. <LinkText onClick={()=>flyTo('slice','assistive')}>Slice Section</LinkText>, used to slice the block section
      </Paragraph>
      {/* <Paragraph style={{marginLeft:'1em'}}>
        6. <LinkText onClick={()=>flyTo('grid','assistive')}>Grid Options</LinkText>, click to show/hide grid options layer
      </Paragraph> */}
      {/* <Paragraph style={{marginLeft:'1em'}}>
        7. Elevation Path (coming soon)
      </Paragraph> */}
      {/* <Paragraph style={{marginLeft:'1em'}}>
        8. <LinkText onClick={()=>flyTo('table','assistive')}>Table</LinkText>, click to show/hide table layer
      </Paragraph>
      <Paragraph style={{marginLeft:'1em'}}>
        9. Screen Capture (coming soon)
      </Paragraph> */}
      <img src={AssistiveButtonSinglePNG} alt="Assistive button single image" width="450px" style={{marginBottom:'1em'}}/>
      <Paragraph>
        c. Moving the assistive button by middle click then move the cursor.
      </Paragraph>
      <img src={AssistiveButtonMiddlePNG} alt="Assistive button middle image" width="1000px" style={{marginBottom:'1em'}}/>
    </FocusContainer>
  </>
}

export default HelpAssistiveButton
