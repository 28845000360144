// source INTERNATIONAL CHRONOSTRATIGRAPHIC CHART v2018/08

export const GEOLOGICAL_TIME_SCALE = [
  {
    era: 'Hadean',
    period: 'Headean',
    epoch: '',
    time: '~ 4600',
    fill: 'hsla(243, 100%, 73%, 1)'
  },
  {
    era: 'Eoarchean',
    period: '',
    epoch: '',
    time: '4000',
    fill: 'hsla(239, 100%, 73%, 1)'
  },
  {
    era: 'Paleoarchean',
    period: '',
    epoch: '',
    time: '3600',
    fill: 'hsla(236, 100%, 73%, 1)'
  },
  {
    era: 'Mesoarchean',
    period: '',
    epoch: '',
    time: '3200',
    fill: 'hsla(232, 100%, 73%, 1)'
  },
  {
    era: 'Neoarchean',
    period: '',
    epoch: '',
    time: '2800',
    fill: 'hsla(229, 100%, 73%, 1)'
  },
  {
    era: 'Paleopterozoic',
    period: 'Siderian',
    epoch: '',
    time: '2500',
    fill: 'hsla(225, 100%, 73%, 1)'
  },
  {
    era: 'Paleopterozoic',
    period: 'Rhyacian',
    epoch: '',
    time: '2300',
    fill: 'hsla(222, 100%, 73%, 1)'
  },
  {
    era: 'Paleopterozoic',
    period: 'Orosirian',
    epoch: '',
    time: '2050',
    fill: 'hsla(218, 100%, 73%, 1)'
  },
  {
    era: 'Paleopterozoic',
    period: 'Statherian',
    epoch: '',
    time: '1800',
    fill: 'hsla(215, 100%, 73%, 1)'
  },
  {
    era: 'Mesopterozoic',
    period: 'Calymmian',
    epoch: '',
    time: '1600',
    fill: 'hsla(211, 100%, 73%, 1)'
  },
  {
    era: 'Mesopterozoic',
    period: 'Ectasian',
    epoch: '',
    time: '1400',
    fill: 'hsla(208, 100%, 73%, 1)'
  },
  {
    era: 'Mesopterozoic',
    period: 'Stenian',
    epoch: '',
    time: '1200',
    fill: 'hsla(205, 100%, 73%, 1)'
  },
  {
    era: 'Neopterozoic',
    period: 'Tonian',
    epoch: '',
    time: '1000',
    fill: 'hsla(201, 100%, 73%, 1)'
  },
  {
    era: 'Neopterozoic',
    period: 'Cryogenian',
    epoch: '',
    time: '~ 720',
    fill: 'hsla(198, 100%, 73%, 1)'
  },
  {
    era: 'Neopterozoic',
    period: 'Ediacaran',
    epoch: '',
    time: '~ 635',
    fill: 'hsla(194, 100%, 73%, 1)'
  },
  {
    era: 'Paleozoic',
    period: 'Cambrian',
    epoch: 'Terreneuvian',
    time: '541 +- 1',
    fill: 'hsla(191, 100%, 73%, 1)'
  },
  {
    era: 'Paleozoic',
    period: 'Cambrian',
    epoch: 'Series 2',
    time: '~ 521',
    fill: 'hsla(187, 100%, 73%, 1)'
  },
  {
    era: 'Paleozoic',
    period: 'Cambrian',
    epoch: 'Miaolingian',
    time: '~ 509',
    fill: 'hsla(184, 100%, 73%, 1)'
  },
  {
    era: 'Paleozoic',
    period: 'Cambrian',
    epoch: 'Furongian',
    time: '~ 497',
    fill: 'hsla(180, 100%, 73%, 1)'
  },
  {
    era: 'Paleozoic',
    period: 'Ordovician',
    epoch: 'Lower',
    epochAlias: 'Early',
    time: '485.4 +- 1.9',
    fill: 'hsla(177, 100%, 73%, 1)'
  },
  {
    era: 'Paleozoic',
    period: 'Ordovician',
    epoch: 'Middle',
    epochAlias: 'Middle',
    time: '470 +- 1.4',
    fill: 'hsla(173, 100%, 73%, 1)'
  },
  {
    era: 'Paleozoic',
    period: 'Ordovician',
    epoch: 'Upper',
    epochAlias: 'Late',
    time: '458.4 +- 0.9',
    fill: 'hsla(170, 100%, 73%, 1)'
  },
  {
    era: 'Paleozoic',
    period: 'Silurian',
    epoch: 'Llandovery',
    time: '443.8 +- 1.5',
    fill: 'hsla(167, 100%, 73%, 1)'
  },
  {
    era: 'Paleozoic',
    period: 'Silurian',
    epoch: 'Wenlock',
    time: '433.4 +- 0.8',
    fill: 'hsla(163, 100%, 73%, 1)'
  },
  {
    era: 'Paleozoic',
    period: 'Silurian',
    epoch: 'Ludlow',
    time: '427.4 +- 1',
    fill: 'hsla(160, 100%, 73%, 1)'
  },
  {
    era: 'Paleozoic',
    period: 'Silurian',
    epoch: 'Pridoli',
    time: '423.0 +- 2.3',
    fill: 'hsla(156, 100%, 73%, 1)'
  },
  {
    era: 'Paleozoic',
    period: 'Devonian',
    epoch: 'Lower',
    epochAlias: 'Early',
    time: '419.2 +- 3.2',
    fill: 'hsla(153, 100%, 73%, 1)'
  },
  {
    era: 'Paleozoic',
    period: 'Devonian',
    epoch: 'Middle',
    epochAlias: 'Middle',
    time: '393.3 +- 1.2',
    fill: 'hsla(149, 100%, 73%, 1)'
  },
  {
    era: 'Paleozoic',
    period: 'Devonian',
    epoch: 'Upper',
    epochAlias: 'Late',
    time: '382.7 +- 1.6',
    fill: 'hsla(146, 100%, 73%, 1)'
  },
  {
    era: 'Paleozoic',
    period: 'Carboniferous',
    epoch: 'Mississippian Lower',
    time: '358.9 +- 0.4',
    fill: 'hsla(142, 100%, 73%, 1)'
  },
  {
    era: 'Paleozoic',
    period: 'Carboniferous',
    epoch: 'Mississippian Middle',
    time: '346.7 +- 0.4',
    fill: 'hsla(139, 100%, 73%, 1)'
  },
  {
    era: 'Paleozoic',
    period: 'Carboniferous',
    epoch: 'Mississippian Upper',
    time: '330.9 +- 0.2',
    fill: 'hsla(135, 100%, 73%, 1)'
  },
  {
    era: 'Paleozoic',
    period: 'Carboniferous',
    epoch: 'Pennsylvanian Lower',
    time: '323.2 +- 0.4',
    fill: 'hsla(132, 100%, 73%, 1)'
  },
  {
    era: 'Paleozoic',
    period: 'Carboniferous',
    epoch: 'Pennsylvanian Middle',
    time: '315.2 +- 0.2',
    fill: 'hsla(129, 100%, 73%, 1)'
  },
  {
    era: 'Paleozoic',
    period: 'Carboniferous',
    epoch: 'Pennsylvanian Upper',
    time: '307.0 +- 0.1',
    fill: 'hsla(125, 100%, 73%, 1)'
  },
  {
    era: 'Paleozoic',
    period: 'Permian',
    epoch: 'Cisuralian',
    time: '298.9 +- 0.15',
    fill: 'hsla(122, 100%, 73%, 1)'
  },
  {
    era: 'Paleozoic',
    period: 'Permian',
    epoch: 'Guadalupian',
    time: '272.95 +- 0.11',
    fill: 'hsla(118, 100%, 73%, 1)'
  },
  {
    era: 'Paleozoic',
    period: 'Permian',
    epoch: 'Lopingian',
    time: '259.1 +- 1',
    fill: 'hsla(115, 100%, 73%, 1)'
  },
  {
    era: 'Mesozoic',
    period: 'Triassic',
    epoch: 'Lower',
    epochAlias: 'Early',
    time: '251.902 +- 0.024',
    fill: 'hsla(111, 100%, 73%, 1)'
  },
  {
    era: 'Mesozoic',
    period: 'Triassic',
    epoch: 'Medium',
    time: '247.2',
    fill: 'hsla(108, 100%, 73%, 1)'
  },
  {
    era: 'Mesozoic',
    period: 'Triassic',
    epoch: 'Upper',
    epochAlias: 'Late',
    time: '~ 237',
    fill: 'hsla(104, 100%, 73%, 1)'
  },
  {
    era: 'Mesozoic',
    period: 'Jurassic',
    epoch: 'Lower',
    epochAlias: 'Early',
    time: '201.3 +- 0.2',
    fill: 'hsla(101, 100%, 73%, 1)'
  },
  {
    era: 'Mesozoic',
    period: 'Jurassic',
    epoch: 'Medium',
    time: '174.1 +- 1',
    fill: 'hsla(97, 100%, 73%, 1)'
  },
  {
    era: 'Mesozoic',
    period: 'Jurassic',
    epoch: 'Upper',
    epochAlias: 'Late',
    time: '163.5 +- 1',
    fill: 'hsla(94, 100%, 73%, 1)'
  },
  {
    era: 'Mesozoic',
    period: 'Cretaceous',
    epoch: 'Lower',
    epochAlias: 'Early',
    time: '~ 145',
    fill: 'hsla(91, 100%, 73%, 1)'
  },
  {
    era: 'Mesozoic',
    period: 'Cretaceous',
    epoch: 'Upper',
    epochAlias: 'Late',
    time: '101',
    fill: 'hsla(87, 100%, 73%, 1)'
  },
  {
    era: 'Cenozoic',
    period: 'Paleogene',
    epoch: 'Paleocene',
    time: '66',
    fill: 'hsla(84, 100%, 73%, 1)'
  },
  {
    era: 'Cenozoic',
    period: 'Paleogene',
    epoch: 'Paleosen',
    time: '66',
    fill: 'hsla(84, 100%, 73%, 1)'
  },
  {
    era: 'Cenozoic',
    period: 'Paleogene',
    epoch: 'Eocene',
    time: '56',
    fill: 'hsla(80, 100%, 73%, 1)'
  },
  {
    era: 'Cenozoic',
    period: 'Paleogene',
    epoch: 'Eosen',
    time: '56',
    fill: 'hsla(80, 100%, 73%, 1)'
  },
  {
    era: 'Cenozoic',
    period: 'Paleogene',
    epoch: 'Oligocene',
    time: '33.9',
    fill: 'hsla(77, 100%, 73%, 1)'
  },
  {
    era: 'Cenozoic',
    period: 'Paleogene',
    epoch: 'Oligosen',
    time: '33.9',
    fill: 'hsla(77, 100%, 73%, 1)'
  },
  {
    era: 'Cenozoic',
    period: 'Neogene',
    epoch: 'Miocene',
    time: '23.03',
    fill: 'hsla(73, 100%, 73%, 1)'
  },
  {
    era: 'Cenozoic',
    period: 'Neogene',
    epoch: 'Miosen',
    time: '23.03',
    fill: 'hsla(73, 100%, 73%, 1)'
  },
  {
    era: 'Cenozoic',
    period: 'Neogene',
    epoch: 'Pliocene',
    time: '5.333',
    fill: 'hsla(70, 100%, 73%, 1)'
  },
  {
    era: 'Cenozoic',
    period: 'Neogene',
    epoch: 'Pliosen',
    time: '5.333',
    fill: 'hsla(70, 100%, 73%, 1)'
  },
  {
    era: 'Cenozoic',
    period: 'Quertenary',
    epoch: 'Pleistocene',
    time: '2.58',
    fill: 'hsla(66, 100%, 73%, 1)'
  },
  {
    era: 'Cenozoic',
    period: 'Quertenary',
    epoch: 'Pleistosen',
    time: '2.58',
    fill: 'hsla(66, 100%, 73%, 1)'
  },
  {
    era: 'Cenozoic',
    period: 'Quertenary',
    epoch: 'Holocene',
    time: '0.0117',
    fill: 'hsla(63, 100%, 73%, 1)'
  },
  {
    era: 'Cenozoic',
    period: 'Quertenary',
    epoch: 'Holosen',
    time: '0.0117',
    fill: 'hsla(63, 100%, 73%, 1)'
  }
]
