import React from 'react'

import FocusContainer, {
  Header,
  Paragraph
} from '../../StyledComponent'
import CrossPNG from '../../HelpImage/assistive-button-cross-section.png'
import { LIST_HELP } from '../../constant'


function HelpCrossSection(props) {
  return <>
    <FocusContainer {...props}>
      <Header>{LIST_HELP['assistive'].detail.cross.header}</Header>
      <Paragraph>
        Cross section tool is provided to make it easier for users to see the earth crosswise. Cross section tool will be very useful when subsurface data is available.
      </Paragraph>
      <img src={CrossPNG} alt="Cross Section image" width="1100px" />
    </FocusContainer>
  </>
}

export default HelpCrossSection
