import React, { useState, useEffect } from 'react'

import styled from 'styled-components'

const WrapperActive = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-family: Abel;
  color: ${(props) => props.toggleAble ? 'goldenrod' : 'grey'};
  font-size: 0.8em;
  width: 1rem;
  height: 1rem;
  cursor: ${(props) => props.toggleAble ? 'pointer': 'default'};
  padding-left: 0.2em;
  padding-right: 0.4em;
`

function DataTypeButton({
  title,
  toggleAble,
  icon,
  onClickHandler
}) {
  return <WrapperActive
    title={title}
    toggleAble={toggleAble}
    onClick={onClickHandler}
  >
    {icon}
  </WrapperActive>
}

export default DataTypeButton
