import React from 'react'

import styled from 'styled-components'

const Label = styled.div`
  font-family: Abel;
  font-size: 0.65em;
  margin-left: 0.4em;
`

/**
 * This components InputSize to resize the entity
 * @param {Number} fieldMode selected mode 
 * @param {Function} setFieldMode selected field mode
 * @param {Object} map the current map selected
 */
function InputSize({
  fieldMode,
  setFieldMode,
  setTemplate
}) {
  function onSetHandler(newPrecision) {
    setTemplate(null)

    setFieldMode((currentFieldMode) => {
      return {
        ...currentFieldMode,
        precision: newPrecision
      }
    })
  }

  return <>
    <Label>
      Decimal:
    </Label>
    <input
      style={{
        margin: 0,
        fontFamily: 'Abel',
        marginLeft: '0.1em',
        fontSize: '0.7em',
        padding: '0.2em 0.4em',
        height: '1em',
        width: '1em',
        border: '1px solid black',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px'
      }}
      type="text"
      value={fieldMode.precision}
      onChange={(event) => {
        onSetHandler(event.target.value)
      }}
      onWheel={(event) => {
        const DEFAULT_PRECISION = 3

        let newPrecision = DEFAULT_PRECISION

        if (!isNaN(fieldMode.precision)) {
          newPrecision = Number(fieldMode.precision)
        }

        if (event.deltaY < 0) {
          newPrecision -= 1
        } else if (event.deltaY > 0) {
          newPrecision += 1
        }

        onSetHandler(newPrecision)
      }}
    />
  </>
}

export default InputSize
