export const searchElements = (header) => {
    const regex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g
    const elmsFinal = []
    const el = [
      'au',
      'ag',
      'al',
      'as',
      'ba',
      'be',
      'bi',
      'br',
      'ca',
      'cd',
      'ce',
      'co',
      'cr',
      'cs',
      'cu',
      'eu',
      'fe',
      'ga',
      'ge',
      'hf',
      'hg',
      'in',
      'la',
      'li',
      'lu',
      'lr',
      'mg',
      'mn',
      'mo',
      'na',
      'nb',
      'ni',
      'p',
      'pb',
      'rb',
      're',
      're',
      'sb',
      'sc',
      'se',
      'sm',
      'sn',
      'sr',
      'ta',
      'tb',
      'te',
      'ti',
      'th',
      'tl',
      'yb',
      'zn',
      'zr'
    ]
    const els = ['v', 'w', 'y', 'c', 's', 'u','k','r']
    const ignore = [
      'bat', 
      'lat', 
      'proj',
      'prosp',
      'litho',
      'alteration',
      'texture',
      'asd',
      'rl'
    ]
    const withoutIgnore = header.filter((head)=>{
      const lowerHead = head.toLowerCase()
      return !ignore.some((ignoreText) => lowerHead.includes(ignoreText))
    })
    withoutIgnore.forEach(function (element) {
      if (element.length <= 5) {
        els.forEach(function (i) {
          if (
            element
              .toLowerCase()
              .replace(regex, '')
              .replace(/ |_/g, '')
              .startsWith(i)
          ) {
            if (elmsFinal.indexOf(element) < 0) {
              elmsFinal.push(element)
            }
          }
        })
        el.forEach(function (i) {
          if (
            element
              .toLowerCase()
              .replace(regex, '')
              .replace(/ |_/g, '')
              .startsWith(i)
          ) {
            if (elmsFinal.indexOf(element) < 0) {
              elmsFinal.push(element)
            }
          }
        })
      } else {
        el.forEach(function (i) {
          if (
            element
              .toLowerCase()
              .replace(regex, '')
              .replace(/ |_/g, '')
              .startsWith(i)
          ) {
            if (elmsFinal.indexOf(element) < 0) {
              elmsFinal.push(element)
            }
          }
        })
      }
    })
    return elmsFinal
  }