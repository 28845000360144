export const CHECK_LIST = [
  {
    id: 1,
    title: 'Home',
    icon: 'home'
  },
  {
    id: 2,
    title: 'Cross Section',
    icon: 'crossSection'
  },
  {
    id: 3,
    title: 'Block Section',
    icon: 'blockSection'
  }, // screenshot
  {
    id: 4,
    title: 'Slice Section',
    icon: 'sliceSection'
  }, // knife
  // {
  //   id: 5,
  //   title: 'Elevation Path',
  //   icon: 'elevationPath'
  // }, // peak
  // {
  //   id: 6,
  //   title: 'Table',
  //   icon: 'table'
  // }, // table
  // {
  //   id: 7,
  //   title: 'Screen Capture',
  //   icon: 'screenCapture'
  // }, // object-selected
  // {
  //   id: 8,
  //   title: 'Screen Recording',
  //   icon: 'screenRecording' 
  // }, // record
  {
    id: 9,
    title: 'Cursor',
    icon: 'cursor' 
  }, // cursor
  // {
  //   id: 10,
  //   title: 'Grid Options',
  //   icon: 'gridOption'
  // },
  // {
  //   id: 11,
  //   title: 'Mineral Occurrences Editor',
  //   icon: 'editor'
  // }
]
