import { 
    callScaleLinX, 
    callScaleLinY, 
    callScaleLogX, 
    callScaleLogY, 
    scaleLinear, 
    scaleLog 
} from '../helpers/callScale';
import { ScatterPlotFunction } from './helpers';

export const convertScale = ({...props}) => {
    let {
        configScatter,
        setCheckedX,
        setCheckedY,
        data,
        dataGroup,
        height,
        width,
        xAxis,
        yAxis,
        svg,
        svgCircle,
        classNameAll,
        series,
        setLoading
    } = props;

    const xLin = scaleLinear({
        data: dataGroup,
        selected: configScatter.setX,
        minrange: 0,
        maxrange: width
    });

    const callXLin = (x) => callScaleLinX({
        scaleAxis: xAxis,
        selectedCall: x,
        data: dataGroup,
        x: configScatter.setX
    });

    const yLin = scaleLinear({
        data: dataGroup,
        selected: configScatter.setY,
        minrange: height,
        maxrange: 0
    });

    const callYLin = (y) => callScaleLinY({
        scaleAxis: yAxis,
        selectedCall: y,
        data: dataGroup,
        y: configScatter.setY
    });

    const xLog = scaleLog({
        data: dataGroup,
        selected: configScatter.setX,
        minrange: 0,
        maxrange: width
    });

    const callXLog = (x) => callScaleLogX({
        scaleAxis: xAxis,
        selectedCall: x
    });

    const yLog = scaleLog({
        data: dataGroup,
        selected: configScatter.setY,
        minrange: height,
        maxrange: 0
    });

    const callYLog = (y) => callScaleLogY({
        scaleAxis: yAxis,
        selectedCall: y
    });

    const scatter = (x,y) => ScatterPlotFunction({
        svg: svg,
        svgCircle:svgCircle,
        data: data,
        x: x,
        y: y,
        selectionX: configScatter.setX,
        selectionY: configScatter.setY,
        color: configScatter.color,
        colorBorder: configScatter.colorBorder,
        classNameAll: classNameAll,
        sizeCircle: configScatter.sizeCircle,
        showRegression: configScatter.showRegression,
        series: series,
        setLoading: setLoading
    })
    
    //---------------conditional log/linear scale--------------//
    if (configScatter.scaleX==='logarithmic') {
        if (configScatter.scaleY==='logarithmic'){
            setCheckedX(true)
            setCheckedY(true)
            let x= xLog
            let y= yLog
            callXLog(x)
            callYLog(y)
            scatter(x,y)
        } else {
            setCheckedX(true)
            let x= xLog
            let y= yLin
            callXLog(x)
            callYLin(y)
            scatter(x,y)
        }
    } else if (configScatter.scaleY==='logarithmic') {
        if (configScatter.scaleX==='logarithmic'){
            setCheckedX(true)
            setCheckedY(true)
            let x= xLog
            let y= yLog
            callXLog(x)
            callYLog(y)
            scatter(x,y)
        } else {
            setCheckedY(true)
            let x= xLin
            let y= yLog
            callXLin(x)
            callYLog(y)
            scatter(x,y)
        }
    } else {
        let x= xLin
        let y= yLin
        callXLin(x)
        callYLin(y)
        scatter(x,y)
    }
}
