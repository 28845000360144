import React, { useState, useEffect } from 'react'

import { generateGroupEntitiesForNumericalCustom } from '../../../../../../../../packages/cesium-add-on/cesiumLayer/helpers/groupEntities'

import { getBottomAndTopFromGroupEntities } from './helpers'

import {
  AlignedFlexRow
} from '../../../../../../../BasicStyle/FlexWrapper'


import styled from 'styled-components'

const Input = styled.input`
  width: 4em;
  height: 0.8rem;
  font-family: Abel;
`

const Span = styled.span`
  color: white;
  font-family: Abel;
  margin: 0 0.4em;
`

/**
 * This component will change ragne of group by value given in input
 * @param {Object} map is the selected map
 * @param {String} name is the name of label
 * @param {Object} groupEntities is current group entities to create this input
 * @param {Function} setGroupEntities is the method to change group entities
 * @param {String} groupBy is current groupby selected
 * @param {Object} fieldMode is the current fieldmode selected, need to get precision
 * @param {Function} setEditOption is method to change onEdit
 * @param {Function} setTemplate is method to change active template
 * @returns 
 */
function EditText({
  map,
  name,
  groupEntities,
  setGroupEntities,
  groupBy,
  fieldMode,
  setEditOption,
  setTemplate
}) {
  const [ bottom, setBottom ] = useState('')
  const [ top, setTop ] = useState('')
  const [ prevTop, setPrevTop ] = useState('')
  const [ prevBottom, setPrevBottom ] = useState('')

  useEffect(() => {
    const range = getBottomAndTopFromGroupEntities({
      name,
      groupEntities
    })

    if (range) {
      const [ newBottom, newTop ] = range
        
      setBottom(newBottom)
      setTop(newTop)

      setPrevBottom(newBottom)
      setPrevTop(newTop)
    }
  }, [name, groupEntities])

  function updateGroupEntities() {
    if (Number(bottom) > Number(top)) {
      return
    }

    if (
      prevTop && prevBottom
    ) {
      if (
        prevTop !== top
        || prevBottom !== bottom
      ) {
        const keys = groupEntities.keys
        let index = -1

        for (let i = 0; i < keys.length; i++) {
          if (keys[i].label === name) {
            index = i

            keys[i].value = `${bottom} - ${top}`
            break
          }
        }

        if (index === -1) {
          return
        }

        if (index > 0) {
          let currentBottom = bottom

          for (let j = index - 1; j >= 0; j--) {
            const keyBottom = keys[j].value.split(' - ')[0]

            if (Number(keyBottom) < Number(currentBottom)) {
              keys[j].value = `${keyBottom} - ${currentBottom}`

              break
            } else {
              let newCurrentBottom = currentBottom - 1 / Math.pow(10, fieldMode.precision)

              keys[j].value = `${newCurrentBottom} - ${currentBottom}`

              currentBottom = newCurrentBottom
            }
          }
        }

        if (index < keys.length - 1) {
          let currentTop = top

          for (let j = index + 1; j < keys.length; j++) {
            const keyTop = keys[j].value.split(' - ')[1]

            if (Number(keyTop) > Number(currentTop)) {
              keys[j].value = `${currentTop} - ${keyTop}`

              break
            } else {
              let newCurrentTop = Number(currentTop) + 1 / Math.pow(10, fieldMode.precision)

              keys[j].value = `${currentTop} - ${newCurrentTop}`

              currentTop = newCurrentTop
            }
          }
        }

        const prevGroupEntities = {
          ...groupEntities,
          keys
        }

        setTemplate(null)

        let min = Number(bottom)
        let max = Number(top)

        if (min > Number(map.cesiumLayer.sliderState.min)) {
          min = Number(map.cesiumLayer.sliderState.min)
        }

        if (max < Number(map.cesiumLayer.sliderState.max)) {
          max = Number(map.cesiumLayer.sliderState.max)
        }

        setGroupEntities(
          generateGroupEntitiesForNumericalCustom({
            map,
            groupBy,
            groupEntities: {
              group: {},
              keys: [],
              style: {}
            },
            prevGroupEntities,
            precision: fieldMode.precision,
            min,
            max 
          })
        )

        setPrevBottom(bottom)
        setPrevTop(top)

        setEditOption((editOption) => {
          return {
            ...editOption,
            onEdit: false
          }
        })
      }
    }
  }

  return <AlignedFlexRow width="22em">
    <Input
      value={bottom}
      onChange={(event) => {
        let value = event.target.value.split(',').join('.')

        if (!isNaN(value) || value === '-') {
          setBottom(value)
        }
      }}
      onKeyPress={(event) => {
        if (event.nativeEvent.key === 'Enter') {
          updateGroupEntities()
        }
      }}
    />
    <Span>-</Span>
    <Input
      value={top}
      onChange={(event) => {
        let value = event.target.value.split(',').join('.')

        if (!isNaN(value) || value === '-') {
          setTop(value)
        }
      }}
      onKeyPress={(event) => {
        if (event.nativeEvent.key === 'Enter') {
          updateGroupEntities()
        }
      }}
    />
  </AlignedFlexRow>
}

export default EditText
