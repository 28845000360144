export const COMPOSITE_NAMES = [
  'location',
  'geology',
  'tenements',
  'port',
  'road',
  'gas_station',
  'settlements',
  'power_generator'
]

export const COMPOSITE_REST_API = {
  location: 'administration',
  geology: 'geology',
  tenements: 'tenements',
  port: 'accessibility/port',
  road: 'accessibility/roads',
  gas_station: 'accessibility/gas_station',
  settlements: 'accessibility/settlements',
  power_generator: 'accessibility/power_generator'
}
