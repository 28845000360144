import React, { useEffect, useState } from 'react'

import { LIST_HELP } from './constant'

import {
  ReactComponent as RightIcon
} from '../../images/Icons/right.svg'

import {
  ReactComponent as DownIcon
} from '../../images/Icons/down.svg'

import styled from 'styled-components'

const HelperButton = styled.div`
  font-family: Abel;
  color: white;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
`

const Paragraph = styled.p`
  margin: 0.15em 0;
`

/**
 * List detail help from main list
 * @param {object} props Component props
 * @param {int} props.key index of the help list
 * @param {string} props.helpKey is 
 * @param {string} props.detail is 
 * @param {string} props.opened is the parent of the content to be opened
 * @param {function} props.setOpened is set state of opened
 * @param {string} props.focus is the content that will be automatically scrolled
 * @param {function} props.setFocus is set state of focus
 * @returns list in navigation side bar
 */

function HelpList({ helpKey, opened, setOpened, focus, setFocus }) {
  function toggleFocus(helpKey) {
    setFocus(helpKey)
  }

  function handleOpen(helpKey) {
    const findIndex = opened.indexOf(helpKey)
    
    let cloneOpened = []
    
    if (findIndex === -1) {
      cloneOpened.push(helpKey)
    }
    
    setOpened(cloneOpened)
    setFocus('')
  }



  return <>
    <HelperButton
      onClick={() => handleOpen(helpKey)}
    >
      <Paragraph>
        {
          LIST_HELP[helpKey].title
        }
        {
          opened.includes(helpKey)
            ? <DownIcon
                fill="white"
                width="1em"
                style={{
                  position: 'absolute',
                  right: '1em'
                }}
              />
            : <RightIcon
                fill="white"
                width="1em"
                style={{
                  position: 'absolute',
                  right: '1em'
                }}
              />
        }
      </Paragraph>
    </HelperButton>
      {
        opened.indexOf(helpKey) !== -1
        && Object.keys(LIST_HELP[helpKey].detail).map((keyDetail, index) => {
          return (
            <HelperButton
              key={index}
              onClick={() => toggleFocus(keyDetail)}
            >
              <Paragraph
                key={index}
                style={{ marginLeft: '0.75em' }}
              >
                {LIST_HELP[helpKey].detail[keyDetail].title}
              </Paragraph>
            </HelperButton>
          )
          })
      }
  </>
}

export default HelpList
