import axiosReact, { getProgressDownload } from '../../../axiosReact'

export async function fetch({
  map,
  params,
  fetchProgress,
  fetchFinish
}) {
  try {
    if (params.withgeojson) {
      const response = await axiosReact.get('/geofix/accessibility/coastline', {
        params,
        onDownloadProgress(event) {
          getProgressDownload(event, fetchProgress)
        }
      })

      fetchFinish()
  
      return {
        geojson: response.data
      }
    }
  } catch (error) {
    throw error
  }
}

export function parseFetch({ data }) {
  return data
}

export function getColor(entity, {
  map
}) {
  return {
    red: 1,
    green: 1,
    blue: 0,
    alpha: map.cesiumLayer.alpha
  }
}

export async function getLegendStyle({
  map,
  params,
  fetchProgress,
  fetchFinish
}) {
  try {
    const { data } = await axiosReact.get('/geoprosp/legend_tileset', {
      params: {
        image_type: 'coastline'
      }
    })

    const result = data.map((legendInfo) => {
      return {
        label: legendInfo.value,
        color: legendInfo.legend,
        geom: 'Polygon'
      }
    })

    if (fetchFinish instanceof Function) {
      fetchFinish()
    }

    return result
  } catch (error) {
  }
}
