import React from 'react'

import { connect } from 'react-redux'

import ZeroLevel from './ZeroLevel'
import FirstLevel from './FirstLevel'
import MultipleLevel from './MultipleLevel'

/**
 * Component Catalog is the component that will loop the menu list
 * and specify by their category:
 * level 0 -> just check the map
 * level 1 -> can be toggle but doesn't have sub category
 * multiple level -> can be toggle and have sub category
 * @param catalog is redux store for the current map available in cesium
 */
function Catalog({
  catalog
}) {
  const categories = Object.keys(catalog)

  return (
    <>
      {
        categories.map((category, index) => {
          return (
            <div key={index}>
              {
                catalog[category].level === 0
                  && <ZeroLevel
                    category={category}
                    categoryValue={catalog[category]}
                  />
              }
              {
                catalog[category].level === 1
                  && <FirstLevel
                    category={category}
                    categoryValue={catalog[category]}
                  />
              }
              {
                catalog[category].level > 1
                  && <MultipleLevel
                    category={category}
                    categoryValue={catalog[category]}
                />
              }
            </div>
          )
        })
      }
    </>
  )
}

function mapStateToProps({ catalog }) {
  return {
    catalog
  }
}

export default connect(mapStateToProps, null)(Catalog)
