import React from 'react'
import { connect } from 'react-redux'

import { getChatSocket } from '../../../../../store/selectors/socket'

import SocketClient from '../../../../../apis/socket'

import {
  Image
} from '../../shares/StyledComponent'

import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${props => props.alreadyAdded ? '#e6e6e6' : 'white'};
  width: auto;
  transition: background-color 330ms;
  padding: 1em 0.5em;
  
  &:hover {
    background-color: #e6e6e6;
  }
`

const FullNameDiv = styled.span`
  display: flex;
  flex-direction: column;
  margin: 0 0.5em;
`

const Paragraph = styled.p`
  margin: 0;
  font-size: ${props => props.main ? '1em' : '0.8em'};
  font-weight: ${props => props.main ? 600 : 400};
`

function MessageIconRecipientList({
  person,
  addRecipient,
  chatRoom,
  roomSocket,
  clearRecipient,
}) {
  function onClickHandler() {
    if (!person.alreadyAdded) {
      if (chatRoom) {
        let messageMD = `Welcome ${person.first_name} ${person.last_name}!\n\n`
        SocketClient.addUser(roomSocket, {
          messageId: chatRoom.message_id,
          recipient: person,
          cb(status) {
            if(status) {
              SocketClient.submitRoomMessage(roomSocket, {
                messageId: chatRoom.message_id,
                body: messageMD,
                cb() {}
              })
              clearRecipient()
            }
          }
        })
      } else {
        addRecipient(person)
      }
    }
  }

  const fullname = `${person.first_name} ${person.last_name}`

  return <Container
    alreadyAdded={person.alreadyAdded}
    onClick={onClickHandler}
  >
    <Image
      width="2em"
      src={`https://ui-avatars.com/api/?name=${fullname}&background=fff`}
      alt={fullname}
    />
    <FullNameDiv>
      <Paragraph main>
        {fullname}
      </Paragraph>
      <Paragraph>
        {person.company_name} {person.alreadyAdded && '- Already Added to Recipient'}
      </Paragraph>
    </FullNameDiv>
  </Container>
}

const mapStateToProps = (state) => {
  return {
    roomSocket: getChatSocket(state)
  }
}

export default connect(mapStateToProps)(MessageIconRecipientList)
