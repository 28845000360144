import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'

import {
  selectSite
} from '../../../store/actions/sites'

import { FlexRow } from '../../BasicStyle/FlexWrapper'

import RankbarRankingIcon from './RankbarRankingIcon'

import styled from 'styled-components'

const RankbarButton = styled(FlexRow)`
  border: 1px solid rgba(0, 0, 0, 0.61);
  margin: 3px 5px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 330ms ease-in-out;
  z-index: 100;

  &:hover {
    border: 1px solid goldenrod;
  }
`

const CustomParagraph = styled.p`
  align-self: center;
  font-family: Dosis;
  font-weight: 600;
`

const CustomParagraphIcon = styled(CustomParagraph)`
  display: flex;
  width: 1.8rem;
  height: 1.8rem;
  margin-left: 0.7em;
  border: 1px solid rgba(0, 0, 0, 0.61);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  transition: border 330ms;

  ${RankbarButton}:hover & {
    border: 1px solid goldenrod;
  }
`

const IMAGES_URL = [
  'https://images.pexels.com/photos/3225517/pexels-photo-3225517.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=100',
  'https://images.pexels.com/photos/3464632/pexels-photo-3464632.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=100',
  'https://images.pexels.com/photos/3900437/pexels-photo-3900437.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=100',
  'https://images.pexels.com/photos/1509582/pexels-photo-1509582.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=100'
]

const CustomParagraphText = styled(CustomParagraph)`
  margin: 10px;
  font-size: 0.8rem;
  width: 8em;
`

function RankButton ({
  selectSite,
  data,
  prospectMap
}) {
  const [ hover, setHover ] = useState(false)
  const [ entity, setEntity ] = useState(null)

  function clickToFly() {
    selectSite({
      site: data
    })
  }

  useEffect(() => {
    if (prospectMap && prospectMap.dataSource) {
      const entities = prospectMap.dataSource.entities.values
      for (let i = 0; i < entities.length; i++) {
        if (entities[i].name === data.name) {
          setEntity(entities[i])
          break
        }
      }
    }
  }, [prospectMap, data])

  return <>
    <RankbarButton
      onClick={clickToFly}
      onMouseEnter={() => {
        if (entity && entity.show) {
          for (let keyId in prospectMap.colorEntities) {
            if (keyId !== entity.id) {
              prospectMap.colorEntities[keyId].alpha = 0
            }
          }
        }

        setHover(true)
      }}
      onMouseLeave={() => {
        if (entity && entity.show) {
          for (let keyId in prospectMap.colorEntities) {
            if (keyId !== entity.id) {
              prospectMap.colorEntities[keyId].alpha = 0.5
            }
          }
        }

        setHover(false)
      }}
    >
      <CustomParagraphIcon
        style={{
          position: 'relative',
          fontSize: `${0.9 - 0.15 * data.rank.toString().length}em`,
          overflow: 'hidden'
        }}
      >
        <span style={{
          position: 'absolute',
          transition: 'opacity 500ms',
          opacity: hover ? 1 : 0
        }}>
          {`# ${data.rank}`}
        </span>
        <img
          className="rank-icon"
          src={IMAGES_URL[(data.rank - 1) % IMAGES_URL.length]}
          alt=""
          style={{
            position: 'absolute',
            transition: 'opacity 500ms',
            opacity: hover ? 0 : 1
          }}
        />
      </CustomParagraphIcon>
      <CustomParagraphText>
        {data.name}
      </CustomParagraphText>
      <RankbarRankingIcon data={data} />
    </RankbarButton>
  </>
}

function mapStateToProps(state) {
  return {
    prospectMap: state.camera.prospectMap
  }
}

const mapDispatchToProps = {
  selectSite
}

export default connect(mapStateToProps, mapDispatchToProps)(RankButton)
