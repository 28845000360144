import React, { useState } from 'react'

import {
  fetchSingleGeochemicalMapData
} from '../../../../../../../../apis/server'

import {
  loadGeochemicalGeoJSON
} from '../../../../../../../../apis/cesiumMap/geochemical'

import {
  getDataSource
} from '../../../../../helpers'

import styled from 'styled-components'

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const Input = styled.input`
  margin-top: 0.4em;
  margin-left: 0.6em;
`

const Label = styled.label`
  font-family: 'Abel';
  font-size: 0.8em;
  margin-top: 0.08em;
  margin-right: 0.6em;
`

const CHECKS = {
  geochemical: ['au', 'ag', 'as', 'au_ag', 'au_ag_as', 'au_ag_sb', 'au_as_sb', 'au_cu_as', 'cu', 'cu_mo_au', 'cu_pb_zn', 'cu_zn', 'mo', 'pb', 'pb_zn', 'sb', 'zn']
}

function OptionMenuFilter({ map }) {
  const [ _, setRefresh ] = useState(new Date())
  const [ detail, setDetail ] = useState({
    fetched: map.filter,
    on: map.filter
  })

  return <Container>
    {
      CHECKS[map.category].map((element, index) => {
        return <div key={index} style={{
          width: '6em'
        }}>
          <Input
            type="checkbox"
            defaultChecked={detail.on.includes(element)}
            onChange={async () => {
              try {
                const dataSource = map.dataSource

                if (detail.on.includes(element)) {
                  const newFilter = []
  
                  for (let i = 0; i < detail.on.length; i++) {
                    if (detail.on[i] !== element) {
                      newFilter.push(detail.on[i])
                    }
                  }

                  dataSource.entities.values.forEach((entity) => {
                    if (entity.properties.subtype1.getValue('') === element) {
                      // EDIT TO SHOW IT LATER
                      entity.show = false
                    }
                  })

                  setDetail({
                    ...detail,
                    on: newFilter
                  })

                  setRefresh(new Date())
                } else {
                  const newFetch = detail.fetched.slice()

                  if (!detail.fetched.includes(element)) {
                    const geojson = await fetchSingleGeochemicalMapData({
                      geom: map.geom,
                      type: map.type, 
                      belt: map.belt,
                      subtype: element
                    })

                    loadGeochemicalGeoJSON({
                      name: map.name,
                      geojson,
                      type: map.type,
                      map,
                      dataSource 
                    })

                    newFetch.push(element)
                    map.filter.push(element)
                    
                  }

                  dataSource.entities.values.forEach((entity) => {
                    if (entity.properties.subtype1.getValue('') === element) {
                      entity.show = true
                    }
                  })

                  const newFilter = detail.on.slice()

                  newFilter.push(element)

                  setDetail({
                    ...detail,
                    on: newFilter,
                    fetched: newFetch
                  })
  
                  setRefresh(new Date())
                }
              } catch (error) {
                throw error
              }
            }}
          />
          <Label>{element}</Label>
        </div>
      })
    }
  </Container>
}

export default OptionMenuFilter
