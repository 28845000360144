import CesiumMap from './cesiumMap'

import { CESIUM_MAP_REFERENCES } from '../../apis/cesiumMap/mapConfig/cesiumMapReference'

export function parseMapData({
  category,
  subCategory,
  data,
  params,
  layerBy,
  layerType,
  templateActive,
  field,
  fields,
  tileset,
  fieldFormat,
  fieldFormatMode,
  templateName
}) {
  const listMap = []

  for (let i = 0; i < CESIUM_MAP_REFERENCES.length; i++) {
    if (
      CESIUM_MAP_REFERENCES[i].category === category
      && CESIUM_MAP_REFERENCES[i].subCategory === subCategory
    ) {
      const rawMaps = CESIUM_MAP_REFERENCES[i].parseFetch({
        data,
        params,
        layerBy
      })

      for (let j = 0; j < rawMaps.length; j++) {
        listMap.push(new CesiumMap({
          ...rawMaps[j],
          category,
          subCategory,
          layerType,
          templateActive,
          tileset,
          field,
          fields,
          fieldFormat,
          fieldFormatMode,
          selectedTemplate: templateName
        }))
      }

      break
    }
  }

  return listMap
}
