import * as actionTypes from '../../constants/store/actionTypes/catalog'

let defaultCatalogState = {}

function catalogReducer(state = defaultCatalogState, { type, payload }) {
  switch (type) {
    case actionTypes.SET_CATALOG_SUCCESS:
      defaultCatalogState = payload.state
      return payload.state
    case actionTypes.CHANGE_TYPE_LAYER_SUCCESS:
      const newLayerTypeDetail = {}
      
      newLayerTypeDetail[payload.layerType] = state[payload.category].layerTypeDetail[payload.layerType]

      for (let key in state[payload.category].layerTypeDetail) {
        if (key !== payload.layerType) {
          newLayerTypeDetail[key] = state[payload.category].layerTypeDetail[key]
        }
      }

      return {
        ...state,
        [payload.category]: {
          ...state[payload.category],
          layerType: payload.layerType,
          layerTypeDetail: newLayerTypeDetail
        }
      }
    case actionTypes.FETCH_LIST_MAP_SUCCESS:
      if (payload.level === 1) {
        return {
          ...state,
          [payload.category]: {
            ...state[payload.category],
            listMap: payload.listMap,
          }
        }
      } else if (payload.level === 2) {
        return {
          ...state,
          [payload.category]: {
            ...state[payload.category],
            subCategory: {
              ...state[payload.category].subCategory,
              [payload.subCategory]: {
                ...state[payload.category].subCategory[payload.subCategory],
                listMap: payload.listMap,
              }
            }
          }
        }
      }

      return {
        ...state
      }
    case actionTypes.FETCH_MAP_SUCCESS:
      if (payload.level === 0) {
        return {
          ...state
        }
      } else if (payload.level === 1) {
        return {
          ...state,
          [payload.category]: {
            ...state[payload.category],
            listMap: payload.listMap
          }
        }
      } else if (payload.level === 2) {
        return {
          ...state,
          [payload.category]: {
            ...state[payload.category],
            layerTypeDetail: {
              ...state[payload.category].layerTypeDetail,
              [payload.layerType]: {
                ...state[payload.category].layerTypeDetail[payload.layerType],
                [payload.subCategory]: {
                  ...state[payload.category].layerTypeDetail[payload.layerType][payload.subCategory],
                  listMap: payload.listMap,
                  onProgress: payload.onProgress || false
                }
              }
            }
          }
        }
      } else if (payload.level === 3) {
        // not ready

        const cloneCategory = {
          ...state[payload.category]
        }

        for (let i = 0; i < payload.listMap.length; i++) {
          const { geom, type, subType } = payload.listMap[i]
          
          for (let key in cloneCategory.subCategory) {
            if (key === geom.toLowerCase() || key + 's' === geom.toLowerCase()) {
              const subCategory = cloneCategory.subCategory[key].subCategory

              for (let key in subCategory) {
                if (key === type || key + 's' === type) {
                  if (!subType) {
                    subCategory[key].listMap.push(payload.listMap[i])
                  } else {
                    const innerCategory = subCategory[key].subCategory

                    for (let key in innerCategory) {
                      if (key === subType) {
                        innerCategory[key].listMap.push(payload.listMap[i])
                      }

                      break
                    }
                  }

                  break
                }
              }

              break
            }
          }
        }

        return {
          ...state,
          [payload.category]: cloneCategory
        }
      } else {
        return {
          ...state
        }
      }
    case actionTypes.ADD_MAP_SUCCESS:
      return {
        ...state
      }
    case actionTypes.CHECK_LAYER_CATEGORY_SUCCESS:
      return {
        ...state,
        [payload.category]: {
          ...state[payload.category],
          toggle: true
        }
      }
    case actionTypes.UNCHECK_LAYER_CATEGORY_SUCCESS:
      return {
        ...state,
        [payload.category]: {
          ...state[payload.category],
          toggle: false
        }
      }
    case actionTypes.CHECK_SUB_LAYER_CATEGORY_SUCCESS:
      if (payload.level >= 2) {
        const cloneCategory = {
          ...state[payload.category]
        }

        let detailCategory = cloneCategory.layerTypeDetail[
          cloneCategory.layerType
        ]

        for (let i = 0; i < payload.subCategory.length; i++) {
          const subCategory = payload.subCategory[i]

          if (detailCategory.subCategory) {
            detailCategory = detailCategory.subCategory[subCategory]
          } else {
            detailCategory = detailCategory[subCategory]
          }

          if (i === payload.subCategory.length - 1) {
            detailCategory.toggle = true
          }
        }

        return {
          ...state,
          [payload.category]: cloneCategory
        }
      }

      return state
    case actionTypes.UNCHECK_SUB_LAYER_CATEGORY_SUCCESS:
      if (payload.level >= 2) {
        const cloneCategory = {
          ...state[payload.category]
        }

        let detailCategory = cloneCategory.layerTypeDetail[
          cloneCategory.layerType
        ]

        for (let i = 0; i < payload.subCategory.length; i++) {
          const subCategory = payload.subCategory[i]

          if (detailCategory.subCategory) {
            detailCategory = detailCategory.subCategory[subCategory]
          } else {
            detailCategory = detailCategory[subCategory]
          }

          if (i === payload.subCategory.length - 1) {
            detailCategory.toggle = false
          }
        }

        return {
          ...state,
          [payload.category]: cloneCategory
        }
      }

      return state
    case actionTypes.TOGGLE_ENTITY_SUCCESS:
      return {
        ...state
      }
    case actionTypes.REMOVE_MAP_SUCCESS:
      return {
        ...state
      }
    case actionTypes.REMOVE_PROJECT_MAP_SUCCESS:
      return {
        ...state
      }
    case actionTypes.REMOVE_ALL_MAP_SUCCESS:
      return {
        ...defaultCatalogState
      }
    case actionTypes.SET_CATALOG_ERROR:
    case actionTypes.CHANGE_TYPE_LAYER_ERROR:
    case actionTypes.FETCH_LIST_MAP_ERROR:
    case actionTypes.FETCH_LIST_MAP_ERROR:
    case actionTypes.FETCH_MAP_ERROR:
    case actionTypes.ADD_MAP_ERROR:
    case actionTypes.CHECK_LAYER_CATEGORY_ERROR:
    case actionTypes.UNCHECK_LAYER_CATEGORY_ERROR:
    case actionTypes.CHECK_SUB_LAYER_CATEGORY_ERROR:
    case actionTypes.UNCHECK_SUB_LAYER_CATEGORY_ERROR:
    case actionTypes.TOGGLE_ENTITY_ERROR:
    case actionTypes.REMOVE_MAP_ERROR:
    case actionTypes.REMOVE_PROJECT_MAP_ERROR:
    case actionTypes.REMOVE_ALL_MAP_ERROR:
      return {
        ...state,
        error: payload
      }
    case actionTypes.RESET_CATALOG:
      defaultCatalogState = {}
      return {
        ...defaultCatalogState
      }
    default:
      return state
  }
}

export default catalogReducer
