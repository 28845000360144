import * as actionTypes from '../../constants/store/actionTypes/sidebarToggle'

export function toggleSidebarButton({ buttonName, status, animate = false }) {
  return {
    type: actionTypes.TOGGLE_SIDEBAR_BUTTON,
    buttonName,
    detail: {
      status,
      animate
    }
  }
}

export function animateOffSidebarButton({ buttonName }) {
  return {
    type: actionTypes.TOGGLE_SIDEBAR_ANIMATION_OFF,
    buttonName
  }
}

export function resetSidebarToggle(payload) {
  return {
    type: actionTypes.RESET_SIDEBAR_TOGGLE,
    payload
  }
}
