import React from 'react'

import { connect } from 'react-redux'

import {
  toggleSidebarButton
} from '../../../store/actions/sidebarToggle'

import './Sidebar.css'

import { ReactComponent as MenuIcon } from '../../../images/Icons/menu.svg'
import { ReactComponent as SearchIcon } from '../../../images/Icons/search.svg'
import { ReactComponent as LayerIcon } from '../../../images/Icons/layer.svg'
import { ReactComponent as AnalyticIcon } from '../../../images/Icons/analytic.svg'
import { ReactComponent as DatabaseIcon } from '../../../images/Icons/database.svg'
import { ReactComponent as NewsIcon } from '../../../images/Icons/news.svg'
import { useHistory, useLocation } from 'react-router-dom'

function Sidebar ({
  sidebarState,
  toggleSidebarButton
}) {
  const location = useLocation()
  const history = useHistory()

  function clickSidebarButton (buttonName) {
    toggleSidebarButton({
      buttonName: buttonName,
      status: !sidebarState[buttonName].status,
      animate: true
    })
  }

  function clickMovePage(page){
    let path ='/'+page
    if(path===location.pathname){
      return
    } else {
      history.push(path)
    }
    // window.location.href=path
  }

  return (
    <div className="sidebar">
      <MenuIcon
        title="Menu"
        fill="#999999"
        className={
          'sidebar-icon sidebar-box '
          + `${sidebarState['menu'].status ? 'sidebar-icon-active' : ''}`
        }
        alt="Menu"
        onClick={ () => {
          clickMovePage('dashboard') 
          clickSidebarButton('menu') 
        }}
      />
      <SearchIcon
        title="Search"
        fill="#999999"
        className={
          'sidebar-icon sidebar-box '
          + `${sidebarState['search'].status ? 'sidebar-icon-active' : ''}`
        }
        alt="Search"
        onClick={ () => {
          clickMovePage('dashboard') 
          clickSidebarButton('search') 
        }}
      />
      <LayerIcon
        title="Layer"
        fill="#999999"
        className={
          'sidebar-icon sidebar-box '
          + `${sidebarState['layer'].status ? 'sidebar-icon-active' : ''}`
        }
        alt="Layer"
        onClick={ () => {
          clickMovePage('dashboard')
          clickSidebarButton('layer') 
        }}
      />
      <DatabaseIcon
        title="Database"
        fill="#999999"
        className={
          'sidebar-icon sidebar-box '
          + `${sidebarState['database'].status ? 'sidebar-icon-active' : ''}`
        }
        alt="Database"
        onClick={ () => {
          clickMovePage('database')
          clickSidebarButton('database')
        }}
      />
      {/* <AnalyticIcon
        title="Analytics"
        fill="#999999"
        className={
          'sidebar-icon sidebar-box '
          + `${sidebarState['analytics'].status ? 'sidebar-icon-active' : ''}`
        }
        alt="Analytics"
        onClick={ () => {
          clickMovePage('analytics') 
          clickSidebarButton('analytics')
        }}
      /> */}
      <NewsIcon
        title="News"
        fill="#999999"
        className={
          'sidebar-icon sidebar-box '
          + `${sidebarState['news'].status ? 'sidebar-icon-active' : ''}`
        }
        alt="News"
        onClick={ () => {
          clickMovePage('dashboard') 
          clickSidebarButton('news') 
        }}
      />
    </div>
  )
}

function mapStateToProps ({ sidebarState }) {
  return {
    sidebarState
  }
}

const mapDispatchToProps = {
  toggleSidebarButton
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
