import axiosReact, { getProgressDownload } from '../../axiosReact'
import { tenements_commodity } from '../../../constants/Maps/tenements'

export async function fetch({
  params,
  fetchProgress,
  fetchFinish
}) {
  try {
    if (params.withgeojson) {
      let commodity = ''

      if (params.commodity) {
        commodity = tenements_commodity[params.commodity].join(';')
      }

      const { data } = await axiosReact.get('/geofix/tenements', {
        params: {
          ...params,
          commodity
        },
        onDownloadProgress(event) {
          getProgressDownload(event, fetchProgress)
        }
      })

      data.features.forEach((feature) => {
        if (feature.properties.administration?.length) {
          feature.properties.administration = feature.properties.administration[0]
        }

        feature.properties = formatProperty(feature.properties)
      })

      fetchFinish()

      return {
        geojson: data
      }
    }
  } catch (error) {
    throw error
  }
}

export function formatProperty(property) {
  return {
    Island: property.island,
    Province: property.administration?.province,
    Regency: property.regency,
    'Main Commodity': property.commodity,
    'IUP Decree': property.sk_iup,
    'Granted By': property.placeman,
    'WIUP Code': property.code_wiup,
    'IUP Status': property.activity,
    'Effective Date': property.date_effective,
    'Date End': property.date_end,
    'Size Area': property.sk_area,
    'CNC Status': property.cnc,
    'IUP Ownership Status': property.generation,
    'Company Name': property.bussiness_name,
    'License Type': property.permission_type,
    'Bussiness Entity': property.bussiness_entity
  }
}

export function parseFetch({ data }) {
  return data
}

export function getColor(_, {
  map
}) {
  return {
    red: Math.random(),
    green: Math.random(),
    blue: Math.random(),
    alpha: map.cesiumLayer.alpha
  }
}
