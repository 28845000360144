/**
 * This function will format to better text {"numbere+number unit"} into numberMillion unit
 * @param {*} text 
 * 
 * Example
 * 
 * INPUT: {"1.265e+07 tonne"}
 * OUTPUT: 12,65 million tonne
 */
export default function removeExponent(text) {
  try {
    if (!text) {
      return text
    }
  
    const dictionaries = [
      [12, 'trillion'],
      [9, 'billion'],
      [6, 'million'],
      [3, 'kilo']
    ]

    let cleanText = ''

    if (text.indexOf('{"') + 1) {
      cleanText = text.slice(2, -2)
    } else if (text.indexOf('{') + 1) {
      cleanText = text.slice(1, -1)
    }

    if (cleanText.indexOf(',') + 1) {
      cleanText = cleanText.split(',')[0]
    }

    if (!cleanText) {
      return text
    }
  
    const [ numericalText, unitText ] = cleanText.split(' ')
    const [ decimal, exponent ] = numericalText.split('e+')
  
    let exponentLeft = Number(exponent) || 0
    let numericDictionary = ''

    if (exponentLeft >= 3) {
      for (let i = 0; i < dictionaries.length; i++) {
        if (exponentLeft >= dictionaries[i][0]) {
          numericDictionary = dictionaries[i][1]
          exponentLeft -= dictionaries[i][0]
          break
        }
      }
    }
  
    const numerical = decimal * Math.pow(10, exponentLeft)
    const result = [numerical.toFixed(2)]
  
    if (numericDictionary) {
      result.push(numericDictionary)
    }
  
    if (unitText) {
      result.push(unitText)
    }
  
    return result.join(' ')
  } catch(error) {
    throw error
  }
}
