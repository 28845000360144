import React, { useState, useEffect } from 'react'

import FlexWrapper from '../../../../shares/FlexWrapper'
import TooltipWrapper from '../../../../shares/TooltipWrapper'
import TooltipHeader from '../../../../shares/TooltipHeader'
import TooltipIconWrapper from '../../../../shares/TooltipIconWrapper'
import TooltipContent from '../../../../shares/TooltipContent'

import DimensionalButton from './Buttons/DimensionalButton'
import HeatMapButton from './Buttons/HeatMapButton'
import EnhanceButton from './Buttons/EnhanceButton'
import SaveButton from './Buttons/SaveButton'
import InputLabel from './Buttons/InputLabel'
import TableButton from './Buttons/TableButton'
import ShowEmptyEntityButton from './Buttons/ShowEmptyEntityButton'
import ShowLegend from './Buttons/ShowLegend'

import OptionMenuFilter from './Buttons/OptionMenuFilter'
import OptionMenuFilterDate from './Buttons/OptionMenuFilterDate'
import OptionMenuTemplate from './Buttons/OptionMenuTemplate'
import OptionMenuGroupBy from './Buttons/OptionMenuGroupBy'
import OptionMenuAttribute from './Buttons/OptionMenuAttribute'
import OptionMenuMode from './Buttons/OptionMenuMode'
import OptionMenuRangeSlider from './Buttons/OptionMenuRangeSlider'
import OptionMenuSortBy from './Buttons/OptionMenuSortBy'
import OptionMenuSize from './Buttons/OptionMenuSize'

/**
 * This component will show option when three button clicked
 * @param showLabel is the status label being checked or not
 * @param setShowLabel method to change status label
 * @param {Object} map is the current map based on category
 * @param clickOutsideHandler is to show off the content
 * @param exceptRef is the ref that need to except
 * @param {Array} resizeAble make entity resizable
 * @param {Array} enhanceMap is list of map that can be enhanced
 * @param {Boolean} showNoEntity is show no entity active
 * @param {Function} setShowNoEntity is method to toggle show no entity active
  */
function OptionMenu({
  showLabel,
  setShowLabel,
  map,
  clickOutsideHandler,
  exceptRefs,
  resizeAble,
  enhanceMap,
  showNoEntity,
  setShowNoEntity
}) {
  const [ showFieldMode, setShowFieldMode ] = useState(!!map.cesiumLayer.fieldMode.selected)
  const [ showRange, setShowRange ] = useState(map.cesiumLayer.fieldAttribute.selected === 'numerical')
  const [ showSort, setShowSort ] = useState(
    map.cesiumLayer.fieldAttribute.selected
    && map.cesiumLayer.fieldAttribute.selected !== 'single'
  )

  useEffect(() => {
    function eventModeHandler() {
      setShowFieldMode(!!map.cesiumLayer.fieldMode.selected)
    }

    function eventAttributeHandler() {
      setShowRange(map.cesiumLayer.fieldAttribute.selected === 'numerical')
      setShowSort(map.cesiumLayer.fieldAttribute.selected && map.cesiumLayer.fieldAttribute.selected !== 'single')
    }

    map.cesiumLayer.subscribeEvent('fieldMode', eventModeHandler)
    map.cesiumLayer.subscribeEvent('fieldAttribute', eventAttributeHandler)

    return () => {
      map.cesiumLayer.unsubscribeEvent('fieldMode', eventModeHandler)
      map.cesiumLayer.unsubscribeEvent('fieldAttribute', eventAttributeHandler)
    }
  }, [])

  function generateTooltipKeywords() {
    const keywords = []

    if (map.filter) {
      keywords.push('filterBy')
    }

    keywords.push('template')

    keywords.push('groupBy')

    keywords.push('attribute')

    if (showFieldMode) {
      keywords.push('mode')
    }

    if (map.subCategory === 'Earthquakes (USGS)') {
      keywords.push('dateFilter')
    }
    
    if (showRange) {
      keywords.push('range')
    }
    
    if (resizeAble.includes(map.category)) {
      keywords.push('size')
    }

    if (showSort) {
      keywords.push('sortBy')
    }
    
    keywords.push('label')

    return keywords
  }

  return <TooltipWrapper
    clickOutsideHandler={clickOutsideHandler}
    exceptRefs={exceptRefs}
    top={'50vh'}
    maxHeight={'30em'}
  >
    <TooltipHeader
      tooltipName="Option Tooltip"
      mapName={map.name}
    />
    <TooltipIconWrapper>
      <FlexWrapper
        non-scroll
        margin="0em 0.3em 0.3em 0.3em"
      >
        <DimensionalButton
          map={map}
        />
      </FlexWrapper>
      {
        map.geom === 'Point' && (
          <FlexWrapper
            non-scroll
            margin="0em 0.3em 0.3em 0.3em"
          >
            <HeatMapButton map={map} />
          </FlexWrapper>
        )
      }
      {
        enhanceMap.includes(map.category) && (
          <FlexWrapper
            non-scroll
            margin="0em 0.3em 0.3em 0.3em"
          >
            <EnhanceButton
              map={map}
            />
          </FlexWrapper>
        )
      }
      <FlexWrapper
        non-scroll
        margin="0em 0.3em 0.3em 0.3em"
      >
        <SaveButton
          width="0.8em"
          onClickHandler={() => console.log('edit it later')}
        />
      </FlexWrapper>
      <FlexWrapper
        non-scroll
        margin="0em 0.3em 0.3em 0.3em"
      >
        <TableButton
          entities={
            map.dataSource
              ? map.dataSource
                .entities
                .values
              : []
          }
          width="0.8em"
        />
      </FlexWrapper>
      <FlexWrapper
        non-scroll
        margin="0em 0.3em 0.3em 0.3em"
      >
        <ShowEmptyEntityButton
          isActive={showNoEntity}
          onClickHandler={() => setShowNoEntity((toggle) => !toggle)}
        />
      </FlexWrapper>
      <FlexWrapper
        non-scroll
        margin="0em 0.3em 0.3em 0.3em"
      >
        <ShowLegend
          map={map}
        />
      </FlexWrapper>
    </TooltipIconWrapper>
    <TooltipContent
      keywords={generateTooltipKeywords()}
      template={<OptionMenuTemplate map={map} />}
      filterBy={
        map.filter &&
          <OptionMenuFilter map={map} />
      }
      groupBy={<OptionMenuGroupBy map={map} />}
      sortBy={<OptionMenuSortBy map={map} />}
      attribute={<OptionMenuAttribute map={map} />}
      mode={<OptionMenuMode map={map} />}
      dateFilter={<OptionMenuFilterDate map={map} />}
      range={<OptionMenuRangeSlider map={map} />}
      size={<OptionMenuSize map={map} />}
      label={
        <InputLabel
          map={map}
          showLabel={showLabel}
          setShowLabel={setShowLabel}
        />
      }
    />
  </TooltipWrapper>
}

export default OptionMenu
