import axios from "axios";
import React, { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import Navbar from "../../components/Menubars/Navbar";
import { BASE_URL } from "../../constants/Server/base";
import ChangePass from "./change-password.svg";
import "./resetpass.css";
import { useLocation, useHistory } from "react-router-dom";
import iziToast from "izitoast";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

function ResetPassword (props) {
  const { register, handleSubmit, errors, getValues } = useForm();
  const [password, setPassword]= useState("");
  const [confirm_password, setConfirmPassword]= useState("");
  const [codeEmail, setCodeEmail]= useState("");

  let query = useQuery()
  let history = useHistory()

  useEffect(()=>{
    const codeEmailAdd=query.get('code')
    setCodeEmail(codeEmailAdd)
  },[])

  let payload = {
    "code": codeEmail,
    "password": password
  }

  const onSubmit= e => {
      axios
      .post(`${BASE_URL}/reset_password`, payload)
      .then(function (response) {
        history.push('/login')
        iziToast.success({
          title: 'Success',
          message: 'Successfully set new password'
        })
      })
      .catch(function (errors) {
        iziToast.error({
          title: 'Failed',
          message: 'Please try again or contact us'
        })
      });
    };
    
  return (
    <div className="content-block" style={{ width: '100vw' }}>
      <Navbar className="row"/>
      <div className="img-bg-big">
        <div className="container-reset-pass">
          <form className="input-pass-form" onSubmit={handleSubmit(onSubmit)} >
            <div className="row">
            <div className="col container-logo-pass">
              <img src={ChangePass} alt="Logo-reset-password" className=" logo-reset-pass"/>
            </div>
              <div className="col container-form-reset-pass">
                <div>
                  <p className="text-center-reset">Change Password</p>
                  <p className="text-center-input">Make sure you remember your new password</p>
                </div>
                {errors.password && <p className="text-error">{errors.password.message}</p>}
                <div className="row mb-2">
                  <input
                    type="password"
                    className="form-control text-left-input"
                    id="password"
                    name="password"
                    placeholder="New password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    ref={register({
                      required: "You must specify a password",
                      minLength: {
                        value:8,
                        message:"Password must have at least 8 characters"
                      }
                    })}
                    required
                  />
                </div>
                {errors.confirm_password && <p className="text-error">{errors.confirm_password.message}</p>}
                <div className="row mb-2">
                  <input
                    type="password"
                    className="form-control text-left-input"
                    id="confirm_password"
                    name="confirm_password"
                    placeholder="Re-enter Your New Password"
                    value={confirm_password}
                    onChange={e => setConfirmPassword(e.target.value)}
                    ref={register({
                      validate: {
                        passwordEqual: value => (value === getValues().password)|| "The passwords do not match"
                      },
                      required:true
                    })}
                    required
                  />
                </div>
                <button
                    type="submit"
                    className="button-reset-pass"
                    block="true"
                > <span>Set new password</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword