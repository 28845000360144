import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'

import MessageIconRecipient from './MessageIconRecipient'
import PersonSuggestion from './PersonSuggestion'
import DeleteConfirmation from './DeleteConfirmation'

import {
  EditableTextIcon,
  MessageValue
} from '../shares/StyledComponent'

import styled from 'styled-components'

import {
  updateRoomRecipients
} from '../../../../../store/actions/socket'

import {
  ReactComponent as CloseIcon
} from '../../../../../images/Icons/close.svg'

import {
  MESSAGE_CONTENT_WIDTH
} from '../../style'

import {
  DANGER_RED
} from '../../../../shares/ColorTemplate'

const AddPersonTextContainer = styled.div`
  display: flex;
  position: relative;
  width: ${MESSAGE_CONTENT_WIDTH};
  flex-wrap: wrap;
  max-height: 6em;
  overflow-y: auto;
`

const ContainerFlexRelative = styled.div`
  display: flex;
  position: relative;
`

const RedIconSVG = styled(CloseIcon)`
  fill: ${DANGER_RED.default};
  width: 0.8em;
  margin-left: 0.3em;
  margin-right: 1em;

  transition: fill 330ms;

  &:hover {
    fill: ${DANGER_RED.hover};
  }
`

const ShowAllButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: blue;
  cursor: pointer;
  transition: color 330ms;
  margin: 0.5em;

  &:hover {
    color: black;
  }
`

let timeout = null
const DEFAULT_LIMIT = 3
const MAX_LIMIT = 100

function RecipientContent({
  chatRoom,
  username,
  recipients,
  setRecipients,
  lastNewMessage,
}) {
  const personInputRef = useRef(null)

  const [ searchPerson, setSearchPerson ] = useState('')
  const [ limit, setLimit ] = useState(DEFAULT_LIMIT)
  const [ waitDelete, setWaitDelete ] = useState(false)
  const [ deleteConfirmation, setDeleteConfirmation ] = useState(null)

  function onChangeSearchPerson(event) {
    if (chatRoom && chatRoom.owner.username !== username) {
      return
    }

    setSearchPerson(event.target.value)

    setWaitDelete(true)

    if (timeout) {
      clearTimeout(timeout)
    }

    timeout = setTimeout(() => {
      setWaitDelete(false)
    }, 500)
  }

  function onErasePersonList(event) {
    if (chatRoom && chatRoom.owner.username !== username) {
      return
    }
    
    if (!searchPerson && event.key === 'Backspace' && !waitDelete) {
      if (chatRoom) {
        setDeleteConfirmation(recipients[recipients.length - 1])
      } else {
        setRecipients((recipients) => {
          return recipients.slice(0, -1)
        })
      }

      if (timeout) {
        clearTimeout(timeout)
      }
  
      timeout = setTimeout(() => {
        setWaitDelete(false)
      }, 500)
    }
  }

  function removeRecipientHandler(recipientIndex) {
    if (chatRoom) {
      setDeleteConfirmation(recipients[recipientIndex])
    } else {
      setRecipients((recipients) => {
        return recipients
          .filter((_, index) => recipientIndex !== index)
      })
    }
  }

  function addRecipient(person) {
    setSearchPerson('')

    setRecipients((recipients) => {
      let arr = [
        ...recipients,
        person
      ]
      arr = arr.filter((item, idx, arr) => {
        return arr.findIndex(r => r.id === item.id) === idx
      })

      // let obj = { ...recipients, [person.id]: person }
      return arr
    })
  }

  function showAllHandler() {
    setLimit(MAX_LIMIT)
  }

  function setLimitHandler() {
    setLimit(DEFAULT_LIMIT)
  }

  function focusClickHandler() {
    if (personInputRef.current) {
      personInputRef.current.focus()
    }
  }

  function getTextWidth(text) {
    if (personInputRef.current) {
      const font = '16px Abel'

      const canvas = document.createElement("canvas")
      const context = canvas.getContext("2d")
      context.font = font
      const width = context.measureText(text).width
      const formattedWidth = Math.ceil(width) + 15 + "px"

      return formattedWidth
    }

    return '5em'
  }

  function clearRecipient() {
    setSearchPerson('')
  }

  useEffect(() => {
    if (chatRoom) {
      let obj = chatRoom.recipients.reduce((prev, cur) => {
        prev[cur.id] = cur
        return prev
      }, {})
      setRecipients(chatRoom.recipients)
      // setRecipients(obj)
    }
  }, [chatRoom])

  useEffect(() => {
    if (recipients && recipients.length <= DEFAULT_LIMIT) {
      setLimit(DEFAULT_LIMIT)
    }
  }, [recipients])

  return <MessageValue>
    <AddPersonTextContainer
      onClick={focusClickHandler}
    >
      {
        Object.values(recipients).map((recipient, index) => {
          if (index >= limit) {
            return null
          }

          return <MessageIconRecipient
            key={recipient.user_id || recipient.id}
            person={recipient}
          >
            {
              !chatRoom || chatRoom.owner.username === username
                ? <RedIconSVG
                    onClick={() => {
                      removeRecipientHandler(index)
                    }}
                  />
                : null
            }
            
          </MessageIconRecipient>
        })
      }
      <ContainerFlexRelative style={{
        width: getTextWidth(searchPerson)
      }}>
        <EditableTextIcon
          ref={personInputRef}
          value={searchPerson}
          onChange={onChangeSearchPerson}
          onKeyDown={onErasePersonList}
          readOnly={chatRoom
            && chatRoom.owner
            && chatRoom.owner.username !== username
          }
        />
      </ContainerFlexRelative>
      <PersonSuggestion
        searchPerson={searchPerson}
        recipients={recipients}
        addRecipient={addRecipient}
        chatRoom={chatRoom}
        username={username}
        lastNewMessage={lastNewMessage}
        clearRecipient={clearRecipient}
      />
    </AddPersonTextContainer>
    {
      recipients.length - 1 >= limit
        ?
          <ShowAllButton
            onClick={showAllHandler}
          >Show all...</ShowAllButton>
        : null
    }
    {
      limit === MAX_LIMIT
        ? <ShowAllButton
            onClick={setLimitHandler}
          >
            Hide
          </ShowAllButton>
        : null
    }
    {
      deleteConfirmation
        && <DeleteConfirmation
          chatRoom={chatRoom}
          person={deleteConfirmation}
          setPerson={setDeleteConfirmation}
          // roomSocket={roomSocket}
        />
    }
  </MessageValue>
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = {
  updateRoomRecipients
}

export default connect(mapStateToProps, mapDispatchToProps)(RecipientContent)
