import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'

import { logout } from '../../../store/actions/user'

import { ReactComponent as HelpIcon } from '../../../images/Icons/help.svg'
import { ReactComponent as NotificationIcon } from '../../../images/Icons/notification.svg'

import navbarGeofixPNG from '../../../images/Logos/geofix.png'
// import navbarPetroseaPNG from '../../../images/Logos/petrosea.png'

import ChatButton from './ChatButton'
import UserMenu from './UserMenu'

import './Navbar.css'
import styled from 'styled-components'

const NumberCircle = styled.div`
  font-family: abel ;
  font-size: 0.7em ;
  font-weight: bold ;
  position: absolute;
  right: 175px;
  top: 5px;
  background: darkgoldenrod;
  border: 2px solid goldenrod;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.2em;
  margin-right: 5px;
  text-align: center;
  width: 1.2em;
  cursor:pointer;
`

/**
 * This component will create navbar based on given parameters
 * @param {Object} user redux state of user to show username
 * @param {Function} logout update logout to state
 */
function Navbar({
  user,
  logout,
  notifCount
}) {
  return <>
    <div className="navbar">
      <div className="row navbar-header-content">
        <a href="https://www.geofix-id.com/" target="_blank" rel="noreferrer" className="self-center">
          <img src={navbarGeofixPNG} className="navbar-logo" alt="geofix icon"/>
        </a>
        {/* <a href="https://www.petrosea.com/" target="_blank" rel="noreferrer" className="self-center">
          <img src={navbarPetroseaPNG} className="navbar-logo" alt="petrosea icon"/>
        </a> */}
      </div>
      <span className="row align-center m-10 grey-font">
        {
          user.isAuthenticate
          && <>
            <div className="row button-icon">
              <HelpIcon
                title="Help"
                fill="#999999"
                className="navbar-icon"
                alt="Help"
                onClick={() => {
                  window.open('/help', '_blank')
                }}
              />
            </div>
            <ChatButton />
            {
              notifCount !== String(0) &&
              <NumberCircle>
                  {notifCount}
              </NumberCircle>
            }
            <div className="row button-icon">
              <NotificationIcon fill="#999999" className="navbar-icon" alt="Notification" />
            </div>
            <UserMenu user={user} logout={logout} />
          </>
        }
      </span>
    </div>
  </>
}

function mapStateToProps({ user, socket }) {
  function handleNotifCount(obj) {
    let number = Object.values(obj)
      .reduce((prev, cur) => prev + cur, 0)
    return number > 9 ? '9+' : String(number)
  }
  return {
    user,
    notifCount: handleNotifCount(socket.newMessages),
  }
}

const mapDispatchToProps = {
  logout
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
