import * as actionTypes from '../../constants/store/actionTypes/sidebarToggle'

const initialState = {
  menu: {
    status: false,
    animate: false
  },
  search: {
    status: false,
    animate: false
  },
  news: {
    status: false,
    animate: false
  },
  layer: {
    status: false,
    animate: false
  },
  database: {
    status: false,
    animate: false
  },
  analytics: {
    status: false,
    animate: false
  }
}

function sidebarReducer (state = initialState, action) {
  if (action.type === actionTypes.RESET_SIDEBAR_TOGGLE) {
    return {
      ...initialState
    }
  } else if (action.type === actionTypes.TOGGLE_SIDEBAR_BUTTON) {
    let checkActiveButton = null

    for (let key in state) {
      if (key !== 'globe' && state[key].status === true) {
        checkActiveButton = key
      }
    }

    return {
      menu: {
        status: false,
        animate: checkActiveButton === 'menu' ? true : false
      },
      search: {
        status: false,
        animate: checkActiveButton === 'search' ? true : false
      },
      news: {
        status: false,
        animate: checkActiveButton === 'news' ? true : false
      },
      layer: {
        status: false,
        animate: checkActiveButton === 'layer' ? true : false
      },
      database: {
        status: false,
        animate: checkActiveButton === 'database' ? true : false
      },
      analytics: {
        status: false,
        animate: checkActiveButton === 'analytics' ? true : false
      },
      [action.buttonName]: action.detail
    }
  } else if (action.type === actionTypes.TOGGLE_SIDEBAR_ANIMATION_OFF) {
    return {
      ...state,
      [ action.buttonName ]: {
        status: state[action.buttonName].status,
        animate: false
      }
    }
  } else {
    return state
  }
}

export default sidebarReducer
