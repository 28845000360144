import styled from 'styled-components';

export const MainWrapper=styled.div`
    grid-area: mdmain;
`;
export const TopMenuWrapper=styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-area: mdtop;
`;
export const MenuItemWrapper=styled.div`
    width: 100%;
    background-color: #2e2e2e;
    position:relative;
    display: flex;
    flex-direction: row;
`;
export const MenuContentWrapper=styled.div`
    width: 100%;
    height:  ${props=> props.toggle ? "85px" : "0"};
    visibility: ${props=> props.toggle ? "visible" : "hidden"};
    border-top:0;
    background-color: #242424;
    position:relative;
    display: flex;
    flex-direction: row;
`;

export const MenuRightBottoms = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    visibility: hidden;

    svg {
        padding: 0.1em;
        fill: #909090;
    }
    svg:hover {
        fill: url(#dashboard-gold-gradient);
    }
`;

export const LayoutContainer = styled.div `
    float: left;
    height: 100%;
    margin: 0.1%;
    padding: 0.1%;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
    background-color: #5e5e5e;
    color: #979797;
    width: 100%;
    font-family: Abel;
    box-sizing: inherit;
    display:block;
`;

export const ToolboxItemsItem = styled.div`
    font-family: abel;
    background-color: #2e2e2e;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
    display: flex;
    flex-direction: column;
    width:fit-content;
    align-items: center;
    font-size:10px;
    cursor: pointer;
    margin:0.5em;
    padding:0.5em;
`;

export const WrapperLayout=styled.div`
    overflow: auto;
    height:100%;
    display:grid;
    grid-template-columns: ${props=>props.toggle ? '250px 1fr' : '0px 1fr'};
    grid-template-areas: 'layer chart';
`;

export const WrapperChart=styled.div`
    grid-area: chart;
    height: 100%;
    width:100%;
    display: grid;
    grid-template-rows: 10fr 86fr 4fr;
    grid-template-areas: 'header' 'group' 'minimize';
`;

export const GroupWidth=styled.div`
    width:${props=>props.width ? props.width : "2280px"} !important;
    height: 100%;
`;

export const WrapperGroupLayout=styled.div`
    width:99%;
    height:100%;
    overflow: hidden;
    grid-area:${({group})=>
        group === "header" && "header" ||
        group === "group" && "group" ||
        group === "minimize" && "minimize"
    };
    display: block;
    margin:auto;
    /* resize:vertical; */
`;

export const ContainerMap=styled.div `
    font-family: abel;
    margin:3px;
    z-index:1;
    display: block !important;
    justify-content: center;
    position:absolute;
`;


export const ButtonChart=styled.span`
    cursor: pointer;
    top: 0;
    opacity:0.5;
    right: 0;
    position:absolute;
    color:black;
    margin-right: ${({type})=>
        type === "minimize" && "2.5em" ||
        type === "close" && "0.5em" ||
        type === "settings" && "1.5em" ||
        "0.5em"
    };
    :hover{
        opacity:1;
        color: goldenrod;

    }
`;

export const LabelCheckbox= styled.label`
    font-size : 10px;
    text-align : center;
    margin-top : ${props => props.marginTop ? props.marginTop : "0.7em"};
    margin-left : ${props => props.marginLeft? props.marginLeft : "2em"};
    color : white;
    cursor : pointer;
`;

export const WrapperLayerManager=styled.div`
    display: ${props=>props.toggle ? "block" : "none"};
    grid-area: layer;
`;

export const ButtonExpandLayoutManager=styled.span`
    position: fixed;
    top: 50%;
    z-index:1;
    flex-wrap: nowrap;
    background-color:#2e2e2e50;
    cursor: pointer;
    border-radius: 0 4px 4px 0;
    box-sizing: border-box;
    height: 5em;
    padding: 2px 0 0;
    width: 1em;
    display: ${props=>props.toggle ? "none" : "block"};
    justify-content: center;
    border: 1px solid #555;
    border-left-width: 0;
    padding-top:1.8em;
    :hover{
        background-color:#2e2e2e;
        color:white;
    }
`;

export const ChartAnalysis=styled.div `
    font-family: abel;
    background-color: #2e2e2e;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
    display: flex;
    flex-direction: column;
    height:100%;
    position: relative;
`;

export const TitleChart = styled.div`
    color: white;
    font-weight: bold;
    text-align: center;
    margin: 0.1em;
    /* background-color: #222222; */
    background-color: black;
    font-size:14px ;
    cursor: move;
`

export const HeaderChart=styled.div`
    display: flex;
    flex-direction: row;
    background-color: rgb(119, 89, 13);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    cursor: move;
    padding:0.3em;
    padding-left:0.5em;
    padding-right:0.5em;
    height: 28px;
    justify-content: ${props => props.left ? 'flex-start' : 'space-between'};
    transform: ${props => 'translate(-50%,-50%)' + 'scale(' + props.min +')'};
`;

export const WrapperChartSvg=styled.div`
    color:lightgray !important;
    display: block;
`

export const WrapperManySvg=styled.div`


`

export const WrapperSpinner=styled.div`
    position:absolute; 
    top:50%; 
    left:50%; 
    margin:0; 
    transform:translate(-50%,-50%)
`

export const WrapperSpinnerBackground= styled.div`
    background-color:rgba(0, 0, 0, 0.5);
    margin-top:2em;
    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    width: 95%;
    padding:0.4em;
    color:white;
    height: ${props => props.small ? '70%' : '75%'};
`

export const ListCheckbox=styled.label`
    display: flex;
    flex-direction: row;
`

export const ContainerChart=styled.div`
    color:lightgray !important;

`
export const WrapperGroup=styled.div`
    grid-area:${({section})=>
        section === "map" && "map" ||
        section === "groupchart" && "groupchart" ||
        section === "groupscatter" && "groupscatter" ||
        section === "groupchart1" && "groupchart1" ||
        section === "groupchart2" && "groupchart2" 
    };
    /* width:100%; */
    overflow: hidden;
    height:100%;
    display:block;
    border: 1px solid lightgray;
    /* margin: 4px; */
`
export const WrapperGridGroup=styled.div`
    height: 100%;
    width:100%;
    display: ${({layout})=>
        layout === "layoutCustom" && "block" ||
        "grid"
    };
    grid-template-columns: ${({layout})=>
        layout === "layoutDefault" && "400px 1fr" ||
        layout === "layout1" && "" ||
        layout === "layout2" && "1fr 1fr"||
        layout === "layout3" && "500px 1fr"||
        layout === "layout4" && "1fr 1fr"||
        layout === "layoutCustom" && ""
    };
    grid-template-rows: ${({layout})=>
        layout === "layoutDefault" && "53fr 33fr" ||
        layout === "layout1" && "1fr 1fr" ||
        layout === "layout2" && ""||
        layout === "layout3" && "7fr 3fr"||
        layout === "layout4" && ""||
        layout === "layoutCustom" && ""
    };
    grid-template-areas: ${({layout})=>
        layout === "layoutDefault" && "'map groupchart' 'groupscatter groupscatter'" ||
        layout === "layout1" && "'groupchart1' 'groupchart2'" ||
        layout === "layout2" && "'map groupchart'"||
        layout === "layout3" && "'map groupchart' 'groupscatter groupchart'"||
        layout === "layout4" && "'groupchart map'"||
        layout === "layoutCustom" && ""
    };
`

export const WrapperGroupOverflowX=styled.div`
    width:100%;
    height:100%;
`


//checkbox custom for correlation matrix and boxplot
export const StyleCheckboxSelect= styled.div`
    background-color:rgba(0, 0, 0, 0.9);
    font-size: 0.7em;
    font-weight: bolder;
    text-align: start;
    margin:0.5em;
    margin-top:1.2em;
    position: absolute;
    overflow: auto;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    width: 80%;
    padding:0.4em;
    color:white;
    height: 75%;
`;

export const ButtonSubmitCustom=styled.button`
    font-family: Abel;
    font-size: 10px;
    font-weight: bolder;
    height: 1.7em;
    margin-top:0.6em;
    width:5em;
    cursor: pointer;
    border-radius: 4px;

    color: ${({type})=>
        type === "submit" && "rgb(0, 71, 0)" ||
        type === "reset" && "rgb(109, 0, 0)" ||
        "rgb(0, 0, 0)"
    };;

    border: ${({type})=>
        type === "submit" && "1px solid rgb(50, 255, 101)" ||
        type === "reset" && "1px solid rgb(255, 0, 0)" ||
        "1px solid rgb(0, 0, 0)"
    };
    background-color: ${({type})=>
        type === "submit" && "rgba(185, 255, 214, 0.8)" ||
        type === "reset" && "rgba(255, 200, 200, 0.8)" ||
        "rgba(0, 0, 0, 0.8))"
    };
`

export const ShowCustomSelect=styled.div`
    position: absolute;
    top: 2.9em;
    left:5em;
    font-size: 11px;
    width: 8em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color:black;
`