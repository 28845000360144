import React, { useState, useRef, useEffect, useMemo } from 'react'

import { getRound } from '../../../shares/helpers/basicMath'

import styled from 'styled-components'

import {
  WHITE,
  DARK_GREY,
  DANGER_RED,
  PRIMARY_BLUE
} from '../../shares/ColorTemplate'

const Paragraph = styled.p`
  color: black;
  font-size: 0.8em;
  font-family: Abel;
  margin: 0;
`

const ScoreInput = styled.input`
  width: 4em;
  height: 1em;
  font-family: Abel;
  font-size: 0.7em;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid black;
  padding: 0.2em 0.4em;
`

const LineWrapper = styled.div`
  position: relative;
  padding: 0.1em 0em;
  margin: 0em 1em;
  width: ${props => props.lineWidth};
  height: 1em;
  z-index: 0;
`

const CircleButton = styled.div`
  left: ${props => `calc(${props.xPosition}px - 0.45em)`};
  border-radius: 50%;
  width: 0.6em;
  height: 0.6em;
  margin: auto;
  border: 0.2em solid ${WHITE.default};
  position: absolute;
  cursor: pointer;
  background-color: ${props => props.background.default};
  transition: all 330ms;

  &:hover {
    background-color: ${props => props.background.hover};
    border: 0.2em solid ${WHITE.hover};
  }
`

const Line = styled.div`
  position: absolute;
  margin: 0.31em 0em;
  height: 0.4em;
  width: ${props => {
    if (props.width !== undefined && !isNaN(props.width)) {
      return `${props.width}px`
    } else if (props.lineWidth && !isNaN(props.width)) {
      return props.lineWidth
    }

    return '4em'
  }};
  left: ${props => `${props.left}px`};
  background: ${props => props.color.default};
  border-top-left-radius: 2.5px;
  border-bottom-left-radius: 2.5px;
  border-top-right-radius: 2.5px;
  border-bottom-right-radius: 2.5px;
  transition: all 330ms;

  &:hover {
    background: ${DARK_GREY.hover};
  }

  ${CircleButton}:hover + & {
    background: ${DARK_GREY.hover};
  }
`

const DEFAULT_PRECISION = 3

/**
 * This function to create component slider for filter data by range
 * @param lineWidth is the width of the line
 * @param range is the current range between two button
 * @param sliderState is the object that contains key max and min
 * @param onChangeHandler is the function that will return the value
 * @param scrollTop is the function that will make parent doesnt scroll when this wheel event happened
 * @examples 
 * sliderState = { min: 0, max: 1 }
 */
function RangeSlider({
  lineWidth = '6em',
  range = {},
  sliderState,
  onChangeHandler = () => {},
  scrollTop = () => {},
  fieldMode = {
    mode: 'equal_interval', // equal_interval | quantile = linear / logarithmic = logarithmic
    precision: DEFAULT_PRECISION // decimal
  }
}) {
  const [ min, setMin ] = useState(range.min)
  const [ textMin, setTextMin ] = useState(range.min)
  const [ max, setMax ] = useState(range.max)
  const [ textMax, setTextMax ] = useState(range.max)

  const [ minHold, setMinHold ] = useState(false)
  const [ maxHold, setMaxHold ] = useState(false)

  const [ minButtonPosition, setMinButtonPosition ] = useState(0)
  const [ maxButtonPosition, setMaxButtonPosition ] = useState(0)

  const lineWrapperRef = useRef(null)

  useEffect(() => {
    function removeEvent() {
      setMinHold(false)
      setMaxHold(false)
    }

    window.addEventListener('mouseup', removeEvent)

    return () => {
      window.removeEventListener('mouseup', removeEvent)
    }
  }, [])

  useEffect(() => {
    function move(event) {
      if (lineWrapperRef.current && (minHold || maxHold)) {
        const { left, width } = lineWrapperRef.current.getBoundingClientRect()
        
        let position = event.clientX - left
        
        if (position < 0) {
          position = 0
        }

        if (position > width) {
          position = width
        }

        if (position > maxButtonPosition) {
          setMinHold(false)
          setMaxHold(true)
        } else if (position < minButtonPosition) {
          setMinHold(true)
          setMaxHold(false)
        }

        let newMin = null
        let newMax = null
        
        if (minHold) {
          newMin = sliderState.min + (position / width) * (sliderState.max - sliderState.min)
          
          if (newMin < sliderState.min) {
            newMin = sliderState.min
          }
          
          if (newMin > max) {
            newMin = max
          }
        } else if (maxHold) {
          newMax = sliderState.min + (position / width) * (sliderState.max - sliderState.min)
          
          if (position >= width || newMax > sliderState.max) {
            newMax = sliderState.max
          }

          if (newMax < min) {
            newMax = min
          }
        }

        onChangeHandler({
          min: Number(newMin) || Number(min),
          max: Number(newMax) || Number(max)
        })
      }
    }

    if (minHold || maxHold) {
      window.addEventListener('mousemove', move)
    }

    return () => {
      window.removeEventListener('mousemove', move)
    }
  }, [minHold, maxHold])

  useEffect(() => {
    if (
      !isNaN(sliderState.min)
      && !isNaN(sliderState.max)
    ) {
      const min = getRound(range.min, fieldMode.precision)
      const max = getRound(range.max, fieldMode.precision)

      if (!isNaN(min) && !isNaN(max)) {
        setMin(min)
        setTextMin(min)
        setMax(max)
        setTextMax(max)
      }
    }
  }, [range])

  
  useEffect(() => {
    if (lineWrapperRef.current) {
      const { width } = lineWrapperRef.current.getBoundingClientRect()
    
      const position = (range.min - sliderState.min) * width / (sliderState.max - sliderState.min)

      setMinButtonPosition(position)
    }
  }, [lineWrapperRef.current, sliderState, range])

  useEffect(() => {
    if (lineWrapperRef.current) {
      const { width } = lineWrapperRef.current.getBoundingClientRect()
    
      const position = (range.max - sliderState.min) * width / (sliderState.max - sliderState.min)

      setMaxButtonPosition(position)
    }
  }, [lineWrapperRef.current, sliderState, range])

  return <>
    {
      range.min === 99999999
      && range.max === -99999999
        ? <Paragraph>No Grade Score</Paragraph>
        : <>
          <ScoreInput
            type="text"
            value={textMin}
            onChange={(event) => {
              setTextMin(event.target.value)
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                if (isNaN(textMin)) {
                  // console.log('edit it validation')
                } else {
                  let newValue = textMin
                  if (newValue.length > 1 && newValue[0] === '0') {
                    newValue = newValue.slice(1)
                  }

                  if (newValue > sliderState.max) {
                    newValue = sliderState.max
                  }
                  if (newValue < sliderState.min) {
                    newValue = sliderState.min
                  }
                  if (newValue > max) {
                    newValue = max
                  }

                  onChangeHandler({
                    min: Number(newValue),
                    max: Number(max)
                  })
                }
              }
            }}
            onWheel={(event) => {
              scrollTop()

              if (lineWrapperRef.current) {
                const { width } = lineWrapperRef.current.getBoundingClientRect()
                
                let position = minButtonPosition

                let additional = -1

                if (event.deltaY < 0) {
                  additional = 1
                }

                position += additional * 1 / 10 * maxButtonPosition

                if (position < 0) {
                  position = 0
                }

                if (position > maxButtonPosition) {
                  position = maxButtonPosition
                }

                let newMin = sliderState.min + (position / width) * (sliderState.max - sliderState.min)

                if (newMin < sliderState.min) {
                  newMin = sliderState.min
                }

                if (newMin > max) {
                  newMin = max
                }

                if (isNaN(Number(newMin)) || isNaN(Number(max))) {
                  return
                }

                onChangeHandler({
                  min: Number(newMin),
                  max: Number(max)
                })
              }
            }}
          />
            <LineWrapper
              lineWidth={lineWidth}
              ref={lineWrapperRef}
            >
              <Line
                lineWidth={lineWidth}
                color={DARK_GREY}
              />
              <Line
                width={(maxButtonPosition - minButtonPosition) || 0}
                left={minButtonPosition}
                color={WHITE}
              />
              <CircleButton
                onMouseDown={(event) => {
                  event.preventDefault()
                  setMinHold(true)
                }}
                xPosition={minButtonPosition}
                draggable="false"
                background={PRIMARY_BLUE}
              />
              <CircleButton
                onMouseDown={(event) => {
                  event.preventDefault()
                  setMaxHold(true)
                }}
                xPosition={maxButtonPosition}
                draggable="false"
                background={DANGER_RED}
              />
            </LineWrapper>
            <ScoreInput
              type="text"
              value={textMax}
              onChange={(event) => {
                setTextMax(event.target.value)
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  if (isNaN(textMax)) {
                    // console.log('edit it validation')
                  } else {
                    let newValue = textMax
                    if (newValue.length > 1 && newValue[0] === '0') {
                      newValue = newValue.slice(1)
                    }
                    if (newValue > sliderState.max) {
                      newValue = sliderState.max
                    }
                    if (newValue < sliderState.min) {
                      newValue = sliderState.min
                    }
                    if (newValue < min) {
                      newValue = min
                    }
      
                    onChangeHandler({
                      min: Number(min),
                      max: Number(newValue)
                    })
                  }
                }
              }}
              onWheel={(event) => {
                scrollTop()

                if (lineWrapperRef.current) {
                  const { width } = lineWrapperRef.current.getBoundingClientRect()
                  
                  let position = maxButtonPosition

                  let additional = -1

                  if (event.deltaY < 0) {
                    additional = 1
                  }

                  position += additional * 1 / 10 * maxButtonPosition

                  if (position < 0) {
                    position = 0
                  }

                  if (position < sliderState.min) {
                    position = sliderState.min
                  }

                  if (position > width) {
                    position = width
                  }

                  if (position < minButtonPosition) {
                    position = minButtonPosition
                  }

                  let newMax = sliderState.min + (position / width) * (sliderState.max - sliderState.min)
                  
                  if (position >= width || newMax > sliderState.max) {
                    newMax = sliderState.max
                  }

                  if (newMax < min) {
                    newMax = min
                  }

                  if (isNaN(Number(min)) || isNaN(Number(newMax))) {
                    return
                  }

                  onChangeHandler({
                    min: Number(min),
                    max: Number(newMax) || Number(max)
                  })
                }
              }}
            />
      </>
    }
  </>
}

export default RangeSlider
