import React from 'react'

import {
  ReactComponent as PlusIcon
} from '../../../../images/Icons/plus.svg'

import {
  ReactComponent as PeakIcon
} from '../../../../images/Icons/peak.svg'

import {
  ReactComponent as ObjectSelectedIcon
} from '../../../../images/Icons/object-selected.svg'

import {
  ReactComponent as RecordIcon
} from '../../../../images/Icons/movies.svg'

import ContentIcon from './ContentIcon'
import HomeButton from './HomeButton'
import CrossSectionButton from './CrossSectionButton'
import BlockSectionButton from './BlockSectionButton'
import SliceSectionButton from './SliceSectionButton'
import TableButton from './TableButton'
import CursorButton from './CursorButton'
import GradeOptionButton from './GradeOptionButton'
import EditorButton from './EditorButton'

/**
 * This component will render button based on given content
 * @param {Object} content that represent the button given
 * @param {Number} translate to set how far this component is translated
 * @param {Number} left the left position of button
 * @param {Number} top the top position of button
 * @param {Number} r radius of the black circle
 * @param {Number} radians the radians of rotation value
 * @param {Number} widthSVG is the width given for SVG
 * @param {Boolean} toggleDisplay status that button being shown or not
 * @param {Object} section is the state for section
 * @param {Function} setSection is the function that use to change the state of section
 * @param {Object} selection use to show that cursor is selected or not
 * @param {Function} setSelection is the method to change selection state
 * @param {Object} sliceEvent - slice state
 * @param {Function} setSliceEvent - method to change slice event
 * @param {Object} crossEvent - cross state
 * @param {Function} setCrossEvent - method to change cross event
 * @param {Object} blockEvent - block state
 * @param {Function} setBlockEvent - method to change block event
 * @param {Array} listPointerEvent - list of available pointer event
 * @returns {React.Component} button component
 */
function AssistButton({
  content,
  translate,
  left,
  top,
  r,
  radians,
  widthSVG,
  toggleDisplay,
  section,
  setSection,
  selection,
  setSelection,
  sliceEvent,
  setSliceEvent,
  crossEvent,
  setCrossEvent,
  blockEvent,
  setBlockEvent,
  listPointerEvent
}) {
  if (content.icon === 'home') {
    return <HomeButton
      content={content}
      translate={translate}
      left={left}
      top={top}
      r={r}
      radians={radians}
      widthSVG={widthSVG}
      toggleDisplay={toggleDisplay}
    />
  } else if (content.icon === 'crossSection') {
    return <CrossSectionButton
      content={content}
      translate={translate}
      left={left}
      top={top}
      r={r}
      radians={radians}
      widthSVG={widthSVG}
      toggleDisplay={toggleDisplay}
      crossEvent={crossEvent}
      setCrossEvent={setCrossEvent}
      listPointerEvent={listPointerEvent}
    />
  } else if (content.icon === 'blockSection') {
    return <BlockSectionButton
      content={content}
      translate={translate}
      left={left}
      top={top}
      r={r}
      radians={radians}
      widthSVG={widthSVG}
      toggleDisplay={toggleDisplay}
      blockEvent={blockEvent}
      setBlockEvent={setBlockEvent}
      listPointerEvent={listPointerEvent}
    />
  } else if (content.icon === 'sliceSection') {
    return <SliceSectionButton
      content={content}
      translate={translate}
      left={left}
      top={top}
      r={r}
      radians={radians}
      widthSVG={widthSVG}
      toggleDisplay={toggleDisplay}
      section={section}
      setSection={setSection}
      setSelection={setSelection}
      sliceEvent={sliceEvent}
      setSliceEvent={setSliceEvent}
      listPointerEvent={listPointerEvent}
    />
  } else if (content.icon === 'table') {
    return <TableButton
      content={content}
      translate={translate}
      left={left}
      top={top}
      r={r}
      radians={radians}
      widthSVG={widthSVG}
      toggleDisplay={toggleDisplay}
    />
  } else if (content.icon === 'addMore') {
    // UI debateable

    return (
      <ContentIcon
        top={
          toggleDisplay
            ? top + 'em'
            : top - translate - r * Math.sin(radians) + 'em'
        }
        left={
          toggleDisplay
            ? left + 'em'
            : left - translate - r * Math.cos(radians) + 'em'
        }
        height={widthSVG + 'em'}
        width={widthSVG + 'em'}
      >
        <PlusIcon
          title={content.title}
        />
      </ContentIcon>
    )
  } else if (content.icon === 'elevationPath') {
    return (
      <ContentIcon
        top={
          toggleDisplay
            ? top + 'em'
            : top - translate - r * Math.sin(radians) + 'em'
        }
        left={
          toggleDisplay
            ? left + 'em'
            : left - translate - r * Math.cos(radians) + 'em'
        }
        height={widthSVG + 'em'}
        width={widthSVG + 'em'}
      >
        <PeakIcon
          title={content.title}
          width={25}
          height={25}
        />
      </ContentIcon>
    )
  } else if (content.icon === 'screenCapture') {
    return (
      <ContentIcon
        top={
          toggleDisplay
            ? top + 'em'
            : top - translate - r * Math.sin(radians) + 'em'
        }
        left={
          toggleDisplay
            ? left + 'em'
            : left - translate - r * Math.cos(radians) + 'em'
        }
        height={widthSVG + 'em'}
        width={widthSVG + 'em'}
      >
        <ObjectSelectedIcon
          title={content.title}
          width={25}
          height={25}
        />
      </ContentIcon>
    )
  } else if (content.icon === 'screenRecording') {
    return (
      <ContentIcon
        top={
          toggleDisplay
            ? top + 'em'
            : top - translate - r * Math.sin(radians) + 'em'
        }
        left={
          toggleDisplay
            ? left + 'em'
            : left - translate - r * Math.cos(radians) + 'em'
        }
        height={widthSVG + 'em'}
        width={widthSVG + 'em'}
      >
        <RecordIcon
          title={content.title}
          width={25}
          height={25}
        />
      </ContentIcon>
    )
  } else if (content.icon === 'cursor') {
    return <CursorButton
      content={content}
      translate={translate}
      left={left}
      top={top}
      r={r}
      radians={radians}
      widthSVG={widthSVG}
      toggleDisplay={toggleDisplay}
      section={section}
      selection={selection}
      setSelection={setSelection}
    />
  } else if (content.icon === 'gridOption') {
    return <GradeOptionButton
      content={content}
      translate={translate}
      left={left}
      top={top}
      r={r}
      radians={radians}
      widthSVG={widthSVG}
      toggleDisplay={toggleDisplay}
    />
  } else if (content.icon === 'editor') {
    return <EditorButton
      content={content}
      translate={translate}
      left={left}
      top={top}
      r={r}
      radians={radians}
      widthSVG={widthSVG}
      toggleDisplay={toggleDisplay}
    />
  }
}

export default AssistButton
