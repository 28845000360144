import React, { useState, useRef } from 'react'

import * as Cesium from 'cesium'

import cesiumViewer from '../../../../../../apis/cesiumViewer'

import {
  createDefaultImageryProviderViewModels,
  createDefaultTerrainProviderViewModels
} from 'cesium'
// import createDefaultTerrainProviderViewModels
//   from 'cesium/Source/Widgets/BaseLayerPicker/createDefaultTerrainProviderViewModels.js'

import TooltipWrapper from '../../../shares/TooltipWrapper'
import TooltipHeader from '../../../shares/TooltipHeader'

import {
  ReactComponent as MapLinesIcon
} from '../../../../../../images/Icons/maps-lines.svg'

import LayerbarIcon from '../../../shares/LayerbarIcon'

import styled from 'styled-components'

const ImageryContainer = styled.div`
  overflow-y: auto;
  max-height: 23.1em;
  padding: 0 0 0.5em 0;
`

const ImageryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
`

const ImageryContent = styled.div`
  position: relative;
  display: flex;
  width: 5em;
  height: 5em;
  margin: 0.5em 0.5em 0 0.5em;
  flex-direction: column;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 10px;
  overflow: hidden;
  background-color: black;
`

const ImageryImage = styled.img`
  width: 5em;
  height: 5em;
  object-fit: cover;
  transition: all 330ms;

  ${ImageryContent}:hover & {
    transform: scale(1.1);
  }
`

const ImageryHeaderText = styled.p`
  margin: 0.5em 0em 0em 0.5em;
  font-size: 1em;
  font-weight: 600;
  font-family: Abel;
`

const ImageryText = styled.p`
  position: absolute;
  width: 100%;
  font-size: 0.7em;
  font-family: Abel;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  bottom: -1em;
  padding: 0.5em 0.2em;

  ${ImageryContent}:hover & {
    background-color: rgba(0, 0, 0, 1);
  }
`

function ImageryPicker() {
  const [ toggle, setToggle ] = useState(false)

  const triggerButtonRef = useRef(null)

  function clickOutsideHandler() {
    setToggle(false)
  }

  if (
    !cesiumViewer
    || !cesiumViewer.viewer
    || !cesiumViewer.viewer.scene
  ) {
    return null
  }

  const providerViewModels = createDefaultImageryProviderViewModels()
  providerViewModels.push(
    new Cesium.ProviderViewModel({
      name: 'Esri Hill Shade',
      tooltip: 'Esri Hill Shade',
      iconUrl: 'http://downloads.esri.com/MappingCenter2007/blog/Nov09Images/SymbolizingTheHillshade_Fig1.png',
      category: 'Other',
      creationFunction: () => {
        return new Cesium.ArcGisMapServerImageryProvider({
          url: 'https://services.arcgisonline.com/arcgis/rest/services/World_Terrain_Base/MapServer'
        })
      }
    })
  )

  return <>
    <LayerbarIcon
      isActive={toggle}
      margin-left="0.4em"
      title="Pick imagery"
    >
      <MapLinesIcon
        title="Pick Imagery / Terrain"
        onClick={() => {
          setToggle(!toggle)
        }}
        ref={triggerButtonRef}
      />
    </LayerbarIcon>
      {
        toggle && (
          <TooltipWrapper
            clickOutsideHandler={clickOutsideHandler}
            exceptRefs={[triggerButtonRef]}
            bottom="2em"
            maxHeight="26em"
          >
            <TooltipHeader
              tooltipName="Imagery Tooltip"
              mapName="Cesium"
            />
            <ImageryContainer>
              <ImageryHeaderText>Imagery</ImageryHeaderText>
              <ImageryWrapper>
                {
                  providerViewModels
                    .map((model, index) => {
                      return <ImageryContent
                        key={index}
                        title={model.tooltip}
                        onClick={() => {
                          const newProvider = model.creationCommand()

                          newProvider.readyPromise
                            .then(() => {
                              if (Cesium.defined(newProvider)) {
                                const baseLayer = cesiumViewer.viewer.imageryLayers.get(0)
                                // console.log(baseLayer.mapStyle)
      
                                if (Cesium.defined(baseLayer)) {
                                  cesiumViewer.viewer.imageryLayers.remove(baseLayer)
                                }
      
                                cesiumViewer.viewer.imageryLayers.addImageryProvider(newProvider, 0)
                              }
                            })
                        }}
                      >
                        <ImageryImage src={model.iconUrl} alt={model.name} />
                        <ImageryText>{model.name}</ImageryText>
                      </ImageryContent>
                    })
                }
              </ImageryWrapper>
              <ImageryHeaderText>World Terrain</ImageryHeaderText>
              <ImageryWrapper>
                {
                  createDefaultTerrainProviderViewModels()
                    .map((model, index) => {
                      return <ImageryContent
                        key={index}
                        title={model.tooltip}
                        onClick={() => {
                          const newProvider = model.creationCommand()

                          cesiumViewer.viewer.scene.globe.terrainProvider = newProvider
                        }}
                      >
                        <ImageryImage src={model.iconUrl} alt={model.name} />
                        <ImageryText>{model.name}</ImageryText>
                      </ImageryContent>
                    })
                }
              </ImageryWrapper>
            </ImageryContainer>
          </TooltipWrapper>
        )
      }
  </>
}

export default ImageryPicker
