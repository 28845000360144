import React, { Component } from 'react'

import { connect } from 'react-redux'

import { Editor } from 'react-draft-wysiwyg'
import draftToMarkdown from 'draftjs-to-markdown'
import { convertToRaw, EditorState } from 'draft-js'

import iziToast from 'izitoast'
import ReactLoading from 'react-loading'

import {
  getChatSocket, getChatRoom
} from '../../../../../store/selectors/socket'

import SocketClient from '../../../../../apis/socket'

import {
  ReactComponent as SendIcon
} from '../../../../../images/Icons/visit.svg'

import styled from 'styled-components'

import {
  SUCCESS_GREEN
} from '../../../../shares/ColorTemplate'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './wysiwyg.css'

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1em;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  background: ${SUCCESS_GREEN.default};
  width: 5em;
  height: 1.5em;
  border-radius: 5px;
  margin-top: 0.2em;
  transition: background 330ms;
  cursor: pointer;

  &:hover {
    background: ${SUCCESS_GREEN.hover};
  }
`

const ParagraphIcon = styled.span`
  display: flex;
  align-items: center;
  margin: 0 0 0 0.5em;
  font-size: 0.8em;
  font-family: Abel;
  color: white;
`

const StyledIcon = styled.div`
  display: flex;
  padding: 0.2em;
  margin: 1em;
  justify-content: center;
  
  svg {
    width: 1em;
    transition: all 330ms;
    fill: white;
  }

  &:hover {
    svg {
      transition: all 330ms;
    }
  }
`

class MessageChat extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editorState: EditorState.createEmpty(),
      loading: false,
      listening: false
    }
  }

  onChangeHandler = (editorState) => {
    this.setState({
      editorState
    })
  }

  sendMessageHandler = () => {
    const {
      chatSocket,
      chatRoom,
      recipients,
      title,
      lastNewMessage
    } = this.props
    const {
      editorState,
      loading
    } = this.state

    if (loading) {
      return
    }

    this.setState({
      loading: true
    })

    const message = convertToRaw(editorState.getCurrentContent())
    const messageMD = draftToMarkdown(message, undefined, undefined, {
      emptyLineBeforeBlock: true
    })

    if (!chatRoom) {
      const checkAllRequirements = []

      if (!title) {
        checkAllRequirements.push('Title')
      }

      if (recipients.length === 0) {
        checkAllRequirements.push('Recipient')
      }

      if (checkAllRequirements.length) {
        iziToast.warning({
          title: 'Form Validation',
          message: `Please pick ${checkAllRequirements.join(' and ')}`
        })
      } else {
        // console.log("Create new chat room")
        SocketClient.createNewMessage(chatSocket, {
          recipients,
          message: messageMD,
          title,
          cb: (status) => {
            if (status) {
              this.setState({
                editorState: EditorState.createEmpty()
              })
            }
            this.setState({
              loading: false
            })

            lastNewMessage(new Date)
          }
        })
      }

    } else {
      // console.log("Send message to chat room")
      SocketClient.submitRoomMessage(chatSocket, {
        messageId: chatRoom.message_id,
        body: messageMD,
        cb: (status) => {
          if (status) {
            this.setState({
              editorState: EditorState.createEmpty()
            })
          }

          this.setState({
            loading: false
          })

          lastNewMessage(new Date)
        }
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let { chatRoom, chatSocket } = this.props
    let { listening } = this.state
    if(chatRoom && chatSocket && !listening) {
      // console.log({ listenTo: chatRoom?.message_id })
      SocketClient.connectRoomByMessageId(chatSocket, chatRoom.message_id)
      this.setState({
        listening: true
      })
    }
  }

  render() {
    const {
      editorState,
      loading
    } = this.state

    return <MessageContainer>
      <Editor
        stripPastedStyles
        editorState={editorState}
        toolbarClassName="custom-rte-toolbar"
        editorClassName="custom-rte-editor"
        onEditorStateChange={this.onChangeHandler}
      />
      <ButtonContainer onClick={this.sendMessageHandler}>
        {
          loading
            ? <ReactLoading color="white" type="spin" width="1em" height="1em" />
            : <StyledIcon>
              <SendIcon title="send" />
              <ParagraphIcon>Send</ParagraphIcon>
            </StyledIcon>
        }
      </ButtonContainer>
    </MessageContainer>
  }
}

function mapStateToProps(state) {
  return {
    chatSocket: getChatSocket(state),
    chatRoomRedux: getChatRoom(state)
  }
}

export default connect(mapStateToProps, null)(MessageChat)
