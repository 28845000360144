import axiosReact, { getProgressDownload } from '../../../axiosReact'

import { LAYER_TYPE_TO_GEOM } from '../constant'

export async function fetch({
  map,
  params,
  layerType,
  fetchProgress,
  fetchFinish
}) {
  try {
    if (params.withgeojson) {
      const { data } = await axiosReact.get(`/geofix/geochemistry/${LAYER_TYPE_TO_GEOM[layerType]}/bleg`, {
        params: {
          ...params,
          element: map.name
        },
        onDownloadProgress(event) {
          getProgressDownload(event, fetchProgress)
        }
      })

      fetchFinish()

      let geojson = null

      for (let i = 0; i < data.length; i++) {
        if (data[i].elements) {
          for (let j = 0; j < data[i].elements.length; j++) {
            if (data[i].elements[j].geojson) {
              if (!geojson) {
                geojson = data[i].elements[j].geojson
              } else {
                geojson.features = [
                  ...geojson.features,
                  ...data[i].elements[j].geojson.features
                ]
              }
            }
          }
        }

        if (data[i].projects) {
          for (let j = 0; j < data[i].projects.length; j++) {
            if (data[i].projects[j].prospects) {
              for (let k = 0; k < data[i].projects[j].prospects.length; k++) {
                if (data[i].projects[j].prospects[k].geojson) {
                  if (!geojson) {
                    geojson = data[i].projects[j].prospects[k].geojson
                  } else {
                    geojson.features = [
                      ...geojson.features,
                      ...data[i].projects[j].prospects[k].geojson.features
                    ]
                  }
                }
              }
            }
          }
        }
      }

      return {
        geojson
      }
    } else {
      const { data } = await axiosReact.get(`/geofix/geochemistry/${LAYER_TYPE_TO_GEOM[layerType]}/bleg`, {
        params
      })

      return data[0]
    }
  } catch (error) {
    throw error
  }
}

export function parseFetch({ data, layerBy }) {
  const result = []

  data.elements.forEach((elementInfo) => {
    result.push({
      name: elementInfo[layerBy]
    })
  })

  return result
}

export function getColor(entity, { map }) {
  return {
    red: Math.random(),
    green: Math.random(),
    blue: Math.random(),
    alpha: map.cesiumLayer.alpha
  }
}
