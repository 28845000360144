import * as actionTypes from '../../constants/store/actionTypes/gradebar'

export function toggleGradebar(payload) {
  return {
    type: actionTypes.TOGGLE_GRADEBAR,
    payload
  }
}

export function toggleMinimizeGradebar(payload) {
  return {
    type: actionTypes.TOGGLE_MINIMIZE_GRADEBAR,
    payload
  }
}

export function changeGradeWeight(payload) {
  return {
    type: actionTypes.CHANGE_GRADE_WEIGHT,
    payload
  }
}

export function setWeightScore(payload) {
  return {
    type: actionTypes.SET_WEIGHT_SCORE,
    payload
  }
}

export function resetGradebar(payload) {
  return {
    type: actionTypes.RESET_GRADEBAR,
    payload
  }
}
