import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'

import SocketClient from '../../../../apis/socket'

import {
  setChatRooms
} from '../../../../store/actions/socket'

import axios from 'axios'

import ReactLoading from 'react-loading'

import {
  getUserChat
} from '../../../../apis/server'

import {
  BlockContent,
  ContainerLoading
} from '../shares/StyledComponent'

import Header from './Header'
import Room from './Room'

function RoomListContent({
  chatRoom,
  setChatRoom,
  newChatHandler,
  chatRooms,
  setChatRooms
}) {
  const [ loading, setLoading ] = useState(false)
  const [ search, setSearch ] = useState('')
  const [ searchList, setSearchList ] = useState([])

  useEffect(() => {
    const source = axios.CancelToken.source()

    async function fetchUserChat() {
      const data = await getUserChat({ source })

      data.sort((a, b) => {
        return new Date(b.last_message.created_at)
          - new Date(a.last_message.created_at)
      })
      let dataObj = data.reduce((prev, cur) => {
        cur.messages = []
        prev[cur.message_id] = cur
        return prev
      }, {})

      setChatRooms({
        chatRooms: data,
        chatRoomsObj: dataObj
      })

      setLoading(false)
    }

    if(!chatRooms.length) {
      setLoading(true)
      fetchUserChat()
    }

    return () => {
      try {
        setLoading(true)
  
        if (source) {
          source.cancel('')
        }
      } catch (error) {
      }
    }
  }, [])

  useEffect(() => {
    const source = axios.CancelToken.source()

    async function fetchUserChat() {
      const data = await getUserChat({ source })

      data.sort((a, b) => {
        return new Date(b.last_message.created_at)
          - new Date(a.last_message.created_at)
      })

      setSearchList(data)
      setLoading(false)
    }

    if (search) {
      setLoading(true)

      fetchUserChat()
    }

    return () => {
      try {
        if (source) {
          source.cancel('')
        }
      } catch (error) {
      }
    }
  }, [search])

  return (
    <BlockContent>
      <Header
        setSearch={setSearch}
        newChatHandler={newChatHandler}
      />
      {
        loading
          ? <ContainerLoading>
            <ReactLoading color="white" type="spin" />
            <p>Fetch Chat Data...</p>
          </ContainerLoading>
          : search
            ? searchList.map((room) => {
              return <Room
                key={room.message_id}
                room={room}
                chatRoom={chatRoom}
                setChatRoom={setChatRoom}
              />
            })
            : chatRooms.map((room) => {
              return <Room
                key={room.message_id}
                room={room}
                chatRoom={chatRoom}
                setChatRoom={setChatRoom}
              />
            })
      }
    </BlockContent>
  )
}

function mapStateToProps(state) {
  const { chatRoomsObj } = state.socket
  let arr = Object.values(chatRoomsObj)
  arr = arr.sort((a, b) => new Date(b.last_message.created_at) - new Date(a.last_message.created_at))
  return {
    chatRooms: arr,
  }
}

const mapDispatchToProps = {
  setChatRooms
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomListContent)
