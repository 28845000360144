import styled from 'styled-components'

/**
 * You can add custom:
 * - width
 * - height
 * - margin
 * - background
 */
export const FlexRow = styled.div.attrs((props) => {
  return {
    style: {
      width: props.width,
      height: props.height,
      margin: props.margin,
      background: props.background
    }
  }
})`
  display: flex;
`

export const FlexColumn = styled(FlexRow)`
  flex-direction: column;
`

export const AlignedFlexRow = styled(FlexRow)`
  align-items: center;
`

export const JustifiedFlexColumn = styled(FlexColumn)`
  justify-content: center;
`

export const CenteredFlexRow = styled(AlignedFlexRow)`
  justify-content: center;
`

export const CenteredFlexColumn = styled(JustifiedFlexColumn)`
  align-items: center;
`
