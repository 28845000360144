import * as Cesium from 'cesium'

import { getDataSource } from './index'

/**
 * This method used to enhance color
 * @param entities is the current entitites
 * @param {String} groupBy is current filter
 * @param map is the currentMap
 * @param status for toggle enhance
 */
export function enhance({
  entities,
  groupBy,
  map,
  status = true
}) {
  if (!entities) {
    return
  }

  if (status) {
    const defaultGroup = {
      borehole: 'AU_FINAL',
      geochemical: 'au1_ppm',
      mineral: 'SAM'
    }

    const minValue = {
      borehole: 1,
      geochemical: 25,
      mineral: 0.8
    }
  
    if (!groupBy) {
      groupBy = defaultGroup[map.category]
    }
  
    entities.sort((a, b) => {
      const aProperties = a.properties.getValue('')
      const bProperties = b.properties.getValue('')
  
      if (aProperties[groupBy] < bProperties[groupBy]) {
        return -1
      }
  
      return 1
    })
  
    for (let i = 0; i < entities.length; i++) {
      if (entities[i].properties[groupBy].getValue('') >= minValue[map.category]) {
        map.colorEntities[entities[i].id].alpha = 0.95
      } else  {
        map.colorEntities[entities[i].id].alpha = 0.15
      }
    }
  } else {
    for (let i = 0; i < entities.length; i++) {
      map.colorEntities[entities[i].id].alpha = map.cesiumLayer.alpha
    }
  }
}
