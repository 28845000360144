import React from 'react'

import { connect } from 'react-redux'

import {
  toggleTablebar,
  setTableProperties
} from '../../../../../../../../store/actions/tablebar'

import LayerbarIcon from '../../../../../shares/LayerbarIcon'

import {
  ReactComponent as TableLineIcon
} from '../../../../../../../../images/Icons/table-line.svg'

function TableButton({
  table,
  entities,
  toggleTablebar,
  setTableProperties,
  ...rest
}) {
  /**
   * This function create save button
   * @param rest margin top of this component
   */

  function onClickHandler() {
    if (!table.status) {
      toggleTablebar()
    }

    setTableProperties({
      properties: entities.map((entity) => {
        return entity.properties.getValue('')
      })
    })
  }

  return (
    <LayerbarIcon
      isActive={table.status}
      {...rest}
    >
      <TableLineIcon
        title="Show on Table"
        onDoubleClick={() => {
          toggleTablebar()
        }}
        onClick={onClickHandler}
      />
    </LayerbarIcon>
  )
}

function mapStateToProps({ table }) {
  return {
    table
  }
}

const mapDispatchToProps = {
  toggleTablebar,
  setTableProperties
}

export default connect(mapStateToProps, mapDispatchToProps)(TableButton)