import * as actionTypes from '../../constants/store/actionTypes/socket'

export function connectChatSocket(payload) {
  return {
    type: actionTypes.CONNECT_CHAT_SOCKET,
    payload
  }
}

export function connectChatSocketSuccess(payload) {
  return {
    type: actionTypes.CONNECT_CHAT_SOCKET_SUCCESS,
    payload
  }
}

export function disconnectChatSocket(payload) {
  return {
    type: actionTypes.DISCONNECT_CHAT_SOCKET,
    payload
  }
}

export function disconnectChatSocketSuccess(payload) {
  return {
    type: actionTypes.DISCONNECT_CHAT_SOCKET_SUCCESS,
    payload
  }
}

export function addChatRoom(payload) {
  return {
    type: actionTypes.ADD_CHAT_ROOM,
    payload
  }
}

export function setChatRooms(payload) {
  return {
    type: actionTypes.SET_CHAT_ROOMS,
    payload
  }
}

export function setChatRoom(payload) {
  return {
    type: actionTypes.SET_CHAT_ROOM,
    payload
  }
}

// TODO: Action ini belum kepake
export function chatNewMessage(payload) {
  return {
    type: actionTypes.CHAT_NEW_MESSAGE,
    payload
  }
}

export function resetSocket(payload) {
  return {
    type: actionTypes.RESET_SOCKET,
    payload
  }
}

export function setChatRoomsSuccess(payload) {
  return {
    type: actionTypes.SET_CHAT_ROOMS_SUCCESS,
    payload
  }
}

export function setUserSocket(payload) {
  return {
    type: actionTypes.SET_USER_SOCKET,
    payload
  }
}

export function appendMessages(payload) {
  return {
    type: actionTypes.APPEND_MESSAGES,
    payload
  }
}

export function appendConnectedRoom(payload) {
  return {
    type: actionTypes.APPEND_TO_CONNECTED_ROOMS,
    payload
  }
}

export function updateRoomRecipients(payload) {
  return {
    type: actionTypes.UPDATE_ROOM_RECIPIENTS,
    payload
  }
}