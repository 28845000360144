import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import UserMenuToggle from './UserMenuToggle'

import { handleLogout } from '../../../../apis/cookies'

import { ReactComponent as UserIcon } from '../../../../images/Icons/user.svg'

import {
  ToggleSVGButton
} from '../../../shares/ToggleSVGIcon'

import {
  GOLD_FONT
} from '../../../shares/ColorTemplate'

/**
 * This component will render User Menu Button and User Menu List
 * @param {Object} user state in redux store
 * @param {Function} logout is method to remove user in redux store 
 * @returns 
 */
function UserMenu({ user, logout }) {
  let history = useHistory()

  const [ userToggle, setUserToggle ] = useState(false)
  const [ userHover, setUserHover ] = useState(false)
  const [ styles, setStyles ] = useState({})

  function logoutHandler() {
    handleLogout()
    logout()
    history.push('/login')
  }

  function userToggleHandler() {
    setUserToggle((toggle) => !toggle)
  }

  function getTextBackground() {
    if (userToggle) {
      if (userHover) {
        return {
          color: '#999999',
          marginLeft: '0.5em',
          fontFamily: 'Abel'
        }
      } else {
        return {
          background: GOLD_FONT,
          backgroundClip: 'text',
          marginLeft: '0.5em',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          fontFamily: 'Abel'
        }
      }
    } else {
      if (userHover) {
        return {
          background: GOLD_FONT,
          backgroundClip: 'text',
          marginLeft: '0.5em',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          fontFamily: 'Abel'
        }
      } else {
        return {
          color: '#999999',
          marginLeft: '0.5em',
          fontFamily: 'Abel'
        }
      }
    }
  }

  useEffect(() => {
    setStyles(getTextBackground())
  }, [userToggle, userHover])

  return <>
    <ToggleSVGButton
      flex
      center
      margin="0 0.5em"
      active={userToggle}
      onClick={userToggleHandler}
      onMouseEnter={() => {
        setUserHover(true)
      }}
      onMouseLeave={() => {
        setUserHover(false)
      }}
    >
      <UserIcon fill="#999999" alt="User" />
      <span
        style={styles}
      >{user.username}</span>
    </ToggleSVGButton>
    {
      <UserMenuToggle
        user={user}
        userToggle={userToggle}
        logoutHandler={logoutHandler}
      />
    }
  </>
}

export default UserMenu
