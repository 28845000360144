import * as actionTypes from '../../constants/store/actionTypes/user'

export function login(payload) {
  return {
    type: actionTypes.LOGIN,
    payload
  }
}

export function loginSuccess(payload) {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    payload
  }
}

export function loginError(payload) {
  return {
    type: actionTypes.LOGIN_ERROR,
    payload
  }
}

export function logout(payload) {
  return {
    type: actionTypes.LOGOUT,
    payload
  }
}

export function logoutSuccess(payload) {
  return {
    type: actionTypes.LOGOUT_SUCCESS,
    payload
  }
}
