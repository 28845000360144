import React from 'react'

import { connect } from 'react-redux'

import {
  toggleSidebarButton
} from '../../../store/actions/sidebarToggle'

import DataCatalog from './DataCatalog'
import Display from './Display'

import SidebarContainer from '../../shares/SidebarContainer'
import FlexWrapper from './shares/FlexWrapper'

function Layerbar ({
  sidebarState
}) {
  return <>
    <SidebarContainer
      status={sidebarState.layer.status}
    >
      <FlexWrapper container col>
        <DataCatalog />
        <Display />
      </FlexWrapper>
    </SidebarContainer>
  </>
}

function mapStateToProps ({
  sidebarState
}) {
  return {
    sidebarState
  }
}

const mapDispatchToProps = {
  toggleSidebarButton
}

export default connect(mapStateToProps, mapDispatchToProps)(Layerbar)