let isENVDevelopment = true

if (process.env.NODE_ENV === 'production') {
  isENVDevelopment = false
} else if (process.env.REACT_APP_SERVER_ENV === 'production') {
  isENVDevelopment = false
}

let baseUrl = isENVDevelopment ? 'http://localhost:5000' : 'https://server.geoprosp.com'
let urlExtension = isENVDevelopment ? '/api/v1' : '/api'

export const BASE_URL = baseUrl + urlExtension
export const SOCKET_URL = baseUrl
export const GEOJSON_URL = 'http://localhost:3005'
export const BOREHOLE_URL = 'http://localhost:3009'