import React, { useState, useRef } from 'react'

import { connect } from 'react-redux'

import {
  toggleEntity,
  removeMap
} from '../../../../../../store/actions/catalog'

import {
  setFocusMap
} from '../../../../../../store/actions/display'

import {
  setContext,
  closeContext
} from '../../../../../../store/actions/rightclick'

import {
  toggleEventWithException
} from '../../../helpers/toggleEvent'

import {
  removeHeatMap
} from '../../../../../../apis/cesiumMap/heatmap'

import FlexWrapper from '../../../shares/FlexWrapper'
import ToggleIcon from '../../../shares/ToggleIcon'
import ToolbarText from '../../../shares/ToolbarText'

import ColorSchemeButton from './ColorScheme/ColorSchemeButton'
import Eye from './Eye'
import DisplayReverse from './DisplayReverse'
import RemoveButton from './RemoveButton'
import ThreeDotsButton from './ThreeDotsButton'
import DetailMenuList from './Detail/DetailMenuList'
import OptionMenu from './OptionMenu'
import DisplayAlphaSlider from './DisplayAlphaSlider'

/**
 * This component is one line from toggle button into delete button
 * @param map is the current map based on category
 * @param dataSource is the cesium data source
 * @param marginLeft margin left of the component position
 * @param listToggle current listToggle for update data toggle
 * @param setListToggle for toggle detail of menu
 * @param toolbarToggle current tooblarToggle to show toolbar when three dots clicked
 * @param setToolbarToggle to change current toolbarToggle
 * @param toggleEntity to show or hide cesium entity / dataSource
 * @param removeMap redux actions to remove the map
 * @param focusMap is redux state display -> focusMap
 * @param {Function} swap to swap while being dragged
 * @param {Function} onDrag is event being called when start drag
 * @param {Function} onDragEnd is event being called when drag end
 * @param {Function} setFocusMap is event to set focus map
 * @param {Function} setContext is event to set context menu
 * @param {Function} closeContext is event to close context menu
 */
function Menu({
  map,
  dataSource,
  marginLeft,
  listToggle,
  setListToggle,
  toolbarToggle,
  setToolbarToggle,
  toggleEntity,
  removeMap,
  swap,
  onDrag,
  onDragEnd,
  focusMap,
  setFocusMap,
  setContext,
  closeContext
}) {
  const displayRef = useRef(null)
  const threeDotRef = useRef(null)

  const [ isDraggable, setDraggable ] = useState(true)

  const [ showLabel, setShowLabel ] = useState(false)

  // map show categories
  const listNotShowDetail = [] // 'grid', 'geochemical', 'mineral', 'borehole'

  const [ showNoEntity, setShowNoEntity ] = useState(false)
  
  // this list of map category that doesnt need single color
  const resizeAble = ['geochemical', 'mineral', 'borehole', 'earthquake']
  const enhanceMap = ['geochemical', 'mineral', 'borehole', 'earthquake']

  /**
   * This function will handle whether the toggle can be clicked or not
   */
  function toggleEnabler() {
    if (listNotShowDetail.includes(map.category)) {
      return false
    }
    return true
  }

  /**
   * This function will handle event that happen when toggle button clicked
   */
  function onClickArrowHandler() {
    if (
      listToggle[map.dataSource.name]
      && toolbarToggle[map.dataSource.name]
    ) {
      toggleEventWithException({
        list: toolbarToggle,
        setList: setToolbarToggle,
        map,
        exceptions: listNotShowDetail
      })
    }

    toggleEventWithException({
      list: listToggle,
      setList: setListToggle,
      map,
      exceptions: listNotShowDetail
    })
  }

  /**
   * This function will show or hide cesium map
   */
  function onClickEyeHandler() {
    toggleEntity({
      dataSource
    })
  }

  /**
   * This function will show or hide cesium map and the all of the entitites
   */
  function onDoubleClickEyeHandler() {
    if (toggleEnabler()) {
      for (let i = 0; i < dataSource.entities.values.length; i++) {
        if (
          dataSource.entities.values[i].show === dataSource.show
        ) {
          toggleEntity({
            entity: dataSource.entities.values[i]
          })
        }
      }
    }

    toggleEntity({
      dataSource
    })
  }

  /**
   * This function remove map
   */
  function onClickRemoveHandler() {
    if (map.cesiumLayer.heat) {
      removeHeatMap({ map })
    }

    removeMap({
      map
    })
  }

  /**
   * This function will handle event that happen when three dots clicked
   */
  function onClickOptionHandler() {
    if (!listToggle[map.dataSource.name]) {
      if (!listNotShowDetail.includes(map.category)) {
        onClickArrowHandler()
      }
    }

    toggleEventWithException({
      list: toolbarToggle,
      setList: setToolbarToggle,
      map,
      exceptions: []
    })
  }

  function onDoubleClickOptionHandler() {
    if (!listToggle[map.dataSource.name] !== toolbarToggle[map.dataSource.name]) {
      if (!listNotShowDetail.includes(map.category)) {
        onClickArrowHandler()
      }
    }

    toggleEventWithException({
      list: toolbarToggle,
      setList: setToolbarToggle,
      map,
      exceptions: []
    })
  } 

  /**
   * This function will close the option menu if clicked outside it
   */
  function onClickOutsideOptionMenuHandler() {
    toggleEventWithException({
      list: toolbarToggle,
      setList: setToolbarToggle,
      map,
      exceptions: []
    })
  }

  return <>
    <FlexWrapper
      highlights={map === focusMap}
      center
      marginLeft={marginLeft}
      no-overflow
      minHeight="1.6em"
      draggable={isDraggable}
      onContextMenu={(event) => {
        setContext({
          position: {
            pageX: event.pageX,
            pageY: event.pageY
          },
          context: [
            {
              title: 'Set focus map',
              clickEvent() {
                setFocusMap({
                  map
                })
              }
            },
            {
              title: 'Cancel',
              clickEvent: closeContext
            }
          ]
        })
      }}
      onDragStartCapture={onDrag}
      onDragEndCapture={onDragEnd}
      onDragOver={(event) => {
        event.preventDefault()
        swap()
      }}
      onDrop={(event) => event.preventDefault()}
      style={
        map === focusMap
          ? {
            border: '1px solid white',
            borderRadius: '4px'
          }
          : null
      }
    >
      <ToggleIcon
        toggle={listToggle[map.dataSource.name] && !map.raster}
        toggleAble={!map.raster}
        onClick={onClickArrowHandler}
        size="smaller"
      />
      <Eye
        isActive={map && map.dataSource && map.dataSource.show}
        onClickHandler={onClickEyeHandler}
        onDoubleClickHandler={onDoubleClickEyeHandler}
      />
      <ToolbarText
        width="9em"
        text={map.name}
        max={18}
      />
      {
        !map.raster
        && <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
          onMouseEnter={() => setDraggable(false)}
          onMouseLeave={() => setDraggable(true)}
        >
          <DisplayAlphaSlider map={map} />
          <ColorSchemeButton
            map={map}
            exceptHideRefs={[displayRef]}
          />
          <FlexWrapper ref={displayRef}>
            <DisplayReverse
              map={map}
            />
          </FlexWrapper>
        </div>
      }
      {
        map.raster && <div style={{width: '5.5em'}}></div>
      }
      <RemoveButton
        onClickHandler={onClickRemoveHandler}
      />
      {
        !map.raster && <ThreeDotsButton
          ref={threeDotRef}
          toggleAble={true}
          isActive={toolbarToggle[map.dataSource.name]}
          onClickHandler={onClickOptionHandler}
          onDoubleClickHandler={onDoubleClickOptionHandler}
        />
      }
    </FlexWrapper>
    {
      toolbarToggle[map.dataSource.name]
        && !map.raster
        && <OptionMenu
          showLabel={showLabel}
          setShowLabel={setShowLabel}
          map={map}
          clickOutsideHandler={onClickOutsideOptionMenuHandler}
          exceptRefs={[threeDotRef]}
          resizeAble={resizeAble}
          enhanceMap={enhanceMap}
          showNoEntity={showNoEntity}
          setShowNoEntity={setShowNoEntity}
        />
    }
    {
      listToggle[map.dataSource.name]
        && <DetailMenuList
          map={map}
          showNoEntity={showNoEntity}
        />
    }
  </>
}

function mapStateToProps({ cesiumEntity, display }) {
  return {
    cesiumEntity,
    focusMap: display.focusMap
  }
}

const mapDispatchToProps = {
  toggleEntity,
  removeMap,
  setFocusMap,
  setContext,
  closeContext
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu)
