import React, { useEffect } from 'react'
import { hot } from 'react-hot-loader'
import cookies, {
  handleLogin,
  handleLogout
} from './apis/cookies'

import Routes from './routes'

import axios from './apis/axiosReact'

import './App.css'

document.onkeydown = function(event) {
  if(event.key === 'F12') {
    return false;
  }
  if(event.ctrlKey && event.shiftKey && event.key === 'I') {
    return false;
  }
  if(event.ctrlKey && event.shiftKey && event.key === 'C') {
    return false;
  }
  if(event.ctrlKey && event.shiftKey && event.key === 'J') {
    return false;
  }
  if(event.ctrlKey && event.key === 'U') {
    return false;
  }
}

function App() {
  useEffect(() => {
    async function checkToken() {
      // let accToken = cookies.accessToken
      let rfsToken = cookies.refreshToken

      let expire = false

      try {
        // 1. using jwt verify, to check token expiry
        // nembak server
        // let verified = jwt.verify(accToken, JWT_SECRET)
      } catch (error) {
        // expire = true
      }

      // edit later
      expire = false

      // 2. request new access and refresh token if expired
      try {
        if (expire) {
          if (!rfsToken) {
            throw new Error('no refresh token')
          }

          let { data } = await axios({
            method: 'GET',
            url: `/refresh`,
            headers: {
              'Authorization': `Bearer ${rfsToken}`,
            },
          })

          handleLogin({
            accToken: data['access_token'],
            rfsToken: data['refresh_token'],
            username: data.username
          })
        }
      } catch (error) {
        handleLogout()
      }
    }

    checkToken()
  }, [])

  return (
    <div className="container">
      <Routes />
    </div>
  )
}

export default hot(module)(App)
