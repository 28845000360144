import {
  Math as CesiumMath,
  Cartesian3,
  Cartographic,
  JulianDate
} from 'cesium'

import {
  generateBlockPolygon,
  generateCrossBlockPolygon
} from './generatePolygon'

import {
  createBlockSection
} from './cesium3d'

import SectionMath from './SectionMath'

/**
 * This function will handle mouse move in section feature block
 * @param {Object} event - event when mouse move triggered
 * @param {Object} cesiumViewer - object of cesiumViewer
 */
export function translatePlaneBlock(event, cesiumViewer) {
  try {
    const { sectionStatus, viewer } = cesiumViewer
    const { transform } = sectionStatus

    event.position = event.endPosition

    let bearing = 90

    if (transform.direction === 'y') {
      bearing = 180 - bearing

      let movement = getMovementY(viewer, event, bearing)

      if (movement) {
        translateY(sectionStatus, bearing, movement)
        transformY(cesiumViewer, bearing, movement)
        
        const polygon = generateBlockPolygon(sectionStatus.dragMovement)
        createBlockSection(cesiumViewer, polygon)
      }
    } else if (transform.direction === 'x') {
      let movement = getMovementX(viewer, event, bearing)

      if (movement) {
        transformAndTranslateX(sectionStatus, bearing, movement)

        const polygon = generateBlockPolygon(sectionStatus.dragMovement)

        createBlockSection(cesiumViewer, polygon)
      }
    } else if (transform.direction === 'z') {
      let movement = getMovementZ(viewer, event)

      if (movement) {
        translateZ(sectionStatus, movement)

        const polygon = generateBlockPolygon(sectionStatus.dragMovement)

        transformZ(cesiumViewer, movement)

        createBlockSection(cesiumViewer, polygon)
      }
    }
  } catch (error) {
    throw error
  }
}

/**
 * This function will handle mouse move in section feature cross
 * @param {Object} event - event when mouse move triggered
 * @param {Object} cesiumViewer - object of cesiumViewer
 */
 export function translatePlaneCross(event, cesiumViewer) {
  const { sectionStatus, viewer } = cesiumViewer
  const { transform, dragMovement } = sectionStatus

  event.position = event.endPosition

  let bearing = SectionMath.getPlaneBearing(dragMovement)

  if (transform.direction === 'y') {
    bearing = 180 - bearing

    let movement = getMovementY(viewer, event, bearing)

    if (sectionStatus.crossSection) {
      movement = movement / 4
    }

    if (movement) {
      translateY(sectionStatus, bearing, movement)
      transformYCross(cesiumViewer, movement)
      
      const polygon = generateCrossBlockPolygon(sectionStatus.dragMovement)
      createBlockSection(cesiumViewer, polygon)
    }
  } else if (transform.direction === 'x') {
    let movement = getMovementX(viewer, event, bearing)

    if (movement) {
      transformAndTranslateX(sectionStatus, bearing, movement)

      const polygon = generateCrossBlockPolygon(sectionStatus.dragMovement)

      createBlockSection(cesiumViewer, polygon)
    }
  } else if (transform.direction === 'z') {
    let movement = getMovementZ(viewer, event)

    if (movement) {
      translateZ(sectionStatus, movement)

      const polygon = generateCrossBlockPolygon(sectionStatus.dragMovement)

      transformZ(cesiumViewer, movement)

      createBlockSection(cesiumViewer, polygon)
    }
  }
}

/**
 * This function will handle mouse move in section feature slice
 * @param {Object} event - event when mouse move triggered
 * @param {Object} cesiumViewer - object of cesiumViewer
 */
export function translatePlaneSlice(event, cesiumViewer) {
  const { sectionStatus, viewer } = cesiumViewer
  const { transform, dragMovement, sliceMovement } = sectionStatus

  event.position = event.endPosition

  let bearing = 90

  if (transform.direction === 'y') {
    bearing = 180 - bearing

    let movement = getMovementY(viewer, event, bearing)

    if (movement) {
      translateY(sectionStatus, bearing, movement)
      transformY(cesiumViewer, bearing, movement)
      
      const polygon = generateBlockPolygon(dragMovement)
      createBlockSection(cesiumViewer, polygon)
    }
  } else if (transform.direction === 'x') {
    let movement = getMovementX(viewer, event, bearing)

    if (movement) {
      transformAndTranslateX(sectionStatus, bearing, movement)

      const polygon = generateBlockPolygon(dragMovement)

      createBlockSection(cesiumViewer, polygon)
    }
  } else if (transform.direction === 'z') {
    let movement = getMovementZ(viewer, event)

    if (movement) {
      translateZ(sectionStatus, movement)

      const polygon = generateBlockPolygon(dragMovement)

      transformZ(cesiumViewer, movement)

      createBlockSection(cesiumViewer, polygon)
    }
  } else if (transform.direction === 'y_slice') {
    bearing = 180 - SectionMath.getPlaneBearing(sliceMovement)

    let movement = 0
    let headingFlag = 1

    if (
      (CesiumMath.toDegrees(viewer.camera.heading) + bearing) % 360 >= 90
      && (CesiumMath.toDegrees(viewer.camera.heading) + bearing) % 360 < 270
    ) {
      headingFlag = -1
    }

    if (event.startPosition.y > event.endPosition.y) {
      movement = viewer.camera.positionCartographic.height / 3839314 * 0.25 * headingFlag
    } else if (event.startPosition.y < event.endPosition.y) {
      movement = -viewer.camera.positionCartographic.height / 3839314 * 0.25 * headingFlag
    }

    if (movement) {
      if (transform.reference) {
        let { longitude, latitude, height } = Cartographic
          .fromCartesian(
            transform.entity.position
              .getValue(JulianDate.now())
          )
        longitude = CesiumMath.toDegrees(longitude) + movement
          * Math.cos(CesiumMath.toRadians(bearing))
        latitude = CesiumMath.toDegrees(latitude) + movement
          * Math.sin(CesiumMath.toRadians(bearing))
        
        const position = new Cartesian3.fromDegrees(
          longitude,
          latitude,
          height
        )
        
        transform.entity.position = position 
      }

      if (sectionStatus.sliceSection) {
        if (transform.reference !== 'start' || transform.click === 2) {
          sliceMovement.start.longitude += movement * Math.sin(CesiumMath.toRadians(bearing)) * headingFlag
          sliceMovement.start.latitude += movement * Math.cos(CesiumMath.toRadians(bearing)) * headingFlag
          sliceMovement.end.longitude += movement * Math.sin(CesiumMath.toRadians(bearing)) * headingFlag
          sliceMovement.end.latitude += movement * Math.cos(CesiumMath.toRadians(bearing)) * headingFlag
        }
        if (transform.reference !== 'end' || transform.click === 2) {
          sliceMovement.start.longitude += movement * Math.sin(CesiumMath.toRadians(bearing)) * headingFlag
          sliceMovement.start.latitude += movement * Math.cos(CesiumMath.toRadians(bearing)) * headingFlag
          sliceMovement.end.longitude += movement * Math.sin(CesiumMath.toRadians(bearing)) * headingFlag
          sliceMovement.end.latitude += movement * Math.cos(CesiumMath.toRadians(bearing)) * headingFlag
        }
      }

      const polygon = generateBlockPolygon(sectionStatus.dragMovement)

      createBlockSection(cesiumViewer, polygon)
    }
  }
}

/**
 * This method will return movement y based on editor drag
 * @param {Cesium.Viewer} viewer - cesium state of viewer
 * @param {Object} event - event when movement stop moving
 * @param {Number} bearing - bearing direction of movement
 * @returns {Number} y movement
 */
 function getMovementY(viewer, event, bearing) {
  let movement = 0
  let headingFlag = 1

  if (
    CesiumMath.toDegrees(viewer.camera.heading) > bearing
    && CesiumMath.toDegrees(viewer.camera.heading) < bearing + 180
  ) {
    headingFlag = -1
  }

  const Y_DISPLACEMENT = 3839314 / 0.25

  if (event.startPosition.y > event.endPosition.y) {
    movement = viewer.camera.positionCartographic.height / Y_DISPLACEMENT * headingFlag
  } else if (event.startPosition.y < event.endPosition.y) {
    movement = -viewer.camera.positionCartographic.height / Y_DISPLACEMENT * headingFlag
  }

  return movement
}

/**
 * This method will return movement x based on editor drag
 * @param {Cesium.Viewer} viewer - cesium state of viewer
 * @param {Object} event - event when movement stop moving
 * @param {Number} bearing - bearing direction of movement
 * @returns {Number} x movement
 */
function getMovementX(viewer, event, bearing) {
  let movement = 0
  let headingFlag = 1

  if (
    CesiumMath.toDegrees(viewer.camera.heading) > bearing
    && CesiumMath.toDegrees(viewer.camera.heading) < bearing + 180
  ) {
    headingFlag = -1
  }

  if (event.startPosition.x < event.endPosition.x) {
    movement = viewer.camera.positionCartographic.height / 3839314 * 0.25 * headingFlag
  } else if (event.startPosition.x > event.endPosition.x) {
    movement = -viewer.camera.positionCartographic.height / 3839314 * 0.25 * headingFlag
  }

  return movement
}

/**
 * This method will return movement z based on editor drag
 * @param {Cesium.Viewer} viewer - cesium state of viewer
 * @param {Object} event - event when movement stop moving
 * @returns {Number} z movement
 */
function getMovementZ(viewer, event) {
  let movement = 0

  const Z_DISPLACEMENT = 3839314 / 5000

  if (event.startPosition.y > event.endPosition.y) {
    movement = viewer.camera.positionCartographic.height / Z_DISPLACEMENT
  } else if (event.startPosition.y < event.endPosition.y) {
    movement = -viewer.camera.positionCartographic.height / Z_DISPLACEMENT
  }
  
  return movement
}

/**
 * This method will translate in y direction based on given parameter
 * @param {Object} sectionStatus - object of sectionStatus
 * @param {Number} bearing - direction of movement
 * @param {Number} movement - total move
 */
function translateY(sectionStatus, bearing, movement) {
  const { transform, sectionEditor } = sectionStatus

  if (transform.reference && transform.click === 1) {
    let { longitude, latitude, height } = Cartographic
      .fromCartesian(
        transform.entity.position
          .getValue(JulianDate.now())
      )
    
    longitude = CesiumMath.toDegrees(longitude) + movement
      * Math.cos(CesiumMath.toRadians(bearing))
    latitude = CesiumMath.toDegrees(latitude) + movement
      * Math.sin(CesiumMath.toRadians(bearing))
    
    const position = new Cartesian3.fromDegrees(
      longitude,
      latitude,
      height
    )

    transform.entity.position = position 
  } else {
    for (let i = 0; i < sectionEditor.length; i++) {
      let { longitude, latitude, height } = Cartographic
        .fromCartesian(
          sectionEditor[i].position
            .getValue(JulianDate.now())
        )
      
      longitude = CesiumMath.toDegrees(longitude) + movement * Math.cos(CesiumMath.toRadians(bearing))
      latitude = CesiumMath.toDegrees(latitude) + movement * Math.sin(CesiumMath.toRadians(bearing))
      
      const position = new Cartesian3.fromDegrees(
        longitude,
        latitude,
        height
      )
      
      sectionEditor[i].position = position 
    }
  }
}

/**
 * This method will transform and translate in x direction based on given parameter
 * @param {Object} sectionStatus - object of sectionStatus
 * @param {Number} bearing - direction of movement
 * @param {Number} movement - total move
 */
function transformAndTranslateX(sectionStatus, bearing, movement) {
  const { transform, sectionEditor, dragMovement } = sectionStatus
  
  if (transform.reference && transform.click === 1) {
    let { longitude, latitude, height } = Cartographic
      .fromCartesian(
        transform.entity.position
          .getValue(JulianDate.now())
      )
    
    longitude = CesiumMath.toDegrees(longitude) + movement * Math.sin(CesiumMath.toRadians(bearing))
    latitude = CesiumMath.toDegrees(latitude) + movement * Math.cos(CesiumMath.toRadians(bearing))
    
    const position = new Cartesian3.fromDegrees(
      longitude,
      latitude,
      height
    )
    
    transform.entity.position = position 
  } else {
    for (let i = 0; i < sectionEditor.length; i++) {
      let { longitude, latitude, height } = Cartographic
        .fromCartesian(
          sectionEditor[i].position
            .getValue(JulianDate.now())
        )

      longitude = CesiumMath.toDegrees(longitude) + movement * Math.sin(CesiumMath.toRadians(bearing))
      latitude = CesiumMath.toDegrees(latitude) + movement * Math.cos(CesiumMath.toRadians(bearing))
      
      const position = new Cartesian3.fromDegrees(
        longitude,
        latitude,
        height
      )
      
      sectionEditor[i].position = position 
    }
  }

  if (transform.reference !== 'end' || transform.click === 2) {
    dragMovement.start.longitude += movement * Math.sin(CesiumMath.toRadians(bearing))
    dragMovement.start.latitude += movement * Math.cos(CesiumMath.toRadians(bearing))
  }
  if (transform.reference !== 'start' || transform.click === 2) {
    dragMovement.end.longitude += movement * Math.sin(CesiumMath.toRadians(bearing))
    dragMovement.end.latitude += movement * Math.cos(CesiumMath.toRadians(bearing))
  }
}

/**
 * This method will transform and translate in z direction based on given parameter
 * @param {Object} sectionStatus - object of sectionStatus
 * @param {Number} movement - total move
 */
 function translateZ(sectionStatus, movement) {
  const { transform, sectionEditor } = sectionStatus
  
  if (transform.reference && transform.click === 1) {
    let { longitude, latitude, height } = Cartographic
      .fromCartesian(
        transform.entity.position
          .getValue(JulianDate.now())
      )
    
    const position = new Cartesian3.fromDegrees(
      CesiumMath.toDegrees(longitude),
      CesiumMath.toDegrees(latitude),
      height + movement
    )
    
    transform.entity.position = position 
  } else {
    for (let i = 0; i < sectionEditor.length; i++) {
      const { longitude, latitude, height } = Cartographic
        .fromCartesian(
          sectionEditor[i].position
            .getValue(JulianDate.now())
        )
      
      const position = new Cartesian3.fromDegrees(
        CesiumMath.toDegrees(longitude),
        CesiumMath.toDegrees(latitude),
        height + movement
      )
      
      sectionEditor[i].position = position 
    }
  }
}

/**
 * This method used to transform Y by default
 * @param {Object} cesiumViewer - object of cesiumViewer
 * @param {Number} movement - total move
 */
export function transformY(cesiumViewer, bearing, movement) {
  const { sectionStatus } = cesiumViewer
  const { transform, dragMovement } = sectionStatus 

  if (transform.reference !== 'end' || transform.click === 2) {
    dragMovement.start.longitude += movement * Math.cos(CesiumMath.toRadians(bearing))
    dragMovement.start.latitude += movement * Math.sin(CesiumMath.toRadians(bearing))
  }
  if (transform.reference !== 'start' || transform.click === 2) {
    dragMovement.end.longitude += movement * Math.cos(CesiumMath.toRadians(bearing))
    dragMovement.end.latitude += movement * Math.sin(CesiumMath.toRadians(bearing))
  }
}

/**
 * This method used to transform Y for cross
 * @param {Object} cesiumViewer - object of cesiumViewer
 * @param {Number} movement - total move
 */
export function transformYCross(cesiumViewer, movement) {
  const { sectionStatus } = cesiumViewer
  const { transform, dragMovement } = sectionStatus

  if (transform.reference !== 'end' || transform.click === 2) {
    dragMovement.crossWidth.left -= movement * 35000 * 3.14
  }
  if (transform.reference !== 'start' || transform.click === 2) {
    dragMovement.crossWidth.right += movement * 35000 * 3.14
  }
}

/**
 * This method used to transform Z by default
 * @param {Object} cesiumViewer - object of cesiumViewer
 * @param {Number} bearing - direction of movement
 * @param {Number} movement - total move
 */
export function transformZ(cesiumViewer, movement) {
  const { transform, dragMovement } = cesiumViewer.sectionStatus

  if (transform.reference !== 'end' || transform.click === 2) {
    dragMovement.top += movement
  }
  if (transform.reference !== 'start' || transform.click === 2) {
    dragMovement.bottom += movement
  }
}
