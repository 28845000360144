import React, { useState } from 'react'

import cookies from '../../../../apis/cookies'
import axiosReact from '../../../../apis/axiosReact'

import DeleteConfirmation from './DeleteConfirmation'

import {
  ReactComponent as DownIcon
} from '../../../../images/Icons/down.svg'

import {
  ReactComponent as SelectIcon
} from '../../../../images/Icons/check.svg'

import {
  ReactComponent as DeleteIcon
} from '../../../../images/Icons/trash.svg'

import {
  ReactComponent as ResetIcon
} from '../../../../images/Icons/reset.svg'

import iziToast from 'izitoast'
import styled from 'styled-components'

import {
  DANGER_RED,
  SUCCESS_GREEN,
  WHITE,
  GREYISH_DARK
} from '../../../shares/ColorTemplate'

const ContainerOption = styled.div`
  display: flex;
  align-items: center;
`

const SelectOption = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 0 0.5rem;
  border: 1px solid black;
  cursor: pointer;
  z-index: 4;
  margin-right: 1em;
`

const SelectOptionToggle = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 2.05rem;
  width: 100%;
  max-height: 20em;
  left: -0.1rem;
  border-radius: 5px;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  background: white;
  overflow: auto;
`

const SelectOptionToggleText = styled.h4`
  display: flex;
  align-items: center;
  margin: 0.5em 0;
  width: 100%;
`

const SelectOptionStripe = styled.div.attrs((props) => {
  return {
    style: {
      background: props.stripe ? 'white' : '#dddddd'
    }
  }
})`
  padding: 0.2em 0.5em;
  display: flex;
  align-items: center;

  &:hover {
    background: ${props => props.stripe ? '#eeeeee' : '#d3d3d3'};
  }
`

const CustomButton = styled.div`
  cursor: pointer;
  margin-right: 1em;
  background-color: ${GREYISH_DARK.default};
  padding: 0.5em 1em;
  border: 1px solid black;
  border-radius: 5px;
  color: ${WHITE.default};
  transition: background-color color 330ms;

  &:hover {
    background-color: ${GREYISH_DARK.hover};
    color: ${WHITE.hover};
  }
`

const GradebarIcon = styled.div`
  svg {
    fill: ${props => props.fill.default};
    width: ${props => props.width || '0.9em'};
    margin-left: 0.5em;
    transition: fill 330ms;
  }
  cursor: pointer;

  &:hover {
    svg {
      fill: ${props => props.fill.hover};
    }
  }
`

function Header({
  gridBin,
  setGridBin,
  generateBinSize,
  grade,
  unselected,
  userRatings,
  setUserRatings,
  pickCommit,
  resetConfig,
  generateLoadingMessage,
  clearLoadingMessage
}) {
  const [ gridConfig, setGridConfig ] = useState({
    toggle: false,
    all: false
  })

  const [ commitConfig, setCommitConfig ] = useState({
    toggle: false
  })

  const [ idDeleteConfirmation, setDeleteIdConfirmation ] = useState(-1)

  async function getCommitId() {
    const { data } = await axiosReact.post('/geofix/user_rating')

    setUserRatings((ratings) => ({
      ...ratings,
      commit_id: data.commit_id
    }))

    return data.commit_id
  }

  async function saveData({ commit_id }) {
    const requestBinSize = []

    gridBin.forEach((bin) => {
      if (bin.status) {
        requestBinSize.push(bin.bin_size)
      }
    })

    let requestGrade = {}

    for (let category in grade) {
      if (category === 'databaseName') {
        continue
      }
      const currentCategory = {}

      for (let attribute in grade[category]) {
        if (attribute === 'databaseName') {
          continue
        }

        let ignore = unselected.includes(attribute)

        for (let detailAttribute in grade[category][attribute]) {
          if (detailAttribute === 'databaseName') {
            continue
          } else {
            const bin = grade[category][attribute][detailAttribute]
            if (!ignore) {
              if (!isNaN(Number(grade[category][attribute][detailAttribute].value))) {
                if (!currentCategory[grade[category][attribute].databaseName]) {
                  currentCategory[grade[category][attribute].databaseName] = {
                    [bin.databaseName]: bin.value
                  }
                } else {
                  currentCategory[grade[category][attribute].databaseName][bin.databaseName] = bin.value
                }
              }
            }
          }
        } 
      }

      if (Object.keys(currentCategory).length) {
        requestGrade = {
          ...requestGrade,
          ...currentCategory
        }
      }
    }

    await axiosReact.post(`/geofix/user_rating/${commit_id}`, {
      weight: requestGrade,
      bin_size: requestBinSize,
      commit_info: {
        commit_name: userRatings.commit_name
      }
    })
  }

  async function commitData({ commit_id }) {
    await axiosReact.put(`/geofix/user_rating/${commit_id}`, {
      commit_value: true
    })
  }

  async function fetchUserRatings() {
    try {
      const { data } = await axiosReact.get('/geofix/user_rating')

      setUserRatings((ratings) => ({
        ...ratings,
        data
      }))
    } catch (error) {
      throw error
    }
  }

  function validityData() {
    const errors = []
    
    if (!userRatings.commit_name) {
      errors.push('add commit name')
    }

    let checkGridBin = false

    for (let i = 0; i < gridBin.length; i++) {
      if (gridBin[i].status) {
        checkGridBin = true
      }
    }

    if (!checkGridBin) {
      errors.push('select grid')
    }

    if (errors.length) {
      iziToast.warning({
        title: 'Uncomplete Request',
        message: 'Please ' + errors.join(' and ')
      })

      return false
    }

    return true
  }

  async function recalculateData({ saveAs, loadingId }) {
    try {
      if (validityData() === false) {
        throw 'Form not completed'
      }
  
      let commit_id = userRatings.commit_id
      
      if (!userRatings.commit_id || saveAs) {
        commit_id = await getCommitId()
      }
  
      await saveData({ commit_id })
      await commitData({ commit_id })
      await fetchUserRatings()
  
      clearLoadingMessage(loadingId)
    } catch (error) {
      clearLoadingMessage(loadingId)
    }
  }

  function toggleSelectGrid() {
    setGridConfig({
      ...gridConfig,
      toggle: !gridConfig.toggle
    })
  }

  async function deleteCommit(commit) {
    let id = await generateLoadingMessage(`Delete commit with name ${commit.commit_info.commit_name}`)
    
    try {  
      if (userRatings.commit_id === commit.id) {
        resetConfig()
      }
      
      await axiosReact.delete(`/geofix/user_rating/${commit.id}`)

      fetchUserRatings()
    } catch (error) {

    } finally {
      clearLoadingMessage(id)
    }
  }

  function checkBin() {
    const cloneBin = gridBin.slice()

    cloneBin.forEach((bin) => {
      bin.status = !gridConfig.all
    })

    setGridBin(cloneBin)
    setGridConfig({
      ...gridConfig,
      all: !gridConfig.all
    })
  }

  function toggleSelectCommit() {
    setCommitConfig({
      ...commitConfig,
      toggle: !commitConfig.toggle
    })
  }

  return <ContainerOption>
    <GradebarIcon
      fill={GREYISH_DARK}
      width="1.5em"
      style={{
        marginRight: '0.8em',
        display: 'flex',
        alignItems: 'center'
      }}
      title="Reset Config"
      onClick={resetConfig}
    >
      <ResetIcon />
    </GradebarIcon>
    <SelectOption style={{ width: '10em' }}>
      <SelectOptionToggleText
        onClick={toggleSelectGrid}
      >
        Select Grid
        <DownIcon style={{
          position: 'absolute',
          right: '1em',
          width: '1em',
          height: '1em'
        }} />
      </SelectOptionToggleText>
      {
        gridConfig.toggle
          &&
          <SelectOptionToggle>
            <SelectOptionStripe
              stripe={true}
              style={{
                cursor: 'default'
              }}
            >
              <input
                type="checkbox"
                style={{ cursor: 'pointer' }}
                checked={gridConfig.all}
                onChange={checkBin}
              />
              All
            </SelectOptionStripe>
            {
              gridBin.map((bin, index) => {
                return <SelectOptionStripe
                  key={index}
                  stripe={index % 2 ? true : false}
                  style={{
                    cursor: 'default'
                  }}
                >
                  <input
                    type="checkbox"
                    style={{ cursor: 'pointer' }}
                    checked={bin.status}
                    onChange={() => {
                      const cloneBin = gridBin.slice()
    
                      cloneBin[index].status = !cloneBin[index].status
    
                      setGridBin(cloneBin)
                    }}
                  />
                  {generateBinSize(bin.bin_size)}
                </SelectOptionStripe>
              })
            }
          </SelectOptionToggle>
      }
    </SelectOption>
    <SelectOption style={{ width: '20em' }}>
      <SelectOptionToggleText
        onClick={toggleSelectCommit}
      >
        {userRatings.commit_name || 'Select Commit'}
        <DownIcon style={{
          position: 'absolute',
          right: '1em',
          width: '1em',
          height: '1em'
        }} />
      </SelectOptionToggleText>
      {
        commitConfig.toggle
          &&
          <SelectOptionToggle>
            {
              userRatings.data.length
                ? userRatings.data.map((commit, index) => {
                  const committer = commit.commit_info.committer || ''

                  let initial = ''

                  if (committer) {
                    initial = committer.split(' ')
                      .map((name) => name[0].toUpperCase())
                      .join('')
                    
                    initial += ' - '
                  }
                  
                  const sameUser = cookies
                    .get('username')
                    .toLowerCase() === committer.toLowerCase()

                  return <SelectOptionStripe
                    key={commit.id}
                    stripe={index % 2 ? false : true}
                    style={{ justifyContent: 'space-between '}}
                  >
                    <div style={{
                      overflowWrap: 'anywhere',
                      width: '100%'
                    }}>{initial + commit.commit_info.commit_name}</div>
                    <div className="row">
                      <GradebarIcon
                        fill={SUCCESS_GREEN}
                      >
                        <SelectIcon
                          onClick={() => {
                            pickCommit(commit)
                          }}
                        />
                      </GradebarIcon>
                      {
                        sameUser &&
                          <GradebarIcon
                            fill={DANGER_RED}
                          >
                            <DeleteIcon
                              onClick={() => {
                                setDeleteIdConfirmation(commit.id)
                              }}
                            />
                          </GradebarIcon>
                      }
                      {
                        idDeleteConfirmation === commit.id
                          && <DeleteConfirmation
                            name={commit.commit_info.commit_name}
                            yesHandler={async () => {
                              await deleteCommit(commit)
                              setDeleteIdConfirmation(-1)
                            }}
                            noHandler={() => {
                              setDeleteIdConfirmation(-1)
                            }}
                          />
                      }
                    </div>
                  </SelectOptionStripe>
                })
                : <SelectOptionStripe
                  stripe={true}
                >
                  No commit available
                </SelectOptionStripe>
            }
          </SelectOptionToggle>
      }
    </SelectOption>
    <h5 style={{ margin: '0 0.5em 0' }}>Commit Name :</h5>
    <input
      type="text"
      placeholder="Please enter commit name"
      style={{
        padding: '0.3em 1em',
        fontFamily: 'Abel',
        width: '11em',
        marginRight: '1em'
      }}
      value={userRatings.commit_name}
      onChange={(event) => {
        setUserRatings({
          ...userRatings,
          commit_name: event.target.value
        })
      }}
    />
    {
      userRatings.commit_id
        ? <>
          <CustomButton onClick={async () => {
            let id = await generateLoadingMessage(`Creating new commit with name ${userRatings.commit_name}`)
            recalculateData({
              saveAs: true,
              loadingId: id
            })
          }}>New Commit</CustomButton>
          {
            cookies && userRatings.committer && cookies
              .get('username')
              .toLowerCase() === userRatings.committer
              .toLowerCase()
              &&
                <CustomButton onClick={async () => {
                  let id = await generateLoadingMessage(`Update commit ${userRatings.commit_name}`)
                  recalculateData({ loadingId: id })
                }}>Update Commit</CustomButton>
          }
        </>
        : <CustomButton onClick={async () => {
          let id = await generateLoadingMessage(`Creating new commit with name ${userRatings.commit_name}`)
          recalculateData({
            loadingId: id
          })
        }}>Commit</CustomButton>
    }
  </ContainerOption>
}

export default Header
