import React from 'react'

import FlexWrapper from '../../shares/FlexWrapper'

import Catalog from './Catalog'

import SearchLocation from './Search/SearchLocation'

function Content() {
  return <>
    <SearchLocation />
    <FlexWrapper
      main-content
      col
    >
      <Catalog />
    </FlexWrapper>
  </>
}

export default Content
