import * as actionTypes from '../../constants/store/actionTypes/cesiumEntity'

const initialState = {
  mapId: '',
  entityId: ''
}

function cesiumEntityReducer (state = initialState, { type, payload }) {
  switch (type) {
    case actionTypes.CHANGE_ENTITY_ALPHA:
      payload.colorEntity.alpha = payload.alpha
    
      return {
        ...state
      }
    case actionTypes.CHANGE_ENTITY_COLOR:
      if (payload.colorEntity) {
        payload.colorEntity.red = payload.red
        payload.colorEntity.green = payload.green
        payload.colorEntity.blue = payload.blue
      }

      return {
        ...state
      }
    case actionTypes.INVERSE_COLOR:
      return {
        ...state
      }
    case actionTypes.TRIGGER_RENDER:
      return {
        ...state
      }
    case actionTypes.RESET_CESIUM_ENTITY:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default cesiumEntityReducer
