import React, { useState, useEffect } from 'react'

import LayerbarIcon from '../../../../../shares/LayerbarIcon'

import {
  enhance
} from '../../../../../helpers/enhance'

import {
  ReactComponent as EnhanceIcon
} from '../../../../../../../../images/Icons/enhance.svg'

/**
 * This function will create 3d button icon
 * @param map the current map / for now is only grid
 */
function EnchanceButton({
  map
}) {

  // need to modify it later
  const [ groupBy, setGroupBy ] = useState(map.cesiumLayer.groupBy)

  const [ isActive, setActive ] = useState(map.enhance)

  function onClickHandler() {
    const dataSource = map.dataSource

    if (!dataSource) {
      return
    }
    
    map.enhance = !map.enhance
    setActive(map.enhance)

    enhance({
      entities: dataSource.entities.values,
      groupBy,
      map,
      status: map.enhance
    })
  }

  return (
    <LayerbarIcon
      isActive={isActive}
    >
      <EnhanceIcon
        title="Enhance"
        onClick={onClickHandler}
      />
    </LayerbarIcon>
  )
}

export default EnchanceButton