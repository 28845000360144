export const TECHNICAL_PARAMETERS_GRAPHIC = {
  ANIMATION_DURATION: 1000,
  ANIMATION_DELAY: 30,
  SCORES: [ 5, 4, 3, 2, 1 ],
  TEXTS: [
    {
      displayName: 'Geology',
      databaseName: 'geology'
    },
    {
      displayName: 'Lineament',
      databaseName: 'lineament'
    },
    {
      displayName: 'Others',
      databaseName: 'others'
    },
    // {
    //   displayName: 'Geochemistry',
    //   databaseName: 'geochemistry'
    // },
    // {
    //   displayName: 'Mineral Occurences',
    //   databaseName: 'occurrence'
    // },
    // {
    //   displayName: 'Deposit Type',
    //   databaseName: 'deposit_type'
    // },
    // {
    //   displayName: 'Geophysics',
    //   databaseName: 'geophysics'
    // },
    // {
    //   displayName: 'Geometallurgy',
    //   databaseName: 'geometallurgy'
    // }
  ]
}

export const NON_TECHNICAL_PARAMETERS_GRAPHIC = {
  ANIMATION_DURATION: 1000,
  ANIMATION_DELAY: 30,
  SCORES: [ 5, 4, 3, 2, 1 ],
  TEXTS: [
    {
      displayName: 'Accessibility',
      databaseName: 'accessibility'
    },
    {
      displayName: 'Forestry',
      databaseName: 'forestry'
    },
    {
      displayName: 'Hazard',
      databaseName: 'hazard'
    },
    // {
    //   displayName: 'WIUP',
    //   databaseName: 'wiup'
    // },
    // {
    //   displayName: 'Geometallurgy',
    //   databaseName: 'geometallurgy'
    // }
  ]
}
