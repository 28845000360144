import styled from "styled-components"

export const WrapperTable = styled.div`
    width: 100%;
    height: 100%;
`
export const TableContainer = styled.div`
    margin:10px 0px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    max-height: calc(100% - 10px);
    overflow-x:auto;
    padding-left: 10px;
    &th,td {
        padding: 5px 10px;
        text-align: center;
    }
`

export const WrapperPagination = styled.div`
    margin-top:10px;
    height: 50px;
    font-family: abel;
    font-size: 0.8rem;
    margin-left:10px;
`