import React, { useState, useEffect, forwardRef } from 'react'
import DatePicker from 'react-datepicker'

import { ReactComponent as DateIcon } from '../../../images/Icons/date.svg'

import { GOLDEN_3D } from '../../shares/ColorTemplate'

import styled from 'styled-components'

import 'react-datepicker/dist/react-datepicker.css'

const DateContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`

const InputDate = styled.input`
  width: 6.5em;
  border-radius: 3px;
`

const StyledDateIcon = styled(DateIcon)`
  width: 1.4em;
  fill: #888888;
  position: absolute;
  right: 0.5em;
  top: -1.6em;
  cursor: pointer;

  &:hover {
    fill: ${GOLDEN_3D.default};
  }
`

function ReactDatePicker({ initial, date = new Date(), onChangeHandler }) {
  const [ typeDate, setTypeDate ] = useState(parseDate(date))

  function parseDate(date) {
    return new Date(date).toLocaleDateString().split('/').join('-')
  }

  useEffect(() => {
    setTypeDate(parseDate(date))
  }, [date])

  const ExampleCustomInput = forwardRef(({ onClick }, ref) => {
    return <StyledDateIcon onClick={onClick} ref={ref} />
  })

  return (
    <div style={{
      position: 'fixed',
      fontFamily: 'Abel',
      fontSize: '0.65em',
      zIndex: 1
    }}>
      <span style={{ marginRight: '0.3em' }}>
        {initial}:
      </span>
      <DateContainer>
        <InputDate
          className="example-custom-input"
          onChange={(event) => {
            const value = event.target.value

            setTypeDate(value)
          }}
          onKeyDown={(event) => {
            if (event.code === 'Enter') {
              onChangeHandler(new Date(typeDate))
            }
          }}
          value={String(typeDate)}
        />
        <DatePicker
          selected={date}
          onChange={onChangeHandler}
          customInput={<ExampleCustomInput />}
          dateFormat="MM-dd-yyyy"
        />
      </DateContainer>
    </div>
  )
}

export default ReactDatePicker
