import * as Cesium from 'cesium'

import cesiumViewer from '../../apis/cesiumViewer';
import { GRID_CONFIGURATION } from '../../constants/Cesium/gridConfig';
import * as gridFile from './GridFile';

function getCesiumAssetId(commodity, bin_size) {
    const ASSET_REFFERENCE = {
      'copper_gold_silver': {
        16000: 1408349,
        8000: 1408352,
        4000: 1408353,
        2000: 1408354
      },
      'nickel': {
        16000: 1408397,
        8000: 1408398,
        4000: 1408399,
        2000: 1408400
      },
      'coal': {
        16000: 1408287,
        8000: 1408288,
        4000: 1408289,
        2000: 1408292
      },
      'bauxite': {
        16000: 1408271,
        8000: 1408272,
        4000: 1408274,
        2000: 1408275
      },
      'lead_zinc': {
        16000: 1408383,
        8000: 1408384,
        4000: 1408385,
        2000: 1408386
      },
      'manganese': {
        16000: 1408387,
        8000: 1408389,
        4000: 1408390,
        2000: 1408391
      },
      'tin': {
        16000: 1408402,
        8000: 1408403,
        4000: 1408404,
        2000: 1408405
      },
      'iron': {
        16000: 1408371,
        8000: 1408372,
        4000: 1408373,
        2000: 1408374
      },
      'non technical': {
        16000: 1408406,
        8000: 1408407,
        4000: 1408409,
        2000: 1408411,
      }
    }

    if (ASSET_REFFERENCE[commodity] && ASSET_REFFERENCE[commodity][bin_size]) {
        return ASSET_REFFERENCE[commodity][bin_size]
    }
    // Default value
    else if (ASSET_REFFERENCE[commodity]?.[8000]) {
        return ASSET_REFFERENCE[commodity][8000]
    }

    return null
}

export const CesiumLoadImages = ({...props}) => {
    return
    const {
        grid=8000,
        commodity
    } = props
    const assetId = getCesiumAssetId(commodity, grid) 
    // let key = commodity == 'copper_gold_silver' ? 'tilesetId' : 'assetId'

    for (let i = 0; i < cesiumViewer.viewer.imageryLayers.length; i++) {
        const layer = cesiumViewer.viewer.imageryLayers._layers[i]

        if (!layer.isBaseLayer || !layer.isBaseLayer()) {
            cesiumViewer.viewer.imageryLayers.remove(layer)
        }
    }

    if (assetId) {
        cesiumViewer.viewer.imageryLayers.addImageryProvider(
            new Cesium.IonImageryProvider({ assetId })
        )

        return
    }


    const file1k=['grid1k0','grid1k1','grid1k2','grid1k3','grid1k4','grid1k5','grid1k6','grid1k7']
    const fileMore1k=['grid0','grid1','grid2','grid3','grid4','grid5','grid6','grid7']
    const nameViewer='grid_'+grid+'_'+commodity
    

    const sourceCopperGold = (file) => {
        let fileReturn = null
        if(grid===1000){
            file1k.map((i,index)=>{
                if(file===i){
                    if(commodity==='copper_gold_silver'){
                        fileReturn = gridFile['grid1000gold'+index]
                    } else if (commodity==='lead_zinc') {
                        fileReturn = gridFile['grid1000lead'+index]
                    } else if (commodity==='bauxite'){
                        fileReturn = gridFile['grid1000bauxite'+index]
                    } else if (commodity==='nickel'){
                        fileReturn = gridFile['grid1000nickel'+index]
                    } else if (commodity==='coal'){
                        fileReturn = gridFile['grid1000coal'+index]
                    } else if (commodity==='manganesse'){
                        fileReturn = gridFile['grid1000lead'+index]
                    } else if (commodity==='iron'){
                        fileReturn = gridFile['grid1000lead'+index]
                    } else if (commodity==='tin'){
                        fileReturn = gridFile['grid1000lead'+index]
                    }
                }
            })
        } else {
            fileMore1k.map((i, index)=>{
                if(file===i){
                    if(commodity==='copper_gold_silver'){
                        fileReturn = gridFile['grid'+grid+'gold'+index]
                    } else if (commodity==='lead_zinc') {
                        fileReturn = gridFile['grid'+grid+'lead'+index]
                    } else if (commodity==='bauxite'){
                        fileReturn = gridFile['grid'+grid+'bauxite'+index]
                    } else if (commodity==='nickel'){
                        fileReturn = gridFile['grid'+grid+'nickel'+index]
                    } else if (commodity==='coal'){
                        fileReturn = gridFile['grid'+grid+'coal'+index]
                    } else if (commodity==='manganesse'){
                        fileReturn = gridFile['grid'+grid+'manganesse'+index]
                    } else if (commodity==='iron'){
                        fileReturn = gridFile['grid'+grid+'iron'+index]
                    } else if (commodity==='tin'){
                        fileReturn = gridFile['grid'+grid+'tin'+index]
                    }
                }
            })
        }
        return fileReturn
    }

    // cesiumViewer.viewer.entities.values.forEach((entity)=>{
    //     if(entity.name && entity.name.includes('grid_')){
    //         cesiumViewer.viewer.entities.remove(entity)
    //     }
    // })

    

    GRID_CONFIGURATION.map((i)=>{
        if(grid===1000){
            if(i.grid==='1000'){
                if(sourceCopperGold(i.file)!==undefined){
                    cesiumViewer.cesiumLoadImages(nameViewer, i.coordinates, sourceCopperGold(i.file))
                }
            }
        } else {
            if(i.grid === '8000'){
                if(sourceCopperGold(i.file)!==undefined){
                    // cesiumViewer.cesiumLoadImages(nameViewer, i.coordinates, sourceCopperGold(i.file))
                }
            }
        }
    })
}