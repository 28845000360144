import * as actionTypes from '../../constants/store/actionTypes/catalogSearch'

const initialState = {
  search: '',
  searchLevel: '',
  searchKeyword: '',
  error: null
}

function catalogSearchReducer(state = initialState, { type, payload }) {
  switch (type) {
    case actionTypes.SET_CATALOG_SEARCH_SUCCESS:
      return {
        ...state,
        search: payload.search,
        searchLevel: payload.searchLevel,
        searchKeyword: payload.searchKeyword
      }
    case actionTypes.SET_CATALOG_SEARCH_ERROR:
      return {
        ...state,
        error: payload
      }
    case actionTypes.RESET_CATALOG_SEARCH:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default catalogSearchReducer
