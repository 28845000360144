import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'

import {
  toggleEntity
} from '../../../../../../../../store/actions/catalog'

import {
  triggerRender
} from '../../../../../../../../store/actions/cesiumEntity'

import FlexWrapper from '../../../../../shares/FlexWrapper'
import ToolbarText from '../../../../../shares/ToolbarText'

import AddButton from './AddButton'
import RemoveButton from './RemoveButton'
import EditButton from './EditButton'
import EditText from './EditText'

import Eye from '../../Eye'

import EntityColorButton from './EntityColorButton'
import ColorPalette from './ColorPalette'

import styled from 'styled-components'

const StaticContainer = styled.div`
  display: flex;
  position: static;
  right: 1.5em;
`

/**
 * This component is one line sub-menu from the current line into color
 * @param entities is the group of entity of cesium dataSource in menu
 * @param name is name to show
 * @param index is submenu index
 * @param map is the menu map
 * @param marginLeft is the margin of submenu
 * @param catalog is the state of cesium map
 * @param toggleEntity redux actions to hide or show category
 * @param {Object} groupEntities is current group entities
 * @param {Function} setGroupEntities is the method to change group entities
 * @param {Object} fieldMode is the selected field mode
 * @param {String} groupBy is the selected group by
 * @param {Function} setTemplate is method to change active template
 */
function DetailMenu({
  entities,
  map,
  name,
  marginLeft,
  background,
  cesiumEntity,
  catalog,
  toggleEntity,
  groupEntities,
  setGroupEntities,
  fieldMode,
  groupBy,
  setTemplate,
  triggerRender
}) {
  const [ isActive, setActive ] = useState(false)
  const [ paletteShow, setPaletteShow ] = useState(false)
  const [ editOption, setEditOption ] = useState({
    editable: false,
    onEdit: false
  })

  useEffect(() => {
    if (!entities[0] || !entities[0].show) {
      setActive(false)
    } else {
      setActive(true)
    }
  }, [entities, catalog, cesiumEntity])

  useEffect(() => {
    if (fieldMode.selected === 'custom') {
      setEditOption((currentOption) => {
        return {
          ...currentOption,
          editable: true,
          onEdit: false
        }
      })
    }

    return () => {
      setEditOption({
        editable: false,
        onEdit: false
      })
    }
  }, [fieldMode])

  function onClickEyeHandler() {
    /**
     * This function will hide / show detail menu and cesium entity
     */
    for (let i = 0; i < entities.length; i++) {
      toggleEntity({
        entity: entities[i]
      })
    }

    setActive(!isActive)

    triggerRender({})
  }

  /**
   * This function will show/hide color palette componnent
   */
  function showHideColorHandler() {
    setPaletteShow(!paletteShow)
  }

  /**
   * this function will change color based on selected color
   */
  function onPickColorHandler(color) {
    setTemplate(null)

    map.colorScale = null

    setGroupEntities({
      ...groupEntities,
      style: {
        ...groupEntities.style,
        [name]: {
          ...groupEntities.style[name],
          color
        }
      }
    })

    showHideColorHandler()
  }

  function getShape() {
    /**
     * This function get shape for entity color button
     */

    if (
      map.geom
      && typeof map.geom === 'string'
      && map.geom.toLowerCase().includes('line')
    ) {
      return 'line'
    }
  }

  return (
    <FlexWrapper
      center
      marginLeft={marginLeft}
      backgroundColor={background}
      minHeight="1.6em"
    >
      <Eye
        isActive={isActive}
        onClickHandler={onClickEyeHandler}
      />
      <EntityColorButton
        color={groupEntities.style[name].color}
        shape={getShape()}
        alpha={groupEntities.style[name].color.alpha}
        onClickHandler={showHideColorHandler}
      />
      {
        editOption.onEdit
          ? <EditText
            map={map}
            name={name}
            groupEntities={groupEntities}
            setGroupEntities={setGroupEntities}
            groupBy={groupBy}
            fieldMode={fieldMode}
            setEditOption={setEditOption}
            setTemplate={setTemplate}
          />
          : <ToolbarText
            width="22em"
            text={name}
            max={32}
            type="displayDetail"
          />
      }
      {
        paletteShow
        && <ColorPalette
          background={groupEntities.style[name].color}
          backgrounds={
            entities
              .map((entity) => map.colorEntities[entity.id])
          }
          onAcceptHandler={onPickColorHandler}
          hidePalette={showHideColorHandler}
          multiple={true}
        />
      }
      {
        editOption.editable
          && <StaticContainer>
            <AddButton
              map={map}
              name={name}
              groupEntities={groupEntities}
              setGroupEntities={setGroupEntities}
              groupBy={groupBy}
              fieldMode={fieldMode}
              setTemplate={setTemplate}
            />
            <RemoveButton
              map={map}
              name={name}
              groupEntities={groupEntities}
              setGroupEntities={setGroupEntities}
              groupBy={groupBy}
              fieldMode={fieldMode}
              setTemplate={setTemplate}
            />
            <EditButton
              editOption={editOption}
              setEditOption={setEditOption}
            />
          </StaticContainer>
          
      }
    </FlexWrapper>
  )
}

function mapStateToProps({ catalog, cesiumEntity }) {
  // to trigger when changing state management hide / show
  return {
    catalog,
    cesiumEntity
  }
}

const mapDispatchToProps = {
  toggleEntity,
  triggerRender
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailMenu)
