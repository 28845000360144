export const CONNECT_CHAT_SOCKET = 'CONNECT_CHAT_SOCKET'
export const CONNECT_CHAT_SOCKET_SUCCESS = 'CONNECT_CHAT_SOCKET_SUCCESS'
export const DISCONNECT_CHAT_SOCKET = 'DISCONNECT_CHAT_SOCKET'
export const DISCONNECT_CHAT_SOCKET_SUCCESS = 'DISCONNECT_CHAT_SOCKET_SUCCESS'

export const ADD_CHAT_ROOM = 'ADD_CHAT_ROOM'
export const SET_CHAT_ROOMS = 'SET_CHAT_ROOMS'

export const SET_CHAT_ROOM = 'SET_CHAT_ROOM'

export const CHAT_NEW_MESSAGE = 'CHAT_NEW_MESSAGE'

export const RESET_SOCKET = 'RESET_SOCKET'

export const CONNECT_NOTIFICATION = 'CONNECT_NOTIFICATION'
export const SET_CHAT_ROOMS_SUCCESS = 'SET_CHAT_ROOMS_SUCCESS'
export const SET_ROOM_SOCKET = 'SET_ROOM_SOCKET'
export const APPEND_MESSAGES = 'APPEND_MESSAGES'
export const APPEND_TO_CONNECTED_ROOMS = 'APPEND_TO_CONNECTED_ROOMS'
export const SET_USER_SOCKET = 'SET_USER_SOCKET'
export const UPDATE_ROOM_RECIPIENTS = 'UPDATE_ROOM_RECIPIENTS'