import React from 'react'

import FlexWrapper from './FlexWrapper'
import Paragraph from './Paragraph'

import { ellipsisText } from '../helpers'

/**
 * This components will create header for tooltip wrapper
 * @param {String} tooltipName is the name of tooltip
 * @param {String} mapName is the name of the map
 */
function TooltipHeader({
  tooltipName = 'tooltip',
  mapName
}) {
  return (
    <FlexWrapper
      non-scroll
      center
      margin="-0.5em -0.5em 0em -0.5em"
      padding="0.3em 0.5em"
      backgroundColor="black"
      style={{
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5
      }}
    >
      <Paragraph fontSize="1em" color="white">
        {tooltipName} - {
          ellipsisText({
            text: mapName,
            max: 40
          })
        }
      </Paragraph>
    </FlexWrapper>
  )
}

export default TooltipHeader
