import React from 'react'

import FocusContainer, {
  Header,
  LinkText,
  Paragraph
} from '../StyledComponent'
import LoginPNG from '../HelpImage/login.png'
import { LIST_HELP } from '../constant'


function HelpLogin(props) {
  const flyTo=(x)=>{
    props.setFocus(x)
  }

  return <>
    <FocusContainer {...props}>
      <Header>{LIST_HELP['access'].detail.login.header}</Header>
      <Paragraph>
      1. Make sure you have a registered account.
      {/* If not, please <LinkText onClick={()=>flyTo('register')}>register</LinkText> first. */}
      </Paragraph>
      {/* <Paragraph>
        2. Make sure you haven't been already activate your account.
        To activate your account see <LinkText onClick={()=>flyTo('activation')}>Activation Tutorial</LinkText>
      </Paragraph> */}
      <Paragraph>
        2. Make sure to insert correct email and password.
      </Paragraph>
      <img src={LoginPNG} alt="How to Login image" width="1100px" />
      {/* <Paragraph>
        4. If you accidentally forget the password, try <LinkText onClick={()=>flyTo('reset')}>how to reset your password</LinkText>
      </Paragraph> */}
      <Paragraph>
        3. If you have a hard time, feel free to contact our email: admin@geofix-id.com.
      </Paragraph>
    </FocusContainer>
  </>
}

export default HelpLogin
