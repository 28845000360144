import React from 'react'

import ListContent from './ListContent'

/**
 * This component will create numerical content group
 * @param objLegend is current selected objLegend to render
 */
function NumericalContent({ objLegend }) {
  const windowWidth = window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth

  const windowHeight = window.innerHeight
    || document.documentElement.clientHeight
    || document.body.clientHeight

  const DEFAULT_POS_X = windowWidth - 70 // this exact position
  const DEFAULT_POS_Y = windowHeight - 300 // this exact position
  
  return <ListContent
    defaultPosX={DEFAULT_POS_X}
    defaultPosY={DEFAULT_POS_Y}
    direction="row"
    objLegend={objLegend}
  />
}

export default NumericalContent
