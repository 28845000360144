import starGoldIcon from "../../../images/Icons/star-gold.svg"
import starWhiteIcon from "../../../images/Icons/star-white.svg"
import { ReactComponent as DocIcon } from '../../../images/Icons/document.svg'

const HeaderTable=({...props})=>{
    let {
        sortOptions,
        checkedAllDownload,
        setCheckedAllDownload,
        pageOptions,
        pageIndex,
        selectedDownload,
        setSelectedDownload,
        toggleShowFavourite,
        setToggleShowFavourite
    }= props;

    let handleOnClickAllChecked=(checked)=>{
        if(pageOptions.length===0) return
        let arr = [...selectedDownload]
        if(checked){
            pageOptions[pageIndex].map((i)=>{
                if(!selectedDownload.includes(i['paper_id'])){
                    arr.push(i['paper_id'])
                }
            })
            setSelectedDownload(arr)
        } else {
            let removed=[]
            pageOptions[pageIndex].map((i)=>{
                if(selectedDownload.includes(i['paper_id'])){
                    removed.push(i['paper_id'])
                }
            })
            let filtered = arr.filter(n => !removed.includes(n))
            setSelectedDownload(filtered)
        }
    }

    let contentHeader = sortOptions.map((i,idx)=>{
        if(i.pickName==='title' || i.pickName==='authors' ){
            return(
                <th 
                    key={`${idx}-header`} 
                    style={{width:'200px'}}
                >
                    {i.pickShow}
                </th>
            )
        } else {
            return(
                <th 
                key={`${idx}-header`}
                style={{width:'100px'}} >
                    {i.pickShow}
                </th>
            )
        }
    })

    let headComplete=()=>{
        return(
            <thead>
                <tr>
                    <th>
                        <input
                            type='checkbox'
                            style={{cursor:'pointer'}}
                            checked={checkedAllDownload}
                            title='Check all'
                            onChange={()=>{
                                setCheckedAllDownload(!checkedAllDownload)
                                handleOnClickAllChecked(!checkedAllDownload)
                            }}
                        />
                    </th>
                    <th>
                        {toggleShowFavourite?
                            <img src={starGoldIcon}
                            alt='fav-gold'
                            style={{width:'15px', cursor:'pointer'}}
                            title='Turn off sort by favorites'
                            onClick={()=>{
                                setToggleShowFavourite(!toggleShowFavourite)
                            }}
                            />
                            :
                            <img src={starWhiteIcon}
                            alt='fav-white'
                            style={{width:'15px', cursor:'pointer'}}
                            title='Turn on sort by favorites'
                            onClick={()=>{
                                setToggleShowFavourite(!toggleShowFavourite)
                            }}
                            />
                        }
                    </th>
                    <th>
                        <DocIcon className='icon-header-site-references-menu'/>
                    </th>
                    {contentHeader}
                </tr>
            </thead>
        )
    }
    return headComplete()
}

export default HeaderTable;