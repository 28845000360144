export default function getPixel(
  styleWidth,
  fontSize = getComputedStyle(document.documentElement)
    .fontSize.slice(0, -2)
) {
  if (Number(styleWidth) || Number(styleWidth) === 0) {
    return Number(styleWidth)
  } else if (styleWidth.includes('px')) {
    return getPixel(styleWidth.slice(0, -2))
  } else if (styleWidth.includes('rem')) {
    return getPixel(
      styleWidth.slice(0, -3)
      * parseFloat(
        getComputedStyle(document.documentElement)
          .fontSize
      )
    )
  } else if (styleWidth.includes('em')) {
    return getPixel(
      styleWidth.slice(0, -2) * fontSize
    )
  } else {
    throw (new Error(`unsupported format : ${styleWidth} - ${fontSize}`))
  }
}
