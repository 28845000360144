import React, { useState } from 'react'

import axios from 'axios'

import cesiumViewer from '../../../../../../../../apis/cesiumViewer'

import RequestParams from '../../../../../../../../apis/generateParams'

import ReactDatePicker from '../../../../../../../Pickers/DatePicker'

import ReactLoading from 'react-loading'

import styled from 'styled-components'

const LoadingContainer = styled.div`
  overflow: hidden;
  margin-top: 0.8em;
  height: 1.2em;
  width: 1em;
`

let source = axios.CancelToken.source()

/**
 * This component will create filter date for option tooltip ex. earthquake
 * @param {Object} map map data
 * @returns filter date content
 */
function OptionMenuFilterDate({ map }) {
  const [ config, setConfig ] = useState({
    start: new Date(map.mapProperties.startTime),
    end: new Date(map.mapProperties.endTime)
  })

  const [ loading, setLoading ] = useState(false)
  
  function formatDate(date) {
    let formatDate = new Date(date)

    let localDate = formatDate.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    })

    const [ month, day, year ] = localDate.split('/')

    return `${year}-${month}-${day}`
  }

  async function updateByFilter() {
    setLoading(true)

    if (source) {
      source.cancel('')
    }

    source = axios.CancelToken.source()

    map.unloadDataSource()
    map.removeDataSource()
    await map.fetchGeojson({
      params: {
        ...RequestParams.FETCH_GEOJSON_WITHOUT_SUMMARY(),
        cancelToken: source.token
      },
      fetchProgress: () => {},
      fetchFinish: () => {},
      layerType: map.layerType,
    })
    await map.generateDataSource()
    map.loadDataSource({
      cesiumViewer
    })

    setLoading(false)
  }

  return (
    <div className="row" style={{overflow: 'hidden', position: 'relative'}}>
      <div className="col" style={{ width: '6.3em', justifyContent: 'center' }}>
        <ReactDatePicker
          initial="Start"
          date={config && config.start}
          onChangeHandler={(date) => {
            const newDate = formatDate(date)

            map.mapProperties.startTime = newDate
            setConfig({
              ...config,
              start: date
            })

            updateByFilter()
          }}
        />
      </div>
      <div className="col" style={{ width: '6.3em', justifyContent: 'center' }}>
        <ReactDatePicker
          initial="End"
          date={config && config.end}
          onChangeHandler={(date) => {
            const newDate = formatDate(date)

            map.mapProperties.endTime = newDate
            setConfig({
              ...config,
              end: date
            })

            updateByFilter()
          }}
        />
      </div>
      {
        loading && (
          <LoadingContainer>
            <ReactLoading type="spin" color="black" width="1em" />
          </LoadingContainer>
        )
      }
    </div>
  )
}

export default OptionMenuFilterDate
