import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'

import {
  addToDisplay
} from '../../../../store/actions/display'

import axiosReact, { getProgressDownload } from '../../../../apis/axiosReact'

import {
  loadGridMapGeoJSON
} from '../../../../apis/cesiumMap/grid'

import ReactLoading from 'react-loading'

import CustomReactLoading from '../../../Loading/CustomReactLoading'

import styled from 'styled-components'

import {
  WHITE,
  GREYISH_DARK
} from '../../../shares/ColorTemplate'
import cesiumViewer from '../../../../apis/cesiumViewer'

const MiniButton = styled.div`
  cursor: pointer;
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  width: 3em;
  font-size: 0.8em;
  background-color: ${GREYISH_DARK.default};
  color: ${WHITE.default};
  transition: background-color color 2000ms;

  &:hover {
    background-color: ${GREYISH_DARK.hover};
    color: ${WHITE.hover};
  }
`

const StickyContainer = styled.div`
  border: 1px solid black;
  border-radius: 5px;
  padding: 1em;
  position: sticky;
  top: 1em;
`

const ProgressBar = styled.div`
  position: absolute;
  top: 1em;
  right: 1em;
`

function Progress({
  progress,
  userRatings,
  addToDisplay
}) {
  const [ buttonsLoading, setButtonsLoading ] = useState({})

  async function tryLoad({ bin_size, commit_id }) {
    if (!buttonsLoading[bin_size]) {
      setButtonsLoading((buttons) => ({
        ...buttons,
        [bin_size]: {
          status: 'fetch',
          progress: 'Pending...'
        }
      }))

      try {
        const { data } = await axiosReact.get(`/geofix/grid/${bin_size}`, {
          params: {
            commit_id
          },
          onDownloadProgress: (event) => {
            getProgressDownload(event, (progress) => {
              setButtonsLoading((buttons) => ({
                ...buttons,
                [bin_size]: {
                  ...buttons[bin_size],
                  status: 'fetch',
                  progress: progress
                }
              }))
            })
          }
        })

        setButtonsLoading((buttons) => ({
          ...buttons,
          [bin_size]: {
            ...buttons[bin_size],
            status: 'render',
            progress: 'Rendering...'
          }
        }))
  
        const random = Math.random()
  
        const map = {
          id: data.name + random,
          name: data.name + random,
          category: 'grid',
          entityName: data.name + random,
          type: null,
          geom: null,
          filter: null,
          // D3 color
          colorScale: 'interpolateRdYlBu',
          // for color purpose
          inverse: true,
          colorEntities: [],
          alpha: 1,
          defaultColor: {},
          colorScaling: 'linear',
          colorTemplates: [],
          selectedTemplate: null,
          extrudedStatus: false,
          properties: {},
          ellipsoidStatus: false,
          enhance: false
        }
  
        const dataSource = await loadGridMapGeoJSON({
          name: map.entityName,
          map,
          geojson: data.geojson,
          clampToGround: true,
          loadingProgress: ((currentProgress) => {
            if (currentProgress < 100) {
              setButtonsLoading((buttons) => ({
                ...buttons,
                [bin_size]: {
                  ...buttons[bin_size],
                  progress: currentProgress
                }
              }))
            } else {
              setButtonsLoading((buttons) => ({
                ...buttons,
                [bin_size]: null
              }))
            }
          })
        })

        cesiumViewer.viewer.dataSources.add(dataSource)
  
        addToDisplay({
          map
        })
      } catch (error) {
        throw error
      } finally {
        setButtonsLoading((buttons) => ({
          ...buttons,
          [bin_size]: null
        }))
      }
    }
  }

  useEffect(() => {
    setButtonsLoading({})
  }, [userRatings.commit_id])

  return <div className="col" style={{
    width: '15%',
    margin: '1em',
    position: 'relative'
  }}>
    <StickyContainer>
      {
        !progress.finish && userRatings.committer
          &&
            <ProgressBar>
              <ReactLoading color="black" type="spin" width="1em" height="1em" />
            </ProgressBar>
      }
      <h4 style={{
        margin: '0 0 0.5em 0'
      }}>Availability</h4>
      {
        Object.keys(progress.solved).map((bin_size) => {
          return <div className="row" style={{ marginBottom: '0.3em' }} key={bin_size}>
            <span style={{ width: '3em' }}>{bin_size / 1000 + ' km'}</span>
            <MiniButton onClick={() => {
              tryLoad({
                bin_size,
                commit_id: userRatings.commit_id
              })
            }}>
              {
                buttonsLoading[bin_size]
                  ? <CustomReactLoading
                      status={buttonsLoading[bin_size].status}
                      color="white"
                      height="0.75em"
                      width="1.5em"
                      progress={buttonsLoading[bin_size].progress}
                    />
                  : 'Load'
              }
            </MiniButton>
          </div>
        })
      }
      <h4 style={{
        margin: '0.5em 0'
      }}>Process</h4>
      {
        Object.keys(progress.unsolved).map((bin_size) => {
          return <div key={bin_size}>
            {bin_size / 1000 + ' km'} : {progress.unsolved[bin_size]}%
          </div>
        })
      }
    </StickyContainer>
  </div>
}

const mapDispatchToProps = {
  addToDisplay
}

export default connect(null, mapDispatchToProps)(Progress)
