import * as actionTypes from '../../constants/store/actionTypes/socket'

const initialState = {
  chatSocket: null,
  userSocket: null,
  chatRoom: null,
  // chatRooms: [],
  chatRoomsObj: {},
  connectedRooms: [],
  newMessages: {},
  // notification: {}
}

function socketReducer(state = initialState, { type, payload }) {
  switch (type) {
    case actionTypes.CONNECT_CHAT_SOCKET_SUCCESS:
      return {
        ...state,
        chatSocket: payload.chatSocket
      }
    case actionTypes.DISCONNECT_CHAT_SOCKET_SUCCESS:
      return {
        ...state,
        chatSocket: null,
        userSocket: null,
        chatRoom: null,
        // chatRooms: [],
        chatRoomsObj: {},
        connectedRooms: [],
        newMessages: 0,
      }
    case actionTypes.ADD_CHAT_ROOM: {
      // edit it socket to sort
      const { chatRoom } = payload
      // let newChatRooms = state.chatRooms.slice()
      let newState = { ...state }
      if (chatRoom.message_id && !newState.chatRoomsObj[chatRoom.message_id]) {
        newState.chatRoomsObj[chatRoom.message_id] = {
          ...payload.chatRoom,
          last_message: payload.chatRoom.message,
        }
      }
      let newChatRoomObj = {
        ...payload.chatRoom,
        last_message: payload.chatRoom.message
      }
      // newChatRooms.unshift(newChatRoomObj)
      // newChatRooms = newChatRooms.filter((item, idx, arr) => {
      //   return arr.findIndex(el => el.message_id === item.message_id) === idx
      // })

      // newChatRooms.sort((a, b) => {
      //   return new Date(b.last_message.created_at)
      //     - new Date(a.last_message.created_at)
      // })

      newState.chatRoomsObj = {
        ...newState.chatRoomsObj,
        [chatRoom.message_id]: newChatRoomObj
      }
      newState.newMessages[chatRoom.message_id] = 1
      return newState
    }
    case actionTypes.SET_CHAT_ROOMS_SUCCESS: {
      let newState = { ...state }
      function findMissing(arr1, arr2) {
        return arr1.filter(i => !arr2.includes(i))
      }
      let missingIds = findMissing(Object.keys(state.newMessages), Object.keys(payload.chatRoomsObj))
      missingIds.forEach(item => {
        delete newState.newMessages[item]
      })
      return {
        ...newState,
        // chatRooms: payload.chatRooms,
        chatRoomsObj: payload.chatRoomsObj,
        chatSocket: payload.socket
      }
    }
    case actionTypes.SET_CHAT_ROOM: {
      let chatRoom = payload.chatRoom
      let newState = {
        ...state,
        chatRoom,
      }
      if(chatRoom) {
        newState.newMessages[chatRoom.message_id] = 0
      }
      return newState
    }
    case actionTypes.CHAT_NEW_MESSAGE:
      return {
        ...state,
        newMessages: payload.newMessages
      }
    case actionTypes.RESET_SOCKET:
      return {
        ...initialState
      }
    case actionTypes.SET_USER_SOCKET: {
      return { ...state, userSocket: payload.userSocket }
    }
    case actionTypes.APPEND_MESSAGES: {
      let { data, id, overwrite } = payload

      let newState = { ...state }
      if (overwrite) {
        newState.chatRoomsObj[id].messages = data
      } else {
        if (!newState.chatRoomsObj[id].messages) {
          newState.chatRoomsObj[id].messages = []
        }
        let arr = newState.chatRoomsObj[id].messages.concat(data)
        arr = arr.filter((item, idx, array) => {
          return array.findIndex(el => el.id === item.id) === idx
        })
        arr = arr.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
        newState.chatRoomsObj[id].messages = arr
        newState.chatRoomsObj[id].last_message = arr[arr.length - 1]
      }
      if(state?.chatRoom?.message_id !== id) {
        newState.newMessages[id] = newState.newMessages[id] ? newState.newMessages[id] + 1 : 1
      }
      return newState
    }
    case actionTypes.UPDATE_ROOM_RECIPIENTS: {
      const { id, recipients } = payload
      const newState = { ...state }
      if(recipients?.length > 0) {
        newState.chatRoomsObj[id].recipients = recipients
        if(newState?.chatRoom?.message_id === id) {
          newState.chatRoom.recipients = recipients
        }
      } else {
        delete newState.chatRoomsObj[id]
      }
      return newState
    }
    case actionTypes.APPEND_TO_CONNECTED_ROOMS: {
      return {
        ...state,
        connectedRooms: Array.from(new Set([...state.connectedRooms, payload]))
      }
    }
    default:
      return state
  }
}

export default socketReducer
