import React from 'react'

import FocusContainer, {
  Header,
  Paragraph
} from '../../StyledComponent'
import DrillholePNG from '../../HelpImage/database-drillhole(1).png'
import DrillholeFailedPNG from '../../HelpImage/database-drillhole(2)-failed.png'
import DrillholeMapPNG from '../../HelpImage/database-drillhole(2)-map.png'
import DrillholeModelPNG from '../../HelpImage/database-drillhole(2)-no-map.png'
import { LIST_HELP } from '../../constant'


function HelpDrillhole(props) {
  return <>
    <FocusContainer {...props}>
      <Header>{LIST_HELP['menu'].detail.drillhole.header}</Header>
      <Paragraph>
      What's displayed for drilling data is a 3d model of the data, in this modeling it can be seen on a map or without a map.
      </Paragraph>
      <img src={DrillholePNG} alt="Drillhole image" width="1100px"/>
      <Paragraph style={{marginTop:"1em"}}>
      Modeling results with maps
      </Paragraph>
      <img src={DrillholeMapPNG} alt="Drillhole image" width="1100px" />
      <Paragraph style={{marginTop:"1em"}}>
        Or without map
      </Paragraph>
      <img src={DrillholeModelPNG} alt="Drillhole image" width="1100px" />
      <Paragraph style={{marginTop:"1em"}}>
      If the model calculation is not found or there is an error in the calculation, an error will be displayed
      </Paragraph>
      <img src={DrillholeFailedPNG} alt="Drillhole image" width="1100px" />
    </FocusContainer>
  </>
}

export default HelpDrillhole
