import * as Cesium from 'cesium'

export function flatteningPropertiesRec(properties, newProperties) {
  for (let key in properties) {
    if (typeof properties[key] === 'object') {
      flatteningPropertiesRec(properties[key], newProperties)
    } else {
      newProperties[key] = properties[key]
    }
  }
}

export function destructuringProperties(properties) {
  if (!properties) {
    return properties
  }
  
  const newProperties = {}

  flatteningPropertiesRec(properties, newProperties)

  return newProperties
}

export function toggle3D({ entities, map }) {
  try {
    
    if (entities[0].point) { // change point into ellipsoid
      const radii = new Cesium.Cartesian3(
        map.pixelSize,
        map.pixelSize,
        map.pixelSize
      )

      for (let i = 0; i < entities.length; i++) {
        const entity = entities[i]
  
        if (entity.point) {
          entity.point = undefined
        }
  
        const colorMaterialProperty = new Cesium.ColorMaterialProperty()
        // colorMaterialProperty.color = new Cesium.CallbackProperty(
        //   () => {
        //     return new Cesium.Color.fromAlpha(
        //       new Cesium.Color(
        //         map.colorEntities[entity.id].red,
        //         map.colorEntities[entity.id].green,
        //         map.colorEntities[entity.id].blue
        //       ),
        //       map.colorEntities[entity.id].alpha
        //     )
        //   },
        //   false
        // )
        colorMaterialProperty.color = new Cesium.Color.fromAlpha(
          new Cesium.Color(
            map.colorEntities[entity.id].red,
            map.colorEntities[entity.id].green,
            map.colorEntities[entity.id].blue
          ),
          map.colorEntities[entity.id].alpha
        )
  
        entity.ellipsoid = new Cesium.EllipsoidGraphics({
          radii,
          material: colorMaterialProperty,
          shadows: Cesium.ShadowMode.ENABLED,
          heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND
        })
      }
    } else if (entities[0].ellipsoid) {  // change ellipsoid into point
      for (let i = 0; i < entities.length; i++) {
        const entity = entities[i]
  
        if (entity.ellipsoid) {
          entity.ellipsoid = undefined
        }
  
        entity.point = new Cesium.PointGraphics({
          color: new Cesium.CallbackProperty(
            () => {
              return new Cesium.Color.fromAlpha(
                new Cesium.Color(
                  map.colorEntities[entity.id].red,
                  map.colorEntities[entity.id].green,
                  map.colorEntities[entity.id].blue
                ),
                map.colorEntities[entity.id].alpha
              )
            },
            false
          ),
          pixelSize: map.pixelSize,
          disableDepthTestDistance: map.disableDepthTestDistance,
          scaleByDistance: map.scaleByDistance
        })
      }
    }
  } catch (error) {
    throw error
  }
  
}