import React from 'react'

import FocusContainer, {
  Header,
  Paragraph
} from '../StyledComponent'
import LogoutChat from '../HelpImage/logout.png'
import { LIST_HELP } from '../constant'


function HelpLogout(props) {
  return <>
    <FocusContainer {...props}>
      <Header>{LIST_HELP['dashboard'].detail.logoutButton.header}</Header>
      <img src={LogoutChat} alt="Map image" width="1000px" />
      <Paragraph>
      You can log out of your account by clicking on account icon or your username.
      </Paragraph>
    </FocusContainer>
  </>
}

export default HelpLogout
