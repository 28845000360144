import React, { useEffect, useRef, useState } from 'react'

import {
  ReactComponent as TriangleSelected
} from '../../../images/Icons/soft-triangle-selected.svg'

import {
  ReactComponent as TriangleUnselected
} from '../../../images/Icons/soft-triangle-unselected.svg'

import styled from 'styled-components'

const TableHead = styled.th`
  font-family: Abel;
  font-size: 0.8em;
  padding: 0.5rem 0.5rem;
  user-select: none;
`

/**
 * This function will generate table header content
 * @param {Array} headers is the list of header
 * @param {Array} listSort is the list status being sorted or not
 * @param {Function} setListSort is the method to change listSort
 * @param {Object} tableConfig is the state of table
 * @param {Function} setTableConfig to change table config
 * @returns header table component
 */
function Header({
  listSort,
  setListSort,
  tableConfig,
  setTableConfig,
  setSortableItems,
  properties
}) {
  const headerRef = useRef(null)
  const [ indexSort, setIndexSort ]=useState(null);

  const [ resizeRowIndex, setResizeRowIndex ] = useState(null)
  const [ initialResizeWindow, setInitialResizeWindow ] = useState(null)

  function startResize(event, index) {
    setResizeRowIndex(index)
    let rowWidth = 0
    let currentWidth = tableConfig.rowWidth.modified[index]

    if (currentWidth) {
      rowWidth = Number(
        currentWidth.split('+')
          [1]
          .slice(0, -3)
      )
    }

    setInitialResizeWindow(event.screenX - rowWidth)
  }

  useEffect(() => {
    function resizeHeader(event) {
      setTableConfig((tableConfig) => {
        const newRowWidth = tableConfig.rowWidth.modified.slice()
  
        let resize = -Number(initialResizeWindow) + Number(event.screenX)
  
        newRowWidth[resizeRowIndex] = `calc(4em + ${resize}px)`
  
        return {
          ...tableConfig,
          rowWidth: {
            ...tableConfig.rowWidth,
            modified: newRowWidth
          }
        }
      })
    }
  
    function stopResize() {
      setResizeRowIndex(null)
      setInitialResizeWindow(null)
    }

    if (resizeRowIndex !== null) {
      document.addEventListener('mousemove', resizeHeader)
      document.addEventListener('mouseup', stopResize)
    }

    return () => {
      document.removeEventListener('mousemove', resizeHeader)
      document.removeEventListener('mouseup', stopResize)
    }
  }, [resizeRowIndex])

  useEffect(() => {
    if (headerRef.current) {
      setTableConfig({
        ...tableConfig,
        width: headerRef.current.getBoundingClientRect().width
      })
    }
  }, [headerRef && headerRef.current])
  
  useEffect(()=>{
    let items = [...properties];
    if(indexSort !== null){
      items.sort((a, b) => {
        const key=Object.keys(a)[indexSort]
        if(a[key] && b[key]){
          if (a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ) {
            return listSort[indexSort].asc ? -1 : 1;
          }
          if (a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ) {
            return listSort[indexSort].asc ? 1 : -1;
          }
        }
        return 0;
      });
    }
    setSortableItems(items)
  },[indexSort, listSort])

  return (
    <thead
      style={{
        display: 'block'
      }}
      ref={headerRef}
    >
      <tr
        style={{
          background: 'white',
          borderRadius: 0
        }}
      >
        {
          tableConfig.headers
          && tableConfig.headers
            .map((header, index) => {
              return (
                <TableHead key={index}>
                  <div
                    style={{
                      position: 'relative',
                      display: 'flex',
                      cursor: 'pointer',
                      justifyContent: 'space-between'
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        width: tableConfig.rowWidth.modified[index] || tableConfig.rowWidth.default + 'em',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                      title={header}
                    >
                      {header}
                    </p>
                    <div
                      style={{
                        width: '1em',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                      }}
                    >
                      {
                        listSort[index] && listSort[index].asc
                          ?
                            <TriangleSelected
                              style={{
                                height: '0.5em'
                              }}
                              onClick={() => {
                                const newListSort = listSort.slice()

                                newListSort[index] = {
                                  asc: false,
                                  desc: false
                                }
                                
                                setListSort(newListSort)
                                setIndexSort(null)
                                setSortableItems(properties)
                              }}
                            />
                          :
                            <TriangleUnselected
                              style={{
                                height: '0.5em'
                              }}
                              onClick={() => {
                                let x=[]
                                for (var i=0 ; i < listSort.length;i++){
                                  x.push({
                                    asc: false,
                                    desc: false
                                  })
                                }
                                const newListSort = x.slice()

                                newListSort[index] = {
                                  asc: true,
                                  desc: false
                                }
                                
                                setListSort(newListSort)
                                setIndexSort(index)
                              }}
                            />
                              
                      }
                      {
                        listSort[index] && listSort[index].desc
                          ?
                            <TriangleSelected
                              style={{
                                height: '0.5em',
                                transform: 'rotate(180deg)'
                              }}
                              onClick={() => {
                                const newListSort = listSort.slice()

                                newListSort[index] = {
                                  asc: false,
                                  desc: false
                                }
                                
                                setListSort(newListSort)
                                setIndexSort(null)
                                setSortableItems(properties)
                              }}
                            />
                          :
                            <TriangleUnselected
                              style={{
                                height: '0.5em',
                                transform: 'rotate(180deg)'
                              }}
                              onClick={() => {
                                let x=[]
                                for (var i=0 ; i < listSort.length;i++){
                                  x.push({
                                    asc: false,
                                    desc: false
                                  })
                                }
                                const newListSort = x.slice()

                                newListSort[index] = {
                                  asc: false,
                                  desc: true
                                }
                                
                                setListSort(newListSort)
                                setIndexSort(index)
                              }}
                            />
                      }
                    </div>
                    <div
                      style={{
                        position: 'absolute',
                        marginTop: '-0.6em',
                        right: '-1em',
                        width: '0.5em',
                        height: '2.6em',
                        cursor: 'ew-resize',
                        zIndex: 10
                      }}
                      onMouseDown={(event) => startResize(event, index)}
                    >
                    </div>
                  </div>
                </TableHead>
              )
            })
        }
      </tr>
    </thead>
  )
}

export default Header
