import {
  findEntityAgeByType
} from '../../../../components/helpers/geologicalTimeScale'

/**
 * This function will sort by selected sort
 * @param groupEntities array of entities that have been filtered
 * @param sortBy is the selected type of sort
 * @param groupBy is the selected filter
 * @return the entities that have been sorted
 */
export function sortGroupEntities({
  groupBy = 'none',
  sortBy = 'none',
  groupEntities
}) {
  if (!groupBy || groupBy === 'none' || sortBy === 'none') {
    return groupEntities
  }

  if (sortBy === 'a-z' || sortBy === '1-9') {
    const keys = groupEntities.keys.sort((a, b) => {
      if (a.value > b.value) {
        return 1
      } else {
        return -1
      }
    })
    return {
      ...groupEntities,
      keys
    }
  } else if (sortBy === 'z-a' || sortBy === '9-1') {
    const keys = groupEntities.keys.sort((a, b) => {
      if (a.value < b.value) {
        return 1
      } else {
        return -1
      }
    })
    return {
      ...groupEntities,
      keys
    }
  }

  return groupEntities
}

/**
 * This function will sort by selected sort of geological data
 * @param entities array of entities that have been filtered
 * @param sortBy is the selected type of sort
 */
export function sortGeologicalEntities({
  sortBy,
  entities
}) {
  if (!sortBy || sortBy === 'a-z') {
    return entities
      .sort((a, b) => {
        if (a[0].name > b[0].name) {
          return 1
        } else {
          return -1
        }
      })
  } else if (sortBy === 'z-a') {
    return entities
      .sort((a, b) => {
        if (a[0].name > b[0].name) {
          return -1
        } else {
          return 1
        }
    })
  } else if (sortBy === 'ageAsc') {
    findEntityAgeByType({
      entity: entities[0][0],
      typeKey: 'beginAge'
    })

    return entities
      .sort((a, b) => {
        const aAge = findEntityAgeByType({
          entity: a[0],
          typeKey: 'beginAge'
        }).indexBeginAge
        const bAge = findEntityAgeByType({
          entity: b[0],
          typeKey: 'beginAge'
        }).indexBeginAge

        if (aAge > bAge) {
          return -1
        } else {
          return 1
        }
    })
  } else if (sortBy === 'ageDesc') {
    return entities
      .sort((a, b) => {
        const aAge = findEntityAgeByType({
          entity: a[0],
          typeKey: 'beginAge'
        }).indexBeginAge
        const bAge = findEntityAgeByType({
          entity: b[0],
          typeKey: 'beginAge'
        }).indexBeginAge

        if (aAge < bAge) {
          return -1
        } else {
          return 1
        }
    })
  }

  return entities
}

/**
 * This function will sort by selected sort of general data
 * @param entities array of entities that have been filtered
 * @param sortBy is the selected type of sort
 * @param groupBy is the selected group by
 */
export function sortGeneralEntities({
  groupBy,
  sortBy,
  entities
}) {
  if (groupBy && groupBy !== 'none') {
    return entities
  }

  if (!sortBy || sortBy === 'a-z') {
    return entities
      .sort((a, b) => {
        if (
          a[0].properties.getValue('')[groupBy]
          > b[0].properties.getValue('')[groupBy]
        ) {
          return 1
        } else {
          return -1
        }
      })
  } else if (sortBy === 'z-a') {
    return entities
      .sort((a, b) => {
        if (
          a[0].properties.getValue('')[groupBy]
          > b[0].properties.getValue('')[groupBy]
        ) {
          return -1
        } else {
          return 1
        }
    })
  }

  return entities
}
