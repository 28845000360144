import store from '../../store'

import cesiumViewer from '../../apis/cesiumViewer'

/**
 * This method used to rendering based on order position in display
 */
function resetEntities() {
  try {
    if (!cesiumViewer.viewer) {
      return
    }
  
    const display = store.getState().display
  
    for (let i = display.maps.length - 1; i >= 0; i--) {
      const map = display.maps[i]
  
      if (map.dataSource) {
  
        cesiumViewer.viewer.dataSources.remove(map.dataSource)
        cesiumViewer.viewer.dataSources.add(map.dataSource)
      }
    }
  } catch (error) {
  }
}

export default resetEntities
