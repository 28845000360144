import styled from 'styled-components'

const Paragraph = styled.div`
  margin: 0;
  font-family: Abel;
  margin-left: ${props => props.marginLeft};
  font-size: ${props => props.fontSize};
  color: ${props => props.color};
  cursor: default;
  user-select: none;
`

export default Paragraph
