import React, { useState, useRef, useEffect } from 'react'

function ScrollBottom({
  count,
  bodies,
  limit,
  newMessage,
  isAtBottom
}) {
  const [ initial, setInitial ] = useState(true)
  const endMessageRef = useRef(null)

  function scrollToBottom(behavior) {
    if (endMessageRef.current) {
      if (endMessageRef.current.scrollIntoView) {
        endMessageRef.current.scrollIntoView({
          behavior
        })
      }
    }
  }

  useEffect(() => {
    if (count === limit || count === bodies.length) {
      if (initial) {
        scrollToBottom()
        setInitial(false)
      }
    }
  }, [count, bodies])

  useEffect(() => {
    scrollToBottom()
  }, [newMessage])

  useEffect(() => {
    if (isAtBottom) {
      scrollToBottom('smooth')
    }
  }, [bodies])

  return <div ref={endMessageRef}></div>
}

export default ScrollBottom
