import * as actionTypes from '../../constants/store/actionTypes/catalogSearch'

export function setCatalogSearch(payload) {
  return {
    type: actionTypes.SET_CATALOG_SEARCH,
    payload
  }
}

export function setCatalogSearchSuccess(payload) {
  return {
    type: actionTypes.SET_CATALOG_SEARCH_SUCCESS,
    payload
  }
}

export function setCatalogSearchError(payload) {
  return {
    type: actionTypes.SET_CATALOG_SEARCH_ERROR,
    payload
  }
}

export function resetCatalogSearch(payload) {
  return {
    type: actionTypes.RESET_CATALOG_SEARCH,
    payload
  }
}
