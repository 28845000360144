import * as uiActionTypes from '../../constants/store/actionTypes/uiActionTypes'

export function startAction(name, params) {
  return {
    type: uiActionTypes.START_ACTION,
    payload: {
      action: {
        name,
        params
      }
    }
  }
}

export function stopAction(name) {
  return {
    type: uiActionTypes.STOP_ACTION,
    payload: { name }
  }
}

export function refreshActionStart(refreshAction) {
  return {
    type: uiActionTypes.REFRESH_ACTION_START,
    payload: { refreshAction }
  }
}

export function refreshActionStop(refreshAction) {
  return {
    type: uiActionTypes.REFRESH_ACTION_START,
    payload: { refreshAction }
  }
}
