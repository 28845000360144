import styled from "styled-components";

export const ContainerHeaderAnalysis=styled.div `
    font-family: abel;
    background-color: #222222;
    overflow:hidden;
    height: 100%;
    padding: 0.2em;
    border-radius:8px;  
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
`;

export const ContainerHeaderColumn=styled.div`
    display:flex;
    flex-direction: column;
`;

export const ContainerImageHeader=styled.img`
    opacity: 1;
    width:1em;
    left:0em;
`;

export const TitleHeaderSet=styled.p`
    /* border:1px solid gray;
    border-radius:4px 4px 4px 4px; */
    padding:0;
    padding-left:0.8em;
    font-size: 1em;
    margin:0.3em;
`

export const HeaderInformationContent=styled.div`
    text-align: center;
    color: #c9c9c9;
    margin:0;
    width:100%;
`;

export const HeaderInformationTitle=styled.div`
    text-align: center;
    font-size: 0.9em;
    white-space: nowrap;
    border-bottom: 1.5px solid rgba(119, 89, 13);
    /* font-weight: bold; */
    color:white;
    font-family: Abel;
    cursor: move;
`;

export const NumberContent = styled.div`
    display: inline;
    margin-top: 0.2em;
    font-size: ${props=> props.size ? props.size : '1.5em'};
`