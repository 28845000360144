import React, { useEffect, useState } from 'react'

import Navbar from '../../components/Menubars/Navbar'

import { LIST_HELP } from './constant'

import DefaultSearch from '../../components/SearchBoxs/DefaultSearch'

import HelpList from './HelpList'

import styled from 'styled-components'
import { Header, HeaderSearch, Paragraph } from './StyledComponent'
import HelpListSearch from './HelpListSearch'

const HelperBar = styled.div`
  width: 15em;
  height: calc(100vh - 44px);
  overflow-y: auto;
  background-color: #2e2e2e;
`

const HelperContent = styled.div`
  width: calc(100vw - 15em);
  height: calc(100vh - 44px - 28px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`
const HelperRight = styled.div`
  width: calc(100vw - 15em);
  height: calc(100vh - 44px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`

function Help() {
  const [ opened, setOpened ] = useState([])
  const [ focus, setFocus ] = useState('')
  const [ allHelpList, setAllHelpList ] = useState ([])
  const [ helpList, setHelpList ] = useState([])
  const [ helpListDetail, setHelpListDetail ] = useState([])
  const [ keySearch, setKeySearch ] = useState('')


  const backHelp = () =>{
    setOpened([])
    setFocus('')
  }

  useEffect(() =>{
    const allNewHelpList=[]
    for (let key in LIST_HELP) {
      allNewHelpList.push(key)
    }
    setAllHelpList(allNewHelpList)

  },[])


  return <>
    <div className="container col max-width">
      <Navbar />
      <div className="row">
        <HelperBar>
          <div style={{
            width: '13em',
            padding: '1.9em 1em 1em 1em'
          }}>
            <Paragraph style={{color:'goldenrod', fontSize:'1.4em', fontWeight:'600', cursor:'pointer'}} onClick={()=>backHelp()}>
              Help Center
            </Paragraph>
            <hr style={{color:'black'}}/>
            {/* <hr style={{color:'goldenrod', marginTop:'-5px'}}/> */}
          </div>
          {
            allHelpList.map((availableHelp, index) => {
              return (
                <HelpList
                  key={index}
                  helpKey={availableHelp}
                  opened={opened}
                  setOpened={setOpened}
                  focus={focus}
                  setFocus={setFocus}
                />
              )
            })
          }
        </HelperBar>
        <HelperRight>
          <HeaderSearch>
              <DefaultSearch
                active={true}
                searchProcess={(keyword) => {
                  if (!keyword) {
                    setKeySearch('')
                    const newHelpList = []
                    const newHelpListDetail = []
                    
                    for (let key in LIST_HELP) {
                      newHelpList.push(key)
                      Object.keys(LIST_HELP[key].detail).map((keyDetail,index) => {
                        newHelpListDetail.push({
                          index: index,
                          key : key,
                          keyDetail : keyDetail
                        })
                      })
                    }
                    setHelpList(newHelpList)
                    setHelpListDetail(newHelpListDetail)
                  } else {
                    setKeySearch(keyword)
                    const newHelpList = []
                    const newHelpListDetail = []
                    const regex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g 
                    
                    for (let key in LIST_HELP) {
                      Object.keys(LIST_HELP[key].detail).map((keyDetail,index) => {
                        if(LIST_HELP[key].detail[keyDetail].header.toLowerCase().replace(regex,'').replace(/ |_/g,'').includes(keyword.toLowerCase().replace(regex,'').replace(/ |_/g,''))){
                          newHelpList.push(key)
                          newHelpListDetail.push(
                            {
                              index: index,
                              key: key,
                              keyDetail: keyDetail
                            })
                        }
                      })
                    }
                    const noduplicate=newHelpList.filter((v,i)=> newHelpList.indexOf(v) === i);
                    setHelpList(noduplicate)
                    setHelpListDetail(newHelpListDetail)

                  }
                }}
                stopSearch={() => {
                  const newHelpList = []
                  const newHelpListDetail = []
                  
                  for (let key in LIST_HELP) {
                    newHelpList.push(key)
                    Object.keys(LIST_HELP[key].detail).map((keyDetail,index) => {
                      newHelpListDetail.push({
                        index: index,
                        key : key,
                        keyDetail : keyDetail
                      })
                    })
                  }
                  setKeySearch('')
                  setHelpList(newHelpList)
                  setHelpListDetail(newHelpListDetail)
                }}
              />
          </HeaderSearch>
          <HelperContent>
          {
            opened.length > 0
            ?
            opened.map((keyTutorial) => {
              const { detail } = LIST_HELP[keyTutorial]
      
              return Object.keys(detail).map((key) => {
                return detail[key].Component(key, focus, setFocus, opened, setOpened)
              })
            })
            : 
            <>
            <Header style={{textAlign: 'center', margin: '0em', padding: '0em 0em 0.5em 0em', fontSize:'3em', textShadow:'1px 2px lightgray'}}> 
              How can we help you?
            </Header>
            <div style={{display:'grid',gridTemplateColumns:'1fr 1fr', margin:'0em 1.5em'}}>
            {
              helpList.map((availableHelp, index) => {
                return (
                  <HelpListSearch
                    key={index}
                    keySearch={keySearch}
                    helpKey={availableHelp}
                    detail={helpListDetail}
                    focus={focus}
                    setFocus={setFocus}
                    opened={opened}
                    setOpened={setOpened}
                  />
                )
              })
            }
            </div>
            </>
          }
          </HelperContent>
        </HelperRight>
      </div>
    </div>
  </>
}

export default Help
