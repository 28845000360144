import React from 'react'

import LayerbarIcon from '../../../shares/LayerbarIcon'

import {
  ReactComponent as TrashIcon
} from '../../../../../../images/Icons/trash.svg'

function RemoveButton({
  onClickHandler
}) {
  return (
    <LayerbarIcon danger isActive no-margin>
      <TrashIcon
        title="Remove Map"
        onClick={onClickHandler}
      />
    </LayerbarIcon>
  )
}

export default RemoveButton