import * as Cesium from 'cesium'

export function generatePolygonOutline(geojson, dataSource) {
  for (let i = 0; i < geojson.features.length; i++) {
    const feature = geojson.features[i]

    const coordinates = feature.geometry.coordinates
    const properties = feature.properties

    for (let j = 0; j < coordinates.length; j++) {
      const entity = dataSource.entities.add({
        polyline: new Cesium.PolylineGraphics({
          positions: new Cesium.Cartesian3.fromDegreesArray(
            coordinates[j][0].flat(3)
          ),
          clampToGround: true
        }),
        properties
      })

      const colorMaterialProperty = new Cesium.ColorMaterialProperty()
      colorMaterialProperty.color = Cesium.Color.RED

      entity.polyline.material = colorMaterialProperty
    }
  }
}
