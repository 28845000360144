import React from 'react'

import FlexWrapper from './FlexWrapper'

import {
  HOLLOW_GREY
} from '../../../shares/ColorTemplate'

/**
 * This componnent will add section for icon
 * @param children is the children of the component 
 */
function TooltipIconWrapper({
  children
}) {
  return (
    <FlexWrapper
      non-scroll
      center
      backgroundColor={HOLLOW_GREY.default}
      style={{
        marginLeft: '-0.5em',
        marginRight: '-0.5em',
        padding: '0.3em 0.5em',
        height: '1em'
      }}
    >
      {
        children
      }
    </FlexWrapper>
  )
}

export default TooltipIconWrapper
