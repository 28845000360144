import React, { useRef, useState } from 'react'

import axios from 'axios'

import { connect } from 'react-redux'
import {
  toggleSidebarButton
} from '../../../store/actions/sidebarToggle'
import {
  setAdvancedSearch,
  setAdvancedSearchOptions,
  resetAdvancedSearchOptions
} from '../../../store/actions/advancedSearch'

import * as Cesium from 'cesium'

import cesiumViewer from '../../../apis/cesiumViewer'
import { advancedSearch } from '../../../apis/server'
import { COMPOSITE_NAMES } from '../../../constants/Server/search'

import SidebarContainer from '../../shares/SidebarContainer'
import SearchBox from './Search/SearchBox'
import SearchRecommendation from './Search/SearchRecommendation'

import { ReactComponent as LeftIcon } from '../../../images/Icons/left.svg'

import './Searchbar.css'

function Searchbar(props) {
  const {
    sidebarState,
    toggleSidebarButton,
    limit,
    currentPage,
    searchKeyword,
    setAdvancedSearch,
    setAdvancedSearchOptions,
    resetAdvancedSearchOptions
  } = props

  let source = null

  const containerRef = useRef(null)

  function setDataDefault() {
    const objData = {}

    COMPOSITE_NAMES.forEach((alias) => {
      objData[alias] = []
    })

    return objData
  }

  const [ data, setData ] = useState(setDataDefault())
  const [ loading, setLoading ] = useState(false)
  const [ keyword, setKeyword ] = useState('')
  const [ dataSource, setDataSource ] = useState(null)
  const [ selected, setSelected ] = useState ('')

  function selectHandler({
    newSelectedKeyword,
    alias,
    search
  }) {
    setLoading(false)
    let selectedKeyword = newSelectedKeyword

    if (selectedKeyword === searchKeyword) {
      selectedKeyword += ' '
    }

    setAdvancedSearchOptions({
      alias,
      search,
      searchKeyword: selectedKeyword
    })
  }

  async function generateDataSource(geojson) {
    removeDataSource()

    const newDataSource = await new Cesium.GeoJsonDataSource('advanced_search')
      .load(
        geojson, {
          clampToGround: true,
          fill: Cesium.Color.WHITE.withAlpha(0.2),
          stroke: Cesium.Color.RED,
          strokeWidth: 2
        }
      )

    let pointCoordinates = null

    newDataSource.entities.values.forEach(entity => {
      if (entity.polygon) {
        const polygonHierarchy = entity.polygon.hierarchy
          .getValue(Cesium.JulianDate.now(), {})

        const polylineMaterialProperty = new Cesium.ColorMaterialProperty()
        polylineMaterialProperty.color = Cesium.Color.RED

        entity.polyline = new Cesium.PolylineGraphics({
          positions: polygonHierarchy.positions,
          clampToGround: true,
          material: polylineMaterialProperty,
          width: 2
        })
      } else if (entity.billboard) {
        const cartesian = entity.position
          .getValue(Cesium.JulianDate.now(), {})
        const cartographic = Cesium.Cartographic.fromCartesian(cartesian)
        cartographic.height = 15000
        pointCoordinates = Cesium.Cartographic.toCartesian(cartographic)
      }
    })

    if (newDataSource.entities.values)
    
    cesiumViewer.viewer.dataSources.add(newDataSource)

    setDataSource(newDataSource)

    if (!pointCoordinates) {
      cesiumViewer.viewer.flyTo(newDataSource.entities.values, {
        offset: {
          heading : Cesium.Math.toRadians(0.0),
          pitch : Cesium.Math.toRadians(-90.0),
          roll : 1.0
        }
      })
    } else {
      cesiumViewer.viewer.camera.flyTo({
        destination: pointCoordinates
      })
    }
  }
  
  function removeDataSource() {
    if (dataSource) {
      if (
        cesiumViewer.viewer.dataSources
          .indexOf(dataSource) !== -1
      ) {
        cesiumViewer.viewer.dataSources.remove(dataSource)
      }
    }
  }

  function convertData(data) {
    const result = setDataDefault()

    if (!data?.results?.length) {
      return result
    }

    for (let i = 0; i < data.results.length; i++) {
      result[data.results[i].alias].push({
        name: data.results[i].name,
        alias: data.results[i].alias,
        detail: data.results[i].column_name
      })
    }

    return result
  }

  async function searchProcessHandler(searchKeyword) {
    try {
      if (!searchKeyword) {
        setKeyword('')
        removeDataSource()
        resetAdvancedSearchOptions()
        
        return
      } else if (searchKeyword === keyword) {
        return
      }
      setKeyword(searchKeyword)
      setLoading(true)

      if (source) {
        source.cancel('')
      }

      source = axios.CancelToken.source()

      const response = await advancedSearch({
        name: searchKeyword,
        alias: '',
        limit,
        offset: 0,
        cancelToken: source.token
      })

      const listData = convertData(response.data)
      const properties = response.data?.properties

      if (!properties) {
        removeDataSource()
        resetAdvancedSearchOptions()
        setData([])
        return
      }

      setAdvancedSearchOptions({
        total: properties.total_count,
        currentPage: 1,
        offset: 0,
        totalPage: properties.total_page
      })
      
      setData(listData)
      // Error will be handled with other component
      return {
        status: true
      }
    } catch (error) {
      // purposed error
    } finally {
      setLoading(false)
    }
  }

  async function findPageProcessHandler(page) {
    try {
      if (!page) {        
        return
      }

      if (source) {
        source.cancel('')
      }

      source = axios.CancelToken.source()

      const response = await advancedSearch({
        name: keyword,
        alias: '',
        limit,
        offset: (page - 1) * limit,
        cancelToken: source.token
      })

      const listData = convertData(response.data)
      const properties = response.data.properties

      setAdvancedSearchOptions({
        total: properties.total_count,
        currentPage: page,
        offset: properties.current_page_count,
        totalPage: properties.total_page
      })
      
      setData(listData)
      // Error will be handled with other component
      return {
        status: true
      }
    } catch (error) {
      // purposed error
    } finally {
      setLoading(false)
    }
  }

  function resetSearchHandler() {
    setKeyword('')
    setData(setDataDefault())
    setLoading(false)
    setAdvancedSearch({
      search:"",
      searchKeyword:"",
      searchLevel:"",
      error:null
    })
    setSelected('')
    removeDataSource()
    resetAdvancedSearchOptions()
  }

  return <>
    <SidebarContainer status={sidebarState.search.status}>
      <div 
        className="col max-width" 
        style={{
          height: 'calc(100% - 1em)'
        }}
        ref={containerRef}
      >
        <div className="row align-center m-20">
          <SearchBox
            setData={setData}
            setDataDefault={setDataDefault}
            keyword={keyword}
            setKeyword={setKeyword}
            setLoading={setLoading}
            dataSource={dataSource}
            setDataSource={setDataSource}
            removeDataSource={removeDataSource}
            setSelected={setSelected}
            searchProcessHandler={searchProcessHandler}
            resetSearchHandler={resetSearchHandler}
          />
          <LeftIcon
            className="close-menubar-toggle"
            onClick={
              sidebarState.search.status
                ? () => toggleSidebarButton({
                  buttonName: 'search',
                  status: !sidebarState.search.status,
                  animate: true
                })
                : null
            }
          />
        </div>
        <div
          className="search-content-box"
          style={{color:'white'}}
        >
          {
            <SearchRecommendation
              parentRef={containerRef}
              data={data}
              loading={loading}
              selectHandler={selectHandler}
              generateDataSource={generateDataSource}
              keyword={keyword}
              selected={selected}
              setSelected={setSelected}
              findPageProcessHandler={findPageProcessHandler}
            />
          }
        </div>
      </div>
    </SidebarContainer>
  </>
}

function mapStateToProps({ sidebarState, advancedSearch }) {
  return {
    sidebarState: sidebarState,
    searchKeyword: advancedSearch.searchKeyword,
    limit: advancedSearch.limit,
    currentPage: advancedSearch.currentPage
  }
}

const mapDispatchToProps = {
  toggleSidebarButton,
  setAdvancedSearch,
  setAdvancedSearchOptions,
  resetAdvancedSearchOptions
}

export default connect(mapStateToProps, mapDispatchToProps)(Searchbar)
