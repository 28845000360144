import * as Cesium from 'cesium'
import * as d3 from 'd3'

import {
  creatingDataSource
} from '../cesium'

/**
 * INPUT:
 * data => [
 *   { bin_size: Number, id: Number, name: String }
 * ]
 * 
 * Objectives filtering less than 1 KM
 * 
 * OUTPUT:
 * [
 *   { bin_size: Number, id: Number, name: String }
 * ]
 */
export function parseFetch({ data }) {
  // lock until grid 500m ready
  data.forEach((element) => {
    const binSize = Number(element.bin_size)
    let name = ''

    if (binSize >= 1000) {
      name = `${binSize / 1000} x ${binSize / 1000} km` 
    } else {
      name = null
      // name = `${binSize} x ${binSize} m`
    }

    element.id = element.bin_size
    element.name = name
  })

  return data.filter((data) => data.name)
}

/**
 * This function will score grid to generate color
 * @param {Boolean} inverse is state of color being inversed
 * @param {Number} score is the basic score rating from 1 - 10
 * @returns {Number} the value to used for generate d3 color
 */
export function scoreGrid({
  inverse,
  final_total,
  currentMax
}) {
  let currentScore = final_total / currentMax

  if (!inverse) {
    return currentScore
  } else {
    return 1 - currentScore
  }
}

export function getColor(feature, {
  map,
  features
}) {
  // if (map)
  let final_total = feature
    .rating
    .value

  if (!final_total) {
    final_total = feature
      .score
      .final_total
  }

  if (final_total < 0) {
    final_total = 0
  }

  const score = scoreGrid({
    inverse: map.cesiumLayer.inverse,
    final_total,
    currentMax: getCurrentMax({ entities: features })
  })

  const [ red, green, blue ] = d3
    [map.colorScale](score)
    .slice(4, -1)
    .split(', ')
  
  return {
    red,
    green,
    blue
  }
}

export function getCurrentMax({ entities } ) {
  let currentMax = 0

  for (let i = 0; i < entities.length; i++) {
    let final_total = entities[i]
      .properties
      .getValue('')
      .rating
      .value

    if (!final_total) {
      final_total = entities[i]
        .properties
        .getValue('')
        .score
        .final_total
    }

    if (currentMax < final_total) {
      currentMax = final_total
    }
  }

  return currentMax
}

/**
 * This function will create and set color to entities
 * @param {Object} map is the current map to show
 * @param entities list of cesium entity
 */
export function createColor({
  map,
  entities
}) {
  if (!entities) {
    return
  }

  for (let i = 0; i < entities.length; i++) {
    let final_total = entities[i]
      .properties
      .getValue('')
      .rating
      .value

    if (!final_total) {
      final_total = entities[i]
        .properties
        .getValue('')
        .score
        .final_total
    }

    if (final_total < 0) {
      final_total = 0
    }

    const score = scoreGrid({
      inverse: map.cesiumLayer.inverse,
      final_total,
      currentMax: getCurrentMax({ entities })
    })

    const [ red, green, blue ] = d3
      [map.colorScale](score)
      .slice(4, -1)
      .split(', ')

    const colorMaterialProperty = new Cesium.ColorMaterialProperty()
    colorMaterialProperty.color = new Cesium.CallbackProperty(() => {
      return new Cesium.Color.fromAlpha(
        new Cesium.Color(
          red / 255,
          green / 255,
          blue / 255
        ),
        map.cesiumLayer.alpha
      )
    }, false)

    entities[i].polygon.material = colorMaterialProperty
    entities[i].polygon.outlineColor = Cesium.Color.TRANSPARENT
  }
}

/**
 * this method will get extruded height from the entity
 * @param {Cesium.Entity} entity
 * @returns {Number} the extruded height value
 */
export function getExtrudedHeight({
  entity,
  currentMax
}) {
  const final_total = entity
    .properties
    .getValue('')
    .rating
    .value

  return 1400 + final_total / currentMax * 10 * 500
}

/**
 * This function will generate extruded height of entities
 * @param {Object} map is the current map selected 
 * @param entities list of cesium entity
 */
export function createExtrudedHeight({
  map,
  entities
}) {
  if (!entities) {
    return
  }

  const currentMax = getCurrentMax({ entities })

  for (let i = 0; i < entities.length; i++) {
    if (map.extrudedStatus) {
      entities[i].polygon.extrudedHeight = getExtrudedHeight({
        entity: entities[i],
        currentMax
      })
      entities[i].polygon.heightReference = undefined
    } else {
      entities[i].polygon.extrudedHeight = undefined
      entities[i].polygon.heightReference = Cesium.HeightReference.CLAMP_TO_GROUND
    }
  }
}

/**
 * This function will set gric color scale with d3
 * @param {Object} map is the current map selected 
 * @param entities list of cesium entity
 */
export async function setGridColorScaleWithD3({
  map,
  entities
}) {
  if (!entities) {
    return
  }

  createColor({
    map,
    entities
  })

  createExtrudedHeight({
    map,
    entities
  })
}

/**
 * This function will load data from geojson into entities
 * @param {string} name is the combination of map.category-map.id used to identify
 * @param {Object} map is the current map selected 
 * @param {Object} geojson geojson data
 * @param {Function} loadingProgress callback of loading progress
 * @return cesium datasource
 */
export async function loadGridMapGeoJSON({
  name,
  map,
  geojson
}) {
  // set default alpha
  map.cesiumLayer.setAlpha(0.5)

  try {
    const dataSource = await creatingDataSource({ name, type: 'custome' })

    for (let i = 0; i < geojson.features.length; i++) {
      const feature = geojson.features[i]

      // find location for labeling, maybe should created later
      // const positions = [feature.geometry.coordinates]
      // const polygon = turf.polygon(positions[0])
      // const centroid = turf.centroid(polygon)

      await dataSource.entities.add({
        // position: new Cesium.Cartesian3.fromDegrees(
        //   centroid.geometry.coordinates[0],
        //   centroid.geometry.coordinates[1]
        // ),
        name: 'grid',
        polygon: new Cesium.PolygonGraphics({
          hierarchy: new Cesium.PolygonHierarchy(
            new Cesium.Cartesian3.fromDegreesArray(
              feature.geometry.coordinates.flat(5)
            )
          ),
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
        }),
        properties: feature.properties
      })
    }
  
    const entities = dataSource.entities.values

    // check for 3D
    for (let i = 0; i < entities.length; i++) {
      if (map.extrudedStatus) {
        const coordinate = Cesium.Cartographic.fromCartesian(entities[i])
        
        entities[i].position = new Cesium.Cartesian3.fromDegrees(
          coordinate.longitude,
          coordinate.latitude,
          getExtrudedHeight({ entity: entities[i] }) + 300
        )
      }
    }

    return dataSource
  } catch (error) {
    throw error
  }
}
