import React from 'react'

import {
  checkInnerListMapRec
} from './helpers/treeData'

import ListMap from '../Map/ListMap'
import ToggleMap from '../Map/ToggleMap'

import ToggleIcon from '../../../shares/ToggleIcon'
import FlexWrapper from '../../../shares/FlexWrapper'
import ToolbarText from '../../../shares/ToolbarText'

function FlexWrapperRecursion({
  marginLeft = 1,
  category,
  subCategory,
  subCategoryKey,
  lastSubCategory = [],
  checkSubLayerCategory,
  uncheckSubLayerCategory
}) {
  let subCategoryKeys = []

  const innerLastSubCategory = lastSubCategory.slice()

  innerLastSubCategory.push(subCategoryKey)

  function onClickSubCategoryHandler() {
    if (subCategory.toggle) {
      uncheckSubLayerCategory({
        category,
        subCategory: innerLastSubCategory
      })
    } else {
      checkSubLayerCategory({
        category,
        subCategory: innerLastSubCategory
      })
    }
  }

  if (subCategory && subCategory.map) {
    return <ToggleMap
      map={subCategory.map}
      marginLeft={marginLeft + 'em'}
      onProgress={subCategory.onProgress}
    />
  }
  
  if (subCategory && subCategory.listMap) {
    let text = subCategoryKey

    if (subCategory.onProgress) {
      text += ' (On Develop)'
    }

    return <>
      <FlexWrapper
        center
        marginLeft={marginLeft + 'em'}
      >
        <ToggleIcon
          toggle={subCategory.toggle}
          size="smaller"
          toggleAble={checkInnerListMapRec(subCategory)}
          onClick={onClickSubCategoryHandler}
          type="toggle"
        />
        <ToolbarText
          width="17em"
          text={text}
          max={40}
        />
      </FlexWrapper>
      {
        subCategory.toggle
          &&
            <ListMap
              categoryValue={subCategory}
              listMap={subCategory.listMap}
              marginLeft={(marginLeft + 1) + 'em'}
              subCategory={innerLastSubCategory}
            />
      }
      </>
  }
  
  if (subCategory && subCategory.subCategory) {
    subCategoryKeys = Object.keys(subCategory.subCategory || {})
  }

  return (
    <>
      <FlexWrapper
        center
        marginLeft={marginLeft + 'em'}
      >
        <ToggleIcon
          toggle={subCategory.toggle}
          size="smaller"
          toggleAble={checkInnerListMapRec(subCategory)}
          onClick={onClickSubCategoryHandler}
        />
        <ToolbarText
          width="17em"
          text={subCategoryKey}
          max={30}
        />
      </FlexWrapper>
      {
        subCategory.toggle && subCategoryKeys.map((subCategoryKey, index) => {
          const subCategoryValue = subCategory.subCategory[subCategoryKey]
          return (
            <FlexWrapperRecursion
              key={index}
              category={category}
              subCategory={subCategoryValue}
              subCategoryKey={subCategoryKey}
              marginLeft={marginLeft + 1}
              lastSubCategory={innerLastSubCategory}
              checkSubLayerCategory={checkSubLayerCategory}
              uncheckSubLayerCategory={uncheckSubLayerCategory}
            />
          )
        })
      }
    </>
  )
}

export default FlexWrapperRecursion
