import React, { 
    useEffect, 
    useState, 
    useRef 
} from 'react';
import * as d3 from 'd3';
import { 
    callScaleLinY, 
    callScaleLogX, 
    scaleLinear, 
    scaleLog 
} from '../helpers/callScale';
import { 
    binsForHistogram, 
    makeHistogramAndTooltip, 
    runXYAxis, 
    scaleLinearX, 
    scaleLinearY 
} from './helpers/histogramFunction';
import { sliderRangeLog } from './helpers/sliderLogarithmic';
import { sliderRangeLinear } from './helpers/sliderLinear';
import { 
    ChartAnalysis, 
    HeaderChart, 
    LabelCheckbox, 
    TitleChart, 
    WrapperChartSvg, 
    WrapperManySvg, 
    WrapperSpinner,
    WrapperSpinnerBackground
} from '../helpers/AnalysisStyle';
import { InputBins, ValueRange } from './partials';
import { capitalizeElement } from '../helpers/capitalizeFirstLetter';
import Dropdown from "../../Dropdowns/Default";
import Checkbox from '../../Checkbox';
import './histo.css'
import { Puff, SpinningCircles } from 'react-loading-icons';
import { dropdownElements, dropdownElemSelect } from '../helpers/dropdownElements';

/**
 * 
 * @param {Object} data
 * @param {boolean} isLoading
 * @param {Array} dropdown
 * @param {String} className
 * @param {Array} config
 * @param {Function} setConfig
 * @param {} onMinimize
 * @param {} openSettings
 * @param {} onDelete 
 * 
 *  config: {
 *      valueSelect: '',
 *      scaleX: 'linear',
 *      range:'',
 *      numBins:30,
 *      minRange:null,
 *      maxRange:null,
 *      color:null
 *  } 
 * 
 * @returns 
 */

let Histogram=({...props})=>{
    let {
        data,
        dataReady,
        dropdown,
        className,
        setConfig,
        config,
        heightContainer,
        widthContainer,
        init,
        dataLocal
    } = props;

    const histogramRef = useRef(null);
    const classNameAll="histogram"+className;
    const [checked,setChecked]= useState(false);
    const [loading,setLoading] = useState(true);
    const [valueDD, setValueDD] = useState();
    const [configHistogram,setConfigHistogram]=useState({
        valueSelect: '',
        scaleX: 'linear',
        range:'',
        numBins:30,
        minRange:null,
        maxRange:null,
        color:null
    });

    //-----handler click log scale-----//
    let handleChangeScaleX=(e)=>{
        let value = e.target.checked? 'logarithmic': 'linear';
        let config_={...configHistogram};
        config_.scaleX=value;
        setConfigHistogram(config_)
        setChecked(e.target.checked)
    }

    useEffect(()=>{
        if(!config) return;
        let newConfig={...configHistogram}
        Object.entries(config).forEach(([key,value])=>{
            if(key in newConfig){
                newConfig[key]=value;
            }
        })
        setConfigHistogram(newConfig)
        if(config.valueSelect!=='' && dataLocal? dataLocal[config.valueSelect]!==undefined: data[config.valueSelect]!==undefined ){
            dropdown.map((d)=>{
                if(d.value===config.valueSelect){
                    setValueDD(d)
                }
            })
        }
    },[])

    useEffect(()=>{
        if(config.valueSelect==="" || data[config.valueSelect]===undefined){
            let newConfig={...configHistogram}
            if(init.length!==0){
                setValueDD({
                    value: init[0],
                    label: capitalizeElement(init[0].slice(0,init[0].lastIndexOf("_")))
                })
                newConfig.valueSelect=init[0]
            }
            setConfigHistogram(newConfig)
        }
    },[init])

    useEffect(()=>{
        setConfig(configHistogram)
    },[configHistogram])
    
    useEffect(() => {
        if (dataReady===false) {
            setLoading(true)
            return
        };
        if (init.length===0) return;
        if (Object.keys(data).length===0) return;

        d3.selectAll("."+className)
            .transition()
            .remove();

        d3.select("."+classNameAll)
        .selectAll("tooltip-histogram")
        .transition()
        .duration(500)
        .remove();

        const configViewBox = {
            minx: 0,
            miny: 0,
            width: widthContainer,
            height: heightContainer,
        }

        const margin = {top: 20, right: 20, bottom: 90, left: 70},
        width = configViewBox.width - margin.left - margin.right,
        height = configViewBox.height - margin.top - margin.bottom;
    
        const svg = d3
        .select(histogramRef.current)
        .attr("viewBox", `${configViewBox.minx} ${configViewBox.miny} ${configViewBox.width} ${configViewBox.height}`)

        const gHistogram= svg
        .append("g")
        .attr("class",className)
        .attr("transform", `translate(${margin.left}, ${margin.top})`);

        const xAxis = gHistogram.append("g")
            .attr("transform", "translate(0," + height + ")")
            .attr("class", "x axis")

        // xAxis.selectAll('text')
        //     .style("text-anchor", "end")
        //     .attr("dx", "-.8em")
        //     .attr("dy", ".15em")
        //     .attr("transform", "rotate(-65)");
        
        gHistogram
        .append('marker')
        .attr('id', 'arrowhead-up')
        .attr('refX', 5)
        .attr('refY', 1)
        .attr('markerWidth', 10)
        .attr('markerHeight', 10)
        .append('path')
        // .attr('d', "M2,2 L2,13 L8,7 L2,2")
        .attr('d', 'M 0 5 L 5 0 L 10 5')
        .attr('stroke', 'white')
        .attr('orient','auto-start-reverse')
        // .attr('stroke-width', 1)
        .attr('fill', 'none');
        // .attr('fill', 'white');

        gHistogram
        .append('marker')
        .attr('id', 'arrowhead-right')
        .attr('refX', 4)
        .attr('refY', 5)
        .attr('markerWidth', 10)
        .attr('markerHeight', 10)
        .append('path')
        // .attr('d', "M2,2 L2,13 L8,7 L2,2")
        .attr('d', 'M 0 0 L 5 5 L 0 10')
        .attr('stroke', 'white')
        .attr('orient','auto-start-reverse')
        // .attr('stroke-width', 1)
        .attr('fill', 'none');
        // .attr('fill', 'white');

        const textTitle = () => {
            if(dataLocal){
                return configHistogram.valueSelect +" (ppm)"
            } else {
                capitalizeElement(configHistogram.valueSelect.slice(0,configHistogram.valueSelect.lastIndexOf("_")))+" (ppm)"
            }
        } 

        gHistogram.append("text")
        .attr("x", (configViewBox.width/4))
        .attr("y",5)
        .attr("fill","white")
        .style("font-size","15px")
        .transition()
        .duration(200)
        .text(textTitle());

        const yAxis = gHistogram.append("g")

        gHistogram.append("text")
        .attr("transform", "rotate(-90)")
        .attr("y",-45)
        .attr("x",-configViewBox.height/2)
        .attr("fill","white")
        .style("font-size","12px")
        .text("Number of samples");

        const gRange = svg.append("g")
        .attr("class",className)
        .attr("transform", `translate(70, ${height+65})`)
        .attr('fill','white');

        if (configHistogram.scaleX==='logarithmic') {
            let x=scaleLog({
                data: data,
                selected: configHistogram.valueSelect,
                minrange: 0,
                maxrange: width
            })
            let bins=binsForHistogram({
                x: x,
                data: data,
                selection: configHistogram.valueSelect,
                numBins: configHistogram.numBins
            })
            let y=scaleLinearY({
                minrange: height,
                maxrange: 0,
                bins: bins
            })
            callScaleLogX({
                scaleAxis: xAxis,
                selectedCall: x
            })
            callScaleLinY({
                scaleAxis: yAxis,
                selectedCall: y,
                data: data,
                y: configHistogram.valueSelect
            })
            sliderRangeLog({
                dataGroup: data,
                selection: configHistogram.valueSelect,
                width: width, 
                height: height, 
                color: configHistogram.color,
                gHistogram: gHistogram,
                svg: svg,
                className: className,
                xAxis: xAxis,
                yAxis: yAxis,
                configHistogram: configHistogram,
                setConfigHistogram: setConfigHistogram,
                minRange: configHistogram.minRange,
                maxRange: configHistogram.maxRange,
                series: Object.keys(data),
                classNameAll: classNameAll
            });
            makeHistogramAndTooltip({
                classNameAll: classNameAll,
                gHistogram: gHistogram,
                bins: bins,
                x: x,
                y: y,
                height: height,
                color: configHistogram.color,
                selection: configHistogram.valueSelect,
                series: Object.keys(data)
            });
        } else {
            let x= scaleLinearX({
                data:data,
                x: configHistogram.valueSelect,
                minrange: 0,
                maxrange: width
            })
            let bins=binsForHistogram({
                x: x,
                data: data,
                selection: configHistogram.valueSelect,
                numBins: configHistogram.numBins
            })
            let y=scaleLinearY({
                minrange: height,
                maxrange: 0,
                bins: bins
            })
            runXYAxis({
                xAxis: xAxis,
                yAxis: yAxis,
                x: x,
                y: y,
                data: data,
                configHistogram: configHistogram
            })
            let sliderRange = sliderRangeLinear({
                dataGroup: data,
                selection: configHistogram.valueSelect,
                width: width,
                height: height,
                color: configHistogram.color,
                gHistogram: gHistogram,
                xAxis: xAxis,
                yAxis: yAxis,
                configHistogram: configHistogram,
                setConfigHistogram: setConfigHistogram,
                minRange: configHistogram.minRange,
                maxRange: configHistogram.maxRange,
                series: Object.keys(data),
                classNameAll: classNameAll
            });
            let newState={...configHistogram};
            if (sliderRange===undefined) return;
            let p = d3.precisionRound(0.01,1.1)
            newState.range=sliderRange.value().map(d3.format("."+p+'r')).join(' - ');
            setConfigHistogram(newState)

            gRange.call(sliderRange)
            .transition()
            .duration(1000);

            makeHistogramAndTooltip({
                classNameAll: classNameAll,
                gHistogram: gHistogram,
                bins: bins,
                x: x,
                y: y,
                height: height,
                color: configHistogram.color,
                selection: configHistogram.valueSelect,
                series: Object.keys(data)
            });
        }
        setLoading(false)
    },[
        data, 
        configHistogram.valueSelect, 
        configHistogram.scaleX,
        configHistogram.numBins,
        dataReady, 
        className, 
        heightContainer, 
        widthContainer
    ]);

    return(
        <ChartAnalysis className={classNameAll}>
            
            <TitleChart className="header-plot">
            Histogram
            </TitleChart>

            <HeaderChart className="header-plot" >
                {
                    dropdownElemSelect({
                        dropdownOptions: dropdown,
                        config: configHistogram,
                        setConfig: setConfigHistogram,
                        val: 'valueSelect',
                        valDD: valueDD,
                        setValDD:setValueDD,
                    })
                }
                {/* <Dropdown
                    label="Series"
                    floatingText="Series"
                    options={dropdown}
                    setToggle={(value)=>{
                        let config_={...configHistogram};
                        config_.valueSelect=value;
                        setConfigHistogram(config_)
                    }}
                    defaultPick={configHistogram.valueSelect}
                    width='3.8em'
                    height = '1.2em'
                    initialBoxColor = 'rgb(119, 89, 13)'
                    fontColor = 'white'
                    marginTop='0.6em'
                    position='absolute'
                    className='noDrag'
                />  */}
                <ValueRange className='noDrag'>
                    {/* Range: <input type="text" value={configHistogram.range}/> */}
                    Range: {configHistogram.range}
                </ValueRange>
                <LabelCheckbox marginLeft="0em" className='noDrag'>
                    <Checkbox 
                        type="checkbox"
                        value={configHistogram.scaleX}
                        className="checkbox"
                        onChange={handleChangeScaleX}
                        checked={checked}
                    />
                    Log-X
                </LabelCheckbox>
            </HeaderChart>

            {loading?
            <WrapperSpinnerBackground>
                <WrapperSpinner>
                    <Puff
                    height={heightContainer/2}
                    width={widthContainer/2}
                    fill='goldenrod'
                    stroke="darkgoldenrod"
                    speed="1"
                    fillOpacity="0.5"
                    strokeOpacity="0.5"
                    strokeWidth="2"
                />
                </WrapperSpinner>
            </WrapperSpinnerBackground>
            :
            <></>
            }

            <WrapperChartSvg>
                <WrapperManySvg>
                    <svg 
                        ref={histogramRef}
                        width={"100%"}
                        height={"100%"}
                    />
                </WrapperManySvg>
            </WrapperChartSvg>
            <InputBins>
                    <input 
                        type="number"
                        style={{width:'40px', fontFamily: 'Abel', fontSize:'0.7em'}}
                        value={configHistogram.numBins}
                        min={1}
                        title="Bins"
                        onChange={(e)=>{
                            let arr={...configHistogram}
                            arr.numBins = e.target.value
                            setConfigHistogram(arr)
                        }}
                    />
            </InputBins>
        </ChartAnalysis>        
    )
}

export default Histogram;