/////////////////////////////////////////////
//                                         //
//            ADD TO DISPLAY               //
//                                         //
/////////////////////////////////////////////
export const SET_DISPLAY = 'SET_DISPLAY'
export const ADD_TO_DISPLAY = 'ADD_TO_DISPLAY'
export const REMOVE_FROM_DISPLAY = 'REMOVE_FROM_DISPLAY'
export const REMOVE_ALL_DISPLAY = 'REMOVE_ALL_DISPLAY'

export const SET_FOCUS_MAP = 'SET_FOCUS_MAP'

export const RESET_DISPLAY = 'RESET_DIPLAY'
