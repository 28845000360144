import axiosReact, { getProgressDownload } from '../../../axiosReact'

export async function fetch({
  // map,
  params,
  fetchProgress,
  fetchFinish
}) {
  try {
    try {
      let { data } = await axiosReact.get('/geoprosp/cesium_asset', {
        params: {
          ion_name: 'landslide'
        }
      })

      if (fetchFinish instanceof Function) fetchFinish()
      data = data
        .map(item => ({ ...item, tilesetId: item.asset_id, name: item.label_name }))
        .filter(item => item.tilesetId)

      return data[0]
    } catch (error) {
      throw error
    }
  } catch (error) {
    throw error
  }
}

/**
 * Nothing happened here
 */
export function parseFetch({ data }) {
  return data
}

export function getColor(entity, {
  map
}) {
  const lithology = entity.properties.getValue('').domlith4

  if (lithology) {
    for (let i = 0; i < GEOLOGICAL_MAP_COLOR_REFERENCES.length; i++) {
      if (lithology.toLowerCase().includes(GEOLOGICAL_MAP_COLOR_REFERENCES[i].key)) {
        const {
          red,
          green,
          blue,
          alpha
        } = GEOLOGICAL_MAP_COLOR_REFERENCES[i].color
        
        return {
          red,
          green,
          blue,
          alpha
        }
      }
    }
  }

  return {
    red: 1,
    green: 1,
    blue: 1,
    alpha: map.cesiumLayer.alpha
  }
}

export async function getLegendStyle({
  map,
  params,
  fetchProgress,
  fetchFinish
}) {
  try {
    const { data } = await axiosReact.get('/geoprosp/legend_tileset', {
      params: {
        image_type: 'landslide'
      }
    })

    const result = data.map((legendInfo) => {
      return {
        label: legendInfo.value,
        color: legendInfo.legend,
        geom: 'Polygon'
      }
    })

    if (fetchFinish instanceof Function) {
      fetchFinish()
    }

    return result
  } catch (error) {
  }
}
