import React from 'react'

import FocusContainer, {
  Header,
  LinkText,
  Paragraph
} from '../StyledComponent'
import DashboardPNG from '../HelpImage/dashboard.png'
import { LIST_HELP } from '../constant'


function HelpDashboard(props) {
  const flyTo=(x)=>{
    props.setFocus(x)
  }
  return <>
    <FocusContainer {...props}>
      <Header>{LIST_HELP['dashboard'].detail.dashboardView.header}</Header>
      <img src={DashboardPNG} alt="Dashboard image" width="1100px" />
      <Paragraph>
        1. <LinkText onClick={()=>flyTo('map')} style={{color:'black'}}>Map</LinkText>
      </Paragraph>
      <Paragraph>
        2. <LinkText onClick={()=>flyTo('assistiveButton')} style={{color:'black'}}>Assistive Button</LinkText>
      </Paragraph>
      <Paragraph>
        3. <LinkText onClick={()=>flyTo('menuBar')} style={{color:'black'}}>Menu Bar</LinkText>
      </Paragraph>
      <Paragraph>
        4. <LinkText onClick={()=>flyTo('commodityOption')} style={{color:'black'}}>Commodity Option</LinkText>
      </Paragraph>
      <Paragraph>
        5. <LinkText onClick={()=>flyTo('helpButton')} style={{color:'black'}}>Help Button</LinkText>
      </Paragraph>
      <Paragraph>
        6. <LinkText onClick={()=>flyTo('chatButton')} style={{color:'black'}}>Chat Button</LinkText>
      </Paragraph>
      <Paragraph>
        7. <LinkText onClick={()=>flyTo('notificationButton')} style={{color:'black'}}>Notification Button</LinkText> (Soon)
      </Paragraph>
      <Paragraph>
        8. <LinkText onClick={()=>flyTo('logoutButton')} style={{color:'black'}}>Log Out Button</LinkText>
      </Paragraph>
    </FocusContainer>
  </>
}

export default HelpDashboard
