import React from 'react'

import {
  generateColorGradient
} from '../../../../helpers'

import TooltipWrapper from '../../../../shares/TooltipWrapper'
import TooltipHeader from '../../../../shares/TooltipHeader'
import TooltipContent from '../../../../shares/TooltipContent'

import {
  COLOR_D3_QGIS_TEMPLATES
} from '../../../../../../../packages/qml/colorTemplate'

import styled, { css } from 'styled-components'

const D3_STYLE_CHROMATIC = [
  'interpolateBlues',
  'interpolateBrBG',
  'interpolateBuGn',
  'interpolateBuPu',
  'interpolateGnBu',
  'interpolateGreens',
  'interpolateGreys',
  'interpolateInferno',
  'interpolateMagma',
  'interpolateOrRd',
  'interpolateOranges',
  'interpolatePRGn',
  'interpolatePiYG',
  'interpolatePlasma',
  'interpolatePuBu',
  'interpolatePuBuGn',
  'interpolatePuOr',
  'interpolatePuRd',
  'interpolatePurples',
  'interpolateRdBu',
  'interpolateRdGy',
  'interpolateRdPu',
  'interpolateRdYlBu',
  'interpolateRdYlGn',
  'interpolateReds',
  'interpolateSpectral',
  'interpolateViridis',
  'interpolateYlGn',
  'interpolateYlGnBu',
  'interpolateYlOrBr',
  'interpolateYlOrRd'
]

const GridColorSchemeScroll = styled.div`
  margin-top: 0.5em;
  padding-right: 0;
  height: 88%;
  overflow-y: scroll;
`

const GridColorScheme = styled.div`
  display: flex;
  padding: 2px;
  width: ${props => props.selected ? 'calc(12em - 8px)' : '12em'};
  height: ${props => props.selected ? '16px' : '20px'};
  margin-bottom: 10px;
  cursor: ${props => !props.selected && 'pointer'};
  border-radius: 5px;
  background-image: linear-gradient(to right, ${props => props.color}); 
  border: ${props => props.selected ? '4px solid black' : ''};
  
  ${
    props => !props.selected &&
      css`
        &:hover {
          height: 16px;
          width: calc(12em - 4px);
          border: 2px solid red;
        }
      `
  }
`

/**
 * This function will select theme for picked color
 * @param map is map of the current entitty
 * @param groupEntities is filtered entity of the current by group and sort
 * @param hideColorPicker is to hide this color picker
 * @param exceptHideRefs array of Ref that will not trigger hideColorPicker
 * @param {Functoin} selectColorHandler to select the color
 */
function ColorPickerDropdown({
  map,
  hideColorPicker,
  exceptHideRefs,
  selectColorHandler
}) {
  /**
   * This keywords will generate keywords for content
   * @returns array of keywords
   */
  function generateKeywords() {
    return ['color']
  }

  return <TooltipWrapper
    clickOutsideHandler={hideColorPicker}
    exceptRefs={exceptHideRefs}
  >
    <TooltipHeader
      tooltipName="Color Tooltip"
      mapName={map.name}
    />
    <TooltipContent
      keywords={
        generateKeywords()
      }
      color={
        <GridColorSchemeScroll>
          {
            COLOR_D3_QGIS_TEMPLATES.map(({ colorScale }, index) => {
              return <GridColorScheme
                key={index}
                color={
                  generateColorGradient({
                    colorScale,
                    inverse: map.cesiumLayer.inverse
                  })
                }
                selected={map.colorScale === colorScale}
                onClick={() => selectColorHandler(colorScale)}
              >
              </GridColorScheme>
            })
          }
        </GridColorSchemeScroll>
      }
    />
  </TooltipWrapper>
}

export default ColorPickerDropdown
