import React, {
  useState,
  useEffect,
  useRef
} from 'react'

import axios from 'axios'

import { connect } from 'react-redux'

import { appendMessages, appendConnectedRoom } from '../../../../../store/actions/socket'
import {
  getChatSocket, getChatRoom, getRoomsObj
} from '../../../../../store/selectors/socket'

import { getUserChatMessageIDDetail } from '../../../../../apis/server'

import ReactLoading from 'react-loading'

import {
  ContainerLoading
} from '../../shares/StyledComponent'

import BubbleChat from './BubbleChat'
import ScrollBottom from './ScrollBottom'

import styled from 'styled-components'

const BodyContainer = styled.div`
  height: 50%;
  margin-top: 0.5em;
  border-radius: 5px;
  background: #1d1d1dbe;
  overflow-y: auto;
  padding: 0.3em;
`

const PAGE = 15

function MessageBody({
  chatRoom,
  username,
  roomSocket,
  newMessage,
  messages,
  appendMessages,
  lastNewMessage,
}) {
  const [ limit, setLimit ] = useState(PAGE)
  const [ scrollHeight, setScrollHeight ] = useState(null)
  const [ time, setTime ] = useState(null)
  const [ count, setCount ] = useState(0)
  const [ staticPosition, setStaticPosition ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  let atBottom = false

  const bodyRef = useRef(null)

  function checkBottom() {
    if (!bodyRef.current) {
      atBottom = false
    } else if (bodyRef.current.offsetHeight === 0) {
      atBottom = true
    } else if (bodyRef.current.scrollTop + 20 >= (
      bodyRef.current.scrollHeight - bodyRef.current.offsetHeight
    )) {
      atBottom = true
    } else {
      atBottom = false
    }
  }
  useEffect(() => {
    if(!loading) {
      lastNewMessage(new Date())
    }
  }, [loading])

  useEffect(() => {
    lastNewMessage(new Date())
  }, [messages.length])

  function onScrollHandler(event) {
    checkBottom()

    if (!bodyRef.current) {
      event.preventDefault()

      return
    }

    if (bodyRef.current.scrollTop !== 0) {
      event.preventDefault()

      return
    }

    if (messages.length < limit || time) {
      event.preventDefault()

      return
    }
    setScrollHeight(bodyRef.current.scrollHeight)
    setStaticPosition(true)
    setLimit((limit) => limit + PAGE)
    setTime(() => {
      return setTimeout(() => {
        clearTimeout(time)
        setTime(null)
      }, 330)
    })
  }

  useEffect(() => {
    if (limit !== PAGE && bodyRef.current && staticPosition) {
      bodyRef.current.scrollTop = bodyRef.current.scrollHeight
        - scrollHeight
      setStaticPosition(false)
    }
  }, [count])

  useEffect(() => {
    setLimit((limit) => limit + 1)
  }, [newMessage])

  useEffect(() => {
    if(!messages.length) {
      setLoading(true)
    }
    const source = axios.CancelToken.source()

    if (roomSocket) {
      async function fetchMessageDetail() {
        if (!chatRoom) {
          setLoading(false)
          return
        }
        const data = await getUserChatMessageIDDetail({
          messageId: chatRoom.message_id,
          source
        })
        appendMessages({
          data,
          id: chatRoom.message_id
        })
        lastNewMessage(new Date())
        setLoading(false)
      }
      fetchMessageDetail()
    } else {
      setLoading(false)
    }

    return () => {
      try {
        if (source) {
          source.cancel()
        }
      } catch (error) {
        throw error
      }
    }
  }, [ roomSocket ])

  useEffect(() => {
    return () => {
      clearTimeout(time)
      setTime(null)
    }
  }, [time])

  return <BodyContainer
    onScroll={onScrollHandler}
    ref={bodyRef}
  >
    {/* {
      !loading && 
    } */}
    {
      messages.length && !loading ?
        <>
          {
            messages.map((message, index) => {
              if (messages.length - index > limit) {
                return null
              }
              let start = true
              if (
                  index
                    && messages?.[index - 1]?.user?.username
                      === message?.user?.username
              ) {
                start = false
              }
              return <BubbleChat
                key={message.id}
                message={message}
                start={start}
                left={username !== message?.user?.username}
                setCount={setCount}
              />
            })
          }
          <ScrollBottom
              count={count}
              bodies={messages}
              limit={limit}
              newMessage={newMessage}
              isAtBottom={atBottom}
            />
        </>
        : chatRoom
          ? <ContainerLoading>
            <ReactLoading color="white" type="spin" />
            <p>Fetching Room Data...</p>
          </ContainerLoading>
          : null
    }
  </BodyContainer>
}

const mapStateToProps = (state) => {
  let { message_id } = getChatRoom(state) || {}
  let messages = []
  if(message_id) {
    messages = getRoomsObj(state)[message_id]?.messages || []
  }
  return {
    messages,
    connectedRooms: state.socket.connectedRooms,
    roomSocket: getChatSocket(state)
  }
}

const mapDispatchToProps = {
  appendMessages,
  appendConnectedRoom
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageBody)
