import React from 'react'

import getPixel from '../../helpers/getPixel'

import DropdownFixed from './DropdownFixed'
import DropdownContainer from './DropdownContainer'
import DropdownText from './DropdownText'

/**
 * This function create list based on dropdown
 * @examples
 * const options = [
 *   pickName: 'rnd',
 *   pickShow: 'Research and Development'
 * ]
 * @param height is the height of the content
 * @param width is the width of the content
 * @param options is array of the options that will show in the list
 * @param dropdown parent toggle for dropdown
 * @param setPick parent set for pick
 * @param setDropdown parent set for dropdown
 * @param setToggle elderparent to show list show based on condition
 * @param backgroundColor it to use for color the content
 * @param fontColor is the color of the text font
 */
function DropdownList({
  height,
  width,
  options,
  dropdown,
  setPick,
  setDropdown,
  setToggle,
  backgroundColor = {
    default: [],
    hover: []
  },
  fontColor
  // position,
  // heightOptions
}) {
  const totalRowShow = 5
  const borderWidth = 3
  const lineHeight = getPixel(
    height
  )

  return <DropdownFixed
    width={width}
    dropdown={dropdown}
    thumbColor="#485563"
    height={
      options.length < totalRowShow
        ? lineHeight * (options.length + 1) + borderWidth + 'px'
        : lineHeight * (totalRowShow + 1) + borderWidth + 'px'
    }
    // position = {position}
    // heightOptions={heightOptions}
  >
    {
      options.map((option, index) => {   
        function onClickHandler() {
          setPick(option.pickShow)
          setDropdown(false)
          setToggle(option.pickName)
        }
  
        return (
          <DropdownContainer
            key={index}
            index={index}
            dropdown={dropdown}
            onClick={onClickHandler}
            height={height}
            width={width}
            style={{
              top: `${(index + 1) * 1.2}em`
            }}
            backgroundColorDefault={
              backgroundColor.default[
                index % backgroundColor.default.length
              ]
            }
            backgroundColorHover={
              backgroundColor.hover[
                index % backgroundColor.hover.length
              ]
            }
          >
            <DropdownText fontColor={fontColor} dropdown={dropdown} width={width}>
              {option.pickShow}
            </DropdownText>
          </DropdownContainer>
        )
      })
    }
  </DropdownFixed>
}

export default DropdownList
