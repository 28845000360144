import React, { useState, useEffect } from 'react'

import {
  ReactComponent as TickIcon
} from '../../../../images/Icons/tick-symbol.svg'

import {
  ReactComponent as LogoutIcon
} from '../../../../images/Icons/logout.svg'

import {
  AlignedFlexRow,
  FlexColumn
} from '../../../BasicStyle/FlexWrapper'

import styled from 'styled-components'

import { GOLD_FONT } from '../../../shares/ColorTemplate'

const InnerContainer = styled(FlexColumn)`
  position: absolute;
  width: 10em;
  background: rgb(24, 24, 24);
  top: 2.75em;
  border-bottom-left-radius: 10px;
  transition: all 330ms;
`

const LineContent = styled(AlignedFlexRow)`
  margin: 0.3em 1em;
  font-family: Abel;
  color: #888888;
  cursor: pointer;

  &:hover {
    background: ${GOLD_FONT};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

/**
 * This component used to show list toggle when user clicked
 * @param {Object} user is the redux state of user
 * @param {Boolean} userToggle is the status if this show list being toggle or not
 * @param {Function} logoutHandler used to logout user when logout button clicked
 */
function UserMenuToggle({
  user,
  userToggle,
  logoutHandler
}) {
  const [ right, setRight ] = useState('-10em')
  const [ show, setShow ] = useState(userToggle)

  useEffect(() => {
    if (userToggle) {
      setShow(userToggle)
      setTimeout(() => {
        setRight(0)
      }, 100)
    } else {
      setRight('-10em')

      setTimeout(() => {
        setShow(userToggle)
      }, 330)
    }
  }, [userToggle])

  return <>
    {
      show
      && <InnerContainer 
        style={{
          right
        }}
      >
        <LineContent className="button-icon">
          <TickIcon fill="#999999" width="1em" className="navbar-icon" alt="User" />
          <span>{user.tierDesc[0] + user.tierDesc.slice(1).toLowerCase()}</span>
        </LineContent>
        <LineContent className="button-icon" onClick={logoutHandler}>
          <LogoutIcon fill="#999999" width="1em" className="navbar-icon" alt="User" />
          <span>Logout</span>
        </LineContent>
      </InnerContainer>
    }
  </>
}

export default UserMenuToggle
