import styled from "styled-components";

export const ValueRange=styled.div`
  position: relative;
  padding: 5px;
  padding-bottom: 0px;
  padding-top:0.2em;
  align-items: center;
  background-color:white;
  color: black;
  height:15px;
  margin-top:0.5em;
  margin-bottom: 0em;
  font-family:abel;
  font-size:0.7em;
  font-weight: bold;
  text-align: center;
  border-radius: 4px;
  cursor:auto;
`

export const InputBins = styled.div`
    border-radius:4px;
    top: 3.5em;
    right: 0.5em;
    position:absolute;
    padding-top: 0.1em;
    padding-bottom: 0.1em;
`