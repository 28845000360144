import * as Cesium from 'cesium';
import {kml} from '@tmcw/togeojson';
import {DOMParser} from 'xmldom';
import * as turf from '@turf/turf';

/**
 * Cesium Utils
*/
class CesiumUtils {
    /**
     * Init cesium utils
     * @param {*} viewer - Cesium viewer instance
     * @param {object} options - Utils option
    */
    constructor(viewer = null, options = {}) {
        this.viewer = viewer;
        this.camera = viewer && viewer.camera;
        this.drawType = null;
        this.options = {...options};
    }

    /**
     * Initiate/reset entity collection
    */
    createSelectorCollection() {
        if (!this.viewer) return;
        this.selectorCollection = new Cesium.EntityCollection();
    }

    /**
     * Remove entity collection
    */
    removeSelectorCollection() {
        if (!this.viewer) return;
        this.selectorCollection.removeAll();
        this.selectorCollection = null;
    }

    /**
     * Export entity from from selector collection
     * @return {Promise} Resolved: the output object {kml}
    */
    exportSelector() {
        if (!this.selectorCollection) return;
        if (!this.DOMParser) {
            this.DOMParser = new DOMParser();
        }

        let output = {};
        output.type = this.drawType;
        switch (this.drawType) {
            case 'circle':
                // export kml not available for circle
                let firstPoint = Cesium.Cartographic.fromCartesian(this.firstPoint);
                firstPoint.longitude *= Cesium.Math.DEGREES_PER_RADIAN;
                firstPoint.latitude *= Cesium.Math.DEGREES_PER_RADIAN;
                output.geojson = turf.circle(
                    [
                        firstPoint.longitude,
                        firstPoint.latitude,
                    ],
                    this.radius / 1000,
                );
                output.geojson.properties = {
                    fill: "#000000",
                    "fill-opacity": 0.5019607843137255,
                    styleHash: "1595e16e",
                    styleUrl: "#style-1",
                    visibility: "1",
                };

                // call export handler
                this.exportCallback && this.exportCallback(output);
                if (this.options.exportHandler && this.options.exportHandler.constructor === Function) {
                    this.options.exportHandler(output);
                }
                break;
            case 'freehand':
                output.type = 'polygon';
            case 'rectangle':
            case 'polygon':
                Cesium.exportKml({
                    entities: this.selectorCollection,
                    ellipsoid: Cesium.Ellipsoid.WGS84,
                })
                    .then(result => {
                        let kmlString = result.kml;
                        output.kml = kmlString;
                        let gjson = kml(
                            new DOMParser().parseFromString(
                                kmlString,
                                'text/xml',
                            ),
                        );
                        output.geojson = gjson.features[0];
                        this.exportCallback && this.exportCallback(output);
                        if (this.options.exportHandler && this.options.exportHandler.constructor === Function) {
                            this.options.exportHandler(output);
                        }
                    });
                break;
            default:
        }
    }

    /**
     * Add event draw rectangle on viewer and save to selectorCollection
     * @param {Function} callback - callback that will bi executed after entity created
    */
    enableDrawRect(callback) {
        this.exportCallback = callback &&
            callback.constructor === Function &&
            callback;

        if (!this.viewer) return;
        this.createSelectorCollection();
        this.drawType = 'rectangle';

        this.rectangleSelector = new Cesium.Rectangle();
        this.screenSpaceEventHandler = new Cesium.ScreenSpaceEventHandler(
            this.viewer.scene.canvas,
        );
        this.m1Down = false;
        this.firstPointSet = false;
        this.firstPoint = new Cesium.Cartographic();
        this.cartesian = new Cesium.Cartesian3();
        this.tempCartographic = new Cesium.Cartographic();
        this.getSelectorLocation = new Cesium.CallbackProperty((time, result) => {
            return Cesium.Rectangle.clone(this.rectangleSelector, result);
        }, false);
        this.getRectToPolygon = new Cesium.CallbackProperty((time, result) => {
            let rect_ = Cesium.Rectangle.clone(this.rectangleSelector, result);
            let polygons = [
                rect_.west, rect_.north,
                rect_.east, rect_.north,
                rect_.east, rect_.south,
                rect_.west, rect_.south,
                rect_.west, rect_.north,
            ];
            return new Cesium.PolygonHierarchy(
                Cesium.Cartesian3.fromRadiansArray(polygons)
            );
        }, false);

        // event: drag mouse and shift down
        this.screenSpaceEventHandler.setInputAction(
            () => {
                // start click shift
                this.m1Down = true;
                this.selector.rectangle.coordinates = this.getSelectorLocation;
            },
            Cesium.ScreenSpaceEventType.LEFT_DOWN,
            Cesium.KeyboardEventModifier.SHIFT,
        );
        this.screenSpaceEventHandler.setInputAction(
            () => {
                if (!this.m1Down) return;
                // end click shift
                this.m1Down = false;
                this.firstPointSet = false;
                this.selector.rectangle.coordinates = this.getSelectorLocation;
                this.exportSelector();
            },
            Cesium.ScreenSpaceEventType.LEFT_UP,
            Cesium.KeyboardEventModifier.SHIFT,
        );
        this.screenSpaceEventHandler.setInputAction(
            (movement) => {
                // on dragged mouse
                if (!this.m1Down) return;
                this.cartesian = this.camera.pickEllipsoid(
                    movement.endPosition,
                    this.viewer.scene.globe.ellipsoid,
                    this.cartesian,
                );
                if (!this.cartesian) return;
                this.tempCartographic = Cesium.Cartographic.fromCartesian(
                    this.cartesian,
                    Cesium.Ellipsoid.WGS84,
                    this.tempCartographic,
                );

                if (!this.firstPointSet) {
                    Cesium.Cartographic.clone(this.tempCartographic, this.firstPoint);
                    this.firstPointSet = true;
                } else {
                    this.rectangleSelector.east = Math.max(
                        this.tempCartographic.longitude,
                        this.firstPoint.longitude,
                    );
                    this.rectangleSelector.west = Math.min(
                        this.tempCartographic.longitude,
                        this.firstPoint.longitude,
                    );
                    this.rectangleSelector.north = Math.max(
                        this.tempCartographic.latitude,
                        this.firstPoint.latitude,
                    );
                    this.rectangleSelector.south = Math.min(
                        this.tempCartographic.latitude,
                        this.firstPoint.latitude,
                    );
                    this.selector.show = true;
                }
            },
            Cesium.ScreenSpaceEventType.MOUSE_MOVE,
            Cesium.KeyboardEventModifier.SHIFT,
        );

        // hide selection on click
        this.screenSpaceEventHandler.setInputAction(
            () => {
                this.selector.show = false;
            },
            Cesium.ScreenSpaceEventType.LEFT_CLICK,
        );

        this.selector = this.viewer.entities.add({
            selectable: false,
            show: false,
            rectangle: {
                coordinates: this.getSelectorLocation,
                material: Cesium.Color.BLACK.withAlpha(0.5),
            }
        });
        this.selectorCollection.add({
            polygon: {
                hierarchy: this.getRectToPolygon,
                material: Cesium.Color.BLACK.withAlpha(0.5),
            }
        });
    }

    /**
     * Remove event draw rectangle on viewer and reset selectorCollection
    */
    disableDrawRect() {
        this.exportCallback = null;
        if (!this.viewer) return;
        if (!this.screenSpaceEventHandler) return;
        if (this.drawType === 'rectangle') {
            this.removeSelectorCollection();
            this.viewer.entities.remove(this.selector);
            this.screenSpaceEventHandler.removeInputAction(
                Cesium.ScreenSpaceEventType.LEFT_DOWN,
                Cesium.KeyboardEventModifier.SHIFT,
            );
            this.screenSpaceEventHandler.removeInputAction(
                Cesium.ScreenSpaceEventType.LEFT_UP,
                Cesium.KeyboardEventModifier.SHIFT,
            );
            this.screenSpaceEventHandler.removeInputAction(
                Cesium.ScreenSpaceEventType.MOUSE_MOVE,
                Cesium.KeyboardEventModifier.SHIFT,
            );

            this.selector = null;
            this.drawType = null;
            this.rectangleSelector = null;
            this.screenSpaceEventHandler = null;
            this.m1Down = null;
            this.firstPointSet = null;
            this.firstPoint = null;
            this.cartesian = null;
            this.tempCartographic = null;
            this.getSelectorLocation = null;
            this.getRectToPolygon = null;
        }
    }

    /**
     * Add event draw circle radius on viewer and save to selectorCollection
     * @param {Function} callback - callback that will bi executed after entity created
    */
    enableDrawCirc(callback) {
        this.exportCallback = callback &&
            callback.constructor === Function &&
            callback;

        if (!this.viewer) return;
        this.createSelectorCollection();
        this.drawType = 'circle';

        // create event handler
        this.screenSpaceEventHandler = new Cesium.ScreenSpaceEventHandler(
            this.viewer.scene.canvas,
        );
        // mouse state
        this.m1Down = false;
        this.firstPointSet = false;
        this.firstPoint = new Cesium.Cartesian3(0, 1, 0);
        this.cartesian = new Cesium.Cartesian3();
        this.tempCartographic = new Cesium.Cartographic();

        this.getFirstPoint = new Cesium.CallbackProperty((time, result) => {
            return Cesium.Cartesian3.clone(this.firstPoint, result);
        }, false);
        this.radius = 1;
        this.getRadius = new Cesium.CallbackProperty((time, result) => {
            return this.radius;
        }, false);

        // start mouse shift
        this.screenSpaceEventHandler.setInputAction(
            () => {
                this.m1Down = true;
                this.radius = 1e-9;
            },
            Cesium.ScreenSpaceEventType.LEFT_DOWN,
            Cesium.KeyboardEventModifier.SHIFT,
        );
        // end mouse shift
        this.screenSpaceEventHandler.setInputAction(
            () => {
                if (!this.m1Down) return;
                this.m1Down = false;
                this.firstPointSet = false;
                this.exportSelector();
            },
            Cesium.ScreenSpaceEventType.LEFT_UP,
            Cesium.KeyboardEventModifier.SHIFT,
        );
        // on dragged mouse
        this.screenSpaceEventHandler.setInputAction(
            (movement) => {
                if (!this.m1Down) return;
                this.cartesian = this.camera.pickEllipsoid(
                    movement.endPosition,
                    this.viewer.scene.globe.ellipse,
                    this.cartesian,
                );
                if (!this.cartesian) return;
                this.tempCartographic = Cesium.Cartographic.fromCartesian(
                    this.cartesian,
                    Cesium.Ellipsoid.WGS84,
                    this.tempCartographic,
                );
                if (!this.firstPointSet) {
                    Cesium.Cartesian3.clone(this.cartesian, this.firstPoint);
                    this.firstPointSet = true;
                } else {
                    let distance = Cesium.Cartesian3.distance(this.firstPoint, this.cartesian);
                    this.radius = distance;
                    this.selector.show = true;
                }
            },
            Cesium.ScreenSpaceEventType.MOUSE_MOVE,
            Cesium.KeyboardEventModifier.SHIFT,
        );
        // hide selection on click
        this.screenSpaceEventHandler.setInputAction(
            () => {
                this.selector.show = false;
            },
            Cesium.ScreenSpaceEventType.LEFT_CLICK,
        );

        this.selector = this.viewer.entities.add({
            position: this.getFirstPoint,
            selectable: false,
            show: true,
            ellipse: {
                semiMinorAxis: this.getRadius,
                semiMajorAxis: this.getRadius,
                material: Cesium.Color.BLACK.withAlpha(0.5),
            },
        });
        this.selectorCollection.add({
            position: this.getFirstPoint,
            ellipse: {
                semiMinorAxis: this.getRadius,
                semiMajorAxis: this.getRadius,
                material: Cesium.Color.BLACK.withAlpha(0.5),
            },
        });
    }

    /**
     * Remove event draw circle radius on viewer and reset selectorCollection
    */
    disableDrawCirc() {
        this.exportCallback = null;
        if (!this.viewer) return;
        if (!this.screenSpaceEventHandler) return;
        if (this.drawType === 'circle') {
            this.removeSelectorCollection();
            this.viewer.entities.remove(this.selector);
            this.screenSpaceEventHandler.removeInputAction(
                Cesium.ScreenSpaceEventType.LEFT_DOWN,
                Cesium.KeyboardEventModifier.SHIFT,
            );
            this.screenSpaceEventHandler.removeInputAction(
                Cesium.ScreenSpaceEventType.LEFT_UP,
                Cesium.KeyboardEventModifier.SHIFT,
            );
            this.screenSpaceEventHandler.removeInputAction(
                Cesium.ScreenSpaceEventType.MOUSE_MOVE,
                Cesium.KeyboardEventModifier.SHIFT,
            );
            this.screenSpaceEventHandler.removeInputAction(
                Cesium.ScreenSpaceEventType.LEFT_CLICK,
            );

            this.getFirstPoint = null;
            this.radius = null;
            this.getRadius = null;
            this.cartesian = null;
            this.tempCartographic = null;
            this.m1Down = null;
            this.firstPointSet = null;
            this.firstPoint = null;
            this.screenSpaceEventHandler = null;
            this.drawType = null;
        }
    }

    /**
     * Add event draw polygon on viewer and save to selectorCollection
     * @param {Function} callback - callback that will bi executed after entity created
    */
    enableDrawPolyg(callback) {
        this.exportCallback = callback &&
            callback.constructor === Function &&
            callback;

        if (!this.viewer) return;
        this.createSelectorCollection();
        this.drawType = 'polygon';

        // create event handler
        this.screenSpaceEventHandler = new Cesium.ScreenSpaceEventHandler(
            this.viewer.scene.canvas,
        );
        // mouse state
        this.m1Down = false;
        this.pickPoint = true;
        this.cartesian = new Cesium.Cartesian3();
        this.tempCartographic = new Cesium.Cartographic();
        
        this.pointIDs = [];

        this.polygons = [];
        this.getPolygons = new Cesium.CallbackProperty((time, result) => {
            if (this.polygons.length < 6) return null;
            return new Cesium.PolygonHierarchy(
                Cesium.Cartesian3.fromRadiansArray(this.polygons)
            );
        }, false);

        this.polylines = [];
        this.getPolylines = new Cesium.CallbackProperty((time, result) => {
            if (this.polylines.length < 6) return null;
            return Cesium.Cartesian3.fromRadiansArray(this.polylines);
        }, false);

        this.pointsEntityCollection = new Cesium.EntityCollection();
        this.removeViewerPoints = () => {
            this.pointIDs.forEach(item => {
                let entity = this.pointsEntityCollection.getById(item);
                if (!entity) return;
                if (!entity.point) return;
                this.viewer.entities.removeById(item);
            });
        };
        this.pointsEntityCollection.collectionChanged.addEventListener(
            (collection, added, removed, changed) => {
                if (added && added.length > 0) {
                    // console.log(added[0].id, 'added points', collection.values.length);
                    this.pointIDs.push(added[0].id);
                    let pos = added[0].position.getValue('');
                    let carto_ = new Cesium.Cartographic.fromCartesian(
                        pos,
                    );
                    this.polylines.push(carto_.longitude, carto_.latitude);
                }
                if (removed && removed.length > 0) {
                    // console.log(removed[0].id, 'removed points', collection.values.length);
                    this.pointIDs = this.pointIDs.filter(item => item !== removed[0].id);
                }
                if (changed && changed.length > 0) {
                }
            },
        );

        // shift m1click -> create point -> append to points
        this.screenSpaceEventHandler.setInputAction(
            (movement) => {
                let {position} = movement || {};
                if (!position) return;
                let feature = this.viewer.scene.pick(position);
                if (feature) {
                    let entity = this.pointsEntityCollection.getById(feature.id._id);
                    if (entity && entity.point) {
                        let idx_ = this.pointsEntityCollection.values.map(item => item.id)
                            .indexOf(feature.id._id);
                        if (this.pointsEntityCollection.values.length > 3 && idx_ === 0) {
                            this.pickPoint = false;
                            this.polygons = [...this.polylines];
                            this.polygons.push(this.polygons[0]);
                            this.polygons.push(this.polygons[1]);
                            this.removeViewerPoints();
                            this.polylines = [];
                            this.exportSelector();
                        }
                    }
                };
                
                if (!this.pickPoint) return;
                this.cartesian = this.camera.pickEllipsoid(
                    position,
                    this.viewer.scene.globe.ellipse,
                    this.cartesian,
                );
                if (!this.cartesian) return;
                this.tempCartographic = Cesium.Cartographic.fromCartesian(
                    this.cartesian,
                    Cesium.Ellipsoid.WGS84,
                    this.tempCartographic,
                );
                let added = this.viewer.entities.add({
                    position: this.cartesian,
                    point: {
                        pixelSize : 5,
                        color : Cesium.Color.RED,
                        outlineColor : Cesium.Color.WHITE,
                        outlineWidth : 2,
                        heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
                    }
                });
                // console.log(added.id);
                this.pointsEntityCollection.add(added);
            },
            Cesium.ScreenSpaceEventType.LEFT_CLICK,
            Cesium.KeyboardEventModifier.SHIFT,
        );

        // ctrl m1 -> remove point
        this.screenSpaceEventHandler.setInputAction(
            (movement) => {
                let {position} = movement || {};
                if (!position) return;
                let feature = this.viewer.scene.pick(position);
                if (!feature) return;
                let entity = this.pointsEntityCollection.getById(feature.id._id);
                if (!entity) return;
                if (!entity.point) return;
                let idx_ = this.pointsEntityCollection.values.map(item => item.id)
                    .indexOf(feature.id._id);
                if (idx_ >= 0) this.polylines.splice(idx_*2, 2);
                this.pointsEntityCollection.removeById(feature.id._id);
                this.viewer.entities.removeById(feature.id._id);
            },
            Cesium.ScreenSpaceEventType.LEFT_CLICK,
            Cesium.KeyboardEventModifier.CTRL,
        );

        // alt m2 -> reset selection
        this.screenSpaceEventHandler.setInputAction(
            () => {
                this.pickPoint = true;
                this.pointIDs.forEach(item => {
                    let entity = this.pointsEntityCollection.getById(item);
                    if (!entity) return;
                    if (!entity.point) return;
                    this.pointsEntityCollection.removeById(item);
                    this.viewer.entities.removeById(item);
                });

                // insecure, justincase.jpeg
                this.polylines = [];
                this.polygons = [];
            },
            Cesium.ScreenSpaceEventType.RIGHT_CLICK,
            Cesium.KeyboardEventModifier.ALT,
        );

        this.selector = this.viewer.entities.add({
            selectable: false,
            polyline: {
                positions: this.getPolylines,
                material: Cesium.Color.WHITE,
            },
            polygon: {
                hierarchy: this.getPolygons,
                material: Cesium.Color.BLACK.withAlpha(0.5),
            },
        });
        this.selectorCollection.add({
            polygon: {
                hierarchy: this.getPolygons,
                material: Cesium.Color.BLACK.withAlpha(0.5),
            },
        });
    }

    /**
     * Remove event draw polygon on viewer and reset selectorCollection
    */
    disableDrawPolyg() {
        this.exportCallback = null;
        if (!this.viewer) return;
        if (!this.screenSpaceEventHandler) return;
        if (this.drawType === 'polygon') {
            this.removeSelectorCollection();
            this.removeViewerPoints();
            this.viewer.entities.remove(this.selector);
            this.pointsEntityCollection.removeAll();
            this.screenSpaceEventHandler.removeInputAction(
                Cesium.ScreenSpaceEventType.LEFT_CLICK,
                Cesium.KeyboardEventModifier.SHIFT,
            );
            this.screenSpaceEventHandler.removeInputAction(
                Cesium.ScreenSpaceEventType.LEFT_CLICK,
                Cesium.KeyboardEventModifier.CTRL,
            );
            this.screenSpaceEventHandler.removeInputAction(
                Cesium.ScreenSpaceEventType.RIGHT_CLICK,
                Cesium.KeyboardEventModifier.ALT,
            );

            this.selector = null;
            this.drawType = null;
            this.removeViewerPoints = null;
            this.pointsEntityCollection = null;
            this.polylines = null;
            this.getPolylines = null;
            this.pointIDs = null;
            this.polygons = null;
            this.getPolygons = null;
            this.tempCartographic = null;
            this.cartesian = null;
            this.pickPoint = null;
            this.screenSpaceEventHandler = null;
        }
    }

    /**
     * Add event draw freehand polygon on viewer and save to selectorCollection
     * @param {Function} callback - callback that will bi executed after entity created
    */
    enableDrawFreehand(callback) {
        this.exportCallback = callback &&
            callback.constructor === Function &&
            callback;

        if (!this.viewer) return;
        this.createSelectorCollection();
        this.drawType = 'freehand';

        // create event handler
        this.screenSpaceEventHandler = new Cesium.ScreenSpaceEventHandler(
            this.viewer.scene.canvas,
        );
        // mouse state
        this.m1Down = false;

        this.cartesian = new Cesium.Cartesian3();
        this.tempCartographic = new Cesium.Cartographic();

        this.polygons = [];
        this.getPolygons = new Cesium.CallbackProperty((time, result) => {
            if (this.polygons.length < 6) return null;
            return new Cesium.PolygonHierarchy(
                Cesium.Cartesian3.fromRadiansArray(this.polygons)
            );
        }, false);
        this.polylines = [];
        this.getPolylines = new Cesium.CallbackProperty((time, result) => {
            if (this.polylines.length < 6) return null;
            return Cesium.Cartesian3.fromRadiansArray(this.polylines);
        }, false);

        // start mouse shift
        this.screenSpaceEventHandler.setInputAction(
            () => {
                this.polylines = [];
                this.m1Down = true;
            },
            Cesium.ScreenSpaceEventType.LEFT_DOWN,
            Cesium.KeyboardEventModifier.SHIFT,
        );

        // end mouse shift
        this.screenSpaceEventHandler.setInputAction(
            () => {
                if (!this.m1Down) return;
                this.m1Down = false;
                this.polygons = this.polylines.map(item => item);
                this.polygons.push(this.polygons[0]);
                this.polygons.push(this.polygons[1]);
                this.polylines = [];
                this.exportSelector();
            },
            Cesium.ScreenSpaceEventType.LEFT_UP,
            Cesium.KeyboardEventModifier.SHIFT,
        );

        // on dragged mouse
        this.screenSpaceEventHandler.setInputAction(
            (movement) => {
                if (!this.m1Down) return;
                // endposition = {"x": 642,"y": -72}
                this.cartesian = this.camera.pickEllipsoid(
                    movement.endPosition,
                    this.viewer.scene.globe.ellipse,
                    this.cartesian,
                );
                if (!this.cartesian) return;
                this.tempCartographic = Cesium.Cartographic.fromCartesian(
                    this.cartesian,
                    Cesium.Ellipsoid.WGS84,
                    this.tempCartographic,
                );
                this.polylines.push(this.tempCartographic.longitude);
                this.polylines.push(this.tempCartographic.latitude);
            },
            Cesium.ScreenSpaceEventType.MOUSE_MOVE,
            Cesium.KeyboardEventModifier.SHIFT,
        );
        this.selector = this.viewer.entities.add({
            polygon: {
                selectable: true,
                hierarchy: this.getPolygons,
                material: Cesium.Color.BLACK.withAlpha(0.5),
            },
            polyline: {
                positions: this.getPolylines,
                material: Cesium.Color.WHITE,
            },
        });
        this.selectorCollection.add({
            polygon: {
                hierarchy: this.getPolygons,
                material: Cesium.Color.BLACK.withAlpha(0.5),
            },
        });
    }

    /**
     * Remove event draw freehand polygon on viewer and reset selectorCollection
    */
    disableDrawFreehand() {
        this.exportCallback = null;
        if (!this.viewer) return;
        if (!this.screenSpaceEventHandler) return;
        if (this.drawType === 'freehand') {
            this.removeSelectorCollection();
            this.viewer.entities.remove(this.selector);
            this.screenSpaceEventHandler.removeInputAction(
                Cesium.ScreenSpaceEventType.LEFT_DOWN,
                Cesium.KeyboardEventModifier.SHIFT,
            );
            this.screenSpaceEventHandler.removeInputAction(
                Cesium.ScreenSpaceEventType.LEFT_UP,
                Cesium.KeyboardEventModifier.SHIFT,
            );
            this.screenSpaceEventHandler.removeInputAction(
                Cesium.ScreenSpaceEventType.MOUSE_MOVE,
                Cesium.KeyboardEventModifier.SHIFT,
            );

            this.selector = null;
            this.drawType = null;
            this.m1Down = null;
            this.cartesian = null;
            this.tempCartographic = null;
            this.polygons = null;
            this.getPolygons = null;
            this.polylines = null;
            this.getPolylines = null;
            this.screenSpaceEventHandler = null;
        }
    }
}

export {
    CesiumUtils,
};
