import React, { useEffect } from 'react'

import styled from 'styled-components'
import { LIST_HELP } from './constant'

const Paragraph = styled.p`
  margin: 0em;
  padding: 0.1em 0.5em;
  font-family: Abel;
  cursor: pointer;
  user-select: none;
  color: black;
  font-weight: ${props => props.title ? '800' : 'normal'};
  &:hover{
    background-color: #FCF0C8;
    font-weight: bold;
  }
`

/**
 * List detail help from main list
 * @param {object} props Component props
 * @param {int} props.key index of the help list
 * @param {string} props.helpKey is 
 * @param {string} props.detail is 
 * @param {string} props.opened is the parent of the content to be opened
 * @param {function} props.setOpened is set state of opened
 * @param {string} props.focus is the content that will be automatically scrolled
 * @param {function} props.setFocus is set state of focus
 * @returns list in navigation side bar
 */

function HelpListSearch({ helpKey, detail, focus, setFocus, opened, setOpened, keySearch}) {
  function handleOpen(helpKey) {
    const findIndex = opened.indexOf(helpKey)
    
    let cloneOpened = []
    
    if (findIndex === -1) {
      cloneOpened.push(helpKey)
    }
    
    setOpened(cloneOpened)
  }
  
  // useEffect(() =>{
  //   if(focus){
  //     setFocus(focus)
  //   }
  // },[opened])
      
  function toggleFocus(helpKey,keyDetail) {
    handleOpen(helpKey)
    setFocus(keyDetail)
  }
  const regex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g 

  return <>
    <div style={{margin:'0.2em 1em 0em 0.5em', padding:'0.5em'}}>
      <Paragraph title style={{fontSize:'1.5em'}} onClick={() => handleOpen(helpKey)}>
        {
          LIST_HELP[helpKey].title
        }
      </Paragraph>
      <hr style={{border:'1px solid goldenrod'}}/>
        {
          detail.map((keyDetail,index)=>{
            if(keyDetail.key===helpKey){
              if(keySearch===''){
                return (
                    <Paragraph
                      key={index}
                      style={{ paddingLeft: '1em' }}
                      onClick={() => 
                        toggleFocus(helpKey,keyDetail.keyDetail)
                      }
                    >
                      {LIST_HELP[keyDetail.key].detail[keyDetail.keyDetail].header}
                    </Paragraph>
                )
              } else {
                const fullList = LIST_HELP[keyDetail.key].detail[keyDetail.keyDetail].header
                const parts = fullList.toString().split(new RegExp(`(${keySearch})`, 'gi'));
                return (
                  <Paragraph
                    key={index}
                    style={{ paddingLeft: '1em' }}
                    onClick={() => 
                      toggleFocus(helpKey,keyDetail.keyDetail)
                    }
                  >
                    {
                      parts.map((part, i) => 
                      <span key={i} style={part.toLowerCase().replace(regex,'').replace(/ |_/g,'') === keySearch.toLowerCase().replace(regex,'').replace(/ |_/g,'') ? { fontWeight: 'bold', backgroundColor: '#FFFF00' } : {} }>
                        { part }
                      </span>
                      )
                    }
                  </Paragraph>
                )
              }  
            }
          })
        }
    </div>


 
  </>
}

export default HelpListSearch
