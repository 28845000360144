import React from "react";
import Navbar from "../../components/Menubars/Navbar";
import "./checkemail.css";
import CheckEmailLogo from "./check-email2.svg";

function CheckEmail (props) {
    
  return (
    <div className="content-block">
      <Navbar className="row"/>
      <div className="img-bg-big">
        <div className="container-reset input-email-form ">
            <div className="row">
                <img src={CheckEmailLogo} alt="Logo-reset-password" className=" logo-reset"/>
            </div>
            <div className="container-check-email">
                <p className="row text-center-reset">Confirm your email!</p>
                <p className="text-center-input">Your account has been successfully registered. To complete the process please check your email for a validation request.</p>
            </div>
        </div>
      </div>
    </div>
  );
}

export default CheckEmail