import { IonImageryProvider } from 'cesium'
import cesiumViewer from '../../apis/cesiumViewer'

import CesiumLayer from './cesiumLayer'

import { fetchMapData } from './fetchMapData'
import { fetchMapTilesetLegend } from './fetchMapTilesetLegend'
import { loadGeoJson } from '../../apis/cesiumMap/geoJson'
import { loadRaster } from '../../apis/cesiumMap/raster'
import { cesiumGenerateColor } from '../../apis/cesiumMap/generateColor'

import {
  STYLE_REFERENCES
} from '../../apis/cesiumMap/mapConfig/style'
import {
  DEFAULT_COLOR_SCALE
} from '../../constants/Colors/d3'

import Geometry from './geometry'

/**
 * This Class used to create custom map that integrate from cesium and our custom design
 */
class CesiumMap {
  /**
   * create new cesium map
   * @param {Object} payload - the option used to create instance
   * @param {Number} payload.id - id of cesium map
   * @param {Boolean} [payload.rasterFile] - is map type raster
   */
  constructor(payload) {
    this.id = payload.id
    this.geojson = null
    this.rasterFile = payload.rasterFile || null
    this.name = payload.name
    this.category = payload.category
    this.subCategory = payload.subCategory
    this.entityName = payload.entityName || payload.name + Math.random()
    this.type = payload.type
    this.colorScaleActive = false
    this.colorTemplateActive = false
    // COLOR SCALE FOR D3 Color Scale
    this.colorScale = payload.templateActive
      ? null
      : DEFAULT_COLOR_SCALE
    this.colorScaleAble = true
    // COLOR ENTITIES
    this.colorEntities = payload.colorEntities || []
    this.alpha = payload.alpha || 1
    this.defaultColor = payload.defaultColor || {}
    this.mapProperties = payload.mapProperties || null
    // TEMPLATES
    this.templates = []
    this.selectedTemplate = payload.selectedTemplate || null
    // 3D Model View
    this.extruded = payload.extruded || false
    this.ellipsoid = payload.ellipsoid || false
    this.pixelSize = payload.pixelSize || 10
    // ENHANCEMENT
    this.enhance = payload.enhance || false
    // RASTER
    this.raster = payload.raster || null
    // TILESET
    this.tileset = payload.tileset || null
    this.tilesetData = payload.tilesetData || null
    this.tilesetLegend = null
    this.tilesetId = payload.tilesetId || null
    // MODEL
    this.model = this.isModel(payload.layerType)
    // POINT, POLYLINE, POLYGON
    this.geom = null // automatically added when add geojson
    this.filter = payload.filter || null
    // CESIUM
    this.dataSource = null
    // FILTERING
    this.field = payload.field || null
    this.fields = payload.fields || []
    this.fieldFormat = payload.fieldFormat || null
    this.fieldFormatMode = payload.fieldFormatMode || null
    // HEATMAP
    this.heat = null
    // FOR RESET DEFAULT
    this.payload = this.cloneObject(payload, {})
    this.cesiumLayer = new CesiumLayer()
  }

  /**
   * Recursion method to deep clone object
   * @param {Object} object - object to clone
   * @param {Object} result - new clone object
   * @function
   * @return {Object} new clone object
   */
  cloneObject = (object, result) => {
    for (let key in object) {
      if (object[key] === null || object[key] === undefined) {
        result[key] = object[key]
      }
      else if (typeof object[key] === 'object' && !Array.isArray(object[key])) {
        result[key] = this.cloneObject(object[key], {})
      } else {
        result[key] = object[key]
      }
    }
    
    return result
  }

  addEntity = (entity) => {
    this.dataSource.entities.add(entity)

    this.colorEntities[entity.id] = {
      red: Math.random(),
      green: Math.random(),
      blue: Math.random(),
      alpha: this.alpha
    }
  }

  removeEntity = (entity) => {
    this.dataSource.entities.remove(entity)

    this.colorEntities[entity.id] = null
  }

  setGeojson = (geojson) => {
    this.geojson = geojson

    this.generateGeometry()
  }

  setRasterFile = (rasterFile) => {
    this.rasterFile = rasterFile
  }

  isModel = (layerType) => {
    if (
      layerType === 'Surface'
      || layerType === 'Solid'
      || layerType === 'Block'
    ) {
      return true
    } else {
      return false
    }
  }

  generateGeometry = () => {
    if (!(this.raster || this.tileset)) {
      this.geom = Geometry.generateGeometry(this)
    }
  }

  setDataSource = (dataSource) => {
    this.dataSource = dataSource
  }

  // GEOJSON
  fetchGeojson = async ({
    params,
    fetchProgress = () => {},
    fetchFinish = () => {},
    layerType
  }) => {
    const data = await fetchMapData({
      category: this.category,
      subCategory: this.subCategory,
      params,
      fetchProgress,
      fetchFinish,
      map: this,
      layerType
    })

    this.setGeojson(data.geojson)
  }

  getStyleReference = () => {
    for (let i = 0; i < STYLE_REFERENCES.length; i++) {
      if (
        STYLE_REFERENCES[i].category === this.category
        && STYLE_REFERENCES[i].subCategory === this.subCategory
      ) {
        return STYLE_REFERENCES[i]
      }
    }
  }

  generateDataSource = async () => {
    if (!this.geojson) {
      throw new Error('please fetch geojson first')
    }

    const styleReference = this.getStyleReference()

    if (styleReference) {
      this.dataSource = await loadGeoJson({
        map: this,
        name: this.name,
        geojson: this.geojson,
        colorCb: styleReference.getColor,
        getIcon: styleReference.getIcon,
        modifyEntity: styleReference.modifyEntity,
        onlyOutline: styleReference.onlyOutline
      })
    } else {
      this.dataSource = await loadGeoJson({
        map: this,
        name: this.name,
        geojson: this.geojson,
        colorCb: null
      })
    }
  }

  // RASTER
  fetchRaster = async ({
    params,
    fetchProgress = () => {},
    fetchFinish = () => {}
  }) => {
    const data = await fetchMapData({
      category: this.category,
      subCategory: this.subCategory,
      params,
      fetchProgress,
      fetchFinish,
      map: this
    })

    this.setRasterFile(data)
  }

  generateRasterDataSource = async ({
    fetchProgress = () => {},
    fetchFinish = () => {}
  }) => {
    if (!this.rasterFile) {
      throw new Error('please fetch raster file first')
    }

    // this.dataSource = await loadRaster({
    //   name: this.name,
    //   data: this.rasterFile,
    //   url: this.rasterFile.url,
    //   fetchProgress,
    //   fetchFinish
    // })
  }

  loadDataSource = ({ cesiumViewer }) => {
    if (cesiumViewer.viewer) {
      if (
        cesiumViewer.viewer.dataSources
          .indexOf(this.dataSource) === -1
      ) {
        cesiumViewer.viewer.dataSources.add(this.dataSource)
      }
    }
  }

  unloadDataSource = () => {
    if (cesiumViewer.viewer) {
      if (
        cesiumViewer.viewer.dataSources
          .indexOf(this.dataSource) !== -1
      ) {
        cesiumViewer.viewer.dataSources.remove(this.dataSource)
      }
    }
  }

  removeDataSource = () => {
    this.dataSource = null
    this.geojson = null
    this.rasterFile = null
  }

  // TILESET
  fetchTilesetId = async ({
    params,
    layerType = 'Tileset',
    fetchProgress = () => {},
    fetchFinish = () => {},
  }) => {
    const data = await fetchMapData({
      category: this.category,
      subCategory: this.subCategory,
      params,
      fetchProgress,
      fetchFinish,
      map: this,
      layerType
    })

    if (data?.tilesetId) {
      this.tilesetId = data.tilesetId
    }
  }
  
  fetchTilesetLegend = async ({
    params,
    fetchProgress = () => {},
    fetchFinish = () => {},
  }) => {
    const data = await fetchMapTilesetLegend({
      category: this.category,
      subCategory: this.subCategory,
      params,
      fetchProgress,
      fetchFinish,
      map: this
    })

    this.tilesetLegend = data
  }

  generateTileset = async () => {
    if(!this.tilesetId) {
      throw new Error('Please fetch tileset first')
    }
  }

  loadTileset = ({ cesiumViewer }) => {
    this.tilesetData = cesiumViewer.viewer.imageryLayers.addImageryProvider(
      new IonImageryProvider({ assetId: this.tilesetId })
    )

    this.tilesetData.alpha = this.alpha
  }

  unloadTileset = () => {
    if (cesiumViewer.viewer) {
      if (
        cesiumViewer.viewer.imageryLayers
          .indexOf(this.tilesetData) !== -1
      ) {
        cesiumViewer.viewer.imageryLayers.remove(this.tilesetData)
      }
    }
  }

  removeTileset = () => {
    // this.tileset = null
    // this.tilesetId = null
    this.tilesetData = null
  }

  resetDefault = () => {
    this.id = this.payload.id
    this.geojson = null
    this.rasterFile = this.payload.rasterFile || null
    this.name = this.payload.name
    this.category = this.payload.category
    this.subCategory = this.payload.subCategory
    this.entityName = this.payload.entityName || payload.name + Math.random()
    this.type = this.payload.type
    this.colorScaleActive = false
    this.colorTemplateActive = false
    // COLOR SCALE FOR D3 Color Scale
    this.colorScale = this.payload.templateActive
      ? null
      : DEFAULT_COLOR_SCALE
    this.colorScaleAble = true
    // COLOR ENTITIES
    this.colorEntities = this.payload.colorEntities || []
    this.alpha = this.payload.alpha || 1
    this.defaultColor = this.payload.defaultColor || {}
    this.mapProperties = this.payload.mapProperties || null
    // TEMPLATES
    this.templates = []
    this.selectedTemplate = this.payload.selectedTemplate || null
    // 3D Model View
    this.extruded = this.payload.extruded || false
    this.ellipsoid = this.payload.ellipsoid || false
    this.pixelSize = this.payload.pixelSize || 10
    // ENHANCEMENT
    this.enhance = this.payload.enhance || false
    // RASTER
    this.raster = this.payload.raster || null
    // TILESET
    this.tileset = this?.payload?.tileset || null
    this.tilesetData = this?.payload?.tilesetData || null
    this.tilesetId = this?.payload?.tilesetId || null
    this.tilesetLegend = null 
    // MODEL
    this.model = this.isModel(this.payload.layerType)
    // POINT, POLYLINE, POLYGON
    this.geom = null // automatically added when add geojson
    this.filter = this.payload.filter || null
    // CESIUM
    this.dataSource = null
    // FILTERING
    this.field = this.payload.field || null
    this.fields = this.payload.fields || []
    this.fieldFormat = this.payload.fieldFormat || null
    this.fieldFormatMode = this.payload.fieldFormatMode || null
    // HEATMAP
    this.heat = null
    // FOR RESET DEFAULT
    this.payload = this.cloneObject(this.payload, {})
    this.cesiumLayer = new CesiumLayer()
  }

  // create clone of this instance
  generateDefaultColor = () => {
    if (!this.dataSource) {
      throw new Error('no data source given')
    }

    const styleReference = this.getStyleReference()

    if (styleReference) {
      cesiumGenerateColor({
        map: this,
        colorCb: styleReference.getColor
      })
    }
  }
}

export default CesiumMap
