import React from 'react'

import DataTypeButton from './DataTypeButton'

import styled from 'styled-components'

const OuterWrapper = styled.div`
  position: absolute;
  top: -0.5em;
  left: 0;
`

const InnerWrapper = styled.div`
  display: flex;
  position: static;
  height: 1em;
`

function DropDownContainer({
  total,
  layerKeys,
  REFERENCES,
  onSelectLayer
}) {
  return <OuterWrapper total={total}>
    <InnerWrapper>
      {
        layerKeys.map((layer, index) => {
          return <DataTypeButton
            key={index}
            title={layer}
            onClickHandler={() => {
              onSelectLayer(layer)
            }}
            toggleAble={true}
            icon={REFERENCES[layer](false)}
          />
        })
      }
    </InnerWrapper>
  </OuterWrapper>
}

export default DropDownContainer
