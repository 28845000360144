import React from 'react'

import FocusContainer, {
  Header,
  LinkText,
  Paragraph
} from '../StyledComponent'
import CommodityPNG from '../HelpImage/dashboard_.png'
import { LIST_HELP } from '../constant'


function HelpCommodity(props) {
  const flyTo=(x)=>{
    props.setFocus(x)
  }
  return <>
    <FocusContainer {...props}>
      <Header>{LIST_HELP['dashboard'].detail.commodityOption.header}</Header>
      <Paragraph>
      The commodities in Geoprosp must be selected to get a prospect map and get the latest news about the selected commodity. It can be selected while on dashboard or on <LinkText onClick={()=>flyTo('menuBar')}>menubar</LinkText>.
      </Paragraph>
      <Paragraph>
      Commodities in Geoprosp include:
      </Paragraph>
      <Paragraph>
        1. Gold, Copper, Silver
      </Paragraph>
      <Paragraph>
        2. Bauxite
      </Paragraph>
      <Paragraph>
        3. Lead, Zinc
      </Paragraph>
      <Paragraph>
        4. Nickel
      </Paragraph>
      <Paragraph>
        5. Manganese
      </Paragraph>
      <Paragraph>
        6. Coal
      </Paragraph>
      <Paragraph>
        7. Iron
      </Paragraph>
      <Paragraph>
        8. Tin
      </Paragraph>
      <img src={CommodityPNG} alt="Dashboard image" width="1100px" style={{marginTop:'0.5em'}}/>
    </FocusContainer>
  </>
}

export default HelpCommodity
