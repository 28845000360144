import {
  JulianDate,
  PropertyBag
} from 'cesium'

import store from '../../../store'

import { EDITBAR_ARRAY_DATA } from '../../../constants/Forms/editbar'

import {
  saveMineralOccurrences,
  updateMineralOccurrences,
  removeMineralOccurrences
} from '../../../apis/server'

/**
 * this method use to get current active Occurrences map
 * @returns {CesiumMap} map instance of CesiumMap
 */
export function getOccurrenceMap() {
  const { display } = store.getState((state) => {
    return {
      display: state.display
    }
  })

  for (let i = 0; i < display.maps.length; i++) {
    if (display.maps[i].category === 'Occurrences') {
      return display.maps[i]
    }
  }
}

/**
 * This method used to prepare payload for save or update
 * @param {Array} listForm - list form for payload cesium entity content
 */
export function preparePayloadSaveUpdate(listForm) {
  const payload = {}

  for (let i = 0; i < listForm.length; i++) {
    if (listForm[i].value === '' && listForm[i].required) {
      throw new Error('Please solve all warning in form')
    } else if (listForm[i].value === '') {
      payload[listForm[i].title] = null
    } else if (listForm[i].dataType === 'number') {
      payload[listForm[i].title] = Number(listForm[i].value)
    } else {
      payload[listForm[i].title] = listForm[i].value
    }
  }

  return payload
}

/**
 * This method used to save mineral occurrences
 * @param {Cesium.Entity} entity - current cesium entity
 * @param {Array} listForm - list form for payload cesium entity content
 */
export async function saveHandler(entity, listForm) {
  try {
    const payload = preparePayloadSaveUpdate(listForm)
  
    const { properties } = await saveMineralOccurrences({
      payload
    })

    for (let title in payload) {
      if (EDITBAR_ARRAY_DATA[title] && payload[title]) {
        payload[title] = payload[title].split(',')
      }
    }
  
    entity.name = properties.deposite_name
    entity.properties = new PropertyBag(properties)
  } catch (error) {
    throw error
  }
}

/**
 * This method used to update mineral occurrences 
 * @param {Cesium.Entity} entity - current cesium entity
 * @param {Array} listForm - list form for payload cesium entity content
 */
export async function updateHandler(entity, listForm) {
  const id = entity.properties.id.getValue(JulianDate.now())

  const payload = preparePayloadSaveUpdate(listForm)

  await updateMineralOccurrences({
    id,
    payload
  })

  for (let title in payload) {
    if (EDITBAR_ARRAY_DATA[title] && payload[title]) {
      payload[title] = payload[title].split(',')
    }
  }

  entity.name = payload.deposite_name
  entity.properties = new PropertyBag({
    ...payload,
    id
  })
}

/**
 * This method used to delete mineral occurrences 
 * @param {Cesium.Entity} entity - current cesium entity
 */
export async function deleteHandler(entity) {
  const id = entity.properties.id.getValue(JulianDate.now())

  await removeMineralOccurrences({
    id
  })

  const map = getOccurrenceMap()

  if (map) {
    map.removeEntity(entity)
  }
}

/**
 * This method used to delete mineral occurrences that haven't being saved on the database
 * @param {Cesium.Entity} entity - current cesium entity
 */
export function removeEntityHandler(entity) {
  const map = getOccurrenceMap()

  if (map) {
    map.removeEntity(entity)
  }
}
