import React from 'react'

import styled from 'styled-components'

const DetailNewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
`

const DetailNewsTitle = styled.a`
  display: flex;
  color: rgb(129, 129, 255);
  font-size: 1.2em;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const DetailSubjectNewsHeader = styled.div`
  font-size: 0.75em;
`

const DetailSubjectNews = styled.div`
  font-size: 0.85em;
`

const NewsDate = styled.span`
  color: #c1c1c1;
`

function DetailNews({
  publishedDate,
  publisher,
  simpleDescription,
  title,
  webUrl
}) {
  function convertDate(date) {
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    return new Date(date).toLocaleDateString('en-US', options)
  }

  return <DetailNewsContainer>
    <DetailNewsTitle href={webUrl} target='_blank'>
      {title}
    </DetailNewsTitle>
    <DetailSubjectNewsHeader>
      <NewsDate>{convertDate(publishedDate)}</NewsDate>
      {` - ${publisher}`}
    </DetailSubjectNewsHeader>
    <DetailSubjectNews>
      {simpleDescription}
    </DetailSubjectNews>
  </DetailNewsContainer>
}

export default DetailNews
