import * as Cesium from 'cesium'
import * as d3 from 'd3'

import cesiumViewer from '../cesiumViewer'

import getCesiumHeat from 'cesiumjs-heat'

import { findKeyProperties } from '../../packages/cesium-add-on/properties'

/**
 * Method to load heatmap on the cesium map
 * @param key is groupBy
 * @param features is the geojson features
 * @param bdl is the below detection limit
 * @return instance of heat instance 
 */
 export async function loadHeatMap({
  key,
  entities,
  min = 0,
  max = 0
}) {
  const CesiumHeat = getCesiumHeat(Cesium)

  const data = []

  let minLong = Number.MAX_SAFE_INTEGER
  let maxLong = Number.MIN_SAFE_INTEGER
  let minLat = Number.MAX_SAFE_INTEGER
  let maxLat = Number.MIN_SAFE_INTEGER

  let total = 0

  for (let i = 0; i < entities.length; i++) {
    const { properties, position } = entities[i]

    let score = findKeyProperties({
      properties,
      key
    })

    if (score < min) {
      score = min
    }

    total += score

    if (score && position) {
      const cartographic = Cesium
        .Cartographic
        .fromCartesian(position.getValue(Cesium.JulianDate.now()))

      if (isNaN(score)) {
        continue
      }

      if (max < score) {
        max = score
      }      

      const long = Cesium.Math.toDegrees(cartographic.longitude)
      const lat = Cesium.Math.toDegrees(cartographic.latitude)

      data.push({
        x: long,
        y: lat,
        value: score
      })

      if (maxLong < long) {
        maxLong = long
      }

      if (minLong > long) {
        minLong = long
      }

      if (maxLat < lat) {
        maxLat = lat
      }

      if (minLat > lat) {
        minLat = lat
      }
    }
  }

  let average = total / entities.length * (max - min)

  for (let i = 0; i < data.length; i++) {
    if (data[i].value < average) {
      data[i].value = 0
    } else {
      data[i].value *= entities.length
    }
  }

  let heat = null

  if (
    data.length
  ) {
    heat = await new CesiumHeat(
      cesiumViewer.viewer,
      {
        autoMaxMin: true,
        min,
        max,
        data
      },
      [ minLong - 0.2, minLat - 0.1, maxLong + 0.2, maxLat + 0.1 ],
      {},
      {
        enabled: true,
        min: 6375000,
        max: 10000000,
        maxRadius: 480,
        minRadius: 5
      }
    )
  }

  return heat
}

/**
 * This function will add heat map to object map
 * @param {Object} map is the current map to add heat map
 * @param {String} groupBy is the string of current filter condition
 */
export async function addHeatMap({
  map,
  groupBy,
  entities
}) {
  if (map.cesiumLayer.heat) {
    await map.cesiumLayer.heat.destory()
    map.cesiumLayer.setHeat(null)
  }

  if (!map.cesiumLayer.heat) {
    const heat = await loadHeatMap({
      key: groupBy,
      entities,
    })            
    
    map.cesiumLayer.setHeat(heat)
  }
}

/**
 * This function will remove heat map from the object
 * @param {Object} map is the current map to add heat map
 */
export async function removeHeatMap({
  map
}) {
  if (map.cesiumLayer.heat) {
    await map.cesiumLayer.heat.destory()
    map.cesiumLayer.setHeat(null)
  }
}
