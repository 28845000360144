import React from 'react'

import { SvgIcon } from '../../shares/SvgIcon'

import {
  ReactComponent as UpIcon
} from '../../../images/Icons/up.svg'

import {
  ReactComponent as DownIcon
} from '../../../images/Icons/down.svg'

import {
  ReactComponent as AscendingIcon
} from '../../../images/Icons/ascending-filter.svg'

import {
  ReactComponent as FilterIcon
} from '../../../images/Icons/filter.svg'

import styled from 'styled-components'

const DropdownSVG = styled(SvgIcon)`
  position: absolute;
  top: 0.1em;
  right: 0.3em;
`

function DropdownIcon({
  dropdown,
  fill,
  type
}) {
  if (type === 'sort') {
    return <DropdownSVG fill={fill} width="0.8em" height="1em">
      <AscendingIcon />
    </DropdownSVG>
  } else if (type === 'filter') {
    return <DropdownSVG fill={fill} width="0.8em" height="1em">
      <FilterIcon />
    </DropdownSVG>
  }

  return dropdown
    ? <DropdownSVG fill={fill} width="0.8em" height="1em">
      <UpIcon />
    </DropdownSVG>
    : <DropdownSVG fill={fill} width="0.8em" height="1em">
      <DownIcon />
    </DropdownSVG>
}

export default DropdownIcon
