import {
  call,
  fork,
  put,
  putResolve,
  select,
  takeEvery
} from 'redux-saga/effects'

import cesiumViewer from '../../apis/cesiumViewer'

import * as catalogActionTypes from '../../constants/store/actionTypes/catalog'
import * as catalogActions from '../actions/catalog'
import * as displayActions from '../actions/display'
import * as legendActions from '../actions/legendbar'
import * as cesiumEntityActions from '../actions/cesiumEntity'

import {
  startAction,
  stopAction,
  refreshActionStart,
  refreshActionStop
} from '../actions/uiActions'

import * as serverAPI from '../../apis/server'

import LayerManagerState from '../../constants/Templates/catalog'

import {
  generateCesiumMaps
} from '../../apis/cesiumMap/generateCesiumMaps'

import RequestParams from '../../apis/generateParams'

export function* setCatalogSaga({ type, payload }) {
  try {
    yield put(
      payload && payload.refreshing 
        ? refreshActionStart(type)
        : startAction(type)
    )
    
    let catalogData = yield call(serverAPI.getCatalogTemplate)

    const user = yield select((state) => state.user)

    catalogData = catalogData
      .map((catalog) => {
        if (
          catalog.subcription_type
          && catalog.subcription_type.includes(user.tierDesc.toLowerCase())
        ) {
          return catalog
        }
      }).filter(catalog => catalog)

    const catalog = yield call(LayerManagerState.generateLayerManager, catalogData)

    yield putResolve(catalogActions.setCatalogSuccess({
      state: catalog
    }))

    const commodity = yield select(state => state.sites.commodity)

    if (commodity) {
      yield put(catalogActions.fetchListMap({}))
    }
  } catch (error) {
    yield put(catalogActions.setCatalogError({
      error
    }))
  } finally {
    yield put(
      payload && payload.refreshing
        ? refreshActionStop(type)
        : stopAction(type)
    )
  }
}

export function* changeTypeLayerSaga({ type, payload }) {
  try {
    yield put(
      payload && payload.refreshing 
        ? refreshActionStart(type)
        : startAction(type)
    )

    yield putResolve(catalogActions.changeTypeLayerSuccess({
      category: payload.category,
      layerType: payload.newLayerType
    }))

    const { catalog } = yield select((state) => ({
      catalog: state.catalog,
    }))

    yield call(fetchListMapSingleCategorySaga, {
      catalog,
      key: payload.category
    })
  } catch (error) {
    yield put(catalogActions.changeTypeLayerError({
      error
    }))
  } finally {
    yield put(
      payload && payload.refreshing
        ? refreshActionStop(type)
        : stopAction(type)
    )
  }
}

export function* fetchListMapSingleCategorySaga({
  catalog,
  key
}) { 
  // fetch map di sini
  if (catalog[key].level === 1 && catalog[key].listMap) {
    yield put(catalogActions.fetchMap({
      ...catalog[key],
      category: key,
      subCategory: null
    }))
  } else if (catalog[key].level === 2) {
    for (let subKey in catalog[key].layerTypeDetail[catalog[key].layerType]) {
      if (catalog[key].layerTypeDetail[catalog[key].layerType][subKey].listMap) {
        yield put(catalogActions.fetchMap({
          ...catalog[key].layerTypeDetail[catalog[key].layerType][subKey],
          category: key,
          subCategory: subKey
        }))
      }
    }
  }
}

export function* fetchListMapSaga({ type, payload }) {
  try {
    yield put(
      payload && payload.refreshing 
        ? refreshActionStart(type)
        : startAction(type)
    )

    let { catalog } = yield select((state) => {
      return {
        catalog: state.catalog,
      }
    })

    for (let key in catalog) {
      yield call(fetchListMapSingleCategorySaga, {
        catalog,
        key
      })
    }
  } catch (error) {
    yield put(catalogActions.fetchListMapError({
      error
    }))
  } finally {
    yield put(
      payload && payload.refreshing
        ? refreshActionStop(type)
        : stopAction(type)
    )
  }
}

export function* fetchMapSaga({ type, payload }) {
  // fix this next
  try {
    yield put(
      payload && payload.refreshing 
        ? refreshActionStart(type)
        : startAction(type)
    )

    const catalogMap = yield select(({ catalog }) => catalog[payload.category])

    // CHECK IS READY TO SHOW OR ON PROGRESS
    if (payload.onProgress) {
      yield putResolve(catalogActions.fetchMapSuccess({}))

      return
    }

    if (!catalogMap.level) {
      yield put(catalogActions.fetchMapSuccess({
        level: 0
      }))

      return
    }

    let listMap = yield call(generateCesiumMaps, {
      category: payload.category,
      subCategory: payload.subCategory,
      fetchProgress: payload.fetchProgress,
      fetchFinish: payload.fetchFinish,
      raster: payload.layerType === 'Raster',
      tileset: payload.layerType === 'Tileset',
      layerType: payload.layerType,
      layerBy: payload.layerBy,
      templateActive: payload.templateActive,
      field: payload.field,
      fields: payload.fields,
      fieldFormat: payload.fieldFormat,
      fieldFormatMode: payload.fieldFormatMode,
      templateName: payload.templateName
    })

    yield putResolve(catalogActions.fetchMapSuccess({
      category: payload.category,
      subCategory: payload.subCategory,
      level: catalogMap.level,
      layerType: payload.layerType,
      listMap
    }))

    if (listMap.length && payload.renderFirstMap) {
      // yield put(catalogActions.addMap({
      //   map: listMap[0],
      //   category: payload.category,
      //   subCategory: payload.type
      // }))
    }
  } catch (error) {
    yield put(catalogActions.fetchMapError({
      error
    }))
  } finally {
    yield put(
      payload.refreshing
        ? refreshActionStop(type)
        : stopAction(type)
    )
  }
}

export function* updateNewListMap({
  catalogMap,
  map
}) {
  const listMap = []
  
  if (catalogMap.level === 1) {
    for (let i = 0; i < catalogMap.listMap.length; i++) {
      if (catalogMap.listMap[i].id !== map.id) {
        listMap.push(catalogMap.listMap[i])
      } else {
        listMap.push(map)
      }
    }
  } else if (catalogMap.level === 2) {
    for (let i = 0; i < catalogMap.subCategory[map.type].listMap.length; i++) {
      if (catalogMap.subCategory[map.type].listMap[i].id !== map.id) {
        listMap.push(catalogMap.subCategory[map.type].listMap[i])
      } else {
        listMap.push(map)
      }
    }
  }

  return listMap
}


export function* addMapSaga({ type, payload }) {
  try {
    yield put(
      payload && payload.refreshing 
        ? refreshActionStart(type)
        : startAction(type)
    )

    if (payload.map.raster) {
      if (!payload.map.rasterFile) {
        yield call(payload.map.fetchRaster, {
          params: RequestParams.FETCH_RASTER(),
          fetchProgress: payload.fetchProgress,
          fetchFinish: payload.fetchFinish
        })
      }

      yield call(payload.map.generateRasterDataSource, {
        fetchProgress: payload.fetchProgress,
        fetchFinish: payload.fetchFinish
      })

      yield call(payload.map.loadDataSource, { cesiumViewer })
    } else if (payload.map.model) {
      const { layerType } = yield select(state => ({
        layerType: state.catalog[payload.category].layerType
      }))

      // need to discussed for load 3d model
      // yield call(payload.map.fetchGeojson, {
      //   params: RequestParams.FETCH_GEOJSON_WITHOUT_SUMMARY(),
      //   fetchProgress: payload.fetchProgress,
      //   fetchFinish: payload.fetchFinish,
      //   layerType
      // })

      // yield call(payload.map.generateDataSource)
      // yield call(payload.map.loadDataSource)
    } else if(payload.map.tileset) {
      // if (!payload.map.tilesetId) {
      //   yield call(payload.map.fetchTilesetId, {
      //     params: RequestParams.FETCH_TILESET(),
      //     bin_size: 8000,
      //     fetchProgress: payload.fetchProgress,
      //     fetchFinish: payload.fetchFinish
      //   })
      // }
      yield call(payload.map.generateTileset)
      yield call(payload.map.loadTileset, { cesiumViewer })
      // yield call(payload.fetchFinish)
    } else {
      const { layerType } = yield select(state => ({
        layerType: state.catalog[payload.category].layerType
      }))

      yield call(payload.map.fetchGeojson, {
        params: RequestParams.FETCH_GEOJSON_WITHOUT_SUMMARY(),
        fetchProgress: payload.fetchProgress,
        fetchFinish: payload.fetchFinish,
        layerType
      })

      yield call(payload.map.generateDataSource)
      yield call(payload.map.loadDataSource, { cesiumViewer })
    }

    yield call(payload.map.cesiumLayer.initialize, { map: payload.map })

    yield put(catalogActions.addMapSuccess())

    yield put(displayActions.addToDisplay({
      map: payload.map
    }))

    yield put(displayActions.setFocusMap({
      map: payload.map
    }))

    yield put(legendActions.addLegend({
      name: payload.map.name,
      map: payload.map
    }))
  } catch (error) {
    yield put(catalogActions.addMapError({
      error
    }))
  } finally {
    yield call(payload.fetchFinish)
    yield put(
      payload.refreshing
        ? refreshActionStop(type)
        : stopAction(type)
    )
  }
}

export function* toggleEntitySaga({ type, payload }) {
  try {
    yield put(
      payload && payload.refreshing 
        ? refreshActionStart(type)
        : startAction(type)
    )

    if (payload.dataSource) {
      payload.dataSource.show = !payload.dataSource.show
    } else if (payload.entity) {
      payload.entity.show = !payload.entity.show
    } else if(payload.tilesetData) {
      payload.tilesetData.show = !payload.tilesetData.show
    }

    yield put(catalogActions.toggleEntitySuccess())
    yield put(cesiumEntityActions.triggerRender())
  } catch (error) {
    yield put(catalogActions.toggleEntityError({
      error
    }))
  } finally {
    yield put(
      payload.refreshing
        ? refreshActionStop(type)
        : stopAction(type)
    )
  }
}

export function* checkLayerCategorySaga({ type, payload }) {
  try {
    yield put(
      payload && payload.refreshing 
        ? refreshActionStart(type)
        : startAction(type)
    )

    yield put(catalogActions.checkLayerCategorySuccess({
      category: payload.category
    }))
  } catch (error) {
    yield put(catalogActions.checkLayerCategoryError({
      error
    }))
  } finally {
    yield put(
      payload.refreshing
        ? refreshActionStop(type)
        : stopAction(type)
    )
  }
}

export function* uncheckLayerCategorySaga({ type, payload }) {
  try {
    yield put(
      payload && payload.refreshing 
        ? refreshActionStart(type)
        : startAction(type)
    )

    yield put(catalogActions.uncheckLayerCategorySuccess({
      category: payload.category
    }))
  } catch (error) {
    yield put(catalogActions.uncheckLayerCategoryError({
      error
    }))
  } finally {
    yield put(
      payload.refreshing
        ? refreshActionStop(type)
        : stopAction(type)
    )
  }
}

export function* checkSubLayerCategorySaga({ type, payload }) {
  try {
    yield put(
      payload && payload.refreshing 
        ? refreshActionStart(type)
        : startAction(type)
    )

    const catalogMap = yield select(({ catalog }) => catalog[payload.category])

    if (catalogMap.level >= 2) {
      yield put(catalogActions.checkSubLayerCategorySuccess({
        level: catalogMap.level,
        category: payload.category,
        subCategory: payload.subCategory
      }))
    } else {
      yield put(catalogActions.checkSubLayerCategorySuccess({
        level: 0
      }))
    }
  } catch (error) {
    yield put(catalogActions.checkSubLayerCategoryError({
      error
    }))
  } finally {
    yield put(
      payload.refreshing
        ? refreshActionStop(type)
        : stopAction(type)
    )
  }
}

export function* uncheckSubLayerCategorySaga({ type, payload }) {
  try {
    yield put(
      payload && payload.refreshing 
        ? refreshActionStart(type)
        : startAction(type)
    )

    const catalogMap = yield select(({ catalog }) => catalog[payload.category])

    if (catalogMap.level === 3) {
      yield put(catalogActions.uncheckSubLayerCategorySuccess({
        level: catalogMap.level,
        category: payload.category,
        subCategory: payload.subCategory
      }))
    }
    else if (catalogMap.level === 2) {
      yield put(catalogActions.uncheckSubLayerCategorySuccess({
        level: catalogMap.level,
        category: payload.category,
        subCategory: payload.subCategory
      }))
    } else {
      yield put(catalogActions.uncheckSubLayerCategorySuccess({
        level: 0,
        category: ''
      }))
    }
  } catch (error) {
    yield put(catalogActions.uncheckSubLayerCategoryError({
      error
    }))
  } finally {
    yield put(
      payload.refreshing
        ? refreshActionStop(type)
        : stopAction(type)
    )
  }
}

export function* removeMapSaga({ type, payload }) {
  try {
    yield put(
      payload && payload.refreshing 
        ? refreshActionStart(type)
        : startAction(type)
    )

    if (payload.map) {
      yield call(payload.map.unloadDataSource)
      yield call(payload.map.unloadTileset)
      yield call(payload.map.removeDataSource)
      yield call(payload.map.removeTileset)

      yield put(catalogActions.removeMapSuccess({}))
  
      yield put(displayActions.removeFromDisplay({
        map: payload.map
      }))

      yield put(legendActions.removeLegend({
        name: payload.map.name
      }))

      yield call(payload.map.resetDefault)
    } else {
      yield put(catalogActions.removeMapError({
        category: '',
        level: 0
      }))
    }
  } catch (error) {
    yield put(catalogActions.removeMapError({
      error
    }))
  } finally {
    yield put(
      payload.refreshing
        ? refreshActionStop(type)
        : stopAction(type)
    )
  }
}

export function* removeSubCategoryProjectByCategory({
  catalogMap
}) {
  for (let i = 0; i < catalogMap.subCategory.project.listMap.length; i++) {
    if (catalogMap.subCategory.project.listMap[i].entityName) {
      const entities = cesiumViewer.viewer.dataSources.getByName(
        catalogMap.subCategory.project.listMap[i].entityName
      )
      
      if (entities && entities.length) {
        cesiumViewer.viewer.dataSources.remove(entities[0], true)
      }

      yield put(displayActions.removeFromDisplay({
        map: catalogMap.subCategory.project.listMap[i]
      }))
    }
  }
}

export function* removeAllMapByCategory({
  catalogMap
}) {
  if (catalogMap.level === 1) {
    for (let i = 0; i < catalogMap.listMap.length; i++) {
      if (catalogMap.listMap[i].entityName) {
        const entities = cesiumViewer.viewer.dataSources.getByName(
          catalogMap.listMap[i].entityName
        )
        
        if (entities && entities.length) {
          cesiumViewer.viewer.dataSources.remove(entities[0], true)
        }

        yield put(displayActions.removeFromDisplay({
          map: catalogMap.listMap[i]
        }))
      }
    }
  } else if (catalogMap.level === 2) {
    for (let key in catalogMap.subCategory) {
      for (let i = 0; i < catalogMap.subCategory[key].listMap.length; i++) {
        if (catalogMap.subCategory[key].listMap[i].entityName) {
          const entities = cesiumViewer.viewer.dataSources.getByName(
            catalogMap.subCategory[key].listMap[i].entityName
          )
          
          if (entities && entities.length) {
            cesiumViewer.viewer.dataSources.remove(entities[0], true)
          }
  
          yield put(displayActions.removeFromDisplay({
            map: catalogMap.subCategory[key].listMap[i]
          }))
        }
      }
    }
  }
}

export function* removeProjectMapSaga({ type, payload }) {
  try {
    yield put(
      payload && payload.refreshing 
        ? refreshActionStart(type)
        : startAction(type)
    )

    const catalog = yield select(({ catalog }) => catalog)
    
    yield call(removeSubCategoryProjectByCategory, {
      catalogMap: catalog.geological
    })

    yield call(removeSubCategoryProjectByCategory, {
      catalogMap: catalog.structure
    })

    // below here remove all, because all data is project scale
    // yield call(removeAllMapByCategory, {
    //   catalogMap: catalog.geochemical
    // })

    // yield call(removeAllMapByCategory, {
    //   catalogMap: catalog.mineral
    // })

    // yield call(removeAllMapByCategory, {
    //   catalogMap: catalog.alteration
    // })

    // yield call(removeAllMapByCategory, {
    //   catalogMap: catalog.borehole
    // })

    // yield call(removeAllMapByCategory, {
    //   catalogMap: catalog.threeDimension
    // })

    yield put(catalogActions.removeProjectMapSuccess({}))
  } catch (error) {
    yield put(catalogActions.removeProjectMapSuccess({
      error
    }))
  } finally {
    yield put(
      payload.refreshing
        ? refreshActionStop(type)
        : stopAction(type)
    )
  }
}

export function* removeAllMapSaga({ type, payload = {} }) {
  try {
    yield put(
      payload && payload.refreshing 
        ? refreshActionStart(type)
        : startAction(type)
    )

    const displayMaps = yield select(({ display }) => display.maps)

    // not implemented yet
    // if (cesiumViewer.viewer.dataSources.getByName('prospectArea')) {
    //   cesiumViewer.viewer.dataSources.remove(
    //     cesiumViewer.viewer.dataSources.getByName('prospectArea')[0],
    //     true
    //   )
    // }

    for (let i = 0; i < displayMaps.length; i++) {
      yield put(catalogActions.removeMap({
        map: displayMaps[i]
      }))
    }

    yield put(catalogActions.removeAllMapSuccess({}))
  } catch (error) {
    yield put(catalogActions.removeProjectMapError({
      error
    }))
  } finally {
    yield put(
      payload.refreshing
        ? refreshActionStop(type)
        : stopAction(type)
    )
  }
}

export function* watchMapSaga() {
  yield takeEvery(catalogActionTypes.SET_CATALOG, setCatalogSaga)
  yield takeEvery(catalogActionTypes.CHANGE_TYPE_LAYER, changeTypeLayerSaga)
  yield takeEvery(catalogActionTypes.FETCH_LIST_MAP, fetchListMapSaga)
  yield takeEvery(catalogActionTypes.FETCH_MAP, fetchMapSaga)
  yield takeEvery(catalogActionTypes.ADD_MAP, addMapSaga)
  yield takeEvery(catalogActionTypes.TOGGLE_ENTITY, toggleEntitySaga)
  yield takeEvery(catalogActionTypes.CHECK_LAYER_CATEGORY, checkLayerCategorySaga)
  yield takeEvery(catalogActionTypes.UNCHECK_LAYER_CATEGORY, uncheckLayerCategorySaga)
  yield takeEvery(catalogActionTypes.CHECK_SUB_LAYER_CATEGORY, checkSubLayerCategorySaga)
  yield takeEvery(catalogActionTypes.UNCHECK_SUB_LAYER_CATEGORY, uncheckSubLayerCategorySaga)
  yield takeEvery(catalogActionTypes.REMOVE_MAP, removeMapSaga)
  yield takeEvery(catalogActionTypes.REMOVE_PROJECT_MAP, removeProjectMapSaga)
  yield takeEvery(catalogActionTypes.REMOVE_ALL_MAP, removeAllMapSaga)
}

const sagaHelpers = [
  fork(watchMapSaga)
]

export default sagaHelpers
