import React, { useState, useEffect } from 'react'

import InputText from '../../../Input/InputText'
import InputMultipleText from '../../../Input/InputMultipleText'

import styled from 'styled-components'

const LineWrapper = styled.div`
  display: flex;
  margin-bottom: 0.3em;
  align-items: flex-start;
`

const ContentWrapper = styled.div`
  display: flex;
  font-size: 0.8em;
  width: 10em;
  align-items: center;
  margin: 0.2em;
`

/**
 * This component will create input form
 * @param {String} showTitle - title to show
 * @param {ReactComponentElement} Icon - react icon component
 * @param {String} value - current value to add / edit
 * @param {String} type - form type (input | multiInput)
 * @param {Boolean} required - file is required or not
 * @param {String} dataType - string to show format data type {String} dataType - string to show format data type
 * @param {Function} changeField - method to current change field
 * @param {Array} fullRecommendation - array of this input recommendation
 */
function InputForm({
  showTitle,
  Icon,
  value,
  type,
  dataType,
  required,
  changeField,
  fullRecommendation
}) {
  const [ error, setError ] = useState(null)

  useEffect(() => {
    setError(null)

    const timeout = setTimeout(() => {
      validate(value)
    }, 330)

    return () => {
      clearTimeout(timeout)
    }
  }, [value])

  function onChangeHandler(value) {
    if (
      dataType === 'string'
      && typeof value === 'string'
    ) {
      changeField(showTitle, value)
    } else if (
      dataType === 'number'
      && !isNaN(Number(value))
    ) {
      changeField(showTitle, value)
    }
  }
  
  function validate(value) {
    if (value === '' && required) {
      setError('Please fill input ' + showTitle)
    }
    
    if (
      dataType === 'string'
      && typeof value === 'string'
      && required
    ) {
      if (!value) {
        setError('Please fill input ' + showTitle)
      }
    }

    if (
      dataType === 'number' && required
    ) {
      if (isNaN(Number(value))) {
        setError(`Field ${showTitle} must be filled number`)
      }
    }
  }

  return <LineWrapper>
    <ContentWrapper>
      {Icon}
      <span
        style={{
          marginLeft: '0.4em',
          cursor: 'default'
        }}
      >{showTitle}</span>
    </ContentWrapper>
    {
      type === 'input'
        && <InputText
          width="13em"
          value={value}
          setValue={onChangeHandler}
          errorMessage={error}
          fullRecommendation={fullRecommendation}
        />
    }
    {
      type === 'multiInput'
        && <InputMultipleText
          width="11.2em"
          value={value}
          setValue={onChangeHandler}
          errorMessage={error}
          fullRecommendation={fullRecommendation}
        />
    }
  </LineWrapper>
}

export default InputForm
