import styled from 'styled-components';

export const CesiumWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background: black;
`;
export const CesiumContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 0;
    // transition: all 500ms;
`;

export const CesiumToolbarButton = styled.button.attrs(({className}) => ({
    className: 'cesium-button cesium-toolbar-button',
}))`
`;