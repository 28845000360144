import React from 'react'

import { connect } from 'react-redux'

import {
  toggleSidebarButton
} from '../../../store/actions/sidebarToggle'

import {
  changeCommodity
} from '../../../store/actions/sites'

import {
  generateStyle,
  selectDetail
} from './helpers'

import './GlobeDetailButton.css'
import buttonPNG from '../../../images/Others/button-black.png'
import goldRingPNG from '../../../images/Others/gold-ring.png'
import silverRingPNG from '../../../images/Others/silver-ring.png'

function GlobeDetailButton ({
  changeCommodity,
  data,
  initialDegree,
  scrollCount,
  sites,
  toggleSidebarButton
}) {
  const silverStyles = {}
  const goldStyles = {}
  const styles = {}

  generateStyle(
    goldStyles,
    initialDegree,
    scrollCount,
    silverStyles,
    styles,
    sites
  )

  return <>
    <div
      style={styles}
      className="globe-yellow-button"
      onClick={ 
        () => {
          selectDetail(
            changeCommodity,
            data,
            toggleSidebarButton
          )
        }
    }>
      <img src={goldRingPNG} style={goldStyles} alt="gold-ring" className="gold-ring" />
      <img src={silverRingPNG} style={silverStyles} alt="silver-ring" className="silver-ring" />
      <img src={buttonPNG} alt="button" className="black-globe-button" />
      <p className="globe-text globe-font-size gold-font">{data.displayName}</p>
    </div>
  </>
}

function mapStateToProps ({
  sites
}) {
  return {
    sites
  }
}

const mapDispatchToProps = {
  changeCommodity,
  toggleSidebarButton
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobeDetailButton)
