import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'

import {
  setDisplay
} from '../../../../store/actions/display'

import DefaultSearch from '../../../SearchBoxs/DefaultSearch'

import FlexWrapper from '../shares/FlexWrapper'
import Header from '../shares/Header'

import Content from './Content'

function Display({
  display,
  setDisplay
}) {
  const [ displayToggle, setDisplayToggle ] = useState(true)
  const [ data, setData ] = useState([])

  /**
   * This method used to search map by keyword
   * @param {string} keyword
   */
  function searchDisplay(
    keyword
  ) {
    if (!keyword) {
      setData(display.maps)
      return
    }

    const searchData = []

    for (let i = 0; i < display.maps.length; i++) {
      if (
        display.maps[i].name
          .toLowerCase()
          .trim()
          .includes(keyword.toLowerCase().trim())
      ) {
        searchData.push(display.maps[i])
      }
    }

    setData(searchData)

    if (searchData.length) {
      return {
        status: true
      }
    } else {
      return {
        status: false
      }
    }
  }

  useEffect(() => {
    setData(display.maps)
  }, [display.maps])

  return <>
    <Header
      dataToggle={displayToggle}
      setToggle={setDisplayToggle}
      text="Display"
    />
    <FlexWrapper
      margin="0.3em 0.5em 0 0.5em"
    >
      <DefaultSearch
        active={true}
        searchProcess={searchDisplay}
        stopSearch={() => {
          setData(display.maps)
        }}
      />
    </FlexWrapper>
    {
      displayToggle
        && <FlexWrapper
          col
          main-content
          style={{
            minHeight: 0,
            position: 'relative'
          }}
        >
          <div style={{
            flex: 1,
            overflowY: 'auto',
            height: '100%'
          }}>
            <Content
              display={{
                maps: data
              }}
              setDisplay={setDisplay}
            />
          </div>
        </FlexWrapper>
    }
  </>
}

function mapStateToProps({ display }) {
  return {
    display
  }
}

const mapDispatchToProps = {
  setDisplay
}

export default connect(mapStateToProps, mapDispatchToProps)(Display)
