import * as d3 from 'd3'

export function barycentric() {
  const { sin, cos, PI } = Math
  const rad = PI / 180

  let a = (d) => d[0]
  let b = (d) => d[1]
  let c = (d) => d[2]

  const angles = [-90, 150, 30]
  let [vA, vB, vC] = angles.map((d) => [cos(d * rad), sin(d * rad)])

  function normalize(_) {
    const values = [a(_), b(_), c(_)]
    const total = d3.sum(values)
    if (total === 0) return [0, 0, 0]
    return values.map((d) => d / total)
  }

  const barycentric = function (d) {
    const [dA, dB, dC] = normalize(d)

    return [
      vA[0] * dA + vB[0] * dB + vC[0] * dC,
      vA[1] * dA + vB[1] * dB + vC[1] * dC
    ]
  }

  barycentric.invert = function ([x, y]) {
    const [xA, yA] = vA
    const [xB, yB] = vB
    const [xC, yC] = vC

    const yByC = yB - yC
    const xCxB = xC - xB
    const xAxC = xA - xC
    const yAyC = yA - yC
    const yCyA = yC - yA
    const xxC = x - xC
    const yyC = y - yC

    const d = yByC * xAxC + xCxB * yAyC
    const lambda1 = (yByC * xxC + xCxB * yyC) / d
    const lambda2 = (yCyA * xxC + xAxC * yyC) / d
    const lambda3 = 1 - lambda1 - lambda2

    return [lambda1, lambda2, lambda3]
  }

  barycentric.a = function (fn) {
    return arguments.length ? ((a = fn), barycentric) : a
  }

  barycentric.b = function (fn) {
    return arguments.length ? ((b = fn), barycentric) : b
  }

  barycentric.c = function (fn) {
    return arguments.length ? ((c = fn), barycentric) : c
  }

  barycentric.normalize = normalize

  barycentric.vertices = function (ABC) {
    return arguments.length
      ? ((vA = ABC[0]), (vB = ABC[1]), (vC = ABC[2]), barycentric)
      : [vA, vB, vC]
  }

  return barycentric
}

export function ternaryPlot(barycentric) {
  // const { abs } = Math
  let radius = 300
  let k = 1
  let tx = 0
  let ty = 0
  let tickFormat = '%'
  let reverse = false

  let unscaledVertices = barycentric.vertices()

  function ternaryPlot(_) {
    const [x, y] = barycentric(_)
    return [x * radius, y * radius]
  }

  let [svA, svB, svC] = scaleVertices()

  const lineBetween =
    ([x1, y1], [x2, y2]) =>
    (t) =>
      [x1 + t * (x2 - x1), y1 + t * (y2 - y1)]

  const A = {
    label: 'A',
    labelAngle: 0,
    labelOffset: 45,
    gridLine: lineBetween(svC, svA),
    scale: d3.scaleLinear().domain([0, 1]),
    tickAngle: 0,
    tickCount: 10,
    tickSize: 6,
    tickTextAnchor: 'start'
  }

  const B = {
    label: 'B',
    labelAngle: 60,
    labelOffset: 45,
    gridLine: lineBetween(svA, svB),
    scale: d3.scaleLinear().domain([0, 1]),
    tickAngle: 60,
    tickCount: 10,
    tickSize: 6,
    tickTextAnchor: 'end'
  }

  const C = {
    label: 'C',
    labelAngle: -60,
    labelOffset: 60,
    gridLine: lineBetween(svB, svC),
    scale: d3.scaleLinear().domain([0, 1]),
    tickAngle: -60,
    tickCount: 10,
    tickSize: 6,
    tickTextAnchor: 'end'
  }

  A.conjugate = B
  B.conjugate = C
  C.conjugate = A

  ternaryPlot.vertices = scaleVertices

  function scaleVertices(newScaledVertices) {
    if (newScaledVertices) {
      const newUnscaledVertices = newScaledVertices.map(([x, y]) => [
        x / radius,
        y / radius
      ])

      barycentric.vertices(newUnscaledVertices)

      return ternaryPlot
    }
    const scaledVertices = barycentric
      .vertices()
      .map(([x, y]) => [x * radius, y * radius])

    return scaledVertices
  }

  ternaryPlot.axisLabels = function ({ center = false } = {}) {
    return [A, B, C].map((d) => {
      const { label, labelAngle } = d
      const [x, y] = d.gridLine(center ? 0.5 : 1)
      const position = [
        (x / radius) * (radius + d.labelOffset),
        (y / radius) * (radius + d.labelOffset)
      ]

      return {
        position,
        label,
        angle: labelAngle
      }
    })
  }

  ternaryPlot.setDomains = function (_) {
    const [domainA, domainB, domainC] = _
    A.scale.domain(domainA.map(Number))
    B.scale.domain(domainB.map(Number))
    C.scale.domain(domainC.map(Number))
    return ternaryPlot
  }

  ternaryPlot.reverseVertices = function () {
    reverse = true
    const swappedVertices = [svC, svA, svB]
    const [vA, vB, vC] = unscaledVertices
    unscaledVertices = [vC, vA, vB]
    ternaryPlot.vertices(swappedVertices)

    A.gridLine = lineBetween(svA, svC)
    B.gridLine = lineBetween(svB, svA)
    C.gridLine = lineBetween(svC, svB)

    return ternaryPlot
  }

  const getDomainLengths = (domains) =>
    new Set(
      domains.map((domain) => {
        const d0 = Math.round((domain[0] + Number.EPSILON) * 100) / 100
        const d1 = Math.round((domain[1] + Number.EPSILON) * 100) / 100
        return Math.abs(d1 - d0)
      })
    )

  ternaryPlot.domains = function (_) {
    if (!arguments.length) {
      return [A.scale.domain(), B.scale.domain(), C.scale.domain()]
    }
    const domainLengths = getDomainLengths(_)
    if (domainLengths.size !== 1) {
      throw new Error('Length of domains must be all be equal')
    }
    const isReverseDomain = _.every((d) => d[0] > d[1])
    if (isReverseDomain) {
      ternaryPlot.reverseVertices(_)
    } else {
      reverse = false
    }
    ternaryPlot.setDomains(_)
    const { x, y, k } = ternaryPlot.transformFromDomains(_)
    ternaryPlot.translate([x, y])
    ternaryPlot.scale(k)
    return ternaryPlot
  }

  ternaryPlot.gridLines = function (counts = 20) {
    return [A, B, C].map((axis, i) => {
      const gridCount = Array.isArray(counts) ? +counts[i] : +counts
      const gridValues = axis.scale.ticks(gridCount - 1) // I forgot what the -1 was for

      return gridValues.map((d) => [
        axis.gridLine(axis.scale(d)),
        axis.conjugate.gridLine(1 - axis.scale(d))
      ])
    })
  }

  ternaryPlot.ticks = function (counts = 10) {
    return [A, B, C].map((axis, i) => {
      const tickCount = Array.isArray(counts) ? +counts[i] : +counts
      const tickValues = axis.scale.ticks(tickCount) //

      const format =
        typeof tickFormat === 'function'
          ? tickFormat
          : axis.scale.tickFormat(tickCount, tickFormat)

      return tickValues.map((tick) => {
        const tickPos = reverse ? 1 - axis.scale(tick) : axis.scale(tick) // not a fan of this
        return {
          tick: format(tick),
          position: axis.gridLine(tickPos),
          angle: axis.tickAngle,
          size: axis.tickSize,
          textAnchor: axis.tickTextAnchor
        }
      })
    })
  }

  ternaryPlot.tickAngles = function (_) {
    return arguments.length
      ? ((A.tickAngle = _[0]),
        (B.tickAngle = _[1]),
        (C.tickAngle = _[2]),
        ternaryPlot)
      : [A.tickAngle, B.tickAngle, C.tickAngle]
  }

  ternaryPlot.tickCounts = function (_) {
    return arguments.length
      ? Array.isArray(_)
        ? ((A.tickCount = _[0]),
          (B.tickCount = _[1]),
          (C.tickCount = _[2]),
          ternaryPlot)
        : (((A.tickCount = +_), (B.tickCount = +_), (C.tickCount = +_)),
          ternaryPlot)
      : [A.tickCount, B.tickCount, C.tickCount]
  }

  ternaryPlot.tickSizes = function (_) {
    // eslint-disable-next-line no-return-assign
    return arguments.length
      ? !Array.isArray(_)
        ? ((A.tickSize = B.tickSize = C.tickSize = +_), ternaryPlot)
        : ((A.tickSize = _[0]),
          (B.tickSize = _[1]),
          (C.tickSize = _[2]),
          ternaryPlot)
      : [A.tickSize, B.tickSize, C.tickSize]
  }

  ternaryPlot.tickFormat = function (_) {
    return arguments.length ? ((tickFormat = _), ternaryPlot) : tickFormat
  }

  ternaryPlot.tickTextAnchors = function (_) {
    return arguments.length
      ? ((A.tickTextAnchor = _[0]),
        (B.tickTextAnchor = _[1]),
        (C.tickTextAnchor = _[2]),
        ternaryPlot)
      : [A.tickTextAnchor, B.tickTextAnchor, C.tickTextAnchor]
  }

  ternaryPlot.labels = function (_) {
    return arguments.length
      ? ((A.label = _[0]), (B.label = _[1]), (C.label = _[2]), ternaryPlot)
      : [A.label, B.label, C.label]
  }

  ternaryPlot.labelAngles = function (_) {
    return arguments.length
      ? ((A.labelAngle = _[0]),
        (B.labelAngle = _[1]),
        (C.labelAngle = _[2]),
        ternaryPlot)
      : [A.labelAngle, B.labelAngle, C.labelAngle]
  }

  ternaryPlot.labelOffsets = function (_) {
    return arguments.length
      ? ((A.labelOffset = _[0]),
        (B.labelOffset = _[1]),
        (C.labelOffset = _[2]),
        ternaryPlot)
      : [A.labelOffset, B.labelOffset, C.labelOffset]
  }

  ternaryPlot.triangle = function () {
    return `M${svA}L${svB}L${svC}Z`
  }

  ternaryPlot.radius = function (_) {
    if (!arguments.length) return radius
    radius = +_
    ;[svA, svB, svC] = ternaryPlot.vertices()
    A.gridLine = lineBetween(svC, svA)
    B.gridLine = lineBetween(svA, svB)
    C.gridLine = lineBetween(svB, svC)

    return ternaryPlot
  }

  ternaryPlot.scale = function (_) {
    return arguments.length
      ? ((k = +_), ternaryPlot.transform(), ternaryPlot)
      : k
  }

  ternaryPlot.translate = function (_) {
    return arguments.length
      ? ((tx = _[0]), (ty = _[1]), ternaryPlot.transform(), ternaryPlot)
      : [tx, ty]
  }

  ternaryPlot.invert = function (_) {
    const xy = [_[0] / radius, _[1] / radius]
    const inverted = barycentric.invert(xy)

    return inverted
  }

  ternaryPlot.transform = function () {
    if (k === 1) {
      tx = 0
      ty = 0
      return ternaryPlot
    }

    const [vA, vB, vC] = unscaledVertices
    // eslint-disable-next-line no-unused-vars
    const [newvA, newvB, newvC] = unscaledVertices.map(([vx, vy]) => [
      vx * k + tx,
      vy * k + ty
    ])

    const getSlope = ([x1, y1], [x2, y2]) => (y2 - y1) / (x2 - x1)

    const mAB = getSlope(vA, vB)
    const mAC = getSlope(vA, vC)
    const mBC = getSlope(vB, vC)

    const bAB = newvA[1] - mAB * newvA[0]
    const bAC = newvA[1] - mAC * newvA[0]
    const bBC = newvB[1] - mBC * newvB[0]

    const parallelLinesDistance = (b1, b2, m) =>
      ((b2 - b1) * Math.sign(b1)) / Math.sqrt(m ** 2 + 1)

    const lineDistanceAC = parallelLinesDistance(vA[1], bAC, mAC)
    const lineDistanceAB = reverse
      ? parallelLinesDistance(vB[1], bAB, mAB)
      : parallelLinesDistance(vA[1], bAB, mAB)
    const lineDistanceBC = parallelLinesDistance(vB[1], bBC, mBC)

    const epsilon = 0.0001
    function getTranslateCorrections(m, distance) {
      if (m === 0) return [0, -distance]

      const inverseSlope = -1 / m

      return getdXdY(inverseSlope, distance)
    }

    function getdXdY(m, c) {
      const { cos, sin, atan, sign } = Math
      const theta = atan(m)
      const dx = c * cos(theta) * sign(theta)
      const dy = c * sin(theta) * sign(theta)

      return [dx, dy]
    }

    if (lineDistanceAB < -epsilon) {
      const [correctionX, correctionY] = getTranslateCorrections(
        mAB,
        lineDistanceAB
      )
      tx += correctionX
      ty += correctionY
    }

    if (lineDistanceAC < -epsilon) {
      const [correctionX, correctionY] = getTranslateCorrections(
        mAC,
        lineDistanceAC
      )

      tx += correctionX
      ty += correctionY
    }

    if (lineDistanceBC < -epsilon) {
      const [correctionX, correctionY] = getTranslateCorrections(
        mBC,
        lineDistanceBC
      )

      tx += correctionX
      ty += correctionY
    }

    const transformedVertices = unscaledVertices.map(([vx, vy]) => [
      vx * k + tx,
      vy * k + ty
    ])

    barycentric.vertices(transformedVertices)

    return ternaryPlot
  }

  ternaryPlot.transformFromDomains = function (domains) {
    const [domainA, domainB, domainC] = domains
    const domainLengths = getDomainLengths(domains)

    const domainLength = [...domainLengths][0]

    const [uvA, uvB, uvC] = unscaledVertices
    const transform = {}

    transform.k = 1 / domainLength
    const domainFromScale = (k) => (k - 1) / (k * 3)

    const untranslatedDomainStart = domainFromScale(k)

    const shiftA = untranslatedDomainStart - domainA[0]
    const shiftB = untranslatedDomainStart - domainB[0]
    const shiftC = untranslatedDomainStart - domainC[0]

    const [tx, ty] = [
      uvA[0] * shiftA + uvB[0] * shiftB + uvC[0] * shiftC,
      uvA[1] * shiftA + uvB[1] * shiftB + uvC[1] * shiftC
    ].map((d) => d * transform.k)

    transform.x = tx
    transform.y = ty

    return transform
  }

  const insideDomain = (n) => (n > 0.999999 ? 1 : n < 0.000001 ? 0 : n)

  ternaryPlot.domainsFromVertices = function () {
    const [bA, bB, bC] = unscaledVertices.map(barycentric.invert)

    const newADomain = [bB[0], bA[0]].map(insideDomain)
    const newBDomain = [bC[1], bB[1]].map(insideDomain)
    const newCDomain = [bA[2], bC[2]].map(insideDomain)

    return [newADomain, newBDomain, newCDomain]
  }

  return ternaryPlot
}

export const createDropdown = (data) => {
  if (typeof data[0] === 'object') {
    return Object.keys(data[0]).map((key) => {
      return {
        value: key,
        label: key
      }
    })
  } else {
    return data.map((key) => {
      return {
        value: key,
        label: key
      }
    })
  }
}
