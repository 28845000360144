import React, { useEffect, useState } from 'react'

import Dropdown from '../../../../../../../Dropdowns/Default'

import InputPrecision from './InputPrecision'

/**
 * Option menu to mode
 * @param {Object} map is the current map
 * @returns 
 */
function OptionMenuMode({ map }) {
  const [ fieldMode, setFieldMode ] = useState(map.cesiumLayer.fieldMode)

  useEffect(() => {
    function eventHandler() {
      setFieldMode(map.cesiumLayer.fieldMode)
    }

    map.cesiumLayer.subscribeEvent('fieldMode', eventHandler)

    return (() => {
      map.cesiumLayer.unsubscribeEvent('fieldMode', eventHandler)
    })
  }, [])

  function setTemplate(template) {
    map.cesiumLayer.setTemplate(template)
  }

  if (!fieldMode.selected) {
    return null
  }

  return (
    <>
      <Dropdown
        label="Attribute"
        options={fieldMode.pickList || []}
        setToggle={(pick) => {
          setTemplate(null)

          map.cesiumLayer.setFieldMode({
            ...fieldMode,
            selected: pick
          })
        }}
        width="10em"
        iconType="attribute"
        defaultPick={fieldMode.selected}
      />
      <InputPrecision
        fieldMode={fieldMode}
        setFieldMode={setFieldMode}
        setTemplate={setTemplate}
      />
    </>
  )
}

export default OptionMenuMode
