import * as prospectRnR from '../prospectivityMap/ratingAndRanking'
import * as depositTypePrediction from '../prospectivityMap/depositTypePrediction'
import * as regionalGeologyLithology from '../geologicalMapSeries/regionalGeologyLithology'
import * as regionalGeologyAge from '../geologicalMapSeries/regionalGeologyAge'
import * as projectGeology from '../geologicalMapSeries/projectScaleGeology'
import * as airborneRadiometric from '../geophysicsMapSeries/airborneRadiometric'
import * as airborneMagnetic from '../geophysicsMapSeries/airborneMagnetic'
import * as airborneGravity from '../geophysicsMapSeries/airborneGravity'
import * as magnetic from '../geophysicsMapSeries/magnetic'
import * as gravity from '../geophysicsMapSeries/gravity'
import * as inducedPolarization from '../geophysicsMapSeries/inducedPolarization'
import * as CSAMT from '../geophysicsMapSeries/CSAMT'
import * as geochemicalBleg from '../geochemicalMapSeries/bleg'
import * as geochemicalStreamSediment from '../geochemicalMapSeries/streamSediment'
import * as geochemicalRock from '../geochemicalMapSeries/rock'
import * as geochemicalSoil from '../geochemicalMapSeries/soil'
import * as regionalAlteration from '../alterationMapSeries/regionalScaleAlteration'
import * as projectAlteration from '../alterationMapSeries/projectScaleAlteration'
import * as regionalGeologyStructure from '../structuralGeologyMapSeries/regionalScaleStructure'
import * as projectGeologyStructure from '../structuralGeologyMapSeries/projectScaleStructure'
import * as interpretedLineament from '../intrepretedLineamentsGeology'
import * as remoteSensingClay from '../remoteSensingMineralMap/clayGroups'
import * as remoteSensingIronOxide from '../remoteSensingMineralMap/ironOxideGroups'
import * as mineralOccurrences from '../occurrences/mineralOccurrences'
import * as mine from '../occurrences/mine'
import * as illegalMine from '../occurrences/illegalMine'
import * as earthquakeUSGS from '../hazardMaps/earthquakeUSGS'
import * as earthquakes from '../hazardMaps/earthquakes'
import * as landslides from '../hazardMaps/landslides'
import * as tsunami from '../hazardMaps/tsunami'
import * as vulcanicEruption from '../hazardMaps/volcanicEruption'
import * as activeFault from '../hazardMaps/activeFault'
import * as forestry from '../forestryMaps'
import * as tenement from '../tenementMaps'
import * as coastlines from '../accessibilityAndInfrastructureMaps/coastlines'
import * as gasStation from '../accessibilityAndInfrastructureMaps/gasStation'
import * as jetty from '../accessibilityAndInfrastructureMaps/jetty'
import * as airport from '../accessibilityAndInfrastructureMaps/airport'
import * as smelter from '../accessibilityAndInfrastructureMaps/smelter'
import * as oilDepot from '../accessibilityAndInfrastructureMaps/oilDepot'
import * as administration from '../accessibilityAndInfrastructureMaps/administration'
import * as river from '../accessibilityAndInfrastructureMaps/river'
import * as road from '../accessibilityAndInfrastructureMaps/road'
import * as settlement from '../accessibilityAndInfrastructureMaps/settlement'
import * as powerGenerator from '../accessibilityAndInfrastructureMaps/powerGenerator'
import * as drillholeTrace from '../drillhole/drillholeTrace'
import * as drillholeLithology from '../drillhole/lithology'
import * as drillholeAlteration from '../drillhole/alteration'
import * as drillholeAssay from '../drillhole/assay'
import * as drillholeRQD from '../drillhole/rqd'
import * as geologicalModel from '../3DModel/geologicalModel'
import * as implicitModel from '../3DModel/implicitModel'

export const CESIUM_MAP_REFERENCES = [
  {
    category: 'Prospectivity map',
    subCategory: 'Rating and ranking',
    fetch: prospectRnR.fetch,
    parseFetch: prospectRnR.parseFetch,
    getLegendStyle: prospectRnR.getLegendStyle
  },
  {
    category: 'Prospectivity map',
    subCategory: 'Deposit type prediction',
    fetch: depositTypePrediction.fetch,
    parseFetch: depositTypePrediction.parseFetch,
    getLegendStyle: depositTypePrediction.getLegendStyle
  },
  {
    category: 'Geological map series',
    subCategory: 'Regional geology lithology',
    fetch: regionalGeologyLithology.fetch,
    parseFetch: regionalGeologyLithology.parseFetch,
    getLegendStyle: regionalGeologyLithology.getLegendStyle
  },
  {
    category: 'Geological map series',
    subCategory: 'Regional geology age',
    fetch: regionalGeologyAge.fetch,
    parseFetch: regionalGeologyAge.parseFetch,
    getLegendStyle: regionalGeologyAge.getLegendStyle
  },
  {
    category: 'Geological map series',
    subCategory: 'Project scale geology',
    fetch: projectGeology.fetch,
    parseFetch: projectGeology.parseFetch,
    getLegendStyle: regionalGeologyAge.getLegendStyle
  },
  {
    category: 'Geophysics map series',
    subCategory: 'Airborne Radiometric',
    fetch: airborneRadiometric.fetch,
    parseFetch: airborneRadiometric.parseFetch,
    getLegendStyle: airborneRadiometric.getLegendStyle
  },
  {
    category: 'Geophysics map series',
    subCategory: 'Airborne magnetic',
    fetch: airborneMagnetic.fetch,
    parseFetch: airborneMagnetic.parseFetch,
    getLegendStyle: airborneMagnetic.getLegendStyle
  },
  {
    category: 'Geophysics map series',
    subCategory: 'Airborne Gravity',
    fetch: airborneGravity.fetch,
    parseFetch: airborneGravity.parseFetch,
    getLegendStyle: airborneGravity.getLegendStyle
  },
  {
    category: 'Geophysics map series',
    subCategory: 'Magnetic',
    fetch: magnetic.fetch,
    parseFetch: magnetic.parseFetch,
    getLegendStyle: magnetic.getLegendStyle
  },
  {
    category: 'Geophysics map series',
    subCategory: 'Gravity',
    fetch: gravity.fetch,
    parseFetch: gravity.parseFetch,
    getLegendStyle: gravity.getLegendStyle
  },
  {
    category: 'Geophysics map series',
    subCategory: 'Induced polarization',
    fetch: inducedPolarization.fetch,
    parseFetch: inducedPolarization.parseFetch,
    getLegendStyle: inducedPolarization.getLegendStyle
  },
  {
    category: 'Geophysics map series',
    subCategory: 'CSAMT',
    fetch: CSAMT.fetch,
    parseFetch: CSAMT.parseFetch,
    getLegendStyle: CSAMT.getLegendStyle
  },
  {
    category: 'Geochemical map series',
    subCategory: 'BLEG',
    fetch: geochemicalBleg.fetch,
    parseFetch: geochemicalBleg.parseFetch
  },
  {
    category: 'Geochemical map series',
    subCategory: 'Stream sediment',
    fetch: geochemicalStreamSediment.fetch,
    parseFetch: geochemicalStreamSediment.parseFetch
  },
  {
    category: 'Geochemical map series',
    subCategory: 'Rock',
    fetch: geochemicalRock.fetch,
    parseFetch: geochemicalRock.parseFetch
  },
  {
    category: 'Geochemical map series',
    subCategory: 'Soil',
    fetch: geochemicalSoil.fetch,
    parseFetch: geochemicalSoil.parseFetch
  },
  {
    category: 'Alteration map series',
    subCategory: 'Regional scale alteration',
    fetch: regionalAlteration.fetch,
    parseFetch: regionalAlteration.parseFetch,
    getLegendStyle: regionalAlteration.getLegendStyle
  },
  {
    category: 'Alteration map series',
    subCategory: 'Project scale alteration',
    fetch: projectAlteration.fetch,
    parseFetch: projectAlteration.parseFetch,
    getLegendStyle: projectAlteration.getLegendStyle
  },
  {
    category: 'Structural geology map series',
    subCategory: 'Regional scale structure',
    fetch: regionalGeologyStructure.fetch,
    parseFetch: regionalGeologyStructure.parseFetch,
    getLegendStyle: regionalGeologyStructure.getLegendStyle
  },
  {
    category: 'Structural geology map series',
    subCategory: 'Project scale structure',
    fetch: projectGeologyStructure.fetch,
    parseFetch: projectGeologyStructure.parseFetch,
    getLegendStyle: projectGeologyStructure.getLegendStyle
  },
  {
    category: 'Interpreted lineaments geology',
    subCategory: null,
    fetch: interpretedLineament.fetch,
    parseFetch: interpretedLineament.parseFetch,
    getLegendStyle: interpretedLineament.getLegendStyle
  },
  {
    category: 'Remote sensing mineral map',
    subCategory: 'Clay groups',
    fetch: remoteSensingClay.fetch,
    parseFetch: remoteSensingClay.parseFetch,
    getLegendStyle: remoteSensingClay.getLegendStyle
  },
  {
    category: 'Remote sensing mineral map',
    subCategory: 'Iron oxide groups',
    fetch: remoteSensingIronOxide.fetch,
    parseFetch: remoteSensingIronOxide.parseFetch,
    getLegendStyle: remoteSensingIronOxide.getLegendStyle
  },
  {
    category: 'Occurrences',
    subCategory: 'Mineral occurrences',
    fetch: mineralOccurrences.fetch,
    parseFetch: mineralOccurrences.parseFetch
  },
  {
    category: 'Occurrences',
    subCategory: 'Mine',
    fetch: mine.fetch,
    parseFetch: mine.parseFetch
  },
  {
    category: 'Occurrences',
    subCategory: 'Illegal mine',
    fetch: illegalMine.fetch,
    parseFetch: illegalMine.parseFetch
  },
  {
    category: 'Hazard maps',
    subCategory: 'Earthquakes (USGS)',
    fetch: earthquakeUSGS.fetch,
    parseFetch: earthquakeUSGS.parseFetch
  },
  {
    category: 'Hazard maps',
    subCategory: 'Active fault',
    fetch: activeFault.fetch,
    parseFetch: activeFault.parseFetch,
    getLegendStyle: activeFault.getLegendStyle
  },
  {
    category: 'Hazard maps',
    subCategory: 'Earthquakes',
    fetch: earthquakes.fetch,
    parseFetch: earthquakes.parseFetch,
    getLegendStyle: earthquakes.getLegendStyle
  },
  {
    category: 'Hazard maps',
    subCategory: 'Landslides',
    fetch: landslides.fetch,
    parseFetch: landslides.parseFetch,
    getLegendStyle: landslides.getLegendStyle
  },
  {
    category: 'Hazard maps',
    subCategory: 'Tsunami',
    fetch: tsunami.fetch,
    parseFetch: tsunami.parseFetch,
    getLegendStyle: tsunami.getLegendStyle
  },
  {
    category: 'Hazard maps',
    subCategory: 'Volcanic eruption',
    fetch: vulcanicEruption.fetch,
    parseFetch: vulcanicEruption.parseFetch,
    getLegendStyle: vulcanicEruption.getLegendStyle
  },
  {
    category: 'Forestry maps',
    subCategory: null,
    fetch: forestry.fetch,
    parseFetch: forestry.parseFetch,
    getLegendStyle: forestry.getLegendStyle,
    getLegendStyle: forestry.getLegendStyle
  },
  {
    category: 'Tenement maps',
    subCategory: null,
    fetch: tenement.fetch,
    parseFetch: tenement.parseFetch
  },
  {
    category: 'Accessibility and infrastructure maps',
    subCategory: 'Coastlines',
    fetch: coastlines.fetch,
    parseFetch: coastlines.parseFetch,
    getLegendStyle: coastlines.getLegendStyle
  },
  {
    category: 'Accessibility and infrastructure maps',
    subCategory: 'Fuel station',
    fetch: gasStation.fetch,
    parseFetch: gasStation.parseFetch
  },
  {
    category: 'Accessibility and infrastructure maps',
    subCategory: 'Port',
    fetch: jetty.fetch,
    parseFetch: jetty.parseFetch
  },
  {
    category: 'Accessibility and infrastructure maps',
    subCategory: 'Airport',
    fetch: airport.fetch,
    parseFetch: airport.parseFetch
  },
  {
    category: 'Accessibility and infrastructure maps',
    subCategory: 'Smelter',
    fetch: smelter.fetch,
    parseFetch: smelter.parseFetch
  },
  {
    category: 'Accessibility and infrastructure maps',
    subCategory: 'Oil depot',
    fetch: oilDepot.fetch,
    parseFetch: oilDepot.parseFetch
  },
  {
    category: 'Accessibility and infrastructure maps',
    subCategory: 'Power plant',
    fetch: powerGenerator.fetch,
    parseFetch: powerGenerator.parseFetch
  },
  {
    category: 'Accessibility and infrastructure maps',
    subCategory: 'Administration',
    fetch: administration.fetch,
    parseFetch: administration.parseFetch,
    getLegendStyle: administration.getLegendStyle
  },
  {
    category: 'Accessibility and infrastructure maps',
    subCategory: 'River',
    fetch: river.fetch,
    parseFetch: river.parseFetch,
    getLegendStyle: river.getLegendStyle
  },
  {
    category: 'Accessibility and infrastructure maps',
    subCategory: 'Road',
    fetch: road.fetch,
    parseFetch: road.parseFetch,
    getLegendStyle: road.getLegendStyle
  },
  {
    category: 'Accessibility and infrastructure maps',
    subCategory: 'Settlement',
    fetch: settlement.fetch,
    parseFetch: settlement.parseFetch,
    getLegendStyle: settlement.getLegendStyle
  },
  {
    category: 'Drillhole',
    subCategory: 'Drillhole trace',
    fetch: drillholeTrace.fetch,
    parseFetch: drillholeTrace.parseFetch
  },
  {
    category: 'Drillhole',
    subCategory: 'Lithology',
    fetch: drillholeLithology.fetch,
    parseFetch: drillholeLithology.parseFetch
  },
  {
    category: 'Drillhole',
    subCategory: 'Alteration',
    fetch: drillholeAlteration.fetch,
    parseFetch: drillholeAlteration.parseFetch
  },
  {
    category: 'Drillhole',
    subCategory: 'Assay',
    fetch: drillholeAssay.fetch,
    parseFetch: drillholeAssay.parseFetch
  },
  {
    category: 'Drillhole',
    subCategory: 'RQD',
    fetch: drillholeRQD.fetch,
    parseFetch: drillholeRQD.parseFetch
  },
  {
    category: '3D Model',
    subCategory: 'Implicit model',
    fetch: geologicalModel.fetch,
    parseFetch: geologicalModel.parseFetch
  },
  {
    category: '3D Model',
    subCategory: 'Geological model',
    fetch: implicitModel.fetch,
    parseFetch: implicitModel.parseFetch
  },
]
