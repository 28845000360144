import axios from 'axios'
import axiosReact, { getProgressDownload } from './axiosReact'

import * as Cesium from 'cesium'
import cesiumViewer from './cesiumViewer'

import store from '../store'

import RequestParams from './generateParams'

import {
  GEOJSON_URL,
  BOREHOLE_URL,
  BASE_URL,
} from '../constants/Server/base'

axios.defaults.headers['Content-Type'] = 'application/json'

export const productionAPI = axiosReact

const serverAPI = axios.create({
  baseURL: BASE_URL
})

const geojsonAPI = axios.create({
  baseURL: GEOJSON_URL
})

const boreholeAPI = axios.create({
  baseURL: BOREHOLE_URL
})

const drawAPI = axios.create({
  baseURL: 'http://localhost:5000'
})

export async function getCatalogTemplate() {
  const { data } = await productionAPI.get('/geoprosp/layer_config')

  return data
}

export async function fetchSiteDetail({ site, filter_wkt, bin_size }) {
  try {
    let response = {}
    if (filter_wkt) {
      if(bin_size === undefined){
        response = await productionAPI.get(`/geofix/grid/8000`, {
          params: {
            commodity: site.commodity,
            filter_wkt: filter_wkt
          }
        })
      } else {
        response = await productionAPI.get(`/geofix/grid/${bin_size}`, {
          params: {
            commodity: site.commodity,
            filter_wkt: filter_wkt
          }
        })
      }
    } else {
      response = await productionAPI.get(`/geofix/grid_specific/${site.grid_id}`, {
        // params: RequestParams.FETCH_GEOJSON_AND_SUMMARY()
        params: {
          commodity: site.type
        }
      })
    }

    const data = response.data.geojson ? response.data.geojson.features[0] : response.data

    const coordinates = data.geometry.coordinates.flat(5) 

    let minlongitude = coordinates[0]
    let minlatitude = coordinates[1]
    let maxlongitude = coordinates[0]
    let maxlatitude = coordinates[1]

    for (let i = 2; i < coordinates.length; i += 2) {
      if (minlongitude > coordinates[i]) {
        minlongitude = coordinates[i]
      }
      if (minlatitude > coordinates[i + 1]) {
        minlatitude = coordinates[i + 1]
      }
      if (maxlongitude < coordinates[i]) {
        maxlongitude = coordinates[i]
      }
      if (maxlatitude < coordinates[i + 1]) {
        maxlatitude = coordinates[i + 1]
      }
    }

    let prop = data.properties

    const contentWkt = {
      name: "GFX-ID-" + prop.bin_size + "-" + prop.id,
      prospect_name: "GFX-ID-" + prop.bin_size + "-" + prop.id,
      image: "https://images.pexels.com/photos/2347774/pexels-photo-2347774.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      minlatitude,
      minlongitude,
      maxlongitude,
      maxlatitude,
      long: (minlongitude + maxlongitude) / 2,
      lat: (minlatitude + maxlatitude) / 2,
      ...prop,
      id: prop.id,
    }

    const contentProspect = {
      name: site.name,
      prospect_name: site.name,
      image: "https://images.pexels.com/photos/2347774/pexels-photo-2347774.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      minlatitude,
      minlongitude,
      maxlongitude,
      maxlatitude,
      long: (minlongitude + maxlongitude) / 2,
      lat: (minlatitude + maxlatitude) / 2,
      ...prop,
      id: site.grid_id,
      grid_id: site.grid_id,
      type: site.type
    }
    
    const content = filter_wkt ? contentWkt : contentProspect

    return content
  } catch (error) {
    throw error
  }
}

export async function fetchExistingMine({ fetchProgress }) {
  try {
    const response = await productionAPI.get('/geofix/mines', {
      params: RequestParams.WITHOUT_PARAMS(),
      onDownloadProgress(event) {
        getProgressDownload(event, fetchProgress)
      }
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export async function fetchMineralOccurrences({ fetchProgress }) {
  try {
    const { data } = await productionAPI.get('/geofix/mineraloccurrences', {
      params: RequestParams.WITHOUT_PARAMS(),
      onDownloadProgress(event) {
        getProgressDownload(event, fetchProgress)
      }
    })

    return data
  } catch (error) {
    throw error
  }
}

export async function fetchAccessibility({ fetchProgress }) {
  try {
    const { data } = await productionAPI.get('/geofix/accessibility', {
      params: RequestParams.WITHOUT_PARAMS(),
      onDownloadProgress(event) {
        getProgressDownload(event, fetchProgress)
      }
    })

    const geojson = {
      features: [],
      type: 'FeatureCollection'
    }

    for (let key in data) {
      data[key].features.forEach((feature) => {
        feature.properties.accessibilityType = key
        
        geojson.features.push(feature)
      })
    }

    return {
      geojson
    }
  } catch (error) {
    throw error
  }
}

export async function fetchHazard({ fetchProgress }) {
  try {
    const { data } = await productionAPI.get('/geofix/hazard', {
      params: RequestParams.WITHOUT_PARAMS(),
      onDownloadProgress(event) {
        getProgressDownload(event, fetchProgress)
      }
    })

    const geojson = {
      features: [],
      type: 'FeatureCollection'
    }

    for (let key in data) {
      data[key].features.forEach((feature) => {
        feature.properties.hazardType = key
        
        geojson.features.push(feature)
      })
    }

    return {
      geojson
    }
  } catch (error) {
    throw error
  }
}

export async function fetchForestry({ fetchProgress }) {
  try {
    const { data } = await productionAPI.get('/geofix/forestry', {
      params: RequestParams.WITHOUT_PARAMS(),
      onDownloadProgress(event) {
        getProgressDownload(event, fetchProgress)
      }
    })

    return {
      geojson: data
    }
  } catch (error) {
    throw error
  }
}

export async function fetchWIUP({ fetchProgress }) {
  try {
    const { data } = await productionAPI.get('/geofix/tenements', {
      params: RequestParams.WITHOUT_PARAMS(),
      onDownloadProgress(event) {
        getProgressDownload(event, fetchProgress)
      }
    })

    return data
  } catch (error) {
    throw error
  }
}

export async function fetchGridMapData({ params }) {
  try {
    // commodity should been added to the query
    const { data } = await productionAPI.get('/geofix/grid', {
      params
    })

    return data
  } catch (error) {
    throw error
  }
}

export async function fetchSingleGridMapData({
  map,
  fetchProgress,
  fetchFinish,
  params
}) {
  try {
    const { data } = await productionAPI.get(`/geofix/grid/${map.id}`, {
      params,
      onDownloadProgress(event) {
        getProgressDownload(event, fetchProgress)
      }
    })

    fetchFinish()

    return data
  } catch (error) {
    throw error
  }
}

export async function fetchGeochemicalMapData({ geom, type }) {
  try {
    let path = `/geofix/geochemistry/${geom}`

    if (type) {
      path += `/${type}`
    }

    const { data } = await productionAPI.get(path)

    // for the first fetch list map
    const listMap = []

    if (!type) {
      for (let key in data) {
        data[key].forEach((el) => {
          el.id = el.belt + key + geom
          el.name = el.belt
          el.type = key
          el.geom = geom

          if (geom === 'area') {
            el.filter = ['au']
          }

          listMap.push(el)
        })
      }
    }

    return listMap
  } catch (error) {
    throw error
  }
}

export async function fetchProspectGeochemicalMapData({
  content,
  intersect,
  fetchProgress,
  fetchFinish
}) {
  try {
    const params = {
      minlongitude: content.minlongitude,
      minlatitude: content.minlatitude,
      maxlongitude: content.maxlongitude,
      maxlatitude: content.maxlatitude,
      withgeojson: true
    }

    if (intersect) {
      params.intersection = true
    }

    const response = {
      points: null,
      area: null
    }

    const responsePoint = await productionAPI.get(`/geofix/geochemistry/points`, {
      params
    })

    const responseArea = await productionAPI.get(`/geofix/geochemistry/area`, {
      params
    })

    response.points = responsePoint.data
    response.area = responseArea.data

    return response
  } catch (error) {
    throw error
  }
}

export async function fetchSingleGeochemicalMapData({ geom, type, belt, subtype, fetchProgress, fetchFinish }) {
  try {
    const { data } = await productionAPI.get(`/geofix/geochemistry/${geom}/${type}`, {
      params: {
        belt,
        withgeojson: true,
        subtype
      },
      onDownloadProgress(event) {
        getProgressDownload(event, fetchProgress)
      }
    })

    fetchFinish()

    const geojson = {
      type: 'FeatureCollection',
      features: []
    }

    if (data.length) {
      if (data[0].subtypes) {
        data[0].subtypes.forEach((element) => {
          geojson.features = geojson.features.concat(
            element.geojson.features
          )
        })
      } else if (data[0].projects) {
        data[0].projects.forEach((project) => {
          project.prospects.forEach((prospect) => {
            geojson.features = geojson.features.concat(
              prospect.geojson.features
            )
          })
        })
      }
    }

    return geojson
  } catch (error) {
    throw error
  }
}

export async function fetchAlterationMapData({ siteId }) {
  try {
    const response = await geojsonAPI.get('/alterationMaps', {
      params: {
        siteId
      }
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export async function fetchGeologicalMapData({ type, siteId }) {
  try {
    // enhanced later

    if (type === 'regional') {
      const { data } = await productionAPI.get('/geofix/geology')

      const geojson = {
        type: 'FeatureCollection',
        features: []
      }

      data.forEach((element) => {
        geojson.features.push(element.geojson.features)
      })

      return geojson
    } else if (type === 'project') {
      const response = await geojsonAPI.get('/geologicalMaps', {
        params: {
          type,
          siteId
        }
      })

      return response.data
    }

    throw new Error('serverAPI.fetchGeologicalMapData parameter of type is not valid')
  } catch (error) {
    throw error
  }
}

export async function fetchSingleGeologicalMapData({ type, fetchProgress, fetchFinish }) {
  try {
    if (type === 'regional') {
      const { data } = await productionAPI.get('/geofix/geology', {
        onDownloadProgress(event) {
          getProgressDownload(event, fetchProgress)
        }
      })

      fetchFinish()

      let geojson = {
        type: 'FeatureCollection',
        features: []
      }
  
      data.forEach((element) => {
        geojson.features = geojson.features.concat(element.geojson.features)
      })

      return geojson
    } else {
      const { data } = await productionAPI.get('/geofix/geology_project', {
        onDownloadProgress(event) {
          getProgressDownload(event, fetchProgress)
        }
      })

      fetchFinish()

      return data
    }
  } catch (error) {
    throw error
  }
}

export async function fetchProspectGeologicalMapData({
  content,
  intersect = false,
  fetchProgress,
  fetchFinish
}) {
  try {
    const params = {
      minlongitude: content.minlongitude,
      minlatitude: content.minlatitude,
      maxlongitude: content.maxlongitude,
      maxlatitude: content.maxlatitude
    }

    if (intersect) {
      params.intersection = true
    }

    const { data } = await productionAPI.get('/geofix/geology', {
      params,
      onDownloadProgress(event) {
        getProgressDownload(event, fetchProgress)
      }
    })

    fetchFinish()

    let geojson = {
      type: 'FeatureCollection',
      features: []
    }

    data.forEach((element) => {
      geojson.features = geojson.features.concat(element.geojson.features)
    })

    return geojson
  } catch (error) {
    throw error
  }
}

export async function fetchStructureMapData({
  type,
  limit,
  minlongitude,
  maxlongitude,
  minlatitude,
  maxlatitude,
  lineament_type = 'major'
}) {
  try {
    if (type === 'regional') {
      const response = await productionAPI.get('/geofix/structures', {
        params: {
          type,
          limit,
          minlongitude,
          maxlongitude,
          minlatitude,
          maxlatitude
        }
      })

      return response.data
    } else {
      return null
    }
  } catch (error) {
    throw error
  }
}

export async function fetchSingleStructureMapData({ id, fetchProgress, fetchFinish, lineament_type = 'major' }) {
  try {
    const response = await productionAPI.get('/geofix/structures/' + id, {
      params: {
        lineament_type
      },
      onDownloadProgress(event) {
        getProgressDownload(event, fetchProgress)
      }
    })

    fetchFinish()

    return response.data
  } catch (error) {
    throw error
  }
}

export async function fetchMineralMapData({ type }) {
  try {
    const { data } = await productionAPI.get(`/geofix/rsg/${type}`)

    // for the first fetch list map
    const listMap = []

    for (let key in data) {
      data[key].forEach((el) => {
        el.id = key
        el.name = 'Sulawesi East Mindanau' // edit it lter
        el.type = key

        listMap.push(el)
      })
    }
 
     return listMap
  } catch (error) {
    throw error
  }
}

export async function fetchSingleMineralMapData({ map, fetchProgress, fetchFinish }) {
  try {
    const { data } = await productionAPI.get(`/geofix/rsg/${map.type}`, {
      params: {
        withgeojson: true
      },
      onDownloadProgress(event) {
        getProgressDownload(event, fetchProgress)
      }
    })

    fetchFinish()

    let geojson = {
      type: 'FeatureCollection',
      features: []
    }

    data.forEach((element) => {
      geojson.features = geojson.features.concat(element.geojson.features)
    })

    return geojson
  } catch (error) {
    throw error
  }
}

export async function fetchGeophysicsRasterData() {
  try {
    const { data } = await productionAPI.get(`${BASE_URL}/geofix/raster`)

    data.forEach((map) => {
      map.name = map.title
      map.raster = true
      map.type = 'raster'
      map.properties = {
        image: 'raster'
      }
    })

    return data
  } catch (error) {
    throw error
  }
}

export async function fetchSingleGeophysicsRasterData({ map, fetchProgress, fetchFinish }) {
  try {
    const { data } = await productionAPI.get(`${BASE_URL}/geofix/raster`, {
      onDownloadProgress(event) {
        getProgressDownload(event, fetchProgress)
      }
    })

    fetchFinish()

    for (let i = 0; i < data.length; i++) {
      if (data[i].title === map.name) {
        return data[i]
      }
    }
  } catch (error) {
    throw error
  }
}

export async function fetchGeophysicsMapData({ type }) {
  try {
    const { data } = await productionAPI.get(`/geofix/geophysics`)

    // for the first fetch list map
    const listMap = []

    for (let key in data) {
      data[key].forEach((el) => {
        el.id = key
        el.name = 'Sulawesi East Mindanau' // edit it lter
        el.type = key

        listMap.push(el)
      })
    }
 
     return listMap
  } catch (error) {
    throw error
  }
}

export async function fetchProspectGeophysicsMapData({ content, fetchProgress, fetchFinish }) {
  try {
    const { data } = await productionAPI.get(`/geofix/geophysics`, {
      params: {
        minlongitude: content.minlongitude,
        minlatitude: content.minlatitude,
        maxlongitude: content.maxlongitude,
        maxlatitude: content.maxlatitude,
        withgeojson: true
      },
      onDownloadProgress(event) {
        getProgressDownload(event, fetchProgress)
      }
    })

    fetchFinish()

    return data
  } catch (error) {
    throw error
  }
}

export async function fetchSingleGeophysicsMapData({ map, fetchProgress, fetchFinish }) {
  try {
    const { data } = await productionAPI.get(`/geofix/geophysics/${map.type}`, {
      params: {
        withgeojson: true
      },
      onDownloadProgress(event) {
        getProgressDownload(event, fetchProgress)
      }
    })

    fetchFinish()

    let geojson = {
      type: 'FeatureCollection',
      features: []
    }

    data.forEach((element) => {
      geojson.features = geojson.features.concat(element.geojson.features)
    })

    return geojson
  } catch (error) {
    throw error
  }
}

export async function fetchBoreholeMapData({ siteId, type }) {
  try {
    const response = await boreholeAPI.get('/boreholes', {
      params: {
        siteId,
        type
      }
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export async function fetchSingleBoreholeMapData({ id, fetchProgress, fetchFinish }) {
  try {
    const response = await boreholeAPI.get('/boreholes', {
      params: {
        id
      },
      onDownloadProgress(event) {
        getProgressDownload(event, fetchProgress)
      }
    })

    fetchFinish()

    return response.data[0] // the data should be object, in json-server still return array
  } catch (error) {
    throw error
  }
}

export async function fetchThreeDimensionMapData() {
  try {
    const { data } = await productionAPI.get(`/geofix/model3d`)
 
    return data
  } catch (error) {
    throw error
  }
}

export async function fetchSingleThreeDimensionMapData() {
  try {
    // cosnt {}
  } catch (error) {
    throw error
  }
}

export async function fetchDrawAPI () {
  try {
    const response = await drawAPI.get('/api/v1/realtime_draw')

    return response.data
  } catch (error) {
    throw error
  }
}

export async function addDrawAPI({
  type,
  positions,
  properties,
  cesiumId,
  status
}) {
  try {
    const response = await drawAPI.post('/api/v1/realtime_draw',
      {
        drawType: type,
        positions,
        properties: {
          shapeType: properties.type
        },
        cesiumId,
        status
      }
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export async function editDrawAPI({
  type,
  positions,
  properties,
  cesiumId,
  status
}) {
  try {
    const response = await drawAPI.put('/api/v1/realtime_draw/' + cesiumId,
      {
        drawType: type,
        positions,
        properties: {
          shapeType: properties.type
        },
        status
      }, {
        headers: {
          ActionType: 'continue'
        }
      }
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export async function startEditDrawAPI({
  type,
  positions,
  properties,
  cesiumId,
  status
}) {
  try {
    const response = await drawAPI.put('/api/v1/realtime_draw/' + cesiumId,
    {
      drawType: type,
      positions,
      properties: {
        shapeType: properties.type
      },
      status
    }, {
      headers: {
        ActionType: 'initial'
      }
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export async function deleteDrawAPI({
  cesiumId
}) {
  try {
    const response = await drawAPI.delete('/api/v1/realtime_draw/' + cesiumId)

    return response.data
  } catch (error) {
    throw error
  }
}

export async function getUserChat({ source }) {
  try {
    const response = await productionAPI.get(`/geofix/chat`, {
      cancelToken: source.token
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export async function getUserChatMessageIDDetail({
  messageId,
  source
}) {
  try {
    const response = await productionAPI.get(`/geofix/chat/${messageId}`, {
      cancelToken: source.token
    })

    return response.data
  } catch (error) {
    throw error
  }
}

/////////////////////
//
// DEVELOPED LATER
//
/////////////////////

export async function fetchSiteDetailByCategory({ category, siteId }) {
  try {
    const response = await serverAPI.get('/siteDetails', {
      params: {
        category,
        siteId
      }
    })

    return response.data[0] // the data should be object, in json-server still return array
  } catch (error) {
    throw error
  }
}

export async function fetchProspectArea({
  cancelToken
}) {
  try {
    const commodity = store.getState().sites.commodity
    const { binSize, depositType, status } = store.getState().sites.ranking

    let params = {
      commodity,
      bin_size: binSize,
      status
    }

    // const boundingBox = store.getState().camera.boundingBox

    // if (boundingBox) {
    //   const {
    //     minLongitude: minlongitude,
    //     minLatitude: minlatitude,
    //     maxLongitude: maxlongitude,
    //     maxLatitude: maxlatitude
    //   } = boundingBox

    //   const height = Cesium.Cartographic
    //     .fromCartesian(
    //       cesiumViewer.viewer.scene.camera.position
    //     ).height
    //     / 1000

    //   const HEIGHT_TO_BIN_SIZE = {
    //     1000: 32,
    //     2000: 64,
    //     4000: 128,
    //     8000: 256,
    //     16000: 512,
    //     32000: 1024
    //   }

    //   let bin_size = 64000

    //   for (let refHeight in HEIGHT_TO_BIN_SIZE) {
    //     if (height < HEIGHT_TO_BIN_SIZE[refHeight]) {
    //       bin_size = refHeight
    //       break
    //     }
    //   }

    //   params = {
    //     ...params
    //     // minlongitude,
    //     // minlatitude,
    //     // maxlongitude,
    //     // maxlatitude,
    //     // bin_size        
    //   }
    // }

    const { data } = await productionAPI.get('/geofix/prospect', {
      params,
      cancelToken: cancelToken.token
    })

    const sites = []

    // const geojson = {
    //   features: [],
    //   type: 'FeatureCollection'
    // }
    // const geojson = data.geojson

    data.forEach((prospect, index) => {
      // const coordinates = prospect.geojson.geometry.coordinates.flat(5)
      // const coordinates = prospect.geometry.coordinates.flat(5)
      // geojson.features.push(prospect.geojson)

      // let minlongitude = coordinates[0]
      // let minlatitude = coordinates[1]
      // let maxlongitude = coordinates[0]
      // let maxlatitude = coordinates[1]

      // for (let i = 2; i < coordinates.length; i += 2) {
      //   if (minlongitude > coordinates[i]) {
      //     minlongitude = coordinates[i]
      //   }
      //   if (minlatitude > coordinates[i + 1]) {
      //     minlatitude = coordinates[i + 1]
      //   }
      //   if (maxlongitude < coordinates[i]) {
      //     maxlongitude = coordinates[i]
      //   }
      //   if (maxlatitude < coordinates[i + 1]) {
      //     maxlatitude = coordinates[i + 1]
      //   }
      // }

      sites.push({
        id: prospect.uuid,
        name: `GFX-ID-${prospect.bin_size}-${prospect.grid_id}`,
        rank: prospect.ranking,
        changeRank: -1, // the beta version all data is added so default is increased
        type: commodity,
        image: "https://images.pexels.com/photos/2347774/pexels-photo-2347774.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        // minlatitude,
        // minlongitude,
        // maxlongitude,
        // maxlatitude,
        // long: (minlongitude + maxlongitude) / 2,
        // lat: (minlatitude + maxlatitude) / 2,
        ...prospect
      })
    })
    
    // sites.sort((a, b) => b.rating_technical - a.rating_technical)

    return {
      sites
      // geojson
    }
  } catch (error) {
    throw error
  }
}

export async function fetchUpdatesInGeoprosp({ selectedArea }) {
  // try {
  //   // const response = await serverAPI.get('/updatesInGeoprosp', {
  //   //   params: {
  //   //     prospectType: selectedArea
  //   //   }
  //   // })

  //   // not ready yet

  //   return []
  // } catch (error) {
  //   throw error
  // }
  return []
}

export async function fetchFeaturedProjects({ selectedArea }) {
  try {
    const response = await serverAPI.get('/featuredProjects', {
      params: {
        prospectType: selectedArea
      }
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export async function fetchPublishedArticles({ selectedArea }) {
  try {
    const response = await serverAPI.get('/publishedArticles', {
      params: {
        prospectType: selectedArea
      }
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export async function fetchSearch({ search }) {
  try {
    const response = await serverAPI.get('/search', {
      params: {
        search
      }
    })

    return {
      status: response.data.length ? true : false,
      ...response.data[0]
    } // the data should be object, in json-server still return array
  } catch (error) {
    throw error
  }
}

export async function fetchSimilarProjects({ commodity, siteId }) {
  try {
    const response = await productionAPI.get(`/geofix/grid_specific/${siteId}/similar`, {
      params: {
        grid_id: siteId,
        commodity
      }
    })

    return response.data[0] || [] // the data should be object, in json-server still return array
  } catch (error) {
    throw error
  }
}

export async function fetchSimilarProjectCountries () {
  try {
    const response = await serverAPI.get('/similarProjectCountries')

    return response.data
  } catch (error) {
    throw error
  }
}

export async function fetchSite({
  siteId
}) {
  try {
    const response = await productionAPI.get('/geofix/sites', {
      params: {
        id: siteId
      }
    })

    return response.data[0] // the data should be object, in json-server still return array
  } catch (error) {
    throw error
  }
}

export const fetchDataGeochemSurface = async (data_type) => {
  let url = `${BASE_URL}/geofix/geochemistry/points/${data_type}?withgeojson=True&limit=1000000`;
  try {
      let response = await axiosReact({
        method: 'get',
        url: url,
      });

      return response.data
  } catch (err) {
    throw err
  }
};
export async function saveMineralOccurrences({
  payload
}) {
  try {
    const response = await productionAPI.post('/geofix/mineraloccurrences', payload)
    
    return response.data
  } catch (error) {
    throw error
  }
}

export async function updateMineralOccurrences({
  id,
  payload
}) {
  try {
    const response = await productionAPI.put('/geofix/mineraloccurrences/' + id, payload)
    
    return response.data
  } catch (error) {
    throw error
  }
}

export async function removeMineralOccurrences({
  id
}) {
  try {
    const response = await productionAPI.delete('/geofix/mineraloccurrences/' + id)
    
    return response.data
  } catch (error) {
    throw error
  }
}

export async function fetchReferences({ asset_type, keywords }) {
  try {
    let response = await productionAPI.get('/geoprosp/references/' + asset_type, {
      params: {
        keywords: keywords || ''
      }
    })

    return response.data
  } catch (err) {
    throw err
  }
}

export async function saveUploadGeojson({
  payload
}) {
  try {
    const response = await productionAPI.post('/geoprosp/json/upload', payload)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function fetchListDatabaseDetail ({id}) {
  try {
    const response = await productionAPI.get(`/geoprosp/json/${id}`)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function fetchTopRating({ commodity, bin_size, technical }) {
  try {
      let response = await productionAPI.get('/geofix/grid_top_rating', {
        params: {
          commodity: commodity,
          bin_size: bin_size,
          technical: technical
        }
      })
      return response.data

  } catch (err) {
    throw err
  }
}

export async function fetchPathData({
  payload
}) {
  try {
    const { data } = await productionAPI.post(`/geofix/model3d_drillhole`, payload,{
      headers:{
        'Content-Type': 'application/json'
      }
    })
    return data
  } catch (error) {
    throw error
  }
}

// export async function fetchThreeDimensionGLB({
//   file
// }) {
//   try {
//     const { data } = await productionAPI.get(`/geofix/glb/model3d/${file}`)
 
//     return data
//   } catch (error) {
//     throw error
//   }
// }

/**
 * This method will call rest API server for advanced search
 * @param name input search keyword
 * @param alias input search alias
 * @param limit input search limit
 * @param offset input search offset
 */
export async function advancedSearch({
  name,
  alias = '',
  limit = 10,
  offset = 0,
  cancelToken
}) {
  try {
    const params = {
      name,
      limit,
      offset
    }

    if (alias) {
      params[alias] = alias
    }

    return await productionAPI.get('/geofix/search/composite_names', {
      params,
      cancelToken
    })
  } catch (error) {
    throw error
  } 
}
