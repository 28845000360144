import axiosReact, { getProgressDownload } from '../../../axiosReact'

import { generateKeyword } from '../constant'

import rgbToHex from '../../../helpers/rgbToHex'

export async function fetch({
  // map,
  params,
  fetchProgress,
  fetchFinish
}) {
  try {
    if (params.withgeojson) {
      const { data } = await axiosReact.get('/geofix/illegal_mines', {
        params: {
          geometry_type: 'point',
          commodity: generateKeyword()
        },
        onDownloadProgress(event) {
          getProgressDownload(event, fetchProgress)
        }
      })

      data.features.forEach((feature) => {
        feature.properties.administration = feature.properties.administration[0]

        feature.properties = formatProperty(feature.properties)
      })
  
      fetchFinish()

      return {
        geojson: data
      }
    }
  } catch (error) {
    throw error
  }
}

export function formatProperty(property) {
  return {
    Island: property.administration?.island,
    Longitude: property.longitude,
    Latitude: property.latitude,
    Province: property.administration?.province,
    Regency: property.regency_city,
    'Major Commodity': property.major_commodity,
    Status: property.status,
    'Mine Type': property.mine_type,
    Processing: property.processing,
    'Deposit Type': property.deposit_type
  }
}

export function parseFetch({ data }) {
  return data
}

export function getColor(_, {
  map
}) {
  return {
    red: 1,
    green: 1,
    blue: 1,
    alpha: map.cesiumLayer.alpha
  }
}

export function getIcon(entity, color) {
  let hex = null

  // const status = entity.properties.getValue('').status

  // if (status && status.toLowerCase().includes('inactive')) {
  //   hex = '#808080'
  // } else if (color) {
  //   hex = rgbToHex(
  //     Math.round(color.red * 255),
  //     Math.round(color.green * 255),
  //     Math.round(color.blue * 255)
  //   )
  // } else {
  //   hex = rgbToHex(
  //     Math.random() * 255,
  //     Math.random() * 255,
  //     Math.random() * 255
  //   )
  // }

  // let svg = `
  //   <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
  //   width="260.000000pt" height="280.000000pt" viewBox="0 0 260.000000 280.000000"
  //   preserveAspectRatio="xMidYMid meet">
  //     <style type="text/css">.st0{fill-rule:evenodd;clip-rule:evenodd;}</style>
  //     <g transform="translate(0.000000,280.000000) scale(0.100000,-0.100000)" fill="%23${hex.slice(1)}" stroke="none">
  //       <path d="M1095 1847 c-118 -62 -213 -159 -266 -271 -22 -48 -39 -90 -37 -92 3
  //       -2 21 20 41 48 51 74 122 142 141 135 8 -3 19 -1 23 5 4 8 54 -38 134 -122
  //       l128 -135 -132 -132 -132 -133 23 -22 22 -22 130 134 130 135 130 -135 130
  //       -134 22 22 23 22 -132 133 -132 132 128 134 c79 83 130 130 135 123 3 -6 14
  //       -8 22 -5 19 7 90 -61 141 -135 20 -28 38 -50 41 -48 2 2 -15 44 -37 92 -53
  //       113 -148 210 -268 272 -87 45 -135 57 -91 23 13 -11 42 -29 65 -40 54 -29 76
  //       -55 77 -91 1 -26 -18 -49 -126 -157 l-128 -128 -127 128 c-109 108 -128 131
  //       -127 157 1 36 23 62 77 91 23 11 52 29 65 40 45 35 -6 22 -93 -24z"/>
  //     </g>
  //   </svg>
  // `

  let svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 189.6 189.6" width="60pt" height="60pt" preserveAspectRatio="xMidYMid meet">
  <circle cx="94.8" cy="94.8" r="94.8" style="fill:%23c5a079" />
  <path d="M45.92,48.67c2.12-.14,4.23-.41,6.34-.39A48.63,48.63,0,0,1,72,53.09a9.66,9.66,0,0,1,1,.56c8.44,5.2,16.84,10.44,25.31,15.57a50.26,50.26,0,0,1,16.19,16.4A32,32,0,0,1,119.6,100c0,.28,0,.56,0,1-.35-.34-.59-.55-.81-.78A116.09,116.09,0,0,0,97.93,82.6a6.35,6.35,0,0,0-5.62-.92c-3,.87-6,1.68-9,2.45a1.94,1.94,0,0,1-1.41-.2q-5.12-3.08-10.17-6.26a2.46,2.46,0,0,1-.93-1.28c-.87-3.14-1.71-6.29-2.49-9.46a7.31,7.31,0,0,0-3.71-4.88A111.26,111.26,0,0,0,40,51.65c-.26-.07-.53-.15-.78-.24-.06,0-.1-.08-.25-.21l3-1.46Z" style="fill:%23fff" />
  <path d="M65.2,113.85c-.8-.53-1.68-1.16-2.6-1.71a1.76,1.76,0,0,0-2.64.58,2,2,0,0,0,.88,2.81c.88.55,1.79,1,2.57,1.49l-4,7.47c-1.8-1.11-3.77-2.4-5.81-3.55a2.61,2.61,0,0,0-2-.29,2.09,2.09,0,0,0-1,1.6,2.58,2.58,0,0,0,.95,1.83c1.7,1.2,3.5,2.23,5.28,3.31a6.15,6.15,0,0,0,1,.4l-2.87,5.3c-.82-.56-1.68-1.19-2.58-1.76a1.82,1.82,0,0,0-2.8.54,2.1,2.1,0,0,0,.95,2.81c.84.54,1.7,1.06,2.55,1.6.06,0,.09.1.16.18-.87,1.63-1.71,3.28-2.63,4.89a1.66,1.66,0,0,1-2.54.63q-3.21-1.92-6.37-3.93c-1.12-.72-1.28-1.7-.55-3.05q4.44-8.28,8.92-16.56,9.77-18.14,19.54-36.27c.16-.3.34-.59.5-.88l9.46,5.91C74.83,96,70.07,104.79,65.2,113.85Z" style="fill:%23fff" />
  <path d="M95.19,62.78l-13-8c.63-1.16,1.22-2.39,1.92-3.54a1.66,1.66,0,0,1,2.35-.48c3.25,2,6.5,4,9.72,6a2,2,0,0,1,.72,2.62C96.4,60.5,95.79,61.61,95.19,62.78Z" style="fill:%23fff" />
  <path d="M101.74,153.69c-.59-1.78-1.14-3.45-1.71-5.11a1.53,1.53,0,0,1,0-1c1.37-4.51,2.74-9,4.14-13.61l9.42,1.83c.33.07.63.12.76.51,1.28,3.83,2.57,7.66,3.88,11.57Z" style="fill:%23fff" />
  <path d="M121.92,117.32l-1.19-.83a.27.27,0,0,1-.13-.22c-.17-1.29-.34-2.58-.52-3.9l3.13-1.42c.11,0,.21-.09.3,0l2.7,1.87Z" style="fill:%23fff" />
  <path d="M121.26,127.51l-2.86,1.1a.7.7,0,0,1-.55,0l-7.6-2.86c.36-2.13.7-4.2,1-6.27,0-.22.07-.42.28-.5l6.48-2.5Z" style="fill:%23fff" />
  <polygon points="126.25 104.83 130.13 96 126.26 95.15 126.25 104.83" style="fill:%23fff" />
  <polygon points="121.53 108.15 115.5 103.45 114.54 105.16 121.53 108.15" style="fill:%23fff" />
  <path d="M149.33,110.59c-6.18-1.73-12.79-3.41-16.48-5.61-6.38,9.18-3.37,16.67-9.63,25.67a3.56,3.56,0,0,0-.66,2.18c0,4.15,0,8.3,0,12.75l9.76,0A71.38,71.38,0,0,0,149.33,110.59Z" style="fill:%23fff" />
  <path d="M181.21,94.92a86.29,86.29,0,0,1-86.77,86.3c-47.77-.08-86.11-38.85-86-87C8.46,46.86,47.32,8.29,94.86,8.38A86.31,86.31,0,0,1,181.21,94.92ZM39.45,50.68c-21.36,25.39-22.89,69.94,8.9,97.7,29.12,25.45,69.35,20.27,90.52,1.68Zm110.69,88.23c21.46-25.35,22.82-70-8.9-97.7-28.76-25.11-69-20.54-90.5-1.69Z" style="fill:%23f8564b" />
</svg>`

  return `data:image/svg+xml;utf8,${svg}`
}
