export const GEOLOGICAL_STRATIGRAPHIC_KEYWORDS = {
  age: {
    begins: [
      'bage',
      'bepoch'
    ],
    ends: [
      'tage',
      'tepoch'
    ],
  },
  listSandi: [
    'lbl_gfxfin'
  ],
  useGroups: [
    'usegpfingf'
  ],
  dominantLithologies: [
    'domlith4'
  ],
  positions: [
    'position',
    // {
    //   name: 'Mod Code_2', // need to be updated
    //   split: 'SulA_',
    //   typeSplit: 'left'
    // }
  ]
}

