import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import * as d3 from 'd3-array';
import { 
    ContainerHeaderAnalysis, 
    ContainerImageHeader, 
    HeaderInformationContent, 
    HeaderInformationTitle, 
    NumberContent, 
    TitleHeaderSet 
} from './partials';
import './headerInformation.css'
import './ReactGridLayoutStyle.css'
import TableDesStatistics from './TableDesStatistics';
import { SpinningCircles, ThreeDots } from 'react-loading-icons'
import SelectedData from './SelectedData';
import { connect } from 'react-redux';

const AnalyticInfo = ({...props}) => {
    let {
        data,
        dataFilter,
        dataGroup,
        dataReady,
        setSurfaceType,
        belt,
        project,
        prospect,
        moreFilter,
        moreFilterValue,
        setBeltSelect,
        setProjectSelect,
        setProspectSelect,
        setFilterType,
        setFilterValue,
        selectedMap,
        sidebarState,
        dataLocal
    } = props || {};
    const ReactGridLayout = require('react-grid-layout');
    const [widthContainer, setWidthContainer] = useState(800);
    let column = 50
    let box= document.querySelector('.information')

    useEffect(()=>{
        if(box!==null){
            let wbox=box.offsetWidth
            setWidthContainer(wbox)
        }
    },[box, sidebarState['analytics']])

    if(box!==null){
        window.addEventListener("resize", function(event) {
            let w = box.offsetWidth
            setWidthContainer(w)
        })
    }

    const configurationInfo=([
        {
            key: 'sts',
            title: 'Statistical Descriptions',
            layout: {i: "sts", x:0, y:0, w:50, h:44},
            size:'7em'
        },
        {
            key: 'tote',
            title: 'Total Elements',
            layout:{i: "tote", x:25, y:44, w:25, h:15},
            size:'2em'
        },
        {
            key: 'sample',
            title: 'Total Samples',
            layout: {i: "sample", x:0, y:44, w:25, h:15},
            size:'2em'
        },
        // {
        //     key: 'dataSelect',
        //     title: 'Selection Data Analysis',
        //     layout:{i:'dataSelect',x:0,y:59,w:50,h:45},
        //     size:'3em'
        // },
        // {
        //     key: 'drilin',
        //     title: 'Drilling Intercept',
        // }
    ])

    const statistics = () => {
        let arr={};
        Object.keys(dataGroup).map((i)=>{
            let dataAvailable=[];
            dataGroup[i].map((j)=>{
                if(j!==null && j!==undefined){
                    dataAvailable.push(j)
                }
            })
            let min = d3.min(dataAvailable) ? d3.min(dataAvailable) : null
            let max = d3.max(dataAvailable) ? d3.max(dataAvailable) : null
            let mean = d3.mean(dataAvailable) ? d3.mean(dataAvailable).toFixed(2) : null
            let median = d3.median(dataAvailable) ? d3.median(dataAvailable).toFixed(2) : null
            let n = dataAvailable.length
            let modal = d3.mode(dataAvailable) ? d3.mode(dataAvailable).toFixed(2) : null
            let sd = d3.deviation(dataAvailable) ? d3.deviation(dataAvailable).toFixed(2) : null
            let varian = d3.variance(dataAvailable) ? d3.variance(dataAvailable).toFixed(2) : null
            let value = {
                n: n,
                mean: mean,
                median: median,
                mode: modal,
                std: sd,
                var: varian,
                range: "(" + min+" - "+ max + ")"
            }
            arr[i]=value;
        })

        return arr
    }
    let stat = statistics()

    let sorterValue = Object.keys(stat).sort(function(a,b){
        return (stat[a].n===null)-(stat[b].n===null) || +(stat[a].n<stat[b].n) || -(stat[a].n>stat[b].n)
    })

    const contentInfo = (key,size) => {
        if(key === 'sts'){
            return(
                TableDesStatistics({
                    dataTable:stat,
                    sortResult:sorterValue,
                    dataReady: dataReady,
                    size:size,
                    dataLocal:dataLocal
                })
            )
        } else if (key === 'tote'){
            return(
                dataReady?
                    selectedMap.length === 0 ?
                    <NumberContent>
                        {sorterValue.length}
                    </NumberContent>
                    :
                    <NumberContent>
                        {sorterValue.length}
                    </NumberContent>
                :
                <ThreeDots
                height={size}
                width={size}
                fill='goldenrod'
                stroke="darkgoldenrod"
                speed="1"
                fillOpacity="0.5"
                strokeOpacity="0.5"
                strokeWidth="3"
            />
            )
        } else if (key === 'drilin'){
            return(
                <div>
                    TC160118 : 100.3 m @ 9.69% Cu, 8.76 g/t Au TC160116 : 125.5 m @
                </div>
            )
        } else if (key === 'sample'){
            return(
                dataReady?
                selectedMap.length === 0 ?
                <NumberContent>
                    {data.length}
                </NumberContent>
                :
                <>
                <NumberContent>
                    {dataFilter.length}
                </NumberContent>
                <NumberContent size={'0.8em'}>
                    { }   of {data.length}
                </NumberContent>
                </>
                :
                <ThreeDots
                height={size}
                width={size}
                fill='goldenrod'
                stroke="darkgoldenrod"
                speed="1"
                fillOpacity="0.5"
                strokeOpacity="0.5"
                strokeWidth="3"
            />
            )
        }else if (key === 'dataSelect'){
            return(
                SelectedData({
                    belt:belt,
                    project:project,
                    prospect:prospect,
                    moreFilter:moreFilter,
                    moreFilterValue:moreFilterValue,
                    setSurfaceType:setSurfaceType,
                    width:widthContainer,
                    setBeltSelect:setBeltSelect,
                    setProjectSelect:setProjectSelect,
                    setProspectSelect:setProspectSelect,
                    setFilterType:setFilterType,
                    setFilterValue:setFilterValue
                })
            )
        }
    }

    
    const generateDOM = () => {
        const config = configurationInfo;
        return _.map(config, function (l) {
                return (
                    <div key={l.key} className="header-information-container" data-grid={l.layout}>
                        <ContainerHeaderAnalysis>
                            <HeaderInformationTitle className="header-information">
                                <TitleHeaderSet>
                                    {l.title}
                                </TitleHeaderSet>
                            </HeaderInformationTitle>
                                <HeaderInformationContent>
                                    { contentInfo(l.key,l.size)}
                                </HeaderInformationContent>
                        </ContainerHeaderAnalysis>
                    </div>
                );

            // }
        });
    };


    return (
        <ReactGridLayout
            className="information"
            rowHeight= {2}
            cols={column}
            containerPadding={[3,3]}
            width={widthContainer}
            isDraggable={true}
            isResizable={false}
            draggableHandle='.header-information'
            margin={[3,3]}
            preventCollision={false}
            useCSSTransforms={false}
            style={{height:"100%", width:"calc(100% - 15px)"}}
        >
            {generateDOM()}

        </ReactGridLayout>
    );
}

function mapStateToProps({ sidebarState }) {
    return {
        sidebarState
    }
}

export default connect(mapStateToProps, null)(AnalyticInfo)