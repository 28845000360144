import * as actionTypes from '../../constants/store/actionTypes/chat'

import {
  CHATBAR_WIDTH
} from '../../constants/Menubars/Chatbar'

const defaultChatState = {
  toggle: false,
  minimize: false,
  width: CHATBAR_WIDTH
}

function chatReducer(state = defaultChatState, { type, payload }) {
  switch (type) {
    case actionTypes.TOGGLE_CHATBAR_SUCCESS:
      return {
        ...state,
        toggle: payload.toggle,
        minimize: payload.minimize
      }
    case actionTypes.MINIMIZE_CHATBAR:
      return {
        ...state,
        minimize: payload.minimize
      }
    case actionTypes.RESET_CHAT:
      return {
        ...defaultChatState
      }
    default:
      return state
  }
}

export default chatReducer
