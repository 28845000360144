import React from 'react'

import FocusContainer, {
  Header,
  Paragraph
} from '../../StyledComponent'
import ChatPNG from '../../HelpImage/chat-new.png'
import { LIST_HELP } from '../../constant'


function HelpChatNew(props) {
  return <>
    <FocusContainer {...props}>
      <Header>{LIST_HELP['chat'].detail.newChat.header}</Header>
      <img src={ChatPNG} alt="Chat new image" width="1100px" />
    </FocusContainer>
  </>
}

export default HelpChatNew
