import React from 'react'

import UseSimpleAndDoubleClick from '../../../../../helpers/UseSimpleAndDoubleClick'

import LayerbarIcon from '../../../shares/LayerbarIcon'

import {
  ReactComponent as ThreeDotsIcon
} from '../../../../../../images/Icons/ellipsis-v.svg'

/**
 * This function to create three dots button
 * @param ref is the ref to insert
 * @param isActive to show the status of the current button being toggled or not
 * @param toggleAble to tell that button can be clicked or not
 * @param onClickHandler the event that will happen when this button clicked
 * @param onClickHandler the event that will happen when this button clicked double
 */
const ThreeDotsButton = React.forwardRef((props, ref) => {
  const {
    isActive,
    toggleAble,
    onClickHandler,
    onDoubleClickHandler
  } = props

  return (
    <div ref={ref}>
      <LayerbarIcon
        isActive={isActive ? true : false}
        no-margin
        toggleAble={toggleAble}
      >
        <ThreeDotsIcon
          title="More Option"
          onClick={
            onDoubleClickHandler
              ? UseSimpleAndDoubleClick(
                  onClickHandler,
                  onDoubleClickHandler
                )
              : onClickHandler
          }
        />
      </LayerbarIcon>
    </div>
  )
})

export default ThreeDotsButton
