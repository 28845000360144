import {
  fork,
  put,
  select,
  takeLatest,
  call
} from 'redux-saga/effects'

import ClientSocket, { listenMessage } from '../../apis/socket'

import * as socketActionTypes from '../../constants/store/actionTypes/socket'

import {
  connectChatSocketSuccess,
  disconnectChatSocketSuccess,
  setChatRoom,
  setChatRoomsSuccess,
  setUserSocket,
} from '../actions/socket'

import {
  toggleChatbar
} from '../actions/chatbar'

import {
  getChatSocket,
  getRoomSocket,
  getUserSocket,
} from '../selectors/socket'

export function* connectChatSocketSaga(_) {
  // const socket = yield call(() => {
  //   return ClientSocket.connect('user')
  // })

  // yield call(ClientSocket.connectToNameSpace, socket)
  // yield call(ClientSocket.connectNewUserMessage, socket)
  // yield call(ClientSocket.listenRemoveUser, socket)

  // yield put(setUserSocket({ userSocket: socket }))
}

export function* disconnectChatSocketSaga({ }) {
  const { chatSocket, userSocket } = yield select((state) => {
    return {
      chatSocket: getChatSocket(state),
      userSocket: getUserSocket(state)
    }
  })

  if (chatSocket) {
    chatSocket.disconnect()
  }

  if (userSocket) {
    userSocket.disconnect()
  }

  yield put(toggleChatbar({
    toggle: false,
    minimize: false
  }))

  yield put(setChatRoom({
    chatRoom: null
  }))

  yield put(disconnectChatSocketSuccess({}))
}

export function* setChatRoomsSaga({ payload, type }) {
  const socket = yield call(() => {
    return ClientSocket.connect('chat')
  })
  payload?.chatRooms?.forEach(room => {
    // ClientSocket.connectRoomByMessageId(socket, room.message_id)
  })

  ClientSocket.appendRoomMessage(socket, listenMessage)
  // From socket /user
  yield call(ClientSocket.connectToNameSpace, socket, { token: null, cb: null })
  yield call(ClientSocket.connectNewUserMessage, socket)
  yield call(ClientSocket.listenRemoveUser, socket, { backToChat: null })

  yield put(setChatRoomsSuccess({ ...payload, socket }))
}
export function* watchSocket() {
  yield takeLatest(socketActionTypes.SET_CHAT_ROOMS, setChatRoomsSaga)
  // yield takeLatest(socketActionTypes.CONNECT_CHAT_SOCKET, connectChatSocketSaga)
  yield takeLatest(socketActionTypes.DISCONNECT_CHAT_SOCKET, disconnectChatSocketSaga)
}

const sagaHelpers = [
  fork(watchSocket)
]

export default sagaHelpers

