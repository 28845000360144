import * as Cesium from 'cesium'

/**
 * This method used to find key in cesium entity
 * @param {Object} properties cesium entity properties
 * @param {string} key is the key to search in the properties
 * @returns value of the key in properties
 */
export function findKeyProperties({
  properties,
  key
}) {
  if (!properties) {
    return null
  }

  let propertiesValue = properties.getValue(Cesium.JulianDate.now())

  let searchKeywords = key.split('>')[0].split('.')
  
  for (let i = 0; i < searchKeywords.length; i++) {
    if (!propertiesValue) {
      break
    }

    propertiesValue = propertiesValue[searchKeywords[i]]
  }

  return propertiesValue
}
