import React from 'react'

import getHeightUnit from './helpers/getHeightUnit'

import styled from 'styled-components'

const Wrapper = styled.div`
  background: ${props => props.color};
  min-width: ${props => props.size};
  min-height: ${props => props.size};
  border-radius: 50%;
  border: 1px solid black;
`

/**
 * This component will create point component of legend symbol
 * @param {String} color is the color selected
 * @param {String} size of the selected symbol
 */
function Point({
  color,
  size
}) {
  const { height, unit } = getHeightUnit(size)

  return <Wrapper color={color} size={height / 2 + unit} />
}

export default Point
