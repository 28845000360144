import React from 'react'

import { ReactComponent as SaveIcon } from '../../../../images/Icons/save.svg'

import { ToggleSVGButton } from '../../../shares/ToggleSVGIcon'

import { SUCCESS_GREEN } from '../../../shares/ColorTemplate'

/**
 * This component will create in point
 * @param {String} title - hover title
 * @param {Function} setConfirmation - method to change state confirmation
 */
function SavePoint({
  title,
  setConfirmation
}) {
  const toggle = false

  return <ToggleSVGButton
    flex
    margin="0.5em"
    active={toggle}
    fillActive={SUCCESS_GREEN.hover}
    fillInActive={SUCCESS_GREEN.default}
    onClick={() => {
      setConfirmation({
        show: true,
        process: 'save',
        event: () => {}
      })
    }}
  >
    <SaveIcon title={title} />
  </ToggleSVGButton>
}

export default SavePoint
