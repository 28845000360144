import React from 'react'

import { connect } from 'react-redux'

import {
  checkLayerCategory,
  uncheckLayerCategory,
  checkSubLayerCategory,
  uncheckSubLayerCategory
} from '../../../../../../store/actions/catalog'

import FlexWrapperRecursion from './FlexWrapperRecursion'

import ToggleIcon from '../../../shares/ToggleIcon'
import FlexWrapper from '../../../shares/FlexWrapper'
import ToolbarText from '../../../shares/ToolbarText'

import DataTypeSelector from '../DataTypeSelector'

import {
  checkInnerListMapRec
} from './helpers/treeData'
  
function MultipleLevel({
  category,
  categoryValue,
  checkLayerCategory,
  uncheckLayerCategory,
  checkSubLayerCategory,
  uncheckSubLayerCategory
}) {
  const { toggle, layerType, layerTypeDetail } = categoryValue

  if (!layerTypeDetail) {
    return null
  }

  const subCategoryKeys = Object.keys(layerTypeDetail[layerType])
  
  function onClickCategoryHandler() {
    if (toggle) {
      uncheckLayerCategory({
        category
      })
    } else {
      checkLayerCategory({
        category
      })
    }
  }

  return <>
    <FlexWrapper
      center
      no-overflow
    >
      <ToggleIcon
        toggle={toggle}
        size="smaller"
        toggleAble={checkInnerListMapRec(categoryValue)}
        onClick={onClickCategoryHandler}
      />
      <DataTypeSelector
        data={categoryValue}
        category={category}
      />
      <ToolbarText
        width="17em"
        text={category}
        max={40}
      />
    </FlexWrapper>
    {
      toggle && subCategoryKeys.map((subCategoryKey, index) => {
        const subCategoryValue = layerTypeDetail[layerType][subCategoryKey]
        const lastSubCategory = []

        return (
          <FlexWrapperRecursion
            key={index}
            category={category}
            subCategory={subCategoryValue}
            subCategoryKey={subCategoryKey}
            marginLeft={1}
            lastSubCategory={lastSubCategory}
            checkSubLayerCategory={checkSubLayerCategory}
            uncheckSubLayerCategory={uncheckSubLayerCategory}
          />
        )
      })
    }
  </>
}

const mapDispatchToProps = {
  checkLayerCategory,
  uncheckLayerCategory,
  checkSubLayerCategory,
  uncheckSubLayerCategory
}

export default connect(null, mapDispatchToProps)(MultipleLevel)
