import axiosReact, { getProgressDownload } from '../../../axiosReact'

export async function fetch({
  map,
  params,
  fetchProgress,
  fetchFinish
}) {
  try {
    const response = await axiosReact.get('/geofix/accessibility/oil_depot', {
      params,
      onDownloadProgress(event) {
        getProgressDownload(event, fetchProgress)
      }
    })

    fetchFinish()

    return {
      geojson: response.data
    }
  } catch (error) {
    throw error
  }
}

export function parseFetch({ data }) {
  return data
}

export function getColor(entity, {
  map
}) {
  return {
    red: 1,
    green: 1,
    blue: 0,
    alpha: map.cesiumLayer.alpha
  }
}
