import React from 'react'

import Text from '../../../shares/Text'

import {
  ellipsisText
} from '../helpers'

function getFontByType(type) {
  if (type === 'header') {
    return '1em'
  }

  return '0.8em'
}

function getWeightByType(type) {
  if (type === 'header') {
    return 600
  }

  return 400
}

function ToolbarText({
  width = "3em",
  text,
  max,
  type,
  onClick
}) {
  return <Text
    title={text}
    width={width}
    margin="0.2em 0.5em"
    overflowX="hidden"
    fontSize={getFontByType(type)}
    fontWeight={getWeightByType(type)}
    pointer
    onClick={onClick}
    style={{
      color: 'white'
    }}
  >
    {
      ellipsisText({
        text: text || '',
        max
      })
    }
  </Text>
}

export default ToolbarText
