import React from 'react'

import HideButton from './HideButton'
import AddPoint from './AddPoint'
import MovePoint from './MovePoint'

import { FlexRow } from '../../../BasicStyle/FlexWrapper'

import iziToast from 'izitoast'

import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% + 1em);
  background-color: #e0e0e0;
  margin-left: -0.5em;
  margin-top: -0.5em;
  margin-bottom: 0.5em;
  flex-wrap: wrap;
  overflow: hidden;
`

const Header = styled.span`
  background: black;
  color: white;
  padding: 0.3em 0.7em;
  font-family: Abel;
  font-size: 0.9em;
  cursor: default;
`
/**
 * Editor header component from title and top header button
 * @param {Object} editorCesiumState - state of editor in cesium
 * @param {Function} setEditorCesiumState - method to set editor in cesiumjs
 * @param {Function} hideEditor - method to hide editor
 */
function HeaderForm({
  editorCesiumState,
  setEditorCesiumState,
  hideEditor
}) {
  function addPointHandler() {
    if (editorCesiumState.entity) {
      iziToast.info({
        title: 'Warning',
        message: 'Please update, cancel, or delete your current work'
      })

      return
    }

    setEditorCesiumState((state) => {
      const status = !state.add

      if (!status) {
        return {
          ...state,
          add: false,
          move: false,
          edit: false
        }
      }

      return {
        ...state,
        add: true,
        move: false,
        edit: false
      }
    })
  }

  function movePointHandler() {
    if (!editorCesiumState.entity) {
      iziToast.warning({
        title: 'Error',
        message: 'Please pick entity that need to be move'
      })

      return
    }

    setEditorCesiumState((state) => {
      return {
        ...state,
        move: !state.move
      }
    })
  }

  return <Wrapper>
    <Header>Mineral Occurrences Editor</Header>
    <HideButton hideEditor={hideEditor} />
    <FlexRow>
      <AddPoint
        isActive={editorCesiumState.add}
        onClickHandler={addPointHandler}
      />
      {
        editorCesiumState.entity
        &&
          <MovePoint
            isActive={editorCesiumState.move}
            onClickHandler={movePointHandler}
          />
      }
    </FlexRow>
  </Wrapper>
}

export default HeaderForm
