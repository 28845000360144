// import { notify } from "react-notify-toast";

export const toggleCheck = ({...props}) => {
    let {
        inputName,
        nameCheckbox,
        config,
        setConfig
    } = props;
    var checks = document.querySelectorAll(`input[name=${nameCheckbox}]:checked`);
    let max=10;
    if(checks.length>=max+1){
        // notify.show("You can select maximum of " + max + " elements.");
        const newState = {...config};
        newState.checked[inputName]= false;
        setConfig(newState)
    } else {
        const newState = {...config};
        newState.checked[inputName]= !config.checked[inputName];
        setConfig(newState)
    }
};

export const selectAll=({...props})=>{
    let {
        value,
        setCheckedAll,
        config,
        setConfig
    } = props;
    setCheckedAll(value);
    const newState = {...config};
    for (const inputName in newState.checked){
        newState.checked[inputName]= value;
    }
    setConfig(newState)
};

export const getCheckboxValues=({...props})=>{
    let {
        // customRef,
        // buttonSubmitRef,
        // buttonResetRef,
        nameCheckbox,
        config,
        setConfig,
        setToggle
    } = props;
    // customRef.current.style.display = "none";
    // buttonSubmitRef.current.style.display= "none";
    // buttonResetRef.current.style.display= "block";
    let checkboxValues=[];
    let checkboxTitle=[];
    var checks = document.querySelectorAll(`input[name=${nameCheckbox}]:checked`);
    checks.forEach((check)=>{
        checkboxValues.push(check.value);
        checkboxTitle.push(check.title)
    });
    let newValue_={...config};
    newValue_.seriesSelect=checkboxValues;
    newValue_.customSelect=`(${checkboxTitle})`;
    setConfig(newValue_);
    setToggle(false)
};

