import React, { useEffect, useRef, useState } from 'react'

import {
  SITEBAR_MENU_BUTTONS
} from '../../../constants/Menubars/DetailSitebar'

import { connect } from 'react-redux'

// import {
//   fetchSimilarProjects,
//   fetchSimilarProjectCountries
// } from '../../../apis/server'

import {
  selectSite,
  changeSiteMenuCategory,
  visitSiteHome,
  visitSiteBack,
  visitSiteForward
} from '../../../store/actions/sites'

import {
  toggleSitebar,
  minimizeSitebar
} from '../../../store/actions/sitebar'

import {
  setCesiumContentRight
} from '../helpers/Rightbar'

import RightbarToggleButton from '../../Buttons/RightToggleButton'
import SitebarMenuToggleButton from '../../Buttons/SitebarMenuToggleButton'
import SimilarProjectMenu from '../../Menus/SimilarProjectMenu'
import SiteOverviewMenu from '../../Menus/SiteOverviewMenu'
import SiteRankingCriteriaMenu from '../../Menus/SiteRankingCriteriaMenu'
import SiteReferencesMenu from '../../Menus/SiteReferencesMenu'
import GlossaryMenu from '../../Menus/GlossaryMenu'

import { ReactComponent as CloseIcon } from '../../../images/Icons/close.svg'
import { ReactComponent as BackIcon } from '../../../images/Icons/back.svg'
import { ReactComponent as ForwardIcon } from '../../../images/Icons/forward.svg'
import { ReactComponent as HomeIcon } from '../../../images/Icons/home.svg'

import './DetailSitebar.css'

function DetailSitebar({
  changeSiteMenuCategory,
  selectSite,
  sites,
  visitSiteHome,
  visitSiteBack,
  visitSiteForward,
  sitebar,
  toggleSitebar,
  minimizeSitebar
}) {
  const sitebarMenuRef = useRef(null)

  const [ scrollPosition, setScrollPosition ] = useState({
    overview: {
      axis: 'y',
      y: 0
    },
    rankingCriteria: {
      axis: 'y',
      y: 0
    },
    similarProject: {
      axis: 'y',
      y: 0
    },
    references: {
      axis: 'y',
      y: 0
    },
    glossary: {
      axis: 'y',
      y: 0
    }
  })

  const [ criteriaToggle, setCriteriaToggle ] = useState('')
  const [ countryToggle, setCountryToggle ] = useState('')

  const [ matchSites, setMatchSites ] = useState([])
  const [ compareSite, setCompareSite ] = useState({
    from: null,
    target: null
  })
  const [ countries, setCountries ] = useState([])
  const [ compare, setCompare ] = useState(false)

  const selected = sites.selected
  const site = selected.site
  const category = selected.menu.category

  function scroll (event, detailContainer) {
    if (sitebarMenuRef.current) {
      const container = sitebarMenuRef.current
      const keyMenu = category.databaseName
      if (
        keyMenu === 'similarProject'
        && detailContainer !== undefined
      ) {
        setScrollPosition({
          ...scrollPosition,
          [keyMenu]: {
            ...scrollPosition[keyMenu],
            y: detailContainer.scrollTop
          }
        })
      } else if (event) {
        if (keyMenu) {
          setScrollPosition({
            ...scrollPosition,
            [keyMenu]: {
              ...scrollPosition[keyMenu],
              y: container.scrollTop
            }
          })
        }
      } else {
        if (keyMenu) {
          container.scrollTo({
            top: scrollPosition[keyMenu].y,
            behaviour: 'smooth'
          })
        }
      }
    }
  }

  function renderMenu () {
    if (category.databaseName === 'overview') {
      return <SiteOverviewMenu
        scrollPosition={scrollPosition}
        selected={selected}
        commodity={sites.commodity}
      />
    } else if (category.databaseName === 'rankingCriteria') {
      return <SiteRankingCriteriaMenu
        selected={selected}
        sitebarMenuRef={sitebarMenuRef}
        scrollPosition={scrollPosition}
        setScrollPosition={setScrollPosition}
      />
    } else if (category.databaseName === 'similarProject') {
      return <SimilarProjectMenu
        compare={compare}
        compareSite={compareSite}
        countries={countries}
        criteriaToggle={criteriaToggle}
        countryToggle={countryToggle}
        matchSites={matchSites}
        scroll={scroll}
        scrollPosition={scrollPosition}
        setCompare={setCompare}
        setCompareSite={setCompareSite}
        setCountries={setCountries}
        setCriteriaToggle={setCriteriaToggle}
        setCountryToggle={setCountryToggle}
        setMatchSites={setMatchSites}
      />
    } else if (category.databaseName === 'references'){
      return <SiteReferencesMenu />
    } else if (category.databaseName === 'glossary') {
      return <GlossaryMenu commodity={sites.commodity} />
    }
  }

  function closeSitebar() {
    toggleSitebar({
      toggle: false,
      minimize: false
    })

    setTimeout(() => {
      selectSite({ site: null })
    }, 100)
  }

  useEffect(() => {
    if (sitebarMenuRef.current) {
      scroll(null)
    }
  }, [category])

  useEffect(() => {
    setCesiumContentRight(sitebar)
  }, [sitebar])

  useEffect(() => {
    setCompare(false)
  }, [sites.selected.site])

  return <>
    <div
      className="detail-sitebar"
      style={{
        right: sitebar.toggle && !sitebar.minimize ? 0 : `-${sitebar.width}`,
        backgroundImage: site
          ? `url("${site.image}")`
          : null,
        backgroundSize: site
          ? 'cover'
          : null,
        boxShadow: site
          ? 'box-shadow: 3px 3px 9px 1px #ffffff'
          : null
      }}
    >
      <div className="detail-sitebar-layer-container">
        {sitebar.toggle && <RightbarToggleButton minimize={sitebar.minimize} minimizeBar={minimizeSitebar} />}
        <div className="detail-sitebar-layer">
        </div>
      </div>
      { 
        site
          ? <div className="detail-sitebar-header">
              <div className="col">
                <img
                  className="detail-sitebar-header-image"
                  src={site.image }
                  alt={site.name || site.prospect_name }
                />
              </div>
              <div className="col" style={{
                justifyContent: 'center'
              }}>
                <p
                  className="
                    detail-sitebar-header-text
                    detail-sitebar-header-text-name
                  "
                >{ site.name || site.prospect_name }</p>
                <p
                  className="detail-sitebar-header-text"
                >{ site.location }</p>
              </div>
              <HomeIcon
                className={
                  `detail-sitebar-icon
                   home-sitebar-icon
                  ${
                    sites.visit.position
                      ? 'home-sitebar-icon-pick'
                      : ''
                  }`
                }
                title={
                  sites.visit.position
                    ? `Back to ${sites.visit.sites[0].name}`
                    : null
                }
                onClick={() => {
                    visitSiteHome({
                      site: sites.visit.sites[0]
                    })
                    setCompare(false)
                  }
                }
              />
              <BackIcon
                className={
                  `detail-sitebar-icon
                   back-sitebar-icon
                  ${
                    sites.visit.position > 0
                      ? 'back-sitebar-icon-pick'
                      : ''
                  }`
                }
                title={sites.visit.position > 0
                  ? `Back to ${ sites.visit.sites[sites.visit.position - 1].name }`
                  : null
                }
                onClick={() => {
                    visitSiteBack({
                      site: sites.visit.sites[sites.visit.position - 1]
                    })
                    setCompare(false)
                  }
                }
              />
              <ForwardIcon
                className={
                  `detail-sitebar-icon
                   forward-sitebar-icon
                  ${
                    sites.visit.position < sites.visit.sites.length - 1
                      ? 'forward-sitebar-icon-pick'
                      : ''
                  }`
                }
                title={sites.visit.position < sites.visit.sites.length - 1
                  ? `Forward to ${ sites.visit.sites[sites.visit.position + 1].name }`
                  : null
                }
                onClick={() => {
                  visitSiteForward({
                    site: sites.visit.sites[sites.visit.position + 1]
                  })
                  setCompare(false)
                }}
              />
              <CloseIcon
                className="detail-sitebar-icon close-sitebar-icon"
                onClick={closeSitebar}
              />
            </div>
          : <></>
      }
      <div className="detail-sitebar-menu-container">
        <div className="detail-sitebar-menu-toggle">
          {
            SITEBAR_MENU_BUTTONS.map((menu, index) => {
              return <SitebarMenuToggleButton
                key={index}
                menu={selected.menu}
                pickMenu={menu}
                changeSiteMenuCategory={changeSiteMenuCategory}
              />
            })
          }
        </div>
      </div>
      {
        category.databaseName === 'references' ?
        <div
          className="sitebar-menu"
          ref={sitebarMenuRef}
          onScroll={scroll}
          style={{overflow:'hidden'}}
        >
          {renderMenu()}
        </div>
        :
        <div
          className="sitebar-menu"
          ref={sitebarMenuRef}
          onScroll={scroll}
        >
          {renderMenu()}
        </div>
      }
    </div>
  </>
}

function mapStateToProps(state) {
  return {
    sitebar: state.sitebar,
    sites: state.sites
  }
}

const mapDispatchToProps = {
  changeSiteMenuCategory,
  selectSite,
  visitSiteHome,
  visitSiteBack,
  visitSiteForward,
  toggleSitebar,
  minimizeSitebar
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailSitebar)
