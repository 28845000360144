import * as actionTypes from '../../constants/store/actionTypes/catalog'

export function setCatalog(payload) {
  return {
    type: actionTypes.SET_CATALOG,
    payload
  }
}

export function setCatalogSuccess(payload) {
  return {
    type: actionTypes.SET_CATALOG_SUCCESS,
    payload
  }
}

export function setCatalogError(payload) {
  return {
    type: actionTypes.SET_CATALOG_ERROR,
    payload
  }
}

export function changeTypeLayer(payload) {
  return {
    type: actionTypes.CHANGE_TYPE_LAYER,
    payload
  }
}

export function changeTypeLayerSuccess(payload) {
  return {
    type: actionTypes.CHANGE_TYPE_LAYER_SUCCESS,
    payload
  }
}

export function changeTypeLayerError(payload) {
  return {
    type: actionTypes.CHANGE_TYPE_LAYER_ERROR,
    payload
  }
}

export function fetchListMap(payload) {
  return {
    type: actionTypes.FETCH_LIST_MAP,
    payload
  }
}

export function fetchListMapSuccess(payload) {
  return {
    type: actionTypes.FETCH_LIST_MAP_SUCCESS,
    payload
  }
}

export function fetchListMapError(payload) {
  return {
    type: actionTypes.FETCH_LIST_MAP_ERROR,
    payload
  }
}

export function fetchMap(payload) {
  return {
    type: actionTypes.FETCH_MAP,
    payload
  }
}

export function fetchMapSuccess(payload) {
  return {
    type: actionTypes.FETCH_MAP_SUCCESS,
    payload
  }
}

export function fetchMapError(payload) {
  return {
    type: actionTypes.FETCH_MAP_ERROR,
    payload
  }
}

export function addMap(payload) {
  return {
    type: actionTypes.ADD_MAP,
    payload
  }
}

export function addMapSuccess(payload) {
  return {
    type: actionTypes.ADD_MAP_SUCCESS,
    payload
  }
}

export function addMapError(payload) {
  return {
    type: actionTypes.ADD_MAP_ERROR,
    payload
  }
}

export function checkLayerCategory(payload) {
  return {
    type: actionTypes.CHECK_LAYER_CATEGORY,
    payload
  }
}

export function checkLayerCategorySuccess(payload) {
  return {
    type: actionTypes.CHECK_LAYER_CATEGORY_SUCCESS,
    payload
  }
}

export function checkLayerCategoryError(payload) {
  return {
    type: actionTypes.CHECK_LAYER_CATEGORY_ERROR,
    payload
  }
}

export function uncheckLayerCategory(payload) {
  return {
    type: actionTypes.UNCHECK_LAYER_CATEGORY,
    payload
  }
}

export function uncheckLayerCategorySuccess(payload) {
  return {
    type: actionTypes.UNCHECK_LAYER_CATEGORY_SUCCESS,
    payload
  }
}

export function uncheckLayerCategoryError(payload) {
  return {
    type: actionTypes.UNCHECK_LAYER_CATEGORY_ERROR,
    payload
  }
}

export function checkSubLayerCategory(payload) {
  return {
    type: actionTypes.CHECK_SUB_LAYER_CATEGORY,
    payload
  }
}

export function checkSubLayerCategorySuccess(payload) {
  return {
    type: actionTypes.CHECK_SUB_LAYER_CATEGORY_SUCCESS,
    payload
  }
}

export function checkSubLayerCategoryError(payload) {
  return {
    type: actionTypes.CHECK_SUB_LAYER_CATEGORY_ERROR,
    payload
  }
}

export function uncheckSubLayerCategory(payload) {
  return {
    type: actionTypes.UNCHECK_SUB_LAYER_CATEGORY,
    payload
  }
}

export function uncheckSubLayerCategorySuccess(payload) {
  return {
    type: actionTypes.UNCHECK_SUB_LAYER_CATEGORY_SUCCESS,
    payload
  }
}

export function uncheckSubLayerCategoryError(payload) {
  return {
    type: actionTypes.UNCHECK_SUB_LAYER_CATEGORY_ERROR,
    payload
  }
}

export function toggleEntity(payload) {
  return {
    type: actionTypes.TOGGLE_ENTITY,
    payload
  }
}

export function toggleEntitySuccess(payload) {
  return {
    type: actionTypes.TOGGLE_ENTITY_SUCCESS,
    payload
  }
}

export function toggleEntityError(payload) {
  return {
    type: actionTypes.TOGGLE_ENTITY_ERROR,
    payload
  }
}

export function removeMap(payload) {
  return {
    type: actionTypes.REMOVE_MAP,
    payload
  }
}

export function removeMapSuccess(payload) {
  return {
    type: actionTypes.REMOVE_MAP_SUCCESS,
    payload
  }
}

export function removeMapError(payload) {
  return {
    type: actionTypes.REMOVE_MAP_ERROR,
    payload
  }
}

export function removeProjectMap(payload) {
  return {
    type: actionTypes.REMOVE_PROJECT_MAP,
    payload
  }
}

export function removeProjectMapSuccess(payload) {
  return {
    type: actionTypes.REMOVE_PROJECT_MAP_SUCCESS,
    payload
  }
}

export function removeProjectMapError(payload) {
  return {
    type: actionTypes.REMOVE_PROJECT_MAP_ERROR,
    payload
  }
}

export function removeAllMap(payload) {
  return {
    type: actionTypes.REMOVE_ALL_MAP,
    payload
  }
}

export function removeAllMapSuccess(payload) {
  return {
    type: actionTypes.REMOVE_ALL_MAP_SUCCESS,
    payload
  }
}

export function removeAllMapError(payload) {
  return {
    type: actionTypes.REMOVE_ALL_MAP_ERROR,
    payload
  }
}

export function resetCatalog(payload) {
  return {
    type: actionTypes.RESET_CATALOG,
    payload
  }
}
