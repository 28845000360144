import Text from '../../shares/Text'

import styled from 'styled-components'

const DropdownText = styled(Text).attrs((props) => {
  return {
    style: {
      marginTop: props.dropdown ? '0em' : '-0.3em',
      color: props.dark && 'white',
      top: props.currentSelect ? '0.6em' : '0.2em',
      width: `calc(${props.width} + 1.3em)`
    }
  }
})`
  left: 0.5em;
  position: absolute;
  font-weight: 600;
  font-size: 0.7em;
  transition: all 330ms;
  pointer-events: none;
  user-select: none;
  white-space:nowrap;
  overflow:hidden;
  text-overflow: ellipsis
`

export default DropdownText
