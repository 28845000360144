import React from 'react'

import LayerbarIcon from '../../../../../shares/LayerbarIcon'

import {
  ReactComponent as SaveIcon
} from '../../../../../../../../images/Icons/save.svg'

function SaveButton({
  onClickHandler,
  ...rest
}) {
  /**
   * This function create save button
   * @param onClickHandler event that trigger when this button clicked
   * @param rest margin top of this component
   */

  return (
    <LayerbarIcon
      success
      isActive
      {...rest}
    >
      <SaveIcon
        title="Save Format"
        onClick={onClickHandler}
      />
    </LayerbarIcon>
  )
}

export default SaveButton
