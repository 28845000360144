import React, { useEffect,useState,useRef }  from 'react';
import * as d3 from 'd3';
import { 
    ChartAnalysis, 
    HeaderChart, 
    LabelCheckbox, 
    TitleChart, 
    WrapperChartSvg, 
    WrapperManySvg, 
    WrapperSpinner,
    WrapperSpinnerBackground
} from '../helpers/AnalysisStyle';
import { 
    CenterSelect, 
    CheckboxWrapper, 
    DropdownWrapper, 
    VersusWrapper, 
    WrapperLog 
} from './partials';
import Checkbox from '../../Checkbox';
import { pointsInPolygon } from '../helpers/mathFunction';
import { convertScale} from './convertScale';
import { capitalizeElement} from '../helpers/capitalizeFirstLetter';
import LassoIcon from '../../../images/Icons/lassoScatter.svg'
import SyncIcon from '../../../images/Icons/sync.svg'
import { Puff, SpinningCircles } from 'react-loading-icons';
import { dropdownElemSelect } from '../helpers/dropdownElements';
import iziToast from 'izitoast';
import styled from 'styled-components';

// const MenuScatter = styled.button.attrs({
//     className: "container-menu-scatter"
// })
// `
//     /* top: ${ props=>props.top? props.top : '3em' };
//     right: ${props=>props.right? props.right : '0.5em'}; */
// `


let ScatterPlot = ({...props}) => {
    let {
        dataReady,
        selectedMap,
        widthContainer,
        heightContainer,
        config,
        setConfig,
        className,
        dropdown,
        init,
        selected,
        setSelected,
        dataFilter,
        dataNonFilter,
        dataFilterGroup,
        dataNonFilterGroup,
        dataLocal,
        idDataLocal
    } = props;

    const refScatter = useRef (null);
    const containerAll= d3.select(refScatter.current)
    const centerButton="center-add-button-scatter-"+className;
    const classNameAll=className+"-allscatter";
    const [lassoTool,setLassoTool]=useState('lassoOff');
    const [syncWithMap,setSyncWithMap]=useState(false);
    const [checkedX,setCheckedX]=useState(false);
    const [checkedY,setCheckedY]=useState(false);
    const [toggleCenter,setToggleCenter]=useState(false);
    const [loading, setLoading] = useState(false)
    const [valDDX, setValDDX] = useState();
    const [valDDY, setValDDY] = useState()
    const [nullData, setNullData] = useState(false)
    const [data, setData] = useState([])
    const [dataGroup, setDataGroup] = useState({})


    const [configScatter,setConfigScatter]=useState({
        setX: '',
        setY: '',
        scaleX: 'linear',
        scaleY: 'linear',
        classification:"",
        color:null,
        colorBorder:null,
        sizeCircle:2,
        showRegression:true,
    });

    let handleChangeScaleX=(e)=>{
        let value = e.target.checked? 'logarithmic': 'linear';
        let config_={...configScatter};
        config_.scaleX=value;
        setConfigScatter(config_)
        setCheckedX(e.target.checked)
    }

    let handleChangeScaleY=(e)=>{
        let value = e.target.checked? 'logarithmic': 'linear';
        let config_={...configScatter};
        config_.scaleY=value;
        setConfigScatter(config_)
        setCheckedY(e.target.checked)
    }

    let handleOnClickLasso=(e)=>{
        let value = e.target.checked? 'lassoOn':'lassoOff'
        setLassoTool(value)
    }

    const configViewBox = {
        minX: 0,
        minY: 0,
        width: widthContainer,
        height: heightContainer
    }

    const margin = { top: 40, right: 70, bottom: 20, left: 55 },
        width = configViewBox.width - margin.left - margin.right,
        height = configViewBox.height - margin.top - margin.bottom;
    
    function unhighlight() {
        d3.selectAll('.circleScatter')
            .classed("unhighlighted", true);
    }
    
    function highlightMap(ids) {
        d3.selectAll('.circleScatter')
            .filter(function(d, i) {
                return ids.includes(d.id);
            })
            .classed('unhighlighted',false)
            .classed("highlightMap", true);
    }

    function highlightScatter(ids) {
        d3.selectAll('.circleScatter')
            .filter(function(d, i) {
                return ids.includes(d.id);
            })
            .classed('unhighlighted',false)
            .classed("highlightScatter", true);
    }

    useEffect(()=>{
        if(!config) return;
        let newConfig={...configScatter}
        Object.entries(config).forEach(([key,value])=>{
            if(key in newConfig){
                newConfig[key]=value;
            }
        })
        if(config.setX!=='' && config.setY!==''){
            setValDDX({
                value: config.setX,
                label: config.setX
            })
            setValDDY({
                value: config.setY,
                label: config.setY
            })
        }
        setConfigScatter(newConfig)
        setLoading(true)
    },[])

    useEffect(()=>{
        if (dataReady===false) return;
        if(dataFilter.length === 0 ) return;
        if(dataNonFilter.length === 0 ) return;
        if(Object.keys(dataFilterGroup).length === 0 ) return;
        if(Object.keys(dataNonFilterGroup).length === 0 ) return;

        if(syncWithMap){
            setData(dataFilter)
            setDataGroup(dataFilterGroup)
        } else {
            setData(dataNonFilter)
            setDataGroup(dataNonFilterGroup)
        }
    },[syncWithMap,dataReady])

    useEffect(()=>{
        if(config.setX==="" && config.setY==="" || 
        dataLocal
            // data[config.setX]===undefined && data[config.setY]===undefined || 
            // !init.includes(config.setX) || !init.includes(config.setY)
            ){
            let newConfig = {...configScatter}
            if(init.length!==0){
                let label = (d) => dataLocal ? d : capitalizeElement(d.slice(0,d.lastIndexOf("_")))
                if(className==='sp-01'){
                    newConfig.setX=init[0]
                    newConfig.setY=init[1]
                    setValDDX({
                        value: init[0],
                        label: label(init[0])
                    })
                    setValDDY({
                        value: init[1],
                        label: label(init[1])
                    })
                } else if(className==='sp-02'){
                    newConfig.setX=init[2]
                    newConfig.setY=init[3]
                    setValDDX({
                        value: init[2],
                        label: label(init[2])
                    })
                    setValDDY({
                        value: init[3],
                        label: label(init[3])
                    })
                } else if(className==='sp-03'){
                    newConfig.setX=init[4]
                    newConfig.setY=init[5]
                    setValDDX({
                        value: init[4],
                        label: label(init[4])
                    })
                    setValDDY({
                        value: init[5],
                        label: label(init[5])
                    })
                } else{
                    newConfig.setX=init[0]
                    newConfig.setY=init[1]
                    setValDDX({
                        value: init[0],
                        label: label(init[0])
                    })
                    setValDDY({
                        value: init[1],
                        label: label(init[1])
                    })
                }
                setConfigScatter(newConfig)
            }
        }
    },[init])

    useEffect(()=>{
        setConfig(configScatter)
    },[configScatter])

    let series=[]
    let info=[]
    useEffect(()=>{
        dropdown.map((item)=>{
            let infoSeries = {
                name: item.pickShow,
                unit: "ppm",
            }
            info.push(infoSeries)
            series.push(item.pickName)
        })
    },[dropdown])

    
    useEffect(() => {
        if (selectedMap.length!==0){
            unhighlight()
            highlightMap(selectedMap);
        } else if(selected.length!==0){
            d3.selectAll('.circleScatter')
            .classed('highlightMap',false)
            unhighlight()
            highlightScatter(selected);
        } else{
            d3.selectAll('.circleScatter')
            .classed('highlightMap',false)
            .classed("unhighlighted", false);
        }
    },[selectedMap]);

    useEffect(()=>{
        if(configScatter.setX!=="" && configScatter.setY!=="" ){
            setToggleCenter(true)
        }
    },[configScatter.setY,configScatter.setX])


    useEffect(() => {
        if (dataReady===false) return;
        if (dataGroup[configScatter.setX]===undefined) return;
        if (dataGroup[configScatter.setY]===undefined) return;
        if (configScatter.setX==="" ) return;
        if (configScatter.setY==="" ) return;
        if (Object.keys(dataGroup).length===0) return;

        setLoading(true)

        //---delete all last data to update new data----//
        d3.selectAll("."+centerButton)
            .style("opacity",1)
            .transition()
            .duration(700)
            .style("opacity",0)
            .remove();
        d3.selectAll('.'+className)
            .style("opacity",1)
            .transition()
            .duration(700)
            .style("opacity",0)
            .remove();
        d3.select("."+className)
            .selectAll(".circleScatter")
            .attr("r", 2)
            .transition()
            .duration(700)
            .attr("r", 0)
            .remove();
        d3.selectAll(".tooltip-scatter"+classNameAll)
            .remove()

        const dataFilter=data.filter(i=>{
            let xi=i[configScatter.setX]
            let yi=i[configScatter.setY]
            let scaleX=configScatter.scaleX
            let scaleY=configScatter.scaleY
            if(scaleX==='logarithmic' && scaleY==="linear"){
                if(xi<=0){
                    return false
                }
            } else if(scaleY==='logarithmic' && scaleX==="linear"){
                if(yi<=0){
                    return false
                }
            } else if(scaleX==="logarithmic" && scaleY==="logarithmic"){
                if(xi<=0){
                    return false
                }
                if(yi<=0){
                    return false
                }
            }
    
            if(xi===undefined && yi===undefined){
                return false
            }else if(xi===undefined){
                return false
            } else if (yi===undefined){
                return false
            }
    
            return true
        })

        if (dataFilter.length===0){
            setNullData(true)
            setLoading(false)
            iziToast.warning({
                title: 'Error',
                message: 'No data to Plot in Scatter!'
            })
            return;
        } else {
            setNullData(false)
        }

        const dataGroupFilter=()=>{
            let ser=[]
            dataFilter.map((item)=>{
                let{id, sample_id, ...elem}=item
                ser.push(elem)
            })
    
            let d={}
            ser.forEach((row) => {
                Object.entries(row).forEach(([key,value])=>{
                    if(!(key in d)){
                        d[key]=[]
                    }
                    d[key].push(value);
                })     
            });
            return d
        }
        
        //--------------- prepare svg scatter plot ----------------//
        
        const containerTest = containerAll
        .attr("viewBox", `${configViewBox.minX} ${configViewBox.minY} ${configViewBox.width} ${configViewBox.height}`)

        const svg = containerTest
            .append("g")
            .attr("class",className)
            .attr("transform", `translate(${margin.left}, ${margin.top})`)
        
        //---------create x axis---------//
        let labelXY = (d) => dataLocal? d : capitalizeElement(d.slice(0,d.lastIndexOf("_")))
        const xAxis = svg
            .append("g")
            .attr("transform", `translate(0, ${height})`)
        const xAxisLabel = svg
            .append("text")
        svg
        .append('marker')
        .attr('id', 'arrowhead-right')
        .attr('refX', 4)
        .attr('refY', 5)
        .attr('markerWidth', 10)
        .attr('markerHeight', 10)
        .append('path')
        // .attr('d', "M2,2 L2,13 L8,7 L2,2")
        .attr('d', 'M 0 0 L 5 5 L 0 10')
        .attr('stroke', 'white')
        .attr('orient','auto-start-reverse')
        // .attr('stroke-width', 1)
        .attr('fill', 'none');
        // .attr('fill', 'white');
        xAxisLabel
            .attr("x", width+15)
            .attr("y",height)
            .attr("fill","white")
            .attr("class","axisTitle")
            .transition()
            .duration(1000)
            .text( labelXY(configScatter.setX) + ' (ppm)');
        if (d3.max(dataGroup[configScatter.setX])>1000){
            xAxis.selectAll('text')
                .style("text-anchor", "end")
                .attr("dx", "-.8em")
                .attr("dy", ".15em")
                .attr("transform", "rotate(-65)");
        }

        //---------create y axis---------//
        const yAxis = svg.append("g")
        const yAxisLabel = svg.append("text")
        svg
        .append('marker')
        .attr('id', 'arrowhead-up')
        .attr('refX', 5)
        .attr('refY', 1)
        .attr('markerWidth', 10)
        .attr('markerHeight', 10)
        .append('path')
        // .attr('d', "M2,2 L2,13 L8,7 L2,2")
        .attr('d', 'M 0 5 L 5 0 L 10 5')
        .attr('stroke', 'white')
        .attr('orient','auto-start-reverse')
        // .attr('stroke-width', 1)
        .attr('fill', 'none');
        // .attr('fill', 'white');
        yAxisLabel
            .attr("x", margin.left-90)
            .attr("y",margin.top-55)
            .attr("fill","white")
            .attr("class","axisTitle")
            .transition()
            .duration(1000)
            .text(labelXY(configScatter.setY) + ' (ppm)');

        //--------------- create scatter plot based on axis ----------------//
        const scatterCircle = svg.append("g")
        convertScale({
            configScatter: configScatter,
            setCheckedX: setCheckedX,
            setCheckedY: setCheckedY,
            data: dataFilter,
            dataGroup:dataGroupFilter(),
            height: height,
            width: width,
            xAxis: xAxis,
            yAxis: yAxis,
            svgCircle: scatterCircle,
            svg: svg,
            classNameAll: classNameAll,
            series: series,
            setLoading: setLoading,
        })
        if(selectedMap.length!==0 && selected.length!==0){
            unhighlight()
            highlightMap(selectedMap);
            highlightScatter(selected);
        } else if (selectedMap.length!==0){
            unhighlight()
            highlightMap(selectedMap);
        } else if(selected.length!==0){
            unhighlight()
            highlightScatter(selected);
        }
    },[dataGroup,data,configScatter.setX, configScatter.setY, configScatter.scaleX, configScatter.scaleY,dataReady,width,height]);
    
    // useEffect(()=>{
    //     d3.select("."+className).selectAll(".selection").remove();
    //     d3.select("."+className).selectAll(".terminator").remove();
    //     setSelected([]);
    //     d3.selectAll("circle")
    //         .classed("unhighlighted", false);
    //     return;
    // },[syncMap])

    useEffect(()=>{
        //------selection tool - lasso-----//
        let coords = [];
        let selected_=[];
        let line = d3.line()

        const dragMove=(event)=>{
            coords.push([event.x-50, event.y-38])
            
            containerAll.select(".selection")
            .attr("d", line(coords))
            .attr("transform", `translate(${margin.left}, ${margin.top})`);

            selected_=[];
            containerAll.selectAll("circle").each(function(d,i){
                const point = [d3.select(this).attr("cx"), d3.select(this).attr("cy")];
                if (pointsInPolygon(point,coords)){
                    selected_.push(d.id);
                }

            });
            unhighlight();
            highlightScatter(selected_);
        }
        if(lassoTool==="lassoOn"){
            containerAll.classed("select-tool-lasso-scatter", true);
            const drag = d3.drag()
                .on("start", function(){
                    coords = [];
                    containerAll.select (".selection").remove();
                    containerAll.select (".terminator").remove();
                    containerAll
                        .append("path")
                        .attr("class","selection");
                    d3.selectAll("circle")
                        .classed("highlightScatter", false)
                })
                .on("drag", (event)=>dragMove(event))
                .on("end", function(){
                    if(coords.length===0){
                        d3.select("."+className).selectAll(".selection").remove();
                        d3.select("."+className).selectAll(".terminator").remove();
                        setSelected([]);
                        if(selectedMap.length!==0){
                            d3.selectAll("circle")
                            .classed("highlightScatter", false)
                            unhighlight()
                            highlightMap(selectedMap)
                        }else{
                            d3.selectAll("circle")
                            .classed("unhighlighted", false)
                            .classed("highlightScatter", false)
                            .classed("highlightMap", false);
                        }
                        return;
                    }
                    containerAll.append("path")
                    .attr("class","terminator")
                    .attr("d", line([coords[0], coords[coords.length-1]]) )
                    .attr("transform", `translate(${margin.left}, ${margin.top})`);

                    let selectedUpdate={...selected};
                    selectedUpdate=selected_;
                    setSelected(selectedUpdate)
                })
            containerAll.call(drag)
        } else if(lassoTool==="lassoOff"){
            containerAll.classed("select-tool-lasso-scatter", false);
            d3.selectAll(".selection").remove();
            d3.selectAll(".terminator").remove();
            setSelected([]);
            if(selectedMap.length!==0){
                d3.selectAll("circle")
                .classed("highlightScatter", false)
                unhighlight()
                highlightMap(selectedMap)
            }else{
                d3.selectAll("circle")
                .classed("unhighlighted", false)
                .classed("highlightScatter", false)
                .classed("highlightMap", false);
            }
            const dragNull = d3.drag()
            .on("start", null)
            .on("drag", null)
            .on("end", null)
            containerAll.call(dragNull)
        } 
    },[lassoTool])
    

    return (
        <ChartAnalysis className={classNameAll}>
            
            <TitleChart className="header-plot">
            Scatter Plot
            </TitleChart>

            <HeaderChart className="header-plot">
                <DropdownWrapper>
                    {dropdownElemSelect({
                        dropdownOptions: dropdown,
                        config: configScatter,
                        setConfig: setConfigScatter,
                        val: "setX",
                        valDD: valDDX,
                        setValDD: setValDDX
                    })}
                    <VersusWrapper>
                        vs
                    </VersusWrapper>
                    {dropdownElemSelect({
                        dropdownOptions: dropdown,
                        config: configScatter,
                        setConfig: setConfigScatter,
                        val: "setY",
                        valDD: valDDY,
                        setValDD: setValDDY
                    })}
                </DropdownWrapper>
                <CheckboxWrapper>
                    <LabelCheckbox title="Log scale(x-axis)" marginTop="0.6em" marginLeft="0em" className='noDrag'>
                        <Checkbox 
                            type="checkbox"
                            value={configScatter.scaleX}
                            onChange={handleChangeScaleX}
                            checked={checkedX}
                        />
                        Log-X
                    </LabelCheckbox>
                    <LabelCheckbox title="Log scale(y-axis)" marginTop="0.6em" marginLeft="0.5em" className='noDrag'>
                        <Checkbox 
                            type="checkbox"
                            value={configScatter.scaleY}
                            onChange={handleChangeScaleY}
                            checked={checkedY}
                        />
                        Log-Y
                    </LabelCheckbox>
                </CheckboxWrapper>
            </HeaderChart>

            {loading?
            <WrapperSpinnerBackground small>
                <WrapperSpinner>
                    <Puff
                    height={heightContainer/2}
                    width={widthContainer/2}
                    fill='goldenrod'
                    stroke="darkgoldenrod"
                    speed="1"
                    fillOpacity="0.5"
                    strokeOpacity="0.5"
                    strokeWidth="2"
                />
                </WrapperSpinner>
            </WrapperSpinnerBackground>
            :
            <></>
            }
            {
                nullData?
                dataLocal?
                <CenterSelect> No correlation data between {configScatter.setX + ' (ppm)'} and {configScatter.setY + ' (ppm)'}</CenterSelect>
                :
                <CenterSelect> No correlation data between {capitalizeElement(configScatter.setX.slice(0,configScatter.setX.lastIndexOf("_"))) + ' (ppm)'} and {capitalizeElement(configScatter.setY.slice(0,configScatter.setY.lastIndexOf("_"))) + ' (ppm)'}</CenterSelect>
                :
                <></>
            }
            <WrapperChartSvg>
                <WrapperManySvg>
                <svg ref={refScatter} height={"100%"} width={"100%"} />
                </WrapperManySvg>
            </WrapperChartSvg>
            {/* <CenterSelect
                className={centerButton}
                toggle={toggleCenter}
            >
                <h4>Add Scatter Plot</h4>
                <WrapperLog>
                    {dropdownElements("X","setX")}
                    <div style={{marginLeft:"0.5em"}}/>
                    {dropdownElements("Y","setY")}
                </WrapperLog>
            </CenterSelect> */}
            <div className='container-menu-scatter'>
                <label title="Selection Lasso Tool"> 
                    <input 
                        type="checkbox"
                        value={lassoTool}
                        onChange={handleOnClickLasso}
                        hidden
                    />
                    <span><img src={LassoIcon} alt="lasso_icon" className="img-menu-scatter"/></span> 
                </label>
            </div>
            <div className='container-menu-scatter' style={{top:'6.5em'}}>
                <label title="Sync with Map"> 
                    <input 
                        type="checkbox"
                        value={syncWithMap}
                        onChange={(e)=>{
                            setSyncWithMap(e.target.checked)
                        }}
                        hidden
                    />
                    <span><img src={SyncIcon} alt="sync_icon" className="img-menu-scatter"/></span> 
                </label>
            </div>
        </ChartAnalysis>
    );
}

export default ScatterPlot;