import styled from 'styled-components';

export const TextCenter=styled.div`
    margin: 0;
    position: absolute;
    top: ${props => props.top ? props.top : '40%'};
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    font-size: 30px;
    font-family: abel;
    `

export const ButtonImport=styled.button`
    display: inline-block;
    cursor: pointer;
    font-size: 18px;
    padding:5px;
    border: 1px solid white;
    border-radius: 4px;
    width: 250px;
    transition: 0.7s;
    background-color: black;
    color:white;
    font-family: abel;
    margin-left:${props => props.left ? props.left : 0};
    z-index: 1;
    :hover{
        color:goldenrod;
        border: 1px solid goldenrod;
        font-size: 19px;
        transition: 1s;
    }
`

export const ButtonLink=styled.button`
    display: inline-block;
    cursor: pointer;
    font-size: 18px;
    padding:5px;
    border: 1px solid white;
    border-radius: 4px;
    width: 50px;
    transition: 0.4s;
    background-color: black;
    color:white;
    font-family: abel;
    margin-left:${props => props.left ? props.left : 0};
    z-index: 1;
    :hover{
        color:goldenrod;
        border: 1px solid goldenrod;
        font-size: 19px;
        transition: 0.7s;
    }
`

export const CenterGrid=styled.div`
    grid-area: 1/1/2/3;
    margin-left:2px;
    margin-right:2px;
    box-sizing: border-box;
`

export const SquareDragDrop=styled.div`
    background-color: gray;
    padding: 30px;
    display: inline-block;
    border: 1px solid red;
`

export const ImageButton=styled.img`
    height:${props=>props.height?props.height:'30px'};
    display:block;
    margin:auto;
    margin-bottom:${props=>props.marginBottom?props.marginBottom:'0.3em'};
    filter: ${props => props.color==='white' && 'invert(100%) sepia(93%) saturate(152%) hue-rotate(37deg) brightness(700%) contrast(100%)' };
`;

export const BackButton=styled.img`
    height:${props=>props.height?props.height:'30px'};
    display:block;
    margin-bottom:${props=>props.marginBottom?props.marginBottom:'0.3em'};
    filter: ${props => props.color==='white' && 'invert(100%) sepia(93%) saturate(152%) hue-rotate(37deg) brightness(700%) contrast(100%)' };
`;

export const ContainerImageButton=styled.div`
    color: ${props => props.on ? 'goldenrod' : 'white'};
    cursor: pointer;
    opacity: 1;
    text-align: center;
    margin:0.5em;
    font-size: 0.7em;
    padding: 0.5em;
    padding-bottom:${props => props.marginBottom? props.marginBottom : '1em'};
    min-width:${props => props.width? props.width : '50px'};
    height:${props => props.width && `calc(${props.width} + 10px)` };
    border: ${props => props.border && '1px solid gray'};
    border-color: ${props => props.borderColor && props.borderColor };
    label {
        font-family: abel, 'Roboto Condensed', sans;
        padding-bottom: 0.5em;
    }
    &:hover {
        color: goldenrod;
        border: ${props => props.border && '1px solid goldenrod'};
    }
    & > * {
        cursor: pointer;
    }
    &:focus {
        border-color: goldenrod;
        background-color: #242424;
    }
    &:focus > label{
        color: goldenrod;
    }
    &:hover ${ImageButton} {
        filter: invert(60%) sepia(32%) saturate(935%) hue-rotate(5deg) brightness(102%) contrast(98%);
    }
    &:hover ${BackButton} {
        filter: invert(60%) sepia(32%) saturate(935%) hue-rotate(5deg) brightness(102%) contrast(98%);
    }
`;

