import * as actionTypes from '../../constants/store/actionTypes/rightclick'

const initialState = {
  position: {
    pageX: null,
    pageY: null
  },
  toggle: false,
  context: []
}

function rightClickReducer(state = initialState, { type, payload }) {
  switch(type) {
    case actionTypes.CLOSE_CONTEXT:
      return {
        ...state,
        toggle: false,
        context: [],
        position: {
          pageX: null,
          pageY: null
        }
      }
    case actionTypes.SET_CONTEXT:
      return {
        ...state,
        toggle: true,
        context: payload.context,
        position: payload.position
      }
    case actionTypes.RESET_RIGHTCLICK:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default rightClickReducer
