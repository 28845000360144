// IUGS Classification diagram for felsic rocks based on modal percentages of three felsic minerals (quartz, alkali feldspar, and plagioclase feldspar)
// Hess, P. C., Origins of Igneous Rocks. Harvard University Press: Cambridge, 1989

export const felsic = {
  info: 'IUGS Classification diagram for felsic rocks based on modal percentages of three felsic minerals (quartz, alkali feldspar, and plagioclase feldspar) (Hess, P. C., Origins of Igneous Rocks. Harvard University Press: Cambridge, 1989)',
  data: [
    {
      class: 'Quartzolite',
      values: [
        {
          q: 100,
          a: 0,
          p: 0
        },
        {
          q: 90,
          a: 0,
          p: 10
        },
        {
          q: 90,
          a: 10,
          p: 0
        }
      ],
      rotation: null
    },
    {
      class: 'Quartz-rich Granitoid',
      values: [
        {
          q: 90,
          a: 10,
          p: 0
        },
        {
          q: 60,
          a: 40,
          p: 0
        },
        {
          q: 60,
          a: 0,
          p: 40
        },
        {
          q: 90,
          a: 0,
          p: 10
        }
      ],
      rotation: null
    },
    {
      class: 'Alkali-feldspar-granite',
      values: [
        {
          q: 60,
          a: 40,
          p: 0
        },
        {
          q: 20,
          a: 80,
          p: 0
        },
        {
          q: 20,
          a: 72,
          p: 8
        },
        {
          q: 60,
          a: 36,
          p: 4
        }
      ],
      rotation: null
    },
    {
      class: 'Syeno-granite',
      values: [
        {
          q: 60,
          a: 36,
          p: 4
        },
        {
          q: 20,
          a: 72,
          p: 8
        },
        {
          q: 20,
          a: 52,
          p: 28
        },
        {
          q: 60,
          a: 26,
          p: 14
        }
      ],
      rotation: null
    },
    {
      class: 'Monzo-granite',
      values: [
        {
          q: 60,
          a: 26,
          p: 14
        },
        {
          q: 20,
          a: 52,
          p: 28
        },
        {
          q: 20,
          a: 28,
          p: 52
        },
        {
          q: 60,
          a: 14,
          p: 26
        }
      ],
      rotation: null
    },
    {
      class: 'Granodiorite',
      values: [
        {
          q: 60,
          a: 14,
          p: 26
        },
        {
          q: 20,
          a: 28,
          p: 52
        },
        {
          q: 20,
          a: 8,
          p: 72
        },
        {
          q: 60,
          a: 4,
          p: 36
        }
      ],
      rotation: null
    },
    {
      class: 'Tonalite',
      values: [
        {
          q: 60,
          a: 4,
          p: 36
        },
        {
          q: 20,
          a: 8,
          p: 72
        },
        {
          q: 20,
          a: 0,
          p: 80
        },
        {
          q: 60,
          a: 0,
          p: 40
        }
      ],
      rotation: null
    },
    {
      class: 'Alkali-feldspar Quartz Syenite',
      values: [
        {
          q: 20,
          a: 80,
          p: 0
        },
        {
          q: 20,
          a: 72,
          p: 8
        },
        {
          q: 5,
          a: 85.5,
          p: 9.5
        },
        {
          q: 5,
          a: 95,
          p: 0
        }
      ],
      rotation: null
    },
    {
      class: 'Quartz Syenite',
      values: [
        {
          q: 20,
          a: 72,
          p: 8
        },
        {
          q: 20,
          a: 52,
          p: 28
        },
        {
          q: 5,
          a: 62,
          p: 33
        },
        {
          q: 5,
          a: 85.5,
          p: 9.5
        }
      ],
      rotation: null
    },
    {
      class: 'Quartz Monzonite',
      values: [
        {
          q: 20,
          a: 52,
          p: 28
        },
        {
          q: 20,
          a: 28,
          p: 52
        },
        {
          q: 5,
          a: 33,
          p: 62
        },
        {
          q: 5,
          a: 62,
          p: 33
        }
      ],
      rotation: null
    },
    {
      class: 'Quartz Monzodiorite or Monzogabbro',
      values: [
        {
          q: 20,
          a: 28,
          p: 52
        },
        {
          q: 20,
          a: 8,
          p: 72
        },
        {
          q: 5,
          a: 9.5,
          p: 84.5
        },
        {
          q: 5,
          a: 33,
          p: 62
        }
      ],
      rotation: null
    },
    {
      class: 'Quartz Diorite or Quartz Gabbro',
      values: [
        {
          q: 20,
          a: 8,
          p: 72
        },
        {
          q: 20,
          a: 0,
          p: 80
        },
        {
          q: 5,
          a: 0,
          p: 95
        },
        {
          q: 5,
          a: 9.5,
          p: 85.5
        }
      ],
      rotation: null
    },
    {
      class: 'Alkali Feldspar Syenite',
      values: [
        {
          q: 5,
          a: 85.5,
          p: 9.5
        },
        {
          q: 5,
          a: 95,
          p: 0
        },
        {
          q: 0,
          a: 100,
          p: 0
        },
        {
          q: 0,
          a: 90,
          p: 10
        }
      ],
      rotation: null
    },
    {
      class: 'Syenite',
      values: [
        {
          q: 5,
          a: 62,
          p: 33
        },
        {
          q: 5,
          a: 85.5,
          p: 9.5
        },
        {
          q: 0,
          a: 90,
          p: 10
        },
        {
          q: 0,
          a: 65,
          p: 35
        }
      ],
      rotation: null
    },
    {
      class: 'Monzonite',
      values: [
        {
          q: 5,
          a: 33,
          p: 62
        },
        {
          q: 5,
          a: 62,
          p: 33
        },
        {
          q: 0,
          a: 65,
          p: 35
        },
        {
          q: 0,
          a: 35,
          p: 65
        }
      ],
      rotation: null
    },
    {
      class: 'Monzodiorite or Monzogabbro',
      values: [
        {
          q: 5,
          a: 9.5,
          p: 85.5
        },
        {
          q: 5,
          a: 33,
          p: 62
        },
        {
          q: 0,
          a: 35,
          p: 65
        },
        {
          q: 0,
          a: 10,
          p: 90
        }
      ],
      rotation: null
    },
    {
      class: 'Diorite or Gabbro',
      values: [
        {
          q: 5,
          a: 0,
          p: 95
        },
        {
          q: 5,
          a: 9.5,
          p: 85.5
        },
        {
          q: 0,
          a: 10,
          p: 90
        },
        {
          q: 0,
          a: 0,
          p: 100
        }
      ],
      rotation: null
    }
  ]
}
