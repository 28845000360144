import React, { useState, useEffect } from 'react'

import Wrapper from './Wrapper'
import Slider from './Slider'
import Label from './Label'

function AlphaSlider({
  height = '0.5em',
  width = '3em',
  min,
  max,
  initialValue,
  valueOnChange,
  textPosition = 'right',
  textSpace = '3.8em',
  withText = true,
  onDoubleClickHandler = () => {}
}) {
  const [ value, setValue ] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return <>
    <Wrapper
      height={height}
      width={width}
    >
      <Slider
        height={height}
        width={width}
        min={min}
        max={max}
        step="0.02"
        value={value}
        onChange={(event) => {
          setValue(event.target.value)
          valueOnChange(event.target.value)
        }}
        onWheel={(event) => {
          let newValue = value
  
          if (event.deltaY < 0) {
            newValue = value - 0.1
          } else if (event.deltaY > 0) {
            newValue = value + 0.1
          }
  
          if (newValue < min) {
            newValue = min
          }
  
          if (newValue > max) {
            newValue = max
          }

          if (!isNaN(newValue)) {
            setValue(newValue)
            valueOnChange(newValue)
          }
        }}
        onDoubleClick={() => {
          onDoubleClickHandler(setValue)
        }}
      />
      {
        withText
        &&
          <Label
            textPosition={textPosition}
            textSpace={textSpace}
          >
            {Math.floor(value * 100) + '%'}
          </Label>
      }
    </Wrapper>
  </>
}

export default AlphaSlider
