import React from 'react'

import ReactLoading from 'react-loading'

import {
  CenteredFlexRow
} from '../../../BasicStyle/FlexWrapper'

function FetchRankLoading() {
  return <CenteredFlexRow
    width={`calc(100% - 15em)`}
  >
    <ReactLoading
      color="black"
      type="spin"
      height="1em"
      width="1em"
    />
    <span style={{
      marginLeft: '0.5em',
      fontFamily: 'Abel'
    }}>
      Fetching...
    </span>
  </CenteredFlexRow>
}

export default FetchRankLoading
