import styled from 'styled-components'

const DropdownFixed = styled.div`
  position: fixed;
  width: ${props => `calc(${props.width} + 1.4em)`};
  height: ${props => `calc(${props.height})`};
  overflow-y: scroll;
  z-index: 5;
  pointer-events: ${props => !props.dropdown && 'none'};
  &::-webkit-scrollbar {
    width: ${props => !props.dropdown && 0 };
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${props => props.thumbColor};
  }
`

export default DropdownFixed
