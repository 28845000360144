import React, { useState } from 'react'

import resetEntities from '../../../../../packages/cesium-add-on/resetEntities'

import GlobeContent from './GlobeContent'
import Menu from './Menu'
import MenuTileset from './MenuTileset'

/**
 * Content components are loop the list of the display map and entitites
 * @param display the maps that have been filtered by search
 * @param setDisplay method to swap redux state of display
 */
function Content({
  display,
  setDisplay
}) {
  // listToggle to show each entity when being toggled
  const [ listToggle, setListToggle ] = useState({})
  const [ toolbarToggle, setToolbarToggle ] = useState({})
  const [ indexOnDrag, setIndexOnDrag ] = useState(null)

  function onDragEnd() {
    setIndexOnDrag(null)
    resetEntities()
  }

  return <>
    <GlobeContent />
    {
      display.maps.map((map, index) => {
        function onDrag() {
          setIndexOnDrag(index)
        }

        function swap() {
          if (indexOnDrag === null) {
            return
          }

          if (index === indexOnDrag) {
            return
          }

          const cloneMaps = [...display.maps]

          const tempMap = cloneMaps[indexOnDrag]
          cloneMaps[indexOnDrag] = cloneMaps[index]
          cloneMaps[index] = tempMap

          setDisplay({
            maps: cloneMaps
          })

          setIndexOnDrag(index)
        }
        const key = map.id + map.name + map.category + map.subCategory
        // this entities is getting changed in toolbar
        return map.dataSource ?
          <Menu
            key={key}
            map={map}
            dataSource={map.dataSource}
            marginLeft="0em"
            listToggle={listToggle}
            setListToggle={setListToggle}
            toolbarToggle={toolbarToggle}
            setToolbarToggle={setToolbarToggle}
            swap={swap}
            onDrag={onDrag}
            onDragEnd={onDragEnd}
          />
          : map.tileset ?
          <MenuTileset
            key={key}
            map={map}
            marginLeft="0em"
            listToggle={listToggle}
            setListToggle={setListToggle}
            toolbarToggle={toolbarToggle}
            setToolbarToggle={setToolbarToggle}
            swap={swap}
            onDrag={onDrag}
            onDragEnd={onDragEnd}
          />
          : null
      })
    }
  </>
}

export default Content
