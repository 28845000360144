export function checkInnerListMapRec(data) {
  if (!data) {
    return false
  } else if (data.map) {
    return true
  } else if (data.listMap && data.listMap.length) {
    return true
  } else if (data.listMap) {
    return false
  }
  
  if (data.layerTypeDetail) {
    return checkInnerListMapRec(data.layerTypeDetail[data.layerType])
  } else if (data.subCategory) {
    for (let key in data.subCategory) {
      let result = checkInnerListMapRec(data.subCategory[key])
  
      if (result) {
        return result
      }
    }

    return false
  } else if (typeof data === 'object') {
    for (let key in data) {
      let result = checkInnerListMapRec(data[key])
    
      if (result) {
        return result
      }
    }
  }

  return false
}
