import React, { useState, useEffect } from 'react'

function SitebarMenuBorderHeaderSpan ({
  headerRef,
  className
}) {
  const [ width, setWidth ] = useState(0)

  useEffect(() => {
    if (headerRef.current) {
      setWidth(headerRef.current.offsetWidth + 1) 
    }
  }, [ headerRef.current ])

  return <>
    <span 
      style={{
        width: width
      }}
      className={ className }
    ></span>
  </>
}

export default SitebarMenuBorderHeaderSpan