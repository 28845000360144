import React, { useState, useEffect, useRef } from 'react'
import instance from '../../../../../../../apis/axiosReact'

import * as ColorTemplate from '../../../../../../shares/ColorTemplate'

import FlexWrapper from '../../../../shares/FlexWrapper'

import DetailMenu from './DetailMenu'

/**
 * This component is display detail pick and loop the DetailMenu
 * @param {Object} map is the current map based on category
 * @param {Boolean} showNoEntity is to show or not entity that is empty
 */
function DetailMenuList({
  map,
  showNoEntity
}) {
  const DEFAULT_SIZE_CONTENT = 10
  const DEFAULT_HEIGHT = 1.6 // in em

  const scrollRef = useRef(null)

  const [ groupEntities, setGroupEntities ] = useState(map.cesiumLayer.groupEntities)
  const [ groupBy, setGroupBy ] = useState(map.cesiumLayer.groupBy)
  const [ fieldMode, setFieldMode ] = useState(map.cesiumLayer.fieldMode)

  function modifyGroupEntities(groupEntities) {
    map.cesiumLayer.setGroupEntities(groupEntities)
  }

  function setTemplate(template) {
    map.cesiumLayer.setTemplate(template)
  }

  useEffect(() => {
    function eventGroupEntitiesHandler() {
      setGroupEntities(map.cesiumLayer.groupEntities)
    }

    function eventGroupByHandler() {
      setGroupBy(map.cesiumLayer.groupBy)
    }

    function eventFieldModeHandler() {
      setFieldMode(map.cesiumLayer.fieldMode)
    }

    map.cesiumLayer.subscribeEvent('groupEntities', eventGroupEntitiesHandler)
    map.cesiumLayer.subscribeEvent('groupBy', eventGroupByHandler)
    map.cesiumLayer.subscribeEvent('fieldMode', eventFieldModeHandler)

    return () => {
      map.cesiumLayer.unsubscribeEvent('groupEntities', eventGroupEntitiesHandler)
      map.cesiumLayer.unsubscribeEvent('groupBy', eventGroupByHandler)
      map.cesiumLayer.unsubscribeEvent('fieldMode', eventFieldModeHandler)
    }
  }, [])

  useEffect(() => {
    setSizeContent(10)

    let groupLength = 0

    if (groupEntities) {
      groupLength = groupEntities.length
    }

    let newMinHeight = DEFAULT_HEIGHT * groupLength
    
    if (newMinHeight > DEFAULT_SIZE_CONTENT) {
      newMinHeight = DEFAULT_SIZE_CONTENT
    }

    setMinHeight(newMinHeight)
  }, [groupEntities])

  const [ sizeContent, setSizeContent ] = useState(DEFAULT_SIZE_CONTENT)
  const [ minHeight, setMinHeight ] = useState(DEFAULT_HEIGHT)

  function scrollHandler() {
    if (scrollRef.current) {
      if (scrollRef.current.scrollTop + 5 >= (
        scrollRef.current.scrollHeight - scrollRef.current.offsetHeight
      )) {
        setSizeContent((currentContent) => {
          return currentContent + DEFAULT_SIZE_CONTENT
        })
      }
    }
  }

  let count = 0 // for stripe color
  
  if (
    !groupEntities
    || !Array.isArray(groupEntities.keys)
  ) {
    return null
  }

  return (
    <FlexWrapper
      col
      minHeight={`${minHeight}em`}
      maxHeight="10em"
      ref={scrollRef}
      onScroll={scrollHandler}
    >
      {
        groupEntities.keys
          .map((key, index) => {
            if (count > sizeContent) {
              return null
            }

            if (
              groupEntities.group[key.label].length === 0
              && !showNoEntity
            ) {
              return null
            }

            count += 1

            return (
              <DetailMenu
                key={index}
                name={key.label}
                entities={groupEntities.group[key.label]}
                groupEntities={groupEntities}
                setGroupEntities={modifyGroupEntities}
                map={map}
                marginLeft="1.3em"
                background={
                  ColorTemplate.STRIPE_LIGHT_GREY[
                    count % 2
                  ]
                }
                groupBy={groupBy}
                fieldMode={fieldMode}
                setTemplate={setTemplate}
              />
            )
          })
      }
    </FlexWrapper>
  )
}

export default DetailMenuList
