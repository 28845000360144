import HelpLogin from './HelpContent/HelpLogin'
// import HelpRegister from './HelpContent/HelpRegister'
// import HelpActivation from './HelpContent/HelpActivation'
import HelpProspect from './HelpContent/Menu/HelpProspect'
import HelpProspectMenu from './HelpContent/Menu/HelpProspectMenu'
import HelpLayer from './HelpContent/Menu/HelpLayer'
// import HelpReset from './HelpContent/HelpResetPassword'
import HelpDashboard from './HelpContent/HelpDashboard'
import HelpMap from './HelpContent/HelpMap'
import HelpAssistiveButton from './HelpContent/HelpAssistiveButton'
import HelpMenu from './HelpContent/HelpMenu'
import HelpHelp from './HelpContent/HelpHelp'
import HelpChat from './HelpContent/HelpChat'
// import HelpNotification from './HelpContent/HelpNotification'
import HelpLogout from './HelpContent/HelpLogout'
import HelpCrossSection from './HelpContent/AssistiveButton/HelpCrossSection'
import HelpBlockSection from './HelpContent/AssistiveButton/HelpBlockSection'
import HelpCursor from './HelpContent/AssistiveButton/HelpCursor'
import HelpSliceSection from './HelpContent/AssistiveButton/HelpSliceSection'
// import HelpGridOptions from './HelpContent/HelpGridOptions'
// import HelpTable from './HelpContent/HelpTable'
import HelpChatNew from './HelpContent/Chat/HelpChatNew'
import HelpChatAdmin from './HelpContent/Chat/HelpChatAdmin'
import HelpContact from './HelpContent/HelpContact'
import HelpCommodity from './HelpContent/HelpCommodity'
import HelpHome from './HelpContent/AssistiveButton/HelpHome'
import HelpSurface from './HelpContent/Menu/HelpSurface'
import HelpDrillhole from './HelpContent/Menu/HelpDrillhole'
import HelpSurfaceMap from './HelpContent/SurfaceDatabase/HelpSurfaceMap'
import HelpSurfaceStatistics from './HelpContent/SurfaceDatabase/HelpSurfaceStatistics'
import HelpSurfaceBoxPlot from './HelpContent/SurfaceDatabase/HelpSurfaceBoxPlot'
import HelpSurfaceCorrelation from './HelpContent/SurfaceDatabase/HelpSurfaceCorrelation'
import HelpSurfaceHistogram from './HelpContent/SurfaceDatabase/HelpSurfaceHistogram'
import HelpSurfaceScatter from './HelpContent/SurfaceDatabase/HelpSurfaceScatter'

export const LIST_HELP = {
  access: {
    title: 'Access Geoprosp',
    detail: {
      login: {
        title: 'Login',
        header: 'How to login on Geoprosp?',
        tag:'login, log in',
        Component: (key, focus, setFocus, opened, setOpened) => <HelpLogin key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="login" />
      },
      // register: {
      //   title: 'Register',
      //   header: 'How to register on Geoprosp?',
      //   tag:'register, sign up, signup',
      //   Component: (key, focus, setFocus, opened, setOpened) => <HelpRegister key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="register" />
      // },
      // activation: {
      //   title: 'Activation',
      //   header: 'How to activate a registered account? (This feature is still under development)',
      //   tag:'active, activation, activate',
      //   Component: (key, focus, setFocus, opened, setOpened) => <HelpActivation key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="activation"/>
      // },
      // reset: {
      //   title: 'Reset Password',
      //   header:'What if you forget the password? (This feature is still under development)',
      //   tag:'reset, forgot, forget, password',
      //   Component: (key, focus, setFocus, opened, setOpened) => <HelpReset key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="reset" />
      // }
    }
  },
  dashboard: {
    title: 'Dashboard Geoprosp',
    detail: {
      dashboardView: {
        title: 'Dashboard',
        header:"What's on the Geoprosp dashboard?",
        tag:'dashboard, view, front, geoprosp, main, map, menu, assistive, help, chat, notification, logout',
        Component: (key, focus, setFocus, opened, setOpened) => <HelpDashboard key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="dashboardView" />
      },
      map: {
        title: 'Map',
        header:"What's on map?",
        tag:'map, globe, zoom, pan, rotate, view, compass, directional light, map information, solar time, full screen ',
        Component: (key, focus, setFocus, opened, setOpened) => <HelpMap key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="map" />
      },
      assistiveButton: {
        title: 'Assistive Button',
        header:"What's on assistive button?",
        tag:'assistive, button, circle, display settings, move',
        Component: (key, focus, setFocus, opened, setOpened) => <HelpAssistiveButton key={key} focus={focus} setFocus={setFocus}  opened={opened} setOpened={setOpened} currentHelp="assistiveButton"/>
      },
      menuBar: {
        title: 'Menu Bar',
        header: "What's on menu bar?",
        tag:'menu, commodity, search, news, layer, database, analytics',
        Component: (key, focus, setFocus, opened, setOpened) => <HelpMenu key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="menuBar" />
      },
      commodityOption: {
        title: 'Commodity Option',
        header: "What's on commodity option?",
        tag:'menu, commodity, gold, coppper, silver, lead, zinc, manganesse, iron, bauxite, nickel, coal',
        Component: (key, focus, setFocus, opened, setOpened) => <HelpCommodity key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="commodityOption" />
      },
      helpButton: {
        title: 'Help Button',
        header: "What's on help page?",
        tag:'help, search',
        Component: (key, focus, setFocus, opened, setOpened) => <HelpHelp key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="helpButton" />
      },
      chatButton: {
        title: 'Chat Button',
        header: "What's on chat feature?",
        tag:'chat, message, new message, chatting, search',
        Component: (key, focus, setFocus, opened, setOpened) => <HelpChat key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="chatButton" />
      },
      // notificationButton: {
      //   title: 'Notification Button',
      //   header: "What's the use of notification button?",
      //   tag:'notification, alert',
      //   Component: (key, focus, setFocus, opened, setOpened) => <HelpNotification key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="notificationButton" />
      // },
      logoutButton: {
        title: 'Log Out Button',
        header: "What's the use of log out button?",
        tag:'log out, logout, signout, return',
        Component: (key, focus, setFocus, opened, setOpened) => <HelpLogout key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="logoutButton" />
      }
    }
  },

  assistive: {
    title: 'Features on Assistive Button',
    detail:{
      home:{
        title: 'Home',
        header:"What's the use of home button?",
        tag:'home',
        Component: (key, focus, setFocus, opened, setOpened) => <HelpHome key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="home"/>
      },
      cross: {
        title: 'Cross Section',
        header:"How to use cross section tool?",
        tag:'cross section, cut, draw, cursor',
        Component: (key, focus, setFocus, opened, setOpened) => <HelpCrossSection key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="cross"/>
      },
      block: {
        title: 'Block Section',
        header:"How to use block section tool?",
        tag:'block section, cut',
        Component: (key, focus, setFocus, opened, setOpened) => <HelpBlockSection key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="block" />
      },
      cursor: {
        title: 'Cursor',
        header:"How to use cursor tool?",
        tag:'cursor, back, return, original',
        Component: (key, focus, setFocus, opened, setOpened) => <HelpCursor key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="cursor"/>
      },
      slice: {
        title: 'Slice Section',
        header:"How to use slice section tool?",
        tag:'slice section, block section, cut, cutting',
        Component: (key, focus, setFocus, opened, setOpened) => <HelpSliceSection key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="slice" />
      },
      // grid: {
      //   title: 'Grid Options',
      //   header:"How to set grid options?",
      //   tag:'grid, option, ranking, weight, score, non technical parameter',
      //   Component: (key, focus, setFocus, opened, setOpened) => <HelpGridOptions key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="grid" />
      // },
      // table: {
      //   title: 'Table',
      //   header:"How to use table tool?",
      //   tag:'table, commodity',
      //   Component: (key, focus, setFocus, opened, setOpened) => <HelpTable key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="table" />
      // }
    }
  },
  menu:{
    title: 'Features on Menu',
    detail:{
      prospect: {
        title: 'Prospect Bar',
        header:"What's on prospect bar?",
        tag:'prospect, commodity ',
        Component: (key, focus, setFocus, opened, setOpened) => <HelpProspect key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="prospect"/>
      },
      prospectLayer: {
        title: 'Prospect Layer',
        header:"What's on prospect detail layer?",
        tag:'prospect detail, overview, about, fact sheet, geology, structure, ranking criteria, summary, non technical parameters, geological stratigraphy, similar project features, references, compare',
        Component: (key, focus, setFocus, opened, setOpened) => <HelpProspectMenu key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="prospectLayer" />
      },
      layer: {
        title: 'Layer Menu',
        header:"What's on layer manager if commodity is already selected?",
        tag:'active, layer manager, commodity, display, transparent, map, globe, more, show, hide, colour, color, remove, imaginary, terrain, reverse, gradient, grid, geology, geochemical, lineament, structure, data, earthquake, remote sensing mineral map, area, point ',
        Component: (key, focus, setFocus, opened, setOpened) => <HelpLayer key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="layer" />
      },
      surface: {
        title: 'Database Surface Data',
        header:"How to display our surface data in Geoprosp?",
        tag:'surface, data, database, import',
        Component: (key, focus, setFocus, opened, setOpened) => <HelpSurface key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="surface" />
      },
      drillhole: {
        title: 'Database Drilling Data',
        header:"How to display our drilling data in Geoprosp?",
        tag:'drilling, drillhole, data, database, import',
        Component: (key, focus, setFocus, opened, setOpened) => <HelpDrillhole key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="drillhole" />
      },
    }
  },
  chat: {
    title: 'Feature on Chat',
    detail:{
      newChat: {
        title: 'New Chat',
        header:"How to start new conversation in chat feature?",
        tag:'chat, new, chatting, conversation, speak, message',
        Component: (key, focus, setFocus, opened, setOpened) => <HelpChatNew key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="newChat" />
      },
      adminChat: {
        title: 'Chat to Admin',
        header:"How to chat with Geoprosp admin?",
        tag:'chat, new, chatting, conversation, speak, admin, message',
        Component: (key, focus, setFocus, opened, setOpened) => <HelpChatAdmin key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="adminChat" />
      }
    }
  },
  database:{
    title: 'Feature on Surface Database',
    detail:{
      maps:{
        title: 'Plots on Map',
        header: "How to select point data on map?",
        tag:'plot, map, display, point',
        Component: (key, focus, setFocus, opened, setOpened) => <HelpSurfaceMap key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="maps" />
      },
      statistical:{
        title: 'Statistical Description',
        header: "How to understand Statistical Description?",
        tag:'statistics, statistical, summary, description',
        Component: (key, focus, setFocus, opened, setOpened) => <HelpSurfaceStatistics key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="statistical" />
      },
      boxplot:{
        title: 'Box Plots',
        header: "How to display data in box plots?",
        tag:'boxplot, box, display, surface',
        Component: (key, focus, setFocus, opened, setOpened) => <HelpSurfaceBoxPlot key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="boxplot" />
      },
      correlation:{
        title: 'Correlation Matrix',
        header: "How to display data in correlation matrix?",
        tag:'correlation, matrix, data, surface, display',
        Component: (key, focus, setFocus, opened, setOpened) => <HelpSurfaceCorrelation key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="correlation" />
      },
      histogram:{
        title: 'Histograms',
        header: "How to display data in histogram?",
        tag:'histogram, data, surface, display',
        Component: (key, focus, setFocus, opened, setOpened) => <HelpSurfaceHistogram key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="histogram" />
      },
      // ternary:{
      //   title: 'Ternary Plots',
      //   header: "How to display data in ternary plots?",
      //   tag:'ternary, data, surface, display',
      //   Component: (key, focus, setFocus, opened, setOpened) => <HelpTernary key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="ternary" />
      // },
      // hexbin:{
      //   title: 'Hexbin Plots',
      //   header: "How to display data in hexbin plots?",
      //   tag:'hexbin, data, surface, display',
      //   Component: (key, focus, setFocus, opened, setOpened) => <HelpHexbin key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="hexbin" />
      // },
      scatter:{
        title: 'Scatter Plots',
        header: "How to display data in scatter plot?",
        tag:'scatter, plot, data, surface, display',
        Component: (key, focus, setFocus, opened, setOpened) => <HelpSurfaceScatter key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="scatter" />
      },
    }
  },
  others:{
    title: 'Other',
    detail:{
      other: {
        title: 'Contact Us',
        header: 'Send us a message',
        tag:'other, contact, admin, geoprosp',
        Component: (key, focus, setFocus, opened, setOpened) => <HelpContact key={key} focus={focus} setFocus={setFocus} opened={opened} setOpened={setOpened} currentHelp="other"/>
      }
    }
  }
 
}
