import styled from 'styled-components'

import { BLOCK_CONTENT_WIDTH } from '../style'

export const Image = styled.img.attrs((props) => {
  return {
    style: {
      width: props.width,
      height: props.height || props.width
    }
  }
})`
  border-radius: 100%;
  border: 1px solid black;
`

export const ContainerLoading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-family: Abel;
  color: white;
`

export const BlockContent = styled.div`
  background: #0000009d;
  display: flex;
  position: relative;
  height: calc(100vh - 44px - 3em);
  flex-direction: column;
  margin: 0.7em;
  padding: 0.5em 1em 1em 1em;
  border-radius: 5px;
  box-shadow: 1px 2px 11px 1px #646464;
  overflow-y: auto;
  width: ${BLOCK_CONTENT_WIDTH};
`

export const HeaderContainer = styled.div`
  display: flex;
  margin: 0.5em;
`
