import styled from "styled-components";

export const ModalWrapperPopUp = styled.div`
    position: fixed;
    left: 50px;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    display: ${props => props.toggle ? "flex" : "none" };
    /* display: flex; */
    align-items: center;
    justify-content: center;
    z-index:2;
    color: black;
`;

export const ModalHeader = styled.div`
    padding: 10px;
    font-size: 18px;
    text-align: center;
    font-family: abel;
    cursor: move;
`;

export const ModalBody = styled.div`
    padding: 10px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    font-size:14px;
    font-family: abel;
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    height: ${props => props.type==="Drillhole" ? "190px" : "280px" };
`;

export const ModalFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: ${props => props.type==="Drillhole" ? "right" : "space-between" };
    /* justify-content: space-between; */
    font-family: abel;
`;

export const Button = styled.button.attrs(({type}) => ({
    type: type || 'button'
}))`
    margin: 0.3em 0.6em;
    padding: 0em 1em;
    height: ${props => props.height ? props.height : '2em'};
    border-radius: 10px;
    display: ${props => props.display ? props.display : 'flex'};
    flex-direction: row;
    align-items: center;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    pointer-events: ${props => props.disabled ? 'none' : 'all'};
    border: 1px solid;
    font-family: Abel;
    border-color: ${props => props.color ? props.color : '#909090'};
    background-color: ${props => props.color ? props.color : '#909090'};
    svg {
        height: 80%;
        width: auto;
    }
    &:hover svg {
        fill: url(#dashboard-gold-gradient);
    }
    label {
        pointer-events: none;
    }
    &:hover {
        background-color: ${props => props.colorHover ? props.colorHover : '#242424'};
        border-color: ${props => props.colorHoverBorder ? props.colorHoverBorder : '#242424'};
    }
    &:hover label {
        color: goldenrod;
    }
`;