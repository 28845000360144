import * as Cesium from 'cesium'

import {
  computeCircle
} from '../../../../apis/cesiumMap/borehole'

import {
  getDataSource
} from './index'


/**
 * This function will handle resize ellipsoid on the selected map
 * @param {Object} map that need to change the size
 */
export function resizeEllipsoid({
  map
}) {
  const currentEntities = map.dataSource
    .entities
    .values

  let radii = null

  for (let i = 0; i < currentEntities.length; i++) {
    if (currentEntities[i].ellipsoid) {
      if (!radii) {
        radii = new Cesium.Cartesian3(
          map.pixelSize,
          map.pixelSize,
          map.pixelSize
        )
      }

      currentEntities[i].ellipsoid.radii = radii
    } else if (currentEntities[i].point) {
      currentEntities[i].point.pixelSize = map.pixelSize
    }
  }
}

/**
 * This function will handle resize polyline on the selected map
 * @param {Object} map that need to change the size
 */
export function resizePolyline({
  map
}) {
  const currentEntities = map.dataSource
    .entities
    .values

  let shape = null

  for (let i = 0; i < currentEntities.length; i++) {
    if (currentEntities[i].polylineVolume) {
      if (!shape) {
        shape = computeCircle(map.pixelSize)
      }

      currentEntities[i].polylineVolume.shape = shape
    } else if (currentEntities[i].point) {
      currentEntities[i].point.pixelSize = map.pixelSize
    }
  }
}
