export const GEOLOGICAL_MAP_COLOR_REFERENCES = [
  {
    "text": "Medium-grained mixed clastic rock",
    "color": {
      "red": 0.5647058823529412,
      "green": 0.6470588235294118,
      "blue": 0.396078431372549,
      "alpha": 0.5
    },
    "key": "Sediment: medium grained clastic",
    "type": "sediment"
  },
  {
    "text": "Batholith",
    "color": {
      "red": 1,
      "green": 0,
      "blue": 0,
      "alpha": 0.5
    },
    "key": "Batholith",
    "type": "intrusive"
  },
  {
    "text": "Coarse-grained mixed clastic rock",
    "color": {
      "red": 0.6470588235294118,
      "green": 0.6666666666666666,
      "blue": 0.6784313725490196,
      "alpha": 0.5
    },
    "key": "Sediment: coarse grained clastic",
    "type": "sediment"
  },
  {
    "text": "Evaporite",
    "color": {
      "red": 0.00392156862745098,
      "green": 0.611764705882353,
      "blue": 0.803921568627451,
      "alpha": 0.5
    },
    "key": "Sediment: chemical Evaporite and Chert",
    "type": "sediment"
  },
  {
    "text": "Gabbro",
    "color": {
      "red": 0.9137254901960784,
      "green": 0.5764705882352941,
      "blue": 0.7450980392156863,
      "alpha": 0.5
    },
    "key": "Intrusive: Gabbro - Basalt - Diabase - Dolereit - Vitrophyre",
    "type": "intrusive"
  },
  {
    "text": "Pyroclastic rock",
    "color": {
      "red": 1,
      "green": 0.8784313725490196,
      "blue": 0.8705882352941177,
      "alpha": 0.5
    },
    "key": "Volcanic: Pyroclastic",
    "type": "volcanic"
  },
  {
    "text": "Ultramafic intrusive rock",
    "color": {
      "red": 0.9098039215686274,
      "green": 0,
      "blue": 0.21568627450980393,
      "alpha": 0.5
    },
    "key": "Ultramafic Rocks",
    "type": "intrusive"
  },
  {
    "text": "Granite",
    "color": {
      "red": 0.9764705882352941,
      "green": 0.7098039215686275,
      "blue": 0.7333333333333333,
      "alpha": 0.5
    },
    "key": "Intrusive: Granite - Granodiorite - Adamellite - Quartz Monzonite",
    "type": "intrusive"
  },
  {
    "text": "Fine-grained mixed clastic rock",
    "color": {
      "red": 0.5843137254901961,
      "green": 1,
      "blue": 0.792156862745098,
      "alpha": 0.5
    },
    "key": "Sediment: fine grained clastic",
    "type": "sediment"
  },
  {
    "text": "Lava flow",
    "color": {
      "red": 1,
      "green": 0.6352941176470588,
      "blue": 0.15294117647058825,
      "alpha": 0.5
    },
    "key": "Volcanic: Lava",
    "type": "volcanic"
  },
  {
    "text": "Unknown",
    "color": {
      "red": 1,
      "green": 1,
      "blue": 1,
      "alpha": 0.5
    },
    "key": "Islands Unknown",
    "type": "others"
  },
  {
    "text": "Melange",
    "color": {
      "red": 0.7333333333333333,
      "green": 0.7529411764705882,
      "blue": 0.7725490196078432,
      "alpha": 0.5
    },
    "key": "Tectonite: Melange - Ophiolite",
    "type": "others"
  },
  {
    "text": "Metamorphic rock",
    "color": {
      "red": 0.6549019607843137,
      "green": 0.6549019607843137,
      "blue": 1,
      "alpha": 0.5
    },
    "key": "Metamorphic Rocks",
    "type": "metamorphic"
  },
  {
    "text": "Alluvium",
    "color": {
      "red": 1,
      "green": 1,
      "blue": 0.5372549019607843,
      "alpha": 0.5
    },
    "key": "Alluvium - Colluvium - Swamp - Terrace - Beach - Lake Deposits",
    "type": "sediment"
  },
  {
    "text": "Limestone",
    "color": {
      "red": 0.2627450980392157,
      "green": 0.6862745098039216,
      "blue": 0.9764705882352941,
      "alpha": 0.5
    },
    "key": "Limestone",
    "type": "sediment"
  },
  {
    "text": "Andesite",
    "color": {
      "red": 0.6941176470588235,
      "green": 0.2823529411764706,
      "blue": 0.00392156862745098,
      "alpha": 0.5
    },
    "key": "Intrusive: Andesite - Dacite",
    "type": "intrusive"
  },
  {
    "text": "Alluvium",
    "color": {
      "red": 1,
      "green": 1,
      "blue": 0.5372549019607843,
      "alpha": 0.5
    },
    "key": "Lake Deposits",
    "type": "sediment"
  },
  {
    "text": "water",
    "color": {
      "red": 0.6,
      "green": 0.8,
      "blue": 1,
      "alpha": 0.5
    },
    "key": "Water Body",
    "type": "others"
  },
  {
    "text": "Tonalite",
    "color": {
      "red": 0.9882352941176471,
      "green": 0.7137254901960784,
      "blue": 0.7137254901960784,
      "alpha": 0.5
    },
    "key": "Intrusive: Tonalite - Diorite - Quartz Diorite - Rhyolite",
    "type": "intrusive"
  }
]
