import React from 'react'

import DefaultSearch from '../../../SearchBoxs/DefaultSearch'

import { ToggleSVGButton } from '../../../shares/ToggleSVGIcon'

import { ReactComponent as BackIcon } from '../../../../images/Icons/left.svg'

import {
  HeaderContainer
} from '../shares/StyledComponent'

function Header({ setSearch, backToChat }) {
  function searchHandler(search) {
    setSearch(search)
  }

  return <HeaderContainer>
    <ToggleSVGButton
      flex
      center
      margin="0 0.5em 0 0"
      title="Back to Chat"
      onClick={backToChat}
    >
      <BackIcon />
    </ToggleSVGButton>
    <DefaultSearch
      active={true}
      searchProcess={searchHandler}
      stopSearch={searchHandler}
    />
  </HeaderContainer>
}

export default Header
