export function getChatToggle(state) {
  return state.chat.toggle
}

export function getChatWidth(state) {
  return state.chat.width
}

export function getChatMinimize(state) {
  return state.chat.minimize
}
