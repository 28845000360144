import React, { useState, useEffect, useRef, useMemo } from 'react'

import { connect } from 'react-redux'

import getPixel from '../../helpers/getPixel'

import Content from './Content'
import TilesetContent from './TilesetContent'

import styled from 'styled-components'

const FixedContainer = styled.div`
  position: fixed;
  height: max-content;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.6);
  padding: 0.3em;
  transition: ${props => props.activeMove ? null : '500ms'};
  transition-property: background, left, top;
  border-radius: 10px;
  pointer-events: auto;

  &:hover {
    background: rgba(255, 255, 255, 1);
    cursor: move;
  }
`

const ResizeBlock = styled.div`
  position: fixed;
  height: 0.5em;
  width: 0.5em;
  z-index: 1001;
  pointer-events: auto;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 100%;
  border-bottom-left-radius: 0;
  border-bottom: 4px solid red;
  border-right: 4px solid red;
  transition: opacity 330ms;

  &:hover {
    cursor: se-resize;
  }
`

/**
 * This component will generate list content
 * @param {Object} objLegend object of legend to render
 * @param {Number} defaultPosX is the default position x
 * @param {Number} defaultPosY is the default position y
 * @param {String} direction is the flex direction
 * @param {Object} rankbar is redux state of rankbar
 * @param {Object} sidebarState is redux state of sidebar
 * @param {Object} sitebar is redux state of sitebar
 */
function ListContent({
  objLegend,
  defaultPosX,
  defaultPosY,
  direction,
  rankbar,
  sidebarState,
  sitebar
}) {
  const RANKBAR_HEIGHT = 44
  const SIDEBAR_WIDTH = 50
  const SIDEBAR_WITH_CONTENT_WIDTH = 370
  const SITEBAR_WIDTH = getPixel(sitebar.width)
  const MARGIN_RESIZE = 10

  const containerRef = useRef(null)

  const maxTolerance = 100 // max tolerance to clamping
  const [ offset, setOffset ] = useState({
    pageX: 0,
    pageY: 0,
    status: false
  })

  const [ position, setPosition ] = useState({
    pageX: defaultPosX,
    pageY: defaultPosY,
    marginX: defaultPosX - SIDEBAR_WIDTH,
    marginY: defaultPosY - RANKBAR_HEIGHT
  })

  const [ size, setSize ] = useState({
    width: null,
    height: null
  })

  const [ activeMove, setActiveMove ] = useState(false)
  const [ activeResize, setActiveResize ] = useState(false)

  const [ isHover, setHover ] = useState(false)

  const styles = useMemo(() => {
    return {
      top: position.pageY,
      left: position.pageX,
      overflowY: 'auto',
      display: 'flex',
      flexDirection: direction,
      width: !size.width ? null : size.width - MARGIN_RESIZE,
      height: !size.height ? null : size.height - MARGIN_RESIZE
    }
  }, [position, direction, size])

  useEffect(() => {
    let open = false

    for (let key in sidebarState) {
      if (sidebarState[key].status) {
        open = true
        break
      }
    }

    const NAVBAR_HEIGHT = RANKBAR_HEIGHT

    let newMarginX = defaultPosX
    let newMarginY = defaultPosY - NAVBAR_HEIGHT

    if (open) {
      newMarginX -= SIDEBAR_WITH_CONTENT_WIDTH
    } else {
      newMarginX -= SIDEBAR_WIDTH
    }

    if (rankbar.toggle && !rankbar.minimize) {
      newMarginY -= RANKBAR_HEIGHT
    }

    setPosition(() => ({
      pageX: defaultPosX,
      pageY: defaultPosY,
      marginX: newMarginX,
      marginY: newMarginY
    }))
  }, [])

  useEffect(() => {
    const currentHeight = getPixel(position.pageY)

    if (rankbar.toggle && !rankbar.minimize) {
      if (position.marginY < RANKBAR_HEIGHT) {
        setPosition((position) => ({
          ...position,
          pageY: currentHeight + RANKBAR_HEIGHT
        }))
      }
    } else {
      if (position.marginY - RANKBAR_HEIGHT - maxTolerance <= 0) {
        setPosition((position) => ({
          ...position,
          pageY: position.marginY + RANKBAR_HEIGHT
        }))
      }
    }
  }, [rankbar])

  useEffect(() => {
    let open = false

    for (let key in sidebarState) {
      if (sidebarState[key].status) {
        open = true
        break
      }
    }

    const currentWidth = getPixel(position.pageX)

    if (open) {
      if (currentWidth < SIDEBAR_WITH_CONTENT_WIDTH) {
        setPosition((position) => ({
          ...position,
          pageX: SIDEBAR_WITH_CONTENT_WIDTH + position.marginX
        }))
      }
    } else {
      if (currentWidth - SIDEBAR_WITH_CONTENT_WIDTH < maxTolerance) {
        setPosition((position) => ({
          ...position,
          pageX: SIDEBAR_WIDTH + position.marginX
        }))
      }
    }
  }, [sidebarState])

  useEffect(() => {
    if (containerRef.current) {
      const windowWidth = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth
  
      const currentWidth = getPixel(position.pageX)

      const { width } = containerRef.current.getBoundingClientRect()

      if (!sitebar.toggle || sitebar.minimize) {
        if (windowWidth - currentWidth - width - SITEBAR_WIDTH - maxTolerance < 0) {
          let newPageX = currentWidth + SITEBAR_WIDTH

          if (newPageX > windowWidth - width) {
            newPageX = windowWidth - width
          }
          
          setPosition((position) => ({
            ...position,
            pageX: newPageX,
            marginX: position.marginX + newPageX - getPixel(position.pageX)
          }))
        }
      } else {
        if (windowWidth - SITEBAR_WIDTH < position.marginX + width) {
          let newPageX = position.pageX - SITEBAR_WIDTH

          setPosition((position) => ({
            ...position,
            pageX: newPageX,
            marginX: position.marginX + newPageX - getPixel(position.pageX)
          }))
        }
      }
    }

  }, [sitebar])

  useEffect(() => {
    if (offset.status) {
      setActiveMove(true)
    } else {
      setActiveMove(false)
    }
  }, [offset])
  
  useEffect(() => {
    function moveHandler(event) {
      setPosition((position) => ({
        ...position,
        pageX: event.pageX - offset.pageX,
        pageY: event.pageY - offset.pageY,
        marginX: position.marginX + (event.pageX - offset.pageX) - getPixel(position.pageX),
        marginY: position.marginY + (event.pageY - offset.pageY) - getPixel(position.pageY)
      }))
    }

    function cancelMove() {
      setOffset((newOffset) => ({
        ...newOffset,
        status: false
      }))
    }

    if (activeMove) {
      document.addEventListener('mousemove', moveHandler)
      document.addEventListener('mouseup', cancelMove)
    } else {
      document.removeEventListener('mousemove', moveHandler)
      document.removeEventListener('mouseup', cancelMove)
    }

    return () => {
      document.removeEventListener('mousemove', moveHandler)
      document.removeEventListener('mouseup', cancelMove)
    }
  }, [activeMove])

  useEffect(() => {
    function resizeHandler(event) {
      setSize((prevSize) => {
        if (prevSize.width === null) {
          const { width, height } = containerRef.current.getBoundingClientRect()

          return {
            width: width + event.movementX,
            height: height + event.movementY
          }
        } else {
          return {
            width: prevSize.width + event.movementX,
            height: prevSize.height + event.movementY
          }
        }
      })
    }

    function cancelResize() {
      setActiveResize(false)
    }

    if (activeResize) {
      document.addEventListener('mousemove', resizeHandler)
      document.addEventListener('mouseup', cancelResize)
    } else {
      document.removeEventListener('mousemove', resizeHandler)
      document.removeEventListener('mouseup', cancelResize)
    }

    return () => {
      document.removeEventListener('mousemove', resizeHandler)
      document.removeEventListener('mouseup', cancelResize)
    }
  }, [activeResize])

  useEffect(() => {
    if (direction === 'row') {
      setSize((size) => ({
        ...size,
        width: null
      }))
    } else {
      setSize((size) => ({
        ...size,
        height: null
      }))
    }

    if (containerRef.current) {
      const windowWidth = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth
  
      const { width } = containerRef.current.getBoundingClientRect()

      if (!sitebar.toggle || sitebar.minimize) {
        if (position.pageX + width > windowWidth) {
          const newPageX = windowWidth - width

          setPosition((position) => ({
            ...position,
            pageX: newPageX,
            marginX: position.marginX + newPageX - getPixel(position.pageX)
          }))
        }
      } else {
        if (position.pageX + width > windowWidth - SITEBAR_WIDTH) {
          const newPageX = windowWidth - SITEBAR_WIDTH - width
          
          setPosition((position) => ({
            ...position,
            pageX: newPageX,
            marginX: position.marginX + newPageX - getPixel(position.pageX)
          }))
        }
      }
    }
  }, [objLegend, direction])

  const result = []

  let index = 0

  for (let name in objLegend) {
    const { map } = objLegend[name]

    index += 1

    if (map?.dataSource?.show) {
      result.push(
        <Content
          key={map.name + String(index)}
          map={map}
          header={name}
          isHover={isHover}
          keys={objLegend[name]?.keys}
        />
      )
    } else if (map?.tilesetData?.show) {
      result.push(
        <TilesetContent
          key={map.name}
          map={map}
          header={name}
          isHover={isHover}
        />
      )
    }
  }

  return <>
    <FixedContainer
      style={styles}
      activeMove={activeMove}
      onMouseDown={(event) => {
        event.preventDefault()
        setOffset({
          pageX: event.pageX - getPixel(position.pageX),
          pageY: event.pageY - getPixel(position.pageY),
          status: true
        })
        setActiveMove(true)
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      ref={containerRef}
    >
      {result}
    </FixedContainer>
    {
      containerRef.current
        && <ResizeBlock
          style={{
            top: !size.height
              ? position.pageY + containerRef.current.getBoundingClientRect().height - MARGIN_RESIZE
              : position.pageY + size.height - MARGIN_RESIZE,
            left: !size.width
              ? position.pageX + containerRef.current.getBoundingClientRect().width - MARGIN_RESIZE
              : position.pageX + size.width - MARGIN_RESIZE,
            opacity: isHover ? 1 : 0
          }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onMouseDown={(event) => {
            event.preventDefault()
            setActiveResize(true)
          }}
        />
    }
  </>
}

function mapStateToProps(state) {
  return {
    rankbar: state.rankbar,
    sitebar: state.sitebar,
    sidebarState: state.sidebarState
  }
}

export default connect(mapStateToProps, null)(ListContent)
