import React, { useEffect, useState } from 'react'

import Dropdown from '../../../../../../../Dropdowns/Default'

/**
 * Option menu to attribute
 * @param {Object} map is the current map based on attribute 
 * @returns 
 */
function OptionMenuAttribute({ map }) {
  const [ fieldAttribute, setFieldAttribute ] = useState(map.cesiumLayer.fieldAttribute)

  useEffect(() => {
    function eventHandler() {
      setFieldAttribute(map.cesiumLayer.fieldAttribute)
    }

    map.cesiumLayer.subscribeEvent('fieldAttribute', eventHandler)

    return (() => {
      map.cesiumLayer.unsubscribeEvent('fieldAttribute', eventHandler)
    })
  }, [])

  return (
    <Dropdown
      label="Attribute"
      options={fieldAttribute.pickList || []}
      setToggle={(pick) => {
        map.cesiumLayer.setTemplate(null)

        map.cesiumLayer.setFieldAttribute({
          ...fieldAttribute,
          selected: pick
        })
      }}
      width="10em"
      iconType="attribute"
      defaultPick={fieldAttribute.selected}
    />
  )
}

export default OptionMenuAttribute
