import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import _ from 'lodash';

import { fetchReferences } from '../../../apis/server';

import { 
    TableContainer, 
    WrapperPagination, 
    WrapperTable 
} from './partials';

import Pagination from '../../Pagination';

import HeaderMenu from './HeaderMenu';
import HeaderTable from './HeaderTable';
import BodyTable from './BodyTable';

import './SiteReferencesMenu.css';

function SiteReferencesMenu({
    sites
}) {
    const [ keySort, setKeySort ] = useState('paper_id')
    const [ keySearch, setKeySearch ] = useState('')

    const [ pageSize, setPageSize ] = useState(30)
    const [ pageIndex, setPageIndex ] = useState(0)
    const [ pageOptions, setPageOptions ] = useState([])
    
    const [ bookmarkRef, setBookmarkRef ] = useState([])
    const [ selectedDownload, setSelectedDownload ] = useState([])
    const [ checkedAllDownload, setCheckedAllDownload ] = useState(false)
    const [ toggleIconDownload, setToggleIconDownload ] = useState(false)
    const [ toggleShowFavourite, setToggleShowFavourite ] = useState(false)

    const [ dataPaper, setDataPaper ] = useState([])

    const sortOptions = [
        {
            pickName:'title',
            pickShow:'Title'
        },{
            pickName:'authors',
            pickShow:'Authors'
        },{
            pickName:'year',
            pickShow:'Year'
        },{
            pickName:'publisher',
            pickShow:'Publisher'
        }
    ]
    
    useEffect(() => {
        async function loadData(commodity) {
            const dataReferences = await fetchReferences({asset_type: commodity, keywords: keySearch})
            
            return dataReferences
        }

        let commodities = sites.commodity.toLowerCase().split('_')
        
        const fetchPromises = commodities.map((commodity)=>{
            return loadData(commodity)
        })

        Promise
            .all(fetchPromises)
            .then((listResponsesData) => {
                const combinedResponseData = []

                listResponsesData.forEach(responseData => {
                    responseData.forEach(reference => {
                        combinedResponseData.push(reference)
                    })
                })

                setDataPaper(combinedResponseData)
            })
            .catch(() => {})
    }, [sites, keySearch])
    
    useEffect(() => {
        if (dataPaper.length === 0) return
        
        let arr = []
        let arrBookmark = []
        let arrNoBookmark = []

        dataPaper.forEach((paper)=>{
            if(toggleShowFavourite){
                if(bookmarkRef.includes(paper['paper_id'])){
                    arrBookmark.push(paper)
                } else {
                    arrNoBookmark.push(paper)
                }
            } else {
                arrNoBookmark.push(paper)
            }
        })

        for (let i = 0; i < dataPaper.length; i += pageSize) {
            let arrCamp = []
            
            if (keySort==='paper_id') {
                arrCamp = [...arrBookmark.sort(),...arrNoBookmark.sort()]
            } else {
                arrCamp = [
                    ...arrBookmark.sort((a, b) => a[keySort].localeCompare(b[keySort])),
                    ...arrNoBookmark.sort((a, b) => a[keySort].localeCompare(b[keySort]))
                ]    
            }

            arr.push(arrCamp.slice(i,i+pageSize))
        }

        setPageOptions(arr)
        
        if (pageIndex < pageOptions.length-1) {
            setPageIndex(0)
            document.getElementById('inputPage').value = 1
        }
    },[pageSize, toggleShowFavourite, bookmarkRef, keySearch, keySort, dataPaper])

    useEffect(() =>{
        if (pageOptions.length === 0) return

        let arrayEquals = (a, b) => {
            return Array.isArray(a) &&
                Array.isArray(b) &&
                a.length === b.length &&
                a.every((val, index) => val === b[index]);
        }

        let idPage=[]
        let selectedInPage=[]

        pageOptions[pageIndex].forEach((pageOption)=>{
            idPage.push(pageOption['paper_id'])
            if(!selectedDownload.includes(pageOption['paper_id'])){
                setCheckedAllDownload(false)
            }
            if(selectedDownload.includes(pageOption['paper_id'])){
                selectedInPage.push(pageOption['paper_id'])
            }
        })

        if (arrayEquals(idPage.sort(), selectedInPage.sort())) {
            setCheckedAllDownload(true)
        } else {
            setCheckedAllDownload(false)
        }

        if (selectedDownload.length === 0) {
            setToggleIconDownload(false)
        } else {
            setToggleIconDownload(true)
        }
    },[selectedDownload, pageIndex])

    return <>
        <HeaderMenu
            setKeySearch={setKeySearch}
            sortOptions={sortOptions}
            setKeySort={setKeySort}
            toggleIconDownload={toggleIconDownload}
        />
        <div className='site-references-container'>
            <div className='site-references-content'>
                <WrapperTable>
                    <TableContainer>
                        <table className='table-site-references-menu'>
                            <HeaderTable
                                sortOptions={sortOptions}
                                checkedAllDownload={checkedAllDownload}
                                setCheckedAllDownload={setCheckedAllDownload}
                                pageOptions={pageOptions}
                                pageIndex={pageIndex}
                                selectedDownload={selectedDownload}
                                setSelectedDownload={setSelectedDownload}
                                toggleShowFavourite={toggleShowFavourite}
                                setToggleShowFavourite={setToggleShowFavourite}
                            />
                            {pageOptions.length !==0 &&
                                <BodyTable
                                    pageOptions={pageOptions}
                                    pageIndex={pageIndex}
                                    bookmarkRef={bookmarkRef}
                                    setBookmarkRef={setBookmarkRef}
                                    selectedDownload={selectedDownload}
                                    setSelectedDownload={setSelectedDownload}
                                    sortOptions={sortOptions}
                                    keySearch={keySearch}
                                />
                            }
                        </table>
                    </TableContainer>
                </WrapperTable>
                <WrapperPagination>
                    <Pagination
                        pageIndex={pageIndex}
                        setPageIndex={setPageIndex}
                        pageOptions={pageOptions}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        fontColor='black'
                        fontFamily='abel'
                        fontSize='0.8em'
                    />
                </WrapperPagination>
            </div>
        </div>
    </>
}

function mapStateToProps ({ sites }) {
    return {
        sites
    }
}

// const mapDispatchToProps = {
//     visitSite
// }

export default connect(mapStateToProps, null)(SiteReferencesMenu)