import { fixedPoints } from './dragHandler'
import { Math as CesiumMath } from 'cesium'
import {
  bearing as turfBearing,
  point as turfPoint
} from '@turf/turf'

class SectionMath {
  /**
   * Math syntax to convert from meters based on distance and bearing to longlat
   * @param {Number} longitude - coordinate in longitude
   * @param {Number} latitude - coordinate in latitude
   * @param {Number} distance - distance from current coordinate
   * @param {Number} bearing - direction in degrees from current coordinate 
   * @returns {Object} - object contains longitude and latitude
   */
  static convertMetersToLongLat({
    longitude,
    latitude,
    distance,
    bearing
  }) {
    const R = 6371 // km
    const λ1 = CesiumMath.toRadians(longitude)
    const φ1 = CesiumMath.toRadians(latitude)
    const d = distance
    const brng = CesiumMath.toRadians(bearing)

    const φ2 = Math.asin(
      Math.sin(φ1) * Math.cos(d/R)
      + Math.cos(φ1) * Math.sin(d/R) * Math.cos(brng)
    )

    const λ2 = λ1 + Math.atan2(
      Math.sin(brng) * Math.sin(d/R) * Math.cos(φ1),
      Math.cos(d/R) - Math.sin(φ1) * Math.sin(φ2)
    )

    return {
      longitude: CesiumMath.toDegrees(λ2),
      latitude: CesiumMath.toDegrees(φ2)
    }
  }

  /**
   * Math method to get bearing from dragMovement
   * @param {Object} dragMovement - object that have start and end of coordinate position
   * @returns {Number} bearing in degrees
   */
  static getPlaneBearing(dragMovement) {
    const initialPoint = turfPoint([
      dragMovement.start.longitude,
      dragMovement.start.latitude
    ])
  
    const endPoint = turfPoint([
      dragMovement.end.longitude,
      dragMovement.end.latitude
    ])
  
    return turfBearing(initialPoint, endPoint)
  }

  /**
   * This method to find regresion linear
   * @param {Array} values_x the array value of x (longitude)
   * @param {Array} values_y the array value of y (latitude) 
   * @returns {Object} with m = gradient, b = coeficient
   */
  static findRegresionLinear(values_x, values_y) {
    let sum_x = 0
    let sum_y = 0
    let sum_xy = 0
    let sum_xx = 0
    let count = 0

    /*
    * We'll use those variables for faster read/write access.
    */
    let x = 0
    let y = 0
    const values_length = values_x.length

    if (values_length !== values_y.length) {
      throw new Error('The parameters values_x and values_y need to have same size!');
    }

    /*
    * Nothing to do.
    */
    if (values_length === 0) {
      return [ [], [] ];
    }

    /*
    * Calculate the sum for each of the parts necessary.
    */
    for (let i = 0; i < values_length; i++) {
      x = values_x[i]
      y = values_y[i]
      sum_x += x
      sum_y += y
      sum_xx += x * x
      sum_xy += x * y
      count++
    }

    /*
    * Calculate m and b for the formula:
    * y = x * m + b
    */
    const m = (count * sum_xy - sum_x * sum_y)
      / (count * sum_xx - sum_x * sum_x)
    const b = (sum_y / count) - (m * sum_x) / count

    return {
      m,
      b
    }
  }
}

export default SectionMath
