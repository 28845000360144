import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { getChatSocket } from '../../../../../store/selectors/socket'
import SocketClient from '../../../../../apis/socket'

import styled from 'styled-components'

import {
  SUCCESS_GREEN,
  DANGER_RED
} from '../../../../shares/ColorTemplate'

const Container = styled.div.attrs((props) => {
  return {
    style: {
      marginLeft: props.styles.marginLeft,
      opacity: props.styles.opacity
    }
  }
})`
  color: white;
  background: #000000;
  padding: 0.3em;
  cursor: default;
  transition: all 330ms;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4em;
  height: 1em;
  background: white;
  border: 2px solid ${props => {
    if (props.success) {
      return SUCCESS_GREEN.default
    } else {
      return DANGER_RED.default
    }
  }};
  transition: border 330ms;
  border-radius: 3px;
  color: black;
  cursor: pointer;

  &:hover {
    border: 2px solid ${props => {
      if (props.success) {
        return SUCCESS_GREEN.hover
      } else {
        return DANGER_RED.hover
      }
    }};
  }
`

function DeleteConfirmation({
  chatRoom,
  person,
  setPerson,
  roomSocket
}) {
  const [ styles, setStyles ] = useState({
    marginLeft: '-1em',
    opacity: 0
  })

  function fadeOut() {
    setStyles({
      marginLeft: '-1em',
      opacity: 0
    })
  }

  useEffect(() => {
    setTimeout(() => {
      setStyles({
        marginLeft: 0,
        opacity: 1
      })
    }, 0)

    return fadeOut
  }, [])

  function yesHandler() {
    SocketClient.removeUser(roomSocket, {
      messageId: chatRoom.message_id,
      recipient: person,
      token: null,
      cb() {
        fadeOut()
        setTimeout(() => {
          setPerson(null)
        }, 330)
      }
    })
  }
  
  function noHandler() {
    fadeOut()
    setTimeout(() => {
      setPerson(null)
    }, 330)
  }

  return <Container styles={styles}>
    Are you sure want to remove {person.username} from this message?
    <ButtonContainer>
      <Button onClick={yesHandler}>Yes</Button>
      <Button success onClick={noHandler}>No</Button>
    </ButtonContainer>
  </Container>
}

const mapStateToProps = (state) => {
  return {
    roomSocket: getChatSocket(state)
  }
}

export default connect(mapStateToProps)(DeleteConfirmation)
