import React, { useState, useEffect, useRef } from 'react'

import { connect } from 'react-redux'

import {
  FETCH_PROSPECT_AREA_SITES,
  CHANGE_COMMODITY
} from '../../../../constants/store/actionTypes/sites'

import RankButton from '../../../Buttons/RankButton'

import SmartViewButton from './SmartViewButton'
import SmartSelectButton from './SmartSelectButton'
import SmartFreezeButton from './SmartFreezeButton'
import SelectOptionBinSize from './SelectOptionBinSize'
import SelectOptionType from './SelectOptionType'
import ScrollRankButton from './ScrollRankButton'
import FetchRankLoading from './FetchRankLoading'
import OptionMenubar from './OptionMenubar'

import {
  FlexRow
} from '../../../BasicStyle/FlexWrapper'

import styled from 'styled-components'

const ButtonContainer = styled.div`
  display: flex;
  background-color: rgb(24, 24, 24);
  z-index: 4;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-right: 0.5em;
  margin-right: -0.5em;
  align-items: center;
  height: 44px;
  position: relative;
  min-width: 10em;
  justify-content: space-around;
`

const PROSPECT_LIMIT = 15

function ScrollBar({
  prospectArea,
  rankbar,
  sidebarState,
  sitebar,
  loading
}) {
  const scrollRef = useRef(null)

  const [ arrowMove, setArrowMove ] = useState(null)
  const [ limit, setLimit ] = useState(PROSPECT_LIMIT)
  const [ optionFilter, setOptionFilter ] = useState({
    active: false,
    status: '',
    value: ''
  })

  function scroll(event) {
    if (scrollRef.current) {
      const container = scrollRef.current
      
      container.scrollTo({
        left: container.scrollLeft + event.deltaY,
        behaviour: 'smooth'
      })

      if (container.scrollLeft >= container.scrollWidth / 2 - 1) {
        setLimit((limit) => limit + PROSPECT_LIMIT)
      }

      checkScroll()
    }
  }

  function clickMove(move) {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        top: 0,
        left: 100 * move,
        behavior: 'smooth'
      })
      
      setTimeout(() => {
        checkScroll()
      }, 551)
    }
  }

  function checkScroll() {
    if (!scrollRef.current) {
      return null
    } else {
      const {
        clientWidth,
        scrollWidth,
        scrollLeft
      } = scrollRef.current
      
      let moveLeft = false
      let moveRight = false
      
      if (scrollWidth - clientWidth > scrollLeft) {
        moveRight = true
      }

      if (scrollLeft > 0) {
        moveLeft = true
      }
      if (moveLeft & moveRight) {
        setArrowMove('both')
      } else if (moveLeft) {
        setArrowMove('left')
      } else if (moveRight) {
        setArrowMove('right')
      } else {
        setArrowMove('none')
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      checkScroll()
    }, 551)
  }, [prospectArea, sidebarState, sitebar, rankbar])

  useEffect(() => {
    setOptionFilter({
      active: false,
      status: '',
      value: ''
    })
  }, [prospectArea])

  useEffect(() => {
    setLimit(PROSPECT_LIMIT)

    return () => {
      setLimit(PROSPECT_LIMIT)
    }
  }, [])

  return <>
    {
      rankbar.toggle
        &&
          <>
            <FlexRow
              height="44px"
              background="white"
              style={{
                position: 'relative',
                overflow: 'hidden',
                zIndex: 3
              }}
            >
              <ButtonContainer>
                {/* <SmartViewButton />
                <SmartSelectButton />
                <SmartFreezeButton /> */}
                <SelectOptionType setOptionFilter={setOptionFilter} />
                <SelectOptionBinSize setOptionFilter={setOptionFilter} />
              </ButtonContainer>
              {
                optionFilter.active && <OptionMenubar
                  optionFilter={optionFilter}
                  setOptionFilter={setOptionFilter}
                />
              }
              {
                loading
                  && <FetchRankLoading />
              }
              <FlexRow
                background="white"
                style={{
                  position: 'relative',
                  overflow: 'hidden',
                  zIndex: 3,
                  paddingLeft: '0.5em'
                }}
                onWheel={scroll}
                ref={scrollRef}
              >
                {
                  !loading
                    && prospectArea.map((el, index) => {
                        if (index > limit) {
                          return null
                        }

                        return <RankButton
                          key={el.id}
                          data={el}
                        />
                      })
                }
              </FlexRow>
              <ScrollRankButton
                arrowMove={arrowMove}
                clickMove={!rankbar.minimize ? clickMove : null}
              />
            </FlexRow>
          </>
    }
  </>
}

function mapStateToProps({ ui }) {
  let loading = false

  const actions = ui.loader.actions

  for (let i = 0; i < actions.length; i++) {
    if (
      actions[i].name === FETCH_PROSPECT_AREA_SITES
      || actions[i].name === CHANGE_COMMODITY
    ) {
      loading = true
      break
    }
  }
 
  return {
    loading
  }
}

export default connect(mapStateToProps, null)(ScrollBar)
