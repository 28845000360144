export function getSitebarToggle(state) {
  return state.sitebar.toggle
}

export function getSitebarWidth(state) {
  return state.sitebar.width
}

export function getSitebarMinimize(state) {
  return state.sitebar.minimize
}
