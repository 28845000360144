import React, { useEffect, useState } from 'react'

import Dropdown from '../../../../../../../Dropdowns/Default'

/**
 * Option menu to group by
 * @param {Object} map is the current map based on category 
 * @returns 
 */
function OptionMenuGroupBy({ map }) {
  const [ groupBy, setGroupBy ] = useState(map.cesiumLayer.groupBy)
  const [ listGroup, setListGroup ] = useState(map.cesiumLayer.listGroup)

  useEffect(() => {
    function eventGroupHandler() {
      setGroupBy(map.cesiumLayer.groupBy)
    }

    function eventListGroupHandler() {
      setListGroup(map.cesiumLayer.listGroup)
    }

    map.cesiumLayer.subscribeEvent('groupBy', eventGroupHandler)
    map.cesiumLayer.subscribeEvent('listGroup', eventListGroupHandler)

    return (() => {
      map.cesiumLayer.unsubscribeEvent('groupBy', eventGroupHandler)
      map.cesiumLayer.unsubscribeEvent('listGroup', eventListGroupHandler)
    })
  }, [])

  return (
    <Dropdown
      label="Group By"
      options={listGroup || []}
      setToggle={(value) => {
        map.cesiumLayer.setTemplate(null)
        map.cesiumLayer.setGroupBy(value)
      }}
      width="10em"
      iconType="filter"
      defaultPick={groupBy || ''}
    />
  )
}

export default OptionMenuGroupBy
