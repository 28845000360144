import * as d3 from 'd3';
import { adjustMarginLeftCharts } from '../helpers/adjustMarginLeftCharts';
import { capitalizeElement } from '../helpers/capitalizeFirstLetter';
import { dotme } from '../helpers/textStyle';

export const correlationMatrix = ({...props}) => {
    let {
        correlationMatrixRef,
        colorScaleCorrelationMatrixRef,
        value,
        valueTranspose,
        configMatrix,
        dataCorrelation,
        className,
        widthContainer,
        heightContainer,
        dataLocal
    } = props;

    d3.selectAll("."+className).transition().duration(1000).remove();

    const configViewBox = {
        minx: 0,
        miny: 0,
        width: widthContainer,
        height: heightContainer,
    }
    let marginLeft = 35
    if(configMatrix.seriesSelect.length!==0){
        marginLeft = adjustMarginLeftCharts({
            selected: configMatrix.seriesSelect,
            marginLeftInit: 35
        })
    }


    const margin = { top: 5, right: 25, bottom: 25, left: marginLeft },
    width = configViewBox.width - margin.left - margin.right,
    height = configViewBox.height - margin.top - margin.bottom;

    const svg = d3
    .select(correlationMatrixRef.current)
    .attr("viewBox", `${configViewBox.minx} ${configViewBox.miny} ${configViewBox.width} ${configViewBox.height}`)
    .append("g")
    .attr("class",className)
    .attr("transform", `translate(${margin.left}, ${margin.top})`);

    const xScale = d3.scaleBand()
    .range([0, width])
    .domain(value)
    .padding(0.1);

    const xAxis = svg.append("g")
    .attr("transform", "translate(0," + height + ")")
    .style("font-size", "0.8em")
    .style("font-family","Abel");

    xAxis
    .transition()
    .duration(1000)
    .call(
        d3.axisBottom(xScale)
        .tickSize(0)
        .tickFormat(function(d) {
            if(dataLocal){
                return dotme(d,3)
            }else{
                return dotme(capitalizeElement(d.slice(0,d.lastIndexOf("_"))),3);
            }
        })
    );

    const yScale = d3.scaleBand()
    .range([ height, 0 ])
    .domain(valueTranspose)
    .padding(0.1);

    const yAxis = svg.append("g")
    .style("font-family","Abel")
    .style("font-size", "0.8em");
    
    yAxis
    .transition()
    .duration(1000)
    .call(
        d3.axisLeft(yScale)
        .tickSize(0)
        .tickFormat(function(d) {
            if(dataLocal){
                return dotme(d,3)
            } else {
                return dotme(capitalizeElement(d.slice(0,d.lastIndexOf("_"))),3);
            }
        })
    );

    const myColor = d3.scaleLinear()
    .domain(configMatrix.domainColorScale)
    .range(configMatrix.colorScaleSelect);

    const groupsRect=svg.selectAll(".groups")
    .data(dataCorrelation, function(d) {return d.x+':'+d.y;})
    .enter()
    .append("g")
    .on("mouseover", function(d) {
        d3.select(this)
        .select(".textMatrix")
        .style("font-size", '0.8em')
        .style("stroke", '4px')
        .style("stroke", function (d) {
            if (d.value >= 1.00) {
                return 'white'
            } else if(d.value<0.00 && d.value>-0.00) {
                return 'white'
            } else {
                return 'black'
            }
        })
        xAxis.selectAll(".tick text")
        .text(function(t){
            if(t === d.target.__data__.x){
                return t
            } else {
                return dotme(t,3)
            }
        })
        .style("font-size", function(x){
            if(x === d.target.__data__.x){
                return '1.3em'
            }
        })
        .style("color",function(x){
            if(x === d.target.__data__.x){
                return 'gold'
            } else {
                return '#404040'
            }
        })
        .style("stroke",function(x){
            if(x === d.target.__data__.x){
                return '4px'
            }
        })
        yAxis.selectAll(".tick text")
        .text(function(t){
            if(t === d.target.__data__.y){
                return t
            }else {
                return dotme(t,3)
            }
        })
        .style("font-size", function(y){
            if(y === d.target.__data__.y){
                return '1.3em'
            }
        })
        .style("color",function(y){
            if(y === d.target.__data__.y){
                return 'gold'
            } else {
                return '#404040'
            }
        })
        .style("stroke",function(y){
            if(y === d.target.__data__.y){
                return '4px'
            }
        })
    })
    .on("mouseleave", function(d){
        d3.select(this)
        .select(".textMatrix")
        .style("font-size", '0.6em')
        .style("stroke", "none")
        xAxis.selectAll(".tick text")
        .text(function(d){
            return dotme(d,3)
        })
        .style("font-size",'1em')
        .style("stroke", "none")
        .style("color","white")
        yAxis.selectAll(".tick text")
        .text(function(d){
            return dotme(d,3)
        })
        .style("font-size",'1em')
        .style("stroke", "none")
        .style("color","white")
    })
    .on("mousemove", function(event, d){
        d3.select(this)
        .style("cursor","pointer")	
        .select(".textMatrix")
        .style("stroke", '4px')
        .style("font-size", '0.8em')
        .style("stroke", function (d) {
            if (d.value >= 1.00) {
                return 'white'
            } else if(d.value<0.00 && d.value>-0.00) {
                return 'white'
            } else {
                return 'black'
            }
        })
        .style("cursor","pointer")	
    });

    groupsRect
    .append("rect")
    .attr("x", function(d) {
        return xScale(d.x)
    })
    .attr("y", function(d) { 
        return yScale(d.y) 
    })
    .attr("rx", 4)
    .attr("ry", 4)
    .attr("width", 0 )
    .attr("height", 0 )
    .style("fill", function(d) { return myColor(d.value)})
    .style("stroke-width", 4)
    .style("stroke", "none")
    .style("opacity",1)

    groupsRect.selectAll("rect")
    .transition()
    .attr("width", xScale.bandwidth() )
    .attr("height", yScale.bandwidth() )
    .duration(1000);

    groupsRect
    .append("text")
    .attr("class","textMatrix")
    .text(function(d) { return d.value;})
    .attr("x", function (d) {
        return xScale(d.x) + xScale.bandwidth() / 2
    })
    .attr("y", function(d) {
        return yScale(d.y) + yScale.bandwidth() / 2 + 4
    }) 
    .style("font-family", "Abel")
    .style("font-size", '0px')
    .style("text-anchor", "middle")
    .style("fill", function (d) {
        if (d.value >= 1.00) {
            return 'white'
        } else if(d.value<=0.00 && d.value>=-0.00) {
            return 'white'
        } else {
            return 'black'
        }
    })

    groupsRect.selectAll("text")
    .transition()
    .style("font-size", '0.6em')
    .duration(1000)

    //---------------COLOR SCALE----------------//

    updateColorScale();
    svg.select("#selectData").on("change", updateColorScale);
    
    function updateColorScale(){
        const legend_top = 20;
        const legend_height = 10;
        const legend_left = marginLeft;
        const legend_right= 0;
    
        var legend_svg = d3.select(colorScaleCorrelationMatrixRef.current)
        .attr("viewBox", "0 0 "+configViewBox.width+" 35")
        .append("g")
        .attr("class",className)
        .attr("transform", "translate(" + legend_left + ", " + legend_top + ")");
    
        var defs = legend_svg.append("defs");
    
        var gradient = defs.append("linearGradient")
            .attr("id", "linear-gradient");

        var stops = [
            {offset: 0, color: configMatrix.colorScaleSelect[0], value: -1},
            {offset: .5, color: configMatrix.colorScaleSelect[1], value: 0},
            {offset: 1, color: configMatrix.colorScaleSelect[2], value: 1}
        ];

        const dataColorScale= gradient.selectAll("stop").data(stops);

        dataColorScale.enter()
            .append("stop")
            .attr("offset", function(d){ return (100 * d.offset) + "%"; })
            .attr("stop-color", function(d){ return d.color; });
        
        legend_svg
            .append("rect")
            .attr('class','rect')
            .attr("width", width-legend_right)
            .attr("height", legend_height)
            .style("fill", "url(#linear-gradient)");
        
        const textColorScale=legend_svg.selectAll("text").data(stops).attr("class","textRect");
        
        textColorScale.enter()
        .append("text")
        .attr("x", function(d){ return (width-legend_right)* d.offset; })
        .attr("dy", -3)
        .style("fill","white")
        .style("font-size","0.7em")
        .style("text-anchor", function(d, i){ return i === 0 ? "start" : i === 1 ? "middle" : "end"; })
        .text(function(d, i){ return d.value.toFixed(2); });
    }
}