import * as Cesium from 'cesium'
import * as d3 from 'd3'

import cesiumViewer from '../cesiumViewer'

// edit it later
// const BDL = [

// ]

export function computeCircle(radius) {
  var positions = []

  for (var i = 0; i < 360; i++) {
    const radians = Cesium.Math.toRadians(i)

    positions.push(
      new Cesium.Cartesian2(
        radius / 20 * Math.cos(radians),
        radius / 20 * Math.sin(radians)
      )
    )
  }

  return positions
}

/**
 * This function will score the borehole for create color
 * @param {Boolean} inverse
 * @param {Number} score is the score of current borehole
 * between 0 - 1 
 * @returns number of score
 */
export function scoreBorehole({
  inverse,
  score
}) {
  if (!inverse) {
    return score
  } else {
    return 1 - score
  }
}

/**
 * This function will generate 
 * @param {Object} map is the current selected map
 * @param {Number} min is the minimum value of data
 * @param {Number} max is the maximum value of data
 */
 export function generateScaleMethod({
  map,
  min,
  max
}) {
  if (map.colorScaling === 'linear') {
    return d3
      .scaleLinear()
      .domain([min, max])
      .range([0, 1])
  } else if (map.colorScaling === 'logarithmic') {
    return d3
      .scaleLog()
      .domain([min, max])
      .range([0, 1])
  }
}

/**
 * This function is to create cesium dataSource from borehole provided
 * @param bdl is the bellow detection limit
 * @param entities is the entities that need to recolor
 * @param elementName is the current filtered element
 * @param map is the map that selected
 */
export function setBoreholeColorScaleWithD3({
  bdl = 0.01,
  elementName = 'AU_FINAL',
  entities,
  map
}) {
  if (!elementName) {
    elementName = 'AU_FINAL'
  }

  const colors = []
  
  let min = bdl
  let max = 0

  // score the color
  for (let i = 0; i < entities.length; i++) {
    let elementValue = 0

    if (entities[i].properties[elementName]) {
      elementValue = entities[i].properties[elementName].getValue(Cesium.JulianDate.now())
    } else {
      colors.push(bdl)
      continue
    }

    if (elementValue < min) {
      if (elementValue < bdl) {
        elementValue = bdl
      }
      min = elementValue
    }

    if (elementValue > max) {
      max = elementValue
    }

    colors.push(elementValue)
  }

  const scoreScale = generateScaleMethod({
    map,
    min,
    max
  })

  for (let i = 0; i < entities.length; i++) {
    const entity = entities[i]

    if (colors[i] !== null) {
      const [ red, green, blue ] = d3[map.colorScale](
        scoreBorehole({
          inverse: map.cesiumLayer.inverse,
          score: scoreScale(colors[i])
        })
      )
        .slice(4, -1)
        .split(', ')
  
      map.colorEntities[entity.id] = {
        red: red / 255,
        green: green / 255,
        blue: blue / 255,
        alpha: map.cesiumLayer.alpha
      }
    }
  }
}

/**
 * This function is to generate cesium entity shape
 * @param map is the current map reference
 * @param entities is the entities to change
 */
 export async function setBoreholeCesiumEntityShape({
  map,
  entities
}) {
  if (map.polylineStatus) {
    let currentBoreholeId = ''

    for (let i = 0; i < entities.length; i++) {
      const entity = entities[i]

      const properties = entity.properties.getValue('')

      if (entity.point) {
        entity.point = undefined
      }

      const colorMaterialProperty = new Cesium.ColorMaterialProperty()
      colorMaterialProperty.color = new Cesium.CallbackProperty(
        () => {
          return new Cesium.Color.fromAlpha(
            new Cesium.Color(
              map.colorEntities[entity.id].red,
              map.colorEntities[entity.id].green,
              map.colorEntities[entity.id].blue
            ),
            map.colorEntities[entity.id].alpha
          )
        },
        false
      )

      if (
        properties.coordinates[0] !== null
        && properties.coordinates[1] !== null
        && properties.coordinates[2] !== null
      ) {
        if (!currentBoreholeId) {
          currentBoreholeId = properties.BHID
        } else if (currentBoreholeId !== properties.BHID) {
          currentBoreholeId = properties.BHID
        } else if (currentBoreholeId === properties.BHID) {
          const prevProperties = entities[i - 1].properties.getValue('')
          
          // add translucency for enhance feature later
          entity.polylineVolume = {
            positions: Cesium.Cartesian3.fromDegreesArrayHeights([
              prevProperties.coordinates[0],
              prevProperties.coordinates[1],
              prevProperties.coordinates[2],
              properties.coordinates[0],
              properties.coordinates[1],
              properties.coordinates[2]
            ]),
            shape: computeCircle(map.pixelSize),
            cornerType: Cesium.CornerType.ROUNDED,
            material: colorMaterialProperty
          }
        }
      }
    }
  } else {
    for (let i = 0; i < entities.length; i++) {
      const entity = entities[i]

      if (entity.polylineVolume) {
        entity.polylineVolume = undefined
      }

      const properties = entity.properties.getValue('')

      entity.position = Cesium.Cartesian3.fromDegrees(
        properties.coordinates[0],
        properties.coordinates[1],
        properties.coordinates[2]
      )

      entity.point = {
        color: new Cesium.CallbackProperty(
          () => {
            return new Cesium.Color.fromAlpha(
              new Cesium.Color(
                map.colorEntities[entity.id].red,
                map.colorEntities[entity.id].green,
                map.colorEntities[entity.id].blue
              ),
              map.colorEntities[entity.id].alpha
            )
          },
          false
        ),
        pixelSize: map.pixelSize
      }
    }
  }
}

/**
 * This function is to create cesium dataSource from geojson provided
 * @param name is the name for dataSource
 * @param geojson is geojson data of borehole
 * @param map is the map that selected
 * @param type is need to edit later, but used for create multiple purpose
 */
 export async function loadBoreholeGeoJSON({
  name,
  geojson,
  map
}) {
  try {
    const dataSource = await new Cesium.CustomDataSource(name)

    // get height
    const pointsOfInterests = []
    const heightIndex = []
    const initialHeight = []

    let pointResults = []
    let currentBoreholeId = ''

    for (let i = 0; i < geojson.features.length; i++) {
      const { geometry, properties } = geojson.features[i]
      // To get height, from no height reference
      // Specify our point of interest.
      // Sample the terrain (async) and write the answer to the console.
      if (currentBoreholeId !== properties.BHID) {
        currentBoreholeId = properties.BHID

        if (
          geometry.coordinates[0] !== null
          && geometry.coordinates[1] !== null
        ) {
          pointsOfInterests.push(
            Cesium.Cartographic.fromDegrees(
              geometry.coordinates[0],
              geometry.coordinates[1]
            )
          )
          initialHeight.push(geometry.coordinates[2])
        }
      }

      heightIndex.push(pointsOfInterests.length - 1)
    }

    pointResults = await Cesium.sampleTerrainMostDetailed(cesiumViewer.viewer.terrainProvider, pointsOfInterests)

    for (let i = 0; i < pointResults.length; i++) {
      pointResults[i].height -= initialHeight[i]
    }

    for (let i = 0; i < geojson.features.length; i++) {
      const { geometry, properties } = geojson.features[i]

      await dataSource.entities.add({
        properties: {
          ...properties,
          customType: 'editItLater',
          coordinates: [
            geometry.coordinates[0],
            geometry.coordinates[1],
            pointResults[heightIndex[i]].height + geometry.coordinates[2]
          ]
        }  
      })
    }

    setBoreholeColorScaleWithD3({
      map,
      entities: dataSource.entities.values
    })

    setBoreholeCesiumEntityShape({
      map,
      entities: dataSource.entities.values
    })
  
    return dataSource
  } catch (error) {
    throw error
  }
}
