import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'

import {
  connectLegend,
  disconnectLegend
} from '../../../apis/cesiumHandler/legend'

import {
  getLegendToggle
} from '../../../store/selectors/legendbar'

import DefaultContent from './DefaultContent'
import NumericalContent from './NumericalContent'

/**
 * This component will be render under menubar layer management display
 * The reason because display configuration always active eventhough being hide
 * @param {Object} legendbar is the redux state of legendbar
 */
function Legendbar({
  legendbar
}) {
  const [ legendConfig, setLegendConfig ] = useState({
    numerical: {},
    other: {}
  })

  const [ barLegend, setBarLegend ] = useState({})

  async function prepareLegendConfig(name) {
    const map = legendbar.legend[name]

    if (map.tileset) {
      if (!map.tilesetData || !map.tilesetData.show) {
        return
      }

      if (!map.tilesetLegend) {
        await map.fetchTilesetLegend({
          params: ''
        })
      }

      if (map.tilesetLegend?.length && map.tilesetLegend[0]) {
        if (map.tilesetLegend[0].min !== undefined) {
          map.cesiumLayer.setFieldAttribute({
            pickList: ['numerical'],
            selected: 'numerical'
          })

          return {
            map,
            keys: [{}]
          }
        }

        map.cesiumLayer.setFieldAttribute({
          pickList: ['categorical'],
          selected: 'categorical'
        })

        return {
          map,
          keys: [{}]
        }
      }

      return
    }

    if (
      !map.dataSource
      || !map.dataSource.show
      || !map.cesiumLayer.groupEntities
    ) {
      return
    }

    let newBarLegend = null

    prepareGroupEntities:
    for (let i = 0; i < map.cesiumLayer.groupEntities.keys.length; i++) {
      const { label, value } = map.cesiumLayer.groupEntities.keys[i]
      const entities = map.cesiumLayer.groupEntities.group[label]
      
      for (let j = 0; j < entities.length; j++) {
        if (entities[j].show) {
          if (!newBarLegend) {
            newBarLegend = {
              map,
              keys: [
                {
                  label,
                  value
                }
              ]
            }
          } else {
            newBarLegend.keys.push({
              label,
              value
            })
          }

          continue prepareGroupEntities
        }
      }
    }

    return newBarLegend
  }

  useEffect(() => {
    return () => {
      setLegendConfig({
        numerical: {},
        other: {}
      })
    }
  }, [])

  useEffect(() => {
    async function prepareBarLegend() {
      const barLegend = {}

      const promiseGetLegend = []

      const legendNames = []

      for (const name in legendbar.legend) {
        legendNames.push(name)
        promiseGetLegend.push(prepareLegendConfig(name))
      }

      const solvedPromise = await Promise.all(promiseGetLegend)

      let i = 0

      solvedPromise.forEach((newLegend) => {
        barLegend[legendNames[i]] = newLegend

        i += 1
      })

      setBarLegend(barLegend)
    }

    prepareBarLegend()

    function eventHandler(name) {
      prepareLegendConfig(name)
    }

    const methodEventList = {}

    for (const name in legendbar.legend) {
      const map = legendbar.legend[name]

      methodEventList[name] = () => {
        eventHandler(name)
      }

      map.cesiumLayer.subscribeEvent('groupEntities', methodEventList[name])
    }

    return () => {
      for (const name in legendbar.legend) {
        const map = legendbar.legend[name]
  
        map.cesiumLayer.unsubscribeEvent('groupEntities', methodEventList[name])
      }
    }
  }, [legendbar.legend])

  useEffect(() => {
    connectLegend(barLegend, setLegendConfig)

    return () => {
      disconnectLegend()
    }
  }, [barLegend])
  
  return <>
    {
      !Object.keys(legendConfig.numerical).length
        ? null
        : <NumericalContent objLegend={legendConfig.numerical} />
    }
    {
      !Object.keys(legendConfig.other).length
        ? null
        : <DefaultContent objLegend={legendConfig.other} />
    }
  </>
}

function mapStateToProps(state) {
  return {
    toggle: getLegendToggle(state),
    legendbar: state.legendbar
  }
}

export default connect(mapStateToProps, null)(Legendbar)
