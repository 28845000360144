import axiosReact, { getProgressDownload } from '../../../axiosReact'
import rgbToHex from '../../../helpers/rgbToHex'

export async function fetch({
  map,
  params,
  fetchProgress,
  fetchFinish
}) {
  try {
    if (params.withgeojson) {
      const { data } = await axiosReact.get('/geofix/accessibility/port', {
        // params,
        onDownloadProgress(event) {
          getProgressDownload(event, fetchProgress)
        }
      })

      fetchFinish()

      data.features.forEach((feature) => {
        feature.properties = formatProperty(feature.properties)
      })
  
      return {
        geojson: data
      }
    }
  } catch (error) {
    throw error
  }
}

export function formatProperty(property) {
  return {
    name: property.name,
    'UPT Teknis': property.upt_teknis,
    Address: property.address,
    Region: property.region,
    Source: property.source,
    'Port Function': property.port_function,
    'Port Type': property.port_type,
    Status: property.status,
    Remark: property.REMARK
  }
}

export function parseFetch({ data }) {
  return data
}

export function getColor(entity, {
  map
}) {
  return {
    red: 1,
    green: 1,
    blue: 0,
    alpha: map.cesiumLayer.alpha
  }
}

export function getIcon(entity, color) {
  let hex = null

  const status = entity.properties.getValue('class').class

  if (status && status.toLowerCase().includes('old')) {
    hex = '#808080'
  } else if (color) {
    hex = rgbToHex(
      Math.round(color.red * 255),
      Math.round(color.green * 255),
      Math.round(color.blue * 255)
    )
  } else {
    hex = rgbToHex(
      Math.round(Math.random() * 255),
      Math.round(Math.random() * 255),
      Math.round(Math.random() * 255)
    )
  }
  const svgDataDeclare = "data:image/svg+xml,";
  const svgCircle = `<circle cx="94.8" cy="94.8" r="94.8" style="fill:%234e5284" /><path d="M97.28,28.65c4.9,1.28,9.2,3.43,12.14,7.82,5.45,8.15,3.18,19.6-5.34,24.45-2.56,1.45-3.25,3-3,5.57a31,31,0,0,1,0,3.22c4.47,0,8.68.07,12.87,0,.82,0,1.6-.79,2.42-1.18a6.11,6.11,0,0,1,7.45,1.65,5.85,5.85,0,0,1,.49,7.15c-1.5,2.39-4.68,3.87-7.15,2.48-3.32-1.87-6.7-1.32-10.11-1.44-1.83-.07-3.66,0-5.73,0v58.55c12.21.69,29-6.72,33.94-17.34L130.85,118l16-15.25c1.9,7.1,3.7,13.85,5.51,20.6v1.1l-5.28-1.64c-.3.23-.4.26-.45.34-.48.77-.94,1.56-1.43,2.33-9.62,15.1-22.91,25.09-40.7,28.2-4.25.74-7.16,1.95-8.31,6.22-.08.31-.41.56-.76,1A30,30,0,0,0,93,156.53a5.46,5.46,0,0,0-3-2.14A57.71,57.71,0,0,1,60.11,141.7,60.38,60.38,0,0,1,43,120.13l-5.77,1v-.55q2.94-8.82,5.87-17.64c.29-.87.57-1.73,1-3l15,16.32-4.43,1.23C59.25,128.71,75,136.89,89.77,137V78.33c-4.83,0-9.56,0-14.28.06-.73,0-1.43.74-2.16,1.1a6.26,6.26,0,0,1-7.7-1.66,5.86,5.86,0,0,1-.15-7.38,6.48,6.48,0,0,1,7.61-2.08,21.65,21.65,0,0,0,6,1.31c3.46.21,6.94.06,10.85.06,0-2.11.1-4-.07-5.86,0-.51-.82-1.11-1.39-1.39-8.35-4.13-11.9-10.1-11-18.63A17.43,17.43,0,0,1,91.87,29c.16,0,.3-.2.45-.3ZM94.89,55.17a9,9,0,0,0,9-9.12,9.06,9.06,0,0,0-18.11.19A8.92,8.92,0,0,0,94.89,55.17Z" style="fill:%23fff"/>

  `;
  const svgPrefix = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/2000/xlink" x="0px" y="0px" viewBox="0 0 189.6 189.6" height="60pt" width="60pt" preserveAspectRatio="xMidYMid meet">`;
  const svgSuffix = "</svg>";
  const svgString = svgPrefix + svgCircle + svgSuffix;

  // create the cesium entity
  const svgEntityImage = svgDataDeclare + svgString;
  return svgEntityImage
}


