import styled from "styled-components";

export const FooterTextRight = styled.div`
    font-size:10px;
    margin-left:15px;
    margin-right:15px;
    color:red;
    display:flex;
    flex-direction:column;
    width: ${props => props.width ? props.width : '175px'};
`;

export const TableWraper = styled.table`
    margin-right:${props => props.type==="Drillhole" ? '0em' : '2em'};
    margin-top:${props => props.type==="Drillhole" ? '0em' : '1.5em'};
`