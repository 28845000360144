import React from 'react'

import FocusContainer, {
  Header,
  LinkText,
  Paragraph
} from '../StyledComponent'
import MenuPNG from '../HelpImage/menu.png'
import CommodityPNG from '../HelpImage/commodity.png'
import SearchPNG from '../HelpImage/search.png'
import LayerPNG from '../HelpImage/layer-no-commodity.png'
import NewsPNG from '../HelpImage/news.png'
import DatabasePNG from '../HelpImage/database.png'
import { LIST_HELP } from '../constant'


function HelpMenu(props) {
  const flyTo=(x,y)=>{
    let cloneOpened = []
    cloneOpened.push(y)
    
    props.setOpened(cloneOpened)

    props.setFocus(x)
  }

  return <>
    <FocusContainer {...props}>
      <Header>{LIST_HELP['dashboard'].detail.menuBar.header}</Header>
      <img src={MenuPNG} alt="Menu image" width="700px" style={{marginBottom:'1em'}} />
      <Paragraph>
        Show/hide menu by left click
      </Paragraph>
      <Paragraph>
        a. Commodity Menu
      </Paragraph>
      <img src={CommodityPNG} alt="Commodity image" width="750px" style={{marginBottom:'1em'}}/>
      <Paragraph>
        b. Search Menu
      </Paragraph>
      <img src={SearchPNG} alt="Search image" width="700px" style={{marginBottom:'1em'}}/>
      <Paragraph>
        c. Layer Menu
      </Paragraph>
      <Paragraph style={{marginLeft:'1em'}}>
      In the image below, prospective map is not active, because commodity data has not been selected yet. Please, go to <LinkText onClick={()=>flyTo('menuBar','dashboard')}>How to choose commodity?</LinkText>
      </Paragraph>
      <img src={LayerPNG} alt="Layer image" width="550px" style={{marginBottom:'1em'}}/>
      <Paragraph>
        d. Database Menu
      </Paragraph>
      <Paragraph style={{marginLeft:'1em'}}>
      This menu is used to display local data from the user. To use it, see how to display <LinkText onClick={()=>flyTo('surface','menu')}>surface</LinkText> and <LinkText onClick={()=>flyTo('surface','menu')}>drilling</LinkText>  data on Geoprosp
      </Paragraph>
      <img src={DatabasePNG} alt="Database image" width="1100px" style={{marginBottom:'1em'}}/>
      <Paragraph>
        e. News Menu
      </Paragraph>
      <Paragraph style={{marginLeft:'1em'}}>
      News that is displayed based on the <LinkText onClick={()=>flyTo('menuBar','dashboard')}>selected commodity</LinkText>, this news is the latest news.
      </Paragraph>
      <img src={NewsPNG} alt="News image" width="1100px" style={{marginBottom:'1em'}}/>
    </FocusContainer>
  </>
}

export default HelpMenu
