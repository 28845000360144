import React, { useState, useEffect, useRef } from 'react'

import { connect } from 'react-redux'

import { getUsername } from '../../../../store/selectors/user'
import { getChatSocket, getRoomSocket } from '../../../../store/selectors/socket'

import SocketClient from '../../../../apis/socket'

import RecipientContent from './RecipientContent'

import Header from './Header'
import MessageBody from './MessageBody'
import MessageChat from './MessageChat'

import { ReactComponent as PencilIcon } from '../../../../images/Icons/pencil.svg'
import { ReactComponent as AddPersonIcon } from '../../../../images/Icons/add-person.svg'

import {
  BlockContent
} from '../shares/StyledComponent'

import {
  EditableTextIcon,
  MessageValue
} from './shares/StyledComponent'

import styled from 'styled-components'

const LineHeader = styled.div`
  display: flex;
  margin: 0.2em 0.5em;
`

function MessageContent({
  chatRoom,
  backToChat,
  username,
  socket,
  // roomSocket,
}) {  
  const [ title, setTitle ] = useState('')
  const [ search, setSearch ] = useState('')
  const [ roomSocket, setRoomSocket ] = useState(null)
  const [ recipients, setRecipients ] = useState([])
  const [ newMessage, lastNewMessage ] = useState(new Date)
  const inputTitleRef = useRef(null)

  function onChangeInputTitle(event) {
    // if (!chatRoom || chatRoom.owner.username === username) {
    if (!chatRoom) {
      setTitle(event.target.value)
    }
  }

  function editTitleHandler() {
    if (inputTitleRef.current && inputTitleRef.current.focus) {
      inputTitleRef.current.focus()
    }
  }

  useEffect(() => {
    if (chatRoom) {
      if (!roomSocket) {
        setRoomSocket(socket)
      }
      SocketClient.connectRoomByMessageId(socket, chatRoom.message_id)
      SocketClient.connectRemoveRecipient(socket, {
        setRecipients,
        backToChat
      })
      SocketClient.connectAddRecipient(socket, {
        setRecipients,
      })
      SocketClient.listenRemoveUser(socket, {
        backToChat
      })

      setTitle(chatRoom.title)
    }

    return () => {
      if (roomSocket) {
        // roomSocket.disconnect()
        // setRoomSocket(null)
        // setRecipients([])
      }
    }
  }, [chatRoom, roomSocket])

  return <>
    <BlockContent>
      <Header
        setSearch={setSearch}
        backToChat={backToChat}
      />
      <LineHeader>
        <PencilIcon
          fill="white"
          width="1.2em"
          style={{
            marginTop: '0.1em'
          }}
          title="Title"
        />
        <MessageValue onClick={editTitleHandler}>
          <EditableTextIcon
            value={title}
            onChange={onChangeInputTitle}
            readOnly={chatRoom
              // && chatRoom.owner.username !== username
            }
            onClick={editTitleHandler}
            ref={inputTitleRef}
          />
        </MessageValue>
      </LineHeader>
      <LineHeader>
        <AddPersonIcon
          fill="white"
          width="1.2em"
          style={{
            marginTop: '0.1em'
          }}
          title="Recipients"
        />
        <RecipientContent
          chatRoom={chatRoom}
          username={username}
          lastNewMessage={lastNewMessage}
          recipients={recipients}
          setRecipients={setRecipients}
        />
      </LineHeader>
      <MessageBody
        chatRoom={chatRoom}
        username={username}
        newMessage={newMessage}
        lastNewMessage={lastNewMessage}
      />
      <MessageChat
        chatRoom={chatRoom}
        // setRoomSocket={setRoomSocket}
        recipients={recipients}
        title={title}
        lastNewMessage={lastNewMessage}
      />
    </BlockContent>
  </>
}

function mapStateToProps(state) {
  return {
    username: getUsername(state),
    socket: getChatSocket(state),
    // roomSocket: getRoomSocket(state)
  }
}

export default connect(mapStateToProps, null)(MessageContent)
