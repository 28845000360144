import React, { useEffect, useState } from 'react'

import Dropdown from '../../../../../../../Dropdowns/Default'

import {
  LIST_SORT_BY_OPTION
} from '../../../../../../../../constants/Menubars/Layerbar'

/**
 * Option menu to sortBy
 * @param {Object} map is the current map
 * @returns 
 */
function OptionMenuSortBy({ map }) {
  const [ sortBy, setSortBy ] = useState(map.cesiumLayer.sortBy)
  const [ fieldAttribute, setFieldAttribute ] = useState(map.cesiumLayer.fieldAttribute)

  useEffect(() => {
    function eventSortHandler() {
      setSortBy(map.cesiumLayer.sortBy)
    }

    function eventAttributeHandler() {
      setFieldAttribute(map.cesiumLayer.fieldAttribute)
    }

    map.cesiumLayer.subscribeEvent('sortBy', eventSortHandler)
    map.cesiumLayer.subscribeEvent('fieldAttribute', eventAttributeHandler)

    return (() => {
      map.cesiumLayer.unsubscribeEvent('sortBy', eventSortHandler)
      map.cesiumLayer.unsubscribeEvent('fieldAttribute', eventAttributeHandler)
    })
  }, [])

  function setTemplate(template) {
    map.cesiumLayer.setTemplate(template)
  }

  return (
    <Dropdown
      label="Sort By"
      options={LIST_SORT_BY_OPTION[fieldAttribute.selected] || []}
      setToggle={(value) => {
        setTemplate(null)
        map.cesiumLayer.setSortBy(value)
      }}
      width="10em"
      iconType="sort"
      defaultPick={sortBy}
    />
  )
}

export default OptionMenuSortBy
