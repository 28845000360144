import React from 'react'

import DefaultSearch from '../../../SearchBoxs/DefaultSearch'

import { ToggleSVGButton } from '../../../shares/ToggleSVGIcon'

import { ReactComponent as MessageIcon } from '../../../../images/Icons/message.svg'

import {
  HeaderContainer
} from '../shares/StyledComponent'

function Header({ setSearch, newChatHandler }) {
  function searchHandler(search) {
    setSearch(search)
  }

  return <HeaderContainer>
    <ToggleSVGButton
      flex
      center
      margin="0 0.5em 0 0"
      onClick={newChatHandler}
      title="New Message"
    >
      <MessageIcon />
    </ToggleSVGButton>
    <DefaultSearch
      active={true}
      searchProcess={searchHandler}
      stopSearch={searchHandler}
    />
  </HeaderContainer>
}

export default Header
