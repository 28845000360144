import { combineReducers } from 'redux'

import sidebarReducer from './sidebarToggle'
import sitebarReducer from './sitebar'
import rankbarReducer from './rankbar'
import legendbarReducer from './legendbar'
import cesiumReducer from './cesium'
import cesiumEntityReducer from './cesiumEntity'
import catalogReducer from './catalog'
import catalogSearchReducer from './catalogSearch'
import sitesReducer from './sites'
import dragReducer from './drag'
import displayReducer from './display'
import gradebarReducer from './gradebar'
import tablebarReducer from './tablebar'
import toolbarReducer from './toolbar'
import chatReducer from './chatbar'
import uiReducer from './uiReducer'
import userReducer from './user'
import socketReducer from './socket'
import cameraReducer from './camera'
import rightClickReducer from './rightclick'
import editorReducer from './editor'
import advancedSearchReducer from './advancedSearch'
import databaseReducer from './database'

const reducer = combineReducers({
  cesiumState: cesiumReducer,
  cesiumEntity: cesiumEntityReducer,
  catalog: catalogReducer,
  catalogSearch: catalogSearchReducer,
  rankbar: rankbarReducer,
  legendbar: legendbarReducer,
  sidebarState: sidebarReducer,
  sitebar: sitebarReducer,
  sites: sitesReducer,
  socket: socketReducer,
  drag: dragReducer,
  display: displayReducer,
  grade: gradebarReducer,
  table: tablebarReducer,
  toolbar: toolbarReducer,
  chat: chatReducer,
  ui: uiReducer,
  user: userReducer,
  camera: cameraReducer,
  rightClick: rightClickReducer,
  editor: editorReducer,
  advancedSearch: advancedSearchReducer,
  database: databaseReducer
})

export default reducer
