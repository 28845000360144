import Icon from './Icon'

import styled from 'styled-components'

export const SvgIcon = styled(Icon)`
  margin: ${props => props.margin};
  height: ${props => props.height};
  
  svg {
    margin-top: ${props=> props.marginTop};
    width: ${props => props.width};
    height: ${props => props.height};
    fill: ${props => props.fill};
    cursor: ${props => props.cursor};
    
    &:hover {
      fill: ${props => props.hoverFill};
    }
  }
`
