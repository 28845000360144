import React, { useEffect } from 'react'
import cesiumViewer from '../../../../apis/cesiumViewer'

import DefaultSearch from '../../../SearchBoxs/DefaultSearch'
import FlexWrapper from './FlexWrapper'

function SearchBox({
  dataSource,
  setDataSource,
  searchProcessHandler,
  resetSearchHandler
}) { 
  useEffect(() => {
    resetSearchHandler()

    return () => {
      if (dataSource) {
        if (
          cesiumViewer.viewer.dataSources
            .indexOf(dataSource) !== -1
        ) {
          cesiumViewer.viewer.dataSources.remove(dataSource)
        }
      }
      setDataSource(null)
      resetSearchHandler()
    }
  }, [])

  return <>
    <FlexWrapper
      margin="0.3em 0.5em 0 0.5em"
      style={{
        position: 'relative'
      }}
    >
      <DefaultSearch
        active={true}
        searchProcess={searchProcessHandler}
        stopSearch={resetSearchHandler}
        placeholder='Search ...'
      />
    </FlexWrapper>
  </>
}

export default SearchBox
