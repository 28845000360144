import { useEffect } from 'react'

/**
 * Hook that alerts clicks outside of the passed ref
 * Alert if clicked on outside of element
 * @param ref is the ref that will be checked
 * @param eventHandler the event that will be triggered when clicked outside the ref and except ref
 * @param exceptRefs if the ref that will except event Handler
 */
function ClickOutsideHandler({
  ref,
  eventHandler,
  exceptRefs
}) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (exceptRefs && exceptRefs.length) {
          let isTrigger = true

          for (let i = 0; i < exceptRefs.length; i++) {
            if (exceptRefs[i].current && exceptRefs[i].current.contains(event.target)) {
              isTrigger = false
              break
            }
          }

          if (isTrigger) {
            eventHandler()
          }
        } else {
          eventHandler()
        }
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

export default ClickOutsideHandler
