import { components } from 'react-select';
import { drillingType } from '../../constants/Database/configImport';
import { CenterGrid } from './partials';
import PreviewTable from './PreviewTable';

const { Option } = components

export const IconOption = props => (
    <Option {...props}>
        <img
        src={require('../../images/Icons/' + props.data.icon)}
        style={{ width: 20, marginRight: 20 , marginLeft:5}}
        alt={props.data.label}
        />
        {props.data.label}
    </Option>
);

export const IconOptionWhite = props => (
    <Option {...props}>
        <img
        src={require('../../images/Icons/' + props.data.icon)}
        style={{ width: 20, marginRight: 20,filter: 'invert(100%) sepia(0%) saturate(1026%) hue-rotate(204deg) brightness(118%) contrast(100%)'}}
        alt={props.data.label}
        />
        {props.data.label}
    </Option>
);

export const customSingleValue = ({ data }) => (
    <CenterGrid>
        <img
        src={require('../../images/Icons/' + data.icon)}
        style={{ width: 20, marginRight: 20, marginLeft:5}}
        alt={data.label}
        />
        {data.label}
    </CenterGrid>
);

export const customSingleValueWhite = ({ data }) => (
    <CenterGrid>
        <img
        src={require('../../images/Icons/' + data.icon)}
        style={{ width: 20, marginRight: 20,filter: 'invert(100%) sepia(0%) saturate(1026%) hue-rotate(204deg) brightness(118%) contrast(100%)'}}
        alt={data.label}
        />
        {data.label}
    </CenterGrid>
);


export const customStyles = {
    control: base => ({
        ...base,
        padding:0,
        width:300,
        height:40,
    }),
    container:base=>({
        ...base,
        marginTop:30,
        color:'black'
    }),
    option:(provided)=>({
        ...provided,
        borderBottom:'1px dotted black',
        padding:3,
        fontSize:18,
        textAlign:'left'
    }),
    menu:base=>({
        ...base,
        width:300,
        marginTop:2,
        zIndex:9999
    }),
    menuPortal:base=>({
        ...base,
        zIndex:9999
    }),
    valueContainer:base=>({
        ...base,
        fontSize:18,
        textAlign:'left',
        alignItems: 'center'
    })
}

export const customStylesTitle = {
    control: base => ({
        ...base,
        backgroundColor:'black',
    }),
    container:base=>({
        ...base,
        color:'white',
        padding:0,
        marginLeft:36,
        marginBottom:16,
        width:250,
        height:40,
    }),
    option:(provided,{ data, isDisabled, isFocused, isSelected })=>({
        ...provided,
        borderBottom:'1px dotted black',
        padding:3,
        fontSize:18,
        textAlign:'left',
        backgroundColor:isDisabled
        ? undefined
        : isSelected
        ? '#808080'
        : isFocused
        ? '#404040'
        : undefined,
        
    }),
    menu:base=>({
        ...base,
        backgroundColor:'black',
        marginTop:2
    }),
    valueContainer:base=>({
        ...base,
        fontSize:18,
        textAlign:'left',
        alignItems: 'center'
    })
}

export const customStyleTable = {
    control: base => ({
        ...base,
        padding:0,
        height: 20,
        minHeight: 20,
        marginTop:3,
        borderRadius:4
    }),
    container:base=>({
        ...base,
        color:'black',
        fontFamily:'abel'
    }),
    option:(provided,state)=>({
        ...provided,
        borderBottom:'1px dotted black',
        padding:3,
        fontSize:12
    }),
    menu:base=>({
        ...base,
        marginTop:2,
        zIndex:'999 !important',
    }),
    menuList:base =>({
        ...base,
        maxHeight:150,
    }),
    valueContainer:base=>({
        ...base,
        fontSize:12,
        marginLeft:5,
        height: 20,
        padding:0,
        fontWeight:'bold'
    }),
    input:base=>({
        ...base,
        paddingTop:0,
        fontSize:12
    }),
    dropdownIndicator:base=>({
        ...base,
        marginTop:0,
        padding:0,
        paddingRight:4,
        height: 20,
    }),
    clearIndicator:base=>({
        ...base,
        marginTop:0,
        padding:0,
        height: 20,
    }),
    indicatorSeparator:base=>({
        ...base,
        marginBottom:10,
        marginTop:2,
        height: 15,
        visibility: 'hidden'
    }),
    indicatorsContainer:base=>({
        ...base,
        height: 20,
    })
    
};

export const optionsSettings=(array)=>{
    let arOpt=[]
    array.map((i)=>{
        let option={
            label: i,
            value: i 
        }
        arOpt.push(option)
    })
    return arOpt;
}

export const togglePreviewDrillingMulti = ({...props}) => {
    let {
        data,
        setData,
        valHeader,
        setValHeader,
        typeHeader,
        setTypeHeader,
        type,
        togglePreviewDrilling,
        setTogglePreviewDrilling
    } = props;
    let pop = drillingType.map((i,idx)=>{
        if(togglePreviewDrilling[i.key]){
            let setPopup=(val)=>{
                let obj={...togglePreviewDrilling}
                obj[i.key]=val
                setTogglePreviewDrilling(obj)
            }
            let subtypeUpload =()=>{
                if(i.key==='others'){
                    return(data.others.subtype)
                } else {
                    return(i.key)
                }
            }
            let setHeaderUpload = (head) =>{
                let arr = {...data}
                arr[i.key].header=head
                setData(arr)
            }
            let handleSetValueHeader=(val)=>{
                let obj = {...valHeader}
                obj[i.key]=val
                setValHeader(obj)
            }
            let handleSetTypeHeader=(val)=>{
                let obj = {...typeHeader}
                obj[i.key]=val
                setTypeHeader(obj)
            }
            
            return(
                <PreviewTable
                    key={'drilling'+idx}
                    type={type}
                    popup={togglePreviewDrilling[i.key]}
                    setPopup={setPopup}
                    headerData={data[i.key].header}
                    valuesHeader={valHeader[i.key]}
                    setValuesHeader={handleSetValueHeader}
                    subtype={subtypeUpload()}
                    dataTypeHeader={typeHeader[i.key]}
                    setDataTypeHeader={handleSetTypeHeader}
                />
            )
        }
    })
    return pop
}

