import { all } from 'redux-saga/effects'

import catalogSaga from './catalog'
import catalogSearchSaga from './catalogSearch'
import advancedSearchSaga from './advancedSearch'
import sitesSaga from './sites'
import toolbarSaga from './toolbar'
import toggleSaga from './toggle'
import socketSaga from './socket'
import userSaga from './user'

export default function* rootSaga() {
  yield all([
    ...catalogSaga,
    ...catalogSearchSaga,
    ...advancedSearchSaga,
    ...sitesSaga,
    ...toolbarSaga,
    ...toggleSaga,
    ...socketSaga,
    ...userSaga
  ])
}
