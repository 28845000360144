import React, { useState, useEffect, useRef } from 'react'

import ClickOutsideHandler from '../../helpers/ClickOutsideHandler'

import DropdownLabel from './DropdownLabel'
import DropdownIcon from './DropdownIcon'
import DropdownList from './DropdownList'
import DropdownText from './DropdownText'

import * as ColorTemplate from '../../shares/ColorTemplate'

import styled from 'styled-components'

const DropdownLayout = styled.div.attrs((props) => {
  return {
    style: {
      marginTop: props.marginTop,
      width: props.width,
      height: props.height
    }
  }
})`
  position: relative;
  font-family: Abel;
  font-weight: 600;
`

const DropdownBox = styled.div.attrs((props) => {
  return {
    style: {
      backgroundColor: props.initialBoxColor
    }
  }
})`
  border: 1px solid #000000;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  height: 100%;
  overflow: auto;
  cursor: pointer;
`

const FloatingText = styled.div.attrs((props) => {
  return {
    style: {
      top: props.top,
      color: props.fontColor
    }
  }
})`
  position: absolute;
  font-family: Abel;
  font-size: 0.6em;
  pointer-events: none;
`

/**
 * This component is display detail pick and loop the DetailMenu
 * @param label is the name of the label
 * @param options is the options
 * @example <caption>Options format</caption>
 * // [
 * //   {
 * //     pickName: 'depositType',
 * //     pickShow: 'Deposit Type'
 * //   },
 * //   {
 * //     pickName: 'reserve',
 * //     pickShow: 'Reserve'
 * //   }
 * // ]
 * @param setToggle to set parent toggle choice
 * @param floatingText is the header text of the content description
 * @param marginTop use if you use floating text
 * @param height the height of dropdown
 * @param width the width of dropdown
 * @param iconType the type of icon (sort, filter, none)
 * @param defaultPick is the default selected value
 * @param initialBoxColor is the color for the initial of box
 * @param backgroundColor it to use for color the content
 * @param fontColor is the color of the text font
 */
function Drowdown({
  label,
  options,
  setToggle,
  floatingText,
  marginTop,
  width,
  height = '1.2em',
  iconType,
  defaultPick,
  initialBoxColor = 'white',
  backgroundColor = {
    default: [
      ColorTemplate.LIGHT_WHITE.default,
      ColorTemplate.LIGHT_YELLOW.default
    ],
    hover: [
      ColorTemplate.LIGHT_WHITE.hover,
      ColorTemplate.LIGHT_YELLOW.hover
    ]
  },
  fontColor = 'black',
  position = 'fixed',
  heightOptions = '10.85em',
  className
}) {
  const containerRef = useRef(null)

  ClickOutsideHandler({
    ref: containerRef,
    eventHandler: () => {
      setDropdown(false)
    },
    exceptRefs: [
      containerRef
    ]
  })

  const [ pick, setPick ] = useState(null)
  const [ dropdown, setDropdown ] = useState(false)

  function generateTop(height) {
    let isEm = false
    let number = 0
    
    if (height.includes('em')) {
      isEm = true
      number = Number(height.split('em')[0])
    }

    if (isNaN(number)) {
      return 0
    } else if (isEm) {
      return - number * 1.2 + 'em'
    }  else {
      return - number * 1.2
    }
  }

  useEffect(() => {
    function setInitialPick() {
      if (defaultPick) {
        for (let i = 0; i < options.length; i++) {
          if (options[i].pickName === defaultPick) {
            return options[i].pickShow
          }
        }
      }

      return null
    }
    
    if (defaultPick || defaultPick === null) {
      setPick(setInitialPick())
    }
  }, [defaultPick, options])

  return (
    <DropdownLayout
      marginTop={marginTop}
      width={width}
      height={height}
      className={className}
    >
      {
        floatingText
          &&
            <FloatingText
              fontColor={fontColor}
              top={generateTop(height)}
            >
              {floatingText}
            </FloatingText>
      }
      {
        !pick && <DropdownLabel pick={pick}>
          {label}
        </DropdownLabel>
      }
      {
        pick
          && (
            <>
              <DropdownText 
                currentSelect
                width={width}
              >
                {pick}
              </DropdownText>
            </>
          )
      }
      <DropdownBox
      initialBoxColor={initialBoxColor}
      ref={containerRef}
        onClick={() => {
          setDropdown(!dropdown)
        }}
      >
        <DropdownIcon
          dropdown={dropdown}
          fill="black"
          type={iconType}
        />
        {
          dropdown
            &&
              <DropdownList
                containerRef={containerRef}
                height={height}
                width={width}
                options={options}
                dropdown={dropdown}
                setPick={setPick}
                setDropdown={setDropdown}
                setToggle={setToggle}
                backgroundColor={backgroundColor}
                fontColor={fontColor}
                position={position}
                heightOptions={heightOptions}
              />
        }
      </DropdownBox>
    </DropdownLayout>
  )
}

export default Drowdown
