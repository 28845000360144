import {
  io,
} from 'socket.io-client';

import {
  SOCKET_URL,
} from '../constants/Server/base';

import cookies from './cookies'

import store from '../store'

import {
  addChatRoom,
  setChatRoom,
  setChatRooms,
  disconnectChatSocket,
  appendMessages,
  updateRoomRecipients,
} from '../store/actions/socket'

// will notify when reload / close button click while drawing
window.addEventListener('beforeunload', async function (event) {
  event.preventDefault()

  const { socket } = store.getState()
  
  if (socket.chatSocket) {
    event.returnValue = false
    
    socket.chatSocket.disconnect()

    store.dispatch(
      disconnectChatSocket()
    )
  }
})

const CONFIG = {
  // path: (process.env.NODE_ENV != 'production' ? '/_dev' : '') + '/socket.io',
  path: '/socket.io',
  timeout: 2000,
  reconnectionAttempts: 3,
};

class SocketClient {
  constructor(client=null) {
    this.client = client;
  }

  disconnect() {
    if (!this.client) return;
    this.client.disconnect();
    // this.client = null;
  }

  on([...args]) {
    // console.log('ye', args);
    return this.client.on(...args);
  }

  emit(eventName, payload=null, ack) {
    if (!this.client) return;
    return this.client.emit(
      eventName,
      {
        ...payload,
        token: cookies.get('accessToken'),
      },
      ack,
    );
  }

  static connect(namespace = null) {
    let socketNamespace = '/';
    switch(namespace) {
      case 'user':
      case 'USER':
        socketNamespace = '/chat-user';
        break;
      case 'chat':
      case 'CHAT':
        socketNamespace = '/chat-message';
        break;
      default:
        socketNamespace = '/';
    }
    let socketURL = SOCKET_URL + socketNamespace;
    let socket = io(socketURL, CONFIG);
    return socket
  }

  static connectToNameSpace(socket, { token, cb }) {
    socket.on('connect', () => {
      // console.log('connected to namespace');

      let event = "user-online"
      const socketToken = token || cookies.get('accessToken');
      socket.emit(
        event,
        {
          token: socketToken,
        },
        (ack) => {
          // console.log({ emit: event, acknowledgement: ack })
          if(cb) {
            cb(ack)
          }
        }
      );
    });
  }

  static test(socket) {
    socket.emit("test", {
      token: cookies.get('accessToken'),
    })
    socket.on("test", (data) => {
      // console.log({ on: "test", data })
    });
  }

  static connectNewUserMessage(socket) {
    socket.on('new-user-message', (data) => {
      // console.log({ on: "new-user-message", data })
      data.messages = [data.message]
      store.dispatch(
        addChatRoom({
          chatRoom: data
        })
      )
      
      const username = store.getState().user.username
      const owner = data.owner.username

      if (username === owner) {
        store.dispatch(
          setChatRoom({
            chatRoom: data
          })
        )
      }
    });
  }

  static connectRoomByMessageId(socket, messageId) {
    socket.emit(
      'listen-message',
      {
        token: cookies.get('accessToken'),
        'message_id': messageId,
      },
      (ack) => {
        if (ack) {
          // console.log({ emit: "listen-message", messageId, ack })
        }
      },
    );
  }

  static appendRoomMessage(socket, cb) {
    socket.on('append-message', cb)
  }

  static submitRoomMessage(socket, {
    messageId,
    body,
    token,
    cb
  }) {
    socket.emit(
      'append-message',
      {
        token: token || cookies.get('accessToken'),
        'message_id': messageId,
        body
      },
      (ack) => {
        // console.log({ emit: "append-message", ack })
        cb(ack)
      },
    )
  }

  static createNewMessage(socket, {
    recipients,
    message,
    title,
    token,
    cb
  }) {
    const payload = {
      token: token || cookies.get('accessToken'),
      recipients: recipients.map(user => user.user_id),
      body: message,
      title
    }
    socket.emit(
      'new-user-message',
      payload,
      (ack) => {
        // console.log({ emit: "new-user-message", ack })
        cb(ack)
      }
    );
  }

  static removeUser(socket, {
    messageId,
    recipient,
    token,
    cb,
  }) {
    const recipientId = recipient.id || recipient.user_id

    const payload = {
      token: token || cookies.get('accessToken'),
      message_id: messageId,
      recipients: [recipientId]
    }

    socket.emit(
      'remove-recipient',
      payload,
      (ack) => {
        // console.log({ emit: 'remove-recipient', ack })
        if (ack) {
          // store.dispatch()
          cb(ack)
        }
      }
    )
  }

  // Diterima oleh owner
  static connectRemoveRecipient(socket, {
    setRecipients,
    backToChat
  }) {
    socket.on('remove-recipient', (data) => {
      // console.log({ on: 'remove-recipient', data })
      let myself = true
      
      setRecipients((recipients) => {
        let newRecipients = recipients.filter((recipient) => {
          const recipientId = recipient.id || recipient.user_id

          if (data.recipients.includes(recipientId)) {
            myself = false

            return false
          }

          return true
        })
        // newRecipients = { ...recipients }
        // data.forEach(recipient => {
        //   delete newRecipients[recipient]
        // })
        store.dispatch(updateRoomRecipients({
          recipients: newRecipients,
          id: data.message_id
        }))
        return newRecipients
      })

      if (myself) {
        backToChat()
      }
    })
  }
  
  // Diterima oleh member
  static listenRemoveUser(socket, { backToChat }) {
    socket.on('remove-user-message', (data) => {
      // const newChatRooms = store
      //   .getState().socket.chatRooms
      //   .filter((chatRoom) => {
      //     const roomId = chatRoom.id || chatRoom.message_id
      //     return roomId !== data
      //   })

      const newChatRoomsObj = { ...store.getState().socket.chatRoomsObj }
      delete newChatRoomsObj[data]

      // console.log({ on: "remove-user-message", newChatRoomsObj })

      store.dispatch(
        setChatRooms({
          // chatRooms: newChatRooms,
          chatRoomsObj: newChatRoomsObj
        })
      )
      if(backToChat) {
        backToChat()
      }
    })
  }

  // Dikirim oleh owner
  static addUser(socket, {
    messageId,
    recipient,
    cb
  }) {
    const recipientId = recipient.id || recipient.user_id

    let payload = {
      token: cookies.get('accessToken'),
      message_id: messageId,
      recipients: [recipientId]
    }

    socket.emit(
      'add-recipient',
      payload,
      (ack) => {
        // console.log({ emit: 'add-recipient', ack, payload })
        if (ack) {
          cb(ack)
        }
      }
    )
  }

  static connectAddRecipient(socket, {
    setRecipients
  }) {
    socket.on('add-recipient', (data) => {
      setRecipients((recipients) => {
        let newRecipients = [...recipients, ...data.recipients]
        newRecipients = newRecipients.filter((item, idx, arr) => {
          return arr.findIndex(r => r.id === item.id) === idx
        })

        // newRecipients = newRecipients.reduce((prev, cur) => {
        //   prev[cur.id] = cur
        //   return prev
        // }, {})
        // newRecipients = { ...newRecipients, ...data.recipients }

        // console.log({ on: 'add-recipient', newRecipients })
        return newRecipients
      })
    })
  }
}

export function listenMessage(data) {
  // console.log({ on: "append-message", data })
  store.dispatch(appendMessages({
    data,
    id: data.message_id
  }))
}

if(window.Cypress) {
  window.SocketClient = SocketClient
}

export default SocketClient;

// import { io } from 'socket.io-client'

// import {
//   setDrawSave
// } from '../store/actions/toolbar'

// import {
//   updateDrawData
// } from '../store/actions/cesium'

// class Socket {
//   constructor () {
//     // Connect to the Socket.IO server.
//     // The connection URL has the following format, relative to the current page:
//     //     http[s]://<domain>:<port>[/<namespace>]
//     this.socket = io.connect('http://localhost:5000')
//   }

//   listenDraw () {
//     // Event handler for new connections.
//     // The callback function is invoked when a connection with the
//     // server is established.
//     // socket.on('connection', function() {
//     //   socket.emit('my event', {data: 'I\'m connected!'})
//     // })

//     // Event handler for server sent data.
//     // The callback function is invoked whenever the server emits data
//     // to the client. The data is then displayed in the "Received"
//     // section of the page.
//     this.socket.on('listen_draw', function(payload) {
//       const updateStatus =  payload.updateStatus || null
//       let cesiumId = null
//       if (payload.data && payload.data.cesium_id) {
//         cesiumId = payload.data.cesium_id
//       }
//       const datum = payload.data
//       let geometry = null
//       if (payload.data && payload.data.geometry) {
//         geometry = JSON.parse(payload.data.geometry)
//       }
//       let type = null
//       if (payload.data && payload.data.shape_type) {
//         type = payload.data.shape_type
//       }
    
//       store.dispatch(
//         updateDrawData({
//           updateStatus,
//           cesiumId,
//           datum,
//           geometry,
//           type
//         })
//       )
//     })
//   }
// }

// export default Socket
