import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';
import _ from 'lodash';
import BoxPlot from '../Charts/BoxPlot';
import CorrelationMatrix from '../Charts/CorrelationMatrix';
import Histogram from '../Charts/Histogram';
import ScatterPlot from '../Charts/ScatterPlot';
import { connect } from 'react-redux';
import Ternary from '../Charts/Ternary';
import Som from '../Charts/SOM';

let ChartGroup=({...props})=>{
    let {
        dataReady,
        data,
        dataGroup,
        dropdown,
        dropdownGroup,
        initGroup,
        selectedScatter,
        setSelectedScatter,
        selectedMap,
        sidebarState,
        dataNonFilter,
        dataGroupNonFilter,
        dropdownNonFilter,
        dataLocal,
        idDataLocal,
        column,
        setLayoutCurrent,
        widthContainer,
        configurationChart,
        setConfigurationChart
    }=props;
    const ReactGridLayout = require('react-grid-layout');
     

    const showThisChart=({
        type,
        className,
        configChart,
        setConfigChart,
        height,
        width,
    })=>{
        if (type==="correlation"){
            return(
                <CorrelationMatrix
                    data={dataGroup}
                    dataSample={data}
                    dataReady={dataReady}
                    className={className}
                    config={configChart}
                    setConfig={setConfigChart}
                    heightContainer ={height}
                    widthContainer={width}
                    dropdown={dropdownGroup}
                    init={initGroup}
                    dataLocal={dataLocal}
                />
            )
        } else if (type==="histogram"){
            return(
                <Histogram
                    data={dataGroup}
                    dataReady={dataReady}
                    dropdown={dropdown}
                    className={className}
                    config={configChart}
                    setConfig={setConfigChart}
                    heightContainer ={height}
                    widthContainer={width}
                    init={initGroup}
                    dataLocal={dataLocal}
                />
            )
        } else if(type==="boxplot"){
            return(
                <BoxPlot 
                    data={dataGroup}
                    dataReady={dataReady}
                    className={className}
                    config={configChart}
                    setConfig={setConfigChart}
                    heightContainer ={height}
                    widthContainer={width}
                    dropdown={dropdownGroup}
                    init={initGroup}
                    dataLocal={dataLocal}
                    selectedMap={selectedMap}
                />
            )
        } else if(type==="scatterplot"){
            return(
                <ScatterPlot
                    dataReady={dataReady}
                    dropdown={dropdownNonFilter}
                    className={className}
                    config={configChart}
                    setConfig={setConfigChart}
                    widthContainer={width}
                    heightContainer={height}
                    selectedMap={selectedMap}
                    init={initGroup}
                    selected={selectedScatter}
                    setSelected={setSelectedScatter}
                    dataFilter={data}
                    dataNonFilter={dataNonFilter}
                    dataFilterGroup={dataGroup}
                    dataNonFilterGroup={dataGroupNonFilter}
                    dataLocal={dataLocal}
                    idDataLocal={idDataLocal}
                />
            )
        } else if(type==="ternary"){
            return(
                <Ternary
                    dataReady= {dataReady}
                    dropdown={dropdownNonFilter}
                    className = {className}
                    data = {data}
                    config = {configChart}
                    setConfig = {setConfigChart}
                    widthContainer={width}
                    heightContainer={height}
                />
            )
        } else if(type="som"){
            return (
                <Som
                    data={data}
                    className={className}
                    config={configChart}
                    setConfig={setConfigChart}
                    dataReady={dataReady}
                    widthContainer={width}
                    heightContainer={height}
                />
            )

        }else{
            return(<></>)
        }
    }

    const generateGroup=()=>{
        // if(!dataReady) return;
        const config = configurationChart
        return _.map(config, function (l,index) {
            const setConfigChart=(element)=>{
                let newConfig = [...config]
                newConfig[index].config=element;
                setConfigurationChart(newConfig)
            }
            
            return (
                <div key={l.className} data-grid={l.layout}>
                    {showThisChart({
                        type: l.type,
                        className: l.className, 
                        configChart: l.config,
                        height : l.height,
                        width: l.width,
                        setConfigChart: setConfigChart
                    })}
                </div>
            );
            // }
        });
    }


    return<>
        <ReactGridLayout 
            className="dashboard"
            rowHeight= {25}
            cols={column}
            containerPadding={[3,3]}
            width={widthContainer}
            margin={[5,5]}
            preventCollision={false}
            useCSSTransforms={false}
            isDraggable
            isResizable
            isRearrangeable
            draggableHandle='.header-plot'
            draggableCancel='.noDrag'
            onLayoutChange= {(lay)=>{
                setLayoutCurrent(lay)
            }}
            style={{height:"100%", marginBottom:"1em", width:"calc(100% - 15px)"}}
        >
            {generateGroup()}
                                
        </ReactGridLayout>
    </>
    
}
function mapStateToProps({ sidebarState }) {
    return {
        sidebarState
    }
}

export default connect(mapStateToProps, null)(ChartGroup)