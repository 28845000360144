import React, { useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';

import { 
    ButtonSubmitCustom, 
    ChartAnalysis, 
    HeaderChart, 
    ListCheckbox, 
    ShowCustomSelect, 
    StyleCheckboxSelect, 
    TitleChart, 
    WrapperChartSvg,
    WrapperManySvg,
    WrapperSpinner,
    WrapperSpinnerBackground
} from '../helpers/AnalysisStyle';

import "./boxPlot.css";
import { 
    createBoxPlotAll, 
    createBoxPlotSeries, 
    seriesAlteration, 
    seriesLithology 
} from './helpers';
import Checkbox from '../../Checkbox';
import { capitalizeElement } from '../helpers/capitalizeFirstLetter';
import { getCheckboxValues, toggleCheck } from '../helpers/checkboxElements';
import { Puff } from 'react-loading-icons'
import { dropdownGroupElemSelect } from '../helpers/dropdownElements';
import { adjustMarginLeftCharts } from '../helpers/adjustMarginLeftCharts';


/**
 * 
 * @param {Object} data 
 * @param {array} dropdown
 * @param {boolean} isLoading
 * @param {string} className
 * @param {Object} config
 * @param {function} setConfig
 * @param {handlerClick} onDelete
 * @param {handlerClick} onMinimize
 * @param {handlerClick} openSettings 
 * To make boxplot with config: 
 *  {
 *      type: series 
 *      seriesSelect: [elements]
 *      color:
 *      checked:{}
 *      defaultPickDropDown:''
 *      customSelect:''
 *      
 *      type: domain
 *      domainSelect: alteration/lithology
 *      domainSeriesSelect: element
 *      color:
 *  }
 * @returns 
 */



let BoxPlot=({...props})=>{
    let{
        data,
        dataReady,
        className,
        config,
        setConfig,
        heightContainer,
        widthContainer,
        dropdown,
        init,
        dataLocal
    } = props;

    const refBoxPlot = useRef (null);
    const nameCheckbox=className+'checkboxBoxPlot';
    const [loading, setLoading] = useState (true)
    const [toggleSubmitButton, setToggleSubmitButton] = useState (true)
    const [valDD, setValDD] = useState()

    // if(!dataReady){
    //     setLoading(true)
    // }

    const [configBoxPlot, setConfigBoxPlot]=useState({
        seriesSelect:[],
        checked:{},
        defaultPickDropDown:'',
        customSelect:'',
        color:d3.schemeCategory10,
    });

    const handleDropdownSeries=(value)=>{
        if(value.value==="Custom"){
            let newValue={...configBoxPlot};
            if(newValue.defaultPickDropDown==="Custom"){
                setToggleSubmitButton(true)
            } else {
                let checkboxValues=[];
                let checkboxTitle=[];
                var checks = document.querySelectorAll(`input[name=${nameCheckbox}]:checked`);
                checks.forEach((check)=>{
                checkboxValues.push(check.value);
                checkboxTitle.push(check.title)
                });
                newValue.seriesSelect=checkboxValues;
                newValue.customSelect=`(${checkboxTitle})`;
                newValue.defaultPickDropDown="Custom";
            }
            setConfigBoxPlot(newValue);
            setValDD(value)
        }else{
            let newValue=value.value.split(",")
            let newConfig={...configBoxPlot};
            newConfig.seriesSelect=newValue;
            newConfig.defaultPickDropDown=newValue.toString();
            setConfigBoxPlot(newConfig);
            setValDD(value)
        }
    }

    useEffect(()=>{
        let newConfig={...configBoxPlot}
        Object.entries(config).forEach(([key,value])=>{
            if(key in newConfig){
                newConfig[key]=value;
            }
        })
        setConfigBoxPlot(newConfig)
        
        if(config.defaultPickDropDown!==''){
            dropdown.map((d)=>{
                if(d.value===config.defaultPickDropDown){
                    setValDD(d)
                }
            })
        }
    },[])

    useEffect(()=>{
        if(config.seriesSelect.length===0 || data[config.seriesSelect[0]]===undefined){
            let newConfig={...configBoxPlot}
            newConfig.seriesSelect=init
            if(dropdown.length!==0){
                newConfig.defaultPickDropDown=dropdown[0].value
                setValDD(dropdown[0])
            }
            setConfigBoxPlot(newConfig)
        }
    },[init,dropdown])

    useEffect(()=>{
        if (dataReady===false){
            setLoading(true)
            return
        };

        d3.selectAll("."+className)
            .style("opacity",1)
            .transition()
            .duration(700)
            .style("opacity",0)
            .remove();
        
        d3.select('.boxplot-container'+className)
            .selectAll(".tooltip-boxplot")
            .remove();

        const configViewBox = {
            minx: 0,
            miny: 0,
            width: widthContainer,
            height: heightContainer,
        }
        let marginLeft = 40
        if(configBoxPlot.seriesSelect.length!==0){
            marginLeft = adjustMarginLeftCharts({
                selected: configBoxPlot.seriesSelect,
                marginLeftInit: 40
            })
        }

        //make canvas
        const margin = {top: 20, right: 20, bottom: 20, left: marginLeft},
        width = configViewBox.width - margin.left - margin.right,
        height = configViewBox.height - margin.top - margin.bottom;

        const svg = d3
        .select(refBoxPlot.current)
        .attr("viewBox", `${configViewBox.minx} ${configViewBox.miny} ${configViewBox.width} ${configViewBox.height}`)
        .append("g")
        .attr("class",className)
        .attr("transform", `translate(${margin.left}, ${margin.top})`);

        d3.select("."+className+"domain").style("visibility","hidden");
        createBoxPlotSeries({
            data: data,
            svg: svg,
            height: height,
            width: width,
            className: className,
            color: configBoxPlot.color,
            series: configBoxPlot.seriesSelect,
            dataLocal: dataLocal
        });
        setLoading(false)
    },[
        data,
        configBoxPlot.seriesSelect,
        configBoxPlot.color,
        heightContainer,
        widthContainer,
        dataReady, 
        className
    ]);
    useEffect(()=>{
        if(configBoxPlot.defaultPickDropDown !=="Custom"){
            setToggleSubmitButton(true)
        }
    },[configBoxPlot.defaultPickDropDown])

    useEffect(()=>{
        setConfig(configBoxPlot)
    },[configBoxPlot])

    const classNameAll="boxplot-container"+className;

    const resetCheckboxValues=()=>{
        setToggleSubmitButton(true)
    }

    const checkboxCustom = () => {
        const col1 = [], col2 = [], col3 = [], col4=[]
        Object.keys(data).map((item,i)=>{
            if(i<Object.keys(data).length/4){
                col1.push(item)
            } else if (i>=Object.keys(data).length/4 && i < Object.keys(data).length /2){
                col2.push(item)
            } else if (i>=Object.keys(data).length/2 && i < Object.keys(data).length*3 /4){
                col3.push(item)
            } else {
                col4.push(item)
            }
        })
        const mapColumn=[col1,col2,col3,col4]
        return(
            <div style={{display:'flex', justifyContent:'space-evenly'}}>
                {
                    mapColumn.map((col,i)=>
                    <div>
                        {
                            col.map((item,idx)=>
                                <ListCheckbox key={`${item}-checkCorreMat`}>
                                    <Checkbox
                                        key={`${idx}-checkCorreMat`} 
                                        type="checkbox"
                                        className="checkSelector"
                                        id="checkboxCustomSelector"
                                        name={nameCheckbox}
                                        onChange={() => toggleCheck({
                                            inputName: item,
                                            nameCheckbox: nameCheckbox,
                                            config: configBoxPlot,
                                            setConfig: setConfigBoxPlot
                                        })}
                                        checked={configBoxPlot.checked[item]}
                                        value={item}
                                        title={ dataLocal ? item : capitalizeElement(item.slice(0,item.lastIndexOf("_")))}
                                        />
                                    {dataLocal? item : capitalizeElement(item.slice(0,item.lastIndexOf("_")))}
                                </ListCheckbox>
                            )
                        }
                    </div>
                    )
                }
            </div>
        )
    }
    


    return (
        <ChartAnalysis className={classNameAll}>
            
            <TitleChart className="header-plot">
            Box Plot
            </TitleChart>

            <HeaderChart left className="header-plot" id='headerBoxPlot'>
                {dropdownGroupElemSelect({
                    dropdownOptions:dropdown,
                    handle:handleDropdownSeries,
                    valDD:valDD
                })}
                {
                    configBoxPlot.defaultPickDropDown==="Custom" 
                    ?
                    <>
                    <ShowCustomSelect className="col noDrag">
                        {configBoxPlot.customSelect}
                    </ShowCustomSelect>
                    <div style={{marginLeft:'0.5em'}}>
                    {
                        toggleSubmitButton ?
                        <ButtonSubmitCustom 
                            className="buttonSubmitBoxPlot col noDrag"
                            title="Create Box Plot"
                            id="buttonSubmitBoxPlot"
                            type="submit"
                            onClick={()=>getCheckboxValues({
                                nameCheckbox: nameCheckbox,
                                config:configBoxPlot,
                                setConfig: setConfigBoxPlot,
                                setToggle: setToggleSubmitButton
                            })}
                        >
                            Create
                        </ButtonSubmitCustom>
                        :
                        <ButtonSubmitCustom 
                            title="Reset Custom Box Plot"
                            className="buttonResetBoxPlot col noDrag"
                            id="buttonResetBoxPlot"
                            type="reset"
                            onClick={()=>resetCheckboxValues()}
                        >
                            Reset
                        </ButtonSubmitCustom>
                    }
                    </div>
                </>
                :
                <></>
                }
            </HeaderChart>

            {loading?
            <WrapperSpinnerBackground>
                <WrapperSpinner>
                    <Puff
                    height={heightContainer/2}
                    width={widthContainer/2}
                    fill='goldenrod'
                    stroke="darkgoldenrod"
                    speed="1"
                    fillOpacity="0.5"
                    strokeOpacity="0.5"
                    strokeWidth="2"
                />
                </WrapperSpinner>
            </WrapperSpinnerBackground>
            :
            <></>
            }
            <WrapperChartSvg>
                <WrapperManySvg>
                    <svg 
                        className="chart-plot"
                        ref={refBoxPlot}
                    />
                </WrapperManySvg>
            </WrapperChartSvg>
            {
                configBoxPlot.defaultPickDropDown==="Custom" && toggleSubmitButton 
                ?
                <StyleCheckboxSelect>
                {
                    checkboxCustom()
                }
                </StyleCheckboxSelect>
                :
                <></>
            }
        </ChartAnalysis>
    )
}

export default BoxPlot;