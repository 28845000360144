import Papa from 'papaparse';

export const parseFile = (file, data, setData, type, subtype) => {
    Papa.parse(file, {
        header: true,
        complete: results => {
            let dat={...data}
            if(type==="Drillhole"){
                dat[subtype].data = results.data
            } else {
                dat.data = results.data
            }
            let header=[]
            results.data.map((i)=>{
                Object.keys(i).map((j)=>{
                    if(header.indexOf(j) === -1){
                        header.push(j)
                    }
                })
            })
            dat.header = header
            setData(dat)
        }
    })
}