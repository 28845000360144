import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'

import axiosReact from '../../../../apis/axiosReact'

import axios from 'axios'

import { COMPOSITE_REST_API } from '../../../../constants/Server/search'

import ReactLoading from 'react-loading'

import styled from 'styled-components'

import { ReactComponent as RightIcon } from '../../../../images/Icons/right.svg'
import { ReactComponent as LeftIcon } from '../../../../images/Icons/left.svg'

const TextContainer = styled.div`
  font-family: Abel;
  color: white;
`

const TextSearchResult = styled.div`
  color: #bababaf2;
  margin-left: 0.8em;
  font-size: 0.7em;
`

const TextSmallHeader = styled(TextContainer)`
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  padding-left: 1em;
  font-size: 0.7em;
  font-weight: 600;
  cursor: default;
`

const TextResultSearch = styled(TextContainer)`
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  padding-left: 1em;
  font-size: 0.85em;
  cursor: pointer;
  transition: background-color 330ms;
  background-color:${props => (props.selected ? '#eaeaea' : 'rgb(17,17,17)')};
  color:${props => (props.selected ? 'black' : 'white')};
`

const PaginationContainer = styled(TextContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.5em;
  margin-top: 1em;
  margin-bottom: 1em;
`

const NavigationButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 2px solid ${props => (props.selected ? 'goldenrod' : 'white')};
  width: 1.5em;
  height: 1.5em;
  font-size: 0.8em;
  margin-left: 0.2em;
  margin-right: 0.2em;
  color: ${props => (props.selected ? 'goldenrod' : 'white')};
  cursor: pointer;
  
   &:hover {
    color: goldenrod;
    border-color: goldenrod;
  }
`

function SearchRecommendation({
  parentRef,
  loading,
  data,
  keyword,
  selectHandler,
  generateDataSource,
  selected,
  setSelected,
  total,
  currentPage,
  totalPage,
  findPageProcessHandler
}) {
  let source = null

  const [ width, setWidth ] = useState(0)
  const [ height, setHeight ] = useState('0em')
  const [ dataFound, setDataFound ] = useState(null)

  useEffect(() => {
    if (parentRef.current) {
      setWidth(parentRef.current.getBoundingClientRect().width-20)
    }
  }, [parentRef])

  useEffect(() => {
    if (loading) {
      setHeight('2em')
    } else if (!checkData()) {
      setHeight('2em')
      setDataFound(false)
    } else {
      setHeight('auto')
      setDataFound(true)
    }
  }, [loading, data])

  function checkData() {
    for (let key in data) {
      if (data[key].length) {
        return true
      }
    }

    return false
  }

  async function fetchGeojson({
    name,
    alias,
    detail
  }) {
    try {
      if (source) {
        source.cancel('')
      }

      source = axios.CancelToken.source()

      let { data } = await axiosReact.get(`/geofix/${COMPOSITE_REST_API[alias]}`, {
        params: {
          composite_name: `${name}:${detail}`
        },
        cancelToken: source.token
      })

      if (data instanceof Array) {
        data = data.reduce((previous, current) => {
          if (current.geojson) {
            previous.features.push(...current.geojson.features)
          }

          return previous
        }, {
          features: [],
          type: 'FeatureCollection'
        })
      }

      if (data) {
        await generateDataSource(data)
      }
    } catch (error) {
      throw error
    }
  }

  function refineText(alias) {
    if (!alias) {
      return alias
    }

    const cleanAlias = alias.split('_').join(' ').trim()

    return cleanAlias[0].toUpperCase() + cleanAlias.slice(1).toLowerCase()
  }

  async function onClickHandler({ name, alias, detail }) {
    let search = ''
    setSelected(name)

    selectHandler({
      newSelectedKeyword: `[${alias}]: ${name}`,
      alias,
      search
    })

    await fetchGeojson({
      name,
      alias,
      detail
    })
  }

  function generatePaginations() {
    const paginations = []

    let i = currentPage - 2

    if (i < 1) {
      i = 1
    }

    while (paginations.length < 5 && i <= totalPage) {
      paginations.push(i)

      i += 1
    }

    let j = paginations[0] - 1

    while (paginations.length < 5 && j > 1) {
      paginations.unshift(j)

      j -= 1
    }

    return paginations
  }

  return (
  <>
      <div style={{
        width,
        height,
        display: loading && 'flex',
        justifyContent: loading || !dataFound ? 'center' : 'flex-start',
        alignItems: loading || !dataFound ? 'center' : 'flex-start'
      }}>
        {
          loading && <ReactLoading color="white" height="1em" width="1em" type="spin"/>
        }
        {
          !loading && dataFound === false && keyword!=='' && <TextContainer>Data not found</TextContainer>
        }
        {
          !loading && dataFound === true && <>
            <TextSearchResult>
              {`The total result found ${total}, with total page ${totalPage}`}
            </TextSearchResult>
          </>
        }
        {
          !loading && dataFound === true && <PaginationContainer>
            {
              currentPage > 1 && <>
                <LeftIcon
                  className="close-menubar-toggle"
                  style={{
                    width: '2em',
                    height: '0.8em'
                  }}
                  onClick={() => {
                    findPageProcessHandler(currentPage - 1)
                  }}
                />
              </>
            }
            {
              generatePaginations().map((numberPage) => {
                return <NavigationButton
                  key={numberPage + '-page'}
                  selected={numberPage === currentPage}
                  onClick={() => {
                    findPageProcessHandler(numberPage)
                  }}
                >
                  {numberPage}
                </NavigationButton>
              })
            }
            {
              currentPage < totalPage && <>
                <RightIcon
                  className="close-menubar-toggle"
                  style={{
                    width: '2em',
                    height: '0.8em'
                  }}
                  onClick={() => {
                    findPageProcessHandler(currentPage + 1)
                  }}
                />
              </>
            }
          </PaginationContainer>
        }
        {
          !loading && dataFound === true && Object.keys(data).map((alias, index) => {
            if (!data[alias].length) {
              return null
            }

            return <div key={alias + index} style={{ marginTop: '0.2em' }}>
              <TextSmallHeader>
                {refineText(alias)}
              </TextSmallHeader>
              {
                data[alias].map((dataInfo, index) => {
                  let text = dataInfo.name

                  if (alias === 'location') {
                    text = `${text} - ${refineText(dataInfo.detail)}`
                  }

                  return <TextResultSearch
                    key={text + index}
                    selected={dataInfo.name === selected}
                    style={{
                      width: width - 30
                    }}
                    onClick={() => {
                      onClickHandler(dataInfo)
                    }}
                  >
                    {text}
                  </TextResultSearch>
                })
              }
            </div>
          })
        }
      </div>
    </>
    )
}

function mapStateToProps({ advancedSearch }) {
  return {
    total: advancedSearch.total,
    offset: advancedSearch.offset,
    currentPage: advancedSearch.currentPage,
    totalPage: advancedSearch.totalPage
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SearchRecommendation)
