export const CHANGE_TYPE = 'CHANGE_TYPE'
export const TOGGLE_ANALYSIS = 'TOGGLE_ANALYSIS'
export const TOGGLE_PREVIEW_TABLE = 'TOGGLE_PREVIEW_TABLE'
export const CHANGE_DATA_IMPORT = 'CHANGE_DATA_IMPORT'
export const CHANGE_VALUES_HEADER = 'CHANGE_VALUES_HEADER'

export const IMPORT_TYPE = 'IMPORT_TYPE'
export const PROJECT_NAME = 'PROJECT_NAME'
export const NAME_TABLE = 'NAME_TABLE'

export const UTM_ZONE = 'UTM_ZONE'
export const CONFIG_CHART = 'CONFIG_CHART'
export const FILTER_VALUE = 'FILTER_VALUE'
export const AUTOCHECK_SURFACE = 'AUTOCHECK_SURFACE'