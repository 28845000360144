export const tenements_commodity = {
  "copper_gold_silver": [
    "BIJIH EMAS",
    "EMAS",
    "EMAS DMP",
    "EMAS,TEMBAGA,LDS",
    "MINERAL LOGAM / EMAS",
    "TEMBAGA",
    "TEMBAGA DMP",
  ],
  "nickel": [
    "BIJIH NIKEL",
    "MINERAL LOGAM NIKEL",
    "NIKEL",
    "NIKEL DMP",
    "PERIDOTIT",
  ],
  "bauxite": [
    "BAUKSIT",
    "BAUKSIT DMP",
    "LATERIT",
  ],
  "coal": [
    "BATUBARA",
  ],
  "lead_zinc": [
    "GALENA",
    "GALENA DMP",
    "TIMBAL",
    "TIMBAL DAN SENG",
  ],
  "manganese": [
    "MANGAAN",
    "MANGAN",
  ],
  "tin": [
    "BIJIH TIMAH",
    "MINERAL LOGAM (TIMAH)",
    "PASIR TIMAH",
    "TIMAH",
    "TIMAH DMP",
    "TIMAH HITAM",
    "TIMAH LAUT",
    "TIMAH PUTIH",
  ],
  "iron": [
    "BATU BESI",
    "BESI",
    "BIJIH BESI",
    "BIJIH BESI DMP",
    "LATERIT BESI",
    "PASIR BESI",
    "PASIR BESI DMP",
  ],
}
