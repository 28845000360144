export default function getHeightUnit(size) {
  let height = 0
  let unit = 'px'
  let i = size.length - 1

  while (i >= 0) {
    if (Number(size.slice(0, i))) {
      height = Number(size.slice(0, i))
      unit = size.slice(i, size.length)
    }

    i -= 1
  }

  return {
    height,
    unit
  }
}