import React, { useState } from 'react'

import cesiumViewer from '../../../../../../apis/cesiumViewer'

import AlphaSlider from '../../../../../Sliders/AlphaSlider'

import FlexWrapper from '../../../shares/FlexWrapper'
import ToggleIcon from '../../../shares/ToggleIcon'
import ToolbarText from '../../../shares/ToolbarText'

import Eye from '../Menu/Eye'
import SceneMode from './SceneMode'
import ImageryPicker from './ImageryPicker'

function GlobeContent() {
  const [ globeShow, setGlobeShow ] = useState(true)

  return <FlexWrapper
    center
    marginLeft="0em"
    no-overflow
    minHeight="1.6em"
  >
    <ToggleIcon
      toggle={false}
      toggleAble={false}
      size="smaller"
    />
    <Eye
      isActive={globeShow}
      onClickHandler={() => {
        if (!cesiumViewer.viewModel.show) {
          setGlobeShow(true)
          cesiumViewer.viewModel.show = true
        } else {
          setGlobeShow(false)
          cesiumViewer.viewModel.show = false
        }
      }}
    />
    <ToolbarText
      width="9em"
      text="Globe"
      max={18}
    />
    <AlphaSlider
      height="0.5em"
      width="2.5em"
      min={0}
      max={1}
      initialValue={cesiumViewer.viewModel.alpha}
      valueOnChange={(newValue) => {
        cesiumViewer.viewModel.alpha = newValue
      }}
    />
    <SceneMode />
    <ImageryPicker />
  </FlexWrapper>
}

export default GlobeContent
