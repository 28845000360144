import store from '../store'

import {
  POLYLINE_POINT
} from '../constants/Menubars/Toolbar'

import {
  startEditDrawAPI,
  editDrawAPI
} from './server'

import {
  addPolylineEntity,
  setPointSelected,
  removePointSelected,
  addActivePolylineIndex,
  updatePolylineHistories,
  updateActiveShapePoints,
  groupEntity
} from '../store/actions/toolbar'

import {
  selectSite
} from '../store/actions/sites'

import * as Cesium from 'cesium'
import cesiumViewer from './cesiumViewer'

import rectangleBlue from '../images/Icons/rectangleBlue.svg'
import rectangleRed from '../images/Icons/rectangleRed.svg'

export function clearDataSources ({
  dataSources
}) {
  dataSources.entities.removeAll()
}

export function clearDataSourcesByReference ({
  dataSources,
  key,
  value
}) {
  for (let i = 0; i < dataSources.entities.length; i++) {
    const property = dataSources.entities[i].properties.getValue('')

    if (property[key] === value) {
      dataSources.entities.remove(dataSources.entities[i])
    }
  }
}

export function boxPolyline ({
  id,
  color,
  positions,
  properties
}) {
  if (!id) {
    return {
      name: POLYLINE_POINT,
      polyline: {
        show: true,
        width: 3,
        material: color,
        clampToGround: true,
        positions
      },
      properties
    }
  } else {
    return {
      id,
      name: POLYLINE_POINT,
      polyline: {
        show: true,
        width: 3,
        material: color,
        clampToGround: true,
        positions
      },
      properties
    }
  }
}

export function generateFunctionHandler ({
  camera,
  handler,
  scene,
  viewer
}) {
  function boxRectangle ({
    index
  }) {
    return {
      name: POLYLINE_POINT,
      billboard: {
        image: rectangleBlue,
        scale: 0.5,
        heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
      },
      position: new Cesium.CallbackProperty(function () {
        const { pointHistories, active } = store.getState().toolbar
        if (!pointHistories[active]) {
          return null
        } else if (!pointHistories[active][index]) {
          return null
        } else {
          return pointHistories[active][index]
        }
      }, false)
    }
  }

  handler.setInputAction(async function (movement) {
    const pickedObject = scene.pick(movement.position)

    const { toolbar } = store.getState()

    if (toolbar.selector === 'select') {
      if (
        Cesium.defined(pickedObject)
        && pickedObject.id
        && pickedObject.id.name === POLYLINE_POINT
      ) {
        store.dispatch(groupEntity({
          entity: pickedObject.id
        }))
      }
    }
    else if (toolbar.selector === 'drawEdit') {
      if (
        Cesium.defined(pickedObject)
        && pickedObject.id
        && pickedObject.id.name === POLYLINE_POINT
      ) {
        if (
          toolbar.entity
          && toolbar.entity.id === pickedObject.id.id
        ) {
          return
        }

        const activeShapePoints = pickedObject
          .id.polyline.positions.getValue('')

        const {
          type
        } = pickedObject.id.properties.getValue('')

        try {
          await startEditDrawAPI({
            type: 'LINE',
            positions: positionsCartesianToCartographic({
              positions: activeShapePoints
            }),
            properties: {
              type
            },
            cesiumId: pickedObject.id.id,
            status: 'EDIT'
          })

          const pointDataSource = new Cesium.CustomDataSource('PointDataSource')

          const pointHistories = []

          for (let i = 0; i < activeShapePoints.length; i++) {
            pointDataSource.entities.add(boxRectangle({
              index: i
            }))

            pointHistories.push(activeShapePoints.slice(0, i + 1))
          }

          viewer.dataSources.add(pointDataSource)

          store.dispatch(addPolylineEntity({
            selector: 'drawPolyline',
            activeShapePoints,
            entity: pickedObject.id,
            pointDataSource,
            active: activeShapePoints.length - 1,
            histories: pointHistories,
            pointHistories: pointHistories,
            type
          }))
          
          pickedObject.id.polyline.positions = getActiveShapePoints()
        } catch (error) {
          throw error
        }
      }
    } else if (toolbar.selector === 'drawPolyline') {
      const {
        catalog
      } = store.getState()

      if (
        Cesium.defined(pickedObject)
        && pickedObject.id
        && pickedObject.id.name === POLYLINE_POINT
      ) {
        return
      }

      if (!catalog.draw.dataSource) {
        return
      }

      if (!toolbar.entity) {
        let color = null

        if (toolbar.type === 'ridge') {
          color = Cesium.Color.YELLOW
        } else if (toolbar.type === 'spur') {
          color = Cesium.Color.BLUE
        }

        const ray = scene.camera.getPickRay(
          movement.position
        )
        const position = scene.globe.pick(ray, scene)

        const pointDataSource = new Cesium.CustomDataSource('PointDataSource')
        const activeShapePoints = [ position ]

        const entity = catalog.draw.dataSource.entities.add(boxPolyline({
          color,
          positions: getActiveShapePoints(),
          properties: {
            type: toolbar.type
          }
        }))

        pointDataSource.entities.add(boxRectangle({
          index: 0
        }))

        viewer.dataSources.add(pointDataSource)

        store.dispatch(addPolylineEntity({
          activeShapePoints,
          entity,
          pointDataSource
        }))
        
        store.dispatch(addActivePolylineIndex())
        store.dispatch(updatePolylineHistories({
          data: [ position ],
          dataPoint: [ position ]
        }))
      } else {
        const ray = scene.camera.getPickRay(
          movement.position
        )

        const position = scene.globe.pick(ray, scene)

        const newShapePoints = [
          ...toolbar.activeShapePoints,
          position
        ]
        let newDataPoints = []
        if (toolbar.pointHistories[toolbar.active]) {
          newDataPoints = [
            ...toolbar.pointHistories[toolbar.active],
            position
          ]
        } else {
          newDataPoints = [ position ]
        }
        
        store.dispatch(updateActiveShapePoints({
          activeShapePoints: newShapePoints
        }))

        toolbar.pointDataSource.entities.add(boxRectangle({
          index: toolbar.pointDataSource.entities.values.length
        }))

        store.dispatch(addActivePolylineIndex())
        store.dispatch(updatePolylineHistories({
          data: newShapePoints,
          dataPoint: newDataPoints
        }))

        if (toolbar.selector === 'drawPolyline') {
          editDrawAPI({
            type: 'LINE',
            positions: positionsCartesianToCartographic({
              positions: newShapePoints
            }),
            properties: {
              type: toolbar.type
            },
            cesiumId: toolbar.entity.id,
            status: 'EDIT'
          })
        }
      }
      
      return
    }
    
    if (
      Cesium.defined(pickedObject)
      && pickedObject.primitive instanceof Cesium.Billboard
      && pickedObject.id
      && pickedObject.id.properties
    ) {
      if (pickedObject.id.name && pickedObject.id.name.includes('GPR')) {
        const area = pickedObject.id.properties.getValue('')
  
        flyTo({
          camera,
          area
        })
  
        store.dispatch(
          selectSite({
            site: area
          })
        )
      }
    }
  }, Cesium.ScreenSpaceEventType.LEFT_CLICK)

  handler.setInputAction(function (movement) {
    const pickedObject = scene.pick(movement.position)

    const { toolbar } = store.getState()
    
    if (toolbar.selector === 'drawPolyline') {
      if (
        Cesium.defined(pickedObject)
        && pickedObject.id
        && pickedObject.id.name === POLYLINE_POINT
      ) {
        if (!toolbar.pointSelected) {
          store.dispatch(setPointSelected({
            pointSelected: pickedObject.id
          }))
          pickedObject.id.billboard.image = rectangleRed
        } else if (pickedObject.id === toolbar.pointSelected) {
          pickedObject.id.billboard.image = rectangleBlue
          store.dispatch(removePointSelected())
        } else {
          toolbar.pointSelected.billboard.image = rectangleBlue
          pickedObject.id.billboard.image = rectangleRed
          store.dispatch(setPointSelected({
            pointSelected: pickedObject.id
          }))
        }

        scene.screenSpaceCameraController.enableRotate = false
        return
      }
  
      if (toolbar.pointSelected) {
        toolbar.pointSelected.billboard.image = rectangleBlue
        store.dispatch(removePointSelected())
      }
    }
  }, Cesium.ScreenSpaceEventType.LEFT_DOWN)

  handler.setInputAction(function (movement) {
    const { toolbar } = store.getState()
    
    if (toolbar.selector === 'drawPolyline') {  
      if (toolbar.pointSelected) {
        toolbar.pointSelected.billboard.image = rectangleBlue
        store.dispatch(removePointSelected())
        
        const index = toolbar
          .pointDataSource.entities.values.indexOf(toolbar.pointSelected)
        
        toolbar.pointSelected.position.setCallback(function () {
          const { pointHistories, active } = store.getState().toolbar
        
          if (!pointHistories[active]) {
            return null
          } else {
            return pointHistories[active][index]
          }
        }, false)


        const ray = scene.camera.getPickRay(
          movement.position
        )
        const position = scene.globe.pick(ray, scene)

        const newShapePoints = toolbar.activeShapePoints.slice()
        newShapePoints[index] = position
        let newDataPoints = []
        
        if (toolbar.pointHistories[toolbar.active].length) {
          newDataPoints = toolbar.pointHistories[toolbar.active]
            .slice()
            newDataPoints[index] = position
        }

        store.dispatch(addActivePolylineIndex())
        store.dispatch(
          updatePolylineHistories({
            data: newShapePoints,
            dataPoint: newDataPoints
          })
        )

        if (toolbar.selector === 'drawPolyline') {
          editDrawAPI({
            type: 'LINE',
            positions: positionsCartesianToCartographic({
              positions: newShapePoints
            }),
            properties: {
              type: toolbar.type
            },
            cesiumId: toolbar.entity.id,
            status: 'EDIT'
          })
        }
      }
      scene.screenSpaceCameraController.enableRotate = true
    }
  }, Cesium.ScreenSpaceEventType.LEFT_UP)
}

export function addEllipse ({
  viewer,
  area
}) {
  viewer.entities.add({
    position: Cesium.Cartesian3.fromDegrees(area.long, area.lat, 10001),
    ellipse: {
      semiMinorAxis : 250000.0,
      semiMajorAxis : 250000.0,
      material: area.image
    },
    properties: area
  })
}

export function flyTo ({
  area,
  areaHeight = 100000,
  actions
}) {
  if (area && area.long && area.lat) {
    cesiumViewer.viewer.scene.camera
      .flyTo({
        destination: Cesium.Cartesian3.fromDegrees(
          area.long,
          area.lat,
          areaHeight,
        ),
        complete: function () {
          if (actions) {
            store.dispatch(actions)
          }
        }
      })
  }
}

export async function removeDataSource ({
  viewer,
  dataSource
}) {
  try {
    viewer.dataSources.remove(dataSource, true)
  } catch (error) {
    throw error
  }
}

export function generateCartesianFromGeometry ({
  geometry
}) {
  if (!geometry || !geometry.coordinates) {
    return []
  }

  const positions = []

  const rawPositions = geometry.coordinates

  for (let i = 0; i < rawPositions.length; i++) {
    positions.push(
      Cesium.Cartesian3.fromDegrees(rawPositions[i][0], rawPositions[i][1])
    )
  }

  return positions
}

export function generateDrawEntity ({
  datum
}) {
  if (datum.draw_type === 'LINE') {
    let color = null
    
    if (datum.shape_type === 'ridge') {
      color = Cesium.Color.YELLOW
    } else if (datum.shape_type === 'spur') {
      color = Cesium.Color.BLUE
    }

    let geometry = []

    if (datum.geometry) {
      geometry = JSON.parse(datum.geometry)
    }

    const positions = generateCartesianFromGeometry({
      geometry
    })

    return boxPolyline({
      id: datum.cesium_id,
      positions,
      color,
      properties: {
        type: datum.shape_type
      }
    })
  }

  return null
}

export function getActiveShapePoints () {       
  return new Cesium.CallbackProperty(() => {
    return store.getState().toolbar.activeShapePoints
  }, false)
}

export function loadDrawMap (data) {
  // edit it later

  const dataSource = new Cesium.CustomDataSource('DRAW')
  cesiumViewer.viewer.dataSources.add(dataSource)

  for (let i = 0; i < data.length; i++) {
    const entity = generateDrawEntity({
      datum: data[i]
    })

    if (entity) {
      dataSource.entities.add(entity)
    }
  }

  return dataSource
}

export function positionsCartesianToCartographic ({
  positions
}) {
  return positions.map((position) => {
    const pos = Cesium.Cartographic.fromCartesian(position)
    return {
      longitude: Cesium.Math.toDegrees(pos.longitude),
      latitude: Cesium.Math.toDegrees(pos.latitude)
    }
  })
}

export function switchPolylineColor ({
  activeShapePoints,
  entity,
  selector,
  type
}) {
  if (selector === 'drawPolyline') {
    if (type === 'ridge') {
      entity.polyline.material = Cesium.Color.YELLOW
    } else if (type === 'spur') {
      entity.polyline.material = Cesium.Color.BLUE
    }

    entity.properties = {
      type
    }
    
    editDrawAPI({
      type: 'LINE',
      positions: positionsCartesianToCartographic({
        positions: activeShapePoints
      }),
      properties: {
        type
      },
      cesiumId: entity.id,
      status: 'EDIT'
    })
  } 
}

/**
 * This function will return the position based on given type
 * @param event is the event from javascript
 * @param ellipsoid cesium ellipsoid
 * @param type selected type 'cartesian3' / 'long/lat' / both
 * @returns object of selected type
 */
export function findPosition({ event, ellipsoid, type }) {
  const { x, y } = event.position
  
  let cartesian

  try {
    const ray = cesiumViewer.viewer.scene.camera.getPickRay(
      new Cesium.Cartesian2(x, y)
    )
    
    cartesian = cesiumViewer.viewer.scene.globe
      .pick(ray, cesiumViewer.viewer.scene)
  } catch (error) {
    cartesian = new Cesium.Cartesian3(x, y)
  }

  if (!cartesian) {
    cartesian = new Cesium.Cartesian3(x, y)
  }

  if (type === 'cartesian3') {
    return cartesian
  } else if (type === 'long/lat') {
    const cartographic = ellipsoid.cartesianToCartographic(cartesian)
    const longitude = Cesium.Math.toDegrees(cartographic.longitude)
    const latitude = Cesium.Math.toDegrees(cartographic.latitude)

    return { longitude, latitude, height: cartographic.height }
  } else if (type === 'both') {
    const cartographic = ellipsoid.cartesianToCartographic(cartesian)
    const longitude = Cesium.Math.toDegrees(cartographic.longitude)
    const latitude = Cesium.Math.toDegrees(cartographic.latitude)

    return { cartesian, longitude, latitude, height: cartographic.height }
  }
}

/**
 * This function will create rectangle coordinates for cesium
 * @param {*} end the event end position
 * @param {*} dragMovement the dragMovement
 * @returns Object that contains east, west, north, and south
 */
export function generateRectanglePosition(end, dragMovement) {
  let west
  let south
  let east
  let north
  const { start } = dragMovement

  if (!start) {
    return {}
  }

  if (start.longitude > end.longitude) {
    east = start.longitude
    west = end.longitude
  } else {
    west = start.longitude
    east = end.longitude
  }

  if (start.latitude > end.latitude) {
    south = end.latitude
    north = start.latitude
  } else {
    north = end.latitude
    south = start.latitude
  }

  return {
    west,
    south,
    east,
    north
  }
}

export async function creatingDataSource({ name, type }) {
  let dataSource = null

  if (type === 'geojson') {
    dataSource = new Cesium.GeoJsonDataSource(name)
  } else {
    dataSource = new Cesium.CustomDataSource(name)
  }

  if (cesiumViewer.viewer.dataSources.getByName(name)) {
    cesiumViewer.viewer.dataSources.remove(
      cesiumViewer.viewer.dataSources.getByName(name)[0],
      true
    )
  }

  return dataSource
}
