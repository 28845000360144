import React, {useEffect,useRef,useState} from 'react';
import * as d3 from 'd3';
import _ from 'lodash';
import { 
    SELECT_TERNARY_MENU_CLASSIFICATION 
} from './options';
import { 
    barycentric, 
    ternaryPlot,
    createDropdown,
} from './helpers';
import { 
    ChartAnalysis, 
    HeaderChart, 
    TitleChart, 
    WrapperChartSvg, 
    WrapperManySvg,
    WrapperSpinner,
    WrapperSpinnerBackground
} from '../helpers/AnalysisStyle';
import { dropdownElemSelect, dropdownGroupElemSelect } from '../helpers/dropdownElements';
import { bauxite } from './classification/bauxite/bauxite'
import { felsic } from './classification/rocks/felsic'
import { mafic } from './classification/rocks/mafic'
import { ultramafic } from './classification/rocks/ultramafic'
import { Puff } from 'react-loading-icons';
import './Ternary.css'

/**
 * 
 * @param {array} data is data group
 * @param {} className
 * @param {} config
 * @param {} setConfig
 * @param {} onDelete
 * @param {} onMinimize
 * @param {} openSettings
 * @param {} isLoading
 * 
 * config: {
 *       data1: "Al2O3%_XRF230",
 *       data2: "Fe2O3%_XRF230",
 *       data3: "SiO2%_XRF230",
 *       selectDivision:null,
 *       sizeCircle:4,
 *       color:{
 *           fill:"#444444",
 *           stroke:"#dddddd"
 *       },
 *       classification:"no_classification"
 * }
 * 
 * @returns 
 */

let Ternary=({...props})=>{
    let {
        data,
        dataReady,
        className,
        config,
        setConfig,
        dropdown,
        widthContainer,
        heightContainer
    }=props;

    const ternaryRef = useRef(null);
    const classNameAll="ternary-"+className;
    const [loading, setLoading ]=useState(true)
    const [ternaryName1,setTernaryName1]=useState("Al");
    const [ternaryName2,setTernaryName2]=useState("Ag");
    const [ternaryName3,setTernaryName3]=useState("Au1");
    const [configTernary,setConfigTernary]=useState({
        data1: "",
        data2: "",
        data3: "",
        selectDivision:null,
        sizeCircle:4,
        color:{
            fill:"#444444",
            stroke:"#dddddd"
        },
        classification:"no_classification"
    });
    const [valDD1, setValDD1] = useState();
    const [valDD2, setValDD2] = useState()
    const [valDD3, setValDD3] = useState();
    const [valDDclass, setValDDclass] = useState({
        value: 'no_classification',
        label: 'no classification'
    })
    const labels = [configTernary.data1,configTernary.data2,configTernary.data3];

    useEffect(()=>{
        if(!config) return;
        let newConfig={...configTernary}
        Object.entries(config).forEach(([key,value])=>{
            if(key in newConfig){
                newConfig[key]=value;
            }
        })
        setConfigTernary(newConfig)
        if(config.data1!=="" && config.data2!=="" && config.data3!==""){
            if(dropdown.length!==0){
                dropdown.map((d)=>{
                    if(d.value===config.data1){
                        setValDD1(d)
                    }
                    if(d.value===config.data2){
                        setValDD2(d)
                    }
                    if(d.value===config.data3){
                        setValDD3(d)
                    }
                })
            }
        }
        if(config.classification!==""){
            SELECT_TERNARY_MENU_CLASSIFICATION.map((i)=>{
                if(i.value===config.classification){
                    setValDDclass(i)
                }
            })
        }
    },[])

    useEffect(()=>{
        if(configTernary.data1!=="" && configTernary.data2!=="" && configTernary.data3!==""){
            setConfig(configTernary)
        }
    },[configTernary])

    useEffect(()=>{
        if(dropdown.length!==0){
            if(config.data1==="" && config.data2==="" && config.data3===""){
                let newConfig={...configTernary}
                if(!valDD1 && dropdown.length>0){
                    newConfig.data1=dropdown[0].value
                    setValDD1(dropdown[0])
                }
                if(!valDD2 && dropdown.length>1){
                    newConfig.data2=dropdown[1].value
                    setValDD2(dropdown[1])
                }
                if(!valDD3 && dropdown.length>2){
                    newConfig.data3=dropdown[2].value
                    setValDD3(dropdown[2])
                }
                setConfigTernary(newConfig)
            } else {
                dropdown.map((d)=>{
                    if(!valDD1){
                        if(d.value===config.data1){
                            setValDD1(d)
                            setTernaryName1(d.label)
                        }
                    }
                    if(!valDD2){
                        if(d.value===config.data2){
                            setValDD2(d)
                            setTernaryName2(d.label)
                        }
                    }
                    if(!valDD3){
                        if(d.value===config.data3){
                            setValDD3(d)
                            setTernaryName3(d.label)
                        }
                    }
                })
            }
        }
    },[dropdown])

    useEffect(() => {
        let i;
            for(i=0;i<dropdown.length;i++){
            if(dropdown[i].value===configTernary.data1){
                setTernaryName1(dropdown[i].label)
            }
        }
    },[configTernary.data1]);

    useEffect(() => {
        let i;
        for(i=0;i<dropdown.length;i++){
            if(dropdown[i].value===configTernary.data2){
                setTernaryName2(dropdown[i].label)
            }
        }
    },[configTernary.data2]);

    useEffect(() => {
        let i;
        for(i=0;i<dropdown.length;i++){
            if(dropdown[i].value===configTernary.data3){
                setTernaryName3(dropdown[i].label)
            }
        }
    },[configTernary.data3]);

    useEffect(() => {
        let con={...configTernary}
        const regex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g
        const searchElemStartsWith = (elm,str) => {
            elm.value
            .toLowerCase()
            .replace(regex, '')
            .replace(/ |_/g, '')
            .startsWith(str)
        }
        if (configTernary.classification === 'bauxite') {
            if (dropdown.length !== 0) {
                dropdown.map((elm) => {
                    if (searchElemStartsWith(elm,'fe2')) {
                        con.data1 = elm.value // Fe2o3
                        setValDD1(elm)
                    } else if (searchElemStartsWith(elm,'al2')) {
                        con.data2 = elm.value // Al2O3
                        setValDD2(elm)
                    } else if (searchElemStartsWith(elm,'si')) {
                        con.data3 = elm.value // SiO2
                        setValDD3(elm)
                    }
                })
            }
            setConfigTernary(con)
        }
    },[configTernary.classification]);


    useEffect(()=>{
        if (dataReady===false) return;
        if (data.length===0) return;
        setLoading(true)

        d3.select("."+className)
            .style("opacity",1)    
            .transition()
            .delay(100)
            .duration(500)
            .style("opacity",0)
            .remove();
        d3.selectAll("."+className+"-circle")
            .attr("r", 4)    
            .transition()
            .duration(700)
            .attr("r", 0)
            .remove();

        d3.select('.'+ classNameAll)
            .selectAll(".tooltip-ternary")
            .remove();
        
        const configViewBox = {
            minX: 0,
            minY: 0,
            width: widthContainer,
            height: heightContainer
        }
        
        const margin = { top: 40, right: 30, bottom: 30, left: 30 },
            width = configViewBox.width - margin.left - margin.right,
            height = configViewBox.height - margin.top - margin.bottom;

        const containerAll= d3.select(ternaryRef.current)
        
        const containerTest = containerAll
            .attr("viewBox", `${configViewBox.minX} ${configViewBox.minY} ${configViewBox.width} ${configViewBox.height}`)

        const svg = containerTest
        .append("g")
        .attr("class",className)
        .attr("transform", `translate(${margin.left}, ${margin.top})`);

        const gTernary = svg
        // .attr('viewBox', '-40 -10 450 390')
        .append('g')
        .attr('class', 'ternaryInfo')
        .attr('transform', `translate(${margin.left}, ${margin.top})`)

        const radius = Math.min(width, height) / 2
        
        const ternaryGeoChemical = barycentric()
        .a(d => d[configTernary.data1])
        .b(d => d[configTernary.data2])
        .c(d => d[configTernary.data3]);
        
        function textureTernaryPlot(){
            return ternaryPlot(ternaryGeoChemical)
            .radius(radius)
            // .domains([[1, 0], [1, 0], [1, 0]])
            .labels([ternaryName1+", %", ternaryName2+", %", ternaryName3+", %"])
            // .labelAngles([0, 0, 0])
            // .labelOffsets([-100, -100, -100])
            // .tickAngles([0, 0, 0])
            // .tickTextAnchors(["start", "end", "start"]);
        } 
        const yOffset = radius/4;

        const GTernaryPlot = svg
            .append("g")
            .attr("transform", `translate(${width / 2} ${height / 2 + yOffset})`)
            .attr("font-family", "Abel")
            .attr("id", "ternaryPlot")
            // .on("mousemove",)

        const defs = GTernaryPlot.append("defs");
        defs
            .append("clipPath")
            .attr("id", "trianglePath")
            .append("path")
            .attr("d", textureTernaryPlot().triangle());

        GTernaryPlot
            .append("circle")
            .attr("r", 2)
            .attr("fill", "white")
            .append("title")
            .text("Center point");

        const axisLabels = (g, labels) => g
            .selectAll("text")
            .data(labels, d => d.label)
            .join(
            enter =>
                enter
                .append("text")
                .attr("dy", (d, i) => (i === 2 ? "-0.5em" : ".5em"))
                .attr(
                    "transform",
                    (label, i) => `translate(${label.position})rotate(${label.angle})`
                )
                .attr("text-anchor", "middle")
                .text(label => label.label),
            update =>
                update.attr(
                "transform",
                (label, i) => `translate(${label.position})rotate(${label.angle})`
                )
            );
        
        const axisLabelsGroups = GTernaryPlot
            .append("g")
            .attr("class", "axis-labels")
            .append("g")
            .attr("class", "labels")
            .attr("font-size", 16)
            .attr("fill","white");
        
        
        axisLabelsGroups.call(axisLabels, textureTernaryPlot().axisLabels(axisLabelsGroups,labels));
        
        const gridLinesPaths = textureTernaryPlot()
            .gridLines()
            .map(axisGrid => axisGrid.map(d3.line()).join(" "));
        
        const grid = (g, gridLines) => g.selectAll("path")
        .data(gridLines)
        .join(
            enter => enter.append("path")
                .attr("d", d => d)
                .attr("stroke", "#777777")
                .attr("stroke-width", (d, i) => i & 0.2 ? 0.2 : 0.4),
            update => update.attr('d', d => d)
        )
        
        const gridGroup = GTernaryPlot
            .append("g")
            .attr("class", "grid")
            .call(grid, gridLinesPaths);
        
        const epsilon=0.0001;
        
        const ticks = g => g
            .selectAll("g")
            .data(d => d, d => d.tick)
            .join(
                enter => {
                const tickGroups = enter
                    .append("g")
                    .attr("class", "tick")
                    .attr("transform", tick => `translate(${tick.position})`);
        
                tickGroups
                    .append("text")
                    .attr("text-anchor", tick => tick.textAnchor)
                    .attr("transform", tick => `rotate(${tick.angle})`)
                    .attr(
                    "dx",
                    tick => (-tick.size - 5) * (tick.textAnchor === "start" ? -1 : 1)
                    )
                    .attr("dy", ".5em")
                    .text(tick => tick.tick);
        
                tickGroups
                    .append("line")
                    .attr("transform", d => `rotate(${d.angle + 90})`)
                    .attr(
                    "y2",
                    tick => tick.size * (tick.textAnchor === "start" ? -1 : 1)
                    )
                    .attr("stroke", "grey");
        
                return tickGroups;
                },
                update => update.attr("transform", tick => `translate(${tick.position})`),
                exit => exit.attr("opacity", epsilon).remove()
            )
        
        const axisTicksGroups = GTernaryPlot
            .append("g")
            .attr("class", "ternary-ticks")
            .attr("fill", "white")
            .attr("font-size", 10)
            .selectAll("g")
            .data(textureTernaryPlot().ticks())
            .join("g")
            .attr("class", "axis-ticks")
        .call(ticks);

        const filterData= _.reject(data, ['sample_id', ""]);

        const dataTernary = [
            ...filterData
        ];
        
        const ternaryData = dataTernary.map(d => {
            const [x, y] = textureTernaryPlot()(d);
            return { x, y, ...d };
        });
        const filterTernaryData= _.reject(ternaryData, {'x':0,'y':0});

        const dots = GTernaryPlot
            .append("g")
            .attr("clip-path", "url(#trianglePath)")
            .selectAll("circle")
            .data(filterTernaryData)
            .join("circle")
        
            dots
            .attr("class", className+"-circle")
            .attr("r",0)
            .transition()
            .duration(1000)
            .attr("r", configTernary.sizeCircle)
            .attr("cx", d => d.x)
            .attr("cy", d => d.y)
            .attr("fill", configTernary.color.fill)
            .attr("stroke", configTernary.color.stroke);
    
        dots.append("title")
        .text(
            d => `${d.sample_id}
            ${ternaryName1}: ${d[configTernary.data1]}
            ${ternaryName2}: ${d[configTernary.data2]}
            ${ternaryName3}: ${d[configTernary.data3]}`
        )

        // const circleTernary=GTernaryPlot
        // .selectAll("."+className+'-circle')

        // circleTernary.enter()
        // .append("circle")
        // .on("mouseover", function(d) {		
        //     tooltip
        //         .transition()
        //         .duration(500)
        //         .style("opacity", 1)
        //     d3.select(this)
        //         .style("stroke","white")
        //         .style("stroke-width", "1px")	
        // })
        // .on("mouseleave", function(d){
        //     tooltip
        //         .transition()
        //         .duration(500)
        //         .style("opacity", 0)
        //     d3.select(this)
        //         .style("stroke","gray")
        // })
        // .on("mousemove", function(event, d){
        //     console.log(d)
        //     tooltip
        //         .html(ternaryName1 +':'+d[ternaryData1]+'</br>'+ ternaryName2 +':'+d[ternaryData2]+'</br>'+ternaryName3 +':'+d[ternaryData3])
        //         .style('left', event.pageX + 30 + "px")
        //         .style('top', event.pageY - 30 + "px")
        //     d3.select(this)
        //         .style("stroke","white")
        //         .style("stroke-width", "1px")
        //         .style("cursor","pointer")	
        // })
        // .merge(circleTernary)

        // initial triangle
        const trianglePath = GTernaryPlot
        .append("path")

        trianglePath
        .attr("stroke-width", 0)
        .transition()
        .duration(2000)
        .attr("d", textureTernaryPlot().triangle())
        .attr("fill", "transparent")
        .attr("stroke", "darkgoldenrod")
        .attr("stroke-width", 2);
          
        const zoom = d3.zoom()
            .scaleExtent([1, 100])
            .on("zoom", zoomed);
        
        const [tx, ty] = textureTernaryPlot().translate();
        const k = textureTernaryPlot().scale();
        
        // We need to sync d3-zoom with the tranform of the partial domains
        const initialTransform = d3.zoomIdentity
            .translate(tx * radius, ty * radius)
            .scale(k);
          
        // GTernaryPlot.call(zoom).call(zoom.transform, initialTransform);
          
        function zoomed({ transform }) {
            const { x, y, k } = transform;
        
            const tx = x / radius,
            ty = y / radius;
        
            // apply transform
            textureTernaryPlot().translate([tx, ty]);
            textureTernaryPlot().scale(k);
        
            const zoomedDomains = textureTernaryPlot().domainsFromVertices();
        
            textureTernaryPlot().setDomains(zoomedDomains);
        
            // update data
            dots
            .attr("cx", d => textureTernaryPlot()(d)[0])
            .attr("cy", d => textureTernaryPlot()(d)[1]);
        
            // update gridlines and ticks
            const gridLinesPaths = textureTernaryPlot()
            .gridLines()
            .map(axisGrid => axisGrid.map(d3.line()).join(" "));
        
            gridGroup.call(grid, gridLinesPaths);
        
            axisTicksGroups.data(textureTernaryPlot().ticks()).call(ticks, d => d);
        
            const [zoomvA, zoomvB, zoomvC] = textureTernaryPlot().vertices();

            trianglePath.attr("d", `M${zoomvA}L${zoomvB}L${zoomvC}Z`);
        }

        // function handleMouseMove(event, d) {
        //     const xy = d3.pointer(d);
        //     const inverse = textureTernaryPlot().invert(xy);
        
        //     // GTernaryPlot.dispatchEvent(new CustomEvent("input"), { bubbles: true });
        //     // GTernaryPlot.value = inverse;
        // }
          
        if (configTernary.classification !== 'no_classification') {
            const closedLine = d3.line().curve(d3.curveLinearClosed)
            const groupClass = () => {
              if (configTernary.classification === 'bauxite') {
                return bauxite
              } else if (configTernary.classification === 'felsic') {
                return felsic
              } else if (configTernary.classification === 'mafic') {
                return mafic
              } else if (configTernary.classification === 'ultramafic') {
                return ultramafic
              } else {
                return {
                  data: [],
                  info: ''
                }
              }
            }
      
            const ternaryDivisions = groupClass().data.map((d) => {
              // add centroid for label position to each division
      
              const newDiv = () =>
                d.values.map((i) => {
                  const da = ['data1', 'data2', 'data3']
                  const nob = Object.keys(i)
                  const newObject = {}
                  Object.keys(i).map((j, idx) => {
                    if (j === nob[idx]) {
                      newObject[configTernary[da[idx]]] = i[nob[idx]]
                    }
                  })
                  return newObject
                })
      
              // const rotate = () => {
              //   if (d.class.length === "Bx Feritic") {
              //     return 10
              //   } else {
              //     return null
              //   }
              // }
      
              const centroid = d3.polygonCentroid(newDiv().map(textureTernaryPlot()))
              const coords = newDiv().map(textureTernaryPlot())
              const classnih = d.class
              const valuenih = newDiv()
              const rotation = d.rotation
      
              return { class: classnih, values: valuenih, coords, centroid, rotation }
            })

            const tooltip = d3.select("."+classNameAll)
            .append("div")
            .style("opacity",0)
            .attr ("class","tooltip-ternary")
            .style("position", "fixed")
            .style("z-index", 3);

            const pathLine = GTernaryPlot.append("g")

            const division = pathLine
            .attr("class", "data-division")
            .attr("clip-path", "url(#trianglePath)")
            .selectAll("path")
            .data(ternaryDivisions.map(d => d.coords))
            .join("path")
            .attr("d", closedLine)
            .attr("fill", "transparent")
            .attr("stroke", "darkgoldenrod")
            .attr("stroke-width", 2)
            
            division
            .data(ternaryDivisions.map(d=>d))
            .on("mouseover", function(d) {	
                d3.select(d.target)
                .style("fill", "goldenrod")
                .style("fill-opacity",0.3)	
                tooltip
                    .transition()
                    .duration(500)
                    .style("opacity", 1)	
            })
            .on("mouseleave", function(d){
                d3.select(d.target)
                .style("fill", "transparent")
                .style("fill-opacity",0)
                tooltip
                    .transition()
                    .duration(500)
                    .style("opacity", 0)
            })
            .on("mousemove", function(ev,d){
                tooltip
                    .html(d.class)
                    .style('left', ev.pageX +10+ "px")
                    .style('top', ev.pageY -30+ "px")
                d3.select(this)
                    .style("cursor","pointer")	
            }) 

             // GTernaryPlot
            // .append("g")
            // .selectAll("text")
            // .data(ternaryDivisions)
            // .join("text")
            // .attr("transform", d => `translate(${d.centroid})rotate(${d.rotation})`)
            // .attr("x", d => d.centroid[0])
            // .attr("y", d => d.centroid[1])
            // .attr("text-anchor", "middle")
            // .attr("stroke", "lightgreen")
            // .attr("stroke-width", 0.5)
            // // .attr("paint-order", "stroke")
            // .attr("alignment-baseline", "middle")
            // .attr("font-size", 10)
            // .text(d => d.class)
            
            const wrap = (text) => {
                text.each(function () {
                    const text = d3.select(this)
                    const words = text.text().split(/\s+/).reverse()
                    const width = parseFloat(text.attr('width'))
                    const height = parseFloat(text.attr('height'))
                    const y = parseFloat(text.attr('y'))
                    const x = text.attr('x')
                    const anchor = text.attr('text-anchor')
        
                    let tspan = text
                    .text(null)
                    .append('tspan')
                    .attr('x', x)
                    .attr('y', y)
                    .attr('text-anchor', anchor)
                    let lineNumber = 0
                    let line = []
                    let word = words.pop()
        
                    while (word) {
                    line.push(word)
                    tspan.text(line.join(' '))
                    if (tspan.node().getComputedTextLength() > width) {
                        const lineHeight = height / 10
                        lineNumber += 1
                        line.pop()
                        tspan.text(line.join(' '))
                        line = [word]
                        tspan = text
                        .append('tspan')
                        .attr('x', x)
                        .attr('y', y + lineNumber * lineHeight)
                        .attr('anchor', anchor)
                        .text(word)
                    }
                    word = words.pop()
                    }
                })
            }
            
            const rectInfo = gTernary.append('g')
            const wRect = width / 2 - width / 5
            const hRect = height / 4
            const xRect = 0 - margin.left
            const yRect = 0 - margin.top
        
            rectInfo
                .append('rect')
                .attr('class', 'class_information')
                .attr('x', xRect)
                .attr('y', yRect)
                .attr('width', wRect)
                .attr('height', hRect)
                .attr('stroke', 'gray')
                .attr('fill-opacity', '0')
        
            rectInfo
                .append('text')
                .attr('class', 'wrapme')
                .attr('x', xRect + wRect / 20)
                .attr('y', yRect + hRect / 5)
                .attr('width', wRect - wRect / 20)
                .attr('height', hRect)
                // .attr('text-anchor', 'middle')
                .attr('font-size', width / 60)
                .attr('fill', 'white')
                .text(groupClass().info)
        
            rectInfo.selectAll('.wrapme').call(wrap)
        }

        setLoading(false)
    },[
        ternaryName1,
        ternaryName2,
        ternaryName3, 
        configTernary.classification,
        heightContainer,
        widthContainer, 
        dataReady, 
        className
    ]);

    const handleClassificationSelect = (value) => {
        let config_={...configTernary};
        config_.classification=value.value;
        setValDDclass(value)
        setConfigTernary(config_)
    }


    return (
        <ChartAnalysis  className={classNameAll}>
            
            <TitleChart className="header-plot">
            Ternary
            </TitleChart>

            <HeaderChart left className="header-plot">
                {dropdownElemSelect({
                    dropdownOptions: dropdown,
                    config: configTernary,
                    setConfig: setConfigTernary,
                    val: "data1",
                    valDD: valDD1,
                    setValDD: setValDD1
                })
                }
                {/* <Dropdown
                    label="Data 1"
                    floatingText="Data 1"
                    options={dropdown}
                    setToggle={(value)=>{
                        let config_={...configTernary};
                        config_.data1=value;
                        setConfigTernary(config_)
                    }}
                    defaultPick={configTernary.data1}
                    width='3.5em'
                    height = '1.2em'
                    initialBoxColor = 'rgb(119, 89, 13)'
                    fontColor = 'white'
                    marginTop='0.6em'
                    position='absolute'
                /> */}
                <div style={{marginLeft:"0.3em"}}>
                    {
                        dropdownElemSelect({
                            dropdownOptions: dropdown,
                            config: configTernary,
                            setConfig: setConfigTernary,
                            val: "data2",
                            valDD: valDD2,
                            setValDD: setValDD2
                        })
                    }
                </div>
                <div style={{marginLeft:"0.3em"}}>
                    {
                        dropdownElemSelect({
                            dropdownOptions: dropdown,
                            config: configTernary,
                            setConfig: setConfigTernary,
                            val: "data3",
                            valDD: valDD3,
                            setValDD: setValDD3
                        })
                    }
                </div>
                <div style={{marginLeft:"0.5em"}}>
                    {valDD1 && valDD2 && valDD3 && dropdownGroupElemSelect({
                        dropdownOptions: SELECT_TERNARY_MENU_CLASSIFICATION,
                        handle: handleClassificationSelect,
                        valDD: valDDclass
                        })
                    }
                </div>
            </HeaderChart>

            <WrapperChartSvg>
                <WrapperManySvg>
                    <svg
                        className="line-cor text-cor path-cor cor label"
                        ref={ternaryRef}
                        width={"100%"}
                        height={"85%"}
                    />
                </WrapperManySvg>
            </WrapperChartSvg>
            {loading?
                <WrapperSpinnerBackground small>
                    <WrapperSpinner>
                        <Puff
                        height={heightContainer/2}
                        width={widthContainer/2}
                        fill='goldenrod'
                        stroke="darkgoldenrod"
                        speed="1"
                        fillOpacity="0.5"
                        strokeOpacity="0.5"
                        strokeWidth="2"
                    />
                    </WrapperSpinner>
                </WrapperSpinnerBackground>
            :
                <></>
            }
        </ChartAnalysis>
    )
}

export default Ternary;