import React from 'react';
import './Switch.css';

const ToggleButton = ({...props}) => {
    let{
        isOn,
        handleToggle,
        colorOn,
        colorOff,
        showOn,
        showOff
    } = props;

    return (
    <div style={{display:'flex',alignItems:'center',justifyContent:'center', marginTop:'0.5em'}}>
        <input
            checked={isOn}
            onChange={handleToggle}
            className="react-switch-checkbox"
            id={`react-switch-new`}
            type="checkbox"
        />
        <label
            style={{ 
                background: isOn ? 
                    colorOn ? colorOn : 'brown' 
                    :  
                    colorOff ? colorOff : 'green'
            }}
            className="react-switch-label"
            htmlFor={`react-switch-new`}
        >
            <span className={`react-switch-button`} >
                {isOn ? showOn : showOff}
            </span>
        </label>
    </div>
    );
};

export default ToggleButton;