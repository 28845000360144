import React from 'react'

import { connect } from 'react-redux'

import {
  checkLayerCategory,
  uncheckLayerCategory
} from '../../../../../../store/actions/catalog'

import FlexWrapper from '../../../shares/FlexWrapper'
import ToolbarText from '../../../shares/ToolbarText'
import ToggleIcon from '../../../shares/ToggleIcon'

import ListMap from '../Map/ListMap'
import DataTypeSelector from '../DataTypeSelector'
  
function FirstLevel({
  category,
  categoryValue,
  checkLayerCategory,
  uncheckLayerCategory
}) {
  const { toggle } = categoryValue

  const listMap = categoryValue.listMap

  function onClickHandler() {
    if (toggle) {
      uncheckLayerCategory({
        category
      })
    } else {
      checkLayerCategory({
        category
      })
    }
  }

  return <>
    <FlexWrapper
      center
    >
      <ToggleIcon
        toggle={toggle}
        size="smaller"
        toggleAble={listMap.length}
        onClick={onClickHandler}
      />
      <DataTypeSelector
        data={categoryValue}
        category={category}
      />
      <ToolbarText
        width="17em"
        text={category}
        max={30}
      />
    </FlexWrapper>
    {
      toggle && (
        <ListMap
          listMap={listMap}
          marginLeft="1em"
        />
      )
    }
  </>
}

const mapDispatchToProps = {
  checkLayerCategory,
  uncheckLayerCategory
}

export default connect(null, mapDispatchToProps)(FirstLevel)
