import React, { useState } from 'react'
import { useHistory } from "react-router-dom";

import FocusContainer, {
  Header,
  Paragraph
} from '../StyledComponent'
import { LIST_HELP } from '../constant'
import iziToast from 'izitoast';
import styled from 'styled-components';
import IconLocation from '../../../images/Icons/pin.svg';
import IconEmail from '../../../images/Icons/email.svg';
import IconPhone from '../../../images/Icons/phone-call.svg';
import emailjs from 'emailjs-com';

const Container = styled.div`
  background: #fff;
  border-radius: 6px;
  padding: 30px 50px 30px 30px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  position: relative;
`

const LeftSide = styled.div`
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  position: relative;
  &::before{
    content: '';
    position: absolute;
    height: 85%;
    width: 2px;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    border-right: 0.5px solid goldenrod;
    background: #afafb6;
  }
`
const RightSide=styled.div`
  width: 75%;
  margin-left:1em;
  font-size: 23px;
  font-weight: 600;
  height: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  border-radius: 6px;
  padding: 0 15px;
  resize: none;
`

const Icon= styled.img`
  width: 2em;
  text-align: center;
  margin-left: 10%;
  margin-right: 1em;
  margin-top: ${props => props.top ? '0em' : '1.5em' };
  margin-bottom: 0.5em;
`
const Text =styled.div`
  font-weight: ${props=>props.bold ? 'bold' : 'normal' };
  font-family: Abel;
  text-align: center;
  font-size: ${props=>props.bold ? '1.5em' : '1em' };;
`

const FormControl = styled.input`
  display: block;
  width: 100%-8px;
  padding: 0.25rem 1rem;
  height: ${props=>props.big ? '10em' : '1.25em'};
  font-size: 1em;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-align: left;
  font-family: abel;
  margin-bottom: 0.5em;
`

const MessageControl = styled.textarea`
  display: block;
  width: 100%-8px;
  padding: 0.25rem 1rem;
  height: ${props=>props.big ? '10em' : '1.25em'};
  font-size: 1em;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-align: left;
  font-family: abel;
  margin-bottom: 0.5em;
`

const ButtonSubmit = styled.button`
  display: inline-block;
  border-radius: 4px;
  background-color: black;
  background-image: linear-gradient(to right, black, darkgoldenrod);
  border: none;
  color: #FFFFFF;
  text-align: center;
  padding: 0.5rem;
  width: 100%;
  transition: all 0.5s;
  cursor: pointer;
  font-size: 1em;
  font-family: Abel;
`

/**
 * The contents of the help page about contact us
 * @param {object} props Component props
 * @param {int} props.key index of the help list
 * @param {string} props.focus is the content that will be automatically scrolled
 * @param {string} props.opened is the parent of the content to be opened
 * @param {function} props.setOpened is set state of opened
 * @param {function} props.setFocus is set state of focus
 * @returns page from navigation contact us
 */

function HelpContact(props) {
  let history = useHistory()

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const [ customError, setCustomError ] = useState({
    email: null,
    name: null,
    subject: null,
    message: null
  })

  const configTime = {
    timer: null,
    focus: '',
    fn: null,
    delay: 5000
  }
  
  const emailRegex = new RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)

  function onChangeHandler(e, key, valueChange, validation) {
    if (customError[key]) {
      setCustomError({
        ...customError,
        [key]: null
      })
    }

    valueChange(e.target.value)

    if (!configTime.timer) {
      configTime.fn = validation
      configTime.timer = setTimeout(configTime.fn, configTime.delay)
      configTime.focus = key

    } else if (configTime.focus !== key) {
      if (configTime.fn) {
        configTime.fn()
      }
      clearTimeout(configTime.timer)
      configTime.fn = validation
      configTime.timer = setTimeout(configTime.fn, configTime.delay)
      configTime.focus = key

    } else {
      clearTimeout(configTime.timer)
      configTime.fn = validation
      configTime.timer = setTimeout(configTime.fn, configTime.delay)
    }
  }
  
  // const recaptcha_response = '';

  const handleReset = () => {
    setName('')
    setEmail('')
    setSubject('')
    setMessage('')
  };

  const onSubmit = (event) => {
    event.preventDefault()

    for (let key in customError) {
      if (customError[key]) {
        iziToast.warning({
          title: 'Error',
          message: 'Please fix all error before continue'
        })
        return
      }
    }
    emailjs.sendForm("geoprosp_email", "template_r0b2twx", event.target, 'user_I6syLQ9urxVTjIxF7I01o')
      .then(function() {
        // history.push('/help')
        iziToast.success({
          title: 'Success',
          message: 'Successfully sent a message to us, the answer will be sent to your email, please wait we will send it soon'
        })
        handleReset();

        // if(recaptcha_response.length === 0) {
        //   document.getElementById('g-recaptcha-error').innerHTML = '<span style="color:red;">This field is required.</span>';
        //   return false;
        // }
        // return true;
      }, (error) => {
        iziToast.warning({
          title: 'Error',
          message: 'Server Error: '+ error.text
        })
      });
  };
  
  // function verifyCaptcha(token) {
  //   recaptcha_response = token;
  //   document.getElementById('g-recaptcha-error').innerHTML = '';
  // }

  return <>
    <FocusContainer {...props}>
      <Header>{LIST_HELP['others'].detail.other.header}</Header>
      <Container>
        <LeftSide>
          <Icon top src={IconLocation} alt="Location"/>
          <Text bold>
            Address
          </Text>
          <Text>
          Talavera Office Park, 28th Floor
          </Text>
          <Text>
          Jl. Simatupang Kav 22-26
          </Text>
          <Text>
          Jakarta 12430
          </Text>
          <Icon src={IconEmail} alt="Location"/>
          <Text bold>
            Email
          </Text>
          <Text>
          admin@geofix-id.com
          </Text>
          <Icon src={IconPhone} alt="Location"/>
          <Text bold>
            Phone
          </Text>
          <Text>
            (021)7599-7968
          </Text>
        </LeftSide>
        <RightSide>
          <Text style={{textAlign:'left', marginBottom:'1em'}}>
          If you have questions regarding the Geoprosp website or related questions that have not been answered on the help page. Please write in the form below or contact us. It's our pleasure to help you.
          </Text>
          <form action="" onSubmit={onSubmit} style={{display:'flex', flexDirection:'column'}}>
              {customError.name && <p className="text-error">{customError.name}</p>}
              <FormControl 
                type='text'
                name='name'
                placeholder='Enter Your Name'
                value={name}
                onChange={(e) => {
                  onChangeHandler(e, 'name', setName, function(){
                    if ( !e.target.value || e.target.value.length < 2) {
                      setCustomError({
                        ...customError,
                        name: "Minimum name length at least 2 characters"
                      })
                    }
                  })
                }}
                required
              />
              {customError.email && <p className="text-error">{customError.email}</p>}
              <FormControl 
                type='email'
                name='email'
                placeholder='Enter Your Email'
                value={email}
                onChange={ (e) => {
                  onChangeHandler(e, 'email', setEmail, function () {
                    if (!emailRegex.test(e.target.value)) {
                      setCustomError({
                        ...customError,
                        email: 'Invalid email format'
                      })
                    }
                  })
                }}
                required
              />
              {customError.subject && <p className="text-error">{customError.subject}</p>}
              <FormControl 
                type='text'
                name='subject'
                placeholder='Enter The Subject' 
                value={subject}
                onChange={(e) => {
                  onChangeHandler(e, 'subject', setSubject, function(){
                    if ( !e.target.value || e.target.value.length < 5) {
                      setCustomError({
                        ...customError,
                        subject: "Minimum subject length at least 5 characters"
                      })
                    }
                  }) 
                }}
                required
              />
              {customError.message && <p className="text-error">{customError.message}</p>}
              <MessageControl
              big
                type='text'
                name='message'
                placeholder='Enter The Message'
                value={message}
                onChange={(e) => {
                  onChangeHandler(e, 'message', setMessage, function(){
                    if ( !e.target.value || e.target.value.length < 20) {
                      setCustomError({
                        ...customError,
                        message: "Minimum message length at least 20 characters"
                      })
                    }
                  }) 
                }}
                required
              />
              {/* <div class="g-recaptcha" data-sitekey="6LeO9G4cAAAAAJhL77RJ7dSylgMWUIfjAO5l5yfY" data-callback={verifyCaptcha}></div>
              <div id="g-recaptcha-error"></div> */}
              <ButtonSubmit
                type="submit"
              >
                <span>Send Message</span>
              </ButtonSubmit>
          </form>
          {/* <script src='https://www.google.com/recaptcha/api.js'></script> */}
        </RightSide>
      </Container>
    </FocusContainer>
  </>
}

export default HelpContact
