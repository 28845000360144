import styled from 'styled-components'

import * as ColorTemplate from '../../../shares/ColorTemplate'

const ContentIcon = styled.div.attrs(props => ({
  style: {
    width: props.width,
    height: props.height,
    top: props.top,
    left: props.left,
  }
}))`
  position: absolute;
  transition: all 330ms;
  cursor: pointer;
  
  svg {
    fill: ${props => {
      if (props.isActive === true) {
        return ColorTemplate.GOLDEN_3D.default
      } else if (props.isActive === false) {
        return ColorTemplate.LIGHT_GREY.default
      }

      return ColorTemplate.LIGHT_GREY.default
    }};

    &:hover {
      fill: ${ColorTemplate.GOLDEN_3D.default};
    }
  }
`

export default ContentIcon