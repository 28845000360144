import axiosReact, { getProgressDownload } from '../../../axiosReact'

import { generateKeyword } from '../constant'

import rgbToHex from '../../../helpers/rgbToHex'

export async function fetch({
  params,
  fetchProgress,
  fetchFinish
}) {
  try {
    if (params.withgeojson) {
      const { data } = await axiosReact.get('/geofix/mineraloccurrences', {
        params: {
          ...params,
          commodity: generateKeyword()
        },
        onDownloadProgress(event) {
          getProgressDownload(event, fetchProgress)
        }
      })
  
      fetchFinish()

      return {
        geojson: data
      }
    }
  } catch (error) {
    throw error
  }
}

export function parseFetch({ data }) {
  const formatData = {
    Island: data.administration.island,
    Longitude: data.longitude,
    Latitude: data.latitude,
    Province: data.administration.province,
    Regency: data.regency,
    'Major commodity': data.major_commodity,
    Status: data.status,
    'Ore resources': data.ore_resources,
    Grade: data.grade,
    'Deposit type': data.deposit_type,
    'Drilling status': data.drilling_status
  }

  return formatData
}

export function getColor(entity, {
  map
}) {
  return {
    red: 1,
    green: 1,
    blue: 0,
    alpha: map.cesiumLayer.alpha
  }
}

export function getIcon(entity, color) {
  let hex = null

  // const status = entity.properties.getValue('').status
  // if (status && status.toLowerCase().includes('old')) {
  //   hex = '#808080'
  // } else
  if (color) {
    hex = rgbToHex(
      Math.round(color.red * 255),
      Math.round(color.green * 255),
      Math.round(color.blue * 255)
    )
  } else {
    hex = rgbToHex(
      Math.random() * 255,
      Math.random() * 255,
      Math.random() * 255
    )
  }

  let svg = ''

  if (status && status.toLowerCase().includes('mine')) {
    svg = `
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="260.000000pt" height="280.000000pt" viewBox="0 0 260.000000 280.000000"
      preserveAspectRatio="xMidYMid meet">
        <style type="text/css">.st0{fill-rule:evenodd;clip-rule:evenodd;}</style>
        <g transform="translate(0.000000,280.000000) scale(0.100000,-0.100000)" fill="%23${hex.slice(1)}" stroke="none">
          <path d="M1095 1847 c-118 -62 -213 -159 -266 -271 -22 -48 -39 -90 -37 -92 3
          -2 21 20 41 48 51 74 122 142 141 135 8 -3 19 -1 23 5 4 8 54 -38 134 -122
          l128 -135 -132 -132 -132 -133 23 -22 22 -22 130 134 130 135 130 -135 130
          -134 22 22 23 22 -132 133 -132 132 128 134 c79 83 130 130 135 123 3 -6 14
          -8 22 -5 19 7 90 -61 141 -135 20 -28 38 -50 41 -48 2 2 -15 44 -37 92 -53
          113 -148 210 -268 272 -87 45 -135 57 -91 23 13 -11 42 -29 65 -40 54 -29 76
          -55 77 -91 1 -26 -18 -49 -126 -157 l-128 -128 -127 128 c-109 108 -128 131
          -127 157 1 36 23 62 77 91 23 11 52 29 65 40 45 35 -6 22 -93 -24z"/>
        </g>
      </svg>
    `
  } else {
    svg = `
      <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="%23${hex.slice(1)}">
        <circle cx="18" cy="18" r="18"/>
      </svg>
    `
  }

  return `data:image/svg+xml;utf8,${svg}`
}
