import React, { useEffect, useRef, useState } from 'react';
import { 
    ModalBody, 
    ModalContent, 
    ModalFooter, 
    ModalHeader, 
    ModalWrapperPopUp 
} from './partials';
import { Rnd } from 'react-rnd'

let PopUpModal = ({...props}) => {
    let {
        type,
        popup,
        header,
        body,
        footer,
        classBody,
        position = {x: 80,y: 200},
        dragndrop= true,
        resizeable = false,
        resizeUpdate = {
          height:385,
          width:480
        },
        setResizeUpdate
    } = props;
    const [positionUpdate, setPositionUpdate] = useState({
        x: 0,
        y: 0
    })
    const grayScreenRef = useRef(null)
    useEffect(()=>{
      if(grayScreenRef.current!==null){
        const xN=(grayScreenRef.current.clientWidth/2)-(resizeUpdate.width/2)
        const yN=(grayScreenRef.current.clientHeight/2)-(resizeUpdate.height/2)
        setPositionUpdate({x:xN,y:yN})
      }
    },[resizeUpdate])

    const chartResult = () => {
      return(
        <>
              <ModalHeader className='dragMe'>
                  {header()}
              </ModalHeader>
              <ModalBody className={classBody} type={type}>
                  {body()}
              </ModalBody>
              <ModalFooter type={type}>
                  {footer()}
              </ModalFooter>
        </>
        )
    }

    const styleContainer = {
        fontFamily: 'Abel',
        borderRadius: '4px',
        backgroundColor: 'white'
    }

    const enableResizeable = () => {
        if (resizeable) {
          return {
            top: true,
            right: true,
            bottom: true,
            left: true,
            topRight: true,
            bottomRight: true,
            bottomLeft: true,
            topLeft: true
          }
        } else if (!resizeable) {
          return {
            top: false,
            right: false,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false
          }
        }
      }
      const posX = (position, positionUpdate) => {
        if (position.x !== null && positionUpdate.x === 0) {
          return position.x
        } else {
          return positionUpdate.x
        }
      }
      
      const posY = (position, positionUpdate) => {
        if (position.y !== null && positionUpdate.y === 0) {
          return position.y
        } else {
          return positionUpdate.y
        }
      }

    return (<>
      <ModalWrapperPopUp toggle={popup} ref={grayScreenRef}>
            <Rnd
            style={styleContainer}
            size={{ width: resizeUpdate.width, height: resizeUpdate.height }}
            position={{
            x: posX(position, positionUpdate),
            y: posY(position, positionUpdate)
            }}
            dragHandleClassName='dragMe'
            maxWidth='1000px'
            maxHeight='1000px'
            minWidth='400px'
            minHeight='200px'
            enableResizing={enableResizeable()}
            disableDragging={!dragndrop}
            onDragStop={(e, d) => {
            setPositionUpdate({ x: d.x, y: d.y })
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
            setResizeUpdate({
                width: ref.style.width,
                height: ref.style.height
            })
            }}
        >
      {chartResult()}
         </Rnd>
         </ModalWrapperPopUp>
    </>
    );
}

export default PopUpModal;