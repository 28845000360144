import React, { useState, useEffect } from 'react'

import axiosReact from '../../../../../../apis/axiosReact'

import ReactLoading from 'react-loading'

import { SINGULAR_ADMINISTRATION } from './constant'

import styled from 'styled-components'

const OuterWrapper = styled.div`
  position: absolute;
  bottom: -0.2em;
`

const FixedWrapper = styled.div`
  position: fixed;
  z-index: 1;
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  border-radius: 5px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 11em;
`

const TextContainer = styled.div`
  font-family: Abel;
`

const TextSmallHeader = styled(TextContainer)`
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  padding-left: 1em;
  font-size: 0.7em;
  font-weight: 600;
  cursor: default;
`

const TextAdministration = styled(TextContainer)`
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  padding-left: 1em;
  font-size: 0.85em;
  cursor: pointer;
  transition: background-color 330ms;

  &:hover {
    background-color: #cacaca;
  }
`

const GET_ID = {
  Tenements: {
    searchValue: 'wiup_id',
    search: 'id'
  },
  BusinessNames: {
    searchValue: 'wiup_id',
    search: 'id'
  }
}

function SearchRecommendation({
  parentRef,
  loading,
  data,
  selectHandler,
  generateDataSource
}) {
  const [ width, setWidth ] = useState(0)
  const [ height, setHeight ] = useState('0em')
  const [ dataFound, setDataFound ] = useState(null)

  useEffect(() => {
    if (parentRef.current) {
      setWidth(parentRef.current.getBoundingClientRect().width)
    }
  }, [parentRef])

  useEffect(() => {
    if (loading) {
      setHeight('2em')
    } else if (!checkData()) {
      setHeight('2em')
      setDataFound(false)
    } else {
      setHeight('auto')
      setDataFound(true)
    }
  }, [loading, data])

  function checkData() {
    for (let key in data) {
      if (data[key].length) {
        return true
      }
    }

    return false
  }

  async function fetchAdministrationGeoJson({
    searchLevel,
    search
  }) {
    try {
      if (searchLevel !== 'wiup_id') {
        // the time is not worthy to wait
        // const { data } = await axiosReact.get('/geofix/administration', {
        //   params: {
        //     [searchLevel]: search
        //   }
        // })
  
        // await generateDataSource(data)
      } else {
        const { data } = await axiosReact.get(`/geofix/tenements/${search}`)

        await generateDataSource({
          features: [data]
        })
      }
    } catch (error) {
      throw error
    }
  }

  function normalizeText(longText) {
    return longText.split(',').join(', ').split(' ')
      .map((text) => {
        return text[0] + text.slice(1).toLowerCase()
      })
      .join(' ')
  }

  return <OuterWrapper>
    <FixedWrapper>
      <InnerWrapper style={{
        width,
        height,
        justifyContent: loading || !dataFound ? 'center' : 'flex-start',
        alignItems: loading || !dataFound ? 'center' : 'flex-start'
      }}>
        {
          loading && <ReactLoading color="black" height="1em" width="1em" type="spin" />
        }
        {
          !loading && dataFound === false && <TextContainer>data not found</TextContainer>
        }
        {
          !loading && dataFound === true && Object.keys(data).map((administrationLevel, index) => {
            if (!data[administrationLevel].length) {
              return null
            }

            return <div key={index}>
              <TextSmallHeader>
                {administrationLevel}
              </TextSmallHeader>
              {
                data[administrationLevel].map((administration, index) => {
                  let text = ''
                  let valueData = ''

                  if (!GET_ID[administrationLevel]) {
                    valueData = Object.values(administration).reverse()

                    text = valueData.join(', ')
                  } else {
                    const firstText = normalizeText(administration.firstText)
                    const secondText = normalizeText(administration.secondText)

                    text = [firstText, secondText].join(' - ')
                  }

                  return <TextAdministration
                    key={index}
                    style={{
                      width: width - 30
                    }}
                    onClick={async () => {
                      let searchLevel = ''
                      let search = ''
                      let keyword = ''

                      if (!GET_ID[administrationLevel]) {
                        searchLevel = SINGULAR_ADMINISTRATION[administrationLevel]
                        search = valueData[0]
                        keyword =  valueData.join(', ')
                      } else {
                        searchLevel = GET_ID[administrationLevel].searchValue
                        search = administration[GET_ID[administrationLevel].search]
                        keyword = text
                      }

                      selectHandler({
                        newSelectedKeyword: `[${administrationLevel}]: ${keyword}`,
                        searchLevel,
                        search
                      })

                      await fetchAdministrationGeoJson({
                        searchLevel,
                        search
                      })
                    }}
                  >
                    {text}
                  </TextAdministration>
                })
              }
            </div>
          })
        }
      </InnerWrapper>
    </FixedWrapper>
  </OuterWrapper>
}

export default SearchRecommendation
