import styled from "styled-components"
import DefaultSearch from '../../SearchBoxs/DefaultSearch';
import SimilarProjectMenuDropdown from '../../Dropdowns/SimilarProjectMenuDropdown';
import downloadAllIcon from "../../../images/Icons/downloadAll.svg"

const ContainerHeaderMenu = styled.div`
    position: relative;
    display: flex;
    height: 33px;
    padding-bottom: 5px;
    border-bottom: 2px solid rgb(0, 0, 90);
    flex-direction: column;
`;
const ContainerHeaderSubMenu = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
`;
const LeftSectionHeader = styled.div`
    display: flex;
    flex-direction: row;
    width: 400px;
`;

const HeaderMenu = ({...props}) =>{
    let {
        setKeySearch,
        sortOptions,
        setKeySort,
        toggleIconDownload,
    } = props;

    let optionsDropdownSort=()=>{
        let nosort={
            pickName: 'paper_id',
            pickShow:'Id'
        }
        return (
            [nosort,...sortOptions]
        )
    }

    return(
        <ContainerHeaderMenu>
            <ContainerHeaderSubMenu>
                <LeftSectionHeader>
                    <div style={{width:'200px', marginRight:'5px'}}>
                        <DefaultSearch
                            active={true}
                            searchProcess={(keyword) => {
                                if (!keyword) {
                                    setKeySearch('')
                                } else {
                                    setKeySearch(keyword)
                                }
                            }}
                            stopSearch={() => {
                                setKeySearch('')
                            }}
                            backgroundColor='rgb(236, 236, 236)'
                            borderColor='rgb(17, 17, 17)'
                            color='black'
                            iconColor='black'
                            borderWidth='0.5px'
                            height='24px'
                            fontSize='0.7em'
                        />
                    </div>
                    <SimilarProjectMenuDropdown
                        marginTop='3px'
                        label={'Sort By'}
                        options={optionsDropdownSort()}
                        setToggle={setKeySort}
                        defaultPick={'paper_id'}
                    />
                </LeftSectionHeader>
                {/* {toggleIconDownload &&
                    <img src={downloadAllIcon}
                        alt='download'
                        style={{width:'23px', cursor:'pointer'}}
                        title='Open all checked references'
                        onClick={()=>{
                            // console.log('download all')
                            // selectedDownload.sort().map((i)=>i)
                        }}
                    />
                } */}
            </ContainerHeaderSubMenu>
        </ContainerHeaderMenu>
    )
}

export default HeaderMenu;