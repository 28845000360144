import React from 'react'

import ProspectMenuPNG from '../../HelpImage/prospect-detail.png'
import ProspectMenuOverviewPNG from '../../HelpImage/prospect-menu-overview.png'
import ProspectMenuSimilarProjectPNG from '../../HelpImage/prospect-menu-similar-project.png'
import ProspectMenuRankingCriteriaPNG from '../../HelpImage/prospect-menu-ranking-criteria.png'
import ProspectMenuReferencesPNG from '../../HelpImage/prospect-menu-references.png'
import ProspectMenuGlossaryPNG from '../../HelpImage/prospect-menu-glossary.png'

import FocusContainer, {
  Header,
  Paragraph,
  List,
  LinkText
} from '../../StyledComponent'
import { LIST_HELP } from '../../constant'

function HelpProspectMenu(props) {
  const flyTo=(x)=>{
    props.setFocus(x)
  }
  return <>
    <FocusContainer {...props}>
      <Header>{LIST_HELP['menu'].detail.prospectLayer.header}</Header>
      <Paragraph>
        Detail layer of the prospect will appear if the prospect has been selected, to select a prospect you can open a <LinkText onClick={()=>flyTo('prospect')}>prospect bar</LinkText>.
      </Paragraph>
      <img src={ProspectMenuPNG} alt="Prospect menu image" width="550px" style={{marginBottom:"1em", marginLeft:"1em"}}/>
      <Paragraph>
        1. Overview
      </Paragraph>
      <ul style={{ margin: 0 }}>
        <List>Executive Summary - The prospect about.</List>
        <List>Prospects are assessed from the technical parameters.</List>
        <List>Prospects are assessed from the non-technical parameters.</List>
      </ul>
      <img src={ProspectMenuOverviewPNG} alt="Technical Parameter image" width="500px" style={{marginBottom:"1em", marginLeft:"1em"}}/>
      <Paragraph>
        2. Ranking Criteria
      </Paragraph>
      <ul style={{ margin: 0 }}>
        <List>Technical and Non Technical Parameters</List>
        <List>Geological Stratigraphy and Structure </List>
      </ul>
      <img src={ProspectMenuRankingCriteriaPNG} alt="Ranking Criteria image" width="1100px" style={{marginBottom:"1em", marginLeft:"0.5em"}}/>
      <Paragraph>
        3. Similar Project
      </Paragraph>
      <Paragraph style={{ marginLeft:'1em', marginBottom:'0.5em'}}>
        To compare two prospect areas with almost similar properties.
      </Paragraph>
      <img src={ProspectMenuSimilarProjectPNG} alt="Similar Project image" width="1000px" style={{marginBottom:"1em"}}/>
      <Paragraph>
        4. References
      </Paragraph>
      <Paragraph style={{ marginLeft:'1em', marginBottom:'0.5em'}}>
      References are used to see the relationship between journal publications and prospect locations.
      </Paragraph>
      <img src={ProspectMenuReferencesPNG} alt="References image" width="550px" style={{marginBottom:"1em"}}/>
      <Paragraph>
        5. Glossary
      </Paragraph>
      <Paragraph style={{ marginLeft:'1em', marginBottom:'0.5em'}}>
      Glossary is used to see an explanation of the terms in this prospect.
      </Paragraph>
      <img src={ProspectMenuGlossaryPNG} alt="Glossary image" width="500px" style={{marginBottom:"1em", marginLeft:"1em"}}/>
    </FocusContainer>
  </>
}

export default HelpProspectMenu
