import * as actionTypes from '../../constants/store/actionTypes/drag'

export function dragMap(payload) {
  return {
    type: actionTypes.DRAG_MAP,
    payload
  }
}

export function dropMap(payload) {
  return {
    type: actionTypes.DROP_MAP,
    payload
  }
}

export function resetDrag(payload) {
  return {
    type: actionTypes.RESET_DRAG,
    payload
  }
}
