import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3'; 

import cesiumViewer from '../../apis/cesiumViewer';

// import { connect } from 'react-redux';

import * as Cesium from 'cesium';

import { 
    CesiumContainer, 
    CesiumToolbarButton, 
    // CesiumToolbarButton, 
    CesiumWrapper 
} from './partials';
// import Spinner from '../Spinner/';
import { pointsViewer } from './pointsViewer';
// import {
//     analysisActionCreators as analysisActions,
// } from '../../../../store/actions';
// import { fetchInfoGeochemSurface } from '../../../../api/server';
// import { CesiumUtils } from '../../../../api/cesium/utils';
import { 
    CESIUM_ION_DEFAULT_ACCESS_TOKEN, 
    DEFAULT_VIEW_FACTOR, 
    HOME_EXTENT_EAST, 
    HOME_EXTENT_NORTH, 
    HOME_EXTENT_SOUTH, 
    HOME_EXTENT_WEST 
} from '../../constants/Cesium/config';
import SelectTools from './SelectTools';

import {ReactComponent as RectIcon} from '../../images/Icons/rect-tools.svg';
import {ReactComponent as PolygIcon} from '../../images/Icons/poly-tools.svg';
import {ReactComponent as LassoIcon} from '../../images/Icons/lasso-tools.svg';
import {ReactComponent as CircIcon} from '../../images/Icons/circle-tools.svg';
import {ReactComponent as ClearIcon} from '../../images/Icons/clear-tools.svg';
import {ReactComponent as GroupIcon} from '../../images/Icons/group-select-tools.svg';
import { CesiumUtils } from '../../apis/cesiumMap/utils';
import { pointsInPolygon } from '../Charts/helpers/mathFunction';
import { pointUtm } from './pointUtm';
import iziToast from 'izitoast';

/**
 * 
 * @param {string} selectTools se 
 * @returns 
 */

function createCesiumOptions() {
    const imagerProviderViewModels = Cesium.createDefaultImageryProviderViewModels()

    return {
        // widget
        animation: false,
        geocoder: false,
        navigationHelpButton: false,
        selectionIndicator: false,
        timeline: false,
        // make scene only available in 3d
        // scene3DOnly: true,
        // set image provider to esri
        baseLayerPicker: true,
        imageryProviderViewModels: imagerProviderViewModels,
        selectedImageryProviderViewModel: imagerProviderViewModels[3],
        shadows: true,
        mapProcjection: new Cesium.WebMercatorProjection(),
        // set terrain
        terrainProvider: Cesium.createWorldTerrain({
            requestVertexNormals : true,
            requestWaterMask : true
        }),
        // terrainExaggeration: 1,
        // for performance, need to implement after the beta version finished
        // requestRenderMode: true
    }
}



let AnalyticsMap = ({...props}) => {
    let {
        data,
        onViewerLoad,
        toggleSelection,
        selectedMap,
        selectedScatter,
        setSelectionOnMap,
        onDropHandler,
        onDragOverHandler,
        utmZone= {
            number:1,
            region:'n'
        },
    } = props;

    let [viewer_, setViewer] = useState(null);
    let [utils_, setUtils] = useState(null);
    let [loadingMap, setLoadingMap] = useState(true)
    let [selectTools, setSelectTools] = useState(false)
    let [labelEntity, setLabelEntity] = useState()
    let [coordSelection, setCoordSelection] = useState([])
    let containerRef = useRef(null)
    let [dataUsed, setDataUsed] = useState([])
    

    useEffect(() => {
        Cesium.Ion.defaultAccessToken = CESIUM_ION_DEFAULT_ACCESS_TOKEN

        let homeExtent = new Cesium.Rectangle.fromDegrees(
            HOME_EXTENT_EAST, // E
            HOME_EXTENT_SOUTH, // S
            HOME_EXTENT_WEST, // W
            HOME_EXTENT_NORTH, // N
        );
        
        Cesium.Camera.DEFAULT_VIEW_RECTANGLE = homeExtent;
        Cesium.Camera.DEFAULT_VIEW_FACTOR = DEFAULT_VIEW_FACTOR;
        
        let imageryProvideViewModels = Cesium.createDefaultImageryProviderViewModels();
        let viewer = new Cesium.Viewer(containerRef.current, {
            animation: false,
            geocoder: false,
            navigationHelpButton: false,
            selectionIndicator: false,
            timeline: false,
            fullscreenButton:false,
            // set esri imager
            baseLayerPicker: true,
            imageryProviderViewModels: imageryProvideViewModels,
            selectedImageryProviderViewModel: imageryProvideViewModels[3],
            // terrainProvider: Cesium.createWorldTerrain({
                // requestVertexNormals : true,
                // requestWaterMask : true
            // }),
            // mapProjection: new Cesium.WebMercatorProjection(),
            
            // save GPU memory
            // scene3DOnly: true, // scene only in 3d
            automaticallyTrackDataSourceClocks: false,
            // framerate?
            targetFrameRate: 30,
        });

        // viewer.scene.debugShowFramesPerSecond = true;
        viewer.scene.screenSpaceCameraController.enableTranslate = false;
        // viewer.scene.screenSpaceCameraController.enableTilt = false;
        viewer.scene.screenSpaceCameraController.enableLook = false;
        viewer.scene.screenSpaceCameraController.enableCollisionDetection = false;
        setViewer(viewer);

        onViewerLoad && onViewerLoad.constructor === Function &&
            onViewerLoad(viewer);

        let handleUtilsExport = selectTools && selectTools.constructor === Function ?
        (payload) => {
            selectTools(payload);
        } :
        (payload) => {
            setCoordSelection(payload.geojson.geometry.coordinates[0])
        }

        let utils = new CesiumUtils(viewer, {
            exportHandler: handleUtilsExport,
        });
        setUtils(utils)
        
        cesiumViewer.viewer = viewer;
        return () => {
            viewer && viewer.destroy();
            setViewer(null);
            cesiumViewer.viewer = null
        }

    }, []);

    // useEffect(()=>{
    //     if(!viewer_) return;

    //     let handleUtilsExport = selectTools && selectTools.constructor === Function ?
    //     (payload) => {
    //         selectTools(payload);
    //     } :
    //     (payload) => {
    //         setCoordSelection(payload.geojson.geometry.coordinates[0])
    //     }

    //     let utils = new CesiumUtils(viewer_, {
    //         exportHandler: handleUtilsExport,
    //     });
    //     setUtils(utils)
    // },[containerRef.current])


    useEffect(()=>{
        if(!viewer_) return;
        if(data.length > 0){
            if(Object.keys(data[0]).indexOf("latitude") === -1 &&
                Object.keys(data[0]).indexOf("longitude")===-1){
                let confirmZone = utmZone.number
                let coordToMap=[]
                if(confirmZone===null || confirmZone == "") {
                    iziToast.warning({
                        title: 'No data',
                        message: 'Your data unavailable to show on map'
                    })
                }else{
                    let position=utmZone.region
                    data.map((i)=>{
                        coordToMap.push(pointUtm({
                            easting:Number(i.easting),
                            northing:Number(i.northing),
                            region: position,
                            zone: confirmZone,
                            id:i.id
                        }))
                    })
                }
                coordToMap = coordToMap.filter(function( element ) {
                    return element !== undefined;
                 });
                setDataUsed(coordToMap)
                pointsViewer({
                    view: viewer_,
                    data: coordToMap,
                    color: Cesium.Color.INDIANRED,
                    outlineColor: Cesium.Color.BROWN
                })
                // 6.1754° S, 106.8272° E
                // const monasPosition = pointUtm({
                //     easting: 702178.12,
                //     northing: 9317060.29,
                //     region: 's',
                //     zone:48,
                //     id:'monas'
                // })
                // pointsViewer({
                //     view: viewer_,
                //     data: [monasPosition],
                //     color: Cesium.Color.INDIANRED,
                //     outlineColor: Cesium.Color.BROWN
                // })
            }else{
                setDataUsed(data)
                pointsViewer({
                    view: viewer_,
                    data: data,
                    color: Cesium.Color.INDIANRED,
                    outlineColor: Cesium.Color.BROWN
                })
            }
            
        }
    },[data])
    
    useEffect(()=>{
        if(!viewer_) return;
        if(selectedScatter.length!==0 && selectedMap.length===0 ){
            let unhighlightScatter= dataUsed.filter(item=> !selectedScatter.includes(item.id));
            let highlightScatter= dataUsed.filter(item=> selectedScatter.includes(item.id));
            highlightScatter.forEach(e => {
                if(e.entity !== undefined){
                    e.entity.point.color = Cesium.Color.DODGERBLUE
                    e.entity.point.outlineColor = Cesium.Color.DEEPSKYBLUE
                }
            });
            unhighlightScatter.forEach(e=>{
                if(e.entity!==undefined){
                    e.entity.point.color=Cesium.Color.GRAY.withAlpha(0.3)
                    e.entity.point.outlineColor = Cesium.Color.LIGHTGRAY.withAlpha(0.3)
                }
            })
        } else if(selectedScatter.length===0 && selectedMap.length!==0){
            let unhighlightMap= dataUsed.filter(item=> !selectedMap.includes(item.id));
            let highlightMap= dataUsed.filter(item=> selectedMap.includes(item.id));
            highlightMap.forEach(e => {
                if(e.entity !== undefined){
                    e.entity.point.color = Cesium.Color.GOLDENROD
                    e.entity.point.outlineColor = Cesium.Color.GOLD
                }
            });
            unhighlightMap.forEach(e=>{
                if(e.entity !== undefined){
                    e.entity.point.color=Cesium.Color.GRAY.withAlpha(0.3)
                    e.entity.point.outlineColor = Cesium.Color.LIGHTGRAY.withAlpha(0.3)
                }
            })
        } else if(selectedScatter.length!==0 && selectedMap.length!==0){
            let unhighlight= dataUsed.filter(item=> !selectedScatter.includes(item.id) && !selectedMap.includes(item.id));
            let highlightScatter= dataUsed.filter(item=> selectedScatter.includes(item.id));
            let highlightMap= dataUsed.filter(item=> selectedMap.includes(item.id));
            unhighlight.forEach(e=>{
                if(e.entity !== undefined){
                    e.entity.point.color=Cesium.Color.GRAY.withAlpha(0.3)
                    e.entity.point.outlineColor = Cesium.Color.LIGHTGRAY.withAlpha(0.3)
                }
            })
            highlightMap.forEach(e => {
                if(e.entity !== undefined){
                    e.entity.point.color = Cesium.Color.GOLDENROD
                    e.entity.point.outlineColor = Cesium.Color.GOLD
                }
            });
            highlightScatter.forEach(e => {
                if(e.entity !== undefined){
                    e.entity.point.color = Cesium.Color.DODGERBLUE
                    e.entity.point.outlineColor = Cesium.Color.DEEPSKYBLUE
                }
            });
        } else {
            if(dataUsed.length!==0){
                dataUsed.forEach(e=>{
                    if(e.entity !== undefined){
                        e.entity.point.color=Cesium.Color.INDIANRED
                        e.entity.point.outlineColor = Cesium.Color.BROWN
                    }
                })
            }
        }
    },[selectedScatter, selectedMap])

    useEffect(()=>{
        if(coordSelection.length===0) return;
        let selected_=[];
            dataUsed.map((map)=>{
                let point=[map.longitude,map.latitude]
                if (pointsInPolygon(point,coordSelection)){
                    selected_.push(map.id);
                }
            })
        setSelectionOnMap(selected_)
    },[coordSelection])

    // if(viewer_ !== null){
    //     var helper = new Cesium.EventHelper();
    //     helper.add(viewer_.scene.globe.tileLoadProgressEvent, function (event) {
    //         setLoadingMap(true)
    //         if (event === 0) {
    //             setLoadingMap(false)
    //         }
    //     });
    // }

    // useEffect(()=>{
    //     if(selectedMap.length!==0){
    //         const filter_= dataAnalysis
    //             .filter(function(d, i) {
    //                 return selectedMap.includes(d._id);
    //             });
                
    //         setDataFilterMap(filter_)

    //         let d={}
    //         filter_.forEach((row) => {
    //             Object.entries(row).forEach(([key,value])=>{
    //                 if(!(key in d)){
    //                     d[key]=[]
    //                 }
    //                 d[key].push(value); 
    //             })     
    //         });
    //         let {"_id": deletedKey,"_sample_id": deletedKey2, ...otherKeys} = d;
    //         setDataGroupFilterMap(otherKeys)
    //     }else{
    //         setDataFilterMap([])
    //         setDataGroupFilterMap({})
    //     }
    // },[selectedMap])


    let disableAllSelect = () => {
        utils_ && utils_.disableDrawRect();
        utils_ && utils_.disableDrawCirc();
        utils_ && utils_.disableDrawPolyg();
        utils_ && utils_.disableDrawFreehand();
        d3.selectAll(".selection").remove();
        d3.selectAll(".terminator").remove();
        viewer_.entities.remove(labelEntity)
    };

    let handleSelectRect = () => {
        disableAllSelect();
        const viewerLabel = new Cesium.ScreenSpaceEventHandler(viewer_.scene.canvas);
        const labelEntity = viewer_.entities.add({
            id:'label',
            name: 'label',
            label: {
                show: false,
                showBackground: true,
                font: "11px abel",
                horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                pixelOffset: new Cesium.Cartesian2(15, 0),
            },
        });
        setLabelEntity(labelEntity)
        viewerLabel.setInputAction(function (movement) {
            const cartesian = viewer_.camera.pickEllipsoid(
                movement.endPosition,
                viewer_.scene.globe.ellipsoid
            );
        
            labelEntity.position = cartesian;
            labelEntity.label.show = true;
            labelEntity.label.text =
                "Hold down 'shift' key,"+
                "\npress down to start from top left corner, "+
                "\ndrag to set area, let go to finish"
        }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
        
        utils_ && utils_.enableDrawRect();
    };

    let handleSelectPolyg = () => {
        disableAllSelect();
        const viewerLabel = new Cesium.ScreenSpaceEventHandler(viewer_.scene.canvas);
        const labelEntity = viewer_.entities.add({
            name: 'label',
            label: {
                show: false,
                showBackground: true,
                font: "11px abel",
                horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                pixelOffset: new Cesium.Cartesian2(15, 0),
            },
        });
        setLabelEntity(labelEntity)
        viewerLabel.setInputAction(function (movement) {
            const cartesian = viewer_.camera.pickEllipsoid(
                movement.endPosition,
                viewer_.scene.globe.ellipsoid
            );
        
            labelEntity.position = cartesian;
            labelEntity.label.show = true;
            labelEntity.label.text =
                "Hold down 'shift' key,"+
                "\nclick to add point for polygon corner, "+
                "\nback click the first point to finish"
        }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
        utils_ && utils_.enableDrawPolyg();
    };

    let handleSelectLasso = () => {
        disableAllSelect();
        const viewerLabel = new Cesium.ScreenSpaceEventHandler(viewer_.scene.canvas);
        const labelEntity = viewer_.entities.add({
            name: 'label',
            label: {
                show: false,
                showBackground: true,
                font: "11px abel",
                horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                pixelOffset: new Cesium.Cartesian2(15, 0),
            },
        });
        setLabelEntity(labelEntity)
        viewerLabel.setInputAction(function (movement) {
            const cartesian = viewer_.camera.pickEllipsoid(
                movement.endPosition,
                viewer_.scene.globe.ellipsoid
            );
        
            labelEntity.position = cartesian;
            labelEntity.label.show = true;
            labelEntity.label.text =
                "Hold down 'shift' key,"+
                "\npress down to start, "+
                "\ndrag to draw, let go to finish"
        }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
        utils_ && utils_.enableDrawFreehand();
    };

    let handleSelectCirc = () => {
        disableAllSelect();
        const viewerLabel = new Cesium.ScreenSpaceEventHandler(viewer_.scene.canvas);
        const labelEntity = viewer_.entities.add({
            name: 'label',
            label: {
                show: false,
                showBackground: true,
                font: "11px abel",
                horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                pixelOffset: new Cesium.Cartesian2(15, 0),
            },
        });
        setLabelEntity(labelEntity)
        viewerLabel.setInputAction(function (movement) {
            const cartesian = viewer_.camera.pickEllipsoid(
                movement.endPosition,
                viewer_.scene.globe.ellipsoid
            );
        
            labelEntity.position = cartesian;
            labelEntity.label.show = true;
            labelEntity.label.text =
                "Hold down 'shift' key,"+
                "\npress down to start from center, "+
                "\ndrag to set radius, let go to finish"
        }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
        utils_ && utils_.enableDrawCirc();
    };
    let handleClear = () => {
        setSelectionOnMap([]);
        disableAllSelect();
    };
    let handleOpenSelection = () => {
        setSelectTools(!selectTools)
    }

    return (
        <CesiumWrapper
            onDrop={onDropHandler}
            onDragOver={onDragOverHandler}
        >
            <CesiumContainer ref={containerRef}/>
                {containerRef.current &&
                    <SelectTools parentRef={containerRef}>
                        <CesiumToolbarButton>
                            <GroupIcon
                                title='Selection Tools'
                                // className='cesium-svgPath-svg'
                                width={28}
                                height={28}
                                onClick={handleOpenSelection}
                            />
                        </CesiumToolbarButton>
                        {viewer_ && selectTools &&
                        <>
                            <CesiumToolbarButton>
                                <LassoIcon
                                    title='Freehand Select Tools'
                                    className='cesium-svgPath-svg'
                                    width={20}
                                    height={20}
                                    onClick={handleSelectLasso}
                                />
                            </CesiumToolbarButton>
                            <CesiumToolbarButton>
                                <RectIcon
                                    title='Rectangle Select Tools'
                                    className='cesium-svgPath-svg'
                                    width={20}
                                    height={20}
                                    onClick={handleSelectRect}
                                />
                            </CesiumToolbarButton>
                            <CesiumToolbarButton>
                                <PolygIcon
                                    title='Polygon Select Tools'
                                    className='cesium-svgPath-svg'
                                    width={20}
                                    height={20}
                                    onClick={handleSelectPolyg}
                                />
                            </CesiumToolbarButton>
                            <CesiumToolbarButton>
                                <CircIcon
                                    title='Circle Select Tools'
                                    className='cesium-svgPath-svg'
                                    width={20}
                                    height={20}
                                    onClick={handleSelectCirc}
                                />
                            </CesiumToolbarButton>
                            <CesiumToolbarButton>
                                <ClearIcon
                                    title='Clear'
                                    className='cesium-svgPath-svg'
                                    width={20}
                                    height={20}
                                    onClick={handleClear}
                                />
                            </CesiumToolbarButton>
                        </>
                        }
                    </SelectTools>
                }
        </CesiumWrapper>
    );
}

export default AnalyticsMap;