import React, { useState, useEffect } from 'react'

import LayerbarIconRotate from '../../../shares/LayerbarIconRotate'

import {
  ReactComponent as FasterArrowTopBottomIcon
} from '../../../../../../images/Icons/faster-arrow-top-bottom.svg'

/**
 * This function to inverse color by scale
 * @param map is the current map that being edited
 * @param groupBy is the current group by value (for geochemical)
 * @param setGroupEntities to change group entities
 */
function DisplayReverse({
  map
}) {
  const [ isActive, setIsActive ] = useState(false)

  useEffect(() => {
    function eventHandler() {
      setIsActive(map.cesiumLayer.inverse)
    }

    map.cesiumLayer.subscribeEvent('inverse', eventHandler)

    return () => {
      map.cesiumLayer.unsubscribeEvent('inverse', eventHandler)
    }
  }, [])

  function onClickHandler() {
    map.cesiumLayer.setInverse(!map.cesiumLayer.inverse)

    map.cesiumLayer.setTemplate(null)

    const groupEntities = map.cesiumLayer.groupEntities

    const style = groupEntities.style

    const arrStyle = Object.keys(style)

    for (let i = 0; i < arrStyle.length / 2; i++) {
      let holdColor = style[arrStyle[i]].color
      style[arrStyle[i]].color = style[arrStyle[arrStyle.length - 1 - i]].color
      style[arrStyle[arrStyle.length - 1 - i]].color = holdColor
    }
    
    map.cesiumLayer.setGroupEntities({
      ...groupEntities,
      style
    })
  }

  return (
    <LayerbarIconRotate
      isActive={isActive}
      show={true}
    >
      <FasterArrowTopBottomIcon
        title="Reverse Color"
        onClick={onClickHandler}
      />
    </LayerbarIconRotate>
  )
}

export default DisplayReverse
