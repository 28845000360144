import Icon from '../../../shares/Icon'

import styled from 'styled-components'

export const CloseIcon = styled(Icon)`
  position: absolute;
  right: 1em;

  svg {
    width: 2em;
    fill: #e6e5e5;
    cursor: pointer;

    &:hover {
      fill: url(#gold-gradient) goldenrod;
    }
  }
`

export default CloseIcon
