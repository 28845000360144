import styled from 'styled-components'

const DropdownContainer = styled.div.attrs((props) => {
  return {
    style: {
      width: `calc(${props.width} - 0.3em)`,
      height: props.height,
      backgroundColor: props.backgroundColorDefault,
      opacity: props.dropdown ? 0.9 : 0,
      pointerEvents: !props.dropdown && 'none'
    }
  }
})`
  position: absolute;
  padding-left: 0.3em;
  transition: all 330ms;
  border: 1px solid grey;
  border-radius: 5px;
  
  &:hover {
    background-color: ${
      props =>  props.backgroundColorHover
    };
  }
`

export default DropdownContainer
