import React from 'react'

import { ReactComponent as MoveIcon } from '../../../../images/Icons/move.svg'

import { ToggleSVGButton } from '../../../shares/ToggleSVGIcon'

function MovePoint({
  isActive,
  onClickHandler
}) {
  return <ToggleSVGButton
    flex
    margin="0.5em"
    active={isActive}
    onClick={onClickHandler}
  >
    <MoveIcon
      width="1.5em"
      height="1.5em"
      title="Move Point"
    />
  </ToggleSVGButton>
}

export default MovePoint
