import * as actionTypes from '../../constants/store/actionTypes/editor'

const initialState = {
  id: null,
  show: false,
  data: null
}

function editorReducer(state = initialState, { type, payload }) {
  switch(type) {
    case actionTypes.SET_ID_OCCURRENCES:
      return {
        ...state,
        id: payload.id,
        show: true,
        data: payload.data
      }
    case actionTypes.SHOW_EDITOR:
      return {
        ...state,
        show: true
      }
    case actionTypes.HIDE_EDITOR:
      return {
        ...state,
        show: false
      }
    case actionTypes.RESET_EDITOR:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default editorReducer
