import * as actionTypes from '../../constants/store/actionTypes/user'

const initialState = {
  username: '',
  tierDesc: '',
  isAuthenticate: false,
  error: null
}

function userReducer(state = initialState, { type, payload, error }) {
  switch (type) {
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        username: payload.username,
        tierDesc: payload.tierDesc,
        isAuthenticate: true
      }
    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        username: '',
        tierDesc: '',
        isAuthenticate: false
      }
    case actionTypes.LOGIN_ERROR:
      return {
        ...state,
        error
      }
    default:
      return state
  }
}

export default userReducer
