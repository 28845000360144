import React, { useState, useEffect, useRef } from 'react'

import { connect } from 'react-redux'

import axiosReact from '../../../apis/axiosReact'

import {
  changeGradeWeight,
  setWeightScore,
  toggleGradebar,
  toggleMinimizeGradebar
} from '../../../store/actions/gradebar'

import ReactLoading from 'react-loading'

import Header from './Header'
import CheckForm from './CheckForm'
import Progress from './Progress'

import CloseBottomBar from '../../Buttons/CloseBottombar'

import { ReactComponent as UpIcon } from '../../../images/Icons/up.svg'
import { ReactComponent as DownIcon } from '../../../images/Icons/down.svg'

import styled from 'styled-components'

const Container = styled.div.attrs((props) => {
  return {
    style: {
      bottom: props.minimize
        ? '-28rem'
        : 0
    }
  }
})`
  position: absolute;
  height: 26rem;
  bottom: 0;
  z-index: 2;
  background: white;
  transition: width 550ms, left 550ms;
  overflow-x: auto;
  overflow-y: hidden;
  font-family: Abel;
  font-size: 0.85em;
  padding: 1rem 2rem;
  flex-direction: column;
  /* transition: bottom 500ms; */
`

const Loading = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: #333333c1;
  color: white;
  z-index: 3;
  border-radius: 5px;
`

const MinimizeButton = styled.div.attrs((props) => {
  return {
    style: {
      bottom: props.minimize
        ? '2rem'
        : '30rem'
    }
  }
})`
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  left: calc(50vw - 0.3em);
  background-color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 4em;
  height: 0.8em;
  z-index: 3;
  transition: background-color 500ms;
  box-shadow: -1px 1px 9px 1px #3f3f3f;
  cursor: pointer;

  svg {
    width: 0.5em;
    fill: black;
  }

  &:hover {
    background-color: black;
    svg {
      fill: white;
    }
  }
`

/**
 * This component will create grade in the bottom of the windows
 * @param {Object} grade is the global state of gradebar
 * @param {Object} sitebar to minimize when spesific sitebar is opened
 * @param {Object} sidebarState to minimize when spesific sidebar layer is opened
 * @returns 
 */
function Gradebar({
  grade,
  sitebar,
  sidebarState,
  changeGradeWeight,
  setWeightScore,
  toggleGradebar,
  toggleMinimizeGradebar
}) {
  const [ mapBoundingRect, setMapBoundingRect ] = useState({
    width: null,
    left: 0,
    prevWidthStatus: null,
    prevLeftStatus: null
  })

  const [ gridBin, setGridBin ] = useState([])
  
  const [ userRatings, setUserRatings ] = useState({
    commit_id: null,
    commit_name: '',
    committer: '',
    data: []
  })

  const [ unselected, setUnselected ] = useState([])

  const [ progress, setProgress ] = useState({
    loadingId: null,
    solved: {},
    unsolved: {}
  })

  const [ loading, setLoading ] = useState({
    ready: false,
    data: []
  })

  const contentRef = useRef(null)

  async function fetchDefaultWeightScore() {
    try {
      const { data } = await axiosReact.get('/geofix/user_rating_weight')

      const weightScore = grade.weightScore

      for (const category in weightScore) {
        if (category === 'databaseName') {
          continue
        }
        
        for (const subCategory in weightScore[category]) {
          if (subCategory === 'databaseName') {
            continue
          }

          let databaseData = data[weightScore[category][subCategory].databaseName]

          // edit it if the server API change
          if (subCategory === 'Average grade') {
            databaseData = data.geoc_rock_ag_average_grade
          } else if (subCategory === 'Footprint area') {
            databaseData = data.geoc_rock_ag
          }
          
          if (!databaseData) {
            continue
          }

          const {
            weight,
            maximum_weight,
            minimum_weight
          } = databaseData

          weightScore[category][subCategory] = {
            ...weightScore[category][subCategory],
            value: weight,
            max: maximum_weight,
            min: minimum_weight
          }
        }
      }

      setWeightScore({
        weightScore
      })
    } catch (error) {
      throw error
    }
  }

  function clearState() {
    setGridBin((gridBin) => {
      return gridBin.map((bin) => ({
        ...bin,
        status: false
      }))
    })

    setProgress((currentProgress) => ({
      ...currentProgress,
      solved: {},
      unsolved: {}
    }))

    setUnselected([])
  }

  async function resetConfig() {
    clearState()

    setUserRatings((userRatings) => ({
      ...userRatings,
      commit_id: null,
      commit_name: '',
      committer: ''
    }))

    await fetchDefaultWeightScore()
  }

  function checkLeft() {
    for (let key in sidebarState) {
      if (key === 'globe') {
        continue
      }
      if (key === 'menu') {
        continue
      }

      if (sidebarState[key].status) {
        return '20rem'
      }
    }

    return 0
  }

  function checkWidth() {
    if (sitebar.toggle && !sitebar.minimize && checkLeft()) {
      return `calc(100% - ${sitebar.width} - 24rem)`
    } else if (sitebar.toggle && !sitebar.minimize) {
      return `calc(100% - ${sitebar.width} - 4rem)`
    } else if (checkLeft()) {
      return `calc(100% - 24rem)`
    }

    return 'calc(100% - 4rem)'
  }

  function generateBinSize(binSize) {
    if (binSize >= 1000) {
      return (binSize / 1000) + ' km'
    } else {
      return binSize + ' m'
    }
  }
  
  async function pickCommit(commit) {
    let loadingId = await generateLoadingMessage(`Fetch commit data ${commit.commit_info.commit_name}`)
    
    const { data } = await axiosReact.get(`/geofix/user_rating/${commit.id}`)
    
    clearState()

    setUserRatings((userRatings) => {
      return {
        ...userRatings,
        commit_id: commit.id,
        commit_name: data.commit_info.commit_name || '',
        committer: data.commit_info.committer
      }
    })

    if (data.bin_size) {
      setGridBin((gridBin) => {
        return gridBin.map((bin) => {
          return {
            ...bin,
            status: data.bin_size.includes(Number(bin.bin_size))
          }
        })
      })
    }
    
    if (data.aspects) {
      const weightScore = grade.weightScore

      const aspectUnselected = []

      for (const category in weightScore) {
        let isUnselected = true

        if (category === 'databaseName') {
          continue
        }

        const dataCategory = data.aspects[weightScore[category].databaseName]
        
        if (dataCategory) {
          for (const subCategory in weightScore[category]) {
            if (subCategory === 'databaseName') {
              continue
            }
            
            const scoreSubCategory = dataCategory[weightScore[category][subCategory].databaseName]
            
            if (scoreSubCategory === undefined || scoreSubCategory === null) {
              weightScore[category][subCategory].value = null
            } else {
              isUnselected = false
              weightScore[category][subCategory].value = scoreSubCategory
            }
          }
        } else {
          for (const subCategory in weightScore[category]) {
            if (subCategory === 'databaseName') {
              continue
            }

            weightScore[category][subCategory].value = null
          }
        }

        if (isUnselected === true) {
          aspectUnselected.push(category)
        }
      }

      setWeightScore({
        weightScore
      })

      setUnselected(aspectUnselected)
    }

    clearLoadingMessage(loadingId)
  }

  async function generateLoadingMessage(message) {
    return new Promise((resolve) => {
      setLoading((currentLoading) => {
        let id = 1

        const cloneData = currentLoading.data.slice()
  
        if (cloneData.length) {
          id = cloneData[cloneData.length - 1].id + 1
        }
  
        cloneData.push({
          id,
          message
        })

        resolve(id)
  
        return {
          ...currentLoading,
          data: cloneData
        }
      })
    })
  }

  function clearLoadingMessage(id) {
    setLoading((currentLoading) => {
      const cloneData = currentLoading.data.slice()
        .filter((loadingInfo) => loadingInfo.id !== id)

      return {
        ...currentLoading,
        data: cloneData
      }
    })
  }

  useEffect(() => {
    if (
      sitebar.toggle
      && !sitebar.minimize
      && sidebarState.layer.status
      && grade.status  
    ) {
      toggleGradebar()
    }

    setMapBoundingRect({
      ...mapBoundingRect,
      width: 'calc(100% - 4rem)',
      left: 0
    })

    setTimeout(() => {
      setMapBoundingRect({
        ...mapBoundingRect,
        width: checkWidth(),
        left: checkLeft()
      })
    }, 550)
  }, [sidebarState])

  useEffect(() => {
    if (sitebar.status === 'hide'
      && sidebarState.layer.status
      && grade.status  
    ) {
      toggleGradebar()
    }

    setMapBoundingRect({
      ...mapBoundingRect,
      width: checkWidth()
    })
  }, [sitebar])

  useEffect(() => {
    async function fetchBinGrid() {
      try {
        const { data } = await axiosReact.get('/geofix/grid')

        data.forEach((binData) => {
          binData.status = false
        })

        // limiting to 1km
        const filterData = data.filter((bin) => bin.bin_size >= 1000)

        setGridBin(filterData)
      } catch (error) {
        throw error
      }
    }

    async function fetchUserRatings() {
      try {
        const { data } = await axiosReact.get('/geofix/user_rating')

        setUserRatings({
          ...userRatings,
          data
        })
      } catch (error) {
        throw error
      }
    }

    // fetchDefaultWeightScore()
    // fetchBinGrid()
    // fetchUserRatings()

    return () => {
      setGridBin([])
      setUserRatings({
        commit_id: null,
        commit_name: '',
        committer: '',
        data: []
      })
      setUnselected([])
      setProgress({
        loadingId: null,
        solved: {},
        unsolved: {}
      })
      setLoading({
        ready: false,
        data: []
      })
    }
  }, [])

  useEffect(() => {
    if (contentRef.current) {
      setLoading((currentLoading) => ({
        ...currentLoading,
        ready: true
      }))
    }
  }, [contentRef])
  
  useEffect(() => {
    let checkProgress = null

    async function checkProgressMapInfo() {
      if (progress.loadingId) {
        clearLoadingMessage(progress.loadingId)
      }

      setProgress((objProgress) => ({
        ...objProgress,
        loadingId: userRatings.commit_id,
        finish: false,
        solved: {},
        unsolved: {}
      }))

      checkProgress = setInterval(async () => {
        const { data } =  await axiosReact.get('/geofix/user_rating_progress/' + userRatings.commit_id)

        let solved = true

        const objProgress = {
          loadingId: userRatings.commit_id,
          finish: false,
          solved: {},
          unsolved: {}
        }

        for (let bin_size in data.progress) {
          if (data.progress[bin_size] < 100) {
            solved = false

            objProgress.unsolved[bin_size] = data.progress[bin_size]
          } else {
            objProgress.solved[bin_size] = data.progress[bin_size]
          }
        }

        if (solved) {
          clearInterval(checkProgress)
          objProgress.finish = true
        }

        setProgress(objProgress)
      }, 1000)
    }

    if (userRatings.commit_id) {
      checkProgressMapInfo()
    }

    return () => {
      if (checkProgress) {
        clearInterval(checkProgress)
      }
    }
  }, [userRatings.commit_id, userRatings.data])

  return <>
    {
      <>
        <Container
          minimize={grade.minimize}
          style={{
            display: grade.status ? 'flex' : 'none',
            left: mapBoundingRect.left,
            width: mapBoundingRect.width
          }}
        >
          <Header
            gridBin={gridBin}
            setGridBin={setGridBin}
            generateBinSize={generateBinSize}
            grade={grade}
            unselected={unselected}
            userRatings={userRatings}
            setUserRatings={setUserRatings}
            pickCommit={pickCommit}
            resetConfig={resetConfig}
            generateLoadingMessage={generateLoadingMessage}
            clearLoadingMessage={clearLoadingMessage}
          />
          <div
            className="row"
            ref={contentRef}
            style={{
              marginTop: '0.5em',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              overflowY: 'auto',
              border: '1px solid grey',
              borderRadius: '5px',
              position: 'relative'
            }}
          >
            {
              loading.ready && loading.data.length
                ? <Loading style={{
                  height: contentRef.current.getBoundingClientRect().height,
                  width: contentRef.current.getBoundingClientRect().width,
                }}>
                  {
                    loading.data.map((loadingInfo) => {
                      return <div
                        key={loadingInfo.id}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          paddingBottom: '1em'
                        }}
                      >
                        <ReactLoading color="white" type="spin" height="1em" width="1em" />
                        <span style={{
                          marginLeft: '0.5em'
                        }}>
                          {loadingInfo.message}
                        </span>
                      </div>
                    })
                  }
                </Loading>
                : null
            }
            <div className="row" style={{
              flexWrap: 'wrap',
              width: '80%',
              position: 'relative'
            }}>
              <div
                className="col"
                style={{
                  margin: '1em'
                }}
              >
                {
                  Object.keys(grade.weightScore).map((category, index) => {
                    const titleIndex = 0
                    const endTitle = 5
                    
                    if (index >= endTitle) {
                      return null
                    }
                    
                    return <CheckForm
                      category={category}
                      key={index}
                      index={index}
                      titleIndex={titleIndex}
                      title="Technical Parameter"
                      unselected={unselected}
                      setUnselected={setUnselected}
                      grade={grade}
                      changeGradeWeight={changeGradeWeight}
                    />
                  })
                }
              </div>
              <div
                className="col"
                style={{
                  margin: '1em'
                }}
              >
                {
                  Object.keys(grade.weightScore).map((category, index) => {
                    const titleIndex = 5
                    
                    if (index < titleIndex) {
                      return null
                    }
                    
                    return <CheckForm
                      category={category}
                      key={index}
                      index={index}
                      titleIndex={titleIndex}
                      title="Non-Technical Parameter"
                      unselected={unselected}
                      setUnselected={setUnselected}
                      grade={grade}
                      changeGradeWeight={changeGradeWeight}
                    />
                  })
                }
              </div>
            </div>
            <Progress
              progress={progress}
              setProgress={setProgress}
              userRatings={userRatings}
            />
          </div>
          <CloseBottomBar
            onClickHandler={toggleGradebar}
          />
        </Container>
        {
          grade.status
            &&
            <MinimizeButton
              minimize={grade.minimize}
              onClick={() => {
                toggleMinimizeGradebar()
              }}
            >
              {
                grade.minimize
                  ? <UpIcon />
                  : <DownIcon />
              }
            </MinimizeButton>
        }
      </>
    }
  </>
}

function mapStateToProps({ sidebarState, grade, sitebar }) {
  return {
    sidebarState,
    grade,
    sitebar
  }
}

const mapDispatchToProps = {
  changeGradeWeight,
  setWeightScore,
  toggleGradebar,
  toggleMinimizeGradebar
}

export default connect(mapStateToProps, mapDispatchToProps)(Gradebar)