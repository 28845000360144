import React from 'react'

import { ReactComponent as AddIcon } from '../../../../images/Icons/plus-text.svg'

import { ToggleSVGButton } from '../../../shares/ToggleSVGIcon'

function AddPoint({
  isActive,
  onClickHandler
}) {
  return <ToggleSVGButton
    flex
    margin="0.5em"
    active={isActive}
    onClick={onClickHandler}
  >
    <AddIcon title="Add Point" />
  </ToggleSVGButton>
}

export default AddPoint
