import * as actionTypes from '../../constants/store/actionTypes/database'

const initialState = {
    type: null,
    toggleAnalysis: false,
    togglePreviewTable: false,
    dataImport:{
      data:[],
      header:[],
      name:[],
      elements:[],
      headerOfLocation:[]
    },
    valuesHeader:{},
    importType:null,
    projectName:"proj1",
    nameTable: null,
    utmZone:{
      active:false,
      number:1,
      region:'n'
    },
    filterValue:{
      max: 100,
      min: 0,
      distance: 200
    },
    autoCheckSurface:false,
    configChart:[
      {
          type: 'boxplot',
          config:{
              seriesSelect:'',
              checked:{},
              defaultPickDropDown:'' ,
              customSelect:'',
              color:null,
          },
          delete:false,
          minimized: false,
          className: 'bp-01',
          layout:{i: "bp-01", x:0, y:0, w:30, h:12},
          width: 0,
          height: 0
      },
      {
          type: 'correlation',
          config:{
              seriesSelect:'',
              colorScaleSelect:["tomato","white","steelblue"],
              domainColorScale:[-1,0,1],
              checkIgnore:false,
              checked:{},
              defaultPickDropDown:'',
              customSelect:"",
          },
          delete:false,
          minimized: false,
          className: 'cm-01',
          layout:{i: "cm-01", x:30, y:0, w:30, h:12},
          width: 0,
          height: 0
      },
      {
          type:'histogram',
          config:{
              valueSelect: '',
              scaleX: 'linear',
              range:'',
              numBins:30,
              minRange:null,
              maxRange:null,
              color:null
          },
          delete:false,
          minimized:false,
          className: 'hg-01',
          layout:{i: "hg-01", x:60, y:0, w:30, h:12},
          width: 0,
          height: 0
      },
      // {
      //     type:'som',
      //     config:{
      //         valueSelect: '',
      //         scaleX: 'linear',
      //         range:'',
      //         numBins:30,
      //         minRange:null,
      //         maxRange:null,
      //         color:null
      //     },
      //     delete:false,
      //     minimized:false,
      //     className: 'som-01',
      //     layout:{i: "som-01", x:0, y:12, w:30, h:12},
      //     width: 0,
      //     height: 0
      // },
      // {
      //     type:'ternary',
      //     config:{
      //       data1: "",
      //       data2: "",
      //       data3: "",
      //       selectDivision:null,
      //       sizeCircle:4,
      //       color:{
      //           fill:"#444444",
      //           stroke:"#dddddd"
      //       },
      //       classification:"no_classification"
      //     },
      //     delete:false,
      //     minimized:false,
      //     className: 'tr-01',
      //     layout:{i: "tr-01", x:30, y:12, w:30, h:12},
      //     width: 0,
      //     height: 0
      // },
      {
          type:'scatterplot',
          config: {
              setX: '',
              setY: '',
              scaleX: 'linear',
              scaleY: 'linear',
              classification:"",
              color:'#00B7C2',
              colorBorder:'#0F4C75',
              sizeCircle:2,
              showRegression:true,
          },
          delete:false,
          minimized: false,
          className: 'sp-01',
          layout:{i: "sp-01", x:60, y:12, w:30, h:12},
          width: 0,
          height: 0
      },
      {
          type:'scatterplot',
          config: {
              setX: '',
              setY: '',
              scaleX: 'linear',
              scaleY: 'linear',
              classification:"",
              color:'#ff92c2',
              colorBorder:'#d1345b',
              sizeCircle:2,
              showRegression:true,
          },
          delete:false,
          minimized: false,
          className: 'sp-02',
          layout:{i: "sp-02", x:0, y:24, w:30, h:12},
          width: 0,
          height: 0
      },
      {
          type:'scatterplot',
          config: {
              setX: '',
              setY: '',
              scaleX: 'linear',
              scaleY: 'linear',
              classification:"",
              color:'#4E9F3D',
              colorBorder:'#1E5128',
              sizeCircle:2,
              showRegression:true,
          },
          delete:false,
          minimized: false,
          className: 'sp-03',
          layout:{i: "sp-03", x:30, y:24, w:30, h:12},
          width: 0,
          height: 0
      },
      // {
      //     type:'scatterplot',
      //     config: {
      //         setX: '',
      //         setY: '',
      //         scaleX: 'linear',
      //         scaleY: 'linear',
      //         classification:"",
      //         color:'#4E9F3D',
      //         colorBorder:'#1E5128',
      //         sizeCircle:2,
      //         showRegression:true,
      //     },
      //     delete:false,
      //     minimized: false,
      //     className: 'sp-04',
      //     layout:{i: "sp-04", x:60, y:24, w:30, h:12},
      //     width: 0,
      //     height: 0
      // },
  ]
}
  
function databaseReducer(state = initialState, { type, payload }) {
    switch (type) {
      case actionTypes.CHANGE_TYPE:
        return {
          ...state,
          type: payload
        }
      case actionTypes.TOGGLE_ANALYSIS:
        return {
          ...state,
          toggleAnalysis: payload
        }
      case actionTypes.TOGGLE_PREVIEW_TABLE:
        return {
          ...state,
          togglePreviewTable: payload
        }
      case actionTypes.CHANGE_DATA_IMPORT:
        return {
          ...state,
          dataImport: payload
        }
      case actionTypes.CHANGE_VALUES_HEADER:
        return {
          ...state,
          valuesHeader: payload
        }
      case actionTypes.IMPORT_TYPE:
        return {
          ...state,
          importType: payload
        }
      case actionTypes.PROJECT_NAME:
        return {
          ...state,
          projectName: payload
        }
      case actionTypes.NAME_TABLE:
        return {
          ...state,
          nameTable: payload
        }
      case actionTypes.UTM_ZONE:
        return {
          ...state,
          utmZone: payload
        }
      case actionTypes.CONFIG_CHART:
        return {
          ...state,
          configChart: payload
        }
      case actionTypes.FILTER_VALUE:
        return {
          ...state,
          filterValue: payload
        }
      case actionTypes.AUTOCHECK_SURFACE:
        return {
          ...state,
          autoCheckSurface: payload
        }
      default:
        return state
    }
  }

export default databaseReducer
