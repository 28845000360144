import React from 'react'

import { ReactComponent as DeleteIcon } from '../../../../images/Icons/trash.svg'

import { ToggleSVGButton } from '../../../shares/ToggleSVGIcon'

import { DANGER_RED } from '../../../shares/ColorTemplate'

/**
 * This component will create in point
 * @param {String} title - hover title
 * @param {Function} setConfirmation - method to change state confirmation
 */
function DeletePoint({
  title,
  setConfirmation
}) {
  const toggle = false

  return <ToggleSVGButton
    flex
    margin="0.5em"
    active={toggle}
    fillActive={DANGER_RED.hover}
    fillInActive={DANGER_RED.default}
    onClick={() => {
      setConfirmation({
        show: true,
        process: 'delete',
        action: title
      })
    }}
  >
    <DeleteIcon title={title} />
  </ToggleSVGButton>
}

export default DeletePoint
