import * as actionTypes from '../../constants/store/actionTypes/tablebar'

export function toggleTablebar(payload) {
  return {
    type: actionTypes.TOGGLE_TABLEBAR,
    payload
  }
}

export function setDataId(payload) {
  return {
    type: actionTypes.SET_DATA_ID,
    payload
  }
}

export function clearDataId(payload) {
  return {
    type: actionTypes.CLEAR_DATA_ID,
    payload
  }
}

export function filterDataId(payload) {
  return {
    type: actionTypes.FILTER_DATA_ID,
    payload
  }
}

export function setDeltaHeight(payload) {
  return {
    type: actionTypes.SET_DELTA_HEIGHT,
    payload
  }
}

export function setTableProperties(payload) {
  return {
    type: actionTypes.SET_TABLE_PROPERTIES,
    payload
  }
}

export function resetTablebar(payload) {
  return {
    type: actionTypes.RESET_TABLEBAR,
    payload
  }
}
