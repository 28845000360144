import {
  fork,
  put,
  putResolve,
  takeEvery
} from 'redux-saga/effects'

import * as userActionTypes from '../../constants/store/actionTypes/user'
import * as userActions from '../actions/user'
import * as cameraActions from '../actions/camera'
import * as catalogActions from '../actions/catalog'
import * as catalogSearchActions from '../actions/catalogSearch'
import * as cesiumActions from '../actions/cesium'
import * as cesiumEntityActions from '../actions/cesiumEntity'
import * as chatbarActions from '../actions/chatbar'
import * as displayActions from '../actions/display'
import * as dragActions from '../actions/drag'
import * as editorActions from '../actions/editor'
import * as gradebarActions from '../actions/gradebar'
import * as legendbarActions from '../actions/legendbar'
import * as rankbarActions from '../actions/rankbar'
import * as rightclickActions from '../actions/rightclick'
import * as sidebarToggleActions from '../actions/sidebarToggle'
import * as sitebarActions from '../actions/sitebar'
import * as sitesActions from '../actions/sites'
import * as socketActions from '../actions/socket'
import * as tablebarActions from '../actions/tablebar'
import * as toolbarActions from '../actions/toolbar'

import {
  startAction,
  stopAction,
  refreshActionStart,
  refreshActionStop
} from '../actions/uiActions'

export function* setLoginSaga({ type, payload }) {
  try {
    yield put(
      payload && payload.refreshing 
        ? refreshActionStart(type)
        : startAction(type)
    )
    
    yield putResolve(userActions.loginSuccess(payload))

    yield put(catalogActions.setCatalog())  
  } catch (error) {
    yield put(userActions.loginError({
      error
    }))
  } finally {
    yield put(
      payload && payload.refreshing
        ? refreshActionStop(type)
        : stopAction(type)
    )
  }
}

export function* setLogoutSaga({ type, payload }) {
  try {
    yield put(
      payload && payload.refreshing 
        ? refreshActionStart(type)
        : startAction(type)
    )
    
    yield putResolve(cameraActions.resetCamera())
    yield putResolve(catalogActions.resetCatalog())
    yield putResolve(catalogSearchActions.resetCatalogSearch())
    yield putResolve(cesiumActions.resetCesium())
    yield putResolve(cesiumEntityActions.resetCesiumEntity())
    yield putResolve(chatbarActions.resetChatbar())
    yield putResolve(displayActions.resetDisplay())
    yield putResolve(dragActions.resetDrag())
    yield putResolve(editorActions.resetEditor())
    yield putResolve(gradebarActions.resetGradebar())
    yield putResolve(legendbarActions.resetLegendbar())
    yield putResolve(rankbarActions.resetRankbar())
    yield putResolve(rightclickActions.resetRightclick())
    yield putResolve(sidebarToggleActions.resetSidebarToggle())
    yield putResolve(sitebarActions.resetSitebar())
    yield putResolve(sitesActions.resetSites())
    yield putResolve(socketActions.disconnectChatSocket())
    yield putResolve(socketActions.resetSocket())
    yield putResolve(tablebarActions.resetTablebar())
    yield putResolve(toolbarActions.resetToolbar())

    yield putResolve(userActions.logoutSuccess(payload))
  } catch (error) {
    yield put(userActions.loginError({
      error
    }))
  } finally {
    yield put(
      payload && payload.refreshing
        ? refreshActionStop(type)
        : stopAction(type)
    )
  }
}

export function* watchUserSaga() {
  yield takeEvery(userActionTypes.LOGIN, setLoginSaga)
  yield takeEvery(userActionTypes.LOGOUT, setLogoutSaga)
}

const sagaHelpers = [
  fork(watchUserSaga)
]

export default sagaHelpers
