import { useState, useEffect } from 'react'

/**
 * This function will handle Single or DoubleClick
 * @param {*} actionSimpleClick is the event handler for single click
 * @param {*} actionDoubleClick is the event handler for double click
 * @param {number} delay is the number to listen count of click
 * @returns 
 */
function UseSimpleAndDoubleClick(actionSimpleClick, actionDoubleClick, delay = 250) {
  const [click, setClick] = useState(0)

  useEffect(() => {
    const timer = setTimeout(() => {
        // simple click
        if (click === 1) {
          actionSimpleClick()
        }
        setClick(0)
    }, delay)

    // the duration between this click and the previous one
    // is less than the value of delay = double-click
    if (click === 2) {
      actionDoubleClick()
    }

    return () => clearTimeout(timer)
  }, [click])

  return () => setClick(prev => prev + 1)
}

export default UseSimpleAndDoubleClick
