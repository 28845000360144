import React from 'react'

import { ReactComponent as LeftIcon } from '../../../images/Icons/left.svg'
import { ReactComponent as RightIcon } from '../../../images/Icons/right.svg'

import './RightbarToggleButton.css'

function RightbarToggleButton({
  minimize,
  minimizeBar
}) {
  function onClickHandler() {
    minimizeBar({
      minimize: !minimize
    })
  }

  return (
    <div
      className="rightbar-toggle-button"
      onClick={onClickHandler}
    >
      {
        minimize
          ? <LeftIcon
            className="rightbar-toggle-icon"
          />
          : <RightIcon
            className="rightbar-toggle-icon"
            />
      }
    </div>
  )
}

export default RightbarToggleButton
