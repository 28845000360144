import React from 'react';
import Select from 'react-select';
import { 
    headersDrilling, 
    headersPoints, 
    headersPointsUTM
} from '../../../constants/Database/headers';
import { 
    customStyleTable, 
    optionsSettings 
} from '../helpers';

function TableBody({...props}) {
    let {
        type,
        subtype,
        headerData,
        valuesHeader,
        setValuesHeader,
        utmZone
    } = props;

    const handleChangeHeader =(i,val)=>{
        const arr={...valuesHeader}
        arr[i] = val
        setValuesHeader(arr)
    }

    const dropDownDBHeader = (i) => {
        let optionDBHeader = optionsSettings(headerData)
        return(
            <Select
                options={optionDBHeader}
                styles={customStyleTable}
                value={valuesHeader[i]}
                onChange={(val)=>handleChangeHeader(i,val)}
                isClearable={true}
            />
        )
    }

    let content
    let outContent=(i,idx)=>{
        return(
        <tr key={`row-header-${subtype?subtype:type}-${idx}`}>
            <td>{i}</td>
            <td>{dropDownDBHeader(i)}</td>
        </tr>
        )
    }
    if(type==='Drillhole'){
        content = headersDrilling.map((i,idx)=>outContent(i,idx))
    }else{
        if(utmZone.active){
            content = headersPointsUTM.map((i,idx)=>outContent(i,idx))
        } else {
            content = headersPoints.map((i,idx)=>outContent(i,idx))
        }
    } 
    return content;
}

export default TableBody;