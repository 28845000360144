import { DOMAIN_ALTERATION_DATA_GEOCHEMICAL, DOMAIN_LITHOLOGY_DATA_GEOCHEMICAL } from "../../../constants/Analytic/series";
import { boxPlot } from "./boxPlotFunction";

//function to plot all 
export let createBoxPlotAll=({...props})=>{
    let {
        data,
        svg,
        height,
        width,
        className,
        color,
        type
    }= props;

    let barHeight = 15;
    let groupCounts={};
    let globalCounts = [];
    let min = 0;
    let max = 9;
    
    Object.entries(data).forEach((key,value)=>{
        if(value>=min && value<=max){
            globalCounts.push(...key[1])
            groupCounts[key[0]]=key[1];
        }
    })

    boxPlot({
        groupCounts: groupCounts, 
        globalCounts: globalCounts, 
        svg:svg, 
        height:height,
        width:width, 
        barHeight: barHeight,
        className:className,
        color: color,
        type: type
    })
}

//function to plot series 
export let createBoxPlotSeries=({...props})=>{
    let {
        data,
        svg,
        height,
        width,
        className,
        color,
        series,
        dataLocal
    }= props;

    let barHeight = 15;
    let groupCounts={};
    let globalCounts = [];
    
    Object.entries(data)
        .sort()
        .forEach((key,index)=>{
        for (let i=0;i<series.length;i++){
            if(key[0]===series[i]){
                globalCounts.push(...key[1])
                groupCounts[key[0]]=key[1];
            }
        }
    })

    boxPlot({
        groupCounts: groupCounts, 
        globalCounts: globalCounts, 
        svg:svg, 
        height:height,
        width:width, 
        barHeight: barHeight,
        className:className,
        color: color,
        dataLocal: dataLocal
    })
}

//function to plot one series in alteration domain 
export let seriesAlteration = ({...props}) => {
    let {
        series,
        svg,
        height,
        width,
        className,
        color,
        data,
        type
    } = props;

    let groupCounts = {}
    let barHeight = 30;
    let globalCounts = data.map(item => item[series])
    DOMAIN_ALTERATION_DATA_GEOCHEMICAL.forEach(alter_ => {
        groupCounts[alter_] = [];
        
        groupCounts[alter_] = data
            .filter(row => row["Alteration"].toLowerCase() === alter_.toLowerCase())
            .map(item => item[series]);
    });
    boxPlot({
        groupCounts: groupCounts, 
        globalCounts: globalCounts, 
        svg:svg, 
        height:height,
        width:width, 
        barHeight: barHeight,
        className:className,
        color: color,
        type:type
    })
}


//function to plot one series in lithology domain
export let seriesLithology = ({...props}) => {
    let {
        series,
        svg,
        height,
        width,
        className,
        color,
        data,
        type
    } = props;
    let barHeight = 40;
    let groupCounts = {};
    let globalCounts = data.map(item => item[series])
    DOMAIN_LITHOLOGY_DATA_GEOCHEMICAL.forEach(alter_ => {
        groupCounts[alter_] = [];
        
        groupCounts[alter_] = data
            .filter(row => row["Lithology"].toLowerCase() === alter_.toLowerCase())
            .map(item => item[series]);
    });
    boxPlot({
        groupCounts: groupCounts, 
        globalCounts: globalCounts, 
        svg:svg, 
        height:height,
        width:width, 
        barHeight: barHeight,
        className:className,
        color: color,
        type: type
    })
};

