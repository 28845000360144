import {
  scaleLinear,
  scaleLog
} from 'd3'

/**
 * This function will generate 
 * @param {Object} map is the current selected map
 * @param {Number} min is the minimum value of data
 * @param {Number} max is the maximum value of data
 */
export function generateScaleMethod({
  mode,
  min,
  max
}) {
  if (mode === 'equal_interval') {
    return scaleLinear()
      .domain([min, max])
      .range([0, 1])
  } else if (mode === 'logarithmic') {
    return scaleLog()
      .domain([min, max])
      .range([0, 1])
  }
}

/**
 * This function will score the for create color
 * @param {Boolean} inverse
 * @param {Number} score is the score of current
 * between 0 - 1 
 * @returns number of score
 */
export function getScore({
  inverse,
  score
}) {
  if (!inverse) {
    return score
  } else {
    return 1 - score
  }
}
