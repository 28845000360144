import Slider from './Slider'

import styled from 'styled-components'

const Label = styled.span`
  opacity: 0;
  position: absolute;
  color: black;
  background-color: white;
  left: ${props => {
    if (props.textPosition === 'right') {
      if (props.textSpace) {
        return props.textSpace
      } else {
        return '3.8em'
      }
    }
  }};
  right: ${props => {
    if (props.textPosition === 'left') {
      if (props.textSpace) {
        return props.textSpace
      } else {
        return '3.8em'
      }
    }
  }};
  width: 4em;
  text-align: center;
  font-size: 0.8rem;
  transition: 330ms;
  z-index: 1400;
  pointer-events: none;

  ${Slider}:hover + & {
    opacity: 1
  }
`

export default Label
