import React from 'react'

import BackgroundImage from '../../../images/Icons/light.svg'

import styled from 'styled-components'

const Input = ({ ...rest }) => (
  <>
    <input 
      type="range"
      {...rest}
    />
  </>
)

const Slider = styled(Input)`
  background: grey;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
  opacity: 0.8;
  transition: all 330ms;
  -webkit-appearance: none;
  appearance: none;
  -webkit-transition: 330ms;
  outline: none;
  height: ${props => props.height};
  width: ${props => props.width};

  &:hover {
    background: #bebebe;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1em;
    height: 1em;
    background: url(${BackgroundImage});
    background-size: 1em;
    background-color: white;
    cursor: pointer;
    border-radius: 50%;
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1em;
    height: 1em;
    background: url(${BackgroundImage});
    background-size: 1em;
    background-color: white;
    cursor: pointer;
    border-radius: 50%;
  }
`

export default Slider
