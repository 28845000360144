import React, { Suspense, useEffect, useState } from 'react';
import './style.css'
import { fetchPathData} from '../../apis/server';
import { connect } from 'react-redux';
import ModelToMap from './ModelToMap';
import { renderDrag } from '../Cesium/helpers/renderDrag';
import { BASE_URL } from '../../constants/Server/base';

const ThreeCanvas = React.lazy(() => import('./ThreeCanvas'))

let ThreeDModel = ({...props}) => {
  let{
    drag,
    data,
    valuesHeader,
    utmZone,
    modelWithMap,
    filterValue
  } = props

  const [ pathData, setPathData ] = useState("")
  const [ file, setFile ] = useState("")
  const [ progress, setProgress ] = useState(0)
  const [ viewer, setViewer ] = useState(null)

  let handleViewerLoad = (viewer) => {
    setViewer(viewer);
  };

  const onDropHandler = (event) => {
      renderDrag(event)

      drag.event()
      event.preventDefault()
      event.stopPropagation()
  }

  const onDragOverHandler = (event) => {
      event.dataTransfer.dropEffect = 'copy'

      event.preventDefault()
      event.stopPropagation()
  }

  useEffect(()=>{
    if(data.data.length!==0){
      let jsonData = {}
      let x = []
      let y = []
      let z = []
      let val = []
      data.data.map((d)=>{
        Object.keys(d).map((ho)=>{
          if(valuesHeader.x.value === ho){
            x.push(Number(d[ho]))
          } else if (valuesHeader.y.value === ho) {
            y.push(Number(d[ho]))
          } else if (valuesHeader.z.value === ho) {
            z.push(Number(d[ho]))
          } else if (valuesHeader.value.value === ho) {
            if (d[ho]===""){
              val.push(null)
            } else {
              val.push(Number(d[ho]))
            }
          }
        })
      })
      jsonData.data = {
        x:x,
        y:y,
        z:z,
        val:val
      }
      jsonData.meta = {
        utmZone: utmZone,
        contour: false,
        filter: filterValue
      }
      const json = JSON.stringify(jsonData)
      async function loadPathModel({ data }) {
        try {
          const fetchPath = await fetchPathData({
            payload:data
          })
          setPathData(fetchPath.filename)
          return fetchPath
        } catch (error) {
          console.log(error)
          throw error
        }
      }
      loadPathModel({data: json})

    }
  },[data.data])

  useEffect(()=>{
    if(pathData!=='' && file == ""){
      const fileName = pathData.split(/[\\\/]/).pop()
      const urlFile = `${BASE_URL}/geofix/glb/model3d/${fileName}`
      setFile(urlFile)
    }
  },[pathData])
  
  if(file==="") return <div style={{color: 'white', position: 'absolute', top:'50%', left:'40%'}}>Model Loading...</div>

  if (modelWithMap) {
    return <ModelToMap 
      onViewerLoad={handleViewerLoad}
      onDropHandler={onDropHandler}
      onDragOverHandler={onDragOverHandler}
      utmZone={utmZone}
      data={data}
      pathData={file}
    />
  }

  return <Suspense fallback={null}>
    <ThreeCanvas 
      {...props}
      pathData={file}
    />
  </Suspense>
}

function mapStateToProps(state) {
  return {
    data: state.database.dataImport,
    valuesHeader: state.database.valuesHeader,
    drag: state.drag,
    utmZone: state.database.utmZone,
    filterValue: state.database.filterValue
  }
}

export default connect(mapStateToProps, null)(ThreeDModel)
