/**
 * This function helper function for click non grid to show menu detail
 * @param list is the array of status togle is open or not
 * @param setList is the function to change the the status toggle
 * @param map is the current selected map to find the category
 * @param exceptions is array that except some map category
*/
export function toggleEventWithException({
  list,
  setList,
  map,
  exceptions = []
}) {
  let except = false

  for (let i = 0; i < exceptions.length; i++) {
    if (exceptions[i] === map.category) {
      except = true
      break
    }
  }

  if (!except) {
    if (list[map.dataSource.name]) {
      setList(
        Object.assign([], list, {
          [map.dataSource.name]: false
        })
      )
    } else {
      setList(
        Object.assign([], list, {
          [map.dataSource.name]: true
        })
      )
    }
  }
}
