//--------Drilling----------//
export const headersDrilling = [
    'x',
    'y',
    'z',
    'value'
];

//-----------------Surface------------------//
export const headersPoints = [
    'id',
    'longitude',
    'latitude',
    'altitude'
];

export const headersPointsUTM = [
    'id',
    'easting',
    'northing',
    'altitude'
];