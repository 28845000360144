import React from 'react'

import FlexWrapper from './FlexWrapper'
import ToggleIcon from './ToggleIcon'
import ToolbarText from './ToolbarText'

function Header({
  dataToggle,
  setToggle,
  text,
  children
}) {
  function toggleClickHandler() {
    setToggle(!dataToggle)
  }

  return (
    <FlexWrapper center header no-overflow>
      <ToggleIcon
        toggle={dataToggle}
        toggleAble
        onClick={toggleClickHandler}
      />
      <ToolbarText
        width="10em"
        text={text}
        max={20}
        type="header"
        onClick={toggleClickHandler}
      />
      {children}
    </FlexWrapper>
  )
}

export default Header
