import store from '../store'

/**
 * Notes untuk ditambah ke mas teja
 * mineral occurrences:
 * - minlongitude, minlatitude, maxlongitude, maxlatitude
 * accessibility, hazard, forestry:
 * - withgeojson
 * 
 * 
 * 
 * Developed Later
 * prospect area
 * updates in geoprosp
 * featured projects
 * published articles
 * search
 * similar project
 * site
 */

class RequestParams {
  static GET_SEARCH_POSITION() {
    return {}
  }

  static GET_INTERSECTION_STATUS() {
    const noIntersections = [
      'country',
      'island',
      'province'
    ]

    return false
  }

  // without means need to be improved later
  static WITHOUT_PARAMS() {
    return {
      ...RequestParams.GET_SEARCH_POSITION()
    }
  }

  static ONLY_FETCH_DATA() {
    return {
      withgeojson: false,
      commodity: store.getState().sites.commodity,
      ...RequestParams.GET_SEARCH_POSITION()
    }
  }

  static FETCH_AND_RENDER() {
    return {
      withgeojson: true,
      commodity: store.getState().sites.commodity,
      intersection: RequestParams.GET_INTERSECTION_STATUS(),
      ...RequestParams.GET_SEARCH_POSITION()
    }
  }

  static FETCH_RASTER() {
    return {
      raster: true,
      ...RequestParams.GET_SEARCH_POSITION()
    }
  }

  static FETCH_ALL_RASTER() {
    return {
      raster: true,
      multipleRaster: true,
      ...RequestParams.GET_SEARCH_POSITION()
    }
  }

  static FETCH_ALL_TILESET() {
    return {
      tileset: true,
      commodity: store.getState().sites.commodity,
    }
  }

  static FETCH_TILESET() {
    return {
      tileset: true,
      commodity: store.getState().sites.commodity,
      bin_size: 8000,
    }
  }

  static FETCH_GEOJSON_WITHOUT_SUMMARY() {
    return {
      withgeojson: true,
      withsummary: false,
      commodity: store.getState().sites.commodity,
      intersection: RequestParams.GET_INTERSECTION_STATUS(),
      ...RequestParams.GET_SEARCH_POSITION()
    }
  }

  static FETCH_GEOJSON_AND_SUMMARY() {
    return {
      withgeojson: true,
      withsummary: true,
      commodity: store.getState().sites.commodity,
      intersection: RequestParams.GET_INTERSECTION_STATUS(),
      ...RequestParams.GET_SEARCH_POSITION()
    }
  }

  static WITH_LOCATION_COORDINATES(coordinate) {
    return {
      minlongitude: coordinate.minlongitude,
      minlatitude: coordinate.minlatitude,
      maxlongitude: coordinate.maxlongitude,
      maxlatitude: coordinate.maxlatitude,
      ...RequestParams.GET_SEARCH_POSITION()
    }
  }

  static FETCH_SITES_WITHGEOJSON() {
    return {
      withgeojson: true,
      withsummary: false
    }
  }
}

export default RequestParams
