import CesiumMap from '../../packages/cesium-add-on/cesiumMap'

class LayerManagerState {
  static simplify(data) {
    let onProgress = false

    if (data.on_progress === true || data.on_progress === 'TRUE') {
      onProgress = true
    }

    let templateActive = false

    if (data.default_template === true || data.default_template === 'TRUE') {
      templateActive = true
    }

    let filterBy = []

    if (
      typeof data.filter_by === 'string'
      && data.filter_by[0] === '{'
    ) {
      filterBy = data.filter_by
        .replace(/["{} ]/g, '')
        .replace(/(?:\\[rn]|[\r\n])+/g, '')
        .split(',')
    } else {
      filterBy = data.filter_by
    }

    let subscriptionTypes = []

    if (
      typeof data.subcription_type === 'string'
      && data.subcription_type[0] === '{'
    ) {
      subscriptionTypes = data.subcription_type
        .replace(/["{} ]/g, '')
        .replace(/(?:\\[rn]|[\r\n])+/g, '')
        .split(',')
    } else {
      subscriptionTypes = data.subcription_type
    }

    let fields = []

    if (
      typeof data.field === 'string'
      && data.field[0] === '{'
    ) {
      fields = data.field
        .replace(/["{} ]/g, '')
        .replace(/(?:\\[rn]|[\r\n])+/g, '')
        .split(',')
    } else {
      fields = data.field
    }

    return {
      onProgress,
      templateActive,
      filterBy,
      subscriptionTypes,
      fields
    }
  }

  static generateListMap(data, level) {
    let layerBy = null

    if (level === 1) {
      layerBy = data.layer_0_by === 'default'
        ? null
        : data.layer_0_by
    } else if (level === 2) {
      layerBy = data.layer_1_by === 'default'
        ? null
        : data.layer_1_by
    }

    const {
      onProgress,
      templateActive,
      filterBy,
      subscriptionTypes,
      fields
    } = LayerManagerState.simplify(data)
    
    return {
      category: data.layer_0,
      subCategory: data.layer_1,
      layerBy,
      toggle: false,
      layerType: data.layer_type,
      templateActive,
      templateName: data.default_template_name,
      field: data.default_field || null,
      fieldFormat: data.field_format || null,
      fieldFormatMode: data.field_format_mode || null,
      fields,
      filterBy,
      subscriptionTypes,
      listMap: [],
      onProgress,
      selectedTemplate: data.default_template
    }
  }

  static async generateCheckMap(data) {
    const {
      onProgress,
      templateActive,
      filterBy,
      subscriptionTypes,
      fields
    } = LayerManagerState.simplify(data)
    const map = new CesiumMap({
      id: 0,
      geojson: null,
      name: data.layer_1 || data.layer_0,
      category: data.layer_0,
      subCategory: data.layer_1 || null,
      raster: data.layer_type === 'Raster' ? true : false,
      tileset: data.layer_type === 'Tileset',
      layerType: data.layer_type,
      templateActive,
      field: data.default_field || null,
      fields,
      fieldFormat: data.field_format || null,
      fieldFormatMode: data.field_format_mode || null,
      selectedTemplate: data.default_template_name
    })

    if (map.tileset) {
      map.fetchTilesetId({})
    }

    return {
      layerType: data.layer_type,
      templateActive,
      templateName: data.default_template_name,
      field: data.default_field || null,
      fields,
      fieldFormat: data.field_format || null,
      fieldFormatMode: data.field_format_mode || null,
      filterBy: filterBy,
      subscriptionTypes: subscriptionTypes,
      map,
      onProgress
    }
  }

  // asumsi layer_0 ada null, 'type', atau 'default'
  static async generateLayerManager(data) {
    const result = {}

    for (let i = 0; i < data.length; i++) {
      if (!result[data[i].layer_0]) {
        if (!data[i].layer_0_by || data[i].layer_0_by === 'None') {
          result[data[i].layer_0] = {
            level: 0,
            ...await LayerManagerState.generateCheckMap(data[i])
          }

          continue
        } else if (data[i].layer_0_by === 'type') {
          result[data[i].layer_0] = {
            level: 1,
            ...LayerManagerState.generateListMap(data[i], 1)
          }

          continue
        } else {
          result[data[i].layer_0] = {
            level: 2,
            toggle: false,
            layerType: data[i].default_layer_type,
            layerTypeDetail: {
              [data[i].default_layer_type]: {}
            }
          }
        }
      }

      // continue for level 2++
      if (!result[data[i].layer_0].layerTypeDetail[data[i].layer_type]) {
        result[data[i].layer_0].layerTypeDetail[data[i].layer_type] = {}
      }

      let listMap = true

      if (!data[i].layer_1_by || data[i].layer_1_by === 'None') {
        listMap = false
      }

      if (listMap) {
        result[data[i].layer_0].layerTypeDetail[data[i].layer_type] = {
          ...result[data[i].layer_0].layerTypeDetail[data[i].layer_type],
          [data[i].layer_1]: LayerManagerState.generateListMap(data[i], 2)
        }
      } else {
        result[data[i].layer_0].layerTypeDetail[data[i].layer_type] = {
          ...result[data[i].layer_0].layerTypeDetail[data[i].layer_type],
          [data[i].layer_1]: await LayerManagerState.generateCheckMap(data[i])
        }
      }
    }

    return result
  }
}

export default LayerManagerState
