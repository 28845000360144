import { BASE_URL } from '../../../../constants/Server/base'

import axiosReact, { getProgressDownload } from '../../../axiosReact'

export async function fetch({
  map,
  params,
  fetchProgress = () => {},
  fetchFinish = () => {}
}) {
  if (params.raster && params.multipleRaster) {

    const { data } = await axiosReact.get('/geofix/raster', {
      params,
      onDownloadProgress(event) {
        getProgressDownload(event, fetchProgress)
      }
    })

    fetchFinish()

    return data.filter((fileInfo) => fileInfo.title.includes('Radiometric'))
  }
}

export function parseFetch({ data, params }) {
  if (params.raster) {
    data.forEach((element) => {
      element.name = element.title.split(' ')[1]
      element.rasterFile = {
        ...element,
        url: `${BASE_URL}/geofix/raster_texture/wsenTexture/${element.filename}`
      }
      element.raster = true

    })
  
    return data
  }

  return []
}

export function getColor(entity, { map }) {
  return {
    red: Math.random(),
    green: Math.random(),
    blue: Math.random(),
    alpha: map.cesiumLayer.alpha
  }
}

export async function getLegendStyle({
  map,
  params,
  fetchProgress,
  fetchFinish
}) {
  try {
  } catch (error) {
  }
}
