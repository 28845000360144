import * as actionTypes from '../../constants/store/actionTypes/rightclick'

export function closeContext(payload) {
  return {
    type: actionTypes.CLOSE_CONTEXT,
    payload
  }
}

export function setContext(payload) {
  return {
    type: actionTypes.SET_CONTEXT,
    payload
  }
}

export function resetRightclick(payload) {
  return {
    type: actionTypes.RESET_RIGHTCLICK,
    payload
  }
}
