import * as actionTypes from '../../constants/store/actionTypes/drag'

const initialState = {
  map: null,
  event () {}
}

function dragReducer (state = initialState, { type, payload }) {
  const { map } = state
  
  switch (type) {
    case actionTypes.DRAG_MAP:
      return {
        ...state,
        map: payload.map,
        event: payload.event
      }
    case actionTypes.DROP_MAP:
      if (map) {
        return {
          ...state,
          map: null,
          event () {}
        }
      }

      return state
    case actionTypes.RESET_DRAG:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default dragReducer
