import React, { useEffect } from 'react'

import { connect } from 'react-redux'

import {
  login,
  logout
} from '../store/actions/user'

import PropTypes from 'prop-types'
import { Route, Redirect, useHistory } from 'react-router-dom'

import axiosReact from '../apis/axiosReact'
import axios from 'axios'
import { BASE_URL } from '../constants/Server/base'

import cookies, { handleLogin, handleLogout } from '../apis/cookies'

let link = BASE_URL;
function RouteWrapper({ 
  PageComponent,
  isPrivate,
  isPublic = false,
  user,
  login,
  logout,
  ...rest 
}) {
  let history = useHistory()

  useEffect(() => {
    async function getRefreshToken() {
      try {
        let { data } = await axios({
          method: 'GET',
          // url: `/refresh`,
          url: `${link}/refresh`,
          headers: {
            'Authorization': `Bearer ${cookies.get('refreshToken')}`,
          },
        })
        const response = await axiosReact({
          method: 'GET',
          url: `/claims`
        })

        const userData = response.data

        handleLogin({
          accToken: data.access_token,
          rfsToken: data.refresh_token,
          username: userData.username,
          tierDesc: userData.tier_desc
        })

        login({
          username: userData.username,
          tierDesc: userData.tier_desc
        })
      } catch (error) {
        handleLogout()
        history.push('/login')
      }
    }

    if (isPublic === false) {
      if (cookies.get('refreshToken')) {
        getRefreshToken()
      } else {
        handleLogout()
      }
    }
  }, [history, isPrivate, login, isPublic])

  
  function renderPage() {
    let signed = cookies.get('accessToken')

    if (isPrivate && !signed) {     
      return <Redirect to="/login" />
    }

    if (isPrivate === false && signed) {     
      return <Redirect to="/dashboard" />
    }

    return <Route {...rest} component={PageComponent} />
  }

  return renderPage()
}

function mapStateToProps({ user }) {
  return {
    user
  }
}

const mapdDispatchToProps = {
  login,
  logout
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  PageComponent: PropTypes
    .oneOfType([
      PropTypes.element,
      PropTypes.elementType,
      PropTypes.func
    ])
    .isRequired,
  user: PropTypes.object,
  login: PropTypes.func,
  logout: PropTypes.func
}

export default connect(mapStateToProps, mapdDispatchToProps)(RouteWrapper)
