/**
 * make the position of dragMovement correct between top left and bottom right
 * @param {Object} dragMovement - object that have top and bottom position
 */
export function fixedPoints(dragMovement) {
  if (!dragMovement.end) {
    dragMovement.end = dragMovement.start
  }

  if (dragMovement.start.longitude > dragMovement.end.longitude) {
    let hold = dragMovement.start.longitude
    dragMovement.start.longitude = dragMovement.end.longitude
    dragMovement.end.longitude = hold
  }

  if (dragMovement.start.latitude > dragMovement.end.latitude) {
    let hold = dragMovement.start.latitude
    dragMovement.start.latitude = dragMovement.end.latitude
    dragMovement.end.latitude = hold
  }
}
