import React, {useCallback, useEffect, useState} from 'react'
import DragDropZone from './DragDropZone';
import { parseFile } from './helpers';

function DropZoneContainer({...props}) {
    let {
        setData,
        setPopup,
        type,
        data
    }= props;
    const [ fileAttributes, setFileAttributes ] = useState ({
        selectedFiles: undefined,
        progressInfo: []
    })

    const onDrop = useCallback(acceptedFiles => {
        const typeParse = () => {type}
        if(acceptedFiles.length){
            let att={...fileAttributes}
            att.selectedFiles = acceptedFiles
            setFileAttributes(att)
            let obj = {...data}
            let nm=[]
            acceptedFiles.map((file) => nm.push(file.name))
            obj.name=nm
            parseFile(acceptedFiles[0],obj, setData, typeParse())
            setPopup(true)
        }
    }, []);

    // useEffect(() =>{
    //     if (data.data.length === 0) return
    //     let header=[]
    //     data.data.map((i)=>{
    //         Object.keys(i).map((j)=>{
    //             if(header.indexOf(j) === -1){
    //                 header.push(j)
    //             }
    //         })
    //     })
    //     let obj={...data}
    //     obj.header=header
    //     setData(obj)
    // },[data.data])

    return (
        <DragDropZone
            data={data}
            type={type}
            onDrop={onDrop}
        />
    );
}
    
export default DropZoneContainer