import * as actionTypes from '../../constants/store/actionTypes/display'

const initialState = {
  maps: [],
  focusMap: null
}

function displayState(state = initialState, { type, payload }) {
  const { maps } = state

  switch (type) {
    case actionTypes.SET_DISPLAY:
      return {
        ...state,
        maps: payload.maps
      }
    case actionTypes.ADD_TO_DISPLAY:
      return {
        ...state,
        maps: [
          payload.map,
          ...state.maps
        ]
      }
    case actionTypes.SET_FOCUS_MAP:
      return {
        ...state,
        focusMap: payload.map
      }
    case actionTypes.REMOVE_FROM_DISPLAY:
      let focusMap = state.focusMap

      if (focusMap === payload.map) {
        focusMap = null
      }

      const filteredMaps = maps.filter((map) => {
        return map !== payload.map
      })

      return {
        ...state,
        maps: filteredMaps,
        focusMap
      }
    case actionTypes.RESET_DISPLAY:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default displayState
