import * as actionTypes from '../../constants/store/actionTypes/sites'

const initialState = {
  prospectArea: [],
  prospectMap: null,
  selected: {
    site: null,
    content: null,
    menu: {
      category: {
        databaseName: '',
        displayName: ''
      }
    }
  },
  visit: {
    position: 0,
    sites: []
  },
  commodity: null,
  ranking: {
    binSize: 8000,
    depositType: '',
    status: 'technical'
  },
  error: null
}

function sitesReducer (state = initialState, { type, payload }) {
  const { selected, visit } = state
  const { menu } = selected

  switch (type) {
    case actionTypes.CHANGE_COMMODITY_SUCCESS:
      return {
        ...state,
        commodity: payload.commodity
      }
    case actionTypes.CHANGE_SITE_MENU_CATEGORY_SUCCESS:
      return {
        ...state,
        selected: {
          ...selected,
          menu: {
            ...menu,
            category: payload.category
          }
        }
      }
    case actionTypes.FETCH_PROSPECT_AREA_SITES_SUCCESS:
      return {
        ...state,
        prospectArea: payload.sites,
        error: null
      }
    case actionTypes.SELECT_SITE_SUCCESS:
      return {
        ...state,
        selected: {
          ...selected,
          site: payload.site,
          content: payload.content,
          menu: {
            ...menu,
            category: payload.category
          }
        },
        visit: {
          position: 0,
          sites: [ payload.site ]
        },
        error: null
      }
    case actionTypes.VISIT_SITE_SUCCESS:
      return {
        ...state,
        selected: {
          ...selected,
          site: payload.site,
          content: payload.content,
          menu: {
            ...menu,
            category: payload.category
          }
        },
        visit: {
          ...visit,
          position: visit.position + 1,
          sites: [
            ...visit.sites.slice(0, visit.position + 1),
            payload.site
          ]
        },
        error: null
      }
    case actionTypes.VISIT_SITE_BACK_SUCCESS:
      return {
        ...state,
        selected: {
          ...selected,
          site: payload.site,
          content: payload.content,
          menu: {
            ...menu,
            category: payload.category
          }
        },
        visit: {
          ...visit,
          position: visit.position - 1
        },
        error: null
      }
    case actionTypes.VISIT_SITE_FORWARD_SUCCESS:
      return {
        ...state,
        selected: {
          ...selected,
          site: payload.site,
          content: payload.content,
          menu: {
            ...menu,
            category: payload.category
          }
        },
        visit: {
          ...visit,
          position: visit.position + 1
        },
        error: null
      }
    case actionTypes.VISIT_SITE_HOME_SUCCESS:
      return {
        ...state,
        selected: {
          ...selected,
          site: payload.site,
          content: payload.content,
          menu: {
            ...menu,
            category: payload.category
          }
        },
        visit: {
          ...visit,
          position: 0
        },
        error: null
      }
    case actionTypes.CHANGE_RANKING_PREFERENCES_SUCCESS:
      return {
        ...state,
        ranking: {
          ...state.ranking,
          binSize: payload.binSize || state.ranking.binSize,
          depositType: payload.depositType || state.ranking.depositType,
          status: payload.status || state.ranking.status
        }
      }
    case actionTypes.RESET_RANKING_PREFERENCES:
      return {
        ...state,
        ranking: {
          binSize: 8000,
          depositType: '',
          status: 'technical'
        }
      }
    case actionTypes.CHANGE_COMMODITY_ERROR:
    case actionTypes.CHANGE_SITE_MENU_CATEGORY_ERROR:
    case actionTypes.FETCH_PROSPECT_AREA_SITES_ERROR:
    case actionTypes.SELECT_SITE_ERROR:
    case actionTypes.VISIT_SITE_ERROR:
    case actionTypes.CHANGE_RANKING_PREFERENCES_ERROR:
      return {
        ...state,
        error: payload
      }
    case actionTypes.RESET_SITES:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default sitesReducer
