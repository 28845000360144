import React, { useState } from 'react'

import {
  ToggleSVGButton
} from '../../shares/ToggleSVGIcon'

import {
  ReactComponent as FocusIcon
} from '../../../images/Icons/focus-crosshair.svg'

import {
  ReactComponent as TableLineIcon
} from '../../../images/Icons/table-line.svg'

import styled from 'styled-components'

const Wrapper = styled.div`
  margin: 0.4em 1em;
  display: flex;
  align-items: center;
`

const SelectOptionToggle = styled.div`
  position: absolute;
  top: 3rem;
  width: 50%;
  max-height: 15em;
  left: 0.5rem;
  border-radius: 5px;
  border: 1px solid black;
  /* border-bottom: 1px solid black;
  border-right: 1px solid black; */
  background: white;
  font-size: 12px;
  font-family: Abel;
  overflow: auto;
  z-index: 100;
`

const SelectOptionSeparation = styled.div`
  display: block;
  float: left;
  width: 33.3%;
`

const SelectOptionStripe = styled.div`
  padding: 0.2em 0.5em;
  background: ${props => props.stripe ? 'white' : '#dddddd'};

  &:hover {
    background: ${props => props.stripe ? '#eeeeee' : '#d3d3d3'};
  }
`

function TableTool({
  headerProp,
  searchValue,
  setSearchValue,
  checkedAll,
  setCheckedAll,
  checkboxValue,
  setCheckboxValue
}) {
  const [ toggleCheckbox, setToggleCheckbox ] = useState(false)
  const [ toggleSearchbox, setToggleSearchbox ] = useState(false)

  return (
    <Wrapper>
      <ToggleSVGButton light flex margin="0 0.5em" active={toggleCheckbox}>
        <TableLineIcon
          title="Set Table Header"
          height="1.5em"
          onClick={()=>{
            setToggleCheckbox(!toggleCheckbox)
          }}
        />
      </ToggleSVGButton>
      {
        toggleCheckbox
          &&
          <SelectOptionToggle>
            <SelectOptionSeparation>
              <SelectOptionStripe
                stripe={true}
                style={{
                  cursor: 'default'
                }}
              >
                <input
                  type="checkbox"
                  style={{ cursor: 'pointer' }}
                  checked={checkedAll}
                  onChange={()=>{
                    setCheckedAll(!checkedAll);
                    
                    setCheckboxValue((checkboxValue) => {
                      const newState = {...checkboxValue};
                      for (const inputName in checkboxValue){
                        newState[inputName] = !checkedAll
                      }

                      return newState
                    })
                  }}
                />
                All
              </SelectOptionStripe>
            </SelectOptionSeparation>
            {
              headerProp.map((item, index) => {
                return<SelectOptionSeparation key={index}>
                  <SelectOptionStripe
                    key={index}
                    stripe={index % 2 ? true : false}
                    style={{
                      cursor: 'default'
                    }}
                  >
                    <input
                      type="checkbox"
                      style={{ cursor: 'pointer' }}
                      name="checkboxTable"
                      checked={checkboxValue[item]}
                      value={item}
                      title={item[0].toUpperCase() + item.substring(1)}
                      onChange={() => {
                        setCheckboxValue((checkboxValue) => {
                          const newState = {...checkboxValue};
                          
                          newState[item] = !checkboxValue[item]

                          return newState
                        })
                      }}
                    />
                    {item[0].toUpperCase() + item.substring(1)}
                  </SelectOptionStripe>
                </SelectOptionSeparation>
              })
            }
          </SelectOptionToggle>
      }
      <ToggleSVGButton light flex margin="0 0.5em" active={toggleSearchbox}>
        <FocusIcon
          title="Set Focus Header"
          height="1.5em"
          onClick={()=>{
            setToggleSearchbox(!toggleSearchbox)
          }}
        />
      </ToggleSVGButton>
      {
        toggleSearchbox
          &&
          <input
            type="text"
            placeholder="Search"
            style={{
              padding: '0.12em 0.5em',
              fontFamily: 'Abel',
              width: '15em',
              marginLeft: '0.5em'
            }}
            value={searchValue}
            onChange={(event) => {
              setSearchValue(event.target.value)
            }}
          />
      }
    </Wrapper>
  )
}

export default TableTool
