import React, { useEffect, useRef, useState } from 'react';
import cesiumViewer from '../../../apis/cesiumViewer';
import * as Cesium from 'cesium';

import { 
    CesiumContainer, 
    CesiumWrapper 
} from './partials';
import { 
    CESIUM_ION_DEFAULT_ACCESS_TOKEN, 
    DEFAULT_VIEW_FACTOR, 
    HOME_EXTENT_EAST, 
    HOME_EXTENT_NORTH, 
    HOME_EXTENT_SOUTH, 
    HOME_EXTENT_WEST 
} from '../../../constants/Cesium/config';
import './Cesium.css'
import './widgets.css'
import { pointUtm } from './pointUtm';
import { min, max } from 'lodash';
import iziToast from 'izitoast';
import { generateCoordinate } from '../../Cesium/helpers/eventMouseMove';
import * as turf from "@turf/turf"
// import { pointsViewer } from '../../AnalyticsMap/pointsViewer';

function createCesiumOptions() {
    const imageryProviderViewModels = Cesium.createDefaultImageryProviderViewModels()
  
    return {
      // widget
      animation: false,
      baseLayerPicker: true,
      geocoder: true,
      navigationHelpButton: false,
      selectionIndicator: false,
      timeline: true,
      // make scene only available in 3d
      // scene3DOnly: true,
      // set image provider to esri
      imageryProvider: Cesium.createWorldImagery({
        style: Cesium.IonWorldImageryStyle.AERIAL_WITH_LABELS,
      }),
      imageryProviderViewModels: imageryProviderViewModels,
      selectedImageryProviderViewModel: imageryProviderViewModels[3],
      shadows: true,
    //   mapProcjection: new Cesium.WebMercatorProjection(),
      // set terrain
      terrainProvider: Cesium.createWorldTerrain({
        requestVertexNormals : true,
        requestWaterMask : true
      }),
      // terrainExaggeration: 1,
      // for performance, need to implement after the beta version finished
      // requestRenderMode: true
      mapProjection: new Cesium.WebMercatorProjection(),
              
      // save GPU memory
    //   scene3DOnly: true, // scene only in 3d
      automaticallyTrackDataSourceClocks: false,
      // framerate?
      targetFrameRate: 30,
    }
  }

let ModelToMap = ({...props}) => {
    let {
        data,
        onDropHandler,
        onDragOverHandler,
        utmZone= {
            active:false,
            number:1,
            region:'n'
        },
        pathData
    } = props;

    let [viewer_, setViewer] = useState(null);
    let containerRef = useRef(null)
    // let [location, setLocation] = useState({})

    async function createModel(viewer, url) {
        viewer.entities.removeAll()

        let xArray=[]
        let yArray=[]
        let scale = 1
        let xyArray=[]
        let coordToMap=[]

        data.data.forEach(function(i){
            const east = i[data.headerOfLocation.x.value]
            const north = i[data.headerOfLocation.y.value]
            if(Number(east)&&Number(north) !== NaN){
                xyArray.push([Number(east),Number(north)])
                xArray.push(Number(east))
                yArray.push(Number(north))
            }
            coordToMap.push(pointUtm({
                easting:east,
                northing:north,
                region: utmZone.region,
                zone: utmZone.number,
                id:'point'
            }))
        })

        function pointsViewer({
            view,
            data,
            color,
            outlineColor,
        }){
            // view.entities.removeAll()
            for (let i = 0; i < data.length; i++) {
                const oneData = data[i]
                if(oneData!==undefined){
                    if(oneData.longitude !== null && oneData.latitude !== null){
                        data[i].entity = view.entities.add({
                            position: Cesium.Cartesian3.fromDegrees(oneData.longitude, oneData.latitude),
                            point: {
                                // scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1, 8.0e6, 0.2),
                                pixelSize: 5,
                                color: color,
                                outlineColor: outlineColor,
                                outlineWidth: 1,                    
                                heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
                            },
                            properties: {
                            ...oneData,
                            },
                        });  
                    }
                }
            }
            view.flyTo(view.entities)
        };

        
        const polygon = turf.polygon([[...xyArray,xyArray[0]]])
        const centroid = turf.centroid(polygon)
        // console.log(centroid.geometry.coordinates)

        const minValue = {
            easting: centroid.geometry.coordinates[0],
            northing: centroid.geometry.coordinates[1]
        }

        //  const minValue = {
        //     easting: min(xArray),
        //     northing: min(yArray)
        // }

        let coordLongLat
        if(utmZone.active){
            coordLongLat = pointUtm({
                easting: minValue.easting,
                northing: minValue.northing,
                region: utmZone.region,
                zone: utmZone.number,
                id:'modelDrilling'
            })
            let xScale = max(xArray) - min(xArray)
            let yScale = max(yArray) - min(yArray)
            // scale=xScale
            // console.log(scale, xScale, yScale)

        } else if( minValue.easting>181 && minValue.northing>91 ){
            iziToast.warning({
                title: 'UTM not valid',
                message: 'Please enter UTM data in previous page'
            })
            // coordLongLat = pointUtm({
            //     easting: minValue.easting,
            //     northing: minValue.northing,
            //     region: utmZone.region,
            //     zone: utmZone.number,
            //     id:'modelDrilling'
            // })
        } else{
            coordLongLat = {
                longitude:minValue.easting,
                latitude:minValue.northing,
                id:'modelDrilling'
            }
            let xScale = (max(xArray) - min(xArray))* 111.11 * 1000
            let yScale = (max(yArray) - min(yArray))* 111.11 * 1000
            // scale=xScale
            // console.log(scale, xScale, yScale)
        }

        const positions = [Cesium.Cartographic.fromDegrees(
            coordLongLat.longitude,
            coordLongLat.latitude)]
        const arrayPosition = await Cesium.sampleTerrainMostDetailed(viewer.terrainProvider, positions);
      
        const position = Cesium.Cartesian3.fromDegrees(
          coordLongLat.longitude,
          coordLongLat.latitude,
          arrayPosition[0].height
        );
        // console.log(coordLongLat.longitude,
        //     coordLongLat.longitude,
        //     arrayPosition[0].height)
        // console.log(arrayPosition[0])
        // console.log(arrayPosition)
        // const position = arrayPosition[0]
        const heading = Cesium.Math.toRadians(0);
        const pitch = 0;
        const roll = 0;
        const hpr = new Cesium.HeadingPitchRoll(heading, pitch, roll);
        const orientation = Cesium.Transforms.headingPitchRollQuaternion(
          position,
          hpr
        );
      
        const entity = viewer.entities.add({
            id:'model3d',
            name: 'model 3D',
            position: position,
            orientation: orientation,
            model: {
                uri: url,
                // heightReferences: Cesium.HeightReference.RELATIVE_TO_GROUND,
                // dimensions: new Cesium.Cartesian3(40000, 30000, 50000),
                // fill: false,
                // outline: true,
                // outlineColor: Cesium.Color.YELLOW,
                scale: scale,
                // modelMatrix: matrix
                // minimumPixelSize: 128,
                // maximumScale: 1000,
            },
        });
        pointsViewer({
            view: viewer,
            data: coordToMap,
            color: Cesium.Color.INDIANRED,
            outlineColor: Cesium.Color.BROWN
        })
        // console.log(coordToMap)
        // entity.modelMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(position);
        viewer.trackedEntity = entity;
    }
    
    useEffect(() => {
        Cesium.Ion.defaultAccessToken = CESIUM_ION_DEFAULT_ACCESS_TOKEN
        let homeExtent = new Cesium.Rectangle.fromDegrees(
            HOME_EXTENT_EAST, // E
            HOME_EXTENT_SOUTH, // S
            HOME_EXTENT_WEST, // W
            HOME_EXTENT_NORTH, // N
        );
        
        Cesium.Camera.DEFAULT_VIEW_RECTANGLE = homeExtent;
        Cesium.Camera.DEFAULT_VIEW_FACTOR = DEFAULT_VIEW_FACTOR;
        
        let viewer = new Cesium.Viewer(containerRef.current, createCesiumOptions());
        viewer.scene.skyBox.show = false
        setViewer(viewer);
        // createModel(viewer,"./model3d2.gltf",15)
        cesiumViewer.setViewer(viewer)
        cesiumViewer.initViewModel()
        cesiumViewer.cesiumMouseEventHandler(viewer)

        return () => {
            if (viewer) {
                viewer.destroy()
                setViewer(null);
        
                cesiumViewer.viewer = null
              }
            }
    }, []);

    useEffect(()=>{
        if(data.data.length!==0 && viewer_!==null ){
            createModel(viewer_,pathData)
        }
    },[data,viewer_])

    return (
        <CesiumWrapper
            onDrop={onDropHandler}
            onDragOver={onDragOverHandler}
            // onMouseDown={(event)=>{
            //     const cartesian2 = new Cesium.Cartesian2(event.clientX - 50, event.clientY - 44)
  
            //     if (!cartesian2 || isNaN(cartesian2.x) || isNaN(cartesian2.y)) {
            //     return
            //     } else {
            //     const ray = cesiumViewer.viewer.scene.camera.getPickRay(
            //         cartesian2
            //     )

            //     if (!ray.origin && !ray.direction) {
            //         return
            //     }
            
            //     const cartesian = cesiumViewer.viewer.scene.globe
            //         .pick(ray, cesiumViewer.viewer.scene)

            //     // console.log(generateCoordinate({
            //     //     cartesian
            //     //     }))
            // }}
        // }
        >
            <CesiumContainer ref={containerRef}/>
        </CesiumWrapper>
    );
}

export default ModelToMap;