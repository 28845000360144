import axiosReact, { getProgressDownload } from '../../../axiosReact'

export async function fetch({
  // map,
  params,
  // layerType,
  fetchProgress,
  fetchFinish
}) {
  if (params.withgeojson) {
    try {
      const { data } = await axiosReact.get(`/geofix/project_alteration`, {
        params,
        onDownloadProgress(event) {
          getProgressDownload(event, fetchProgress)
        }
      })

      fetchFinish()

      return {
        geojson: data
      }
    } catch (error) {
      throw error
    }
  }
}

export function parseFetch({ data }) {
  return data
}

export function getColor(entity, { map }) {
  return {
    red: Math.random(),
    green: Math.random(),
    blue: Math.random(),
    alpha: map.cesiumLayer.alpha
  }
}

export async function getLegendStyle({
  map,
  params,
  fetchProgress,
  fetchFinish
}) {
  try {
  } catch (error) {
  }
}
