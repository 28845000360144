import React from 'react'

import FocusContainer, {
  Header,
  Paragraph
} from '../StyledComponent'
import MapPNG from '../HelpImage/map.png'
import { LIST_HELP } from '../constant'


function HelpMap(props) {
  return <>
    <FocusContainer {...props}>
      <Header>{LIST_HELP['dashboard'].detail.map.header}</Header>
      <img src={MapPNG} alt="Map image" width="1100px" />
      <Paragraph>
        a. Compass, drag outer ring to rotate view, drag inner gyroscope to free orbit, double click to reset view. Tip: you can also free orbit by holding CTRL key and dragging the map.
      </Paragraph>
      <Paragraph>
        b. Directional Light, drag to set distance (in degrees) and scroll to set bearing from the camera position.
      </Paragraph>
      <Paragraph>
        c. Exaggeration Settings, drag to adjust the height of the topography on the map.
      </Paragraph>
      <Paragraph>
        d. Search Location, fill in the name of a location to find the location quickly .
      </Paragraph>
      <Paragraph>
        e. Solar Time, set the direction of sunlight based on real time, by default real time is in the left corner, to change it click or drag along the timeline.
      </Paragraph>
      <Paragraph>
        f. Map Information, displays information on the map, such as height, azimuth, plunge, and selected commodity.
      </Paragraph>
      <Paragraph>
        g. Full Screen Button, left click on button to view Geoprosp in full screen
      </Paragraph>
    </FocusContainer>
  </>
}

export default HelpMap
