import axiosReact, { getProgressDownload } from '../../../axiosReact'
import rgbToHex from '../../../helpers/rgbToHex'

export async function fetch({
  map,
  params,
  fetchProgress,
  fetchFinish
}) {
  try {
    if (params.withgeojson) {
      const { data } = await axiosReact.get('/geofix/accessibility/airport', {
        params,
        onDownloadProgress(event) {
          getProgressDownload(event, fetchProgress)
        }
      })

      data.features.forEach((feature) => {
        feature.properties = formatProperty(feature.properties)
      })

      fetchFinish()
  
      return {
        geojson: data
      }
    }
  } catch (error) {
    throw error
  }
}


export function formatProperty(property) {
  return {
    name: property.name,
    Source: property.source,
    Remark: property.REMARK
  }
}

export function parseFetch({ data }) {
  return data
}

export function getColor(entity, {
  map
}) {
  return {
    red: 1,
    green: 1,
    blue: 0,
    alpha: map.cesiumLayer.alpha
  }
}

export function getIcon(entity, color) {
  let hex = null

  const status = entity.properties.getValue('class').class

  if (status && status.toLowerCase().includes('old')) {
    hex = '#808080'
  } else if (color) {
    hex = rgbToHex(
      Math.round(color.red * 255),
      Math.round(color.green * 255),
      Math.round(color.blue * 255)
    )
  } else {
    hex = rgbToHex(
      Math.round(Math.random() * 255),
      Math.round(Math.random() * 255),
      Math.round(Math.random() * 255)
    )
  }
  const svgDataDeclare = "data:image/svg+xml,";
  const svgCircle = `<circle cx="94.8" cy="94.8" r="94.8" style="fill:%23809cda" /><path d="M93.71,27.63H95.9a.91.91,0,0,0,.25.11,8.36,8.36,0,0,1,5.48,3.2,18.3,18.3,0,0,1,3.71,9.7c.38,3.36.63,6.73.88,10.1.29,3.82.56,7.65.78,11.48s.37,7.89.56,11.84a.71.71,0,0,0,.18.47c1.64,1.2,3.29,2.37,5,3.61.14-1,.24-1.89.42-2.77a1.13,1.13,0,0,1,.5-.7,7.15,7.15,0,0,1,7-.16,1.33,1.33,0,0,1,.83,1.11,41.44,41.44,0,0,1,.39,8.33A1.05,1.05,0,0,0,122.4,85c1.69,1.17,3.35,2.37,5,3.56.16.11.33.2.54.33.1-.73.15-1.39.3-2a1.44,1.44,0,0,1,.57-.81A7.07,7.07,0,0,1,135.9,86a1.17,1.17,0,0,1,.72,1,37.65,37.65,0,0,1,.42,7.79,1.05,1.05,0,0,0,.52,1.06c3.63,2.57,7.25,5.16,10.89,7.73a1.08,1.08,0,0,1,.53,1c0,3,0,5.94,0,8.91v.76l-41.29-9.8c0,.3,0,.52,0,.75-.19,4.88-.33,9.77-.6,14.65-.28,5.13-.65,10.25-1,15.37-.24,3.1-.61,6.18-.89,9.27a.93.93,0,0,0,.34.7c1.15.86,2.33,1.68,3.5,2.52q6.19,4.41,12.38,8.82c.22.16.5.43.5.66,0,1.61,0,3.23,0,4.85h-.27a2.92,2.92,0,0,0-.49-.19q-10.47-2.49-21-5a1.18,1.18,0,0,0-.85.24,7.13,7.13,0,0,1-8.88,0,1.1,1.1,0,0,0-1.06-.21c-5.49,1.32-11,2.6-16.49,3.91-1.59.38-3.16.81-4.74,1.21H67.8c0-1.53-.07-3.06,0-4.58a1.28,1.28,0,0,1,.45-.88c1.26-.95,2.56-1.84,3.84-2.75l12-8.54c.28-.2.54-.37.45-.82-.13-.71-.22-1.44-.28-2.17-.49-6-1.06-12.1-1.44-18.15-.36-5.54-.52-11.09-.75-16.63,0-1,0-2,0-3.08l-41.38,9.8v-.76c0-3,0-5.9,0-8.84a1.16,1.16,0,0,1,.59-1.11c3.62-2.55,7.22-5.13,10.83-7.69a1,1,0,0,0,.51-1.07A35,35,0,0,1,53,86.79a1.19,1.19,0,0,1,.57-.74,7.1,7.1,0,0,1,7.12,0,1,1,0,0,1,.64.87c.07.66.18,1.32.28,2.07,1.92-1.36,3.73-2.66,5.57-3.94a1,1,0,0,0,.48-1,40.73,40.73,0,0,1,.4-8.4,1.27,1.27,0,0,1,.78-1.06,7.18,7.18,0,0,1,6.95,0,1,1,0,0,1,.64.85c.09.88.25,1.74.39,2.71,1.71-1.23,3.34-2.39,5-3.57a.73.73,0,0,0,.24-.51c.06-1.12.09-2.24.15-3.35.23-4.59.42-9.18.73-13.75.33-5,.8-9.93,1.19-14.89a22.21,22.21,0,0,1,2.46-9,9.61,9.61,0,0,1,5.69-5C92.74,27.86,93.23,27.76,93.71,27.63Z" style="fill:%23fff"/>`;
  const svgPrefix = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/2000/xlink" x="0px" y="0px" viewBox="0 0 189.6 189.6" height="60pt" width="60pt" preserveAspectRatio="xMidYMid meet">`;
  const svgSuffix = "</svg>";
  const svgString = svgPrefix + svgCircle + svgSuffix;

  // create the cesium entity
  const svgEntityImage = svgDataDeclare + svgString;
  return svgEntityImage
}