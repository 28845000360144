import * as d3 from 'd3'

import { generateScaleMethod, getScore } from '../../d3ScaleMethod'

import axiosReact, { getProgressDownload } from '../../../axiosReact'

export async function fetch({
  map,
  params,
  fetchProgress,
  fetchFinish
}) {
  params = {
    ...params,
    commodity: params.commodity,
    withgeojson: Boolean(params.withgeojson),
    withsummary: Boolean(params.withsummary)
  }

  let url = '/geoprosp/cesium_asset'
  let axiosConfig = { params }

  // if (params.withgeojson && map.id) {
  //   url = url + `/${map.id}`
  //   axiosConfig = {
  //     ...axiosConfig,
  //     onDownloadProgress(event) {
  //       getProgressDownload(event, fetchProgress)
  //     }
  //   }
  // }

  try {
    let response = await axiosReact.get(url, axiosConfig)
    response?.data?.sort((a, b) => Number(b.bin_size) - Number(a.bin_size))

    let responseNonTechnical = await axiosReact.get(url, {
      params: {
        ...axiosConfig,
        commodity: 'non_technical'
      }
    })
    responseNonTechnical?.data?.sort((a, b) => Number(b.bin_size) - Number(a.bin_size))

    let result = []

    result.push(
      ...response.data.filter(item => item.asset_type === 'final_rating_technical'),
      ...response.data.filter(item => item.asset_type === 'final_rating'),
      ...responseNonTechnical.data
    )

    if (fetchFinish instanceof Function) fetchFinish()
    result = result
      .map(item => ({ ...item, tilesetId: item.asset_id, name: item.label_name }))
      .filter(item => item.tilesetId)

    return result
  } catch (error) {
    throw error
  }
}

/**
 * INPUT:
 * data => [
 *   { bin_size: Number, id: Number, name: String }
 * ]
 * 
 * Objectives filtering less than 1 KM
 * 
 * OUTPUT:
 * [
 *   { bin_size: Number, id: Number, name: String }
 * ]
 */
 export function parseFetch({ data }) {
  let result = data.filter((data) => data.name)
  return result
}

export function getCurrentMax({ entities } ) {
  let currentMax = 0

  for (let i = 0; i < entities.length; i++) {
    let final_total = entities[i]
      .properties
      .getValue('')
      .final_rating_technical

    if (!final_total) {
      final_total = 0
    }

    if (currentMax < final_total) {
      currentMax = final_total
    }
  }

  return currentMax
}

/**
 * This function will score grid to generate color
 * @param {Boolean} inverse is state of color being inversed
 * @param {Number} score is the basic score rating from 1 - 10
 * @returns {Number} the value to used for generate d3 color
 */
 export function scoreGrid({
  inverse,
  score
}) {
  if (!inverse) {
    return score
  } else {
    return 1 - score
  }
}

export function getColor(entity, {
  map,
  currentMax
}) {
  let final_total = entity
    .properties
    .getValue('')
    .final_rating_technical

  if (!final_total) {
    final_total = 0
  }

  if (final_total < 0) {
    final_total = 0
  }

  const score = scoreGrid({
    inverse: map.cesiumLayer.inverse,
    final_total,
    currentMax
  })

  const [ red, green, blue ] = d3
    [map.colorScale](score)
    .slice(4, -1)
    .split(', ')

  return {
    red: Number(red) / 255,
    green: Number(green) / 255,
    blue: Number(blue) / 255,
    alpha: map.cesiumLayer.alpha
  }
}

export async function getLegendStyle({
  map,
  params,
  fetchProgress,
  fetchFinish
}) {
  try {
    const { data } = await axiosReact.get('/geoprosp/legend_tileset', {
      params: {
        image_type: 'grid_rating'
      }
    })

    const result = data.map((legendInfo) => {
      const [ min, max ] = legendInfo.value.split(' - ')
      
      return {
        min: Number(min),
        max: Number(max),
        color: legendInfo.legend
      }
    })

    result.sort((a, b) => b.min - a.min)

    if (fetchFinish instanceof Function) {
      fetchFinish()
    }

    return result
  } catch (error) {
  }
}
