export function cesiumGenerateColor({
  map,
  colorCb,
  mode
}) {
  map.dataSource.entities.values.forEach((entity, index) => {
    let color = null

    if (!colorCb) {
      return
    }
    
    color = colorCb(entity, {
      map,
      entities: map.dataSource.entities.values,
      index,
      mode
    })

    map.colorEntities[entity.id] = color
  })
}

export function cesiumGroupEntitiesColor({
  map,
  groupEntities
}) {
  for (let label in groupEntities.group) {
    for (let i = 0; i < groupEntities.group[label].length; i++) {
      const entity = groupEntities.group[label][i]

      map.colorEntities[entity.id] = groupEntities.style[label].color
    }
  }
}
