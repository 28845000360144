import React, { useState } from 'react'

import { ReactComponent as WarningIcon } from '../../images/Icons/warning.svg'

import InputRecommendation from './InputRecommendation'

import { WarningIconWrapper } from './shares/Icon'

import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
`

const InputWrapper = styled.input`
  width: ${props => props.width};
  height: ${props => props.height};
  font-family: Abel;
  border: 1px solid ${props => props.isError ? 'red' : 'black'};
  border-radius: 5px;
  padding: 0.2em 0.5em;

  &:focus {
    outline: none;
  }
`

const InputWarningIcon = styled(WarningIconWrapper)`
  position: absolute;
  right: 0.5em;
  top: 0;
  z-index: 1;
  cursor: pointer;
  transition: all 330ms;
`

/**
 * This input will handle text input logic
 * @param {String} width - width of input
 * @param {String} height - height of input
 * @param {String} value - text value in input
 * @param {Function} setValue - method to change input value
 * @param {Number} timeoutDuration - long duration before afterDelayHandler
 * @param {Function} afterDelayHandler - method that trigger after delay
 * @param {String} errorMessage - error message to show
 * @param {Array} fullRecomendation - array of recommendation
 * @param {Number} maxContentRecomendation - total recommendation show
 */
function InputText({
  width,
  height,
  value = '',
  setValue = () => {},
  timeoutDuration = 330,
  afterDelayHandler = () => {},
  errorMessage = '',
  fullRecommendation = [],
  maxContentRecomendation
}) {
  const [ delay, setDelay ] = useState(null)

  function onChangeHandler(event) {
    setValue(event.target.value)
    
    if (delay) {
      clearTimeout(delay)
    }

    const timeout = setTimeout(() => {
      setDelay(null)
      afterDelayHandler()
    }, timeoutDuration)

    setDelay(timeout)
  }

  function onSelectHandler(text) {
    setValue(text)

    if (delay) {
      clearTimeout(delay)
      setDelay(null)
    }

    afterDelayHandler()
  }

  return <Wrapper>
    <InputWrapper
      width={width}
      height={height}
      value={value}
      onChange={onChangeHandler}
      isError={errorMessage ? true : false}
      title={value}
    />
    {
      errorMessage
        && <InputWarningIcon width="0.8em">
          <WarningIcon title={errorMessage} />
        </InputWarningIcon>
    }
    {
      !fullRecommendation.length
        ? null
        : <InputRecommendation
          search={value}
          fullRecommendation={fullRecommendation}
          width={width}
          maxContent={maxContentRecomendation}
          onSelect={onSelectHandler}
        />
    }
  </Wrapper>
}

export default InputText
