import {
  generateListGroup,
  generateListTemplate
} from './helpers'

import {
  changeTemplateHandler,
  changeGroupByHandler,
  changeFieldAttributeHandler,
  changeFieldModeHandler,
  changeRangeHandler,
  changeGroupEntitiesHandler,
  changeSortByHandler,
  changeAlphaHandler
} from './helpers/changeHandler'

export const DEFAULT_PRECISION = 3 // total number in decimals 

/**
 * This class will integrate event listener that being connected with all event that listen to this current component
 */
export default class CesiumLayer {
  constructor() {
    this.template = null
    this.listTemplate = []
    this.groupBy = null
    this.listGroup = []
    this.fieldAttribute = {
      pickList: [],
      selected: null
    }
    this.fieldMode = {
      pickList: [],
      selected: null,
      precision: DEFAULT_PRECISION
    }
    this.sortBy = 'none'
    this.range = {
      min: 99999999,
      max: -99999999
    }
    this.sliderState = {
      min: 99999999,
      max: -99999999
    }
    this.groupEntities = []
    this.alpha = 1
    this.inverse = true
    this.showLabel = false
    this.size = 10
    this.showNoEntity = false
    this.groupEntities = []
    this.heat = null
    this.events = {
      template: [],
      listTemplate: [],
      groupBy: [],
      listGroup: [],
      fieldAttribute: [],
      fieldMode: [],
      sortBy: [],
      range: [],
      sliderState: [],
      groupEntities: [],
      alpha: [],
      inverse: [],
      size: [],
      heat: []
    }
    this.map = null
  }

  initialize = async ({ map }) => {
    try {
      let entity = null

      this.map = map

      if (map.dataSource && map.dataSource.entities && map.dataSource.entities.values) {
        entity = map.dataSource.entities.values[0]
      }

      if (map.geom) {
        const defaultListGroup = generateListGroup({ map }) || []
        const defaultListTemplate = await generateListTemplate({ map }) || []

        this.setListGroup(defaultListGroup)
        this.setListTemplate(defaultListTemplate)
        this.setAlpha(map.cesiumLayer.alpha)

        if (map.selectedTemplate) {
          await this.setTemplate(map.selectedTemplate)
        } else if (defaultListGroup.length) {
          this.setGroupBy(defaultListGroup[0].pickName)
        }
      }
    } catch (error) {
      throw error
    }
  }

  subscribeEvent(property, event) {
    if (typeof event === 'function') {
      this.events[property].push(event)
    }
  }

  unsubscribeEvent(property, unsubEvent) {
    if (typeof unsubEvent === 'function') {
      this.events[property] = this.events[property]
        .filter((event) => {
          return event !== unsubEvent
        })
    }
  }

  setListGroup(listGroup) {
    this.listGroup = listGroup

    this.events.listGroup.forEach((event) => {
      event()
    })
  }

  setListTemplate(listTemplate) {
    this.listTemplate = listTemplate

    this.events.listTemplate.forEach((event) => {
      event()
    })
  }

  async setTemplate(template) {
    this.template = template

    await changeTemplateHandler(this.map)
    
    this.events.template.forEach((event) => {
      event()
    })
  }

  setGroupBy(groupBy) {
    this.groupBy = groupBy

    changeGroupByHandler(this.map)
    
    this.events.groupBy.forEach((event) => {
      event()
    })
  }

  setFieldAttribute(fieldAttribute) {
    this.fieldAttribute = fieldAttribute

    changeFieldAttributeHandler(this.map)
    
    this.events.fieldAttribute.forEach((event) => {
      event()
    })
  }

  setFieldMode(fieldMode) {
    this.fieldMode = fieldMode

    changeFieldModeHandler(this.map)
    
    this.events.fieldMode.forEach((event) => {
      event()
    })
  }

  setRange(range) {
    this.range = range

    changeRangeHandler(this.map)
    
    this.events.range.forEach((event) => {
      event()
    })
  }

  setSortBy(sortBy) {
    this.sortBy = sortBy

    changeSortByHandler(this.map)
    
    this.events.sortBy.forEach((event) => {
      event()
    })
  }

  setGroupEntities(groupEntities) {
    this.groupEntities = groupEntities

    changeGroupEntitiesHandler(this.map)

    this.events.groupEntities.forEach((event) => {
      event()
    })
  }

  setAlpha(alpha) {
    this.alpha = alpha

    changeAlphaHandler(this.map)
    
    this.events.alpha.forEach((event) => {
      event()
    })

    this.events.groupEntities.forEach((event) => {
      event()
    })
  }

  setSliderState(sliderState) {
    this.sliderState = sliderState

    this.events.sliderState.forEach((event) => {
      event()
    })
  }

  setInverse(inverse) {
    this.inverse = inverse

    this.events.inverse.forEach((event) => {
      event()
    })
  }

  setSize(size) {
    this.size = size

    this.events.size.forEach((event) => {
      event()
    })
  }

  setHeat(heat) {
    this.heat = heat

    this.events.heat.forEach((event) => {
      event()
    })
  }
}
