import React from 'react';
import { connect } from 'react-redux';
import { TextCenter } from './partials';
import { 
    togglePreviewTable,
    changeValuesHeader,
    changeData,
} from '../../store/actions/database';
import PreviewTable from './PreviewTable';
import DropZoneContainer from './DropZone/DropZoneContainer';


function ImportSection({...props}) {
    let {
        type,
        togglePreviewTable,
        setTogglePreviewTable,
        data,
        valuesHeader,
        setValuesHeader,
        setData,
        modelWithMap,
        setModelWithMap
    } = props
    
    return (<>
        <TextCenter top={'40%'}>
            <DropZoneContainer
                setData={setData}
                type={type}
                data={data}
                setPopup={setTogglePreviewTable}
            />
        </TextCenter>
        {
            togglePreviewTable &&
            <PreviewTable
                type={type}
                popup={togglePreviewTable}
                setPopup={setTogglePreviewTable}
                headerData={data.header}
                valuesHeader={valuesHeader}
                setValuesHeader={setValuesHeader}
                data={data}
                setData={setData}
                modelWithMap={modelWithMap}
                setModelWithMap={setModelWithMap}
            />
        }
    </> 
    )
}
 function mapStateToProps(state) {
        return {
            type: state.database.type,
            togglePreviewTable: state.database.togglePreviewTable,
            data: state.database.dataImport,
            valuesHeader: state.database.valuesHeader,
        }
    }
    
const mapDispatchToProps = {
    setTogglePreviewTable: togglePreviewTable,
    setValuesHeader: changeValuesHeader,
    setData: changeData
}
    
export default connect(mapStateToProps, mapDispatchToProps)(ImportSection)