import React from 'react'

import FocusContainer, {
  Header,
  LinkText,
  Paragraph
} from '../../StyledComponent'
import SlicePNG from '../../HelpImage/assistive-button-slice-section.png'
import { LIST_HELP } from '../../constant'


function HelpSliceSection(props) {
  const flyTo=(x)=>{
    props.setFocus(x)
  }
  return <>
    <FocusContainer {...props}>
      <Header>{LIST_HELP['assistive'].detail.slice.header}</Header>
      <Paragraph>
        Slice section only use for <LinkText onClick={()=>flyTo('block')}>block section</LinkText>. The point is to cut like a <LinkText onClick={()=>flyTo('cross')}>cross section</LinkText> but this is only for cutting block sections.
      </Paragraph>
      <img src={SlicePNG} alt="Slice section image" width="1100px" />
    </FocusContainer>
  </>
}

export default HelpSliceSection