import React, { useState, useEffect } from 'react'

import AlphaSlider from '../../../../../Sliders/AlphaSlider'

/**
 * This component will create alpha slider that being used for display
 * @param map is the current map based on category
 * @returns 
 */
function DisplayAlphaSlider({ map }) {
  const [ alpha, setAlpha ] = useState(map.cesiumLayer.alpha)

  useEffect(() => {
    function eventHandler() {
      setAlpha(map.cesiumLayer.alpha)
    }

    map.cesiumLayer.subscribeEvent('alpha', eventHandler)

    return () => {
      map.cesiumLayer.unsubscribeEvent('alpha', eventHandler)
    }
  }, [])

  /**
   * This function will handle the slider logic when input range value is changed
   * @param value new value based on slider position
   */
  function valueOnChangeHandler(value) {
    value = Number(value)
    
    map.cesiumLayer.setTemplate(null)
    map.cesiumLayer.setAlpha(value)
  }

  return (
    <AlphaSlider
      height="0.5em"
      width="2.5em"
      min={0}
      max={1}
      initialValue={alpha}
      valueOnChange={valueOnChangeHandler}
    />
  )
}

export default DisplayAlphaSlider
