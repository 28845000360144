import { centroid as turfCentroid } from '@turf/turf'

import { generateTurfPolygonFromPolygon } from './generatePolygon'

import SectionMath from './SectionMath'

/**
 * get centroid of plane polygon
 * @param {Array} polygon - polygon
 * @param {Object} dragMovement - object that have top and bottom position
 */
export function getCentroidPolygon(polygon, dragMovement) {
  const turfPolygon = generateTurfPolygonFromPolygon(polygon)

  const turfCentroidPolygon = turfCentroid(turfPolygon)

  // clipping point data
  // clipPointData(turfPolygon)

  return {
    longitude: turfCentroidPolygon.geometry.coordinates[0],
    latitude: turfCentroidPolygon.geometry.coordinates[1],
    height: (dragMovement.top + dragMovement.bottom) / 2
  }
}

/**
 * get centroid of plane polygon
 * @param {Object} sliceMovement - object that slice position
 * @param {Object} dragMovement - object that have top and bottom position
 */
export function getCentroidSlice(sliceMovement, dragMovement) {
  const bearing = SectionMath.getPlaneBearing(sliceMovement)

  const firstPoint = SectionMath.convertMetersToLongLat({
    longitude: sliceMovement.start.longitude,
    latitude: sliceMovement.start.latitude,
    distance: sliceMovement.crossWidth.left / 1000,
    bearing: bearing + 90
  })

  const secondPoint = SectionMath.convertMetersToLongLat({
    longitude: sliceMovement.start.longitude,
    latitude: sliceMovement.start.latitude,
    distance: sliceMovement.crossWidth.right / 1000,
    bearing: bearing + 270
  })

  const thirdPoint = SectionMath.convertMetersToLongLat({
    longitude: sliceMovement.end.longitude,
    latitude: sliceMovement.end.latitude,
    distance: sliceMovement.crossWidth.right / 1000,
    bearing: bearing + 270
  })

  const fourthPoint = SectionMath.convertMetersToLongLat({
    longitude: sliceMovement.end.longitude,
    latitude: sliceMovement.end.latitude,
    distance: sliceMovement.crossWidth.left / 1000,
    bearing: bearing + 90
  })

  const polygon = [
    firstPoint.longitude, firstPoint.latitude,
    secondPoint.longitude, secondPoint.latitude,
    thirdPoint.longitude, thirdPoint.latitude,
    fourthPoint.longitude, fourthPoint.latitude
  ]

  return getCentroidPolygon(polygon, dragMovement)
}
