import React, { useRef, useState, useEffect } from 'react'

import * as Cesium from 'cesium'

import cesiumViewer from '../../apis/cesiumViewer'

import { ReactComponent as SunIcon } from '../../images/Icons/sun.svg'
import PlanetPNG from '../../images/Globes/indo-icon.png'

import styled from 'styled-components'

import { GOLDEN_3D } from '../shares/ColorTemplate'

const Wrapper = styled.div`
  width: 4em;
  height: 4em;
  position: absolute;
  right: 0.3em;
  top: 7em;
  user-select: none;
`

const BlackLayer = styled.div`
  position: absolute;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(2, 2, 2, 0.5);
  user-select: none;
`

const Container = styled.div`
  position: relative;
  width: 4em;
  height: 4em;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  box-shadow: -1px 1px 9px 1px #888888;
  user-select: none;
`

const PointLight = styled(SunIcon)`
  position: absolute;
  width: 1em;
  height: 1em;
  fill: ${GOLDEN_3D.default};
  border-radius: 50%;
  cursor: pointer;
  z-index: 101;
`

const Tooltip = styled.div.attrs((props) => {
  return {
    style: {
      opacity: props.show ? 1 : 0
    }
  }
})`
  position: absolute;
  right: 6em;
  width: 6em;
  height: 4em;
  z-index: 1002;
  background: white;
  border-top-left-radius: 2.5px;
  border-top-right-radius: 2.5px;
  border-bottom-left-radius: 2.5px;
  border-bottom-right-radius: 2.5px;
  font-family: Abel;
  font-size: 0.8em;
  padding: 0.5em;
  transition: all 330ms;
  pointer-events: none;
`

const Paragraph = styled.p`
  margin: 0;
`

let timer = null

/**
 * This function will create light based on distance and bearing provided
 */
function CesiumLight() {
  const [ sunData, setSunData ] = useState({
    status: 'sun',
    distance: 0, // distance is between -1.5 to 1.5 radians, 0 is the top of earth
    bearing: 0 // bearing (in degrees) is the azimuth from the center to point source of light from 360 to -360 (anticlockwise),
  })
  const [ position, setPosition ] = useState({
    top: 0,
    left: 0
  })
  const [ light, setLight ] = useState({
    heading: null,
    positionWC: null
  })
  const [ hold, setHold ] = useState(false)
  const [ tooltip, setTooltip ] = useState({
    show: false
  })

  const containerRef = useRef(null)

  /**
   * This function will change distance and bearing position
   * if the mouse click the container outside the sun ball
   * @param {*} event from the react when click mouse
   */
  function clickHandlerToSetDistanceAndBearing(event) {
    if (hold) {
      return
    }

    if (sunData.status === 'sun') {
      return
    }

    // this function will set distance and bearing based on container position
    if (event.nativeEvent) {
      let { layerX, layerY } = event.nativeEvent

      // 4em = 64px
      const height = 64

      let newDistance = Math.sqrt(
        Math.pow((height / 2) - layerX, 2)
        + Math.pow((height / 2) - layerY, 2)
      ) / (height / 2.5) * 1.5

      if (newDistance > 1.5) {
        newDistance = 1.5
      }

      const newBearing = 90 - Cesium.Math.toDegrees(
        Math.atan2(
          (height / 2) - layerY,
          (height / 2) - layerX
        )
      ) + sunData.heading

      setSunData((sunData) => ({
        ...sunData,
        distance: newDistance,
        bearing: newBearing % 360 
      }))
    }
  }

  function toggleSunlightAndDirectionlight() {
    if (!sunData.status || sunData.status === 'sun') {
      setSunData((sunData) => ({
        ...sunData,
        status: 'direction'
      }))
      
      setFlashlight({
        bearing: sunData.bearing,
        distance: sunData.distance
      })
    } else {
      setSunData((sunData) => ({
        ...sunData,
        status: 'sun',
        distance: 0,
        bearing: 0
      }))

      setSunlight()
    }
  }

  /**
   * This function will get position of the light in the container
   * @returns number of the current top position based on distance and azimuth
   */
  function getPointLightTop() {
    if (containerRef.current) {
      // 4em = 64px
      const height = 64
      
      // circle 4 times more little than the container
      const centerPoint = height / 2 - height / 8

      return centerPoint
        + centerPoint
          * sunData.distance / 1.5
          * Math.cos(Cesium.Math.toRadians(sunData.bearing + 180))
        + 'px'
    }
  }

  /**
   * This function will get position of the light in the container
   * @returns number of the current left position based on distance and azimuth
   */
  function getPointLightLeft() {
    if (containerRef.current) {
      // 4em = 64px
      const height = 64
      
      // circle 8 times more little than the container
      const centerPoint = height / 2 - height / 8

      return centerPoint
        + centerPoint
          * sunData.distance / 1.5
          * Math.sin(Cesium.Math.toRadians(sunData.bearing + 180))
        + 'px'
    }
  }

  /**
   * This function to set the light into flashlight
   * from data bearing, distance, and current camera position
   * @param {number} bearing is the bearing from the camera position
   * @param {number} distance is the distance from the camera position
   * @exceptions this function need cesiumViewer that have viewer as the property
   */ 
  function setFlashlight({
    bearing,
    distance
  }) {
    if (!cesiumViewer || !cesiumViewer.viewer) {
      return
    }

    if (isNaN(bearing) || isNaN(distance)) {
      return
    }

    const newBearing = (bearing - 90) + 180
          
    const cartographic = cesiumViewer.viewer.scene.camera.positionCartographic.clone()
    // cartographic.height = Cesium.Cartographic.fromCartesian(sunCartesian).height
    cartographic.longitude = cartographic.longitude + distance  * Math.cos(Cesium.Math.toRadians(newBearing))
    cartographic.latitude = cartographic.latitude + distance * Math.sin(Cesium.Math.toRadians(newBearing))

    const cartesian = Cesium.Cartographic.toCartesian(cartographic)
    
    Cesium.Cartesian3.normalize(cartesian, cartesian)
    Cesium.Cartesian3.negate(cartesian, cartesian)
    
    cesiumViewer.viewer.scene.light = new Cesium.DirectionalLight({
      direction: cartesian,
      intensity: 1000
    })
  }

  /**
   * This function to set the light into normal sunlight
   */ 
  function setSunlight() {
    if (cesiumViewer.viewer) {
      cesiumViewer.viewer.scene.light = new Cesium.SunLight()
    }
  }

  /**
   * This function will set distance based on event
   */
  function moveDistance(event) {
    if (hold) {
      let newDistance = sunData.distance
      let bearing = Math.abs(sunData.bearing - sunData.heading + 360) % 360

      if (bearing < 0) {
        bearing += 360
      }

      if (bearing === 0) {
        if (event.movementY < 0) {
          newDistance += 0.2
        } else if(event.movementY > 0) {
          newDistance -= 0.2
        }
      } else if (bearing === 90) {
        if (event.movementX < 0) {
          newDistance += 0.2
        } else if(event.movementX > 0) {
          newDistance -= 0.2
        }
      } else if (bearing === 180) {
        if (event.movementY < 0) {
          newDistance -= 0.2
        } else if(event.movementY > 0) {
          newDistance += 0.2
        }
      } else if (bearing === 270) {
        if (event.movementX > 0) {
          newDistance += 0.2
        } else if(event.moveDistance < 0) {
          newDistance -= 0.2
        }
      } else if (bearing > 270) {
        if (event.movementX > 0 || event.movementY < 0) {
          newDistance += 0.15
        } else if (event.movementX < 0 || event.movementY > 0) {
          newDistance -= 0.15
        }
      } else if (bearing > 180) {
        if (event.movementX > 0 || event.movementY > 0) {
          newDistance += 0.15
        } else if (event.movementX < 0 || event.movementY < 0) {
          newDistance -= 0.15
        }
      } else if (bearing > 90) {
        if (event.movementX < 0 || event.movementY > 0) {
          newDistance += 0.15
        } else if (event.movementX > 0 || event.movementY < 0) {
          newDistance -= 0.15
        }
      } else if (bearing > 0) {
        if (event.movementX < 0 || event.movementY < 0) {
          newDistance += 0.15
        } else if (event.movementX > 0 || event.movementY > 0) {
          newDistance -= 0.15
        }
      }

      if (newDistance > 1.5) {
        newDistance = 1.5
      } else if (newDistance < -1.5) {
        newDistance = -1.5
      }

      setSunData((sunData) => ({
        ...sunData,
        distance: newDistance
      }))
    }
  }

  /**
   * This function will rotate the bearing based on the event wheel
   */
  function rotateBearing(event) {
    let newBearing = sunData.bearing

    if (event.deltaY < 0) {
      newBearing -= 10
    } else {
      newBearing += 10
    }
    
    setSunData((sunData) => ({
      ...sunData,
      bearing: newBearing % 360
    }))
  }

  /**
   * This function will handle single or double click event
   * @param {*} event from click 
   */
  function onClickHandler(event) {
    clearTimeout(timer);
    if (event.detail === 1) {
      timer = setTimeout(() => {
        clickHandlerToSetDistanceAndBearing(event)
      }, 200)
    } else if (event.detail === 2) {
      toggleSunlightAndDirectionlight()
    }
  }

  // this use effect is for rotating
  useEffect(() => {
    if (containerRef.current) {
      setPosition((position) => ({
        ...position,
        top: getPointLightTop(),
        left: getPointLightLeft()
      }))
    }

    return () => {
      setPosition({
        top: 0,
        left: 0
      })
    }
  }, [sunData.bearing, sunData.distance])

  // this use effect is for give flash light
  // when the current status is direction
  useEffect(() => {
    if (containerRef.current) {
      if (sunData.status === 'direction') {
        setFlashlight({
          bearing: sunData.bearing,
          distance: sunData.distance
        })
      }
    }

    return () => {
      setFlashlight({
        bearing: null,
        distance: null
      })
    }
  }, [sunData.heading, sunData.bearing, sunData.distance])

  // this use effect to listen the movement
  // that changed the cesium position to WC / heading
  useEffect(() => {
    if (
      light.heading
    ) {
      if (containerRef.current && cesiumViewer && cesiumViewer.viewer) {
        setSunData((sunData) => ({
          ...sunData,
          heading: -Cesium.Math.toDegrees(cesiumViewer.viewer.camera.heading)
        }))
      }
    }

    return () => {}
  }, [light.heading])

  useEffect(() => {
    // wait 1 second before add method to viewer
    setTimeout(() => {
      cesiumViewer.addCameraHandler(setLight)
    }, 1000)
  }, [])

  return <Wrapper draggable="false">
    <div className="cesium-viewer-toolbar">
      <Tooltip show={tooltip.show}>
        <Paragraph>
          Bearing: {Math.abs((sunData.bearing - 360) % 360).toFixed(1)}°
        </Paragraph>
        <Paragraph>
          Angle: {(90 - Math.abs(sunData.distance) * 60).toFixed(1)}°
        </Paragraph>
        <Paragraph>
          Light: {sunData.status}
        </Paragraph>
      </Tooltip>
      <Container
        style={{
          transform: `rotate(${sunData.heading}deg)`
        }}
        ref={containerRef}
        onClick={onClickHandler}
        onMouseEnter={() => {
          setTooltip({
            show: true
          })
        }}
        onMouseUp={() => {
          setTimeout(() => {
            setHold(false)
          }, 1)
        }}
        onMouseLeave={() => {
          setTooltip({
            show: false
          })
          setHold(false)
        }}
        onMouseMove={moveDistance}
        onWheel={rotateBearing}
        draggable="false"
      >
        {
          sunData.status === 'sun'
          && <BlackLayer />
        }
        <img
          src={PlanetPNG}
          alt="globe"
          style={{
            width: '100%',
            height: '100%'
          }}
          draggable="false"
        />
        <PointLight
          style={{
            top: position.top,
            left: position.left
          }}
          onClick={onClickHandler}
          draggable="false"
          onMouseDown={() => {
            setHold(true)
          }}
        />
      </Container>
    </div>
  </Wrapper>
}

export default CesiumLight
