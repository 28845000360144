import React from 'react'

import BasicLayerPNG from '../../HelpImage/basic-layer.png'
import BasicLayerDisplayPNG from '../../HelpImage/basic-layer-display.png'
import BasicLayerDisplayDetailPNG from '../../HelpImage/basic-layer-display-detail.png'
// import DisplayGridPNG from '../../HelpImage/display-grid(dummy).png'
// import DisplayGeologyPNG from '../../HelpImage/display-geology.png'
// import DisplayEarthquakesPNG from '../../HelpImage/display-earthquakes.PNG'
// import DisplayGeochemicalPNG from '../../HelpImage/display-geochemical.png'

import FocusContainer, {
  Header,
  List,
  Paragraph
} from '../../StyledComponent'
import { LIST_HELP } from '../../constant'

function HelpLayer(props) {
  return <>
    <FocusContainer {...props}>
      <Header>{LIST_HELP['menu'].detail.layer.header}</Header>
      <Paragraph>
        Once user select the commodity, the data catalog will contains available datasets of the commodity selected. To visualize the datasets user can expand the data section and then clicking the checkbox untill the progress icon appears to give an information of the downloading and rendering progress. The data will be visible on display section where user can configure the styling properties.
      </Paragraph>
      <img src={BasicLayerPNG} alt="Basic Layer image" width="900px" style={{marginBottom: '1em'}} />
      <Header>How to set data display on map?</Header>
      <Paragraph>
        To set the display of data available on the map available on the display. In the display section there are several tools, such as: show/hide control (eye icon), transparency control, color control, remove button and other settings based on each data, because each data is unique. 
      </Paragraph>
      <Paragraph>
        On display there will always be a Globe, because Globe is the basis for Geoprosp to display its spatial data. Globe has different settings from the others, there are: 
      </Paragraph>
      <Paragraph style={{marginLeft: '1em'}}>
        1. Dimension controller (can choose between 3d and 2d views), and
      </Paragraph>
      <Paragraph style={{marginLeft: '1em'}}>
        2. Imaginary and terrain picker (to select surface view of Globe).
      </Paragraph>
      <img src={BasicLayerDisplayPNG} alt="Basic Layer display image" width="600px"  style={{marginBottom: '1em'}}/>
      <Paragraph>
        The detail of color controller and more option button is as below.
      </Paragraph>
      <Paragraph style={{marginLeft: '1em'}}>
        1. In the color controller there are several gradient colors (if the data allows) as well as colors from the templates that are already available (still under development) and color gradient scale settings (linear or logarithmic). 
      </Paragraph>
      <Paragraph style={{marginLeft: '1em'}}>
        2. If gradient color picked, you can reverse that color by click on reverse color button. 
      </Paragraph>
      <Paragraph style={{marginLeft: '1em', marginBottom:'0.5em'}}>
        3. The others settings, you can reverse that color by click on reverse color button. 
      </Paragraph>
      <Paragraph>
        If you want to see other display settings, click the more options button, then each data will have its own settings, but in default the settings will have several settings such as,
      </Paragraph>
      <ul style={{ margin: 0 }}>
        <List>2D/3D toggle</List>
        <List>Save button to save the settings that have been set</List>
        <List>Table button to view data in tabular form</List>
        <List>Some other setting options (There is a label setting to mark the data in the map in text form)</List>
      </ul>
      <img src={BasicLayerDisplayDetailPNG} alt="Basic Layer display detail image" width="900px" style={{marginBottom: '1em'}}/>
      {/* <Paragraph>
        Some other setting options adjust selected data, because different data will have different display settings. Some of the data that is already available on Geoprosp with different settings, including:
      </Paragraph>
      <Paragraph style={{marginLeft: '1em'}}>
        1. Grid data which in Geoprosp is prospectivity rating data. This data has 2 typical settings, namely grouping to select fields in filtering data and filter range to determine range of data in filtering to be displayed on the map.
      </Paragraph>
      <img src={DisplayGridPNG} alt="Basic Layer grid display detail image" width="500px" style={{marginBottom: '1em'}}/>
      <Paragraph style={{marginLeft: '1em'}}>
        2. Geology, Geophysics, Lineament data. Color of this data can be changed per one data.
      </Paragraph>
      <img src={DisplayGeologyPNG} alt="Basic Layer geology display detail image" width="800px" style={{marginBottom: '1em'}} />
      <Paragraph style={{marginLeft: '1em'}}>
        3. Earthquake data has special settings, which can be filtered based on the time span of the occurrence.
      </Paragraph>
      <img src={DisplayEarthquakesPNG} alt="Basic Layer earthquake display detail image" width="450px" style={{marginBottom: '1em'}}/>
      <Paragraph style={{marginLeft: '1em'}}>
        4. Geochemical data has two types of data namely points and areas, both of which have different settings. The difference is only in the data filter. Settings like this are also found on remote sensing mineral map data.
      </Paragraph>
      <img src={DisplayGeochemicalPNG} alt="Basic Layer geochemical display detail image" width="750px" style={{marginBottom: '1em'}}/>
      <Paragraph>
        
      </Paragraph> */}
    </FocusContainer>
  </>
}

export default HelpLayer
