import React, { useEffect, useRef } from 'react'

import styled from 'styled-components'

export const Container = styled.div`
  padding: 1em 2em;
`

export const Header = styled.h1`
  margin-block-start: 0.3em;
  margin-block-end: 0.3em;
  font-family: Abel;
`

export const Paragraph = styled.p`
  margin: 0;
  font-family: Abel;
`
export const List = styled.li`
  margin: 0;
  font-family: Abel;
`

export const HeaderSearch = styled.div`
  background-color: gainsboro;
  padding: 1.5em 5em 1em 5em;
  height:28px;
`

export const LinkText = styled.span`
  cursor: pointer;
  color: red;
`

function FocusContainer({ focus, setFocus, opened, setOpened, currentHelp, children }) {
  const containerRef = useRef(null)
  
  useEffect(() => {
    if (containerRef.current && focus === currentHelp) {
      containerRef.current.scrollIntoView({ behavior: 'smooth'})
    } 
  }, [focus])

  return (
    <Container ref={containerRef}>
      {children}
    </Container>
  )
}

export default FocusContainer
