import axiosReact, { getProgressDownload } from '../../../axiosReact'

export async function fetch({
  params,
  fetchProgress,
  fetchFinish
}) {
  try {
    try {
      let { data } = await axiosReact.get('/geoprosp/cesium_asset', {
        params: {
          ion_name: 'road'
        }
      })

      if (fetchFinish instanceof Function) fetchFinish()
      data = data
        .map(item => ({ ...item, tilesetId: item.asset_id, name: item.label_name }))
        .filter(item => item.tilesetId)

      return data[0]
    } catch (error) {
      throw error
    }
  } catch (error) {
    throw error
  }
}

export function parseFetch({ data }) {
  return data
}

export function getColor(_, {
  map
}) {
  return {
    red: Math.random(),
    green: Math.random(),
    blue: Math.random(),
    alpha: map.cesiumLayer.alpha
  }
}

export async function getLegendStyle({
  map,
  params,
  fetchProgress,
  fetchFinish
}) {
  try {
    const { data } = await axiosReact.get('/geoprosp/legend_tileset', {
      params: {
        image_type: 'roads'
      }
    })

    const result = data.map((legendInfo) => {
      return {
        label: legendInfo.value,
        color: legendInfo.legend,
        geom: 'LineString'
      }
    })

    if (fetchFinish instanceof Function) {
      fetchFinish()
    }

    return result
  } catch (error) {
  }
}
