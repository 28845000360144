import {
  Cartesian3,
  Plane,
  ClippingPlane

} from 'cesium'

/**
 * This function will create clipping planes based on polygon
 * @param {Array} polygon array of position and MUST BE clockwise 
 * @param {Object} centroid the center of end position and the beginning of the position
 * @param {Object} dragMovement - object that have top and bottom position
 * @returns {Array} of cesium clipping plane
 */
export function clippingPlanesFromPolygon(
  polygon,
  centroid,
  dragMovement
) {
  const clippingPlanes = []

  // https://github.com/CesiumGS/cesium-ion-rest-api-examples/issues/198
  for (let i = 0; i < polygon.length; i++) {
    const nextIndex = (i + 1) % polygon.length
    let midpoint = Cartesian3.add(polygon[i], polygon[nextIndex], new Cartesian3())
    midpoint = Cartesian3.multiplyByScalar(midpoint, 0.5, midpoint)

    const up = Cartesian3.normalize(midpoint, new Cartesian3())
    let right = Cartesian3.subtract(polygon[nextIndex], midpoint, new Cartesian3())
    right = Cartesian3.normalize(right, right)

    let normal = Cartesian3.cross(right, up, new Cartesian3())
    normal = Cartesian3.normalize(normal, normal)

    // Compute distance by pretending the plane is at the origin
    const originCenteredPlane = new Plane(normal, 0.0)

    const distance = Plane.getPointDistance(originCenteredPlane, midpoint)

    clippingPlanes.push(new ClippingPlane(normal, distance))
  }

  const cartesianCenter = new Cartesian3.normalize(
    new Cartesian3.fromDegrees(
      centroid.longitude,
      centroid.latitude,
      centroid.height
    ),
    new Cartesian3()
  )

  const originCenteredPlane = new Plane(cartesianCenter, 0.0)

  const top = new ClippingPlane(
    new Cartesian3(
      -cartesianCenter.x,
      -cartesianCenter.y,
      -cartesianCenter.z
    ),
    Plane.getPointDistance(
      originCenteredPlane,
      new Cartesian3.fromDegrees(
        centroid.longitude,
        centroid.latitude
      )
    ) + dragMovement.top
  )

  clippingPlanes.push(top)

  const bottom = new ClippingPlane(
    new Cartesian3(
      cartesianCenter.x,
      cartesianCenter.y,
      cartesianCenter.z
    ),
    -Plane.getPointDistance(
      originCenteredPlane,
      new Cartesian3.fromDegrees(
        centroid.longitude,
        centroid.latitude
      )
    ) - dragMovement.bottom
  )

  clippingPlanes.push(bottom)

  return clippingPlanes
}
