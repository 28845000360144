import React from 'react'

import cesiumViewer from '../../../../apis/cesiumViewer'

import {
  ReactComponent as HomeIcon
} from '../../../../images/Icons/home.svg'

import ContentIcon from './ContentIcon'

/**
 * The component Home button
 * @param {Object} content that represent the button given
 * @param {Number} translate to set how far this component is translated
 * @param {Number} left the left position of button
 * @param {Number} top the top position of button
 * @param {Number} r radius of the black circle
 * @param {Number} radians the radians of rotation value
 * @param {Number} widthSVG is the width given for SVG
 * @param {Boolean} toggleDisplay status that button being shown or not
 * @param {Number} key is the id of component
 */
function HomeButton({
  content,
  translate,
  left,
  top,
  r,
  radians,
  widthSVG,
  toggleDisplay,
  key
}) {
  function onClickHandler() {
    if (cesiumViewer.viewer) {
      cesiumViewer.viewer.scene.camera.flyHome()
    }
  }

  return (
    <ContentIcon
      key={key}
      top={
        toggleDisplay
          ? top + 'em'
          : top - translate - r * Math.sin(radians) + 'em'
      }
      left={
        toggleDisplay
          ? left + 'em'
          : left - translate - r * Math.cos(radians) + 'em'
      }
      height={widthSVG + 'em'}
      width={widthSVG + 'em'}
    >
      <HomeIcon
        title={content.title}
        onClick={onClickHandler}
      />
    </ContentIcon>
  )
}

export default HomeButton
