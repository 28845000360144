import React from 'react';

import { ReactComponent as CloseIcon } from '../../../../images/Icons/close.svg'

import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  top: 0.2em;
  right: 1em;
  cursor: pointer;

  svg {
    width: 0.8em;
    height: 0.8em;
  }
`;

/**
 * This method to hide editor
 * @param {Function} hideEditor - method to hide editor
 */
function HideButton({
  hideEditor
}) {
  return <Wrapper onClick={hideEditor}>
    <CloseIcon fill="red" />
  </Wrapper>
};

export default HideButton;
