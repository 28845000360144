import React from 'react'

import LayerbarIcon from './LayerbarIcon'

import {
  ReactComponent as BottomIcon
} from '../../../../images/Icons/triangle-bottom.svg'

import {
  ReactComponent as RightIcon
} from '../../../../images/Icons/triangle-right.svg'

import {
  ReactComponent as CheckIcon
} from '../../../../images/Icons/check-box.svg'

import {
  ReactComponent as EmptyIcon
} from '../../../../images/Icons/empty-box.svg'

import {
  ReactComponent as AscendingIcon
} from '../../../../images/Icons/ascending-filter.svg'

import {
  ReactComponent as DescendingIcon
} from '../../../../images/Icons/descending-filter.svg'

function DefaultToggle({ toggle, ...rest }) {
  return toggle
    ? <BottomIcon {...rest} />
    : <RightIcon {...rest} />
}

function CheckToggle({ toggle, ...rest }) {
  return toggle
    ? <CheckIcon {...rest} />
    : <EmptyIcon {...rest} />
}

function AscDescToggle({ toggle, ...rest }) {
  return toggle
    ? <AscendingIcon {...rest} />
    : <DescendingIcon {...rest} />
}

function Toggle({ type, toggle, ...rest }) {
  if (type === 'check') {
    return <CheckToggle toggle={toggle} {...rest} />
  } else if (type === 'asc/desc') {
    return <AscDescToggle toggle={toggle} {...rest} />
  }

  return <DefaultToggle toggle={toggle} {...rest} />
}

function ToggleIcon({
  toggle,
  size,
  toggleAble,
  type,
  isActive,
  ...rest
}) {
  return <LayerbarIcon
    size={size}
    toggleAble={toggleAble}
    isActive={isActive}
    {...rest}
  >
    <Toggle type={type} toggle={toggle} {...rest} />
  </LayerbarIcon>
}

export default ToggleIcon
