import React from 'react'

import ToggleMap from '../Map/ToggleMap'
import DataTypeSelector from '../DataTypeSelector'

function ZeroLevel({
  category,
  categoryValue
}) {
  return (
    <ToggleMap
      layerTypeComponent={
        <DataTypeSelector
          data={categoryValue}
          category={category}
        />
      }
      map={categoryValue.map}
      onProgress={categoryValue.onProgress}
    />
  )
}

export default ZeroLevel
