import * as actionTypes from '../../constants/store/actionTypes/advancedSearch'

export function setAdvancedSearch(payload) {
  return {
    type: actionTypes.SET_ADVANCED_SEARCH,
    payload
  }
}

export function setAdvancedSearchSuccess(payload) {
  return {
    type: actionTypes.SET_ADVANCED_SEARCH_SUCCESS,
    payload
  }
}

export function setAdvancedSearchLimit(payload) {
  return {
    type: actionTypes.SET_ADVANCED_SEARCH_LIMIT,
    payload
  }
}

export function setAdvancedSearchOptions(payload) {
  return {
    type: actionTypes.SET_ADVANCED_SEARCH_OPTIONS,
    payload
  }
}

export function resetAdvancedSearchOptions(payload) {
  return {
    type: actionTypes.RESET_ADVANCED_SEARCH_OPTIONS,
    payload
  }
}

export function setAdvancedSearchError(payload) {
  return {
    type: actionTypes.SET_ADVANCED_SEARCH_ERROR,
    payload
  }
}

export function resetAdvancedSearch(payload) {
  return {
    type: actionTypes.RESET_ADVANCED_SEARCH,
    payload
  }
}
