import iziToast from 'izitoast';
import proj4 from 'proj4';

export function pointUtm({...props}) {
    let {
        easting,
        northing,
        region,
        zone,
        id
    } = props
    if(easting !== null && easting!==undefined && northing!== null && northing !==undefined && !isNaN(easting) && !isNaN(northing)){
        if(region.toLowerCase()==="s"){
            let utm = `+proj=utm +zone=${zone} +south +datum=WGS84 +units=m +no_defs`;
            let lonlat = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';
            let llCoords = proj4(utm, lonlat, [Number(easting), Number(northing)]);
            let latlong = {
                longitude:llCoords[0],
                latitude:llCoords[1],
                id:id
            }
            return latlong
        } else if(region.toLowerCase()==="n"){
            let utm = `+proj=utm +zone=${zone} +datum=WGS84 +units=m +no_defs`;
            let lonlat = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';
            let llCoords = proj4(utm, lonlat, [Number(easting), Number(northing)]);
            let latlong = {
                longitude:llCoords[0],
                latitude:llCoords[1],
                id:id
            }
            return latlong
        } else {
            iziToast.warning({
                title: 'Convert uncomplete',
                message: 'Your data unavailable to show on map'
            })
        }
    }
}