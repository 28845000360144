import React from "react";
import Navbar from "../../components/Menubars/Navbar";
import "./success.css";
import { Link } from 'react-router-dom';
import SuccessLogo from "./success.svg";

function Success (props) {
    
  return (
    <div className="content-block">
      <Navbar className="row"/>
      <div className="img-bg-big">
        <div className="container-reset input-email-form ">
            <div className="row">
              <img src={SuccessLogo} alt="Logo-reset-password" className=" logo-reset"/>
            </div>
            <div>
              <p className="row text-center-reset">Yay, your registration is complete!</p>
            </div>
            <p className="text-center-input">Congratulations, your account has been successfully created. Please login first to start your journey!</p>
            <div className="row button-success">
            <Link className="link-login" to="/login">
              <button
                type="submit"
                className="button-back-login"
              > <span>Login</span>
              </button> </Link>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Success