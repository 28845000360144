export function generateSequences ({
  GEOLOGICAL_MAP_COLOR_REFERENCES,
  GEOLOGICAL_STRATIGRAPHIC_KEYWORDS,
  GEOLOGICAL_TIME_SCALE,
  values
}) {
  const sequences = []

  for (let i = 0; i < values.length; i++) {
    const properties = values[i]
    let useGroup = null
    let dominantLithology = null
    let sandi = null
    let indexBeginAge = null
    let beginAge = null
    let indexEndAge = null
    let endAge = null
    let position = properties.position
    let color = null
    let type = 'sediment'

    for (let j = 0; j < GEOLOGICAL_STRATIGRAPHIC_KEYWORDS.age.begins.length; j++) {
      const begin = GEOLOGICAL_STRATIGRAPHIC_KEYWORDS.age.begins[j]

      if (properties[begin]) {
        beginAge = properties[begin]
        break
      }
    }

    for (let j = 0; j < GEOLOGICAL_STRATIGRAPHIC_KEYWORDS.age.ends.length; j++) {
      const end = GEOLOGICAL_STRATIGRAPHIC_KEYWORDS.age.ends[j]

      if (properties[end]) {
        endAge = properties[end]
        break
      }
    }

    for (let j = 0; j < GEOLOGICAL_STRATIGRAPHIC_KEYWORDS.listSandi.length; j++) {
      const keySandi = GEOLOGICAL_STRATIGRAPHIC_KEYWORDS.listSandi[j]

      if (properties[keySandi]) {
        sandi = properties[keySandi]
        break
      }
    }

    for (let j = 0; j < GEOLOGICAL_STRATIGRAPHIC_KEYWORDS.dominantLithologies.length; j++) {
      const keyDominant = GEOLOGICAL_STRATIGRAPHIC_KEYWORDS.dominantLithologies[j]

      if (properties[keyDominant]) {
        dominantLithology = properties[keyDominant]
        break
      }
    }

    for (let j = 0; j < GEOLOGICAL_STRATIGRAPHIC_KEYWORDS.useGroups.length; j++) {
      const keyUseGroup = GEOLOGICAL_STRATIGRAPHIC_KEYWORDS.useGroups[j]
      if (properties[keyUseGroup]) {
        useGroup = properties[keyUseGroup]
        for (let k = 0; k < GEOLOGICAL_MAP_COLOR_REFERENCES.length; k++) {
          const colorReference = GEOLOGICAL_MAP_COLOR_REFERENCES[k]
          if (
            properties[keyUseGroup] === colorReference.key
          ) {
            color = colorReference.color
            type = colorReference.type
            break
          }
        }
        break
      }
    }

    // for (let j = 0; j < GEOLOGICAL_STRATIGRAPHIC_KEYWORDS.positions.length; j++) {
    //   const keyPosition = GEOLOGICAL_STRATIGRAPHIC_KEYWORDS.positions[j]
    //   if (properties[keyPosition.name]) {
    //     position = properties[keyPosition.name]
          
    //       .split(keyPosition.split)
    //       [keyPosition.typeSplit === 'right' ? 0 : 1]
    //     position = Number(position)
    //     break
    //   }
    // }

    if (beginAge && useGroup) {
      sequences.push({
        begin: {
          era: null,
          period: null,
          epoch: null
        },
        beginAge,
        color,
        dominantLithology,
        end: {
          era: null,
          period: null,
          epoch: null
        },
        endAge,
        indexBeginAge,
        indexEndAge,
        position,
        sandi,
        type,
        useGroup,
        entities: [values[i]]
      })
    }
  }

  for (let i = 0; i < GEOLOGICAL_TIME_SCALE.length; i++) {
    const timeScale = GEOLOGICAL_TIME_SCALE[i]

    for (let j = 0; j < sequences.length; j++) {
      const sequence = sequences[j]
      
      if (
        !sequence.begin.era
        && sequence.beginAge
        && timeScale.era
        && sequence
          .beginAge
          .toLowerCase()
          .includes(timeScale.era.toLowerCase())
        ) {
          sequence.indexBeginAge = i
          sequence.begin.period = null
          sequence.begin.epoch = null
          sequence.begin.era = timeScale.era
      }

      if (
        !sequence.begin.period
        && sequence.beginAge
        && timeScale.period
        && sequence
          .beginAge
          .toLowerCase()
          .includes(timeScale.period.toLowerCase())
        ) {
          sequence.indexBeginAge = i
          sequence.begin.epoch = null
          sequence.begin.period = timeScale.period
      }

      if (
        !sequence.begin.epoch
        && !timeScale.epochAlias
        && sequence.beginAge
        && timeScale.epoch
        && sequence
          .beginAge
          .toLowerCase()
          .includes(timeScale.epoch.toLowerCase())
        ) {
          sequence.indexBeginAge = i
          sequence.begin.epoch = timeScale.epoch
      }

      if (
        !sequence.begin.epoch
        && sequence.beginAge
        && timeScale.epoch
        && sequence.begin.period
        && timeScale.epochAlias
        && sequence
          .beginAge
          .toLowerCase()
          .includes(timeScale.epochAlias.toLowerCase())
        ) {
          sequence.indexBeginAge = i
          sequence.begin.epoch = timeScale.epoch
      }

      if (
        sequence.endAge
        && timeScale.era
        && sequence
          .endAge
          .toLowerCase()
          .includes(timeScale.era.toLowerCase())
        ) {
          sequence.indexEndAge = i
          sequence.end.era = timeScale.era
      }

      if (
        sequence.endAge
        && timeScale.period
        && sequence
          .endAge
          .toLowerCase()
          .includes(timeScale.period.toLowerCase())
        ) {
          sequence.indexEndAge = i
          sequence.end.period = timeScale.period
          sequence.end.epoch = null
      }

      if (
        !sequence.end.epoch
        && !timeScale.epochAlias
        && sequence.endAge
        && timeScale.epoch
        && sequence
          .endAge
          .toLowerCase()
          .includes(timeScale.epoch.toLowerCase())
        ) {
          sequence.indexEndAge = i
          sequence.end.epoch = timeScale.epoch
      }

      if (
        !sequence.end.epoch
        && sequence.endAge
        && timeScale.epoch
        && sequence.end.period
        && timeScale.epochAlias
        && sequence
          .endAge
          .toLowerCase()
          .includes(timeScale.epochAlias.toLowerCase())
        ) {
          sequence.indexEndAge = i
          sequence.end.epoch = timeScale.epoch
      }
    }
  }

  const filterSequences = []

  for (let i = 0; i < sequences.length; i++) {    
    const sequence = sequences[i]

    if (!sequence.indexBeginAge || !sequence.indexEndAge) {
      continue
    }

    let duplicate = false

    for (let j = 0; j < filterSequences.length; j++) {
      const filterSequence = filterSequences[j]
      if (filterSequence.sandi === sequence.sandi) {
        if (filterSequence.indexBeginAge < sequence.indexBeginAge) {
          filterSequence.indexBeginAge = sequence.indexBeginAge
          filterSequence.beginAge = sequence.beginAge
        }

        if (filterSequence.indexEndAge > sequence.indexEndAge) {
          filterSequence.indexEndAge = sequence.indexEndAge
          filterSequence.endAge = sequence.endAge
        }

        filterSequence.entities.push(...sequence.entities)

        duplicate = true
        break
      }
    }

    if (!duplicate) {
      filterSequences.push(sequence)
    }
  }

  filterSequences.sort((a, b) => {
    return a.indexBeginAge - b.indexBeginAge
  })

  filterSequences.sort((a, b) => {
    return a.indexEndAge - b.indexEndAge
  })

  return filterSequences
}

export function generateUniqueBox(sequences) {
  const list = [
    'sediment',
    'metamorphic',
    'volcanic',
    'intrusive',
    'others'
  ]

  const result = {}

  const unique = {}

  sequences.forEach((sequence) => {
    if (!unique[sequence.type]) {
      unique[sequence.type] = true
    }
  })

  for (let key of list) {
    if (unique[key]) {
      result[key] = {
        left: 0,
        right: 0
      }
    }
  }

  return result
}

export function generateUniqueLith(sequences, totalWideMargin) {
  const list = [
    'sediment',
    'metamorphic',
    'volcanic',
    'intrusive',
    'others'
  ]

  const result = []

  const unique = {}

  sequences.forEach((sequence) => {
    if (!unique[sequence.type]) {
      unique[sequence.type] = true
    }
  })

  const totalUnique = Object.keys(unique).length

  for (let key of list) {
    if (unique[key]) {
      result.push({
        text: key[0].toUpperCase() + key.slice(1),
        type: key,
        wideMargin: totalWideMargin / totalUnique
      })
    }
  }

  return result
}
