import React from 'react'

import LayerbarIcon from '../../../../../shares/LayerbarIcon'

import {
  ReactComponent as FolderFilterIcon
} from '../../../../../../../../images/Icons/folder-filter.svg'

/**
 * Component to toggle show all available entities or not
 * @param onClickHandler event that trigger when this button clicked
 * @param isActive to show whether the button is active or not
 * @param rest margin top of this component
 */
function ShowEmptyEntityButton({
  onClickHandler,
  isActive,
  ...rest
}) {
  return (
    <LayerbarIcon
      isActive={isActive}
      {...rest}
    >
      <FolderFilterIcon
        title="Show / Hide Empty Entity"
        onClick={onClickHandler}
      />
    </LayerbarIcon>
  )
}

export default ShowEmptyEntityButton
