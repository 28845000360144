import React, { useEffect } from 'react'

import cesiumViewer from '../../../../apis/cesiumViewer'

import {
  clearSectionEditor,
  unclip,
  clearSectionBox
} from '../../../../apis/cesiumSection'

import {
  ReactComponent as CutterIcon
} from '../../../../images/Icons/cut.svg'

import ContentIcon from './ContentIcon'

/**
 * The component Cross section button
 * @param {Object} content that represent the button given
 * @param {Number} translate to set how far this component is translated
 * @param {Number} left the left position of button
 * @param {Number} top the top position of button
 * @param {Number} r radius of the black circle
 * @param {Number} radians the radians of rotation value
 * @param {Number} widthSVG is the width given for SVG
 * @param {Boolean} toggleDisplay status that button being shown or not
 * @param {Number} key is the id of component
 * @param {Object} crossEvent - cross state
 * @param {Function} setCrossEvent - method to change cross event
 * @param {Array} listPointerEvent - list of available pointer event
 */
function CrossSectionButton({
  content,
  translate,
  left,
  top,
  r,
  radians,
  widthSVG,
  toggleDisplay,
  key,
  crossEvent,
  setCrossEvent,
  listPointerEvent
}) {
  function resetClip() {
    if (
      cesiumViewer
      && cesiumViewer.viewer
      && cesiumViewer.viewer.scene
      && cesiumViewer.viewer.scene.globe
    ) {
      unclip()
      clearSectionEditor(cesiumViewer)
      clearSectionBox(cesiumViewer)
    }
  }

  function onClickHandler(status) {
    if (status) {
      listPointerEvent.forEach(pointerEvent => {
        if (pointerEvent.name !== 'crossEarth') {
          pointerEvent.otherPointerOnHandler(crossEvent)
        }
      })
    }

    cesiumViewer.sectionStatus.crossSection = status

    setCrossEvent((crossEvent) => ({
      ...crossEvent,
      status
    }))
    
    if (status === false) {
      resetClip()
    }
  }

  useEffect(() => {
    setCrossEvent((crossEvent) => {
      return {
        ...crossEvent,
        selectionOnHandler: () => {
          setCrossEvent((crossEvent) => ({
            ...crossEvent,
            status: false
          }))
        },
        otherPointerOnHandler: () => onClickHandler(false)
      }
    })
  }, [])

  return (
    <ContentIcon
      key={key}
      top={
        toggleDisplay
          ? top + 'em'
          : top - translate - r * Math.sin(radians) + 'em'
      }
      left={
        toggleDisplay
          ? left + 'em'
          : left - translate - r * Math.cos(radians) + 'em'
      }
      height={widthSVG + 'em'}
      width={widthSVG + 'em'}
      isActive={crossEvent.status}
    >
      <CutterIcon
        title={content.title}
        onClick={() => {
          onClickHandler(!crossEvent.status)
        }}
        width={30}
        height={30}
      />
    </ContentIcon>
  )
}

export default CrossSectionButton
