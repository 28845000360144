import React from "react";
import Navbar from "../../../components/Menubars/Navbar";
import "./forgotPassword.css";
import { Link } from 'react-router-dom';
import SuccessLogo from "./forget-password.svg";

function ForgotPassword (props) {
    
  return (
    <div className="content-block">
      <Navbar className="row"/>
      <div className="img-bg-big">
        <div className="container-reset input-email-form container-forgot-pass">
            <div className="row">
              <img src={SuccessLogo} alt="Logo-reset-password" className=" logo-reset"/>
            </div>
            <div>
              <p className="row text-center-reset">Trouble signing in?</p>
            </div>
            <p className="text-center-input">Resetting your password is easy. Just press the button below and follow the instructions. We'll have you up and running in no time.</p>
            <div className="row button-success">
            <Link className="link-login" to="/resetpasswordemail">
              <button
                type="submit"
                className="button-back-login"
              > <span>Reset Password</span>
              </button> </Link>
            </div>
            <p className="text-right-input">Or want to try signing in again? <Link to="/login">Back to login page.</Link> </p>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword