import React, { useState } from 'react'

import { PhotoshopPicker } from 'react-color'

import { generateColor } from '../../../../../helpers'

import styled from 'styled-components'

const Wrapper = styled.div`
  position: fixed;
  bottom: 3em;
  z-index: 1004;
`

function setBackgroundColor({
  background,
  color
}) {
  background.red = color.red
  background.green = color.green
  background.blue = color.blue
  background.alpha = color.alpha
}

function ColorPalette({
  background,
  backgrounds,
  onAcceptHandler,
  hidePalette,
  multiple
}) {
  /**
   * This function will show photoshop picker that created by react-color
   * @param background the first background color
   * @param backgrounds array of background color to handle multiple entity change
   * @param onAcceptHandler is the event when color is selected
   * @param hidePalette is the event to remove this componnent
   * @param multiple to toggle between multiple and single
   */
  const [ initialColor, setInitialColor ] = useState({
    ...background
  })
  const [ currentColor, setCurrentColor ] = useState(
    generateColor({
      defaultColor: background,
      alpha: background.alpha,
      fromCesium: true
    })
  )

  function onSaveHandler() {
    const [ r, g, b, a ] = currentColor.slice(5, -1).split(',')

    const red = r / 255
    const green = g / 255
    const blue = b / 255
    const alpha = Number(a)

    onAcceptHandler({
      red,
      green,
      blue,
      alpha
    })
  }

  function onChangeHandler(color) {
    const { r, g, b, a } = color.rgb    

    setCurrentColor(
      generateColor({
        defaultColor: {
          red: r / 255,
          green: g / 255,
          blue: b / 255
        },
        alpha: a,
        fromCesium: true
      })
    )

    const red = r / 255
    const green = g / 255
    const blue = b / 255
    const alpha = a

    if (multiple) {
      backgrounds.forEach((eachBackground) => {
        setBackgroundColor({
          background: eachBackground,
          color: {
            red,
            green,
            blue,
            alpha
          }
        })
      })
    } else {
      setBackgroundColor({
        background,
        color: {
          red,
          green,
          blue,
          alpha
        }
      })
    }
  }

  function onCancelHandler() {
    /**
     * Reset color to the initial state
     * And then hide this palette
     */

    if (multiple) {
      backgrounds.forEach((eachBackground) => {
        setBackgroundColor({
          background: eachBackground,
          color: initialColor
        })
      })
    } else {
      setBackgroundColor({
        background,
        color: initialColor
      })
    }

    hidePalette()
  }

  return (
    <Wrapper>
      <PhotoshopPicker
        color={currentColor}
        onAccept={onSaveHandler}
        onChange={onChangeHandler}
        onCancel={onCancelHandler}
      />
    </Wrapper>
  )  
}

export default ColorPalette
