import * as actionTypes from '../../constants/store/actionTypes/cesiumEntity'

export function changeEntityAlpha(payload) {
  return {
    type: actionTypes.CHANGE_ENTITY_ALPHA,
    payload
  }
}

export function changeEntityColor(payload) {
  return {
    type: actionTypes.CHANGE_ENTITY_COLOR,
    payload
  }
}

export function inverseColor(payload) {
  return {
    type: actionTypes.INVERSE_COLOR,
    payload
  }
}

export function triggerRender(payload) {
  return {
    type: actionTypes.TRIGGER_RENDER,
    payload
  }
}

export function resetCesiumEntity(payload) {
  return {
    type: actionTypes.RESET_CESIUM_ENTITY,
    payload
  }
}
