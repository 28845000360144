import React, { memo } from 'react'

import { connect } from 'react-redux'

import {
  toggleChatbar
} from '../../../store/actions/chatbar'

import {
  getChatToggle
} from '../../../store/selectors/chatbar'

import { ReactComponent as ChatIcon } from '../../../images/Icons/chat.svg'

import {
  ToggleSVGButton
} from '../../shares/ToggleSVGIcon'


function ChatButton({
  toggle,
  toggleChatbar
}) {
  function onClickHandler() {
    toggleChatbar({
      toggle: !toggle,
      minimize: !toggle ? false : true
    })
  }

  return <>
    <ToggleSVGButton flex margin="0.5em" active={toggle}>
      <ChatIcon
        title="Chat"
        alt="chat"
        onClick={onClickHandler}
      />
    </ToggleSVGButton>
  </>
}

function mapStateToProps(state) {
  return {
    toggle: getChatToggle(state)
  }
}

const mapDispatchToProps = {
  toggleChatbar
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(ChatButton))
