import * as d3 from 'd3';
import { sliderBottom } from 'd3-simple-slider';
import { 
    binsForHistogram,
    scaleLinearY,
    runXYAxis,
    makeHistogram
} from './histogramFunction';

export const sliderRangeLinear = ({...props}) => {
    let {
        dataGroup,
        selection,
        width,
        height,
        color,
        gHistogram,
        xAxis,
        yAxis,
        configHistogram,
        setConfigHistogram,
        minRange,
        maxRange,
        series,
        classNameAll
    } = props;

    if(dataGroup[selection]===undefined) return;
    // let res=(d3.max(dataGroup[selection])-d3.min(dataGroup[selection]))/10
    if (minRange===null){
        minRange=d3.min(dataGroup[selection])
    }
    if(maxRange===null){
        maxRange=d3.max(dataGroup[selection])
    }
    if(color===null){
        color=d3.scaleOrdinal()
        .domain(series)
        .range(d3.schemeSet2);
    }
    let p = d3.precisionRound(0.01,1.1)
    let sliderRange = sliderBottom() 
        .min(d3.min(dataGroup[selection]))
        .max(d3.max(dataGroup[selection]))
        .width(width)
        .ticks(7)
        .tickFormat(d3.format(".2n"))
        .default([minRange, maxRange])
        .fill(function(d){return color(selection)})
        .displayValue(true)
        .on('onchange', val => {
            let x= scaleLinearXUpdate(width,val[0],val[1]);
            let bins=binsForHistogram({
                x: x,
                data: dataGroup,
                selection: selection,
                numBins:configHistogram.numBins
            })
            let y=scaleLinearY({
                minrange: height,
                maxrange: 0,
                bins: bins
            })
            runXYAxis({
                xAxis: xAxis,
                yAxis: yAxis,
                x:x,
                y:y,
                data: dataGroup,
                configHistogram: configHistogram
            })
            makeHistogram({
                classNameAll:classNameAll,
                gHistogram: gHistogram,
                bins: bins,
                x: x,
                y: y,
                height: height,
                color: color,
                selection: selection,
                series: series
            })

            let newState={...configHistogram};
            newState.range=val.map(d3.format("."+p+'r')).join(' - ')
            setConfigHistogram(newState)
            // setRange(val.map(d3.format('.2n')).join('-'))
        });
        return sliderRange;
}

function scaleLinearXUpdate(width,mindomain,maxdomain){
    // let res=(maxdomain-mindomain)/10
    return(
        d3.scaleLinear()
            .range([0,width])
            .domain([mindomain, maxdomain])
    )
}