import React from 'react'

import FocusContainer, {
  Header,
  LinkText,
  Paragraph
} from '../../StyledComponent'
import SurfacePNG from '../../HelpImage/database-surface(1).png'
import Surface2PNG from '../../HelpImage/database-surface(2).png'
import { LIST_HELP } from '../../constant'


function HelpSurface(props) {
  const flyTo=(x)=>{
    props.setFocus(x)
  }
  return <>
    <FocusContainer {...props}>
      <Header>{LIST_HELP['menu'].detail.surface.header}</Header>
      <Paragraph>
      If you have a surface data, you can display it in Geoprosp. The data displayed can be in the form of map plots and graphs, including:
      </Paragraph>
      <Paragraph>
      1. Plot on the map
      </Paragraph>
      <Paragraph>
      2. Summary on the statistical description
      </Paragraph>
      <Paragraph>
      3. Box plots
      </Paragraph>
      <Paragraph>
      4. Correlation matrix
      </Paragraph>
      <Paragraph>
      5. Histograms
      </Paragraph>
      {/* <Paragraph>
      6. Ternary plots
      </Paragraph>
      <Paragraph>
      7. Hexbin plots
      </Paragraph> */}
      <Paragraph>
      6. Scatter plots
      </Paragraph>
      <img src={SurfacePNG} alt="Import data 1 image" width="1100px" style={{marginTop:"1em"}}/>
      <img src={Surface2PNG} alt="Analysis data image" width="900px" />
      <Paragraph>
      The use of charts can be seen in <LinkText onClick={()=>flyTo('menuBar')}>how to use charts in the analysis of the data surface</LinkText>
      </Paragraph>
    </FocusContainer>
  </>
}

export default HelpSurface
