export function generateSlot(
  sequence,
  countObject
) {
  const {
    indexBeginAge,
    indexEndAge,
    type
  } = sequence

  if (indexBeginAge !== indexEndAge) {
    countObject[type] = {
      [indexEndAge]: [
        {
          sequence,
          render: true
        }
      ],
      [indexBeginAge]: [
        {
          sequence,
          render: false
        }
      ]
    }
  } else {
    countObject[type] = {
      [indexEndAge]: [
        {
          sequence,
          render: true
        }
      ]
    }
  }
}

export function findMax({
  countObject,
  type,
  age,
  maxObject
}) {
  if (maxObject[type][age]) {
    return maxObject[type][age]
  }

  const rangeAge = {
    [age]: 0
  }

  const arrayAge = Object.keys(countObject[type])
  const indexAge = arrayAge.indexOf(age)

  let lowestIndexAge = indexAge
  let highestIndexAge = indexAge
  
  for (let i = 0; i < countObject[type][age].length; i++) {
    if (!countObject[type][age][i]) {
      continue
    }
    
    for (let j = highestIndexAge + 1; j < arrayAge.length; j++) {
      if (!countObject[type][arrayAge[j]][i]) {
        continue
      }
      
      if (countObject[type][age][i].sequence !== countObject[type][arrayAge[j]][i].sequence) {
        break
      }

      rangeAge[arrayAge[j]] = 0
      highestIndexAge = j
    }

    for (let j = lowestIndexAge - 1; j >= 0; j--) {
      if (!countObject[type][arrayAge[j]][i]) {
        continue
      }

      if (countObject[type][age][i].sequence !== countObject[type][arrayAge[j]][i].sequence) {
        break
      }

      rangeAge[arrayAge[j]] = 0
      lowestIndexAge = j
    }
  }

  let max = 1

  for (let keyAge in rangeAge) {
    if (max < countObject[type][keyAge].length) {
      max = countObject[type][keyAge].length
    }
  }

  for (let keyAge in rangeAge) {
    maxObject[type][keyAge] = max
  }
}

export function checkSlot(sequence, countObject) {
  const {
    indexBeginAge,
    indexEndAge,
    type
  } = sequence

  for (let i = indexBeginAge; i <= indexEndAge; i++) {
    if (countObject[type][i]) {
      return true
    }
  }

  return false
}

export function findSlot(
  sequence,
  countObject
) {
  const {
    indexBeginAge,
    indexEndAge,
    type
  } = sequence

  const slots = countObject[type]

  let beginSlot = 0
  let indexRangeAge = indexBeginAge
  let endSlot = -1

  while (beginSlot !== endSlot && endSlot === -1) {
    if (slots[indexRangeAge] && slots[indexRangeAge][beginSlot]) {
      beginSlot += 1
      indexRangeAge = indexBeginAge
    } else if (slots[indexEndAge] && slots[indexEndAge][beginSlot]) {
      beginSlot += 1
      indexRangeAge = indexBeginAge
    } else if (indexRangeAge !== indexEndAge) {
      indexRangeAge += 1
    } else {
      endSlot = beginSlot
    }
  }

  for (let i = indexBeginAge; i <= indexEndAge; i++) {
    if (!slots[i]) {
      slots[i] = []
      slots[i][endSlot] = {
        sequence,
        render: i === indexEndAge
      }
    } else {
      slots[i][endSlot] = {
        sequence,
        render: i === indexEndAge
      }
    }
  }
}

export function renderLabel({
  block,
  diagramLabel,
  GEOLOGICAL_TIME_SCALE,
  maxYBlock,
  oldest,
  orientation,
  svg,
  type,
  youngest
}) {
  for (let i = youngest.index; i >= oldest.index; i--) {
    const currentDrawLabelEra = GEOLOGICAL_TIME_SCALE[i][type]

    let scale = 1
    diagramLabel[type].totalScale += 1

    while (
      GEOLOGICAL_TIME_SCALE[i - 1]
      && i > oldest.index
      && currentDrawLabelEra === GEOLOGICAL_TIME_SCALE[i - 1][type]
    ) {
      i -= 1
      scale += 1
      diagramLabel[type].totalScale += 1
    }
    
    diagramLabel[type].details.push({
      age: GEOLOGICAL_TIME_SCALE[i],
      scale,
      fill: GEOLOGICAL_TIME_SCALE[i].fill
    })
  }

  for (let i = 0; i < diagramLabel[type].details.length; i++) {
    const {
      totalScale,
      initialY
    } = diagramLabel[type]
    
    const {
      age,
      scale,
      fill
    } = diagramLabel[type].details[i]
    
    const afterY = scale / totalScale * maxYBlock

    diagramLabel[type].details[i].initialY = initialY
    diagramLabel[type].details[i].afterY = afterY

    const labelBlock = svg
      .append('rect')
        .attr('x', block[type].left)
        .attr('y', initialY)
        .attr('width', block[type].right - block[type].left)
        .attr('height',  `${afterY}px`)
        .style('stroke', 'black')
        .style('stroke-width', '2px')
        .style('fill', fill ? fill : 'grey')
        .style('opacity', 0.7)
        .style('cursor', 'pointer')
        .on('mouseover', function () {
          labelBlock.style('opacity', 1)
        })
        .on('mouseleave', function () {
          labelBlock.style('opacity', 0.7)
        })

    if (orientation === 'vertical') {
      svg
        .append('text')
          .text(age[type])
          .style('font-size', function () {
            if (this.getComputedTextLength() >= afterY) {
              return 10.5 - (this.getComputedTextLength() / afterY) + 'px'
            } else {
              return '13px'
            }
          })
          .style('cursor', 'pointer')
          .attr('x', function () {
            return -(initialY + afterY / 2) - this.getComputedTextLength() / 2
          })
          .attr('y', function () {
            return (block[type].left + block[type].right) / 2 + 3
          }) // 17px font height
          .attr('transform', function () {
            return `rotate(-90)`
          })
          .on('mouseover', function () {
            labelBlock.style('opacity', 1)
          })
          .on('mouseleave', function () {
            labelBlock.style('opacity', 0.7)
          })
    } else {
      svg
        .append('text')
          .text(age[type])
          .style('font-size', function () {
            if (this.getComputedTextLength() >= afterY) {
              return 12 - (this.getComputedTextLength() / afterY) + 'px'
            } else {
              return '12px'
            }
          })
          .style('cursor', 'pointer')
          .attr('x', function () {
            return block[type].left + 3
          })
          .attr('y', function () {
            return initialY + afterY / 2 + 3
          })
          .on('mouseover', function () {
            labelBlock.style('opacity', 1)
          })
          .on('mouseleave', function () {
            labelBlock.style('opacity', 0.7)
          })
    }
    
    diagramLabel[type].initialY += afterY
  }
}

export function renderGeology ({
  ageLabels,
  block,
  diagramLabel,
  regionalGeologicalStratigraphyGraphicRef,
  sequences,
  // map,
  svg,
  tooltipDiv
}) {
  const heightBar = diagramLabel.epoch.details[0].afterY

  for (let i = 0; i < sequences.length; i++) {
    const sequence = sequences[i]

    generateLabel:
    for (let j = ageLabels.length - 1; j >= 0; j--) {
      const ageLabel = ageLabels[j]
      const {
        details
      } = diagramLabel[ageLabel]

      if (
        !sequence.begin.initialY
        && sequence.end[ageLabel]
      ) {
        for (let k = 0; k < details.length; k++) {
          // jangan lupa scale geology
          if (details[k].age[ageLabel] === sequence.end[ageLabel]) {
            sequence.begin.initialY = details[k].initialY
            sequence.end.afterY = heightBar
              * (1 + (sequence.indexEndAge - sequence.indexBeginAge))

            break generateLabel
          }
        }
      }
    }

    const { type } = sequence

    sequence.begin.initialX = block[type].left
    sequence.end.afterX = block[type].right - block[type].left
  }

  const countObject = {}
  
  for (let i = 0; i < sequences.length ; i++) {
    const sequence = sequences[i]
    const {
      indexBeginAge,
      indexEndAge,
      type
    } = sequence

    if (!countObject[type]) {
      generateSlot(
        sequence,
        countObject
      )
    } else if (
      checkSlot(sequence, countObject)
    ) {
      findSlot(
        sequence,
        countObject
      )
    } else {
      for (let j = indexBeginAge; j <= indexEndAge; j++) {
        countObject[type][j] = [{
          sequence,
          render: j === indexEndAge
        }]
      }
    }
  }

  const maxObject = {}

  for (let type in countObject) {
    maxObject[type] = {}
    for (let age in countObject[type]) {
      maxObject[type][age] = null
    }
  }

  for (let type in countObject) {
    for (let age in countObject[type]) {
      findMax({
        countObject,
        type,
        age,
        maxObject
      })
    }
  }

  for (let type in countObject) {
    for (let age in countObject[type]) {
      if (maxObject[type][age] > 1) {
        countObject[type][age].forEach((slot, index) => {
          if (slot.render) {
            const sequence = slot.sequence
            sequence.begin.initialX = index
              / maxObject[type][age]
              * sequence.end.afterX
              + sequence.begin.initialX
            
            sequence.end.afterX = 1
              / maxObject[type][age]
              * sequence.end.afterX
          }
        })
      }
    }
  }

  for (let i = 0; i < sequences.length; i++) {
    const sequence = sequences[i]
    
    const {
      initialX,
      initialY
    } = sequence.begin

    const {
      afterX,
      afterY
    } = sequence.end

    function createColor(color, isHover = false) {
      if (color) {
        const alpha = isHover ? '1' : '0.8'
        return `rgba(${color.red * 255}, ${color.green * 255}, ${color.blue * 255}, ${alpha})`
      } else {
        return 'transparent'
      }
    }

    const stratigraphyBlock = svg
      .append('rect')
        .attr('x', initialX)
        .attr('y', initialY)
        .attr('width', afterX)
        .attr('height',  afterY)
        .style('stroke', 'black')
        .style('stroke-width', '1px')
        .style('fill', createColor(sequence.color))
        .style('cursor', 'pointer')
        .on('mouseover', function (event) {
          stratigraphyBlock
            .style('fill', createColor(sequence.color, true))
          
          tooltipRender({
            addition: {
              left: 30,
              top: 40
            },
            event,
            message: sequence.dominantLithology,
            opacity: 1,
            regionalGeologicalStratigraphyGraphicRef,
            tooltipDiv
          })

          sequence.entities.forEach((entity) => {
            if (entity.polygon) {

              // if (map.cesiumLayer.alpha + 0.2 > 1) {
              //   map.colorEntities[entity.id].alpha = map.cesiumLayer.alpha - 0.2
              // } else {
              //   map.colorEntities[entity.id].alpha = map.cesiumLayer.alpha + 0.2
              // }
            }
          })
        })
        .on('mousemove', function (event) {
          tooltipRender({
            addition: {
              left: 30,
              top: 40
            },
            event,
            message: sequence.dominantLithology,
            opacity: 1,
            regionalGeologicalStratigraphyGraphicRef,
            tooltipDiv
          })
        })
        .on('mouseleave', function (event) {
          stratigraphyBlock
            .style('fill', createColor(sequence.color, false))

          tooltipRender({
            addition: {
              left: 30,
              top: 40
            },
            event,
            message: sequence.dominantLithology,
            opacity: 0,
            regionalGeologicalStratigraphyGraphicRef,
            tooltipDiv
          })

          // sequence.entities.forEach((entity) => {
          //   map.colorEntities[entity.id] = {
          //     ...map.colorEntities[entity.id],
          //     alpha: map.cesiumLayer.alpha
          //   }
          // })
        })
    
    svg
      .append('text')
        .text(sequence.sandi)
        .style('font-size', function () {
          if (this.getComputedTextLength() >= afterY) {
            return 14 - (this.getComputedTextLength() / afterY) + 'px'
          } else {
            return '14px'
          }
        })
        .attr('x', function () {
          return initialX
            + (
              afterX
              - this.getComputedTextLength()
            ) / 2
        })
        .attr('y', function () {
          return initialY + afterY / 2 + 4.2
        })
        .attr('pointer-events', 'none')
        .style('cursor', 'pointer')
        
  }
}

export function renderText({
  block,
  periodic,
  svg,
  text,
  type,
  wideMargin
}) {
  svg
    .append('text')
      .text(text)
      .attr('x', function () {
        let width = wideMargin || periodic.margin

        block[type].left = periodic.left
        block[type].right = block[type].left
          + width
        periodic.left = block[type].left
          + width
          
        return (block[type].right + block[type].left) / 2 - this.getComputedTextLength() / 2
      })
      .attr('y', '40px')
}

export function tooltipRender ({
  addition,
  event,
  message,
  opacity,
  regionalGeologicalStratigraphyGraphicRef,
  tooltipDiv
}) {
  tooltipDiv
    .transition()
    .duration(330)
    .style('opacity', opacity)

  const {
    top,
    left
  } = regionalGeologicalStratigraphyGraphicRef
    .current
    .getBoundingClientRect()

  let positionLeft = event.pageX - left
  let positionTop = event.pageY - top
  let offset = event.pageX - left <= 200
    ? 0
    : event.pageX - left - 200

  if (addition && addition.left) {
    positionLeft += addition.left
  }

  if (addition && addition.top) {
    positionTop += addition.top
  }

  tooltipDiv
    .text(message)
    .style('left', `${positionLeft - offset}px`)
    .style('top', `${positionTop}px`)
}
