export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeElement(string) {
  if(string.length===6){
    return string.charAt(0).toUpperCase() + string.charAt(1) + string.charAt(2).toUpperCase() + string.charAt(3) + string.charAt(4).toUpperCase() + string.charAt(5)
  } else if(string.length===4){
    return string.charAt(0).toUpperCase() + string.charAt(1) + string.charAt(2).toUpperCase() + string.charAt(3)
  } else {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}

export function capitalizeElementWithUnit(string){
  if(string.length===6){
    return string.charAt(0).toUpperCase() + string.charAt(1) + string.charAt(2).toUpperCase() + string.charAt(3) + string.charAt(4).toUpperCase() + string.charAt(5) + ' (' + string.substr(string.indexOf('_')+1) + ')'
  } else if(string.length===4){
    return string.charAt(0).toUpperCase() + string.charAt(1) + string.charAt(2).toUpperCase() + string.charAt(3) + ' (' + string.substr(string.indexOf('_')+1) + ')'
  } else {
    return capitalizeFirstLetter(string.substr(0,string.indexOf('_'))) + ' (' + string.substr(string.indexOf('_')+1) + ')'
  }
}