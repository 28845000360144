import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { 
    headersDrilling, 
    headersPoints, 
    headersPointsUTM
} from '../../../constants/Database/headers';
import { 
    setAutoCheckSurface,
    setFilterValue, 
    setUTMZone, 
    toggleAnalysis
} from '../../../store/actions/database';
import PopUpModal from '../PopUpModal';
import { Button } from '../PopUpModal/partials';
import TableBody from './TableBody';
import './tableFixedHeader.css'
import 'rc-input-number/assets/index.css'
import _, { max, min } from 'lodash';
import { searchElements } from '../../../components/Charts/helpers/searchElements';
import { 
    buttonViewTable, 
    checkActiveUTM, 
    checkboxElements, 
    filterModel, 
    inputUTM, 
    searchValue 
} from './helpers';
import { FooterTextRight, TableWraper } from './partials';

function PreviewTable({...props}) {
    let {
        project,
        type,
        subtype,
        setPreview,
        popup,
        setPopup,
        headerData,
        valuesHeader,
        setValuesHeader,
        setUTMZone,
        utmZone,
        data,
        setData,
        // modelWithMap,
        // setModelWithMap,
        filterValue,
        setFilterValue,
        autocheck,
        setAutocheck
    } = props;

    const [resizeUpdate, setResizeUpdate] = useState({
        height: 400,
        width: 480
    })
    const [filterRange, setFilterRange] = useState({
        max:0,
        min:0
    })
    const [checkElements, setCheckElements] = useState({})
    // const [elementsOnly, setElementsOnly] = useState([])
    // const [autocheck, setAutocheck] = useState(false)
    
    useEffect(()=>{
        if(type==='Drillhole'){
            setResizeUpdate({
                height: 320,
                width: 570
            })
        }else{
            if(data.elements.length!==0){
                let newState = {...checkElements}
                data.elements.map((head)=>{
                    if(!checkElements[head]){
                        newState[head]=!checkElements[head]
                    }
                    
                })
                setCheckElements(newState)
            }
            // if(elementsOnly.length!==0){
            //     elementsOnly.map((e)=>{
            //         if(checkElements[e]===false){
            //             newState[head]=true
            //         }
            //     })
            // }
            setResizeUpdate({
                height: 400,
                width: 700
            })
        }
        if(utmZone.active){
            checkActiveUTM(utmZone)
        }
        // setAutocheck(autocheckSurface)
    },[])
    
    useEffect(()=>{
        if(headerData.length===0) return;
        // if(!_.isEmpty(valuesHeader)) return;
        if(Object.keys(checkElements).length===0){
            let headForCheck={}
            headerData.map((head)=>{
                headForCheck[head] = false
            })
            setCheckElements(headForCheck)
        }
        let obj={}
        const findMatchHeader = (i) => {
            obj[i] = searchValue(i,headerData)
            // if(valuesHeader[i]===null || valuesHeader[i]===undefined ||
            //     data.data[0][valuesHeader[i].value]===undefined
            //     ){
            // }
        }
        if(type==='Drillhole'){
                headersDrilling.map((i)=>{
                    findMatchHeader(i)
                })
            // }
        }else{
            if(utmZone.active){
                headersPointsUTM.map((i)=>{
                    findMatchHeader(i)
                })    
            }else{
                headersPoints.map((i)=>{
                    findMatchHeader(i)
                })
            }
        }
        setValuesHeader(obj)
    },[headerData])

    useEffect(()=>{
        if(headerData.length===0) return;
        if(type!=='Drillhole'){
            if(Object.keys(checkElements).length===0){
                let headForCheck={}
                headerData.map((head)=>{
                    headForCheck[head] = false
                })
                setCheckElements(headForCheck)
            }
            let obj={}
            const findMatchHeader = (i) => {
                obj[i] = searchValue(i,headerData)
            }
            if(utmZone.active){
                headersPointsUTM.map((i)=>{
                    findMatchHeader(i)
                })    
            }else{
                headersPoints.map((i)=>{
                    findMatchHeader(i)
                })
            }
            setValuesHeader(obj)
        }

    },[utmZone.active])

    useEffect(()=>{
        if(headerData.length!==0){
            let headForCheck={}
            let arr= {...data}
            let elmnts = searchElements(headerData)
            headerData.map((head)=>{
                headForCheck[head] = false
                if(elmnts.length!==0){
                    elmnts.forEach(function(el){
                        if(head===el){
                            if(autocheck){
                                headForCheck[head] = true
                            } else {
                                headForCheck[head] = false
                            }
                        }
                    })
                }
            })
            if(autocheck){
                arr.elements = elmnts
            } else {
                arr.elements = []
            }
            // if(data.elements.length!==0){
            //     data.elements.map((check)=>{
            //         headForCheck[check]=true
            //     })
            // }
            setCheckElements(headForCheck)
            setData(arr)
        }

    },[autocheck,headerData])

    useEffect(()=>{
        let arr = {...data}
        arr.headerOfLocation = valuesHeader
        setData(arr)
    },[valuesHeader])

    // useEffect(()=>{
    //     let elmnts = searchElements(headerData)
    //     if(data.elements.length!==elmnts.length){
    //         if(autocheck){

    //         }
    //     }

    // },[data.elements])

    useEffect(()=>{
        if(utmZone.active){
            checkActiveUTM(utmZone)
        }
    },[utmZone])


    // useEffect(() => {
    //     const ut = {...utmZone}
    //     if(valuesHeader['northing']!==null && valuesHeader['easting']!==null && valuesHeader['northing']!==undefined && valuesHeader['easting']!==undefined){
    //         ut.active = true
    //     } else if (utmZone.active) {
    //         ut.active = utmZone.active
    //     } else {
    //         ut.active = false
    //     }
    //     setUTMZone(ut)
    // },[valuesHeader])

    useEffect(()=>{
        if(valuesHeader.value!==undefined && valuesHeader.value!==null){
            let val = []
            data.data.map((d)=>{
                val.push(Number(d[valuesHeader.value.value]))
            })
            let minVal = min(val)
            let maxVal = max(val)
            if(filterValue.min===0 && 
                filterValue.max===0 && 
                filterValue.min===minVal && 
                filterValue.max===maxVal
                ){
                let filt = {...filterValue}
                filt.min = minVal
                filt.max = maxVal
                setFilterValue(filt)
            } else if (filterValue.min<minVal ||
                filterValue.max>maxVal){
                let filt = {...filterValue}
                filt.min = minVal
                filt.max = maxVal
                setFilterValue(filt)
            }
            setFilterRange({
                min:minVal,
                max:maxVal
            })
        }
    },[valuesHeader.value])
    
    const headerPopup = () => {
        return (
            <>
                Import {type} data ({project} project))
            </>
        )
    }

    const bodyPopup = () => {
        const heightMaxBody=()=>{
            return resizeUpdate.height-170+'px'
        }
        return(
            <div style={{display:'flex'}}>
                <TableWraper type={type}>
                    <thead>
                        <tr>
                            <th>Headers</th>
                            <th>Headers Data {type}</th>
                        </tr>
                    </thead>
                    <tbody style={{height:heightMaxBody()}}>
                        <TableBody
                            type={type}
                            subtype={subtype}
                            headerData={headerData}
                            valuesHeader={valuesHeader}
                            setValuesHeader={setValuesHeader}
                            utmZone={utmZone}
                        />
                    </tbody>
                </TableWraper>
                {type!=="Drillhole" ? 
                    checkboxElements(
                        headerData,
                        checkElements,
                        setCheckElements,
                        data,
                        setData,
                        autocheck,
                        setAutocheck,
                        // setAutocheckSurface
                    ) 
                    :
                    valuesHeader.value!==null && 
                    filterModel(filterValue,
                        setFilterValue,
                        filterRange)
                }
            </div>
        )
    }

    const footerPopup = () =>{
        const closePopup=() => {
            setPopup(false)
        }
        const nextStep=() => {
            setPreview(true)
            setPopup(false)
        }
        const checkboxUTM = () => {
            return(
                <div>
                    <input 
                        type='checkbox'
                        onChange={(e)=>{
                            let obj={...utmZone}
                            obj.active=e.target.checked
                            setUTMZone(obj)
                        }}
                        checked={utmZone.active}
                    />
                    <span style={{color:'black'}}>
                        UTM Coordinates
                    </span>
                </div>
            )
        }
        // const checkboxModeltoMap = () => {
        //     return(
        //         <div>
        //             <input 
        //                 type='checkbox'
        //                 onChange={(e)=>{
        //                     setModelWithMap(!modelWithMap)
        //                 }}
        //                 checked={modelWithMap}
        //             />
        //             <span style={{color:'black'}}>
        //                 Model on Map
        //             </span>
        //         </div>
        //     )
        // }
        const footerButtonRight = () => {
            return(
                <div style={{display:'flex', flexDirection:'row', width:'150px', marginLeft:'1em'}}>
                    <Button onClick={closePopup}>
                        <label>
                            Close
                        </label>
                    </Button>
                    {
                        buttonViewTable(nextStep, type, valuesHeader, utmZone)
                    }
                </div>
            )
        }
        return(
            <>
            <FooterTextRight width={type==="Drillhole"? '175px':'240px'} >
                Position data required.
                {
                    type==="Drillhole" && 
                    valuesHeader['value']===null && 
                    <div>Value data required.</div>
                }
                {checkboxUTM()}
                {/* {type==="Drillhole" && checkboxModeltoMap()} */}
            </FooterTextRight>
            {
                utmZone.active? 
                inputUTM(utmZone,setUTMZone)
                : 
                <div style={{width:'225px'}}/>
            }
            
            {
                footerButtonRight()
            }
            
        </>
        )
    }

    return (
        <PopUpModal
            type={type}
            popup={popup}
            header={headerPopup}
            body={bodyPopup}
            footer={footerPopup}
            classBody='tableFixHead'
            resizeUpdate={resizeUpdate}
            setResizeUpdate={setResizeUpdate}
        />
    )
}

function mapStateToProps(state) {
    return {
        project: state.database.projectName,
        utmZone: state.database.utmZone,
        filterValue: state.database.filterValue,
        autocheck: state.database.autoCheckSurface
    }
}
    
const mapDispatchToProps = {
    setPreview: toggleAnalysis,
    setUTMZone: setUTMZone,
    setFilterValue: setFilterValue,
    setAutocheck: setAutoCheckSurface
}
    
export default connect(mapStateToProps, mapDispatchToProps)(PreviewTable);