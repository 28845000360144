import {
  polygon as turfPolygon
} from '@turf/turf'

import SectionMath from './SectionMath'

import { fixedPoints } from './dragHandler'

/**
 * This method used to generate polygon block from dragMovement
 * @param {Object} dragMovement - object that have start and end drag position
 * @returns {Array} array of polygon coordinate
 */
export function generateBlockPolygon(dragMovement) {
  fixedPoints(dragMovement)

  return [
    dragMovement.start.longitude, dragMovement.start.latitude,
    dragMovement.start.longitude, dragMovement.end.latitude,
    dragMovement.end.longitude, dragMovement.end.latitude,
    dragMovement.end.longitude, dragMovement.start.latitude,
  ]
}

/**
 * This method used to generate cross polygon block from dragMovement
 * @param {Object} dragMovement - object that have start, end, crossWidth position
 * @returns {Array} array of polygon coordinate
 */
export function generateCrossBlockPolygon(dragMovement) {
  const bearing = SectionMath.getPlaneBearing(dragMovement)

  const firstPoint = SectionMath.convertMetersToLongLat({
    longitude: dragMovement.start.longitude,
    latitude: dragMovement.start.latitude,
    distance: dragMovement.crossWidth.left / 1000,
    bearing: bearing + 90
  })

  const secondPoint = SectionMath.convertMetersToLongLat({
    longitude: dragMovement.start.longitude,
    latitude: dragMovement.start.latitude,
    distance: dragMovement.crossWidth.right / 1000,
    bearing: bearing + 270
  })

  const thirdPoint = SectionMath.convertMetersToLongLat({
    longitude: dragMovement.end.longitude,
    latitude: dragMovement.end.latitude,
    distance: dragMovement.crossWidth.right / 1000,
    bearing: bearing + 270
  })

  const fourthPoint = SectionMath.convertMetersToLongLat({
    longitude: dragMovement.end.longitude,
    latitude: dragMovement.end.latitude,
    distance: dragMovement.crossWidth.left / 1000,
    bearing: bearing + 90
  })

  return [
    firstPoint.longitude, firstPoint.latitude,
    secondPoint.longitude, secondPoint.latitude,
    thirdPoint.longitude, thirdPoint.latitude,
    fourthPoint.longitude, fourthPoint.latitude
  ]
}

/**
 * This method used to generate slice from sliceMovement and bearing
 * @param {Object} sliceMovement - slice movement include start and end that contain coordinate
 * @returns {Array} slice polygon
 */
export function getPolygonFromSlice(sliceMovement) {
  const bearing = SectionMath.getPlaneBearing(sliceMovement)

  const firstPoint = SectionMath.SectionMath.convertMetersToLongLat({
    longitude: sliceMovement.start.longitude,
    latitude: sliceMovement.start.latitude,
    distance: sliceMovement.crossWidth.left / 1000,
    bearing: bearing + 90
  })

  const secondPoint = SectionMath.SectionMath.convertMetersToLongLat({
    longitude: sliceMovement.start.longitude,
    latitude: sliceMovement.start.latitude,
    distance: sliceMovement.crossWidth.right / 1000,
    bearing: bearing + 270
  })

  const thirdPoint = SectionMath.SectionMath.convertMetersToLongLat({
    longitude: sliceMovement.end.longitude,
    latitude: sliceMovement.end.latitude,
    distance: sliceMovement.crossWidth.right / 1000,
    bearing: bearing + 270
  })

  const fourthPoint = SectionMath.SectionMath.convertMetersToLongLat({
    longitude: sliceMovement.end.longitude,
    latitude: sliceMovement.end.latitude,
    distance: sliceMovement.crossWidth.left / 1000,
    bearing: bearing + 90
  })

  return [
    firstPoint.longitude, firstPoint.latitude,
    secondPoint.longitude, secondPoint.latitude,
    thirdPoint.longitude, thirdPoint.latitude,
    fourthPoint.longitude, fourthPoint.latitude
  ]
}

/**
 * This function will generate turf polygon from polygon point
 * @param {Array} polygon - array of polygon point
 * @returns {Array} turf object polygon
 */
export function generateTurfPolygonFromPolygon(polygon) {
  const turfObjPolygon = [
    []
  ]

  for (let i = 0; i < polygon.length; i += 2) {
    turfObjPolygon[0].push([
      polygon[i], polygon[i + 1]
    ])
  }

  // to close the turfObjPolygon segment
  turfObjPolygon[0].push([
    polygon[0], polygon[1]
  ])

  return turfPolygon(turfObjPolygon)
}
