export const DEFAULT_FORM_EDITBAR = [
  {
    "title": "longitude",
    "value": 0
  },
  {
    "title": "latitude",
    "value": 0
  },
  {
    "title": "deposite_name",
    "value": ""
  },
  {
    "title": "major_commodity",
    "value": ""
  },
  {
    "title": "minor_commodities",
    "value": []
  },
  {
    "title": "model_types",
    "value": []
  },
  {
    "title": "grade_major",
    "value": ""
  },
  {
    "title": "ore_resources",
    "value": []
  },
  {
    "title": "Resources_U_Tonne",
    "value": ""
  },
  {
    "title": "Resources_Co_Tonne",
    "value": ""
  },
  {
    "title": "Resources_Cr_Tonne",
    "value": ""
  },
  {
    "title": "Resources_Cu_Tonne",
    "value": ""
  },
  {
    "title": "Resources_Hg_Tonne",
    "value": ""
  },
  {
    "title": "Resources_Mo_Tonne",
    "value": ""
  },
  {
    "title": "Resources_Ni_Tonne",
    "value": ""
  },
  {
    "title": "Resources_Pb_Tonne",
    "value": ""
  },
  {
    "title": "Resources_Sb_Tonne",
    "value": ""
  },
  {
    "title": "Resources_Sn_Tonne",
    "value": ""
  },
  {
    "title": "Resources_Zn_Tonne",
    "value": ""
  },
  {
    "title": "Resources_Ag_Ounces",
    "value": ""
  },
  {
    "title": "Resources_Au_Ounces",
    "value": ""
  },
  {
    "title": "Resources_MnO2_Tonne",
    "value": ""
  },
  {
    "title": "Resources_TiO2_Tonne",
    "value": ""
  },
  {
    "title": "Resources_Al2O3_Tonne",
    "value": ""
  },
  {
    "title": "Resources_Fe2O3_Tonne",
    "value": ""
  },
  {
    "title": "Resources_Monazite_Tonne",
    "value": ""
  },
  {
    "title": "age",
    "value": ""
  },
  {
    "title": "link",
    "value": ""
  },
  {
    "title": "u_ppm",
    "value": ""
  },
  {
    "title": "Island",
    "value": ""
  },
  {
    "title": "ag_ppm",
    "value": ""
  },
  {
    "title": "au_ppm",
    "value": ""
  },
  {
    "title": "co_ppm",
    "value": ""
  },
  {
    "title": "cu_ppm",
    "value": ""
  },
  {
    "title": "fe_ppm",
    "value": ""
  },
  {
    "title": "hg_ppm",
    "value": ""
  },
  {
    "title": "mo_ppm",
    "value": ""
  },
  {
    "title": "ni_ppm",
    "value": ""
  },
  {
    "title": "pb_ppm",
    "value": ""
  },
  {
    "title": "sb_ppm",
    "value": ""
  },
  {
    "title": "status",
    "value": ""
  },
  {
    "title": "zn_ppm",
    "value": ""
  },
  {
    "title": "au2_ppm",
    "value": ""
  },
  {
    "title": "company",
    "value": ""
  },
  {
    "title": "regency",
    "value": ""
  },
  {
    "title": "remarks",
    "value": ""
  },
  {
    "title": "tin_ppb",
    "value": ""
  },
  {
    "title": "tin_ppm",
    "value": ""
  },
  {
    "title": "location",
    "value": ""
  },
  {
    "title": "mno2_ppm",
    "value": ""
  },
  {
    "title": "province",
    "value": ""
  },
  {
    "title": "reserves",
    "value": ""
  },
  {
    "title": "sio2_ppm",
    "value": ""
  },
  {
    "title": "tio2_ppm",
    "value": ""
  },
  {
    "title": "al2o3_ppm",
    "value": ""
  },
  {
    "title": "cr2o3_ppm",
    "value": ""
  },
  {
    "title": "host_rock",
    "value": ""
  },
  {
    "title": "intrusive",
    "value": ""
  },
  {
    "title": "structure",
    "value": ""
  },
  {
    "title": "References",
    "value": ""
  },
  {
    "title": "alteration",
    "value": ""
  },
  {
    "title": "geophysics",
    "value": ""
  },
  {
    "title": "peti_status",
    "value": ""
  },
  {
    "title": "geochemistry",
    "value": ""
  },
  {
    "title": "monazite_ppm",
    "value": ""
  },
  {
    "title": "erosion_level",
    "value": ""
  },
  {
    "title": "mineralization",
    "value": ""
  },
  {
    "title": "drilling_status",
    "value": ""
  },
  {
    "title": "Project_Histories",
    "value": []
  },
  {
    "title": "u",
    "value": ""
  },
  {
    "title": "topografic_position",
    "value": ""
  },
  {
    "title": "verification_result",
    "value": ""
  },
  {
    "title": "verification_status",
    "value": ""
  }
]

export const EDITBAR_ARRAY_DATA = {
  minor_commodities: true,
  model_types: true,
  ore_resources: true,
  Project_Histories: true
}

export const EDITBAR_NUMBER_DATA = {
  longitude: true,
  laitude: true,
  sb_ppm: true,
  u_ppm: true,
  monazite_ppm: true,
  tin_ppm: true,
  tin_ppb: true,
  hg_ppm: true,
  mno2_ppm: true,
  ni_ppm: true,
  fe_ppm: true,
  cu_ppm: true,
  au_ppm: true,
  au2_ppm: true,
  ag_ppm: true,
  pb_ppm: true,
  zn_ppm: true,
  mo_ppm: true,
  co_ppm: true,
  cr2o3_ppm: true,
  al2o3_ppm: true,
  tio2_ppm: true,
  sio2_ppm: true,
  Resources_Sb_Tonne: true,
  Resources_Hg_Tonne: true,
  Resources_U_Tonne: true,
  Resources_Monazite_Tonne: true,
  Resources_MnO2_Tonne: true,
  Resources_Ni_Tonne: true,
  Resources_Fe2O3_Tonne: true,
  Resources_Co_Tonne: true,
  Resources_Cr_Tonne: true,
  Resources_Al2O3_Tonne: true,
  Resources_TiO2_Tonne: true,
  Resources_Sn_Tonne: true,
  Resources_Cu_Tonne: true,
  Resources_Au_Ounces: true,
  Resources_Ag_Ounces: true,
  Resources_Mo_Tonne: true,
  Resources_Pb_Tonne: true,
  Resources_Zn_Tonne: true,
}
