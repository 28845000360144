import {
  DEFAULT_COLOR_SCALE
} from '../../../../constants/Colors/d3'

import { findKeyProperties } from '../../properties'

import { getRound } from '../../../../shares/helpers/basicMath'

/**
 * This method to create group entities
 * @param {Object} map object of the current map
 * @param {String} groupBy current group by
 * @param {String} attribute attribute of field
 * @param {String} mode used for numerical
 * @param {Number} min used with mode on for minimum value
 * @param {Number} max used with mode on for maximum value
 * @param {Number} precision is number at the end of field mode
 * @param {Object} prevGroupEntities group entities before
 * @returns object group entities
 */
export function generateGroupEntities({
  map,
  groupBy,
  attribute,
  mode,
  min,
  max,
  precision,
  prevGroupEntities
}) {
  const groupEntities = {
    group: {},
    keys: [],
    style: {}
  }

  if (!map.colorScale) {
    map.colorScale = DEFAULT_COLOR_SCALE
    map.cesiumLayer.setInverse(true)
  }

  if (!mode) {
    if (attribute === 'single') {
      return generateGroupEntitiesForSingle({
        map,
        groupBy,
        groupEntities
      })
    } else {
      return generateGroupEntitiesForCategorical({
        map,
        groupBy,
        groupEntities
      })
    }
  } else if (mode === 'equal_interval') {
    return generateGroupEntitiesForNumericalEqualInterval({
      map,
      groupBy,
      groupEntities,
      min,
      max,
      precision
    })
  } else if (mode === 'logarithmic') {
    return generateGroupEntitiesForNumericalLogarithmic({
      map,
      groupBy,
      groupEntities,
      min,
      max,
      precision
    })
  } else if (mode) {
    return generateGroupEntitiesForNumericalCustom({
      map,
      groupBy,
      groupEntities,
      prevGroupEntities,
      precision,
      min,
      max
    })
  }

  return groupEntities
}

/**
 * This method to create group entities for attribute single
 * @param {Object} map object of the current map
 * @param {String} groupBy current group by
 * @param {Object} groupEntities is object for group entities
 * @returns object group entities
 */
export function generateGroupEntitiesForSingle({
  map,
  groupBy,
  groupEntities
}) {
  const entities = map.dataSource.entities.values

  for (let i = 0; i < entities.length; i++) {
    entities[i].show = true
  }

  groupEntities.group[groupBy] = entities

  groupEntities.keys.push({
    value: groupBy,
    label: groupBy
  })

  groupEntities.style[groupBy] = {
    color: {
      red: 255,
      green: 255,
      blue: 255,
      alpha: map.cesiumLayer.alpha
    }
  }

  return groupEntities
}

/**
 * This method to create group entities for attribute categorical
 * @param {Object} map object of the current map
 * @param {String} groupBy current group by
 * @param {Object} groupEntites is object for group entities
 * @returns object group entities
 */
export function generateGroupEntitiesForCategorical({
  map,
  groupBy,
  groupEntities
}) {
  const entities = map.dataSource.entities.values

  for (let i = 0; i < entities.length; i++) {
    entities[i].show = true
    
    const value = findKeyProperties({
      properties: entities[i].properties,
      key: groupBy
    })
  
    if (groupEntities.group[value]) {
      groupEntities.group[value].push(entities[i])
    } else {
      groupEntities.group[value] = [entities[i]]
      groupEntities.keys.push({
        value,
        label: value
      })
      groupEntities.style[value] = {
        color: {
          red: 255,
          green: 255,
          blue: 255,
          alpha: map.cesiumLayer.alpha
        }
      }
    }
  }
  
  return groupEntities
}

/**
 * This method to create group entities for attribute numerical mode equal interval
 * @param {Object} map object of the current map
 * @param {String} groupBy current group by
 * @param {Object} groupEntites is object for group entities
 * @param {Number} min used with mode on for minimum value
 * @param {Number} max used with mode on for maximum value
 * @param {Number} precision is the maximum decimal value for show
 * @returns object group entities
 */
export function generateGroupEntitiesForNumericalEqualInterval({
  map,
  groupBy,
  groupEntities,
  min,
  max,
  precision
}) {
  const groupEqualInterval = []
  
  let INTERVAL = 5

  if (max === min) {
    INTERVAL = 1
  }
  
  for (let i = 0; i < INTERVAL; i++) {
    const start = (i / INTERVAL * (Number(max) - Number(min)) + Number(min))
    const end = ((i + 1) / INTERVAL * (Number(max) - Number(min)) + Number(min))
  
    groupEqualInterval.push({
      start,
      end
    })

    const value = `${start} - ${end}`

    const startLabel = getRound(start, precision)
    const endLabel = getRound(end, precision)

    let label = `${startLabel} - ${endLabel}`.replace(/\./g, ',')

    groupEntities.group[label] = []
    groupEntities.keys.push({
      value,
      label
    })

    groupEntities.style[label] = {
      color: {
        red: 255,
        green: 255,
        blue: 255,
        alpha: map.cesiumLayer.alpha
      }
    }
  }

  prepareEntities({
    groupEntities,
    groupMode: groupEqualInterval,
    entities: map.dataSource.entities.values,
    groupBy,
    min: Number(min),
    max: Number(max)
  })
  
  return groupEntities
}

/**
 * This method to create group entities for attribute numerical mode logarithmic
 * @param {Object} map object of the current map
 * @param {String} groupBy current group by
 * @param {Object} groupEntites is object for group entities
 * @param {Number} min used with mode on for minimum value
 * @param {Number} max used with mode on for maximum value
 * @param {Number} precision is the maximum decimal value for show
 * @returns object group entities
 */
export function generateGroupEntitiesForNumericalLogarithmic({
  map,
  groupBy,
  groupEntities,
  min,
  max,
  precision
}) {
  const groupLogarithmic = []

  const MAX_MIN_LOGARITHMIC = -10
  let i = MAX_MIN_LOGARITHMIC

  while (Math.pow(10, i + 1) < min) {
    i += 1
  }

  for (i; Math.pow(10, i) <= max; i++) {
    let start = Math.pow(10, i)
    let showStart = 10

    if (min >= start) {
      start = min
      showStart = getRound(min, precision)
    } else if (i === 0) {
      start = 1
      showStart = 1
    } else {
      showStart += `^${i}`
    }

    let end = Math.pow(10, i + 1)
    let showEnd = 10

    if (end >= max) {
      end = max
      showEnd = getRound(max, precision)
    } else if (i + 1 === 0) {
      end = 1
      showEnd = 1
    } else {
      showEnd += `^${i + 1}`
    }

    groupLogarithmic.push({
      start,
      end
    })

    const label = `${showStart} - ${showEnd}`.replace(/\./g, ',')

    groupEntities.group[label] = []

    groupEntities.keys.push({
      value: `${start} - ${end}`,
      label
    })

    groupEntities.style[label] = {
      color: {
        red: 255,
        green: 255,
        blue: 255,
        alpha: map.cesiumLayer.alpha
      }
    }
  }

  prepareEntities({
    groupEntities,
    groupMode: groupLogarithmic,
    entities: map.dataSource.entities.values,
    groupBy,
    min: Number(min),
    max: Number(max)
  })

  return groupEntities
}

/**
 * This method to create group entities for attribute numerical mode custom
 * @param {Object} map object of the current map
 * @param {String} groupBy current group by
 * @param {Object} groupEntities is object for group entities
 * @param {Object} prevGroupEntities is object from previous group entities
 * @param {Number} precision is the maximum decimal value for show
 * @returns object group entities
 */
 export function generateGroupEntitiesForNumericalCustom({
  map,
  groupBy,
  groupEntities,
  prevGroupEntities,
  precision,
  min,
  max
}) {
  const groupCustom = []

  for (let key of prevGroupEntities.keys) {
    const [ start, end ] = key.value.split(' - ')

    groupCustom.push({
      start,
      end
    })

    const value = `${start} - ${end}`

    const startLabel = getRound(start, precision)
    const endLabel = getRound(end, precision)
  
    let label = `${startLabel} - ${endLabel}`.replace(/\./g, ',')
  
    groupEntities.group[label] = []
    groupEntities.keys.push({
      value,
      label
    })

    groupEntities.style[label] = {
      color: {
        red: 255,
        green: 255,
        blue: 255,
        alpha: map.cesiumLayer.alpha
      }
    }
  }

  prepareEntities({
    groupEntities,
    groupMode: groupCustom,
    entities: map.dataSource.entities.values,
    groupBy,
    min: Number(min),
    max: Number(max)
  })
  
  return groupEntities
}

/**
 * This method to prepare entities
 * @param {Object} groupEntities is group entities to display on cesium
 * @param {Object} groupMode is grouped by mode
 * @param {String} groupBy current group by
 * @param {String} entities current entities
 * @param {Number} min used with mode on for minimum value
 * @param {Number} max used with mode on for maximum value
 * @returns object group entities
 */
 export function prepareEntities({
  groupEntities,
  groupMode,
  entities,
  groupBy,
  min,
  max
}) {
  prepareEntity:
  for (let i = 0; i < entities.length; i++) {
    const value = findKeyProperties({
      properties: entities[i].properties,
      key: groupBy
    })

    if (
      isNaN(Number(value))
      || Number(value) < min
      || Number(value) > max
    ) {
      entities[i].show = false

      continue
    }

    if (Number(value) === max) {
      const range = groupEntities.keys[groupEntities.keys.length - 1].label
      groupEntities.group[range].push(entities[i])
      entities[i].show = true

      continue
    }

    for (let j = 0; j < groupMode.length; j++) {
      if (
        Number(value) >= Number(groupMode[j].start)
        && Number(value) < Number(groupMode[j].end)
      ) {
        const range = groupEntities.keys[j].label

        groupEntities.group[range].push(entities[i])
        entities[i].show = true
        
        continue prepareEntity
      }
    }

    entities[i].show = false
  }
}
