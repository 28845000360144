import React from 'react'

import { generateGroupEntitiesForNumericalCustom } from '../../../../../helpers/groupEntities'

import LayerbarIcon from '../../../../../shares/LayerbarIcon'

import {
  ReactComponent as RemoveIcon
} from '../../../../../../../../images/Icons/remove-text.svg'

/**
 * This button to remove number on numerical field mode custom
 * @param {Object} map is the selected map
 * @param {String} name is the name of label
 * @param {Object} groupEntities is current group entities to create this input
 * @param {Function} setGroupEntities is the method to change group entities
 * @param {String} groupBy is current groupby selected
 * @param {Object} fieldMode is the current fieldmode selected, need to get precision
 * @param {Function} setEditOption is method to change onEdit
 * @param {Function} setTemplate is method to change active template
 */
function RemoveButton({
  map,
  name,
  groupEntities,
  setGroupEntities,
  groupBy,
  fieldMode,
  setTemplate
}) {
  function removeHandler() {
    const keys = groupEntities.keys

    if (keys.length <= 1) {
      return
    }

    for (let i = 0; i < keys.length; i++) {
      if (keys[i].label === name) {
        const [ bottom, top ] = keys[i].value.split(' - ')

        const newTop = Number(top) - 1 / Math.pow(10, fieldMode.precision)

        keys[i].value = `${bottom} - ${newTop}`

        if (i === 0) {
          const nextTop = keys[i + 1].value.split(' - ')[1]

          keys[i + 1].value = `${bottom} - ${nextTop}`
        } else {
          const prevBottom = keys[i - 1].value.split(' - ')[0]

          keys[i - 1].value = `${prevBottom} - ${top}`
        }

        keys.splice(i, 1)
        break
      }
    }

    const prevGroupEntities = {
      ...groupEntities,
      keys
    }

    setTemplate(null)

    setGroupEntities(
      generateGroupEntitiesForNumericalCustom({
        map,
        groupBy,
        groupEntities: {
          group: {},
          keys: [],
          style: {}
        },
        prevGroupEntities,
        precision: fieldMode.precision
      })
    )
  }

  return <LayerbarIcon
    title="remove"
    isActive={false}
  >
    <RemoveIcon onClick={removeHandler} />
  </LayerbarIcon>
}

export default RemoveButton
