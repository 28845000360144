import React, { useRef, useState, useEffect } from 'react'

import * as d3 from 'd3'

import {
  SCORING_COLOR,
  SCORING_LOGIC,
  CUSTOM_INTERPOLATION
} from '../../../constants/Colors/graph'

import { rgbToHsl } from '../../helpers/colorParser'

import TechnicalParametersGraphic from '../../Graphics/TechnicalParametersGraphic'
import RegionalGeologicalStratigraphyGraphic from '../../Graphics/RegionalGeologicalStratigraphyGraphic'
import StructureGraphic from '../../Graphics/StructureGraphic'

function TechnicalParameter({
  selected,
  sitebarMenuRef
}) {
  const technicalParametersDetailRef = useRef(null)
  const colorScaleGraphicRef = useRef(null)
  
  // move into another component next, to crowded here
  const [ toggleGraph, setToggleGraph ] = useState('technical')

  const {
    final_rating_technical,
    final_rating_non_technical,
    final_rating_accessibility, // non-technical
    final_rating_forestry,
    final_rating_hazard,
    final_rating_geology, // technical
    final_rating_lineament,
    final_rating_others
  } = selected.content

  if (final_rating_technical > 10) {
    final_rating_technical = 10
  }

  if (final_rating_non_technical > 10) {
    final_rating_non_technical = 10
  }

  // const technicalObjectScore = findScore(technical_detail)
  const technicalScore = final_rating_technical === null
   ? final_rating_technical
   : Number(final_rating_technical).toFixed(2)

  const technicalSubjectScore = {
    geology: Number(final_rating_geology),
    lineament: Number(final_rating_lineament),
    others: Number(final_rating_others)
  } 
 
  // const nonTechnicalObjectScore = findScore(final_rating_non_technical_detail)
  const nonTechnicalScore = final_rating_non_technical === null
    ? final_rating_non_technical
    : Number(final_rating_non_technical).toFixed(2)

 const nonTechnicalSubjectScore = {
    accessibility: Number(final_rating_accessibility),
    forestry: Number(final_rating_forestry),
    hazard: Number(final_rating_hazard)
  }

  function disableScroll () {
    sitebarMenuRef.current.onwheel = function (event) {
      event.preventDefault()
    }
  }

  function enableScroll () {
    sitebarMenuRef.current.onwheel = function () {
      return true
    }
  }

  function scroll (event) {
    if (technicalParametersDetailRef.current) {
      const sitebarBounding = sitebarMenuRef
        .current
        .getBoundingClientRect()
      const sitebarTop = sitebarBounding.top
      const sitebarBottom = sitebarBounding.bottom
      const container = technicalParametersDetailRef.current

      const containerBounding = container
        .getBoundingClientRect()
      
      const containerTop = containerBounding.top
      const containerBottom = containerBounding.bottom

      if (
        sitebarTop <= containerTop + event.deltaY 
        && containerBottom + event.deltaY  <= sitebarBottom
      ) {
        disableScroll()
      }
      
      if (
        sitebarTop <= containerTop
        && containerBottom <= sitebarBottom
      ) {
        disableScroll()
        container.scrollTo({
          left: container.scrollLeft + (event.deltaY || 0),
          behaviour: 'smooth'
        })
      } else {
        enableScroll()
      }
      
      // if (container.scrollLeft === 0 && event.deltaY < 0) {
      //   sitebarMenuRef.current.scrollTo({
      //     top: sitebarMenuRef.current.scrollTop + event.deltaY,
      //     behaviour: 'smooth'
      //   })
      // } else if (
      //   container.scrollWidth
      //   - container.clientWidth
      //   === container.scrollLeft
      //   && event.deltaY > 0
      // ) {
      //   sitebarMenuRef.current.scrollTo({
      //     top: sitebarMenuRef.current.scrollTop + event.deltaY,
      //     behaviour: 'smooth'
      //   })
      // }
    }
  }

  function getHueColor(score) {
    const interpolate = CUSTOM_INTERPOLATION(score)

    const [red, green, blue] = interpolate(score).slice(4, -1).split(', ')

    return rgbToHsl(red, green, blue)
  }

  function generateGraphDesign(score) {
    const { h, s, l } = getHueColor(score)

    return {
      point: {
        hue: h,
        saturation: s,
        lightness: l - 40
      },
      outline: {
        hue: h,
        saturation: s,
        lightness: l - 20
      },
      fill: {
        hue: h,
        saturation: s,
        lightness: l
      }
    }
  }

  function renderDetail(score, basedOn) {
    if (basedOn === 'Technically') {
      if (score >= 8) {
        return 'Excellent'
      } else if (score >= 6) {
        return 'Very good'
      } else if (score >= 4) {
        return 'Good'
      } else if (score >= 2) {
        return 'Medium'
      } else {
        return 'Poor'
      }
    } else {
      if (score >= 8) {
        return 'Very low constraint'
      } else if (score >= 6) {
        return 'Low constraint'
      } else if (score >= 4) {
        return 'Medium constraint'
      } else if (score >= 2) {
        return 'High constraint'
      } else {
        return 'Very high constraint'
      }
    }
  }

  function renderScore(score, basedOn) {
    function percentageColor(darker) {
      const { h, s, l } = getHueColor(score)

      if (darker) {
        return `hsl(${h}, ${s}%, 25%)`
      } else {
        return `hsl(${h}, ${s}%, ${l}%)`
      }
    }

    if (score) {
      return <>
        <div
          className="technical-parameters-score"
          style={{
            backgroundColor: percentageColor(false),
            cursor: 'pointer'
          }}
          onClick={() => {
            if (basedOn === 'Technically') {
              setToggleGraph('technical')
            } else {
              setToggleGraph('non-technical')
            }
          }}
        >
          <span
            className="technical-parameters-score-circle"
            style={{
              border: `
                5px
                solid
                ${
                   toggleGraph[0].toLowerCase() === basedOn[0].toLowerCase()
                    ? 'black'
                    : percentageColor(false)
                }
              `
            }}
          ></span>
          <div style={{ color: 'black', fontSize: '0.5em' }}>
            {score} / 10
          </div>
        </div>
        <p
          className="sitebar-menu-content-text"
          style={{
            color: percentageColor(true)
          }}
        >{basedOn}</p>
        <p
          className="sitebar-menu-content-text"
          style={{
            color: percentageColor(true)
          }}
        >{
          renderDetail(score, basedOn)
        }</p>
      </>
    } else {
      return <></>
    }
  }

  function intensityColorScale(score) {
    const interpolate = CUSTOM_INTERPOLATION(score)

    return interpolate(score)
  }

  useEffect(() => {
    if (
      colorScaleGraphicRef
      && colorScaleGraphicRef.current
    ) {
      const svg = d3.select(colorScaleGraphicRef.current)

      let yPos = 15

      svg
        .append('text')
          .text('Rating Color Scale')
          .attr('class', 'rating-score-block')
          .attr('x', '40%')
          .attr('y', yPos)
          .style('font-size', '0.7em')

      yPos += 5

      svg
        .selectAll('.rating-score-block')
        .data([0, 1, 2, 3, 4, 5])
        .enter()
        .append('rect')
        .attr('height', 100)
        .attr('x', (d) => {
          return `${30 + 6 * d}%`
        })
        .attr('y', yPos)
        .attr('width', '6%')
        .attr('height', '10px')
        .attr('fill', (d) => {
          let counter = d

          if (d <= 2) {
            counter -= 1
          }

          return intensityColorScale(counter * 2)
        })
        .attr('stroke', 'black')

      yPos += 10

      svg
        .append('text')
          .text('0')
          .attr('x', '31%')
          .attr('y', yPos - 2)
          .style('font-size', '0.7em')

      svg
        .append('text')
          .text('10')
          .attr('x', '69%')
          .attr('y', yPos - 2)
          .style('font-size', '0.7em')
    }
  }, [colorScaleGraphicRef])

  return <>
    <div className="row">
      <div className="technical-parameters-score-container">
        { 
          renderScore(
            technicalScore,
            'Technically'
          )
        }
        {
          renderScore(
            nonTechnicalScore,
            'Non-Technically'
          )
        }
      <svg ref={colorScaleGraphicRef} style={{ height: '2.4em' }}></svg>
      </div>
      <div className="technical-parameters-graphics-container">
        {
          toggleGraph === 'technical'
            ? <TechnicalParametersGraphic
                classContainer=".technical-parameters-graphics-container"
                id="technical-parameters-graphic"
                graph={technicalSubjectScore}
                graphDesign={generateGraphDesign(technicalScore)}
                parameterType={toggleGraph}
              />
            : <TechnicalParametersGraphic
                classContainer=".technical-parameters-graphics-container"
                id="technical-parameters-graphic"
                graph={nonTechnicalSubjectScore}
                graphDesign={generateGraphDesign(nonTechnicalScore)}
                parameterType={toggleGraph}
              />
        }
      </div>
    </div>
    <div
      className="technical-parameters-detail"
      ref={technicalParametersDetailRef}
      onWheel={scroll}
      onMouseEnter={scroll}
      onMouseLeave={enableScroll}
    >
      <div className="row">
        <div className="regional-geology-stratigraphy-container">
          <RegionalGeologicalStratigraphyGraphic site={selected.site} />
        </div>
        <div className="technical-parameters-structure-container">
          <StructureGraphic site={selected.site} content={selected.content} />
        </div>
      </div>
    </div>
  </>
}

export default TechnicalParameter
