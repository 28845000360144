import { findKeyProperties } from '../../../../packages/cesium-add-on/properties'

/**
 * This function will find the minimum value entities score
 * @param map is the object of map
 * @param groupBy is the category of group
 * @returns 
 */
export function getMinScore({
  map,
  groupBy
}) {
  const entities = map.dataSource.entities.values

  let min = Number.MAX_SAFE_INTEGER

  for (let i = 0; i < entities.length; i++) {
    const value = findKeyProperties({
      properties: entities[i].properties,
      key: groupBy
    })

    if (value < min) {
      min = value
    }
  }

  return min
}

/**
 * This function will find the minimum value entities score for logarithmic
 * @param map is the object of map
 * @param groupBy is the category of group
 * @returns 
 */
export function getMinScoreForLogarithmic({
  map,
  groupBy
}) {
  const entities = map.dataSource.entities.values

  let min = Number.MAX_SAFE_INTEGER

  for (let i = 0; i < entities.length; i++) {
    const value = findKeyProperties({
      properties: entities[i].properties,
      key: groupBy
    })

    if (value < min && value > 0) {
      min = value
    }
  }
  
  return min
}

/**
 * This function will find the minimum value entities score
 * @param map is the object of map
 * @param groupBy is the category of group
 * @returns 
 */
export function getMaxScore({
  map,
  groupBy
}) {
  const entities = map.dataSource.entities.values

  let max = Number.MIN_SAFE_INTEGER

  for (let i = 0; i < entities.length; i++) {
    const value = findKeyProperties({
      properties: entities[i].properties,
      key: groupBy
    })

    if (value > max) {
      max = value
    }
  }

  return max
}
