import * as d3 from 'd3'

export function renderArchitecturalImage ({
  x,
  y,
  height,
  width,
  image,
  svg
}) {
  svg.append('svg:image')
    .attr('x', x)
    .attr('y', y)
    .attr('width', width)
    .attr('height', height)
    .attr('xlink:href', image)
}

export function renderHeader ({
  magmaticBelt,
  line_pattern,
  midPoint,
  svg
}) {
  svg
    .append('text')
      .text('Structure')
      .attr('x', function () {
        return midPoint.x - this.getComputedTextLength() / 2
      })
      .attr('y', '20px')
  
  svg
    .append('text')
      .text('Magmatic belt')
      .attr('x', '20px')
      .attr('y', '50px')
      .style('font-size', '0.8em')
  
  svg
    .append('text')
      .text('Lineaments pattern')
      .attr('x', '20px')
      .attr('y', '65px')
      .style('font-size', '0.8em')

  svg
    .append('text')
      .text(`: ${magmaticBelt}`)
      .attr('x', '100px')
      .attr('y', '50px')
      .style('font-size', '0.8em')
  
  svg
    .append('text')
      .text(`: ${line_pattern}`)
      .attr('x', '100px')
      .attr('y', '65px')
      .style('font-size', '0.8em')
}

export function renderRoset ({
  circleX,
  colorScale,
  dataRender,
  focusRadius = 25,
  initialStructureAxisY,
  label,
  radius = 6,
  svg,
  structureGraphicRef,
  tooltipDiv,
  tooltipRender
}) {
  let focus = false
  const circleRadius = []

  for (let i = 1; i <= 5; i++) {
    circleRadius.push(i * radius)
  }

  const outerRadius = circleRadius[circleRadius.length - 1]

  let maximum = 0

  for (let i = 0; i < dataRender.length / 2; i++) {
    if (maximum < dataRender[i].references.length) {
      maximum = dataRender[i].references.length
    }
  }

  const rosetUI = {
    container: {
      element: null,
      unfocus: {
        x: function (circleX, outerRadius) {
          return circleX - outerRadius - 15
        },
        y: function (initialStructureAxisY, outerRadius) {
          return initialStructureAxisY - outerRadius - 15
        },
        width: function (outerRadius) {
          return 2 * outerRadius + 30
        },
        height: function (outerRadius, radius) {
          return 2 * outerRadius + 30 + radius / 6 * 16
        },
        opacity: 0.2
      },
      focus: {
        x: 0,
        y: '15%',
        height: '75%',
        width: '100%',
        fill: 'white',
        opacity: 0.9
      }
    },
    circle: {
      elements: [],
      unfocus: {
        cx: circleX,
        cy: initialStructureAxisY,
        radius: function (circleRadius, i) {
          return circleRadius[i]
        }
      },
      focus: {
        cx: '50%',
        cy: '50%',
        radius: function (focusRadius, i) {
          return focusRadius[i]
        }
      }
    },
    scale: {
      elements: [],
      unfocus: {
        strokeWidth: function (radius) {
          return radius / 6 * 0.1
        },
        x1: function (
          i,
          circleX,
          outerRadius
        ) {
          let posX = Math.abs(Math.sin(Math.PI * i / 180))
          if (i > 180) {
            posX *= -1
          }
          return circleX + posX * (outerRadius - 2)
        },
        y1: function (
          i,
          initialStructureAxisY,
          outerRadius
        ) {
          let posY = Math.abs(Math.cos(Math.PI * i / 180))
          if (i < 90 || i > 270) {
            posY *= -1
          }
          return initialStructureAxisY + posY * (outerRadius - 2)
        },
        x2: function (
          i,
          circleX,
          outerRadius
        ) {
          let posX = Math.abs(Math.sin(Math.PI * i / 180))
          if (i > 180) {
            posX *= -1
          }
          return circleX + posX * (outerRadius + 2)
        },
        y2: function (
          i,
          initialStructureAxisY,
          outerRadius
        ) {
          let posY = Math.abs(Math.cos(Math.PI * i / 180))
          if (i < 90 || i > 270) {
            posY *= -1
          }
          return initialStructureAxisY + posY * (outerRadius + 2)
        }
      },
      focus: {
        strokeWidth: function (radius) {
          return radius / 6 * 0.1
        },
        x1: function (
          i,
          circleX,
          outerRadius
        ) {
          let posX = Math.abs(Math.sin(Math.PI * i / 180))
          if (i > 180) {
            posX *= -1
          }
          return circleX + posX * (outerRadius - 2)
        },
        y1: function (
          i,
          initialStructureAxisY,
          outerRadius
        ) {
          let posY = Math.abs(Math.cos(Math.PI * i / 180))
          if (i < 90 || i > 270) {
            posY *= -1
          }
          return initialStructureAxisY + posY * (outerRadius - 2)
        },
        x2: function (
          i,
          circleX,
          outerRadius
        ) {
          let posX = Math.abs(Math.sin(Math.PI * i / 180))
          if (i > 180) {
            posX *= -1
          }
          return circleX + posX * (outerRadius + 2)
        },
        y2: function (
          i,
          initialStructureAxisY,
          outerRadius
        ) {
          let posY = Math.abs(Math.cos(Math.PI * i / 180))
          if (i < 90 || i > 270) {
            posY *= -1
          }
          return initialStructureAxisY + posY * (outerRadius + 2)
        }
      }
    },
    N: {
      element: null,
      unfocus: {
        x: function (circleX, element) {
          return circleX - element.getComputedTextLength() / 2
        },
        y: function (initialStructureAxisY, outerRadius) {
          return initialStructureAxisY - outerRadius - 5
        }
      },
      focus: {
        x: function (circleX, element) {
          return circleX - element.getComputedTextLength() / 2
        },
        y: function (initialStructureAxisY, outerRadius) {
          return initialStructureAxisY - outerRadius - 5
        }
      }
    },
    E: {
      element: null,
      unfocus: {
        x: function (circleX, outerRadius) {
          return circleX + outerRadius + 5
        },
        y: function (initialStructureAxisY) {
          return initialStructureAxisY + 2.5
        }
      },
      focus: {
        x: function (circleX, outerRadius) {
          return circleX + outerRadius + 5
        },
        y: function (initialStructureAxisY) {
          return initialStructureAxisY + 2.5
        }
      }
    },
    S: {
      element: null,
      unfocus: {
        x: function (circleX, element) {
          return circleX - element.getComputedTextLength() / 2
        },
        y: function (initialStructureAxisY, outerRadius) {
          return initialStructureAxisY + outerRadius + 12
        }
      },
      focus: {
        x: function (circleX, element) {
          return circleX - element.getComputedTextLength() / 2
        },
        y: function (initialStructureAxisY, outerRadius) {
          return initialStructureAxisY + outerRadius + 12
        }
      }
    },
    W: {
      element: null,
      unfocus: {
        x: function (circleX, outerRadius) {
          return circleX - outerRadius - 12
        },
        y: function (initialStructureAxisY) {
          return initialStructureAxisY + 2.5
        }
      },
      focus: {
        x: function (circleX, outerRadius) {
          return circleX - outerRadius - 12
        },
        y: function (initialStructureAxisY) {
          return initialStructureAxisY + 2.5
        }
      }
    },
    label: {
      element: null,
      unfocus: {
        fontSize: function (radius) {
          return `${radius / 6 * 0.9}em`
        },
        x: function (circleX, element, modifier = 1) {
          return circleX
            - element.getComputedTextLength() / 2 / modifier
        },
        y: function (initialStructureAxisY, outerRadius, radius) {
          return initialStructureAxisY
            + outerRadius
            + radius + 21
        }
      },
      focus: {
        fontSize: function (radius) {
          return `${radius / 6 * 0.9}em`
        },
        x: function (circleX, element) {
          return circleX
            - element.getComputedTextLength()
        },
        y: function (initialStructureAxisY, outerRadius, radius) {
          return initialStructureAxisY
            + outerRadius
            + radius + 21
        }
      }
    },
    pieChart: {
      elements: [],
      unfocus: {
        innerRadius: 0,
        outerRadius: function (
          references,
          maximum,
          outerRadius
        ) {
          return references.length
            / maximum
            * outerRadius
        },
        startAngle: function (
          dataRender,
          i
        ) {
          if (i === dataRender.length - 1) {
            return Math.PI / 180 * -5
          } else {
            return Math.PI
              / 180 * dataRender[i].before
          }
        },
        endAngle: function (
          dataRender,
          i
        ) {
          if (i === dataRender.length - 1) {
            return Math.PI / 180 * 5
          } else {
            return Math.PI
              / 180 * dataRender[i].after
          }
        },
        opacity: 0.8,
        transform: function (
          circleX,
          initialStructureAxisY
        ) {
          return `translate(
            ${circleX},
            ${initialStructureAxisY}
          )`
        }
      },
      focus: {
        innerRadius: 0,
        outerRadius: function (
          references,
          maximum,
          outerRadius
        ) {
          return references.length
            / maximum
            * outerRadius
        },
        opacity: 1,
        transform: function (
          circleX,
          initialStructureAxisY
        ) {
          return `translate(
            ${circleX},
            ${initialStructureAxisY}
          )`
        }
      }
    }
  }

  const rosetSVG = svg
    .append('svg')
      .style('cursor', 'pointer')

  rosetUI.container.element = rosetSVG
    .append('rect')
      .attr('id', `structure-${label}`)
      .attr('rx', 5)
      .attr('ry', 5)
      .attr('x',
        rosetUI
          .container
          .unfocus
          .x(circleX, outerRadius)
      )
      .attr('y',
        rosetUI
          .container
          .unfocus
          .y(initialStructureAxisY, outerRadius)
      )
      .attr('width',
        rosetUI
          .container
          .unfocus
          .width(outerRadius)
      )
      .attr('height',
        rosetUI
          .container
          .unfocus
          .height(outerRadius, radius)
      )
      .style('stroke', 'transparent')
      .style('fill', 'white')
      .style('border-radius', 5)
      .style('transition', 'all 330ms')
      .style('opacity', rosetUI.container.unfocus.opacity)

  for (let i = 0; i < circleRadius.length; i++) {
    const circle = rosetSVG
      .append('circle')
        .attr('cx', rosetUI.circle.unfocus.cx)
        .attr('cy', rosetUI.circle.unfocus.cy)
        .attr('r', rosetUI.circle.unfocus.radius(circleRadius, i))
        .attr('pointer-events', 'none')
        .style('fill', 'none')
        .style('stroke', 'grey')
        .style('transition', 'all 330ms')

    rosetUI.circle.elements.push(circle)
  }

  for (let i = 5; i <= 355; i += 10) {
    const scale = rosetSVG
      .append('line')
      .style('stroke', 'black')
      .style('stroke-width',
        rosetUI
          .scale
          .unfocus
          .strokeWidth(radius)
      )
      .attr('x1',
        rosetUI
          .scale
          .unfocus
          .x1(i, circleX, outerRadius)
      )
      .attr('y1',
        rosetUI
          .scale
          .unfocus
          .y1(i, initialStructureAxisY, outerRadius)
      )
      .attr('x2',
        rosetUI
          .scale
          .unfocus
          .x2(i, circleX, outerRadius)
      )
      .attr('y2',
        rosetUI
          .scale
          .unfocus
          .y2(i, initialStructureAxisY, outerRadius)
      )
      .style('transition', 'all 330ms')
      .attr('pointer-events', 'none')

    rosetUI.scale.elements.push({ scale, i })
  }

  rosetUI.N.element = rosetSVG
    .append('text')
      .text('N')
      .style('font-size', '0.7em')
      .attr('x', function () {
        return rosetUI
          .N
          .unfocus
          .x(circleX, this)
      })
      .attr('y',
        rosetUI
          .N
          .unfocus
          .y(initialStructureAxisY, outerRadius)
      )
      .attr('pointer-events', 'none')
      .style('transition', 'all 330ms')

  rosetUI.E.element = rosetSVG
    .append('text')
      .text('E')
      .style('font-size', '0.7em')
      .attr('x',
        rosetUI
          .E
          .unfocus
          .x(circleX, outerRadius)
      )
      .attr('y',
        rosetUI
          .E
          .unfocus
          .y(initialStructureAxisY)
      )
      .attr('pointer-events', 'none')
      .style('transition', 'all 330ms')

  rosetUI.S.element = rosetSVG
    .append('text')
      .text('S')
      .style('font-size', '0.7em')
      .attr('x', function () {
        return rosetUI
          .S
          .unfocus
          .x(circleX, this)
      })
      .attr('y',
        rosetUI
          .S
          .unfocus
          .y(initialStructureAxisY, outerRadius)
      )
      .attr('pointer-events', 'none')
      .style('transition', 'all 330ms')
  
  rosetUI.W.element = rosetSVG
    .append('text')
      .text('W')
      .style('font-size', '0.7em')
      .attr('x',
        rosetUI
          .W
          .unfocus
          .x(circleX, outerRadius)
      )
      .attr('y',
        rosetUI
          .W
          .unfocus
          .y(initialStructureAxisY)
      )
      .attr('pointer-events', 'none')
      .style('transition', 'all 330ms')

  rosetUI.label.element = rosetSVG
    .append('text')
      .text(label)
      .style('font-size',
        rosetUI
          .label
          .unfocus
          .fontSize(radius)
      )
      .attr('x', function () {
        return rosetUI
          .label
          .unfocus
          .x(circleX, this)
      })
      .attr('y',
        rosetUI
          .label
          .unfocus
          .y(initialStructureAxisY, outerRadius, radius)
      )
      .attr('pointer-events', 'none')
      .style('transition', 'all 330ms')

  for (let i = 0; i < dataRender.length; i++) {
    const arc = {
      i
    }

    let radius = rosetUI
      .pieChart
      .unfocus
      .outerRadius(
        dataRender[i].references,
        maximum,
        outerRadius
      )

    arc.element = d3.arc()
      .innerRadius(0)
      .outerRadius(radius)
      .startAngle(
        rosetUI
          .pieChart
          .unfocus
          .startAngle(dataRender, i)
      )
      .endAngle(
        rosetUI
          .pieChart
          .unfocus
          .endAngle(dataRender, i)
      )

    arc.path = rosetSVG
      .append('path')
        .attr('d', arc.element())
        .attr('fill', () => {
          return colorScale(
            dataRender[i].references.length,
            maximum
          )
        })
        .attr('transform',
          rosetUI
            .pieChart
            .unfocus
            .transform(
              circleX,
              initialStructureAxisY
            )
        )
        .attr('pointer-events', 'none')
        .style('opacity',
          rosetUI
            .pieChart
            .unfocus
            .opacity
        )
        .style('stroke', 'black')
        .style('stroke-width', 0.2)
        .style('transition', 'all 330ms')

      arc.text = 'Azimuth: ' + dataRender[i].before + '°'
        + '-' + dataRender[i].after + '° \t'
        + ' Frequency:  '
        + dataRender[i].references.length

      arc.references = dataRender[i].references.length
    rosetUI.pieChart.elements.push(arc)
  }

  function close (target) {
    if (focus) {
      if (target.getAttribute('id') !== `structure-${label}`) {
        focus = false
  
        rosetSVG
          .style('cursor', 'pointer')
        
        rosetUI.container.element
          .attr('x',
            rosetUI
              .container
              .unfocus
              .x(
                circleX,
                outerRadius
              )
          )
          .attr('y',
            rosetUI
              .container
              .unfocus
              .y(
                initialStructureAxisY,
                outerRadius
              )
          )
          .attr('height',
            rosetUI
              .container
              .unfocus
              .height(
                outerRadius,
                radius
              )
          )
          .attr('width',
            rosetUI
              .container
              .unfocus
              .width(
                outerRadius
              )
          )
          .style('fill', 'white')
          .style('stroke', 'transparent')
          .style('opacity', 0.2)
  
          rosetUI.circle.elements.forEach((element, i) => {
            element
              .attr('cx',
                rosetUI
                  .circle
                  .unfocus
                  .cx
              )
              .attr('cy',
                rosetUI
                  .circle
                  .unfocus
                  .cy
              )
              .attr('r',
                rosetUI
                  .circle
                  .unfocus
                  .radius(
                    circleRadius,
                    i
                  )
              )
          })
  
          rosetUI.scale.elements.forEach(({ scale, i }) => {
            scale
              .style('opacity', 0)
          })
  
          rosetUI.N.element
            .style('opacity', 0)
  
          rosetUI.E.element
            .style('opacity', 0)
          
          rosetUI.S.element
            .style('opacity', 0)
  
          rosetUI.W.element
            .style('opacity', 0)
          
          rosetUI.label.element
            .style('opacity', 0)
  
          rosetUI.pieChart.elements
            .forEach(({ element, i, path }) => {
              const radius = rosetUI
                .pieChart
                .unfocus
                .outerRadius(
                  dataRender[i].references,
                  maximum,
                  outerRadius
                )
              
              element
                .outerRadius(radius)
  
              path
                .attr('d', element())
                .attr('transform',
                  rosetUI
                    .pieChart
                    .unfocus
                    .transform(
                      circleX,
                      initialStructureAxisY
                    )
                )
                .attr('pointer-events', 'none')
                .style('opacity',
                  rosetUI
                    .pieChart
                    .unfocus
                    .opacity
                )
                .on('mouseover', function () {})
                .on('mouseleave', function () {})
            })

        setTimeout(() => {
          rosetUI.scale.elements.forEach(({ scale, i }) => {
            scale
              .style('stroke-width',
                rosetUI
                  .scale
                  .unfocus
                  .strokeWidth(
                    radius
                  )
              )
              .attr('x1',
                rosetUI
                  .scale
                  .unfocus
                  .x1(i, circleX, outerRadius)
              )
              .attr('y1',
                rosetUI
                  .scale
                  .unfocus
                  .y1(i, initialStructureAxisY, outerRadius)
              )
              .attr('x2',
                rosetUI
                  .scale
                  .unfocus
                  .x2(i, circleX, outerRadius)
              )
              .attr('y2',
                rosetUI
                  .scale
                  .unfocus
                  .y2(i, initialStructureAxisY, outerRadius)
              )
              .style('opacity', 1)
          })
  
          rosetUI.N.element
            .attr('x', function () {
              return rosetUI
                .N
                .unfocus
                .x(circleX, this)
            })
            .attr('y',
              rosetUI
                .N
                .unfocus
                .y(initialStructureAxisY, outerRadius)
            )
            .style('opacity', 1)
  
          rosetUI.E.element
            .attr('x', function () {
              return rosetUI
                .E
                .unfocus
                .x(circleX, outerRadius)
            })
            .attr('y',
              rosetUI
                .E
                .unfocus
                .y(initialStructureAxisY, outerRadius)
            )
            .style('opacity', 1)
          
          rosetUI.S.element
            .attr('x', function () {
              return rosetUI
                .S
                .unfocus
                .x(circleX, this)
            })
            .attr('y',
              rosetUI
                .S
                .unfocus
                .y(initialStructureAxisY, outerRadius)
            )
            .style('opacity', 1)
  
          rosetUI.W.element
            .attr('x', function () {
              return rosetUI
                .W
                .unfocus
                .x(circleX, outerRadius)
            })
            .attr('y',
              rosetUI
                .W
                .unfocus
                .y(initialStructureAxisY, outerRadius)
            )
            .style('opacity', 1)
  
          rosetUI.label.element
            .style('font-size',
              rosetUI
                .label
                .unfocus
                .fontSize(radius)
            )
            .attr('x', function () {
              return rosetUI
                .label
                .unfocus
                .x(circleX, this, 2)
            })
            .attr('y',
              rosetUI
                .label
                .unfocus
                .y(initialStructureAxisY, outerRadius, radius)
            )
            .style('opacity', 1)
        }, 330)
      }
    }
  }

  rosetSVG
    .on('mouseover', function (event) {
      if (!focus) {
        rosetUI.container.element
        .style('fill', 'gold')
        .style('stroke', 'red')
        .style('opacity', 0.3)

        tooltipRender({
          addition: {
            left: 10,
            top: 20
          },
          event,
          message: 'show detail',
          opacity: 1,
          structureGraphicRef,
          tooltipDiv
        })
      }
    })
    .on('click', function (event) {
      if (!structureGraphicRef
        || !structureGraphicRef.current) {
        return ''
      } else if (!focus) {
        focus = true

        d3.select(this).raise()

        tooltipRender({
          addition: {
            left: 10,
            top: 20
          },
          event,
          message: 'show detail',
          opacity: 0,
          structureGraphicRef,
          tooltipDiv
        })
        
        setTimeout(() => {
          if (!structureGraphicRef
            || !structureGraphicRef.current
          ) {
            return
          }

          rosetSVG
            .style('cursor', 'unset')

          rosetUI.container.element
            .attr('x',
              rosetUI
                .container
                .focus
                .x
            )
            .attr('y',
              rosetUI
                .container
                .focus
                .y
            )
            .attr('height',
              rosetUI
                .container
                .focus
                .height
            )
            .attr('width',
              rosetUI
                .container
                .focus
                .width
            )
            .style('fill',
              rosetUI
                .container
                .focus
                .fill
            )
            .style('opacity',
              rosetUI
                .container
                .focus
                .opacity
            )

          const focusRadiusRange = []

          const {
            clientWidth,
            clientHeight
          } = structureGraphicRef.current

          for (let i = 1; i <= 5; i++) {
            focusRadiusRange.push(i * focusRadius)
          }

          const outerFocusRadius = focusRadiusRange[
            focusRadiusRange.length - 1
          ]

          const circleXFocus = clientWidth / 2
          const focusAxisY = clientHeight / 2

          rosetUI.scale.elements.forEach(({ scale, i }) => {
            scale
              .style('opacity', 0)
          })

          rosetUI.N.element
            .style('opacity', 0)

          rosetUI.E.element
            .style('opacity', 0)
          
          rosetUI.S.element
            .style('opacity', 0)

          rosetUI.W.element
            .style('opacity', 0)

          rosetUI.label.element
            .style('opacity', 0)
          
          rosetUI.circle.elements.forEach((element, i) => {
            element
              .attr('cx',
                rosetUI
                  .circle
                  .focus
                  .cx
              )
              .attr('cy',
                rosetUI
                  .circle
                  .focus
                  .cy
              )
              .attr('r',
                rosetUI
                  .circle
                  .focus
                  .radius(
                    focusRadiusRange,
                    i
                  )
              )
          })

          rosetUI.pieChart.elements
            .forEach(({ element, entities, i, path, text }) => {
              const radius = rosetUI
                .pieChart
                .focus
                .outerRadius(
                  dataRender[i].references,
                  maximum,
                  outerFocusRadius
                )
              
              element
                .outerRadius(radius)

              path
                .attr('d', element())
                .attr('transform',
                  rosetUI
                    .pieChart
                    .focus
                    .transform(
                      circleXFocus,
                      focusAxisY
                    )
                )
                .attr('pointer-events', 'unset')
                .style('opacity',
                  rosetUI
                    .pieChart
                    .focus
                    .opacity
                )
                .on('mouseover', function (event) {
                  path
                    .style('stroke', 'red')
                    .style('stroke-width', 2)
                  
                  tooltipRender({
                    addition: {
                      left: 10,
                      top: 20
                    },
                    event,
                    message: text,
                    opacity: 1,
                    structureGraphicRef,
                    tooltipDiv
                  })

                  // here to edit
                  // if (entities && entities.length) {
                  //   entities.forEach((entity) => {
                  //     entity.polyline.material.outlineColor = {
                  //       ...entity.polyline.material.color.getValue('')
                  //     }
                  //   })
                  // }
                })
                .on('mousemove', function (event) {
                  tooltipRender({
                    addition: {
                      left: 10,
                      top: 20
                    },
                    event,
                    message: text,
                    opacity: 1,
                    structureGraphicRef,
                    tooltipDiv
                  })
                })
                .on('mouseleave', function (event) {
                  tooltipRender({
                    addition: {
                      left: 10,
                      top: 20
                    },
                    event,
                    message: text,
                    opacity: 0,
                    structureGraphicRef,
                    tooltipDiv
                  })

                  path
                    .style('stroke', 'black')
                    .style('stroke-width', 0.1)

                  // if (entities && entities.length) {
                  //   entities.forEach((entity) => {
                  //     entity.polyline.material.outlineColor = {
                  //       ...entity.polyline.material.outlineColor.getValue(''),
                  //       alpha: 0.01
                  //     }
                  //   })
                  // }
                })
            })
        }, 20)

        setTimeout(() => {
          const focusRadiusRange = []

          const {
            clientWidth,
            clientHeight
          } = structureGraphicRef.current

          for (let i = 1; i <= 5; i++) {
            focusRadiusRange.push(i * focusRadius)
          }

          const outerFocusRadius = focusRadiusRange[
            focusRadiusRange.length - 1
          ]

          const circleXFocus = clientWidth / 2
          const focusAxisY = clientHeight / 2

          rosetUI.scale.elements.forEach(({ scale, i }) => {
            scale
              .style('stroke-width',
                rosetUI
                  .scale
                  .focus
                  .strokeWidth(focusRadius)
              )
              .attr('x1',
                rosetUI
                  .scale
                  .focus
                  .x1(i, circleXFocus, outerFocusRadius)
              )
              .attr('y1',
                rosetUI
                  .scale
                  .focus
                  .y1(i, focusAxisY, outerFocusRadius)
              )
              .attr('x2',
                rosetUI
                  .scale
                  .focus
                  .x2(i, circleXFocus, outerFocusRadius)
              )
              .attr('y2',
                rosetUI
                  .scale
                  .focus
                  .y2(i, focusAxisY, outerFocusRadius)
              )
              .style('opacity', 1)
          })

          rosetUI.N.element
            .attr('x', function () {
              return rosetUI
                .N
                .focus
                .x(circleXFocus, this)
            })
            .attr('y',
              rosetUI
                .N
                .focus
                .y(focusAxisY, outerFocusRadius)
            )
            .style('opacity', 1)

          rosetUI.E.element
            .attr('x', function () {
              return rosetUI
                .E
                .focus
                .x(circleXFocus, outerFocusRadius)
            })
            .attr('y',
              rosetUI
                .E
                .focus
                .y(focusAxisY, outerFocusRadius)
            )
            .style('opacity', 1)
          
          rosetUI.S.element
            .attr('x', function () {
              return rosetUI
                .S
                .focus
                .x(circleXFocus, this)
            })
            .attr('y',
              rosetUI
                .S
                .focus
                .y(focusAxisY, outerFocusRadius)
            )
            .style('opacity', 1)

          rosetUI.W.element
            .attr('x', function () {
              return rosetUI
                .W
                .focus
                .x(circleXFocus, outerFocusRadius)
            })
            .attr('y',
              rosetUI
                .W
                .focus
                .y(focusAxisY, outerFocusRadius)
            )
            .style('opacity', 1)

          rosetUI.label.element
            .style('font-size',
              rosetUI
                .label
                .focus
                .fontSize(radius * 2)
            )
            .attr('x', function () {
              return rosetUI
                .label
                .focus
                .x(circleXFocus, this, 2)
            })
            .attr('y',
              rosetUI
                .label
                .focus
                .y(focusAxisY, outerFocusRadius, focusRadius)
            )
            .style('opacity', 1)
        }, 330)
      }
    })
    .on('mousemove', function (event) {
      if (!focus) {
        tooltipRender({
          addition: {
            left: 10,
            top: 20
          },
          event,
          message: 'show detail',
          opacity: 1,
          structureGraphicRef,
          tooltipDiv
        })
      }
    })
    .on('mouseleave', function (event) {
      if (!focus) {
        rosetUI.container.element
          .style('fill', 'white')
          .style('stroke', 'transparent')
          .style('opacity', 0.2)

        tooltipRender({
          addition: {
            left: 10,
            top: 20
          },
          event,
          message: 'show detail',
          opacity: 0,
          structureGraphicRef,
          tooltipDiv
        })
      }
    })

  return {
    close
  }
}

export function renderSubHeader ({
  cx,
  cy,
  svg,
  text,
  x,
  y
}) {
  svg
    .append('circle')
      .attr('cx', cx)
      .attr('cy', cy)
      .attr('r', '2px')
  
  svg
    .append('text')
      .text(text)
      .attr('x', x)
      .attr('y', y)
      .style('font-size', '0.8em')
}

export function renderTableAxis ({
  initialStructureAxisY,
  structureAxis,
  svg
}) {
  for (let i = 0; i < structureAxis.length; i++) {
    svg
      .append('text')
        .text(structureAxis[i].name)
        .attr('x', '20px')
        .attr('y', function () {
          structureAxis[i].start = initialStructureAxisY
          
          structureAxis[i].end = initialStructureAxisY
            + 20 * structureAxis[i].scale
        
          initialStructureAxisY += 20 * structureAxis[i].scale

          const centerY = (structureAxis[i].end
            + structureAxis[i].start) / 2

          return centerY
        })
        .style('font-size', '0.8em')
  }

  return {
    initialStructureAxisY
  }
}

export function renderTableContent ({
  colorScale,
  filterReference,
  structureAxis,
  structureHeader,
  structureGraphicRef,
  svg,
  tooltipDiv,
  tooltipRender
}) {
  for (let i = 0; i < structureHeader.length; i++) {
    const header = structureHeader[i]

    
    for (let j = 0; j < structureAxis.length; j++) {
      const axis = structureAxis[j]
      let total = 'N/A'
      let fill = 'none'
      
      if (filterReference[axis.name]) {
        const { data, totalLength } = filterReference[axis.name]

        if (data[header.databaseName]) {
          let longLine = 0

          data[header.databaseName].forEach((lineData) => {
            longLine += lineData.structureLength
          })

          total = `${(longLine/1000).toFixed(1)} km / ${(totalLength / 1000).toFixed(1)} km`
          fill = colorScale(longLine, totalLength)
        }
      }

      const contentRec = svg
        .append('rect')
          .attr('x', header.start)
          .attr('y', axis.start)
          .attr('width', header.end - header.start)
          .attr('height', axis.end - axis.start - 5)
          .attr('fill', fill)
          .attr('stroke', 'black')
          .style('cursor', 'pointer')
          .on('mouseover', function (event) {
            tooltipRender({
              addition: {
                left: 10,
                top: 20
              },
              event,
              message: total,
              opacity: 1,
              structureGraphicRef,
              tooltipDiv
            })

            contentRec
              .attr('stroke', 'red')
              .attr('stroke-width', 2)
            
            d3.select(this).raise()

            if (filterReference[axis.name]) {
              const { data } = filterReference[axis.name]
  
              if (data[header.databaseName]) {
                // data[header.databaseName].forEach((entity) => {
                //   entity.polyline.material.outlineColor = {
                //     ...entity.polyline.material.color.getValue('')
                //   }
                // })
              }
            }
          })
          .on('mousemove', function (event) {
            tooltipRender({
              addition: {
                left: 10,
                top: 20
              },
              event,
              message: total,
              opacity: 1,
              structureGraphicRef,
              tooltipDiv
            })
          })
          .on('mouseleave', function (event) {
            tooltipRender({
              addition: {
                left: 10,
                top: 20
              },
              event,
              message: total,
              opacity: 0,
              structureGraphicRef,
              tooltipDiv
            })

            contentRec
              .attr('stroke', 'black')
              .attr('stroke-width', 1)

            if (filterReference[axis.name]) {
              // const { data } = filterReference[axis.name]
  
              // if (data[header.databaseName]) {
              //   data[header.databaseName].forEach((entity) => {
              //     entity.polyline.material.outlineColor = {
              //       ...entity.polyline.material.color.getValue(''),
              //       alpha: 0.01
              //     }
              //   })
              // }
            }
          })
    }
  }
}

export function renderTableHeader ({
  clientWidth,
  structureHeader,
  structureHeaderY,
  svg
}) {
  const tableScale = 1
    / (structureHeader.length + 1)
    * (clientWidth - 30)

  for (let i = 0; i < structureHeader.length; i++) {
    svg
      .append('text')
        .text(structureHeader[i].name)
        .attr('x', function () {
          const start = (i + 1) * tableScale + 10

          structureHeader[i].start = start
          structureHeader[i].end = start + tableScale
          
          const posX = start
            + tableScale / 2
            - this.getComputedTextLength() / 2
          return `${posX}px`
        })
        .attr('y', `${structureHeaderY}px`)
        .style('font-size', '0.8em')
  }
}

export function renderTableIntensity ({
  colorScale,
  initialStructureAxisY,
  svg
}) {
  svg
    .append('text')
      .text('Intensity')
      .attr('class', 'intensity-block')
      .attr('x', '53.6%')
      .attr('y', initialStructureAxisY)
      .style('font-size', '0.7em')

  initialStructureAxisY += 5

  svg
    .selectAll('.intensity-block')
    .data([0, 1, 2, 3, 4, 5])
    .enter()
    .append('rect')
    .attr('height', 100)
    .attr('x', (d) => {
      return `${24 + 10 * d}%`
    })
    .attr('y', initialStructureAxisY)
    .attr('width', '10%')
    .attr('height', '10px')
    .attr('fill', (d) => {
      return colorScale(d - 1, 4)
    })
    .attr('stroke', 'black')

  initialStructureAxisY += 10

  svg
    .append('text')
      .text('Low')
      .attr('x', '27%')
      .attr('y', initialStructureAxisY - 2.5)
      .style('font-size', '0.7em')

  svg
    .append('text')
      .text('High')
      .attr('x', '87%')
      .attr('y', initialStructureAxisY - 2.5)
      .style('font-size', '0.7em')

  return {
    initialStructureAxisY
  }
}

export function tooltipRender ({
  addition,
  event,
  message,
  opacity,
  structureGraphicRef,
  tooltipDiv
}) {
  tooltipDiv
    .transition()
    .duration(330)
    .style('opacity', opacity)

  const {
    top,
    left
  } = structureGraphicRef
    .current
    .getBoundingClientRect()

  let positionLeft = event.pageX - left
  let positionTop = event.pageY - top
  let offset = event.pageX - left <= 200
    ? 0
    : event.pageX - left - 200

  if (addition && addition.left) {
    positionLeft += addition.left
  }

  if (addition && addition.top) {
    positionTop += addition.top
  }

  tooltipDiv
    .text(message)
    .style('left', `${positionLeft - offset}px`)
    .style('top', `${positionTop}px`)
}
