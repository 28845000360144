import React, { useState, useEffect, useRef } from 'react'

import {
  CHECK_LIST
} from '../../../constants/Cesium/assistCheck'

import styled from 'styled-components'

const Container = styled.div`
  position: absolute;
  width: 10em;
  height: 14em;
  margin-top: -1.3em;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  transition: all 330ms;
  z-index: -2;
`

const ContentContainer = styled.div`
  padding: 1em 0em;
  font-size: 0.8em;
  overflow-y: scroll;
  height: 14.2em;
  scrollbar-width: none;
`

/**
 * This function will generate initial style of this component
 * @param {React.Ref} containerRef react container of AssistCheck
 * @param {Boolean} isActive to set the initial status being showed or not
 * @param {String} position current position 'left' / 'right'
 * @returns object of initial style state 
 */
function initialStyle({
  containerRef,
  isActive,
  position
}) {
  let top = null
  let right = null
  let left = null
  let paddingRight = null
  let paddingLeft = null

  if (containerRef.current) {
    const { height } = containerRef.current
      .getBoundingClientRect()
    
    top = `-${height / 3}px`
  }

  if (position === 'left') {
    left = '-17.7em'
    paddingRight = '9em'
    paddingLeft = '2.5em'
  } else if (position === 'right') {
    right = '-17.7em'
    paddingLeft = '9em'
  }

  return {
    opacity: 0,
    top,
    left,
    right,
    paddingLeft,
    paddingRight,
    pointerEvents: isActive ? 'auto' : 'none'
  }
}

/**
 * This function will show check status is on or not
 * @param {String} iconSearch icon name to search
 * @param {Array} currentActives is the current active icons
 * @returns Boolean true or false
 */
function checkStatus({ iconSearch, currentActives }) {
  for (let i = 0; i < currentActives.length; i++) {
    if (currentActives[i].icon === iconSearch) {
      return true
    }
  }

  return false
}

/**
 * This component will render checkbox option for assist touch
 * @param {Boolean} isActive is whether checkbox is active or not
 * @param {Array} currentActives is to show list that already on the shortcut
 */
function AssistCheck({
  isActive,
  currentActives,
  setActives,
  position
}) {
  const containerRef = useRef(null)
  const [ style, setStyle ] = useState(initialStyle({
    containerRef,
    isActive,
    position
  }))

  /**
   * This function will handle when user click the checkbox
   * @param {*} event change event javascript
   * @param {Object} list selected list checked
   */
  function onCheckHandler(event, list) {
    const current = [...currentActives]

    if (event.target.checked) {
      current.push(list)
    } else {
      for (let i = 0; i < current.length; i++) {
        if (current[i].icon === list.icon) {
          current.splice(i, 1)
          break
        }
      }
    }

    setActives(current)
  }

  useEffect(() => {
    if (position === 'left') {
      setStyle((style) => ({
        ...style,
        left: '-17.7em',
        paddingRight: '9em',
        paddingLeft: '2.5em',
        right: null
      }))
    } else if (position === 'right') {
      setStyle((style) => ({
        ...style,
        right: '-17.7em',
        paddingLeft: '9em',
        left: null,
        paddingRight: null
      }))
    }
  }, [position])

  useEffect(() => {
    if (containerRef.current) {
      const { height } = containerRef.current
        .getBoundingClientRect()
      
      setStyle((style) => ({
        ...style,
        top: `-${height / 3}px`
      }))
    }
  }, [containerRef])

  useEffect(() => {
    if (isActive) {
      setStyle((style) => ({
        ...style,
        opacity: 1,
        pointerEvents: 'auto'
      }))
    } else {
      setStyle((style) => ({
        ...style,
        opacity: 0,
        pointerEvents: 'none'
      }))
    }
  }, [isActive])

  return (
    <Container style={style} ref={containerRef}>
      <ContentContainer className="no-scrollbar">
        {
          CHECK_LIST.map((list) => {
            return (
              <div key={list.id} style={{
                padding: '0.3em 0em',
                display: 'flex',
                alignItems: 'center'
              }}>
                <input
                  type="checkbox"
                  style={{
                    cursor: 'pointer'
                  }}
                  checked={checkStatus({
                    iconSearch: list.icon,
                    currentActives
                  })}
                  onChange={(event) => {
                    onCheckHandler(event, list)
                  }}
                />
                <span
                  style={{
                    fontFamily: 'Abel',
                    color: 'white',
                  }}
                >{list.title}</span>
              </div>
            )
          })
        }
      </ContentContainer>
    </Container>
  )
}

export default AssistCheck
