import React from 'react'

import {
  ReactComponent as CloseBoxIcon
} from '../../../images/Icons/close-box.svg'

import styled from 'styled-components'

const LegendWarningIcon = styled.div`
  margin-left: 0.5em;
  cursor: pointer;
  opacity: ${props => props.isHover ? 1 : 0};
  transition: all 330ms;
  transition-property: opacity;

  svg {
    fill: #ff5454;
    width: 0.8em;
    height: 0.8em;
  }

  &:hover {
    svg {
      fill: red;
    }
  }
`

/**
 * This function used to close the map legend
 * @param {Function} closeHandler is button that triggered to remove legend
 * @param {Boolean} isHover to tell that component being show or not
 */
function CloseButton({
  closeHandler,
  isHover
}) {
  return <LegendWarningIcon onClick={closeHandler} isHover={isHover}>
    <CloseBoxIcon />
  </LegendWarningIcon>
}

export default CloseButton
