import React, { useEffect, useState } from 'react'

import { ReactComponent as UpIcon } from '../../../images/Icons/up.svg'
import { ReactComponent as DownIcon } from '../../../images/Icons/down.svg'

import './SimilarProjectMenuDropdown.css'

function SimilarProjectMenuDropdown ({
  label,
  options,
  setToggle,
  marginTop='5px',
  defaultPick
}) {
  const [ pick, setPick ] = useState(null)
  const [ dropDown, setDropDown ] = useState(false)

  useEffect(()=>{
    if(defaultPick){
      options.map((i)=>{
        if(i.pickName===defaultPick){
          setPick(i.pickShow)
          setToggle(i.pickName)
        }
      })
    }
  },[defaultPick])

  function renderOption () {
    return <>
      {
        dropDown
          ? <UpIcon className="similar-project-dropdown-select-collapse"/>      
          : <DownIcon className="similar-project-dropdown-select-collapse" />
      }
      {
        options.map((option, i) => {
          return <div
            key={ i }
            className={
              "similar-project-dropdown-option"
              + ` option-strip-${ i % 2 ? 'grey' : 'white' }`
            }
            disabled="disabled"
            style={{
              top: `calc(100% + ${19 * i}px)`,
              opacity: !dropDown ? 0 : 0.9,
              pointerEvents: !dropDown ? 'none' : 'unset'
            }}
            onClick={ () => {
              setPick(option.pickShow)
              setDropDown(false)
              setToggle(option.pickName)
            } }
          >
            <p
              className="similar-project-dropdown-option-text"
              style={{
                marginTop: dropDown
                  ? '2.5%'
                  : '-2.5%'
              }}
            >
              { option.pickShow }
            </p>
          </div>
        }) 
      }
    </>
  }

  return <>
    <div
      className="similar-project-dropdown-container"
      style={{marginTop:marginTop}}
    >
      <span
        className="similar-project-dropdown-span"
        style={{
          top: pick ? -12 : 3,
          left: pick ? 3 : 10,
          fontSize: pick ? 8 : 11,
          pointerEvents: 'none'
        }}
      >{ label }</span>
      {
        pick 
          ? <p className="similar-project-dropdown-text">
              { pick }
            </p>
          : <></>
      }
      <div
        className="similar-project-dropdown"
        onClick={ () => {
          setDropDown(!dropDown)
        } }
      >
        {
          renderOption()
        }
      </div>
    </div>
  </>
}

export default SimilarProjectMenuDropdown
