import store from '../store'

import {
  setBoundingBox
} from '../store/actions/camera'

// https://www.movable-type.co.uk/scripts/latlong.html
import * as Cesium from 'cesium'

import {
  generateRectanglePosition,
  findPosition
} from './cesium'

let blockRectangle = null
let rectangle = null
const dragMovement = {
  start: null,
  end: null,
  bottom: -2000,
  top: 2000,
  crossWidth: {
    left: 10,
    right: 10
  }
}

/**
 * This function will handle mouse down in section feature
 * @param event the event when mouse down triggered
 * @param {Object} cesiumViewer the object of cesiumViewer
 */
export function sectionSmartSelectionMouseDownHandler(event, cesiumViewer) {
  const smartSelect = store.getState().camera.smartSelect

  if (smartSelect) {
    if (!cesiumViewer.smartSelectionStatus.start) {
      cesiumViewer.smartSelectionStatus.start = true
    }

    try {
      const ellipsoid = cesiumViewer.viewer.scene.globe.ellipsoid
  
      dragMovement.start = null
      dragMovement.end = null
  
      dragMovement.start = findPosition({
        event,
        ellipsoid,
        type: 'both'
      })
    } catch (error) {
      throw error
      // invalid position
    }

    cesiumViewer.viewer.scene.screenSpaceCameraController.enableRotate = false
    cesiumViewer.viewer.scene.screenSpaceCameraController.enableTilt = false
  }
}

/**
 * This function will handle mouse move in section feature
 * @param event the event when mouse move triggered
 * @param {Object} cesiumViewer the object of cesiumViewer
 */
export function sectionSmartSelectionMouseMoveHandler(event, cesiumViewer) {
  const smartSelect = store.getState().camera.smartSelect

  if (smartSelect && cesiumViewer.smartSelectionStatus.start) {
    const ellipsoid = cesiumViewer.viewer.scene.globe.ellipsoid
  
    event.position = event.endPosition
  
    try {
      const endPosition = findPosition({
        event,
        ellipsoid,
        type: 'long/lat'
      })
  
      dragMovement.end = endPosition
  
      const {
        west,
        south,
        east,
        north
      } = generateRectanglePosition(endPosition, dragMovement)
  
      rectangle = new Cesium.Rectangle.fromDegrees(
        west,
        south,
        east,
        north
      )
  
      if (!blockRectangle) {
        blockRectangle = cesiumViewer.viewer.entities.add({
          ellipsoid: Cesium.Ellipsoid.WGS84,
          rectangle: {
            coordinates: new Cesium.CallbackProperty(
              () => {
                return rectangle
              },
              false
            ),
            fill: false,
            extrudedHeight: 2000,
            height: -1000,
            outline: true, // height must be set for outline to display
            outlineColor: Cesium.Color.WHITE,
            outlineWidth: 1
          }
        })
      }
    } catch (error) {
      throw error
      // invalid position
      return
    }
  }
}

/**
 * This function will handle mouse up in section feature
 * @param {Object} cesiumViewer the object of cesiumViewer
 * @param viewer is the cesium viewer
 */
export function sectionSmartSelectionMouseUpHandler(cesiumViewer, viewer) {
  const smartSelect = store.getState().camera.smartSelect

  // for editor translate handle
  if (smartSelect && cesiumViewer.smartSelectionStatus.start) {
    cesiumViewer.viewer.entities.remove(blockRectangle)
    blockRectangle = null
    cesiumViewer.smartSelectionStatus.start = false

    store.dispatch(
      setBoundingBox({
        boundingBox: {
          minLongitude: Cesium.Math.toDegrees(rectangle.west),
          maxLatitude: Cesium.Math.toDegrees(rectangle.north),
          maxLongitude: Cesium.Math.toDegrees(rectangle.east),
          minLatitude: Cesium.Math.toDegrees(rectangle.south)
        }
      })
    )

    cesiumViewer.getCameraBoundingBox()
    
    cesiumViewer.viewer.scene.screenSpaceCameraController.enableRotate = true
    cesiumViewer.viewer.scene.screenSpaceCameraController.enableTilt = true
  }
}
