export function rgbToHsl(r, g, b) {
  // copy from stackoverflow
  r /= 255;
  g /= 255;
  b /= 255;
  var max = Math.max(r, g, b);
  var min = Math.min(r, g, b);
  var c = max - min;
  var hue;
  if (c == 0) {
    hue = 0;
  } else {
    switch(max) {
      case r:
        var segment = (g - b) / c;
        var shift   = 0 / 60;       // R° / (360° / hex sides)
        if (segment < 0) {          // hue > 180, full rotation
          shift = 360 / 60;         // R° / (360° / hex sides)
        }
        hue = segment + shift;
        break;
      case g:
        var segment = (b - r) / c;
        var shift   = 120 / 60;     // G° / (360° / hex sides)
        hue = segment + shift;
        break;
      case b:
        var segment = (r - g) / c;
        var shift   = 240 / 60;     // B° / (360° / hex sides)
        hue = segment + shift;
        break;
    }
  }
    
  var lum = (max + min) / 2;
  var sat;

  if (max == min) { // no saturation
      hue = 0;
      sat = 0;
  } else {
      var c = max - min; // chroma
      // saturation is simply the chroma scaled to fill
      // the interval [0, 1] for every combination of hue and lightness
      sat = c / (1 - Math.abs(2 * lum - 1));
  }
  hue = Math.round(hue * 60); // °
  sat = Math.round(sat * 100); // %
  lum = Math.round(lum * 100); // %
  return {
    h: hue,
    s: sat,
    l: lum
  };
}
