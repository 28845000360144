export const adjustMarginLeftCharts = ({...props})=> {
    let {
        selected,
        marginLeftInit = 40
    } = props
    let marginLeft = marginLeftInit
    if(selected.length!==0){
        const moreThan4 = selected.filter(function(series){
            return series.length>4
        })
        if(moreThan4.length>0){
            const longest = moreThan4.reduce(
                function (a, b) {
                    return a.length > b.length ? a : b;
                }
            )
            if(longest.length>9){
                marginLeft = marginLeftInit + 40
            } else if (longest.length>7 && longest.length<10){
                marginLeft = marginLeftInit + 30
            } else {
                marginLeft = marginLeftInit + 20
            }
        }
    }else{
        marginLeft=marginLeftInit
    }
    return marginLeft
}