export const optionsType=[
    // {
    //     value:'geology',
    //     label:'Geology',
    //     icon: 'geology.svg'
    // },
    // {
    //     value:'structure',
    //     label:'Structure',
    //     icon: 'structure.svg'
    // },
    {
        value:'geochemical',
        label:'Surface Geochemistry',
        icon: 'geochemical.svg'
    },
    {
        value: 'drillhole',
        label: 'Drillhole',
        icon: 'drillhole.svg'
    }
];

export const optionsOthersType=[
    {
        value:'geology',
        label:'Geology',
        icon: 'geology.svg'
    },
    {
        value:'structure',
        label:'Structure',
        icon: 'structure.svg'
    },
    {
        value:'geochemical',
        label:'Geochemical',
        icon: 'geochemical.svg'
    }
];

