import * as actionTypes from '../../constants/store/actionTypes/chat'

export function toggleChatbar(payload) {
  return {
    type: actionTypes.TOGGLE_CHATBAR,
    payload
  }
}

export function toggleChatbarSuccess(payload) {
  return {
    type: actionTypes.TOGGLE_CHATBAR_SUCCESS,
    payload
  }
}

export function minimizeChatbar(payload) {
  return {
    type: actionTypes.MINIMIZE_CHATBAR,
    payload
  }
}

export function resetChatbar(payload) {
  return {
    type: actionTypes.RESET_CHAT,
    payload
  }
}
