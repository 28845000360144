import React, { useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';
import { hexbin } from 'd3-hexbin';
import { 
    ChartAnalysis, 
    HeaderChart,
    TitleChart,
    WrapperChartSvg, 
    WrapperManySvg, 
    WrapperSpinner, 
    WrapperSpinnerBackground
} from '../helpers/AnalysisStyle';
import Dropdown from "../../Dropdowns/Default";
import { dropdownGroupElements, dropdownGroupElemSelect } from '../helpers/dropdownElements';
import { SELECT_SOM_MENU } from './optionsSOM';
import Spinner from '../../Loading';
import './som.css'
import { Puff } from 'react-loading-icons';

/**
 * 
 * @param {array} data is data group
 * @param {} className
 * @param {} config
 * @param {} setConfig
 * @param {} onDelete
 * @param {} onMinimize
 * @param {} openSettings
 * @param {} isLoading
 * 
 * config: {
 *      valueSelect: 'som',
 *      typeSOM: 'U-Matrix',
 *      color: d3.interpolateViridis,
 * }
 * 
 * @returns 
 */


let Som=({...props})=>{
    let {
        data,
        className,
        config,
        setConfig,
        dataReady,
        heightContainer,
        widthContainer
    }=props;
    const refSOMMatrix = useRef(null)
    const [loading,setLoading] = useState(true)
    const [configSOM,setConfigSOM]=useState({
        valueSelect: 'som',
        typeSOM: 'U-Matrix',
        color: d3.interpolateViridis,
    })
    const classNameAll='som';

    useEffect(()=>{
        if(!config.config) return;
        let newConfig={...configSOM}
        Object.entries(config.config).forEach(([key,value])=>{
            if(key in newConfig){
                newConfig[key]=value;
            }
        })
        setConfigSOM(newConfig)
    },[])

    useEffect(()=>{
        setConfig(configSOM)
    },[configSOM])

    useEffect(()=>{
        if(dataReady===false) return;
        
        d3.selectAll("."+className)
            .transition()
            .remove();

        setLoading(true)
        
        const configViewBox = {
            minX: 0,
            minY: 0,
            width: widthContainer,
            height: heightContainer
        }
        let nMap = 10

        let radius= heightContainer < widthContainer ?
            heightContainer / (nMap + 2) :
            widthContainer / (nMap + 2)
        let num_rows= nMap * 3/2 * radius + 1/2 * radius,
        num_columns= nMap * Math.sqrt(3) * radius + Math.sqrt(3)/2 * radius;

        let margin = {
            top: (heightContainer/nMap) *2, 
            right: (widthContainer/nMap)*2, 
            bottom:(heightContainer/nMap)*2, 
            left: (widthContainer/nMap)*2},
        width = num_columns - margin.left - margin.right,
        height = num_rows - margin.top - margin.bottom;

        const containerAll= d3.select(refSOMMatrix.current)
        
        const containerTest = containerAll
            .attr("viewBox", `${configViewBox.minX} ${configViewBox.minY} ${configViewBox.width} ${configViewBox.height}`)

        const svg = containerTest
        .append("g")
        .attr("class",className)
        .attr("transform", `translate(${margin.left}, ${margin.top})`);

        const hexRadius=d3.min([width/((nMap+0.5)*Math.sqrt(3)),
            height/((nMap+1/3)*1.5)]);

        const points = [];
        for (var i = 0; i < nMap; i++) {
            for (var j = 0; j < nMap; j++) {
                var k=(nMap *j)+(nMap-(i+1))
                var x = hexRadius * j * Math.sqrt(3)
                if(i%2 === 1) x += (hexRadius * Math.sqrt(3))/2
                var y = hexRadius * i * 1.5
                var z=data[k];
                points.push([x,y,z])
            }
        }

        const createHexbin= hexbin().radius(hexRadius);
        
        const color=d3.scaleSequential(configSOM.color)
        .domain([0, d3.max(data)]) ;

        svg.append("g")
        .selectAll(".hexagon")
        .data(createHexbin(points))
        .enter()
        .append("path")
        .attr("class", "hexagon")
        .attr("d", function (d) {
            return "M" + d.x + "," + d.y + createHexbin.hexagon();
        })
        .attr("stroke", "white")
        .attr("stroke-width", "0.1px")
        .attr("fill", function (d) {
            return color(d[0][2]);
        })
        .on("mouseover", mouseover)
        .on("mouseout", mouseout)
        .on("mousemove",mousemove);

        // const svg = d3
        // .select(reference)
        // .append("svg")
        // .attr("width",width + margin.left + margin.right)
        // .attr("height",height + margin.top + margin.bottom)
        // .append("g")
        // .attr("transform", `translate(${margin.left}, ${margin.top})`);

        // const x = d3.scaleLinear()
        // .domain(d3.extent(data, d => d.x))
        // .range([margin.left, width - margin.right]);
        // // svg.append("g")
        // // .attr("transform", "translate(0," + height + ")")
        // // .call(d3.axisBottom(x));
        // const y = d3.scaleLinear()
        // .domain(d3.extent(data, d => d.y))
        // .range([height - margin.bottom, margin.top]);
        // svg.append("g")
        // .call(d3.axisLeft(y));

        // const inputForHexbinFun = []
        // data.forEach(function(d) {
        //     inputForHexbinFun.push( [x(d.x), y(d.y)] )  // Note that we had the transform value of X and Y !
        // })

        // var radius=3;

        // const createHexbin = hexbin()
        // .x(d => x(d.x))
        // .y(d => y(d.y))
        // .radius(radius * width/(height-1)) // size of the bin in px
        // .extent([ [0, 0], [width, height] ])
        

        // var color = d3.scaleLinear()
        // .domain([0, 400]) // Number of points in the bin?
        // .range(["transparent",  "#69b3a2"])

        // const color=d3.scaleSequential(d3.interpolateViridis)
        // .domain([0, d3.max(createHexbin(data), d => d.length) / 2]) 

        // svg.append("g")
        // .attr("clip-path", "url(#clip)")
        // .selectAll("path")
        // .data(createHexbin(points) )
        // .enter().append("path")
        //     .attr("d", createHexbin.hexagon())
        //     .attr("transform", function(d) { return "translate(" + d.x+ "," + d.y + ")"; })
        //     .attr("fill", function(d) { return color(d.length); })
        //     .attr("stroke", "white")
        //     .attr("stroke-width", "0.1")
        // .on("mouseover", mover)
        // .on("mouseout", mout);

        // svg.append("clipPath")
        // .attr("id", "clip")
        //  .append("rect")
        // .attr("width", width)
        // .attr("height", height)

        // changeInputSOM(zValue.z, refSOMUMatrix.current);
        // changeInputSOM(data, refSOMCountMatrix.current);
        // changeInputSOM(data, refSOMClassifiedSOM.current);

        const tooltip = d3.select('.hexabin-container')
        .append("div")
        .style("opacity",0)
        .attr ("class","tooltip-hexabin")
        .style("position", "fixed")
        .style("z-index", 999999)
        
        function mouseover(d) {
            tooltip
            .transition()
            .duration(500)
            .style("opacity",1)
            d3.select(this)
            .transition()
            .duration(10)		  
            .style("fill-opacity", 0.1);
        }
        function mouseout(d) { 
            tooltip
            .transition()
            .duration(500)
            .style("opacity", 0)
            d3.select(this)
            .transition()
            .duration(1000)
            .style("fill-opacity", 1);
        };
        function mousemove(event,d){
            // tooltip
            // .html("Value: "+d3.format('.2f')(d[0][2]))
            // .style('left', event.pageX + 15 + "px")
            // .style('top', event.pageY - 10 + "px")
            d3.select(this)
            .style("cursor","pointer")

        }
        setLoading(false)

    },[
        data, 
        dataReady, 
        className,
        heightContainer,
        widthContainer
    ])
    
    const handleValueSelected = (value) => {
        let config_={...configSOM};
        config_.valueSelect=value;
        setConfigSOM(config_)
    }
    
    return(
        <ChartAnalysis className={classNameAll}>
            
            <TitleChart className="header-plot">
            Classification
            </TitleChart>

            <HeaderChart left className="row header-plot">
                {dropdownGroupElemSelect({
                    dropdownOptions: SELECT_SOM_MENU,
                    handle: handleValueSelected,
                    valDD: configSOM.valueSelect 
                })
                }
            </HeaderChart>
            <WrapperChartSvg>
                <WrapperManySvg>
                    <p className="titleSOM"> {configSOM.typeSOM} </p>
                    <svg width= {"100%"} height= {"100%"} ref={refSOMMatrix}/>
                </WrapperManySvg>
            </WrapperChartSvg>

            {loading?
            <WrapperSpinnerBackground small>
                <WrapperSpinner>
                    <Puff
                    height={heightContainer/2}
                    width={widthContainer/2}
                    fill='goldenrod'
                    stroke="darkgoldenrod"
                    speed="1"
                    fillOpacity="0.5"
                    strokeOpacity="0.5"
                    strokeWidth="2"
                />
                </WrapperSpinner>
            </WrapperSpinnerBackground>
            :
            <></>
            }
            <div style={{position:'absolute', top: '50%', width: '100%', textAlign:'center', fontSize:'30px', color:'white'}}>
                Under Development
            </div>
        </ChartAnalysis> 
    )
}

export default Som;