import * as d3 from 'd3'

/**
 * This function will get the maximum and minimimum of score grids
 * @param {Array} entities cesium entities 
 */
export function getCurrentMinAndMax({}) {
  let currentMin = 0
  let currentMax = 10
  
  // for (let i = 0; i < entities.length; i++) {
  //   const score = entities[i].properties
  //     .getValue('').rating.value

  //   // if (currentMin > score) {
  //   //   currentMin = score
  //   // }

  //   if (currentMax < score) {
  //     currentMax = score
  //   }
  // }

  return {
    currentMax,
    currentMin
  }
}

/**
 * This function will score grid to generate color
 * @param {Boolean} inverse is state of color being inversed
 * @param {Number} score is the basic score rating from 1 - 10
 * @param {Number} currentMax is the current max of entities
 * @param {Number} currentMin is the current min of entities
 * @returns {Number} the value to used for generate d3 color
 */
 export function scoreGrid({
  inverse,
  final_total,
  currentMax,
  currentMin
}) {
  let currentScore = (final_total - currentMin) / (currentMax - currentMin)

  if (!inverse) {
    return currentScore
  } else {
    return 1 - currentScore
  }
}

export function getColor(entity, {
  map,
  currentMax,
  currentMin
}) {
  let score = scoreGrid({
    inverse: map.cesiumLayer.inverse,
    final_total: entity.properties
      .getValue('').rating.value,
    currentMax,
    currentMin
  })

  if (!score) {
    score = 0
  }

  const [ red, green, blue ] = d3
    [map.colorScale](score)
    .slice(4, -1)
    .split(', ')

  return {
    red: Number(red) / 255,
    green: Number(green) / 255,
    blue: Number(blue) / 255,
    alpha: map.cesiumLayer.alpha
  }
}
