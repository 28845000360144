import React, { useState, useRef, useEffect } from 'react'

import * as d3 from 'd3'

import {
  TECHNICAL_PARAMETERS_GRAPHIC,
  NON_TECHNICAL_PARAMETERS_GRAPHIC
} from '../../../constants/Graphics/TechnicalParametersGraphic'

import './TechnicalParametersGraphic.css'

function validateGraph(parameterGraphic, graph) {
  for (let i = 0; i < parameterGraphic.TEXTS.length; i++) {
    const text = parameterGraphic.TEXTS[i]

    if (!graph[text.databaseName] && graph[text.databaseName] !== 0) {
      graph[text.databaseName] = null
    }
  }

  for (let databaseName in graph) {
    if (graph[databaseName] > 10) {
      graph[databaseName] = 10
    }
  }
}

function TechnicalParametersGraphic({
  classContainer,
  id,
  graph,
  graphDesign,
  parameterType = 'technical'
}) {
  const technicalParametersGraphicRef = useRef(null)
  // const [ tooltipDiv, setTooltipDiv ] = useState(() => {
  //   return d3
  //     .select(classContainer)
  //     .append('div')
  //       .attr('class', 'regional-geological-stratigraphy-tooltip')				
  //       .style('opacity', 0)
  // })

  let PARAMETER_GRAPHIC = TECHNICAL_PARAMETERS_GRAPHIC

  if (parameterType === 'non-technical') {
    PARAMETER_GRAPHIC = NON_TECHNICAL_PARAMETERS_GRAPHIC
  }

  validateGraph(PARAMETER_GRAPHIC, graph)
  
  const animateDuration = PARAMETER_GRAPHIC.ANIMATION_DURATION
  const animateDelay = PARAMETER_GRAPHIC.ANIMATION_DELAY

  function renderGraphics () {
    if (technicalParametersGraphicRef
    && technicalParametersGraphicRef.current) {
      d3.selectAll(`${classContainer}-tooltip`).remove()
      const {
        clientWidth,
        clientHeight
      } = technicalParametersGraphicRef.current
      
      const {
        left
      } = technicalParametersGraphicRef.current.getBoundingClientRect()

      let normalWidth = clientWidth / 4.5

      const midPoint = {
        x: clientWidth / 2,
        y: clientHeight / 2
      }

      function generateColor ({
        key
      }) {
        return `hsl(
          ${graphDesign[key].hue},
          ${graphDesign[key].saturation}%,
          ${graphDesign[key].lightness}%
        )`
      }

      function generatePolygon(score) {
        const r = normalWidth * score / 5

        let result = ''

        for (let i = 0 ; i < texts.length; i++) {
          result += midPoint.x
            + r * Math.cos(2 * Math.PI * i / texts.length - Math.PI / 2)
          result += ','
          result += midPoint.y
            + r * Math.sin(2 * Math.PI * i / texts.length - Math.PI / 2)
          result += '\n'
        }

        return result
      }

      function generateTexts () {
        const r = normalWidth + 10

        texts.forEach((text, i) => {
          let showText = text.displayName

          if (graph[text.databaseName] < 0) {
            showText += ' (-)'
          }
          
          svg
            .append('text')
            .text(showText)
            .style('font-size', `${clientWidth / 314 * 0.9}rem`)
            .attr('x', function () {
              const xPosition = midPoint.x
                + r * Math.cos(2 * Math.PI * i / texts.length - Math.PI / 2)
              if (xPosition === midPoint.x) {
                return midPoint.x
                  - this.getComputedTextLength() / 2
              } else if (xPosition > midPoint.x) {
                return midPoint.x
                  + r * Math.cos(2 * Math.PI * i / texts.length - Math.PI / 2)
              } else {
                return midPoint.x
                  + r * Math.cos(2 * Math.PI * i / texts.length - Math.PI / 2)
                  - this.getComputedTextLength()
              }
            })
            .attr('y', function () {
                return midPoint.y
                  + r * Math.sin(2 * Math.PI * i / texts.length - Math.PI / 2)
                  + 3
            })
        })
      }

      function generateGraphics () {
        let outlines = []
        const trueScores = []

        function getPointDetail () {
          texts.forEach((text, i) => {
            let score = 0
 
            if (typeof graph[text.databaseName] === 'number') {
              score = graph[text.databaseName]
            }

            trueScores.push(score)

            if (score < 0) {
              score = Math.abs(score)
            }
  
            const r = normalWidth * score / 10
            const xPoint = midPoint.x 
              + r * Math.cos(2 * Math.PI * i / texts.length - Math.PI / 2)
            const yPoint = midPoint.y
              + r * Math.sin(2 * Math.PI * i / texts.length - Math.PI / 2)
  
            outlines.push(`${xPoint},${yPoint}`)
          })
        }

        function renderFill () {
          svg
          .append('polygon')
            .attr('points', outlines
              .map(() => (`${midPoint.x}, ${midPoint.y}`))
              .join(' ')
            )
            .style('stroke', generateColor({
              key: 'outline'
            }))
            .style('stroke-width', '3px')
            .style('fill',  generateColor({
              key: 'fill'
            }))
            .style('opacity', '0.3')
            .style('transition', 'all 330ms')
            .on('mouseover', function () {
              d3.select(this)
                .style('opacity', '0.8')
            })
            .on('mouseout', function () {
              d3.select(this)
                .style('opacity', '0.3')
            })
            .transition()
            .attr('points', outlines.join(' '))
            .duration(animateDuration)
            .delay(function (_, i) {
              return i * animateDelay
            })
            .ease(d3.easeBounceInOut)
        }

        function renderPoint () {
          let tooltipDiv = d3
            .select(classContainer)
            .append('div')
              .attr('class', 'regional-geological-stratigraphy-tooltip')				
              .style('opacity', 0)
              
          outlines.forEach((outline, index) => {
            const [ xPoint, yPoint ] = outline.split(',')
            
            let pointData = svg
              .append('circle')
                .attr('cx', midPoint.x)
                .attr('cy', midPoint.y)
                .attr('r', '2px')
                .style('fill', () => {
                  if (trueScores[index] < 0) {
                    return 'red'
                  } else {
                    return generateColor({
                      key: 'point'
                    })
                  }
                })
                .style('cursor', 'pointer')
                .style('transition', 'all 330ms')

            pointData
              .transition()
              .attr('cx', xPoint)
              .attr('cy', yPoint)
              .duration(animateDuration - 330)
              .delay(function (_, i) {
                return i * animateDelay
              })
              .ease(d3.easeBounceInOut)
              .on('end', () => {
                if (trueScores[index] < 0) {
                  repeat()
                }
              })
          

            function repeat() {
              pointData
                .attr('r', 2)
                .attr('opacity', 1)
                .transition()
                .duration(2000)
                .attr('r', 8)
                .attr('opacity', 0.4)
                .on('end', repeat)
            }

            svg
              .append('circle')
                .attr('cx', xPoint)
                .attr('cy', yPoint)
                .attr('r', '5px')
                .style('cursor', 'pointer')
                .style('opacity', '0')
                .on('mouseover', function (event) {
                  d3
                    .selectAll(pointData)
                    .attr('r', '5px')
                    .style('fill', () => {
                      if (trueScores[index] < 0) {
                        return 'red'
                      } else {
                        return generateColor({
                          key: 'outline'
                        })
                      }
                    })
                    .style('stroke', generateColor({
                      key: 'point'
                    }))
                    .style('stroke-width', '2px')

                  tooltipDiv
                    .transition()
                    .duration(330)
                    .style('opacity', 1)

                  const positionLeft = event.pageX - left < 0
                    ? event.pageX - left + 520
                    : event.pageX - left

                  if (graph[texts[index].databaseName]) {
                    tooltipDiv
                      .text(
                        Math.floor(graph[texts[index].databaseName]) === graph[texts[index].databaseName]
                          ? graph[texts[index].databaseName]
                          : graph[texts[index].databaseName].toFixed(3)
                      )
                      .style('left', `${positionLeft}px`)
                      .style('top', `${
                        event.pageY
                        - technicalParametersGraphicRef
                            .current
                            .getBoundingClientRect()
                            .top
                        + 5}px`)
                      .style('color', () => {
                        if (graph[texts[index].databaseName] > 0) {
                          return 'black'
                        } else {
                          return 'red'
                        }
                      })
                  } else {
                    const notAvailables = []
                    const zeros = []

                    for (let key in graph) {
                      if (graph[key] === 0) {
                        zeros.push(key)
                      } else if (!graph[key]) {
                        for (let i = 0; i < PARAMETER_GRAPHIC.TEXTS.length; i++) {
                          if (key === PARAMETER_GRAPHIC.TEXTS[i].databaseName) {
                            notAvailables.push(PARAMETER_GRAPHIC.TEXTS[i].databaseName)
                          }
                        }
                      }
                    }

                    let messages = []

                    if (notAvailables.length > 0) {
                      messages.push(`<p class="m-0">N/A : ${notAvailables.join(', ')}</p>`)
                    }

                    if (zeros.length > 0) {
                      messages.push(`<p class="m-0">0 : ${zeros.join(', ')}</p>`)
                    }

                    tooltipDiv
                      .html(messages.join('\n'))
                      .style('left', `${positionLeft}px`)
                      .style('top', `${
                        event.pageY
                        - technicalParametersGraphicRef
                            .current
                            .getBoundingClientRect()
                            .top
                        + 5}px`)
                  }
                })
                .on('mouseout', function () {
                  d3
                    .selectAll(pointData)
                    .attr('r', '2px')
                    .style('fill', () => {
                      if (trueScores[index] < 0) {
                        return 'red'
                      } else {
                        return generateColor({
                          key: 'point'
                        })
                      }
                    })
                    .style('stroke-width', '0px')

                  tooltipDiv
                    .transition()
                    .duration(330)
                    .style('opacity', 0)
                })
          })
        }

        getPointDetail()
        renderFill()
        renderPoint()
      }

      const svg = d3.select(technicalParametersGraphicRef.current)

      const scores = PARAMETER_GRAPHIC.SCORES

      const texts = PARAMETER_GRAPHIC.TEXTS

      scores.forEach((score) => {
        svg
          .append('polygon')
            .attr('points', generatePolygon(0, texts.length))
            .style('fill', 'none')
            .style('stroke', `grey`)
            .style('stroke-width', '2px')
            .style('opacity', '0.1')
            .transition()
            .attr('points', generatePolygon(score, texts.length))
            .duration(animateDuration)
            .delay(function (_, i) {
              return i * animateDelay
            })
            .style('opacity', 0.3)
            .ease(d3.easeBounceInOut)
      })

      generateTexts()
      generateGraphics()
    }
  }

  useEffect(() => {
    // setTooltipDiv(() => {
    //   return d3
    //     .select(classContainer)
    //     .append('div')
    //       .attr('class', 'regional-geological-stratigraphy-tooltip')				
    //       .style('opacity', 0)
    // })

    if (technicalParametersGraphicRef.current) {
      renderGraphics()
    }

    return () => {
      // setTooltipDiv(null)
      d3.selectAll(`#${id} > *`).remove()
    }
  }, [parameterType])

  return <>
    <svg
      id={id}
      style={{
        width: '100%',
        height: '100%'
      }}
      ref={technicalParametersGraphicRef}
    >
    </svg>
  </>
}

export default TechnicalParametersGraphic
