import Dropdown from '../../Dropdowns/Default'
import Select from 'react-select';


export const dropdownElements = ({...props}) => {
    let {
        dropdownOptions,
        config,
        setConfig,
        val
    } = props;

    let handleToggle=(value)=>{
        let con={...config}
        con[val]=value
        setConfig(con)
    }

    return(
        <Dropdown
            label="Series"
            floatingText="Series"
            options={dropdownOptions}
            setToggle={(value)=>{handleToggle(value)}}
            defaultPick={config[val]}
            width='3.8em'
            height = '1.2em'
            initialBoxColor = 'rgb(119, 89, 13)'
            fontColor = 'white'
            marginTop='0.6em'
            position='absolute'
            className='noDrag'
        /> 
    )
}

const customStyles = {
    control: base => ({
        ...base,
        padding:0,
        height: 20,
        minHeight: 20,
        width:70,
        marginTop:3,
        borderRadius:4
    }),
    container:base=>({
        ...base,
        color:'black'
    }),
    option:(provided,state)=>({
        ...provided,
        borderBottom:'1px dotted black',
        padding:3,
        fontSize:12
    }),
    menu:base=>({
        ...base,
        width:70,
        marginTop:2
    }),
    menuList:base =>({
        ...base,
        width:70,
        maxHeight:150
    }),
    valueContainer:base=>({
        ...base,
        fontSize:12,
        marginLeft:5,
        height: 20,
        padding:0,
        fontWeight:'bold'
    }),
    input:base=>({
        ...base,
        paddingTop:0,
        fontSize:12
    }),
    dropdownIndicator:base=>({
        ...base,
        marginTop:0,
        paddingTop:0,
        height: 20,
    }),
    clearIndicator:base=>({
        ...base,
        marginTop:0,
        paddingTop:0,
        height: 20,
    }),
    indicatorSeparator:base=>({
        ...base,
        marginBottom:10,
        marginTop:2,
        height: 15,
        visibility: 'hidden'
    }),
    indicatorsContainer:base=>({
        ...base,
        height: 20,
    })
    
};

export const dropdownElemSelect = ({...props}) => {
    let {
        dropdownOptions,
        config,
        setConfig,
        val,
        valDD,
        setValDD
    } = props;

    let handleToggle=(value)=>{
        let con={...config}
        con[val]=value.value
        setConfig(con)
        setValDD(value)
    }
    
    return(
        <Select
            styles={customStyles}
            value={valDD}
            options={dropdownOptions}
            onChange={(value)=>handleToggle(value)}
            isSearchable={true}
            className='noDrag'
        />
    )
}

// export const dropdownElementsWithoutFloating = ({...props}) => {
//     let {
//         label,
//         dropdownOptions,
//         config,
//         setConfig,
//         val
//     } = props;

//     return (
//         <Dropdown
//             label={label}
//             options={dropdownOptions}
//             setToggle={(value)=>{
//                 let config_={...config};
//                 config_[val]=value;
//                 setConfig(config_)
//             }}
//             defaultPick={config[val]}
//             width='4em'
//             height = '1.2em'
//             initialBoxColor = 'rgb(119, 89, 13)'
//             fontColor = 'white'
//             marginTop='0.4em'
//             heightOptions='6.05em'
//             position='absolute'
//             className='noDrag'
//         /> 
//     )
// }

export const dropdownGroupElements = ({...props}) => {
    let {
        dropdownOptions,
        config,
        handle,
        val,
        label = "Series"
    } = props;

    return(
        <Dropdown
            label={label}
            floatingText={label}
            options={dropdownOptions}
            setToggle={(value)=>{handle(value)}}
            defaultPick={config[val]}
            width='10em'
            height = '1.2em'
            initialBoxColor = 'rgb(119, 89, 13)'
            fontColor = 'white'
            marginTop='0.65em'
            position='absolute'
            className="noDrag"
        />
    )
}

const customGroupStyles = {
    control: base => ({
        ...base,
        padding:0,
        height: 20,
        minHeight: 20,
        width:180,
        marginTop:3,
        borderRadius:4
    }),
    container:base=>({
        ...base,
        color:'black'
    }),
    option:(provided,state)=>({
        ...provided,
        borderBottom:'1px dotted black',
        padding:3,
        fontSize:12
    }),
    menu:base=>({
        ...base,
        width:180,
        marginTop:2
    }),
    menuList:base =>({
        ...base,
        width:180,
        maxHeight:150
    }),
    valueContainer:base=>({
        ...base,
        fontSize:12,
        marginLeft:5,
        height: 20,
        padding:0,
        fontWeight:'bold'
    }),
    input:base=>({
        ...base,
        paddingTop:0,
        fontSize:12
    }),
    dropdownIndicator:base=>({
        ...base,
        marginTop:0,
        paddingTop:0,
        height: 20,
    }),
    clearIndicator:base=>({
        ...base,
        marginTop:0,
        paddingTop:0,
        height: 20,
    }),
    indicatorSeparator:base=>({
        ...base,
        marginBottom:10,
        marginTop:2,
        height: 15,
        visibility: 'hidden'
    }),
    indicatorsContainer:base=>({
        ...base,
        height: 20,
    })
    
}

export const dropdownGroupElemSelect = ({...props}) => {
    let {
        dropdownOptions,
        handle,
        valDD
    } = props;

    return(
        <Select
            styles={customGroupStyles}
            value={valDD}
            options={dropdownOptions}
            onChange={(value)=>handle(value)}
            isSearchable={false}
            className='noDrag'
        />
    )
}