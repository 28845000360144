export function colorScale (score, max) {
  const minHSLA = 0
  const maxHSLA = 70

  const hue = (max - score) / max * (maxHSLA - minHSLA) + minHSLA

  return `hsla(${hue}, 100%, 50%, 0.8)`
}

export function generateFilterReference ({
  references
}) {
  const filterReference = {}

  for (let i = 0; i < references.length; i++) {
    const reference = references[i]
    
    let duplicate = false
    
    for (let key in filterReference) {
      if (
        reference.structureClass === key
      ) {
        const { data } = filterReference[key]
        if (data[reference.domain]) {
          data[reference.domain].push(reference)

          duplicate = true
        }
        break
      }
    }

    if (!duplicate) {
      if (!filterReference[reference.structureClass]) {
        filterReference[reference.structureClass] = {
          data: {
            [reference.domain]: [reference]
          }
        }
      } else {
        const { data } = filterReference[reference.structureClass]

        if (!data[reference.domain]) {
          data[reference.domain] = [reference]
        } else {
          data[reference.domain].push(reference)
        }
      }
    }
  }

  for (let structureClass in filterReference) {
    for (let domain in filterReference[structureClass].data) {
      let totalLength = 0

      filterReference[structureClass].data[domain].forEach(reference => {
        totalLength += reference.structureLength
      })

      if (!filterReference[structureClass].totalLength) {
        filterReference[structureClass].totalLength = totalLength
      } else if (filterReference[structureClass].totalLength < totalLength) {
        filterReference[structureClass].totalLength = totalLength
      }
    }
    
  }

  return filterReference
}

export function generateRoset ({
  references
}) {
  const rosetReference = {
    'Major': [],
    'Moderate': [],
    'Minor': []
  }

  for (let key in rosetReference) {
    for (let i = 5; i <= 355; i+= 10) {
      let before = i
      let after = i + 10
  
      if (after > 360) {
        after -= 360
      }
  
      rosetReference[key].push({
        after,
        before,
        references: []
      })
    }
  }

  for (let i = 0; i < references.length; i++) {
    const {
      azimuth,
      structureClass
    } = references[i]
    
    for (let key in rosetReference) {
      if (key === structureClass) {
        for (let j = 0; j < rosetReference[key].length; j++) {
          const roset = rosetReference[key][j]
          if (azimuth < 5 || azimuth >= 355) {
            roset.references.push(references[i])
            
            rosetReference[key][(j + 18)].references.push(references[i])
            break
          } else if (
            azimuth >= roset.before
            && roset.after > azimuth
          ) {
            roset.references.push(references[i])
            
            const lastIndex = (j + 18) % 36
            rosetReference[key][lastIndex].references.push(references[i])
            break
          }
        }
        break
      }
    }
    
  }

  return rosetReference
}

export function generateReferences ({
  values
}) {
  const references = []

  for (let i = 0; i < values.length; i++) {
    const properties = values[i]
    if (properties.type) {
      const azimuth = properties.azimuth
      const domain = properties.domain
      const structureClass = properties.type
      const structureLength = properties.length
  
      references.push({
        azimuth,
        domain,
        structureClass,
        structureLength
      })
    }
  }

  return references
}


