import * as d3 from'd3'; 
import * as ss from 'simple-statistics'
import './scatter.css';

const regressionLine = ({...props}) => {
    let {
        data,
        selectionX,
        selectionY,
        classNameAll,
        x,
        y,
        svg
    } = props;

    const dataRegression= data.map(d => {
        let x=d[selectionX]
        let y=d[selectionY]
        return({
            x: x,
            y: y
        })
    });

    dataRegression.sort(function (a, b) {
        return a.x - b.x || a.y - b.y;
    });
    
    let filterDataRegression = []
    dataRegression.map((d)=>{
        if(!isNaN(d.x) && !isNaN(d.y)){
            filterDataRegression.push(d)
        }
    })

    const linearRegression=ss.linearRegression(filterDataRegression.map(d => [d.x, d.y]));

    const lineRegression = ss.linearRegressionLine(linearRegression);

    const regressionPoints = () => {
        const firstX = filterDataRegression.length>0? filterDataRegression[0].x : dataRegression[0].x;
        const lastX = filterDataRegression.length>0? filterDataRegression.slice(-1)[0].x : dataRegression.slice(-1)[0].x;
        const xCoordinates = [firstX, lastX];
        return xCoordinates.map(d => ({
            x: d,
            y: lineRegression(d)
        }));
    }
    
    const line = d3.line()
    .x(d => x(d.x))
    .y(d => y(d.y));

    const tooltip = d3.select("."+classNameAll)
    .append("div")
    .style("opacity",0)
    .attr ("class","tooltip-scatter tooltip-scatter"+classNameAll)
    .style("position", "fixed")
    .style("z-index", 999999);
    
    const pathLine = svg.append("path")
    .on("mouseover", function(d) {		
        tooltip
            .transition()
            .duration(500)
            .style("opacity", 1)	
    })
    .on("mouseleave", function(d){
        tooltip
            .transition()
            .duration(500)
            .style("opacity", 0)
    })
    .on("mousemove", function(event, d){
        tooltip
            .html("y= "+ d3.format(".5f")(linearRegression.m) + "x + "+ d3.format(".5f")(linearRegression.b))
            .style('left', event.pageX +10+ "px")
            .style('top', event.pageY -30+ "px")
        d3.select(this)
            .style("cursor","pointer")	
    })  
    .attr("class", 'regressionLine')

    pathLine
    .attr("d", line({
        x: 0,
        y: 0
    },{
        x: 0,
        y: 0
    }))
    .transition()
    .duration(3000)
    .attr("d", line(regressionPoints()));
}

export const ScatterPlotFunction = ({...props}) => {
    let {
        svg,
        svgCircle,
        data,
        x,
        y,
        selectionX,
        selectionY,
        color,
        colorBorder,
        classNameAll,
        sizeCircle,
        showRegression,
        series,
        setLoading
    } = props;

    if(color===null){
        color=d3.scaleOrdinal()
        .domain(series)
        .range(d3.schemePastel2);
    }
    if(colorBorder===null){
        colorBorder=d3.scaleOrdinal()
        .domain(series)
        .range(d3.schemeSet2);
    }
    const circleScatter=svgCircle
    .selectAll('circle')
    .data(data)

    circleScatter.select('circle')
        .attr("cx", function (d) { return x(d[selectionX]); } )
        .attr("cy",  function (d) { return y(d[selectionY]); } )
    
    let circleScatterUpdate = circleScatter.enter()
    
    const circlePlot=circleScatterUpdate
    .append("circle")
    .attr('class','circleScatter')
    .attr("cx", function (d) { return x(d[selectionX]); } )
    .attr("cy",  function (d) { return y(d[selectionY]); } )
    
    circlePlot
    .attr("r", 0)
    .transition()
    .duration(1000)
    .attr("r", sizeCircle)
    .style("stroke-width",1.5)
    .style("stroke", colorBorder)
    .style("fill", color)
    // .style("stroke", function(d) {return colorBorder(selectionX);})
    // .style("fill", function(d) {return color(selectionX);})

    if(showRegression===true){
        regressionLine({
            data: data,
            selectionX: selectionX,
            selectionY: selectionY,
            classNameAll: classNameAll,
            x: x,
            y: y,
            svg: svg
        });
    }
    setLoading(false)
}