import React, { useState, useEffect } from 'react'

import LayerbarIcon from '../../../../../shares/LayerbarIcon'

import {
  addHeatMap,
  removeHeatMap
} from '../../../../../../../../apis/cesiumMap/heatmap'

import {
  ReactComponent as PinMapIcon
} from '../../../../../../../../images/Icons/pin-map.svg'

function HeatMapButton({
  map
}) {
  const [ heat, setHeat ] = useState(map.cesiumLayer.heat)
  const [ fieldAttribute, setFieldAttribute ] = useState(map.cesiumLayer.fieldAttribute)
  const [ groupBy, setGroupBy ] = useState(map.cesiumLayer.groupBy)

  useEffect(() => {
    function eventHeatHandler() {
      setHeat(map.cesiumLayer.heat)
    }
    
    function eventAttributeHandler() {
      setFieldAttribute(map.cesiumLayer.fieldAttribute)
    }

    function eventGroupHandler() {
      setGroupBy(map.cesiumLayer.groupBy)
    }

    map.cesiumLayer.subscribeEvent('heat', eventHeatHandler)
    map.cesiumLayer.subscribeEvent('fieldAttribute', eventAttributeHandler)
    map.cesiumLayer.subscribeEvent('groupBy', eventGroupHandler)

    return () => {
      map.cesiumLayer.unsubscribeEvent('heat', eventHeatHandler)
      map.cesiumLayer.unsubscribeEvent('fieldAttribute', eventAttributeHandler)
      map.cesiumLayer.unsubscribeEvent('groupBy', eventGroupHandler)
    }
  }, [])

  async function onClickHandler() {
    if (!heat) {
      await addHeatMap({
        map,
        groupBy,
        entities: map.dataSource && map.dataSource
          .entities
          .values
      })
    } else {
      await removeHeatMap({ map })
    }
  }

  if (map.geom !== 'Point') {
    return null
  }

  if (
    !fieldAttribute.pickList
      .map((list) => list.pickName)
      .includes('numerical')
  ) {
    return null
  }

  return (
    <LayerbarIcon
      isActive={!!heat}
      no-margin
    >
      <PinMapIcon
        title="Heat Map"
        onClick={onClickHandler}
      />
    </LayerbarIcon>
  )
}

export default HeatMapButton
