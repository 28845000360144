import store from '../../../store'

const COMMODITY_KEYWORDS = {
  Gold: ['Gold',], // 'Au'
  Copper: ['Copper',], // 'Cu'
  Silver: ['Silver',], // 'Ag'
  Lead: ['Lead',], // 'Ga'
  Zinc: ['Zinc',], // 'Zn'
  Molybdenum: ['Molybdenum',], // 'Mo'
  Manganesse: ['Manganesse',], // 'Mn'
  Iron: ['Iron',], // 'Fe'
  Bauxite: ['Bauxite',], // 'Al'
  Nickel: ['Nickel',], // 'Ni'
  Coal: ['Coal'],
  Tin: ['Tin']
}

export function generateKeyword() {
  const commodity = store.getState().sites.commodity

  if (!commodity) {
    return ''
  }

  const result = []

  commodity.split('_').join(' ').split(' ').forEach((element) => {
    for (let mineral in COMMODITY_KEYWORDS) {
      if (element.toLowerCase() === mineral.toLowerCase()) {
        result.push(...COMMODITY_KEYWORDS[mineral])
      }
    }
  })

  return result.join(',')
}