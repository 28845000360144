import * as actionTypes from '../../constants/store/actionTypes/gradebar'

const initialState = {
  status: false,
  minimize: false,
  weightScore: {
    'Geophysics': {
      databaseName: 'geophysics',
      'Airborne Magnetic': {
        databaseName: 'geophysics_magnetic',
        value: 1,
        max: 5,
        min: 0
      },
      'Airborne Radiometric K': {
        databaseName: 'geophysics_radiometric_k',
        value: 1,
        max: 5,
        min: 0
      },
      'Airborne Radiometric K Th': {
        databaseName: 'geophysics_radiometric_k_th',
        value: 1,
        max: 5,
        min: 0
      },
      'Airborne Radiometric U': {
        databaseName: 'geophysics_radiometric_u',
        value: 1,
        max: 5,
        min: 0
      },
      'Ground magnetic': {
        databaseName: 'geophysics_ground_magnetic',
        value: 1,
        max: 5,
        min: 0
      },
      'IP Chargeability': {
        databaseName: 'geophysics_ip_chargeability',
        value: 1,
        max: 5,
        min: 0
      },
      'IP Resistivity': {
        databaseName: 'geophysics_ip_resistivity',
        value: 1,
        max: 5,
        min: 0
      },
      'CSAMT': {
        databaseName: 'geophysics_csamt',
        value: 1,
        max: 5,
        min: 0
      },
      'Gravity': {
        databaseName: 'geophysics_gravity',
        value: 1,
        max: 5,
        min: 0
      }
    },
    'Geochemistry': {
      databaseName: 'geochemistry',
      'Average grade': {
        databaseName: 'average_grade',
        value: 1,
        max: 5,
        min: 0
      },
      'Footprint area': {
        databaseName: 'footprint',
        value: 1,
        max: 5,
        min: 0
      }
    },
    'Structural geology': {
      databaseName: 'lineament',
      'Lineament density': {
        databaseName: 'line_density',
        value: 1,
        max: 5,
        min: 0
      },
      'Lineament pattern': {
        databaseName: 'line_pattern',
        value: 1,
        max: 5,
        min: 0
      }
    },
    'Mineral occurrences': {
      databaseName: 'mineral_occurrences',
      'Existing mine': {
        databaseName: 'occu_total_existing_mine',
        value: 1,
        max: 5,
        min: 0
      },
      'Total numbers': {
        databaseName: 'occu_total_numbers',
        value: 1,
        max: 5,
        min: 0
      },
      'Total resources': {
        databaseName: 'occu_total_resources',
        value: 1,
        max: 5,
        min: 0
      }
    },
    'Geology and mineralization': {
      databaseName: 'geology',
      'Intrusive age': {
        databaseName: 'geol_intrusive_age',
        value: 1,
        max: 5,
        min: 0
      },
      'Intrusive type': {
        databaseName: 'geol_intrusive_type',
        value: 1,
        max: 5,
        min: 0
      }
    },
    'Forestry': {
      databaseName: 'forestry',
      'Utility status': {
        databaseName: 'forestry_status',
        value: 1,
        max: 5,
        min: 0
      }
    },
    'Hazard and Disaster': {
      databaseName: 'hazard_and_disaster',
      'Tsunami': {
        databaseName: 'hazard_tsunami',
        value: 1,
        max: 5,
        min: 0
      },
      'Landslides': {
        databaseName: 'hazard_landslide',
        value: 1,
        max: 5,
        min: 0
      },
      'Earthquakes': {
        databaseName: 'hazard_earthquake',
        value: 1,
        max: 5,
        min: 0
      },
      'Volcanic eruption': {
        databaseName: 'hazard_volcanic_eruption',
        value: 1,
        max: 5,
        min: 0
      }
    },
    'WIUP': {
      databaseName: 'wiup',
      'WIUP Expiration': {
        databaseName: 'wiup_expiration',
        value: 1,
        max: 5,
        min: 0
      },
      'WIUP Type': {
        databaseName: 'wiup_type',
        value: 1,
        max: 5,
        min: 0
      }
    },
    'Accessibility': {
      databaseName: 'accessibility',
      'Road': {
        databaseName: 'accessibility_road',
        value: 1,
        max: 5,
        min: 0
      },
      'Port': {
        databaseName: 'accessibility_jetty',
        value: 1,
        max: 5,
        min: 0
      },
      'Coastlines': {
        databaseName: 'accessibility_coastline',
        value: 1,
        max: 5,
        min: 0
      },
      'Power plant': {
        databaseName: 'accessibility_power_generator',
        value: 1,
        max: 5,
        min: 0
      },
      'Fuel station': {
        databaseName: 'accessibility_gas_station',
        value: 1,
        max: 5,
        min: 0
      },
      'Settlement area': {
        databaseName: 'accessibility_settlement',
        value: 1,
        max: 5,
        min: 0
      },
      'Oil and gas depot': {
        databaseName: 'accessibility_oil_depot',
        value: 1,
        max: 5,
        min: 0
      },
      'Water Body': {
        databaseName: 'accessibility_water_body',
        value: 1,
        max: 5,
        min: 0
      }
    }
  }
}

function gradebarState(state = initialState, { type, payload }) {
  const { status, minimize } = state

  switch (type) {
    case actionTypes.TOGGLE_GRADEBAR:
      return {
        ...state,
        status: !status,
        minimize: false
      }
    case actionTypes.TOGGLE_MINIMIZE_GRADEBAR:
      return {
        ...state,
        minimize: !minimize
      }
    case actionTypes.CHANGE_GRADE_WEIGHT:
      return {
        ...state,
        weightScore: {
          ...state.weightScore,
          [payload.category]: {
            ...state.weightScore[payload.category],
            [payload.attribute]: {
              ...state.weightScore[payload.category][payload.attribute],
              value: payload.value
            }
          }
        }
      }
    case actionTypes.SET_WEIGHT_SCORE:
      return {
        ...state,
        weightScore: payload.weightScore
      }
    case actionTypes.RESET_GRADEBAR:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default gradebarState
