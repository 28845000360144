import {
  call,
  fork,
  put,
  putResolve,
  select,
  take,
  takeLatest
} from 'redux-saga/effects'

import * as Cesium from 'cesium'
import axios from 'axios'

import CesiumMap from '../../packages/cesium-add-on/cesiumMap'

import * as sitesActionTypes from '../../constants/store/actionTypes/sites'
import * as catalogActionTypes from '../../constants/store/actionTypes/catalog'

import {
  changeCommodityError,
  changeCommoditySuccess,
  changeSiteMenuCategoryError,
  changeSiteMenuCategorySuccess,
  fetchProspectAreaSites,
  fetchProspectAreaSitesError,
  fetchProspectAreaSitesSuccess,
  selectSiteError,
  selectSiteSuccess,
  visitSiteSuccess,
  visitSiteBackSuccess,
  visitSiteForwardSuccess,
  visitSiteHomeSuccess,
  visitSiteError,
  changeRankingPreferencesSuccess,
  changeRankingPreferencesError,
  resetRankingPreferences
} from '../actions/sites'

import {
  toggleSitebarSuccess
} from '../actions/sitebar'

import {
  setProspectMap
} from '../actions/camera'

import {
  toggleRankbar
} from '../actions/rankbar'

import {
  toggleSitebar
} from '../actions/sitebar'

import * as catalogActions from '../actions/catalog'
import * as cameraActions from '../actions/camera'
import * as catalogSearchActions from '../actions/catalogSearch'
import * as displayActions from '../actions/display'

import {
  startAction,
  stopAction,
  refreshActionStart,
  refreshActionStop
} from '../actions/uiActions'

import * as serverAPI from '../../apis/server'
import cesiumViewer from '../../apis/cesiumViewer'
import {
  getGeologyColor,
  loadGeologyGeoJSON,
  setGeologyColor
} from '../../apis/cesiumMap/geological'
import { loadGeoJson } from '../../apis/cesiumMap/geoJson'
import {
  checkBboxIntersect,
  loadRaster
} from '../../apis/cesiumMap/raster'

import {
  flyTo
} from '../../apis/cesium'

export function* fetchProspectAreaSaga({ type, payload }) {
  const refreshing = payload ? payload.refreshing : false
  
  try {
    yield put(
      refreshing 
        ? refreshActionStart(type)
        : startAction(type)
    )

    const { commodity } = yield select((state) => ({
      commodity: state.sites.commodity
      // map: state.camera.prospectMap
    }))

    if(commodity){
      const {
        sites
      } = yield call(serverAPI.fetchProspectArea, {
        cancelToken: payload.cancelToken
      })
  
      // if (!map) {
      //   // const newMap = new CesiumMap({
      //   //   id: 0,
      //   //   category: 'Smart View',
      //   //   subCategory: null,
      //   //   templateActive: false
      //   // })
  
      //   // yield call(newMap.setGeojson, geojson)
      //   // yield call(newMap.generateDataSource)
      //   // yield call(newMap.loadDataSource, {
      //   //   cesiumViewer
      //   // })
  
      //   const {
      //     smartView,
      //     smartSelect,
      //     smartFreeze
      //   } = yield select((state) => (state.camera))
  
      //   if (!smartView && !smartSelect && !smartFreeze) {
      //     // newMap.dataSource.show = false
      //   }
  
      //   // yield put(setProspectMap({
      //   //   map: newMap
      //   // }))
      // } else {
      //   // yield call(map.setGeojson, geojson)
      //   yield call(map.unloadDataSource)
      //   yield call(map.generateDataSource)
      //   yield call(map.loadDataSource, {
      //     cesiumViewer
      //   })
      // }
  
      yield putResolve(fetchProspectAreaSitesSuccess({
        sites
      }))
    }
  } catch (error) {
    yield put(fetchProspectAreaSitesError(error))
  } finally {
    yield put(
      refreshing
        ? refreshActionStop(type)
        : stopAction(type)
    )
  }
}

export function* watchFetchProspectArea() {
  yield takeLatest(sitesActionTypes.FETCH_PROSPECT_AREA_SITES, fetchProspectAreaSaga)
}

export function* changeCommoditySaga({ type, payload }) {
  try {
    const { refreshing } = payload

    yield put(
      refreshing 
        ? refreshActionStart(type)
        : startAction(type)
    )

    const {
      commodity
    } = yield select(({ sites }) => ({
      commodity: sites.commodity
    }))

    if (payload.commodity) {
      yield call(cesiumViewer.getCameraBoundingBox)

      if (payload.refreshing || commodity !== payload.commodity) {   
        yield putResolve(catalogActions.removeAllMap({}))
        yield take(catalogActionTypes.REMOVE_ALL_MAP_SUCCESS)
        yield putResolve(resetRankingPreferences())

        yield put(toggleRankbar({
          toggle: true
        }))

        yield putResolve(catalogActions.fetchListMap())
  
        yield put(changeCommoditySuccess({
          commodity: payload.commodity
        }))

        const cancelToken = axios.CancelToken.source()

        yield put(fetchProspectAreaSites({ cancelToken }))
        yield put(toggleSitebarSuccess({
          toggle: false,
          minimize: false
        }))
      }
    }
  } catch (error) {
    yield put(changeCommodityError(error))
  } finally {
    yield put(
      payload.refreshing
        ? refreshActionStop(type)
        : stopAction(type)
    )
  }
}

export function* changeRankingPreferencesSaga({ type, payload }) {
  try {
    const { refreshing } = payload

    yield put(
      refreshing 
        ? refreshActionStart(type)
        : startAction(type)
    )

    yield putResolve(changeRankingPreferencesSuccess(payload))

    const {
      sites
    } = yield call(serverAPI.fetchProspectArea, {
      cancelToken: payload.cancelToken
    })

    yield putResolve(fetchProspectAreaSitesSuccess({
      sites
    }))
  } catch (error) {
    // console.log(error)
    yield put(changeRankingPreferencesError(error))
  } finally {
    yield put(
      payload.refreshing
        ? refreshActionStop(type)
        : stopAction(type)
    )
  }
}

export function* watchChangeCommodity () {
  yield takeLatest(sitesActionTypes.CHANGE_COMMODITY, changeCommoditySaga)
  yield takeLatest(sitesActionTypes.CHANGE_RANKING_PREFERENCES, changeRankingPreferencesSaga)
}

export function* getProspectDetail({ content }) {
  try {
    // const geologyGeojson = yield call(serverAPI.fetchProspectGeologicalMapData, {
    //   content: content,
    //   intersect: true,
    //   fetchProgress: () => {},
    //   fetchFinish: () => {}
    // })

    // const geologicalMap = new CesiumMap({
    //   geojson: geologyGeojson,
    //   id: 999999999,
    //   name: 'Geology - ' + content.name,
    //   category: 'geological',
    //   entityName: 'GRID_Summary - Geology - ' + content.name
    // })

    // const geologicalDatasource = yield call(async () => {
    //   return await loadGeoJson({
    //     map: geologicalMap,
    //     name: geologicalMap.entityName,
    //     geojson: geologyGeojson,
    //     colorCb: getGeologyColor
    //   })
    // })

    // cesiumViewer.viewer.dataSources.add(geologicalDatasource)

    // yield put(displayActions.addToDisplay({
    //   map: geologicalMap
    // }))
    
    // const geophysicsRasterData = yield call(serverAPI.fetchGeophysicsRasterData)

    // for (let i = 0; i < geophysicsRasterData.length; i++) {
    //   const bbox = {
    //     maxlatitude: content.maxlatitude,
    //     minlatitude: content.minlatitude,
    //     maxlongitude: content.maxlongitude,
    //     minlongitude: content.minlongitude
    //   }

    //   const rasterData = geophysicsRasterData[i]

    //   const intersectStatus = yield call(checkBboxIntersect, {
    //     bbox,
    //     imageBbox: rasterData.rectangle
    //   })

    //   if (intersectStatus) {
    //     const geophysicsMap = new CesiumMap({
    //       geojson: null,
    //       id: 999999999 + i,
    //       name: 'Geophysics - ' + rasterData.title + ' - ' + content.name,
    //       category: 'geophysics',
    //       entityName: 'GRID_Summary - Geophysics - ' + rasterData.title + ' - ' + content.name,
    //       raster: rasterData,
    //     })
  
    //     const geophysicsDataSource = yield call(loadRaster, {
    //       name: geophysicsMap.entityName,
    //       data: rasterData,
    //       bbox,
    //       intersect: true,
    //       url: `http://18.191.16.68/api/geofix/raster_texture/wsenTexture/${rasterData.filename}`
    //     })

    //     if (geophysicsDataSource) {
    //       cesiumViewer.viewer.dataSources.add(geophysicsDataSource)
    
    //       yield put(displayActions.addToDisplay({
    //         map: geophysicsMap
    //       }))
    //     }
    //   }
    // }

    // const geochemicalGeojson = yield call(serverAPI.fetchProspectGeochemicalMapData, {
    //   content: content,
    //   intersect: true,
    //   fetchProgress: () => {},
    //   fetchFinish: () => {}
    // })

    // if (geochemicalGeojson.area) {
    //   for (let geochemicalType in geochemicalGeojson.area) {
    //     for (let i = 0; i < geochemicalGeojson.area[geochemicalType].length; i++) {
    //       const geochemicalData = geochemicalGeojson.area[geochemicalType][i]

    //       for (let j = 0; j < geochemicalData.elements.length; j++) {
    //         const geochemicalMap = new CesiumMap({
    //           geojson: geochemicalData.elements[j].geojson,
    //           id: 999999999 + i + j,
    //           name: geochemicalType + ' - ' + geochemicalData.elements[j].element + ' - ' + content.name,
    //           category: 'geochemical',
    //           entityName: 'GRID_Summary - Geochemical - ' + geochemicalType + ' - ' + geochemicalData.elements[j].element + ' - ' + content.name
    //         })

    //         const geochemicalDataSource = yield call(loadGeoJson, {
    //           map: geochemicalMap,
    //           name: geochemicalMap.entityName,
    //           geojson: geochemicalMap.geojson
    //         })

    //         cesiumViewer.viewer.dataSources.add(geochemicalDataSource)
      
    //         yield put(displayActions.addToDisplay({
    //           map: geochemicalMap
    //         }))
    //       }
    //     }
    //   }
    // }
  } catch (error) {
    throw error
  }
}

export function* removeProspectDetail() {
  // const { maps } = yield select((state) => ({
  //   maps: state.display.maps
  // }))

  // for (let i = 0; i < maps.length; i++) {
  //   const map = maps[i]

  //   if (map.entityName.includes('GRID_Summary')) {
  //     yield putResolve(displayActions.removeFromDisplay({
  //       map
  //     }))

  //     const prospectDataSources = cesiumViewer.viewer.dataSources.getByName(
  //       map.entityName
  //     )
    
  //     if (prospectDataSources) {
  //       prospectDataSources.forEach(dataSource => {
  //         cesiumViewer.viewer.dataSources.remove(dataSource, true)
  //       })
  //     }
  //   }
  // }
}

export function* selectSiteSaga({ type, payload }) {
  try {
    const { refreshing } = payload

    yield put(
      refreshing 
        ? refreshActionStart(type)
        : startAction(type)
    )

    const {
      site
    } = yield select(({ sites }) => ({
      site: sites.selected.site
    }))

    if (!payload.site) {
      yield putResolve(catalogActions.removeProjectMap({}))
      yield take(catalogActionTypes.REMOVE_PROJECT_MAP_SUCCESS)
      yield call(removeProspectDetail, {})

      const entities = cesiumViewer.viewer.dataSources.getByName(
        'boundingGridBox'
      )
      
      if (entities && entities.length) {
        cesiumViewer.viewer.dataSources.remove(entities[0], true)
      }

      yield put(selectSiteSuccess({
        site: null,
        content: null,
        category: {
          databaseName: 'overview',
          displayName: 'Overview'
        }
      }))

      yield put(toggleSitebar({
        toggle: false,
        minimize: false
      }))
    } else if (!site || site.id === null || site.id === undefined || site.id !== payload.site.id) {
      yield putResolve(catalogActions.removeProjectMap({}))
      yield take(catalogActionTypes.REMOVE_PROJECT_MAP_SUCCESS)
      yield call(removeProspectDetail, {})

      const findCategory = { databaseName: 'overview', displayName: 'Overview' }

      const content = yield call(serverAPI.fetchSiteDetail, {
        site: payload.site,
        bin_size: payload.bin_size,
        filter_wkt: payload.filter_wkt
      })

      yield putResolve(selectSiteSuccess({
        site: content,
        content,
        category: findCategory
      }))

      // refactor it later
      // const entities = cesiumViewer.viewer.dataSources.getByName(
      //   'boundingGridBox'
      // )

      // if (entities && entities.length) {
      //   cesiumViewer.viewer.dataSources.remove(entities[0], true)
      // }

      // const {
      //   minlongitude,
      //   minlatitude,
      //   maxlongitude,
      //   maxlatitude
      // } = content

      // const boundingBox = new Cesium.CustomDataSource('boundingGridBox')

      // boundingBox.entities.add({
      //   polyline: new Cesium.PolylineGraphics({
      //     positions: new Cesium.Cartesian3.fromDegreesArray([
      //       minlongitude,
      //       minlatitude,
      //       maxlongitude,
      //       minlatitude,
      //       maxlongitude,
      //       maxlatitude,
      //       minlongitude,
      //       maxlatitude,
      //       minlongitude,
      //       minlatitude
      //     ]),
      //     material: Cesium.Color.RED,
      //     clampToGround: true
      //   })
      // })

      const {
        maps
      } = yield select(({ display }) => ({
        maps: display.maps
      }))

      maps.forEach((map) => {
        const dataSource = cesiumViewer.viewer.dataSources.getByName(
          map.entityName
        )

        if (dataSource) {
          dataSource.forEach((data) => {
            data.show = false
          })
        }
      })

      // cesiumViewer.viewer.dataSources.add(boundingBox)

      yield call(flyTo, {
        area: content,
        actions: toggleSitebar({
          toggle: true,
          minimize: false
        })
      })

      yield call(getProspectDetail, { content })
    }
  } catch (error) {
    // console.log(error)
    yield put(selectSiteError(error))
  } finally {
    yield put(
      payload.refreshing
        ? refreshActionStop(type)
        : stopAction(type)
    )
  }
}

export function* watchSelectSiteSaga () {
  yield takeLatest(sitesActionTypes.SELECT_SITE, selectSiteSaga)
}

export function* changeSiteMenuCategorySaga({ type, payload }) {
  try {
    const { refreshing, menu } = payload

    yield put(
      refreshing
        ? refreshActionStart(type)
        : startAction(type)
    )

    const {
      category,
      site
    } = yield select(({ sites }) => ({
      category: sites.selected.menu.category,
      site: sites.selected.site
    }))

    if (site && category.databaseName !== menu.databaseName) {
      // const content = yield call(serverAPI.fetchSiteDetail, {
      //   siteId: site.id,
      //   category: menu.databaseName
      // })

      yield put(changeSiteMenuCategorySuccess({
        // content,
        category: menu
      }))
    }
  } catch (error) {
    yield put(changeSiteMenuCategoryError(error))
  } finally {
    yield put(
      payload.refreshing
        ? refreshActionStop(type)
        : stopAction(type)
    )
  }
}

export function* watchChangeSiteMenuCategorySaga() {
  yield takeLatest(sitesActionTypes.CHANGE_SITE_MENU_CATEGORY, changeSiteMenuCategorySaga)
}

export function* visitSite ({ type, payload }) {
  try {
    const { refreshing } = payload

    yield put(
      refreshing 
        ? refreshActionStart(type)
        : startAction(type)
    )

    const {
      site
    } = yield select(({ sites }) => ({
      site: sites.selected.site
    }))

    if (
      site
      && payload.site
      && payload.site.id !== site.id
    ) {
      // yield putResolve(catalogActions.removeAllMap({}))
      // yield take(catalogActionTypes.REMOVE_ALL_MAP_SUCCESS)

      const findCategory = { databaseName: 'overview', displayName: 'Overview' }

      const content = yield call(serverAPI.fetchSiteDetail, {
        site: payload.site
      })

      if (type === sitesActionTypes.VISIT_SITE) {
        yield put(visitSiteSuccess({
          site: payload.site,
          content,
          category: findCategory
        }))
      } else if (type === sitesActionTypes.VISIT_SITE_FORWARD) {
        yield put(visitSiteForwardSuccess({
          site: payload.site,
          content,
          category: findCategory
        }))
      } else if (type === sitesActionTypes.VISIT_SITE_BACK) {
        yield put(visitSiteBackSuccess({
          site: payload.site,
          content,
          category: findCategory
        }))
      } else if (type === sitesActionTypes.VISIT_SITE_HOME) {
        yield put(visitSiteHomeSuccess({
          site: payload.site,
          content,
          category: findCategory
        }))
      }

      yield call(flyTo, {
        area: payload.site,
        actions: toggleSitebar({
          toggle: true,
          minimize: false
        })
      })
    }
  } catch (error) {
    yield put(visitSiteError(error))
  } finally {
    yield put(
      payload.refreshing
        ? refreshActionStop(type)
        : stopAction(type)
    )
  }
}

export function* watchVisitSite () {
  yield takeLatest(sitesActionTypes.VISIT_SITE, visitSite)
  yield takeLatest(sitesActionTypes.VISIT_SITE_BACK, visitSite)
  yield takeLatest(sitesActionTypes.VISIT_SITE_FORWARD, visitSite)
  yield takeLatest(sitesActionTypes.VISIT_SITE_HOME, visitSite)
}

const sagaHelpers = [
  fork(watchFetchProspectArea),
  fork(watchChangeCommodity),
  fork(watchSelectSiteSaga),
  fork(watchChangeSiteMenuCategorySaga),
  fork(watchVisitSite)
]

export default sagaHelpers