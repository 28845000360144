// utm settings on footer table preview
import InputNumber from 'rc-input-number';
import { Button } from '../PopUpModal/partials';
import { ListCheckbox } from '../../../components/Charts/helpers/AnalysisStyle';
import Checkbox from '../../../components/Checkbox';
import RangeSlider from '../../../components/Sliders/RangeSlider';


const handleChangeNumberUtm=(ev, utmZone, setUTMZone)=>{
    const obj={...utmZone}
    obj.number=ev
    setUTMZone(obj)
}

const handleSetUtm=(reg,utmZone,setUTMZone)=>{
    const obj={...utmZone}
    obj.region=reg
    setUTMZone(obj)
}

export const inputUTM = (utmZone,setUTMZone) =>{
    return(
        <div style={{
            border:'1px solid red', 
            display: 'flex', 
            flexDirection:'row', 
            padding:'3px',
            borderRadius:'4px', 
            height: 'fit-content'
            }}
        >
            <span style={{marginTop:'10px'}}>
                UTM (WGS84) 
            </span>
            <InputNumber 
                value={utmZone.number} 
                min={1} 
                max={60}
                style={{ width: 60, marginLeft:'10px', marginTop:'3px'}}
                onChange={(e)=>handleChangeNumberUtm(e,utmZone, setUTMZone)}
                required
            />
            <form 
                style={{width:'50px', marginLeft:'10px'}} 
                onChange={(val)=>handleSetUtm(val.target.value,utmZone,setUTMZone)}
            >
                <input type="radio" name="region" id="north" value="N" />N<br/>   
                <input type="radio" name="region" id="south" value="S" />S<br/>  
            </form>
        </div>
    )
} 

// search header on dropdown table
export const searchValue=(i,headerData)=>{
    const regex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g
    const value=[]
    headerData.map((a)=>{
        if(a.toLowerCase().replace(regex,'').replace(/ |_/g,'').includes(i.toLowerCase().replace(regex,'').replace(/ |_/g,''))){
            value.push(a)
        }else if(i==='hole_id'){
            if(a.toLowerCase().replace(regex,'').replace(/ |_/g,'').includes('id')){
                value.push(a)
            }
        }else if(i==='total_depth'){
            if(a.toLowerCase().replace(regex,'').replace(/ |_/g,'').includes('depth')){
                value.push(a)
            }
        }else if(i==='easting'){
            if(a.toLowerCase().replace(regex,'').replace(/ |_/g,'').includes('east')){
                value.push(a)
            }
        }else if(i==='northing'){
            if(a.toLowerCase().replace(regex,'').replace(/ |_/g,'').includes('north')){
                value.push(a)
            }
        }else if(i==='at_depth'){
            if(a.toLowerCase().replace(regex,'').replace(/ |_/g,'').includes('at')){
                value.push(a)
            }
        }else if(i==='azimuth'){
            if(a.toLowerCase().replace(regex,'').replace(/ |_/g,'').includes('azim')){
                value.push(a)
            }
        }else if(i==='x'){
            if(a.toLowerCase().replace(regex,'').replace(/ |_/g,'').includes('east')){
                value.push(a)
            } else if(a.toLowerCase().replace(regex,'').replace(/ |_/g,'').includes('long')){
                value.push(a)
            }
        }else if(i==='y'){
            if(a.toLowerCase().replace(regex,'').replace(/ |_/g,'').includes('north')){
                value.push(a)
            } else if(a.toLowerCase().replace(regex,'').replace(/ |_/g,'').includes('latitude')){
                value.push(a)
            }
        }else if(i==='z'||i==='altitude'){
            if(a.toLowerCase().replace(regex,'').replace(/ |_/g,'').includes('elev')){
                value.push(a)
            } else if(a.toLowerCase().replace(regex,'').replace(/ |_/g,'').includes('depth')){
                value.push(a)
            } else if(a.toLowerCase().replace(regex,'').replace(/ |_/g,'').includes('altitude')){
                value.push(a)
            }
        }else if(i==='value'){
            if(a.toLowerCase().replace(regex,'').replace(/ |_/g,'').includes('value')){
                value.push(a)
            } else if(a.toLowerCase().replace(regex,'').replace(/ |_/g,'').includes('val')){
                value.push(a)
            }
        }
    })
    if(value.length!==0){
        return({
            label: value[0],
            value: value[0]
        })
    } else {
        return null
    }
}

//check UTM actived or not
export const checkActiveUTM = (utmZone) => {
    let north = document.getElementById('north')
    let south = document.getElementById('south')
    if(north!==null && south !==null) {
        if(utmZone.region.toLowerCase()==='n'){
            north.checked = true
            south.checked = false
        } else {
            south.checked = true
            north.checked = false

        }
    }
}

//button
export const buttonViewTable=(
    nextStep,
    type,
    valuesHeader,
    utmZone,
    )=>{
    let content= (
        <Button onClick={nextStep}>
            <label>
                Next
            </label>
        </Button>
    )
    if(Object.keys(valuesHeader).length!==0){
        if(type==="Drillhole"){
            if(
                valuesHeader['x']!==null && 
                valuesHeader['y']!==null && 
                valuesHeader['z']!==null && 
                valuesHeader['value']!==null
            ){
                return content
            }
        }else{
            if(
                utmZone.active &&
                valuesHeader['easting']!==null && 
                valuesHeader['northing']!==null  
                // data.elements.length>0
            ){
                return content
            }else if(
                !utmZone.active &&
                valuesHeader['longitude']!==null && 
                valuesHeader['latitude']!==null 
                // data.elements.length>0
            ){
                return content
            } else {
                return <></>
            }
        }
    }else {
        return <></>
    }
}

//checkbox elements
export const checkboxElements = (
    headerData,
    checkElements,
    setCheckElements,
    data,
    setData,
    autocheck,
    setAutocheck,
    // setAutoCheckSurface
    ) => {
    const col1 = [], col2 = [], col3 = [], col4=[]
    headerData.map((item,i)=>{
        if(i<headerData.length/4){
            col1.push(item)
        } else if (i>=headerData.length/4 && i < headerData.length /2){
            col2.push(item)
        } else if (i>=headerData.length/2 && i < headerData.length*3 /4){
            col3.push(item)
        } else {
            col4.push(item)
        }
    })
    const mapColumn=[col1,col2,col3,col4]

    const toggleCheck = ({...props}) => {
        let {
            inputName,
            config,
            setConfig
        } = props;
        const newState = {...config};
        newState[inputName]= !config[inputName];
        setConfig(newState)

        let checked=[]
        headerData.map((head)=>{
            if(newState[head]){
                checked.push(head)
            }
        })
        let arr ={...data}
        arr.elements = checked
        setData(arr)
    };


    return(
        <div>
            <div style={{color:'black'}}>
                Check for the elements only:
                <input 
                    type='checkbox'
                    onChange={(e)=>{
                        // setAutoCheckSurface(!autocheck)
                        setAutocheck(!autocheck)
                    }}
                    checked={autocheck}
                    style={{marginLeft:'2em'}}
                />
                <span style={{fontSize:'10px'}}>
                    Auto Check Elements
                </span>
            </div>
            <div style={{
                display:'flex', 
                justifyContent:'space-evenly',
                backgroundColor:'dimgray',
                fontSize:'10px',
                padding:'14px',
                borderRadius:'8px',
                marginTop:'8px',
                marginLeft:'4px',
                height:'170px',
                overflowY: 'scroll'
                }}
            >
                {
                    mapColumn.map((col,i)=>
                    <div style={{marginRight:'4px'}} key={`${i}col`}>
                        {
                            col.map((item,idx)=>
                                <ListCheckbox key={`${item}-checkHeader`} >
                                    <Checkbox
                                        key={`${idx}-checkHeader`} 
                                        type="checkbox"
                                        className="checkSelector"
                                        name={item}
                                        onChange={() => toggleCheck({
                                            inputName: item,
                                            config: checkElements,
                                            setConfig: setCheckElements
                                        })}
                                        checked={checkElements[item]}
                                        value={item}
                                        title={item}
                                        />
                                    {item}
                                </ListCheckbox>
                            )
                        }
                    </div>
                    )
                }
            </div>
            <div style={{color:'black', fontSize:'10px', marginTop:'4px'}}>
            Checked elements are used to be displayed on analysis charts.
            </div>
        </div>
    )
}

//filter settings on 3d model
export const filterModel = (
    filterValue,
    setFilterValue,
    filterRange
) => {
    return(
        <div style={{
            color:'black', 
            fontSize:'16px', 
            marginTop:'14px', 
            marginLeft:'1.5em'
        }}>
            Filter data setting
            <div style={{border:'solid gray 1px'}}>
                <div style={{color:'black', fontSize:'12px', marginTop:'8px'}}>
                    Distance Interpolation 
                    <div className="row" style={{marginLeft:'4em'}}>
                        <div className="column">
                            <input 
                                type="text" 
                                style={{
                                    width:'50px', 
                                    fontSize:'12px', 
                                    fontFamily:'abel'
                                }} 
                                value={filterValue.distance} 
                                onChange={(ev)=>{
                                    const re = /^[0-9\b]+$/
                                    if(
                                        ev.target.value === "" || 
                                        re.test(ev.target.value)
                                    ){
                                        let v = {...filterValue}
                                        v.distance=Number(ev.target.value)
                                        setFilterValue(v)
                                    }
                                }}/>
                        </div>
                        <div className="column" style={{marginLeft:'0.5em'}}>
                            m
                        </div>
                    </div>
                </div>
                <div 
                    style={{
                        color:'black', 
                        fontSize:'12px', 
                        marginTop:'20px', 
                        marginBottom:'10px'
                        }} 
                    className="row"
                >
                    <div className="column" style={{marginLeft:'1em', textAlign:'left'}}>
                        <div>min</div>
                        <div>Range Value</div>
                        <div>max</div>
                    </div>
                    <div className="column" style={{marginLeft:'1em'}}>
                        <RangeSlider
                        sliderState={filterRange}
                        lineWidth = '4em'
                        range = {{
                            max: filterValue.max,
                            min: filterValue.min
                        }}
                        onChangeHandler = {(val) => {
                            let v = {...filterValue}
                            v.max = val.max
                            v.min = val.min
                            setFilterValue(v)
                        }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}