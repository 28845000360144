import React, { useEffect } from 'react'

import { connect } from 'react-redux'

// import iziToast from 'izitoast'

function Loading({ ui }) {
  useEffect(() => {
    if (ui) {
      // const { actions, refreshing } = ui.loader
      // actions.forEach((action) => {
      //   iziToast.info({
      //     title: 'Loading',
      //     message: action
      //   })
      // })
  
      // refreshing.forEach((refresh) => {
      //   iziToast.info({
      //     title: 'Loading',
      //     message: refresh
      //   })
      // })
    }
  }, [ui])

  return <></>
}

const mapStateToProps = ({ ui }) => {
  return {
    ui
  }
}

export default connect(mapStateToProps, null)(Loading)
