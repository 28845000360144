import styled from 'styled-components'

export const AbsoluteWrapper = styled.div.attrs((props) => {
  return {
    style: {
      height: props.height,
      width: props.width
    }
  }
})`
  position: absolute;
`
