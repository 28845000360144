import RequestParams from '../generateParams'
import { fetchMapData } from '../../packages/cesium-add-on/fetchMapData'
import { parseMapData } from '../../packages/cesium-add-on/parseMapData'

/**
 * This method will fetch data by category and subcategory then parse into cesium map
 * @param {String} category data category type
 * @param {String} subCategory data subCategory type
 * @param {Function} fetchProgress method to show the progress
 * @param {Function} fetchFinish method to show the progress completed
 * @param {Boolean} raster is raster or not
 * @param {String} layerType is the layerType of map data
 * @param {Boolean} templateActive template active => not gonna set color gradient by default
 * @param {String} field the default field that will be shown in display
 * @param {Array} fields all properties data that will be show in display
 */
export async function generateCesiumMaps({
  category,
  subCategory,
  fetchProgress,
  fetchFinish,
  raster,
  layerType,
  layerBy,
  templateActive,
  field,
  fields,
  fieldFormat,
  fieldFormatMode,
  templateName,
  tileset
}) {
  try {
    let params = RequestParams.ONLY_FETCH_DATA()

    if (raster) {
      params = RequestParams.FETCH_ALL_RASTER()
    } else if (tileset) {
      params = RequestParams.FETCH_ALL_TILESET()
    }

    const data = await fetchMapData({
      category,
      subCategory,
      fetchProgress,
      fetchFinish,
      params,
      layerType
    })

    const maps = parseMapData({
      category,
      subCategory,
      data,
      params,
      layerBy,
      layerType,
      templateActive,
      field,
      fields,
      fieldFormat,
      fieldFormatMode,
      templateName,
      tileset,
    })

    return maps
  } catch (error) {
    return []
  }
}
