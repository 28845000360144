import * as d3 from 'd3'

import axiosReact, { getProgressDownload } from '../../../axiosReact'

const LAYER_TYPE_TO_GEOM = {
  Polygon: 'area',
  Line: 'network',
  Point: 'point'
}

export async function fetch({
  map,
  params,
  layerType,
  fetchProgress,
  fetchFinish
}) {

  if (params.withgeojson) {
    try {
      // const { data } = await axiosReact.get(`/geofix/grid/${map.id}`, {
      //   params,
      //   onDownloadProgress(event) {
      //     getProgressDownload(event, fetchProgress)
      //   }
      // })

      // fetchFinish()

      // return data
    } catch (error) {
      throw error
    }
  }
}

export function parseFetch({ data }) {
  return data
}

export function getColor(entity, { map }) {
  return {
    red: Math.random(),
    green: Math.random(),
    blue: Math.random(),
    alpha: map.cesiumLayer.alpha
  }
}

export async function getLegendStyle({
  map,
  params,
  fetchProgress,
  fetchFinish
}) {
  try {
  } catch (error) {
  }
}
