import {
  Color,
  Math as CesiumMath,
  Cartesian3,
  HeadingPitchRoll,
  Entity,
  Transforms,
  ClippingPlaneCollection,
  ClippingPlane,
  CallbackProperty,
  Ellipsoid,
  PolygonHierarchy
} from 'cesium'

import SectionMath from './SectionMath'

import {
  generateCrossBlockPolygon
} from './generatePolygon'

import {
  polygonSliceByPolyline,
  pickSlice
} from './sliceSection'

import {
  getCentroidPolygon,
  getCentroidSlice
} from './centroid'
import { clippingPlanesFromPolygon } from './clip'

import ArrowGLB from '../../images/GLB/arrow.glb'

/**
 * Heading for the block to create 3D
 * @param {Object} centroid - center of block plane
 * @param {Object} bearing - bearing of block
 * @param {Object} dragMovement - object that have position of drag
 * @returns {Array} Array of heading
 */
export function generateBlockHeading(centroid, bearing, dragMovement) {
  return [
    {
      longitude: dragMovement.start.longitude,
      latitude: centroid.latitude,
      height: centroid.height,
      heading: CesiumMath.toRadians(bearing + 180),
      pitch: 0,
      roll: 0,
      color: Color.RED,
      properties: {
        type: 'resize',
        value: 'x',
        reference: 'start'
      }
    },
    {
      longitude: dragMovement.end.longitude,
      latitude: centroid.latitude,
      height: centroid.height,
      heading: CesiumMath.toRadians(bearing),
      pitch: 0,
      roll: 0,
      color: Color.RED,
      properties: {
        type: 'resize',
        value: 'x',
        reference: 'end'
      }
    },
    {
      longitude: centroid.longitude,
      latitude: dragMovement.start.latitude,
      height: centroid.height,
      heading: CesiumMath.toRadians(180),
      pitch: 0,
      roll: 0,
      color: Color.GREEN,
      properties: {
        type: 'resize',
        value: 'y',
        reference: 'start'
      }
    },
    {
      longitude: centroid.longitude,
      latitude: dragMovement.end.latitude,
      height: centroid.height,
      heading: CesiumMath.toRadians(0),
      pitch: 0,
      roll: 0,
      color: Color.GREEN,
      properties: {
        type: 'resize',
        value: 'y',
        reference: 'end'
      }
    },
    {
      longitude: centroid.longitude,
      latitude: centroid.latitude,
      height: dragMovement.bottom,
      heading: 0,
      pitch: 0,
      roll: CesiumMath.toRadians(270),
      color: Color.BLUE,
      properties: {
        type: 'resize',
        value: 'z',
        reference: 'end'
      }
    },
    {
      longitude: centroid.longitude,
      latitude: centroid.latitude,
      height: dragMovement.top,
      heading: 0,
      pitch: 0,
      roll: CesiumMath.toRadians(90),
      color: Color.BLUE,
      properties: {
        type: 'resize',
        value: 'z',
        reference: 'start'
      }
    }
  ]
}

/**
 * Heading for the cross block to create 3D
 * @param {Object} centroid - center of block plane
 * @param {Object} bearing - bearing of block
 * @param {Object} dragMovement - object that have position of drag
 * @returns {Array} Array of heading
 */
export function generateCrossBlockHeading(centroid, bearing, dragMovement) {
  const polygon = generateCrossBlockPolygon(dragMovement)

  const width = (dragMovement.crossWidth.left + dragMovement.crossWidth.right) / 2

  const start = SectionMath.convertMetersToLongLat({
    longitude: centroid.longitude,
    latitude: centroid.latitude,
    distance: width / 1000,
    bearing: bearing + 90
  })

  const end = SectionMath.convertMetersToLongLat({
    longitude: centroid.longitude,
    latitude: centroid.latitude,
    distance: width / 1000,
    bearing: bearing - 90
  })

  return [
    {
      longitude: (polygon[0] + polygon[2]) / 2,
      latitude: (polygon[1] + polygon[3]) / 2,
      height: centroid.height,
      heading: CesiumMath.toRadians(bearing + 180),
      pitch: 0,
      roll: 0,
      color: Color.RED,
      properties: {
        type: 'resize',
        value: 'x',
        reference: 'start'
      }
    },
    {
      longitude: (polygon[4] + polygon[6]) / 2,
      latitude: (polygon[5] + polygon[7]) / 2,
      height: centroid.height,
      heading: CesiumMath.toRadians(bearing),
      pitch: 0,
      roll: 0,
      color: Color.RED,
      properties: {
        type: 'resize',
        value: 'x',
        reference: 'end'
      }
    },
    {
      longitude: start.longitude,
      latitude: start.latitude,
      height: centroid.height,
      heading: CesiumMath.toRadians(bearing + 90),
      pitch: 0,
      roll: 0,
      color: Color.GREEN,
      properties: {
        type: 'resize',
        value: 'y',
        reference: 'start'
      }
    },
    {
      longitude: end.longitude,
      latitude: end.latitude,
      height: centroid.height,
      heading: CesiumMath.toRadians(bearing - 90),
      pitch: 0,
      roll: 0,
      color: Color.GREEN,
      properties: {
        type: 'resize',
        value: 'y',
        reference: 'end'
      }
    },
    {
      longitude: centroid.longitude,
      latitude: centroid.latitude,
      height: dragMovement.bottom,
      heading: 0,
      pitch: 0,
      roll: CesiumMath.toRadians(270),
      color: Color.BLUE,
      properties: {
        type: 'resize',
        value: 'z',
        reference: 'end'
      }
    },
    {
      longitude: centroid.longitude,
      latitude: centroid.latitude,
      height: dragMovement.top,
      heading: 0,
      pitch: 0,
      roll: CesiumMath.toRadians(90),
      color: Color.BLUE,
      properties: {
        type: 'resize',
        value: 'z',
        reference: 'start'
      }
    }
  ]
}

/**
 * Heading for the slice block to create 3D
 * @param {Object} centroid - center of block plane
 * @param {Object} bearing - bearing of block
 * @param {Object} sectionStatus - object that store section status
 * @returns {Array} Array of heading
 */
export function generateSliceBlockHeading(centroid, bearing, sectionStatus) {
  const {
    slicingIndex,
    sliceMovement,
    dragMovement
  } = sectionStatus

  if (slicingIndex !== -1) {
    const sliceCentroid = getCentroidSlice(
      sliceMovement,
      dragMovement
    )

    const width = (sliceMovement.crossWidth.left + sliceMovement.crossWidth.right) / 2

    const start = SectionMath.convertMetersToLongLat({
      longitude: sliceCentroid.longitude,
      latitude: sliceCentroid.latitude,
      distance: width / 1000,
      bearing: bearing + 90
    })

    const end = SectionMath.convertMetersToLongLat({
      longitude: sliceCentroid.longitude,
      latitude: sliceCentroid.latitude,
      distance: width / 1000,
      bearing: bearing - 90
    })

    return [
      // block heading slice default is 90, can be update later on
      ...generateBlockHeading(centroid, 90, dragMovement),
      // direction for slice
      {
        longitude: start.longitude,
        latitude: start.latitude,
        height: centroid.height,
        heading: CesiumMath.toRadians(bearing + 90),
        pitch: 0,
        roll: 0,
        color: Color.YELLOW,
        properties: {
          type: 'resize',
          value: 'y_slice',
          reference: 'start'
        }
      },
      {
        longitude: end.longitude,
        latitude: end.latitude,
        height: centroid.height,
        heading: CesiumMath.toRadians(bearing - 90),
        pitch: 0,
        roll: 0,
        color: Color.YELLOW,
        properties: {
          type: 'resize',
          value: 'y_slice',
          reference: 'end'
        }
      }
    ]
  } else {
    return generateBlockHeading(centroid, bearing, dragMovement)
  }
}

/**
 * This will create arrow generate arrow object to dragging the position for block section
 * @param {Object} cesiumViewer - object cesiumViewer
 * @param {Object} centroid - centroid properties
 * @param {Object} dragMovement - object that have position of drag
 */
export function createCentroid3dBlock(cesiumViewer, centroid, dragMovement) {
  // default bearing is 90 for block
  const bearing = 90

  const { viewer, sectionStatus } = cesiumViewer

  const headings = generateBlockHeading(centroid, bearing, dragMovement)

  createSectionEditor(
    viewer,
    sectionStatus.sectionEditor,
    headings
  )
}

/**
 * This will create arrow generate arrow object to dragging the position for cross section
 * @param {Object} cesiumViewer - object cesiumViewer
 * @param {Object} centroid - centroid properties
 * @param {Object} dragMovement - object that have position of drag
 */
export function createCentroid3dCross(cesiumViewer, centroid, dragMovement) {
  const { viewer, sectionStatus } = cesiumViewer
  
  const bearing = SectionMath.getPlaneBearing(dragMovement)

  const headings = generateCrossBlockHeading(centroid, bearing, dragMovement)

  createSectionEditor(
    viewer,
    sectionStatus.sectionEditor,
    headings
  )
}

/**
 * This will create arrow generate arrow object to dragging the position for block with slice section
 * @param {Object} cesiumViewer - object cesiumViewer
 * @param {Object} centroid - centroid properties
 * @param {Object} sliceMovement - object that have position of slice
 */
 export function createCentroid3dSlice(cesiumViewer, centroid, sliceMovement) {
  const { viewer, sectionStatus } = cesiumViewer

  const bearing = SectionMath.getPlaneBearing(sliceMovement)

  const headings = generateSliceBlockHeading(centroid, bearing, sectionStatus)

  createSectionEditor(
    viewer,
    sectionStatus.sectionEditor,
    headings
  )
}

/**
 * This method will create section edtior based on headings
 * @param {Cesium.Viewer} viewer - state of cesium viewer
 * @param {Array} sectionEditor - array of available 3d editor
 * @param {Array} headings - array of each arrow of 3d editor
 */
export function createSectionEditor(viewer, sectionEditor, headings) {
  for (let i = 0; i < headings.length; i++) {
    const position = Cartesian3.fromDegrees(
      headings[i].longitude,
      headings[i].latitude,
      headings[i].height
    )

    const hpr = new HeadingPitchRoll(
      headings[i].heading,
      headings[i].pitch,
      headings[i].roll
    )

    const editor = viewer.entities.add(new Entity({
      position,
      model: {
        color: headings[i].color,
        uri: ArrowGLB,
        minimumPixelSize: 50,
        nodeTransformations : {
          root : {
            scale : new Cartesian3(1.7, 1.0, 1.0)
          }
        },
        modelMatrix: Transforms.eastNorthUpToFixedFrame(
          Cartesian3.fromDegrees(
            headings[i].longitude,
            headings[i].latitude,
            headings[i].height
          )
        ),
        clippingPlanes: new ClippingPlaneCollection({
          planes : [
            new ClippingPlane(new Cartesian3(0.0, 1.0, 0.0), 1.0)
          ],
        }),
        shadows: 0
        // heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND
      },
      orientation: Transforms
        .headingPitchRollQuaternion(position, hpr),
      properties: headings[i].properties
    }))

    sectionEditor.push(editor)
  }
}

/**
 * This method will create block section with arrow 3d data to resize box size and also it's clipping plane
 * @param {Object} cesiumViewer the cesiumViewer data object
 * @param {Array} polygon - polygon points
 */
export function createBlockSection(cesiumViewer, polygon) {
  const { viewer, sectionStatus } = cesiumViewer

  sectionStatus.cartesianPoints = Cartesian3.fromDegreesArray(polygon)

  if (!sectionStatus.sectionBox) {
    sectionStatus.sectionBox = viewer.entities.add({
      ellipsoid: Ellipsoid.WGS84,
      polygon: {
        hierarchy: new CallbackProperty(() => {
          return new PolygonHierarchy(sectionStatus.cartesianPoints)
        }, false),
        material: Color.WHITE.withAlpha(0.1),
        extrudedHeight: new CallbackProperty(() => {
          return sectionStatus.dragMovement.top
        }, false),
        height: new CallbackProperty(() => {
          return sectionStatus.dragMovement.bottom
        }, false),
        outline: true, // height must be set for outline to display
        outlineColor: Color.WHITE,
      }
    })
  }

  try {
    const centroidProperties = getCentroidPolygon(polygon, sectionStatus.dragMovement)
    
    const globe = viewer.scene.globe

    if (sectionStatus.slicingIndex === -1) {
      globe.clippingPlanes = new ClippingPlaneCollection({
        planes: clippingPlanesFromPolygon(
          sectionStatus.cartesianPoints,
          centroidProperties,
          sectionStatus.dragMovement
        ),
        unionClippingRegions : true,
        edgeWidth: 1,
        edgeColor: Color.WHITE,
        enabled : true
      })
    } else {
      polygonSliceByPolyline(cesiumViewer)
      pickSlice(
        cesiumViewer,
        sectionStatus.sliceMovement.pick,
        sectionStatus.slicingIndex
      )
    }
          
    if (!sectionStatus.sectionEditor.length) {
      if (sectionStatus.blockSection) {
        createCentroid3dBlock(
          cesiumViewer,
          centroidProperties,
          sectionStatus.dragMovement
        )
      } else if (sectionStatus.crossSection) {
        createCentroid3dCross(
          cesiumViewer,
          centroidProperties,
          sectionStatus.dragMovement
        )
      } else if (sectionStatus.sliceSection) {
        createCentroid3dSlice(
          cesiumViewer,
          centroidProperties,
          sectionStatus.sliceMovement
        )
      }
    }
  } catch (error) {
    throw error
    // invalid poisition
  }
}
