import React from 'react'

import { ReactComponent as CloseIcon } from '../../../../images/Icons/close.svg'

import { ToggleSVGButton } from '../../../shares/ToggleSVGIcon'

import { LIGHT_YELLOW } from '../../../shares/ColorTemplate'

/**
 * This component will create in point
 * @param {String} title - hover title
 * @param {Function} setConfirmation - method to change state confirmation
 */
function CancelPoint({
  title,
  setConfirmation
}) {
  const toggle = false

  return <ToggleSVGButton
    flex
    margin="0.5em"
    active={toggle}
    fillActive={LIGHT_YELLOW.hover}
    fillInActive={LIGHT_YELLOW.default}
    onClick={() => {
      setConfirmation({
        show: true,
        process: 'cancel',
        action: title
      })
    }}
  >
    <CloseIcon title={title} />
  </ToggleSVGButton>
}

export default CancelPoint
