import * as d3 from'd3';
import { powerOfTen } from './mathFunction';

export const callScaleLogX = ({...props}) => {
    let {
        scaleAxis,
        selectedCall
    } = props;
    return(
        scaleAxis
        .call(d3.axisBottom(selectedCall)
        .tickSizeOuter(0))
        .selectAll(".tick text")
            .text(null)
        .filter(powerOfTen)
            .attr("dy", "1em")
            .text(10)
        .append("tspan")
            .attr("dy", "-.5em")
            .text(function(d) { return Math.round(Math.log(d) / Math.LN10); })
    )
}

export const callScaleLogY = ({...props}) => {
    let {
        scaleAxis,
        selectedCall
    } = props;
    return(
        scaleAxis
        .call(d3.axisLeft(selectedCall)
        .tickSizeOuter(0))
        .selectAll(".tick text")
            .text(null)
        .filter(powerOfTen)
            .attr("dy", "1em")
            .text(10)
        .append("tspan")
            .attr("dy", "-.5em")
            .text(function(d) { return Math.round(Math.log(d) / Math.LN10); })
    )
}

export const callScaleLinX = ({...props}) => {
    let {
        scaleAxis,
        selectedCall,
        data,
        x
    } =props;

    if (d3.max(data[x])>1000){
        scaleAxis.selectAll('text')
            .style("text-anchor", "end")
            .attr("dx", "-.1em")
            .attr("dy", ".5em")
            .attr("transform", "rotate(-45)");
    }
    
    scaleAxis
        .transition()
        .duration(1000)
        .call(d3.axisBottom(selectedCall)
            .ticks(5)
            .tickSizeOuter(0));

    if (d3.max(data[x])>1000){
        scaleAxis
        .transition()
        .duration(1000)
        .call(d3.axisBottom(selectedCall)
            .ticks(5)
            .tickSizeOuter(0));
    }

    // scaleAxis.select("path")
    // .attr("marker-end","url(#arrowhead-right)")
}

export const callScaleLinY=({...props})=>{
    let {
        scaleAxis,
        selectedCall,
        data,
        y
    } =props;
    scaleAxis
    .transition()
    .duration(1000)
    .call(d3.axisLeft(selectedCall)
        .ticks(6)
        .tickSizeOuter(0));

    if (d3.max(data[y])>1000){
        scaleAxis
        .transition()
        .duration(1000)
        .call(d3.axisLeft(selectedCall)
            .ticks(5)
            .tickSizeOuter(0));
    }

    // scaleAxis.select("path")
    // .attr("marker-end","url(#arrowhead-up)")
}

export const scaleLinear = ({...props}) => {
    let {
        data,
        selected,
        minrange,
        maxrange
    } = props;

    let res=(d3.max(data[selected])-d3.min(data[selected]))/10

    let lin=d3.scaleLinear()
    .domain([d3.min(data[selected])-res, d3.max(data[selected])+res])
    .range([minrange, maxrange])
    .nice()
    return lin;
}

export const scaleLog = ({...props}) => {
    let {
        data,
        selected,
        minrange,
        maxrange
    } = props;
    let min=d3.min(data[selected], d => d > 0 ? d: NaN)
    let log=d3.scaleLog()
        .domain([min, d3.max(data[selected])])
        .range([minrange,maxrange]);
    return log;
}