import React, {useEffect, useState, useRef} from 'react';
import ReactDOM from 'react-dom';

let SelectTools = ({...props}) => {
    let {
        children,
        parentRef,
    } = props;
    let cesiumToolbarRef = useRef(document.querySelector('.cesium-viewer-toolbar'));
    // console.log(!!parentRef);
    // parentRef && console.log(!!parentRef.current);
    if (parentRef && parentRef.current) {
        let parentCesiumToolbar = parentRef.current.querySelector('.cesium-viewer-toolbar');
        if (parentCesiumToolbar.current) {
            cesiumToolbarRef.current = parentCesiumToolbar.current;
        }
    }

    useEffect(() => {
        // toolbar classname = cesium-viewer-toolbar
        // TODO: READ: https://www.jayfreestone.com/writing/react-portals-with-hooks/
    }, []);

    if (parentRef && parentRef.current) {
        let target = parentRef.current.querySelector('.cesium-viewer-toolbar');
        // target && console.log(target.current);
        if (target) {
            return ReactDOM.createPortal(children, target);
        } else {
            return ReactDOM.createPortal(children, parentRef.current);
        }
    } else {
        if (!cesiumToolbarRef && !cesiumToolbarRef.current) return null;
        return ReactDOM.createPortal(children, cesiumToolbarRef.current);
    }
}

export default SelectTools;
