export const bauxite = {
  info: '(no information)',
  data: [
    {
      class: 'Ferrite',
      values: [
        {
          fe2o3: 80,
          al2o3: 20,
          sio2: 0
        },
        {
          fe2o3: 100,
          al2o3: 0,
          sio2: 0
        },
        {
          fe2o3: 80,
          al2o3: 0,
          sio2: 20
        }
      ],
      rotation: null
    },
    {
      class: 'Ferrite',
      values: [
        {
          fe2o3: 80,
          al2o3: 10,
          sio2: 10
        },
        {
          fe2o3: 55,
          al2o3: 35,
          sio2: 10
        },
        {
          fe2o3: 55,
          al2o3: 10,
          sio2: 35
        }
      ],
      rotation: null
    },
    {
      class: 'Bx Ferrite',
      values: [
        {
          fe2o3: 80,
          al2o3: 20,
          sio2: 0
        },
        {
          fe2o3: 50,
          al2o3: 50,
          sio2: 0
        },
        {
          fe2o3: 50,
          al2o3: 40,
          sio2: 10
        },
        {
          fe2o3: 80,
          al2o3: 10,
          sio2: 10
        }
      ],
      rotation: null
    },
    {
      class: 'Ferritic Bx',
      values: [
        {
          fe2o3: 50,
          al2o3: 50,
          sio2: 0
        },
        {
          fe2o3: 50,
          al2o3: 40,
          sio2: 10
        },
        {
          fe2o3: 10,
          al2o3: 80,
          sio2: 10
        },
        {
          fe2o3: 20,
          al2o3: 80,
          sio2: 0
        }
      ],
      rotation: null
    },
    {
      class: 'Bauxite',
      values: [
        {
          fe2o3: 0,
          al2o3: 80,
          sio2: 20
        },
        {
          fe2o3: 20,
          al2o3: 80,
          sio2: 0
        },
        {
          fe2o3: 0,
          al2o3: 100,
          sio2: 0
        }
      ]
    },
    {
      class: 'Bauxite',
      values: [
        {
          fe2o3: 10,
          al2o3: 80,
          sio2: 10
        },
        {
          fe2o3: 40,
          al2o3: 50,
          sio2: 10
        },
        {
          fe2o3: 10,
          al2o3: 50,
          sio2: 40
        }
      ]
    },
    {
      class: 'Kaolinite Bx',
      values: [
        {
          fe2o3: 10,
          al2o3: 80,
          sio2: 10
        },
        {
          fe2o3: 0,
          al2o3: 80,
          sio2: 20
        },
        {
          fe2o3: 0,
          al2o3: 50,
          sio2: 50
        },
        {
          fe2o3: 10,
          al2o3: 45,
          sio2: 45
        }
      ]
    },
    {
      class: 'Bx Kaolinite',
      values: [
        {
          fe2o3: 0,
          al2o3: 50,
          sio2: 50
        },
        {
          fe2o3: 10,
          al2o3: 45,
          sio2: 45
        },
        {
          fe2o3: 10,
          al2o3: 10,
          sio2: 80
        },
        {
          fe2o3: 0,
          al2o3: 20,
          sio2: 80
        }
      ]
    },
    {
      class: 'Kaolinite',
      values: [
        {
          fe2o3: 20,
          al2o3: 0,
          sio2: 80
        },
        {
          fe2o3: 0,
          al2o3: 20,
          sio2: 80
        },
        {
          fe2o3: 0,
          al2o3: 0,
          sio2: 100
        }
      ]
    },
    {
      class: 'Ferritic Kaolinite ',
      values: [
        {
          fe2o3: 50,
          al2o3: 10,
          sio2: 40
        },
        {
          fe2o3: 50,
          al2o3: 0,
          sio2: 50
        },
        {
          fe2o3: 20,
          al2o3: 0,
          sio2: 80
        },
        {
          fe2o3: 10,
          al2o3: 10,
          sio2: 80
        }
      ]
    },
    {
      class: 'Kaolinite Ferritic',
      values: [
        {
          fe2o3: 80,
          al2o3: 10,
          sio2: 10
        },
        {
          fe2o3: 80,
          al2o3: 0,
          sio2: 20
        },
        {
          fe2o3: 50,
          al2o3: 0,
          sio2: 50
        },
        {
          fe2o3: 50,
          al2o3: 10,
          sio2: 40
        }
      ]
    },
    {
      class: 'Kaolinite',
      values: [
        {
          fe2o3: 10,
          al2o3: 10,
          sio2: 80
        },
        {
          fe2o3: 40,
          al2o3: 10,
          sio2: 50
        },
        {
          fe2o3: 10,
          al2o3: 40,
          sio2: 50
        }
      ]
    },
    {
      class: 'Laterite',
      values: [
        {
          fe2o3: 55,
          al2o3: 35,
          sio2: 10
        },
        {
          fe2o3: 40,
          al2o3: 50,
          sio2: 10
        },
        {
          fe2o3: 10,
          al2o3: 50,
          sio2: 40
        },
        {
          fe2o3: 10,
          al2o3: 40,
          sio2: 50
        },
        {
          fe2o3: 40,
          al2o3: 10,
          sio2: 50
        },
        {
          fe2o3: 55,
          al2o3: 10,
          sio2: 35
        }
      ]
    }
  ]
}
