import React from 'react'

import styled from 'styled-components'

const Div = ({ className, children }) => (
  <i
    className={className}
  >{children}</i>
)


const Wrapper = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => props.height};
  width: ${props => props.width};
  position: relative;
`

export default Wrapper
