import * as d3 from 'd3';
import { capitalizeElement } from '../helpers/capitalizeFirstLetter';

/**
 * This the function to make boxplot from data group
 * @param {object} groupCounts is group
 * @param {string}
 *  
 */

export let boxPlot = ({...props}) => {
    let {
        groupCounts, 
        globalCounts, 
        svg, 
        height, 
        width, 
        barHeight,
        className,
        color,
        dataLocal
    } = props;

    
    for(var key in groupCounts) {
        var groupCount = groupCounts[key];
        groupCounts[key] = groupCount.sort(sortNumber);
    }
    
    var zoom = d3.zoom()
        .scaleExtent([.5, 20])  // This control how much you can unzoom (x0.5) and zoom (x20)
        .extent([[0, 0], [width, height]])
        .on("zoom", updateChart);

    var colorScale = d3.scaleOrdinal(color)
        .domain(Object.keys(groupCounts));

    var boxPlotData = [];
    for ([key, groupCount] of Object.entries(groupCounts)) {
        var localMin = d3.min(groupCount);
        var localMax = d3.max(groupCount);
        var obj = {};
        obj["key"] = key;
        obj["counts"] = groupCount;
        obj["quartile"] = boxQuartiles(groupCount);
        obj["whiskers"] = [localMin, localMax];
        obj["color"] = colorScale(key);
        boxPlotData.push(obj);
    }
 
    var yScale = d3.scalePoint()
        .domain(Object.keys(groupCounts))
        .rangeRound([0, height])
        .padding([0.5]);

    var min = d3.min(globalCounts)- ((d3.max(globalCounts)-d3.min(globalCounts))/10);
    var max = d3.max(globalCounts)+ ((d3.max(globalCounts)-d3.min(globalCounts))/10);
    var xScale = d3.scaleLinear()
        .domain([min, max])
        .range([0, width]);

    svg.append("defs").append("SVG:clipPath")
        .attr("id", "clip")
        .append("svg:rect")
        .attr("width", width )
        .attr("height", height )
        .attr("x", 0)
        .attr("y", 0);
    
    var g = svg.append("g").attr("clip-path", "url(#clip)").call(zoom);;

    var horizontalLines = g.selectAll(".horizontalLines")
        .data(boxPlotData)
    horizontalLines.enter()
        .append("line")
        .on("mouseover", function(d) {		
            tooltip
                .transition()
                .duration(500)
                .style("opacity", 1)
            d3.select(this)
                .style("stroke","white")
                .style("stroke-width", "1px")	
        })
        .on("mouseleave", function(d){
            tooltip
                .transition()
                .duration(500)
                .style("opacity", 0)
            d3.select(this)
                .style("stroke","gray")
        })
        .on("mousemove", function(event, d){
            tooltip
                .html("<b>"+label(d)+"</b> </br>Min: "+d3.format(".2f")(d.whiskers[0])+"</br>Q1: "+d3.format(".2f")(d.quartile[0])+"</br>Median: "+d3.format(".2f")(d.quartile[1])+"</br>Q3: "+d3.format(".2f")(d.quartile[2])+"</br>Max: "+d3.format(".2f")(d.whiskers[1]))
                .style('left', event.pageX + 30 + "px")
                .style('top', event.pageY - 30 + "px")
            d3.select(this)
                .style("stroke","white")
                .style("stroke-width", "1px")
                .style("cursor","pointer")	
        })
        .merge(horizontalLines)
        .attr("y1", function(datum) { return yScale(datum.key); })
        .attr("y2", function(datum) { return yScale(datum.key); })
        .attr("x1", function(datum) { return xScale(datum.whiskers[0]); })
        .attr("x2", function(datum) { return xScale(datum.whiskers[0]); })
        .transition()
        .duration(1000)
        .attr("y1", function(datum) { return yScale(datum.key); })
        .attr("x1", function(datum) { return xScale(datum.whiskers[0]); })
        .attr("y2", function(datum) { return yScale(datum.key); })
        .attr("x2", function(datum) { return xScale(datum.whiskers[1]); })
        .attr("stroke", "white")
        .attr("stroke-width", 1)
        .attr("fill", "none");

    const tooltip = d3.select('.boxplot-container'+className)
    .append("div")
    .style("opacity",0)
    .attr ("class","tooltip-boxplot")
    .style("position", "fixed")
    .style("z-index", 3);

    let label = (d) => dataLocal? d.key : capitalizeElement(d.key.slice(0,d.key.lastIndexOf("_")))

    let rects = g.selectAll("rect")
    .data(boxPlotData)
    rects.enter()
    .append("rect")
    .on("mouseover", function(d) {		
        tooltip
            .transition()
            .duration(500)
            .style("opacity", 1)
        d3.select(this)
            .style("stroke","white")
            .style("stroke-width", "1px")	
    })
    .on("mouseleave", function(d){
        tooltip
            .transition()
            .duration(500)
            .style("opacity", 0)
        d3.select(this)
            .style("stroke","gray")
    })
    .on("mousemove", function(event, d){
        tooltip
            .html("<b>"+label(d)+"</b> </br>Min: "+d3.format(".2f")(d.whiskers[0])+"</br>Q1: "+d3.format(".2f")(d.quartile[0])+"</br>Median: "+d3.format(".2f")(d.quartile[1])+"</br>Q3: "+d3.format(".2f")(d.quartile[2])+"</br>Max: "+d3.format(".2f")(d.whiskers[1]))
            .style('left', event.pageX + 30 + "px")
            .style('top', event.pageY - 30 + "px")
        d3.select(this)
            .style("stroke","white")
            .style("stroke-width", "1px")
            .style("cursor","pointer")	
    })
    .merge(rects)
    .attr("width", function(datum) {
        var quartiles = datum.quartile;
        var width =  xScale(quartiles[0]) - xScale(quartiles[0]);   
        return width;
    })
    .attr("height", barHeight)
    .attr("y", function(datum) { return yScale(datum.key) - (barHeight/2); })
    .attr("x", function(datum) { return xScale(datum.quartile[0]); })
    .transition()
    .delay(300)
    .duration(500)
    .attr("height", barHeight)
    .attr("width", function(datum) {
        var quartiles = datum.quartile;
        var width =  xScale(quartiles[2]) - xScale(quartiles[0]);   
        return width;
    })
    .attr("y", function(datum) { return yScale(datum.key) - (barHeight/2); })
    .attr("x", function(datum) { return xScale(datum.quartile[0]); })
    .attr("fill", function(datum) { return datum.color; })
    .attr("stroke", "gray")
    .attr("stroke-width", 1)
    // rects.exit()
    // .remove();
    

    var verticalLineConfigs = [
        {
        y1: function(datum) { return yScale(datum.key) - barHeight/2 },
        x1: function(datum) { return xScale(datum.whiskers[0]) },
        y2: function(datum) { return yScale(datum.key) + barHeight/2 },
        x2: function(datum) { return xScale(datum.whiskers[0]) }
        },
        {
        y1: function(datum) { return yScale(datum.key) - barHeight/2 },
        x1: function(datum) { return xScale(datum.quartile[1]) },
        y2: function(datum) { return yScale(datum.key) + barHeight/2 },
        x2: function(datum) { return xScale(datum.quartile[1]) }
        },
        {
        y1: function(datum) { return yScale(datum.key) - barHeight/2 },
        x1: function(datum) { return xScale(datum.whiskers[1]) },
        y2: function(datum) { return yScale(datum.key) + barHeight/2 },
        x2: function(datum) { return xScale(datum.whiskers[1]) }
        }
    ];

    for(var i=0; i < verticalLineConfigs.length; i++) {
        var lineConfig = verticalLineConfigs[i];

        var verticalLine = g.selectAll(".whiskers")
        .data(boxPlotData)
        verticalLine
        .enter()
        .append("line")
        .on("mouseover", function(d) {		
            tooltip
                .transition()
                .duration(500)
                .style("opacity", 1)
            d3.select(this)
                .style("stroke","white")
                .style("stroke-width", "1px")	
        })
        .on("mouseleave", function(d){
            tooltip
                .transition()
                .duration(500)
                .style("opacity", 0)
            d3.select(this)
                .style("stroke","gray")
        })
        .on("mousemove", function(event, d){
            tooltip
                .html("<b>"+label(d)+"</b> </br>Min: "+d3.format(".2f")(d.whiskers[0])+"</br>Q1: "+d3.format(".2f")(d.quartile[0])+"</br>Median: "+d3.format(".2f")(d.quartile[1])+"</br>Q3: "+d3.format(".2f")(d.quartile[2])+"</br>Max: "+d3.format(".2f")(d.whiskers[1]))
                .style('left', event.pageX + 30 + "px")
                .style('top', event.pageY - 30 + "px")
            d3.select(this)
                .style("stroke","white")
                .style("stroke-width", "1px")
                .style("cursor","pointer")	
        })
        .merge(verticalLine)
        .attr("x1", lineConfig.x1)
        .attr("y1", lineConfig.y1)
        .attr("x2", lineConfig.x2)
        .attr("y2", lineConfig.y1)
        .transition()
        .duration(500)
        .attr("x1", lineConfig.x1)
        .attr("y1", lineConfig.y1)
        .attr("x2", lineConfig.x2)
        .attr("y2", lineConfig.y2)
        .attr("stroke", "white")
        .attr("stroke-width", 1)
        .attr("fill", "none");
    }

    svg
    .append('marker')
    .attr('id', 'arrowhead-right')
    .attr('refX', 4)
    .attr('refY', 5)
    .attr('markerWidth', 10)
    .attr('markerHeight', 10)
    .append('path')
    // .attr('d', "M2,2 L2,13 L8,7 L2,2")
    .attr('d', 'M 0 0 L 5 5 L 0 10')
    .attr('stroke', 'white')
    .attr('orient','auto-start-reverse')
    // .attr('stroke-width', 1)
    .attr('fill', 'none');
    // .attr('fill', 'white');

    const xAxis = svg.append("g")
        .attr("transform", "translate(0," + height + ")")
        .transition()
        .duration(500)
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(xScale)
        .ticks(4)
        .tickSizeOuter(0));
    
    // xAxis.select("path")
    // .attr("marker-end","url(#arrowhead-right)")

    // if (d3.max(globalCounts)>1000){
    //     xAxis.selectAll('text')
    //         .style("text-anchor", "end")
    //         .attr("dx", "-.8em")
    //         .attr("dy", ".15em")
    //         .attr("transform", "rotate(-35)");
    // };

    svg.append("g")
        .transition()
        .duration(500)
        .call(d3.axisLeft(yScale)
        .tickFormat(function(d){
            if(dataLocal){
                return d
            } else {
                return capitalizeElement(d.slice(0,d.lastIndexOf("_")))
            }
        })
        .tickSizeOuter(0));

    // yAxis.select("path")
    // .attr("marker-start","url(#arrowhead-up)")
        
    function boxQuartiles(d) {
    return [
        d3.quantile(d, .25),
        d3.quantile(d, .5),
        d3.quantile(d, .75)
    ];
    }
        
    function sortNumber(a,b) {
        return a - b;
    }

    function updateChart(event,d) {
        // recover the new scale
        var newX = event.transform.rescaleX(xScale);
        // var newY = event.transform.rescaleY(yScale);
        // console.log(newX)
        // console.log(newY)

        // update axes with these new boundaries
        xAxis.call(d3.axisBottom(newX))
        // yAxis.call(d3.axisLeft(newY))

        // update
        g.selectAll(".horizontalLines")
        // .attr("y1", function(datum) { return newY(datum.key); })
        .attr("x1", function(datum) { return newX(datum.whiskers[0]); })
        // .attr("y2", function(datum) { return newY(datum.key); })
        .attr("x2", function(datum) { return newX(datum.whiskers[1]); })

        g.selectAll("rect")
        .attr("width", function(datum) {
            var quartiles = datum.quartile;
            var width =  newX(quartiles[2]) - newX(quartiles[0]);   
            return width;
        })
        // .attr("y", function(datum) { return newY(datum.key) - (barHeight/2); })
        .attr("x", function(datum) { return newX(datum.quartile[0]); })

        var verticalLineConfigs = [
            {
            // y1: function(datum) { return newY(datum.key) - barHeight/2 },
            x1: function(datum) { return newX(datum.whiskers[0]) },
            // y2: function(datum) { return newY(datum.key) + barHeight/2 },
            x2: function(datum) { return newX(datum.whiskers[0]) }
            },
            {
            // y1: function(datum) { return newY(datum.key) - barHeight/2 },
            x1: function(datum) { return newX(datum.quartile[1]) },
            // y2: function(datum) { return newY(datum.key) + barHeight/2 },
            x2: function(datum) { return newX(datum.quartile[1]) }
            },
            {
            // y1: function(datum) { return newY(datum.key) - barHeight/2 },
            x1: function(datum) { return newX(datum.whiskers[1]) },
            // y2: function(datum) { return newY(datum.key) + barHeight/2 },
            x2: function(datum) { return newX(datum.whiskers[1]) }
            }
        ];

        for(var i=0; i < verticalLineConfigs.length; i++) {
            var lineConfig = verticalLineConfigs[i];
    
            var verticalLine = g.selectAll(".whiskers")
            .data(boxPlotData)
            verticalLine
            .enter()
            .append("line")
            .merge(verticalLine)
            .transition()
            .duration(500)
            .attr("x1", lineConfig.x1)
            .attr("y1", lineConfig.y1)
            .attr("x2", lineConfig.x2)
            .attr("y2", lineConfig.y2)
            .attr("stroke", "white")
            .attr("stroke-width", 1)
            .attr("fill", "none");
        }
    }
}