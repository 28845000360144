import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'

import styled from 'styled-components'

const AbsoluteWrapper = styled.div`
  position: absolute;
  left: ${props => props.left};
  bottom: ${props => props.bottom};
  transition: left 550ms;
`

const InnerWrapper = styled.div`
  position: relative;
  width: ${props => props.width};
  height: ${props => props.height};
  background: white;
  transition: width 550ms;
`

function TableContainer({
  table,
  sidebarState,
  sitebar,
  children
}) {
  const LEFTBAR_WIDTH = '50px'

  const [ tableLeft, setTableLeft ] = useState(0)
  const [ tableWidth, setTableWidth ] = useState(null)

  function checkLeft() {
    for (let key in sidebarState) {
      if (key === 'globe') {
        continue
      }
      if (key === 'menu') {
        continue
      }

      if (sidebarState[key].status) {
        return '20em'
      }
    }

    return 0
  }

  function checkWidth() {
    if (sitebar.toggle && !sitebar.minimize && checkLeft()) {
      return `calc(100vw - ${LEFTBAR_WIDTH} - ${sitebar.width} - 20em)`
    } else if (sitebar.toggle && !sitebar.minimize) {
      return `calc(100vw - ${LEFTBAR_WIDTH} - ${sitebar.width})`
    } else if (checkLeft()) {
      return `calc(100vw - ${LEFTBAR_WIDTH} - 20em)`
    }

    return `calc(100vw - ${LEFTBAR_WIDTH})`
  }

  useEffect(() => {
    return () => {
      setTableLeft(0)
      setTableWidth(null)
    }
  }, [])

  useEffect(() => {
    setTableLeft(checkLeft())
    setTableWidth(`calc(100vw - ${LEFTBAR_WIDTH})`)

    setTimeout(() => {
      setTableWidth(checkWidth())
    }, 550)
  }, [sidebarState])

  useEffect(() => {
    setTableWidth(checkWidth())
  }, [sitebar])

  if (!table.status) {
    return null
  }

  return (
    <AbsoluteWrapper
      left={tableLeft}
      bottom={
        table.status
          ? table.bottom + 'em'
          : -table.height * 5 + 'em'
      }
    >
      <InnerWrapper
        width={tableWidth}
        height={`calc(${table.height}em + ${table.deltaHeight}px)`}
      >
        {children}
      </InnerWrapper>
    </AbsoluteWrapper>
  )
}

function mapStateToProps({ table, sidebarState, sitebar }) {
  return {
    table,
    sidebarState,
    sitebar
  }
}

export default connect(mapStateToProps, null)(TableContainer)
