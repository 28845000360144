import React, { useCallback, useMemo } from 'react';
import iziToast from 'izitoast';
import {useDropzone} from 'react-dropzone'
import { 
    acceptStyle, 
    activeStyle, 
    baseStyle, 
    rejectStyle 
} from './partials';

function DragDropZone({...props}) {
    let {
        data,
        type,
        onDrop
    } = props;

    const onDropAccepted = useCallback(()=>{
        iziToast.success({
            title: 'File added',
            message: 'Your file is accepted'
        })
    })

    const onDropRejected = useCallback(()=>{
        iziToast.error({
            title: 'File rejected',
            message: 'Please use files with csv extension only'
        })
    })
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values, .shp, text/shp", 
        onDrop,
        maxFiles:1,
        onDropAccepted,
        onDropRejected,
        multiple: false
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    return (
        <div className="container">
            <div {...getRootProps({style})}>
                <input {...getInputProps()} />
                {isDragAccept && data.name.length===0 && 
                <p style={{fontSize:'24px'}}> Drop your {type} csv file here </p>
                }
                {isDragReject && data.name.length===0 &&
                <p style={{fontSize:'24px'}}>PLEASE DROP CSV FILE ONLY!!</p>
                }
                {
                    data.name.length!==0 ?
                        isDragAccept ? 
                            <p style={{fontSize:'24px'}}> Drop your new {type} csv file here </p>
                        :
                        isDragReject ?
                        <p style={{fontSize:'24px'}}>PLEASE DROP CSV FILE ONLY!!</p>
                        :
                    <>
                    <p style={{fontSize:'24px'}}>Current file:</p>
                    {
                        data.name.map((name,idx) => {
                            return(
                                <div key={`upload-list-${idx}`}>
                                <p style={{fontSize:'18px', margin:0, border:'1px solid gray', padding:'0.3em 0.8em'}}>{name}</p>
                                <p style={{fontSize:'18px', color:'bisque'}}>To change the selected file please drop file or click here again</p>
                                </div>                   
                            )
                        })
                    }
                    </>
                    :
                    !isDragActive && <>
                        <p style={{fontSize:'30px', textAlign:'center'}}>Drag n drop {type} files here, or click to select files</p>
                        <em style={{fontSize:'16px'}}>(Only *.csv file will be accepted)</em>
                    </>
                }
            </div>
        </div>
    );
}

export default DragDropZone;