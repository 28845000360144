import React from 'react'

import { connect } from 'react-redux'

import './GreyScreen.css'

function GreyScreen ({ sidebarState }) {
  return (
    <>
      {
        sidebarState.menu.status
          ? <div className="greyscreen-layer max-height"></div>
          : <></>
      }
    </>
  )
}

function mapStateToProps ({ sidebarState }) {
  return {
    sidebarState
  }
}

export default connect(mapStateToProps, null)(GreyScreen)
