import * as actionTypes from '../../constants/store/actionTypes/toolbar'

import * as Cesium from 'cesium'

import {
  switchPolylineColor
} from '../../apis/cesium'

const initialState = {
  selector: 'none',
  groups: [],
  entity: null,
  type: null,
  activeShapePoints: [],
  histories: [],
  pointHistories: [],
  active: -1,
  pointDataSource: null,
  pointSelected: null,
  error: null
}

function toolbarReducer (state = initialState, { type, payload, error }) {
  switch (type) {
    case actionTypes.ADD_POLYLINE_ENTITY:
      return {
        ...state,
        ...payload,
        activeShapePoints: payload.activeShapePoints,
        entity: payload.entity,
        pointDataSource: payload.pointDataSource
      }
    case actionTypes.UPDATE_ACTIVE_SHAPE_POINTS:
      return {
        ...state,
        activeShapePoints: payload.activeShapePoints
      }
    case actionTypes.ADD_ACTIVE_POLYLINE_INDEX:
      return {
        ...state,
        active: state.active + 1
      }
    case actionTypes.UPDATE_POLYLINE_HISTORIES:
      return {
        ...state,
        histories: [
          ...state.histories
            .slice(
              0,
              state.active
            ),
          payload.data
        ],
        pointHistories: [
          ...state.pointHistories
            .slice(
              0,
              state.active
            ),
          payload.dataPoint
        ]
      }
    case actionTypes.SET_POINT_SELECTED:
      return {
        ...state,
        pointSelected: payload.pointSelected
      }
    case actionTypes.REMOVE_POINT_SELECTED:
      return {
        ...state,
        pointSelected: null
      }
    case actionTypes.SET_ACTION_SELECT:
      if (state.selector !== 'select') {
        return {
          ...state,
          selector: 'select',
          type: null
        }
      } else {
        return {
          ...state,
          selector: 'none',
          type: null
        }
      }
    case actionTypes.SET_ACTION_DRAW_POLYLINE:
      if (state.entity && state.entity.polyline) {
        switchPolylineColor({
          activeShapePoints: state.activeShapePoints,
          entity: state.entity,
          selector: state.selector,
          type: payload.type
        })

        return {
          ...state,
          selector: 'drawPolyline',
          type: payload.type
        }
      } else if (state.selector !== 'drawPolyline' || payload.type) {
        return {
          ...state,
          selector: 'drawPolyline',
          type: payload.type
        }
      } else {
        return {
          ...state,
          selector: 'none',
          type: null
        }
      }
    case actionTypes.DRAW_POLYLINE:
      return {
        ...state,
        histories: payload.histories,
        active: payload.index
      }
    case actionTypes.DRAW_UNDO_SUCCESS:
      return {
        ...payload
      }
    case actionTypes.DRAW_REDO_SUCCESS:
      return {
        ...payload
      }
    case actionTypes.DRAW_SAVE_SUCCESS:
      if (state.entity) {
        return {
          ...initialState,
          selector: state.selector,
          type: state.type
        }
      } else {
        return {
          ...state
        }
      }
    case actionTypes.DRAW_DELETE_SUCCESS:
      if (state.entity) {
        return {
          ...initialState
        }
      } else {
        return {
          ...state
        }
      }
    case actionTypes.DRAW_EDIT:
      if (state.selector !== 'drawEdit') {
        return {
          ...state,
          selector: 'drawEdit'
        }
      } else {
        return {
          ...state,
          selector: 'none'
        }
      }
    case actionTypes.GROUP_ENTITY:
      const newGroups = [ ...state.groups ]

      if (payload.entity) {
        const index = newGroups.indexOf(payload.entity)
        if (index === -1) {
          payload.entity.polyline.material = Cesium.Color.RED
          newGroups.push(payload.entity)
        } else {
          const properties = payload.entity.properties.getValue('')
          if (properties.type === 'ridge') {
            payload.entity.polyline.material = Cesium.Color.YELLOW
          } else if (properties.type === 'spur') {
            payload.entity.polyline.material = Cesium.Color.BLUE
          }

          newGroups.splice(index, 1)
        }
      }

      return {
        ...state,
        groups: newGroups
      }
    case actionTypes.UNGROUP_ENTITY:
      for (let i = 0; i < state.groups.length; i++) {
        const properties = state.groups[i].properties.getValue('')
        if (properties.type === 'ridge') {
          state.groups[i].polyline.material = Cesium.Color.YELLOW
        } else if (properties.type === 'spur') {
          state.groups[i].polyline.material = Cesium.Color.BLUE
        }
      }

      return {
        ...state,
        groups: []
      }
    case actionTypes.DRAW_UNDO_ERROR:
    case actionTypes.DRAW_REDO_ERROR:
    case actionTypes.DRAW_SAVE_ERROR:
    case actionTypes.DRAW_DELETE_ERROR:
      return {
        ...state,
        error
      }
    case actionTypes.RESET_TOOLBAR:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default toolbarReducer
