import * as actionTypes from '../../constants/store/actionTypes/legendbar'

export function addLegend(payload) {
  return {
    type: actionTypes.ADD_LEGEND,
    payload
  }
}

export function removeLegend(payload) {
  return {
    type: actionTypes.REMOVE_LEGEND,
    payload
  }
}

export function resetLegendbar(payload) {
  return {
    type: actionTypes.RESET_LEGENDBAR,
    payload
  }
}
