import styled from 'styled-components'

const DropdownLabel = styled.span.attrs((props) => {
  return {
    style: {
      color: props.dark && '#bdbdbd',
      top: props.pick ? '-1.3em' : '0.3em',
      left: props.pick ? 0 : '0.5em'
    }
  }
})`
  position: absolute;
  transition: all 330ms;
  pointer-events: none;
  font-size: 0.7em;
`

export default DropdownLabel
