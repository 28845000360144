// https://www.movable-type.co.uk/scripts/latlong.html
import * as Cesium from 'cesium'

import * as turf from '@turf/turf'

import {
  generateRectanglePosition,
  findPosition
} from './cesium'

import {
  clipGeochemicalData,
  unclipGeochemicalData
} from './cesiumMap/geochemical'

import {
  activateEventDefaultCesium,
  disactivateEventDefaultCesium
} from './cesium3DSection/changeCesiumDefaulEvent'

import {
  generateBlockPolygon,
  generateCrossBlockPolygon,
  generateTurfPolygonFromPolygon
} from './cesium3DSection/generatePolygon'

import {
  createBlockSection,
  createCentroid3dBlock,
  createCentroid3dCross,
  createCentroid3dSlice
} from './cesium3DSection/cesium3d'

import {
  getCentroidPolygon
} from './cesium3DSection/centroid'

import {
  polygonSliceByPolyline,
  pickSlice,
  pickFly
} from './cesium3DSection/sliceSection'

import {
  translatePlaneBlock,
  translatePlaneCross,
  translatePlaneSlice
} from './cesium3DSection/translate'

/**
 * This function will clear section editor ex. arrow
 * @param {Object} cesiumViewer - object cesiumViewer
 */
export function clearSectionEditor(cesiumViewer) {
  if (
    cesiumViewer
    && cesiumViewer.viewer
    && cesiumViewer.sectionStatus.sectionEditor.length
  ) {
    for (let i = 0; i < cesiumViewer.sectionStatus.sectionEditor.length; i++) {
      cesiumViewer.viewer.entities.remove(cesiumViewer.sectionStatus.sectionEditor[i])
    }

    cesiumViewer.sectionStatus.sectionEditor = []
  }
}

/**
 * This will clip all point data with single position
 * @param {Object} polygon the polygon of turf polygon
 */
export function clipPointData(polygon) {
  // edit performance it later, should index all the data that have been clipped
  clipGeochemicalData(polygon)
}

/**
 * This will unclip all data
 */
export function unclip() {
  // edit performance it later, should index all the data that have been clipped
  unclipGeochemicalData()
}

/**
 * This method will clear sectionBox
 * @param {Object} cesiumViewer - object cesiumViewer
 */
export function clearSectionBox(cesiumViewer) {
  const { viewer, sectionStatus } = cesiumViewer

  if (!sectionStatus.crossSection
    && !sectionStatus.blockSection
    && !sectionStatus.sliceSection
  ) {
    viewer.entities
      .remove(sectionStatus.sectionBox)
    
    sectionStatus.sectionBox = null

    viewer.scene.globe.clippingPlanes = undefined
  }
}

/**
 * This method will slicing
 * @param {Object} cesiumViewer - object cesiumViewer
 */
export function clearSlicing(cesiumViewer) {
  const { sectionStatus } = cesiumViewer

  sectionStatus.slicePick = false
    
  sectionStatus.slicePolygon.forEach(entity => {
    viewer.entities.remove(entity)
  })

  clearSectionEditor(cesiumViewer)

  sectionStatus.sliceSection = false
  sectionStatus.blockSection = true

  sectionStatus.slicingIndex = -1

  const points = generateBlockPolygon(sectionStatus.dragMovement)

  createBlockSection(cesiumViewer, cesiumViewer.viewer, points)
}

/**
 * This function will handle mouse down in section feature
 * @param event the event when mouse down triggered
 * @param {Object} cesiumViewer the object of cesiumViewer
 */
export function sectionMouseDownHandler(event, cesiumViewer) {
  const { viewer, sectionStatus } = cesiumViewer

  if (sectionStatus.selection) {
    try {
      if (sectionStatus.sectionEditor.length) {
        const pickedObjects = viewer.scene.drillPick(
          event.position
        )
  
        let editorSelected = null
  
        searchId:
        for (let i = 0; i < sectionStatus.sectionEditor.length; i++) {
          const sectionEditorId = sectionStatus.sectionEditor[i].id
          for (let j = 0; j < pickedObjects.length; j++) {
            if (pickedObjects[j].id) {
              if (pickedObjects[j].id.id === sectionEditorId) {
                editorSelected = pickedObjects[j].id
  
                break searchId
              }
            }
          }
        }
  
        if (!editorSelected) {
          return
        } else {
          sectionStatus.transform.direction = editorSelected.properties.value.getValue('')
          sectionStatus.transform.entity = editorSelected
          sectionStatus.transform.reference = editorSelected.properties.reference.getValue('')

          if (sectionStatus.timer) {
            clearTimeout(sectionStatus.timer)
            sectionStatus.timer = null

            sectionStatus.transform.click = 2
          } else {
            sectionStatus.timer = setTimeout(() => {
              sectionStatus.transform.click = 1

              clearTimeout(sectionStatus.timer)
              sectionStatus.timer = null
            }, 330)
          }
        }
  
        viewer.scene.screenSpaceCameraController.enableRotate = false
        viewer.scene.screenSpaceCameraController.enableTilt = false
      }
    } catch (error) {
      throw new Error(error)
      // outside position
    }
    
    return
  }

  if (
    sectionStatus.blockSection
    || sectionStatus.crossSection
    || sectionStatus.sliceSection
  ) {
    try {
      const ellipsoid = viewer.scene.globe.ellipsoid

      if (!sectionStatus.sliceSection) {
        sectionStatus.dragMovement.start = null
        sectionStatus.dragMovement.end = null
        sectionStatus.dragMovement.top = 2000
        sectionStatus.dragMovement.bottom = -2000
        sectionStatus.dragMovement.crossWidth.left = 10
        sectionStatus.dragMovement.crossWidth.right = 10
        
        sectionStatus.dragMovement.start = findPosition({
          event,
          ellipsoid,
          type: 'both'
        })
      } else {
        if (!sectionStatus.slicePick) {
          sectionStatus.sliceMovement.start = findPosition({
            event,
            ellipsoid,
            type: 'both'
          })
        } else {
          sectionStatus.sliceMovement.pick = findPosition({
            event,
            ellipsoid,
            type: 'both'
          })
        }
      }

      sectionStatus.holdClick = true
    } catch (error) {
      // invalid position
    }

    disactivateEventDefaultCesium(viewer)
  }
}

/**
 * This function will handle mouse move in section feature
 * @param event the event when mouse move triggered
 * @param {Object} cesiumViewer the object of cesiumViewer
 */
export function sectionMouseMoveHandler(event, cesiumViewer) {
  const { viewer, sectionStatus } = cesiumViewer

  // for editor handler
  if (sectionStatus.selection) {
    if (sectionStatus.transform.entity && sectionStatus.transform.click) {
      try {
        if (sectionStatus.blockSection) {
          translatePlaneBlock(event, cesiumViewer)
        } else if (sectionStatus.crossSection) {
          translatePlaneCross(event, cesiumViewer)
        } else if (sectionStatus.sliceSection) {
          translatePlaneSlice(event, cesiumViewer)
        }
      } catch (error) {
        throw error
        // outside position
      }
    }
    return
  }

  // for section handler
  if (sectionStatus.blockSection && sectionStatus.holdClick) {
    const ellipsoid = viewer.scene.globe.ellipsoid

    event.position = event.endPosition

    try {
      const endPosition = findPosition({
        event,
        ellipsoid,
        type: 'long/lat'
      })

      sectionStatus.dragMovement.end = endPosition

      const {
        west,
        south,
        east,
        north
      } = generateRectanglePosition(endPosition, sectionStatus.dragMovement)

      sectionStatus.rectangle = new Cesium.Rectangle.fromDegrees(
        west,
        south,
        east,
        north
      )

      if (!sectionStatus.blockRectangle) {
        sectionStatus.blockRectangle = viewer.entities.add({
          ellipsoid: Cesium.Ellipsoid.WGS84,
          rectangle: {
            coordinates: new Cesium.CallbackProperty(
              () => {
                return sectionStatus.rectangle
              },
              false
            ),
            material: Cesium.Color.WHITE.withAlpha(0.05),
            extrudedHeight: sectionStatus.dragMovement.top,
            height: sectionStatus.dragMovement.bottom,
            outline: true, // height must be set for outline to display
            outlineColor: Cesium.Color.WHITE,
          }
        })
      }
    } catch (error) {
      throw error
      // invalid position
      return
    }
  } else if (sectionStatus.crossSection && sectionStatus.holdClick) {
    const ellipsoid = viewer.scene.globe.ellipsoid

    event.position = event.endPosition

    try {
      const endPosition = findPosition({
        event,
        ellipsoid,
        type: 'long/lat'
      })

      sectionStatus.dragMovement.end = endPosition

      sectionStatus.linePosition = [
        Cesium.Cartesian3.fromDegrees(
          sectionStatus.dragMovement.start.longitude,
          sectionStatus.dragMovement.start.latitude
        ),
        Cesium.Cartesian3.fromDegrees(
          sectionStatus.dragMovement.end.longitude,
          sectionStatus.dragMovement.end.latitude
        )
      ]

      if (!sectionStatus.crossLine) {
        sectionStatus.crossLine = viewer.entities.add({
          ellipsoid: Cesium.Ellipsoid.WGS84,
          polyline: {
            width: 1,
            material: Cesium.Color.WHITE,
            positions: new Cesium.CallbackProperty(
              () => {
                return sectionStatus.linePosition
              },
              false
            ),
            clampToGround: true
          }
        })
      }
    } catch (error) {
      throw error
      // invalid position
      return
    }

    disactivateEventDefaultCesium(viewer)
  } else if (sectionStatus.sliceSection && !sectionStatus.slicePick && sectionStatus.holdClick) {
    const ellipsoid = viewer.scene.globe.ellipsoid

    event.position = event.endPosition

    try {
      const endPosition = findPosition({
        event,
        ellipsoid,
        type: 'long/lat'
      })

      sectionStatus.sliceMovement.end = endPosition

      sectionStatus.linePosition = [
        Cesium.Cartesian3.fromDegrees(
          sectionStatus.sliceMovement.start.longitude,
          sectionStatus.sliceMovement.start.latitude,
          sectionStatus.dragMovement.top
        ),
        Cesium.Cartesian3.fromDegrees(
          sectionStatus.sliceMovement.end.longitude,
          sectionStatus.sliceMovement.end.latitude,
          sectionStatus.dragMovement.top
        )
      ]

      if (!sectionStatus.sliceLine) {
        sectionStatus.sliceLine = viewer.entities.add({
          ellipsoid: Cesium.Ellipsoid.WGS84,
          wall: {
            width: 1,
            material: Cesium.Color.WHITE.withAlpha(0.5),
            positions: new Cesium.CallbackProperty(
              () => {
                return sectionStatus.linePosition
              },
              false
            ),
            minimumHeights: [sectionStatus.dragMovement.bottom, sectionStatus.dragMovement.bottom],
            clampToGround: true
          }
        })
      }
    } catch (error) {
      throw error
      // invalid position
      return
    }

    disactivateEventDefaultCesium(viewer)
  }
}

/**
 * This function will handle mouse up in section feature
 * @param {Object} cesiumViewer the object of cesiumViewer
 * @returns 
 */
export function sectionMouseUpHandler(cesiumViewer) {
  const { viewer, sectionStatus } = cesiumViewer

  // for editor translate handle
  if (sectionStatus.selection) {
    if (sectionStatus.transform.entity) {
      sectionStatus.transform.entity = null
      sectionStatus.transform.direction = ''

      if (sectionStatus.transform.reference) {
        sectionStatus.transform.reference = ''

        sectionStatus.sectionEditor.forEach((editor) => {
          viewer.entities.remove(editor)
        })

        let polygon = []

        if (sectionStatus.crossSection) {
          polygon = generateCrossBlockPolygon(sectionStatus.dragMovement)
        } else {
          polygon = generateBlockPolygon(sectionStatus.dragMovement)
        }

        let centroidProperties = null

        if (sectionStatus.sliceSection && !sectionStatus.slicePick) {
          centroidProperties = getCentroidPolygon(polygon, sectionStatus.dragMovement)
        } else {
          const turfPolygon = generateTurfPolygonFromPolygon(polygon)

          const turfCentroidPolygon = turf.centroid(turfPolygon)
          
          centroidProperties = {
            longitude: turfCentroidPolygon.geometry.coordinates[0],
            latitude: turfCentroidPolygon.geometry.coordinates[1],
            height: (sectionStatus.dragMovement.top + sectionStatus.dragMovement.bottom) / 2
          }
        }

        if (sectionStatus.blockSection) {
          createCentroid3dBlock(cesiumViewer, centroidProperties, sectionStatus.dragMovement)
        } else if (sectionStatus.crossSection) {
          createCentroid3dCross(cesiumViewer, centroidProperties, sectionStatus.dragMovement)
        } else if (sectionStatus.sliceSection) {
          createCentroid3dSlice(cesiumViewer, centroidProperties, sectionStatus.sliceMovement)
        }
      }

      viewer.scene.screenSpaceCameraController.enableRotate = true
      viewer.scene.screenSpaceCameraController.enableTilt = true
    }
    return
  }

  // for section handler
  if (sectionStatus.sectionBox) {
    if (!sectionStatus.sliceSection) {
      viewer.entities.remove(sectionStatus.sectionBox)
      sectionStatus.sectionBox = null
    }
  }

  if (!sectionStatus.sliceSection) {
    clearSectionEditor(cesiumViewer)
  }
  
  if (sectionStatus.blockSection && sectionStatus.holdClick) {
    if (sectionStatus.blockRectangle) {
      viewer.entities.remove(sectionStatus.blockRectangle)
      sectionStatus.blockRectangle = null
    }
  } else if (sectionStatus.crossSection && sectionStatus.holdClick) {
    if (sectionStatus.crossLine) {
      viewer.entities.remove(sectionStatus.crossLine)
      sectionStatus.crossLine = null
    }
  }

  if (!sectionStatus.sliceSection) {
    if (sectionStatus.crossSection) {
      const polygon = generateCrossBlockPolygon(sectionStatus.dragMovement)

      createBlockSection(cesiumViewer, polygon)
    } else {
      const polygon = generateBlockPolygon(sectionStatus.dragMovement)

      createBlockSection(cesiumViewer, polygon)
    }
  } else if (!sectionStatus.sliceMovement.end) {
    // nothing happened yet
  } else if (!sectionStatus.slicePick) {
    polygonSliceByPolyline(cesiumViewer)
  } else {
    pickSlice(cesiumViewer, sectionStatus.sliceMovement.pick)
    pickFly(cesiumViewer)

    clearSectionEditor(cesiumViewer)
    
    const polygon = generateBlockPolygon(sectionStatus.dragMovement)
    const centroidProperties = getCentroidPolygon(polygon, sectionStatus.dragMovement)

    createCentroid3dSlice(
      cesiumViewer,
      centroidProperties,
      sectionStatus.sliceMovement
    )
  }
  
  activateEventDefaultCesium(viewer)

  sectionStatus.holdClick = false
}
