import React, { useRef } from 'react'

import styled from 'styled-components'

import ClickOutsideHandler from '../../../helpers/ClickOutsideHandler'

const Wrapper = styled.div`
  position: fixed;
  left: 23.2em;
  width: 20em;
  max-height: ${props => props.maxHeight};
  top: calc(${props => !props.bottom && props.top});
  bottom: ${props => props.bottom};
  background-color: white;
  padding: 0.5em;
  border-top-left-radius: 7.5px;
  border-top-right-radius: 7.5px;
  border-bottom-left-radius: 7.5px;
  border-bottom-right-radius: 7.5px;
  overflow-y: hidden;
  transition: all 330ms;
`

/**
 * This function will create tooltip menu
 * @param children is the children of this component
 * @param exceptRefs is array of ref that won't trigger click outside event
 * @param clickOutsideHandler is the event when the outside componnent click
 * @param top is to set the top position
 * @param maxHeight to set maximum height of tooltip
 * @param bottom to set based on bottom
 */
function TooltipWrapper({
  clickOutsideHandler,
  exceptRefs = [],
  children,
  top = '60vh',
  maxHeight = '20em',
  bottom = ''
}) {
  const tooltipRef = useRef(null)

  // for close if click outside the div scope
  ClickOutsideHandler({
    ref: tooltipRef,
    eventHandler: clickOutsideHandler,
    exceptRefs: [...exceptRefs, tooltipRef]
  })

  return (
    <Wrapper
      ref={tooltipRef}
      top={top}
      maxHeight={maxHeight}
      bottom={bottom}
    >
      {children}
    </Wrapper>
  )
}

export default TooltipWrapper
