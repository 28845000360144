import cesiumViewer from '../../../apis/cesiumViewer'

import * as Cesium from 'cesium'

import {
  toGeographic
} from './geoutm'

import store from '../../../store'

import {
  addToDisplay
} from '../../../store/actions/display'

export function renderDrag(event) {
  const files = event.dataTransfer.files
  if (files.length) {
    async function readData() {
      const reader = new FileReader()
  
      reader.addEventListener('load', async (event) => {
        function getGeographicsInfo(vertice, zone) {
          if (!vertice) {
            return []
          }

          const [ lat, long ] = toGeographic(vertice.x, vertice.y, zone)
          const height = vertice.z

          return [ long, lat, height ]
        }

        if (window.Worker) {
          if (files[0].name.toLowerCase().includes('.zip')) {
            const myWorker = new Worker('./worker-shp.js')
  
            myWorker.postMessage(event.target.result)
    
            myWorker.onmessage = async function (event) {
              // const points = await loadGeochemicalGeoJSON({
              //   geojson: event.data,
              //   type: 'test',
              //   clampToGround: true
              // })

              // cesiumViewer.viewer.scene.primitives.add(points)

              const data = event.data

              function recursionData(coordinate) {
                if (typeof coordinate[0] === 'object') {
                  for (let i = 0; i < coordinate.length; i++) {
                    recursionData(coordinate[i])
                  }
                } else {
                  const geographic = {
                    x: coordinate[0],
                    y: coordinate[1]
                  }

                  if (coordinate[2]) {
                    geographic.z = coordinate[2]
                  } else {
                    geographic.z = 0
                  }

                  coordinate = getGeographicsInfo(geographic, '51N')
                }
              }

              data.features.forEach((feature) => {
                recursionData(feature.geometry.coordinates)
              })

              try {
                const map = {
                  id: data.fileName,
                  name: data.fileName,
                  category: '',
                  entityName: data.fileName,
                  type: null,
                  geom: null,
                  filter: null,
                  // D3 color
                  colorScale: 'interpolateRdYlBu',
                  // for color purpose
                  inverse: true,
                  colorEntities: [],
                  alpha: 1,
                  defaultColor: {},
                  colorScaling: 'linear',
                  colorTemplates: [],
                  selectedTemplate: null,
                  extrudedStatus: false,
                  properties: {},
                  ellipsoidStatus: false,
                  enhance: false
                }
                
                const dataSource = await new Cesium.GeoJsonDataSource(map.entityName).load(data, {
                  clampToGround: true
                })

                cesiumViewer.viewer.dataSources.add(dataSource)

                store.dispatch(
                  addToDisplay({
                    map
                  })
                )
              } catch (error) {
                throw error
              }

              // edit here
            }
          } else if (files[0].name.toLowerCase().includes('.dxf')) {
            const myWorker = new Worker('./worker-dxf.js')
  
            myWorker.postMessage(event.target.result)
    
            myWorker.onmessage = async function (event) {
              const entities = event.data.entities

              const dataSources = new Cesium.CustomDataSource()

              
              for (let i = 0; i < entities.length; i++) {
                const convertArr = []

                const vertices = entities[i].vertices

                for (let j = 0; j < vertices.length; j++) {
                  if (vertices[j].faceA === undefined) {
                    let id = parseInt(vertices[j].handle, 16)
  
                    convertArr[id] = vertices[j]
  
                    const geographic = getGeographicsInfo(vertices[j], '51N')
  
                    dataSources.entities.add({
                      position: new Cesium.Cartesian3.fromDegrees(
                        geographic[0],
                        geographic[1],
                        geographic[2]
                      ),
                      point: {
                        color: Cesium.Color.WHITE,
                        pixelSize: 10
                      }
                    })
                  }
                }

                // edit here
                // console.log(convertArr)

                for (let j = 0; j < vertices.length; j++) {
                  if (vertices[j].faceA && vertices[j].faceB && vertices[j].faceC) {
                    if (!convertArr[vertices[j].faceA]) {
                      // console.log(vertices[j].faceA)
                      continue
                    }
                    if (!convertArr[vertices[j].faceB]) {
                      // console.log(vertices[j].faceB)
                      continue
                    }
                    if (!convertArr[vertices[j].faceC]) {
                      // console.log(vertices[j].faceC)
                      continue
                    }
  
                    try {
                      dataSources.entities.add({
                        polyline: {
                          positions: new Cesium.Cartesian3.fromDegreesArrayHeights([
                            ...getGeographicsInfo(convertArr[vertices[j].faceA], '51N'),
                            ...getGeographicsInfo(convertArr[vertices[j].faceB], '51N'),
                            ...getGeographicsInfo(convertArr[vertices[j].faceC], '51N')
                          ])
                        }
                      })
                    } catch (error) {
                      throw error
                    }
                  }
                }
              }

              cesiumViewer.viewer.dataSources.add(dataSources)
              
              // const points = await loadGeochemicalGeoJSON({
              //   geojson: event.data,
              //   type: 'test',
              //   clampToGround: true
              // })

              // cesiumViewer.viewer.scene.primitives.add(points)

              // const data = await Cesium.GeoJsonDataSource.load(event.data, {
              //   clampToGround: true
              // })

              // cesiumViewer.viewer.dataSources.add(data)

              // // console.log(data)
              // edit here
            }
          } else {
            // console.log('unsupported format')
            
          }
        }
      })
  
      reader.addEventListener('progress', (event) => {
        if (event.loaded && event.total) {
          // const percent = (event.loaded / event.total) * 100
          // // console.log(`Progress: ${Math.round(percent)}`)
        }
      })
  
      reader.readAsArrayBuffer(files[0])
    }
  
    readData()
  }
}
