import React, { useState } from 'react'

import { connect } from 'react-redux'

import {
  addMap,
  toggleEntity
} from '../../../../../../store/actions/catalog'

import {
  dragMap,
  dropMap
} from '../../../../../../store/actions/drag'

import CustomReactLoading from '../../../../../Loading/CustomReactLoading'

import ToggleIcon from '../../../shares/ToggleIcon'
import FlexWrapper from '../../../shares/FlexWrapper'
import ToolbarText from '../../../shares/ToolbarText'

function ToggleMap({
  map,
  layerTypeComponent,
  dragMap,
  dropMap,
  marginLeft,
  addMap,
  toggleEntity,
  subCategory,
  onProgress
}) {
  const [ loading, setLoading ] = useState({
    status: null,
    progress: null
  })

  function onDragStartCaptureHandler() {
    dragMap({
      map,
      event() {
        onClickHandler()
      }
    })
  }

  function onClickHandler() {
    if (map.dataSource) {
      toggleEntity({
        dataSource: map.dataSource
      })
    } else if(map.tilesetData) {
      toggleEntity({
        tilesetData: map.tilesetData
      })
    } else {
      setLoading((loading) => ({
        ...loading,
        status: 'fetch',
        progress: 'Pending...'
      }))

      addMap({
        category: map.category,
        subCategory: map.subcategory || null,
        map,
        fetchProgress(progress) {
          setLoading((loading) => ({
            ...loading,
            status: 'fetch',
            progress: progress
          }))
        },
        fetchFinish() {
          setLoading((loading) => ({
            ...loading,
            status: null,
            progress: null
          }))
        }
      })
    }
  }

  let text = null

  if (map.name) {
    text = map.name
  } else if (map.category) {
    text = map.category
  }

  if (onProgress) {
    text += ' (On Develop)'
  }

  return (
    <FlexWrapper
      center
      no-overflow
      draggable
      marginLeft={marginLeft}
      onDragStartCapture={onDragStartCaptureHandler}
      onDragEndCapture={dropMap}
    >
      {/* <div style={{ color: 'white' }} > → </div> */}
      {
        loading.status
          ? <CustomReactLoading
            width="1em"
            height="1em"
            status={loading.status}
            progress={loading.progress}
          />
          : <ToggleIcon
            toggle={
              map?.dataSource?.show || map?.tilesetData?.show
            }
            size="smaller"
            toggleAble
            type="check"
            onClick={onClickHandler}
          />
      }
      {layerTypeComponent}
      <ToolbarText
        width="17em"
        text={text}
        max={40}
      />
      {/* { loading.status ?  <div style={{ color: 'white' }} > × </div> : null } */}
    </FlexWrapper>
  )
}

function mapStateToProps({ catalog }) {
  return {
    catalog
  }
}

const mapDispatchToProps = {
  dragMap,
  dropMap,
  addMap,
  toggleEntity
}

export default connect(mapStateToProps, mapDispatchToProps)(ToggleMap)
