import React from 'react'

import FocusContainer, {
  Header,
  Paragraph
} from '../../StyledComponent'
import ChatPNG from '../../HelpImage/chat-admin.png'
import { LIST_HELP } from '../../constant'


function HelpChatAdmin(props) {
  return <>
    <FocusContainer {...props}>
      <Header>{LIST_HELP['chat'].detail.adminChat.header}</Header>
      <img src={ChatPNG} alt="Chat admin image" width="1100px" />
    </FocusContainer>
  </>
}

export default HelpChatAdmin
