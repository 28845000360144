import React, { Fragment, useEffect, useState } from 'react';
import ToggleButton from '../ToggleButton';
import Select from 'react-select';
import styled from 'styled-components';

const WrapperRowDropdown = styled.div`
    display:flex; 
    flex-direction:row; 
    justify-content:space-evenly;
`

const WrapperDropdown = styled.div`
    padding-left:8px;
    padding-top:4px;
    padding-bottom:0px;
    text-align:left; 
    vertical-align:top;
    font-size: 12px;
`

function SelectedData({...props}) {
    let{
        setSurfaceType,
        belt,
        prospect,
        project,
        moreFilter,
        moreFilterValue,
        width,
        setBeltSelect,
        setProjectSelect,
        setProspectSelect,
        setFilterType,
        setFilterValue
    }=props;
    const [toggleGeochemSubsurface, setToggleGeochemSubsurface]=useState(false)
    const [selectSurfaceTypeDD, setSelectSurfaceTypeDD] =useState({value:'soil', label:'Soil'})
    const [selectBeltDD, setSelectBeltDD] =useState({value:'all', label:'All'})
    const [selectProjectDD, setSelectProjectDD] =useState({value:'all', label:'All'})
    const [selectProspectDD, setSelectProspectDD] =useState({value:'all', label:'All'})
    const [selectFilterDD, setSelectFilterDD] =useState({value:'locality', label:'Locality'})
    const [selectFilterValueDD, setSelectFilterValueDD] =useState({value:'Torosik', label:'Torosik'})

    // const [surfaceType, setSurfaceType]=useState('soil')
    const selectSurface=[
        {
            value:'bleg',
            label:'BLEG'
        },{
            value:'rocks',
            label:'Rocks'
        },{
            value:'soil',
            label:'Soil'
        },{
            value:'ss',
            label:'Stream Sediment'
        }
    ]

    
    const optionsDropdown=(arr,type)=>{
        let dd=[]
        let add={}
        if(type==='all'){
            add = {
                value: 'all',
                label: 'All'
            }
        } else if(type==='none'){
            add = {
                value: 'none',
                label: 'None'
            }
        }
        arr.sort().map((i)=>{
            dd.push({
                value: i,
                label:i[0].toUpperCase()+i.slice(1).toLowerCase()
            })
        })
        return([add, ...dd])
    }

    let handleChangeSubsurface=()=>{
        setToggleGeochemSubsurface(!toggleGeochemSubsurface)
    }


    const customStyles = {
        control: base => ({
            ...base,
            padding:0,
            height: 20,
            minHeight: 20,
            width:width/2-20,
            borderRadius:10
        }),
        container:base=>({
            ...base,
            height:20,
            marginTop:2,
            color:'black'
        }),
        option:(provided,state)=>({
            ...provided,
            borderBottom:'1px dotted black',
            padding:3,
            fontSize:12
        }),
        menu:base=>({
            ...base,
            width:width/2-20,
            marginTop:2
        }),
        menuList:base =>({
            ...base,
            width:width/2-20,
            maxHeight:70
        }),
        valueContainer:base=>({
            fontSize:12,
            marginLeft:5,
            height: 20,
            padding:0,
            paddingTop:2,
        }),
        input:base=>({
            ...base,
            marginTop:2,
            paddingTop:0,
            marginBottom:7,
            fontSize:12
        }),
        dropdownIndicator:base=>({
            ...base,
            marginTop:0,
            paddingTop:0,
            height: 20,
        }),
        clearIndicator:base=>({
            ...base,
            marginTop:0,
            paddingTop:0,
            height: 20,
        }),
        indicatorSeparator:base=>({
            ...base,
            marginBottom:10,
            marginTop:2,
            height: 15,
        }),
        indicatorsContainer:base=>({
            ...base,
            height: 20,
        })
        
    };
    let noneValue = {value:'none', label:'None'}
    let allValue = {value:'all', label:'All'}

    return (
        <>
            <ToggleButton
            isOn={toggleGeochemSubsurface}
            handleToggle={handleChangeSubsurface}
            showOn='Subsurface'
            showOff='Surface'
            />
            {toggleGeochemSubsurface?
                <div>No Subsurface Data</div>
                :
                <>
                <WrapperRowDropdown>
                    <WrapperDropdown>
                        Surface Sample
                        <Select
                            styles={customStyles}
                            value={selectSurfaceTypeDD}
                            options={selectSurface}
                            onChange={(value)=>{
                                setSurfaceType(value.value)
                                setSelectSurfaceTypeDD(value)
                                setBeltSelect(allValue.value)
                                setSelectBeltDD(allValue)
                                setProjectSelect(allValue.value)
                                setSelectProjectDD(allValue)
                                setProspectSelect(allValue.value)
                                setSelectProspectDD(allValue)
                                setFilterType(noneValue.value)
                                setSelectFilterDD(noneValue)
                                setFilterValue(noneValue.value)
                                setSelectFilterValueDD(noneValue)
                            }}
                            isSearchable={true}
                        />
                    </WrapperDropdown>
                    <WrapperDropdown>
                        Belt
                        <Select
                            styles={customStyles}
                            value={selectBeltDD}
                            options={optionsDropdown(belt,'all')}
                            onChange={(value)=>{
                                setBeltSelect(value.value)
                                setSelectBeltDD(value)
                                setProjectSelect(allValue.value)
                                setSelectProjectDD(allValue)
                                setProspectSelect(allValue.value)
                                setSelectProspectDD(allValue)
                                setFilterType(noneValue.value)
                                setSelectFilterDD(noneValue)
                                setFilterValue(noneValue.value)
                                setSelectFilterValueDD(noneValue)
                            }}
                            isSearchable={true}
                        />
                    </WrapperDropdown>
                </WrapperRowDropdown>
                <WrapperRowDropdown>
                    <WrapperDropdown>
                        Project
                        <Select
                            styles={customStyles}
                            value={selectProjectDD}
                            options={optionsDropdown(project,'all')}
                            onChange={(value)=>{
                                setProjectSelect(value.value)
                                setSelectProjectDD(value)
                                setProspectSelect(allValue.value)
                                setSelectProspectDD(allValue)
                                setFilterType(noneValue.value)
                                setSelectFilterDD(noneValue)
                                setFilterValue(noneValue.value)
                                setSelectFilterValueDD(noneValue)
                            }}
                            isSearchable={true}
                        />
                    </WrapperDropdown>
                    <WrapperDropdown>
                        Prospect
                        <Select
                            styles={customStyles}
                            value={selectProspectDD}
                            options={optionsDropdown(prospect,'all')}
                            onChange={(value)=>{
                                setProspectSelect(value.value)
                                setSelectProspectDD(value)
                                setFilterType(noneValue.value)
                                setSelectFilterDD(noneValue)
                                setFilterValue(noneValue.value)
                                setSelectFilterValueDD(noneValue)
                            }}
                            isSearchable={true}
                        />
                    </WrapperDropdown>
                </WrapperRowDropdown>
                <WrapperRowDropdown>
                    <WrapperDropdown>
                        More Filter
                        <Select
                            styles={customStyles}
                            value={selectFilterDD}
                            options={optionsDropdown(moreFilter,'none')}
                            onChange={(value)=>{
                                
                                setSelectFilterValueDD(noneValue)
                                setFilterValue(noneValue.value)
                                setFilterType(value.value)
                                setSelectFilterDD(value)
                            }}
                            isSearchable={true}
                        />
                    </WrapperDropdown>
                    <WrapperDropdown>
                        Filter Value
                        <Select
                            styles={customStyles}
                            value={selectFilterValueDD}
                            options={optionsDropdown(moreFilterValue,'none')}
                            onChange={(value)=>{
                                setFilterValue(value.value)
                                setSelectFilterValueDD(value)
                            }}
                            isSearchable={true}
                        />
                    </WrapperDropdown>
                </WrapperRowDropdown>
                </>
            }
        </>
    )
}

export default SelectedData;