export const GLOBE_DATA_REFERENCES = [
  {
    id: 0,
    // name: 'Tin',
    displayName: 'Tin',
    name:'tin'
  },
  {
    id: 1,
    // name: 'Iron',
    displayName: 'Iron',
    name:'iron'
  },
  {
    id: 2,
    // name: 'Manganesse',
    displayName: 'Manganesse',
    name:'manganesse'
  },
  {
    id: 3,
    // name: 'Lead Zync',
    displayName: 'Lead Zinc',
    name: 'lead_zinc'
  },
  {
    id: 4,
    // name: 'Gold Copper Silver Molybdenum',
    displayName: 'Gold Copper Silver',
    name: 'copper_gold_silver'
  },
  {
    id: 5,
    // name: 'Bauxite',
    displayName: 'Bauxite',
    name: 'bauxite'
  },
  {
    id: 6,
    // name: 'Nickel',
    displayName: 'Nickel',
    name: 'nickel'
  },
  {
    id: 7,
    // name: 'Coal',
    displayName: 'Coal',
    name: 'coal'
  }
] // button of reference

export const RANGE_DEGREE = 41.15 // degree buton distance

// Globe Menubar Modifier
export const GLOBE_SCROLL_MODIFIER = 10 // for smooth the movement purpose
export const GLOBE_MIDDLE_BUTTON_MODIFIER = 3 // for smooth hold middle button
