import React, { useRef, useEffect } from 'react'

import * as d3 from 'd3'

import getRGBA from '../helpers/getRGBA'

import { generateColorGradient } from '../../Layerbar/helpers'

/**
 * This component will create legend scale for numerical data
 * @param {Object} map is the selected map to render
 * @param {Object} groupEntities is current selected groupEntities object
 * @param {Array} keys is list key that need to be show on the map
 */
function LegendScale({
  map,
  groupEntities,
  keys
}) {
  const scaleRef = useRef(null)
  let legendSvg = null
  let tickSvg = null
  let textSvg = null

  function findMaxWidth(groupEntities) {
    let max = 0
    const multiplier = 12

    for (let group in groupEntities.group) {
      const [ bottom, top ] = group.split(' - ')

      if (bottom.length > max) {
        max = bottom.length
      }

      if (top.length > max) {
        max = top.length
      }
    }

    return max * multiplier
  }

  function findDomains(keys) {
    const unique = {}

    for (let key of keys) {
      const [ bottom, top ] = key.value.split(' - ')

      unique[bottom] = 0
      unique[top] = 0
    }

    return Object.keys(unique).map(str => Number(str)).sort((a, b) => a - b)
  }

  function prepareColor(map, colors) {
    const result = []

    if (map.cesiumLayer.inverse) {
      for (let i = 0; i < colors.length; i++) {
        result.push({
          color: colors[colors.length - i - 1].split(' ')[0],
          offset: colors[i].split(' ')[1]
        })
      }
    } else {
      for (let i = 0; i < colors.length; i++) {
        const [ color, offset ] = colors[i].split(' ')
        
        result.push({
          color,
          offset
        })
      }
    }

    return result
  }

  function generateGradientColor({
    legendSvg,
    scaleWidth,
    legendHeight,
    margin
  }) {
    let colors = generateColorGradient({
      colorScale: map.colorScale
    }).split(', ')

    colors = prepareColor(map, colors)

    // append gradient bar
    const gradient = legendSvg.append('defs')
      .append('linearGradient')
      .attr('id', 'gradient')
      .attr('x1', '0%') // bottom
      .attr('y1', '100%')
      .attr('x2', '0%') // to top
      .attr('y2', '0%')
      .attr('spreadMethod', 'pad')

    for (let i = 0; i < colors.length; i++) {
      gradient.append('stop')
        .attr('offset', colors[i].offset)
        .attr('stop-color', colors[i].color)
        .attr('stop-opacity', 1)
    }

    legendSvg.append('rect')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('width', scaleWidth)
      .attr('height', legendHeight)
      .style('fill', 'url(#gradient)')
      .attr('transform', `translate(0, ${margin / 2})`)
  }

  function generateDiscreteRect({
    legendSvg,
    y,
    unitHeight,
    scaleWidth,
    margin,
    color
  }) {
    legendSvg.append('rect')
      .attr('x1', 0)
      .attr('y', y)
      .attr('width', scaleWidth)
      .attr('height', unitHeight)
      .style('fill', color)
      .attr('transform', `translate(0, ${margin / 2})`)
  }

  function generateDiscreteColor({
    legendSvg,
    scaleWidth,
    legendHeight,
    margin,
    groupEntities,
    keys
  }) {
    let unitHeight = legendHeight / keys.length

    for (let i = 0; i < keys.length; i++) {
      const label = keys[keys.length - i - 1].label

      if (!groupEntities.style[label]) {
        continue
      }

      const color = getRGBA(groupEntities.style[label].color)

      generateDiscreteRect({
        legendSvg,
        y: i * unitHeight,
        unitHeight,
        scaleWidth,
        margin,
        color
      })
    }
  }

  function findRangeLinear(domains, legendHeight) {
    const ranges = []

    let max = domains[domains.length - 1]
    let min = domains[0]

    for (let i = 0; i < domains.length; i++) {
      ranges.push(
        (1 - (domains[i] - min) / (max - min)) * legendHeight
      )
    }

    return ranges
  }

  function findRangeDiscrete(domains, legendHeight) {
    const range = []

    if (domains.length === 1) {
      range.push(legendHeight)
    } else {
      for (let i = domains.length - 1; i >= 0; i--) {
        range.push(
          i * legendHeight / (domains.length - 1)
        )
      }
    }

    return range
  }

  function generateGradientTicks({
    legendSvg,
    legendHeight,
    scaleWidth,
    margin,
    keys
  }) {
    const domains = findDomains(keys)

    const range = findRangeDiscrete(domains, legendHeight)

    const legendScale = d3.scaleLinear()
      .domain(domains)
      .range(range)

    const legendAxis = d3.axisRight(legendScale)
      .tickValues(domains)
      .tickFormat(d3.format('.2f'))

    legendSvg.append('g')
      .attr('transform', `translate(${scaleWidth}, ${margin / 2 - 0.5})`)
      .call(legendAxis)
  }

  function generateDiscreteTicks({
    legendSvg,
    legendHeight,
    scaleWidth,
    margin,
    keys
  }) {
    const MARGIN_LEFT = 10
    let unitHeight = legendHeight / keys.length
    const LINE_LENGTH = 10
    const TEXT_MARGIN = 4

    tickSvg = legendSvg.append('g')
      .attr('transform', `translate(${scaleWidth}, ${margin / 2})`)

    for (let i = 0; i <= keys.length; i++) {
      tickSvg.append('line')
        .style('stroke', 'rgb(0, 0, 0)')
        .style('stroke-width', 0.1)
        .attr('x1', 0)
        .attr('y1', i * unitHeight)
        .attr('x2', LINE_LENGTH)
        .attr('y2', i * unitHeight)
    }

    for (let i = 0; i < keys.length; i++) {
      const label = keys[i].label

      textSvg = tickSvg.append('text')
        .attr('x', MARGIN_LEFT)
        .attr('y', function () {
          return ((keys.length - i - 1) + 0.5) * unitHeight + TEXT_MARGIN
        })
        .text(() => label)
    }
  }

  function renderLegendWithD3() {
    const legendHeight = 200 // height 200px
    const margin = 10
    const marginBottom = 10
    const legendWidth = 20 + findMaxWidth(groupEntities)
    const scaleWidth = 10

    legendSvg = d3.select(scaleRef.current)
      .attr('width', legendWidth + margin)
      .attr('height', legendHeight + margin + marginBottom)
      .attr('stroke-width', 0.1)
      .attr('stroke', 'rgb(0, 0, 0)')
      .attr('transform', `translate(${margin / 2}, ${margin / 2})`)
      .append('g')

    generateDiscreteColor({
      legendSvg,
      scaleWidth,
      legendHeight,
      margin,
      groupEntities,
      keys
    })

    generateDiscreteTicks({
      legendSvg,
      legendHeight,
      scaleWidth,
      margin,
      keys
    })
  }

  useEffect(() => {
    if (scaleRef.current) {
      renderLegendWithD3()
    }

    return () => {
      if (legendSvg) {
        legendSvg.remove()
        legendSvg = null
      }

      if (tickSvg) {
        tickSvg.remove()
        tickSvg = null
      }

      if (textSvg) {
        textSvg.remove()
        textSvg = null
      }
    }
  }, [scaleRef, groupEntities, keys])

  return <svg ref={scaleRef}></svg>
}

export default LegendScale
