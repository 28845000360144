import React from 'react'

import {
  ReactComponent as CheckIcon
} from '../../../../images/Icons/check-box.svg'

import {
  ReactComponent as EmptyIcon
} from '../../../../images/Icons/empty-box.svg'

import AlphaSlider from '../../../Sliders/AlphaSlider'

import styled from 'styled-components'

const Paragraph = styled.p`
  margin: 0;
`

function CheckForm({
  category,
  index,
  title,
  titleIndex,
  unselected = [],
  setUnselected,
  grade,
  changeGradeWeight
}) {
  return <div key={index}
    style={{
      margin: '0 0 0 0em',
      width: '100%'
    }}
  >
    {
      index === titleIndex
      && <h4 style={{
        margin: '0 0 0.5em 0'
      }}>{title}</h4>
    }
    <div style={{
      display: 'flex',
      alignItems: 'center'
    }}>
      {
        unselected.includes(category)
          ? <EmptyIcon
              height="0.8em"
              fill="goldenrod"
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {
                const cloneUnselected = []

                unselected.forEach((unselectedCategory) => {
                  if (category !== unselectedCategory) {
                    cloneUnselected.push(unselectedCategory)
                  }
                })

                setUnselected(cloneUnselected)
              }}
            />
          : <CheckIcon
              height="0.8em"
              fill="goldenrod"
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {
                const cloneUnselected = unselected.slice()

                cloneUnselected.push(category)

                setUnselected(cloneUnselected)
              }}
            />
      }
      <p style={{ margin: 0 }}>{category}</p>
    </div>
    {
      !unselected.includes(category)
        &&
        Object.keys(grade.weightScore[category]).map((attribute, index) => {
          if (attribute === 'databaseName') {
            return null
          }

          return <div
            key={index}
            style={{
              margin: '0 20em 0 1.5rem'
            }}
          >
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '12em'
            }}>
              <div style={{
                marginRight: '1em',
                width: '100%',
                display: 'flex',
                alignItems: 'center'
              }}>
                {
                  grade.weightScore[category][attribute].value === null
                  || grade.weightScore[category][attribute].value === undefined
                    ? <EmptyIcon
                        height="0.8em"
                        fill="goldenrod"
                        style={{
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          changeGradeWeight({
                            value: 0,
                            category,
                            attribute 
                          })
                        }}
                      />
                    : <CheckIcon
                        height="0.8em"
                        fill="goldenrod"
                        style={{
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          changeGradeWeight({
                            value: null,
                            category,
                            attribute 
                          })
                        }}
                      />
                }
                <Paragraph style={{ width: '15em', marginLeft: '0.4em' }}>{attribute}</Paragraph>
              </div>
              {
                grade.weightScore[category][attribute].value !== null
                && grade.weightScore[category][attribute].value !== undefined
                  &&
                  <div style={{ width: '13em', display: 'flex' }}>
                    <AlphaSlider
                      height="0.75em"
                      width="5em"
                      min={grade.weightScore[category][attribute].min}
                      max={grade.weightScore[category][attribute].max}
                      initialValue={grade.weightScore[category][attribute].value}
                      withText={false}
                      valueOnChange={(value) => {
                        if (!value) {
                          value = 0
                        }

                        if (value == 0 || (value && value > grade.weightScore[category][attribute].min) && value <= grade.weightScore[category][attribute].max) {
                          changeGradeWeight({
                            value,
                            category,
                            attribute
                          })
                        }
                      }}
                    />
                    
                    <input
                      type="text"
                      value={grade.weightScore[category][attribute].value}
                      onChange={(event) => {
                        let value = event.target.value

                        if (!value) {
                          value = 0
                        } else if (value.length === 2 && value[1] !== '.') {
                          value = value[1]
                        }

                        if (value == 0 || (value && value > grade.weightScore[category][attribute].min) && value <= grade.weightScore[category][attribute].max) {
                          changeGradeWeight({
                            value,
                            category,
                            attribute
                          })
                        }
                      }}
                      style={{
                        width: '3em',
                        height: '0.8em',
                        marginLeft: '0.5em'
                      }}
                    />
                    <p style={{ margin: '0 0 0 1em', width: '5em' }}>
                      ({grade.weightScore[category][attribute].min} - {grade.weightScore[category][attribute].max})
                    </p>
                  </div>
              }
            </div>
          </div>
        })
    }
  </div>
}

export default CheckForm
