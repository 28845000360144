export const SET_CATALOG = 'SET_CATALOG'
export const SET_CATALOG_SUCCESS = 'SET_CATALOG_SUCCESS'
export const SET_CATALOG_ERROR = 'SET_CATALOG_ERROR'

export const CHANGE_TYPE_LAYER = 'CHANGE_TYPE_LAYER'
export const CHANGE_TYPE_LAYER_SUCCESS = 'CHANGE_TYPE_LAYER_SUCCESS'
export const CHANGE_TYPE_LAYER_ERROR = 'CHANGE_TYPE_LAYER_ERROR'

export const FETCH_LIST_MAP = 'FETCH_LIST_MAP'
export const FETCH_LIST_MAP_SUCCESS = 'FETCH_LIST_MAP_SUCCESS'
export const FETCH_LIST_MAP_ERROR = 'FETCH_LIST_MAP_ERROR'

export const FETCH_MAP = 'FETCH_MAP'
export const FETCH_MAP_SUCCESS = 'FETCH_MAP_SUCCESS'
export const FETCH_MAP_ERROR = 'FETCH_MAP_ERROR'

export const ADD_MAP = 'ADD_MAP'
export const ADD_MAP_SUCCESS = 'ADD_MAP_SUCCESS'
export const ADD_MAP_ERROR = 'ADD_MAP_ERROR'

export const CHECK_LAYER_CATEGORY = 'CHECK_LAYER_CATEGORY'
export const CHECK_LAYER_CATEGORY_SUCCESS = 'CHECK_LAYER_CATEGORY_SUCCESS'
export const CHECK_LAYER_CATEGORY_ERROR = 'CHECK_LAYER_CATEGORY_ERROR'

export const UNCHECK_LAYER_CATEGORY = 'UNCHECK_LAYER_CATEGORY'
export const UNCHECK_LAYER_CATEGORY_SUCCESS = 'UNCHECK_LAYER_CATEGORY_SUCCESS'
export const UNCHECK_LAYER_CATEGORY_ERROR = 'UNCHECK_LAYER_CATEGORY_ERROR'

export const CHECK_SUB_LAYER_CATEGORY = 'CHECK_SUB_LAYER_CATEGORY'
export const CHECK_SUB_LAYER_CATEGORY_SUCCESS = 'CHECK_SUB_LAYER_CATEGORY_SUCCESS'
export const CHECK_SUB_LAYER_CATEGORY_ERROR = 'CHECK_SUB_LAYER_CATEGORY_ERROR'

export const UNCHECK_SUB_LAYER_CATEGORY = 'UNCHECK_SUB_LAYER_CATEGORY'
export const UNCHECK_SUB_LAYER_CATEGORY_SUCCESS = 'UNCHECK_SUB_LAYER_CATEGORY_SUCCESS'
export const UNCHECK_SUB_LAYER_CATEGORY_ERROR = 'UNCHECK_SUB_LAYER_CATEGORY_ERROR'

export const TOGGLE_ENTITY = 'TOGGLE_ENTITY'
export const TOGGLE_ENTITY_SUCCESS = 'TOGGLE_ENTITY_SUCCESS'
export const TOGGLE_ENTITY_ERROR = 'TOGGLE_ENTITY_ERROR'

export const REMOVE_MAP = 'REMOVE_MAP'
export const REMOVE_MAP_SUCCESS = 'REMOVE_MAP_SUCCESS'
export const REMOVE_MAP_ERROR = 'REMOVE_MAP_ERROR'

export const REMOVE_ALL_MAP = 'REMOVE_ALL_MAP'
export const REMOVE_ALL_MAP_SUCCESS = 'REMOVE_ALL_MAP_SUCCESS'
export const REMOVE_ALL_MAP_ERROR = 'REMOVE_ALL_MAP_ERROR'

export const REMOVE_PROJECT_MAP = 'REMOVE_PROJECT_MAP'
export const REMOVE_PROJECT_MAP_SUCCESS = 'REMOVE_PROJECT_MAP_SUCCESS'
export const REMOVE_PROJECT_MAP_ERROR = 'REMOVE_PROJECT_MAP_ERROR'

export const RESET_CATALOG = 'RESET_CATALOG'
