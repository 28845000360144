import React, { useEffect, useState } from 'react'
import {
  GLOBE_SCROLL_MODIFIER,
  GLOBE_DATA_REFERENCES,
  RANGE_DEGREE
} from '../../../constants/Menubars/GlobeMenubar'

import { connect } from 'react-redux'

import GlobeDetailButton from '../../Buttons/GlobeDetailButton'

import {
  middleScroll,
  scroll,
  mouseMove
} from './helpers'

import './GlobeMenubar.css'

import GlobeGIF from '../../../images/Globes/globe.gif'

function GlobeMenubar ({
  sites,
  sidebarState
}) {
  const [ scrollCount, setScrollCount ] = useState({
    targetPosition: 0,
    currentPosition: 0,
    wait: false
  })

  const [ middleHold, setMiddleHold ] = useState(false)
  const [ cursorY, setCursorY ] = useState(0)
  const [ timer, setTimer ] = useState(null)

  let initialDegree = 582 + 30 * RANGE_DEGREE // to make position gold in the center, added data will change the 624

  function reverseLoop(geoDataReferences) {
    const result = []
  
    for (let i = geoDataReferences.length - 1; i >= 0; i--) {
      const data = geoDataReferences[i]
      initialDegree += RANGE_DEGREE
      result.push(<GlobeDetailButton
        key={data.id}
        data={data}
        initialDegree={initialDegree}
        scrollCount={scrollCount.currentPosition}
      />)
    }
  
    return result
  }

  function renderAnimate () {
    if (sidebarState.menu.animate) {
      if (sidebarState.menu.status) {
        return 'animate-toggle-in'
      } else {
        return 'animate-toggle-out'
      }
    } else {
      return null
    }
  }

  useEffect(() => {
    function initialScrollCount () {
      let currentIndex = 4 // 4 is gold
  
      if (sites.commodity) {
        for (let i = 0; i < GLOBE_DATA_REFERENCES.length; i++) {
          if (GLOBE_DATA_REFERENCES[i].name === sites.commodity) {
            currentIndex = GLOBE_DATA_REFERENCES[i].id
            break
          }
        }
      }
  
      return 2 * (currentIndex - 4) * GLOBE_SCROLL_MODIFIER
    }

    setScrollCount({
      ...scrollCount,
      currentPosition: initialScrollCount()
    })
  }, [ sites.commodity ])
  
  return <>
    <div
      style={{
        animationName: renderAnimate(),
        left: sidebarState.menu.status ? null : '-100%'
      }}
      className="globe-flex"
      onMouseDown={
        sidebarState.menu.status
          ? (event) => { middleScroll(
            event,
            middleHold,
            setMiddleHold,
            setCursorY
          ) }
          : null
      }
      onWheel={
        sidebarState.menu.status
          ? (event) => { scroll(
            event,
            scrollCount,
            setScrollCount,
            timer,
            setTimer
          ) }
          : null
      }
      onMouseMove={
        sidebarState.menu.status
          ? (event) => { mouseMove(
            cursorY,
            event,
            middleHold,
            scrollCount,
            setCursorY,
            setScrollCount
          ) }
          : null
      }
    >
      <img className="globe-img" src={ GlobeGIF } width="70%" alt="Globe"/>
      <div className="grey-screen-circle"></div>
      <div className="gold-screen-circle"></div>
      {
        reverseLoop(GLOBE_DATA_REFERENCES)
      }
    </div>
  </>
}

function mapStateToProps ({ sites, sidebarState }) {
  return {
    sidebarState,
    sites
  }
}

export default connect(mapStateToProps, null)(GlobeMenubar)
