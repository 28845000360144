import * as smartView from '../smartView'
import * as prospectRnR from '../prospectivityMap/ratingAndRanking'
import * as depositTypePrediction from '../prospectivityMap/depositTypePrediction'
import * as regionalGeologyLithology from '../geologicalMapSeries/regionalGeologyLithology'
import * as regionalGeologyAge from '../geologicalMapSeries/regionalGeologyLithology'
import * as projectGeology from '../geologicalMapSeries/projectScaleGeology'
import * as airborneRadiometric from '../geophysicsMapSeries/airborneRadiometric'
import * as airborneMagnetic from '../geophysicsMapSeries/airborneMagnetic'
import * as airborneGravity from '../geophysicsMapSeries/airborneGravity'
import * as magnetic from '../geophysicsMapSeries/magnetic'
import * as gravity from '../geophysicsMapSeries/gravity'
import * as inducedPolarization from '../geophysicsMapSeries/inducedPolarization'
import * as CSAMT from '../geophysicsMapSeries/CSAMT'
import * as geochemicalBleg from '../geochemicalMapSeries/bleg'
import * as geochemicalStreamSediment from '../geochemicalMapSeries/streamSediment'
import * as geochemicalRock from '../geochemicalMapSeries/rock'
import * as geochemicalSoil from '../geochemicalMapSeries/soil'
import * as regionalAlteration from '../alterationMapSeries/regionalScaleAlteration'
import * as projectAlteration from '../alterationMapSeries/projectScaleAlteration'
import * as regionalGeologyStructure from '../structuralGeologyMapSeries/regionalScaleStructure'
import * as projectGeologyStructure from '../structuralGeologyMapSeries/projectScaleStructure'
import * as interpretedLineament from '../intrepretedLineamentsGeology'
import * as remoteSensingClay from '../remoteSensingMineralMap/clayGroups'
import * as remoteSensingIronOxide from '../remoteSensingMineralMap/ironOxideGroups'
import * as mineralOccurrences from '../occurrences/mineralOccurrences'
import * as mine from '../occurrences/mine'
import * as illegalMine from '../occurrences/illegalMine'
import * as earthquakeUSGS from '../hazardMaps/earthquakeUSGS'
import * as earthquakes from '../hazardMaps/earthquakes'
import * as landslides from '../hazardMaps/landslides'
import * as tsunami from '../hazardMaps/tsunami'
import * as volcanicEruption from '../hazardMaps/volcanicEruption'
import * as activeFault from '../hazardMaps/activeFault'
import * as forestry from '../forestryMaps'
import * as tenement from '../tenementMaps'
import * as coastlines from '../accessibilityAndInfrastructureMaps/coastlines'
import * as gasStation from '../accessibilityAndInfrastructureMaps/gasStation'
import * as jetty from '../accessibilityAndInfrastructureMaps/jetty'
import * as airport from '../accessibilityAndInfrastructureMaps/airport'
import * as oilDepot from '../accessibilityAndInfrastructureMaps/oilDepot'
import * as smelter from '../accessibilityAndInfrastructureMaps/smelter'
import * as powerGenerator from '../accessibilityAndInfrastructureMaps/powerGenerator'
import * as administration from '../accessibilityAndInfrastructureMaps/administration'
import * as river from '../accessibilityAndInfrastructureMaps/river'
import * as road from '../accessibilityAndInfrastructureMaps/road'
import * as settlement from '../accessibilityAndInfrastructureMaps/settlement'
import * as drillholeTrace from '../drillhole/drillholeTrace'
import * as drillholeLithology from '../drillhole/lithology'
import * as drillholeAlteration from '../drillhole/alteration'
import * as drillholeAssay from '../drillhole/assay'
import * as drillholeRQD from '../drillhole/rqd'
import * as geologicalModel from '../3DModel/geologicalModel'
import * as implicitModel from '../3DModel/implicitModel'

export const STYLE_REFERENCES = [
  {
    category: 'Smart View',
    subCategory: null,
    getColor: (entity, { map, entities }) => {
      const {
        currentMax,
        currentMin
      } = smartView.getCurrentMinAndMax({
        entities
      })

      return smartView.getColor(entity, {
        map,
        entities,
        currentMax,
        currentMin
      })
    }
  },
  {
    category: 'Prospectivity map',
    subCategory: 'Rating and ranking',
    getColor: (entity, { map, entities }) => {
      const currentMax = prospectRnR.getCurrentMax({
        entities: entities
      })

      return prospectRnR.getColor(entity, {
        map,
        currentMax
      })
    }
  },
  {
    category: 'Prospectivity map',
    subCategory: 'Deposit type prediction',
    getColor: depositTypePrediction.getColor
  },
  {
    category: 'Geological map series',
    subCategory: 'Regional geology lithology',
    getColor: regionalGeologyLithology.getColor
  },
  {
    category: 'Geological map series',
    subCategory: 'Regional geology age',
    getColor: regionalGeologyAge.getColor
  },
  {
    category: 'Geological map series',
    subCategory: 'Project scale geology',
    getColor: projectGeology.getColor
  },
  {
    category: 'Geophysics map series',
    subCategory: 'Airborne Radiometric',
    getColor: airborneRadiometric.getColor
  },
  {
    category: 'Geophysics map series',
    subCategory: 'Airborne magnetic',
    getColor: airborneMagnetic.getColor
  },
  {
    category: 'Geophysics map series',
    subCategory: 'Airborne Gravity',
    getColor: airborneGravity.getColor
  },
  {
    category: 'Geophysics map series',
    subCategory: 'Magnetic',
    getColor: magnetic.getColor
  },
  {
    category: 'Geophysics map series',
    subCategory: 'Gravity',
    getColor: gravity.getColor
  },
  {
    category: 'Geophysics map series',
    subCategory: 'Induced polarization',
    getColor: inducedPolarization.getColor
  },
  {
    category: 'Geophysics map series',
    subCategory: 'CSAMT',
    getColor: CSAMT.getColor
  },
  {
    category: 'Geochemical map series',
    subCategory: 'BLEG',
    getColor: geochemicalBleg.getColor
  },
  {
    category: 'Geochemical map series',
    subCategory: 'Stream sediment',
    getColor: geochemicalStreamSediment.getColor
  },
  {
    category: 'Geochemical map series',
    subCategory: 'Rock',
    getColor: geochemicalRock.getColor
  },
  {
    category: 'Geochemical map series',
    subCategory: 'Soil',
    getColor: geochemicalSoil.getColor
  },
  {
    category: 'Alteration map series',
    subCategory: 'Regional scale alteration',
    getColor: regionalAlteration.getColor
  },
  {
    category: 'Alteration map series',
    subCategory: 'Project scale alteration',
    getColor: projectAlteration.getColor
  },
  {
    category: 'Structural geology map series',
    subCategory: 'Regional scale structure',
    getColor: regionalGeologyStructure.getColor
  },
  {
    category: 'Structural geology map series',
    subCategory: 'Project scale structure',
    getColor: projectGeologyStructure.getColor
  },
  {
    category: 'Interpreted lineaments geology',
    subCategory: null,
    getColor: interpretedLineament.getColor
  },
  {
    category: 'Remote sensing mineral map',
    subCategory: 'Clay groups',
    getColor: remoteSensingClay.getColor
  },
  {
    category: 'Remote sensing mineral map',
    subCategory: 'Iron oxide groups',
    getColor: remoteSensingIronOxide.getColor
  },
  {
    category: 'Occurrences',
    subCategory: 'Mineral occurrences',
    getColor: mineralOccurrences.getColor,
    getIcon: mineralOccurrences.getIcon
  },
  {
    category: 'Occurrences',
    subCategory: 'Mine',
    getColor: mine.getColor,
    getIcon: mine.getIcon
  },
  {
    category: 'Occurrences',
    subCategory: 'Illegal mine',
    getColor: null,
    getIcon: illegalMine.getIcon
  },
  {
    category: 'Hazard maps',
    subCategory: 'Earthquakes (USGS)',
    getColor: earthquakeUSGS.getColor,
    modifyEntity: earthquakeUSGS.modifyEntity
  },
  {
    category: 'Hazard maps',
    subCategory: 'Earthquakes',
    getColor: earthquakes.getColor
  },
  {
    category: 'Hazard maps',
    subCategory: 'Landslides',
    getColor: landslides.getColor
  },
  {
    category: 'Hazard maps',
    subCategory: 'Tsunami',
    getColor: tsunami.getColor
  },
  {
    category: 'Hazard maps',
    subCategory: 'Volcanic eruption',
    getColor: volcanicEruption.getColor
  },
  {
    category: 'Hazard maps',
    subCategory: 'Active fault',
    getColor: activeFault.getColor
  },
  {
    category: 'Forestry maps',
    subCategory: null,
    getColor: forestry.getColor
  },
  {
    category: 'Tenement maps',
    subCategory: null,
    getColor: tenement.getColor,
    onlyOutline: true
  },
  {
    category: 'Accessibility and infrastructure maps',
    subCategory: 'Coastlines',
    getColor: coastlines.getColor
  },
  {
    category: 'Accessibility and infrastructure maps',
    subCategory: 'Fuel station',
    getColor: gasStation.getColor,
    getIcon: gasStation.getIcon
  },
  {
    category: 'Accessibility and infrastructure maps',
    subCategory: 'Port',
    getColor: jetty.getColor,
    getIcon: jetty.getIcon
  },
  {
    category: 'Accessibility and infrastructure maps',
    subCategory: 'Oil depot',
    getColor: oilDepot.getColor
  },
  {
    category: 'Accessibility and infrastructure maps',
    subCategory: 'Power plant',
    getColor: powerGenerator.getColor,
    getIcon: powerGenerator.getIcon
  },
  {
    category: 'Accessibility and infrastructure maps',
    subCategory: 'Administration',
    getColor: administration.getColor,
  },
  {
    category: 'Accessibility and infrastructure maps',
    subCategory: 'River',
    getColor: river.getColor,
  },
  {
    category: 'Accessibility and infrastructure maps',
    subCategory: 'Road',
    getColor: road.getColor,
  },
  {
    category: 'Accessibility and infrastructure maps',
    subCategory: 'Settlement',
    getColor: settlement.getColor,
  },
  {
    category: 'Accessibility and infrastructure maps',
    subCategory: 'Airport',
    getColor: airport.getColor,
    getIcon: airport.getIcon
  },
  {
    category: 'Accessibility and infrastructure maps',
    subCategory: 'Smelter',
    getColor: smelter.getColor,
    getIcon: smelter.getIcon
  },
  {
    category: 'Drillhole',
    subCategory: 'Drillhole trace',
    getColor: drillholeTrace.getColor
  },
  {
    category: 'Drillhole',
    subCategory: 'Lithology',
    getColor: drillholeLithology.getColor
  },
  {
    category: 'Drillhole',
    subCategory: 'Alteration',
    getColor: drillholeAlteration.getColor
  },
  {
    category: 'Drillhole',
    subCategory: 'Assay',
    getColor: drillholeAssay.getColor
  },
  {
    category: 'Drillhole',
    subCategory: 'RQD',
    getColor: drillholeRQD.getColor
  },
  {
    category: '3D Model',
    subCategory: 'Implicit model',
    getColor: geologicalModel.getColor
  },
  {
    category: '3D Model',
    subCategory: 'Geological model',
    getColor: implicitModel.getColor
  },
]
