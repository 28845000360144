import React from 'react'

import FocusContainer, {
  Header,
  Paragraph
} from '../../StyledComponent'
import BlockPNG from '../../HelpImage/assistive-button-block-section.png'
import { LIST_HELP } from '../../constant'


function HelpBlockSection(props) {
  return <>
    <FocusContainer {...props}>
      <Header>{LIST_HELP['assistive'].detail.block.header}</Header>
      <Paragraph>
        Block section tool is provided to make it easier for users to see the earth in 3 dimension with a shape that has been cut like a box. Block section tool will be very useful when subsurface data is available.
      </Paragraph>
      <img src={BlockPNG} alt="Block section image" width="1100px" />
    </FocusContainer>
  </>
}

export default HelpBlockSection
