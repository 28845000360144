import React from 'react'

import { connect } from 'react-redux'

import {
  toggleGradebar
} from '../../../../store/actions/gradebar'

import {
  toggleTablebar
} from '../../../../store/actions/tablebar'

import {
  ReactComponent as GradeIcon
} from '../../../../images/Icons/certificate.svg'

import ContentIcon from './ContentIcon'

/**
 * This is table component toggle
 * @param {Object} content that represent the button given
 * @param {Number} translate to set how far this component is translated
 * @param {Number} left the left position of button
 * @param {Number} top the top position of button
 * @param {Number} r radius of the black circle
 * @param {Number} radians the radians of rotation value
 * @param {Number} widthSVG is the width given for SVG
 * @param {Boolean} toggleDisplay status that button being shown or not
 * @param {Number} key is the id of component,
 * @param {Object} grade react redux state of grade
 */
function GradeOptionButton({
  content,
  translate,
  left,
  top,
  r,
  radians,
  widthSVG,
  toggleDisplay,
  key,
  grade,
  table,
  toggleGradebar,
  toggleTablebar
}) {
  function onClickHandler() {
    toggleGradebar()

    if (table.status) {
      toggleTablebar()
    }
  }

  return (
    <ContentIcon
      key={key}
      top={
        toggleDisplay
          ? top + 'em'
          : top - translate - r * Math.sin(radians) + 'em'
      }
      left={
        toggleDisplay
          ? left + 'em'
          : left - translate - r * Math.cos(radians) + 'em'
      }
      height={widthSVG + 'em'}
      width={widthSVG + 'em'}
      isActive={grade.status}
    >
      <GradeIcon
        title={content.title}
        width={25}
        height={25}
        onClick={onClickHandler}
      />
    </ContentIcon>
  )
}

function mapStateToProps({ grade, table }) {
  return {
    grade,
    table
  }
}

const mapDispathToProps = {
  toggleGradebar,
  toggleTablebar
}


export default connect(mapStateToProps, mapDispathToProps)(GradeOptionButton)
