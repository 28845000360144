export const SELECT_TERNARY_MENU_CLASSIFICATION = [
  {
    value: 'AFM',
    label: 'AFM'
  },
  {
    value: 'bauxite',
    label: 'Bauxite'
  },
  {
    value: 'felsic',
    label: 'Felsic'
  },
  {
    value: 'mafic',
    label: 'Mafic'
  },
  {
    value: 'ultramafic',
    label: 'Ultramafic'
  },
  {
    value: 'no_classification',
    label: 'no classification'
  }
]

export const ternaryDivisions = [
  {
    texture: 'A',
    rotation: 0,
    values: [
      {
        al2o3: 95,
        fe2o3: 5,
        sio2: 0
      },
      {
        al2o3: 95,
        fe2o3: 0,
        sio2: 5
      },
      {
        al2o3: 100,
        fe2o3: 0,
        sio2: 0
      }
    ]
  }
]
