import React from 'react'

import LayerbarIcon from '../../../../../shares/LayerbarIcon'

import {
  ReactComponent as EditIcon
} from '../../../../../../../../images/Icons/edit-text.svg'

/**
 * This button to edit number on numerical field mode custom
 * @param {Object} editOption is the option if list being edit or not
 * @param {Function} setEditOption is the method to set edit option value
 */
function EditButton({
  editOption,
  setEditOption
}) {
  function editHandler() {
    setEditOption((currentEditOption) => {
      return {
        ...currentEditOption,
        onEdit: !currentEditOption.onEdit
      }
    })
  }

  return <LayerbarIcon
      title="edit"
      isActive={editOption.onEdit}
    >
    <EditIcon onClick={editHandler} />
  </LayerbarIcon>
}

export default EditButton
