import React, { useState, useEffect, useRef } from 'react'

import * as d3 from 'd3'
import axios from 'axios'

import {
  productionAPI
} from '../../../apis/server'

import {
  GEOLOGICAL_MAP_COLOR_REFERENCES,
} from '../../../constants/Colors/geological'

import {
  GEOLOGICAL_TIME_SCALE
} from '../../../constants/Geologicals/GeologicalTimeScale'

import {
  GEOLOGICAL_STRATIGRAPHIC_KEYWORDS
} from '../../../constants/Geologicals/GeologicalStratigraphicKeyword'

import {
  generateSequences,
  renderLabel,
  renderGeology,
  renderText,
  generateUniqueLith,
  generateUniqueBox
} from './helpers'

import ReactLoading from 'react-loading'

import './RegionalGeologicalStratigraphyGraphic.css'

function GeologicalStratigraphyGraphic({
  site
}) {
  const [ geologyData, setGeologyData ] = useState(null)
  const [ tooltipDiv, setTooltipDiv ] = useState(null)
  const [ dataReady, setDataReady ] = useState(false)
  let svg = null

  const regionalGeologicalStratigraphyGraphicRef = useRef(null)

  function renderGraphics({
    sequences,
    // map
  }) {
    if (regionalGeologicalStratigraphyGraphicRef
      && regionalGeologicalStratigraphyGraphicRef.current
    ) {
      const {
        clientWidth,
        clientHeight
      } = regionalGeologicalStratigraphyGraphicRef.current
      
      const {
        top,
        bottom
      } = regionalGeologicalStratigraphyGraphicRef
        .current
        .getBoundingClientRect()

      const midPoint = {
        x: clientWidth / 2,
        y: clientHeight / 2
      }

      svg = d3.select(regionalGeologicalStratigraphyGraphicRef.current)

      svg
        .append('text')
          .text('Geological Stratigraphy')
          .attr('x', function () {
            return midPoint.x - this.getComputedTextLength() / 2
          })
          .attr('y', '20px')
          
      
      const periodic = {
        left: 10,
        margin: 10
      }

      const block = {
        era: {
          left: 0,
          right: 0
        },
        period: {
          left: 0,
          right: 0
        },
        epoch: {
          left: 0,
          right: 0
        },
        ...generateUniqueBox(sequences)
      }

      const totalWideMargin = 260

      const renderTextContents = [
        {
          text: 'Era',
          type: 'era',
          wideMargin: 30
        },
        {
          text: 'Period',
          type: 'period',
          wideMargin: 30
        },
        {
          text: 'Epoch',
          type: 'epoch',
          wideMargin: 60
        },
        ...generateUniqueLith(sequences, totalWideMargin)
      ]

      const oldest = {
        era: sequences[0].begin.era,
        period: sequences[0].begin.period,
        epoch: sequences[0].begin.epoch,
        index: sequences[0].indexBeginAge
      }

      const youngest = {
        era: sequences[sequences.length - 1].end.era,
        period: sequences[sequences.length - 1].end.period,
        epoch: sequences[sequences.length - 1].end.epoch,
        index: sequences[sequences.length - 1].indexEndAge
      }

      const maxYBlock = bottom - top - 60 // 60px from the top

      const diagramLabel = {
        era: {
          details: [],
          totalScale: 0,
          initialY: 50
        },
        period: {
          details: [],
          totalScale: 0,
          initialY: 50
        },
        epoch: {
          details: [],
          totalScale: 0,
          initialY: 50
        }
      }

      renderTextContents.forEach((content) => {
        renderText({
          block,
          periodic,
          svg,
          ...content
        })
      })

      renderLabel({
        block,
        diagramLabel,
        GEOLOGICAL_TIME_SCALE,
        maxYBlock,
        oldest,
        orientation: 'vertical',
        svg,
        type: 'era',
        youngest
      })

      renderLabel({
        block,
        diagramLabel,
        GEOLOGICAL_TIME_SCALE,
        maxYBlock,
        oldest,
        orientation: 'vertical',
        svg,
        type: 'period',
        youngest
      })

      renderLabel({
        block,
        diagramLabel,
        GEOLOGICAL_TIME_SCALE,
        maxYBlock,
        oldest,
        orientation: 'horizontal',
        svg,
        type: 'epoch',
        youngest
      })

      renderGeology({
        ageLabels: ['era', 'period', 'epoch'],
        block,
        diagramLabel,
        regionalGeologicalStratigraphyGraphicRef,
        // map,
        sequences,
        svg,
        tooltipDiv
      })
    }
  }

  useEffect(() => {
    let cancel = null

    async function fetchGeologyData() {
      try {
        const { data } = await productionAPI.get('/geofix/geology', {
          params: {
            intersection: true,
            minlatitude: site.minlatitude,
            maxlatitude: site.maxlatitude,
            minlongitude: site.minlongitude,
            maxlongitude: site.maxlongitude
          },
          cancelToken: new axios.CancelToken((c) => {
            cancel = c
          })
        })

        const convertGeologyData = data.map((geology) => {
          return geology.geojson.features[0].properties
        })

        setGeologyData(convertGeologyData)
      } catch (error) {
      }
    }

    fetchGeologyData()

    setTooltipDiv(() => {
      return d3
        .select('.regional-geology-stratigraphy-container')
        .append('div')
          .attr('class', 'technical-parameters-tooltip')				
          .style('opacity', 0)
    })

    return () => {
      if (cancel) {
        cancel()
      }

      setGeologyData(null)
      setTooltipDiv(null)

      cancel = null
    }
  }, [site])

  useEffect(() => {
    function initialRender() {
      if (geologyData && geologyData.length) {
        const sequences = generateSequences({
          GEOLOGICAL_MAP_COLOR_REFERENCES,
          GEOLOGICAL_STRATIGRAPHIC_KEYWORDS,
          GEOLOGICAL_TIME_SCALE,
          values: geologyData
        })
        if(sequences.length>0){
          renderGraphics({
            sequences
          })
          setDataReady(true)
        }

      }
    }

    initialRender()

    return () => {
      if (svg) {
        svg.selectAll('*').remove()

        svg = null
      }
    }
  }, [geologyData])

  return <>
    <div
      style={{
        position: 'absolute',
        left: 'calc(50% - 2em)',
        top: '230px',
        opacity: !geologyData ? 1 : 0,
        transition: 'opacity 330ms',
        pointerEvents: 'none'
      }}
    >
      <ReactLoading type="spin" color="black" width="4em" />
      <span style={{
        marginLeft: '0.5em',
        fontFamily: 'Abel'
      }}>
        Fetching...
      </span>
    </div>
    <div
      style={{
        position: 'absolute',
        left: '8em',
        top: '230px',
        opacity: !geologyData? 0 : !dataReady ? 1: 0,
        transition: 'opacity 330ms',
        pointerEvents: 'none'
      }}
    >
      <span style={{
        marginLeft: '0.5em',
        fontFamily: 'Abel'
      }}>
        Geological Stratigraphy Data Not Available
      </span>
    </div>
    <svg
      id="regional-geological-stratigraphy-graphic"
      ref={regionalGeologicalStratigraphyGraphicRef}
    >
    </svg>
  </>
}

export default GeologicalStratigraphyGraphic
