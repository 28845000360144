import React, { useState, useRef } from 'react'

import InputRecommendation from './InputRecommendation'
import InputSpan from './InputSpan'

import { ReactComponent as WarningIcon } from '../../images/Icons/warning.svg'
import { WarningIconWrapper } from './shares/Icon'

import styled from 'styled-components'

const InputWrapper = styled.div`
  width: ${props => props.width};
  min-height: ${props => props.minHeight};
  font-family: Abel;
  border: 1px solid ${props => props.isError ? 'red' : 'black'};
  border-radius: 5px;
  padding: 0.1em 0.22em;
  display: flex;
  cursor: text;
  flex-wrap: wrap;
  position: relative;
`

const PlainInput = styled.input`
  border-width: 0;
  max-width: 100%;
  font-family: Abel;
  width: ${props => props.width};
  margin: 0.1em;

  &:focus {
    outline: none;
  }
`

const InputWarningIcon = styled(WarningIconWrapper)`
  position: absolute;
  right: 0.5em;
  top: 0;
  z-index: 1;
  cursor: pointer;
  transition: all 330ms;
`

/**
 * This input will handle text with multiple input logic
 * @param {String} width - width of input
 * @param {String} height - height of input
 * @param {String} value - text value in input
 * @param {Function} setValue - method to change input value
 * @param {Number} timeoutDuration - long duration before afterDelayHandler
 * @param {Function} afterDelayHandler - method that trigger after delay
 * @param {String} errorMessage - error message to show
 * @param {Array} fullRecomendation - array of recommendation
 * @param {Number} maxContentRecomendation - total recommendation show
 */
function InputMultipleText({
  width,
  minHeight = '1.5em',
  value = '',
  setValue = () => {},
  errorMessage = '',
  fullRecommendation = [],
  maxContentRecomendation
}) {
  const wrapperRef = useRef(null)
  const plainInputRef = useRef(null)

  const [ newValue, setNewValue ] = useState('')

  let splitValue = []

  if (value) {
    splitValue = value.split(',')
    splitValue = [...new Set(splitValue)]
  }

  function focusInput() {
    if (plainInputRef.current) {
      plainInputRef.current.focus()
    }
  }
  
  function removeText(index) {
    const reduceValue = splitValue.slice()
    reduceValue.splice(index, 1)
    setValue(reduceValue.join(','))
  }

  function changeInput(event) {
    setNewValue(event.target.value)
  }

  function extendValue(event) {
    if (newValue && event.key === 'Enter' && !splitValue.includes(newValue)) {
      let templateValue = value
      
      if (value) {
        templateValue += ',' + newValue
      } else {
        templateValue += newValue
      }
      
      setValue(templateValue)
      setNewValue('')
    } else if (value && !newValue && event.key === 'Backspace') {
      removeText(splitValue.length - 1)
    }
  }

  function getInputWidth() {
    if (!plainInputRef.current || !wrapperRef.current) {
      return '2em'
    } else {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')

      let width = context.measureText(newValue).width * 1.2

      if (width > wrapperRef.current.clientWidth - 20) {
        width = wrapperRef.current.clientWidth - 20
      }

      return width + 'px'
    }
  }

  function onSelectHandler(selected) {
    let templateValue = value
      
    if (value) {
      templateValue += ',' + selected
    } else {
      templateValue += selected
    }

    setValue(templateValue)
    setNewValue('')
  }

  return <InputWrapper
    ref={wrapperRef}
    width={width}
    minHeight={minHeight}
    onClick={focusInput}
    isError={!!errorMessage}
  >
    {
      splitValue.map((text, index) => {
        return <InputSpan
          key={text}
          text={text}
          onDeleteHandler={() => {
            removeText(index)
          }}
        />
      })
    }
    <PlainInput
      ref={plainInputRef}
      type="text"
      value={newValue}
      onChange={changeInput}
      onKeyDown={extendValue}
      width={getInputWidth()}
    />
    {
      !fullRecommendation.length
        ? null
        : <InputRecommendation
          parentRef={wrapperRef}
          search={newValue}
          fullRecommendation={fullRecommendation}
          width={width}
          maxContent={maxContentRecomendation}
          onSelect={onSelectHandler}
          ignoreRecommendation={splitValue}
        />
    }
    {
      errorMessage
        && <InputWarningIcon width="0.8em">
          <WarningIcon title={errorMessage} />
        </InputWarningIcon>
    }
  </InputWrapper>
}

export default InputMultipleText
