import React, { useState, useEffect, useRef } from 'react'

import * as d3 from 'd3'
import axios from 'axios'

import {
  productionAPI
} from '../../../apis/server'

import {
  colorScale,
  generateFilterReference,
  generateReferences,
  generateRoset,
  renderHeader,
  renderRoset,
  // renderArchitecturalImage, not implemented in the near possible time
  renderSubHeader,
  renderTableAxis,
  renderTableContent,
  renderTableHeader,
  renderTableIntensity,
  tooltipRender
} from './helpers'

import ReactLoading from 'react-loading'

import './StructureGraphic.css'

function StructureGraphic({
  site,
  content
}) {
  const [ structureData, setStructureData ] = useState(null)
  const [ tooltipDiv, setTooltipDiv ] = useState(null)
  const [ dataReady, setDataReady ] = useState(false)

  const structureGraphicRef = useRef(null)

  function renderGraphics ({
    magmaticBelt = '',
    line_pattern = '',
    references
  }) {
    if (structureGraphicRef
      && structureGraphicRef.current
    ) {
      const {
        clientWidth,
        clientHeight
      } = structureGraphicRef.current

      const midPoint = {
        x: clientWidth / 2,
        y: clientHeight / 2
      }

      const svg = d3.select(structureGraphicRef.current)

      renderHeader({
        magmaticBelt,
        line_pattern,
        midPoint,
        svg
      })

      renderSubHeader({
        cx: 40,
        cy: 85,
        svg,
        text: 'Trend and Intensity',
        x: 50,
        y: 88
      })

      const structureHeaderY = 110
      const structureHeader = [
        {
          name: 'E-W',
          databaseName: 'WE',
          start: null,
          end: null
        },
        {
          name: 'NW-SE',
          databaseName: 'SE',
          start: null,
          end: null
        },
        {
          name: 'N-S',
          databaseName: 'NS',
          start: null,
          end: null
        },
        {
          name: 'NE-SW',
          databaseName: 'NE',
          start: null,
          end: null
        }
      ]
      
      renderTableHeader({
        clientWidth,
        structureHeader,
        structureHeaderY,
        svg
      })

      let initialStructureAxisY = 120
      const structureAxis = [
        { name: 'Major', scale: 1.5, start: null, end: null },
        { name: 'Moderate', scale: 1.25, start: null, end: null },
        { name: 'Minor', scale: 1, start: null, end: null }
      ]

      const objTableAxis = renderTableAxis({
        initialStructureAxisY,
        structureAxis,
        svg
      })

      initialStructureAxisY = objTableAxis.initialStructureAxisY

      const filterReference = generateFilterReference({
        references
      })

      renderTableContent({
        colorScale,
        filterReference,
        structureAxis,
        structureHeader,
        structureGraphicRef,
        svg,
        tooltipDiv,
        tooltipRender
      })

      initialStructureAxisY += 10

      const objTableIntensity = renderTableIntensity({
        colorScale,
        initialStructureAxisY,
        svg
      })

      initialStructureAxisY = objTableIntensity.initialStructureAxisY

      const rosetReference = generateRoset({
        references
      })

      initialStructureAxisY += 60

      let circleX = 60

      const objMajor = renderRoset({
        circleX,
        colorScale,
        dataRender: rosetReference.Major,
        label: 'Major',
        initialStructureAxisY,
        svg,
        structureGraphicRef,
        tooltipDiv,
        tooltipRender
      })

      const closeMajor = objMajor.close

      circleX = 150

      const objModerate = renderRoset({
        circleX,
        colorScale,
        dataRender: rosetReference.Moderate,
        label: 'Moderate',
        initialStructureAxisY,
        svg,
        structureGraphicRef,
        tooltipDiv,
        tooltipRender
      })

      const closeModerate = objModerate.close

      circleX = 240

      const objMinor = renderRoset({
        circleX,
        colorScale,
        dataRender: rosetReference.Minor,
        initialStructureAxisY,
        label: 'Minor',
        svg,
        structureGraphicRef,
        tooltipDiv,
        tooltipRender
      })

      const closeMinor = objMinor.close

      d3.select('.sitebar-menu')
        .on('click', (event) => {
          closeMajor(event.target)
          closeModerate(event.target)
          closeMinor(event.target)
        })


      // not yet gonna be implemented
      // if (
      //   technicalParameters
      //   && technicalParameters.architectural
      //   && technicalParameters.architectural.image
      // ) {
      //   initialStructureAxisY += 80

      //   renderSubHeader({
      //     cx: 40,
      //     cy: initialStructureAxisY,
      //     svg,
      //     text: 'Architectural',
      //     x: 50,
      //     y: initialStructureAxisY + 2.5
      //   })
        
      //   initialStructureAxisY += 20

      //   const architecturalImage = technicalParameters.architectural.image

      //   renderArchitecturalImage({
      //     x: 30,
      //     y: initialStructureAxisY - 30,
      //     width: '80%',
      //     height: '30%',
      //     image: architecturalImage,
      //     svg
      //   })
      // }
    }
  }

  useEffect(() => {
    let cancelFetchList = []

    async function fetchStructureData() {
      try {
        const structuresData = await productionAPI.get('/geofix/structures', {
          params: {
            intersection:true,
            minlatitude: site.minlatitude,
            maxlatitude: site.maxlatitude,
            minlongitude: site.minlongitude,
            maxlongitude: site.maxlongitude,
            lineament_type: 'major,minor'
          },
          cancelToken: new axios.CancelToken((c) => {
            cancelFetchList.push(c)
          })
        })

        const joinStructureData = []

        structuresData.data.forEach((structure) => {
          structure.geojson.features.forEach((props)=>{
            joinStructureData.push(props.properties)
          })
        })

        setStructureData(joinStructureData)
      } catch (error) {
        throw error
      }
    }

    fetchStructureData()
    setTooltipDiv(() => {
      return d3
        .select('.technical-parameters-structure-container')
        .append('div')
          .attr('class', 'technical-parameters-structure-tooltip')				
          .style('opacity', 0)
    })

    return () => {
      cancelFetchList.forEach((cancel) => {
        cancel()
      })

      setTooltipDiv(null)
      setStructureData(null)

      cancelFetchList = []
    }
  }, [site])

  useEffect(() => {
    function initialRender() {
      if (structureData && structureData.length) {
        // const magmaticBelt = content.fact_sheet.belt
        // const line_pattern = content.detail_parameter
        //   ? content.detail_parameter.line_pattern
        //   : null
  
        const references = generateReferences({
          values: structureData
        })
        
        renderGraphics({
          // magmaticBelt,
          // line_pattern,
          references: references
        })
        setDataReady(true)
      }
    }

    initialRender()
  }, [structureData])

  return <>
    <div
      style={{
        position: 'absolute',
        left: 'calc(50% - 2em)',
        top: '230px',
        opacity: !structureData ? 1 : 0,
        transition: 'opacity 330ms',
        pointerEvents: 'none'
      }}
    >
      <ReactLoading type="spin" color="black" width="4em" />
      <span style={{
        marginLeft: '0.5em',
        fontFamily: 'Abel'
      }}>
        Fetching...
      </span>
    </div>
    <div
      style={{
        position: 'absolute',
        left: '3em',
        top: '230px',
        opacity: !structureData? 0 : !dataReady ? 1: 0,
        transition: 'opacity 330ms',
        pointerEvents: 'none'
      }}
    >
      <span style={{
        marginLeft: '0.5em',
        fontFamily: 'Abel'
      }}>
        Geological Structure Data Not Available
      </span>
    </div>
    <svg
      id="structure-graphic"
      ref={structureGraphicRef}
    >
    </svg>
  </>
}

export default StructureGraphic
