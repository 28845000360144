import * as actionTypes from '../../constants/store/actionTypes/camera'

export function smartViewOn(payload) {
  return {
    type: actionTypes.SMART_VIEW_ON,
    payload
  }
}

export function smartViewOff(payload) {
  return {
    type: actionTypes.SMART_VIEW_OFF,
    payload
  }
}

export function smartSelectOn(payload) {
  return {
    type: actionTypes.SMART_SELECT_ON,
    payload
  }
}

export function smartSelectOff(payload) {
  return {
    type: actionTypes.SMART_SELECT_OFF,
    payload
  }
}

export function smartFreezeOn(payload) {
  return {
    type: actionTypes.SMART_FREEZE_ON,
    payload
  }
}

export function smartFreezeOff(payload) {
  return {
    type: actionTypes.SMART_FREEZE_OFF,
    payload
  }
}

export function setBoundingBox(payload) {
  return {
    type: actionTypes.SET_BOUNDING_BOX,
    payload
  }
}

export function setProspectMap(payload) {
  return {
    type: actionTypes.SET_PROSPECT_MAP,
    payload
  }
}

export function resetCamera(payload) {
  return {
    type: actionTypes.RESET_CAMERA,
    payload
  }
}
