import React from 'react'
import './YoutubeFrame.css'

function YoutubeFrame ({
  src = 'https://www.youtube.com/embed/M19OZIxr9s8'
}) {
  return <div className="dashboard-video">
    <iframe
      title="dashboard video"
      width="550px"
      height="350px"
      src={ src }
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen></iframe>
  </div>
}

export default YoutubeFrame
