import React, { useRef, useState, useEffect } from 'react'

import axios from 'axios'

import axiosReact from '../../../apis/axiosReact'

import { connect } from 'react-redux'

import SidebarContainer from '../../shares/SidebarContainer'
import DetailNews from './News/DetailNews'

import styled from 'styled-components'

const NewsbarContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Abel;
  color: white;
  margin: 0.5em;
`

function Newsbar({
  sidebarState,
  commodity
}) {
  let source = null

  const containerRef = useRef(null)

  const [ data, setData ] = useState([])
  const [ loading, setLoading ] = useState(false)

  useEffect(() => {
    if (source) {
      source.cancel('')
    }

    source = axios.CancelToken.source()

    async function fetchNews() {
      try {
        setLoading(true)

        const { data } = await axiosReact.get('/geoprosp/news', {
          params: {
            commodity
          },
          cancelToken: source.token
        })
  
        setData(data)
      } catch (error) {
      } finally {
        setLoading(false)
      }
    }

    if (sidebarState.news.status) {
      fetchNews()
    }

    return () => {
      setData([])
      setLoading(false)

      if (source) {
        source.cancel('')
      }
      source = null
    }
  }, [sidebarState.news.status])

  return <>
    <SidebarContainer status={sidebarState.news.status}>
      <div 
        className="col max-width" 
        style={{
          height: 'calc(100% - 1em)'
        }}
        ref={containerRef}
      >
        <NewsbarContainer>
          {
            data.map((info) => {
              return <DetailNews
                key={info.uuid}
                publishedDate={info.published_date}
                publisher={info.publisher}
                simpleDescription={info.simple_description}
                title={info.title}
                webUrl={info.web_url}
              />
            })
          }
        </NewsbarContainer>
      </div>
    </SidebarContainer>
  </>
}

function mapStateToProps({ sidebarState, sites }) {
  return {
    sidebarState: sidebarState,
    commodity: sites.commodity
  }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Newsbar)
