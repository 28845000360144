import React, { useEffect, useState } from 'react'

import Dropdown from '../../../../../../../Dropdowns/Default'

/**
 * Option menu to select template
 * @param {Object} map is the current map based on category 
 * @returns 
 */
function OptionMenuTemplate({ map }) {
  const [ template, setTemplate ] = useState(map.cesiumLayer.template)

  useEffect(() => {
    function eventHandler() {
      setTemplate(map.cesiumLayer.template)
    }

    map.cesiumLayer.subscribeEvent('template', eventHandler)

    return (() => {
      map.cesiumLayer.unsubscribeEvent('template', eventHandler)
    })
  }, [])

  return (
    <Dropdown
      label={
        map.cesiumLayer.listTemplate.length === 0
          ? 'No Template'
          : 'Pick Template'
      }
      options={map.cesiumLayer.listTemplate || []}
      width="10em"
      defaultPick={template || null}
      setToggle={(template) => {
        map.cesiumLayer.setTemplate(template)
      }}
    />
  )
}

export default OptionMenuTemplate
