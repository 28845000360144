import * as actionTypes from '../../constants/store/actionTypes/sites'

export function changeCommodity(payload) {
  return {
    type: actionTypes.CHANGE_COMMODITY,
    payload
  }
}

export function changeCommoditySuccess(payload) {
  return {
    type: actionTypes.CHANGE_COMMODITY_SUCCESS,
    payload
  }
}

export function changeCommodityError(error) {
  return {
    type: actionTypes.CHANGE_COMMODITY_ERROR,
    error
  }
}

export function changeSiteMenuCategory(payload) {
  return {
    type: actionTypes.CHANGE_SITE_MENU_CATEGORY,
    payload
  }
}

export function changeSiteMenuCategorySuccess(payload) {
  return {
    type: actionTypes.CHANGE_SITE_MENU_CATEGORY_SUCCESS,
    payload
  }
}

export function changeSiteMenuCategoryError(error) {
  return {
    type: actionTypes.CHANGE_SITE_MENU_CATEGORY_ERROR,
    error
  }
}

export function fetchProspectAreaSites(payload) {
  return {
    type: actionTypes.FETCH_PROSPECT_AREA_SITES,
    payload
  }
}

export function fetchProspectAreaSitesSuccess(payload) {
  return {
    type: actionTypes.FETCH_PROSPECT_AREA_SITES_SUCCESS,
    payload
  }
}

export function fetchProspectAreaSitesError(error) {
  return {
    type: actionTypes.FETCH_PROSPECT_AREA_SITES_ERROR,
    error
  }
}

export function selectSite(payload) {
  return {
    type: actionTypes.SELECT_SITE,
    payload
  }
}

export function selectSiteSuccess(payload) {
  return {
    type: actionTypes.SELECT_SITE_SUCCESS,
    payload
  }
}

export function selectSiteError(error) {
  return {
    type: actionTypes.SELECT_SITE_ERROR,
    error
  }
}

export function visitSite(payload) {
  return {
    type: actionTypes.VISIT_SITE,
    payload
  }
}

export function visitSiteSuccess(payload) {
  return {
    type: actionTypes.VISIT_SITE_SUCCESS,
    payload
  }
}

export function visitSiteBack(payload) {
  return {
    type: actionTypes.VISIT_SITE_BACK,
    payload
  }
}

export function visitSiteBackSuccess(payload) {
  return {
    type: actionTypes.VISIT_SITE_BACK_SUCCESS,
    payload
  }
}

export function visitSiteForward(payload) {
  return {
    type: actionTypes.VISIT_SITE_FORWARD,
    payload
  }
}

export function visitSiteForwardSuccess(payload) {
  return {
    type: actionTypes.VISIT_SITE_FORWARD_SUCCESS,
    payload
  }
}

export function visitSiteHome(payload) {
  return {
    type: actionTypes.VISIT_SITE_HOME,
    payload
  }
}

export function visitSiteHomeSuccess(payload) {
  return {
    type: actionTypes.VISIT_SITE_HOME_SUCCESS,
    payload
  }
}

export function visitSiteError(payload) {
  return {
    type: actionTypes.VISIT_SITE_ERROR,
    payload
  }
}

export function changeRankingPreferences(payload) {
  return {
    type: actionTypes.CHANGE_RANKING_PREFERENCES,
    payload
  }
}

export function changeRankingPreferencesSuccess(payload) {
  return {
    type: actionTypes.CHANGE_RANKING_PREFERENCES_SUCCESS,
    payload
  }
}

export function changeRankingPreferencesError(payload) {
  return {
    type: actionTypes.CHANGE_RANKING_PREFERENCES_ERROR,
    payload
  }
}

export function resetRankingPreferences(payload) {
  return {
    type: actionTypes.RESET_RANKING_PREFERENCES,
    payload
  }
}

export function resetSites(payload) {
  return {
    type: actionTypes.RESET_SITES,
    payload
  }
}
