import React, { useRef, useEffect } from 'react'

import * as d3 from 'd3'

import getRGBA from '../helpers/getRGBA'

import { generateColorGradient } from '../../Layerbar/helpers'

/**
 * This component will create legend scale for numerical data
 * @param {Object} map is the selected map to render
 * @param {Object} groupEntities is current selected groupEntities object
 * @param {Array} keys is list key that need to be show on the map
 */
function LegendScaleTileset({
  map,
  keys
}) {
  const scaleRef = useRef(null)
  let legendSvg = null
  let tickSvg = null
  let textSvg = null

  function findMaxWidth() {
    let max = map.name.length
    const multiplier = 4

    return max * multiplier
  }

  function generateDiscreteRect({
    legendSvg,
    y,
    unitHeight,
    scaleWidth,
    margin,
    label
  }) {
    legendSvg.append('rect')
      .attr('x1', 0)
      .attr('y', y)
      .attr('width', scaleWidth)
      .attr('height', unitHeight)
      .style('fill', label.color)
      .attr('transform', `translate(0, ${margin / 2})`)
  }

  function generateDiscreteColor({
    legendSvg,
    scaleWidth,
    legendHeight,
    margin
  }) {
    let unitHeight = legendHeight / map.tilesetLegend.length

    for (let i = 0; i < map.tilesetLegend.length; i++) {
      const label = map.tilesetLegend[i]

      generateDiscreteRect({
        legendSvg,
        y: i * unitHeight,
        unitHeight,
        scaleWidth,
        margin,
        label
      })
    }
  }

  function generateDiscreteTicks({
    legendSvg,
    legendHeight,
    scaleWidth,
    margin
  }) {
    const MARGIN_LEFT = 10
    let unitHeight = legendHeight / map.tilesetLegend.length
    const LINE_LENGTH = 10
    const TEXT_MARGIN = 4

    tickSvg = legendSvg.append('g')
      .attr('transform', `translate(${scaleWidth}, ${margin / 2})`)

    for (let i = 0; i <= map.tilesetLegend.length; i++) {
      tickSvg.append('line')
        .style('stroke', 'rgb(0, 0, 0)')
        .style('stroke-width', 0.1)
        .attr('x1', 0)
        .attr('y1', i * unitHeight)
        .attr('x2', LINE_LENGTH)
        .attr('y2', i * unitHeight)
    }

    for (let i = 0; i < map.tilesetLegend.length; i++) {
      const j = map.tilesetLegend.length - 1 - i

      const label = `${map.tilesetLegend[j].min} - ${map.tilesetLegend[j].max}`

      textSvg = tickSvg.append('text')
        .attr('x', MARGIN_LEFT)
        .attr('y', function () {
          return ((map.tilesetLegend.length - i - 1) + 0.5) * unitHeight + TEXT_MARGIN
        })
        .text(() => label)
    }
  }

  function renderLegendWithD3() {
    const legendHeight = 200 // height 200px
    const margin = 10
    const marginBottom = 10
    const legendWidth = 20 + findMaxWidth()
    const scaleWidth = 10

    legendSvg = d3.select(scaleRef.current)
      .attr('width', legendWidth + margin)
      .attr('height', legendHeight + margin + marginBottom)
      .attr('stroke-width', 0.1)
      .attr('stroke', 'rgb(0, 0, 0)')
      .attr('transform', `translate(${margin / 2}, ${margin / 2})`)
      .append('g')

    generateDiscreteColor({
      legendSvg,
      scaleWidth,
      legendHeight,
      margin
    })

    generateDiscreteTicks({
      legendSvg,
      legendHeight,
      scaleWidth,
      margin
    })
  }

  useEffect(() => {
    if (scaleRef.current) {
      renderLegendWithD3()
    }

    return () => {
      if (legendSvg) {
        legendSvg.remove()
        legendSvg = null
      }

      if (tickSvg) {
        tickSvg.remove()
        tickSvg = null
      }

      if (textSvg) {
        textSvg.remove()
        textSvg = null
      }
    }
  }, [scaleRef, keys])

  return <svg ref={scaleRef}></svg>
}

export default LegendScaleTileset
