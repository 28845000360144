import * as d3 from 'd3';
import { callScaleLinY, callScaleLogX } from '../../helpers/callScale';
import { powerOfTen } from '../../helpers/mathFunction';
import { sliderBottom } from './d3-simple-slider-log';
import { 
    binsForHistogram, 
    makeHistogram, 
    scaleLinearY 
} from './histogramFunction';

export const sliderRangeLog = ({...props}) => {
    let {
        dataGroup,
        selection,
        width,
        height, 
        color,
        gHistogram,
        svg,
        className,
        xAxis,
        yAxis,
        configHistogram,
        setConfigHistogram,
        minRange,
        maxRange,
        series,
        classNameAll
    } = props;

    if(dataGroup[selection]===undefined) return;

    if (minRange===null){
        minRange=d3.min(dataGroup[selection])
    }
    if(maxRange===null){
        maxRange=d3.max(dataGroup[selection])
    }
    if(color===null){
        color=d3.scaleOrdinal()
        .domain(series)
        .range(d3.schemeSet2);
    }

    let minX=d3.min(dataGroup[selection], d => d > 0 ? d: NaN)

    let p = d3.precisionRound(0.01,1.1)
    let sliderRange = sliderBottom() 
        .min(minX)
        .max(d3.max(dataGroup[selection]))
        .width(width)
        .ticks(8)
        .tickFormat(d3.format("."+p+'r'))
        .default([minRange, maxRange])
        .fill(function(d){return color(selection)})
        .displayValue(true)
        .on('onchange', val => {
            let newState={...configHistogram};
            newState.range=val.map(d3.format("."+p+'r')).join(' - ');
            setConfigHistogram(newState)
            let val1=d3.format("."+p+'r')(val[0])
            let val2=d3.format("."+p+'r')(val[1])
            let x= scaleLogXUpdate(width,val1,val2);
            let bins=binsForHistogram({
                x: x,
                data: dataGroup,
                selection: selection,
                numBins:configHistogram.numBins
            })
            let y= scaleLinearY({
                minrange: height,
                maxrange: 0,
                bins: bins
            })
            callScaleLogX({
                scaleAxis: xAxis,
                selectedCall: x
            })
            callScaleLinY({
                scaleAxis: yAxis,
                selectedCall: y,
                data: dataGroup,
                y: selection
            })
            makeHistogram({
                classNameAll:classNameAll,
                gHistogram: gHistogram,
                bins: bins,
                x: x,
                y: y,
                height: height,
                color: color,
                selection: selection,
                series: series
            })
        });

        const gRange = svg.append("g")
        .attr("class",className)
        .attr("transform", `translate(70, ${height+65})`)
        .attr('fill','white');
    
        gRange
        .call(sliderRange)
        .selectAll(".tick text")
            .text(null)
        .filter(powerOfTen)
            .attr("dy", "1em")
            .text(10)
        .append("tspan")
            .attr("dy", "-1em")
            .text(function(d) { return Math.round(Math.log(d) / Math.LN10); });

        let newState={...configHistogram};
        newState.range=sliderRange.value().map(d3.format("."+p+'r')).join(' - ')
        setConfigHistogram(newState)
}

function scaleLogXUpdate(width,mindomain,maxdomain){
    return(
        d3.scaleLog()
            .range([0,width])
            .domain([mindomain, maxdomain])
    )
}