import pdfIcon from "../../../images/Icons/pdfIcon.svg"
import starGoldIcon from "../../../images/Icons/star-gold.svg"
import starWhiteIcon from "../../../images/Icons/star-white.svg"
import { openInNewTab } from "./helpers"

const BodyTable = ({...props}) => {
    let {
        pageOptions,
        pageIndex,
        bookmarkRef,
        setBookmarkRef,
        selectedDownload,
        setSelectedDownload,
        sortOptions,
        keySearch,
    } = props
    const regex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g

    let body = pageOptions[pageIndex].map((i,idx)=>{
        let fav=()=>{
            if(bookmarkRef.includes(i['paper_id'])){
                return (
                    <img src={starGoldIcon}
                    alt='fav-gold'
                    style={{width:'15px', cursor:'pointer'}}
                    title='Unmark favorite'
                    onClick={()=>{
                        let arr=[...bookmarkRef]
                        let filtered = arr.filter(function(value, index, arr){
                            return value !== i['paper_id'];
                        });
                        setBookmarkRef(filtered)
                    }}
                    />
                ) 
            } else {
                return (
                    <img src={starWhiteIcon}
                    alt='fav-white'
                    style={{width:'15px', cursor:'pointer'}}
                    title='Mark as favorite'
                    onClick={()=>{
                        let arr=[...bookmarkRef]
                        arr.push(i['paper_id'])
                        setBookmarkRef(arr)
                    }}
                    />
                ) 
            }
        }

        let handleClickCheckbox = (e) => {
            let arr = [...selectedDownload]
            if(e.target.checked){
                arr.push(i['paper_id'])
                setSelectedDownload(arr)
            } else {
                let filtered = arr.filter(function(value, index, arr){
                    return value !== i['paper_id'];
                });
                setSelectedDownload(filtered)
            }
        }

        let checkboxDownload=()=>{
            if(selectedDownload.includes(i['paper_id'])){
                return (
                    <input
                        type='checkbox'
                        checked={true}
                        style={{cursor:'pointer'}}
                        onChange={(e)=>{
                            let arr = [...selectedDownload]
                            if(e.target.checked){
                                arr.push(i['paper_id'])
                                setSelectedDownload(arr)
                            } else {
                                let filtered = arr.filter(function(value, index, arr){
                                    return value !== i['paper_id'];
                                });
                                setSelectedDownload(filtered)
                            }
                        }}
                    />
                )
            } else {
                return (
                    <input
                        type='checkbox'
                        checked={false}
                        style={{cursor:'pointer'}}
                        title='Check this to mark'
                        onChange={(e)=>{
                            handleClickCheckbox(e)
                        }}
                    />
                )
            }
        }

        return(
            <tr key={`${idx}-body-table`}>
                <td>
                    {checkboxDownload()}
                </td>
                <td>
                    {fav()}
                </td>
                <td>
                    <img src={pdfIcon}
                        alt='pdf'
                        style={{width:'15px', cursor:'pointer'}}
                        title='Open Reference'
                        onClick={()=>{
                            if(i['publisher']==='Elsevier BV'){
                                openInNewTab(i['source_link'])
                            } else {
                                openInNewTab(i['pdf_link'])
                            }
                        }}
                    />
                </td>
                {
                    sortOptions.map((j,idy)=>{
                        if(i[j.pickName]){
                            if(keySearch!==''){
                                let parts = i[j.pickName].toString().split(new RegExp(`(${keySearch})`, 'gi'));
                                let content = parts.map((part, l) => 
                                    <span key={l} style={part.toLowerCase().replace(regex,'').replace(/ |_/g,'') === keySearch.toLowerCase().replace(regex,'').replace(/ |_/g,'') ? { fontWeight: 'bold', backgroundColor: '#FFFF00' } : {} }>
                                        { part }
                                    </span>
                                )
                                if(j.pickName!=='year'){
                                    return(
                                    <td key={`${idy}-content-body`} style={{textAlign:'left'}}>
                                        {content}
                                    </td>
                                    )
                                } else {
                                    return(
                                        <td key={`${idy}-content-body`} style={{textAlign:'center'}}>
                                            {content}
                                        </td>
                                        )
                                }
                            }else {
                                if(j.pickName!=='year'){
                                    return(
                                    <td key={`${idy}-content-body`} style={{textAlign:'left'}}>
                                        {i[j.pickName]}
                                    </td>
                                    )
                                } else {
                                    return(
                                        <td key={`${idy}-content-body`} style={{textAlign:'center'}}>
                                            {i[j.pickName]}
                                        </td>
                                        )
                                }
                            }
                        } else {
                            return(
                                <td key={`${idy}-content-body`}>
                                    {null}
                                </td>
                            )
                        }
                    })
                }
            </tr>
        )
    })

    return (
        <tbody>
            {body}
        </tbody>
    )
}

export default BodyTable;