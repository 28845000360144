import React from 'react'

import { ReactComponent as UpIcon } from '../../../images/Icons/up.svg'
import { ReactComponent as DownIcon } from '../../../images/Icons/down.svg'

import styled from 'styled-components'

const IconWrapper = styled.div`
  svg {
    width: 0.7em;
  }
  margin-left: -0.8em;
  margin-right: 0.3em;
`

function RankbarRankingIcon({ data }) {
  if (data.changeRank === 0) {
    return <></>
  } else if (data.changeRank > 0) {
    return <IconWrapper>
      <DownIcon fill="red" />
    </IconWrapper>
  } else {
    return <IconWrapper>
      <UpIcon fill="green" />
    </IconWrapper>
  }
}

export default RankbarRankingIcon
