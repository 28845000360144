import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'

import {
  addLegend,
  removeLegend
} from '../../../../../../../../store/actions/legendbar'

import LayerbarIcon from '../../../../../shares/LayerbarIcon'

import {
  ReactComponent as ListViewIcon
} from '../../../../../../../../images/Icons/list-view.svg'

/**
 * This component used to show legend data on map
 * @param {Object} map is the object representative of cesium data
 * @param {Object} legendbar is the redux store of the legend
 * @param {Function} addLegend is method to add legend to redux state
 * @param {Function} removeLegend is method to reomve legend from redux state
 */
function ShowLegend({
  map,
  legendbar,
  addLegend,
  removeLegend
}) {
  const [ isActive, setIsActive ] = useState(false)

  useEffect(() => {
    if (legendbar.legend[map.name]) {
      setIsActive(true)
    } else {
      setIsActive(false)
    }
  }, [map, legendbar])

  function toggleHandler() {
    if (legendbar.legend[map.name]) {
      removeLegend({
        name: map.name
      })
    } else {
      addLegend({
        name: map.name,
        map
      })
    }
  }

  return (
    <LayerbarIcon
      isActive={isActive}
    >
      <ListViewIcon
        title="Show Legend"
        onClick={toggleHandler}
      />
    </LayerbarIcon>
  )
}

function mapStateToProps(state) {
  return {
    legendbar: state.legendbar
  }
}

const mapDispatchToProps = {
  addLegend,
  removeLegend
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowLegend)
