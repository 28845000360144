import React, { useState } from 'react'

import { connect } from 'react-redux'

import {
  changeTypeLayer
} from '../../../../../../store/actions/catalog'

import DropDownContainer from './DropDownContainer'
import DataTypeButton from './DataTypeButton'

import PolygonPNG from '../../../../../../images/Icons/polygon.png'
import RasterPNG from '../../../../../../images/Icons/raster.png'
import LinePNG from '../../../../../../images/Icons/line.png'
import PointPNG from '../../../../../../images/Icons/point.png'
import SurfacePNG from '../../../../../../images/Icons/surface.png'
import SolidPNG from '../../../../../../images/Icons/solid.png'
import BlockPNG from '../../../../../../images/Icons/block.png'

import styled from 'styled-components'

const Wrapper = styled.div.attrs((props) => {
  return {
    style: {
      width: `${props.total * 1.4}em` 
    }
  }
})`
  position: relative;
  transition: all 200ms;
`

const Image = styled.img`
  filter: ${props => props.defaultGold && 'invert(65%) sepia(40%) saturate(754%) hue-rotate(8deg) brightness(140%) contrast(98%)'};

  &:hover {
    filter: invert(65%) sepia(40%) saturate(754%) hue-rotate(8deg) brightness(140%) contrast(98%);
  }
`

const REFERENCES = {
  Polygon: (defaultGold) => <Image defaultGold={defaultGold} src={PolygonPNG} width="17" height="17" />,
  Raster: (defaultGold) => <Image defaultGold={defaultGold} src={RasterPNG} width="17" height="17" />,
  Tileset: (defaultGold) => <Image defaultGold={defaultGold} src={RasterPNG} width="17" height="17" />,
  Line: (defaultGold) => <Image defaultGold={defaultGold} src={LinePNG} width="17" height="17" />,
  Point: (defaultGold) => <Image defaultGold={defaultGold} src={PointPNG} width="17" height="17" />,
  Surface: (defaultGold) => <Image defaultGold={defaultGold} src={SurfacePNG} width="17" height="17" />,
  Solid: (defaultGold) => <Image defaultGold={defaultGold} src={SolidPNG} width="17" height="17" />,
  Block: (defaultGold) => <Image defaultGold={defaultGold} src={BlockPNG} width="17" height="17" />
}

function DataTypeSelector({
  data,
  category,
  changeTypeLayer
}) {
  const [ hover, setHover ] = useState(false)
  const [ timeoutHover, setTimeoutHover ] = useState(null)

  let canChange = data.layerTypeDetail
    && Object.keys(data.layerTypeDetail).length > 1

  function onClickHandler() {
    if (!hover) {
      onMouseEnterHandler()
    } else {
      onMouseLeaveHandler()
    }
  }

  function onMouseEnterHandler() {
    if (canChange) {
      setTimeoutHover((timeoutHover) => {
        if (timeoutHover) {
          return timeoutHover
        } else {
          setHover(true)
          // changes for the case of using non computer device
          // return setTimeout(() => {
          //   setTimeoutHover((timeoutHover) => {
          //     if (timeoutHover) {
          //       setHover(true)
          //       clearTimeout(timeoutHover)
          //     }
      
          //     return null
          //   })
          // }, 500) 
        }
      })
    }
  }

  function onMouseLeaveHandler() {
    if (canChange) {
      setHover(false)
      if (timeoutHover) {
        clearTimeout(timeoutHover)
      }
      setTimeoutHover(null)
    }
  }

  function onSelectLayerHandler(layerType) {
    if (canChange && data.layerType !== layerType) {
      changeTypeLayer({
        category,
        newLayerType: layerType
      })
    }
  }

  let total = 1

  if (data.layerTypeDetail) {
    total = Object.keys(data.layerTypeDetail).length
  }

  return <Wrapper
    onClick={onClickHandler}
    total={hover ? total : 1}
  >
    {
      hover
        ? <DropDownContainer
          total={total}
          layerKeys={Object.keys(data.layerTypeDetail)}
          REFERENCES={REFERENCES}
          onSelectLayer={onSelectLayerHandler}
        />
        : <DataTypeButton
          title={data.layerType}
          toggleAble={total > 1}
          icon={REFERENCES[data.layerType](canChange) || 'X'}
        />
    }
  </Wrapper>
}

const mapDispatchToProps = {
  changeTypeLayer
}

export default connect(null, mapDispatchToProps)(DataTypeSelector)
