import React from 'react'

import styled from 'styled-components'

const Wrapper = styled.div`
  background: ${props => props.color};
  min-width: ${props => props.size};
  min-height: ${props => props.size};
  border: 1px solid black;
`

/**
 * This component will create polygon component of legend symbol
 * @param {String} color is the color selected
 * @param {String} size of the selected symbol
 */
function Polygon({
  color,
  size
}) {
  return <Wrapper color={color} size={size} />
}

export default Polygon
