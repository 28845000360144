import React, { useState, useEffect, useRef } from 'react'

import { connect } from 'react-redux'

import {
  visitSite
} from '../../../store/actions/sites'

import {
  fetchSimilarProjects,
  fetchSimilarProjectCountries,
  fetchSiteDetail
} from '../../../apis/server'

import SimilarProjectMenuDropdown from '../../Dropdowns/SimilarProjectMenuDropdown'

import { ReactComponent as ReturnIcon } from '../../../images/Icons/return.svg'

import {
  CRITERIA_OPTIONS
} from '../../../constants/Menus/SimilarProjectMenu'

import {
  addThousand,
  renderCompare,
  renderContent
} from './helpers'

import ReactLoading from 'react-loading'

import styled from 'styled-components'

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
  font-weight: 600;
  font-family: Abel;
  height: 100%;
`

import './SimilarProjectMenu.css'

function SimilarProjectMenu({
  criteriaToggle,
  compare,
  compareSite,
  countries,
  countryToggle,
  matchSites,
  scroll,
  scrollPosition,
  setCriteriaToggle,
  setCountryToggle,
  setCountries,
  setCompare,
  setCompareSite,
  setMatchSites,
  sites,
  visitSite
}) {
  const similarMatchesRef = useRef(null)

  const [ loading, setLoading ] = useState(false)

  useEffect(() => {
    async function fetchDataSimilarProject () {
      setLoading(true)

      if (
        sites
        && sites.selected
        && sites.selected.site
      ) {
        // const dataCountries = await fetchSimilarProjectCountries()
  
        // setCountries(
        //   dataCountries.map((data) => {
        //     return {
        //       pickName: data.country,
        //       pickShow: data.country
        //     }
        //   })
        // )

        const sitesData = await fetchSimilarProjects({
          siteId: sites.selected.site.id,
          commodity: sites.commodity
        })

        let similarSites = []

        if (sitesData?.id_similar?.length) {
          const promises = []

          sitesData.id_similar.forEach(id => {
            promises.push(fetchSiteDetail(
              {
                site: {
                  grid_id: id,
                  type: sites.commodity
                }
              }
            ))
          })

          similarSites = await Promise.all(promises)

          similarSites = similarSites.map((similarSite, i) => {
            return {
              ...similarSite,
              name: `GFX-${similarSite.bin_size}-${similarSite.id}`,
              deposit_type: sitesData.deposit_type,
              percentage_similar: sitesData.percentage_similar[i]
            }
          })
        }

        setMatchSites(similarSites)

        if (
          similarMatchesRef
          && similarMatchesRef.current
        ) {
          const container = similarMatchesRef.current
          container.scrollTo({
            behaviour: 'smooth',
            top: scrollPosition.similarProject.y
          })
        }
      }

      setLoading(false)
    }

    fetchDataSimilarProject()
  }, [sites])

  return <>
    <div
      className="similar-project-header-container"
    >
      <div className="sitebar-menu-content">
        <p className="similar-project-header-header">
          {
            !!!compare
              ? <span>Similar Result</span>
              : <span>Comparison of Grid</span>
          }
        </p>
      </div>
      {/* <div
        className="similar-project-header-find"
        style={{
          marginTop: compare
            ? '-5px'
            : '0px',
          opacity: compare
            ? 0
            : 1
        }}
      >
        <SimilarProjectMenuDropdown
          label={'Criteria'}
          options={CRITERIA_OPTIONS}
          setToggle={setCriteriaToggle}
        />
        <SimilarProjectMenuDropdown
          label={'Countries'}
          options={countries}
          setToggle={setCountryToggle}
        />
        <button
          className="similar-project-find"
          onClick={async () => {
            const sitesData = await fetchSimilarProjects({
              criteria: criteriaToggle,
              country: countryToggle 
            })

            setMatchSites(sitesData.sites || [])
          }}
        >
          <p className="similar-project-find-text">
            Find Matches
          </p>
        </button>
      </div> */}

      <div
        className="similar-project-header-compare"
        style={{
          marginLeft: !compare
            ? '400px'
            : '430px',
          opacity: !compare
            ? 0
            : 1
        }}
        onClick={() => {
          setCompare(false)
        }}
      >
        <ReturnIcon className="compare-return-icon" />
      </div>
    </div>

    {
      loading
        ? <LoadingWrapper>
          <ReactLoading color="black" type="spin" height="4em" width="4em"/>
          <span style={{ margin: '1em' }}>Fetching...</span>
        </LoadingWrapper>
        : <div
          className="similar-project-container"
        >
          <div
            className="similar-project-content"
            style={{
              marginTop: compare
                ? '0px'
                : '5px',
              opacity: compare
                ? 0
                : 1,
              zIndex: compare
                ? 0
                : 1300
            }}
            ref={similarMatchesRef}
            onScroll={(event) => {
              if (
                similarMatchesRef
                && similarMatchesRef.current
              ) {
                scroll(event, similarMatchesRef.current)
              }
            }}
          >
            {
              renderContent({
                matchSites,
                setCompare,
                setCompareSite,
                sites,
                visitSite
              })
            }
          </div>
          <div
            className="similar-project-compare"
            style={{
              marginTop: compare
                ? '20px'
                : '18px',
              opacity: compare
                ? 1
                : 0,
              zIndex: compare
                ? 1390
                : 0
            }}
          >
            {
              compare && renderCompare({
                compareSite,
                addThousand
              })
            }
          </div>
        </div>
    }
  </>
}

function mapStateToProps ({ sites }) {
  return {
    sites
  }
}

const mapDispatchToProps = {
  visitSite
}

export default connect(mapStateToProps, mapDispatchToProps)(SimilarProjectMenu)
