import * as Cesium from 'cesium'

import cesiumViewer from '../cesiumViewer'

function randomColor() {
  return {
    red: Math.random(),
    green: Math.random(),
    blue: Math.random(),
    alpha: 1
  }
}

// function simplifyProperties(geojson) {
//   for (let i = 0; i < geojson.features.length; i++) {
//     if (geojson.features[i].properties) {
//       const simplifyProperties = {}
      
//       simplifyRecursion(
//         geojson.features[i].properties,
//         [],
//         simplifyProperties
//       )

//       geojson.features[i].properties = simplifyProperties
//     }
//   }
// }

// function simplifyRecursion(properties, prefix, result) {
//   for (let key in properties) {
//     if (
//       properties[key] instanceof Object 
//       && !(properties[key] instanceof Array)
//     ) {
//       simplifyRecursion(
//         properties[key],
//         [...prefix, key],
//         result
//       )
//     } else {
//       result[[...prefix, key].join('_')] = properties[key]
//     }
//   }
// }

export function createNestedLines(hierarchy, material, dataSource) {
  if (hierarchy.holes?.length > 0) {
    return createNestedLines(hierarchy.holes, material, dataSource)
  }

  const entity = dataSource.entities.add({
    polyline:  new Cesium.PolylineGraphics({
      positions: hierarchy.positions,
      clampToGround: true,
      material
    })
  })

  return entity
}

export async function loadGeoJson({
  map,
  name,
  geojson,
  colorCb,
  getIcon,
  modifyEntity,
  onlyOutline
}) {
  try {
    // simplifyProperties(geojson)

    const dataSource = new Cesium.GeoJsonDataSource(name).load(geojson, {
      clampToGround: true
    })

    const readyDataSource = await dataSource

    const disableDepthTestDistance = cesiumViewer.normalDisableDepthTestDistance

    readyDataSource.entities.values.forEach((entity, index) => {
      let color = null

      if (!colorCb) {
        color = randomColor()
      } else {
        color = colorCb(entity, {
          map,
          entities: readyDataSource.entities.values,
          index,
          mode: map.fieldFormatMode
        })
      }

      map.colorEntities[entity.id] = color

      if (getIcon) {
        entity.billboard = new Cesium.BillboardGraphics({
          disableDepthTestDistance,
          scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1, 8.0e6, 0.4),
          scale: 0.24,
          heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
          image: new Cesium.CallbackProperty(() => {
            return getIcon(entity, map.colorEntities[entity.id])
          }, false)
        })
        
        return
      }

      const colorMaterialProperty = new Cesium.ColorMaterialProperty()

      if (onlyOutline) {
        colorMaterialProperty.color = Cesium.Color.WHITE.withAlpha(0.1)

        const polygonHierarchy = entity.polygon.hierarchy.getValue(Cesium.JulianDate.now(), {})
        const polylineMaterialProperty = new Cesium.ColorMaterialProperty()
        polylineMaterialProperty.color = new Cesium.CallbackProperty(
          () => {
            return new Cesium.Color.fromAlpha(
              new Cesium.Color(
                map.colorEntities[entity.id].red,
                map.colorEntities[entity.id].green,
                map.colorEntities[entity.id].blue
              ),
              map.colorEntities[entity.id].alpha
            )
          },
          false
        )

        entity.polyline = new Cesium.PolylineGraphics({
          positions: polygonHierarchy.positions,
          clampToGround: true,
          material: polylineMaterialProperty,
          width: 1.5
        })

        // createNestedLines(polygonHierarchy, polylineMaterialProperty, readyDataSource)
      } else {
        colorMaterialProperty.color = new Cesium.CallbackProperty(
          () => {
            return new Cesium.Color.fromAlpha(
              new Cesium.Color(
                map.colorEntities[entity.id].red,
                map.colorEntities[entity.id].green,
                map.colorEntities[entity.id].blue
              ),
              map.colorEntities[entity.id].alpha
            )
          },
          false
        )
      }
      
      if (entity.polygon) {
        entity.polygon.material = colorMaterialProperty
        entity.polygon.shadows = Cesium.ShadowMode.ENABLED
      } else if (entity.billboard) {
        if (map.selectedTemplate && map.selectedTemplate.includes('billboard')) {
          entity.billboard.image = `./icon/${map.selectedTemplate.split('billboard_')[1]}.svg`
          entity.billboard.scaleByDistance =  new Cesium.NearFarScalar(1.5e2, 0.2, 8.0e6, 0.05)
          entity.billboard.disableDepthTestDistance = disableDepthTestDistance
        } else {
          entity.billboard = undefined
  
          entity.point = new Cesium.PointGraphics({
            pixelSize: map.pixelSize,
            disableDepthTestDistance,
            scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1, 8.0e6, 0.2),
            heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
            color: colorMaterialProperty.color
          })
        }
      } else if (entity.polyline) {
        entity.polyline.material = colorMaterialProperty
      }

      if (modifyEntity) {
        modifyEntity(entity, {
          map
        })
      }
    })

    return dataSource
  } catch (error) {
    throw error
  }
}
