import React, { useEffect } from 'react'

import cesiumViewer from '../../../../apis/cesiumViewer'

import {
  clearSectionEditor,
  unclip,
  clearSectionBox
} from '../../../../apis/cesiumSection'

import {
  ReactComponent as ScreenShotIcon
} from '../../../../images/Icons/screenshot.svg'

import ContentIcon from './ContentIcon'

/**
 * The component Block section button
 * @param {Object} content that represent the button given
 * @param {Number} translate to set how far this component is translated
 * @param {Number} left the left position of button
 * @param {Number} top the top position of button
 * @param {Number} r radius of the black circle
 * @param {Number} radians the radians of rotation value
 * @param {Number} widthSVG is the width given for SVG
 * @param {Boolean} toggleDisplay status that button being shown or not
 * @param {Number} key is the id of component
 * @param {Object} blockEvent - block state
 * @param {Function} setBlockEvent - method to change block event
 * @param {Array} listPointerEvent - list of available pointer event
 */
function BlockSectionButton({
  content,
  translate,
  left,
  top,
  r,
  radians,
  widthSVG,
  toggleDisplay,
  key,
  blockEvent,
  setBlockEvent,
  listPointerEvent
}) {
  function resetClip() {
    if (
      cesiumViewer
      && cesiumViewer.viewer
      && cesiumViewer.viewer.scene
      && cesiumViewer.viewer.scene.globe
    ) {
      unclip()
      clearSectionEditor(cesiumViewer)
      clearSectionBox(cesiumViewer)
    }
  }

  function onClickHandler(status) {
    if (status) {
      listPointerEvent.forEach(pointerEvent => {
        if (pointerEvent.name !== 'blockEarth') {
          pointerEvent.otherPointerOnHandler(blockEvent)
        }
      })
    }

    cesiumViewer.sectionStatus.blockSection = status

    setBlockEvent((blockEvent) => ({
      ...blockEvent,
      status
    }))
    
    if (status === false) {
      resetClip()
    }
  }

  useEffect(() => {
    setBlockEvent((blockEvent) => {
      return {
        ...blockEvent,
        selectionOnHandler: () => {
          setBlockEvent((blockEvent) => ({
            ...blockEvent,
            status: false
          }))
        },
        otherPointerOnHandler: (event) => {
          if (event.name === 'sliceEarth') {
            cesiumViewer.sectionStatus.blockSection = false
            
            setBlockEvent((blockEvent) => ({
              ...blockEvent,
              status: false
            }))
          } else {
            onClickHandler(false)
          }
        }
      }
    })
  }, [])

  return (
    <ContentIcon
      key={key}
      top={
        toggleDisplay
          ? top + 'em'
          : top - translate - r * Math.sin(radians) + 'em'
      }
      left={
        toggleDisplay
          ? left + 'em'
          : left - translate - r * Math.cos(radians) + 'em'
      }
      height={widthSVG + 'em'}
      width={widthSVG + 'em'}
      isActive={blockEvent.status}
    >
      <ScreenShotIcon
        title={content.title}
        onClick={() => {
          onClickHandler(!blockEvent.status)
        }}
        width={30}
        height={30}
      />
    </ContentIcon>
  )
}

export default BlockSectionButton
