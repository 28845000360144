import React from 'react'

import { connect } from 'react-redux'

import {
  toggleSidebarButton
} from '../../../../store/actions/sidebarToggle'

import CloseIcon from '../shares/CloseIcon'

import {
  ReactComponent as LeftIcon
} from '../../../../images/Icons/left.svg'

function CloseSidebar({
  sidebarState,
  toggleSidebarButton,
  buttonName
}) {
  return <CloseIcon>
    <LeftIcon
      onClick={
        sidebarState[buttonName].status
          ? () => toggleSidebarButton({
            buttonName,
            status: !sidebarState[buttonName].status,
            animate: true
          })
          : null
      }
    />
  </CloseIcon>
}

function mapStateToProps({ sidebarState }) {
  return {
    sidebarState
  }
}

const mapDispatchToProps = {
  toggleSidebarButton
}

export default connect(mapStateToProps, mapDispatchToProps)(CloseSidebar)
