class Geometry {
  static generateGeometry(cesiumMap) {
    if (cesiumMap.raster) {
      return null
    } else {
      if (!cesiumMap.geojson) {
        throw 'please provide raster data / geojson data to be checked geometry'
      }

      if (cesiumMap.geojson.features.length) {
        return cesiumMap.geojson.features[0].geometry.type
      } else {
        return null
      }
    }
  }
}

export default Geometry
