import React from 'react'

import { SvgIcon } from '../../../shares/SvgIcon'

import {
  ReactComponent as FilterIcon
} from '../../../../images/Icons/filter.svg'

import {
  ReactComponent as AscendingIcon
} from '../../../../images/Icons/ascending-filter.svg'

import {
  ReactComponent as AttributeIcon
} from '../../../../images/Icons/attribute.svg'

import {
  ReactComponent as ModeIcon
} from '../../../../images/Icons/mode.svg'

import {
  ReactComponent as CalendarIcon
} from '../../../../images/Icons/calendar.svg'

import {
  ReactComponent as DataAnaliticsIcon
} from '../../../../images/Icons/data-analytics.svg'

import {
  ReactComponent as GeometryIcon
} from '../../../../images/Icons/geometry.svg'

import {
  ReactComponent as TagLineIcon
} from '../../../../images/Icons/tags-line.svg'

import {
  ReactComponent as PackageIcon
} from '../../../../images/Icons/package.svg'

import {
  ReactComponent as PencilRulerIcon
} from '../../../../images/Icons/pencil-ruler.svg'

import {
  ReactComponent as PaintIcon
} from '../../../../images/Icons/paint.svg'

import FlexWrapper from './FlexWrapper'
import Paragraph from './Paragraph'

const CONTENTS = {
  filterBy: {
    name: 'Filter'
  },
  groupBy: {
    name: 'Grouping'
  },
  sortBy: {
    name: 'Sort'
  },
  attribute: {
    name: 'Attribute'
  },
  mode: {
    name: 'Mode'
  },
  dateFilter: {
    name: 'Filter Date'
  },
  range: {
    name: 'Filter Range'
  },
  size: {
    name: 'Size'
  },
  template: {
    name: 'Template'
  },
  color: {
    name: 'Color'
  },
  label: {
    name: 'Label'
  },
  scalling: {
    name: 'Scalling'
  }
}

/**
 * This component will show additional tooltip when button clicked
 * @examples
 * const keywords = [
 *    'groupBy', 'range'
 * ] // based on your need, the keys are
 * - groupBy => to show dropdown filter
 * - sort => to show dropdown sort
 * - attribute => the attribute field
 * - mode => numerical attribute mode
 * - dateFilter => to filter date ex. earthquake
 * - range => to show slider range
 * - size => to show size
 * - template => to show dropdown template for color
 * - scalling => to set color scale logarithmic / linear
 * - color => to show gradient color scrollbar
 * - label => to show label to entity
 * @param {Array} keywords the key to select
 * @param {React.Component} groupBy is the react component
 * @param {React.Component} sort is the react component
 * @param {React.Component} attribute is the react component
 * @param {React.Component} mode is the react component
 * @param {React.Component} dateFilter is the react component
 * @param {React.Component} range is the react component
 * @param {React.Component} size is the react component
 * @param {React.Component} scalling is the react component
 * @param {React.Component} template is the react component
 * @param {React.Component} color is the react component
 * @param {React.Component} label is the react component
 */
function TooltipContent({
  keywords,
  filterBy,
  groupBy,
  sortBy,
  attribute,
  mode,
  dateFilter,
  range,
  size,
  template,
  scalling,
  color,
  label
}) {
  function renderIcon(key) {
    if (key === 'filterBy') {
      return <SvgIcon
        fill="black"
        width="0.8em"
        height="0.8em"
        margin="0 0.2em 0.4em 0"
      >
        <FilterIcon />
      </SvgIcon>
    } else if (key === 'groupBy') {
      return <SvgIcon
        fill="black"
        width="0.8em"
        height="0.8em"
        margin="0 0.2em 0.4em 0"
      >
        <FilterIcon />
      </SvgIcon>
    } else if (key === 'sortBy') {
      return <SvgIcon
        fill="black"
        width="0.8em"
        height="0.8em"
        margin="0 0.2em 0.4em 0"
      >
        <AscendingIcon />
      </SvgIcon>
    } else if (key === 'attribute') {
      return <SvgIcon
        fill="black"
        width="0.8em"
        height="0.8em"
        margin="0 0.2em 0.4em 0"
      >
        <AttributeIcon />
      </SvgIcon>
    } else if (key === 'mode') {
      return <SvgIcon
        fill="black"
        width="0.8em"
        height="0.8em"
        margin="0 0.2em 0.4em 0"
      >
        <ModeIcon />
      </SvgIcon>
    } else if (key === 'dateFilter') {
      return <SvgIcon
        fill="black"
        width="0.8em"
        height="0.8em"
        margin="0 0.2em 0.4em 0"
      >
        <CalendarIcon />
      </SvgIcon>
    } else if (key === 'range') {
      return <SvgIcon
        fill="black"
        width="0.8em"
        height="0.8em"
        margin="0 0.2em 0.4em 0"
      >
        <DataAnaliticsIcon />
      </SvgIcon>
    } else if (key === 'size') {
      return <SvgIcon
        fill="black"
        width="0.8em"
        height="0.8em"
        margin="0 0.2em 0.4em 0"
      >
        <GeometryIcon />
      </SvgIcon>
    } else if (key === 'label') {
      return <SvgIcon
        fill="black"
        width="0.8em"
        height="0.8em"
        margin="0 0.2em 0.4em 0"
      >
        <TagLineIcon />
      </SvgIcon>
    } else if (key === 'template') {
      return <SvgIcon
        fill="black"
        width="0.8em"
        height="0.8em"
        margin="0 0.2em 0.4em 0"
      >
        <PackageIcon />
      </SvgIcon>
    } else if (key === 'scalling') {
      return <SvgIcon
        fill="black"
        width="0.8em"
        height="0.8em"
        margin="0 0.2em 0.4em 0"
      >
        <PencilRulerIcon />
      </SvgIcon>
    } else if (key === 'color') {
      return <SvgIcon
        fill="black"
        width="0.8em"
        height="0.8em"
        margin="0 0.2em 0.4em 0"
      >
        <PaintIcon />
      </SvgIcon>
    }
  }

  function renderContent(key) {
    if (key === 'filterBy') {
      return filterBy
    } else if (key === 'groupBy') {
      return groupBy
    } else if (key === 'sortBy') {
      return sortBy
    } else if (key === 'attribute') {
      return attribute
    } else if (key === 'mode') {
      return mode
    } else if (key === 'dateFilter') {
      return dateFilter
    } else if (key === 'range') {
      return range
    } else if (key === 'size') {
      return size
    } else if (key === 'template') {
      return template
    } else if (key === 'color') {
      return color
    } else if (key === 'label') {
      return label
    } else if (key === 'scalling') {
      return scalling
    }
  }

  function getHeight(key) {
    if (key === 'filterBy') {
      return '13em'
    } else if (key !== 'color') {
      return '2em'
    } else if (scalling) {
      return '15em'
    } else {
      return '17em'
    }
  }

  return (
    <FlexWrapper
      style={{
        position: 'relative'
      }}
      marginLeft="-0.5em"
      width="calc(100% + 1em)"
      marginBottom="-0.5em"
    >
      <FlexWrapper
        col
      >
        {
          keywords.map((key, i) => {
            return <FlexWrapper row key={i}>
              <FlexWrapper
                height={getHeight(key)}
                width="6em"
                padding="0em 0.5em"
                style={{
                  borderRight: '0.5px solid black'
                }}
                backgroundColor={
                  i % 2 === 0
                    ? '#f8f8f8'
                    : '#ebebeb'
                }
                center
              >
                {renderIcon(key)}
                <Paragraph
                  fontSize="0.8em"
                >
                  {CONTENTS[key].name}
                </Paragraph>
              </FlexWrapper>
              <FlexWrapper
                height={getHeight(key)}
                width="17em"
                padding="0 0 0 1em"
                center
                backgroundColor={
                  i % 2 === 0
                    ? '#f8f8f8'
                    : '#ebebeb'
                }
              >
                {renderContent(key)}
              </FlexWrapper>
            </FlexWrapper>
          })
        }
      </FlexWrapper>
    </FlexWrapper>
  )
}

export default TooltipContent
