import * as actionTypes from '../../constants/store/actionTypes/tablebar'

const initialState = {
  status: false,
  listId: [],
  filter: '',
  bottom: 0,
  height: 20,
  deltaHeight: 0,
  properties: []
}

function tablebarState(state = initialState, { type, payload }) {
  const { status } = state
  
  switch (type) {
    case actionTypes.TOGGLE_TABLEBAR:
      return {
        ...state,
        status: !status
      }
    case actionTypes.SET_DATA_ID:
      return {
        ...state,
        listId: payload.listId
      }
    case actionTypes.CLEAR_DATA_ID:
      return {
        ...state,
        listId: []
      }
    case actionTypes.FILTER_DATA_ID:
      return {
        ...state,
        filter: payload.filter
      }
    case actionTypes.SET_DELTA_HEIGHT:
      return {
        ...state,
        deltaHeight: payload.deltaHeight
      }
    case actionTypes.SET_TABLE_PROPERTIES:
      const result = []

      function flattenProperties(properties, result) {
        for (let key in properties) {
          if (typeof properties[key] === 'object') {
            flattenProperties(properties[key], result)
          } else {
            result[key] = properties[key]
          }
        }
      }

      for (let i = 0; i < payload.properties.length; i++) {
        const objProperties = {}

        flattenProperties(payload.properties[i], objProperties)

        result.push(
          objProperties
        )
      }

      return {
        ...state,
        properties: result
      }
    case actionTypes.RESET_TABLEBAR:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default tablebarState
