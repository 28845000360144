import React, { useState, useEffect } from 'react'

import styled from 'styled-components'

import {
  ToggleSVGButton
} from '../../shares/ToggleSVGIcon'

import {
  ReactComponent as DownloadSVG
} from '../../../images/Icons/download.svg'

const Wrapper = styled.div`
  position: absolute;
  right: 2em;
  top: 3em;
  transition: all 330ms;
  opacity: ${props => props.opacity};
  cursor: pointer;
`

function DownloadImageGeology({
  hover,
  onDownloadHandler
}) {
  const [ opacity, setOpacity ] = useState(0)
  const [ innerHover, setInnerHover ] = useState(false)

  useEffect(() => {
    if (hover || innerHover) {
      setOpacity(1)
    } else {
      setOpacity(0)
    }
  }, [hover, innerHover])

  return <Wrapper
    opacity={opacity}
    onMouseEnter={() => {
      setInnerHover(true)
    }}
    onMouseLeave={() => {
      setInnerHover(false)
    }}
    onClick={onDownloadHandler}
  >
    <ToggleSVGButton>
      <DownloadSVG width="2em" />
    </ToggleSVGButton>
  </Wrapper>
}

export default DownloadImageGeology
