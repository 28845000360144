import React from 'react'

import { ReactComponent as CloseIcon } from '../../../images/Icons/close.svg'

import styled from 'styled-components'

import {
  DANGER_RED
} from '../../shares/ColorTemplate'

const Wrapper = styled.div`
  position: absolute;
  right: 1em;
  top: 1em;
  cursor: pointer;

  svg {
    fill: ${DANGER_RED.default};
    transition: fill 330ms;
  }

  &:hover {
    svg {
      fill: ${DANGER_RED.hover};
    }
  }
`

/**
 * This component used to toggle bottom bar
 * @param {Function} onClickHandler - event to toggle off selected bar 
 */
function CloseBottombar({
  onClickHandler
}) {
  return (
    <Wrapper
      onClick={onClickHandler}
    >
      <CloseIcon />
    </Wrapper>
  )
}

export default CloseBottombar
