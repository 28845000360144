import * as actionTypes from '../../constants/store/actionTypes/editor'

export function setIdOccurrences(payload) {
  return {
    type: actionTypes.SET_ID_OCCURRENCES,
    payload
  }
}

export function showEditor(payload) {
  return {
    type: actionTypes.SHOW_EDITOR,
    payload
  }
}

export function hideEditor(payload) {
  return {
    type: actionTypes.HIDE_EDITOR,
    payload
  }
}

export function resetEditor(payload) {
  return {
    type: actionTypes.RESET_EDITOR,
    payload
  }
}
