import React, { useState, useEffect } from 'react'

import TimeAgo from 'react-timeago'
import removeMd from 'remove-markdown'

import { connect } from 'react-redux'

import {
  getUsername
} from '../../../../store/selectors/user'

import {
  Image
} from '../shares/StyledComponent'

import PeopleSVG from '../../../../images/Icons/people.svg'

import styled from 'styled-components'

const RoomContainer = styled.div`
  display: flex;
  align-items: center;
  background: ${props => props.chatRoom ? 'whitesmoke': '#cccccc'};
  border: 1px solid black;
  border-radius: 5px;
  height: 3em;
  margin: 0.2em 0.5em;
  padding: 0.2em 0;
  cursor: pointer;
  transition: all 330ms;

  &:hover {
    /* background: ${props => props.chatRoom ? '#cccccc': '#fefefe'}; */
    background: #fefefe;
    border: 1px solid white;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.5em;
`

const Paragraph = styled.p`
  color: ${props => props.grey && '#b4b4b4'};
  font-family: Abel;
  font-size: ${props => {
    if (props.main) {
      return '0.7em'
    } else if (props.mini) {
      return '0.6em'
    }

    return '0.7em'
  }};
  margin: 0;
  text-overflow: ellipsis;
  width: 19em;
  white-space: nowrap;
  overflow: hidden;
`

const NotifBadge = styled.div`
  /* color: white;
  background-color: #a8323a;
  font-family: Abel;
  width: 10px;
  padding: 0 5px 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 0.6em; */
  font-family: abel ;
  font-size: 0.7em ;
  font-weight: bold ;
  background: darkgoldenrod;
  border: 2px solid darkgoldenrod;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.2em;
  text-align: center;
  width: 1.2em;

`

function Room({
  room,
  chatRoom,
  setChatRoom,
  username,
  newMessages
}) {
  const recepients = ['Admin Geofix', 'puput geofix', 'reza ramadhan']

  const [ fullname, setFullname ] = useState('')
  const content = room.last_message || room.message

  useEffect(() => {
    if (recepients.length === 2) {
      if (recepients[0] === username) {
        setFullname(recepients[1])
      } else {
        setFullname(recepients[0])
      }
    }

    return () => {
      setFullname('')
    }
  }, [recepients])

  function onClickHandler() {
    if (!chatRoom) {
      setChatRoom({
        chatRoom: room
      })
    } else if (room.message_id !== chatRoom.message_id) {
      setChatRoom({
        chatRoom: room
      })
    }
  }


  function handleNotifCount(number) {
    return number > 9 ? '9+' : number == 0 ? '' : String(number)
  }

  return <RoomContainer
    // chatRoom={chatRoom && room.message_id === chatRoom.message_id}
    chatRoom = {newMessages>0}
    onClick={onClickHandler}
  >
    {
      fullname
        ? <Image
            src={`https://ui-avatars.com/api/?name=${fullname}&background=fff`}
            alt="room"
            width="2em"
            style={{
              marginLeft: '0.5em'
            }}
          />
        : <Image
          src={PeopleSVG}
          alt="room"
          width="2em"
          style={{
            marginLeft: '0.5em'
          }}
        />
    }
    
    <TextContainer>
      <Paragraph main grey={!room.title}>{room.title || '[No Title]'}</Paragraph>
      <Paragraph>{removeMd(content.body)}</Paragraph>
      <Paragraph mini>
        Last reply by {content.user.username} <TimeAgo date={content.created_at} live={true} />
      </Paragraph>
    </TextContainer>
    <TextContainer>
      {
        
        newMessages ?
        // <Paragraph mini style={{
        //   color: 'white',
        //   background: '#a8323a',
        //   width: '10px',
        //   padding: "0 5px 0 5px",
        //   display: "flex",
        //   justifyContent: "center",
        //   alignItems: "center",
        //   fontWeight: 'bold'
        // }}>{ newMessages[room.message_id] }</Paragraph>
        <NotifBadge mini>
          { handleNotifCount(newMessages) }
        </NotifBadge>
        : null
      }
    </TextContainer>
  </RoomContainer>
}

function mapStateToProps(state, { room }) {
  let count = state.socket.newMessages?.[room?.message_id] || 0

  return {
    username: getUsername(state),
    newMessages: count
  }
}

export default connect(mapStateToProps, null)(Room)
