import React, { useEffect } from 'react'

import cesiumViewer from '../../../../apis/cesiumViewer'

import {
  clearSectionEditor,
  clearSlicing,
  unclip,
  clearSectionBox
} from '../../../../apis/cesiumSection'

import {
  ReactComponent as KnifeIcon
} from '../../../../images/Icons/knife.svg'

import ContentIcon from './ContentIcon'

import iziToast from 'izitoast'

/**
 * The component Block section button
 * @param {Object} content that represent the button given
 * @param {Number} translate to set how far this component is translated
 * @param {Number} left the left position of button
 * @param {Number} top the top position of button
 * @param {Number} r radius of the black circle
 * @param {Number} radians the radians of rotation value
 * @param {Number} widthSVG is the width given for SVG
 * @param {Boolean} toggleDisplay status that button being shown or not
 * @param {Number} key is the id of component
 * @param {Object} section is the state for section
 * @param {Function} setSection is the function that use to change the state of section
 * @param {Function} setSelection is the method to change selection sta
 * @param {Boolean} smartSelect is the status of smart select in rankbar
 * @param {Function} setSelection method to turn of smart select
 * @param {Object} sliceEvent - slice state
 * @param {Function} setSliceEvent - method to change slice event
 * @param {Array} listPointerEvent - list of available pointer event
 */
function SliceSectionButton({
  content,
  translate,
  left,
  top,
  r,
  radians,
  widthSVG,
  toggleDisplay,
  key,
  sliceEvent,
  setSliceEvent,
  listPointerEvent
}) {
  function resetClip() {
    if (
      cesiumViewer
      && cesiumViewer.viewer
      && cesiumViewer.viewer.scene
      && cesiumViewer.viewer.scene.globe
    ) {
      unclip()
      clearSectionEditor(cesiumViewer)
      clearSectionBox(cesiumViewer)

      if (cesiumViewer.sectionStatus.slicePolygon && cesiumViewer.sectionStatus.slicePolygon.length) {
        clearSlicing()
      }
    }
  }

  function onClickHandler(status) {
    if (!cesiumViewer.sectionStatus.blockSection && status) {
      return iziToast.warning({
        title: 'Incorrect process',
        message: 'Please create block section first'
      })
    }

    if (status) {
      listPointerEvent.forEach(pointerEvent => {
        if (
          pointerEvent.name !== 'sliceEarth'
          && pointerEvent.name !== 'blockEarth'
        ) {
          pointerEvent.otherPointerOnHandler(sliceEvent)
        }
      })
    }

    cesiumViewer.sectionStatus.sliceSection = status

    setSliceEvent((sliceEvent) => ({
      ...sliceEvent,
      status
    }))

    if (status === false) {
      resetClip()
    }
  }

  useEffect(() => {
    setSliceEvent((sliceEvent) => {
      return {
        ...sliceEvent,
        selectionOnHandler: () => {
          setSliceEvent((sliceEvent) => ({
            ...sliceEvent,
            status: false
          }))
        },
        otherPointerOnHandler: () => {
          onClickHandler(false)
        }
      }
    })
  }, [])

  return (
    <ContentIcon
      key={key}
      top={
        toggleDisplay
          ? top + 'em'
          : top - translate - r * Math.sin(radians) + 'em'
      }
      left={
        toggleDisplay
          ? left + 'em'
          : left - translate - r * Math.cos(radians) + 'em'
      }
      height={widthSVG + 'em'}
      width={widthSVG + 'em'}
      isActive={sliceEvent.status}
    >
      <KnifeIcon
        title={content.title}
        onClick={() => {
          onClickHandler(!sliceEvent.status)
        }}
        width={30}
        height={30}
      />
    </ContentIcon>
  )
}

export default SliceSectionButton
