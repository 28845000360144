import {
  call,
  fork,
  put,
  select,
  takeLatest
} from 'redux-saga/effects'

import * as toolbarActionTypes from '../../constants/store/actionTypes/toolbar'

import cesiumViewer from '../../apis/cesiumViewer'

import {
  setDrawSaveSuccess,
  setDrawSaveError,
  setDrawUndoSuccess,
  setDrawUndoError,
  setDrawRedoSuccess,
  setDrawRedoError,
  setDrawDeleteSuccess,
  setDrawDeleteError,
  ungroupEntity
} from '../actions/toolbar'

import {
  startAction,
  stopAction,
  refreshActionStart,
  refreshActionStop
} from '../actions/uiActions'

import {
  getActiveShapePoints,
  positionsCartesianToCartographic
} from '../../apis/cesium'

import {
  addDrawAPI,
  editDrawAPI,
  deleteDrawAPI
} from '../../apis/server'

export function* savePolylineSaga ({ type, payload }) {
  try {
    yield put(
      payload && payload.refreshing 
        ? refreshActionStart(type)
        : startAction(type)
    )

    const {
      toolbar
    } = yield select(({ toolbar }) => ({
      toolbar,
    }))

    if (!toolbar.entity) {
      yield put(setDrawSaveSuccess())
      return
    }

    const positions = toolbar.entity.polyline.positions.getValue('')
    const properties = toolbar.entity.properties.getValue('')

    yield call(async () => {
      await addDrawAPI({
        type: 'LINE',
        positions: positionsCartesianToCartographic({
          positions
        }),
        properties,
        cesiumId: toolbar.entity.id,
        status: 'DONE'
      })
    })

    toolbar.entity.polyline.positions = positions
    toolbar.entity.polyline.properties = properties
    cesiumViewer.viewer.dataSources.remove(toolbar.pointDataSource)

    yield put(setDrawSaveSuccess())
  } catch (error) {
    // console.log(error)
    yield put(setDrawSaveError(error))
  } finally {
    yield put(
      payload && payload.refreshing
        ? refreshActionStop(type)
        : stopAction(type)
    )
  }
}

export function* undoPolylineSaga ({ type, payload }) {
  try {
    yield put(
      payload && payload.refreshing 
        ? refreshActionStart(type)
        : startAction(type)
    )

    const {
      toolbar
    } = yield select(({ toolbar }) => ({
      toolbar
    }))
    
    if (toolbar.entity && toolbar.active > -1) {
      let undoNewPosition = -1

      if (toolbar.active - 1 > -1) {
        undoNewPosition = toolbar.active - 1
        toolbar.activeShapePoints = toolbar.histories[undoNewPosition] || []
      } else {
        toolbar.activeShapePoints = []
      }

      yield put(setDrawUndoSuccess({
        ...toolbar,
        active: undoNewPosition,
        activeShapePoints: toolbar.activeShapePoints
      }))

      if (toolbar.selector === 'drawPolyline') {
        yield call(() => {
          editDrawAPI({
            type: 'LINE',
            positions: positionsCartesianToCartographic({
              positions: toolbar.activeShapePoints
            }),
            properties: {
              type: toolbar.type
            },
            cesiumId: toolbar.entity.id,
            status: 'EDIT'
          })
        })
      }
    } else {
      yield put(setDrawUndoSuccess({
        ...toolbar
      }))
    }
  } catch (error) {
    yield put(setDrawUndoError(error))
  } finally {
    yield put(
      payload && payload.refreshing
        ? refreshActionStop(type)
        : stopAction(type)
    )
  }
}

export function redoRender ({
  toolbar,
  draw
}) {
  if (toolbar.pointDataSource) {
    if (!cesiumViewer.viewer.dataSources.contains(toolbar.pointDataSource)) {      
      cesiumViewer.viewer.dataSources.add(toolbar.pointDataSource)
    }
  }
  if (toolbar.entity) {
    if (draw.dataSource) {
      if (!draw.dataSource.entities.contains(toolbar.entity)) {
        toolbar.entity.polyline.positions = getActiveShapePoints()
  
        draw.dataSource.entities.add(toolbar.entity)
      }
    }
  }
}

export function* redoPolylineSaga ({ type, payload }) {
  try {
    yield put(
      payload && payload.refreshing 
        ? refreshActionStart(type)
        : startAction(type)
    )

    const {
      toolbar,
      draw
    } = yield select(({ toolbar, catalog }) => ({
      toolbar,
      draw: catalog
    }))

    let redoNewPosition = toolbar.histories.length - 1

    if (toolbar.active < toolbar.histories.length - 1) {
      redoNewPosition = toolbar.active + 1
      toolbar.activeShapePoints = toolbar.histories[redoNewPosition] || toolbar.histories[toolbar.active]

      redoRender({
        toolbar,
        draw
      })

      yield put(setDrawRedoSuccess({
        ...toolbar,
        active: redoNewPosition
      }))

      if (toolbar.selector === 'drawPolyline') {
        yield call(() => {
          editDrawAPI({
            type: 'LINE',
            positions: positionsCartesianToCartographic({
              positions: toolbar.activeShapePoints
            }),
            properties: {
              type: toolbar.type
            },
            cesiumId: toolbar.entity.id,
            status: 'EDIT'
          })
        })
      }
    }
  } catch (error) {
    yield put(setDrawRedoError(error))
  } finally {
    yield put(
      payload && payload.refreshing
        ? refreshActionStop(type)
        : stopAction(type)
    )
  }
}

export function* deletePolylineSaga ({ type, payload }) {
  try {
    yield put(
      payload && payload.refreshing 
        ? refreshActionStart(type)
        : startAction(type)
    )

    const {
      toolbar
    } = yield select(({ toolbar }) => ({
      toolbar
    }))

    if (toolbar.selector === 'drawPolyline' && toolbar.entity) {
      yield call(async () => {
        await deleteDrawAPI({
          cesiumId: toolbar.entity.id
        })
      })
      cesiumViewer.viewer.entities.remove(toolbar.entity)
    } else if (toolbar.selector === 'select' && toolbar.groups.length) {
      yield call(async () => {
        const promises = []

        for (let i = 0; i < toolbar.groups.length; i++) {
          const entity = toolbar.groups[i]
          promises.push(deleteDrawAPI({
            cesiumId: entity.id
          }))
        }

        await Promise.all(promises)
      })
    }

    yield put(setDrawDeleteSuccess())
    yield put(ungroupEntity())
  } catch (error) {
    yield put(setDrawDeleteError(error))
  } finally {
    yield put(
      payload && payload.refreshing
        ? refreshActionStop(type)
        : stopAction(type)
    )
  }
}

export function* watchPolylineSaga () {
  yield takeLatest(toolbarActionTypes.DRAW_SAVE, savePolylineSaga)
  yield takeLatest(toolbarActionTypes.DRAW_UNDO, undoPolylineSaga)
  yield takeLatest(toolbarActionTypes.DRAW_REDO, redoPolylineSaga)
  yield takeLatest(toolbarActionTypes.DRAW_DELETE, deletePolylineSaga)
}

const sagaHelpers = [
  fork(watchPolylineSaga)
]

export default sagaHelpers
