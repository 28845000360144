import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Analytic from '../../components/Analytic';
import Loading from '../../components/Loading';
import Analysisbar from '../../components/Menubars/Analysisbar';
import Navbar from '../../components/Menubars/Navbar';
import Sidebar from '../../components/Menubars/Sidebar';
import { toggleSitebar } from '../../store/actions/sitebar'
import { flyTo } from '../../apis/cesium'
import { connectChatSocket, disconnectChatSocket } from '../../store/actions/socket'
import {
    toggleSidebarButton
  } from '../../store/actions/sidebarToggle'



function Analytics({
    sites,
    sidebarState,
    fetchDrawData,
    connectChatSocket,
    disconnectChatSocket,
    toggleSidebarButton
}) {
    useEffect(() => {
        // fetchDrawData()
        toggleSidebarButton({
            buttonName: 'analytics',
            status: true,
            animate: true
          })
        if (sites.selected.site) {
          flyTo({
            area: sites.selected.site,
            cb: function () {
              toggleSitebar({
                toggle: true,
                minimize: false
              })
            }
          })
        }
    
        connectChatSocket()
    
        return () => {
          disconnectChatSocket()
        }
      }, [])
    return <>
    <Sidebar className="sidebar-block" />
    <div className="content-block">
        <Navbar className="row" />
        <div className="row max-height">
            <div className="row max-height max-width" style={{backgroundColor:'gainsboro'}}>
                <Analytic/>
                <Analysisbar/>
            </div>
        </div>
    </div>
    <Loading />
</>
}

function mapStateToProps({
        sites,
        sidebarState
    }) {
    return {
        sites,
        sidebarState
    }
}

const mapDispatchToProps = {
    toggleSitebar,
    // fetchDrawData,
    connectChatSocket,
    disconnectChatSocket,
    toggleSidebarButton
}

export default connect(mapStateToProps, mapDispatchToProps)(Analytics)