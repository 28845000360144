import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { renderDrag } from '../Cesium/helpers/renderDrag';

import AnalyticInfo from '../AnalyticInfo';
import ChartGroup from './chartGroup';
import { capitalizeElement } from '../Charts/helpers/capitalizeFirstLetter';
import './perfectStyle.css'
import iziToast from 'izitoast';
import AnalyticsMap from '../AnalyticsMap';
import { 
    setConfigChart
} from '../../store/actions/database';

let WrapperAnalysis=styled.div`
    background-color: gainsboro;
    height: 100%;
    width: ${props => props.open ? 'calc(100% - 20em)': '100%'};
    display: grid;
    grid-template-areas: 
    "map map info"
    "chart chart chart";
    grid-template-rows: 5fr 8fr;
    grid-template-columns: 1fr 1fr 1fr;
    position: absolute;
    left: ${props => props.open ? '20em': '0em'};
`
let WrapperMap=styled.div`
    grid-area: map;
    padding:0.2em;
    margin:0.2em;
    border-radius: 4px;
    background: rgba(0,0,0,0.5);
`

let WrapperInfo=styled.div`
    grid-area: info;
    overflow: auto;
    padding:0.2em;
    border-radius: 4px;
    background: rgba(0,0,0,0.5);
    margin:0.2em;
`

let WrapperChart=styled.div`
    grid-area: chart;
    overflow: auto;
    display: block;
    height:89%;
    border-radius: 4px;
    background: rgba(0,0,0,0.5);
    padding:0.2em;
    margin:0.2em;
`

const Analytic = ({
    display,
    sidebarState,
    drag,
    importData = {data:[]},
    utmZone,
    configurationChart,
    setConfigurationChart
}) => {
    const [dataReady, setDataReady] = useState(false)
    const [dataLocal, setDataLocal] = useState(false)
    const [dataTemp, setDataTemp] = useState([])
    const [allDataTemp, setAllDataTemp] = useState([])
    const [elemTemp, setElemTemp] = useState([])
    const [elemTempNonFilter, setElemTempNonFilter] = useState([])
    const [dataTempNonFilter,setDataTempNonFilter] = useState([])
    const [dataTempFilter,setDataTempFilter] = useState([])

    const [dataAnalysis, setDataAnalysis] = useState([])
    const [dataGroupAnalysis, setDataGroupAnalysis] = useState({})
    const [dataAnalysisNonFilter,setDataAnalysisNonFilter] = useState([]);
    const [dataGroupAnalysisNonFilter,setDataGroupAnalysisNonFilter] = useState([]);
    const [dropdownList, setDropdownList] = useState([])
    const [dropdownListNonFilter, setDropdownListNonFilter] = useState([])
    const [dropdownGroup, setDropdownGroup] = useState([])
    const [initialSelectGroup, setInitialSelectGroup] = useState([])
    const [dataLocation, setDataLocation] = useState([])

    const selectFilter=['longitude', 'latitude', 'utm_east', 'utm_north','utm_zone', 'elevation','site_id', 'company','geologist','collectiondate','initials_checked','tenement','locality','belt','project','prospect','survey_pegnumber','card_checked','description', 'asd_feox','asd_min1','asd_min2','asd_min3','asd_min4','asd_min5','alteration','alteration_assemblage','alteration_intensity','alteration_style', 'lithology', 'mineralization', 'oxidation', 'remarks', 'structure_type','texture', 'weathering']

    const [selectValueFilter,setSelectValueFilter] = useState([])
    const [selectedMap, setSelectedMap] = useState([])
    const [selectedScatter, setSelectedScatter] = useState([])
    const [viewer, setViewer] = useState(null)

    const [widthPerColumn, setWidthPerColumn] = useState(8.5)
    const [widthContainer, setWidthContainer] = useState(800)
    const [layoutCurrent, setLayoutCurrent] = useState()
    const column=90
    const widthInit1=(column/3)*widthPerColumn
    const heightInit1=12*25
    const heightInit2=8*25

    // const [configurationChart, setConfigurationChart] = useState([
    //     {
    //         type: 'boxplot',
    //         config:{
    //             seriesSelect:'',
    //             checked:{},
    //             defaultPickDropDown:'' ,
    //             customSelect:'',
    //             color:d3.schemeCategory10,
    //         },
    //         delete:false,
    //         minimized: false,
    //         className: 'bp-01',
    //         layout:{i: "bp-01", x:0, y:0, w:30, h:12},
    //         width: widthInit1,
    //         height: heightInit1
    //     },
    //     {
    //         type: 'correlation',
    //         config:{
    //             seriesSelect:'',
    //             colorScaleSelect:["tomato","white","steelblue"],
    //             domainColorScale:[-1,0,1],
    //             checkIgnore:false,
    //             checked:{},
    //             defaultPickDropDown:'',
    //             customSelect:"",
    //         },
    //         delete:false,
    //         minimized: false,
    //         className: 'cm-01',
    //         layout:{i: "cm-01", x:30, y:0, w:30, h:12},
    //         width: widthInit1,
    //         height: heightInit1
    //     },
    //     {
    //         type:'histogram',
    //         config:{
    //             valueSelect: '',
    //             scaleX: 'linear',
    //             range:'',
    //             numBins:30,
    //             minRange:null,
    //             maxRange:null,
    //             color:null
    //         },
    //         delete:false,
    //         minimized:false,
    //         className: 'hg-01',
    //         layout:{i: "hg-01", x:60, y:0, w:30, h:12},
    //         width: widthInit1,
    //         height: heightInit1
    //     },
    //     // {
    //     //     type:'som',
    //     //     config:{
    //     //         valueSelect: '',
    //     //         scaleX: 'linear',
    //     //         range:'',
    //     //         numBins:30,
    //     //         minRange:null,
    //     //         maxRange:null,
    //     //         color:null
    //     //     },
    //     //     delete:false,
    //     //     minimized:false,
    //     //     className: 'som-01',
    //     //     layout:{i: "som-01", x:0, y:12, w:30, h:12},
    //     //     width: widthInit1,
    //     //     height: heightInit1
    //     // },
    //     {
    //         type:'ternary',
    //         config:{
    //             id :  'geoternary',
    //             // data: {dataJSON}, 
    //             csv :  false,
    //             // selectedData :  ['Al_pct','ag','au'], 
    //             style :  {
    //                 circle: { size: 4, color: { fill: 'black', stroke:'black'}},
    //               mode: 'dark', 
    //               position: { 
    //                 x: 0,
    //                 y: 0
    //               },
    //               size: {
    //                 width: widthInit1,
    //                 height: heightInit1
    //               },
    //               resizeable : false,
    //               dragndrop : false
    //             },
    //             // classification:  'no_classification'
    //             // filterColumn :  ''
    //             showClassification :  true,
    //             plotOnly:true
    //             // dropdown: {dropdownData}
    //         },
    //         delete:false,
    //         minimized:false,
    //         className: 'tr-01',
    //         layout:{i: "tr-01", x:30, y:12, w:30, h:12},
    //         width: widthInit1,
    //         height: heightInit1
    //     },
    //     {
    //         type:'scatterplot',
    //         config: {
    //             setX: '',
    //             setY: '',
    //             scaleX: 'linear',
    //             scaleY: 'linear',
    //             classification:"",
    //             color:'#00B7C2',
    //             colorBorder:'#0F4C75',
    //             sizeCircle:2,
    //             showRegression:true,
    //         },
    //         delete:false,
    //         minimized: false,
    //         className: 'sp-01',
    //         layout:{i: "sp-01", x:60, y:12, w:30, h:12},
    //         width: widthInit1,
    //         height: heightInit1
    //     },
    //     {
    //         type:'scatterplot',
    //         config: {
    //             setX: '',
    //             setY: '',
    //             scaleX: 'linear',
    //             scaleY: 'linear',
    //             classification:"",
    //             color:'#ff92c2',
    //             colorBorder:'#d1345b',
    //             sizeCircle:2,
    //             showRegression:true,
    //         },
    //         delete:false,
    //         minimized: false,
    //         className: 'sp-02',
    //         layout:{i: "sp-02", x:0, y:24, w:30, h:9},
    //         width: widthInit1,
    //         height: heightInit2
    //     },
    //     {
    //         type:'scatterplot',
    //         config: {
    //             setX: '',
    //             setY: '',
    //             scaleX: 'linear',
    //             scaleY: 'linear',
    //             classification:"",
    //             color:'#4E9F3D',
    //             colorBorder:'#1E5128',
    //             sizeCircle:2,
    //             showRegression:true,
    //         },
    //         delete:false,
    //         minimized: false,
    //         className: 'sp-03',
    //         layout:{i: "sp-03", x:30, y:24, w:30, h:9},
    //         width: widthInit1,
    //         height: heightInit2
    //     },
    //     {
    //         type:'scatterplot',
    //         config: {
    //             setX: '',
    //             setY: '',
    //             scaleX: 'linear',
    //             scaleY: 'linear',
    //             classification:"",
    //             color:'#4E9F3D',
    //             colorBorder:'#1E5128',
    //             sizeCircle:2,
    //             showRegression:true,
    //         },
    //         delete:false,
    //         minimized: false,
    //         className: 'sp-04',
    //         layout:{i: "sp-04", x:60, y:24, w:30, h:9},
    //         width: widthInit1,
    //         height: heightInit2
    //     },
    // ])

      //to responsive with width
      let box= document.querySelector('.dashboard')
      useEffect(()=>{
          if(box!==null){
              let wbox=box.offsetWidth
              setWidthContainer(wbox)
              setWidthPerColumn(wbox/column)
          }
      },[box, sidebarState['analytics']])
      if(box!==null){
          window.addEventListener("resize", function(event) {
              let w = box.offsetWidth
              setWidthContainer(w)
              setWidthPerColumn(w/column)
          })
      }
  
      useEffect(()=>{
          if(layoutCurrent===undefined)return;
          configurationChart.map((i,indx) => {
              let lay = layoutCurrent[indx]
              let w = lay.w*widthPerColumn
              let h = lay.h*25
              let con =[...configurationChart]
              if (i.layout.w === lay.w && i.layout.h === lay.h && lay.width!==w){
                  con[indx].width=w
                  setConfigurationChart(con)
              } else if (i.layout.w !== lay.w && i.layout.h !== lay.h){
                  con[indx].width=w
                  con[indx].height=h
                  con[indx].layout=lay
                  setConfigurationChart(con)
              } else if (i.layout.w !== lay.w){
                  con[indx].width=w
                  con[indx].layout=lay
                  setConfigurationChart(con)
              } else if(i.layout.h !== lay.h){
                  con[indx].height=h
                  con[indx].layout=lay
                  setConfigurationChart(con)
              }
          })
      },[widthPerColumn, layoutCurrent])
  

    useEffect(() => {
        if (viewer) {
            display.maps.forEach(map => {
                if (
                    map.dataSource
                    && map.category !== 'Geochemical map series'
                    && map.geom !== 'Point'
                ) {
                    viewer.dataSources.add(map.dataSource)
                }
            })
        }
    }, [viewer])
    
    useEffect(() =>{
        if(importData.data.length!==0){
            setDataLocal(true)
            setAllDataTemp(importData.data)
        } else {
            setDataLocal(false)
            let allData=[]
            if(display.maps.length!==0){
                for(let i=0; i<display.maps.length; i++){
                    let prop =display.maps[i]
                    if(prop.category==="Geochemical map series"){
                        if(prop.geom==="Point"){
                            prop.geojson.features.map((i)=>{
                                allData.push(i.properties)
                            })
                        }
                    }
                }
                
            } else {
                setDataReady(true)
                iziToast.error({
                    title: 'Error',
                    message: 'No data to plot!',
                    closeOnClick: true
                })
            }
            setAllDataTemp(allData)
        }
        if(configurationChart[0].width===0 && configurationChart[0].height===0){
            let conf = [...configurationChart]
            configurationChart.map((c,idx)=>{
                conf[idx].height = heightInit1
                conf[idx].width = widthInit1
            })
            setConfigurationChart(conf)
        }
    },[])

    useEffect(() =>{
        let allData=[]
        if(importData.data.length===0){
            if(display.maps.length!==0){
                for(let i=0; i<display.maps.length; i++){
                    let prop =display.maps[i]
                    if(prop.category==="Geochemical map series"){
                        if(prop.geom==="Point"){
                            prop.geojson.features.map((i)=>{
                                allData.push(i.properties)
                            })
                        }
                    }
                }
                
            } else {
                setDataReady(true)
                iziToast.error({
                    title: 'Error',
                    message: 'No data to plot!',
                    closeOnClick: true
                })
            }
            setAllDataTemp(allData)
        }
    },[display.maps])


    // useEffect(()=>{
    //     let selectValue = []
    //         allDataTemp.map((l)=>{
    //             selectFilter.map((m)=>{
    //                 if(filterType===m && l[m]){
    //                     if(selectValue.indexOf(l[m])===-1){
    //                         selectValue.push(l[m])
    //                     }
    //                 }
    //             })
    //         })
    //         setSelectValueFilter(selectValue.sort())
    // },[filterType])

    useEffect(()=>{
        setDataReady(false)
        let dataFilter = []
        let dataNonFilter = []
        let dataLoc = []
        if (importData.data.length!==0 ){
            setDataLocal(true)
            let longKey = importData.headerOfLocation.longitude
            let latKey = importData.headerOfLocation.latitude
            let northKey = importData.headerOfLocation.northing
            let eastKey = importData.headerOfLocation.easting
            allDataTemp.map((i,idx)=>{
                let uniq='id'+idx
                let filteredElem = Object.keys(i)
                    .filter(key => importData.elements.includes(key))
                    .reduce((obj, key) => {
                        obj[key] = Number(i[key])
                        return obj
                    }, {})
                let filtered = {...filteredElem,id:uniq}
                if (!utmZone.active){
                    let long = i[longKey.value]
                    let lat = i[latKey.value]
                    let id = uniq
                    let loc = {
                        id: id,
                        longitude: long,
                        latitude: lat,
                    }
                    dataLoc.push(loc)
                }else{
                    let east = i[eastKey.value]
                    let north = i[northKey.value]
                    let id = uniq
                    let loc = {
                        id: id,
                        easting: east,
                        northing: north,
                    }
                    dataLoc.push(loc)
                }
                dataFilter.push(filtered)
                dataNonFilter.push(filtered)
            })
        }else{
            setDataLocal(false)
            if (selectedMap.length===0){
                allDataTemp.map((i)=>{
                    let {sample_type, longitude, latitude, utm_east, utm_north,utm_zone, elevation,ll_datum,utm_datum,site_id, company,geologist,collectiondate,initials_checked,tenement,locality,belt,project,prospect,survey_pegnumber,card_checked,description, asd_feox,asd_min1,asd_min2,asd_min3,asd_min4,asd_min5, alteration,alteration_assemblage,alteration_intensity,alteration_style, lithology, mineralization, oxidation, remarks, structure_type,texture, weathering, ...element} = i
                    dataFilter.push(element)
                })
            } else {
                let  arr = allDataTemp.filter(function(item){
                    return selectedMap.indexOf(item.id) !== -1;
                });
                arr.map((i)=>{
                    let {sample_type, longitude, latitude, utm_east, utm_north,utm_zone, elevation,ll_datum,utm_datum,site_id, company,geologist,collectiondate,initials_checked,tenement,locality,belt,project,prospect,survey_pegnumber,card_checked,description, asd_feox,asd_min1,asd_min2,asd_min3,asd_min4,asd_min5, alteration,alteration_assemblage,alteration_intensity,alteration_style, lithology, mineralization, oxidation, remarks, structure_type,texture, weathering, ...element} = i
                    
                    dataFilter.push(element)
                })
            }
    
            allDataTemp.map((i)=>{
                let {sample_type, longitude, latitude, utm_east, utm_north,utm_zone, elevation,ll_datum,utm_datum,site_id, company,geologist,collectiondate,initials_checked,tenement,locality,belt,project,prospect,survey_pegnumber,card_checked,description, asd_feox,asd_min1,asd_min2,asd_min3,asd_min4,asd_min5, alteration,alteration_assemblage,alteration_intensity,alteration_style, lithology, mineralization, oxidation, remarks, structure_type,texture, weathering, ...element} = i
                
                let long = i.longitude
                let lat = i.latitude
                let samID = i.sample_id
                let id = i.id
                let loc = {
                    id: id,
                    sample_id: samID,
                    longitude: long,
                    latitude: lat,
                }
                dataLoc.push(loc)
                dataNonFilter.push(element)
            })
        }
        setDataLocation(dataLoc)
        setDataTemp(dataFilter)
        setDataTempNonFilter(dataNonFilter)
    },[allDataTemp])

    useEffect(()=>{
        if(dataTemp.length===0) return;
        if (selectedMap.length===0){
            setDataTempFilter(dataTemp)
        } else {
            let  arr = dataTemp.filter(function(item){
                return selectedMap.indexOf(item.id) !== -1;
            });
            setDataTempFilter(arr)
        }
    },[selectedMap, dataTemp])

    useEffect(() => {
        if(dataTempFilter.length===0) return;
        let arrFull=[]
        let arr=[]
        let series = []
        let seriesShow = []
        let dropdown=[]
        let dropdownGrp=[]
        let arrDD=[]
        let arrSS=[]

        dataTempFilter.map((item,index)=>{
            let{id, sample_id, ...elem}=item
            Object.keys(elem).map((i)=>{
                if(series.indexOf(i)===-1){
                    series.push(i)
                }
            })
            arr.push(elem)
            arrFull.push(item)
        })

        let labelDD=(d)=> dataLocal ? d : capitalizeElement(d.slice(0,d.lastIndexOf("_")))

        series.sort().map((item,index)=>{
            let arrDropdown={
                value: item,
                label: labelDD(item)
            }
            seriesShow.push(labelDD(item))
            dropdown.push(arrDropdown)
        })

        let size=10
        for (let i=0 ; i< series.length;i+=size){
            arrDD.push(series.slice(i,i+size))
            arrSS.push(seriesShow.slice(i,i+size))
        }

        arrDD.map((i,idx)=>{
            let add={
                value: i.toString(),
                label: arrSS[idx].toString()
            }
            dropdownGrp.push(add)
        })
        let y={
            value: 'Custom',
            label: 'Custom'
        }
        setInitialSelectGroup(arrDD[0])
        setDropdownGroup([...dropdownGrp,y])
        setElemTemp(arr)
        setDataAnalysis(arrFull)
        setDropdownList(dropdown)
    },[dataTempFilter])

    useEffect(() => {
        if(dataTempNonFilter.length===0) return;
        let arrFull=[]
        let arr=[]
        let series = []
        let seriesShow = []
        let dropdown=[]
        let dropdownGrp=[]
        let arrDD=[]
        let arrSS=[]

        dataTempNonFilter.map((item,index)=>{
            let{id, sample_id, ...elem}=item
            Object.keys(elem).map((i)=>{
                if(series.indexOf(i)===-1){
                    series.push(i)
                }
            })
            arr.push(elem)
            arrFull.push(item)
        })
        let labelDD=(d)=> dataLocal ? d : capitalizeElement(d.slice(0,d.lastIndexOf("_")))

        series.sort().map((item,index)=>{
            let arrDropdown={
                value: item,
                label: labelDD(item)
            }
            seriesShow.push(labelDD(item))
            dropdown.push(arrDropdown)
        })

        let size=10
        for (let i=0 ; i< series.length;i+=size){
            arrDD.push(series.slice(i,i+size))
            arrSS.push(seriesShow.slice(i,i+size))
        }

        arrDD.map((i,idx)=>{
            let add={
                value: i.toString(),
                label: arrSS[idx].toString()
            }
            dropdownGrp.push(add)
        })
        let y={
            value: 'Custom',
            label: 'Custom'
        }
        setElemTempNonFilter(arr)
        setDataAnalysisNonFilter(arrFull)
        setDropdownListNonFilter(dropdown)
    },[dataTempNonFilter])

    useEffect(()=>{
        let d={}
        elemTemp.forEach((row) => {
            Object.entries(row).forEach(([key,value])=>{
                if(!(key in d)){
                    d[key]=[]
                }
                d[key].push(Number(value));
            })     
        });
        setDataGroupAnalysis(d)
    },[elemTemp])

    useEffect(()=>{
        let d={}
        elemTempNonFilter.forEach((row) => {
            Object.entries(row).forEach(([key,value])=>{
                if(!(key in d)){
                    d[key]=[]
                }
                d[key].push(Number(value));
            })     
        });
        setDataGroupAnalysisNonFilter(d)
    },[elemTempNonFilter])

    useEffect(()=>{
        if(dropdownList.length!==0 && 
            dataAnalysis.length !==0 && 
            dataGroupAnalysis !==0 && 
            dropdownGroup.length !==0 &&
            initialSelectGroup.length !==0 &&
            dataAnalysisNonFilter.length !==0 && 
            dataGroupAnalysisNonFilter !==0 &&
            dropdownListNonFilter.length!==0){
                setDataReady(true);
        }
    },[dropdownList, 
        dataAnalysis, 
        dataGroupAnalysis,
        dataAnalysisNonFilter, 
        dataGroupAnalysisNonFilter,  
        dropdownGroup,
        initialSelectGroup,
        dropdownListNonFilter
    ])

    let handleViewerLoad = (viewer) => {
        setViewer(viewer);
    };

    const onDropHandler = (event) => {
        renderDrag(event)

        drag.event()
        event.preventDefault()
        event.stopPropagation()
    }

    const onDragOverHandler = (event) => {
        event.dataTransfer.dropEffect = 'copy'

        event.preventDefault()
        event.stopPropagation()
    }

    return (
        <WrapperAnalysis open={sidebarState['analytics'].status}>
            <WrapperMap>
                <AnalyticsMap 
                    data = {dataLocation}
                    selectedMap = {selectedMap}
                    selectedScatter = {selectedScatter}
                    setSelectionOnMap = {setSelectedMap}
                    onViewerLoad = {handleViewerLoad}
                    selectTools={true}
                    onDropHandler = {onDropHandler}
                    onDragOverHandler = {onDragOverHandler}
                    utmZone={utmZone}
                />
            </WrapperMap>
            <WrapperInfo>
                <PerfectScrollbar>
                    <AnalyticInfo
                        data={dataAnalysisNonFilter}
                        dataFilter={dataAnalysis}
                        dataGroup={dataGroupAnalysis}
                        dataReady={dataReady}
                        moreFilter={selectFilter}
                        moreFilterValue={selectValueFilter}
                        selectedMap={selectedMap}
                        dataLocal={dataLocal}
                    />
                </PerfectScrollbar>
            </WrapperInfo>
            <WrapperChart>
                <PerfectScrollbar>
                    <ChartGroup
                        dataReady = {dataReady}
                        data = {dataAnalysis}
                        dataGroup= {dataGroupAnalysis}
                        dropdown = {dropdownList}
                        dropdownGroup = {dropdownGroup}
                        initGroup={initialSelectGroup}
                        selectedScatter={selectedScatter}
                        setSelectedScatter={setSelectedScatter}
                        selectedMap={selectedMap}
                        dataNonFilter = {dataAnalysisNonFilter}
                        dataGroupNonFilter = {dataGroupAnalysisNonFilter}
                        dropdownNonFilter = {dropdownListNonFilter}
                        dataLocal={dataLocal}
                        idDataLocal={dataLocal? importData.headerOfLocation.id.value:[]}
                        column={column}
                        setLayoutCurrent={setLayoutCurrent}
                        widthContainer={widthContainer}
                        configurationChart={configurationChart}
                        setConfigurationChart={setConfigurationChart}
                    />
                </PerfectScrollbar>
            </WrapperChart>
        </WrapperAnalysis>
    );
};

function mapStateToProps({ display, sidebarState, drag, database }) {
    return {
        display,
        sidebarState,
        drag, 
        utmZone: database.utmZone,
        configurationChart: database.configChart
    }
}

const mapDispatchToProps = {
    setConfigurationChart: setConfigChart
}

export default connect(mapStateToProps, mapDispatchToProps)(Analytic)