import React from 'react'

import FocusContainer, {
  Header,
  Paragraph
} from '../StyledComponent'
import HelpPNG from '../HelpImage/help.png'
import { LIST_HELP } from '../constant'


function HelpHelp(props) {
  return <>
    <FocusContainer {...props}>
      <Header>{LIST_HELP['dashboard'].detail.helpButton.header}</Header>
      <img src={HelpPNG} alt="Map image" width="1100px" />
      <Paragraph>
        a. Help List Side Bar - navigation to move to help topic
      </Paragraph>
      <Paragraph>
        b. Help Search Box - type here to find help
      </Paragraph>
      <Paragraph>
        c. Help Content - section that displays the content of help
      </Paragraph>
    </FocusContainer>
  </>
}

export default HelpHelp
