import * as actionTypes from '../../constants/store/actionTypes/legendbar'

const initialState = {
  legend: {}
}

/**
 * Legend Format
 * {
 *   [mapName]: CesiumMap
 * }
 */

function legendbarReducer(state = initialState, { type, payload }) {
  switch (type) {
    case actionTypes.ADD_LEGEND:
      return {
        ...state,
        legend: {
          ...state.legend,
          [payload.name]: payload.map
        }
      }
    case actionTypes.REMOVE_LEGEND:
      const newLegend = {}

      for (let name in state.legend) {
        if (name !== payload.name) {
          newLegend[name] = state.legend[name]
        }
      }

      return {
        ...state,
        legend: newLegend
      }
    case actionTypes.RESET_LEGENDBAR:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default legendbarReducer
