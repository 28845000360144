import React, { useRef } from 'react'

import SitebarMenuBorderHeaderSpan from '../../Spans/SitebarMenuBorderHeaderSpan'

import TechnicalParameter from './TechnicalParameter'

import './SiteRankingCriteriaMenu.css'

function SiteRankingCriteriaMenu ({
  selected,
  sitebarMenuRef
}) {
  const rankingSummaryRef = useRef(null)
  const technicalParametersRef = useRef(null)
  
  // function findScore(parameter) {
  //   let score = 0
  //   let total = 0
  //   const subjectScore = {}

  //   for (let keySubject in parameter) {
  //     const subject = parameter[keySubject]
      
  //     total += 1

  //     if (typeof subject === 'object') {
  //       let totalSubjectScore = 0
  //       let totalSubject = 0
        
  //       for (let keySubjectScore in subject) {
  //         const subjectScore = subject[keySubjectScore]
  //         totalSubject += 1
  //         totalSubjectScore += Number(subjectScore)
  //       }

  //       subjectScore[keySubject] = totalSubjectScore / totalSubject
  //     } else if (typeof subject === 'string') {
  //       subjectScore[keySubject] = Number(subject)
  //     }

  //     if (subjectScore[keySubject] > 5) {
  //       subjectScore[keySubject] = 5
  //     }

  //     score += subjectScore[keySubject] || 0
  //   }

  //   score = score / total

  //   return {
  //     ratingScore: score.toFixed(1),
  //     subjectScore
  //   }
  // }

  // useEffect(() => {
  //   if (colorScaleGraphicRef.current) {

  //   }
  // }, [colorScaleGraphicRef])

  // useEffect(() => {
  //   const content = renderTechnicalParameters()
  //   setGraphContent(content)
  // }, [])

  return <>
    {/* <div className="sitebar-menu-content">
      <p className="sitebar-menu-content-header">
        <span ref={rankingSummaryRef}>Ranking summary</span>
        <SitebarMenuBorderHeaderSpan
          headerRef={rankingSummaryRef}
          className={'sitebar-menu-border-header'}
        />
      </p>
      <p className="sitebar-menu-content-container">
        {
          selected.content.summary || 'not available'
        }
      </p>
    </div> */}
    <div className="sitebar-menu-content">
      <p className="sitebar-menu-content-header">
        <span ref={technicalParametersRef}>Technical and Non Technical parameters</span>
        <SitebarMenuBorderHeaderSpan
          headerRef={technicalParametersRef}
          className={'sitebar-menu-border-header'}
        />
      </p>
      <div className="sitebar-menu-content-container">
        <TechnicalParameter
          selected={selected}
          sitebarMenuRef={sitebarMenuRef}
        />
      </div>
    </div>
    {/* <svg
      id="color-scale-graphic"
      ref={colorScaleGraphicRef}
    >
    </svg> */}
  </>
}

export default SiteRankingCriteriaMenu
