import {
  call,
  fork,
  put,
  putResolve,
  select,
  takeEvery
} from 'redux-saga/effects'

import * as catalogSearchActionTypes from '../../constants/store/actionTypes/catalogSearch'
import * as catalogSearchActions from '../actions/catalogSearch'

import * as catalogActions from '../actions/catalog'

import {
  startAction,
  stopAction,
  refreshActionStart,
  refreshActionStop
} from '../actions/uiActions'


export function* setCatalogSearchSaga({ type, payload }) {
  try {
    yield put(
      payload && payload.refreshing 
        ? refreshActionStart(type)
        : startAction(type)
    )

    if (payload.search) {
      yield putResolve(catalogActions.fetchListMap())
    }

    yield put(catalogSearchActions.setCatalogSearchSuccess({
      searchLevel: payload.searchLevel,
      search: payload.search,
      searchKeyword: payload.searchKeyword
    }))
  } catch (error) {
    yield put(catalogSearchActions.setCatalogSearchError({
      error
    }))
  } finally {
    yield put(
      payload && payload.refreshing
        ? refreshActionStop(type)
        : stopAction(type)
    )
  }
}

export function* watchMapSaga() {
  yield takeEvery(catalogSearchActionTypes.SET_CATALOG_SEARCH, setCatalogSearchSaga)
}

const sagaHelpers = [
  fork(watchMapSaga)
]

export default sagaHelpers
