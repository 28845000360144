import React, { useState, useRef, useEffect, useMemo } from 'react'

import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertFromRaw } from 'draft-js'
import { markdownToDraft } from 'markdown-draft-js'

import {
  Image
} from '../../shares/StyledComponent'

import styled from 'styled-components'

import './bubble-wysiwyg.css'

const Container = styled.div.attrs((props) => {
  return {
    style: {
      height: props.height
    }
  }
})`
  display: flex;
  flex-direction: ${
    props => props.left
      ? 'row'
      : 'row-reverse'
  };
  margin: 0.2em;
`

const BubleContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 10em;
  margin-left: ${props => props.left && '0.7em'};
  margin-right: ${props => !props.left && '1.4em'};
`

const Bubble = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  background-color: ${props => {
    if (props.left) {
      return '#90da90'
    } else {
      return 'white'
    }
  }};
  font-family: Abel;
  border-radius: 5px;
  z-index: 1;
  padding: 0.4em;
  font-size: 0.8em;
`

const Arrow = styled.div`
  position: absolute;
  top: 0;
  left: ${props => props.left && '-10px'};
  right: ${props => !props.left && '-19px'};
  width: 0; 
  height: 0;
  border-top: 0px solid transparent;
  border-bottom: 20px solid transparent; 
  
  border-right: ${props => props.left && '20px solid #90da90'};
  border-left: ${props => !props.left && '20px solid white'};
`

const SpanTime = styled.span`
  font-size: 0.7em;
  text-align: right;
`

function BubbleChat({ message, start, left, setCount }) {
  const [ height, setHeight ] = useState(0)
  
  const bubbleRef = useRef(null)

  const fullname = message.user.first_name + ' ' + message.user.last_name

  function getTime() {
    const date = new Date(message.created_at)
    const hour = String(date.getHours()).length === 2
      ? String(date.getHours())
      : '0' + String(date.getHours())
    const minute = String(date.getMinutes()).length === 2
      ? String(date.getMinutes())
      : '0' + String(date.getMinutes())

    return `${hour}:${minute}`
  }

  function sanitize() {
    let text = message.body
    text = text.replace(/\\s/g, '')
    return text
  }

  useEffect(() => {
    setCount((count) => count + 1)
  }, [])

  useEffect(() => {
    if (bubbleRef.current) {
      setHeight(
        bubbleRef.current
          .getBoundingClientRect().height + 'px'
      )
    }
  }, [bubbleRef])

  return <Container
    height={height}
    left={left}
  >
    <Image
      width="1.5em"
      src={`https://ui-avatars.com/api/?name=${fullname}&background=fff`}
      alt={fullname}
      title={fullname}
    />
    <BubleContainer left={left}>
      <Bubble ref={bubbleRef} left={left}>
        <Editor
          toolbarHidden
          editorState={EditorState.createWithContent(
            convertFromRaw(
              markdownToDraft(
                sanitize()
              )
            )
          )}
          editorClassName={`bubble-wysiwyg${left ? '-left' : ''}`}
          readOnly
        />

        <SpanTime>
          {getTime()}
        </SpanTime>
      </Bubble>
      {
        start && <Arrow left={left} />
      }
    </BubleContainer>
  </Container>
}

export default BubbleChat
