import React from 'react'

import DeletePoint from './DeletePoint'
import CancelPoint from './CancelPoint'
import SavePoint from './SavePoint'
import ConfirmationForm from '../ConfirmationForm'

import { FlexRow } from '../../../BasicStyle/FlexWrapper'

import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% + 1em);
  background-color: #e0e0e0;
  margin-top: 0.5em;
  margin-left: -0.5em;
  margin-bottom: -0.5em;
  flex-wrap: wrap;
  overflow: hidden;
  position: relative;
`

const ButtonWrapper = styled(FlexRow)`
  justify-content: space-between;
`

/**
 * This method to create action form
 * @param {Object} confirmation - state confirmation
 * @param {Function} setConfirmation - method to change state confirmation
 * @param {Object} editorCesiumState - state of editor in cesium
 * @param {Function} acceptConfirmationHandler - method will be trigger if confirmation accepted
 * @param {Function} declineConfirmationHandler - method will be trigger if confirmation declined
 */
function ActionForm({
  confirmation,
  setConfirmation,
  editorCesiumState,
  acceptConfirmationHandler,
  declineConfirmationHandler
}) {
  return <Wrapper>
    <ButtonWrapper>
      <DeletePoint
        setConfirmation={setConfirmation}
        title="Delete"
      />
      {
        editorCesiumState.edit
          && <CancelPoint
            setConfirmation={setConfirmation}
            title="Cancel"
          />
      }
      <SavePoint
        setConfirmation={setConfirmation}
        title={
          confirmation.process === 'save'
            ? 'Create'
            : 'Update'
        }
      />
    </ButtonWrapper>
    {
      confirmation.show
      && confirmation.process
      && <ConfirmationForm
        confirmation={confirmation}
        setConfirmation={setConfirmation}
        yesHandler={acceptConfirmationHandler}
        noHandler={declineConfirmationHandler}
      />
    }
  </Wrapper>
}

export default ActionForm
