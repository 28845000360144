import React from 'react'
import { LIST_HELP } from '../../constant'

import ProspectBarPNG from '../../HelpImage/prospectbar.png'

import FocusContainer, {
  Header,
  LinkText,
  Paragraph
} from '../../StyledComponent'

function HelpProspect(props) {
  const flyTo=(x,y)=>{
    let cloneOpened = []
    cloneOpened.push(y)
    
    props.setOpened(cloneOpened)

    props.setFocus(x)
  }

  const flyOnly=(x)=>{
    props.setFocus(x)
  }
  return <>
    <FocusContainer {...props}>
      <Header>{LIST_HELP['menu'].detail.prospect.header}</Header>
      <Paragraph>
        1. To see prospect bar you must have <LinkText onClick={()=>flyTo('menuBar','dashboard')}>selected a commodity</LinkText>.
      </Paragraph>
      <Paragraph>
        2. Scroll on prospect bar to see list of left or right, or click left or right arrows button if data is available.
      </Paragraph>
      <Paragraph>
        3. Click down arrow button below "Prospect Bar" to show or hide this bar.
      </Paragraph>
      <Paragraph>
        4. The prospect automatically sorted by the current rating and ranking by Geofix AI systems. This prospect can be calculated based on all parameters or can be selected only technical or non-technical parameters, the calculation grid size can also be adjusted.
      </Paragraph>
      <Paragraph>
        5. After selecting a prospect, "Prospect Detail" layer will appear in the right window. Information prospect detail on <LinkText onClick={()=>flyOnly('prospectLayer')}>"Prospect Layer"</LinkText>
      </Paragraph>
      <img src={ProspectBarPNG} alt="Prospectbar image" width="1000px" style={{marginBottom:"1.5em"}} />
    </FocusContainer>
  </>
}

export default HelpProspect
