import React from 'react'

import {
  Image
} from '../../shares/StyledComponent'

import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 25px;
  margin: 0.5em;
  background-color: #e6e6e6;
  width: auto;
  transition: background-color 330ms;
  font-size: 0.85em;
  
  &:hover {
    background-color: #c9c9c9;
  }
`

const FullNameDiv = styled.span`
  margin: 0 0.5em;
`

function MessageIconRecipient({ person, children }) {
  const fullname = `${person.first_name} ${person.last_name}`

  return <Container>
    <Image
      width="2em"
      src={`https://ui-avatars.com/api/?name=${fullname}&background=fff`}
      alt={fullname}
    />
    <FullNameDiv>
      {fullname}
    </FullNameDiv>
    {
      children
    }
  </Container>
}

export default MessageIconRecipient
