export const SITEBAR_WIDTH = '510px'

export const SITEBAR_MENU_BUTTONS = [
  {
    displayName: 'Overview',
    databaseName: 'overview'
  },
  {
    displayName: 'Ranking criteria',
    databaseName: 'rankingCriteria'
  },
  {
    displayName: 'Similar project',
    databaseName: 'similarProject'
  },
  {
    displayName: 'References',
    databaseName: 'references'
  },
  {
    displayName: 'Glossary',
    databaseName: 'glossary'
  }
]
