import React from 'react'
import { LIST_HELP } from '../../constant'
import Scatter1PNG from '../../HelpImage/database-surface-scatter(1).png'
import Scatter2PNG from '../../HelpImage/database-surface-scatter(2).png'
import ScatterSelectPNG from '../../HelpImage/database-surface-scatter-selection.png'
import ScatterSyncPNG from '../../HelpImage/database-surface-scatter-sync.png'

import FocusContainer, {
  Header,
  LinkText,
  Paragraph
} from '../../StyledComponent'

/**
 * This will render help component for help section activation
 * @param {String} focus is the the newest clicked help
 * @param {String} currentHelp to tell component need to focus or not
 * @returns react component
 */
function HelpSurfaceScatter(props) {
  const flyTo=(x)=>{
    props.setFocus(x)
  }
  return <>
    <FocusContainer {...props}>
      <Header>{LIST_HELP['database'].detail.scatter.header}</Header>
      <img src={Scatter1PNG} alt="Scatter image" width="1100px" />
      <img src={Scatter2PNG} alt="Scatter image" width="600px" />
      <Paragraph>
      Apart from selecting data, scatter has 2 tools, selection tools and sync tools. These two buttons are located in the upper right corner of the plot.
      </Paragraph>
      <Paragraph style={{marginBottom:'0.2em', marginTop:'1em'}}>
      1. Selection tool is used to select some data to see its location on the map and on other scatter plots.
      </Paragraph>
      <img src={ScatterSelectPNG} alt="Scatter image" width="1100px"/>
      <Paragraph style={{marginBottom:'0.2em', marginTop:'1em'}}>
      2. Sync tool is used to to synchronize existing data on the map and scatter plot. This tool will be useful when there are <LinkText onClick={()=>flyTo('maps')}>points selection on the map</LinkText>. Points that are not in the map selection will disappear.
      </Paragraph>
      <img src={ScatterSyncPNG} alt="Scatter image" width="1100px" />
      <Paragraph>
      </Paragraph>
    </FocusContainer>
  </>
}

export default HelpSurfaceScatter
