export const SELECT_SOM_MENU =[
    {
        value: 'som',
        label: 'Self Organizing Maps (SOM)'
    }, {
        value: '......................',
        label: '......................'
    }, {
        value: '..................',
        label: '..................'
    }, {
        value: '............',
        label: '............'
    }, {
        value: '.........',
        label: '.........'
    }, {
        value: '........',
        label: '........'
    }
];