import {
  fork,
  put,
  putResolve,
  select,
  take,
  takeLatest,
  delay
} from 'redux-saga/effects'

import * as chatActionTypes from '../../constants/store/actionTypes/chat'
import * as sitebarActionTypes from '../../constants/store/actionTypes/sitebar'

import {
  selectSite
} from '../actions/sites'

import {
  toggleSitebarSuccess
} from '../actions/sitebar'

import {
  toggleChatbarSuccess
} from '../actions/chatbar'

import {
  getSitebarToggle
} from '../selectors/sitebar'

import {
  getChatToggle
} from '../selectors/chatbar'

export function* toggleRightbarSaga({ type, payload }) {
  if (type === chatActionTypes.TOGGLE_CHATBAR) {
    if (payload.toggle) {
      const { toggleSite } = yield select((state) => {
        return {
          toggleSite: getSitebarToggle(state)
        }
      })
  
      if (toggleSite) {
        yield put(toggleSitebarSuccess({
          toggle: false,
          minimize: false
        }))

        yield delay(330)
        
        yield putResolve(selectSite({}))

        yield take(sitebarActionTypes.TOGGLE_SITEBAR_SUCCESS)
      }
    }

    yield put(toggleChatbarSuccess(payload))
  } else if (type === sitebarActionTypes.TOGGLE_SITEBAR) {
    if (payload.toggle) {
      const { toggleChat } = yield select((state) => {
        return {
          toggleChat: getChatToggle(state)
        }
      })
  
      if (toggleChat) {
        yield put(toggleChatbarSuccess({
          toggle: false,
          minimize: false
        }))
  
        yield delay(330)
      }
    }

    if (payload.toggle) {
      const { site } = yield select((state) => {
        return {
          site: state.sites.selected.site
        }
      })

      if (!site) {
        payload.toggle = false
      }
    }
    
    yield put(toggleSitebarSuccess(payload))
  }
}

export function* watchToggle() {
  yield takeLatest(chatActionTypes.TOGGLE_CHATBAR, toggleRightbarSaga)
  yield takeLatest(sitebarActionTypes.TOGGLE_SITEBAR, toggleRightbarSaga)
}

const sagaHelpers = [
  fork(watchToggle)
]

export default sagaHelpers
