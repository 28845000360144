import React, { useEffect, useState } from 'react'

import {
  resizeEllipsoid,
  resizePolyline
} from '../../../../../helpers/resize'

import InputSize from './InputSize'

/**
 * Option menu to mode
 * @param {Object} map is the current map
 * @returns 
 */
function OptionMenuSize({ map }) {
  const [ size, setSize ] = useState(map.cesiumLayer.fieldMode)

  useEffect(() => {
    function eventHandler() {
      setSize(map.cesiumLayer.size)
    }

    map.cesiumLayer.subscribeEvent('size', eventHandler)

    return (() => {
      map.cesiumLayer.unsubscribeEvent('size', eventHandler)
    })
  }, [])

  function setTemplate(template) {
    map.cesiumLayer.setTemplate(template)
  }

  if (!fieldMode.selected) {
    return null
  }

  return (
    <InputSize
      size={size}
      setSize={(size) => {
        map.cesiumLayer.setSize(size)
      }}
      map={map}
      onSetHandler={(size) => {
        setTemplate(null)

        map.pixelSize = size

        const entities = map.dataSource
          .entities
          .values

        if (entities[0].point || entities[0].ellipsoid) {
          resizeEllipsoid({
            map
          })
        } else if (entities[0].polyline || entities[0].polylineVolume) {
          resizePolyline({
            map
          })
        }
      }}
    />
  )
}

export default OptionMenuSize




