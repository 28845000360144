import React from 'react'

import {
  SCROLL_CLICK
} from '../../../../constants/Menubars/Rankbar'

import { ReactComponent as LeftIcon } from '../../../../images/Icons/left.svg'
import { ReactComponent as RightIcon } from '../../../../images/Icons/right.svg'

import { CenteredFlexRow } from '../../../BasicStyle/FlexWrapper'

import styled from 'styled-components'

const ButtonWrapper = styled(CenteredFlexRow)`
  border: 1px solid rgba(0, 0, 0, 0.247);
  cursor: pointer;
  position: absolute;
  top: 0px;
  background: white;
  height: 80%;
  width: 15px;
  box-shadow: -1px 1px 9px 1px #888888;
  margin: 3px 0px;
  z-index: 3;
  transition: all 330ms ease-in-out;

  svg {
    width: 0.5em;
    fill: black;
    transition: all 330ms;
  }

  &:hover {
    transform: scale(1.1);
    background: black;

    svg {
      fill: white;
    }
  }
`

const RightButtonWrapper = styled(ButtonWrapper)`
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  right: 0.5em;
`

const LeftButtonWrapper = styled(ButtonWrapper)`
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  left: 11.1em;
  
  &:hover {
    transform: scale(1.1);
  }
`

function ScrollRankButton({ arrowMove, clickMove }) {
  return <>
    {
      arrowMove === 'right' || arrowMove === 'both'
        ? <RightButtonWrapper
            onClick={() => clickMove(SCROLL_CLICK)}
          >
            <RightIcon />
          </RightButtonWrapper>
        : null
    }
    {
      arrowMove === 'left' || arrowMove === 'both'
        ? <LeftButtonWrapper
            onClick={() => clickMove(-SCROLL_CLICK)}
          >
            <LeftIcon />
          </LeftButtonWrapper>
        : null
    }
  </>
}

export default ScrollRankButton