import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import { BASE_URL } from '../../constants/Server/base'

import Navbar from "../../components/Menubars/Navbar"
import axios from "../../apis/axiosReact";
import LogoGeofix from "../../images/Logos/geofix.png";
import LogoGeoprosp from "../../images/Logos/geoprosp.png";
// import LogoPetrosea from "../../images/Logos/petrosea.png";
import { Link } from 'react-router-dom';

import iziToast from 'izitoast'

import "./register.css";

const configTime = {
  timer: null,
  focus: '',
  fn: null,
  delay: 500
}

function Register() {
  let history = useHistory()

  const[firstname,setFirstname] = useState('');
  const[lastname,setLastname] = useState('');
  const[username,setUsername] = useState('');
  const[email,setEmail] = useState('');
  const[password,setPassword] = useState('');
  const[confirm_password,setConfirmPassword] = useState('');
  const[country,setCountry] = useState('');
  const[organization,setOrganization] = useState('');
  const[role,setRole] = useState('');

  const [ optionList, setOptionList ] = useState({
    organization: [],
    role: [],
    country: []
  })

  const [ customError, setCustomError ] = useState({
    firstname: null,
    lastname: null,
    username: null,
    email: null,
    password: null,
    confirm_password: null,
    country: null,
    organization: null,
    role: null
  })

  const emailRegex = new RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)

  const onSubmit = (event) => {
    event.preventDefault()

    let selectOptionFilled = true

    // selectOption = ['country', 'organization', 'role']

    if (!country && !customError.country) {
      selectOptionFilled = false

      setCustomError({
        ...customError,
        country: 'Please select your country'
      })
    }

    if (!organization && !customError.organization) {
      selectOptionFilled = false

      setCustomError({
        ...customError,
        organization: 'Please select your organization'
      })
    }

    if (!role && !customError.role) {
      selectOptionFilled = false

      setCustomError({
        ...customError,
        role: 'Please select your role'
      })
    }

    if (!selectOptionFilled) {
      return
    }

    for (let key in customError) {
      if (customError[key]) {
        iziToast.warning({
          title: 'Error',
          message: 'Please fix all error before continue'
        })

        return
      }
    }

    let payload = {
      'first_name': firstname,
      'last_name': lastname,
      'username': username,
      'email': email,
      'password': password,
      'country': country,
      'organization': organization,
      'role': role
    }

    axios({
      method: 'post',
      url: `/register`,
      data: payload
    })
      .then(function () {
        history.push('/login')

        iziToast.success({
          title: 'Success',
          message: 'Successfully registered, please open your email for activation'
        })
      })
      .catch(function (error) {
        let {response} = error || {};
        let {data} = response || {};
        let {errors} = data || {};
        let {json} = errors || {};
        json && Object.entries(json).forEach(([key, item]) => {
          setCustomError({
            ...customError,
            [key]: item
          })
        })
      });
  };

  /**
   * This method will handle change event on input
   * @param {*} e event that triger when changed
   * @param {*} key the key of attribute that changed
   * @param {*} valueChange 
   * @param {*} validation 
   */
  function onChangeHandler(e, key, valueChange, validation) {
    if (customError[key]) {
      setCustomError({
        ...customError,
        [key]: null
      })
    }

    valueChange(e.target.value)

    if (!configTime.timer) {
      configTime.fn = validation

      configTime.timer = setTimeout(configTime.fn, configTime.delay)

      configTime.focus = key
    } else if (configTime.focus !== key) {
      if (configTime.fn) {
        configTime.fn()
      }

      clearTimeout(configTime.timer)

      configTime.fn = validation

      configTime.timer = setTimeout(configTime.fn, configTime.delay)

      configTime.focus = key
    } else {
      clearTimeout(configTime.timer)

      configTime.fn = validation

      configTime.timer = setTimeout(configTime.fn, configTime.delay)
    }
  }

  useEffect(() => {
    async function fetchOptionData() {
      try {
        const country = await axios({
          method: 'GET',
          url: `${BASE_URL}/countries`
        })

        const organization = await axios({
          method: 'GET',
          url: `${BASE_URL}/companies`
        })

        const role = await axios({
          method: 'GET',
          url: `${BASE_URL}/company_roles`
        })

        setOptionList((optionList) => ({
          ...optionList,
          country: country.data || [],
          organization: organization.data || [],
          role: role.data || []
        }))
      } catch (error) {
        iziToast.warning({
          title: 'Error',
          message: 'Failed to read server data, please contact administration'
        })
      }
    }

    fetchOptionData()
  }, [])

  return (
    <div className="content-block"  style={{ width: '100vw' }}>
      <Navbar className="row" />
      <div className="register-container img-bg-big">
        <div className="register-box img-bg">
          <div className="col register-box-content register-form-content">
            <h2 className="register-header">Membership Application</h2>
            <p className="par">To apply for member please complete all forms</p>
            <hr className="line"/>
            <form action="" onSubmit={onSubmit}>
              {customError.firstname && <p className="text-error">{customError.firstname}</p>}
              <div className="row mb-2">
                <input
                  type="firstname"
                  className="form-control form-control-half text-left-input"
                  id="firstname"
                  name="firstname"
                  placeholder="First name"
                  value={firstname}
                  onChange={(e) => {
                    onChangeHandler(e, 'firstname', setFirstname, function () {
                      if (!e.target.value || e.target.value.length < 2) {
                        setCustomError({
                          ...customError,
                          firstname: 'Minimum firstname length at least 2 characters'
                        })
                      }
                    })
                  }}
                  required
                />
                <input
                  type="lastname"
                  className="form-control form-control-half text-left-input"
                  id="lastname"
                  name="lastname"
                  placeholder="Last name"
                  value={lastname}
                  onChange={(e) => {
                    onChangeHandler(e, 'lastname', setLastname, function () {})
                  }}
                />
              </div>
              {customError.username && <p className="text-error">{customError.username}</p>}
              <div className="row mb-2">
                <input
                  type="username"
                  className="form-control text-left-input"
                  id="username"
                  name="username"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => {
                    onChangeHandler(e, 'username', setUsername, function () {
                      if (e.target.value.length < 5 || e.target.value > 15) {
                        setCustomError({
                          ...customError,
                          username: 'Username should between 5 and 15 characters'
                        })
                      }
                    })
                  }}
                  maxLength="15"
                  required
                />
              </div>
              {customError.email && <p className="text-error">{customError.email}</p>}
              <div className="row mb-2">
                <input
                  type="email"
                  className="form-control text-left-input"
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => {
                    onChangeHandler(e, 'email', setEmail, function () {
                      if (!emailRegex.test(e.target.value)) {
                        setCustomError({
                          ...customError,
                          email: 'Invalid email format'
                        })
                      }
                    })
                  }}
                  required
                />
              </div>
              {customError.password && <p className="text-error">{customError.password}</p>}
              <div className="row mb-2">
                <input
                  type="password"
                  className="form-control text-left-input"
                  id="password"
                  name="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => {
                    onChangeHandler(e, 'password', setPassword, function () {
                      if (e.target.value.length < 8) {
                        setCustomError({
                          ...customError,
                          password: 'Minimum password length at least 8 characters'
                        })
                      }
                    })
                  }}
                  required
                />
              </div>
              {customError.confirm_password && <p className="text-error">{customError.confirm_password}</p>}
              <div className="row mb-2">
                <input
                  type="password"
                  className="form-control text-left-input"
                  id="confirm_password"
                  name="confirm_password"
                  placeholder="Confirm Password"
                  value={confirm_password}
                  onChange={(e) => {
                    onChangeHandler(e, 'confirm_password', setConfirmPassword, function () {
                      if (e.target.value !== password) {
                        setCustomError({
                          ...customError,
                          confirm_password: "Confirm password didn't match with password"
                        })
                      }
                    })
                  }}
                  required
                />
              </div>
              {customError.country && <p className="text-error">{customError.country}</p>}
              <div className="row mb-2">
                <select
                  id="country"
                  name="country"
                  className="form-control text-left-input"
                  value={country}
                  required
                  onChange={(e) => {
                    onChangeHandler(e, 'country', setCountry, function () {
                      if (e.target.value.length < 1) {
                        setCustomError({
                          ...customError,
                          country: 'Please select your country'
                        })
                      }
                    })
                  }}
                >
                  <option value="" disabled hidden>Select Your Country</option>
                  {
                    optionList.country.map((countryData) => {
                      return <option
                        key={countryData.id}
                        value={countryData.code}
                      >
                        {countryData.label}
                      </option>
                    })
                  }
                </select>
              </div>
              {customError.organization && <p className="text-error">{customError.organization}</p>}
              <div className="row mb-2">
                <select
                  id="organization"
                  name="organization"
                  className="form-control text-left-input"
                  value={organization}
                  required
                  onChange={(e) => {
                    onChangeHandler(e, 'organization', setOrganization, function () {
                      if (e.target.value.length < 1) {
                        setCustomError({
                          ...customError,
                          organization: 'Please select your organization name'
                        })
                      }
                    })
                  }}
                >
                  <option value="" disabled hidden>Select Your Organization</option>
                  {
                    optionList.organization.map((organizationData) => {
                      return <option
                        key={organizationData.id}
                        value={organizationData.id}
                      >
                        {organizationData.name}
                      </option>
                    })
                  }
                </select>
              </div>
              {customError.role && <p className="text-error">{customError.role}</p>}
              <div className="row mb-2">
                <select 
                  id="role"
                  name="role" 
                  className="form-control text-left-input"
                  value={role} 
                  onChange={(e) => {
                    onChangeHandler(e, 'role', setRole, function () {
                      if (e.target.value.length < 1) {
                        setCustomError({
                          ...customError,
                          role: 'Please select role'
                        })
                      }
                    })
                  }}
                >
                  <option value="" disabled hidden>Select Your Role</option>
                  {
                    optionList.role.map((roleData) => {
                      return <option
                        key={roleData.id}
                        value={roleData.code}
                      >
                        {roleData.label}
                      </option>
                    })
                  }
                </select>
              </div>
            <button
              type="submit"
              className="button"
              size="lg"
            ><span>Register</span>
            </button>
            </form>
            <p className="text-left-input">Already have an account? <Link to="/login">Login</Link></p>
          </div>
          <div className="col register-box-content register-creator-content">
            <div className="register-square">
              <div className="row text-center">
                <img className="logo-reg" src={LogoGeoprosp} alt="logo-geoprosp" />
              </div>
              <div className="row text-center">
                {/* <div className="col logo-container-register">
                  <a href="https://www.petrosea.com/" target="_blank" rel="noreferrer" className="self-center">
                  <img src={LogoPetrosea} alt="Petrosea" className="logo-register"/> </a>
                </div> */}
                <div className="col logo-container-register">
                  <a href="https://www.geofix-id.com/" target="_blank" rel="noreferrer" className="self-center">
                  <img src={LogoGeofix} alt="Geofix" className="logo-register"/></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;