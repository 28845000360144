import React from 'react'

import { connect } from 'react-redux'

import {
  showEditor,
  hideEditor
} from '../../../../store/actions/editor'

import {
  ReactComponent as EditorIcon
} from '../../../../images/Icons/editor.svg'

import ContentIcon from './ContentIcon'

import iziToast from 'izitoast'

/**
 * The component Editor button
 * @param {Object} content - content represent the button given
 * @param {Number} translate - number translated
 * @param {Number} left - left position of button
 * @param {Number} top - top position of button
 * @param {Number} r - radius of the black circle
 * @param {Number} radians - radians of rotation value
 * @param {Number} widthSVG - the width given for SVG
 * @param {Boolean} toggleDisplay - status that button being shown or not
 * @param {Number} key - the id of component
 * @param {Boolean} show - editor is being toggle or not
 * @param {Function} showEditor - method to show editor
 * @param {Function} hideEditor - method to hide editor
 * @param {Object} display - state of display
 */
function EditorButton({
  content,
  translate,
  left,
  top,
  r,
  radians,
  widthSVG,
  toggleDisplay,
  key,
  show,
  showEditor,
  hideEditor,
  display
}) {
  function onClickHandler() {
    if (show) {
      hideEditor()
    } else {
      let categoryFound = false

      for (let i = 0; i < display.maps.length; i++) {
        if (display.maps[i].name === 'Mineral occurrences') {
          categoryFound = true

          break
        }
      }

      if (categoryFound) {
        showEditor()
      } else {
        iziToast.warning({
          title: 'Error',
          message: 'Please open mineral occurences map first'
        })
      }
    }
  }

  return (
    <ContentIcon
      key={key}
      top={
        toggleDisplay
          ? top + 'em'
          : top - translate - r * Math.sin(radians) + 'em'
      }
      left={
        toggleDisplay
          ? left + 'em'
          : left - translate - r * Math.cos(radians) + 'em'
      }
      height={(widthSVG + 0.5) + 'em'}
      width={(widthSVG + 0.5) + 'em'}
      isActive={show}
    >
      <EditorIcon
        title={content.title}
        onClick={onClickHandler}
      />
    </ContentIcon>
  )
}

function mapStateToProps(state) {
  return {
    show: state.editor.show,
    display: state.display
  }
}

const mapDispatchToProps = {
  showEditor,
  hideEditor
}

export default connect(mapStateToProps, mapDispatchToProps)(EditorButton)
