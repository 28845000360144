import React from 'react'

import { connect } from 'react-redux'

import {
  smartViewOn,
  smartViewOff
} from '../../../../store/actions/camera'

import { ReactComponent as BinocularIcon } from '../../../../images/Icons/binoculars.svg'
import cesiumViewer from '../../../../apis/cesiumViewer'

import iziToast from 'izitoast'

import {
  ToggleSVGButton
} from '../../../shares/ToggleSVGIcon'

function SmartViewButton({
  smartView,
  smartSelect,
  smartFreeze,
  smartViewOn,
  smartViewOff,
  prospectMap
}) {
  function onClickHandler() {
    if (!prospectMap || !prospectMap.dataSource) {
      return iziToast.warning({
        title: 'Initial Loading',
        message: 'Please waiting until first render process complete'
      })
    }

    if (smartView) {
      smartViewOff()

      if (smartSelect === false && smartFreeze === false) {
        prospectMap.dataSource.show = false
      }
      
      cesiumViewer.getCameraBoundingBox()
    } else {
      smartViewOn()
      prospectMap.dataSource.show = true
      cesiumViewer.getCameraBoundingBox()
    }
  }

  return <ToggleSVGButton flex margin="0.5em" active={smartView}>
    <BinocularIcon
      title="Smart View"
      alt="smart-view"
      onClick={onClickHandler}
    />
  </ToggleSVGButton>
}

function mapStateToProps(state) {
  return {
    smartView: state.camera.smartView,
    smartSelect: state.camera.smartSelect,
    smartFreeze: state.camera.smartFreeze,
    prospectMap: state.camera.prospectMap
  }
}

const mapDispatchToProps = {
  smartViewOn,
  smartViewOff
}

export default connect(mapStateToProps, mapDispatchToProps)(SmartViewButton)
