import React, { useState, useEffect, useCallback } from 'react'

import { connect } from 'react-redux'

import {
  setDeltaHeight
} from '../../../store/actions/tablebar'

import styled from 'styled-components'

const LineResizer = styled.div`
  height: 0.5em;
  cursor: ns-resize;
  width: 100%;
  transition: background 330ms;
  background: ${props => props.background};
  
  &:hover {
    background: red;
  }
`

function Resizer({
  tableDeltaHeight,
  setDeltaHeight
}) {
  const [ status, setStatus ] = useState(false)
  const [ initialY, setInitialY ] = useState(null)

  function startResize(event) {
    setStatus(true)
    setInitialY(tableDeltaHeight + event.screenY)
  }

  useEffect(() => {
    function stopResize() {
      setStatus(false)
      setInitialY(0)
    }

    function resizeWindow(event) {
      setDeltaHeight({
        deltaHeight: initialY - event.screenY - 5
      })
    }

    if (status) {
      document.addEventListener('mousemove', resizeWindow)
      document.addEventListener('mouseup', stopResize)
    }

    return () => {
      document.removeEventListener('mousemove', resizeWindow)
      document.removeEventListener('mouseup', stopResize)
    }
  }, [status])

  return (
    <LineResizer
      background={status ? 'red' : null}
      onMouseDown={startResize}
      onDrag={(event) => event.preventDefault}
    />
  )
}

function mapStateToProps({ table }) {
  return {
    tableDeltaHeight: table.deltaHeight
  }
}

const mapDispatchToProps = {
  setDeltaHeight
}

export default connect(mapStateToProps, mapDispatchToProps)(Resizer)
