import React from 'react'

import ToggleMap from './ToggleMap'

function ListMap({
  listMap,
  marginLeft
}) {
  return listMap.map((map, index) => {
    return (
      <ToggleMap key={index} map={map} marginLeft={marginLeft} />
    )
  })
}

export default ListMap
