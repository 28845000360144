import React, { useState, useEffect } from 'react'

// import {
//   createExtrudedHeight
// } from '../../../../apis/cesiumMap/grid'

// import {
//   setGeochemicalCesiumEntityShape
// } from '../../../../apis/cesiumMap/geochemical'

// import {
//   setMineralCesiumEntityShape
// } from '../../../../apis/cesiumMap/mineral'

// import {
//   setBoreholeCesiumEntityShape
// } from '../../../../apis/cesiumMap/borehole'

import {
  toggle3D
} from '../../../../../../../../apis/cesiumMap/helpers'

import {
  getDataSource
} from '../../../../../helpers'

import LayerbarIcon from '../../../../../shares/LayerbarIcon'

import {
  ReactComponent as DimensionalIcon
} from '../../../../../../../../images/Icons/3d.svg'

/**
 * This function will create 3d button icon
 * @param map the current map / for now is only grid
 */
function DimensionalButton({
  map
}) {
  const categories = ['grid', 'geochemical', 'mineral', 'borehole']
  const keywords = {
    grid: 'extrudedStatus',
    geochemical: 'ellipsoidStatus',
    mineral: 'ellipsoidStatus',
    borehole: 'polylineStatus'
  }
  
  const [ isActive, setActive ] = useState(false)

  useEffect(() => {
    const dataSource = map.dataSource

    if (!dataSource
      || !dataSource.entities
      || !dataSource.entities.values
      || !dataSource.entities.values.length) {
      return
    }

    const entity = dataSource.entities.values[0]

    if (entity.point) {
      setActive(false)
    } else if (entity.ellipsoid) {
      setActive(true)
    }
  }, [])

  function onClickHandler() {
    const dataSource = map.dataSource

    if (!dataSource
      || !dataSource.entities
      || !dataSource.entities.values
      || !dataSource.entities.values.length) {
      return
    }

    toggle3D({ entities: dataSource.entities.values, map })
    
    // map[keywords[map.category]] = !map[keywords[map.category]]
    // setActive(map[keywords[map.category]])

    // if (map.category === 'grid') {
    //   createExtrudedHeight({
    //     map,
    //     entities: dataSource.entities.values
    //   })
    // } else if (map.category === 'geochemical') {
    //   setGeochemicalCesiumEntityShape({
    //     map,
    //     entities: dataSource.entities.values
    //   })
    // } else if (map.category === 'mineral') {
    //   setMineralCesiumEntityShape({
    //     map,
    //     entities: dataSource.entities.values
    //   })
    // } else if (map.category === 'borehole') {
    //   setBoreholeCesiumEntityShape({
    //     map,
    //     entities: dataSource.entities.values
    //   })
    // }
  }

  return (
    <LayerbarIcon
      isActive={isActive}
    >
      <DimensionalIcon
        title="3D"
        onClick={onClickHandler}
      />
    </LayerbarIcon>
  )
}

export default DimensionalButton