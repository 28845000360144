import React, { useEffect, useState } from 'react'
import { productionAPI } from '../../../apis/server'
import { stringify } from 'wkt'

import ImageGeology from './ImageGeology'

function GeologyAndStructure({ selected, geologyAssets }) {
  if (geologyAssets?.properties?.assets?.geology) {
    return <ImageGeology selected={selected} gridId={selected?.site?.id} assets={geologyAssets}/>
  } else {
    return 'Not available...'
  }
}

export default GeologyAndStructure