import React, { useState, useEffect, useRef } from 'react'

import * as Cesium from 'cesium'

import { connect } from 'react-redux'

import {
  hideEditor
} from '../../../store/actions/editor'

import axiosReact from '../../../apis/axiosReact'

import ReactLoading from 'react-loading'

import HeaderForm from './HeaderForm'
import EditorForm from './EditorForm'
import ActionForm from './ActionForm'

import {
  saveHandler,
  updateHandler,
  deleteHandler,
  removeEntityHandler
} from './requestServerHelper'

import {
  getDefaultListForm,
  generateParametersFromCesium
} from './formHelper'

import iziToast from 'izitoast'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: absolute;
  background: white;
  right: 0.5em;
  top: 19.5em;
  width: 22em;
  border-radius: 20px;
  padding: 0.5em;
  z-index: 1;
  overflow: hidden;
`

const LoadingWrapper = styled.div`
  background: #05050553;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: ${props => props.width};
  height: ${props => props.height};
  display: flex;
  align-items: center;
  justify-content: center;
`;

/**
 * This component will create editbar
 * @param {Object} editor - redux state of editor
 * @param {Function} hideEditor - method to toggle of this editor
 * @param {Object} editorCesiumState - state of editor in cesium
 * @param {Function} setEditorCesiumState - method to set editor in cesiumjs
 */
function Editbar({
  editor,
  hideEditor,
  editorCesiumState,
  setEditorCesiumState
}) {
  const containerRef = useRef({
    current: null
  })

  const [ listForm, setListForm ] = useState([])
  const [ recommendation, setRecomendation ] = useState({})
  const [ confirmation, setConfirmation ] = useState({
    show: false,
    process: '', // 'update', 'save', 'delete', 'cancel'
    action: '' // 'cancel', 'remove'
  })

  const [ loading, setLoading ] = useState(false)
  const [ loadingStyle, setLoadingStyle ] = useState({
    width: null,
    height: null
  })

  const [ initialData, setInitialData ] = useState(null) // for edit

  useEffect(() => {
    if (containerRef.current && containerRef.current.getBoundingClientRect) {
      const { width, height } = containerRef.current.getBoundingClientRect()

      const marginBottom = 10

      setLoadingStyle((loadingStyle) => ({
        ...loadingStyle,
        width: width + 'px',
        height: height + marginBottom + 'px'
      }))
    }
  }, [containerRef.current, confirmation])

  useEffect(() => {
    async function fetchModelTypeRecommendation() {
      try {
        const { data } = await axiosReact.get('/geofix/helper_distinct/mineraloccurrence/model_types')
  
        setRecomendation((recommendation) => {
          return {
            ...recommendation,
            model_types: data
          }
        })
      } catch (error) {}
    }

    async function fetchMajorCommodityRecommendation() {
      try {
        const { data } = await axiosReact.get('/geofix/helper_distinct/mineraloccurrence/major_commodity')
  
        setRecomendation((recommendation) => {
          return {
            ...recommendation,
            major_commodity: data
          }
        })
      } catch (error) {}
    }

    fetchModelTypeRecommendation()
    fetchMajorCommodityRecommendation()

    return () => {
      setRecomendation({})
    }
  }, [])

  useEffect(() => {
    if (!editor.id) {
      setListForm(getDefaultListForm())
    }

    return (() => {
      setListForm([])
    })
  }, [editor])

  useEffect(() => {
    if (editorCesiumState && editorCesiumState.entity) {
      const now = Cesium.JulianDate.now()

      const position = editorCesiumState.entity.position.getValue(now)

      const cartographic = Cesium.Cartographic.fromCartesian(position)

      if (!editorCesiumState.entity.properties) {
        setListForm(getDefaultListForm({
          longitude: Cesium.Math.toDegrees(cartographic.longitude),
          latitude: Cesium.Math.toDegrees(cartographic.latitude)
        }))
      } else {
        const parameters = generateParametersFromCesium(editorCesiumState.entity.properties)

        setListForm(
          getDefaultListForm({
            parameters,
            longitude: Cesium.Math.toDegrees(cartographic.longitude),
            latitude: Cesium.Math.toDegrees(cartographic.latitude)
          })
        )
      }

      if (!initialData) {
        setInitialData({
          longitude: cartographic.longitude,
          latitude: cartographic.latitude
        })
      }
    }
  }, [editorCesiumState])

  function changeField(field, value) {
    if (field === 'Longitude') {
      for (let i = 0; i < listForm.length; i++) {
        if (listForm[i].showTitle === 'Latitude') {
          editorCesiumState.entity.position = Cesium.Cartesian3.fromDegrees(
            Number(value),
            Number(listForm[i].value)
          )

          break
        }
      }
    } else if (field === 'Latitude') {
      for (let i = 0; i < listForm.length; i++) {
        if (listForm[i].showTitle === 'Longitude') {
          editorCesiumState.entity.position = Cesium.Cartesian3.fromDegrees(
            Number(listForm[i].value),
            Number(value)
          )

          break
        }
      }
    }

    setListForm((listForm) => {
      return listForm.map((form) => {
        if (form.showTitle === field) {
          return {
            ...form,
            value
          }
        } else {
          return form
        }
      })
    })
  }

  function onCompleteChange() {
    setListForm(getDefaultListForm())

    setEditorCesiumState((editorCesiumState) => ({
      ...editorCesiumState,
      entity: null,
      add: false,
      edit: false,
      move: false
    }))

    setInitialData(null)
  }

  async function acceptConfirmationHandler() {
    setLoading(true)

    if (confirmation.process === 'save') {
      try {
        const { entity } = editorCesiumState
        
        if (entity) {
          // differ add and update is being seen from entity property available orn not
          if (!entity.properties) {
            await saveHandler(entity, listForm)
          } else {
            await updateHandler(entity, listForm)
          }
        }
        
        onCompleteChange()
      } catch (error) {
        iziToast.warning({
          title: 'Error',
          message: error.message
        })
      } finally {
        setLoading(false)
      }      
      return
    }

    if (confirmation.process === 'cancel') {
      if (initialData) {
        const { entity } = editorCesiumState

        entity.position = Cesium.Cartesian3.fromDegrees(
          Cesium.Math.toDegrees(initialData.longitude),
          Cesium.Math.toDegrees(initialData.latitude)
        )
      }

      onCompleteChange()
      
      setLoading(false)
      return
    }

    if (confirmation.process === 'delete') {
      const { entity } = editorCesiumState
      
      if (entity) {
        // differ new or not is being seen from entity property available orn not
        if (!entity.properties) {
          removeEntityHandler(entity)
        } else {
          await deleteHandler(entity)
        }
      }

      onCompleteChange()
      
      setLoading(false)
      return
    }
  }

  async function declineConfirmationHandler() {
    if (confirmation.process === 'save') {
      return
    }

    if (confirmation.process === 'delete') {
      return
    }
  }

  if (!editor.show) {
    return null
  }

  function hideEditorHandler() {
    const { entity } = editorCesiumState
      
    if (entity) {
      // differ new or not is being seen from entity property available orn not
      if (!entity.properties) {
        removeEntityHandler(entity)
      } else if (initialData) {
        const { entity } = editorCesiumState

        entity.position = Cesium.Cartesian3.fromDegrees(
          Cesium.Math.toDegrees(initialData.longitude),
          Cesium.Math.toDegrees(initialData.latitude)
        )
      }
    }

    onCompleteChange()

    hideEditor()
  }

  if (!editorCesiumState) {
    return null
  }

  return <Wrapper ref={containerRef}>
    <HeaderForm
      hideEditor={hideEditorHandler}
      editorCesiumState={editorCesiumState}
      setEditorCesiumState={setEditorCesiumState}
    />
    {
      editorCesiumState.entity
        && <>
          <EditorForm
            listForm={listForm}
            changeField={changeField}
            recommendation={recommendation}
          />
          <ActionForm
            editor={editor}
            hideEditor={hideEditor}
            confirmation={confirmation}
            setConfirmation={setConfirmation}
            editorCesiumState={editorCesiumState}
            acceptConfirmationHandler={acceptConfirmationHandler}
            declineConfirmationHandler={declineConfirmationHandler}
          />
        </>
    }
    { 
      loading
      && <LoadingWrapper 
        width={loadingStyle.width}
        height={loadingStyle.height}
      >
        <ReactLoading type="spin" color="white" width="4em" />
      </LoadingWrapper>
    }
  </Wrapper>
}

function mapStateToProps(state) {
  return {
    editor: state.editor
  }
}

const mapDispatchToProps = {
  hideEditor
}

export default connect(mapStateToProps, mapDispatchToProps)(Editbar)
