import React from "react";
import Navbar from "../../components/Menubars/Navbar";
import "./failed.css";
import { Link } from 'react-router-dom';
import FailLogo from "./failed.svg";

function Failed (props) {
    
  return (
    <div className="content-block">
      <Navbar className="row"/>
      <div className="img-bg-big">
        <div className="container-reset input-email-form ">
            <div className="row">
              <img src={FailLogo} alt="Logo-reset-password" className=" logo-reset"/>
            </div>
            <div>
              <p className="row text-center-reset">Sorry, email verification failed!</p>
            </div>
            <p className="text-center-input">Please make sure that you click the link that we sent to you or have copied and pasted the entire URL from your email in your browser. If you are still having trouble verifying your email <Link>contact customer support.</Link> </p>
        </div>
      </div>
    </div>
  );
}

export default Failed