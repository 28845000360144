// https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb

function componentToHex(c) {
  const hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

export default function rgbToHex(red, green, blue) {
  return '#' + String(componentToHex(red))
    + String(componentToHex(green))
    + String(componentToHex(blue))
}
