import React from 'react'

import FocusContainer, {
  Header,
  Paragraph
} from '../../StyledComponent'
import HomePNG from '../../HelpImage/assistive-button-home.png'
import { LIST_HELP } from '../../constant'


function HelpHome(props) {
  return <>
    <FocusContainer {...props}>
      <Header>{LIST_HELP['assistive'].detail.home.header}</Header>
      <Paragraph>
        Home tool is used to return zoom view to Indonesia.
      </Paragraph>
      <img src={HomePNG} alt="Home image" width="500px" />
    </FocusContainer>
  </>
}

export default HelpHome
