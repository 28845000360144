export const COLOR_D3_QGIS_TEMPLATES = [
  {color1: '247,251,255,255', color2: '8,48,107,255', stops: '0.13;222,235,247,255:0.26;198,219,239,255:0.39;158,202,225,255:0.52;107,174,214,255:0.65;66,146,198,255:0.78;33,113,181,255:0.9;8,81,156,255', colorScale: 'interpolateBlues'},
  {color1: '166,97,26,255', color2: '1,133,113,255', stops: '0.25;223,194,125,255:0.5;245,245,245,255:0.75;128,205,193,255', colorScale: 'interpolateBrBG'},
  {color1: '237,248,251,255', color2: '0,109,44,255', stops: '0.25;178,226,226,255:0.5;102,194,164,255:0.75;44,162,95,255', colorScale: 'interpolateBuGn'},
  {color1: '237,248,251,255', color2: '129,15,124,255', stops: '0.25;179,205,227,255:0.5;140,150,198,255:0.75;136,86,167,255', colorScale: 'interpolateBuPu'},
  {color1: '240,249,232,255', color2: '8,104,172,255', stops: '0.25;186,228,188,255:0.5;123,204,196,255:0.75;67,162,202,255', colorScale: 'interpolateGnBu'},
  {color1: '247,252,245,255', color2: '0,68,27,255', stops: '0.13;229,245,224,255:0.26;199,233,192,255:0.39;161,217,155,255:0.52;116,196,118,255:0.65;65,171,93,255:0.78;35,139,69,255:0.9;0,109,44,255', colorScale: 'interpolateGreens'},
  {color1: '250,250,250,255', color2: '5,5,5,255', stops: null, colorScale: 'interpolateGreys'},
  {color1: '0,0,4,255', color2: '252,255,164,255', stops: '0.0196078;2,2,12,255:0.0392157;5,4,23,255:0.0588235;10,7,34,255:0.0784314;16,9,45,255:0.0980392;22,11,57,255:0.117647;30,12,69,255:0.137255;38,12,81,255:0.156863;47,10,91,255:0.176471;56,9,98,255:0.196078;64,10,103,255:0.215686;73,11,106,255:0.235294;81,14,108,255:0.254902;89,16,110,255:0.27451;97,19,110,255:0.294118;105,22,110,255:0.313725;113,25,110,255:0.333333;120,28,109,255:0.352941;128,31,108,255:0.372549;136,34,106,255:0.392157;144,37,104,255:0.411765;152,39,102,255:0.431373;160,42,99,255:0.45098;168,46,95,255:0.470588;176,49,91,255:0.490196;183,53,87,255:0.509804;191,57,82,255:0.529412;198,61,77,255:0.54902;204,66,72,255:0.568627;211,71,67,255:0.588235;217,77,61,255:0.607843;223,83,55,255:0.627451;228,90,49,255:0.647059;233,97,43,255:0.666667;237,105,37,255:0.686275;241,113,31,255:0.705882;244,121,24,255:0.72549;247,130,18,255:0.745098;249,139,11,255:0.764706;250,148,7,255:0.784314;251,157,7,255:0.803922;252,166,12,255:0.823529;252,176,20,255:0.843137;251,186,31,255:0.862745;250,196,42,255:0.882353;248,205,55,255:0.901961;246,215,70,255:0.921569;244,225,86,255:0.941176;242,234,105,255:0.960784;242,242,125,255:0.980392;245,249,146,255', colorScale: 'interpolateInferno'},
  {color1: '0,0,4,255', color2: '252,253,191,255', stops: '0.0196078;2,2,11,255:0.0392157;5,4,22,255:0.0588235;9,7,32,255:0.0784314;14,11,43,255:0.0980392;20,14,54,255:0.117647;26,16,66,255:0.137255;33,17,78,255:0.156863;41,17,90,255:0.176471;49,17,101,255:0.196078;57,15,110,255:0.215686;66,15,117,255:0.235294;74,16,121,255:0.254902;82,19,124,255:0.27451;90,22,126,255:0.294118;98,25,128,255:0.313725;106,28,129,255:0.333333;114,31,129,255:0.352941;121,34,130,255:0.372549;129,37,129,255:0.392157;137,40,129,255:0.411765;145,43,129,255:0.431373;153,45,128,255:0.45098;161,48,126,255:0.470588;170,51,125,255:0.490196;178,53,123,255:0.509804;186,56,120,255:0.529412;194,59,117,255:0.54902;202,62,114,255:0.568627;210,66,111,255:0.588235;217,70,107,255:0.607843;224,76,103,255:0.627451;231,82,99,255:0.647059;236,88,96,255:0.666667;241,96,93,255:0.686275;244,105,92,255:0.705882;247,114,92,255:0.72549;249,123,93,255:0.745098;251,133,96,255:0.764706;252,142,100,255:0.784314;253,152,105,255:0.803922;254,161,110,255:0.823529;254,170,116,255:0.843137;254,180,123,255:0.862745;254,189,130,255:0.882353;254,198,138,255:0.901961;254,207,146,255:0.921569;254,216,154,255:0.941176;253,226,163,255:0.960784;253,235,172,255:0.980392;252,244,182,255', colorScale: 'interpolateMagma'},
  {color1: '254,240,217,255', color2: '179,0,0,255', stops: '0.25;253,204,138,255:0.5;252,141,89,255:0.75;227,74,51,255', colorScale: 'interpolateOrRd'},
  {color1: '255,245,235,255', color2: '127,39,4,255', stops: '0.13;254,230,206,255:0.26;253,208,162,255:0.39;253,174,107,255:0.52;253,141,60,255:0.65;241,105,19,255:0.78;217,72,1,255:0.9;166,54,3,255', colorScale: 'interpolateOranges'},
  {color1: '123,50,148,255', color2: '0,136,55,255', stops: '0.25;194,165,207,255:0.5;247,247,247,255:0.75;166,219,160,255', colorScale: 'interpolatePRGn'},
  {color1: '208,28,139,255', color2: '77,172,38,255', stops: '0.25;241,182,218,255:0.5;247,247,247,255:0.75;184,225,134,255', colorScale: 'interpolatePiYG'},
  {color1: '13,8,135,255', color2: '240,249,33,255', stops: '0.0196078;27,6,141,255:0.0392157;38,5,145,255:0.0588235;47,5,150,255:0.0784314;56,4,154,255:0.0980392;65,4,157,255:0.117647;73,3,160,255:0.137255;81,2,163,255:0.156863;89,1,165,255:0.176471;97,0,167,255:0.196078;105,0,168,255:0.215686;113,0,168,255:0.235294;120,1,168,255:0.254902;128,4,168,255:0.27451;135,7,166,255:0.294118;142,12,164,255:0.313725;149,17,161,255:0.333333;156,23,158,255:0.352941;162,29,154,255:0.372549;168,34,150,255:0.392157;174,40,146,255:0.411765;180,46,141,255:0.431373;186,51,136,255:0.45098;191,57,132,255:0.470588;196,62,127,255:0.490196;201,68,122,255:0.509804;205,74,118,255:0.529412;210,79,113,255:0.54902;214,85,109,255:0.568627;218,91,105,255:0.588235;222,97,100,255:0.607843;226,102,96,255:0.627451;230,108,92,255:0.647059;233,114,87,255:0.666667;237,121,83,255:0.686275;240,127,79,255:0.705882;243,133,75,255:0.72549;245,140,70,255:0.745098;247,147,66,255:0.764706;249,154,62,255:0.784314;251,161,57,255:0.803922;252,168,53,255:0.823529;253,175,49,255:0.843137;254,183,45,255:0.862745;254,190,42,255:0.882353;253,198,39,255:0.901961;252,206,37,255:0.921569;251,215,36,255:0.941176;248,223,37,255:0.960784;246,232,38,255:0.980392;243,240,39,255', colorScale: 'interpolatePlasma'},
  {color1: '241,238,246,255', color2: '4,90,141,255', stops: '0.25;189,201,225,255:0.5;116,169,207,255:0.75;43,140,190,255', colorScale: 'interpolatePuBu'},
  {color1: '246,239,247,255', color2: '1,108,89,255', stops: '0.25;189,201,225,255:0.5;103,169,207,255:0.75;28,144,153,255', colorScale: 'interpolatePuBuGn'},
  {color1: '230,97,1,255', color2: '94,60,153,255', stops: '0.25;253,184,99,255:0.5;247,247,247,255:0.75;178,171,210,255', colorScale: 'interpolatePuOr'},
  {color1: '241,238,246,255', color2: '152,0,67,255', stops: '0.25;215,181,216,255:0.5;223,101,176,255:0.75;221,28,119,255', colorScale: 'interpolatePuRd'},
  {color1: '252,251,253,255', color2: '63,0,125,255', stops: '0.13;239,237,245,255:0.26;218,218,235,255:0.39;188,189,220,255:0.52;158,154,200,255:0.65;128,125,186,255:0.75;106,81,163,255:0.9;84,39,143,255', colorScale: 'interpolatePurples'},
  {color1: '202,0,32,255', color2: '5,113,176,255', stops: '0.25;244,165,130,255:0.5;247,247,247,255:0.75;146,197,222,255', colorScale: 'interpolateRdBu'},
  {color1: '202,0,32,255', color2: '64,64,64,255', stops: '0.25;244,165,130,255:0.5;255,255,255,255:0.75;186,186,186,255', colorScale: 'interpolateRdGy'},
  {color1: '254,235,226,255', color2: '122,1,119,255', stops: '0.25;251,180,185,255:0.5;247,104,161,255:0.75;197,27,138,255', colorScale: 'interpolateRdPu'},
  {color1: '215,25,28,255', color2: '44,123,182,255', stops: '0.25;253,174,97,255:0.5;255,255,191,255:0.75;171,217,233,255', colorScale: 'interpolateRdYlBu'},
  {color1: '215,25,28,255', color2: '26,150,65,255', stops: '0.25;253,174,97,255:0.5;255,255,192,255:0.75;166,217,106,255', colorScale: 'interpolateRdYlGn'},
  {color1: '255,245,240,255', color2: '103,0,13,255', stops: '0.13;254,224,210,255:0.26;252,187,161,255:0.39;252,146,114,255:0.52;251,106,74,255:0.65;239,59,44,255:0.78;203,24,29,255:0.9;165,15,21,255', colorScale: 'interpolateReds'},
  {color1: '215,25,28,255', color2: '43,131,186,255', stops: '0.25;253,174,97,255:0.5;255,255,191,255:0.75;171,221,164,255', colorScale: 'interpolateSpectral'},
  {color1: '68,1,84,255', color2: '253,231,37,255', stops: '0.0196078;70,8,92,255:0.0392157;71,16,99,255:0.0588235;72,23,105,255:0.0784314;72,29,111,255:0.0980392;72,36,117,255:0.117647;71,42,122,255:0.137255;70,48,126,255:0.156863;69,55,129,255:0.176471;67,61,132,255:0.196078;65,66,135,255:0.215686;63,72,137,255:0.235294;61,78,138,255:0.254902;58,83,139,255:0.27451;56,89,140,255:0.294118;53,94,141,255:0.313725;51,99,141,255:0.333333;49,104,142,255:0.352941;46,109,142,255:0.372549;44,113,142,255:0.392157;42,118,142,255:0.411765;41,123,142,255:0.431373;39,128,142,255:0.45098;37,132,142,255:0.470588;35,137,142,255:0.490196;33,142,141,255:0.509804;32,146,140,255:0.529412;31,151,139,255:0.54902;30,156,137,255:0.568627;31,161,136,255:0.588235;33,165,133,255:0.607843;36,170,131,255:0.627451;40,174,128,255:0.647059;46,179,124,255:0.666667;53,183,121,255:0.686275;61,188,116,255:0.705882;70,192,111,255:0.72549;80,196,106,255:0.745098;90,200,100,255:0.764706;101,203,94,255:0.784314;112,207,87,255:0.803922;124,210,80,255:0.823529;137,213,72,255:0.843137;149,216,64,255:0.862745;162,218,55,255:0.882353;176,221,47,255:0.901961;189,223,38,255:0.921569;202,225,31,255:0.941176;216,226,25,255:0.960784;229,228,25,255:0.980392;241,229,29,255', colorScale: 'interpolateViridis'},
  {color1: '255,255,204,255', color2: '0,104,55,255', stops: '0.25;194,230,153,255:0.5;120,198,121,255:0.75;49,163,84,255', colorScale: 'interpolateYlGn'},
  {color1: '255,255,204,255', color2: '37,52,148,255', stops: '0.25;161,218,180,255:0.5;65,182,196,255:0.75;44,127,184,255', colorScale: 'interpolateYlGnBu'},
  {color1: '255,255,212,255', color2: '153,52,4,255', stops: '0.25;254,217,142,255:0.5;254,153,41,255:0.75;217,95,14,255', colorScale: 'interpolateYlOrBr'},
  {color1: '255,255,178,255', color2: '189,0,38,255', stops: '0.25;254,204,92,255:0.5;253,141,60,255:0.75;240,59,32,255', colorScale: 'interpolateYlOrRd'}
]

export function convertColorQgisToD3({ map, prop }) {
  let color1 = null
  let color2 = null
  let stops = null

  for (let i = 0; i < prop.length; i++) {
    if (prop[i]._k === 'color1') {
      color1 = prop[i]._v
    } else if (prop[i]._k === 'color2') {
      color2 = prop[i]._v
    } else if (prop[i]._k === 'stops') {
      stops = prop[i]._v
    }

    if (color1 && color2 && stops) {
      break
    }
  }

  if (!color1 || !color2) {
    return
  }

  for (let i = 0; i < COLOR_D3_QGIS_TEMPLATES.length; i++) {
    const template = COLOR_D3_QGIS_TEMPLATES[i]

    if (
      template.color1 === color1
      && template.color2 === color2
      && template.stops === stops
    ) {
      map.colorScale = template.colorScale
      map.cesiumLayer.setInverse(true)

      return
    }
    
    let revertStops = null

    if (template.stops) {
      revertStops = template.stops.split(';')

      for (let j = 1; j < revertStops.length / 2; j += 2) {
        const hold = revertStops[j]
        revertStops[j] = revertStops[revertStops.length - j]
        revertStops[revertStops.length - j] = hold
      }

      revertStops = revertStops.join(';')
    }

    if (
      template.color1 === color2
      && template.color2 === color1
      && revertStops === stops
    ) {
      map.colorScale = template.colorScale
      map.cesiumLayer.setInverse(true)
      
      return
    }
  }

  const customColorScale = `custom-${COLOR_D3_QGIS_TEMPLATES.length}`

  COLOR_D3_QGIS_TEMPLATES.push({
    color1,
    color2,
    stops,
    colorScale: customColorScale
  })

  map.colorScale = customColorScale
  map.cesiumLayer.setInverse(false)
}

export function convertColorD3ToQgis({ map }) {
  if (!map.colorScale) {
    return null
  }

  for (let i = 0; i < COLOR_D3_QGIS_TEMPLATES.length; i++) {
    const template = COLOR_D3_QGIS_TEMPLATES[i]

    if (template.colorScale === map.colorScale) {
      let stops = template.stops

      if (!map.cesiumLayer.inverse) {
        stops = stops.split(';')

        for (let j = 1; j < stops.length / 2; j += 2) {
          const hold = stops[j]
          stops[j] = stops[stops.length - j]
          stops[stops.length - j] = hold
        }

        stops = stops.join(';')
      }

      return {
        colorramp: {
          _name: "[source]",
          _type: "gradient",
          prop: [
            {
              _v: template.color1,
              _k: "color1"
            },
            {
              _v: template.color2,
              _k: "color2"
            },
            {
              _v: 0,
              _k: "discrete"
            },
            {
              _v: "gradient",
              _k: "rampType"
            },
            {
              _v: stops,
              _k: "stops"
            }
          ]
        }
      }
    }
  }
}
