import React, { useState } from 'react'

import { ReactComponent as CloseIcon } from '../../images/Icons/close.svg'

import { WarningIconWrapper } from './shares/Icon'

import styled from 'styled-components'

const SpanWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid goldenrod;
  font-family: Abel;
  font-size: 0.7em;
  border-radius: 10px;
  padding: 0.2em 0.4em;
  margin: 0.2em;
  transition: all 330ms;
  cursor: default;

  &:hover {
    background: #ffc22892;
  }
`

const InputSpanIcon = styled(WarningIconWrapper)`
  margin-left: 0.3em;
  cursor: pointer;

  svg {
    height: 0.6em;
  }
`

/**
 * Component to create span that can be removed
 * @param {String} text - selected text
 * @param {Function} onDeleteHandler - method to delete this span
 */
function InputSpan({
  text,
  onDeleteHandler = () => {}
}) {
  const [ hover, setHover ] = useState(false)

  return <SpanWrapper
    onMouseEnter={() => {
      setHover(true)
    }}
    onMouseLeave={() => {
      setHover(false)
    }}
  >
    <span>{text}</span>
    {
      hover
      && <InputSpanIcon onClick={onDeleteHandler} width="0.6em">
        <CloseIcon />
      </InputSpanIcon>
    }
  </SpanWrapper>
}

export default InputSpan
