import * as actionTypes from '../../constants/store/actionTypes/sitebar'

export function toggleSitebar(payload) {
  return {
    type: actionTypes.TOGGLE_SITEBAR,
    payload
  }
}

export function toggleSitebarSuccess(payload) {
  return {
    type: actionTypes.TOGGLE_SITEBAR_SUCCESS,
    payload
  }
}

export function minimizeSitebar(payload) {
  return {
    type: actionTypes.MINIMIZE_SITEBAR,
    payload
  }
}

export function resetSitebar(payload) {
  return {
    type: actionTypes.RESET_SITEBAR,
    payload
  }
}
