import React, { useState } from 'react'

import Header from '../shares/Header'
import CloseSidebar from '../shares/CloseSidebar'

import Content from './Content'

function Display() {
  const [dataCatalogToggle, setDataCatalog] = useState(true)

  return <>
    <Header
      dataToggle={dataCatalogToggle}
      setToggle={setDataCatalog}
      text="Data Catalog"
    >
      <CloseSidebar
        buttonName="layer"
      />
    </Header>
    {
      dataCatalogToggle
        && <Content />
    }
  </>
}

export default Display
