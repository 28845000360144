import parser from 'fast-xml-parser'

import { findKeyProperties } from '../../packages/cesium-add-on/properties'

import { convertColorQgisToD3 } from './colorTemplate'

function readQML(qml) {
  const options = {
    attributeNamePrefix : "_",
    // attrNodeName: "attr", //default is 'false'
    // textNodeName : "#text",
    ignoreAttributes : false,
    // ignoreNameSpace : false,
    allowBooleanAttributes : true,
    // parseNodeValue : true,
    parseAttributeValue : true,
    trimValues: true,
    // cdataTagName: "__cdata", //default is 'false'
    // cdataPositionChar: "\\c",
    // parseTrueNumberOnly: false,
    // numParseOptions:{
    //   hex: true,
    //   leadingZeros: true,
    //   //skipLike: /\+[0-9]{10}/
    // },
    arrayMode: false, //"strict"
    // attrValueProcessor: (val, attrName) => he.decode(val, {isAttributeValue: true}),//default is a=>a
    attrValueProcessor: a => a,
    tagValueProcessor: a => a,
    // tagValueProcessor : (val, tagName) => he.decode(val), //default is a=>a
    // stopNodes: ["parse-me-as-string"]
  }

  if(parser.validate(qml) === true) { //optional (it'll return an object in case it's not valid)
    return parser.parse(qml, options)
  }
}

export default function generateGroupEntitiesFromQML({
  map,
  listGroup,
  setGroupEntities,
  setFieldMode,
  setFieldAttribute,
  setGroupBy,
  qml
}) {
  const objQML = readQML(qml).qgis

  const groupEntities = {
    group: {},
    keys: [],
    style: {}
  }

  const groupBy = objQML['renderer-v2']._attr

  setGroupBy(groupBy)

  objQML['renderer-v2'].symbols.symbol.sort((a, b) => {
    return a._name - b._name
  })

  if (objQML['renderer-v2']._type === 'categorizedSymbol') {
    setFieldAttribute('categorical')

    objQML['renderer-v2'].categories.category.sort((a, b) => {
      return a._symbol - b._symbol
    })

    groupEntities.keys = objQML['renderer-v2'].categories.category
      .map((category) => {
        return {
          value: category._value,
          label: category._label
        }
      })

    let ready = true // not ready for symbols
    groupEntities.keys.forEach((key, index) => {
      groupEntities.group[key.label] = []

      if (!objQML['renderer-v2'].symbols.symbol[index].layer.prop) {
        ready = false
        return false
      }

      for (let i = 0; i < objQML['renderer-v2'].symbols.symbol[index].layer.prop.length; i++) {
        const prop = objQML['renderer-v2'].symbols.symbol[index].layer.prop[i]

        if (prop._k === 'color' || prop._k === 'line_color') {
          const [ red, green, blue, alpha ] = prop._v.split(',')

          groupEntities.style[key.label] = {
            color: {
              red: Number(red) / 255,
              green: Number(green) / 255,
              blue: Number(blue) / 255,
              alpha: Number(alpha) / 255
            }
          }

          break
        }
      }
    })

    if (!ready) {
      return false
    }

    generateGroupValue({
      map,
      listGroup,
      groupBy,
      groupEntities,
      attribute: 'categorical'
    })
  } else if (objQML['renderer-v2']._type === 'graduatedSymbol') {
    setFieldAttribute('numerical')

    objQML['renderer-v2'].ranges.range.sort((a, b) => {
      return a._symbol - b._symbol
    })

    groupEntities.keys = objQML['renderer-v2'].ranges.range
      .map((range) => {
        return {
          value: range._lower + ' - ' + range._upper,
          label: range._label
        }
      })

    groupEntities.keys.forEach((key, index) => {
      groupEntities.group[key.label] = []

      for (let i = 0; i < objQML['renderer-v2'].symbols.symbol[index].layer.prop.length; i++) {
        const prop = objQML['renderer-v2'].symbols.symbol[index].layer.prop[i]

        if (prop._k === 'color' || prop._k === 'line_color') {
          const [ red, green, blue, alpha ] = prop._v.split(',')

          groupEntities.style[key.label] = {
            color: {
              red: Number(red) / 255,
              green: Number(green) / 255,
              blue: Number(blue) / 255,
              alpha: Number(alpha) / 255
            }
          }

          break
        }
      }
    })

    generateGroupValue({
      map,
      listGroup,
      groupBy,
      groupEntities,
      attribute: 'numerical'
    })
  }

  if (objQML['renderer-v2'].classificationMethod) {
    const DEFAULT_PRECISION = 3

    let precision = DEFAULT_PRECISION

    if (
      objQML['renderer-v2'].classificationMethod.labelFormat
      && objQML['renderer-v2'].classificationMethod.labelFormat._labelprecision
    ) {
      precision = objQML['renderer-v2'].classificationMethod.labelFormat._labelprecision
    } 

    if (objQML['renderer-v2'].classificationMethod._id === 'Logarithmic') {
      setFieldMode({
        mode: 'logarithmic',
        precision
      })
    } else if (objQML['renderer-v2'].classificationMethod._id === 'EqualInterval') {
      setFieldMode({
        mode: 'equal_interval',
        precision
      })
    } else if (objQML['renderer-v2'].classificationMethod._id) {
      setFieldMode({
        mode: 'custom',
        precision
      })
    }
  }

  if (objQML['renderer-v2']?.colorramp?.ramp) {
    convertColorQgisToD3({
      map,
      prop: objQML['renderer-v2'].colorramp.prop
    })
  } else {
    map.colorScale = null
    map.cesiumLayer.setInverse(false)
  }

  if (groupEntities.keys.length) {
    const alpha = groupEntities.style[groupEntities.keys[0].label].color.alpha

    map.cesiumLayer.setAlpha(alpha)
  }

  setGroupEntities(groupEntities)

  return true
}

function generateGroupValue({
  map,
  listGroup,
  groupBy,
  groupEntities,
  attribute
}) {
  const entities = map.dataSource.entities.values

  setGroupValue:
  for (let i = 0; i < entities.length; i++) {
    entities[i].show = true

    const indexKey = listGroup
      .map((group) => {
        return group.pickName.toLowerCase()
      })
      .indexOf(groupBy.toLowerCase())

    if (indexKey === -1) {
      continue
    }

    const value = findKeyProperties({
      properties: entities[i].properties,
      key: listGroup[indexKey].pickName
    })

    if (attribute === 'categorical') {
      for (let j = 0; j < groupEntities.keys.length; j++) {
        const key = groupEntities.keys[j]

        if (key.value === value) {
          groupEntities.group[key.label].push(entities[i])
          
          continue setGroupValue
        }
      }

      entities[i].show = false
    } else if (attribute === 'numerical') {
      for (let j = 0; j < groupEntities.keys.length; j++) {
        const key = groupEntities.keys[j]

        const arrayNumber = key.value.split(' - ')
        const min = Number(arrayNumber[0])
        const max = Number(arrayNumber[1])

        if (Number(min) <= Number(value) && Number(value) < Number(max)) {
          groupEntities.group[key.label].push(entities[i]) 
          
          continue setGroupValue
        }
      }

      entities[i].show = false
    }
  }
}