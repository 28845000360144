import React, { useState, useEffect } from 'react'

import {
  generateLabel,
  changeLabel,
  removeLabel
} from '../../../../../../../../apis/cesiumLabel'

import Dropdown from '../../../../../../../Dropdowns/Default'

import styled from 'styled-components'

const Input = styled.input`
  margin-top: 0.4em;
  margin-left: 0.6em;
`

const Label = styled.label`
  font-family: 'Abel';
  font-size: 0.8em;
  margin-top: 0.08em;
  margin-right: 0.6em;
`

/**
 * This components will create dropdown and checkbox of label
 * to show into entities
 * @param {Object} map is the current selected map
 * @param {Boolean} showLabel is the status label being checked or not
 * @param {Function} setShowLabel method to change status label
 */
function InputLabel({
  map,
  showLabel,
  setShowLabel
}) {
  const [ label, setLabel ] = useState(null)
  const [ groupBy, setGroupBy ] = useState(map.cesiumLayer.groupBy)
  const [ listGroup, setListGroup ] = useState(map.cesiumLayer.listGroup)

  /**
   * This function will create or update label
   */
  function createOrUpdateLabel() {
    const entities = map.dataSource
      .entities
      .values

    if (entities.length) {
      if (!entities[0].label) {
        generateLabel({
          entities,
          key: label
        })
      } else {
        changeLabel({
          entities,
          key: label
        })
      }
    }
  }

  function checkHandler() {
    setShowLabel(!showLabel)

    if (!showLabel) {
      createOrUpdateLabel()
    } else {
      const entities = map.dataSource
        .entities
        .values

      removeLabel({
        entities
      })
    }
  }

  useEffect(() => {
    function eventGroupHandler() {
      setGroupBy(map.cesiumLayer.groupBy)
    }

    function eventListGroupHandler() {
      setListGroup(map.cesiumLayer.listGroup)
    }

    map.cesiumLayer.subscribeEvent('groupBy', eventGroupHandler)
    map.cesiumLayer.subscribeEvent('listGroup', eventListGroupHandler)

    return (() => {
      map.cesiumLayer.unsubscribeEvent('groupBy', eventGroupHandler)
      map.cesiumLayer.unsubscribeEvent('listGroup', eventListGroupHandler)
    })
  }, [])

  useEffect(() => {
    if (groupBy) {
      setLabel(groupBy)
    }
  }, [groupBy])

  useEffect(() => {
    if (showLabel && label) {
      createOrUpdateLabel()
    }
  }, [label])

  return listGroup.length
    ? <>
      <Dropdown
        label="Pick label"
        options={listGroup}
        setToggle={setLabel}
        width="10em"
        defaultPick={label}
      />
      <Input
        type="checkbox"
        defaultChecked={showLabel}
        onChange={checkHandler}
      />
      <Label>Show</Label>
    </>
    : <Label>Properties not found</Label>
}

export default InputLabel
