import React from 'react'

import styled from 'styled-components'

import {
  DANGER_RED,
  SUCCESS_GREEN
} from '../../../shares/ColorTemplate'

const Container = styled.div`
  display: flex;
  position: fixed;
  margin-left: 5em;
  background-color: white;
  border: 1px solid black;
  padding: 0.5em 1em;
  font-family: Abel;
  font-size: 0.8em;
  border-radius: 5px;
  flex-direction: column;
`

const Button = styled.div`
  color: black;
  background: whitesmoke;
  padding: 0.5em 1em;
  width: 2em;
  height: 1em;
  border-radius: 5px;
  margin: 0 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border-color 330ms;
  border-width: 2px;
  border-style: solid;
  border-color: ${props => props.background.default};
  
  &:hover {
    border-color: ${props => props.background.hover};
  }
`

/**
 * This component show before delete commit from gradebar
 * @param {String} name is the name commit that want to be deleted
 * @param {Function} yesHandler is the method that will be trigger when yes button clicked
 * @param {Function} noHandler is the method that will be trigger when no button clicked  
 */
function DeleteConfirmation({
  name,
  yesHandler,
  noHandler
}) {
  return <Container>
    <div>
      Are you sure want to delete commit name {name}?
    </div>
    <div className="row" style={{
      marginTop: '0.3em',
      justifyContent: 'space-around'
    }}>
      <Button
        background={DANGER_RED}
        onClick={yesHandler}
      >Yes</Button>
      <Button
        background={SUCCESS_GREEN}
        onClick={noHandler}
      >No</Button>
    </div>
  </Container>
}

export default DeleteConfirmation
