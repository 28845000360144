import * as Cesium from 'cesium';

export function pointsViewer({
    view,
    data,
    color,
    outlineColor,
}){
    view.entities.removeAll()
    for (let i = 0; i < data.length; i++) {
        const oneData = data[i]
        if(oneData!==undefined && oneData.longitude!== undefined && oneData.latitude!==undefined){
            if(oneData.longitude !== null && oneData.latitude !== null){
                data[i].entity = view.entities.add({
                    position: Cesium.Cartesian3.fromDegrees(Number(oneData.longitude), Number(oneData.latitude)),
                    point: {
                        scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1, 8.0e6, 0.2),
                        pixelSize: 5,
                        color: color,
                        outlineColor: outlineColor,
                        outlineWidth: 1,                    
                        // heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
                    },
                    properties: {
                    ...oneData,
                    },
                });  
            }
        }
    }
    view.flyTo(view.entities)
};