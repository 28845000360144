import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'

import * as Cesium from 'cesium'

import {
  selectSite
} from '../../store/actions/sites'

import {
  setContext,
  closeContext
} from '../../store/actions/rightclick'

import {
  smartSelectOff
} from '../../store/actions/camera'

import {
  setIdOccurrences
} from '../../store/actions/editor'

import {
  CESIUM_ION_DEFAULT_ACCESS_TOKEN,
  DEFAULT_VIEW_FACTOR,
  HOME_EXTENT_EAST,
  HOME_EXTENT_NORTH,
  HOME_EXTENT_SOUTH,
  HOME_EXTENT_WEST
} from '../../constants/Cesium/config'

import cesiumViewer from '../../apis/cesiumViewer'

import {
  changeCommodity
} from '../../store/actions/sites'

import CesiumLegend from './CesiumLegend'
import CesiumLight from './CesiumLight'
import CesiumExaggeration from './CesiumExaggeration'
import AssistTouch from '../Buttons/CesiumAssistButton/AssistTouch'

import CesiumUsePointer from './CesiumUsePointer'

import {
  mouseMoveHandler,
  generateScale
} from './helpers/eventMouseMove'

import {
  renderDrag
} from './helpers/renderDrag'

// import Toolbar from '../Menubars/Toolbar'
import Editbar from '../Menubars/Editbar'

import iziToast from 'izitoast'

import './Cesium.css'
import './widgets.css'
import { CesiumLoadImages } from './CesiumLoadImages'
/**
 * This function will generate cesium basic options
 * @returns options to create cesium
 */
function createCesiumOptions() {
  const imageryProviderViewModels = Cesium.createDefaultImageryProviderViewModels()

  return {
    // widget
    animation: false,
    baseLayerPicker: true,
    geocoder: true,
    navigationHelpButton: false,
    selectionIndicator: false,
    timeline: true,
    // make scene only available in 3d
    // scene3DOnly: true,
    // set image provider to esri
    imageryProvider: Cesium.createWorldImagery({
      style: Cesium.IonWorldImageryStyle.AERIAL_WITH_LABELS,
    }),
    imageryProviderViewModels: imageryProviderViewModels,
    selectedImageryProviderViewModel: imageryProviderViewModels[3],
    shadows: true,
    mapProcjection: new Cesium.WebMercatorProjection(),
    // set terrain
    terrainProvider: Cesium.createWorldTerrain({
      requestVertexNormals : true,
      // requestWaterMask : true
    }),
    // terrainExaggeration: 1,
    // for performance, need to implement after the beta version finished
    // requestRenderMode: true
    mapProjection: new Cesium.WebMercatorProjection(),
            
    // save GPU memory
    scene3DOnly: true, // scene only in 3d
    automaticallyTrackDataSourceClocks: false,
    // framerate?
    targetFrameRate: 30,
  }
}

/**
 * This is Cesium Component that load cesium
 */
function CesiumComponent({
  drag,
  sites,
  selectSite,
  smartSelect,
  smartSelectOff,
  setContext,
  closeContext,
  setIdOccurrences,
  focusMap
}) {
  const [ hover, setHover ] = useState({
    status: null, // 'area' / 'map'
    entity: null,
    cartesian2: null
  })

  const [ legend, setLegend ] = useState({
    coordinate: '',
    scale: ''
  })

  const [ selection, setSelection ] = useState(true)

  const [ sliceEvent, setSliceEvent ] = useState({
    name: 'sliceEarth',
    status: false,
    selectionOnHandler() {},
    otherPointerOnHandler() {}
  })

  const [ crossEvent, setCrossEvent ] = useState({
    name: 'crossEarth',
    status: false,
    selectionOnHandler() {},
    otherPointerOnHandler() {}
  })

  const [ blockEvent, setBlockEvent ] = useState({
    name: 'blockEarth',
    status: false,
    selectionOnHandler() {},
    otherPointerOnHandler() {}
  })

  const [ addOccurrencesEvent, setAddOccurrencesEvent ] = useState({
    name: 'addOccurrences',
    status: false,
    selectionOnHandler() {},
    otherPointerOnHandler() {}
  })

  const [ moveOccurrencesEvent, setMoveOccurrencesEvent ] = useState({
    name: 'moveOccurrences',
    status: false,
    selectionOnHandler() {},
    otherPointerOnHandler() {}
  })

  const [ smartSelectEvent, setSmartSelectEvent ] = useState({
    name: 'smartSelect',
    status: smartSelect,
    selectionOnHandler: () => {
      smartSelectOff()
    },
    otherPointerOnHandler: () => {
      smartSelectOff()
    }
  })

  const [ section, setSection ] = useState({
    sliceSection: false,
    crossSection: false,
    blockSection: false
  })

  const [ editorCesiumState, setEditorCesiumState ] = useState({
    add: false,
    edit: false,
    move: false,
    entity: null
  })

  const listPointerEvent = [
    sliceEvent,
    crossEvent,
    blockEvent,
    addOccurrencesEvent,
    moveOccurrencesEvent,
    smartSelectEvent
  ]
  
  CesiumUsePointer({
    listPointerEvent,
    selection,
    setSelection,
    smartSelect,
    smartSelectOff
  })

  useEffect(() => {
    Cesium.Ion.defaultAccessToken = CESIUM_ION_DEFAULT_ACCESS_TOKEN

    const homeExtent = new Cesium.Rectangle.fromDegrees(
      HOME_EXTENT_EAST,
      HOME_EXTENT_SOUTH,
      HOME_EXTENT_WEST,
      HOME_EXTENT_NORTH
    )
  
    Cesium.Camera.DEFAULT_VIEW_RECTANGLE = homeExtent
    Cesium.Camera.DEFAULT_VIEW_FACTOR = DEFAULT_VIEW_FACTOR

    const viewer = new Cesium.Viewer('cesiumContainer', createCesiumOptions())

    viewer.scene.skyBox.show = false
    // viewer.scene.requestRenderMode = true

    cesiumViewer.setViewer(viewer)
    cesiumViewer.initViewModel()
    cesiumViewer.cesiumMouseEventHandler(viewer)

    return () => {
      if (viewer) {
        viewer.destroy()

        cesiumViewer.viewer = null
      }
    }
  }, [])

  useEffect(() => {
    const cesiumToolbars = document.getElementsByClassName('cesium-viewer-toolbar')
    
    const buttonToolbars = document.getElementsByClassName('cesium-toolbar-button')
    if (cesiumToolbars && cesiumToolbars.length && cesiumToolbars[1] && buttonToolbars && buttonToolbars.length) { 
      buttonToolbars[0].style.display = 'none'
      buttonToolbars[1].style.display = 'none'
    }

    const compass = document.getElementsByClassName('compass')
    
    for (let i = 0; i < cesiumToolbars.length; i++) {
        cesiumToolbars[i].style.transition = 'all 500ms'
    }
    
    if (compass.length) {
      compass[0].style.top = '1em'
      compass[0].style.transition = 'all 500ms'
    }
    const performances = document.getElementsByClassName('cesium-performanceDisplay-defaultContainer')
    
    for (let i = 0; i < performances.length; i++) {
      performances[i].style.transition = 'all 500ms'
    }

    const dropDownBaseLayers = document.getElementsByClassName('cesium-baseLayerPicker-dropDown')

    if (dropDownBaseLayers.length) {
      dropDownBaseLayers[0].style.zIndex = 2000
    }
  }, [])

  useEffect(() => {
    if (smartSelect) {
      listPointerEvent.forEach(pointerEvent => {
        if (pointerEvent.name !== 'smartSelect') {
          pointerEvent.otherPointerOnHandler(smartSelectEvent)
        }
      })
    }

    setSmartSelectEvent((selectEvent) => ({
      ...selectEvent,
      status: smartSelect
    }))
  }, [smartSelect])

  useEffect(() => {
    cesiumViewer.mineralOccurrences = {
      ...cesiumViewer.mineralOccurrences,
      editorCesiumState: editorCesiumState,
      setEditorCesiumState: setEditorCesiumState
    }
  }, [])

  useEffect(() => {
    cesiumViewer.mineralOccurrences = {
      ...cesiumViewer.mineralOccurrences,
      editorCesiumState
    }

    if (editorCesiumState.add) {
      const entity = editorCesiumState.entity

      if (entity) {
        iziToast.info({
          title: 'Uncomplete Process',
          message: 'Please save or cancel current work'
        })
      } else {
        setSection({
          sliceSection: false,
          crossSection: false,
          blockSection: false
        })
      }
    } else if (
      editorCesiumState.move
      && editorCesiumState.entity
    ) {
      setSection({
        sliceSection: false,
        crossSection: false,
        blockSection: false
      })
    }
  }, [editorCesiumState])
  
  useEffect(()=>{
    if(cesiumViewer.viewer!==null && sites.commodity !==null){
      CesiumLoadImages({
        commodity:sites.commodity
      }) // grid in raster
    }
  },[sites.commodity])

  function renderPointer() {
    if (editorCesiumState.move) {
      return 'pointer-cursor'
    } else if (editorCesiumState.add) {
      return 'target-cursor'
    } else if (cesiumViewer.sectionStatus.slicePick) {
      return 'pointer-cursor'
    } else if (selection) {
      return ''
    } else {
      return 'target-cursor'
    }
  }

  return (
    <div
      id="cesiumContainer"
      className={renderPointer()}
      style={{
        top: '44px',
        height: `calc(100% - 44px)`,
        width: `calc(100%)`
      }}
      onClick={() => {
        closeContext()
      }}
      onContextMenu={(event) => {
        const { stringify } = require('wkt')
        const cartesian2 = new Cesium.Cartesian2(event.clientX - 50, event.clientY - 44)
        const ray = cesiumViewer.viewer.scene.camera.getPickRay(
          cartesian2
        )
        if (!ray.origin && !ray.direction) {
          return
        }
        const cartesian = cesiumViewer.viewer.scene.globe
        .pick(ray, cesiumViewer.viewer.scene)
        if (cartesian) {
          const cartographic = Cesium.Cartographic.fromCartesian(cartesian)
                
          const longitudeString = Cesium.Math.toDegrees(
            cartographic.longitude
          )
          
          const latitudeString = Cesium.Math.toDegrees(
            cartographic.latitude
          )
      
          const geometry = {
            type: "Point",
            coordinates: [longitudeString, latitudeString]
          }
          const wktcoordinate = stringify(geometry)

          if (
            sites.commodity !== null
            && focusMap
            && focusMap.subCategory === 'Rating and ranking'
          ) {
            let sitesWkt = {
              commodity: sites.commodity
            }

            let bin_size = 8000

            if (focusMap?.payload?.bin_size) {
              bin_size = focusMap.payload.bin_size
            }

            setContext({
              position: {
                pageX: event.pageX,
                pageY: event.pageY
              },
              context: [
                {
                  title: 'Show Detail',
                  clickEvent() {
                    selectSite({
                      site: sitesWkt,
                      filter_wkt: wktcoordinate,
                      bin_size: bin_size
                    })
                  }
                }
              ]
            })
          }
        }

        const listPick = cesiumViewer.viewer.scene.drillPick(cartesian2)
        for (let i = 0; i < listPick.length; i++) {
          if (
            listPick[i].id
            && listPick[i].id.properties
            && listPick[i].id.properties.deposite_name
            && listPick[i].id.properties.major_commodity
          ) {
            setContext({
              position: {
                pageX: event.pageX,
                pageY: event.pageY
              },
              context: [
                {
                  title: 'Edit',
                  clickEvent() {
                    if (editorCesiumState.entity) {
                      iziToast.warning({
                        title: 'Error',
                        message: 'Please save, cancel, or delete previous edit'
                      })
                      
                      return
                    }

                    const id = listPick[i].id.properties
                      .id.getValue(Cesium.JulianDate.now())
                    
                    setIdOccurrences({
                      id,
                      data: listPick[i].id
                    })

                    setEditorCesiumState((editorState) => ({
                      ...editorState,
                      entity: listPick[i].id,
                      edit: true
                    }))
                  }
                }
              ]
            })

            break
          }
        }
      }}
      onDoubleClick={(event) => {
        const cartesian2 = new Cesium.Cartesian2(event.clientX - 50, event.clientY - 44)

        const listPick = cesiumViewer.viewer.scene.drillPick(cartesian2)

        for (let i = 0; i < listPick.length; i++) {
          if (
            listPick[i].id
            && listPick[i].id.properties
            && listPick[i].id.properties.rating
            && listPick[i].id.properties.ranking
          ) {
            selectSite({
              site: {
                gridId: listPick[i].id.properties.id.getValue(Cesium.JulianDate.now())
              }
            })

            break
          }

          if (
            listPick[i].id
            && listPick[i].id.properties
            && listPick[i].id.properties.type_hse_class
            && listPick[i].id.properties.type_porphyry_class
            && listPick[i].id.properties.type_epithermal_class
          ) {
            selectSite({
              site: {
                gridId: listPick[i].id.properties.grid_id.getValue(Cesium.JulianDate.now())
              }
            })

            break
          }
        }
      }}
      onMouseMove={(event) => {
        mouseMoveHandler(event, {
          hover,
          setHover,
          legend,
          setLegend,
          section
        })
      }}
      onWheel={(event) => {
        setLegend({
          ...legend,
          scale: generateScale({
            event
          })
        })
      }}
      onDrop={(event) => {
        renderDrag(event)

        drag.event()
        event.preventDefault()
        event.stopPropagation()
      }}
      onDragOver={(event) => {
        event.dataTransfer.dropEffect = 'copy'

        event.preventDefault()
        event.stopPropagation()
      }}
    >
      <CesiumLegend legend={legend} />
      <CesiumLight />
      <CesiumExaggeration legend={legend} />
      <AssistTouch
        selection={selection}
        setSelection={setSelection}
        section={section}
        setSection={setSection}
        sliceEvent={sliceEvent}
        setSliceEvent={setSliceEvent}
        crossEvent={crossEvent}
        setCrossEvent={setCrossEvent}
        blockEvent={blockEvent}
        setBlockEvent={setBlockEvent}
        listPointerEvent={listPointerEvent}
      />
      {/* <Toolbar /> */}
      <Editbar
        editorCesiumState={editorCesiumState}
        setEditorCesiumState={setEditorCesiumState}
      />
      {/* <Toolbar /> */}
      <Editbar />
    </div>
  )
}

function mapStateToProps({ sites, drag, camera, display }) {
  return {
    sites,
    drag,
    smartSelect: camera.smartSelect,
    focusMap: display.focusMap
  }
}

const mapDispatchToProps = {
  changeCommodity,
  selectSite,
  smartSelectOff,
  setContext,
  closeContext,
  setIdOccurrences
}

export default connect(mapStateToProps, mapDispatchToProps)(CesiumComponent)
