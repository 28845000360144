import React from 'react'

import {
  JustifiedFlexColumn,
  FlexRow
} from '../../BasicStyle/FlexWrapper'

import styled from 'styled-components'

const Scale = styled.div`
  border-top: 1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid white;
  height: 3px;
  align-items: center;
`

const ScaleText = styled(FlexRow)`
  justify-content: space-between;
`

function CesiumScaller({ scale }) {
  if (!scale) {
    return null
  }

  let convertScale = scale

  let status = 'm'
  let width = 0
  if (convertScale >= 1000) {
    convertScale = convertScale / 1000
    status = 'km'
  }
  
  const rangeInM = [0, 1, 2.5, 5, 10, 25, 50, 100, 250, 500, 1000]
  const rangeInKm = [0, 1, 2.5, 5, 10, 25, 50, 100, 250, 500, 1000, 2500, 5000, 10000, 12500]

  if (status === 'm') {
    for (let i = rangeInM.length - 1; i >= 0; i--) {
      if (convertScale > rangeInM[i]) {
        width = 100 + ((rangeInM[i + 1] - convertScale) / rangeInM[i + 1]) * 100
        convertScale = rangeInM[i + 1]
        break
      }
    }
  } else {
    for (let i = rangeInKm.length - 1; i >= 0; i--) {
      if (convertScale > rangeInKm[i]) {
        width = 100 + ((rangeInKm[i + 1] - convertScale) / rangeInKm[i + 1]) * 100
        convertScale = rangeInKm[i + 1]
        break
      }
    }
  }

  if (convertScale === 1000 && status === 'm') {
    status = 'km'
    convertScale = 1
  }

  return <JustifiedFlexColumn>
    <Scale
      style={{
        width
      }}
    >
    </Scale>
    <ScaleText>
      <span>0</span>
      <span>{convertScale + ' ' + status}</span>
    </ScaleText>
  </JustifiedFlexColumn>
}

export default CesiumScaller
