import React from 'react'
import { LIST_HELP } from '../../constant'
import Map1PNG from '../../HelpImage/database-surface-maps(1).png'
import Map2PNG from '../../HelpImage/database-surface-maps(2).png'

import FocusContainer, {
  Header,
  LinkText,
  Paragraph
} from '../../StyledComponent'

/**
 * This will render help component for help section activation
 * @param {String} focus is the the newest clicked help
 * @param {String} currentHelp to tell component need to focus or not
 * @returns react component
 */
function HelpSurfaceMap(props) {
  const flyTo=(x)=>{
    props.setFocus(x)
  }
  return <>
    <FocusContainer {...props}>
      <Header>{LIST_HELP['database'].detail.maps.header}</Header>
      <img src={Map1PNG} alt="Chat new image" width="1100px" />
      <img src={Map2PNG} alt="Chat new image" width="800px" />
      {/* <Paragraph>
      </Paragraph>
      <Paragraph>
      <LinkText onClick={()=>flyTo('login')}>login</LinkText>.
      </Paragraph> */}
    </FocusContainer>
  </>
}

export default HelpSurfaceMap
