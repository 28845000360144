export const WHITE = {
  default: 'rgb(211, 211, 211)',
  hover: 'white'
}

export const HOLLOW_GREY = {
  default: 'rgb(58, 58, 58)',
  hover: 'rgb(100, 100, 100)'
}

export const DARK_GREY = {
  default: 'rgb(100, 100, 100)',
  hover: 'rgb(155, 155, 155)'
}

export const LIGHT_GREY = {
  default: '#999999'
}

export const GREYISH_DARK = {
  default: '#333333',
  hover: 'black'
}

// example stripe color
export const STRIPE_LIGHT_GREY = {
  0: 'rgb(37, 37, 37)',
  1: 'rgb(19, 18, 18)'
}

// example color in dropdown
export const LIGHT_WHITE = {
  default: 'white',
  hover: 'rgb(223, 223, 223)'
}

export const LIGHT_YELLOW = {
  default:  'rgb(255, 231, 172)',
  hover: 'rgb(253, 217, 124)'
}

// example color in delete and save icon
export const DANGER_RED = {
  default: 'rgb(255, 109, 109)',
  hover: '#e00202'
}

// example color range slider blue

export const PRIMARY_BLUE = {
  default: 'rgb(93, 166, 250)',
  hover: '#4294ff'
}

export const SUCCESS_GREEN = {
  default: 'rgb(46, 172, 30)',
  hover: 'rgb(28, 128, 15)'
}

export const GOLDEN_3D = {
  default: 'url(#gold-gradient) goldenrod',
  hover: 'url(#gold-gradient) goldenrod',
}

export const GOLD_FONT = `
radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #b68b2f 30%, #b99541 40%, transparent 80%),
radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #927531 62.5%, #8a7033 100%)
`

export const GOLD_GRADIENT = 'radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #b68b2f 30%, #b99541 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #927531 62.5%, #8a7033 100%)'
