import React from 'react'

import { connect } from 'react-redux'

import {
  triggerRender
} from '../../../../../../store/actions/cesiumEntity'

import UseSimpleAndDoubleClick from '../../../../../helpers/UseSimpleAndDoubleClick'

import LayerbarIcon from '../../../shares/LayerbarIcon'

import {
  ReactComponent as EyeIcon
} from '../../../../../../images/Icons/eye-2.svg'
import {
  ReactComponent as EyeSlashIcon
} from '../../../../../../images/Icons/eye-2-slash.svg'

/**
 * Function Eye is to show and hide cesium entity
 * @param isActive to show is eye active or not
 * @param onClickHandler the event will trigger when button clicked
 * @param onDobuleClickHandler the event will trigger when button clicked twice
 */
function Eye({
  isActive,
  onClickHandler,
  onDoubleClickHandler,
  triggerRender
}) {
  const doubleClick = UseSimpleAndDoubleClick(
    onClickHandler,
    onDoubleClickHandler
  )

  function toggle() {
    onDoubleClickHandler
      ? doubleClick()
      : onClickHandler()

    triggerRender({})
  }

  return (
    <LayerbarIcon isActive={isActive}>
      {
        isActive
          ? <EyeIcon
              title="hide"
              onClick={toggle}
            />
          : <EyeSlashIcon
              title="show"
              onClick={toggle}
            />
      }
    </LayerbarIcon>
  )
}

const mapDispatchToProps = {
  triggerRender
}

export default connect(null, mapDispatchToProps)(Eye)
