import * as actionTypes from '../../constants/store/actionTypes/advancedSearch'

const initialState = {
  search: '',
  alias: '',
  limit: 25,
  offset: 0,
  total: 0,
  currentPage: 1,
  totalPage: 0,
  searchLevel: '',
  searchKeyword: '',
  error: null
}

function advancedSearchReducer(state = initialState, { type, payload }) {
  switch (type) {
    case actionTypes.SET_ADVANCED_SEARCH_SUCCESS:
      return {
        ...state,
        search: payload.search,
        searchLevel: payload.searchLevel,
        searchKeyword: payload.searchKeyword
      }
    case actionTypes.SET_ADVANCED_SEARCH_LIMIT:
      return {
        ...state,
        limit: payload.limit
      }
    case actionTypes.SET_ADVANCED_SEARCH_OPTIONS:
      const options = {
        limit: state.limit,
        offset: state.offset,
        total: state.total,
        currentPage: state.currentPage,
        totalPage: state.totalPage
      }

      for (const key in options) {
        if (payload[key] !== undefined) {
          options[key] = payload[key]
        }
      }

      return {
        ...state,
        ...options
      }
    case actionTypes.RESET_ADVANCED_SEARCH_OPTIONS:
      const defaultOptions = {
        limit: 25,
        offset: 0,
        total: 0,
        currentPage: 1,
        totalPage: 0
      }

      return {
        ...state,
        ...defaultOptions
      }
    case actionTypes.SET_ADVANCED_SEARCH_ERROR:
      return {
        ...state,
        error: payload
      }
    case actionTypes.RESET_ADVANCED_SEARCH:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default advancedSearchReducer
