import React from 'react'

import { connect } from 'react-redux'

import {
  removeLegend
} from '../../../../store/actions/legendbar'

import {
  FlexColumn,
  AlignedFlexRow
} from '../../../BasicStyle/FlexWrapper'

import LegendScaleTileset from './LegendScaleTileset'
import LegendSymbol from '../LegendSymbol'
import CloseButton from '../CloseButton'

import styled from 'styled-components'

const Header = styled.div`
  font-family: Abel;
  font-size: 0.8em;
  font-weight: 600;
  margin: 0 0.5em;
  display: flex;
  align-items: center;
`

const Wrapper = styled(FlexColumn)`
  flex-wrap: wrap;
  font-size: 0.7em;
  margin: 0.5em;
`

const Span = styled.span`
  margin: 0.1em 0 0.1em 0.3em;
`

const EllipsisText = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

/**
 * This component will create content with header and content by given params
 * @param {Object} map is the selected map to render
 * @param {String} header is header to show
 * @param {Function} removeLegend is the method to remove this legend
 * @param {Boolean} isHover is the state that component are being hover or not
 * @param {Array} keys is list key that need to be show on the map
 */
function TilesetContent({
  map,
  header,
  removeLegend,
  isHover
}) {
  const attribute = map.cesiumLayer.fieldAttribute.selected

  function closeHandler() {
    removeLegend({
      name: map.name
    })
  }

  return <FlexColumn>
    <Header width="auto">
      <EllipsisText>{header}</EllipsisText>
      <CloseButton closeHandler={closeHandler} isHover={isHover} />
    </Header>
    <Wrapper>
      {
        attribute === 'numerical'
          ? <LegendScaleTileset
            map={map}
          />
          : map.tilesetLegend.map((styleLegend, i) => {
            return <AlignedFlexRow key={styleLegend.label} height="auto">
              <LegendSymbol
                geom={styleLegend.geom}
                color={styleLegend.color}
              />
              <Span>{styleLegend.label}</Span>
            </AlignedFlexRow>
          })
      }
    </Wrapper>
  </FlexColumn>
}

const mapDispatchToProps = {
  removeLegend
}

export default connect(null, mapDispatchToProps)(TilesetContent)
