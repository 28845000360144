import React from 'react'

import { generateGroupEntitiesForNumericalCustom } from '../../../../../helpers/groupEntities'

import LayerbarIcon from '../../../../../shares/LayerbarIcon'

import {
  ReactComponent as AddIcon
} from '../../../../../../../../images/Icons/add-text.svg'

/**
 * This button to add number on numerical field mode custom
 * @param {Object} map is the selected map
 * @param {String} name is the name of label
 * @param {Object} groupEntities is current group entities to create this input
 * @param {Function} setGroupEntities is the method to change group entities
 * @param {String} groupBy is current groupby selected
 * @param {Object} fieldMode is the current fieldmode selected, need to get precision
 * @param {Function} setEditOption is method to change onEdit
 * @param {Function} setTemplate is method to change active template
 */
function AddButton({
  map,
  name,
  groupEntities,
  setGroupEntities,
  groupBy,
  fieldMode,
  setTemplate
}) {
  function addHandler() {
    const keys = groupEntities.keys

    for (let i = 0; i < keys.length; i++) {
      if (keys[i].label === name) {
        const [ bottom, top ] = keys[i].value.split(' - ')

        const newTop = Number(top) - 1 / Math.pow(10, fieldMode.precision)

        keys[i].value = `${bottom} - ${newTop}`

        keys.splice(i + 1, 0, {
          label: `${newTop} - ${top}`,
          value: `${newTop} - ${top}`
        })
        break
      }
    }

    const prevGroupEntities = {
      ...groupEntities,
      keys
    }

    setTemplate(null)

    setGroupEntities(
      generateGroupEntitiesForNumericalCustom({
        map,
        groupBy,
        groupEntities: {
          group: {},
          keys: [],
          style: {}
        },
        prevGroupEntities,
        precision: fieldMode.precision
      })
    )
  }

  return <LayerbarIcon
    title="add"
    isActive={false}
  >
    <AddIcon onClick={addHandler} />
  </LayerbarIcon>
}

export default AddButton
