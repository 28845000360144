import React from 'react'

import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #b1b1b1;
  padding: 0.2em 0.5em;
`

const Span = styled.div`
  font-family: Abel;
  font-size: 0.8em;
  color: black;
`

const WrapperButton = styled.div`
  display: flex;
  justify-content: center;
`

const Button = styled.div`
  text-align: center;
  border: 1px solid goldenrod;
  background: white;
  border-radius: 10px;
  font-family: Abel;
  font-size: 0.8em;
  padding: 0.2em 0.5em;
  width: 2em;
  transition: all 330ms;
  cursor: pointer;
  margin: 0.3em 1em;

  &:hover {
    background: #ffd15e;
  }
`

/**
 * This component will create confirmation form
 * @param {Object} confirmation - state confirmation
 * @param {Function} setConfirmation - method to change state confirmation
 */
function ConfirmationForm({
  confirmation,
  setConfirmation,
  yesHandler,
  noHandler
}) {
  function showMessage() {
    const process = confirmation.process
    const action = process[0].toUpperCase()
    + process.slice(1)

    return `Are you sure want to ${action} this data?`
  }

  async function acceptEventHandler() {
    await yesHandler()

    setConfirmation({
      show: false,
      process: '',
      event: null
    })
  }

  async function declineEventHandler() {
    noHandler()

    setConfirmation({
      show: false,
      process: '',
      event: null
    })
  }

  return <Wrapper>
    <Span>{showMessage()}</Span>
    <WrapperButton>
      <Button
        onClick={declineEventHandler}
      >No</Button>
      <Button
        onClick={acceptEventHandler}
      >Yes</Button>
    </WrapperButton>
  </Wrapper>
}

export default ConfirmationForm
