import Icon from '../../../shares/Icon'
import * as ColorTemplate from '../../../shares/ColorTemplate'

import styled from 'styled-components'

export const LayerbarIcon = styled(Icon)`
  display: flex;
  align-items: center;

  margin-left: ${
    props => {
      if (props['margin-left']) {
        return props['margin-left']
      } else if (props['no-margin']) {
        return '0'
      } 
      
      return '0.2em'
  }};
  margin-right: ${
    props => {
      if (props['margin-right']) {
        return props['margin-right']
      } else if (props['no-margin']) {
        return '0'
      }
      return '0.2em'
  }};
  
  height: 1em;

  opacity: ${props => props.show === false && 0};

  svg {
    width: ${props => {
      if (props.width) {
        return props.width
      } else if (props.size === 'smaller') {
        return '0.6em'
      }

      return '1em'
    }};

    height: ${props => {
      if (props.size === 'smaller') {
        return '0.7em'
      }

      return '1em'
    }};

    fill: ${
      props => {
        if (props.danger) {
          return ColorTemplate.DANGER_RED.default
        } else if (props.success) {
          return ColorTemplate.SUCCESS_GREEN.default
        } else if (
          props.isActive !== undefined
          && props.toggleAble !== undefined) {
          if (props.isActive) {
            return ColorTemplate.GOLDEN_3D.default
          } else if (props.toggleAble) {
            return ColorTemplate.LIGHT_GREY.default
          }
        } else if (props.isActive) {
          return ColorTemplate.GOLDEN_3D.default
        } else if (props.toggleAble) {
          return ColorTemplate.GOLDEN_3D.default
        } 

        return ColorTemplate.DARK_GREY.default
      }
    };
    
    cursor: ${
      props => {
        if (props.isActive) {
          return 'pointer'
        } else if (props.toggleAble === false) {
          return 'default'
        } else if (props.isActive === false) {
          return 'pointer'
        } else if (props.toggleAble) {
          return 'pointer'
        }

        return 'default'
      }
    };
    
    &:hover {
      fill: ${
        props => {
          if (props.danger) {
            return ColorTemplate.DANGER_RED.hover
          } else if (props.success) {
            return ColorTemplate.SUCCESS_GREEN.hover
          } else if (
            props.isActive !== undefined
            && props.toggleAble !== undefined
          ) {
            if (props.toggleAble) {
              if (props.isActive === false) {
                return ColorTemplate.GOLDEN_3D.default
              }
            }
          } else if (props.isActive) {
            return ColorTemplate.LIGHT_GREY.default
          } else if (props.isActive === false) {
            return ColorTemplate.GOLDEN_3D.default
          } else if (props.toggleAble) {
            return ColorTemplate.LIGHT_GREY.default
          }

          return ColorTemplate.DARK_GREY.default
        }
      };
    }
  }
`

export default LayerbarIcon
