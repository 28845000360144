import * as actionTypes from '../../constants/store/actionTypes/cesium'

const defaultCesiumState = {
  areaEntityHover: null,
  mapEntityHover: null,
  cartesian2: null
}

function cesiumReducer(state = defaultCesiumState, { type, payload }) {
  switch (type) {
    case actionTypes.SET_HOVER_AREA_ENTITY:
      return {
        ...state,
        areaEntityHover: payload.entity,
        cartesian2: payload.cartesian2
      }
    case actionTypes.REMOVE_HOVER_AREA_ENTITY:
      return {
        ...state,
        areaEntityHover: null,
        cartesian2: null
      }
    case actionTypes.SET_HOVER_MAP_ENTITY:
      return {
        ...state,
        mapEntityHover: payload.entity,
        cartesian2: payload.cartesian2
      }
    case actionTypes.REMOVE_HOVER_MAP_ENTITY:      
      return {
        ...state,
        mapEntityHover: null,
        cartesian2: null
      }
    // edit it later
    // case actionTypes.FETCH_DRAW_DATA_SUCCESS:
    //   return {
    //     ...state,
    //     layer: {
    //       ...layer,
    //       draw: {
    //         ...draw,
    //         dataSource: payload.dataSource,
    //         status: true
    //       }
    //     }
    //   }
    // case actionTypes.UPDATE_DRAW_DATA_SUCCESS:
    //   return {
    //     ...state,
    //     layer: {
    //       ...layer,
    //       draw: {
    //         ...draw,
    //         dataSource: payload.dataSource,
    //         status: true
    //       }
    //     }
    //   }
    // case actionTypes.CHECK_DRAW_DATA_SUCCESS:
    //   return {
    //     ...state
    //   }
    // case actionTypes.UNCHECK_DRAW_DATA_SUCCESS:
    //   return {
    //     ...state
    //   }
    // case actionTypes.REMOVE_ALL_DRAW_DATA_SUCCESS:
    //   return {
    //     ...state,
    //     layer: {
    //       ...layer,
    //       draw: {
    //         ...draw,
    //         dataSource: null
    //       }
    //     }
    //   }
    /////////////////////////////////////////////
    //                                         //
    //            ERROR HANDLER                //
    //                                         //
    /////////////////////////////////////////////
    case actionTypes.FETCH_DRAW_DATA_ERROR:
    case actionTypes.UPDATE_DRAW_DATA_ERROR:
    case actionTypes.CHECK_DRAW_DATA_ERROR:
    case actionTypes.UNCHECK_DRAW_DATA_ERROR:
    case actionTypes.REMOVE_ALL_DRAW_DATA_ERROR:
      return {
        ...state,
        error: payload
      }
    case actionTypes.RESET_CESIUM:
      return {
        ...defaultCesiumState
      }
    default:
      return state
  }
}

export default cesiumReducer
