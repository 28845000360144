import Cookies from 'universal-cookie'

const cookieOpt = {
  path: '/',
}

const cookies = new Cookies(cookieOpt)

export function handleLogin({ accToken, rfsToken, username, tierDesc }) {
  cookies.set('accessToken', accToken, {...cookieOpt})
  cookies.set('refreshToken', rfsToken, {...cookieOpt})
  cookies.set('username', username, {...cookieOpt})
  cookies.set('tierDesc', tierDesc, {...cookieOpt})
}

export function handleLogout () {
  cookies.remove('accessToken')
  cookies.remove('refreshToken')
  cookies.remove('username')
  cookies.remove('tierDesc')
}

export default cookies
