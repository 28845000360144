import * as actionTypes from '../../constants/store/actionTypes/database'

export function changeType(payload) {
  return {
    type: actionTypes.CHANGE_TYPE,
    payload
  }
}

export function toggleAnalysis(payload) {
  return {
    type: actionTypes.TOGGLE_ANALYSIS,
    payload
  }
}

export function togglePreviewTable(payload) {
  return {
    type: actionTypes.TOGGLE_PREVIEW_TABLE,
    payload
  }
}

//data
export function changeData(payload) {
  return {
    type: actionTypes.CHANGE_DATA_IMPORT,
    payload
  }
}


//header
export function changeValuesHeader(payload) {
  return {
    type: actionTypes.CHANGE_VALUES_HEADER,
    payload
  }
}

//STRING
export function changeImportType(payload) {
  return {
    type: actionTypes.IMPORT_TYPE,
    payload
  }
}
export function changeProjectName(payload) {
  return {
    type: actionTypes.PROJECT_NAME,
    payload
  }
}
export function changeNameTable(payload) {
  return {
    type: actionTypes.NAME_TABLE,
    payload
  }
}
export function setUTMZone(payload){
  return {
    type: actionTypes.UTM_ZONE,
    payload
  }
}
export function setConfigChart(payload){
  return {
    type: actionTypes.CONFIG_CHART,
    payload
  }
}
export function setFilterValue(payload){
  return {
    type: actionTypes.FILTER_VALUE,
    payload
  }
}
export function setAutoCheckSurface(payload){
  return {
    type: actionTypes.AUTOCHECK_SURFACE,
    payload
  }
}

