import styled from 'styled-components';

export const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 4,
    borderColor: '#bdbdbd',
    borderStyle: 'dashed',
    backgroundColor: 'rgb(40,40,40)',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    width:'500px',
    height:'200px'
};

export const baseStyleMulti = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 4,
    borderColor: '#bdbdbd',
    borderStyle: 'dashed',
    backgroundColor: 'rgb(40,40,40)',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    width:'250px',
    height:'200px'
};

export const activeStyle = {
    borderColor: '#2196f3',
    backgroundColor: 'rgb(70,70,70)'
};

export const acceptStyle = {
    borderColor: '#00e676'
};

export const rejectStyle = {
    borderColor: '#ff1744'
};

export const WrapperMulti=styled.div`
    display: flex;
    flex-direction: row;
`;

export const WrapperOthersDrill=styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2;
    border-radius: 4;
    border-color: #bdbdbd;
    border-style: dashed;
    background-color: rgb(40,40,40);
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    width:250px;
    height:200px;
`;