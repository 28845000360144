import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'

import { minimizeRankbar } from '../../../store/actions/rankbar'

import MenubarToggleDownButton from '../../Buttons/MenubarToggleDownButton'
import ScrollBar from './Scrollbar'

import {
  AbsoluteWrapper
} from '../../BasicStyle/PositionWrapper'

function Rankbar({
  rankbar,
  sitebar,
  chat,
  sites,
  sidebarState,
  minimizeRankbar
}) {
  const [ mapBoundingRect, setMapBoundingRect ] = useState({
    width: null,
    left: 0,
    prevWidthStatus: null,
    prevLeftStatus: null
  })

  const styles = {
    top: !rankbar.minimize ? '44px' : 0,
    width: mapBoundingRect.width,
    left: mapBoundingRect.left,
    transition: 'all 510ms',
    zIndex: 3000
  }

  function checkLeft() {
    for (let key in sidebarState) {
      if (key === 'globe') {
        continue
      }
      if (key === 'menu') {
        continue
      }

      if (sidebarState[key].status) {
        return '20em'
      }
    }

    return 0
  }

  function checkWidth(rightbar) {
    if (rightbar.toggle && !rightbar.minimize && checkLeft()) {
      return `calc(100% - ${rightbar.width} - 20em)`
    } else if (rightbar.toggle && !rightbar.minimize) {
      return `calc(100% - ${rightbar.width})`
    } else if (checkLeft()) {
      return 'calc(100% - 20em)'
    }

    return '100%'
  }

  useEffect(() => {
    setMapBoundingRect({
      ...mapBoundingRect,
      width: '100%',
      left: 0
    })

    setTimeout(() => {
      let rightbar = sitebar

      if (chat.toggle) {
        rightbar = chat
      } 

      setMapBoundingRect({
        ...mapBoundingRect,
        width: checkWidth(rightbar),
        left: checkLeft()
      })
    }, 550)
  }, [sidebarState])

  useEffect(() => {
    setMapBoundingRect({
      ...mapBoundingRect,
      width: checkWidth(sitebar)
    })
  }, [sitebar])

  useEffect(() => {
    setMapBoundingRect({
      ...mapBoundingRect,
      width: checkWidth(chat)
    })
  }, [chat])

  useEffect(() => {
    const cesiumToolbars = document.getElementsByClassName('cesium-viewer-toolbar')
    const compass = document.getElementsByClassName('compass')
    
    for (let i = 0; i < cesiumToolbars.length; i++) {
      if (rankbar.toggle && !rankbar.minimize) {
        cesiumToolbars[i].style.top = 'calc(55px)'
      } else {
        cesiumToolbars[i].style.top = '0.3em'
      }
    }
    
    for (let i = 0; i < compass.length; i++) {
      if (rankbar.toggle && !rankbar.minimize) {
        compass[i].style.top = 'calc(55px + 0.5em)'
      } else {
        compass[i].style.top = '1em'
      }
    }
    
    const performances = document.getElementsByClassName('cesium-performanceDisplay-defaultContainer')
    for (let i = 0; i < performances.length; i++) {
      if (rankbar.toggle && !rankbar.minimize) {
        performances[i].style.top = 'calc(55px + 0.2em)'
      } else {
        performances[i].style.top = '0.2em'
      }
    }
  }, [rankbar])

  function toggleHandler() {
    minimizeRankbar({
      minimize: !rankbar.minimize
    })
  }

  return <>
    {
      rankbar.toggle
        &&
          <>
            <AbsoluteWrapper
              width={mapBoundingRect.width}
              style={styles}
            >
              <ScrollBar
                prospectArea={sites.prospectArea}
                rankbar={rankbar}
                sidebarState={sidebarState}
                sitebar={sitebar}
              />
            </AbsoluteWrapper>
            <MenubarToggleDownButton
              bar={rankbar}
              topMinimize="48px"
              topUnminimize="92px"
              toggleHandler={toggleHandler}
            />
          </>
    }
  </>
}

function mapStateToProps ({ sidebarState, rankbar, sitebar, chat, sites }) {
  return {
    sidebarState,
    rankbar,
    sitebar,
    chat,
    sites
  }
}

const mapDispatchToProps = {
  minimizeRankbar
}

export default connect(mapStateToProps, mapDispatchToProps)(Rankbar)