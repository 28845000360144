import React from 'react'

import FlexWrapper from '../Menubars/Layerbar/shares/FlexWrapper'

import styled from 'styled-components'

const SidebarWrapper = styled(FlexWrapper)`
  position: absolute;
  flex-direction: column;
  z-index: 3001;
  width: ${props => props.width};
  overflow-y: hidden;
  height: 100%;
  animation-duration: 1000ms;
  background-color: rgb(17, 17, 17);
  justify-content: center;
  transition: all 1000ms;
  left: ${props => {
    if (props.status) {
      return 0
    }
    return `calc(-${props.width} * 2)`
  }};
`

function SidebarContainer({
  status,
  width='20em',
  children
}) {
  return (
    <SidebarWrapper
      width={width}
      status={status}
    >
      {children}
    </SidebarWrapper>
  )
  
}

export default SidebarContainer