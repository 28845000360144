import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'

import UseSimpleAndDoubleClick from '../../helpers/UseSimpleAndDoubleClick'
import getPixel from '../../helpers/getPixel'

import AssistCheck from './AssistCheck'
import AssistButton from './AssistButton'

import goldRingPNG from '../../../images/Others/gold-ring.png'

import styled from 'styled-components'

const ContainerWrapper = styled.div`
  border-radius: 17px;
  background-color: rgba(0, 0, 0, 0.4);
  width: 2.5em;
  height: 2.5em;
  position: fixed;
  transition: all 500ms;
  user-select: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  &:hover {
    background-color: rgba(0, 0, 0, 0.6);
  }

  z-index: 2000;
`

const CircleButton = styled.div`
  position: absolute;
  border-radius: 50%;
  width: 1.3em;
  height: 1.3em;
  background-color: rgba(255, 255, 255, 0.5);
  left: 0.4em;
  top: 0.4em;
  transition: all 1000ms;
  cursor: pointer;

  ${ContainerWrapper}:hover & {
    background-color: rgba(255, 255, 255, 1);
  }
`

const ContentContainer = styled.div`
  content: '';
  position: absolute;
  border-radius: 50%;
  z-index: -1;
  /* overflow: hidden; */
`

const Content = styled.div`
  position: relative;
  width: 15em;
  height: 15em;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  transition: all 330ms;
`

let timeout

/**
 * This function will generate assistive touch like iphone
 * @param {Object} rankbar is the state of rankbar
 * @param {Object} sidebarState is the state of sidebar
 * @param {Object} sitebarState is the state of sitebar
 * @param {Boolean} selection is the current cesium can be select default
 * @param {Function} setSelection to change cesium cursor
 * @param {Object} section is the state of cross section
 * @param {Function} setSection function to change cross section
 * @param {Object} sliceEvent - slice state
 * @param {Function} setSliceEvent - method to change slice event
 * @param {Object} crossEvent - cross state
 * @param {Function} setCrossEvent - method to change cross event
 * @param {Object} blockEvent - block state
 * @param {Function} setBlockEvent - method to change block event
 * @param {Array} listPointerEvent - list of available pointer event
 */
function AssistTouch({
  rankbar,
  sidebarState,
  sitebar,
  selection,
  setSelection,
  section,
  setSection,
  sliceEvent,
  setSliceEvent,
  crossEvent,
  setCrossEvent,
  blockEvent,
  setBlockEvent,
  listPointerEvent
}) {
  // radius = 5 em => 80px + margin 60px
  const buffer = 160

  const [ position, setPosition ] = useState({
    pageX: '60px',
    pageY: 'calc(100vh - 100px)'
  })
  
  const [ toggleMove, setToggleMove ] = useState(false)
  const [ toggleDisplay, setToggleDisplay ] = useState(false)
  const [ allContent, setAllContent ] = useState([
    {
      id: 1,
      title: 'Home',
      icon: 'home'
    },
    {
      id: 2,
      title: 'Cross Section',
      icon: 'crossSection'
    },
    {
      id: 3,
      title: 'Block Section',
      icon: 'blockSection'
    },
    {
      id: 8,
      title: 'Cursor',
      icon: 'cursor' 
    },
    {
      id: 4,
      title: 'Slice Section',
      icon: 'sliceSection' 
    },
    // {
    //   id: 10,
    //   title: 'Grid Options',
    //   icon: 'gridOption'
    // },
    // {
    //   id: 11,
    //   title: 'Mineral Occurrences Editor',
    //   icon: 'editor'
    // }
  ])
  const [ checkPosition, setCheckPosition ] = useState('right')
  const [ checkToggle, setCheckToggle ] = useState(false)

  const doubleClickHandler = UseSimpleAndDoubleClick(
    () => {
      if (toggleDisplay === false) {
        triggerMove(() => {
          setToggleDisplay(!toggleDisplay)
        })
      } else {
        setCheckToggle(false)
        setTimeout(() => {
          setToggleDisplay(!toggleDisplay)
        }, 330)
      }
    },
    () => {
      if (toggleDisplay) {
        triggerMove(() => {
          setCheckToggle(!checkToggle)
        })
      } else {
        triggerMove(() => {
          setToggleDisplay(!toggleDisplay)
          setTimeout(() => {
            setCheckToggle(true)
          }, 330)
        })
      }
    }
  )

  function triggerMove(cb) {
    const waitTime = 1000

    const width = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth

    const height = window.innerHeight
      || document.documentElement.clientHeight
      || document.body.clientHeight

    const left = 0
    const top = 0

    const currentWidth = Number(position.pageX.slice(0, -2))
    const currentHeight = Number(position.pageY.slice(0, -2))

    let change = false
    const newPosition = {
      ...position
    }

    if (currentWidth + buffer > width) {
      change = true
      newPosition.pageX = width - buffer + 'px'
    }

    if (currentHeight + buffer > height) {
      change = true
      newPosition.pageY = height - buffer + 'px'
    }

    if (currentWidth - buffer < left) {
      change = true
      newPosition.pageX = left + buffer + 'px'
    }

    if (currentHeight - buffer < top) {
      change = true
      newPosition.pageY = top + buffer + 'px'
    }

    if (change) {
      setPosition(newPosition)

      setTimeout(() => {
        cb()
      }, waitTime)
    } else {
      cb()
    }
  }

  function generateIconContent() {
    return allContent.map((content, i) => {
      const widthSVG = 1

      const cy = 7.3 - widthSVG / 2
      const cx = 7.3 - widthSVG / 2
      const r = 5
      const degree = 360 * i / allContent.length - 90
      const radians = degree * Math.PI / 180
      const translate = 7.3

      const left = cx + r * Math.cos(radians)
      const top = cy + r * Math.sin(radians)

      return <div
        key={i}
        onClick={() => {
          setToggleDisplay(false)
        }}
      >
        <AssistButton
          toggleDisplay={toggleDisplay}
          content={content}
          translate={translate}
          left={left}
          top={top}
          r={r}
          radians={radians}
          widthSVG={widthSVG}
          key={i}
          selection={selection}
          setSelection={setSelection}
          section={section}
          setSection={setSection}
          sliceEvent={sliceEvent}
          setSliceEvent={setSliceEvent}
          crossEvent={crossEvent}
          setCrossEvent={setCrossEvent}
          blockEvent={blockEvent}
          setBlockEvent={setBlockEvent}
          listPointerEvent={listPointerEvent}
        />
      </div>
    })
  }

  useEffect(() => {
    if (toggleDisplay === false) {
      setCheckToggle(false)

      setPosition((position) => {
        const movement = 49
        
        const currentHeight = Number(position.pageY.slice(0, -2))
        
        let newPosition = {
          ...position
        }
  
        if (rankbar.toggle && !rankbar.minimize) {
          if (currentHeight - movement - 100 <= 15) {
            newPosition = {
              ...newPosition,
              pageY: 50 + movement + 'px'
            }
          }
        } else {
          if (currentHeight - movement - 100 <= 50) {
            newPosition = {
              ...newPosition,
              pageY: 50 + 'px'
            }
          }
        }
  
        let open = false
  
        for (let key in sidebarState) {
          if (sidebarState[key].status) {
            open = true
            break
          }
        }
  
        const currentWidth = Number(position.pageX.slice(0, -2))
        const movementFirst = 380
        
        if (open) {
          if (currentWidth < movementFirst) {
            newPosition = {
              ...newPosition,
              pageX: movementFirst + 'px'
            }
          }
        } else {
          if (currentWidth < movementFirst) {
            newPosition = {
              ...newPosition,
              pageX: 60 + 'px'
            }
          } else if (currentWidth - movementFirst < 100) {
            newPosition = {
              ...newPosition,
              pageX: 60 + 'px'
            }
          }
        }
  
        // modify it later
        const movementSecond = 560
        const width = window.innerWidth
          || document.documentElement.clientWidth
          || document.body.clientWidth
  
        if (sitebar.toggle && !sitebar.minimize) {
          if (currentWidth >= width - movementSecond) {
            newPosition = {
              ...newPosition,
              pageX: width - movementSecond + 'px'
            }
          }
        } else {
          if (currentWidth + 100 >= width - movementSecond) {
            newPosition = {
              ...newPosition,
              pageX: width - 50 + 'px'
            }
          }
        }
  
        return newPosition
      })
    }
  }, [toggleDisplay])

  useEffect(() => {
    if (!toggleMove) {
      const movement = 49
      const currentHeight = Number(position.pageY.slice(0, -2))

      if (rankbar.toggle && !rankbar.minimize) {
        if (currentHeight < 50 + movement) {
          setPosition((position) => ({
            ...position,
            pageY: 50 + movement + 'px'
          }))
        }
      } else {
        if (currentHeight - movement - 100 <= 50) {
          setPosition((position) => ({
            ...position,
            pageY: 50 + 'px'
          }))
        }
      }
    }
  }, [rankbar])

  useEffect(() => {
    if (!toggleMove) {
      let open = false

      for (let key in sidebarState) {
        if (sidebarState[key].status) {
          open = true
          break
        }
      }

      const currentWidth = Number(position.pageX.slice(0, -2))
      const movement = 380
      
      if (open) {
        if (currentWidth < movement) {
          setPosition((position) => ({
            ...position,
            pageX: movement + 'px'
          }))
        }
      } else {
        if (currentWidth < movement) {
          setPosition((position) => ({
            ...position,
            pageX: 60 + 'px'
          }))
        } else if (currentWidth - movement < 100) {
          setPosition((position) => ({
            ...position,
            pageX: 60 + 'px'
          }))
        }
      }
    }
  }, [sidebarState])

  useEffect(() => {
    if (!toggleMove) {
      const movement = getPixel(sitebar.width) + 50

      const width = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth

      const currentWidth = Number(position.pageX.slice(0, -2))

      if (!sitebar.toggle || sitebar.minimize) {
        if (currentWidth + 100 >= width - movement) {
          setPosition((position) => ({
            ...position,
            pageX: width - 50 + 'px'
          }))
        }
      } else {
        if (currentWidth >= width - movement) {
          setPosition((position) => ({
            ...position,
            pageX: width - movement + 'px'
          }))
        }
      }
    }
  }, [sitebar])

  // this use effect will handle mouse move event
  useEffect(() => {
    /**
     * This function will wait for spesific duration before trigger action
     * @param {Object} event event from mouse move
     */
    function mouseMoveHandler(event) {
      const waitTime = 50 // wait 50 ms before move

      if (toggleMove && event.pageX && event.pageY) {
        if (timeout) clearTimeout(timeout)
        timeout = setTimeout(() => {
          setPosition((position) => ({
            ...position,
            pageX: event.pageX - 20 + 'px',
            pageY: event.pageY - 20 + 'px'
          }))
        }, waitTime)
      }
    }

    window.addEventListener('mousemove', mouseMoveHandler)

    return () => (
      window.removeEventListener('mousemove', mouseMoveHandler)
    )
  }, [toggleMove])

  // to make open from the left or right
  useEffect(() => {
    if (checkPosition) {
      const width = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth

      const currentWidth = Number(position.pageX.slice(0, -2))
      let left = 0

      // width check content 344px
      let checkContentWidth = 344

      if (currentWidth + checkContentWidth > width) {
        setCheckPosition('left')
      } else if (currentWidth - checkContentWidth < left) {
        setCheckPosition('right')
      }
    }
  }, [checkToggle])

  return (
    <ContainerWrapper
      style={{
        top: position.pageY,
        left: position.pageX
      }}
      draggable={false}
    >
      <CircleButton
        style={{
          border: toggleMove
            ? '0.2em solid red'
            : '0.2em solid grey',
          zIndex: 1000
        }}
        onMouseDown={(event) => {
          if (event.button === 1) {
            setToggleMove(!toggleMove)
          } else if (event.button === 0) {
            doubleClickHandler()
          }
        }}
      />
      {
        checkToggle
        &&
          <img src={goldRingPNG} alt="gold-ring"
            style={{
              animation: 'spin 6s infinite',
              width: '16.2em',
              left: '-6.85em',
              position: 'absolute',
              top: '-6.96em',
              translate: 'translate(-50%, -50%)',
              zIndex: 1,
              pointerEvents: 'none'
            }}
          />
      }
      <ContentContainer
        style={{
          top: toggleDisplay
            ? '1.25em'
            : '1.25em',
          left: toggleDisplay
            ? '1.25em'
            : '1.25em',
          transform: 'translate(-50%, -50%)',
          zIndex: 100
        }}
      >
        <Content
          style={{
            height: toggleDisplay
              ? '15em'
              : '0em',
            width: toggleDisplay
              ? '15em'
              : '0em',
            backgroundColor: checkToggle
            ? 'rgba(0, 0, 0, 1)'
            : 'rgba(0, 0, 0, 0.8)'
          }}
        >
          {
            generateIconContent({})
          }
        </Content>
      </ContentContainer>
      <AssistCheck
        isActive={checkToggle}
        position={checkPosition}
        currentActives={allContent}
        setActives={setAllContent}
      />
    </ContainerWrapper>
  )
}

function mapStateToProps(state) {
  return {
    rankbar: state.rankbar,
    sidebarState: state.sidebarState,
    sitebar: state.sitebar
  }
}

export default connect(mapStateToProps, null)(AssistTouch)
