import React, { useEffect } from 'react'

import { connect } from 'react-redux'

import { connectChatSocket, disconnectChatSocket } from '../../store/actions/socket'
// import { fetchDrawData } from '../store/actions/cesium'
import { toggleSitebar } from '../../store/actions/sitebar'
import { toggleSidebarButton } from '../../store/actions/sidebarToggle'

import { flyTo } from '../../apis/cesium'

import Navbar from '../../components/Menubars/Navbar'
import Sidebar from '../../components/Menubars/Sidebar'
import Rankbar from '../../components/Menubars/Rankbar'
import Searchbar from '../../components/Menubars/Searchbar'
import Newsbar from '../../components/Menubars/Newsbar'
import DetailSitebar from '../../components/Menubars/DetailSitebar'
import Chatbar from '../../components/Menubars/Chatbar'
import Layerbar from '../../components/Menubars/Layerbar'
import Tablebar from '../../components/Menubars/Tablebar'
import Cesium from '../../components/Cesium'
import GreyScreen from '../../components/Layers/GreyScreen'
import GlobeMenu from '../../components/Menubars/GlobeMenubar'
import Loading from '../../components/Loading'
import Gradebar from '../../components/Menubars/Gradebar'
import Legendbar from '../../components/Menubars/Legendbar'
import Analysisbar from '../../components/Menubars/Analysisbar'
import Analytic from '../../components/Analytic'
import RightClickbar from '../../components/Menubars/RightClickbar'

function Dashboard({
  sites,
  sidebarState,
  fetchDrawData,
  connectChatSocket,
  disconnectChatSocket,
  toggleSidebarButton
}) {
  useEffect(() => {
    // fetchDrawData()
    if (sites.selected.site) {
      flyTo({
        area: sites.selected.site,
        cb: function () {
          toggleSitebar({
            toggle: true,
            minimize: false,
            animate: true
          })
        }
      })
    }

    connectChatSocket()
    toggleSidebarButton({
      buttonName: 'menu',
      status: true
    })

    return () => {
      disconnectChatSocket()
    }
  }, [])


  return <>
    <Sidebar className="sidebar-block" />
    <div className="content-block">
      <Navbar className="row" />
      <div className="row max-height">
        {
          sidebarState.analytics.status
          ? <></>
          :
            sites.commodity
              ? <Rankbar />
              : <></>
        }
        <div className="row max-height max-width">
          <GlobeMenu />
          <Searchbar />
          <Layerbar />
          <Analysisbar />
          <Newsbar />
          {
            sidebarState.analytics.status
              ? <Analytic/>
              : <>
                  <Cesium />
                  <Gradebar />
                  <Tablebar />
                  <Legendbar />
                  <RightClickbar />
                </>
          }
        </div>
        <DetailSitebar />
        <Chatbar />
        <GreyScreen />
      </div>
    </div>
    <Loading />
  </>
}

function mapStateToProps({
  sites,
  sidebarState
}) {
  return {
    sites,
    sidebarState
  }
}

const mapDispatchToProps = {
  toggleSitebar,
  // fetchDrawData,
  connectChatSocket,
  disconnectChatSocket,
  toggleSidebarButton
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
