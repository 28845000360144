import styled from 'styled-components'

import { GOLD_FONT } from '../../../shares/ColorTemplate'

export const TextContainer = styled.div`
  display: flex;
  margin-left: 0.5em;
  margin-right: 0.5em;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const OptionbarContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: rgb(24, 24, 24);
  width: 10em;
  position: fixed;
  top: 88px;
  width: 10em;
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
`

export const TextOption = styled.div`
  font-family: Abel;
  color: white;
  font-weight: bold;
  margin: 0.5em;
  margin-left: 1.3em;
  cursor: pointer;

  &:hover {
    background: ${GOLD_FONT};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

export const TitleTextParagraph = styled.span`
  color: white;
  font-size: 0.6em;
  font-family: Abel;
  position: absolute;
  top: -1em;
  left: 0;
`

export const TextParagraph = styled.span`
  color: white;
  font-weight: bold;
  font-family: Abel;
  cursor: pointer;

  &:hover {
    background: ${GOLD_FONT};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`
