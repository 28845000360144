import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'

import {
  removeLegend
} from '../../../../store/actions/legendbar'

import {
  FlexColumn,
  AlignedFlexRow
} from '../../../BasicStyle/FlexWrapper'

import LegendScale from './LegendScale'
import LegendSymbol from '../LegendSymbol'
import CloseButton from '../CloseButton'

import getRGBA from '../helpers/getRGBA'

import styled from 'styled-components'

const Header = styled.div`
  font-family: Abel;
  font-size: 0.8em;
  font-weight: 600;
  margin: 0 0.5em;
  display: flex;
  align-items: center;
`

const Wrapper = styled(FlexColumn)`
  flex-wrap: wrap;
  font-size: 0.7em;
  margin: 0.5em;
`

const Span = styled.span`
  margin: 0.1em 0 0.1em 0.3em;
`

const EllipsisText = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

/**
 * This component will create content with header and content by given params
 * @param {Object} map is the selected map to render
 * @param {String} header is header to show
 * @param {Function} removeLegend is the method to remove this legend
 * @param {Boolean} isHover is the state that component are being hover or not
 * @param {Array} keys is list key that need to be show on the map
 */
function Content({
  map,
  header,
  removeLegend,
  isHover,
  keys
}) {
  const [ attribute, setAttribute ] = useState(map.cesiumLayer.fieldAttribute.selected)
  const [ groupEntities, setGroupEntities ] = useState(map.cesiumLayer.groupEntities)

  useEffect(() => {
    function eventAttributeHandler() {
      setAttribute(map.cesiumLayer.fieldAttribute.selected)
    }

    function eventGroupEntitiesHandler() {
      setGroupEntities(map.cesiumLayer.groupEntities)
    }

    map.cesiumLayer.subscribeEvent('fieldAttribute', eventAttributeHandler)
    map.cesiumLayer.subscribeEvent('groupEntities', eventGroupEntitiesHandler)

    return () => {
      map.cesiumLayer.unsubscribeEvent('fieldAttribute', eventAttributeHandler)
      map.cesiumLayer.unsubscribeEvent('groupEntities', eventGroupEntitiesHandler)
    }
  }, [])

  function closeHandler() {
    removeLegend({
      name: map.name
    })
  }

  if (!Array.isArray(keys)) {
    return null
  }

  return <FlexColumn>
    <Header width="auto">
      <EllipsisText>{header}</EllipsisText>
      <CloseButton closeHandler={closeHandler} isHover={isHover} />
    </Header>
    <Wrapper>
      {
        attribute === 'numerical'
          ? <LegendScale
            map={map}
            groupEntities={groupEntities}
            keys={keys}
          />
          : keys.map((key, i) => {
            if (!groupEntities.group[key.label] || !groupEntities.group[key.label].length) {
              return null
            }

            return <AlignedFlexRow key={key.value + String(i)} height="auto">
              <LegendSymbol
                geom={map.geom}
                color={getRGBA(groupEntities.style[key.label].color)}
              />
              <Span>{key.label}</Span>
            </AlignedFlexRow>
          })
      }
    </Wrapper>
  </FlexColumn>
}

const mapDispatchToProps = {
  removeLegend
}

export default connect(null, mapDispatchToProps)(Content)
