import * as actionTypes from '../../constants/store/actionTypes/display'

export function setDisplay(payload) {
  return {
    type: actionTypes.SET_DISPLAY,
    payload
  }
}

export function addToDisplay(payload) {
  return {
    type: actionTypes.ADD_TO_DISPLAY,
    payload
  }
}

export function setFocusMap(payload) {
  return {
    type: actionTypes.SET_FOCUS_MAP,
    payload
  }
}

export function removeFromDisplay(payload) {
  return {
    type: actionTypes.REMOVE_FROM_DISPLAY,
    payload
  }
}

export function resetDisplay(payload) {
  return {
    type: actionTypes.RESET_DISPLAY,
    payload
  }
}
