import React from 'react'

import { ReactComponent as UpIcon } from '../../../images/Icons/up.svg'
import { ReactComponent as DownIcon } from '../../../images/Icons/down.svg'

import {
  FlexRow
} from '../../BasicStyle/FlexWrapper'

import styled from 'styled-components'

const CustomFlexRow = styled(FlexRow)`
  position: absolute;
  transition: all 510ms;
  z-index: 3;
  justify-content: center;
`

const ToggleButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.247);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: -1px 1px 9px 1px #3f3f3f;
  cursor: pointer;
  background-color: white;
  width: 4.5rem;
  height: 0.7rem;
  transition: background-color 330ms;

  &:hover {
    background-color: black;
  }
`

const CustomIcon = styled.div`
  svg {
    fill: black;
    margin-top: 0.28em;
    width: 0.7em;
    transition: all 330ms;
  }

  ${ToggleButton}:hover & {
    svg {
      fill: white;
    }
  }
`

function MenubarToggleDownButton({
  bar,
  topMinimize,
  topUnminimize,
  toggleHandler
}) {
  const outlineStyles = {
    top: !bar.minimize ? topUnminimize : topMinimize,
    left: 'calc(50% - 4.5rem / 2)'
  }

  return <>
    {
      bar.toggle
        &&
          <CustomFlexRow
            background="transparent"
            style={outlineStyles}
          >
            <ToggleButton
              onClick={toggleHandler}
            >
              {
                !bar.minimize
                  ? <CustomIcon>
                    <UpIcon />
                  </CustomIcon>
                  : <CustomIcon>
                    <DownIcon />
                  </CustomIcon>
              }
            </ToggleButton>
          </CustomFlexRow>
    }
  </>
}

export default MenubarToggleDownButton
