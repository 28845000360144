import { scaleLinear } from 'd3'

export const SCORING_COLOR = {
  MIN: {
    // HUE: 5,
    // SATURATION: 90,
    // LIGHTNESS: 61
    HUE: 0,
    SATURATION: 80,
    LIGHTNESS: 50
  },
  MAX: {
    // HUE: 233,
    // SATURATION: 90,
    // LIGHTNESS: 61
    HUE: 120,
    SATURATION: 80,
    LIGHTNESS: 50
  }
}

function hexToRgbString(hex) {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b
  })

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  if (!result) {
    return 'rgb(0, 0, 0)'
  }

  return `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)})`
}

export function CUSTOM_INTERPOLATION(score) {
  const SCORE_LIST = [0, 2.5, 5, 7.5, 10]
  const SPECTRAL_COLORS = [
    '#2b83ba',
    '#abdda4',
    '#ffffbf',
    '#fdae61',
    '#d7191c'
  ]

  for (let i = 0; i < SCORE_LIST.length; i++) {
    if (Number(score) === SCORE_LIST[i]) {
      return () => hexToRgbString(SPECTRAL_COLORS[i])
    }
  }

  let min = SCORE_LIST.length - 1
  let max = 0

  for (let i = 0; i < SCORE_LIST.length; i++) {
    if (max === SCORE_LIST[0] && Number(score) < SCORE_LIST[i]) {
      max = i
    } else if (Number(score) > SCORE_LIST[i]) {
      min = i
    }
  }

  return scaleLinear()
    .domain([SCORE_LIST[min], SCORE_LIST[max]])
    .range([SPECTRAL_COLORS[min], SPECTRAL_COLORS[max]])
}

export function SCORING_LOGIC(score) {
  return (1 - (score / 10))
}
