import * as actionTypes from '../../constants/store/actionTypes/rankbar'

const initialState = {
  toggle: false,
  minimize: false
}

function rankbarReducer(state = initialState, { type, payload }) {
  switch (type) {
    case actionTypes.TOGGLE_RANKBAR:
      return {
        ...state,
        toggle: payload.toggle
      }
    case actionTypes.MINIMIZE_RANKBAR:
      return {
        ...state,
        minimize: payload.minimize
      }
    case actionTypes.RESET_RANKBAR:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default rankbarReducer
