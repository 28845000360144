import React, { useEffect, useRef, useState } from 'react';
import { 
    ButtonSubmitCustom,
    ChartAnalysis, 
    HeaderChart, 
    LabelCheckbox,
    ListCheckbox,
    ShowCustomSelect,
    StyleCheckboxSelect,
    TitleChart,
    WrapperChartSvg,
    WrapperManySvg,
    WrapperSpinner,
    WrapperSpinnerBackground
} from '../helpers/AnalysisStyle';
import { 
    correlationValue,
    filterNaNValue,
    transposeValue
} from './helpers';

import Checkbox from '../../Checkbox';
import { correlationMatrix } from './correlationMatrixFunction';
import { capitalizeElement } from '../helpers/capitalizeFirstLetter';
import { getCheckboxValues, toggleCheck } from '../helpers/checkboxElements';
import { Puff } from 'react-loading-icons';
import { dropdownGroupElemSelect } from '../helpers/dropdownElements';

/**
 * Plotting
 * @param {array} data
 * @param {} className
 * @param {} 
 * @returns 
 */

let CorrelationMatrix = ({...props}) => {
    let {
        data,
        dataSample,
        dataReady,
        className,
        setConfig,
        config,
        heightContainer,
        widthContainer,
        dropdown,
        init,
        dataLocal
    } = props;

    const correlationMatrixRef = useRef(null);
    const colorScaleCorrelationMatrixRef = useRef(null);

    const nameCheckbox=className+'checkboxCorMat';

    const [valuesToPlot, setValuesToPlot]=useState([]);
    const [checkedAll, setCheckedAll] = useState(false);
    const [valDD, setValDD] = useState()
    const [loading,setLoading] = useState(false)
    const [toggleSubmitButton, setToggleSubmitButton]=useState(true)
    const [configMatrix, setConfigMatrix]=useState(
    {
        seriesSelect:[],
        colorScaleSelect:["tomato","white","steelblue"],
        domainColorScale:[-1,0,1],
        checkIgnore:false,
        checked:{},
        defaultPickDropDown:'',
        customSelect:"",
    });

    // if(!dataReady){
    //     setLoading(true)
    // }
    
    useEffect(()=>{
        if(!config) return;
        let newConfig={...configMatrix}
        Object.entries(config).forEach(([key,value])=>{
            if(key in newConfig){
                newConfig[key]=value;
            }
        })
        setConfigMatrix(newConfig)
        setLoading(true)
        if(config.defaultPickDropDown!==''){
            dropdown.map((d)=>{
                if(d.value===config.defaultPickDropDown){
                    setValDD(d)
                }
            })
        }
    },[])

    useEffect(()=>{
        if(config.seriesSelect.length===0 || data[config.seriesSelect[0]]===undefined){
            let newConfig={...configMatrix}
            newConfig.seriesSelect=init
            if(dropdown.length!==0){
                newConfig.defaultPickDropDown=dropdown[0].value
                setValDD(dropdown[0])
            }
            setConfigMatrix(newConfig)
        }
    },[init,dropdown])

    // useEffect(()=>{
    //     let newConfig={...configMatrix}
    //     let initialCheck={}
    //     Object.entries(seriesDataList).forEach((key)=>{
    //         initialCheck[key[1]]=false;
    //     })
    //     newConfig.checked=initialCheck;
    //     setConfigMatrix(newConfig)
    // },[seriesDataList])
    
    useEffect(()=>{
        if(dataReady===false){
            setLoading(true)
            return
        }
        if(configMatrix.checkIgnore===true){
            setValuesToPlot(
                filterNaNValue({
                    values: configMatrix.seriesSelect,
                    data: data
                })
            );
        }else if(configMatrix.checkIgnore===false){
        setValuesToPlot(configMatrix.seriesSelect);
        };
        if(configMatrix.defaultPickDropDown !=="Custom"){
            setToggleSubmitButton(true)
        }
    },[data,configMatrix.seriesSelect,configMatrix.checkIgnore,configMatrix.defaultPickDropDown,dataReady])

    useEffect(() => {
        if(dataReady===false)return;
        if (Object.keys(data).length===0) return;
        setLoading(true)

        const valueTranspose=transposeValue(valuesToPlot);
        const dataCorrelation=correlationValue({
            values: valuesToPlot,
            dataSample: dataSample,
        });

        correlationMatrix({
            correlationMatrixRef: correlationMatrixRef,
            colorScaleCorrelationMatrixRef: colorScaleCorrelationMatrixRef,
            value: valuesToPlot,
            valueTranspose: valueTranspose,
            configMatrix: configMatrix,
            dataCorrelation: dataCorrelation,
            className: className,
            widthContainer: widthContainer,
            heightContainer: heightContainer*(80/100),
            dataLocal: dataLocal
        });

        setLoading(false)
    }, [data,valuesToPlot,dataReady, className, configMatrix, heightContainer,widthContainer]);

    useEffect(()=>{
        setConfig(configMatrix)
    },[configMatrix])

    useEffect(() => {
        let allChecked = true;
        for (const inputName in configMatrix.checked) {
            if (configMatrix.checked[inputName] === false) {
                allChecked = false;
            }
        }
        if (allChecked) {
            setCheckedAll(true);
        } else {
            setCheckedAll(false);
        }
    }, [configMatrix.checked]);

    const resetCheckboxValues = () => {
        setToggleSubmitButton(true)
    }

    const checkboxCustom = () => {
        const col1 = [], col2 = [], col3 = [], col4=[]
        Object.keys(data).map((item,i)=>{
            if(i<Object.keys(data).length/4){
                col1.push(item)
            } else if (i>=Object.keys(data).length/4 && i < Object.keys(data).length /2){
                col2.push(item)
            } else if (i>=Object.keys(data).length/2 && i < Object.keys(data).length*3 /4){
                col3.push(item)
            } else {
                col4.push(item)
            }
        })
        const mapColumn=[col1,col2,col3,col4]
        return(
            <div style={{display:'flex', justifyContent:'space-evenly'}}>
                {
                    mapColumn.map((col,i)=>
                    <div>
                        {
                            col.map((item,idx)=>
                            <ListCheckbox key={`${item}-checkCorreMat`}>
                            <Checkbox
                                key={`${idx}-checkCorreMat`} 
                                type="checkbox"
                                className="checkSelector"
                                id="checkboxCustomSelector"
                                name={nameCheckbox}
                                onChange={() => toggleCheck({
                                    inputName: item,
                                    nameCheckbox: nameCheckbox,
                                    config: configMatrix,
                                    setConfig: setConfigMatrix
                                })}
                                checked={configMatrix.checked[item]}
                                value={item}
                                title={ dataLocal? item : capitalizeElement(item.slice(0,item.lastIndexOf("_")))}
                                />
                                {dataLocal? item: capitalizeElement(item.slice(0,item.lastIndexOf("_")))}
                            </ListCheckbox>
                            )
                        }
                    </div>
                    )
                }
            </div>
        )
    }

    const handleDropdownSeries=(value)=>{
        if(value.value==="Custom"){
            let newValue={...configMatrix};
            if(newValue.defaultPickDropDown==="Custom"){
                setToggleSubmitButton(true)
            } else {
                let checkboxValues=[];
                let checkboxTitle=[];
                var checks = document.querySelectorAll(`input[name=${nameCheckbox}]:checked`);
                checks.forEach((check)=>{
                checkboxValues.push(check.value);
                checkboxTitle.push(check.title)
                });
                newValue.seriesSelect=checkboxValues;
                newValue.customSelect=`(${checkboxTitle})`;
                newValue.defaultPickDropDown="Custom";
            }
            setConfigMatrix(newValue);
            setValDD(value)
        }else{
            let newValue=value.value.split(",")
            let newConfig={...configMatrix};
            newConfig.seriesSelect=newValue;
            newConfig.defaultPickDropDown=newValue.toString();
            setConfigMatrix(newConfig);
            setValDD(value)
        }
    }

    
    return (
        <ChartAnalysis>
            
            <TitleChart className="header-plot">
            Correlation Matrix
            </TitleChart>

            <HeaderChart className="header-plot row">
                {
                    dropdownGroupElemSelect({
                        dropdownOptions:dropdown,
                        handle: handleDropdownSeries,
                        valDD: valDD
                    })
                }
                {
                    configMatrix.defaultPickDropDown === "Custom" 
                    ?
                    <>
                    <ShowCustomSelect className="col noDrag" id="customSelectChecks">
                        {configMatrix.customSelect}
                    </ShowCustomSelect>

                    {
                        toggleSubmitButton ?
                        <ButtonSubmitCustom 
                            className="buttonSubmitCorMat col noDrag"
                            title="Create Correlation Matrix"
                            id="buttonSubmitCorMat"
                            type="submit"
                            onClick={()=>getCheckboxValues({
                                nameCheckbox: nameCheckbox,
                                config:configMatrix,
                                setConfig: setConfigMatrix,
                                setToggle: setToggleSubmitButton
                            })}
                        >
                            Create
                        </ButtonSubmitCustom>
                        :
                        <ButtonSubmitCustom 
                            title="Reset Custom Correlation Matrix"
                            className="buttonResetCorMat col noDrag"
                            id="buttonResetCorMat"
                            type="reset"
                            onClick={()=>resetCheckboxValues()}
                        >
                            Reset
                        </ButtonSubmitCustom>
                    }
                </>
                :
                <></>
                }
            <LabelCheckbox 
                title="Ignore NaN Value"
                marginLeft="0em"
                className='noDrag'
            >
                <Checkbox 
                type="checkbox"
                id="ignoreNan"
                onChange={()=>{
                    let newValue_={...configMatrix};
                    newValue_.checkIgnore=!configMatrix.checkIgnore;
                    setConfigMatrix(newValue_);
                }}
                checked={configMatrix.checkIgnore}
                />
                Ignore NAN
            </LabelCheckbox>
            </HeaderChart>

            {loading?
            <WrapperSpinnerBackground>
                <WrapperSpinner>
                    <Puff
                    height={heightContainer/2}
                    width={widthContainer/2}
                    fill='goldenrod'
                    stroke="darkgoldenrod"
                    speed="1"
                    fillOpacity="0.5"
                    strokeOpacity="0.5"
                    strokeWidth="2"
                />
                </WrapperSpinner>
            </WrapperSpinnerBackground>
            :
            <></>
            }

            <WrapperChartSvg>
                <WrapperManySvg>
                    <svg
                        ref={colorScaleCorrelationMatrixRef}
                        width={"100%"}
                        height={"10%"}
                        />
                        <svg
                        ref={correlationMatrixRef}
                        width={"100%"}
                        height={"100%"}
                    />
                </WrapperManySvg>
            </WrapperChartSvg>

            {
                configMatrix.defaultPickDropDown === "Custom" && toggleSubmitButton
                ?
                <StyleCheckboxSelect>
                {
                    checkboxCustom()
                }
                </StyleCheckboxSelect>
                :
                <></>
            }

            {/* <ButtonChart
                title="Minimize"
                type="minimize"
                onClick={()=>{
                    onMinimize()
                }}
            >
                &minus;
            </ButtonChart>
            <ButtonChart
                title="Settings"
                type="settings"
                onClick={()=>{
                    openSettings()
                }}
            >
                &equiv;
            </ButtonChart>
            <ButtonChart 
                title="Close"
                type="close"
                onClick={()=>onDelete()}
            >
                &times;
            </ButtonChart> */}

            {/* <Spinner 
                sizeContainer = "large"
                isLoading = {loading}
            /> */}
        </ChartAnalysis>
        );
    };
    

export default CorrelationMatrix;