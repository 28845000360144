export function setCesiumContentRight(rightbar) {
  const cesiumToolbars = document.getElementsByClassName('cesium-viewer-toolbar')
  const compass = document.getElementsByClassName('compass')
  
  for (let i = 0; i < cesiumToolbars.length; i++) {
    if (!rightbar.toggle || rightbar.minimize) {
      cesiumToolbars[i].style.right = '0.3em'
    } else {
      cesiumToolbars[i].style.right = `calc(${rightbar.width} + 0.3em)`
    }
  }
  
  if (!rightbar.toggle || rightbar.minimize) {
    compass[0].style.right = '-0.3em'
  } else {
    compass[0].style.right = `calc(${rightbar.width} - 0.3em)`
  }
  const performances = document.getElementsByClassName('cesium-performanceDisplay-defaultContainer')

  for (let i = 0; i < performances.length; i++) {
    if (!rightbar.toggle || rightbar.minimize) {
      performances[i].style.right = '0.5em'
    } else {
      performances[i].style.right = `calc(${rightbar.width} + 0.5em)`
    }
  }
}
