import React, { useState } from "react";
import axios from "axios";
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";

import { BASE_URL } from '../../constants/Server/base'

import Navbar from "../../components/Menubars/Navbar";

import ResetPassword from "../../images/Icons/forgot-password.svg";

import iziToast from 'izitoast'

import "./emailReset.css";

function ResetPasswordEmail (props) {
  let history = useHistory()
  const [ email, setEmail ]= useState("")
  const [ emailSend, setEmailSend ] = useState(false)

  // need to be updated soon

  const onSubmit= e => {
    e.preventDefault()
    axios
      .post(`${BASE_URL}/reset_password_request`, { email })
      .then(function () {
        setEmailSend(true)
        history.push('/login')
        iziToast.success({
          title: 'Success',
          message: 'Please check your email to reset password'
        })
      })
      .catch(function (error) {
        setEmailSend(false)
        if(error.message.split(' ').pop()==='401'){
          history.push('/login')
          iziToast.warning({
            title: 'Failed',
            message: 'Have you asked for a link to reset your password, please check your email, the link has been sent again'
          })
        } else if(error.message.split(' ').pop()==='404'){
          iziToast.error({
            title: 'Failed',
            message: 'Email is not registered'
          })
        } else {
          iziToast.error({
            title: 'Failed',
            message: 'Please check your email'
          })
        }
      });
  };
    
  return (
    <div className="content-block" style={{ width:'100vw'}}>
      <Navbar className="row"/>
      <div className="img-bg-big">
        <div className="container-reset ">
          {
            emailSend?
            <div  style={{textAlign:'center', fontFamily:'Abel'}} className="input-email-form">
              <h1> Password Reset</h1>
              <div>
                An email with a password reset link has been sent to your email : <span style={{fontWeight: 'bold'}}>{email}</span>
              </div>
              <div>
                Check your email dan click the link to proceed!
              </div>
              <p><Link to="/login">Back to login page</Link></p>
            </div>
            :
            <form
              className="input-email-form"
              onSubmit={onSubmit}
            >
              <div className="row">
                <img src={ResetPassword} alt="Logo-reset-password" className=" logo-reset"/>
              </div>
              <div>
                <p className="row text-center-reset">Forgot password?</p>
              </div>
              <p className="text-center-input">Enter the email address associated with your account and we'll send you a link to reset your password</p>
              <div className="row mb-2 pt">
                <input
                  name="email"
                  type="email"
                  id="email"
                  value={email}
                  className="form-control text-center-input"
                  placeholder="Enter your email address"
                  onChange={e => setEmail(e.target.value)}
                  required
                />
                <button
                  type="submit"
                  className="button-reset"
                  block="true"
                > <span>Reset Password</span>
                </button>
              </div>
                <p className="text-reset"><Link to="/login">Back to login page</Link></p>
            </form>
          }
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordEmail