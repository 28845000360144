import axiosReact from '../../../../apis/axiosReact'

import { DEFAULT_PRECISION } from '..'

import {
  removeHeatMap
} from '../../../../apis/cesiumMap/heatmap'

import generateGroupEntitiesFromQML from '../../../qml/readQML'

import {
  DEFAULT_COLOR_SCALE
} from '../../../../constants/Colors/d3'

import {
  generateAttribute,
  generateFieldMode,
  generateGroupEntitiesColor
} from './index'

import {
  generateGroupEntities
} from './groupEntities'

import {
  sortGroupEntities
} from './sortEntities'

import {
  getMaxScore,
  getMinScore,
  getMinScoreForLogarithmic
} from './sliderRange'

import iziToast from 'izitoast'

/**
 * This is effect method that will be triggered after template change
 * @param {CesiumMap} map cesium map
 */
export async function changeTemplateHandler(map) {
  try {
    async function fetchTemplate({ templateName }) {
      map.colorScale = null
  
      const { data } = await axiosReact.get('/geoprosp/layer_style', {
        params: {
          name: templateName
        }
      })

      if (!data) {
        return map.cesiumLayer.setTemplate(null)
      }
  
      const qml = data
  
      let status = false
      let newGroupEntities = null
      let newAttribute = null
      let newMode = null
      let newGroupBy = null
      let newPrecision = null

      status = generateGroupEntitiesFromQML({
        map,
        listGroup: map.cesiumLayer.listGroup,
        setGroupEntities: (value) => {
          newGroupEntities = value
        },
        setFieldAttribute: (value) => {
          newAttribute = value
        },
        setFieldMode: (value) => {
          newMode = value.mode
          newPrecision = value.precision
        },
        setGroupBy: (value) => {
          newGroupBy = value
        },
        qml
      })

      if (!status) {
        map.colorScale = DEFAULT_COLOR_SCALE
  
        iziToast.warning({
          title: 'Error',
          message: 'This feature only support color from QGis'
        })
  
        return map.cesiumLayer.setTemplate(null)
      }

      map.cesiumLayer.setGroupBy(newGroupBy)
      map.cesiumLayer.setFieldAttribute({
        ...generateAttribute({
          map,
          groupBy: newGroupBy
        }),
        selected: newAttribute
      })
  
      if (newMode) {
        let min = 0
      
        if (newMode === 'logarithmic') {
          min = getMinScoreForLogarithmic({
            map,
            groupBy: newGroupBy
          })
        } else {
          min = getMinScore({
            map,
            groupBy: newGroupBy
          })
        } 
  
        const max = getMaxScore({
          map,
          groupBy: newGroupBy
        })
  
        map.cesiumLayer.setSliderState({
          ...map.cesiumLayer.sliderState,
          min,
          max 
        })
  
        map.cesiumLayer.setRange({
          ...map.cesiumLayer.range,
          min,
          max
        })
  
        map.cesiumLayer.setFieldMode(generateFieldMode({
          map,
          selected: newMode,
          precision: newPrecision
        }))
      }
      
      map.cesiumLayer.setGroupEntities(newGroupEntities)
    }

    if (map.cesiumLayer.template) {
      await fetchTemplate({ templateName: map.cesiumLayer.template })
    } else if (Array.isArray(map.cesiumLayer.listGroup) && map.cesiumLayer.listGroup.length) {
      if (!map.cesiumLayer.groupBy) {
        map.cesiumLayer.setGroupBy(map.cesiumLayer.listGroup[0].pickName)
      } else {
        map.cesiumLayer.setGroupBy(map.cesiumLayer.groupBy)
      }
    }
  } catch (error) {
    throw error
  }
}

/**
 * This is effect method that will be triggered after groupBy change
 * @param {CesiumMap} map cesium map
 */
export function changeGroupByHandler(map) {
  try {
    if (!map.cesiumLayer.template) {
      if (map.cesiumLayer.heat) {
        removeHeatMap({ map })
      }
  
      if (map.cesiumLayer.groupBy) {
        map.cesiumLayer.setFieldAttribute(
          generateAttribute({
            map,
            groupBy: map.cesiumLayer.groupBy
          })
        )
      }
    }
  } catch (error) {
    throw error
  }
}

/**
 * This is effect method that will be triggered after fieldAttribute change
 * @param {CesiumMap} map cesium map
 */
export function changeFieldAttributeHandler(map) {
  try {
    if (!map.cesiumLayer.template && !map.tileset) {
      if (map.cesiumLayer.fieldAttribute.selected === 'numerical') {
        map.cesiumLayer.setFieldMode(
          generateFieldMode({
            map,
            selected: map.cesiumLayer.fieldMode.selected,
            precision: map.cesiumLayer.fieldMode.precision
          })
        )
      } else if (map.cesiumLayer.fieldAttribute.selected) {
        map.cesiumLayer.setFieldMode({
          pickList: [],
          selected: null,
          precision: DEFAULT_PRECISION
        })
  
        const currentGroupEntities = generateGroupEntities({
          map,
          groupBy: map.cesiumLayer.groupBy,
          attribute: map.cesiumLayer.fieldAttribute.selected,
          precision: map.cesiumLayer.fieldMode.precision
        })
  
        if (map.cesiumLayer.sortBy === 'none') {
          map.cesiumLayer.setGroupEntities(
            currentGroupEntities
          )
        } else {
          map.cesiumLayer.setGroupEntities(
            sortGroupEntities({
              sortBy: map.cesiumLayer.sortBy,
              groupEntities: currentGroupEntities,
              groupBy: map.cesiumLayer.groupBy
            })
          )
        }
      }
    }
  } catch (error) {
    throw error
  }
}

/**
 * This is effect method that will be triggered after fieldMode change
 * @param {CesiumMap} map cesium map
 */
 export function changeFieldModeHandler(map) {
  try {
    if (!map.cesiumLayer.template && !map.tileset) {
      if (map.cesiumLayer.fieldMode.selected) {     
        let min = 0

        if (map.cesiumLayer.fieldMode.selected === 'logarithmic') {
          min = getMinScoreForLogarithmic({
            map,
            groupBy: map.cesiumLayer.groupBy
          })
        } else {
          min = getMinScore({
            map,
            groupBy: map.cesiumLayer.groupBy
          })
        } 

        const max = getMaxScore({
          map,
          groupBy: map.cesiumLayer.groupBy
        })
  
        map.cesiumLayer.setSliderState({
          ...map.cesiumLayer.sliderState,
          min,
          max
        })
  
        map.cesiumLayer.setRange({
          ...map.cesiumLayer.range,
          min,
          max
        })
  
        const currentGroupEntities = generateGroupEntities({
          map,
          groupBy: map.cesiumLayer.groupBy,
          attribute: map.cesiumLayer.fieldAttribute.selected,
          mode: map.cesiumLayer.fieldMode.selected,
          min,
          max,
          precision: map.cesiumLayer.fieldMode.precision,
          prevGroupEntities: map.cesiumLayer.groupEntities
        })

        if (map.cesiumLayer.sortBy === 'none') {
          map.cesiumLayer.setGroupEntities(
            currentGroupEntities
          )
        } else {
          map.cesiumLayer.setGroupEntities(
            sortGroupEntities({
              sortBy: map.cesiumLayer.sortBy,
              groupEntities: currentGroupEntities,
              groupBy: map.cesiumLayer.groupBy
            })
          )
        }
      }
    }
  } catch (error) {
    throw error
  }
}

/**
 * This is effect method that will be triggered after range change
 * @param {CesiumMap} map cesium map
 */
export function changeRangeHandler(map) {
  try {
    if (!map.cesiumLayer.template) {
      if (map.cesiumLayer.fieldMode.selected) {
        const currentGroupEntities = generateGroupEntities({
          map,
          groupBy: map.cesiumLayer.groupBy,
          attribute: map.cesiumLayer.fieldAttribute.selected,
          mode: map.cesiumLayer.fieldMode.selected,
          min: map.cesiumLayer.range.min,
          max: map.cesiumLayer.range.max,
          precision: map.cesiumLayer.fieldMode.precision,
          prevGroupEntities: map.cesiumLayer.groupEntities
        })
  
        if (map.cesiumLayer.sortBy === 'none') {
          map.cesiumLayer.setGroupEntities(
            currentGroupEntities
          )
        } else {
          map.cesiumLayer.setGroupEntities(
            sortGroupEntities({
              sortBy: map.cesiumLayer.sortBy,
              groupEntities: currentGroupEntities,
              groupBy: map.cesiumLayer.groupBy
            })
          )
        }
      }
    }
  } catch (error) {
    throw error
  }
}

/**
 * This is effect method that will be triggered after sortBy change
 * @param {CesiumMap} map cesium map
 */
export function changeSortByHandler(map) {
  try {
    if (!map.cesiumLayer.template) {
      if (map.cesiumLayer.sortBy !== 'none') {
        map.cesiumLayer.setGroupEntities(
          sortGroupEntities({
            sortBy: map.cesiumLayer.sortBy,
            groupEntities: map.cesiumLayer.groupEntities,
            groupBy: map.cesiumLayer.groupBy
          })
        )
      }
    }
  } catch (error) {
    throw error
  }
}

/**
 * This is effect method that will be triggered after groupEntities change
 * @param {CesiumMap} map cesium map
 */
export function changeGroupEntitiesHandler(map) {
  try {
    if (
      map.cesiumLayer.groupEntities
      && !map.cesiumLayer.template
      && map.colorScale
    ) {
      const styleGroupEntities = generateGroupEntitiesColor({
        map,
        groupEntities: map.cesiumLayer.groupEntities
      })

      for (let key in styleGroupEntities.style) {
        const entities = styleGroupEntities.group[key]

        const {
          red,
          green,
          blue,
          alpha
        } = styleGroupEntities.style[key].color

        for (let i = 0; i < entities.length; i++) {
          map.colorEntities[entities[i].id] = {
            red: red,
            green: green,
            blue: blue,
            alpha: alpha
          }
        }
      }
    } else if (map.cesiumLayer.groupEntities) {
      for (let key in map.cesiumLayer.groupEntities.style) {
        const entitites = map.cesiumLayer.groupEntities.group[key]
        
        const {
          red,
          green,
          blue,
          alpha
        } = map.cesiumLayer.groupEntities.style[key].color

        for (let i = 0; i < entitites.length; i++) {
          map.colorEntities[entitites[i].id] = {
            red: red,
            green: green,
            blue: blue,
            alpha: alpha
          }
        }
      }
    }
  } catch (error) {
    throw error
  }
}

/**
 * This is effect method that will be triggered after alpha change
 * @param {CesiumMap} map cesium map
 */
export function changeAlphaHandler(map) {
  if (map?.tilesetData) {
    map.tilesetData.alpha = map.cesiumLayer.alpha
    return 
  }

  for (let entityId in map.colorEntities) {
    map.colorEntities[entityId].alpha = map.cesiumLayer.alpha
  }

  for (let key in map.cesiumLayer.groupEntities.style) {
    map.cesiumLayer.groupEntities.style[key].color.alpha = map.cesiumLayer.alpha
  }
}
