export function addThousand (number) {
  let text = ''
  let count = 0
  const strNumber = number.toString()

  for (let i = strNumber.length - 1; i >= 0; i--) {
    if (count === 3) {
      text = strNumber[i] + '.' + text
      count = 0
    } else {
      text = strNumber[i] + text
    }
    count += 1
  }

  return text
}
