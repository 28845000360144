
export function powerOfTen(d) {
    return d / Math.pow(10, Math.ceil(Math.log(d) / Math.LN10 - 1e-12)) === 1;
}

export function pointsInPolygon (point, vs){
    var 
        x = point[0],
        y = point[1],
        inside = false;
    for (var i=0, j = vs.length-1; i< vs.length; j = i++){
        const xi = vs[i][0],
            yi = vs[i][1],
            xj = vs[j][0],
            yj = vs[j][1],
            intersect = ((yi > y) !== (yj > y)) && (x < (xj-xi) * (y-yi) / (yj-yi) + xi);
        if (intersect) inside = !inside;
    }
    return inside;
}

export function findWithAttr(array, attr, value) {
    for(var i = 0; i < array.length; i += 1) {
        if(array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}