import * as d3 from 'd3';

export const scaleLinearY = ({...props}) => {
    let {
        minrange,
        maxrange,
        bins
    } = props;

    return(
        d3.scaleLinear()
            .range([minrange,maxrange])
            .domain([0, d3.max(bins, function(d) { return d.length; })+(d3.max(bins, function(d) { return d.length; })-d3.min(bins, function(d) { return d.length; }))/10])
            
    )
}

export const scaleLinearX = ({...props}) => {
    let {
        minrange,
        maxrange,
        data,
        x,
    } = props;
    // let res=(d3.max(data[x])-d3.min(data[x]))/10

    return(
        d3.scaleLinear()
            .range([minrange,maxrange])
            .domain([d3.min(data[x]), d3.max(data[x])])
            
    )
}

export const binsForHistogram = ({...props}) => {
    let {
        x,
        data,
        selection,
        numBins
    } = props;

    let histogram = d3.bin()
        .value(function(d) { return d; })
        .domain(x.domain())
        .thresholds(x.ticks(numBins));
    
    let bins = histogram(data[selection])
    return(bins)
}

export const runXYAxis = ({...props}) => {
    let {
        xAxis,
        yAxis,
        x,
        y,
        data,
        configHistogram
    } = props;

    if (d3.max(data[configHistogram.valueSelect])>1000){
        xAxis.selectAll('text')
            .style("text-anchor", "end")
            .attr("dx", "-.8em")
            .attr("dy", ".15em")
            .attr("transform", "rotate(-45)");
    }

    xAxis
    .transition()
    .duration(500)
    .call(
        d3.axisBottom(x)
            .ticks(5)
            .tickSizeOuter(0)
    )

    yAxis
    .transition()
    .duration(500)
    .call(d3.axisLeft(y)
    .tickSizeOuter(0));

    // xAxis.select("path")
    // .attr("marker-end","url(#arrowhead-right)")
    // yAxis.select("path")
    // .attr("marker-end","url(#arrowhead-up)")
}

export const makeHistogram = ({...props}) => {
    let {
        classNameAll,
        gHistogram,
        bins,
        x,
        y,
        height,
        color,
        selection,
        series
    } = props;
    if(color===null){
        color=d3.scaleOrdinal()
        .domain(series)
        .range(d3.schemeSet2);
    }
    const tooltip = d3.select("."+classNameAll)
    .append("div")
    .style("opacity",0)
    .attr ("class","tooltip-histogram")
    .style("position", "fixed")
    .style("z-index", 3)
    gHistogram.selectAll("rect")
    .data(bins)
    .join("rect")
    .on("mouseover", function(d) {		
        tooltip
            .transition()
            .duration(500)
            .style("opacity",1)
        d3.select(this)
            .style("stroke","white")
            .style("stroke-width", "1px")	
    })
    .on("mouseleave", function(d){
        tooltip
            .transition()
            .duration(500)
            .style("opacity", 0)
        d3.select(this)
            .style("stroke","none")
            
    })
    .on("mousemove", function(event, d){
        tooltip
            .html("Range: " + d.x0 +" - " + d.x1 + "</br> Frequency: " + d.length)
            .style('left', event.pageX + 10 + "px")
            .style('top', event.pageY - 50 + "px")
        d3.select(this)
            .style("stroke","white")
            .style("stroke-width", "1px")	
            .style("cursor","pointer")
    })
    .attr("x", 1)
    .attr("height", function(d) { 
        return 0
    })
    .attr("transform", function(d) { return "translate(" + x(d.x0) + "," + y(0) + ")"; })
    .transition()
    .duration(1000)
    .attr("transform", function(d) { return "translate(" + x(d.x0) + "," + y(d.length) + ")"; })
    .attr("width", function(d) { return x(d.x1) - x(d.x0)})
    .attr("height", function(d) { 
            return height - y(d.length)
    })
    .style("fill", function(){return color(selection)})

}

export const makeHistogramAndTooltip = ({...props}) => {
    let {
        classNameAll,
        gHistogram,
        bins,
        x,
        y,
        height,
        color,
        selection,
        series
    } = props;

    if(color===null){
        color=d3.scaleOrdinal()
        .domain(series)
        .range(d3.schemeSet2);
    }
    
    const tooltip = d3.select("."+classNameAll)
    .append("div")
    .style("opacity",0)
    .attr ("class","tooltip-histogram")
    .style("position", "fixed")
    .style("z-index", 3)

    let u = gHistogram.selectAll("rect").data(bins)

    u.enter()
    .append("rect")
    .on("mouseover", function(d) {		
        tooltip
            .transition()
            .duration(500)
            .style("opacity",1)
        d3.select(this)
            .style("stroke","white")
            .style("stroke-width", "1px")	
    })
    .on("mouseleave", function(d){
        tooltip
            .transition()
            .duration(500)
            .style("opacity", 0)
        d3.select(this)
            .style("stroke","none")
            
    })
    .on("mousemove", function(event, d){
        tooltip
            .html("Range: " + d.x0 +" - " + d.x1 + "</br> Frequency: " + d.length)
            .style('left', event.pageX + 10 + "px")
            .style('top', event.pageY - 50 + "px")
        d3.select(this)
            .style("stroke","white")
            .style("stroke-width", "1px")	
            .style("cursor","pointer")
    })
    .attr("x", 1)
    .attr("transform", function(d) { return "translate(" + x(d.x0) + "," + y(0) + ")"; })
    .transition()
    .duration(1000)
    .delay(100)
    .attr("transform", function(d) { return "translate(" + x(d.x0) + "," + y(d.length) + ")"; })
    .attr("width", function(d) { return x(d.x1) - x(d.x0)})
    .attr("height", function(d) { return height - y(d.length)})
    .style("fill", function(d){return color(selection)})
}
