import axiosReact, { getProgressDownload } from '../../../axiosReact'

import { BASE_URL } from '../../../../constants/Server/base'

export async function fetch({
  map,
  params,
  fetchProgress,
  fetchFinish
}) {
  if (params.raster) {
    try {
      const { data } = await axiosReact.get('/geofix/raster', {
        params,
        onDownloadProgress(event) {
          getProgressDownload(event, fetchProgress)
        }
      })

      fetchFinish()

      for (let i = 0; i < data.length; i++) {
        if (
          map.name === 'Airborne magnetic'
          && data[i].title === 'Aeromagnetic RTP'
        ) {
          data[i].url = `${BASE_URL}/geofix/raster_texture/wsenTexture/${data[i].filename}`

          return data[i]
        }
      }
    } catch (error) {
      throw error
    }
  } else {
    return null
  }
}

export function parseFetch({ data }) {
  return data
}

export function getColor(entity, { map }) {
  return {
    red: Math.random(),
    green: Math.random(),
    blue: Math.random(),
    alpha: map.cesiumLayer.alpha
  }
}

export async function getLegendStyle({
  map,
  params,
  fetchProgress,
  fetchFinish
}) {
  try {
  } catch (error) {
  }
}
