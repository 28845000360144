export function selectDetail (
  changeCommodity,
  data,
  toggleSidebarButton
) {
  try {
    toggleSidebarButton({
      buttonName: 'menu',
      status: false,
      animate: true
    })

    changeCommodity({
      commodity: data.name
    })
  } catch (error) {
    throw error
  }
}
