import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'

import Header from './Header'

import styled from 'styled-components'

const Wrapper = styled.div`
  margin: 1em;
  overflow: auto;
`

const TableStyle = styled.table`
  border-collapse: collapse;
  border: 1px solid black;
`

const TableData = styled.td`
  font-family: Abel;
  font-size: 0.7em;
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
  border-top: 1px solid grey;
  border-bottom: 0.5px solid grey;
  padding: 0.5rem 1rem;
  user-select: none;
`

const ListData = styled.p`
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ active }) => active && `
    background: blue;
  `}
`

function Table({
  setHeaderProp,
  setCheckboxValue,
  searchValue,
  tableConfig,
  setTableConfig,
  listSort,
  setListSort,
  sortableItems,
  setSortableItems,
  tableProperties,
  tableHeight,
  tableDeltaHeight
}) {
  const UPPER_HEIGHT_COMPONENT = '7em'
  const LIMIT = 100
  
  const [ limit, setLimit ] = useState(LIMIT)
  const [ scrollWidth, setScrollbarWidth ] = useState(0)

  useEffect(() => {
    const tbody = document.getElementById('tbody-table')

    setScrollbarWidth(tbody.offsetWidth - tbody.clientWidth)
  }, [])

  function getHeader(properties) {
    if (properties.length) {
      return Object.keys(properties[0])
    }

    return []
  }

  useEffect(() => {
    if (sortableItems.length === 0) {
      setLimit(100)
      setSortableItems(tableProperties)
      setHeaderProp(getHeader(tableProperties))
      setTableConfig({
        ...tableConfig,
        headers: getHeader(tableProperties)
      })
      let x={}
      if (getHeader(tableProperties)) {
        Object.entries(getHeader(tableProperties)).forEach((key)=>{
          x[key[1]]=true;
        })
      }
      setCheckboxValue(x)
    }

    return () => {
      setLimit(100)
      setSortableItems([])
      setHeaderProp([])
      setTableConfig({
        width: '100%',
        notShowList: [],
        focusList: [],
        sortBy: {},
        headers: [],
        rowWidth: {
          default: 4,
          modified: []
        },
        resizeRow: null,
        initialResizeRow: null
      })
      setCheckboxValue({})
    }
  }, [tableProperties])

  return (
    <Wrapper>
      <TableStyle>
        <Header
          tableConfig={tableConfig}
          setTableConfig={setTableConfig}
          listSort={listSort}
          setListSort={setListSort}
          setSortableItems={setSortableItems}
          properties={tableProperties}
        />
        <tbody
          id='tbody-table'
          style={{
            display: 'block',
            height: `calc(${tableHeight}em + ${tableDeltaHeight}px - ${UPPER_HEIGHT_COMPONENT})`,
            overflowY: 'auto',
            overflowX: 'hidden',
            marginRight: -scrollWidth
          }}
        >
          {
            sortableItems
              .map((property, index) => {
                if (index + 1 === limit) {
                  return <div
                    style={{
                      fontFamily: 'Abel',
                      fontSize: '0.8em',
                      color: 'blue',
                      display: 'flex',
                      justifyContent: 'center',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      setLimit((limit) => limit + LIMIT)
                    }}
                  >Add More...</div>
                } else if (index + 1 > limit) {
                  return null
                }

                return (
                  <tr
                    key={index}
                    style={{
                      background: index % 2
                        ? '#ffffff'
                        : '#eeeeee'
                    }}
                  >
                    {
                      tableConfig.headers.map((header, index) => {
                        if(!searchValue){
                          return (
                            <TableData key={index}>
                              <ListData
                                style={{
                                  width: tableConfig.rowWidth.modified[index] || tableConfig.rowWidth.default + 0.19 + 'em'
                                }}
                                >
                                {property[header]}
                              </ListData>
                            </TableData>
                          )
                        } else{
                          if(property[header]){
                            const parts = property[header].toString().split(new RegExp(`(${searchValue})`, 'gi'));
                            return (
                              <TableData key={index}>
                              <ListData
                                style={{
                                  width: tableConfig.rowWidth.modified[index] || tableConfig.rowWidth.default + 0.19 + 'em'
                                }}
                                >
                                  {
                                    parts.map((part, i) => 
                                    <span key={i} style={part.toLowerCase() === searchValue.toLowerCase() ? { fontWeight: 'bold', backgroundColor: '#FFFF00' } : {} }>
                                      { part }
                                    </span>
                                    )
                                  }
                              </ListData>
                            </TableData>
                            )
                          } else{
                            return (
                              <TableData key={index}>
                                <ListData
                                  style={{
                                    width: tableConfig.rowWidth.modified[index] || tableConfig.rowWidth.default + 0.19 + 'em'
                                  }}
                                  >
                                  {property[header]}
                                </ListData>
                              </TableData>
                            )
                          }
                        }
                    })
                  }
                  </tr>
                )
              })
          }
        </tbody>
      </TableStyle>
    </Wrapper>
  )
}

function mapStateToProps({ table }) {
  return {
    tableHeight: table.height,
    tableDeltaHeight: table.deltaHeight
  }
}

export default connect(mapStateToProps, null)(Table)
