import axiosReact, { getProgressDownload } from '../../../axiosReact'
import rgbToHex from '../../../helpers/rgbToHex'

export async function fetch({
  map,
  params,
  fetchProgress,
  fetchFinish
}) {
  try {
    if (params.withgeojson) {
      const { data } = await axiosReact.get('/geofix/accessibility/power_generator', {
        params,
        onDownloadProgress(event) {
          getProgressDownload(event, fetchProgress)
        }
      })

      data.features.forEach((feature) => {
        if (feature.properties.administration?.length) {
          feature.properties.administration = feature.properties.administration[0]
        }

        feature.properties = formatProperty(feature.properties)
      })

      fetchFinish()
  
      return {
        geojson: data
      }
    }
  } catch (error) {
    throw error
  }
}

export function formatProperty(property) {
  return {
    name: property.name,
    Class: property.class,
    Source: property.source,
    City: property.administration?.city,
    Province: property.administration?.province,
    Island: property.administration?.island
  }
}

export function parseFetch({ data }) {
  return data
}

export function getColor(entity, {
  map
}) {
  return {
    red: 1,
    green: 1,
    blue: 0,
    alpha: map.cesiumLayer.alpha
  }
}

export function getIcon(entity, color) {
  let hex = null

  const status = entity.properties.getValue('class').class

  if (status && status.toLowerCase().includes('old')) {
    hex = '#808080'
  } else if (color) {
    hex = rgbToHex(
      Math.round(color.red * 255),
      Math.round(color.green * 255),
      Math.round(color.blue * 255)
    )
  } else {
    hex = rgbToHex(
      Math.round(Math.random() * 255),
      Math.round(Math.random() * 255),
      Math.round(Math.random() * 255)
    )
  }
  const svgDataDeclare = "data:image/svg+xml,";
  const svgCircle = `<circle cx="94.8" cy="94.8" r="94.8" style="fill:%23fcb938" /><path d="M116,76.49c1.11,18.34,2.69,36.44,11.74,52.87,1.15,2.09,3.63,3.48,4.64,5.6a15.47,15.47,0,0,1,1.19,7c-.07,2.11-2.11,2.38-3.92,2.38H52.39c-1.84,0-3.41-.41-3.94-2.49-.82-3.18.06-6.27,1.79-7.39a11.51,11.51,0,0,0,3.42-3.08c5.88-9.13,8.66-19.42,10.34-30,1.18-7.43,1.65-15,2.43-22.46.15-1.52.1-2.56-1.79-3.46-3-1.43-1.75-4.63-1.58-7.07.16-2.26,2.21-2.54,4.14-2.54h47.36c2.25,0,4.6-.17,5.11,2.89C120.42,73.29,119.68,75,116,76.49ZM85.16,127.14l.57.25L105,101.3H92.84L96.92,83l-.65-.25L77.55,108.44H89.38C87.92,114.94,86.54,121,85.16,127.14Z" style="fill:%23fff"/><path d="M102.51,45.77c3.76-5.68,6.27-6.56,12.54-4.9a23.63,23.63,0,0,0,4.79.36c2.22-1.64,3.93-3.48,6-4.34,5-2,10.22,1.08,11.78,6.37a7.72,7.72,0,0,0,1.62,3c3.37,3.68,2.83,8.38-1.53,11-4.54,2.7-9,2.17-13.5-.88-3.57,2.86-7.3,3.16-11.68,1.24-1.94-.84-4.71-1.47-7,1.15-2.06,2.37-5,1.6-7.76-.6a5.53,5.53,0,0,1-.72,1.27c-2.5,2.55-5.84,2.56-7.88.07-1.91-2.32-1.33-5.59,1.3-7.64a3.7,3.7,0,0,0,1.36-1.7C93.49,44.14,97,42.63,102.51,45.77Z" style="fill:%23fff"/>`;
  const svgPrefix = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/2000/xlink" x="0px" y="0px" viewBox="0 0 189.6 189.6" height="60pt" width="60pt" preserveAspectRatio="xMidYMid meet">`;
  const svgSuffix = "</svg>";
  const svgString = svgPrefix + svgCircle + svgSuffix;

  // create the cesium entity
  const svgEntityImage = svgDataDeclare + svgString;
  return svgEntityImage
}