export const ADD_POLYLINE_ENTITY = 'ADD_POLYLINE_ENTITY'

export const UPDATE_ACTIVE_SHAPE_POINTS = 'UPDATE_ACTIVE_SHAPE_POINTS'

export const ADD_ACTIVE_POLYLINE_INDEX = 'ADD_ACTIVE_POLYLINE_INDEX'
export const UPDATE_POLYLINE_HISTORIES = 'UPDATE_POLYLINE_HISTORIES'

export const SET_POINT_SELECTED = 'SET_POINT_SELECTED'
export const REMOVE_POINT_SELECTED = 'REMOVE_POINT_SELECTED'

export const SET_ACTION_SELECT = 'SET_ACTION_SELECT'
export const SET_ACTION_DRAW_POLYLINE = 'SET_ACTION_DRAW_POLYLINE'
export const DRAW_POLYLINE = 'DRAW_POLYLINE'

export const DRAW_UNDO = 'DRAW_UNDO'
export const DRAW_UNDO_SUCCESS = 'DRAW_UNDO_SUCCESS'
export const DRAW_UNDO_ERROR = 'DRAW_UNDO_ERROR'

export const DRAW_REDO = 'DRAW_REDO'
export const DRAW_REDO_SUCCESS = 'DRAW_REDO_SUCCESS'
export const DRAW_REDO_ERROR = 'DRAW_REDO_ERROR'

export const DRAW_EDIT = 'DRAW_EDIT'

export const DRAW_SAVE = 'DRAW_SAVE'
export const DRAW_SAVE_SUCCESS = 'DRAW_SAVE_SUCCESS'
export const DRAW_SAVE_ERROR = 'DRAW_SAVE_ERROR'
export const DRAW_DELETE = 'DRAW_DELETE'
export const DRAW_DELETE_SUCCESS = 'DRAW_DELETE_SUCCESS'
export const DRAW_DELETE_ERROR = 'DRAW_DELETE_ERROR'

export const GROUP_ENTITY = 'GROUP_ENTITY'
export const UNGROUP_ENTITY = 'UNGROUP_ENTITY'

export const RESET_TOOLBAR = 'RESET_TOOLBAR'
