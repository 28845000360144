import React from 'react'

/**
 * This components InputSize to resize the entity
 * @param {Number} size the size of the entities 
 * @param {Function} setSize the function of the size
 * @param {Object} map the current map selected
 * @param {Function} onSetHandler the function to change the value
 */
function InputSize({
  size,
  setSize,
  onSetHandler
}) {
  return <>
    <input
      style={{
        margin: 0,
        fontFamily: 'Abel',
        marginLeft: '0.1em',
        fontSize: '0.7em',
        padding: '0.2em 0.4em',
        height: '1em',
        width: '3em',
        border: '1px solid black',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px'
      }}
      type="number"
      value={size}
      onChange={(event) => {
        setSize(event.target.value)
      }}
      onWheel={(event) => {
        let newSize = 10

        if (!isNaN(size)) {
          newSize = Number(size)
        }

        if (event.deltaY < 0) {
          newSize -= 1
        } else if (event.deltaY > 0) {
          newSize += 1
        }

        setSize(newSize)
      }}
      onKeyPress={(event) => {
        if (event.key === 'Enter') {
          if (!isNaN(size)) {
            const newSize = Number(size)
            onSetHandler(newSize)
          }
        }
      }}
      onClick={() => {
        if (!isNaN(size)) {
          const newSize = Number(size)

          onSetHandler(newSize)
        }
      }}
    />
  </>
}

export default InputSize
