import React, { useState, useEffect, useMemo } from 'react'

import { connect } from 'react-redux'

import {
  getUsername
} from '../../../../../store/selectors/user'

import {
  getChatRoom
} from '../../../../../store/selectors/socket'

import axiosReact from '../../../../../apis/axiosReact'

import ReactLoading from 'react-loading'

import MessageIconRecipientList from './MessageIconRecipientList'

import styled from 'styled-components'

import {
  MESSAGE_CONTENT_WIDTH
} from '../../style'

const Container = styled.div`
  position: absolute;
  left: 0;
  bottom: -0.1em;
`
const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  background: white;
  width: ${MESSAGE_CONTENT_WIDTH};
  height: auto;
  border-radius: 5px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 14em;
  z-index: 2;
  border: 1px solid black;
  margin-left: -3px;
`

const CenterContent = styled.div`
  display: flex;
  justify-content: center;
  width: calc(${MESSAGE_CONTENT_WIDTH} - 2em);
  padding: 0.5em;
`

const PaddingText = styled.div`
  padding: 0.5em;
`

let timeout = null

function PersonSuggestion({
  searchPerson,
  recipients,
  addRecipient,
  username,
  chatRoom,
  clearRecipient,
  lastNewMessage
}) {
  const [ listPerson, setListPerson ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const listPersonChecked = useMemo(() => {
    const recomendations = []

    const inRecipients = []

    listPerson.forEach((person) => {
      let alreadyAdded = false

      for (let i = 0; i < recipients.length; i++) {
        const recipientId = recipients[i].user_id || recipients[i].id
        const personId = person.user_id || person.id

        if (recipientId === personId) {
          person.alreadyAdded = true
          
          inRecipients.push(person)

          alreadyAdded = true
          break
        }
      }

      if (!alreadyAdded) {
        recomendations.push(person)
      }

      setLoading(false)
    })

    return recomendations.concat(inRecipients)
  }, [listPerson, recipients])

  useEffect(() => {
    setLoading(true)

    if (timeout) {
      clearTimeout(timeout)
    }

    async function fetchUsers() {
      await new Promise((resolve) => {
        if (searchPerson) {
          timeout = setTimeout(async () => {
            try {
              const { data } = await axiosReact.get('/employees', {
                params: {
                  keywords: searchPerson
                }
              })
  
              const personData = data.data.filter((person) => {
                if (username === person.username) {
                  return false
                }
  
                return true
              })
  
              let equal = true

              if (!personData.length) {
                equal = true
              } else if (listPerson.length !== personData.length) {
                equal = false
              } else {
                for (let i = 0; i < listPerson.length; i++) {
                  const person = listPerson[i]
                  const fetchPerson = personData[i]

                  if (person.user_id !== fetchPerson.user_id) {
                    equal = false
                  }
                }
              }
              
              if (equal) {
                setLoading(false)
              } else {
                setListPerson(personData)
              }
            } catch (error) {
              throw error
            } finally {
              resolve()
            }
          }, 330)
        }
      })
    }

    fetchUsers()

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [searchPerson])

  return (
    <Container>
      {
        searchPerson &&
          <InnerContainer>
            {searchPerson
              && listPerson.length === 0
              && !loading
                ? <PaddingText>
                  'No results found. Please check spelling.'
                </PaddingText>
                : null
            }
            {
              loading
                && <CenterContent>
                  <ReactLoading
                    color="black"
                    type="spin"
                    width="1em"
                    height="1em"
                  />
                </CenterContent>
            }
            {
              !loading
                &&
                  listPersonChecked.map((recipient) => {
                    return <MessageIconRecipientList
                      key={recipient.user_id}
                      person={recipient}
                      addRecipient={addRecipient}
                      chatRoom={chatRoom}
                      clearRecipient={clearRecipient}
                      lastNewMessage={lastNewMessage}
                    />
                  })
            }
          </InnerContainer>
      }
    </Container>
  )
}

function mapStateToProps(state) {
  return {
    username: getUsername(state),
    chatRoom: getChatRoom(state)
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, null)(PersonSuggestion)
