import axiosReact, { getProgressDownload } from '../../../axiosReact'

export async function fetch({
  map,
  params,
  fetchProgress,
  fetchFinish
}) {
  if (params.withgeojson) {
    try {
      const { data } = await axiosReact.get(`/geofix/grid_deposit/${map.id}`, {
        params,
        onDownloadProgress(event) {
          getProgressDownload(event, fetchProgress)
        }
      })

      fetchFinish()

      return {
        geojson: data
      }
    } catch (error) {
      throw error
    }
  } else {
    const { data } = await axiosReact.get('/geofix/grid_deposit', {
      params
    })

    return data
  }
}

/**
 * INPUT:
 * data => [
 *   { bin_size: Number }
 * ]
 * 
 * Objectives filtering less than 1 KM
 * 
 * OUTPUT:
 * [
 *   { bin_size: Number, id: Number, name: String }
 * ]
 */
 export function parseFetch({ data }) {
  // lock until grid 500m ready
  data.forEach((element) => {
    const binSize = Number(element.bin_size)
    let name = ''

    if (binSize >= 1000) {
      name = `DTP ${binSize / 1000} x ${binSize / 1000} km` 
    } else {
      name = null
      // name = `${binSize} x ${binSize} m`
    }

    element.id = element.bin_size
    element.name = name
  })

  return data.filter((data) => data.name)
}

export function getColor(entity, {
  map,
  currentMax
}) {
  return {
    red: 1,
    green: 1,
    blue: 1,
    alpha: map.cesiumLayer.alpha
  }
}

export async function getLegendStyle({
  map,
  params,
  fetchProgress,
  fetchFinish
}) {
  try {
  } catch (error) {
  }
}
