// default value stay first
export const LIST_FILTER_OPTION = {
  mines: [
    {
      pickName: 'deposit_types',
      pickShow: 'deposit_types'
    }
  ],
  geological: [
    {
      pickName: 'lithology',
      pickShow: 'Lithology'
    },
    {
      pickName: 'lithologyAge',
      pickShow: 'Lithology & Age'
    },
    {
      pickName: 'age',
      pickShow: 'Age'
    }
  ],
  structure: [
    {
      pickName: 'type',
      pickShow: 'Domain'
    },
    {
      pickName: 'class',
      pickShow: 'Class'
    }
  ],
  // geochemical: [
  //   {
  //     pickName: 'au1_ppm',
  //     pickShow: 'Au'
  //   },
  //   {
  //     pickName: 'ag_ppm',
  //     pickShow: 'Ag'
  //   },
  //   {
  //     pickName: 'ba_ppm',
  //     pickShow: 'Ba'
  //   },
  //   {
  //     pickName: 'bi_ppm',
  //     pickShow: 'Bi'
  //   },
  //   {
  //     pickName: 'ca_ppm',
  //     pickShow: 'Ca'
  //   },
  //   {
  //     pickName: 'cd_ppm',
  //     pickShow: 'Cd'
  //   },
  //   {
  //     pickName: 'co_ppm',
  //     pickShow: 'Co'
  //   },
  //   {
  //     pickName: 'cr_ppm',
  //     pickShow: 'Cr'
  //   },
  //   {
  //     pickName: 'cu_ppm',
  //     pickShow: 'Cu'
  //   },
  //   {
  //     pickName: 'fe_ppm',
  //     pickShow: 'Fe'
  //   },
  //   {
  //     pickName: 'ga_ppm',
  //     pickShow: 'Ga'
  //   },
  //   {
  //     pickName: 'la_ppm',
  //     pickShow: 'La'
  //   },
  //   {
  //     pickName: 'li_ppm',
  //     pickShow: 'Li'
  //   },
  //   {
  //     pickName: 'mg_ppm',
  //     pickShow: 'Mg'
  //   },
  //   {
  //     pickName: 'mn_ppm',
  //     pickShow: 'Mn'
  //   },
  //   {
  //     pickName: 'mo_ppm',
  //     pickShow: 'Mo'
  //   },
  //   {
  //     pickName: 'na_ppm',
  //     pickShow: 'Na'
  //   }
  // ],
  // mineral: [
  //   {
  //     pickName: 'Mineral',
  //     pickShow: 'Mineral'
  //   },
  //   {
  //     pickName: 'SAM',
  //     pickShow: 'SAM'
  //   }
  // ],
  borehole: [
    {
      pickName: 'AU_FINAL',
      pickShow: 'AU Final'
    },
    {
      pickName: 'AUCN_FINAL',
      pickShow: 'AUCN Final'
    },
    {
      pickName: 'Ag_FINAL',
      pickShow: 'Ag Final'
    },
    {
      pickName: 'AUCN/AUFA',
      pickShow: 'AUCN/AUFA'
    },
    {
      pickName: 'AG/AU',
      pickShow: 'AG/AU'
    },
    {
      pickName: 'AU1',
      pickShow: 'AU1'
    },
    {
      pickName: 'AU2',
      pickShow: 'AU2'
    },
    {
      pickName: 'AU3',
      pickShow: 'AU3'
    },
    {
      pickName: 'AU4',
      pickShow: 'AU4'
    },
    {
      pickName: 'AU5',
      pickShow: 'AU5'
    },
    {
      pickName: 'AUCN',
      pickShow: 'AUCN'
    },
    {
      pickName: 'AUCN1',
      pickShow: 'AUCN1'
    },
    {
      pickName: 'AUCN2',
      pickShow: 'AUCN2'
    },
    {
      pickName: 'AG1',
      pickShow: 'AG1'
    },
    {
      pickName: 'AG2',
      pickShow: 'AG2'
    },
    {
      pickName: 'CU',
      pickShow: 'CU'
    },
    {
      pickName: 'PB',
      pickShow: 'PB'
    },
    {
      pickName: 'ZN',
      pickShow: 'ZN'
    },
    {
      pickName: 'AS',
      pickShow: 'AS'
    },
    {
      pickName: 'SB',
      pickShow: 'SB'
    },
    {
      pickName: 'BI',
      pickShow: 'BI'
    },
    {
      pickName: 'FE',
      pickShow: 'FE'
    },
    {
      pickName: 'MO',
      pickShow: 'MO'
    },
    {
      pickName: 'HG',
      pickShow: 'HG'
    }
  ],
  earthquake: [
    {
      pickName: 'depth',
      pickShow: 'Depth'
    },
    {
      pickName: 'mag',
      pickShow: 'Magnitude'
    }
  ]
}

export const LIST_SORT_BY_OPTION = {
  categorical: [
    {
      pickName: 'none',
      pickShow: 'none'
    },
    {
      pickName: 'a-z',
      pickShow: 'A - Z'
    },
    {
      pickName: 'z-a',
      pickShow: 'Z - A'
    }
  ],
  none: [
    {
      pickName: 'none',
      pickShow: 'none'
    },
    {
      pickName: 'a-z',
      pickShow: 'A - Z'
    },
    {
      pickName: 'z-a',
      pickShow: 'Z - A'
    }
  ],
  numerical: [
    {
      pickName: 'none',
      pickShow: 'none'
    },
    {
      pickName: 'a-z',
      pickShow: '1 - 9'
    },
    {
      pickName: 'z-a',
      pickShow: '9 - 1'
    }
  ]
}

// {
//   geological: [
//     {
//       pickName: 'a-z',
//       pickShow: 'A - Z'
//     },
//     {
//       pickName: 'z-a',
//       pickShow: 'Z - A'
//     },
//     {
//       pickName: 'ageAsc',
//       pickShow: 'Age (Young - Old)'
//     },
//     {
//       pickName: 'ageDesc',
//       pickShow: 'Age (Old - Young)'
//     }
//   ]
// }
