import React from 'react'

import FocusContainer, {
  Header,
  LinkText,
  Paragraph
} from '../../StyledComponent'
import CursorPNG from '../../HelpImage/assistive-button-cursor.png'
import { LIST_HELP } from '../../constant'


function HelpCursor(props) {
  const flyTo=(x,y)=>{
    let cloneOpened = []
    cloneOpened.push(y)
    
    props.setOpened(cloneOpened)

    props.setFocus(x)
  }
  return <>
    <FocusContainer {...props}>
      <Header>{LIST_HELP['assistive'].detail.cursor.header}</Header>
      <Paragraph>
      This tool is very useful when the cursor changes due to the selection tool, it is used to return to the original cursor to be able to use <LinkText onClick={()=>flyTo('map','dashboard')}>cursor function on the map</LinkText>.
      </Paragraph>
      <img src={CursorPNG} alt="Cursor image" width="800px" />
    </FocusContainer>
  </>
}

export default HelpCursor
