export const SET_VIEWER = 'SET_VIEWER'

export const CLEAR_ENTITIES = 'CLEAR_ENTITIES'

export const SET_HOVER_AREA_ENTITY = 'SET_HOVER_AREA_ENTITY'
export const REMOVE_HOVER_AREA_ENTITY = 'REMOVE_HOVER_AREA_ENTITY'

export const SET_HOVER_MAP_ENTITY = 'SET_HOVER_MAP_ENTITY'
export const REMOVE_HOVER_MAP_ENTITY = 'REMOVE_HOVER_MAP_ENTITY'

/////////////////////////////////////////////
//                                         //
//              DRAW MAPS                  //
//                                         //
/////////////////////////////////////////////

export const FETCH_DRAW_DATA = 'FETCH_DRAW_DATA'
export const FETCH_DRAW_DATA_SUCCESS = 'FETCH_DRAW_DATA_SUCCESS'

export const UPDATE_DRAW_DATA = 'UPDATE_DRAW_DATA'
export const UPDATE_DRAW_DATA_SUCCESS = 'UPDATE_DRAW_DATA_SUCCESS'
export const CHECK_DRAW_DATA = 'CHECK_DRAW_DATA'
export const CHECK_DRAW_DATA_SUCCESS = 'CHECK_DRAW_DATA_SUCCESS'
export const UNCHECK_DRAW_DATA = 'UNCHECK_DRAW_DATA'
export const UNCHECK_DRAW_DATA_SUCCESS = 'UNCHECK_DRAW_DATA_SUCCESS'
export const REMOVE_ALL_DRAW_DATA = 'REMOVE_ALL_DRAW_DATA'
export const REMOVE_ALL_DRAW_DATA_SUCCESS = 'REMOVE_ALL_DRAW_DATA_SUCCESS'

export const FETCH_DRAW_DATA_ERROR = 'FETCH_DRAW_DATA_ERROR'
export const UPDATE_DRAW_DATA_ERROR = 'UPDATE_DRAW_DATA_ERROR'
export const CHECK_DRAW_DATA_ERROR = 'CHECK_DRAW_DATA_ERROR'
export const UNCHECK_DRAW_DATA_ERROR = 'UNCHECK_DRAW_DATA_ERROR'
export const REMOVE_ALL_DRAW_DATA_ERROR = 'REMOVE_ALL_DRAW_DATA_ERROR'

export const CLEAR_PROJECT_MAPS = 'CLEAR_PROJECT_MAPS'

export const RESET_CESIUM = 'RESET_CESIUM'
