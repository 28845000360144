import * as actionTypes from '../../constants/store/actionTypes/toolbar'

export function addPolylineEntity(payload) {
  return {
    type: actionTypes.ADD_POLYLINE_ENTITY,
    payload
  }
}

export function updateActiveShapePoints(payload) {
  return {
    type: actionTypes.UPDATE_ACTIVE_SHAPE_POINTS,
    payload
  }
}

export function addActivePolylineIndex(payload) {
  return {
    type: actionTypes.ADD_ACTIVE_POLYLINE_INDEX,
    payload
  }
}

export function updatePolylineHistories(payload) {
  return {
    type: actionTypes.UPDATE_POLYLINE_HISTORIES,
    payload
  }
}

export function setPointSelected(payload) {
  return {
    type: actionTypes.SET_POINT_SELECTED,
    payload
  }
}

export function removePointSelected(payload) {
  return {
    type: actionTypes.REMOVE_POINT_SELECTED,
    payload
  }
}

export function setActionSelect(payload) {
  return {
    type: actionTypes.SET_ACTION_SELECT,
    payload
  }
}

export function setActionDrawPolyline(payload) {
  return {
    type: actionTypes.SET_ACTION_DRAW_POLYLINE,
    payload
  }
}

export function drawPolyline(payload) {
  return {
    type: actionTypes.DRAW_POLYLINE,
    payload
  }
}

export function setDrawUndo(payload) {
  return {
    type: actionTypes.DRAW_UNDO,
    payload
  }
}

export function setDrawUndoSuccess(payload) {
  return {
    type: actionTypes.DRAW_UNDO_SUCCESS,
    payload
  }
}

export function setDrawUndoError(error) {
  return {
    type: actionTypes.DRAW_UNDO_ERROR,
    error
  }
}

export function setDrawRedo(payload) {
  return {
    type: actionTypes.DRAW_REDO,
    payload
  }
}

export function setDrawRedoSuccess(payload) {
  return {
    type: actionTypes.DRAW_REDO_SUCCESS,
    payload
  }
}

export function setDrawRedoError(error) {
  return {
    type: actionTypes.DRAW_REDO_ERROR,
    error
  }
}

export function setDrawEdit(payload) {
  return {
    type: actionTypes.DRAW_EDIT,
    payload
  }
}

export function setDrawSave(payload) {
  return {
    type: actionTypes.DRAW_SAVE,
    payload
  }
}

export function setDrawSaveSuccess(payload) {
  return {
    type: actionTypes.DRAW_SAVE_SUCCESS,
    payload
  }
}

export function setDrawSaveError(error) {
  return {
    type: actionTypes.DRAW_SAVE_ERROR,
    error
  }
}

export function setDrawDelete(payload) {
  return {
    type: actionTypes.DRAW_DELETE,
    payload
  }
}

export function setDrawDeleteSuccess(payload) {
  return {
    type: actionTypes.DRAW_DELETE_SUCCESS,
    payload
  }
}

export function setDrawDeleteError(error) {
  return {
    type: actionTypes.DRAW_DELETE_ERROR,
    error
  }
}

export function groupEntity(payload) {
  return {
    type: actionTypes.GROUP_ENTITY,
    payload
  }
}

export function ungroupEntity(payload) {
  return {
    type: actionTypes.UNGROUP_ENTITY,
    payload
  }
}

export function resetToolbar(payload) {
  return {
    type: actionTypes.RESET_TOOLBAR,
    payload
  }
}
