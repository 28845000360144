import { CESIUM_MAP_REFERENCES } from '../../apis/cesiumMap/mapConfig/cesiumMapReference'

export async function fetchMapData({
  category,
  subCategory,
  params,
  map,
  fetchProgress = () => {},
  fetchFinish = () => {},
  layerType
}) {
  for (let i = 0; i < CESIUM_MAP_REFERENCES.length; i++) {
    if (
      CESIUM_MAP_REFERENCES[i].category === category
      && CESIUM_MAP_REFERENCES[i].subCategory === subCategory
    ) {
      return await CESIUM_MAP_REFERENCES[i].fetch({
        params,
        map,
        layerType,
        fetchProgress,
        fetchFinish
      })
    }
  }

  return null
}
