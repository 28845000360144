import React, { useState, useEffect, useMemo } from 'react'

import { connect } from 'react-redux'

import * as Cesium from 'cesium'

import cesiumViewer from '../../../apis/cesiumViewer'

import {
  FlexRow
} from '../../BasicStyle/FlexWrapper'

import CesiumCoordinate from './CesiumCoordinate'
import CesiumScaller from './CesiumScaller'

import styled from 'styled-components'

const CesiumAttribution = styled.div`
  display: flex;
  position: absolute;
  flex-direction: row;
  padding: 0px 5px;
  background-color: rgba(32, 32, 32, 0.979);
  color: white;
  font-family: Abel;
  font-size: 0.6rem;
  z-index: 1000;
  transition: width 500ms, right 500ms;
`

const LegendContainer = styled(FlexRow)`
  justify-content: flex-end;
  height: 3em;
  align-items: center;
  overflow: hidden;
`

function CesiumLegend({
  legend,
  sitebar,
  chat,
  table,
  grade,
  sites
}) {
  const FULLSCREEN_WIDTH = 27

  const right = useMemo(() => {
    if (sitebar.toggle && !sitebar.minimize) {
      return Number(sitebar.width.slice(0, -2)) + FULLSCREEN_WIDTH
    } else if (chat.toggle && !chat.minimize) {
      return Number(chat.width.slice(0, -2)) + FULLSCREEN_WIDTH
    } else {
      return FULLSCREEN_WIDTH
    }
  }, [sitebar, chat])

  const [ bottom, setBottom ] = useState(null)

  let heading = null
  let pitch = null
  let height = null

  if (
    cesiumViewer.viewer
    && cesiumViewer.viewer.scene
  ) {
    heading = cesiumViewer.viewer.scene.camera.heading
    pitch = cesiumViewer.viewer.scene.camera.pitch
    height = Cesium.Cartographic.fromCartesian(cesiumViewer.viewer.scene.camera.position).height
  }

  const azimuth = heading
    ? `${
      Cesium.Math.toDegrees(heading).toFixed(2)
    }\u00B0 `
    : ''

  const plunge = pitch
    ? `${
      (Cesium.Math.toDegrees(pitch) * -1).toFixed(2)
    }\u00B0 `
    : ''

  const realHeight = getHeight(height)

  function getHeight(height) {
    let unit = 'm'
    if (height >= 1000) {
      unit = 'km'

      height /= 1000
    }

    if (!height) {
      return ''
    }

    return `${height.toFixed(1)} ${unit}`
  }

  useEffect(() => {
    if (document.getElementsByClassName('cesium-viewer-timelineContainer')[0]) {
      const timeline = document.getElementsByClassName('cesium-viewer-timelineContainer')[0]
      
      timeline.style.userSelect = 'none'
      timeline.style.position = 'absolute'
    }

    const fullscreen = document.getElementsByClassName('cesium-viewer-fullscreenContainer')[0]

    if (fullscreen) {
      fullscreen.style.userSelect = 'none'
      fullscreen.style.position = 'absolute'
    }
  }, [])

  function calculateBottom() {
    const GRID_HEIGHT = '28rem'
    const TABLE_RELATIVE_HEIGHT = 13.4

    const timeline = document.getElementsByClassName('cesium-viewer-timelineContainer')[0]
    const fullscreen = document.getElementsByClassName('cesium-viewer-fullscreenContainer')[0]

    if (fullscreen) {
      fullscreen.style.transition = 'right 500ms, width 500ms'
    }

    if (table.status) {
      if (timeline) {
        timeline.style.bottom = `calc(${3 + table.bottom + table.height}em + ${table.deltaHeight}px)`
      }

      if (fullscreen) {
        fullscreen.style.bottom = `calc(${0.1 + table.bottom + table.height}em + ${table.deltaHeight}px)`
      }
      
      return `calc(${TABLE_RELATIVE_HEIGHT + table.bottom + table.height}em + ${table.deltaHeight}px)`
    } else if (grade.status && !grade.minimize) {
      if (timeline) {
        timeline.style.bottom = GRID_HEIGHT
      }

      if (fullscreen) {
        fullscreen.style.bottom = GRID_HEIGHT
      }
      
      return GRID_HEIGHT
    } else {
      if (timeline) {
        timeline.style.bottom = '0em'
      }

      if (fullscreen) {
        fullscreen.style.bottom = '0em'
      }

      return 0
    }
  }

  useEffect(() => {
    setBottom(calculateBottom())
  }, [table, grade])

  function setFullScreenRight() {
    const fullscreen = document.getElementsByClassName('cesium-viewer-fullscreenContainer')[0]

    if (!fullscreen) {
      return setTimeout(setFullScreenRight, 100)
    }

    fullscreen.style.right = Number(right) - FULLSCREEN_WIDTH + 'px'
  }

  useEffect(() => {
    setFullScreenRight()
  }, [right, legend])

  const commodityName = (str) => {
    if(str==='copper_gold_silver'){
      return 'Gold Copper Silver Molybdenum'
    } else {
      function humanize(str) {
        var i, frags = str.split('_');
        for (i=0; i<frags.length; i++) {
          frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(' ');
      }
      return humanize(str)
    }
  }

  return (
    cesiumViewer.viewer
    &&
      <CesiumAttribution
        style={{
          right,
          bottom
        }}
      >
        <FlexRow>
          {
            (legend.coordinate || legend.scale)
            && <>
              <CesiumScaller scale={legend.scale} />
              <CesiumCoordinate coordinate={legend.coordinate} />
            </>
          }
          <LegendContainer>
              <span style={{marginRight: '0.5em', marginLeft: '0.5em'}}>
                Height: {realHeight}
                <span style={{marginRight: '0.5em', marginLeft: '0.5em'}}>
                  Azimuth: {azimuth}
                </span>
                Plunge: {plunge}
              </span>
          {
            (sites.commodity)
            && <>
              <span style={{marginRight: '0.5em'}}>
                <span style ={{ color:'#D29D2B'}}>
                Commodity(s):   
                </span>
                <span style={{color:'#FBD148'}}>
                {' '+ commodityName(sites.commodity)}
                </span>
              </span>
            </>
          }
          </LegendContainer>
        </FlexRow>
      </CesiumAttribution>
  )
}

function mapStateToProps(state) {
  return {
    sitebar: state.sitebar,
    chat: state.chat,
    table: state.table,
    grade: state.grade,
    sites: state.sites
  }
}

export default connect(mapStateToProps, null)(CesiumLegend)
