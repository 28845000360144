import React from 'react'

import ListContent from './ListContent'

/**
 * This component will create non-numerical content group
 * @param objLegend is current selected objLegend to render
 */
function DefaultContent({ objLegend }) {
  const DEFAULT_POS_X = 370 // default mean sidebarState is openned
  const DEFAULT_POS_Y = 88 // default mean rankbar is opened

  return <ListContent
    defaultPosX={DEFAULT_POS_X}
    defaultPosY={DEFAULT_POS_Y}
    direction="column"
    objLegend={objLegend}
  />
}

export default DefaultContent
