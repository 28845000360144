import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'

import {
  toggleTablebar
} from '../../../store/actions/tablebar'

import CloseBottombar from '../../Buttons/CloseBottombar'

import TableContainer from './Container'
import Resizer from './Resizer'
import TableTool from './Tool'
import Table from './Table'

/**
 * This component will create table in the bottom of the windows
 * @param {Object} table is the global state of tablebar
 * @param {Object} sitebar to minimize when spesific sitebar is opened
 * @param {Object} sidebarState to minimize when spesific sidebar layer is opened
 * @param {Function} setDeltaHeight to set height of this tablebar
 * @param {Function} toggleTablebar - method to toggle off table bar
 */
function Tablebar({
  toggleTablebar,
  tableProperties
}) {
  const [ headerProp, setHeaderProp ] = useState([])
  
  const [ checkboxValue, setCheckboxValue ] = useState({})
  const [ checkedAll, setCheckedAll ] = useState(true)
 
  const [ sortableItems, setSortableItems ] = useState([])
  const [ tableConfig, setTableConfig ] = useState({
    width: '100%',
    notShowList: [],
    focusList: [],
    sortBy: {},
    headers: [],
    rowWidth: {
      default: 4,
      modified: []
    },
    resizeRow: null,
    initialResizeRow: null
  })
  const [ listSort, setListSort ] = useState([])
  const [ searchValue, setSearchValue ] = useState('')

  useEffect(() =>{
    if (searchValue) {
      let filter= tableProperties.filter(row => {
        let key=Object.keys(row)
        return key.some((column)=>{
          if(row[column]){
            return row[column].toString().toLowerCase().replace(/ |_/g,'').indexOf(searchValue) > -1
          }
          } 
        )
      })
      setSortableItems(filter)
    } else {
      setSortableItems(tableProperties)
    }
  }, [searchValue])

  return (
    <TableContainer>
      <CloseBottombar onClickHandler={toggleTablebar} />
      <Resizer />
      <TableTool
        headerProp={headerProp}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        checkedAll={checkedAll}
        setCheckedAll={setCheckedAll}
        checkboxValue={checkboxValue}
        setCheckboxValue={setCheckboxValue}
      />
      <Table
        setHeaderProp={setHeaderProp}
        setCheckboxValue={setCheckboxValue}
        searchValue={searchValue}
        tableConfig={tableConfig}
        setTableConfig={setTableConfig}
        listSort={listSort}
        setListSort={setListSort}
        sortableItems={sortableItems}
        setSortableItems={setSortableItems}
        tableProperties={tableProperties}
      />
    </TableContainer>
  )
}

function mapStateToProps({ table }) {
  return {
    tableProperties: table.properties,
  }
}

const mapDispatchToProps = {
  toggleTablebar
}

export default connect(mapStateToProps, mapDispatchToProps)(Tablebar)
