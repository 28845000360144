import React, { useEffect, useState} from 'react';
import { connect } from 'react-redux';

import { toggleSitebar } from '../../store/actions/sitebar'
import { flyTo } from '../../apis/cesium'
import {
    toggleSidebarButton
} from '../../store/actions/sidebarToggle'

import Loading from '../../components/Loading';
import Navbar from '../../components/Menubars/Navbar';
import Sidebar from '../../components/Menubars/Sidebar';

import FrontView from './FrontView';
import ImportSection from './ImportSection';
import { 
    changeData,
    changeImportType, 
    changeProjectName, 
    changeType, 
    changeValuesHeader, 
    setUTMZone, 
    toggleAnalysis, 
    togglePreviewTable, 
} from '../../store/actions/database';
import { BackButton, ButtonImport, ContainerImageButton, TextCenter } from './partials';
import Analytic from '../../components/Analytic';
import backIcon from "../../images/Icons/back2d.svg";
import ThreeDModel from '../../components/3DModel';


function Database({
    toggleSidebarButton,
    sites,
    importType,
    toggleAnalysis,
    setTogglePreviewTable,
    setToggleAnalysis,
    setImportType,
    setType,
    setProjectName,
    data,
    setData,
    type,
    setValuesHeader,
    setUTMZone
}) {
    const [modelWithMap,setModelWithMap] = useState(false)

    const handleClickPreview = () => {
        setTogglePreviewTable(true)
    }

    const handleResetData = (deleteThis) => {
        if(deleteThis){
            setImportType(null)
            setType(null)
            setProjectName("proj1")
            let objData={...data}
            objData.data=[]
            objData.name=[]
            objData.header=[]
            objData.elements=[]
            objData.headerOfLocation=[]
            setData(objData)
            setValuesHeader({})
            let utm = {
                active: false,
                number:1,
                region:'n'
            }
            setUTMZone(utm)
        }
    }

    const handleClickBack = () => {
        setToggleAnalysis(false)
    }
    const handleClickBackFirst = () => {
        let deleteThis = confirm("This action will delete all data on drag n drop. Want to start all over again?")
        handleResetData(deleteThis)
    }

    useEffect(() => {
        toggleSidebarButton({
            buttonName: 'database',
            status: true,
            animate: true
            })
        if (sites.selected.site) {
            flyTo({
                area: sites.selected.site,
                cb: function () {
                toggleSitebar({
                    toggle: true,
                    minimize: false
                })
                }
            })
        }
    }, [])
    
    const importSelect = () => {
        if( importType === null ){
            return <FrontView/> 
        } else {
            if(toggleAnalysis){
                return <>
                    <div style ={{position:'absolute',zIndex:2}}>
                        <ContainerImageButton
                            onClick={()=>handleClickBack()}
                            title='Back'
                        >
                            <BackButton
                                src={backIcon}
                                alt="back"
                                color="white"
                                marginBottom='0.7em'
                                height='20px'
                            />
                        </ContainerImageButton>
                    </div>
                    {
                        type==="Drillhole"?
                        // <></>
                        <ThreeDModel 
                            modelWithMap={modelWithMap}
                        />
                        :
                        <Analytic importData={data}/>
                    }
                </>
            } else {
                return <>
                    <ImportSection
                        modelWithMap={modelWithMap}
                        setModelWithMap={setModelWithMap}
                    />
                    <TextCenter top={'70%'}>
                        <ButtonImport onClick={handleClickBackFirst}>
                            Back
                        </ButtonImport>
                        {
                            data.data.length!==0 &&
                            <ButtonImport onClick={handleClickPreview} left={'1em'}>
                                Preview
                            </ButtonImport>
                        }
                    </TextCenter> 
                </>
            }
        }
    }

    return <>
        <Sidebar className="sidebar-block" />
        <div className="content-block">
            <Navbar className="row" />
            <div className="row max-height">
                <div 
                    className="row max-height max-width"
                    style={{backgroundColor:'black'}}
                >
                    {importSelect()}
                </div>
            </div>
        </div> 
        <Loading />
    </>
}

function mapStateToProps(state) {
    return {
        sites: state.sites,
        importType: state.database.importType,
        toggleAnalysis: state.database.toggleAnalysis,
        togglePreviewTable: state.database.togglePreviewTable,
        data: state.database.dataImport,
        valuesHeader: state.database.valuesHeader,
        type: state.database.type
    }
}

const mapDispatchToProps = {
    toggleSidebarButton,
    toggleSitebar,
    setTogglePreviewTable: togglePreviewTable,
    setToggleAnalysis: toggleAnalysis,
    setImportType: changeImportType,
    setType: changeType,
    setProjectName: changeProjectName,
    setData: changeData,
    setValuesHeader:changeValuesHeader,
    setUTMZone: setUTMZone
}

export default connect(mapStateToProps, mapDispatchToProps)(Database)