import { DANGER_RED } from '../../shares/ColorTemplate'

import styled from 'styled-components'

export const WarningIconWrapper = styled.div`
  svg {
    fill: ${DANGER_RED.default};
    width: ${props => props.width};
  }

  &:hover {
    fill: ${DANGER_RED.hover};
  }
`