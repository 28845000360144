import React from 'react'

import styled from 'styled-components'
import getHeightUnit from './helpers/getHeightUnit'

const Wrapper = styled.div`
  background: ${props => props.color};
  min-width: ${props => props.size};
  min-height: ${props => props.height};
  border: 1px solid ${props => props.color};
`

/**
 * This component will create polyline component of legend symbol
 * @param {String} color is the color selected
 * @param {String} size of the selected symbol
 */
function Polyline({
  color,
  size
}) {
  const { height, unit } = getHeightUnit(size)

  return <Wrapper color={color} size={size} height={height / 16 + unit} />
}

export default Polyline
