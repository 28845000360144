import * as d3 from 'd3';

const correlationCalculate=(x,y)=>{
    let lenX = x.length
    let meanX = d3.mean([...x])
    let meanY = d3.mean(y)
    let sdX = d3.deviation([...x])
    let sdY = d3.deviation(y)
    let mul=[]
    for (let i=0;i<lenX;i++){
        let minusX = x[i]-meanX
        let minusY = y[i]-meanY
        let multip= minusX * minusY 
        mul[i]=multip
    }    
    let sumMul= d3.sum(mul)
    let corre = (sumMul/(sdX*sdY))/(lenX-1)
    return corre
}

export const correlationValue=({...props})=>{
    let {
        values,
        dataSample
    } = props;
    const dataCorrelation = [];
    values.forEach(e1=> {
        values.forEach(e2=>{
            let data1=[];
            let data2=[];
            for (let i=0;i<dataSample.length;i++){
                if(dataSample[i][e1]!==undefined && dataSample[i][e2]!==undefined){
                    data1.push(dataSample[i][e1])
                    data2.push(dataSample[i][e2])
                }
            }
            const correlation=correlationCalculate(data1,data2).toFixed(2);

            dataCorrelation.push({
                "x":e1,
                "y":e2,
                "value":correlation
            })
        })
    });
    return dataCorrelation;
};

export const transposeValue=(values)=>{
    const valuesTranspose=[]; 
    for(let i = values.length-1; i >= 0; i--){
        valuesTranspose.push(values[i])
    };
    return valuesTranspose
};

export const filterNaNValue=({...props})=>{
    let {
        values,
        data
    } = props;
    const seriesFilter=[]; 
    values.forEach(e1=> {
        if(data[e1].filter(item=>item!==null && item!==0).length!==0 && data[e1].every((val,i,arr)=> val === arr[0])===false){
            seriesFilter.push(e1);
        }
    });
    return seriesFilter
};

