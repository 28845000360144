import React, { useState } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux'
import { optionsType } from '../../../constants/Database/optionsDropDown';
import { 
    customSingleValue, 
    customStyles, 
    IconOption 
} from '../helpers';
import { 
    ButtonImport, 
    ButtonLink, 
    TextCenter 
} from '../partials';
import { 
    changeImportType, 
    changeType, 
    changeProjectName 
} from '../../../store/actions/database'


let FrontView = ({...props}) => {
    let {
        type,
        projectName,
        setType,
        setImportType,
        setProjectName
    } = props;
    const [typeSelect,setTypeSelect]=useState(null)
   
    const handleClick = () => {
        if (typeSelect.value==='drillhole'){
            setImportType('importDrilling')
        } else {
            setImportType('importSurface')
        }
    }

    const handleEnter = (event) => {
        if( event.key.toLowerCase() === "enter"){
            handleClick()
            event.preventDefault()
        }
    }

    return <>
    {/* <div style={{'position': 'absolute', 'right': '1%', 'top': '7%'}}>
        <ButtonLink name='List of uploaded files'>
            List
        </ButtonLink>
    </div> */}
    <TextCenter top={'20%'} style={{fontSize:'50px'}}>
        Import Files
        {/* Upload Files */}
        {/* Upload Your Files to Geoprosp Server */}
        <hr style={{marginTop:'0px', color:'goldenrod'}}/>
    </TextCenter>
    <div style={{border:'1px solid white', width:'50%', height:'50%', margin:'auto', padding:'10px', minWidth:'400px' }}>
        <TextCenter   style={{ zIndex:2}}>
            Please Select File Type
            <Select
                styles={customStyles}
                value={typeSelect}
                options={optionsType}
                onChange={(value)=>{
                    setTypeSelect(value)
                    setType(value.label)
                }}
                isSearchable={false}
                placeholder={'Select Type'}
                components={{SingleValue: customSingleValue, Option: IconOption}}
            />
        </TextCenter>
        <TextCenter top={'51%'}  style={{fontSize:'18px', zIndex:1}}>
        {
            type!==null &&
            <span>
                Project Name:
                <input
                    type="text"
                    value={projectName}
                    onChange={e=>{setProjectName(e.target.value)}}
                    onKeyDown={e=>{handleEnter(e)}}
                    maxLength="10"
                    style={{ width: '180px', marginLeft: '10px'}}
                />
            </span>
        }
        </TextCenter>
        <TextCenter top={'68%'} style={{ zIndex:1}}>
            {
                type!==null &&
                <ButtonImport onClick={handleClick}>
                    Import Data
                </ButtonImport>
            }
        </TextCenter>
    </div>
    </>
}

function mapStateToProps(state) {
    return {
      type: state.database.type,
      projectName: state.database.projectName
    }
}
  
const mapDispatchToProps = {
    setImportType: changeImportType,
    setType: changeType,
    setProjectName: changeProjectName
}

export default connect(mapStateToProps, mapDispatchToProps)(FrontView)