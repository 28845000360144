import React, { useEffect, useState } from 'react'

import {
  ReactComponent as SearchIcon
} from '../../../images/Icons/search.svg'
import {
  ReactComponent as RemoveIcon
} from '../../../images/Icons/remove.svg'

import './DefaultSearch.css'
import { 
  SearchContainer, 
  SearchInput, 
  SearchInputContainer 
} from './partials'

function DefaultSearch({
  active,
  searchProcess,
  stopSearch,
  setValue = '',
  backgroundColor='rgb(17, 17, 17)',
  borderColor='rgb(236, 236, 236)',
  color='white',
  iconColor='white',
  borderWidth='2px',
  fontSize='1em',
  height = '30px',
  placeholder = 'Search ...'
}) {
  const [ textDetail, setTextSearch ] = useState({
    value: '',
    timeout: null,
    focus: false,
    loading: false,
    error: null
  })

  const [ paddingIcon, setPaddingIcon ] = useState('3px 10px')

  useEffect(() =>{
    if(fontSize){
      if(Number(fontSize.replace(/\D/g, ""))<1){
        setPaddingIcon('0px 10px')
      } else {
        setPaddingIcon('3px 10px')
      }
    } 
  },[fontSize])

  async function search(
    key = null,
    errorSearch = true
  ) {
    const keyword = key

    if (keyword) {
      const data = await searchProcess(keyword)

      if (data && !data.status && errorSearch) {
        setTextSearch({
          ...textDetail,
          value: keyword,
          error: `No result found for ${keyword}`
        })
      }
    } else if (typeof stopSearch === 'function') {
      stopSearch()
    }
  }

  function typingSearch(event) {
    if (active) {
      if (textDetail.timeout) {
        clearTimeout(textDetail.timeout)
      }
  
      setTextSearch({
        ...textDetail,
        value: event.target.value,
        timeout: setTimeout(() => {
          search(event.target.value, false)
        }, 750),
        error: null
      })
    }
  }

  function eraseText() {
    setTextSearch({
      ...textDetail,
      value: '',
      error: null
    })

    if (typeof stopSearch === 'function') {
      stopSearch()
    }
  }

  useEffect(() => {
    setTextSearch({
      ...textDetail,
      value: setValue
    })
  }, [setValue])

  useEffect(() => {
    if (!active) {
      eraseText()
    }
  }, [active])

  useEffect(() => {
    if (!textDetail.value) {
      eraseText()
    }
  }, [textDetail.value])

  return <>
    <SearchContainer>
      <SearchInputContainer>
        <SearchIcon
          style={{
            fill: textDetail.focus ? 'url(#gold-gradient) goldenrod' : iconColor,
            padding: paddingIcon ? paddingIcon : '3px 10px'
          }}
          className="default-search-icon-search"
        />
        <SearchInput
          type="search"
          placeholder={placeholder}
          spellCheck="false"
          backgroundColor={backgroundColor}
          color={color}
          borderColor={borderColor}
          borderWidth={borderWidth}
          height={height}
          fontSize={fontSize}
          value={textDetail.value}
          onChange={(event) => typingSearch(event)}
          onFocus={
            active
              ? () => setTextSearch({ ...textDetail, focus: true })
              : null
          }
          onBlur={
            active
              ? () => setTextSearch({ ...textDetail, focus: false })
              : null
          }
          onKeyPress={
            (event) => {
              if (event.key === 'Enter') {
                if (textDetail.timeout) {
                  clearTimeout(textDetail.timeout)
                }
                
                search(textDetail.value)
              }
            }
          }
        />
        {
          textDetail.value
            ? <RemoveIcon
                fill="#ff6666"
                stroke="#ff6666"
                className="default-search-icon-remove"
                style={{
                  padding: paddingIcon ? paddingIcon : '3px 10px'
                }}
                onClick={eraseText}
              />
            : <></>
        }
      </SearchInputContainer>
      {
        textDetail.error
          ? <p className="default-search-error">{textDetail.error}</p>
          : null
      }
    </SearchContainer>
  </>
}

export default DefaultSearch
