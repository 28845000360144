import React from 'react'

import styled from 'styled-components'

import InputForm from './InputForm'

const WrapperForm = styled.div`
  font-family: Abel;
  max-height: 13em;
  overflow: auto;
`

/**
 * This component will create Form
 * @param {Array} listForm - array from content
 * @param {Function} changeField - method used to change the field of form
 * @param {Object} recommendation - available recommendation 
 */
function Form({
  listForm,
  changeField,
  recommendation
}) {
  return <WrapperForm>
    {
      listForm.map((form) => {
        return <InputForm
          key={form.title}
          Icon={form.Icon}
          showTitle={form.showTitle}
          value={form.value}
          type={form.type}
          dataType={form.dataType}
          required={form.required}
          changeField={changeField}
          fullRecommendation={recommendation[form.title] || []}
        />
      })
    }
  </WrapperForm>
}

export default Form
