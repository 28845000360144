import React from 'react'

import axios from 'axios'

import { connect } from 'react-redux'

import {
  changeRankingPreferences
} from '../../../../store/actions/sites'

const OPTION_REFERENCES = {
  status: [
    'total',
    'technical',
    'non-technical'
  ],
  binSize: [
    16000,
    8000,
    4000,
    2000
  ]
}

import {
  OptionbarContainer,
  TextOption
} from './OptionStyle'

function OptionMenubar({
  optionFilter,
  setOptionFilter,
  ranking,
  changeRankingPreferences
}) {
  const options = OPTION_REFERENCES[optionFilter.status]

  function onClickHandler(value) {
    changeRankingPreferences({
      ...ranking,
      [optionFilter.status]: value,
      cancelToken: axios.CancelToken.source()
    })

    setOptionFilter({
      active: false,
      status: ''
    })
  }

  if (!options) {
    return null
  }

  return <OptionbarContainer>
    {
      options.map((option) => {
        if (optionFilter.value === option) {
          return null
        }

        let text = option

        if (option && option.length) {
          text = option[0].toUpperCase() + option.slice(1)
        }

        return <TextOption
          key={option}
          onClick={() => {
            onClickHandler(option)
          }}
        >
          {text}
        </TextOption>
      })
    }
  </OptionbarContainer>
}

function mapStateToProps({ sites, sidebarState }) {
  return {
    ranking: sites.ranking
  }
}

const mapDispatchToProps = {
  changeRankingPreferences
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionMenubar)
