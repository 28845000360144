import React, { useEffect, useState, useRef } from 'react'

import ColorPickerDropdown from './ColorPickerDropdown'

import {
  generateColor,
  generateColorGradient
} from '../../../../helpers'

import styled from 'styled-components'

const GridColorWrapper = styled.div.attrs((props) => {
  if (props.gradient) {
    return {
      style: {
        backgroundImage: `linear-gradient(to right, ${props.gradient})`,
        cursor: props['can-be-click'] && 'pointer'
      }
    }
  } else {
    return {
      style: {
        backgroundColor: props.color,
        cursor: props['can-be-click'] && 'pointer'
      }
    }
  }
})`
  width: 1.5em;
  height: 0.5em;
  margin-left: 0em;
  margin-right: 0em;
  border-radius: 2px;
`

/**
 * A component button to open color scheme layer
 * @param map is map the map to change the color button
 * @param exceptHideRefs is the event that will not trigger toggle off this component children
 */
function ColorSchemeButton({
  map,
  exceptHideRefs
}) {
  const [ groupEntities, setGroupEntities ] = useState([])

  useEffect(() => {
    function eventHandler() {
      setGroupEntities(map.cesiumLayer.groupEntities)
    }

    map.cesiumLayer.subscribeEvent('groupEntities', eventHandler)

    return () => {
      map.cesiumLayer.unsubscribeEvent('groupEntities', eventHandler)
    }
  }, [])

  const [
    colorGradient,
    setColorGradient
  ] = useState(null)

  const [
    colorLinear,
    setColorLinear
  ] = useState(null)

  const [toggle, setToggle] = useState(false)
  const ColorButtonRef = useRef(null)

  useEffect(() => {
    setColorGradient(defaultGradientColor())
    setColorLinear(defaultColorLinear())

    return () => {
      setColorGradient(null)
      setColorLinear(null)
    }
  }, [groupEntities])

  function onClickHandler() {
    setToggle(!toggle)
  }

  function defaultGradientColor() {
    if (!map.colorScale) {
      return null
    }

    return generateColorGradient({
      colorScale: map.colorScale,
      inverse: map.cesiumLayer.inverse
    })
  }

  function defaultColorLinear() {
    return generateColor({
      defaultColor: {
        red: 255,
        green: 255,
        blue: 255
      },
      alpha: 1
    })
  }

  /**
   * This function will trigger for changing the color
   * @param scaleChrome is selected gradient color
   */
  function selectColorHandler(scaleChrome) {
    map.colorScale = scaleChrome
    
    map.cesiumLayer.setTemplate(null)

    setColorGradient(defaultGradientColor())
  }

  return <>
    <GridColorWrapper
      gradient={colorGradient}
      color={colorLinear}
      onClick={onClickHandler}
      can-be-click={true}
      ref={ColorButtonRef}
    />
    {
      toggle
        && <ColorPickerDropdown
          map={map}
          hideColorPicker={onClickHandler}
          exceptHideRefs={[ColorButtonRef].concat(exceptHideRefs)}
          selectColorHandler={selectColorHandler}
        />
    }
  </>
}

export default ColorSchemeButton
