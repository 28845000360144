import React from 'react';

function Pagination({...props}) {
    let {
        pageIndex,
        setPageIndex,
        pageOptions,
        pageSize,
        setPageSize,
        fontColor='white',
        fontSize='1em',
        fontFamily='abel'
    } =props;
    let gotoFirstPage=()=>{
        document.getElementById('inputPage').value=1
        setPageIndex(0)
    }
    let gotoPreviousPage=()=>{
        if(pageIndex-1<0){
            document.getElementById('inputPage').value=1
            setPageIndex(0)
        }else{
            document.getElementById('inputPage').value=pageIndex
            setPageIndex(pageIndex-1)
        }
    }
    let gotoNextPage=()=>{
        if(pageIndex+1>pageOptions.length-1){
            document.getElementById('inputPage').value=pageOptions.length
            setPageIndex(pageOptions.length-1)
        } else {
            document.getElementById('inputPage').value=pageIndex+2
            setPageIndex(pageIndex+1)
        }
    }
    let gotoLastPage=()=>{
        document.getElementById('inputPage').value=pageOptions.length
        setPageIndex(pageOptions.length-1)
    }
    let gotoPage=(val)=>{
        if(val<0){
            setPageIndex(0)
        } else if(val>pageOptions.length-1){
            setPageIndex(pageOptions.length-1)
        } else {
            setPageIndex(val)
        }
    }
    return (
        <>
        <button 
            onClick={() => gotoFirstPage()}
            style={{fontFamily:fontFamily, fontSize:fontSize, cursor:'pointer'}}
        >
            {'<<'}
        </button>{' '}
        <button 
            onClick={() => gotoPreviousPage()}
            style={{fontFamily:fontFamily, fontSize:fontSize, cursor:'pointer'}}
        >
            {'<'}
        </button>{' '}
        <button 
            onClick={() => gotoNextPage()}
            style={{fontFamily:fontFamily, fontSize:fontSize, cursor:'pointer'}}
        >
            {'>'}
        </button>{' '}
        <button 
            onClick={() => gotoLastPage()}
            style={{fontFamily:fontFamily, fontSize:fontSize, cursor:'pointer'}}
        >
            {'>>'}
        </button>{' '}
        <span style={{color:fontColor}}>
            Page{' '}
            <strong>
            {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
        </span>
        <span style={{color:fontColor}}>
            | Go to page:{' '}
            <input
            id='inputPage'
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
            }}
            min={1}
            max={pageOptions.length}
            style={{ width: '100px', fontFamily:fontFamily, fontSize:fontSize }}
            />
        </span>{' '}
        <select
            value={pageSize}
            onChange={e => {
            setPageSize(Number(e.target.value))
            }}
            style={{fontFamily:fontFamily, fontSize:fontSize, cursor:'pointer'}}
        >
            {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
                Show {pageSize}
            </option>
            ))}
        </select>
            
        </>
    );
}

export default Pagination;