/**
 * Turn on all event screenSpaceCameraController default
 * @param {cesiumViewer} viewer the cesium viewer
 */
export function activateEventDefaultCesium(viewer) {
  viewer.scene.screenSpaceCameraController.enableRotate = true
  viewer.scene.screenSpaceCameraController.enabletransform = true
  viewer.scene.screenSpaceCameraController.enableZoom = true
  viewer.scene.screenSpaceCameraController.enableTilt = true
  viewer.scene.screenSpaceCameraController.enableLook = true
}

/**
 * Turn off all event screenSpaceCameraController default
 * @param {cesiumViewer} viewer the cesium viewer
 */
export function disactivateEventDefaultCesium(viewer) {
  viewer.scene.screenSpaceCameraController.enableRotate = false
  viewer.scene.screenSpaceCameraController.enabletransform = false
  viewer.scene.screenSpaceCameraController.enableZoom = false
  viewer.scene.screenSpaceCameraController.enableTilt = false
  viewer.scene.screenSpaceCameraController.enableLook = false
}
