import * as Cesium from 'cesium'

import axios from 'axios'

import store from '../store'

import {
  setBoundingBox
} from '../store/actions/camera'

import {
  fetchProspectAreaSites
} from '../store/actions/sites'

import CesiumNavigation from 'cesium-navigation-es6'

import {
  generateFunctionHandler
} from './cesium'

import {
  sectionMouseDownHandler,
  sectionMouseMoveHandler,
  sectionMouseUpHandler
} from './cesiumSection'

import {
  sectionSmartSelectionMouseDownHandler,
  sectionSmartSelectionMouseMoveHandler,
  sectionSmartSelectionMouseUpHandler
} from './cesiumSmartSelection'

import {
  cesiumMineralOccurrences,
  moveStopMethod
} from './cesiumMineralOccurrencesEditor'

import {
  checkEntitiesInCesium
} from './cesiumHandler/legend'

let timeoutSmartView = null
let cancelToken = axios.CancelToken.source()
let timeoutGetBox = null

const cesiumViewer = {
  viewer: null,
  viewModel: {
    translucencyEnabled: true,
    fadeByDistance: true,
    showVectorData: false,
    alpha: 1,
    show: true
  },
  lightModel: {
    heading: null,
    positionWC: null
  },
  tooltip: null,
  sectionStatus: {
    selection: true,
    crossSection: false,
    blockSection: false,
    sliceSection: false,
    slicePick: false,
    sectionBox: null,
    slicePolygon: [],
    sectionEditor: [],
    slicingIndex: -1,
    holdClick: false,
    crossLine: null,
    sliceLine: null,
    linePosition: [],
    blockRectangle: null,
    rectangle: null,
    dragMovement: {
      start: null,
      end: null,
      bottom: -2000,
      top: 2000,
      crossWidth: {
        left: 10,
        right: 10
      }
    },
    sliceMovement: {
      start: null,
      end: null,
      pick: null,
      crossWidth: {
        left: 10,
        right: 10
      }
    },
    transform: {
      direction: '',
      entity: null,
      click: 0,
      reference: ''
    },
    cartesianPoints: [],
    sliceCartesianPoints: [],
    pickPolygon: null,
    // handle double click for translate the section
    timer: null
  },
  fullSizeBox: null,
  testDistance: 0,
  smartSelectionStatus: {
    start: false
  },
  legendToggle: false,
  normalDisableDepthTestDistance: null,
  mineralOccurrences: {
    editorCesiumState: {},
    setEditorCesiumState: () => {},
    holdMove: false
  },
  getTestDistance() {
    if (this.testDistance > 0) {
      return this.testDistance * 1.5
    } else {
      return 0
    }
  },
  getHeightMultiplier() {
    if (this.testDistance < 150) {
      return 1
    } else if (this.testDistance > 1e7) {
      return 10000
    } else {
      return this.testDistance / 10000
    }
  },
  getCartesian3FromCartesian2(cartesian2) {
    const ray = cesiumViewer.viewer.scene.camera.getPickRay(
      cartesian2
    )

    if (!ray.origin && !ray.direction) {
      return null
    }

    const cartesian = cesiumViewer.viewer.scene.globe
      .pick(ray, cesiumViewer.viewer.scene)

    return cartesian
  },
  preventGenerateCameraFullBox() {
    if (
      cesiumViewer.sectionStatus.blockSection
      || cesiumViewer.sectionStatus.crossSection
      || cesiumViewer.sectionStatus.sliceSection
    ) {
      return true
    }

    if (this.legendToggle) {
      return false
    }

    const smartView = store.getState().camera.smartView

    if (smartView) {
      return false
    }

    return true
  },
  generateCameraFullBox() {
    if (cesiumViewer.preventGenerateCameraFullBox()) {
      return
    }

    if (timeoutGetBox) {
      clearTimeout(timeoutGetBox)
    }

    timeoutGetBox = setTimeout(() => {
      timeoutGetBox = null

      if (!cesiumViewer.viewer) {
        return
      }

      let { x, y, width, height } = cesiumViewer.viewer.canvas.getBoundingClientRect()

      const MARGIN_X = 50
      const MARGIN_Y = 52

      x -= MARGIN_X
      y -= MARGIN_Y
      
      let cartesian2NW = new Cesium.Cartesian2(x, y)
      let cartesian2NE = new Cesium.Cartesian2(width - x, y)
      let cartesian2SW = new Cesium.Cartesian2(x, height - y)
      let cartesian2SE = new Cesium.Cartesian2(width - x, height - y)

      let cartesian3NW = cesiumViewer.getCartesian3FromCartesian2(cartesian2NW)
      let cartesian3NE = cesiumViewer.getCartesian3FromCartesian2(cartesian2NE)
      let cartesian3SW = cesiumViewer.getCartesian3FromCartesian2(cartesian2SW)
      let cartesian3SE = cesiumViewer.getCartesian3FromCartesian2(cartesian2SE)

      let i = 0
      while (!cartesian3NW && i < 50) {
        cartesian2NW = new Cesium.Cartesian2(x + i * (width - x) / 50, y + i * (height - y) / 50)
        cartesian3NW = cesiumViewer.getCartesian3FromCartesian2(cartesian2NW)
        i += 1
      }

      i = 0
      while (!cartesian3NE && i < 50) {
        cartesian2NE = new Cesium.Cartesian2(width - x - i * (width - x) / 50, y + i * (height - y) / 50)
        cartesian3NE = cesiumViewer.getCartesian3FromCartesian2(cartesian2NE)
        i += 1
      }

      i = 0
      while (!cartesian3SW && i < 50) {
        cartesian2SW = new Cesium.Cartesian2(x + i * (width - x) / 50, height + y - i * height / 50)
        cartesian3SW = cesiumViewer.getCartesian3FromCartesian2(cartesian2SW)
        i += 1
      }

      i = 0
      while (!cartesian3SE && i < 50) {
        cartesian2SE = new Cesium.Cartesian2(width - x - i * (width - x) / 50, height + y - i * height / 50)
        cartesian3SE = cesiumViewer.getCartesian3FromCartesian2(cartesian2SE)
        i += 1
      }

      if (!cartesian3NW || !cartesian3NE || !cartesian3SW || !cartesian3SE) {
        this.fullSizeBox = null

        setTimeout(() => {
          cesiumViewer.generateCameraFullBox()
        }, 1000)
      } else {
        const cartographicNW = Cesium.Cartographic.fromCartesian(cartesian3NW)
        const cartographicNE = Cesium.Cartographic.fromCartesian(cartesian3NE)
        const cartographicSE = Cesium.Cartographic.fromCartesian(cartesian3SE)
        const cartographicSW = Cesium.Cartographic.fromCartesian(cartesian3SW)

        const polygon = [
          [Cesium.Math.toDegrees(cartographicNW.longitude), Cesium.Math.toDegrees(cartographicNW.latitude)],
          [Cesium.Math.toDegrees(cartographicNE.longitude), Cesium.Math.toDegrees(cartographicNE.latitude)],
          [Cesium.Math.toDegrees(cartographicSE.longitude), Cesium.Math.toDegrees(cartographicSE.latitude)],
          [Cesium.Math.toDegrees(cartographicSW.longitude), Cesium.Math.toDegrees(cartographicSW.latitude)],
          [Cesium.Math.toDegrees(cartographicNW.longitude), Cesium.Math.toDegrees(cartographicNW.latitude)]
        ]

        this.fullSizeBox = {
          minLongitude: Cesium.Math.toDegrees(cartographicNW.longitude),
          maxLatitude: Cesium.Math.toDegrees(cartographicNW.latitude),
          maxLongitude: Cesium.Math.toDegrees(cartographicSE.longitude),
          minLatitude: Cesium.Math.toDegrees(cartographicSE.latitude),
          polygon
        }
      }
    }, 330)
  },
  getCameraBoundingBox() {
    // try {
    //   const smartView = store.getState().camera.smartView
    //   const smartSelect = store.getState().camera.smartSelect

    //   if (timeoutSmartView) {
    //     if (cancelToken) {
    //       try {
    //         cancelToken.cancel('')
    //         cancelToken = axios.CancelToken.source()
    //       } catch (error) {
    //       }
    //     }
    //     clearTimeout(timeoutSmartView)
    //   }

    //   let boundingBox = {
    //     maxLatitude: 6.093387855061119,
    //     maxLongitude: 140.95183339283415,
    //     minLatitude: -11.528853880214438,
    //     minLongitude: 93.93887675817979
    //   } // indonesia box coordinate

    //   if (smartView) {
    //     if (!cesiumViewer.fullSizeBox) {
    //       cesiumViewer.generateCameraFullBox()

    //       store.dispatch(
    //         setBoundingBox({
    //           boundingBox: null
    //         })
    //       )
          
    //       const prospectMap = store.getState().camera.prospectMap

    //       if (!prospectMap) {
    //         setTimeout(() => {
    //           cesiumViewer.getCameraBoundingBox()
    //         }, 1000)
    //       }
    //     } else {
    //       boundingBox = cesiumViewer.fullSizeBox
    //     }
    //   } else if (smartSelect) {
    //     const stateBoundingBox = store.getState().camera.boundingBox
    //     if (stateBoundingBox) {
    //       boundingBox = {
    //         ...stateBoundingBox
    //       }
    //     }
    //   }


    //   timeoutSmartView = setTimeout(() => {
    //     const prospectMap = store.getState().camera.prospectMap
    //     const smartView = store.getState().camera.smartView
    //     const smartSelect = store.getState().camera.smartSelect
        
    //     if (!smartSelect) {
    //       store.dispatch(
    //         setBoundingBox({
    //           boundingBox
    //         })
    //       )
    //     }

    //     if (!prospectMap || smartView || smartSelect) {
    //       store.dispatch(
    //         fetchProspectAreaSites({
    //           cancelToken
    //         })
    //       )
    //     }
    //   }, 330)
    // } catch (error) {
    //   throw error
    // }
  },
  setViewer (viewer) {
    this.viewer = viewer

    // viewer.scene.debugShowFramesPerSecond = true

    const options = {}
    // 用于启用或禁用罗盘。true是启用罗盘，false是禁用罗盘。默认值为true。如果将选项设置为false，则罗盘将不会添加到地图中。
    options.enableCompass= true
    // 用于启用或禁用缩放控件。true是启用，false是禁用。默认值为true。如果将选项设置为false，则缩放控件将不会添加到地图中。
    options.enableZoomControls= false
    // 用于启用或禁用距离图例。true是启用，false是禁用。默认值为true。如果将选项设置为false，距离图例将不会添加到地图中。
    options.enableDistanceLegend= true
    // 用于启用或禁用指南针外环。true是启用，false是禁用。默认值为true。如果将选项设置为false，则该环将可见但无效。
    options.enableCompassOuterRing= true

    new CesiumNavigation(viewer, options)

    viewer.cesiumWidget.screenSpaceEventHandler
      .removeInputAction(
        Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
      )

    // viewer.cesiumWidget.screenSpaceEventHandler
    //   .removeInputAction(
    //     Cesium.ScreenSpaceEventType.LEFT_CLICK
    //   )

    // viewer.scene.globe.depthTestAgainstTerrain = false
    viewer.scene.globe.enableLighting = true

    // for transparency base layer when zooming in
    viewer.scene.screenSpaceCameraController.enableCollisionDetection = false
    viewer.scene.globe.translucency.frontFaceAlphaByDistance = new Cesium.NearFarScalar(
      400.0,
      0.0,
      800.0,
      1.0
    )
    
    viewer.scene.globe.translucency.enabled = true

    const handler = new Cesium.ScreenSpaceEventHandler(
      viewer.scene.canvas
    )

    viewer.entities.add({
      id: 'tooltip',
      label: {
        show: true,
        showBackground: true,
        font: '20px Abel',
        heightReference: Cesium.HeightReference.NONE,
        horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
        verticalOrigin: Cesium.VerticalOrigin.TOP,
        pixelOffset: new Cesium.Cartesian2(22, -10),
      }
    })

    generateFunctionHandler({
      camera: viewer.scene.camera,
      handler,
      scene: viewer.scene,
      viewer
    })

    cesiumViewer.normalDisableDepthTestDistance = new Cesium.CallbackProperty(() => {
      return cesiumViewer.getTestDistance()
    }, false)

    cesiumViewer.generateCameraFullBox()

    const { display } = store.getState()

    display.maps.forEach(map => {
      if (map.dataSource) {
        viewer.dataSources.add(map.dataSource)
      }
    })
  },
  initViewModel () {
    Cesium.knockout.track(this.viewModel)
    this.viewer.scene.globe.translucency.enabled = true

    const update = () => {
      if (this.viewModel.show === true) {
        let alpha = Number(this.viewModel.alpha)
        alpha = !isNaN(alpha) ? alpha : 1.0
        alpha = Cesium.Math.clamp(alpha, 0.0, 1.0)
      
        this.viewer.scene.globe.translucency.frontFaceAlphaByDistance = new Cesium.NearFarScalar(0, alpha, 8.0e6, alpha)
        this.viewer.scene.globe.translucency.backFaceAlphaByDistance = new Cesium.NearFarScalar(0, alpha, 8.0e6, alpha)
      } else {
        this.viewer.scene.globe.translucency.frontFaceAlphaByDistance = new Cesium.NearFarScalar(0, 0, 8.0e6, 0)
        this.viewer.scene.globe.translucency.backFaceAlphaByDistance = new Cesium.NearFarScalar(0, 0, 8.0e6, 0)
      }
    }

    for (let name in this.viewModel) {
      if (this.viewModel.hasOwnProperty(name)) {
        Cesium.knockout.getObservable(this.viewModel, name).subscribe(update)
      }
    }

    update()
  },
  addCameraHandler(setLight) {
    const update = () => {
      if (
        this.lightModel.heading !== this.viewer.camera.heading
        || !this.lightModel.positionWC
        || this.lightModel.positionWC.x !== this.viewer.camera.positionWC.x
        || this.lightModel.positionWC.y !== this.viewer.camera.positionWC.y
        || this.lightModel.positionWC.z !== this.viewer.camera.positionWC.z
      ) {
        this.generateCameraFullBox()

        this.getCameraBoundingBox()

        if (this.legendToggle) {
          // checkEntitiesInCesium() -- doesn't need to be added for now
        }

        const newLight = {
          heading: this.viewer.camera.heading,
          positionWC: this.viewer.camera.positionWC.clone()
        }

        const cartho = Cesium.Cartographic.fromCartesian(this.viewer.camera.position)

        this.testDistance = cartho.height

        this.lightModel.heading = newLight.heading
        this.lightModel.positionWC = newLight.positionWC

        setLight(newLight)
      }
    }

    if (this.viewer) {
      this.viewer.scene.postRender.addEventListener(update)
      update()
    }
  },
  cesiumMouseEventHandler(viewer) {
    viewer.screenSpaceEventHandler.setInputAction((event) => {
      if (
        cesiumViewer.sectionStatus.blockSection
        || cesiumViewer.sectionStatus.crossSection
        || cesiumViewer.sectionStatus.sliceSection
      ) {
        sectionMouseDownHandler(event, this, viewer)
      }

      sectionSmartSelectionMouseDownHandler(event, this)
      cesiumMineralOccurrences(event, cesiumViewer.mineralOccurrences, this.viewer)
    }, Cesium.ScreenSpaceEventType.LEFT_DOWN)
  
    viewer.screenSpaceEventHandler.setInputAction((event) => {
      if (
        cesiumViewer.sectionStatus.blockSection
        || cesiumViewer.sectionStatus.crossSection
        || cesiumViewer.sectionStatus.sliceSection
      ) {
        sectionMouseMoveHandler(event, this, viewer)
      }

      sectionSmartSelectionMouseMoveHandler(event, this)

      if (cesiumViewer.mineralOccurrences.holdMove) {
        cesiumMineralOccurrences(event, cesiumViewer.mineralOccurrences, viewer)
      }
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE)
  
    viewer.screenSpaceEventHandler.setInputAction(() => {
      if (
        cesiumViewer.sectionStatus.blockSection
        || cesiumViewer.sectionStatus.crossSection
        || cesiumViewer.sectionStatus.sliceSection
      ) {
        sectionMouseUpHandler(this, viewer)
      }
      sectionSmartSelectionMouseUpHandler(this)
      if (cesiumViewer.mineralOccurrences.holdMove) {
        moveStopMethod(viewer)
      }
    }, Cesium.ScreenSpaceEventType.LEFT_UP)
  },
  cesiumLoadImages(name,coordinates, source, selector, setSelector,idx){
    if(source!==null){
      this.viewer.entities.add({
        name: name,
        rectangle: {
          coordinates: Cesium.Rectangle.fromDegrees(
            coordinates[0],
            coordinates[3],
            coordinates[2],
            coordinates[1]
          ),
          material: source,
          classificationType: Cesium.ClassificationType.TERRAIN
        }
      })
    }
  }
}

export default cesiumViewer
