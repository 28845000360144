import React from 'react'

import Point from './Point'
import Polygon from './Polygon'
import Polyline from './Polyline'

const LIST_SHAPE = {
  Point: (color, size) => <Point color={color} size={size} />,
  Polygon: (color, size) => <Polygon color={color} size={size} />,
  MultiPolygon: (color, size) => <Polygon color={color} size={size} />,
  Polyline: (color, size) => <Polyline color={color} size={size} />,
  LineString: (color, size) => <Polyline color={color} size={size} />,
  MultiLineString: (color, size) => <Polyline color={color} size={size} />
}

/**
 * This component will generate legend symbol
 * there're point, line, and polygon
 * @param {String} geom is the selected geometry
 * @param {Object} color is the color selected
 * @param {String} size of the selected symbol
 */
function LegendSymbol({
  geom,
  color = 'rgba(0, 0, 0, 0)',
  size = '0.8rem'
}) {
  return !LIST_SHAPE[geom]
    ? null
    : LIST_SHAPE[geom](color, size)
}

export default LegendSymbol
