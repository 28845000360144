import React, { useState, useEffect, useRef } from 'react'

import { connect } from 'react-redux'

import {
  closeContext
} from '../../../store/actions/rightclick'

import ClickOutsideHandler from '../../helpers/ClickOutsideHandler'

import InputText from './InputText'

import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 8em;
  background-color: white;
  z-index: 9999;
  border-radius: 10px;
  overflow: hidden;
`
/**
 * This component will show right click bar
 * @param {Boolean} rightClick is status being shown or not
 * @param {Function} closeContext is method to turf off
 */
function RightClickbar({
  rightClick,
  closeContext
}) {
  const rightContainerRef = useRef(null)
  const [ styles, setStyles ] = useState({
    left: -100,
    top: -100
  })

  ClickOutsideHandler({
    ref: rightContainerRef,
    eventHandler: () => {
      if (rightClick.toggle) {
        closeContext()
      }
    },
    exceptRefs: []
  })

  useEffect(() => {
    setStyles((styles) => ({
      ...styles,
      left: rightClick.position.pageX
        ? rightClick.position.pageX
        : -100,
      top: rightClick.position.pageY
        ? rightClick.position.pageY
        : -100
    }))

    return () => {
      setStyles({
        left: -100,
        top: -100
      })
    }
  }, [rightClick.position])

  return !rightClick.toggle
    ? null
    : <Wrapper
      ref={rightContainerRef}
      style={styles}
    >
      {
        rightClick.context.map((content) => {
          return <InputText
            key={content.title}
            title={content.title}
            clickEvent={content.clickEvent}
            closeContext={closeContext}
          />
        })
      }
    </Wrapper>
}

function mapStateToProps(state) {
  return {
    rightClick: state.rightClick
  }
}

const mapDispatchToProps = {
  closeContext
}

export default connect(mapStateToProps, mapDispatchToProps)(RightClickbar)
