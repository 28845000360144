// IUGS Classification diagram for ultramafic rocks based on modal percentages of three mafic minerals (olivine, orthopyroxene, and clinopyroxene)
// Hess, P. C., Origins of Igneous Rocks. Harvard University Press: Cambridge, 1989; p 336.

export const ultramafic = {
  info: 'IUGS Classification diagram for ultramafic rocks based on modal percentages of three mafic minerals (olivine, orthopyroxene, and clinopyroxene) (Hess, P. C., Origins of Igneous Rocks. Harvard University Press: Cambridge, 1989; p 336.)',
  data: [
    {
      class: 'Dunite',
      values: [
        {
          ol: 100,
          opx: 0,
          cpx: 0
        },
        {
          ol: 90,
          opx: 0,
          cpx: 10
        },
        {
          ol: 90,
          opx: 10,
          cpx: 0
        }
      ],
      rotation: null
    },
    {
      class: 'Harzburgite',
      values: [
        {
          ol: 90,
          opx: 10,
          cpx: 0
        },
        {
          ol: 90,
          opx: 5,
          cpx: 5
        },
        {
          ol: 60,
          opx: 35,
          cpx: 5
        },
        {
          ol: 60,
          opx: 40,
          cpx: 0
        }
      ],
      rotation: null
    },
    {
      class: 'Lherzolite',
      values: [
        {
          ol: 90,
          opx: 5,
          cpx: 5
        },
        {
          ol: 60,
          opx: 35,
          cpx: 5
        },
        {
          ol: 60,
          opx: 5,
          cpx: 35
        }
      ],
      rotation: null
    },
    {
      class: 'Wherlite',
      values: [
        {
          ol: 90,
          opx: 5,
          cpx: 5
        },
        {
          ol: 90,
          opx: 0,
          cpx: 10
        },
        {
          ol: 60,
          opx: 0,
          cpx: 40
        },
        {
          ol: 60,
          opx: 5,
          cpx: 35
        }
      ],
      rotation: null
    },
    {
      class: 'Olivine Orthopyroxenite',
      values: [
        {
          ol: 60,
          opx: 40,
          cpx: 0
        },
        {
          ol: 60,
          opx: 35,
          cpx: 5
        },
        {
          ol: 5,
          opx: 90,
          cpx: 5
        },
        {
          ol: 10,
          opx: 90,
          cpx: 0
        }
      ],
      rotation: null
    },
    {
      class: 'Olivine Websterite',
      values: [
        {
          ol: 60,
          opx: 35,
          cpx: 5
        },
        {
          ol: 60,
          opx: 5,
          cpx: 35
        },
        {
          ol: 5,
          opx: 5,
          cpx: 90
        },
        {
          ol: 5,
          opx: 90,
          cpx: 5
        }
      ],
      rotation: null
    },
    {
      class: 'Olivine Clinopyroxenite',
      values: [
        {
          ol: 60,
          opx: 5,
          cpx: 35
        },
        {
          ol: 60,
          opx: 0,
          cpx: 40
        },
        {
          ol: 10,
          opx: 0,
          cpx: 90
        },
        {
          ol: 5,
          opx: 5,
          cpx: 90
        }
      ],
      rotation: null
    },
    {
      class: 'Orthopyroxenite',
      values: [
        {
          ol: 10,
          opx: 90,
          cpx: 0
        },
        {
          ol: 0,
          opx: 100,
          cpx: 0
        },
        {
          ol: 0,
          opx: 90,
          cpx: 10
        }
      ],
      rotation: null
    },
    {
      class: 'Websterite',
      values: [
        {
          ol: 5,
          opx: 90,
          cpx: 5
        },
        {
          ol: 0,
          opx: 90,
          cpx: 10
        },
        {
          ol: 0,
          opx: 10,
          cpx: 90
        },
        {
          ol: 5,
          opx: 5,
          cpx: 90
        }
      ],
      rotation: null
    },
    {
      class: 'Clinopyroxenite',
      values: [
        {
          ol: 0,
          opx: 10,
          cpx: 90
        },
        {
          ol: 0,
          opx: 0,
          cpx: 100
        },
        {
          ol: 10,
          opx: 0,
          cpx: 90
        }
      ],
      rotation: null
    }
  ]
}
