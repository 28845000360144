import * as Cesium from 'cesium'

import * as turf from '@turf/turf'

import cesiumViewer from '../cesiumViewer'

import * as d3 from 'd3'

// edit it later
// const BDL = [

// ]

/**
 * This function will score the geochemical for create color
 * @param {Boolean} inverse
 * @param {Number} score is the score of current geochemical
 * between 0 - 1 
 * @returns number of score
 */
export function scoreGeochemical({
  inverse,
  score
}) {
  if (!inverse) {
    return score
  } else {
    return 1 - score
  }
}

/**
 * This function will generate 
 * @param {Object} map is the current selected map
 * @param {Number} min is the minimum value of data
 * @param {Number} max is the maximum value of data
 */
export function generateScaleMethod({
  map,
  min,
  max
}) {
  if (map.colorScaling === 'linear') {
    return d3
      .scaleLinear()
      .domain([min, max])
      .range([0, 1])
  } else if (map.colorScaling === 'logarithmic') {
    return d3
      .scaleLog()
      .domain([min, max])
      .range([0, 1])
  }
}

/**
 * This function is to create cesium dataSource from geojson provided
 * @param bdl is the bellow detection limit
 * @param entities is the entities that need to recolor
 * @param elementName is the current filtered element
 * @param map is the map that selected
 */
export function setGeochemicalColorScaleWithD3({
  bdl = 0.0000001,
  elementName = 'au1_ppm',
  entities,
  map
}) {
  if (!elementName) {
    elementName = 'au1_ppm'
  }

  const colors = []
  
  let min = bdl
  let max = 0

  // score the color
  for (let i = 0; i < entities.length; i++) {
    let elementValue = 0

    if (entities[i].properties[elementName]) {
      elementValue = entities[i].properties[elementName].getValue(Cesium.JulianDate.now())
    } else {
      colors.push(bdl)
      continue
    }

    if (elementValue < min) {
      if (elementValue < bdl) {
        elementValue = bdl
      }
      min = elementValue
    }

    if (elementValue > max) {
      max = elementValue
    }

    colors.push(elementValue)
  }

  const scoreScale = generateScaleMethod({
    map,
    min,
    max
  })

  for (let i = 0; i < entities.length; i++) {
    const entity = entities[i]

    if (colors[i] !== null) {
      const [ red, green, blue ] = d3[map.colorScale](
        scoreGeochemical({
          inverse: map.cesiumLayer.inverse,
          score: scoreScale(colors[i])
        })
      )
        .slice(4, -1)
        .split(', ')
  
      map.colorEntities[entity.id] = {
        red: red / 255,
        green: green / 255,
        blue: blue / 255,
        alpha: map.cesiumLayer.alpha
      }
    }
  }
}

/**
 * This function is to generate cesium entity shape
 * @param map is the current map reference
 * @param entities is the entities to change
 */
export async function setGeochemicalCesiumEntityShape({
  map,
  entities
}) {
  if (map.ellipsoidStatus) {
    const radii = new Cesium.Cartesian3(
      map.pixelSize,
      map.pixelSize,
      map.pixelSize
    )

    for (let i = 0; i < entities.length; i++) {
      const entity = entities[i]

      if (entity.point) {
        entity.point = undefined
      }

      const colorMaterialProperty = new Cesium.ColorMaterialProperty()
      colorMaterialProperty.color = new Cesium.CallbackProperty(
        () => {
          return new Cesium.Color.fromAlpha(
            new Cesium.Color(
              map.colorEntities[entity.id].red,
              map.colorEntities[entity.id].green,
              map.colorEntities[entity.id].blue
            ),
            map.colorEntities[entity.id].alpha
          )
        },
        false
      )

      entity.ellipsoid = new Cesium.EllipsoidGraphics({
        radii,
        material: colorMaterialProperty,
        shadows: Cesium.ShadowMode.ENABLED
      })
    }
  } else {
    for (let i = 0; i < entities.length; i++) {
      const entity = entities[i]

      if (entity.ellipsoid) {
        entity.ellipsoid = undefined
      }

      entity.point = new Cesium.PointGraphics({
        color: new Cesium.CallbackProperty(
          () => {
            return new Cesium.Color.fromAlpha(
              new Cesium.Color(
                map.colorEntities[entity.id].red,
                map.colorEntities[entity.id].green,
                map.colorEntities[entity.id].blue
              ),
              map.colorEntities[entity.id].alpha
            )
          },
          false
        ),
        pixelSize: map.pixelSize,
        distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
          0,
          1e7
        )
      })
    }
  }
}

/**
 * This function will clip geochemical data based on polygon given
 * @param {*} polygon
 */
export function clipGeochemicalData(polygon) {
  const turfPolygon = polygon

  cesiumViewer.viewer.dataSources._dataSources.forEach((dataSource) => {
    if (dataSource.name && dataSource.name.includes('geochemical')) {
      dataSource.entities.values.forEach((entity) => {
        const position = entity.position.getValue('')

        const cartographic = Cesium.Cartographic
          .fromCartesian(position)

        const point = turf.point([
          Cesium.Math.toDegrees(cartographic.longitude),
          Cesium.Math.toDegrees(cartographic.latitude)
        ])

        const status = turf.booleanWithin(point, turfPolygon)

        if (status === false) {
          entity.show = false
        } else {
          entity.show = true
        }
      })
    }
  })
}

/**
 * This function will unclip all geochemical data
 */
export function unclipGeochemicalData() {
  cesiumViewer.viewer.dataSources._dataSources.forEach((dataSource) => {
    if (dataSource.name && dataSource.name.includes('geochemical')) {
      dataSource.entities.values.forEach((entity) => {
        if (entity.show === false) {
          entity.show = true
        }
      })
    }
  })
}

/**
 * This function is to create cesium dataSource from geojson provided
 * @param name is the name for dataSource
 * @param geojson is geojson data of geochem
 * @param map is the map that selected
 * @param type is need to edit later, but used for create multiple purpose
 * @return geochemical datasource, can be destory (literally destory to destroy instance)
 */
export async function loadGeochemicalGeoJSON({
  name,
  geojson,
  type, // remove it later
  map,
  dataSource
}) {
  try {
    if (map.geom === 'points') {
      map.ellipsoidStatus = false // set it to point at first
      
      if (!dataSource) {
        dataSource = await new Cesium.CustomDataSource(name)
      }

      for (let i = 0; i < geojson.features.length; i++) {
        const { geometry } = geojson.features[i]

        await dataSource.entities.add({
          position: new Cesium.Cartesian3.fromDegrees(
            geometry.coordinates[0],
            geometry.coordinates[1]
          ),
          point: new Cesium.PointGraphics({
            heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND
          }),
          properties: {
            ...geojson.features[i].properties
          }
        })
      }

      setGeochemicalColorScaleWithD3({
        entities: dataSource.entities.values,
        map
      })

      setGeochemicalCesiumEntityShape({
        entities: dataSource.entities.values,
        map
      })
  
      return dataSource
    } else if (map.geom === 'area') {
      if (!dataSource) {
        dataSource = await new Cesium.CustomDataSource(name)
      }

      for (let i = 0; i < geojson.features.length; i++) {
        const feature = geojson.features[i]

        dataSource.entities.add({
          polygon: new Cesium.PolygonGraphics({
            hierarchy: new Cesium.PolygonHierarchy(
              new Cesium.Cartesian3.fromDegreesArray(
                feature.geometry.coordinates.flat(5)
              )
            )
          }),
          properties: feature.properties
        })
      }

      return dataSource
    } else if (type === 'test') {
      // console.log('start to pointing')

      const points = new Cesium.PointPrimitiveCollection()
      const pointsOfInterests = []

      for (let i = 0; i < geojson.features.length; i++) {
        const { geometry } = geojson.features[i]
        // const { au1_ppm } = properties

        // To get height, from no height reference
        // Specify our point of interest.
        // Sample the terrain (async) and write the answer to the console.
        pointsOfInterests.push(
          Cesium.Cartographic.fromDegrees(
            geometry.coordinates[0],
            geometry.coordinates[1]
          )
        )
      }

      const pointResults = await Cesium.sampleTerrainMostDetailed(cesiumViewer.viewer.terrainProvider, pointsOfInterests)

      for (let i = 0; i < geojson.features.length; i++) {
        const { geometry } = geojson.features[i]
        const position = new Cesium.Cartesian3.fromDegrees(
          geometry.coordinates[0],
          geometry.coordinates[1],
          pointResults[i].height
        )

        points.add({
          position,
          color: Cesium.Color.RED,
          pixelSize: 2
        })
      }

      return points
    } else {
      throw new Error('invalid type for geochemical dataset')
    }
  } catch (error) {
    throw error
  }
}
