import * as Cesium from 'cesium'
import * as turf from '@turf/turf'

import cesiumViewer from './cesiumViewer'

import {
  findKeyProperties
} from '../packages/cesium-add-on/properties'

// const disableDepthCategories = [
//   'grid'
// ]

// const nonClamp = [
//   'borehole'
// ]

export function generateCentroid(entity) {
  let position = undefined

  if (entity.polygon) {
    const hierarchy = entity.polygon.hierarchy.getValue(Cesium.JulianDate.now())
    
    const positions = []

    hierarchy.positions.forEach((position) => {
      const cartographiPosition = Cesium.Cartographic.fromCartesian(position)
      positions.push([
        Cesium.Math.toDegrees(cartographiPosition.longitude),
        Cesium.Math.toDegrees(cartographiPosition.latitude)
      ])
    })

    const polygon = turf.polygon([positions])

    const centroid = turf.centroid(polygon)
    
    position = new Cesium.Cartesian3.fromDegrees(
      centroid.geometry.coordinates[0],
      centroid.geometry.coordinates[1]
    )
  } else if (entity.polyline) {
    // const coordinates = entity.polyline.

    // const lengthPoint = coordinates.length
    // if (lengthPoint % 2 === 0) {
    //   const midPoint = lengthPoint / 2
  
    //   const firstPosition = coordinates[midPoint - 1]
    //   const lastPosition = coordinates[
    //     midPoint
    //   ]
  
    //   const centroid = [
    //     (firstPosition[0] + lastPosition[0]) / 2,
    //     (firstPosition[1] + lastPosition[1]) / 2
    //   ]
      
    //   position = new Cesium.Cartesian3.fromDegrees(
    //     centroid[0],
    //     centroid[1]
    //   )
    // } else {
    //   const midPoint = Math.floor(lengthPoint / 2)
  
    //   const centroid =  coordinates[midPoint]
  
    //   position = new Cesium.Cartesian3.fromDegrees(
    //     centroid[0],
    //     centroid[1]
    //   )
    // }
  }


  return position
}

/**
 * This function will create label to entity
 * @param {Array} entities the array of entity that need to add label
 * @param {string} key is the key of properties to show
 */
export function generateLabel({
  entities,
  key
}) {
  for (let i = 0; i < entities.length; i++) {
    const properties = entities[i].properties

    const disableDepthTestDistance = cesiumViewer.normalDisableDepthTestDistance

    if (properties) {
      const value = findKeyProperties({
        properties,
        key
      })

      if (!entities[i].position) {
        entities[i].position = generateCentroid(entities[i])
      }

      const label = {
        text: String(value),
        font: '14px abel',
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        fillColor: Cesium.Color.BLACK,
        showBackground: true,
        backgroundColor: new Cesium.Color(1, 1, 1, 0.7),
        backgroundPadding: new Cesium.Cartesian2(8, 4),
        disableDepthTestDistance
      }

      // if (!disableDepthCategories.includes(map.category)) {
      //   label.disableDepthTestDistance = 20000
      // }

      // if (!nonClamp.includes(map.category)) {
      //   label.heightReference = Cesium.HeightReference.CLAMP_TO_GROUND
      // }

      entities[i].label = label
    }
  }
}

/**
 * This function will change label value of entity
 * @param {Array} entities the array of entity to change the label
 * @param {string} key is the key of properties to show
 */
export function changeLabel({
  entities,
  key
}) {
  if (entities.length && !entities[0].label) {
    generateLabel({
      entities,
      key
    })
    return
  }

  for (let i = 0; i < entities.length; i++) {
    const properties = entities[i].properties

    if (properties) {
      const value = findKeyProperties({
        properties,
        key
      })

      entities[i].label.text.setValue(String(value))
    }
  }
}

/**
 * This function will remove label value of entity
 * @param {Array} entities the array of entity to remove the label
 */
export function removeLabel({
  entities
}) {
  for (let i = 0; i < entities.length; i++) {
    entities[i].label = undefined
  }
}
