import {
  Cartographic,
  Math as CesiumMath,
  Color,
  Cartesian3,
  PolygonHierarchy,
  JulianDate,
  ClippingPlaneCollection
} from 'cesium'

import {
  point as turfPoint,
  bearing as turfBearing,
  distance as turfDistance,
  polygon as turfPolygon,
  booleanPointInPolygon
} from '@turf/turf'

import { Slice } from 'polyk'

import SectionMath from './SectionMath'

import {
  clippingPlanesFromPolygon
} from './clip'

import {
  getCentroidPolygon
} from './centroid'

import {
  generateBlockPolygon
} from './generatePolygon'

/**
 * This method used to slice polygon into polyline points and update it to cesium.
 * The update will create two polygon with color red and blue, that used to selected by user to pick the side.
 * @param {Object} cesiumViewer cesium viewer
 */
export function polygonSliceByPolyline(cesiumViewer) {
  const { sectionStatus, viewer } = cesiumViewer
  let { sliceMovement, sliceLine } = sectionStatus

  sectionStatus.cartesianPoints = Cartesian3.fromDegreesArray(
    generateBlockPolygon(sectionStatus.dragMovement)
  )

  const points = [
    sliceMovement.start.longitude, sliceMovement.start.latitude,
    sliceMovement.end.longitude, sliceMovement.end.latitude
  ]

  const coordinates = []

  for (let i = 0; i < sectionStatus.cartesianPoints.length; i++) {
    const { longitude, latitude } = Cartographic
      .fromCartesian(sectionStatus.cartesianPoints[i])
    
    coordinates.push([
      longitude,
      latitude
    ])
  }

  const radianPoints = []

  points.forEach((point) => {
    radianPoints.push(CesiumMath.toRadians(point))
  })

  const { m, b } = SectionMath.findRegresionLinear(
    [radianPoints[0], radianPoints[2]],
    [radianPoints[1], radianPoints[3]]
  )

  const point1 = turfPoint([points[0], points[1]])
  const point2 = turfPoint([points[2], points[3]])

  let bearing = Math.abs(turfBearing(point1, point2))

  let startLong = null
  let startLat = null
  let endLong = null
  let endLat = null

  if (Math.abs(bearing) < 45 || Math.abs(bearing) > 135) {
    startLat = coordinates[0][1] - 0.001
    startLong = (startLat - b) / m
    endLat = coordinates[2][1] + 0.001
    endLong = (endLat - b) / m
  } else {
    startLong = coordinates[0][0] - 0.001
    startLat = m * startLong + b
    endLong = coordinates[2][0] + 0.001
    endLat = m * endLong + b
  }

  if (startLong && startLat && endLong && endLat) {
    const listPolygon = []

    coordinates.forEach(coordinate => {
      listPolygon.push(coordinate[0])
      listPolygon.push(coordinate[1])
    })

    sectionStatus.slicePick = true

    const slicePolygon = Slice(
      listPolygon,
      startLong,
      startLat,
      endLong,
      endLat
    )

    for (let i = 0; i < slicePolygon.length; i++) {
      let material = Color.WHITE

      if (i % 2 === 0) {
        material = Color.RED.withAlpha(0.2)
      } else {
        material = Color.BLUE.withAlpha(0.2)
      }

      const entity = viewer.entities.add({
        polygon: {
          hierarchy: new PolygonHierarchy(
            new Cartesian3.fromRadiansArray(slicePolygon[i])
          ),
          material
        }
      })

      sectionStatus.slicePolygon.push(entity)
    }

    let matchLongitude = []
    let matchLatitude = []

    if (slicePolygon.length === 2) {
      for (let i = 0; i < slicePolygon[0].length; i += 2) {
        for (let j = 0; j < slicePolygon[1].length; j += 2) {
          if (slicePolygon[0][i] === slicePolygon[1][j] && slicePolygon[0][i + 1] === slicePolygon[1][j + 1]) {
            matchLongitude.push(slicePolygon[0][i])
            matchLatitude.push(slicePolygon[0][i + 1])
          }
        }
      }

      sliceMovement.start.longitude = CesiumMath.toDegrees(matchLongitude[0])
      sliceMovement.start.latitude = CesiumMath.toDegrees(matchLatitude[0])
      sliceMovement.end.longitude = CesiumMath.toDegrees(matchLongitude[1])
      sliceMovement.end.latitude = CesiumMath.toDegrees(matchLatitude[1])
    }

    viewer.entities.remove(sliceLine)
    sectionStatus.sliceLine = null
  }
}

/**
 * This method used to pick red or blue slice that have been created before.
 * @param {Object} cesiumViewer - cesium viewer
 * @param {Object} point - selected pick point
 * @param {Number} pickedIndex - picked index value
 */
export function pickSlice(cesiumViewer, point, pickedIndex) {
  const { sectionStatus, viewer } = cesiumViewer
  const { dragMovement } = sectionStatus

  sectionStatus.sliceCartesianPoints = []
  const polygon = []
  let fixedPoints = []

  if (pickedIndex === undefined) {
    const objTurfPoint = turfPoint([
      point.longitude, point.latitude
    ])

    for (let i = 0; i < sectionStatus.slicePolygon.length; i++) {
      const hierarchy = sectionStatus
        .slicePolygon[i].polygon.hierarchy.getValue(JulianDate.now())
  
      fixedPoints = []
  
      hierarchy.positions.forEach((position) => {
        let cartoPosition = Cartographic.fromCartesian(position)
        
        fixedPoints.push([
          CesiumMath.toDegrees(cartoPosition.longitude),
          CesiumMath.toDegrees(cartoPosition.latitude)
        ])
      })
  
      let cartoPosition = Cartographic.fromCartesian(hierarchy.positions[0])
  
      fixedPoints.push([
        CesiumMath.toDegrees(cartoPosition.longitude),
        CesiumMath.toDegrees(cartoPosition.latitude)
      ])
  
      const turfObjPolygon = turfPolygon([fixedPoints]) 
  
      if (booleanPointInPolygon(objTurfPoint, turfObjPolygon)) {
        pickedIndex = i
        
        break
      }
    }
  } else {
    const hierarchy = sectionStatus
      .slicePolygon[pickedIndex].polygon.hierarchy.getValue(JulianDate.now())

    fixedPoints = []

    hierarchy.positions.forEach((position) => {
      let cartoPosition = Cartographic.fromCartesian(position)
      
      fixedPoints.push([
        CesiumMath.toDegrees(cartoPosition.longitude),
        CesiumMath.toDegrees(cartoPosition.latitude)
      ])
    })

    let cartoPosition = Cartographic.fromCartesian(hierarchy.positions[0])

    fixedPoints.push([
      CesiumMath.toDegrees(cartoPosition.longitude),
      CesiumMath.toDegrees(cartoPosition.latitude)
    ])
  }

  if (pickedIndex !== -1) {
    for (let i = 0; i < fixedPoints.length - 1; i++) {
      sectionStatus.sliceCartesianPoints.push(new Cartesian3.fromDegrees(
        fixedPoints[i][0],
        fixedPoints[i][1]
      ))
  
      polygon.push(fixedPoints[i][0])
      polygon.push(fixedPoints[i][1])
    }
  
    const globe = viewer.scene.globe
  
    globe.clippingPlanes = new ClippingPlaneCollection({
      planes: clippingPlanesFromPolygon(
        sectionStatus.sliceCartesianPoints,
        getCentroidPolygon(polygon, sectionStatus.dragMovement),
        dragMovement
      ),
      unionClippingRegions : true,
      edgeWidth: 1,
      edgeColor: Color.WHITE,
      enabled : true
    })

    sectionStatus.slicePick = false
    
    sectionStatus.slicePolygon.forEach(entity => {
      viewer.entities.remove(entity)
    })

    sectionStatus.slicingIndex = pickedIndex
    sectionStatus.slicePolygon = []
  }
}

/**
 * This method used to fly on pick the selected slice
 * @param {Object} cesiumViewer - cesium viewer
 */
export function pickFly(cesiumViewer) {
  const { viewer, sectionStatus } = cesiumViewer
  const { sliceMovement } = sectionStatus

  let bearing = SectionMath.getPlaneBearing(sliceMovement) - 90

  if (cesiumViewer.sectionStatus.slicingIndex === 1) {
    bearing += 180
  }

  let centroid = {
    longitude: (sliceMovement.start.longitude + sliceMovement.end.longitude) / 2,
    latitude: (sliceMovement.start.latitude + sliceMovement.end.latitude) / 2
  }

  const from = turfPoint([sliceMovement.start.longitude, sliceMovement.start.latitude])
  const to = turfPoint([sliceMovement.end.longitude, sliceMovement.end.latitude])

  const distance = turfDistance(from , to, { units: 'kilometers' })

  const convert = SectionMath.convertMetersToLongLat({
    longitude: centroid.longitude,
    latitude: centroid.latitude,
    distance,
    bearing: bearing + 180
  })

  viewer.scene.camera.flyTo({
    destination: new Cartesian3.fromDegrees(
      convert.longitude,
      convert.latitude,
      1000
    ),
    orientation: {
      heading: CesiumMath.toRadians(bearing),
      pitch: CesiumMath.toRadians(-10),
      roll: 0
    }
  })
}
