export const FETCH_PROSPECT_AREA_SITES = 'FETCH_PROSPECT_AREA_SITES'
export const FETCH_PROSPECT_AREA_SITES_SUCCESS = 'FETCH_PROSPECT_AREA_SITES_SUCCESS'
export const FETCH_PROSPECT_AREA_SITES_ERROR = 'FETCH_PROSPECT_AREA_SITES_ERROR'

export const SELECT_SITE = 'SELECT_SITE'
export const SELECT_SITE_SUCCESS = 'SELECT_SITE_SUCCESS'
export const SELECT_SITE_ERROR = 'SELECT_SITE_ERROR'

export const CHANGE_COMMODITY = 'CHANGE_COMMODITY'
export const CHANGE_COMMODITY_SUCCESS = 'CHANGE_COMMODITY_SUCCESS'
export const CHANGE_COMMODITY_ERROR = 'CHANGE_COMMODITY_ERROR'

export const CHANGE_SITE_MENU_CATEGORY = 'CHANGE_SITE_MENU_CATEGORY'
export const CHANGE_SITE_MENU_CATEGORY_SUCCESS = 'CHANGE_SITE_MENU_CATEGORY_SUCCESS'
export const CHANGE_SITE_MENU_CATEGORY_ERROR = 'CHANGE_SITE_MENU_CATEGORY_ERROR'

export const VISIT_SITE = 'VISIT_SITE'
export const VISIT_SITE_SUCCESS = 'VISIT_SITE_SUCCESS'
export const VISIT_SITE_BACK = 'VISIT_SITE_BACK'
export const VISIT_SITE_BACK_SUCCESS = 'VISIT_SITE_BACK_SUCCESS'
export const VISIT_SITE_FORWARD = 'VISIT_SITE_FORWARD'
export const VISIT_SITE_FORWARD_SUCCESS = 'VISIT_SITE_FORWARD_SUCCESS'
export const VISIT_SITE_HOME = 'VISIT_SITE_HOME'
export const VISIT_SITE_HOME_SUCCESS = 'VISIT_SITE_HOME_SUCCESS'
export const VISIT_SITE_ERROR = 'VISIT_SITE_ERROR'

export const CHANGE_RANKING_PREFERENCES = 'CHANGE_RANKING_PREFERENCES'
export const CHANGE_RANKING_PREFERENCES_SUCCESS = 'CHANGE_RANKING_PREFERENCES_SUCCESS'
export const CHANGE_RANKING_PREFERENCES_ERROR = 'CHANGE_RANKING_PREFERENCES_ERROR'
export const RESET_RANKING_PREFERENCES = 'RESET_RANKING_PREFERENCES'

export const RESET_SITES = 'RESET_SITES'
