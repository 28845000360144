import axiosReact, { getProgressDownload } from '../../../axiosReact'

export async function fetch({
  map,
  params,
  fetchProgress,
  fetchFinish
}) {
  try {
    return []
    // let { data } = await axiosReact.get('/geoprosp/cesium_asset', {
    //   params: {
    //     commodity: 'clay'
    //   }
    // })

    // if (fetchFinish instanceof Function) fetchFinish()
    // data = data
    //   .map(item => ({ ...item, tilesetId: item.asset_id, name: item.label_name }))
    //   .filter(item => item.tilesetId)

    // return data
  } catch (error) {
    throw error
  }
}

export function parseFetch({ data, layerBy }) {
  return data
}

export function getColor(entity, { map }) {
  return {
    red: Math.random(),
    green: Math.random(),
    blue: Math.random(),
    alpha: map.cesiumLayer.alpha
  }
}

export async function getLegendStyle({
  map,
  params,
  fetchProgress,
  fetchFinish
}) {
  try {
  } catch (error) {
  }
}
