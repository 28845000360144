import React, { useEffect, useState } from 'react'

import RangeSlider from '../../../../../../../Sliders/RangeSlider'

import {
  enableScroll,
  disableScroll
} from '../../../../../../../helpers/ScrollHandler'

/**
 * Option menu to range
 * @param {Object} map is the current map
 * @returns 
 */
function OptionMenuRangeSlider({ map }) {
  const [ range, setRange ] = useState(map.cesiumLayer.range)
  const [ sliderState, setSliderState ] = useState(map.cesiumLayer.sliderState)

  useEffect(() => {
    function eventRangeHandler() {
      setRange(map.cesiumLayer.range)
    }

    function eventSliderHandler() {
      setSliderState(map.cesiumLayer.sliderState)
    }

    map.cesiumLayer.subscribeEvent('range', eventRangeHandler)
    map.cesiumLayer.subscribeEvent('sliderState', eventSliderHandler)

    return (() => {
      map.cesiumLayer.unsubscribeEvent('range', eventRangeHandler)
      map.cesiumLayer.unsubscribeEvent('sliderState', eventSliderHandler)
    })
  }, [])

  function setTemplate(template) {
    map.cesiumLayer.setTemplate(template)
  }

  return (
    <RangeSlider
      lineWidth="4em"
      range={range}
      sliderState={sliderState}
      onChangeHandler={(event) => {
        setTemplate(null)

        map.cesiumLayer.setRange({
          ...map.cesiumLayer.range,
          min: event.min,
          max: event.max
        })
      }}
      scrollTop={() => {
        disableScroll()
        setTimeout(() => {
          enableScroll()
        }, 330)
      }}
    />
  )
}

export default OptionMenuRangeSlider
