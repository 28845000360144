export const SMART_VIEW_ON = 'SMART_VIEW_ON'
export const SMART_VIEW_OFF = 'SMART_VIEW_OFF'
export const SET_BOUNDING_BOX = 'SET_BOUNDING_BOX'
export const SMART_SELECT_ON = 'SMART_SELECT_ON'
export const SMART_SELECT_OFF = 'SMART_SELECT_OFF'
export const SMART_FREEZE_ON = 'SMART_FREEZE_ON'
export const SMART_FREEZE_OFF = 'SMART_FREEZE_OFF'
export const SET_PROSPECT_MAP = 'SET_PROSPECT_MAP'

export const RESET_CAMERA = 'RESET_CAMERA'
