import * as Cesium from 'cesium'

import cesiumViewer from '../../../apis/cesiumViewer'

import {
  SITE_RANK_ENTITY,
  POLYLINE_MAP_ENTITY,
  POLYGON_MAP_ENTITY
} from '../../../constants/Cesium/entity'

/**
 * This function will handle all mouse move event in cesium
 * @param event is the event from mouse move in cesium canvas
 * @param hover to show current hover entity
 * @param setHover is to change hover state
 * @param legend state of coordinates and scale
 * @param setLegend used to change legend coordinates and scale
 * @param crossSection to check the cross section when triggered
 */
export async function mouseMoveHandler(event, {
  hover,
  setHover,
  legend,
  setLegend,
  section
}) {
  try {
    if (!cesiumViewer.viewer) {
      return
    }
  
    if (!event.clientX || !event.clientY) {
      return
    }

    const cartesian2 = new Cesium.Cartesian2(event.clientX - 50, event.clientY - 44)
  
    if (!cartesian2 || isNaN(cartesian2.x) || isNaN(cartesian2.y)) {
      return
    } else {
      const ray = cesiumViewer.viewer.scene.camera.getPickRay(
        cartesian2
      )

      if (!ray.origin && !ray.direction) {
        return
      }
  
      const cartesian = cesiumViewer.viewer.scene.globe
        .pick(ray, cesiumViewer.viewer.scene)

      setLegend({
        ...legend,
        coordinate: generateCoordinate({
          cartesian
        }),
        scale: generateScale({
          event
        })
      })

      if (cesiumViewer.viewer) {
        // stop next event
        if (section.crossSection || section.blockSection) {
          return
        }
      }
    
      const pickedObject = cesiumViewer.viewer.scene.pick(cartesian2)
      
      const tooltipEntity = cesiumViewer.viewer.entities.getById('tooltip')
    
      if (pickedObject) {
        if (Cesium.defined(pickedObject)) {
          if (pickedObject.id) {
            if (pickedObject.id.properties) {
              const properties = pickedObject.id.properties.getValue('')
    
              // existing mine, edit it later
              if (properties.customType === 'EXISTING_MINE_ENTITY') {
                // const position = new Cesium.Cartesian3.fromDegrees(
                //   Number(properties.LONGITUDE),
                //   Number(properties.LATITUDE),
                //   11000
                // )
              } else if (properties.customType === SITE_RANK_ENTITY) {
                if (!hover.entity) {
                  addHover({
                    properties,
                    pickedObject,
                    cartesian2: null,
                    setHover
                  })
                } else {
                  if (pickedObject.id !== hover.entity) {
                    removeHover({
                      newCartesian2: null,
                      changeEntity: true,
                      hover,
                      setHover
                    })
                    addHover({
                      properties,
                      pickedObject,
                      cartesian2: null,
                      setHover
                    })
                  }
                }
    
                const position = new Cesium.Cartesian3.fromDegrees(
                  properties.long,
                  properties.lat
                )
    
                tooltipEntity.position = position
                tooltipEntity.label.show = true
                tooltipEntity.label.text = properties.name
                tooltipEntity.label.heighReference = Cesium.HeightReference.CLAMP_TO_GROUND
                tooltipEntity.label.disableDepthTestDistance = Number.POSITIVE_INFINITY
              // } else if (properties.customType === POLYLINE_MAP_ENTITY) {
              //   if (!hover.entity) {
              //     addHover({
              //       properties,
              //       pickedObject,
              //       cartesian2,
              //       setHover
              //     })
              //   } else {
              //     if (pickedObject.id !== hover.entity) {
              //       removeHover({
              //         newCartesian2: null,
              //         changeEntity: true,
              //         hover,
              //         setHover
              //       })
              //       addHover({
              //         properties,
              //         pickedObject,
              //         cartesian2: null,
              //         setHover
              //       })
              //     }
              //   }
          
              //   tooltipEntity.label.show = false
              } else {
                removeHover({
                  newCartesian2: cartesian2,
                  changeEntity: true,
                  hover,
                  setHover
                })

                tooltipEntity.label.show = false
              }
            }
          }
        }
      } else {
        removeHover({
          newCartesian2: cartesian2,
          changeEntity: true,
          hover,
          setHover
        })

        tooltipEntity.label.show = false
      }
    }
  } catch (error) {
    throw error
  }
}

// for draw movement
// const { toolbar } = store.getState()

// if (toolbar.selector === 'drawPolyline') {
//   if (toolbar.pointSelected) {
//     const ray = scene.camera.getPickRay(
//       movement.endPosition
//     )

//     const position = scene.globe.pick(ray, scene)
//     toolbar.pointSelected.position.setCallback(function () {
//       return position
//     }, false)

//     const index = toolbar
//       .pointDataSource.entities.values.indexOf(toolbar.pointSelected)
    
//     const positions = toolbar.activeShapePoints.slice()
//     positions[index] = position

//     store.dispatch(updateActiveShapePoints({
//       activeShapePoints: positions
//     }))
//   }

//   return
// }

/**
 * This function to add hover effect to entity
 * @param properties is the properties of entity
 * @param pickedObject is the entity that have been picked
 * @param cartesian2 is the current position mouse on cesium
 * @param hover is the current hover
 * @param setHover is the event to change hover state
 */
function addHover({
  properties,
  pickedObject,
  cartesian2,
  hover,
  setHover
}) {
  if (properties.customType === SITE_RANK_ENTITY) {
    pickedObject.id.billboard.image = properties.billboardImage.hover
    
    setHover({
      ...hover,
      status: 'area',
      entity: pickedObject.id,
      cartesian2: null
    })
  }
  if (properties.customType === POLYGON_MAP_ENTITY) {
    // edit it later
    // const color = pickedObject.id.polygon.material.color.getValue('')
    
    setHover({
      ...hover,
      status: 'map',
      entity: pickedObject.id,
      cartesian2
    })
  } else if (properties.customType === POLYLINE_MAP_ENTITY) {
    pickedObject.id.polyline.material.outlineColor = {
      ...pickedObject.id.polyline.material.color.getValue('')
    }

    setHover({
      ...hover,
      status: 'map',
      entity: pickedObject.id,
      cartesian2
    })
  }
}

/**
 * This function is to remove entity state from hover
 * @param newCartesian2 is the new position of cartesian 2
 * @param changeEntity is used when entity focus are changing
 * @param hover is the current hover state
 * @param setHover to change hover state
 */
function removeHover({
  newCartesian2,
  changeEntity,
  hover,
  setHover
}) {
  if (hover && hover.entity) {
    if (hover.status === 'area') {
      hover.entity.billboard.image = hover.entity.properties
        .getValue('').billboardImage.default
  
      setHover({
        status: null,
        entity: null,
        cartesian2: null
      })
    } else if (hover.status === 'map') {
      const customType = hover.entity
        .properties
        .customType
        .getValue('')

      if (customType === POLYGON_MAP_ENTITY) {
        // edit it later

        // const color = hover.entity
        //   .polygon
        //   .material
        //   .color
        //   .getValue('')

        // hover.entity.polygon.material.color = {
        //   ...color,
        //   alpha: 0.5
        // }
      } else if (customType === POLYLINE_MAP_ENTITY) {
        if (
          changeEntity
            || Math.abs(hover.cartesian2.x - newCartesian2.x) > 10
            || Math.abs(hover.cartesian2.y - newCartesian2.y) > 10
        ) {
          hover.entity.polyline.material.outlineColor = {
            ...hover.entity.polyline.material.outlineColor
              .getValue(''),
            alpha: 0.01
          }

          setHover({
            ...hover,
            status: null,
            entity: null,
            cartesian2: null
          })
        }
      }
    }
  }
}

/**
 * This function will render longitude and latitude in cesium legend
 * @param {Cartesian3} cartesian is the cartesian position
 * @returns string of coordinate
 */
export function generateCoordinate({
  cartesian
}) {
  if (!cesiumViewer.viewer) {
    return
  }

  if (cartesian) {
    const cartographic = Cesium.Cartographic.fromCartesian(cartesian)
          
    const longitudeString = Cesium.Math.toDegrees(
      cartographic.longitude
    ).toFixed(4)
    
    const latitudeString = Cesium.Math.toDegrees(
      cartographic.latitude
    ).toFixed(4)

    const coordinate = 'Long: ' + longitudeString + "\u00B0"
      + ';Lat: ' + latitudeString + "\u00B0"
    
    return coordinate
  } else {
    return null
  }
}

/**
 * This function will create scaller based on current event position
 * @param event is the mouse event
 * @returns number of distance in meters
 */
export function generateScale({
  event
}) {
  const mousePositionStart = new Cesium.Cartesian2(event.clientX - 50, event.clientY)
  const mousePositionEnd = new Cesium.Cartesian2(event.clientX + 50, event.clientY)

  const rayStart = cesiumViewer.viewer.scene.camera.getPickRay(
    mousePositionStart
  )
  const rayEnd = cesiumViewer.viewer.scene.camera.getPickRay(
    mousePositionEnd
  )

  const cartesianStart = cesiumViewer.viewer.scene.globe.pick(rayStart, cesiumViewer.viewer.scene)
  const cartesianEnd = cesiumViewer.viewer.scene.globe.pick(rayEnd, cesiumViewer.viewer.scene)

  if (cartesianStart && cartesianEnd) {
    const startCartographicPoint = Cesium.Cartographic.fromCartesian(cartesianStart)
    const endCartographicPoint = Cesium.Cartographic.fromCartesian(cartesianEnd)

    const ellipsoidGeodesic = new Cesium.EllipsoidGeodesic(startCartographicPoint,
      endCartographicPoint)
    const distance = ellipsoidGeodesic.surfaceDistance
    const distanceInM = distance.toFixed(2)
    
    return distanceInM
  } else {
    return null
  }
}
