import React from 'react'

import './SidebarMenuToggleButton.css'

function SidebarMenuToggleButton({
  menu,
  pickMenu,
  changeSiteMenuCategory
}) {
  const category = menu.category

  function onClickHandler() {
    changeSiteMenuCategory({
      menu: pickMenu
    }) 
  }
  
  return <>
    <div
      className={
        category.displayName === pickMenu.displayName
        ? "detail-sitebar-menu-toggle-button-active"
        : "detail-sitebar-menu-toggle-button"
      }
      onClick={onClickHandler}
    >
      <p className={
        category.displayName === pickMenu.displayName
        ? "detail-sitebar-menu-toggle-button-text-active"
        : "detail-sitebar-menu-toggle-button-text"
      }>{pickMenu.displayName}</p>
    </div>
  </>
}

export default SidebarMenuToggleButton
