import React, { useState, useEffect, useRef } from 'react'

import ClickOutsideHandler from '../helpers/ClickOutsideHandler'

import styled from 'styled-components'

const Wrapper = styled.div`
  width: calc(${props => props.width} - 0.5em);
  position: fixed;
  background: white;
  border-radius: 5px;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 10;
`

const Text = styled.span`
  background: white;
  cursor: pointer;
  transition: background 330ms;
  padding: 0.2em 0.5em;
  font-size: 0.8em;

  &:hover {
    background: #d8d8d8;
  }
`

/**
 * This component will show input recommendation by search value
 * @param {React.RefAttributes} parentRef - this parent container reference
 * @param {String} search - search for recommendation
 * @param {Array} fullRecommendation - available recommendation list
 * @param {Number} width - width of recommendation list
 * @param {Number} maxConent - total maximum content
 * @param {Function} onSelect - event that trigger when select something
 * @param {Array} ignoreRecommendation - list text that won't be shown in recommendation
 */
function InputRecommendation({
  parentRef = null,
  search = '',
  fullRecommendation = [],
  width,
  maxContent = 5,
  onSelect,
  ignoreRecommendation = []
}) {
  const [ recommendations, setRecommendations ] = useState([])
  const wrapperRef = useRef(null)
  let top = null
  let left = null

  if (parentRef && parentRef.current) {
    const { x, y, height } = parentRef.current.getBoundingClientRect()

    top = y + height
    left = x
  }

  ClickOutsideHandler({
    ref: wrapperRef,
    eventHandler: () => {
      setRecommendations([])
    },
    exceptRefs: []
  })

  const selectableReccomendation = fullRecommendation
    .filter((recommendation) => {
      return !ignoreRecommendation.includes(recommendation)
    })

  useEffect(() => {
    function filterRecommendation() {
      if (!search) {
        setRecommendations([])

        return
      }

      const filteredRecommendations = []

      for (
        let i = 0;
        i < selectableReccomendation.length
        && filteredRecommendations.length < maxContent;
        i++
      ) {
        const text = selectableReccomendation[i]

        if (!text) {
          continue
        }

        const indexSearch = text.toLowerCase().indexOf(search.toLowerCase())

        if (indexSearch !== -1 && text !== search) {
          const initial = text.slice(0, indexSearch)
          const highlight = <mark>{text.slice(indexSearch, indexSearch + search.length)}</mark>
          const end = text.slice(indexSearch + search.length, text.length)

          filteredRecommendations.push({
            initial,
            highlight,
            end,
            initIndex: i
          })
        }
      }
      
      setRecommendations(filteredRecommendations)
    }

    filterRecommendation()
    
    return () => {
      setRecommendations([])
    }
  }, [search, fullRecommendation])

  return !recommendations.length
    ? null
    : <Wrapper
      width={width}
      ref={wrapperRef}
      style={{
        top,
        left
      }}
    >
      {
        recommendations.map((recommendation) => {
          return <Text
            key={recommendation.initIndex}
            onClick={() => {
              onSelect(selectableReccomendation[recommendation.initIndex])
            }}
          >{recommendation.initial}{recommendation.highlight}{recommendation.end}</Text>
        })
      }
    </Wrapper>
}

export default InputRecommendation
