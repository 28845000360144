import React from 'react'

import {
  generateColor
} from '../../../../../helpers'

import styled from 'styled-components'

const EntityBoxButton = styled.section.attrs((props) => {
  return {
    style: {
      height: props.shape === 'line' ? '0.08em' : '0.5em',
      backgroundColor: props.backgroundColor,
      border: props.shape === 'line'
        ? '0.5px solid ' + props.backgroundColor
        : '1px solid black'
    }
  }
})`
  width: 0.7em;
  margin-top: 0.1em;
  margin-left: 0.3em;
  margin-right: 0.2em;
  cursor: pointer;
`

/**
 * This function is a button component of cesium entity
 * @param shape the shape of entity (polyline, polygon, point)
 * @param color is the current color pick
 * @param alpha is the current alpha selected
 * @param onClickHandler is the event that trigger when clicked
 */
function EntityColorButton({
  shape,
  color,
  alpha,
  onClickHandler
}) {
  function generateBackgroundColor() {
    return generateColor({
      defaultColor: color,
      alpha,
      fromCesium: true
    })
  }

  return (
    <EntityBoxButton
      backgroundColor={generateBackgroundColor()}
      onClick={onClickHandler}
      shape={shape}
    />
  )
}

export default EntityColorButton
