import React from 'react'

import cesiumViewer from '../../apis/cesiumViewer'

import AlphaSlider from '../Sliders/AlphaSlider'

import {
  ReactComponent as MountainIcon
} from '../../images/Icons/mountain.svg'

import {
  CenteredFlexRow,
  CenteredFlexColumn
} from '../BasicStyle/FlexWrapper'

import styled from 'styled-components'

const Container = styled(CenteredFlexColumn)`
  position: absolute;
  z-index: 10;
  right: 0.2em;
  top: 12.5em;
  background-color: #0000009e;
  border-radius: 10px;
  padding: 0.3em;
`

const Title = styled(CenteredFlexRow)`
  align-items: center;
  margin-bottom: 0.3em;
  cursor: default;
`

const Text = styled.span`
  font-family: Abel;
  color: white;
  font-size: 0.6em;
`

function CesiumExaggeration() {
  return <div className="cesium-viewer-toolbar">
    <Container>
      <Title>
        <MountainIcon fill="white" width="1.5em" />
        <Text>Exaggeration</Text>
      </Title>
      <AlphaSlider
        width={'4em'}
        min={0}
        max={2}
        initialValue={1}
        valueOnChange={(value) => {
          if (cesiumViewer && cesiumViewer.viewer) {
            cesiumViewer.viewer.scene.globe.terrainExaggeration = value
          }
        }}
        textPosition={'left'}
        textSpace={'5em'}
        onDoubleClickHandler={(setValue) => {
          setValue(1)
        }}
      />
    </Container>
  </div>
}

export default CesiumExaggeration
