import * as actionTypes from '../../constants/store/actionTypes/camera'

const initialState = {
  smartView: false,
  smartSelect: false,
  smartFreeze: false,
  boundingBox: null,
  prospectMap: null
}

function cameraReducer(state = initialState, { type, payload }) {
  switch(type) {
    case actionTypes.SMART_VIEW_ON:
      return {
        ...state,
        smartView: true,
        smartSelect: false,
        smartFreeze: false
      }
    case actionTypes.SMART_VIEW_OFF:
      return {
        ...state,
        smartView: false
      }
    case actionTypes.SMART_SELECT_ON:
      return {
        ...state,
        smartView: false,
        smartFreeze: false,
        smartSelect: true
      }
    case actionTypes.SMART_SELECT_OFF:
      return {
        ...state,
        smartSelect: false
      }
    case actionTypes.SMART_FREEZE_ON:
      return {
        ...state,
        smartView: false,
        smartSelect: false,
        smartFreeze: true
      }
    case actionTypes.SMART_FREEZE_OFF:
      return {
        ...state,
        smartFreeze: false
      }
    case actionTypes.SET_BOUNDING_BOX:
      return {
        ...state,
        boundingBox: payload.boundingBox
      }
    case actionTypes.SET_PROSPECT_MAP:
      return {
        ...state,
        prospectMap: payload.map
      }
    case actionTypes.RESET_CAMERA:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default cameraReducer
