import React, { useState, useRef, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import LogoGeofix from "../../images/Logos/geofix.png";
import LogoGeoprosp from "../../images/Logos/geoprosp.png";
// import LogoPetrosea from "../../images/Logos/petrosea.png";
import { ReactComponent as Eye } from '../../images/Icons/eye.svg';
import Navbar from "../../components/Menubars/Navbar"
import YoutubeFrame from "../../components/Videos/YoutubeFrame";
import axios from "../../apis/axiosReact";
import "./login.css"; 

import iziToast from 'izitoast'
import {
  handleLogin
} from '../../apis/cookies'

function Login() {
  const isMounted = useRef(true)
  
  let history = useHistory()

  const [passwordShown, setPasswordShown] = useState(false);
  const [users, setUsers] = useState({
    email: '',
    password: ''
  });
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const submitLogin = (e) => {
    e.preventDefault()

    if (!users.email || !users.password) {
      iziToast.warning({
        title: 'Error',
        message: 'Please fill Email or Username and Password'
      })

      return
    }

    if (isMounted.current) {
      axios
        .post('/users', {
          email: users.email,
          password: users.password
        })
        .then(({ data }) => {
          handleLogin({
            accToken: data.access_token,
            rfsToken: data.refresh_token,
            username: data.username
          })
          
          history.push('/dashboard')
  
          setUsers({
            email: '',
            password: ''
          })
  
          iziToast.success({
            title: 'Success',
            message: 'Welcome to geoprosp'
          })
        })
        .catch(error => {
          if (error && error.response && error.response.data && error.response.data.errors && error.response.data.errors.error) {
            iziToast.warning({
              title: 'Error',
              message: error.response.data.errors.error
            })
          } else {
            iziToast.warning({
              title: 'Error',
              message: 'Server is down, please contact admin'
            })
          }
        })
    }
  };

  function onChange(e) {
    const { name, value } = e.target;
    setUsers(users => ({ ...users, [name]: value }));
  }

  useEffect(() => {
    return () => { // ComponentWillUnmount in Class Component
      isMounted.current = false;
    }
  }, [])

  return (
    <div className="content-block" style={{ width: '100vw' }}>
      <Navbar className="row"/>
      <div className="login-container img-bg-big">
        <div className="login-form-width">
          <form
            className="form-login"
            onSubmit={submitLogin}
          >
            <div className="row pt">
              <img src={LogoGeoprosp} alt="Geoprosp" className=" logo-login-main"/>
            </div>
            <div className="row pt justify-center">
              {/* <div className="col logo-container-login">
                <a href="https://www.petrosea.com/" target="_blank" rel="noreferrer" className="self-center">
                <img src={LogoPetrosea} alt="Petrosea" className="logo-login"/></a>
              </div> */}
              <div className="col logo-container-login">
                <a href="https://www.geofix-id.com/" target="_blank" rel="noreferrer" className="self-center">
                <img src={LogoGeofix} alt="Geofix" className="logo-login"/></a>
              </div>
            </div>
            <div className="row pt">
              <p className=" text-signin">Sign in to continue</p>
            </div>
            <div className="user-login">
              <label className="label-form-login">Email</label>
              <input
                type="email"
                className="form-control text-left-input"
                id="email"
                name="email"
                value={users.email}
                onChange={onChange}
                required
                placeholder="example@mail.com"
              />
            </div>
            <div className="row">
              <label className="label-form-login">Password</label>
            </div>
            <div className="pass-wrapper">
              <input
                id="password"
                placeholder="********"
                name="password"
                type={passwordShown ? "text" : "password"}
                className="form-control text-left-input"
                value={users.password}
                onChange={onChange}
                required
              />
              <Eye
                fill="#999999"
                className={
                  'icon-login'
                }
                alt="See Password"
                onClick={togglePasswordVisiblity}/>
            </div>
            <div className="row pt">
              <button
                type="submit"
                className="button"
                size="lg"
              > <span>Sign In</span>
              </button>
            </div>

            {/* <div className="pt">
              <p className="text-left-input">Don't have an account? <Link to="/register"> Sign up now </Link></p>
              <p className="text-left-input">Forgot your password? <Link to="/resetpasswordemail"> Reset Password </Link></p>
            </div> */}
          </form>
        </div>
        <YoutubeFrame/>
      </div>
    </div>
  );
}

export default Login