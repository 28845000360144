import { JulianDate } from 'cesium'

import {
  DEFAULT_FORM_EDITBAR,
  EDITBAR_NUMBER_DATA
} from '../../../constants/Forms/editbar'

import { ReactComponent as GpsIcon } from '../../../images/Icons/gps.svg'
import { ReactComponent as TextIcon } from '../../../images/Icons/text-document-line.svg'
import { ReactComponent as TextsIcon } from '../../../images/Icons/text-documents-line.svg'

/**
 * This method prettify text to display
 * @param {Array} text - text to prettify
 * @returns text that have been prettified
 */
export function pretifyText(text) {
  if (!text || typeof text !== 'string') {
    return text
  }

  return text
    .split('_')
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(' ')
}

/**
 * This method used to generate default list form based on given parameters
 * @param {Cesium.Entity.Property} cesiumProperties - list of array of object title and value for form parameters
 * @returns list form default value to be render
 */
export function generateParametersFromCesium(cesiumProperties) {
  const now = JulianDate.now()
  
  return DEFAULT_FORM_EDITBAR
    .map((parameter) => {
      if (!cesiumProperties[parameter.title] || !cesiumProperties[parameter.title].getValue(now)) {
        return parameter
      }

      return {
        ...parameter,
        value: cesiumProperties[parameter.title].getValue(now)
      }
    })
}

/**
 * This method used to generate default list form based on given parameters
 * @param {Object} options - optional parameters
 * @param {Array} options.parameters - list of array of object title and value for form parameters
 * @param {Number} options.longitude - position in longitude
 * @param {Number} options.latitude - position in latitude
 * @returns list form default value to be render
 */
export function getDefaultListForm(options = {}) {
  const {
    parameters = DEFAULT_FORM_EDITBAR,
    longitude = 0,
    latitude = 0
  } = options

  const listForm = []
  
  const requiredParams = {
    longitude: true,
    latitude: true,
    deposite_name: true,
    major_commodity: true
  }

  parameters.forEach((parameter) => {
    let { title, value } = parameter

    let type = 'input'
    let Icon = null
    let dataType = null

    if (title === 'longitude' || title === 'latitude') {
      Icon = <GpsIcon width="1em" />

      if (title === 'longitude') {
        value = longitude
      } else {
        value = latitude
      }
    }

    if (Array.isArray(value)) {
      value = value.join(',')

      type = 'multiInput'
      dataType = 'string'

      if (!Icon) {
        Icon = <TextsIcon width="1em" />
      }
    } else {
      if (EDITBAR_NUMBER_DATA[title]) {
        dataType = 'number'
      } else {
        dataType = 'string'
      }

      type = 'input'

      if (!Icon) {
        Icon = <TextIcon width="1em" />
      }
    }

    listForm.push({
      title,
      showTitle: pretifyText(title),
      value,
      type,
      dataType,
      Icon,
      required: !!requiredParams[parameter.title]
    })
  })

  return listForm
}

export function generateResourceKeywords() {
}