import styled from 'styled-components'

const Text = styled.p.attrs((props) => {
  return {
    style: {
      margin: props.margin || 0,
      fontSize: props.fontSize || '1rem',
      fontWeight: props.fontWeight || 400,
      overflowX: props.overflowX || 'auto',
      width: props.width,
      cursor: props.pointer ? 'pointer': 'default'
    }
  }
})`
  font-family: Abel;
`

export default Text