import {
  GLOBE_MIDDLE_BUTTON_MODIFIER,
  GLOBE_SCROLL_MODIFIER
} from '../../../../constants/Menubars/GlobeMenubar'

export function middleScroll (
  event,
  middleHold,
  setMiddleHold,
  setCursorY
) {
  if (event.button === 1) {
    setMiddleHold(!middleHold)
    setCursorY(event.clientY)
  }
}

export function scroll (
  event,
  scrollCount,
  setScrollCount,
  timer,
  setTimer
) {
  event.stopPropagation()

  if (!scrollCount.wait && !timer) {
    setScrollCount({
      ...scrollCount,
      wait: true
    })

    let movement = 0

    if (event.deltaY > 0) {
      movement = -GLOBE_SCROLL_MODIFIER * 2
    } else if (event.deltaY < 0) {
      movement = GLOBE_SCROLL_MODIFIER * 2
    }

    let targetPosition = Math.ceil((scrollCount.currentPosition + movement) / 20) * 20

    setScrollCount({
      ...scrollCount,
      targetPosition
    })

    let currentScroll = {
      currentPosition: scrollCount.currentPosition,
      targetPosition
    }

    const interval = setInterval(function () {
      if (currentScroll.currentPosition === currentScroll.targetPosition) {
        clearInterval(interval)
        setScrollCount({
          ...currentScroll,
          wait: false
        })
        setTimer(null)
      } else {
        let difference = currentScroll.targetPosition - currentScroll.currentPosition
    
        if (difference > 2) {
          difference = 2
        } else if (difference < -2) {
          difference = -2
        }

        currentScroll.currentPosition += difference

        setScrollCount({
          ...currentScroll
        })
      }
    }, 30)

    setTimer(interval)
  }
}

export function mouseMove (
  cursorY,
  event,
  middleHold,
  scrollCount,
  setCursorY,
  setScrollCount
) {
  if (middleHold) {
    let movement = event.clientY - cursorY

    setCursorY(event.clientY)
    setScrollCount({
      ...scrollCount,
      currentPosition: scrollCount.currentPosition + movement / GLOBE_MIDDLE_BUTTON_MODIFIER,
      targetPosition: scrollCount.currentPosition + movement / GLOBE_MIDDLE_BUTTON_MODIFIER,
      wait: false
    })
  }
}
