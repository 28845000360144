import React from 'react'

import styled from 'styled-components'

import {
  LIGHT_WHITE
} from '../../shares/ColorTemplate'

const Text = styled.div`
  display: flex;
  font-family: Abel;
  cursor: pointer;
  transition: background 330ms;
  padding: 0.4em;
  font-size: 0.8em;
  
  &:hover {
    background: ${LIGHT_WHITE.hover};
  }
`

/**
 * This component will creat Input Text Button to click selected event
 * @param {String} title is the title of the content
 * @param {Function}
 * @returns 
 */
function InputText({
  title,
  clickEvent,
  closeContext
}) {
  return <Text
    onClick={() => {
      clickEvent()
      closeContext()
    }}
  >{title}</Text>
}

export default InputText
