import React from 'react'

import styled from 'styled-components'

const CoordinateText = styled.span`
  /* margin-right: 5px; */
  margin-left: 5px;
  width:70px;
  align-self: center;
`

function CesiumCoordinate({ coordinate }) {
  if (!coordinate) {
    return null
  }

  return <>
    <CoordinateText>
      {coordinate.split(';')[0]}
    </CoordinateText>
    <CoordinateText>
      {coordinate.split(';')[1]}
    </CoordinateText>
  </>
}

export default CesiumCoordinate
