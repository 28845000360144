import axios from 'axios'
import * as Cesium from 'cesium'
import * as d3 from 'd3'

const date = new Date()

const DEFAULT_VALUE_PROPERTIES = {
  limit: 20000,
  minMagnitude: 0,
  maxMagnitude: 10,
  maxDepth: 1000,
  minDepth: -100,
  endTime: date.toISOString().split('T')[0],
  startTime: new Date(date.setDate(date.getDate() - 7)).toISOString().split('T')[0],
  minlatitude: -11,
  minlongitude: 93,
  maxlatitude: 10,
  maxlongitude: 143
}

export async function fetch({
  map,
  params,
  fetchFinish
}) {
  try {
    if (params.withgeojson) {
      if (!map.mapProperties) {
        map.mapProperties = DEFAULT_VALUE_PROPERTIES
      }

      const {
        limit,
        minMagnitude,
        maxMagnitude,
        minDepth,
        maxDepth,
        endTime,
        startTime,
        minlatitude,
        minlongitude,
        maxlatitude,
        maxlongitude
      } = map.mapProperties

      let urlPath = 'https://earthquake.usgs.gov/fdsnws/event/1/query?format=geojson'
    
      if (startTime !== null) {
        urlPath += '&starttime=' + startTime
      }
    
      if (endTime !== null) {
        urlPath += '&endtime=' + endTime
      }

      if (minDepth !== null) {
        urlPath += '&mindepth=' + minDepth
      }
    
      if (maxDepth !== null) {
        urlPath += '&maxdepth=' + maxDepth
      }
    
      if (minMagnitude !== null) {
        urlPath += '&minmagnitude=' + minMagnitude
      }
    
      if (maxMagnitude !== null) {
        urlPath += '&maxmagnitude=' + maxMagnitude
      }
    
      urlPath += '&reviewstatus=reviewed'
      urlPath += '&orderby=magnitude'
    
      if (limit !== null) {
        urlPath += '&limit=' + limit
      }

      if (minlatitude, minlongitude, maxlatitude, maxlongitude) {
        urlPath += `&minlatitude=${minlatitude}&minlongitude=${minlongitude}&maxlatitude=${maxlatitude}&maxlongitude=${maxlongitude}`
      }

      const { data } = await axios.get(urlPath, {
        cancelToken: params.cancelToken
      })

      fetchFinish()

      data.features.forEach(feature => {
        const depth = feature.geometry.coordinates.splice(-1, 1)

        feature.properties.depth = depth[0]
        feature.properties.startTime = startTime
        feature.properties.startTime = endTime
      })
    
      return {
        geojson: data
      }
    }
  } catch (error) {
    throw error
  }
}

export function parseFetch({ data }) {
  return data
}

export function generateScore({
  properties,
  map,
  groupBy
}) {
  if (!groupBy) {
    groupBy = 'depth'
  }

  const value = properties[groupBy].getValue(Cesium.JulianDate.now())

  let score = 0

  if (value > map.mapProperties.maxDepth) {
    score = 1
  } else if (value < map.mapProperties.minDepth) {
    score = 0
  } else {
    score = (value - map.mapProperties.minDepth) / map.mapProperties.maxDepth
  }

  if (map.cesiumLayer.inverse) {
    return 1 - score
  } else {
    return score
  }
}

export function getColor(entity, {
  map,
  groupBy
}) {
  const score = generateScore({
    properties: entity.properties,
    map,
    groupBy
  })

  const [ red, green, blue ] = d3
    [map.colorScale](score)
    .slice(4, -1)
    .split(', ')

  return {
    red,
    green,
    blue,
    alpha: map.cesiumLayer.alpha
  }
}

export function modifyEntity(entity, {
  map
}) {
  const { mag } = entity.properties.getValue(Cesium.JulianDate.now())

  entity.point.scaleByDistance = new Cesium.NearFarScalar(
    1.5e2, mag / 2 * map.pixelSize / 10, 8.0e6, mag / 8 * map.pixelSize / 10
  )
}
