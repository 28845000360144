import * as Cesium from 'cesium'

import cesiumViewer from './cesiumViewer'

import store from '../store'

import {
  findPosition
} from './cesium'

export function cesiumMineralOccurrences(event, mineralOccurrences, viewer) {
  if (
    mineralOccurrences.editorCesiumState.add
    && !mineralOccurrences.editorCesiumState.entity
  ) {
    addMethod(event, mineralOccurrences, viewer)
  } else if (
    mineralOccurrences.editorCesiumState.move
    && mineralOccurrences.editorCesiumState.entity
    && !mineralOccurrences.holdMove
  ) {
    moveStartMethod(event, mineralOccurrences, viewer)
  } else if (
    mineralOccurrences.editorCesiumState.move
    && mineralOccurrences.editorCesiumState.entity
  ) {
    moveMethod(event, mineralOccurrences, viewer)
  }
}

export function addMethod(event, mineralOccurrences, viewer) {
  const ellipsoid = viewer.scene.globe.ellipsoid

  const position = findPosition({
    event,
    ellipsoid,
    type: 'both'
  })

  const { display } = store.getState((state) => {
    return {
      display: state.display
    }
  })

  let map = null

  for (let i = 0; i < display.maps.length; i++) {
    if (display.maps[i].category === 'Occurrences') {
      map = display.maps[i]

      break
    }
  }

  if (map) {
    const entity = new Cesium.Entity({
      name: 'Occurrences',
      position: Cesium.Cartesian3.fromDegrees(
        position.longitude,
        position.latitude
      ),
      point: new Cesium.PointGraphics({
        pixelSize: map.pixelSize,
        disableDepthTestDistance: cesiumViewer.normalDisableDepthTestDistance,
        scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1, 8.0e6, 0.2),
        heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
        color: null
      })
    })

    map.addEntity(entity)

    entity.point.color = new Cesium.CallbackProperty(() => {
      return new Cesium.Color(
        map.colorEntities[entity.id] ? map.colorEntities[entity.id].red : 0,
        map.colorEntities[entity.id] ? map.colorEntities[entity.id].green : 0,
        map.colorEntities[entity.id] ? map.colorEntities[entity.id].blue : 0,
        map.colorEntities[entity.id] ? map.colorEntities[entity.id].alpha : 0
      )
    }, false)

    mineralOccurrences.setEditorCesiumState((editorState) => {
      const newState = {
        ...editorState,
        add: false,
        edit: false,
        move: false,
        entity
      }
      
      return newState
    })
  }
}

export function moveStartMethod(event, mineralOccurrences, viewer) {
  const listPick = viewer.scene.drillPick(event.position)

  for (let i = 0; i < listPick.length; i++) {
    if (
      listPick[i].id === mineralOccurrences.editorCesiumState.entity
    ) {
      mineralOccurrences.holdMove = true

      break
    }
  }

  if (mineralOccurrences.holdMove) {
    viewer.scene.screenSpaceCameraController.enableRotate = false
    viewer.scene.screenSpaceCameraController.enabletransform = false
    viewer.scene.screenSpaceCameraController.enableZoom = false
    viewer.scene.screenSpaceCameraController.enableTilt = false
    viewer.scene.screenSpaceCameraController.enableLook = false
  }
}

let moveTimeout = null

export function moveMethod(event, mineralOccurrences, viewer) {
  if (moveTimeout) {
    clearTimeout(moveTimeout)
    moveTimeout = null
  }

  cesiumViewer.mineralOccurrences.holdMove

  const ellipsoid = viewer.scene.globe.ellipsoid

  event.position = event.endPosition

  const position = findPosition({
    event,
    ellipsoid,
    type: 'both'
  })

  const entity = mineralOccurrences.editorCesiumState.entity

  entity.position = Cesium.Cartesian3.fromDegrees(
    position.longitude,
    position.latitude
  )

  moveTimeout = setTimeout(() => {
    mineralOccurrences.setEditorCesiumState((editorState) => {
      const newState = {
        ...editorState,
        move: true,
        entity
      }
      
      return newState
    })

    moveTimeout = null
  }, 3)
}

export function moveStopMethod(viewer) {
  cesiumViewer.mineralOccurrences.holdMove = false
  viewer.scene.screenSpaceCameraController.enableRotate = true
  viewer.scene.screenSpaceCameraController.enabletransform = true
  viewer.scene.screenSpaceCameraController.enableZoom = true
  viewer.scene.screenSpaceCameraController.enableTilt = true
  viewer.scene.screenSpaceCameraController.enableLook = true
}
