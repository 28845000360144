import { MIN_BIG_GLOBE_DEGREE, MAX_BIG_GLOBE_DEGREE } from '../../../../constants/Buttons/GlobeDetailButton'

import { getCos, getSin } from './degrees'

export function generateStyle (
  goldStyles,
  initialDegree,
  scrollCount,
  silverStyles,
  styles
) {
  styles.top = `calc(((100% + (110% + 6px) * ${getCos(initialDegree, scrollCount)}) / 2))`
  styles.left = `calc(100% / 2 - (200% + 6px) * ${getSin(initialDegree, scrollCount)} / 2 - 80%)`

  if (initialDegree + scrollCount * 160 / 81 >= MIN_BIG_GLOBE_DEGREE
    && initialDegree + scrollCount * 160 / 81 <= MAX_BIG_GLOBE_DEGREE) {
    styles.transform = 'scale(1.3)'
    silverStyles.visibility = 'hidden'
    goldStyles.visibility = 'visible'
  }
}
