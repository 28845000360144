import React from 'react'
import { Switch } from 'react-router-dom'

import Route from './Route'

// import Frontpage from '../pages/Frontpage' // soon probably??
import Help from '../pages/Help'
import Login from '../pages/Login'
import Register from '../pages/Register'
import Dashboard from '../pages/Dashboard'
import ResetPassword from '../pages/ResetPassword'
import ResetPasswordEmail from '../pages/ResetPasswordEmail'
import Success from '../pages/Success'
import CheckEmail from '../pages/CheckEmail'
import Failed from '../pages/Failed'
import ForgotPassword from '../pages/Login/ForgotPassword'
import Analytics from '../pages/Analytics'
import Database from '../pages/Database'

function Routes() {
  return (
    <Switch>
      <Route isPrivate={false} exact path="/" PageComponent={Login} />
      <Route isPrivate={false} path="/login" PageComponent={Login} />
      {/* <Route isPrivate={false} path="/register" PageComponent={Register} /> */}
      <Route isPrivate={false} path="/resetpassword" PageComponent={ResetPassword} />
      <Route isPrivate={false} path="/resetpasswordemail" PageComponent={ResetPasswordEmail} />
      <Route isPrivate={false} path="/forgotpassword" PageComponent={ForgotPassword} />
      <Route isPrivate={false} path="/success" PageComponent={Success} />
      <Route isPrivate={false} path="/fail" PageComponent={Failed} />
      <Route isPrivate={false} path="/checkemail" PageComponent={CheckEmail} />
      <Route path="/help" PageComponent={Help} isPrivate />
      <Route path="/dashboard" PageComponent={Dashboard} isPrivate />
      <Route path="/database" PageComponent={Database} isPrivate />
      <Route path="/analytics" PageComponent={Analytics} isPrivate />
      {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
      <Route PageComponent={() => <Login />} />
    </Switch>
  )
}

export default Routes
