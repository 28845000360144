import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'

import {
  toggleChatbar,
  minimizeChatbar
} from '../../../store/actions/chatbar'

import {
  setChatRoom
} from '../../../store/actions/socket'

import {
  getChatRoom
} from '../../../store/selectors/socket'

import {
  setCesiumContentRight
} from '../helpers/Rightbar'

import RightbarToggleButton from '../../Buttons/RightToggleButton'
import MessageContent from './MessageContent'
import RoomListContent from './RoomListContent'

import styled from 'styled-components'

const Container = styled.div.attrs((props) => {
  return {
    style: {
      width: props.width,
      right: props.toggle ? 0 : `-${props.width}`,
      backgroundImage: `url("${props.background}")`
    }
  }
})`
  position: absolute;
  background-size: cover;
  box-shadow: 3px 3px 9px 1px #ffffff;
  display: flex;
  flex-direction: column;
  z-index: 4003;
  overflow-y: hidden;
  max-height: 100%;
  height: 100%;
  transition: all 500ms;
  scroll-behavior: unset;
`

const InnerContainer = styled.div`
  position: fixed;
  height: 100vh;
  background: rgba(40,40,40,0.8);
`

function Chatbar({
  chat,
  toggleChatbar,
  minimizeChatbar,
  chatRoom,
  setChatRoom
}) {
  const [ feature, setFeature ] = useState('chat')
  const [ newChat, setNewChat ] = useState(false)

  const backgrounds = [
    'https://cdn1-production-images-kly.akamaized.net/gVKX4iWSPwBGRbLdJ263Ofus7IA=/640x640/smart/filters:quality(75):strip_icc():format(jpeg)/kly-media-production/medias/1072249/original/050244100_1448956997-grasberg.jpg',
    'https://jubi.co.id/wp-content/uploads/2020/08/Puncak-Sudirman-Puncak-Jaya-dan-Cartensz-Pyramid-Phinemo.com_.jpg'
  ]
  
  function backToChat() {
    setChatRoom({
      chatRoom: null
    })
    setNewChat(false)
    setFeature('chat')
  }

  function newChatHandler() {
    setNewChat(true)
    setFeature('message')
  }

  useEffect(() => {
    return () => {
      setFeature('chat')
    }
  }, [])

  useEffect(() => {
    setCesiumContentRight(chat)
  }, [chat])

  useEffect(() => {
    if (chatRoom) {
      setFeature('message')
    }
  }, [chatRoom])

  return <Container
    width={chat.width}
    toggle={chat.toggle && !chat.minimize}
    // background={backgrounds[Math.floor(Math.random() * backgrounds.length)]}
  >
    <InnerContainer>
      {chat.toggle && <RightbarToggleButton minimize={chat.minimize} minimizeBar={minimizeChatbar} />}
      {
        feature === 'chat' && <RoomListContent
          chatRoom={chatRoom}
          setChatRoom={setChatRoom}
          newChatHandler={newChatHandler}
        />
      }
      {
        feature === 'message' && <MessageContent
          chatRoom={chatRoom}
          backToChat={backToChat}
        />
      }
    </InnerContainer>
  </Container>
}

function mapStateToProps(state) {
  return {
    chat: state.chat,
    chatRoom: getChatRoom(state)
  }
}

const mapDispatchToProps = {
  toggleChatbar,
  minimizeChatbar,
  setChatRoom
}

export default connect(mapStateToProps, mapDispatchToProps)(Chatbar)
