import React, { useRef } from 'react';
import { capitalizeElement } from '../Charts/helpers/capitalizeFirstLetter';
import './tableStatistics.css'
import PerfectScrollbar from 'react-perfect-scrollbar';
import "react-perfect-scrollbar/dist/css/styles.css";
import { SpinningCircles, ThreeDots } from 'react-loading-icons'


function TableDesStatistics({...props}) {
    let {
        dataTable,
        sortResult,
        dataReady,
        size,
        dataLocal
    } = props;

    const fixedTableBodyListRef = useRef(null)
    const bodyListHeaderRef = useRef(null)
    const sidebarListRef = useRef(null)


    const statdesTitle = [
        {
            title: "Total Samples",
            key: 'n'
        },{
            title: "Mean",
            key: 'mean'
        },{
            title: 'Median',
            key: 'median'
        },{
            title: "Mode",
            key: 'mode'
        },{
            title: "Standard Deviation",
            key: 'std'
        },{
            title: "Variance",
            key : 'var'
        },{
            title: "Range",
            key: 'range'
        }
    ]

    const fixedSideList = () => {
        return(
            <div className="fixed-table__list fixed-table__list--fixed" ref={sidebarListRef}>
                {
                    statdesTitle.map((i,index)=>{
                        return (
                            <div className="fixed-table__row" key={`title-side-${index}`}>
                                <div className="fixed-table__col">{i.title}</div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const listDataTable = () => {
        return (
            <div className="fixed-table__list" ref={fixedTableBodyListRef}>
                <PerfectScrollbar
                onScrollX={container => bodyListHeaderRef.current.style.left ="-"+container.scrollLeft+"px"}>
                {
                    statdesTitle.map((i, index) => {
                        return(
                            <div key={`details-row-${index}`} className="fixed-table__row">
                                {
                                    sortResult.map((j,idx)=>{
                                        return (
                                            <div className="fixed-table__col" key={`details-row-${i}-${idx}`}>
                                                {dataTable[j][i.key]}
                                            </div>
                                        )
                                        })
                                }
                            </div>
                        )
                    })
                }
                </PerfectScrollbar>
            </div>
        )
    }

    return (
        dataReady?
        <div className="table-container">
        <div className="fixed-table">
            <div className="fixed-table__sidebar">
                <div className="fixed-table__header">
                    <div className="fixed-table__th" style={{width:'100%'}}>Element</div>
                </div>
                <div className="fixed-table__scrollable-sidebar">
                    {fixedSideList()}
                </div>
            </div>

            <div className="fixed-table__body">
                <div className="fixed-table__header">
                    <div className="fixed-table__scrollable-header" ref={bodyListHeaderRef}>
                        {
                            sortResult.map((i,index)=>{
                                return(
                                    <div className="fixed-table__th" key={`elements-${index}`} >
                                        {dataLocal? i : capitalizeElement(i.slice(0,i.lastIndexOf("_")))}
                                    </div>
                                )
                            })
                        }  
                    </div>
                </div>
                {listDataTable()}
            </div>
        </div>
    </div>
    :
    <ThreeDots
        height={size}
        width={size}
        fill='goldenrod'
        stroke="darkgoldenrod"
        speed="1"
        fillOpacity="0.5"
        strokeOpacity="0.5"
        strokeWidth="3"
    />
    );
}

export default TableDesStatistics;