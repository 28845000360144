import * as Cesium from 'cesium'

import * as turf from '@turf/turf'

import cesiumViewer from '../cesiumViewer'

import { PARSING_DATA_TYPE } from './constant'

import {
  coordinateFromCesiumEntityToTurf
} from './helpers/coordinateRange'

let barLegend = null
let setLegendConfig = null
let delayTimeout = null // for increasing performance

/**
 * Check intersection from entity based on geom in bounding area
 * @param {Cesium.Entity} entity is cesium entity
 * @param {String} geom is selected geometry
 * @param {Object} bounding is the bounding section to select
 */
export function checkIntersection(entity, geom, bounding) {
  if (!bounding) {
    return false
  }

  const boundingPolygon = turf.polygon([
    bounding.polygon.map((coordinate) => {
      return coordinate.map(numberCoordinate => Cesium.Math.toRadians(numberCoordinate))
    })
  ])

  if (PARSING_DATA_TYPE[geom] === 'point') {
    const point = coordinateFromCesiumEntityToTurf(entity, geom)
    
    return turf.booleanPointInPolygon(turf.point(point), boundingPolygon)
  } else if (PARSING_DATA_TYPE[geom] === 'polygon') {
    const polygon = coordinateFromCesiumEntityToTurf(entity, geom)

    return turf.intersect(turf.polygon(polygon), boundingPolygon)
  } else if (PARSING_DATA_TYPE[geom] === 'polyline') {
    const polyline = coordinateFromCesiumEntityToTurf(entity, geom)
    
    for (let i = 0; i < polyline.length; i++) {
      const point = turf.point(polyline[i])

      const intersect = turf.booleanPointInPolygon(point, boundingPolygon)

      if (intersect) {
        return true
      }
    }
  }

  return false
}

function handleTilesetLegend({
  map,
  newBarLegend,
  name,
  key
}) {
  if (!newBarLegend[name]) {
    newBarLegend[name] = {
      ...barLegend[name],
      keys: [key]
    }
  } else {
    newBarLegend[name].keys.push(key)
  }
}

function handleVectorLegend({
  map,
  newBarLegend,
  name,
  key,
  barLegend
}) {
  if (!map.cesiumLayer.groupEntities.group) {
    return
  }

  const entities = map.cesiumLayer.groupEntities.group[key.label]

  if (!entities) {
    return
  }

  // const geom = barLegend[name].map.geom

  for (let j = 0; j < entities.length; j++) {
    // const intersection = checkIntersection(entities[j], geom, cesiumViewer.fullSizeBox)
    const intersection = true

    if (intersection) {
      if (!newBarLegend[name]) {
        newBarLegend[name] = {
          ...barLegend[name],
          keys: [key]
        }
      } else {
        newBarLegend[name].keys.push(key)
      }
      break
    }
  }
}

export function checkEntitiesInCesium() {
  if (!barLegend || !setLegendConfig) {
    return
  }

  if (delayTimeout) {
    clearTimeout(delayTimeout)
  }

  delayTimeout = setTimeout(() => {
    const customLegend = {
      numerical: {},
      other: {}
    }
  
    const newBarLegend = {}

    for (const name in barLegend) {
      if (!barLegend[name]) {
        continue
      }
      
      const { keys, map } = barLegend[name]
  
      for (let i = 0; i < keys.length; i++) {
        if (map.tilesetLegend) {
          handleTilesetLegend({
            map,
            newBarLegend,
            name,
            key: keys[i]
          })

          continue
        }

        handleVectorLegend({
          map,
          newBarLegend,
          name,
          key: keys[i],
          barLegend
        })
      }
    }
  
    for (const name in newBarLegend) {
      const legend = newBarLegend[name]

      if (legend.map.cesiumLayer.fieldAttribute.selected === 'numerical') {
        customLegend.numerical[name] = legend
      } else {
        customLegend.other[name] = legend
      }
    }

    setLegendConfig(customLegend)
  }, 331)
}

export function connectLegend(config, setConfig) {
  barLegend = config
  setLegendConfig = setConfig

  if (cesiumViewer.viewer && cesiumViewer.viewer.scene) {
    cesiumViewer.legendToggle = true
    checkEntitiesInCesium()
  } else {
    setTimeout(() => {
      connectLegend()
    }, 330)
  }
}

export function disconnectLegend() {
  cesiumViewer.legendToggle = false
}
