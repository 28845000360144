export function getChatSocket(state) {
  return state.socket.chatSocket
}

export function getChatRooms(state) {
  return state.socket.chatRooms
}

export function getChatRoom(state) {
  return state.socket.chatRoom
}

export function getRoomSocket(state) {
  return state.socket.roomSocket
}

export function getRoomsObj(state) {
  return state.socket.chatRoomsObj
}

export function getUserSocket(state) {
  return state.socket.userSocket
}