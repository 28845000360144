import React from 'react'

import FocusContainer, {
  Header,
  LinkText,
  Paragraph
} from '../StyledComponent'
import ChatPNG from '../HelpImage/chat.png'
import { LIST_HELP } from '../constant'


function HelpChat(props) {
  const flyTo=(x,y)=>{
    let cloneOpened = []
    cloneOpened.push(y)
    
    props.setOpened(cloneOpened)

    props.setFocus(x)
  }
  return <>
    <FocusContainer {...props}>
      <Header>{LIST_HELP['dashboard'].detail.chatButton.header}</Header>
      <img src={ChatPNG} alt="Chat image" width="600px" />
      <Paragraph>
        a. New Message Button, click to start new messages. For further information check <LinkText onClick={()=>flyTo('newChat','chat')}>How to start new conversation in chat feature?</LinkText>
      </Paragraph>
      <Paragraph>
        b. Search Box, type here to search existing messages.
      </Paragraph>
      <Paragraph>
        c. Existing Messages, summary of information about messages that have been created.
      </Paragraph>
    </FocusContainer>
  </>
}

export default HelpChat
