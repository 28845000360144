import React from 'react'

import { connect } from 'react-redux'

import {
  TextContainer,
  TitleTextParagraph,
  TextParagraph
} from './OptionStyle.jsx'

function SelectOptionType({ status, setOptionFilter }) {
  const prettyStatus = status && status.length
    ? status[0].toUpperCase() + status.slice(1)
    : ''

  function onClickHandler() {
    setOptionFilter((option) => {
      if (option.active && option.status === 'status') {
        return {
          active: false,
          status: ''
        }
      }

      return {
        active: true,
        status: 'status',
        value: status
      }
    })
  }

  return <TextContainer>
    <TitleTextParagraph>Status</TitleTextParagraph>
    <TextParagraph
      onClick={onClickHandler}
    >{prettyStatus}</TextParagraph>
  </TextContainer>
}

function mapStateToProps({ sites }) {
  return {
    status: sites.ranking.status
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SelectOptionType)
