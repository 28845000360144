import React, { useState, useEffect, useRef } from 'react'

import * as Cesium from 'cesium'
import cesiumViewer from '../../../../../../apis/cesiumViewer'
import { generatePolygonOutline } from '../../../../../../apis/cesiumMap/polygonOutline'

import axios from 'axios'
import axiosReact from '../../../../../../apis/axiosReact'

import { connect } from 'react-redux'

import {
  setCatalogSearch
} from '../../../../../../store/actions/catalogSearch'

import DefaultSearch from '../../../../../SearchBoxs/DefaultSearch'

import FlexWrapper from '../../../shares/FlexWrapper'

import SearchRecommendation from './SearchRecommendation'

import { SINGULAR_ADMINISTRATION } from './constant'

function SearchLocation({
  sidebarState,
  setCatalogSearch,
  searchKeyword
}) {
  const containerRef = useRef(null)

  const [ keyword, setKeyword ] = useState('')
  const [ data, setData ] = useState({
    Countries: [],
    Islands: [],
    Provinces: [],
    Cities: [],
    Tenements: [],
    BusinessNames: []
  })
  const [ loading, setLoading ] = useState(false)
  const [ dataSource, setDataSource ] = useState(null)
  let source = null

  function convertData(data) {
    const result = {
      Countries: [],
      Islands: [],
      Provinces: [],
      Cities: [],
      Tenements: [],
      BusinessNames: []
    }

    if (!data || !data.length) {
      return result
    }

    for (let i = 0; i < data.length; i++) {
      result.Countries.push({
        country: data[i].country
      })

      if (data[i].islands) {
        for (let j = 0; j < data[i].islands.length; j++) {
          for (let k = 0; k < data[i].islands[j].islands.length; k++) {
            result.Islands.push({
              country: data[i].islands[j].country,
              island: data[i].islands[j].islands[k]
            })
          }
        }
      }

      if (data[i].provinces) {
        for (let j = 0; j < data[i].provinces.length; j++) {
          for (let k = 0; k < data[i].provinces[j].provinces.length; k++) {
            result.Provinces.push({
              country: data[i].provinces[j].country,
              province: data[i].provinces[j].provinces[k]
            })
          }
        }
      }

      if (data[i].cities) {
        for (let j = 0; j < data[i].cities.length; j++) {
          for (let k = 0; k < data[i].cities[j].provinces.length; k++) {
            const city = {
              country: data[i].cities[j].country,
              province: data[i].cities[j].provinces[k].province
            }

            for (let l = 0; l < data[i].cities[j].provinces[k].cities.length; l++) {
              result.Cities.push({
                ...city,
                city: data[i].cities[j].provinces[k].cities[l]
              })
            }
          }
        }
      }
    }

    return result
  }

  async function searchProcessHandler(searchKeyword) {
    try {
      if (!searchKeyword) {
        setKeyword('')

        return
      } else if (searchKeyword === keyword) {
        return
      }

      setKeyword(searchKeyword)
      setLoading(true)

      if (source) {
        source.cancel('')
      }

      source = axios.CancelToken.source()

      const response = await axiosReact.get('/search/administration', {
        params: {
          keywords: searchKeyword,
          // withgeojson: true // edit it later
        },
        cancelToken: source.token
      })

      const listData = convertData(response.data)
      const TENEMENT_LIMIT = 10

      const { data } = await axiosReact.get('/search/tenements', {
        params: {
          keywords: searchKeyword,
          withgeojson: false,
          withsummary: false,
          limit: TENEMENT_LIMIT
        },
        cancelToken: source.token
      })

      data.forEach((business) => {
        listData.BusinessNames.push({
          ...business,
          id: business.id,
          firstText: business.bussiness_name,
          secondText: business.name_district
        })
      })

      setData(listData)

      // Error will be handled with other component
      return {
        status: true
      }
    } catch (error) {
      // purposed error
    } finally {
      setLoading(false)
    }
  }

  function stopSearchHandler() {
    setKeyword('')
    setData({
      Countries: [],
      Islands: [],
      Provinces: [],
      Cities: []
    })
    setLoading(false)
  }

  function selectHandler({
    newSelectedKeyword,
    searchLevel,
    search
  }) {
    stopSearchHandler()
    let selectedKeyword = newSelectedKeyword

    if (selectedKeyword === searchKeyword) {
      selectedKeyword += ' '
    }

    setCatalogSearch({
      searchLevel,
      search,
      searchKeyword: selectedKeyword
    })
  }

  function generateDataSource(geojson) {
    if (dataSource) {
      if (
        cesiumViewer.viewer.dataSources
          .indexOf(dataSource) !== -1
      ) {
        cesiumViewer.viewer.dataSources.remove(dataSource)
      }
    }

    const newDataSource = new Cesium.CustomDataSource('search-administration')
    cesiumViewer.viewer.dataSources.add(newDataSource)

    generatePolygonOutline(geojson, newDataSource)

    setDataSource(newDataSource)

    const rawPositions = []

    for (let i = 0; i < geojson.features.length; i++) {
      const feature = geojson.features[i]

      const coordinates = feature.geometry.coordinates

      for (let j = 0; j < coordinates.length; j++) {
        for (let k = 0; k < coordinates[j].length; k++) {
          for (let l = 0; l < coordinates[j][k].length; l++) {
            rawPositions.push(...coordinates[j][k][l].flat(3))
          }
        }
      }
    }

    const positions = []

    for (let i = 0; i < rawPositions.length; i += 2) {
      positions.push({
        longitude: rawPositions[i],
        latitude: rawPositions[i + 1]
      })
    }

    if (positions && positions.length) {
      let cartographic = positions[0]
      let west = cartographic.longitude
      let south = cartographic.latitude
      let east = cartographic.longitude
      let north = cartographic.latitude

      for (let i = 1; i < positions.length; i++) {
        cartographic = positions[i]
        
        if (west > cartographic.longitude) {
          west = cartographic.longitude
        }

        if (east < cartographic.longitude) {
          east = cartographic.longitude
        }
        
        if (south > cartographic.latitude) {
          south = cartographic.latitude
        }

        if (north < cartographic.latitude) {
          north = cartographic.latitude
        }
      }

      const rectangle = new Cesium.Rectangle(
        Cesium.Math.toRadians(west - 0.002),
        Cesium.Math.toRadians(south - 0.001),
        Cesium.Math.toRadians(east + 0.002),
        Cesium.Math.toRadians(north + 0.001)
      )

      cesiumViewer.viewer.scene.camera
        .flyTo({
          destination: rectangle
        })
    }
  }

  useEffect(() => {
    stopSearchHandler()

    return () => {
      if (dataSource) {
        if (
          cesiumViewer.viewer.dataSources
            .indexOf(dataSource) !== -1
        ) {
          cesiumViewer.viewer.dataSources.remove(dataSource)
        }
      }
      setDataSource(null)
      stopSearchHandler()
    }
  }, [])

  return <FlexWrapper
    margin="0.3em 0.5em 0 0.5em"
    style={{
      position: 'relative'
    }}
    ref={containerRef}
  >
    <DefaultSearch
      active={true}
      searchProcess={searchProcessHandler}
      stopSearch={stopSearchHandler}
      setValue={searchKeyword}
    />
    {
      keyword && <SearchRecommendation
        parentRef={containerRef}
        data={data}
        loading={loading}
        selectHandler={selectHandler}
        generateDataSource={generateDataSource}
      />
    }
  </FlexWrapper>
}

function mapStateToProps(state) {
  return {
    sidebarState: state.sidebarState,
    search: state.catalogSearch.search,
    searchKeyword: state.catalogSearch.searchKeyword
  }
}

const mapDispatchToProps = {
  setCatalogSearch
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchLocation)
