import * as actionTypes from '../../constants/store/actionTypes/cesium'

export function setHoverAreaEntity(payload) {
  return {
    type: actionTypes.SET_HOVER_AREA_ENTITY,
    payload
  }
}

export function removeHoverAreaEntity() {
  return {
    type: actionTypes.REMOVE_HOVER_AREA_ENTITY
  }
}

export function setHoverMapEntity(payload) {
  return {
    type: actionTypes.SET_HOVER_MAP_ENTITY,
    payload
  }
}

export function removeHoverMapEntity() {
  return {
    type: actionTypes.REMOVE_HOVER_MAP_ENTITY
  }
}

/////////////////////////////////////////////
//                                         //
//              DRAW MAPS                  //
//                                         //
/////////////////////////////////////////////

export function fetchDrawData(payload) {
  return {
    type: actionTypes.FETCH_DRAW_DATA,
    payload
  }
}

export function fetchDrawDataSuccess(payload) {
  return {
    type: actionTypes.FETCH_DRAW_DATA_SUCCESS,
    payload
  }
}

export function fetchDrawDataError(error) {
  return {
    type: actionTypes.FETCH_DRAW_DATA_ERROR,
    error
  }
}

export function updateDrawData(payload) {
  return {
    type: actionTypes.UPDATE_DRAW_DATA,
    payload
  }
}

export function updateDrawDataSuccess(payload) {
  return {
    type: actionTypes.UPDATE_DRAW_DATA_SUCCESS,
    payload
  }
}

export function updateDrawDataError(error) {
  return {
    type: actionTypes.UPDATE_DRAW_DATA_ERROR,
    error
  }
}

export function checkDrawData(payload) {
  return {
    type: actionTypes.CHECK_DRAW_DATA,
    payload
  }
}

export function checkDrawDataSuccess(payload) {
  return {
    type: actionTypes.CHECK_DRAW_DATA_SUCCESS,
    payload
  }
}

export function checkDrawDataError(error) {
  return {
    type: actionTypes.CHECK_DRAW_DATA_ERROR,
    error
  }
}

export function uncheckDrawData(payload) {
  return {
    type: actionTypes.UNCHECK_DRAW_DATA,
    payload
  }
}

export function uncheckDrawDataSuccess(payload) {
  return {
    type: actionTypes.UNCHECK_DRAW_DATA_SUCCESS,
    payload
  }
}

export function uncheckDrawDataError(error) {
  return {
    type: actionTypes.UNCHECK_DRAW_DATA_ERROR,
    error
  }
}

export function removeAllDrawData(payload) {
  return {
    type: actionTypes.REMOVE_ALL_DRAW_DATA,
    payload
  }
}

export function removeAllDrawDataSuccess(payload) {
  return {
    type: actionTypes.REMOVE_ALL_DRAW_DATA_SUCCESS,
    payload
  }
}

export function removeAllDrawDataError(payload) {
  return {
    type: actionTypes.REMOVE_ALL_DRAW_DATA_ERROR,
    payload
  }
}

export function resetCesium(payload) {
  return {
    type: actionTypes.RESET_CESIUM,
    payload
  }
}
