// IUGS Classification diagram for mafic rocks based on modal percentages of three mafic minerals (plagioclase feldspar, pyroxene, and olivine)
// Hess, P. C., Origins of Igneous Rocks. Harvard University Press: Cambridge, 1989; p 336.

export const mafic = {
  info: 'IUGS Classification diagram for mafic rocks based on modal percentages of three mafic minerals (plagioclase feldspar, pyroxene, and olivine) (Hess, P. C., Origins of Igneous Rocks. Harvard University Press: Cambridge, 1989; p 336.)',
  data: [
    {
      class: 'Anorthosite',
      values: [
        {
          p: 100,
          px: 0,
          ol: 0
        },
        {
          p: 90,
          px: 0,
          ol: 10
        },
        {
          p: 90,
          px: 10,
          ol: 0
        }
      ],
      rotation: null
    },
    {
      class: 'Gabbro or Norite',
      values: [
        {
          p: 90,
          px: 10,
          ol: 0
        },
        {
          p: 90,
          px: 5,
          ol: 5
        },
        {
          p: 10,
          px: 85,
          ol: 5
        },
        {
          p: 10,
          px: 90,
          ol: 0
        }
      ],
      rotation: null
    },
    {
      class: 'Olivine Gabbro',
      values: [
        {
          p: 90,
          px: 5,
          ol: 5
        },
        {
          p: 10,
          px: 85,
          ol: 5
        },
        {
          p: 10,
          px: 5,
          ol: 85
        }
      ],
      rotation: null
    },
    {
      class: 'Troctolite',
      values: [
        {
          p: 90,
          px: 5,
          ol: 5
        },
        {
          p: 90,
          px: 0,
          ol: 10
        },
        {
          p: 10,
          px: 0,
          ol: 90
        },
        {
          p: 10,
          px: 5,
          ol: 85
        }
      ],
      rotation: null
    },
    {
      class: 'Plagiclase-bearing ultramafic rocks',
      values: [
        {
          p: 10,
          px: 90,
          ol: 0
        },
        {
          p: 0,
          px: 100,
          ol: 0
        },
        {
          p: 0,
          px: 0,
          ol: 100
        },
        {
          p: 10,
          px: 0,
          ol: 90
        }
      ],
      rotation: null
    }
  ]
}
