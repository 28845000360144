import React, { useState, useRef } from 'react'

import styled from 'styled-components'

import {
  MESSAGE_CONTENT_WIDTH
} from '../../style'

import ClickOutsideHandler from '../../../../helpers/ClickOutsideHandler'

export const EditableTextIcon = styled.input`
  border-width: 0;
  font-family: Abel;
  width: 100%;
  margin: 0.1em;

  &:focus {
    outline: none;
  }
`

export const MessageValueStyle = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${MESSAGE_CONTENT_WIDTH};
  border: 3px solid ${props => props.focus ? 'goldenrod' : 'white'};
  background: white;
  margin-left: 1em;
  border-radius: 5px;
  font-family: Abel;
  font-size: 0.75em;
  overflow: hidden;
  cursor: text;
  transition: all 330ms;
`

export function MessageValue({ children }) {
  const messageRef = useRef(null)
  const [ focus, setFocus ] = useState(false)

  ClickOutsideHandler({
    ref: messageRef,
    eventHandler: () => {
      setFocus(false)
    },
    exceptRefs: []
  })

  function setFocusStyleHandler() {
    setFocus(true)
  }

  return <MessageValueStyle
    ref={messageRef}
    onClick={setFocusStyleHandler}
    focus={focus}
  >
    {children}
  </MessageValueStyle>
}
