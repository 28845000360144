import React, { useState, useRef, useEffect } from 'react'

import { productionAPI } from '../../../apis/server'

import SitebarMenuBorderHeaderSpan from '../../Spans/SitebarMenuBorderHeaderSpan'

import './GlossaryMenu.css'

function GlossaryMenu({
  commodity
}) {
  const glossaryRef = useRef(null)

  const [glossary, setGlossary] = useState('')

  useEffect(()=>{
    async function fetchGlossary() {
      try {
        const { data } = await productionAPI.get(`/geoprosp/glossary`, {
          params: {
            commodity: commodity
          }
        })

        if (data && data.length && data[0]) {
          setGlossary(data[0].information)
        } 
      } catch (error) {
      }
    }
    
    fetchGlossary()
  },[commodity])

  return <>
    <div
      className="sitebar-menu-content"
    >
      <p className="sitebar-menu-content-header">
        <span ref={ glossaryRef }>Glossary Summary</span>
        <SitebarMenuBorderHeaderSpan
          headerRef={ glossaryRef }
          className={ 'sitebar-menu-border-header' }
        />
      </p>
      <p className="sitebar-menu-content-container" id="excSummary">
        <span dangerouslySetInnerHTML={{ __html: glossary}} />
      </p>
    </div>
  </>
}

export default GlossaryMenu
