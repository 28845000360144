import React, { useState, useRef, useEffect } from 'react'

import { productionAPI } from '../../../apis/server'

import SitebarMenuBorderHeaderSpan from '../../Spans/SitebarMenuBorderHeaderSpan'
import GeologyAndStructure from './GeologyAndStructure'
import YoutubeFrame from '../../Videos/YoutubeFrame'

import './SiteOverviewMenu.css'

function SiteOverviewMenu({
  selected,
  commodity
}) {
  const aboutRef = useRef(null)
  const factSheetRef = useRef(null)
  const geologyAndStructureRef = useRef(null)
  const geologicalMapRef = useRef(null)
  const videoRef = useRef(null)
  const [ toggleOccurences, setToggleOccurrences ] = useState(4)
  const [ geologyAssets, setGeologyAssets ] = useState(null)
  const [ imageAsset, setImageAsset ] = useState({
    geology: ''
  })

  useEffect(() => {
    let gridId = selected?.site?.id

    async function fetchGridId() {
      try {
        const response = await productionAPI.get(`/geofix/grid_specific/${gridId}`, {
          params: {
            commodity: commodity
          }
        })
        setGeologyAssets(response.data)
        
      } catch (error) {
      }
    }
    
    if (gridId !== null) {
      if (geologyAssets !== null) {
        if (geologyAssets.properties.grid_id !== gridId) {
          fetchGridId()
        }
      } else {
        fetchGridId()
      }
    }

    return () => {
      setGeologyAssets(null)
    }
  }, [selected.site])

  useEffect(() => {
    async function fetchGeologyImage() {
      try {
        const buffer = await productionAPI
          .get(`/geoprosp/grid_assets/geology/${selected.site.id}/${geologyAssets.properties.assets.geology.jpeg}`, {
            params: {
              commodity: commodity
            },
            responseType: 'arraybuffer'
          })
          .then(response => {
            return Buffer.from(response.data, 'binary').toString("base64")
          })

        setImageAsset((imageAsset) => {
          return {
            ...imageAsset,
            geology: buffer
          }
        })
      } catch (error) {
      }
    }

    if (geologyAssets?.properties?.assets?.geology?.jpeg) {
      fetchGeologyImage()
    }

    return () => {
      setImageAsset({
        geology: ''
      })
    }
  }, [geologyAssets])

  function renderCategory (category, cb) {
    if (!selected.content) {
      return 'On progress generate.'
    } else {
      if (!selected.content[category]) {
        return 'On progress generate.'
      } else {
        return cb()
      }
    }
  }

  function renderFactSheet () {
    const {
      district,
      province,
      belt,
      commodity,
      total_resources = null,
      occurrences = [],
      deposit_type = []
    } = selected.content.fact_sheet

    return <>
      <div 
        className="sitebar-menu-content-text fact-sheet-description"
      >
        Prospect Name &nbsp;
          { `: ${selected.content.name || selected.content.prospect_name || ''}` }
      </div>
      <div 
        className="sitebar-menu-content-text fact-sheet-description"
      >
        District &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          { `: ${district || ''}` }
      </div>
      <div 
        className="sitebar-menu-content-text fact-sheet-description"
      >
        Province&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          { `: ${province || ''}` }
      </div>
      <div 
        className="sitebar-menu-content-text fact-sheet-description"
      >
        Belt &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        { `: ${belt  || ''}` }
      </div>
      <div 
        className="sitebar-menu-content-text fact-sheet-description"
      >
        Commodity &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          { `: ${commodity  || ''}` }
      </div>
      <div 
        className="sitebar-menu-content-text fact-sheet-description"
      >
        Total Resource &nbsp;&nbsp;
          { `: ${`${(total_resources / 1e6).toFixed(3)} million ounces` || ''}` }
      </div>
      <div 
        className="sitebar-menu-content-text fact-sheet-description"
      >
        Occurrences &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
      </div>
      <ul style={{
        marginTop: 0,
        marginBottom: 0
      }}>
        {
          occurrences.map((occurence, index) => {
            if (index >= toggleOccurences) {
              return null
            }

            return <li key={index}>
              {occurence} ({deposit_type[index]})
            </li>
          })
        }
        {
          toggleOccurences <= occurrences.length
          && <div
            style={{
              cursor: 'pointer',
              color: 'blue'
            }}
            onClick={() => {
              if (toggleOccurences === 4) {
                setToggleOccurrences(occurrences.length + 1)
              } else {
                setToggleOccurrences(4)
              }
            }}
          >
            Show more
          </div>
        }
        {
          toggleOccurences !== 4
          && <div
          style={{
            cursor: 'pointer',
            color: 'blue'
          }}
          onClick={() => {
            setToggleOccurrences(4)
          }}
        >
          Hide
        </div>
        }
      </ul>
    </>
  }

  function renderGeologicalMap() {
    let gridId = selected?.site?.id

    if (!gridId && gridId !== 0) {
      return null
    }

    return <>
      {/* <p className="sitebar-menu-content-text mb-10">
        {imageAsset.geology}
      </p> */}
      <img
        className="sitebar-menu-content-image"
        src={`data:image/png;base64,${imageAsset.geology}`}
        alt={imageAsset.geology}
      />
    </>
  }

  return <>
    <div
      className="sitebar-menu-content"
    >
      <p className="sitebar-menu-content-header">
        <span ref={aboutRef}>Executive Summary</span>
        <SitebarMenuBorderHeaderSpan
          headerRef={aboutRef}
          className={'sitebar-menu-border-header'}
        />
      </p>
      <p className="sitebar-menu-content-container" id="excSummary">
        {
          renderCategory('executive_summary', () => {
            return <span dangerouslySetInnerHTML={{ __html: selected.content.executive_summary.slice(1,-1)}} />
          })
        }
      </p>
    </div>
    {/* <div className="sitebar-menu-content">
      <p className="sitebar-menu-content-header">
        <span ref={ factSheetRef }>Fact sheet</span>
        <SitebarMenuBorderHeaderSpan
          headerRef={ factSheetRef }
          className={ 'sitebar-menu-border-header' }
        />
      </p>
      <div className="sitebar-menu-content-container">
        {
          renderCategory('fact_sheet', () => {
            return renderFactSheet()
          })
        }
      </div>
    </div> */}
    {
      imageAsset.geology && <div className="sitebar-menu-content">
        <p className="sitebar-menu-content-header">
          <span ref={geologicalMapRef}>Geological Map</span>
          <SitebarMenuBorderHeaderSpan
            headerRef={geologicalMapRef}
            className={'sitebar-menu-border-header'}
          />
        </p>
        <div className="sitebar-menu-content-container">
          {
            renderGeologicalMap()
          }
        </div>
      </div>
    }
    {/* <div className="sitebar-menu-content">
      <p className="sitebar-menu-content-header">
        <span ref={ videoRef }>Video</span>
        <SitebarMenuBorderHeaderSpan
          headerRef={ videoRef }
          className={ 'sitebar-menu-border-header' }
        />
      </p>
      <div className="sitebar-menu-content-container">
        {
          renderCategory('alterationAndMineralization', () => {
            return <YoutubeFrame src={ selected.content.video } />
          })
        }
      </div>
    </div> */}
  </>
}

export default SiteOverviewMenu
