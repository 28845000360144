import React, { useState, useRef } from 'react'
import ReactLoading from 'react-loading'

function CustomReactLoading({
  status,
  progress,
  width = '1.5em',
  height = '0.75em',
  color = 'white',
  fontColor = 'black'
}) {
  const containerRef = useRef(null)

  const [ showProgress, setShowProgress ] = useState(true)

  function getStatus() {
    if (status === 'fetch') {
      return 'bubbles'
    } else if (status === 'send') {
      return 'cylon'
    } else {
      return 'bars'
    }
  }
  
  return <div
    ref={containerRef}
    style={{ position: 'relative', zIndex: 1000 }}
    onMouseEnter={() => setShowProgress(true)}
    onMouseLeave={() => setShowProgress(false)}
  >
    <ReactLoading
      type={getStatus()}
      color={color}
      width={width}
      height={height}
    />
    {
      containerRef.current
        &&
        <div style={{
          position: 'fixed',
          top: containerRef.current.getBoundingClientRect().top + 10
        }}>
          {
            progress !== null && showProgress
              &&
              <div style={{
                fontFamily: 'Abel',
                position: 'absolute',
                left: '2em',
                backgroundColor: 'white',
                color: fontColor,
                padding: '0.25em 0.5em',
                border: '1px solid black',
                borderRadius: '5px',
                width: '4em',
                fontSize: '0.8rem'
              }}>
                {progress}
              </div>
          }
        </div>
    }
  </div>
}

export default CustomReactLoading
