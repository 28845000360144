import { useState, useEffect } from 'react'

import cesiumViewer from '../../apis/cesiumViewer'

/**
 * This react component used to handle all pointer event state in cesium map
 * @param {Array} listPointerEvent - array of pointer events
 * @param {Object} selection - default state of selection
 * @param {Function} setSelection - method to change selection state
 */
function CesiumUsePointer({
  listPointerEvent = [],
  selection,
  setSelection
}) {
  const [ active, setActive ] = useState(null)

  useEffect(() => {
    let activePointer = null

    for (let i = 0; i < listPointerEvent.length; i++) {
      if (listPointerEvent[i].status) {
        activePointer = listPointerEvent[i]
        break
      }
    }

    if (activePointer) {
      setActive(activePointer)
    } else {
      setActive(null)
      setSelection(true)
      cesiumViewer.sectionStatus.selection = true
    }
  }, [...listPointerEvent])

  useEffect(() => {
    if (active) {
      listPointerEvent.forEach(pointerEvent => {
        if (pointerEvent.name !== active.name) {
          pointerEvent.otherPointerOnHandler(active)
        }
      })

      setSelection(false)
      cesiumViewer.sectionStatus.selection = false
    }
  }, [active])

  useEffect(() => {
    if (selection) {
      listPointerEvent.forEach(pointerEvent => {
        pointerEvent.selectionOnHandler()
      })
    }
  }, [selection])
}

export default CesiumUsePointer
