import React from 'react'

import { connect } from 'react-redux'

import {
  smartSelectOn,
  smartSelectOff
} from '../../../../store/actions/camera'

import { ReactComponent as SelectIcon } from '../../../../images/Icons/select-rectangle.svg'
import cesiumViewer from '../../../../apis/cesiumViewer'

import iziToast from 'izitoast'

import {
  ToggleSVGButton
} from '../../../shares/ToggleSVGIcon'

function SmartSelectButton({
  smartView,
  smartSelect,
  smartFreeze,
  smartSelectOn,
  smartSelectOff,
  prospectMap
}) {
  function onClickHandler() {
    if (!prospectMap || !prospectMap.dataSource) {
      return iziToast.warning({
        title: 'Initial Loading',
        message: 'Please waiting until first render process complete'
      })
    }

    if (smartSelect) {
      smartSelectOff()

      if (smartView === false && smartFreeze === false) {
        prospectMap.dataSource.show = false
      }
      
      cesiumViewer.getCameraBoundingBox()
    } else {
      smartSelectOn()
      prospectMap.dataSource.show = true
    }
  }

  return <ToggleSVGButton flex margin="0.5em" active={smartSelect}>
    <SelectIcon
      title="Smart Select"
      alt="smart-select"
      onClick={onClickHandler}
    />
  </ToggleSVGButton>
}

function mapStateToProps(state) {
  return {
    smartView: state.camera.smartView,
    smartSelect: state.camera.smartSelect,
    smartFreeze: state.camera.smartFreeze,
    prospectMap: state.camera.prospectMap
  }
}

const mapDispatchToProps = {
  smartSelectOn,
  smartSelectOff
}

export default connect(mapStateToProps, mapDispatchToProps)(SmartSelectButton)
