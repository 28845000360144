import * as d3 from 'd3'

import axiosReact from '../../../../apis/axiosReact'

import { COLOR_D3_QGIS_TEMPLATES } from '../../../qml/colorTemplate'

import {
  findKeyProperties
} from '../../properties'

import {
  generateScaleMethod,
  getScore
} from '../../../../apis/cesiumMap/d3ScaleMethod'

/**
 * Get or set default list filter
 * @param {Object} map object of the current map 
 * @returns default list filter
 */
export function generateListGroup({ map }) {
  const entities = map.dataSource.entities.values

  if (!entities.length) {
    return []
  }
  
  const listFilter = []

  if (map.field) {
    let pickShow = map.field

    if (map.field.includes('>')) {
      pickShow = map.field.split('>')[1]
    } else if (map.field.includes('.')) {
      pickShow = map.field.split('.').join('_')
    }

    listFilter.push({
      pickName: map.field,
      pickShow
    })
  }

  for (let key of map.fields) {
    if (key === map.field) {
      continue
    }

    if (key !== map.field) {
      let pickShow = key

      if (key.includes('>')) {
        pickShow = key.split('>')[1]
      } else if (key.includes('.')) {
        pickShow = key.split('.').join('_')
      }

      listFilter.push({
        pickName: key,
        pickShow
      })
    }
  }

  return listFilter
}

/**
 * Get or set default list template
 * @param {Object} map object of the current map 
 * @returns default list template
 */
export async function generateListTemplate({ map }) {
  const listTemplate = []

  const response = await axiosReact.get('/geoprosp/layer_style')

  response.data.forEach((dbTemplate) => {
    const lowDbTemplateType = dbTemplate.type ? dbTemplate.type.toLowerCase() : ''
    const lowGeomType = map.geom ? map.geom.toLowerCase() : ''

    if (
      (lowDbTemplateType.includes(lowGeomType)
        || lowGeomType.includes(lowDbTemplateType))
      && (
        dbTemplate.layer === map.category
        || dbTemplate.layer === map.subCategory
      ) 
    ) {
      listTemplate.push({
        pickName: dbTemplate.stylename,
        pickShow: dbTemplate.stylename
      })
    }
  })

  return listTemplate
}

/**
 * Return default pickList
 * @param {String} category used for generate pick list
 * @returns array of pick list
 */
 export function createPickAttribute(category) {
  if (category === 'all') {
    return [
      {
        pickName: 'single',
        pickShow: 'single'
      },
      {
        pickName: 'categorical',
        pickShow: 'categorical'
      },
      {
        pickName: 'numerical',
        pickShow: 'numerical'
      }
    ]
  } else if (category === 'withoutNumerical') {
    return [
      {
        pickName: 'single',
        pickShow: 'single'
      },
      {
        pickName: 'categorical',
        pickShow: 'categorical'
      }
    ]
  }
}

/**
 * Set default attribute
 * @param {Object} map object of the current map
 * @param {any} value is the value of map by field used to group by
 * @returns default attribute 
 */
 export function generateAttribute({ map, groupBy }) {
  let value = NaN
  const entities = map.dataSource.entities.values

  let i = 0

  while (isNaN(Number(value)) && i < entities.length) {
    value = findKeyProperties({
      properties: entities[i].properties,
      key: groupBy
    })

    i += 1
  }

  if (!map.fieldFormat) {
    if (!isNaN(Number(value))) {
      return {
        pickList: createPickAttribute('all'),
        selected: 'single'
      }
    } else {
      return {
        pickList: createPickAttribute('withoutNumerical'),
        selected: 'single'
      }
    }
  } else if (map.fieldFormat === 'categorical') {
    if (!isNaN(Number(value))) {
      return {
        pickList: createPickAttribute('all'),
        selected: 'categorical'
      }
    } else {
      return {
        pickList: createPickAttribute('withoutNumerical'),
        selected: 'categorical'
      }
    }
  } else if (map.fieldFormat === 'numerical') {
    if (!isNaN(Number(value))) {
      return {
        pickList: createPickAttribute('all'),
        selected: 'numerical'
      }
    } else {
      return {
        pickList: createPickAttribute('withoutNumerical'),
        selected: 'single'
      }
    }
  } else {
    if (!isNaN(Number(value))) {
      return {
        pickList: createPickAttribute('all'),
        selected: 'single'
      }
    } else {
      return {
        pickList: createPickAttribute('withoutNumerical'),
        selected: 'single'
      }
    }
  }
}

/**
 * Set default attribute
 * @param {Object} map object of the current map
 * @returns default attribute 
 */
 export function generateFieldMode({ map, selected, precision }) {
  let currentSelected = selected

  if (!currentSelected) {
    if (map.fieldFormatMode) {
      currentSelected = map.fieldFormatMode
    } else {
      currentSelected = 'equal_interval'
    }
  }
  
  const pickList = [
    {
      pickName: 'equal_interval',
      pickShow: 'Equal Interval'
    },
    {
      pickName: 'logarithmic',
      pickShow: 'Logarithmic'
    },
    {
      pickName: 'custom',
      pickShow: 'Custom'
    }
  ]

  let match = false

  for (let i = 0; i < pickList.length; i++) {
    if (pickList[i].pickName === currentSelected) {
      match = true
      
      break
    }
  }

  if (!match) {
    currentSelected = 'equal_interval'
  }

  return {
    pickList,
    selected: currentSelected,
    precision
  }
}

export function generateGroupEntitiesColor({
  map,
  groupEntities
}) {
  if (!map.colorScale) {
    return groupEntities
  }

  const keys = groupEntities.keys

  const style = {
    ...groupEntities.style
  }

  const scaleMethod = generateScaleMethod({
    mode: 'equal_interval',
    map,
    min: 0,
    max: keys.length - 1
  })

  for (let i = 0; i < keys.length; i++) {
    if (d3[map.colorScale]) {
      const color = d3[map.colorScale](
        getScore({
          inverse: map.cesiumLayer.inverse,
          score: scaleMethod(i)
        })
      )

      if (typeof color === 'string') {
        if (color.includes('rgb')) {
          const [ red, green, blue ] = color
            .slice(4, -1)
            .split(', ')
      
          style[keys[i].label].color = {
            red: Number(red) / 255,
            green: Number(green) / 255,
            blue: Number(blue) / 255,
            alpha: map.cesiumLayer.alpha
          }
        } else if (color.includes('#')) {
          const rgb = hexToRgb(color)

          if (rgb) {
            const { red, green, blue } = rgb

            style[keys[i].label].color = {
              red: Number(red) / 255,
              green: Number(green) / 255,
              blue: Number(blue) / 255,
              alpha: map.cesiumLayer.alpha
            }
          }
        }
      }
    } else {
      let color1 = null
      let color2 = null
      let stops = null

      for (let j = 0; j < COLOR_D3_QGIS_TEMPLATES.length; j++) {
        const template = COLOR_D3_QGIS_TEMPLATES[j]

        if (template.colorScale === map.colorScale) {
          color1 = template.color1
          color2 = template.color2
          stops = template.stops

          break
        }
      }

      const domains = [0, 1]
      const ranges = [`rgb(${color1})`, `rgb(${color2})`]

      const colorRange = d3.scaleLinear()
        .domain(domains)
        .range(ranges)

      const [ red, green, blue, alpha ] = colorRange(getScore({
        inverse: map.cesiumLayer.inverse,
        score: scaleMethod(i)
      }))
        .slice(4, -1)
        .split(',')

      map.cesiumLayer.setAlpha(Number(alpha) / 255)
  
      style[keys[i].label].color = {
        red: Number(red) / 255,
        green: Number(green) / 255,
        blue: Number(blue) / 255,
        alpha: map.cesiumLayer.alpha
      }
    }
  }
  
  return {
    ...groupEntities,
    style
  }
}
