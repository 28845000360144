import cesiumViewer from '../../../../apis/cesiumViewer'
import store from '../../../../store'

import * as d3 from 'd3'

import axiosReact from '../../../../apis/axiosReact'

import { COLOR_D3_QGIS_TEMPLATES } from '../../../../packages/qml/colorTemplate'

import {
  generateScaleMethod,
  getScore
} from '../../../../apis/cesiumMap/d3ScaleMethod'

import * as CesiumMapActions from '../../../../store/actions/catalog'
import * as CesiumEntityActions from '../../../../store/actions/cesiumEntity'

import { findKeyProperties } from '../../../../packages/cesium-add-on/properties'

import {
  LIST_FILTER_OPTION,
  LIST_SORT_BY_OPTION
} from '../../../../constants/Menubars/Layerbar'

import {
  GEOLOGICAL_MAP_COLOR_REFERENCES
} from '../../../../constants/Colors/geological'

export function generateColorGradient({ colorScale }) {
  let colors = []

  for (let i = 0; i < COLOR_D3_QGIS_TEMPLATES.length; i++) {
    if (colorScale === COLOR_D3_QGIS_TEMPLATES[i].colorScale) {
      
      if (COLOR_D3_QGIS_TEMPLATES[i].stops) {
        const { color1, color2, stops } = COLOR_D3_QGIS_TEMPLATES[i]

        const fillColors = stops
          .split(':')
          .map((stop) => {
            const [ repeat, color ] = stop.split(';')

            return (`rgba(${color}) ${repeat * 100}%`)
          })

        colors = [
          `rgba(${color1}) 0%`,
          ...fillColors,
          `rgba(${color2}) 100%`
        ]
      } else {
        const { color1, color2 } = COLOR_D3_QGIS_TEMPLATES[i]

        colors.push(`rgba(${color1}) 0%`)
        colors.push(`rgba(${color2}) 100%`)
      }
      break
    }
  }

  return colors.join(', ')
}

export function generateColor({
  defaultColor,
  alpha,
  fromCesium
}) {
  if (!defaultColor) {
    return null
  }

  let {
    red,
    green,
    blue
  } = defaultColor

  if (fromCesium) {
    red = red * 255
    green = green * 255
    blue = blue * 255
  }

  return `rgba(${red}, ${green}, ${blue}, ${alpha})`
}

export function getDataSource(name) {
  if (!name) {
    return null
  }

  return cesiumViewer.viewer
    && cesiumViewer.viewer.dataSources
    && cesiumViewer.viewer.dataSources.getByName(name)[0]
    
}

export function checkCategoryToggleAble(category){
  for (let subCategoryValue of Object.values(category.subCategory)) {
    if (subCategoryValue.status !== undefined && subCategoryValue.listMap === undefined) {
      return subCategoryValue.status
    }
    
    if (subCategoryValue.listMap.length) {
      return true
    }
  }

  return false
}

export function ellipsisText({
  text,
  max
}) {
  if (text.length > max) {
    return text.slice(0, max) + ' ...'
  } else {
    return text
  }
}

export function deleteDataByCategory({
  map
}) {
  /**
   * This function will remove map from the state
   */
  if (map.category === 'grid') {
    store.dispatch(CesiumMapActions.removeMap({
      map
    }))
  } else if (map.category === 'geological') {
    store.dispatch(CesiumMapActions.removeMap({
      map
    }))
  }
}

export function changeColorGradient({
  map,
  entities,
  scaleChrome
}) {
  /**
   * This function will change map entities color gradient
   * @param map current map to change the color
   * @param entities entities that have been make into a group
   * @param scaleChrome pick of the selected d3 scaleChrome
   */
  if (!entities.length) {
    return
  }

  entities.forEach((listEntity, index) => {
    if (!listEntity.length) {
      return
    }
    
    listEntity.forEach((entity) => {
      let score = index + 1

      if (map.cesiumLayer.inverse) {
        score = entities.length - index
      }

      const [ red, green, blue ] = d3
        [scaleChrome](score / entities.length)
        .slice(4, -1)
        .split(', ')

      store.dispatch(
        CesiumEntityActions.changeEntityColor({
          colorEntity: map.colorEntities[entity.id],
          red: red / 255,
          green: green / 255,
          blue: blue / 255
        })
      )
    })
  })
}

export function changeColorTemplate({
  map,
  entities
}) {
  /**
   * This function will change map entities color default by template
   * @param map current map to change the color
   * @param entities entities that have been make into a group
   */
  if (map.category === 'geological') {
    entities.forEach((listEntity) => {
      listEntity.forEach((entity) => {
        const lithology = entity
          .properties
          .getValue('')
          .Use_Group
          .toLowerCase()

        for (let i = 0; i < GEOLOGICAL_MAP_COLOR_REFERENCES.length; i++) {
          if (lithology.includes(GEOLOGICAL_MAP_COLOR_REFERENCES[i].key)) {
            const {
              red,
              green,
              blue
            } = GEOLOGICAL_MAP_COLOR_REFERENCES[i].color
            
            store.dispatch(
              CesiumEntityActions.changeEntityColor({
                colorEntity: map.colorEntities[entity.id],
                red: red,
                green: green,
                blue: blue
              })
            )

            break
          }
          
          if (i === GEOLOGICAL_MAP_COLOR_REFERENCES.length - 1) {
            store.dispatch(
              CesiumEntityActions.changeEntityColor({
                colorEntity: map.colorEntities[entity.id],
                red: 1,
                green: 1,
                blue: 1
              })
            )
          }
        }
      })
    })
  }
}

/**
 * Return default pickList
 * @param {String} category used for generate pick list
 * @returns array of pick list
 */
export function createPickAttribute(category) {
  if (category === 'all') {
    return [
      {
        pickName: 'single',
        pickShow: 'single'
      },
      {
        pickName: 'categorical',
        pickShow: 'categorical'
      },
      {
        pickName: 'numerical',
        pickShow: 'numerical'
      }
    ]
  } else if (category === 'withoutNumerical') {
    return [
      {
        pickName: 'single',
        pickShow: 'single'
      },
      {
        pickName: 'categorical',
        pickShow: 'categorical'
      }
    ]
  }
}

/**
 * Set default attribute
 * @param {Object} map object of the current map
 * @param {any} value is the value of map by field used to group by
 * @returns default attribute 
 */
export function generateAttribute({ map, groupBy }) {
  let value = NaN
  const entities = map.dataSource.entities.values

  let i = 0

  while (isNaN(Number(value)) && i < entities.length) {
    value = findKeyProperties({
      properties: entities[i].properties,
      key: groupBy
    })

    i += 1
  }

  if (!map.fieldFormat) {
    if (!isNaN(Number(value))) {
      return {
        pickList: createPickAttribute('all'),
        selected: 'single'
      }
    } else {
      return {
        pickList: createPickAttribute('withoutNumerical'),
        selected: 'single'
      }
    }
  } else if (map.fieldFormat === 'categorical') {
    if (!isNaN(Number(value))) {
      return {
        pickList: createPickAttribute('all'),
        selected: 'categorical'
      }
    } else {
      return {
        pickList: createPickAttribute('withoutNumerical'),
        selected: 'categorical'
      }
    }
  } else if (map.fieldFormat === 'numerical') {
    if (!isNaN(Number(value))) {
      return {
        pickList: createPickAttribute('all'),
        selected: 'numerical'
      }
    } else {
      return {
        pickList: createPickAttribute('withoutNumerical'),
        selected: 'single'
      }
    }
  } else {
    if (!isNaN(Number(value))) {
      return {
        pickList: createPickAttribute('all'),
        selected: 'single'
      }
    } else {
      return {
        pickList: createPickAttribute('withoutNumerical'),
        selected: 'single'
      }
    }
  }
}

/**
 * Set default attribute
 * @param {Object} map object of the current map
 * @returns default attribute 
 */
export function generateFieldMode({ map, selected, precision }) {
  let currentSelected = selected

  if (!currentSelected) {
    if (map.fieldFormatMode) {
      currentSelected = map.fieldFormatMode
    } else {
      currentSelected = 'equal_interval'
    }
  }
  
  const pickList = [
    {
      pickName: 'equal_interval',
      pickShow: 'Equal Interval'
    },
    {
      pickName: 'logarithmic',
      pickShow: 'Logarithmic'
    },
    {
      pickName: 'custom',
      pickShow: 'Custom'
    }
  ]

  let match = false

  for (let i = 0; i < pickList.length; i++) {
    if (pickList[i].pickName === currentSelected) {
      match = true
      
      break
    }
  }

  if (!match) {
    currentSelected = 'equal_interval'
  }

  return {
    pickList,
    selected: currentSelected,
    precision
  }
}

/**
 * Get or set default list template
 * @param {Object} map object of the current map 
 * @returns default list template
 */
export async function generateListTemplate({ map }) {
  const listTemplate = []

  const response = await axiosReact.get('/geoprosp/layer_style')

  response.data.forEach((dbTemplate) => {
    const lowDbTemplateType = dbTemplate.type.toLowerCase()
    const lowGeomType = map.geom.toLowerCase()

    if (
      (lowDbTemplateType.includes(lowGeomType)
        || lowGeomType.includes(lowDbTemplateType))
      && (
        dbTemplate.layer === map.category
        || dbTemplate.layer === map.subCategory
      ) 
    ) {
      listTemplate.push({
        pickName: dbTemplate.stylename,
        pickShow: dbTemplate.stylename
      })
    }
  })

  return listTemplate
}

/**
 * Get or set default list filter
 * @param {Object} map object of the current map 
 * @returns default list filter
 */
export function generateListGroup({ map }) {
  const entities = map.dataSource.entities.values

  if (!entities.length) {
    return []
  }
  
  const listFilter = []

  if (map.field) {
    let pickShow = map.field

    if (map.field.includes('>')) {
      pickShow = map.field.split('>')[1]
    } else if (map.field.includes('.')) {
      pickShow = map.field.split('.').join('_')
    }

    listFilter.push({
      pickName: map.field,
      pickShow
    })
  }

  for (let key of map.fields) {
    if (key === map.field) {
      continue
    }

    if (key !== map.field) {
      let pickShow = key

      if (key.includes('>')) {
        pickShow = key.split('>')[1]
      } else if (key.includes('.')) {
        pickShow = key.split('.').join('_')
      }

      listFilter.push({
        pickName: key,
        pickShow
      })
    }
  }

  return listFilter
}

function hexToRgb(hex) {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b
  })

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null
}

export function generateGroupEntitiesColor({
  map,
  groupEntities
}) {
  if (!map.colorScale) {
    return groupEntities
  }

  const keys = groupEntities.keys

  const style = {
    ...groupEntities.style
  }

  const scaleMethod = generateScaleMethod({
    mode: 'equal_interval',
    map,
    min: 0,
    max: keys.length - 1
  })

  for (let i = 0; i < keys.length; i++) {
    
    if (d3[map.colorScale]) {
      const color = d3[map.colorScale](
        getScore({
          inverse: map.cesiumLayer.inverse,
          score: scaleMethod(i)
        })
      )

      if (typeof color === 'string') {
        if (color.includes('rgb')) {
          const [ red, green, blue ] = color
            .slice(4, -1)
            .split(', ')
      
          style[keys[i].label].color = {
            red: Number(red) / 255,
            green: Number(green) / 255,
            blue: Number(blue) / 255,
            alpha: map.cesiumLayer.alpha
          }
        } else if (color.includes('#')) {
          const rgb = hexToRgb(color)

          if (rgb) {
            const { red, green, blue } = rgb

            style[keys[i].label].color = {
              red: Number(red) / 255,
              green: Number(green) / 255,
              blue: Number(blue) / 255,
              alpha: map.cesiumLayer.alpha
            }
          }
        }
      }
    } else {
      let color1 = null
      let color2 = null
      let stops = null

      for (let j = 0; j < COLOR_D3_QGIS_TEMPLATES.length; j++) {
        const template = COLOR_D3_QGIS_TEMPLATES[j]

        if (template.colorScale === map.colorScale) {
          color1 = template.color1
          color2 = template.color2
          stops = template.stops

          break
        }
      }

      const domains = [0, 1]
      const ranges = [`rgb(${color1})`, `rgb(${color2})`]

      const colorRange = d3.scaleLinear()
        .domain(domains)
        .range(ranges)

      const [ red, green, blue, alpha ] = colorRange(getScore({
        inverse: map.cesiumLayer.inverse,
        score: scaleMethod(i)
      }))
        .slice(4, -1)
        .split(',')

      map.cesiumLayer.setAlpha(Number(alpha) / 255)
  
      style[keys[i].label].color = {
        red: Number(red) / 255,
        green: Number(green) / 255,
        blue: Number(blue) / 255,
        alpha: map.cesiumLayer.alpha
      }
    }
  }
  
  return {
    ...groupEntities,
    style
  }
}

/**
 * Get or set default list sort
 * @param {Object} map object of the current map 
 * @returns default list sort
 */
 export function getOrSetDefaultListSort({ map }) {
  if (LIST_SORT_BY_OPTION[map.category]) {
    return LIST_SORT_BY_OPTION[map.category][0].pickName
  } else {
    LIST_SORT_BY_OPTION[map.category] = [
      {
        pickName: 'a-z',
        pickShow: 'A - Z'
      },
      {
        pickName: 'z-a',
        pickShow: 'Z - A'
      }
    ]

    return LIST_SORT_BY_OPTION[map.category][0].pickName
  }
}
