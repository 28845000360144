import axiosReact, { getProgressDownload } from '../../../axiosReact'
import rgbToHex from '../../../helpers/rgbToHex'

export async function fetch({
  // map,
  params,
  fetchProgress,
  fetchFinish
}) {
  try {
    if (params.withgeojson) {
      const { data } = await axiosReact.get('/geofix/accessibility/gas_station', {
        // params,
        onDownloadProgress(event) {
          getProgressDownload(event, fetchProgress)
        }
      })

      data.features.forEach((feature) => {
        if (feature.properties.administration?.length) {
          feature.properties.administration = feature.properties.administration[0]
        }

        feature.properties = formatProperty(feature.properties)
      })

      fetchFinish()

      return {
        geojson: data
      }
    }
  } catch (error) {
    throw error
  }
}

export function formatProperty(property) {
  return {
    name: property.name,
    Class: property.class,
    Source: property.source,
    City: property.administration?.city,
    Province: property.administration?.province,
    Island: property.administration?.island
  }
}

export function parseFetch({ data }) {
  return data
}

export function getColor(_, {
  map
}) {
  return {
    red: 1,
    green: 1,
    blue: 1,
    alpha: map.cesiumLayer.alpha
  }
}

export function getIcon(entity, color) {
  let hex = null

  const status = entity.properties.getValue('class').class

  if (status && status.toLowerCase().includes('old')) {
    hex = '#808080'
  } else if (color) {
    hex = rgbToHex(
      Math.round(color.red * 255),
      Math.round(color.green * 255),
      Math.round(color.blue * 255)
    )
  } else {
    hex = rgbToHex(
      Math.round(Math.random() * 255),
      Math.round(Math.random() * 255),
      Math.round(Math.random() * 255)
    )
  }
  const svgDataDeclare = "data:image/svg+xml,";
  const svgCircle = `<circle cx="94.8" cy="94.8" r="94.8" style="fill:%23be3e51" /><path d="M65.22,37.87H114.8a20.36,20.36,0,0,1,3,1c3.72,1.87,5.41,5,5.41,9.09q0,29.85,0,59.7V109h1.32c5.38,0,8.18,2.83,8.19,8.25,0,1.29,0,2.59.06,3.88a2.26,2.26,0,0,0,2.12,2.07,2.3,2.3,0,0,0,2.4-1.73,6.14,6.14,0,0,0,.14-1.54q0-19.06,0-38.13V80.62c-.44-.07-.76-.13-1.09-.16a9.31,9.31,0,0,1-8.29-8.6c-.11-2.55-.18-5.11-.07-7.66a4,4,0,0,1,1.1-2.3c1.81-2,3.73-3.82,5.64-5.68.58-.56,1.25-1,1.93-1.55-2.32-2.32-4.34-4.33-6.35-6.35a21.32,21.32,0,0,1-1.89-2,2.25,2.25,0,0,1,.23-2.94,2.33,2.33,0,0,1,3-.29,7.6,7.6,0,0,1,1,.87c2.68,2.67,5.31,5.38,8,8a4.76,4.76,0,0,1,1.54,3.73q-.06,32.07,0,64.14c0,.59,0,1.19-.05,1.78a7.06,7.06,0,0,1-6.91,6.4,7,7,0,0,1-7.12-6.14c-.16-1.76-.1-3.55-.15-5.32a2.46,2.46,0,0,0-2.65-2.74c-.65,0-1.31,0-2,0v19c.25,0,.43.07.61.08a9.34,9.34,0,0,1,8.69,8.58c.11,2.29.11,4.59.12,6.88,0,2.69-.74,3.44-3.41,3.44H50.75c-.29,0-.59,0-.89,0a2.34,2.34,0,0,1-2.47-2.44c0-2.63-.06-5.27.07-7.89a8.79,8.79,0,0,1,3.17-6.27,9.76,9.76,0,0,1,6.19-2.38v-1.59q0-41.4,0-82.81a10.42,10.42,0,0,1,2.6-7.45A10.81,10.81,0,0,1,65.22,37.87ZM90.07,80.59h20.55c2.35,0,3.13-.77,3.13-3.13V50.58c0-2.45-.75-3.2-3.17-3.2H69.47c-2.35,0-3.14.79-3.14,3.13V77.39c0,2.43.77,3.2,3.18,3.2Z" style="fill:%23fff" />

  `;
  const svgPrefix = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/2000/xlink" x="0px" y="0px" viewBox="0 0 189.6 189.6" height="60pt" width="60pt" preserveAspectRatio="xMidYMid meet">`;
  const svgSuffix = "</svg>";
  const svgString = svgPrefix + svgCircle + svgSuffix;

  // create the cesium entity
  const svgEntityImage = svgDataDeclare + svgString;
  return svgEntityImage
}
