import styled, { css } from 'styled-components'

export const ToggleSVGButton = styled.div.attrs(props => {
  return {
    style: {
      display: props.flex && 'flex',
      margin: props.margin,
      alignItems: props.center && 'center',
      justifyContent: props.center && 'center'
    }
  }
})`
  transition: all 330ms;
  cursor: pointer;
  
  svg {
    ${
      props => props.active || props.hover
        ? css`
          fill: ${props.fillActive ? props.fillActive : 'url(#gold-gradient) goldenrod'};
          `
        : css`
          fill: ${
            props.fillInActive
              ? props.fillInActive
              : props.light
                ? 'black'
                : '#999999'
          };
        `
    }
  }

  &:hover {
    svg {
      ${
        props => props.active || props.hover
          ? css`
            fill: ${
              props.fillInActive
                ? props.fillInActive
                : props.light
                  ? 'black'
                  : '#999999'
            };
            `
          : css`
            fill: ${props.fillActive ? props.fillActive : 'url(#gold-gradient) goldenrod'};
          `
      }
    }
  }
`
