import React from 'react'

import cesiumViewer from '../../../../apis/cesiumViewer'

import {
  ReactComponent as CursorIcon
} from '../../../../images/Icons/cursor.svg'

import ContentIcon from './ContentIcon'

/**
 * The component cursor button
 * @param {Object} content that represent the button given
 * @param {Number} translate to set how far this component is translated
 * @param {Number} left the left position of button
 * @param {Number} top the top position of button
 * @param {Number} r radius of the black circle
 * @param {Number} radians the radians of rotation value
 * @param {Number} widthSVG is the width given for SVG
 * @param {Boolean} toggleDisplay status that button being shown or not
 * @param {Object} section is the state for section
 * @param {Function} setSelection is the method to change selection sta
 */
function CursorButton({
  content,
  translate,
  left,
  top,
  r,
  radians,
  widthSVG,
  toggleDisplay,
  section,
  selection,
  setSelection
}) {
  function onClickHandler() {
    if (selection === true) {
      if (
        !section.crossSection
        && !section.blockSection
        && !section.sliceSection
      ) {
        return
      }
    }

    setSelection(!selection)
    cesiumViewer.sectionStatus.selection = !selection
  }

  return (
    <ContentIcon
        top={
          toggleDisplay
            ? top + 'em'
            : top - translate - r * Math.sin(radians) + 'em'
        }
        left={
          toggleDisplay
            ? left + 'em'
            : left - translate - r * Math.cos(radians) + 'em'
        }
        height={widthSVG + 'em'}
        width={widthSVG + 'em'}
        isActive={selection}
      >
        <CursorIcon
          title={content.title}
          width={25}
          height={25}
          onClick={onClickHandler}
        />
      </ContentIcon>
  )
}

export default CursorButton
