import * as actionTypes from '../../constants/store/actionTypes/rankbar'

export function toggleRankbar(payload) {
  return {
    type: actionTypes.TOGGLE_RANKBAR,
    payload
  }
}

export function minimizeRankbar(payload) {
  return {
    type: actionTypes.MINIMIZE_RANKBAR,
    payload
  }
}

export function resetRankbar(payload) {
  return {
    type: actionTypes.RESET_RANKBAR,
    payload
  }
}
